import { SmartColumnType } from '@/components/SmartTable/types'
import { useHandleUpdatePayrollPayment } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollPayment'
import { useMemo } from 'react'
import { PayrollPlaygroundTableType } from '../../types'
import { useFormContext, useWatch } from 'react-hook-form'
import { paymentsHeader, paymentsHeaderRaw } from '@/libs/react-query/types'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { capitalizeFirstLetter } from '@/utils/workers/name'
import { useHandleUpdatePayrollConcept } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollConcept'
import { insertAtArrayPosition } from '@/utils/array'
import { useCan } from '@/hooks'

export const usePayrollOtherEarns = () => {
  const { data: company } = useMyCompany()
  const { mutateAsync: handleUpdatePayrollPayment } =
    useHandleUpdatePayrollPayment()
  const { mutateAsync: handleUpdatePayrollConcept } =
    useHandleUpdatePayrollConcept()

  const { control, getValues } = useFormContext<PayrollPlaygroundTableType>()

  const status = useWatch({
    control,
    name: 'status',
  })

  const canEditPayroll = useCan(['payroll.edit', 'payroll.closePayroll'])

  const columns: SmartColumnType[] = useMemo(() => {
    const orderedConcepts = company?.concepts.sort() || []
    const orderedConceptsFormatted = orderedConcepts.map((o) =>
      capitalizeFirstLetter(o),
    )
    const isClosed = status === 'closed' || !canEditPayroll

    let cols: SmartColumnType[] = [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Vacaciones',
        name: 'Vacaciones',
        items: [
          {
            type: 'input-text',
            name: 'vacationDays',
            draggable: null,
            header: 'Dias de Vacaciones',
            disabled: true,
            inputType: 'no-mask',
            width: 160,
          },
          {
            type: 'input-text',
            name: 'vacationDaysValue',
            draggable: null,
            header: 'Valor',
            disabled: true,
            inputType: 'money-guarani',
            width: 120,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Bonificación Familiar',
        name: 'Bonificación Familiar',
        items: [
          {
            type: 'input-text',
            name: 'childrenBonus',
            draggable: null,
            header: 'Hijos < 17',
            disabled: true,
            inputType: 'no-mask',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'childrenBonusValue',
            draggable: null,
            header: 'Total Bonif.',
            disabled: true,
            inputType: 'money-guarani',
            width: 130,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Pagos e Bonificaciones (pre IPS)',
        name: 'Pagos e Bonificaciones (pre IPS)',
        items: [
          {
            type: 'input-text',
            name: 'creditPaymentsPreIPS',
            inputType: 'money-guarani',
            array: true,
            enableEmptySubmit: true,
            length: paymentsHeader.length,
            handleSubmitValue: async (value) => {
              const data = getValues()

              if (!data.data[value.index].userId || !data.id) {
                return
              }

              await handleUpdatePayrollPayment({
                paymentType: paymentsHeaderRaw[value.subindex || 0],
                payroll_id: data.id,
                user_id: data.data[value.index].userId,
                should_include_in_ips: true,
                value:
                  !value.data.value && value.data.value !== 0
                    ? null
                    : Number(value.data.value),
              })
            },
            header: 'Pagos e Bonificaciones',
            headers: paymentsHeader,
            width: 140,
            disabled: isClosed,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Total',
        name: 'Total',
        items: [
          {
            type: 'input-text',
            name: 'paymentsSubtotal',
            inputType: 'money-guarani',
            header: 'Subtotal',
            width: 140,
            disabled: true,
          },
        ],
      },
    ] as SmartColumnType[]

    if (company?.concepts.length) {
      cols = insertAtArrayPosition(cols, 3, {
        type: 'subheader',
        header: 'Conceptos de Salario (pre IPS)',
        name: 'Conceptos de Salario (pre IPS)',
        items: [
          {
            type: 'input-text',
            name: 'conceptsPreIPS',
            inputType: 'money-guarani',
            array: true,
            header: 'Conceptos de Salario',
            enableEmptySubmit: true,
            length: company?.concepts.length,
            headers: orderedConceptsFormatted,
            disabled: isClosed,
            width: company?.concepts.length === 1 ? 220 : 160,
            handleSubmitValue: async (value) => {
              const data = getValues()

              if (!data.data[value.index].userId || !data.id) {
                return
              }

              await handleUpdatePayrollConcept({
                name: orderedConcepts[value.subindex || 0],
                payroll_id: data.id,
                user_id: data.data[value.index].userId,
                ips: true,
                value:
                  !value.data.value && value.data.value !== 0
                    ? null
                    : Number(value.data.value),
              })
            },
          },
        ],
      })
    }

    return cols
  }, [
    status,
    handleUpdatePayrollPayment,
    getValues,
    company,
    handleUpdatePayrollConcept,
    canEditPayroll,
  ])

  return columns
}
