import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdatePlanDTO } from '@/libs/react-query/types/plan'

export const useHandleUpdatePlan = () => {
  const mutation = useCustomMutation(['useHandleUpdatePlan'], handleUpdatePlan)

  return mutation
}

const handleUpdatePlan = async (props: IUpdatePlanDTO) => {
  try {
    await apiV2.post<void>('/permission/update-plan', props)

    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}
