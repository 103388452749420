import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateUsersCompanyConfigsDTO } from '@/libs/react-query/types/company'

export const useUpdateUsersCompanyConfigs = () => {
  const mutation = useCustomMutation(
    ['useUpdateUsersCompanyConfigs'],
    handleUpdateUsersCompanyConfigs,
  )

  return mutation
}

const handleUpdateUsersCompanyConfigs = async (
  props: IUpdateUsersCompanyConfigsDTO,
) => {
  try {
    await apiV2.post<void>('/company/update-users-company-configs', props)

    queryClient.invalidateQueries('worker-liquidation')
    queryClient.invalidateQueries('workers')
  } catch (error) {
    console.log(error)
  }
}
