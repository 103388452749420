import { Div, FormGroup } from '@/components'
import {
  useGroupInfo,
  useLiquidationDiscountsConfiguration,
  useLiquidationEarningsConfiguration,
  useLiquidationGroupConcepts,
  useLiquidationHoursConfiguration,
} from '../form'
import { MAX_LIQUIDATION_DRAWER_WIDTH } from '..'

export const LiquidationConfigGroupTab = () => {
  const { data: groupInfo } = useGroupInfo()
  const { data: liquidationGroupConcepts } = useLiquidationGroupConcepts()
  const { data: liquidationHours } = useLiquidationHoursConfiguration()
  const { data: liquidationEarnings } = useLiquidationEarningsConfiguration()
  const { data: liquidationDiscounts } = useLiquidationDiscountsConfiguration()

  return (
    <Div
      css={{
        paddingTop: 16,
      }}
    >
      <FormGroup
        type="fixed"
        title="Informaciones del Grupo de Configuraciones"
        items={groupInfo}
        cols={1}
        css={{ maxWidth: MAX_LIQUIDATION_DRAWER_WIDTH }}
      />
      <FormGroup
        type="fixed"
        title="Horas para Cálculo de Media Salarial"
        items={liquidationHours}
        cols={2}
        css={{ maxWidth: MAX_LIQUIDATION_DRAWER_WIDTH, marginTop: '$8' }}
      />
      <FormGroup
        type="fixed"
        title="Pagos para Cálculo de Media Salarial"
        items={liquidationEarnings}
        cols={2}
        css={{ maxWidth: MAX_LIQUIDATION_DRAWER_WIDTH, marginTop: '$8' }}
      />
      <FormGroup
        type="fixed"
        title="Descuentos para Cálculo de Media Salarial"
        items={liquidationDiscounts}
        cols={2}
        css={{ maxWidth: MAX_LIQUIDATION_DRAWER_WIDTH, marginTop: '$8' }}
      />
      <FormGroup
        type="fixed"
        title="Conceptos para Cálculo de Media Salarial"
        items={liquidationGroupConcepts}
        cols={2}
        css={{ maxWidth: MAX_LIQUIDATION_DRAWER_WIDTH, marginTop: '$8' }}
      />
    </Div>
  )
}
