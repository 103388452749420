import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Progress, TextInput } from '@punto-ui/react'
import { useMovementsContext } from '../../context/hooks/useMovementsContext'
import { FilterInput, FiltersChips, FiltersContainer } from './styles'
import { CellFilter } from '@/components'

import React from 'react'
import { useMovements } from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'

export const Filters = () => {
  const { filter: movesFilters, setFilter: setMovesFilters } =
    useMovementsContext()
  const { isFetching: isfetchMovements } = useMovements(movesFilters)

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMovesFilters((state) => ({
      ...state,
      filter: e.target.value,
      page: 0,
    }))
  }

  return (
    <FiltersContainer>
      <FilterInput>
        <TextInput
          placeholder="Buscar por nombre, apellido, CI..."
          icon={<MagnifyingGlassIcon />}
          onChange={handleFilterChange}
        />
      </FilterInput>
      {isfetchMovements && <Progress />}

      <FiltersChips>
        <CellFilter
          selectedIds={movesFilters.cellsIds}
          defaultValues={true}
          policiesIds={permissionsArray.filter((p) => p.includes('moves'))}
          callback={(options) => {
            const cellsIds = options.map((cell) => cell.id)
            setMovesFilters((state) => ({
              ...state,
              page: 0,
              cellsIds,
            }))
          }}
        />
      </FiltersChips>
    </FiltersContainer>
  )
}
