export function insertAtArrayPosition<T>(
  array: T[],
  position: number,
  value: T,
): T[] {
  // Using splice to insert value at the specified position
  array.splice(position, 0, value)
  return array
}

export function insertArrayAndReplace<T>(
  arr1: T[],
  insertingArr1: T[],
  index: number,
): T[] {
  const entranceCopyArr = [...arr1]
  const entranceInsertingCopyArr = [...insertingArr1]

  // First, we ensure the index is within the bounds of the array.
  if (index < 0 || index >= entranceCopyArr.length) {
    throw new Error('Index out of bounds.')
  }

  // Use the spread operator to decompose the arrays and slice to remove the element at the specified index.
  // Then, we reconstruct the array with the new elements inserted at the specified position.
  return [
    ...entranceCopyArr.slice(0, index), // Elements before the specified index
    ...entranceInsertingCopyArr, // Elements from the inserting array
    ...entranceCopyArr.slice(index + 1), // Elements after the specified index, excluding the removed element
  ]
}

export const sortArrayBasedOnOrder = (arr: string[], order: string[]) => {
  // Sort the array based on the order array
  return arr.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}
