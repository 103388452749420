import { useEffect, useRef } from 'react'

export const useExecuteOnceWhen = (callback: () => void, when: boolean) => {
  const isFirstSet = useRef(false)

  useEffect(() => {
    if (!when) {
      return
    }

    if (!isFirstSet.current && when) {
      isFirstSet.current = true
      callback()
    }
  }, [callback, when])
}
