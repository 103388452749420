import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { ConfigFields } from '../../..'
import { useCan } from '@/hooks'

export const useGroupInfo = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'input',
      label: 'Nombre del Grupo de Configuración',
      name: 'payment.name',
      disabled: !canCreatePayments,
    },
    {
      disabled: !canCreatePayments,
      _type: 'dropdown',
      label: 'Moneda Estándar',
      name: 'payment.currency',
      options: [
        { value: 'PYG', label: 'Guaraní (G$)' },
        { value: 'USD', label: 'Dólar ($)' },
        { value: 'BRL', label: 'Real (R$)' },
      ],
      defaultOption: {
        value: 'PYG',
        label: 'Guaraní (G$)',
      },
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useMonetaryInfo = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const moneyForm: ConfigFields = [
    {
      _type: 'input',
      label: 'Numero de horas por dia',
      disabled: !canCreatePayments,
      name: 'payment.number_hours_per_day',
      placeholder: '00:00',
      inputType: 'hour',
    },
    {
      _type: 'input',
      label: 'Valor Hora Nocturna %',
      disabled: !canCreatePayments,
      name: 'payment.hours_night_ordinary_value',
      inputType: 'percentage-with-decimal',
    },
    {
      _type: 'input',
      label: 'Valor Hora Extra Diurna %',
      name: 'payment.hours_morning_extra_value',
      disabled: !canCreatePayments,
      inputType: 'percentage-with-decimal',
    },
    {
      _type: 'input',
      label: 'Valor Hora Extra Nocturna %',
      name: 'payment.hours_night_extra_value',
      disabled: !canCreatePayments,
      inputType: 'percentage-with-decimal',
    },
    {
      _type: 'input',
      label: 'Valor Hora Extra Diurna Feriado %',
      name: 'payment.hours_morning_holiday_value',
      disabled: !canCreatePayments,
      inputType: 'percentage-with-decimal',
    },
    {
      _type: 'input',
      label: 'Valor Hora Extra Nocturna Feriado %',
      name: 'payment.hours_night_holiday_value',
      disabled: !canCreatePayments,
      inputType: 'percentage-with-decimal',
    },
  ]

  return {
    data: moneyForm,
  }
}

export const usePayrollParams = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const payrollParams: ConfigFields = [
    {
      _type: 'checkbox',
      label: 'Jornalero',
      disabled: !canCreatePayments,
      name: 'payment.is_daily_payment',
    },
    {
      _type: 'checkbox',
      label: 'Pagamento por Horas',
      disabled: !canCreatePayments,
      name: 'payment.should_pay_hourly',
    },
    {
      _type: 'checkbox',
      label: 'Pagar por dias naturales.',
      disabled: !canCreatePayments,
      name: 'payment.should_pay_as_natural_days',
    },
    {
      _type: 'checkbox',
      label: 'Pagar por dias naturales (sin meses de 31 dias)',
      disabled: !canCreatePayments,
      name: 'payment.should_consider_31_days_month_partially_natural',
    },
    {
      _type: 'checkbox',
      label: 'Registrado en IPS',
      disabled: !canCreatePayments,
      name: 'payment.is_subscribed_ips',
    },
    {
      _type: 'checkbox',
      label: 'Considerar bonificación familiar',
      disabled: !canCreatePayments,
      name: 'payment.should_pay_children_bonus',
    },
    {
      _type: 'checkbox',
      label: 'Bonificación familiar en IPS',
      name: 'payment.should_include_children_bonus_in_ips',
      disabled: !canCreatePayments,
    },
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Incluir pagos en IPS',
      name: 'payment.should_include_payments_in_ips',
    },
  ]

  return {
    data: payrollParams,
  }
}

export const useHoursParams = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const hoursParams: ConfigFields = [
    {
      disabled: !canCreatePayments,
      _type: 'checkbox',
      label: 'Adicional nocturno entero',
      name: 'payment.should_include_ordinary_salary_in_night_hours',
    },
    // {
    //   _type: 'checkbox',
    //   label: 'Considerar hora nocturna entera',
    //   name: 'consider_hours_night_full',
    // },
    // {
    //   _type: 'checkbox',
    //   label: 'Considerar horas extras diurnas',
    //   name: 'consider_hours_extra_morning',
    // },
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Considerar horas extras feriado',
      name: 'payment.should_consider_company_holidays',
    },
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Presencia por padrón',
      name: 'payment.should_consider_presence_default',
    },
  ]

  return {
    data: hoursParams,
  }
}

export const useDiscountParams = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const discountParams = [
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Ignorar intervalos no finalizados',
      name: 'ignore_unfinished_intervals',
    },
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Descontar intervalo nocturno',
      name: 'consider_discount_night_interval',
    },
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Descuento automático de intervalos nocturnos',
      name: 'discount_night_interval',
    },
  ]

  return {
    data: discountParams,
  }
}
export const useIpsParams = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const hoursParams: ConfigFields = [
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Incluir horas ordinárias nocturnas en IPS',
      name: 'payment.should_include_night_ordinary_in_ips',
    },
    {
      _type: 'checkbox',
      label: 'Incluir horas extras nocturnas en IPS',
      disabled: !canCreatePayments,
      name: 'payment.should_include_night_extra_in_ips',
    },
    {
      _type: 'checkbox',
      label: 'Incluir horas extras diurnas en IPS',
      disabled: !canCreatePayments,
      name: 'payment.should_include_morning_extra_in_ips',
    },
    {
      _type: 'checkbox',
      label: 'Incluir horas feriado nocturnas en IPS',
      disabled: !canCreatePayments,
      name: 'payment.should_include_night_holiday_in_ips',
    },
    {
      _type: 'checkbox',
      label: 'Incluir horas feriado diurnas en IPS',
      disabled: !canCreatePayments,
      name: 'payment.should_include_morning_holiday_in_ips',
    },
    {
      _type: 'checkbox',
      disabled: !canCreatePayments,
      label: 'Incluir desconto de horas diurnas en IPS',
      name: 'payment.should_include_morning_hours_discount_in_ips',
    },
    {
      disabled: !canCreatePayments,
      _type: 'checkbox',
      label: 'Incluir desconto de horas nocturnas en IPS',
      name: 'payment.should_include_night_hours_discount_in_ips',
    },
  ]

  return {
    data: hoursParams,
  }
}

export const useHoursAuthorizations = () => {
  const canCreatePayments = useCan(['configurations.payments'])
  const { data: isHourAuthorizationEnabled } =
    useIsFeatureInPlan('hour-authorization')

  const hoursAuthorization: ConfigFields = [
    {
      _type: 'checkbox',
      label: 'Autorizar horas ordinarias nocturnas',
      name: 'payment.should_authorize_night_hours',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
    },
    {
      _type: 'checkbox',
      label: 'Autorizar horas extras diurnas',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
      name: 'payment.should_authorize_extra_morning_hours',
    },
    {
      _type: 'checkbox',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
      label: 'Autorizar horas extras nocturnas',
      name: 'payment.should_authorize_extra_night_hours',
    },
    {
      _type: 'checkbox',
      label: 'Autorizar horas feriado diurnas',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
      name: 'payment.should_authorize_holiday_morning_hours',
    },
    {
      _type: 'checkbox',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
      label: 'Autorizar horas feriado nocturnas',
      name: 'payment.should_authorize_holiday_night_hours',
    },
    {
      _type: 'checkbox',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
      label: 'Autorizar descuento de horas diurnas',
      name: 'payment.should_authorize_morning_hours_discount',
    },
    {
      _type: 'checkbox',
      disabled: !isHourAuthorizationEnabled || !canCreatePayments,
      label: 'Autorizar descuento de horas nocturnas',
      name: 'payment.should_authorize_night_hours_discount',
    },
  ]

  return {
    data: hoursAuthorization,
  }
}

// export const useIpsOtherIncomes = () => {
//   const otherIncomesInfo = [
//     {
//       _type: 'checkbox',
//       label: 'Bonif. familiar',
//       name: 'payment_children_bonus',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Movilidad',
//       name: 'payment_mobility',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Comision',
//       name: 'payment_commission',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Bonos',
//       name: 'payment_bonus',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Gratificaciones',
//       name: 'payment_gift',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Otros ingresos',
//       name: 'payment_others',
//     },
//   ]

//   return {
//     data: otherIncomesInfo,
//   }
// }

// export const useIpsDiscounts = () => {
//   const discountInfo = [
//     {
//       _type: 'checkbox',
//       label: 'Horas diurnas',
//       name: 'discount_hours_required_morning',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Horas nocturnas',
//       name: 'discount_hours_required_night',
//     },
//     {
//       _type: 'checkbox',
//       label: 'Otros descuentos',
//       name: 'discount_others',
//     },
//   ]

//   return {
//     data: discountInfo,
//   }
// }
