import { CellFilter, Div } from '@/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { ICell } from '@/libs/react-query/types/organogram'
import { Button, Progress } from '@punto-ui/react'
import { useSmartShift } from '../../context'
import { useShiftsPage } from '@/pages/turnos/context'
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline'
import React, { memo } from 'react'

export const Header = () => {
  const {
    isLoading,
    cellUsage,
    handleUpdateSmartShiftState,
    selectedCellsIds,
  } = useSmartShift()
  const { setIsFullscreen, isFullscreen } = useShiftsPage()

  return (
    <Div
      css={{
        marginTop: '$4',
        marginBottom: '$4',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '$4',
      }}
    >
      <Div
        css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {isLoading && <Progress css={{ marginRight: '$2' }} />}
        {!cellUsage && (
          <CellFilter
            callback={function (cell: ICell[]): void {
              handleUpdateSmartShiftState({
                selectedCellsIds: cell.map((c) => c.id),
              })
            }}
            defaultValues
            policiesIds={permissionsArray.filter((p) => p.includes('shifts'))}
            selectedIds={selectedCellsIds}
          />
        )}
        <Button
          variant={'tertiary'}
          icon={
            isFullscreen ? <ArrowsPointingInIcon /> : <ArrowsPointingOutIcon />
          }
          onClick={() => setIsFullscreen(!isFullscreen)}
        >
          {isFullscreen ? 'Salir del Fullscreen' : 'Fullscreen'}
        </Button>
      </Div>
    </Div>
  )
}

export const HeaderMemo = memo(Header)
