import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useHolidaysColumns } from './table'
import { IHoliday } from '@/libs/react-query/types/company'
import { HolidayDrawer } from './components/HolidayDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import dayjs from 'dayjs'
import { useCan } from '@/hooks'

interface HolidayConfigDrawerState {
  isOpen: boolean
  selectedHolidayConfig: IHoliday | null
}

export const HolidayConfigs = () => {
  const canCreateHolidays = useCan(['configurations.holidays'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue, reset } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()
  const [drawerState, setDrawerState] =
    React.useState<HolidayConfigDrawerState>({
      isOpen: false,
      selectedHolidayConfig: null,
    })

  const columns = useHolidaysColumns()

  const handleCreateHolidayConfig = () => {
    reset()

    setValue('holiday', {
      active: true,
      date: [dayjs().toDate()],
      name: '',
      should_repeat_every_year: false,
      isWeekDay: false,
    })
    setDrawerState({
      isOpen: true,
      selectedHolidayConfig: null,
    })
  }

  const handleSelectPunchConfig = (row: IHoliday) => {
    reset()

    setValue('holiday', {
      id: row.id,
      name: row.name,
      date: [dayjs(row.date).toDate()],
      active: !!row.active,
      should_repeat_every_year: !!row.should_repeat_every_year,
      isWeekDay: row.day_of_week !== null,
      day_of_week: `${row.day_of_week}`,
    })
    setDrawerState({
      isOpen: true,
      selectedHolidayConfig: row,
    })
  }

  const table = useReactTable({
    data: company?.holidays ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput
          label="Nombre del Feriado"
          placeholder="Nombre del Feriado"
        />
        {canCreateHolidays && (
          <Button onClick={() => handleCreateHolidayConfig()}>
            Crear Feriado
          </Button>
        )}
      </Div>
      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<IHoliday>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectPunchConfig}
        />
      </Div>

      <HolidayDrawer
        isCreation={drawerState.selectedHolidayConfig === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedHolidayConfig: null,
          })
        }
      />
    </Div>
  )
}
