import { useFormContext, useWatch } from 'react-hook-form'
import { AddWorkerFields } from '.'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useMemo } from 'react'
import { useCan } from '@/hooks'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useVacationConfigForm = () => {
  const { data: company } = useMyCompany()
  const { control } = useFormContext<createCompleteWorkerData>()
  const canChangeConfig = useCan(['workers.changeConfig'])
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const vacationGroupIds = useWatch({
    control,
    name: 'vacation_config_id',
  })

  const selectedConfig = useMemo(() => {
    if (!vacationGroupIds) return null

    return company?.vacationConfigGroups?.find((p) => p.id === vacationGroupIds)
  }, [vacationGroupIds, company])

  const paymentForm: AddWorkerFields = [
    {
      _type: 'dropdown',
      placeholder: 'Configuración de Vacaciones',
      label: 'Configuración de Vacaciones',
      name: 'vacation_config_id',
      disabled: !canChangeConfig || !isWorkerCreationAvailable,
      options:
        company?.vacationConfigGroups?.map((punchConfig) => ({
          label: punchConfig.name,
          value: punchConfig.id,
        })) || [],
      defaultOption: {
        label: selectedConfig ? selectedConfig.name : 'Seleccione una opcion',
        value: selectedConfig ? selectedConfig.id : '',
      },
    },
  ]

  return {
    data: paymentForm,
  }
}
