import { IRouteTab, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import {
  LockClosedIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { GroupBuilder, PermissionsList } from './components'
import { useTabStore } from '@/store'
import { useEffect, useRef } from 'react'
import { useHandlePermissionsActions } from './useHandlePermissionActions'
import CreatePermissionV2 from './createV2/index.page'

export default function Permisos({ onlyTabs = false }: { onlyTabs?: boolean }) {
  const possibleLeak = useRef<any>(null)
  // const router = useRouter()
  const { tabsInfos, changeTab, removeTab } = useTabStore((state) => ({
    tabsInfos: state.permissions,
    removeTab: state.removeTab,
    changeTab: state.setActiveTab,
  }))

  const pageActions = [...tabsInfos.actions]

  useHandlePermissionsActions()

  const changeableTabs: IRouteTab[] = []
  tabsInfos.tabs.forEach((tab) => {
    if (tab.type === 'new') {
      changeableTabs.push({
        component: <CreatePermissionV2 />,
        icon: <PlusIcon />,
        label: 'Crear Politica',
        value: 'new',
        closeable: true,
        handleClose: () => {
          removeTab('permissions', 'new')
          possibleLeak.current = setTimeout(() => {
            changeTab('permissions', 'policies')
          }, 20)
        },
      })
    }

    if (tab.type === 'policy') {
      changeableTabs.push({
        component: <CreatePermissionV2 id={tab.id} />,
        icon: <LockClosedIcon />,
        label: tab.label || 'Politica',
        value: tab.id,
        closeable: true,
        handleClose: () => {
          removeTab('permissions', tab.id)
          possibleLeak.current = setTimeout(() => {
            changeTab('permissions', 'policies')
          }, 20)
        },
      })
    }
  })

  useEffect(() => {
    return () => {
      clearTimeout(possibleLeak.current)
    }
  }, [])

  return (
    <>
      <Router
        onlyTabs={onlyTabs}
        defaultTab="policies"
        setTab={(tab) => {
          changeTab('permissions', tab)
        }}
        tab={tabsInfos.activeTab || 'policies'}
        actions={pageActions}
        tabs={[
          {
            icon: <ShieldCheckIcon />,
            label: 'Politicas',
            value: 'policies',
            component: (
              <>
                <PermissionsList />
              </>
            ),
          },
          {
            icon: <UserGroupIcon />,
            label: 'Grupos',
            value: 'groups',
            component: (
              <>
                <GroupBuilder />
              </>
            ),
          },
          ...changeableTabs,
        ]}
      />
    </>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
