import { Div } from '@/components/Div'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import { IAuditStateSchema } from '../../audit-state'
import { useFormContext, useWatch } from 'react-hook-form'

export const AuditPagination = ({
  total,
  totalPages,
}: {
  total: number
  totalPages: number
}) => {
  const methods = useFormContext<IAuditStateSchema>()

  const [page] = useWatch({
    control: methods.control,
    name: ['auditList.page'],
  })

  const setPageIndex = (index: number) => {
    methods.setValue('auditList.page', index)
  }

  const previousPage = () => {
    methods.setValue('auditList.page', page - 1)
  }

  const getCanNextPage = () => {
    return page < totalPages - 1
  }

  const nextPage = () => {
    methods.setValue('auditList.page', page + 1)
  }

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$4',
        padding: '$6',
      }}
    >
      <Button onClick={() => setPageIndex(0)} disabled={page === 0}>
        <ChevronDoubleLeftIcon />
      </Button>
      <Button onClick={() => previousPage()} disabled={page === 0}>
        <ChevronLeftIcon />
      </Button>
      <Button onClick={() => nextPage()} disabled={!getCanNextPage()}>
        <ChevronRightIcon />
      </Button>
      <Button
        onClick={() => setPageIndex(totalPages - 1)}
        disabled={!getCanNextPage()}
      >
        <ChevronDoubleRightIcon />
      </Button>
      <span>
        <Text as="strong">
          {page + 1} de {totalPages}
        </Text>
      </span>
      {total && (
        <span
          style={{
            marginLeft: 'auto',
          }}
        >
          <Text as="strong"> Total: {total} </Text>
        </span>
      )}
    </Div>
  )
}
