import { queryClient } from '@/libs/react-query'
import { ICreateReceiptDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleCreateReceipt = async (data: ICreateReceiptDTO) => {
  await apiV2.post<any>(`/report-builder/create-receipt`, data)
  queryClient.invalidateQueries('receipts')
}

export const useHandleCreateReceipt = () => {
  const mutation = useCustomMutation(
    ['useHandleCreateReceipt'],
    handleCreateReceipt,
  )

  return mutation
}
