import { Div } from '@/components'
import { Tag } from '@punto-ui/react'
import Image from 'next/image'

interface PhotoComponentProps {
  url: string
}

export const PhotoComponent = ({ url }: PhotoComponentProps) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 400,
        minHeight: 400,
      }}
    >
      {!url?.length ? (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Tag variant={'negative'}>Sin foto</Tag>
        </Div>
      ) : (
        <Image
          alt="Foto de la incidencia"
          src={url}
          height={400}
          width={400}
          style={{
            objectFit: 'contain',
            width: '100%',
          }}
        />
      )}
    </Div>
  )
}
