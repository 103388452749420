import { Avatar, Div } from '@/components'
import GoogleMapReact from 'google-map-react'

interface PunchLocation {
  latitude: number | undefined
  longitude: number | undefined
  pictureUrl: string
}

const Marker = ({ lat, lng, url }: any) => (
  <Div
    css={{
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: '$brand_primary_pure',
      position: 'relative',
    }}
  >
    {!!url && (
      <Div
        css={{
          position: 'absolute',
          bottom: '100%',
          left: '-50%',
          height: 40,
          width: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '$brand_primary_pure',
          borderRadius: '100%',
        }}
      >
        <Avatar src={url} alt="" height={30} width={30} />
      </Div>
    )}
  </Div>
)

export const MapComponent = ({
  latitude,
  longitude,
  pictureUrl,
}: PunchLocation) => {
  const defaultProps = {
    center: {
      lat: latitude || 0,
      lng: longitude || 0,
    },
    zoom: 15,
  }

  return (
    <Div css={{ height: 400 }}>
      <GoogleMapReact
        // yesIWantToUseGoogleMapApiInternals
        // bootstrapURLKeys={{ key: '' }}
        bootstrapURLKeys={{
          key: 'AIzaSyAcsDF9M9gUPgVFb1MtnuXPaMA18eX2hsQ',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {latitude && longitude && (
          <Marker lat={latitude} lng={longitude} url={pictureUrl} />
        )}
      </GoogleMapReact>
    </Div>
  )
}
