import { ControlledFilterBar, Div } from '@/components'
import { StarIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { Text } from '@punto-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { ReceiptHubSchema, ReceiptHubSchemaType } from './type'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { TemplateCard } from '../components/TemplateCard'
import { useHorizontalScroll } from '@/hooks/useHorizontalScroll'
import { ReceiptList } from './components/ReceiptList'
import { useHandleReceiptHubActions } from './actions'
import { useTabStore } from '@/store'
import React from 'react'
import { useMe } from '@/libs/react-query/hooks'
import { useReportReceipts } from '@/libs/react-query/hooks/useReportReceipts'
import { useHandleUpdateReceipt } from '@/libs/react-query/mutations/reports/useHandleUpdateReceipt'
import { ExportReceipt } from '@/components/Drawers/ExportReceipt'

export const ReceiptHub = () => {
  const [selectedReceiptId, setSelectedReceiptId] = React.useState('')
  const { data: me } = useMe()
  const scrollRef = useHorizontalScroll<HTMLDivElement>()
  const methods = useForm<ReceiptHubSchemaType>({
    resolver: zodResolver(ReceiptHubSchema),
  })

  const { addTab, changeTab } = useTabStore((state) => ({
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const { data: favoriteTemplates, isFetching: isLoadingReports } =
    useReportReceipts({
      favorite: true,
      page: 0,
      perPage: 20,
    })

  const { isLoading: isLoadingUpdateReceipt } = useHandleUpdateReceipt()

  useHandleReceiptHubActions()

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <ControlledFilterBar
          policiesIds={permissionsArray.filter((p) => p.includes('reports'))}
          isLoading={isLoadingUpdateReceipt || isLoadingReports}
          nameFilterName="configuration.name"
        />
        <Div
          css={{
            padding: '$4',
          }}
        >
          <Div>
            <TemplateHeaderText />
            <Div
              ref={scrollRef}
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$4',
                overflow: 'scroll',
                marginTop: '$4',

                '&::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {favoriteTemplates?.data?.map((t) => (
                <TemplateCard
                  isPublic={t.identifier_id === null && !me?.isAdmin}
                  key={t.id}
                  title={t.name}
                  description={t.description}
                  favorite={t.favorite}
                  type={'.xls'}
                  handleEdit={() => {
                    addTab('reportsV2', [
                      {
                        id: t.id,
                        type: 'receipt',
                        label: `Editar ${t.name}`,
                      },
                    ])
                    changeTab('reportsV2', t.id)
                  }}
                  handleDownload={() => setSelectedReceiptId(t.id)}
                />
              ))}
            </Div>
          </Div>
          <Div
            css={{
              marginTop: '$4',
            }}
          >
            <ReceiptList />
          </Div>
        </Div>
      </Div>
      <ExportReceipt
        receiptId={selectedReceiptId}
        isOpen={!!selectedReceiptId}
        setIsOpen={() => setSelectedReceiptId('')}
      />
    </FormProvider>
  )
}

const TemplateHeaderText = () => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',

        svg: {
          marginRight: '$2',

          fill: '$brand_primary_pure',
          color: '$brand_primary_pure',

          height: 24,
          width: 24,
        },
      }}
    >
      <StarIcon />
      <Text
        variant={'heading2'}
        css={{
          color: '$brand_primary_pure',
        }}
      >
        Recibos Favoritos
      </Text>
    </Div>
  )
}
