import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PayrollPlaygroundTableType } from '../../types'
import { useHandleUpdatePayrollPayment } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollPayment'
import {
  debitPaymentsHeader,
  debitPaymentsHeaderRaw,
  paymentsHeader,
  paymentsHeaderRaw,
} from '@/libs/react-query/types'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { capitalizeFirstLetter } from '@/utils/workers/name'
import { useHandleUpdatePayrollConcept } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollConcept'
import { insertAtArrayPosition } from '@/utils/array'
import { useCan } from '@/hooks'

export const usePayrollAccountPayments = () => {
  const { data: company } = useMyCompany()
  const { mutateAsync: handleUpdatePayrollPayment } =
    useHandleUpdatePayrollPayment()
  const { mutateAsync: handleUpdatePayrollConcept } =
    useHandleUpdatePayrollConcept()

  const { control, getValues } = useFormContext<PayrollPlaygroundTableType>()
  const status = useWatch({
    control,
    name: 'status',
  })

  const canEditPayroll = useCan(['payroll.edit', 'payroll.closePayroll'])

  const columns: SmartColumnType[] = useMemo(() => {
    const isClosed = status === 'closed' || !canEditPayroll

    const orderedConcepts = company?.concepts.sort() || []
    const orderedConceptsFormatted = orderedConcepts.map((o) =>
      capitalizeFirstLetter(o),
    )
    let cols: SmartColumnType[] = [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Salario',
        items: [
          {
            type: 'input-text',
            name: 'salaryAfterIps',
            inputType: 'money-guarani',
            header: 'Salario Neto (G$)',
            width: 160,
            disabled: true,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Pagos e Bonificaciones (pos IPS)',
        items: [
          {
            type: 'input-text',
            name: 'creditPaymentsAfterIPS',
            inputType: 'money-guarani',
            array: true,
            length: paymentsHeader.length,
            headers: paymentsHeader,
            width: 160,
            disabled: isClosed,
            enableEmptySubmit: true,
            handleSubmitValue: async (value) => {
              const data = getValues()

              if (!data.data[value.index].userId || !data.id) {
                return
              }

              await handleUpdatePayrollPayment({
                paymentType: paymentsHeaderRaw[value.subindex || 0],
                payroll_id: data.id,
                user_id: data.data[value.index].userId,
                value:
                  !value.data.value && value.data.value !== 0
                    ? null
                    : Number(value.data.value),
                should_include_in_ips: false,
              })
            },
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Descuentos (Pos IPS)',
        items: [
          {
            type: 'input-text',
            name: 'discountsAfterIPS',
            inputType: 'money-guarani',
            array: true,
            length: debitPaymentsHeader.length,
            headers: debitPaymentsHeader,
            width: 160,
            enableEmptySubmit: true,
            disabled: isClosed,
            handleSubmitValue: async (value) => {
              const data = getValues()

              if (!data.data[value.index].userId || !data.id) {
                return
              }

              await handleUpdatePayrollPayment({
                paymentType: debitPaymentsHeaderRaw[value.subindex || 0],
                payroll_id: data.id,
                user_id: data.data[value.index].userId,
                value:
                  !value.data.value && value.data.value !== 0
                    ? null
                    : Number(value.data.value),
                should_include_in_ips: false,
              })
            },
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Total Salario',
        items: [
          {
            type: 'input-text',
            name: 'totalConceptsAfterIPS',
            inputType: 'money-guarani',
            header: 'T. Conceptos (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'totalPaymentsAfterIPS',
            inputType: 'money-guarani',
            header: 'T. Pagos (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'totalDiscountsAfterIPS',
            inputType: 'money-guarani',
            header: 'T. Descuentos (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'finalSalaryValue',
            inputType: 'money-guarani',
            header: 'Salario a pagar (G$)',
            width: 185,
            disabled: true,
          },
        ],
      },
    ] as SmartColumnType[]

    if (company?.concepts.length) {
      cols = insertAtArrayPosition(cols, 2, {
        type: 'subheader',
        header: 'Conceptos de Salario (pos IPS)',
        items: [
          {
            type: 'input-text',
            name: 'conceptsAfterIPS',
            inputType: 'money-guarani',
            array: true,
            enableEmptySubmit: true,
            length: company?.concepts.length,
            headers: orderedConceptsFormatted,
            disabled: isClosed,
            width: company?.concepts.length === 1 ? 220 : 140,
            handleSubmitValue: async (value) => {
              const data = getValues()

              if (!data.data[value.index].userId || !data.id) {
                return
              }

              await handleUpdatePayrollConcept({
                name: orderedConcepts[value.subindex || 0],
                payroll_id: data.id,
                user_id: data.data[value.index].userId,
                ips: false,
                value:
                  !value.data.value && value.data.value !== 0
                    ? null
                    : Number(value.data.value),
              })
            },
          },
        ],
      })
    }

    return cols
  }, [company, status])

  return columns
}
