import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IListTemplatesParams, IPdfTemplateResponse } from '../../types/reports'

const fetchPdfTemplates = async (props: IListTemplatesParams) => {
  try {
    const response = await apiV2.post<IPdfTemplateResponse>(
      '/report-builder/list-pdf-templates',
      {
        page: props.page,
        perPage: props.perPage,
        name: props.name,
        favorite: props.favorite,
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const usePdfTemplates = (props: IListTemplatesParams) => {
  const query = useQuery(
    ['pdfs', props],
    async () => {
      const workers = await fetchPdfTemplates(props)

      return workers
    },
    {
      keepPreviousData: true,
    },
  )

  return query
}
