import { Text } from '@punto-ui/react'
import React, { useState } from 'react'
import {
  FromDrawerContainer,
  HeaderContainer,
} from '@/components/Drawers/Form/styles'
import { MonthlyPunchReportForm } from './MonthlyPunchReportForm'
import { DataparReportForm } from './DataparIntegrationForm'
import { Div, Drawer, DrawerContainer } from '@/components'
import { DropdownV2 } from '@/components/DropdownV2'

interface ExportDrawerProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const ExportDrawer = ({ isOpen, setIsOpen }: ExportDrawerProps) => {
  const [formType, setFormType] = useState('monthly')
  const handleCloseDrawer = () => {
    setIsOpen(false)
  }

  return (
    <>
      <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
        <Drawer
          onOpenChange={handleCloseDrawer}
          content={
            <FromDrawerContainer
              css={{
                minWidth: 'calc(60vw)',
                overflow: 'scroll',
                maxHeight: '100vh',
              }}
            >
              <HeaderContainer>
                <Text
                  as="h3"
                  variant={'subtitle1'}
                  css={{
                    color: '$brand_primary_pure',
                    fontWeight: 'bold',
                  }}
                >
                  Exportación de Reportes
                </Text>
              </HeaderContainer>
              <Text
                as="p"
                variant={'paragraph'}
                css={{ color: '$interface_dark_deep', marginBottom: '$2' }}
              >
                Selecione las opiciones para la exportación de reporte de puntos
                de los colaboradores.
              </Text>
              <Div css={{ zIndex: 10 }}>
                <DropdownV2
                  containerCss={{
                    zIndex: 4,
                  }}
                  value={formType}
                  options={[
                    {
                      label: 'Reporte Mensual',
                      value: 'monthly',
                    },
                    {
                      label: 'Reporte de Integración - Datapar',
                      value: 'datapar-users',
                    },
                  ]}
                  onChangeValue={(v) => {
                    setFormType(v.value)
                  }}
                  defaultOption={{
                    label: 'Reporte Mensual',
                    value: 'monthly',
                  }}
                />
              </Div>
              <Div>
                {formType === 'monthly' && (
                  <MonthlyPunchReportForm
                    handleCloseDrawer={handleCloseDrawer}
                  />
                )}
                {formType === 'datapar-users' && (
                  <DataparReportForm handleCloseDrawer={handleCloseDrawer} />
                )}
              </Div>
            </FromDrawerContainer>
          }
        />
      </DrawerContainer>
    </>
  )
}
