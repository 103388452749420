import { Div } from '@/components'
import {
  PdfBuilderContentDataSchemaType,
  PdfBuilderContentDataType,
  PdfBuilderPdfContentType,
  PdfBuilderSchemaType,
} from '../../types'
import { useFormContext, useWatch } from 'react-hook-form'
import { EmptyContent } from './EmptyContent'
import { LayoutContentMenu } from './LayoutContextMenu'
import React, { useEffect, useRef } from 'react'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { TextContent } from './TextContent'
import { ImageContent } from './ImageContent'
import { SignatureFieldContent } from './SignatureFieldContent'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { DistanceContent } from './DistanceContent'
import { DividerContent } from './DividerContent'
import { TableContent } from './TableContent'
import { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'

// const DEFAULT_SCALE = 0.5

export const PdfBuilderPreview = () => {
  const methods = useFormContext<PdfBuilderSchemaType>()
  const content = useWatch({
    control: methods.control,
    name: 'pdf.content',
    defaultValue: methods.getValues('pdf.content'),
  })

  const emptyDropArea = useRef<HTMLDivElement>(null)
  const [isOverAndEmpty, setIsOverAndEmpty] = React.useState(false)

  useEffect(() => {
    const monitorForNewLayout = emptyDropArea.current
      ? dropTargetForElements({
          element: emptyDropArea.current,
          canDrop: ({ source }) =>
            source.data.type === 'pdf-builder-layout-new-option',
          onDrop: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-new-option') {
              return
            }

            const columns = source.data.columns as number

            const content = methods.getValues('pdf.content')

            const emptyContent = {
              id: (Math.random() * 10000).toString(),
              numberOfColumns: columns,
              data: Array.from({ length: columns }).map(() => ({
                id: (Math.random() * 10000).toString(),
                type: 'empty' as const,
                width: Math.round(100 / columns),
              })),
            }

            const newContent = [...content, emptyContent]
            methods.setValue('pdf.content', newContent)

            setIsOverAndEmpty(false)
          },
          onDragEnter: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-new-option') {
              return
            }
            setIsOverAndEmpty(true)
          },
          onDragLeave: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-new-option') {
              return
            }
            setIsOverAndEmpty(false)
          },
        })
      : undefined

    const functions = [monitorForNewLayout]
    const functionsToClean = functions.filter((c) => !!c) as CleanupFn[]
    return combine(...functionsToClean)
  }, [content, methods, emptyDropArea, isOverAndEmpty])

  return (
    <Div
      css={{
        padding: '$6',
        background: '$interface_light_down',
        flex: 1,
        overflow: 'scroll',
        height: 'calc(100vh - 54px - 20px - 60px - 24px)',
      }}
    >
      <Div
        css={{
          // height: 631.4175 / DEFAULT_SCALE,
          // width: 446.46 / DEFAULT_SCALE,
          height: 2000,
          width: 800,
          background: '$interface_light_pure',
          padding: '$6',

          '> div': {
            marginBottom: '$2',
          },

          '> div:last-child': {
            marginBottom: 0,
          },
        }}
      >
        {content.map((row, index) => (
          <PdfBuilderLayout key={row.id} index={index} row={row} />
        ))}
        <Div
          ref={emptyDropArea}
          css={{
            height: 60,
            width: '100%',
            background: 'transparent',
          }}
        >
          {isOverAndEmpty && (
            <Div
              css={{
                height: 4,
                width: '100%',
                background: '$brand_primary_deep',
              }}
            />
          )}
        </Div>
      </Div>
    </Div>
  )
}

const PdfBuilderLayout = ({
  row: content,
  index,
}: {
  row: PdfBuilderPdfContentType
  index: number
}) => {
  // const methods = useFormContext<PdfBuilderSchemaType>()
  const dataRespectingNumberOfColumns = content.data.slice(
    0,
    content.numberOfColumns,
  )

  const totalWidths = dataRespectingNumberOfColumns.reduce(
    (acc, data) => acc + (data.width || 0),
    0,
  )

  return (
    <LayoutContentMenu content={content} index={index}>
      <Div
        css={{
          display: 'flex',
        }}
      >
        {dataRespectingNumberOfColumns.map((data, dataIndex) => {
          const percentage = data.width
            ? (data.width / totalWidths) * 100
            : 100 / content.numberOfColumns

          return (
            <LayoutDataDropArea
              key={dataIndex}
              contentIndex={index}
              dataIndex={dataIndex}
              width={percentage}
              type={data.type}
              numberOfColumns={content.numberOfColumns}
              contentData={data}
            >
              <LayoutContentDataMenu
                contentData={data}
                contentIndex={index}
                dataIndex={dataIndex}
              />
            </LayoutDataDropArea>
          )
        })}
      </Div>
    </LayoutContentMenu>
  )
}

const LayoutContentDataMenu = ({
  contentData,
  contentIndex,
  dataIndex,
}: {
  contentData: PdfBuilderContentDataSchemaType
  contentIndex: number
  dataIndex: number
}) => {
  if (contentData.type === 'empty') {
    return (
      <>
        <EmptyContent />
      </>
    )
  }

  if (contentData.type === 'text') {
    return (
      <TextContent
        contentData={contentData}
        contentIndex={contentIndex}
        dataIndex={dataIndex}
      />
    )
  }

  if (contentData.type === 'image') {
    return (
      <ImageContent
        contentData={contentData}
        contentIndex={contentIndex}
        dataIndex={dataIndex}
      />
    )
  }

  if (contentData.type === 'signature') {
    return (
      <SignatureFieldContent
        contentData={contentData}
        contentIndex={contentIndex}
        dataIndex={dataIndex}
      />
    )
  }

  if (contentData.type === 'distance') {
    return (
      <DistanceContent
        contentData={contentData}
        contentIndex={contentIndex}
        dataIndex={dataIndex}
      />
    )
  }

  if (contentData.type === 'divider') {
    return (
      <DividerContent
        contentData={contentData}
        contentIndex={contentIndex}
        dataIndex={dataIndex}
      />
    )
  }

  if (contentData.type.includes('table:')) {
    return (
      <TableContent
        contentData={contentData}
        contentIndex={contentIndex}
        dataIndex={dataIndex}
      />
    )
  }

  return null
}

const LayoutDataDropArea = ({
  children,
  contentIndex,
  dataIndex,
  type,
  width,
  numberOfColumns,
  contentData,
}: {
  width: number
  children: React.ReactNode
  contentIndex: number
  dataIndex: number
  type: PdfBuilderContentDataType
  numberOfColumns?: number
  contentData: PdfBuilderContentDataSchemaType
}) => {
  const dropAreaRef = useRef<HTMLDivElement>(null)
  const dataDropAreaRef = useRef<HTMLDivElement>(null)

  const methods = useFormContext<PdfBuilderSchemaType>()

  const [isOver, setIsOver] = React.useState(false)

  useEffect(() => {
    if (!dropAreaRef.current || !dataDropAreaRef.current) return

    return combine(
      dropTargetForElements({
        element: dropAreaRef.current,
        canDrop: ({ source }) => {
          const isBuilderOption = source.data.type === 'pdf-builder-option'

          if (!isBuilderOption) {
            return false
          }

          const isTable = (source.data.optionType as any)?.includes('table:')

          if (isTable) {
            return numberOfColumns === 1
          }

          return true
        },
        onDrop: ({ source }) => {
          methods.setValue(
            `pdf.content.${contentIndex}.data.${dataIndex}.type`,
            source.data.optionType as any,
          )

          setIsOver(false)
        },
        onDragEnter: ({ source }) => {
          if (source.data.type !== 'pdf-builder-option') {
            return
          }

          setIsOver(true)
        },
        onDragLeave: ({ source }) => {
          if (source.data.type !== 'pdf-builder-option') {
            return
          }

          setIsOver(false)
        },
      }),
      dropTargetForElements({
        element: dataDropAreaRef.current,
        canDrop: ({ source }) =>
          source.data.type === 'pdf-builder-data' &&
          (type === 'text' || type === 'signature'),
        onDrop: ({ source }) => {
          const pdfText = methods.getValues(
            `pdf.content.${contentIndex}.data.${dataIndex}.text`,
          )

          const pdfTextWithVariable = pdfText
            ? `${pdfText} {${source.data.optionType}}`
            : `{${source.data.optionType}}`

          methods.setValue(
            `pdf.content.${contentIndex}.data.${dataIndex}.text`,
            pdfTextWithVariable as any,
          )

          setIsOver(false)
        },
        onDragEnter: ({ source }) => {
          if (source.data.type !== 'pdf-builder-data') {
            return
          }

          setIsOver(true)
        },
        onDragLeave: ({ source }) => {
          if (source.data.type !== 'pdf-builder-data') {
            return
          }

          setIsOver(false)
        },
      }),
    )
  }, [type, contentIndex, dataIndex, numberOfColumns])

  return (
    <Div
      ref={dataDropAreaRef}
      css={{
        width: `${width}%`,
        // flex: 1,
        border: isOver
          ? '1px dashed $brand_primary_deep'
          : contentData.bordered
          ? '1px solid $interface_dark_up'
          : '1px solid transparent',

        position: 'relative',
      }}
    >
      <Div
        css={{
          height: '100%',
          border: isOver
            ? '1px dashed $brand_primary_pure'
            : '1px solid transparent',
        }}
        ref={dropAreaRef}
      >
        {children}
      </Div>
    </Div>
  )
}
