import { paymentOptions } from '@/libs/react-query/types'
import { IReportBuilderColumnType } from '../../type'
import {
  generateRandomDate,
  generateRandomNumberUnder100,
  generateRandomNumberUnderBigMoney,
} from '@/utils/random'

export const multiplePaymentsColumns: IReportBuilderColumnType[] = [
  ...paymentOptions.map((m) => ({
    type: 'payments' as const,
    subgroup: 'Cantidad de pagos',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    value: `${m.value}_count`,
    label: m.label,
  })),
  ...paymentOptions.map((m) => ({
    type: 'payments' as const,
    subgroup: 'Total valor de pagos',
    valueType: 'number' as const,
    value: `${m.value}_value`,
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    label: m.label,
  })),
  {
    type: 'payments' as const,
    subgroup: 'Totales',
    valueType: 'number' as const,
    value: 'total_payments_count',
    getRandomPlaceholder: generateRandomNumberUnder100,
    label: 'Cantidad de pagos',
  },
  {
    type: 'payments' as const,
    subgroup: 'Totales',
    valueType: 'number' as const,
    value: 'total_payments_value',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    label: 'Total valor de pagos',
  },
  {
    type: 'payments',
    value: 'date',
    valueType: 'string' as const,
    label: 'Fechas',
    getRandomPlaceholder: generateRandomDate,
    subgroup: 'Informaciones generales',
  },
]
