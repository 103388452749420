import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const ExportedTemplatesSchema = z.object({
  configurations: z.object({
    name: z.string(),
    cellsIds: z.array(z.string()),
  }),
})

export type ExportedTemplatesSchemaType = z.infer<
  typeof ExportedTemplatesSchema
>
export type ExportedTemplatesFields = Array<
  ControlledFieldProps<ExportedTemplatesSchemaType>
>
