import { Div, FormRenderer, Touchable } from '@/components'
import { Button, Tag, Text } from '@punto-ui/react'
import { usePayrollDay } from '../usePayrollDay'
import dayjs from 'dayjs'
import { IMovementV2, movementOptions } from '@/libs/react-query/types'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useDayDetail } from '../store'
import { useMovementForm } from './useMovementForm'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AddMovementSimpleData,
  createMovementSchema,
} from '@/pages/movimientos/form'
import { zodResolver } from '@hookform/resolvers/zod'
import useToast from '@/hooks/useToast'
import {
  useHandleCreateMovements,
  useHandleUpdateMovements,
} from '@/libs/react-query/mutations/movements'
import { useCan } from '@/hooks'

export const MovementsTab = () => {
  const { data: payrollDay } = usePayrollDay()

  return (
    <Div
      css={{
        paddingTop: '$4',
        paddingBottom: '$4',
      }}
    >
      <Text
        variant={'heading4'}
        css={{
          color: '$interface_dark_down',
          paddingLeft: '$4',
        }}
      >
        Movimientos de {payrollDay?.schedule.worker.name} -{' '}
        {dayjs(payrollDay?.hours.date).format('DD/MM/YYYY')}
      </Text>
      {!!payrollDay?.movements.length && (
        <Div css={{ minWidth: 660 }}>
          {payrollDay?.movements.map((mov) => (
            <MovementsDayDetail movement={mov} key={mov.id} />
          ))}
        </Div>
      )}
      {!payrollDay?.movements.length && (
        <Div css={{ minWidth: 660, padding: '$4' }}>
          <Text variant="subtitle2" css={{ color: '$interface_dark_up' }}>
            Sin movimientos
          </Text>
        </Div>
      )}
    </Div>
  )
}

export const MovementsDayDetail = ({ movement }: { movement: IMovementV2 }) => {
  const { data: payrollDay, isFetching: isLoadingPayrollDay } = usePayrollDay()
  const { updateView } = useDayDetail((state) => ({
    updateView: state.updateView,
  }))

  return (
    <Touchable
      css={{
        cursor: isLoadingPayrollDay ? 'progress' : 'pointer',
      }}
      onClick={() => {
        if (isLoadingPayrollDay) {
          return
        }

        const firstParamPeriod = movement.periods?.[0]
        const newPeriods = firstParamPeriod?.period_start
          ? [
              dayjs(firstParamPeriod?.period_start).toDate(),
              dayjs(firstParamPeriod?.period_end).toDate(),
            ]
          : movement.periods.map((period) =>
              dayjs(period.date).utc(true).add(8, 'hours').toDate(),
            )

        updateView('edit-movement', {
          movement: {
            id: movement.id,
            title: movement.title,
            description: movement.description,
            periods: newPeriods,
            users: [payrollDay?.schedule.worker.id],
            defaultMultiple: newPeriods,
            remuneration: movement.should_pay_period,
            ips: movement.should_include_in_ips,
            should_include_hours: !!movement.should_include_hours,
            type: movement.type,
            category: movement.category,
            withHours: movement.type === 'SINGLE_DAY',
            period_type: movement.periods?.[0]?.period_start
              ? 'range'
              : 'multiple',
          },
        })
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '$4',
          paddingRight: '$4',
          paddingBottom: '$4',
          paddingTop: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text variant={'paragraph'} css={{ color: '$interface_dark_deep' }}>
              {
                movementOptions.find((m) => m.value === movement.category)
                  ?.label
              }
            </Text>
            <Text
              variant={'description'}
              css={{ color: '$interface_dark_down' }}
            >
              {movement.description}
            </Text>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            {movement.should_pay_period && <Tag>Remunerado</Tag>}
            {!movement.should_pay_period && (
              <Tag variant={'negative'}>No Remunerado</Tag>
            )}
            {movement.should_include_in_ips && <Tag>IPS</Tag>}
            <Tag>Autorizado</Tag>
          </Div>
        </Div>
        <Div
          css={{
            svg: {
              width: 20,
              height: 20,
              marginLeft: '$2',
            },
          }}
        >
          <PencilSquareIcon />
        </Div>
      </Div>
    </Touchable>
  )
}

export const AddMovement = () => {
  const shouldRequest = useCan(['moves.request'], true)

  const { isLoading: isLoadingCreateMovement, mutateAsync: handleUpdate } =
    useHandleUpdateMovements()
  const {
    isLoading: isLoadingUpdateMovement,
    mutateAsync: handleCreateMovement,
  } = useHandleCreateMovements()
  const { addToast } = useToast()
  const { data: payrollDay } = usePayrollDay()
  const { params, updateView } = useDayDetail((state) => ({
    params: state.params,
    updateView: state.updateView,
  }))

  const methods = useForm<AddMovementSimpleData>({
    resolver: zodResolver(createMovementSchema),
    defaultValues: {
      title: params?.movement?.title || '',
      description: params?.movement?.description || '',
      category: params?.movement?.category || 'permission',
      period_type: params?.movement?.period_type || 'multiple',
      periods: !params?.movement?.period_type
        ? [dayjs(params.date).toDate()]
        : params?.movement?.periods,
      defaultMultiple: params?.movement?.defaultMultiple || [
        dayjs(params.date).toDate(),
      ],
      users: params?.movement?.users || params.usersIds,
      remuneration: !!params?.movement?.remuneration,
      ips: !!params?.movement?.ips,
      type: params?.movement?.type || 'MULTIPLE_DAYS',
      withHours: params?.movement?.withHours || false,
    },
  })

  const { data: fields } = useMovementForm(methods as any)

  const handleSubmitCreateForm = async (data: AddMovementSimpleData) => {
    try {
      const values = methods.getValues()
      methods.setValue(
        'periods',
        values.periods.filter((p) => p !== null && dayjs(p).isValid()),
      )

      const validatedValues = methods.getValues()

      const isValid = await methods.trigger()

      if (!isValid) {
        return
      }
      if (params?.movement?.id) {
        await handleUpdate({
          ...validatedValues,
          movement_id: params.movement.id,
        })

        addToast({
          title: shouldRequest
            ? 'Movimiento solicitado'
            : 'Movimiento actualizado',
          description: shouldRequest
            ? 'Soliciatión de actualizacion de movimiento creada correctamente'
            : 'El movimiento se actualizo correctamente',
          type: 'positive',
          id: 1,
        })
      } else {
        await handleCreateMovement(validatedValues)
        addToast({
          title: shouldRequest
            ? 'Movimiento solicitado'
            : 'Movimiento actualizado',
          description: shouldRequest
            ? 'El movimiento fue solicitado'
            : 'El movimiento se creo correctamente',
          type: 'positive',
          id: 1,
        })
      }
      updateView('tabs', {})
    } catch (error) {
      addToast({
        title: 'Error',
        description: 'Ocurrio un error al crear el movimiento',
        type: 'negative',
        id: 1,
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          paddingTop: '$2',
          paddingBottom: '$2',

          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            paddingLeft: '$4',
            paddingRight: '$4',
            marginBottom: '$4',
            paddingBottom: '$4',
            borderBottom: '1px solid',
            borderBottomColor: '$interface_light_down',
          }}
        >
          Crear movimiento para {payrollDay?.schedule.worker.name}
        </Text>
        <Div
          css={{
            paddingLeft: '$4',
            paddingRight: '$4',
          }}
        >
          <FormRenderer<AddMovementSimpleData> items={fields} cols={1} />
        </Div>
        <Div
          css={{
            borderTop: '1px solid',
            borderTopColor: '$interface_light_down',
            paddingTop: '$4',
            paddingBottom: '$2',
            paddingLeft: '$4',
            paddingRight: '$4',
            marginTop: '$8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => {
              updateView('tabs', {})
            }}
            variant={'secondary'}
          >
            Volver
          </Button>
          <Button
            isLoading={isLoadingCreateMovement || isLoadingUpdateMovement}
            onClick={() => handleSubmitCreateForm(methods.getValues())}
          >
            Guardar
          </Button>
        </Div>
      </Div>
    </FormProvider>
  )
}
