import { Div } from '@/components'
import { Text, styled } from '@punto-ui/react'
import { InactivateWorkerSchema } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { useMemo } from 'react'

export const InactivateWorkerSubtotal = () => {
  const methods = useFormContext<InactivateWorkerSchema>()

  const [
    aguinaldoValue,
    takenVacationsValue,
    proportionalVacationsValue,
    discountProportionalVacationsValue,
    preWarningValue,
    discountPreWarningValue,
    indemnificationValue,
    totalPositiveSalary,
    totalNegativeSalary,
    paymentConcepts,
    discountConcepts,
    ipsValue,
    familyBonusValue,
    accumulatedVacationsValue,
  ] = useWatch({
    control: methods.control,
    name: [
      'data.aguinaldo',
      'data.total_vacations.value',
      'data.proportional_vacations.value',
      'data.discount_proportional_vacations.value',
      'data.pre_warning.value',
      'data.discount_pre_warning.value',
      'data.indemnification.value',
      'data.total_positive_salary',
      'data.total_negative_salary',
      'data.paymentConcepts',
      'data.discountConcepts',
      'ipsValues.ips9Value',
      'data.familiarBonus.value',
      'data.total_accumulated_vacations.value',
    ],
  })

  const totalPaymentConcepts = useMemo(() => {
    return paymentConcepts.reduce((acc, curr) => {
      return acc + +curr.value
    }, 0)
  }, [paymentConcepts])

  const totalDiscountConcepts = useMemo(() => {
    return discountConcepts.reduce((acc, curr) => {
      return acc + +curr.value
    }, 0)
  }, [discountConcepts])

  const totalFinalValue = useMemo(() => {
    const subtotal =
      +aguinaldoValue +
      +takenVacationsValue +
      +proportionalVacationsValue -
      +discountProportionalVacationsValue +
      +preWarningValue -
      +discountPreWarningValue +
      +indemnificationValue +
      +totalPositiveSalary -
      +totalNegativeSalary +
      +totalPaymentConcepts -
      +totalDiscountConcepts +
      +familyBonusValue +
      +accumulatedVacationsValue

    const total = subtotal - +ipsValue

    return {
      total,
      subtotal,
    }
  }, [
    aguinaldoValue,
    takenVacationsValue,
    proportionalVacationsValue,
    preWarningValue,
    discountPreWarningValue,
    indemnificationValue,
    totalPositiveSalary,
    totalNegativeSalary,
    totalPaymentConcepts,
    totalDiscountConcepts,
    discountProportionalVacationsValue,
    ipsValue,
    familyBonusValue,
    accumulatedVacationsValue,
  ])

  // totalNegativeSalary,
  // paymentConcepts,
  // discountConcepts,

  const values = {
    'Salario Proporcional': totalPositiveSalary,
    'Aguinaldo Proporcional': aguinaldoValue,
    'Vacaciones Causadas': takenVacationsValue,
    'Vacaciones Acumuladas': accumulatedVacationsValue,
    'Vacaciones Proporcionales': proportionalVacationsValue,
    'Pré aviso': preWarningValue,
    'Descuento Pré aviso': -1 * +discountPreWarningValue,
    Indemnización: indemnificationValue,
    'Bonificación Familiar': +familyBonusValue,
    'Conceptos de Credito': totalPaymentConcepts,
    'Descuento Salario Proporcional': -1 * +totalNegativeSalary,
    'Descuento Vacaciones Proporcionales':
      -1 * +discountProportionalVacationsValue,
    'Otros descuentos': -1 * +totalDiscountConcepts,
  }

  return (
    <Div
      css={{
        maxHeight: 'calc(100vh - 70px)',
        overflowY: 'scroll',
        borderRadius: '$md',
        // backgroundColor: '$interface_light_down',
        padding: '$4',
        alignSelf: 'flex-start',
      }}
    >
      {Object.entries(values)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => (
          <TotalItem key={key}>
            <Text
              variant={'description'}
              css={{ color: '$interface_dark_deep' }}
            >
              {key}
            </Text>
            <Text css={{ fontWeight: 'bold', color: '$interface_dark_deep' }}>
              {typeDecoder('money-guarani').input(String(value))}
            </Text>
          </TotalItem>
        ))}
      <TotalItem css={{ marginTop: '$4' }}>
        <Text variant={'paragraph'} css={{ fontWeight: 'bold' }}>
          Subtotal
        </Text>
        <Text css={{ fontWeight: 'bold' }}>
          {typeDecoder('money-guarani').input(
            String(totalFinalValue.subtotal < 0 ? 0 : totalFinalValue.subtotal),
          )}
        </Text>
      </TotalItem>
      <TotalItem css={{ marginTop: '$4' }}>
        <Text variant={'description'}>IPS (9%)</Text>
        <Text css={{ fontWeight: 'bold' }}>
          {typeDecoder('money-guarani').input(
            String(ipsValue < 0 ? 0 : -1 * ipsValue),
          )}
        </Text>
      </TotalItem>
      <TotalItem css={{ marginTop: '$4' }}>
        <Text variant={'subtitle2'}>Total</Text>
        <Text css={{ fontWeight: 'bold' }}>
          {typeDecoder('money-guarani').input(
            String(totalFinalValue.total < 0 ? 0 : totalFinalValue.total),
          )}
        </Text>
      </TotalItem>
    </Div>
  )
}

export const TotalItem = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '$2',
  marginBottom: '$2',
})
