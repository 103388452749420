import { RegisterFormDataFields } from './forms'

export const useRegisterForm = () => {
  const basicDataForm: RegisterFormDataFields = [
    {
      _type: 'input',
      label: 'Nombre de la Empresa',
      name: 'name',
    },
    {
      _type: 'input',
      label: 'Correo electrónico',
      name: 'email',
      inputType: 'email',
    },
    {
      _type: 'input',
      label: 'Teléfono',
      name: 'phone',
    },
    {
      _type: 'input',
      label: 'Nombre del Contacto',
      name: 'contactName',
    },
    {
      _type: 'input',
      label: 'RUC',
      name: 'ruc',
    },
  ]

  return {
    data: basicDataForm,
  }
}
