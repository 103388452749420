import { IReportBuilderRowTypes, IReportBuilderTypes } from '../type'

export const includeRowRelations: Record<
  IReportBuilderTypes,
  Partial<Record<IReportBuilderRowTypes, IReportBuilderRowTypes[]>>
> = {
  single: {
    punches: [],
    shifts: [],
    movements: ['payments', 'vacations'],
    payments: ['movements', 'vacations'],
    vacations: ['movements', 'payments'],
    period: [],
    payrolls: [],
    sectors: [],
    workers: [],
    day: [],
    month: [],
    year: [],
    entities: [],
  },
  collective: {
    workers: [],
    sectors: [],
    period: [],
    punches: [],
    movements: [],
    payments: [],
    payrolls: [],
    shifts: [],
    vacations: [],
    day: [],
    month: [],
    entities: [],
    year: [],
  },
  totals: {
    workers: [],
    punches: ['workers'],
    shifts: ['workers'],
    movements: ['workers'],
    payments: ['workers'],
    payrolls: [],
    period: [],
    sectors: [],
    vacations: [],
    day: [],
    month: [],
    entities: [],
    year: [],
  },
}
