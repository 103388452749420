import React from 'react'
import { WorkersContext } from '../index'

export const useWorkerPage = () => {
  const context = React.useContext(WorkersContext)

  if (!context) {
    throw new Error('useWorkerPage must be used within a WorkersContext')
  }

  return context
}
