import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IListReportsParams, IReportResponse } from '../../types/reports'

const fetchReports = async (params: IListReportsParams) => {
  const response = await apiV2.post<IReportResponse>(
    '/report-builder/list-reports',
    {
      page: params.page,
      perPage: params.perPage,
      name: params.name,
    },
    {
      params: {
        cellsIds: params.cellsIds?.join(','),
      },
    },
  )

  return response.data
}

export const useReports = ({
  page,
  perPage,
  cellsIds,
  name,
}: IListReportsParams) => {
  const query = useQuery(
    ['reports', page, perPage, cellsIds, name],
    () =>
      fetchReports({
        page,
        perPage,
        cellsIds,
        name,
      }),
    {
      enabled: !!cellsIds?.length,
      refetchInterval: (query) => {
        const hasPending = query?.data.find(
          (report) => report.status === 'pending',
        )

        if (hasPending) {
          return 2500
        }

        return false
      },
    },
  )

  return query
}
