import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  SmartTableAvatarItemValue,
  SmartTableFormItemValue,
} from '@/components/SmartTable/types'
import { z } from 'zod'

export const AguinaldoPlaygroundContent = z.object({
  userId: z.string(),

  checked: SmartTableFormItemValue,
  avatar: SmartTableAvatarItemValue,
  name: SmartTableFormItemValue,
  surname: SmartTableFormItemValue,
  ci: SmartTableFormItemValue,

  aguinaldoAntecipate: SmartTableFormItemValue,

  monthsPayrolls: SmartTableFormItemValue.array(),
  monthsValues: SmartTableFormItemValue.array(),

  subtotal: SmartTableFormItemValue,
  total: SmartTableFormItemValue,
})

export const AguinaldoPlaygroundTable = z.object({
  data: z.array(AguinaldoPlaygroundContent),

  name: z.string(),
  status: z.string(),
  nameFilter: z.string(),
  cellsIds: z.string().array(),
  description: z.string(),
  isFavorite: z.boolean(),
  year: z.string(),
  users_ids: z.array(z.string()),
  id: z.string(),

  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
  configuration: z.object({
    nightOrdinaryHours: z.boolean(),
    morningOrdinaryHours: z.boolean(),
    morningExtraHours: z.boolean(),
    nightExtraHours: z.boolean(),
    morningHolidayHours: z.boolean(),
    nightHolidayHours: z.boolean(),
    morningDiscountHours: z.boolean(),
    nightDiscountHours: z.boolean(),

    payments: z.boolean(),
    familyBonus: z.boolean(),
    mobility: z.boolean(),
    bonus: z.boolean(),
    commission: z.boolean(),
    gift: z.boolean(),
    otherPayments: z.boolean(),

    ips9: z.boolean(),
    ips16: z.boolean(),

    antecipate: z.boolean(),
    loan: z.boolean(),
    lost: z.boolean(),
    purchase: z.boolean(),
    judicial: z.boolean(),
    excess: z.boolean(),
    otherDiscount: z.boolean(),
  }),
})

export type AguinaldoPlaygroundTableType = z.infer<
  typeof AguinaldoPlaygroundTable
>
export type AguinaldoPlaygroundContentType = z.infer<
  typeof AguinaldoPlaygroundContent
>
export type AguinaldoPlaygroundFields = Array<
  ControlledFieldProps<AguinaldoPlaygroundTableType>
>
