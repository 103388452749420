import {
  IVacationStatusResult,
  vacationStatusToLabel,
  vacationStatusToTagVariant,
} from '@/libs/react-query/types/vacations'
import { VacationStatusContentType } from './types'
import dayjs from 'dayjs'

export const parseVacationsToVacationsStatusTable = (
  vacations: IVacationStatusResult[],
  allSelectedIds: string[],
  alreadyExpanded: Record<string, boolean> = {},
): VacationStatusContentType[] => {
  const result: VacationStatusContentType[] = []

  vacations.forEach((v) => {
    const yearsDetailed = v.yearsDetailed
      .map((y) => {
        return {
          id: `${y.year}${v.user_id}`,
          // Table props
          antiquity: {
            value: `${y.antiquity} año${y.antiquity !== 1 ? 's' : ''} ${
              y.antiquityMonths
                ? `${y.antiquityMonths} mes${
                    y.antiquityMonths !== 1 ? 'es' : ''
                  }`
                : ''
            }`,
            status: '',
          },
          daysCaused: {
            value: y.days_caused,
            status: '',
          },
          daysOwed: {
            value: y.days_owed,
            status: '',
          },
          daysRemaining: {
            value: y.days_remaining,
            status: '',
          },
          daysTaken: {
            value: y.days_taken,
            status: '',
          },
          name: {
            value: `${y.year - 1}-${y.year}`,
            status: '',
          },
          surname: {
            value: y.fromDate
              ? dayjs(y.fromDate).utc().format('DD/MM/YYYY')
              : '',
            status: '',
          },
          document: {
            value: y.toDate ? dayjs(y.toDate).utc().format('DD/MM/YYYY') : '',
            status: '',
          },
          proportionalDays: {
            value: y.days_proportional,
            status: '',
          },
          status: [
            {
              id: y.status,
              name: vacationStatusToLabel[y.status] || '',
              variant: vacationStatusToTagVariant[y.status] as string,
            },
          ],
        }
      })
      .sort((a, b) => {
        return parseInt(b.id) - parseInt(a.id)
      })

    result.push({
      original: v,
      id: v.user_id,
      status: [],

      expandable: {
        value: alreadyExpanded[v.user_id] || false,
        status: '',
      },

      antiquity: {
        value: `${v.antiquity} año${v.antiquity !== 1 ? 's' : ''} ${
          v.antiquityMonths
        } mes${v.antiquityMonths !== 1 ? 'es' : ''} ${v.antiquityDays} día${
          v.antiquityDays !== 1 ? 's' : ''
        }`,
        status: '',
      },
      hiringDate: {
        value: dayjs(v.hiring_date).utc().format('DD/MM/YYYY'),
        status: '',
      },
      avatar: {
        alt: v.user?.name || '',
        url: v.user?.photo_url || '',
      },
      checked: {
        value: allSelectedIds.includes(v.user_id),
        status: '',
      },
      daysCaused: {
        value: v.days_caused,
        status: '',
      },
      daysOwed: {
        value: v.days_owed,
        status: '',
      },
      daysRemaining: {
        value: v.days_remaining,
        status: '',
      },
      daysTaken: {
        value: v.days_taken,
        status: '',
      },
      name: {
        value: v.user?.firstName || '',
        status: '',
      },
      proportionalDays: {
        value: v.days_proportional,
        status: '',
      },
      surname: {
        value: v.user?.surname || '',
        status: '',
      },
      document: {
        value: v.user?.document || '',
        status: '',
      },

      yearsDetailed,
    })
  })

  return result
}
