import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { IUpdateVacationDTO } from '@/libs/react-query/types/vacations'

dayjs.extend(utc)

const handleUpdateVacation = async (data: IUpdateVacationDTO) => {
  await apiV2.post<void>('/vacation/update-vacation', data)
  queryClient.invalidateQueries()
}

export const useHandleUpdateVacation = () => {
  const mutation = useCustomMutation(
    ['handleUpdateVacation'],
    handleUpdateVacation,
  )

  return mutation
}
