import { Div } from '@/components'
import { FormGroup } from '@/components/Forms/FormGroup'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ConfigSchema, configSchema } from '@/pages/configuraciones/components'
import {
  useAllowedPunch,
  useCompanyAllowedPunch,
} from '@/pages/configuraciones/components/PunchesGroups/components/PunchesConfigDrawer/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface MarcacionConfigProps {
  id: string
}

export const MarcacionConfig = ({ id }: MarcacionConfigProps) => {
  const { data: company } = useMyCompany()

  const methods = useForm<ConfigSchema>({
    resolver: zodResolver(configSchema),
    defaultValues: {},
  })

  const { data: allowedPunch } = useAllowedPunch()
  const { data: companyAllowedPunch } = useCompanyAllowedPunch()

  useEffect(() => {
    const punchConfig = company?.punchConfigGroups.find(
      (group) => group.id === id,
    )

    if (!punchConfig) {
      return
    }

    methods.setValue('punches', {
      id: punchConfig.id,
      is_able_to_punch_face_id: punchConfig.is_able_to_punch_face_id,
      is_able_to_punch_from_anywhere:
        punchConfig.is_able_to_punch_from_anywhere,
      is_able_to_see_punch_history: punchConfig.is_able_to_see_punch_history,
      should_show_invalid_geo_punches:
        punchConfig.should_show_invalid_geo_punches,
      should_allow_facial_registration:
        punchConfig.should_allow_facial_registration,
      should_allow_unique_facial_registration:
        punchConfig.should_allow_unique_facial_registration,
      is_able_to_punch_geolocation: punchConfig.is_able_to_punch_geolocation,
      is_able_to_punch_geolocation_face_id:
        punchConfig.is_able_to_punch_geolocation_face_id,
      is_able_to_punch_offline: punchConfig.is_able_to_punch_offline,
      is_presence_default: punchConfig.is_presence_default,
      required_aws_precision: punchConfig.required_aws_precision,
      required_liveness_precision: punchConfig.required_liveness_precision,
      should_use_liveness: punchConfig.should_use_liveness,
      name: punchConfig.name,
      companyPlaceIds: punchConfig.companyConfigPlaces.map(
        (cp) => cp.company_place_id,
      ),
    })
  }, [id, company, methods])

  const disabledForms = useMemo(() => {
    return {
      allowedPunch: allowedPunch.map((c) => ({
        ...c,
        disabled: true,
      })),
      companyAllowedPunch: companyAllowedPunch.map((c) => ({
        ...c,
        disabled: true,
      })),
    }
  }, [allowedPunch, companyAllowedPunch])

  // const punchId = useWatch({
  //   control: methods.control,
  //   name: 'punches',
  // })

  const punch = methods.watch('punches')

  if (!punch) {
    return null
  }

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          marginTop: '$10',
          border: '2px solid',
          borderColor: '$interface_light_down',
          padding: '$6',
          borderRadius: '$md',
        }}
      >
        <FormGroup
          type="fixed"
          title={punch.name}
          items={disabledForms.companyAllowedPunch}
          cols={3}
        />
        <FormGroup
          type="fixed"
          title="Informaciones de Marcación"
          items={disabledForms.allowedPunch}
          cols={3}
        />
      </Div>
    </FormProvider>
  )
}
