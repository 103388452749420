import { queryClient } from '@/libs/react-query'
import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'

interface UploadCompanyProfilePictureFormData {
  photo: File
}

export const useHandleUpdateCompanyProfilePicture = () => {
  const mutation = useMutation(
    ['useHandleUpdateCompanyProfilePicture'],
    handleUpdateCompanyProfilePicture,
  )

  return mutation
}

const handleUpdateCompanyProfilePicture = async ({
  ...props
}: UploadCompanyProfilePictureFormData) => {
  const formData = new FormData()
  formData.append('file', props.photo)

  const response = await apiV2.post('/company/upload-profile-picture', formData)

  queryClient.invalidateQueries()
  return response.data
}
