import {
  debitPaymentsHeaderRaw,
  paymentOptions,
} from '@/libs/react-query/types'
import { IReceiptBuilderRowDefinitionType } from '../type'
import { generateRandomHour, generateRandomNumberUnder10 } from '@/utils/random'

export const liquidationReceiptRows: IReceiptBuilderRowDefinitionType[] = [
  {
    label: 'Valor dias trabajados',
    value: 'worked_days',
    subgroup: 'Salário Proporcional',
    type: 'liquidation',
    isDebit: false,
    getRandomPlaceholder: generateRandomNumberUnder10,
  },
  {
    label: 'Adicional Nocturno',
    value: 'night_value',
    subgroup: 'Salário Proporcional',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    type: 'liquidation',
  },
  {
    label: 'Horas extras diurnas',
    value: 'morning_extra_value',
    subgroup: 'Salário Proporcional',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    type: 'liquidation',
  },
  {
    label: 'Horas extras nocturnas',
    value: 'night_extra_value',
    subgroup: 'Salário Proporcional',
    getRandomPlaceholder: generateRandomHour,
    isDebit: false,
    type: 'liquidation',
  },
  {
    label: 'Horas feriado diurnas',
    value: 'morning_holiday_value',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Salário Proporcional',
    type: 'liquidation',
  },
  {
    label: 'Horas feriado nocturnas',
    value: 'night_holiday_value',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Salário Proporcional',
    type: 'liquidation',
  },
  {
    label: 'Desc. de horas diurnas',
    value: 'morning_discount_hours_value',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Salário Proporcional',
    isDebit: true,
    type: 'liquidation',
  },
  {
    label: 'Desc. de horas nocturnas',
    value: 'night_discount_hours_value',
    getRandomPlaceholder: generateRandomHour,
    isDebit: true,
    subgroup: 'Salário Proporcional',
    type: 'liquidation',
  },
  {
    label: 'Bonificación Familiar',
    value: 'family_bonus',
    subgroup: 'Salário Proporcional',
    getRandomPlaceholder: generateRandomNumberUnder10,
    isDebit: false,
    type: 'liquidation',
  },
  {
    label: '',
    value: 'salary_concepts',
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup: 'Conceptos de Salário',
    isDebit: false,
    type: 'liquidation',
  },
  {
    label: '',
    value: 'liquidation_concepts',
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup: 'Conceptos de Liquidación',
    isDebit: false,
    type: 'liquidation',
  },
  // {
  //   label: '',
  //   value: 'salary_concepts_ips',
  //   getRandomPlaceholder: generateRandomNumberUnder10,
  //   isDebit: false,
  //   subgroup: 'Conceptos de Salário (IPS)',
  //   type: 'liquidation',
  // },
  // ...paymentOptions.map((m) => ({
  //   label: m.label,
  //   value: `${m.value}_value_ips`,
  //   getRandomPlaceholder: generateRandomNumberUnder10,
  //   subgroup: 'Pagos (IPS)',
  //   isDebit: debitPaymentsHeaderRaw.includes(m.value as any),
  //   type: 'payroll' as const,
  // })),
  ...paymentOptions.map((m) => ({
    label: m.label,
    value: `${m.value}_value`,
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup: 'Pagos',
    isDebit: debitPaymentsHeaderRaw.includes(m.value as any),
    type: 'payroll' as const,
  })),
  {
    label: 'Vacaciones causadas',
    value: 'caused_vacation_value',
    isDebit: false,
    getRandomPlaceholder: () => '-',
    subgroup: 'Vacaciones',
    type: 'liquidation',
  },
  {
    label: 'Vacaciones acumuladas',
    value: 'accumulated_vacations',
    isDebit: false,
    getRandomPlaceholder: () => '-',
    subgroup: 'Vacaciones',
    type: 'liquidation',
  },
  {
    label: 'Vacaciones proporcionales',
    value: 'proportional_vacations',
    isDebit: false,
    getRandomPlaceholder: () => '-',
    subgroup: 'Vacaciones',
    type: 'liquidation',
  },
  {
    label: 'Anticipo de vacaciones',
    value: 'antecipated_vacations',
    isDebit: true,
    getRandomPlaceholder: () => '-',
    subgroup: 'Vacaciones',
    type: 'liquidation',
  },
  {
    label: 'Preaviso',
    value: 'prewarning',
    isDebit: false,
    getRandomPlaceholder: () => '-',
    subgroup: 'Preaviso',
    type: 'liquidation',
  },
  {
    label: 'Preaviso Descuento',
    value: 'discount_prewarning',
    isDebit: true,
    getRandomPlaceholder: () => '-',
    subgroup: 'Preaviso',
    type: 'liquidation',
  },
  {
    label: 'Indemnización',
    value: 'indemnification',
    isDebit: false,
    getRandomPlaceholder: () => '-',
    subgroup: 'Indemnización',
    type: 'liquidation',
  },
  {
    label: 'Aguinaldo Proporcional',
    value: 'aguinaldo',
    isDebit: false,
    getRandomPlaceholder: () => '-',
    subgroup: 'Aguinaldo',
    type: 'liquidation',
  },
  {
    label: 'IPS 9%',
    value: 'ips9',
    isDebit: true,
    getRandomPlaceholder: () => '-',
    subgroup: 'IPS',
    type: 'liquidation',
  },
  {
    label: 'IPS 16%',
    value: 'ips16',
    isDebit: true,
    getRandomPlaceholder: () => '-',
    subgroup: 'IPS',
    type: 'liquidation',
  },
]
