import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { usePayrollDay } from './usePayrollDay'
import { fromDateToFormattedHour } from '@/utils/date'

export const usePunchInDate = (props?: { totalWidth?: number }) => {
  const { data: payrollDay } = usePayrollDay()

  const punchInDateData = useMemo(() => {
    const intervals = payrollDay?.hours?.intervals?.flatMap((i) => {
      if (i.entrance?.date && i.exit?.date) {
        return [
          fromDateToFormattedHour(i.entrance?.date),
          fromDateToFormattedHour(i.exit?.date),
        ]
      } else if (i.entrance?.date) {
        return [fromDateToFormattedHour(i.entrance?.date), 'Sin salida']
      }

      return []
    })

    return [
      {
        entrance: payrollDay?.hours?.firstPunch?.date
          ? fromDateToFormattedHour(payrollDay?.hours?.firstPunch?.date)
          : '',
        exit: payrollDay?.hours?.lastPunch?.date
          ? fromDateToFormattedHour(payrollDay?.hours?.lastPunch?.date)
          : '',
        intervals,
      },
    ]
  }, [payrollDay])

  const greaterInterval = useMemo(() => {
    const intervals = payrollDay?.hours?.intervals?.flatMap((i) => {
      if (i.entrance?.date && i.exit?.date) {
        return [
          fromDateToFormattedHour(i.entrance?.date),
          fromDateToFormattedHour(i.exit?.date),
        ]
      } else if (i.entrance?.date) {
        return [fromDateToFormattedHour(i.entrance?.date), 'Sin salida']
      }

      return []
    })

    return intervals?.length || 0
  }, [payrollDay])

  const widthSize = useMemo(() => {
    const TOTAL_SIZE = props?.totalWidth || 610

    return TOTAL_SIZE / (greaterInterval + 2)
  }, [])

  const punchInDateColumns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Marcaciones del dia',
        name: 'teste',
        items: [
          {
            type: 'input-text',
            name: 'entrance',
            draggable: null,
            disabled: true,
            header: 'Entrada',
            width: widthSize,
          },
          {
            type: 'input-text',
            name: 'intervals',
            draggable: null,
            header: 'Intervalo',
            array: true,
            length: greaterInterval,
            disabled: true,
            inputType: 'no-mask',
            width: widthSize,
          },
          {
            type: 'input-text',
            name: 'exit',
            draggable: null,
            header: 'Salida',
            disabled: true,
            inputType: 'no-mask',
            width: widthSize,
          },
        ],
      },
    ]
  }, [widthSize, greaterInterval])

  return {
    columns: punchInDateColumns,
    data: punchInDateData,
  }
}
