import { useQuery } from 'react-query'
import { apiV2 } from '@/services/api'
import { FetchPunchListProps, IPunch } from '@/libs/react-query/types'
import dayjs from 'dayjs'

export const fetchPunchList = async (props: FetchPunchListProps) => {
  const response = await apiV2.post<IPunch[]>(
    '/punches/list-punches',
    {
      start_date: props.startDate,
      name: props.name,
      end_date: props.endDate,
    },
    {
      params: {
        cellsIds: props?.cellsIds?.join(','),
      },
    },
  )

  return response.data
}

export const usePunchesList = (props: FetchPunchListProps) => {
  const query = useQuery(
    [
      'punches-list',
      props.startDate,
      props.endDate,
      props.name,
      props.cellsIds,
    ],
    () => fetchPunchList(props),
    {
      keepPreviousData: true,
      enabled:
        !!props?.startDate &&
        !!props?.endDate &&
        !!dayjs(props?.startDate).isValid() &&
        !!dayjs(props?.endDate).isValid() &&
        !!props.cellsIds?.length,
    },
  )
  return query
}
