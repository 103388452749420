import { Div, Stepper } from '@/components'
import { StepProps } from '@/components/Stepper'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PdfBuilderSchemaType } from '../types'
import { Tag } from '@punto-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export const PdfStepper = () => {
  const { control, setValue } = useFormContext<PdfBuilderSchemaType>()

  const [formStep, configurations] = useWatch({
    control,
    name: ['currentStep', 'configurations'],
  })

  const stepsArray = useMemo(() => {
    const setFormStep = (index: number) => setValue('currentStep', index)

    const isConfigurationsValid = configurations?.name

    return [
      {
        setFormStep,
        text: '1 Datos Basicos',
        selectedIndex: formStep,
        index: 0,
        error: false,
      },
      {
        setFormStep,
        text: '2 Layout',
        selectedIndex: formStep,
        disabled: !isConfigurationsValid,
        index: 1,
        error: false,
      },
      {
        setFormStep,
        text: '2 Contenido',
        selectedIndex: formStep,
        disabled: !isConfigurationsValid,
        index: 2,
        error: false,
      },
      {
        setFormStep,
        text: '3 Datos',
        selectedIndex: formStep,
        disabled: !isConfigurationsValid,
        index: 3,
        error: false,
      },
    ] as StepProps[]
  }, [formStep, setValue, configurations])

  return (
    <Div
      css={{
        display: 'flex',
        paddingLeft: '$4',
        alignItems: 'center',
        height: 60,
        borderBottom: '1px solid $interface_light_down',
      }}
    >
      <Div
        css={{
          flex: 1,
        }}
      >
        <Stepper stepsArray={stepsArray} />
      </Div>
      <Div
        css={{
          marginRight: '$4',
        }}
      >
        <Tag variant={'warning'}>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',

              svg: {
                height: 12,
                width: 12,

                marginRight: '$2',
              },
            }}
          >
            <ExclamationTriangleIcon />
            Datos ficticios para vizualicación
          </Div>
        </Tag>
      </Div>
    </Div>
  )
}
