import { Div, FormRenderer } from '@/components'
import { FormContainer } from '..'
import { Switch, Text } from '@punto-ui/react'
import { useLastSalaryPayrolls } from '../form/useLastSalariesPayrolls'
import { useLastSalary } from '../form/useLastSalaries'
import { useState } from 'react'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { formatCurrency } from '@/utils/numbers/currency'

interface ILastSalariesLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const LastSalariesLiquidation = ({
  isOpen,
  toggleOpen,
}: ILastSalariesLiquidationParams) => {
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [averageSalary, dailySalary] = useWatch({
    control,
    name: ['data.salary_average', 'data.daily_salary'],
  })

  const [isPayrollSelection, setIsPayrollSelection] = useState(false)

  const { form: lastSalaryForm } = useLastSalary()
  const { form: lastSalaryPayrollsForm } = useLastSalaryPayrolls()

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={isOpen}
        setIsOpen={toggleOpen}
        withoutStatusButton
        title={'Salário Últimos 6 meses'}
        totals={[
          `Salário Promedio ${formatCurrency(+averageSalary)}`,
          `Promedio Diario ${formatCurrency(+dailySalary)}`,
        ]}
        otherButtons={
          <Div css={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              css={{ marginRight: '$4' }}
              checked={isPayrollSelection}
              onCheckedChange={(v) => setIsPayrollSelection(v)}
            />
            {isPayrollSelection ? (
              <Text css={{ color: '$interface_dark_deep' }}>
                Selecionar Valores
              </Text>
            ) : (
              <Text css={{ color: '$interface_dark_deep' }}>
                Selecionar Planillas
              </Text>
            )}
          </Div>
        }
      />
      {isOpen && (
        <>
          {!isPayrollSelection ? (
            <>
              <FormRenderer inline items={lastSalaryForm} cols={4} />
            </>
          ) : (
            <FormRenderer inline items={lastSalaryPayrollsForm} cols={4} />
          )}
        </>
      )}
    </FormContainer>
  )
}
