import { keyframes } from '@punto-ui/react'
import { styled } from '../../styles'

export const ChipRoot = styled('div', {
  position: 'relative',

  defaultVariants: {
    variant: 'default',
  },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translateY(0%) scale(1)' },
})

export const ChipActivatedNum = styled('div', {
  display: 'flex',
  minWidth: '$5',
  width: '$5',
  minHeight: '$5',
  height: '$5',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$brand_primary_pure',
  color: '$brand_primary_pure',
  fontWeight: '$semibold',
  fontFamily: '$default',
  fontSize: '$md',
  borderRadius: '$full',

  variants: {
    hasContent: {
      false: {
        background: 'transparent',
      },
    },
  },
})

export const ChipOptionsContainer = styled('ul', {
  all: 'unset',
  position: 'absolute',
  top: 'calc(100% + 4px)',
  zIndex: 11,

  maxHeight: 400,
  minWidth: 200,
  overflowY: 'hidden',

  backgroundColor: '$interface_light_pure',
  borderRadius: '$md',

  display: 'none',
  flexDirection: 'column',
  alignItems: 'stretch',

  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
  border: 'solid 1px $interface_light_down',
  opacity: 0,
  transition: 'opacity 0.5s ease-in-out',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  variants: {
    isOpen: {
      true: {
        display: 'flex',
        opacity: 1,
      },
      false: {
        display: 'none',
        opacity: 0,

        '&:hover': {
          opacity: 1,
          display: 'flex',
        },
      },
    },
    dropdownSide: {
      left: {
        left: 0,
      },
      right: {
        right: 0,
      },
    },
  },
})

export const ChipItem = styled('li', {
  '& label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 8,
    gap: 4,
  },

  all: 'unset',
  borderRadius: 4,

  variants: {
    isSelected: {
      true: {
        backgroundColor: '$interface_light_up',
      },
    },
  },
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // marginTop: 16,
  paddingTop: 16,
  paddingBottom: 16,

  borderTop: '1px solid $interface_light_down',
})

export const ChipScrollingContainer = styled('div', {
  overflowY: 'auto',
  padding: '$2',
})

export const ChipInput = styled('input', {
  all: 'unset',
  textAlign: 'left',
  width: '100%',

  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  border: 0,
  // width: '100%',
  // maxWidth: 75,

  '&::placeholder': {
    color: '$brand_primary_pure',
  },
})
