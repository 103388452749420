import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { createCompleteWorkerData } from '../types'
import { IWorkerUser } from '@/libs/react-query/types'
import { queryClient } from '@/libs/react-query'
import { parseUpdateWorkerStructure } from './parseStructure'

const handleUpdateWorker = async ({ ...props }: createCompleteWorkerData) => {
  const parsedData = parseUpdateWorkerStructure(props)

  const response = await apiV2.post<IWorkerUser>('/user/update', parsedData)

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleUpdateWorker = () => {
  const mutation = useMutation(['useHandleUpdateWorker'], handleUpdateWorker)

  return mutation
}
