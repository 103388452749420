import { Div, Touchable } from '@/components'
import { Button, Divider, Text } from '@punto-ui/react'
import { ContainerInputTitle } from '..'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  MinusCircleIcon,
  NoSymbolIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { useUpdateUserLiquidationConcept } from '@/libs/react-query/mutations/company/useHandleUpdateLiquidationConcept'
import {
  ILiquidationBasicConcepts,
  ILiquidationConceptTypes,
} from '@/libs/react-query/types/company'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { useEffect, useState } from 'react'

interface ILiquidationCardHeader {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  title: string
  totals?: string[]
  total?: string
  otherButtons?: any

  deactivateKey?: ILiquidationBasicConcepts | string
  isIpsDisabled?: boolean
  conceptType?: ILiquidationConceptTypes

  disabled?: boolean
  withoutStatusButton?: boolean
  withoutIpsButton?: boolean

  withoutNameEditing?: boolean
}

export const LiquidationCardHeader = ({
  isOpen,
  setIsOpen,
  title,
  totals,
  total,
  otherButtons,

  deactivateKey,

  conceptType,
  disabled,
  isIpsDisabled,
  withoutStatusButton,
  withoutIpsButton: withoutIpsButtonParams,
  withoutNameEditing = false,
}: ILiquidationCardHeader) => {
  const [editedTitle, setEditedTitle] = useState(title)
  const {
    mutateAsync: handleUpdateUserLiquidationConcept,
    isLoading: isLoadingConcept,
  } = useUpdateUserLiquidationConcept()

  const isFormDisabled = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [userId, isUserInIps, liquidationId, status] = useWatch({
    control,
    name: ['data.user_id', 'user_in_ips', 'liquidationId', 'data.status'],
  })

  useEffect(() => {
    setEditedTitle(title)
  }, [title])

  const withoutIpsButton = withoutIpsButtonParams || !isUserInIps

  const isDoneLiquidation = status === 'done'

  return (
    <Touchable onClick={() => setIsOpen(!isOpen)}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$4',
            overflow: 'hidden',

            opacity: disabled ? 0.5 : 1,

            svg: {
              width: 20,
              height: 20,
              color: '$brand_primary_pure',
            },
          }}
        >
          <Div>{isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}</Div>
          {/* <ContainerTitle variant="subtitle2">{title}</ContainerTitle> */}
          <ContainerInputTitle
            value={editedTitle}
            readOnly={withoutNameEditing}
            disabled={withoutNameEditing}
            onChange={(e) => setEditedTitle(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={(e) => {
              if (
                e.target.value &&
                e.target.value !== title &&
                conceptType &&
                deactivateKey
              ) {
                handleUpdateUserLiquidationConcept({
                  entity_id: deactivateKey,
                  type: conceptType,
                  user_id: userId,
                  name: e.target.value,
                  liquidation_id: liquidationId,
                })
              }
            }}
          />
          {!!totals?.length &&
            !disabled &&
            totals.map((t, index) => (
              <>
                <Text
                  variant={'paragraph'}
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  {t}
                </Text>
                {index !== totals.length - 1 && (
                  <Divider
                    dir="vertical"
                    css={{
                      background: '$interface_light_deep',
                      width: 1,
                      height: 22,
                    }}
                  />
                )}
              </>
            ))}
          {!disabled && total && (
            <Text
              variant={'paragraph'}
              css={{
                color: '$interface_dark_down',
              }}
            >
              {total}
            </Text>
          )}
        </Div>
        <Div
          onClick={(e) => e.stopPropagation()}
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$4',
          }}
        >
          {!!otherButtons && !disabled && otherButtons}
          {withoutStatusButton ? null : (
            <>
              <Button
                css={{
                  minWidth: 180,
                }}
                icon={disabled ? <PlusCircleIcon /> : <NoSymbolIcon />}
                // isLoading={isLoadingConcept}
                disabled={
                  isFormDisabled || isLoadingConcept || isDoneLiquidation
                }
                variant={disabled ? 'secondaryInverse' : 'secondaryCritical'}
                onClick={() => {
                  if (!deactivateKey || !conceptType) {
                    return
                  }

                  handleUpdateUserLiquidationConcept({
                    entity_id: deactivateKey,
                    type: conceptType,
                    user_id: userId,
                    is_active: !!disabled,
                    liquidation_id: liquidationId,
                  })

                  setIsOpen(disabled || false)
                }}
              >
                {disabled ? 'Activar Concepto' : 'Desactivar Concepto'}
              </Button>
              {!disabled && !withoutIpsButton && (
                <Button
                  css={{
                    minWidth: 200,
                  }}
                  icon={
                    isIpsDisabled ? <PlusCircleIcon /> : <MinusCircleIcon />
                  }
                  // isLoading={isLoadingConcept}
                  disabled={
                    isFormDisabled || isLoadingConcept || isDoneLiquidation
                  }
                  variant={
                    isIpsDisabled ? 'secondaryInverse' : 'secondaryCritical'
                  }
                  onClick={() => {
                    if (!deactivateKey || !conceptType) {
                      return
                    }

                    handleUpdateUserLiquidationConcept({
                      entity_id: deactivateKey,
                      type: conceptType,
                      user_id: userId,
                      ips: isIpsDisabled,
                      liquidation_id: liquidationId,
                    })
                  }}
                >
                  {isIpsDisabled
                    ? 'Incluir en seguro social'
                    : 'Excluir del seguro social'}
                </Button>
              )}
            </>
          )}
        </Div>
      </Div>
    </Touchable>
  )
}
