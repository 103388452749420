import { useCan } from '@/hooks'
import { useWorkerCreation } from '../context/hooks'
import { useMemo } from 'react'
import { useTabStore } from '@/store'

export const useIsWorkerFormAvailable = () => {
  const canEdit = useCan(['workers.all', 'workers.edit'])
  const canCreate = useCan(['workers.fullCreation'])

  const { isCreation } = useWorkerCreation()

  const { activeTab, tabs } = useTabStore((state) => ({
    activeTab: state.worker.activeTab,
    tabs: state.worker.tabs,
  }))

  const isWorkerFormDisabledByTab = useMemo(() => {
    const tab = tabs.find((t) => t.id === activeTab)
    return !!tab?.metadata?.disabled
  }, [tabs, activeTab])

  return isWorkerFormDisabledByTab ? false : isCreation ? canCreate : canEdit
}
