import { Text } from '@punto-ui/react'
import { styled } from '../../../styles'

export const CalendarContainer = styled('div', {
  padding: '$4',
  paddingTop: '$2',

  transition: 'all 0.2s ease-in-out',

  '& table': {
    width: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
  },
})

export const DayCellFill = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  aspectRatio: '1 / 1',
  zIndex: 2,
})

export const DayCell = styled('td', {
  width: '100%',
  aspectRatio: '1 / 1',
  position: 'relative',

  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    '& button': {
      backgroundColor: '$brand_primary_pure',
    },

    border: 0,

    [`& ${Text}`]: {
      color: '$interface_light_pure',
    },
  },

  '& button': {
    all: 'unset',
    width: '100%',
    aspectRatio: '1 / 1',
    cursor: 'pointer',
  },

  variants: {
    disabled: {
      true: {
        '&:hover': {
          [`& ${DayCellFill}`]: {
            backgroundColor: '$interface_light_pure',
          },

          [`& ${Text}`]: {
            color: '$interface_dark_up',
          },
        },
      },
    },
    isRange: {
      false: {
        '& button': {
          borderRadius: '$full',
        },
      },
    },
    isStartDate: {
      true: {
        '& button': {
          borderTopLeftRadius: '$md',
          borderBottomLeftRadius: '$md',
        },
      },
    },
    isEndDate: {
      true: {
        '& button': {
          zIndex: 2,
          borderTopRightRadius: '$md',
          borderBottomRightRadius: '$md',
        },
      },
    },
    selected: {
      true: {
        border: 'none',
        [`& ${DayCellFill}`]: {
          zIndex: 1,
        },

        '& button': {
          zIndex: 2,
          backgroundColor: '$brand_primary_pure',
        },

        [`& ${Text}`]: {
          color: '$interface_light_pure',
        },
      },
      false: {
        '&:hover': {
          '& button': {
            borderRadius: '$full',
          },
        },
      },
    },
    between: {
      true: {
        backgroundColor: '$interface_light_down',
      },
    },
    isHeader: {
      true: {
        '&:hover': {
          [`& ${Text}`]: {
            color: '$interface_dark_down',
          },
        },
      },
    },
    isToday: {
      true: {
        border: 'solid 1px $brand_primary_pure',
        borderRadius: '$full',
        [`& ${Text}`]: {
          color: '$brand_primary_pure',
        },
      },
    },
  },
})

export const BgCellFill = styled('div', {
  backgroundColor: '$interface_light_down',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '50%',
  zIndex: 1,
})
