import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { queryClient } from '@/libs/react-query'
import { UpdatePunchPayload } from '../types'

const handleUpdatePunch = async (props: UpdatePunchPayload) => {
  const response = await apiV2.post<any>(
    '/punches/update-punch-from-time',
    props,
  )

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleUpdatePunch = () => {
  const mutation = useMutation(['useUpdatePunch'], handleUpdatePunch)

  return mutation
}
