import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { CreatePunchPayload } from '../types'

const handleCreatePunch = async (props: CreatePunchPayload) => {
  const response = await apiV2.post<any>('/punches/create-punch', props)

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleCreatePunch = () => {
  const mutation = useCustomMutation(['useCreatePunch'], handleCreatePunch)

  return mutation
}
