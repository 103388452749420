import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ConfigFields } from '../../..'
import { useMemo } from 'react'
import { useCan } from '@/hooks'

export const useGroupInfo = () => {
  const canCreateVacation = useCan(['configurations.vacations'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'input',
      label: 'Nombre del Grupo de Configuración',
      name: 'vacation.name',
      disabled: !canCreateVacation,
    },
    {
      _type: 'switch',
      label: 'Incluir salario base en el cálculo',
      disabled: !canCreateVacation,
      name: 'vacation.include_base_salary',
    },
    {
      _type: 'switch',
      label: 'Usar fecha de IPS',
      disabled: !canCreateVacation,
      name: 'vacation.should_use_ips_date',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useVacationGroupConcepts = () => {
  const { data: company } = useMyCompany()
  const canCreateVacation = useCan(['configurations.vacations'])

  const orderedVacationConcepts = useMemo(() => {
    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const groupInfoForm: ConfigFields = orderedCompanyConcepts.map(
      (c, index) => {
        return {
          _type: 'switch',
          disabled: !canCreateVacation,
          label: c,
          name: `vacation.concepts.${index}`,
        }
      },
    )

    return groupInfoForm
  }, [company])

  return {
    data: orderedVacationConcepts,
  }
}
