import { styled } from '@punto-ui/react'
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useCallback } from 'react'
import { AddShiftData, ShiftData } from '../../forms'
import { Flex } from '@/components'
import { useSkipper } from '@/hooks'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { getWorkHours } from '@/utils/shifts/getWorkHours'
import { formatMinutes } from '@/utils/date'
import { ShiftsTable } from '..'
import { buildShiftsColumns } from './utils'

interface WeekShiftTableProps {
  page: number
  pageLength: number
}

export const WeekShiftTable = ({ page, pageLength }: WeekShiftTableProps) => {
  const { getValues, setValue, control } = useFormContext<AddShiftData>()

  const { replace, fields: shifts } = useFieldArray({
    name: 'shifts',
    control,
  })

  const sheetShifts = React.useMemo(() => {
    return shifts.slice((page - 1) * pageLength, page * pageLength) || []
  }, [shifts, page, pageLength])

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

  const handleRemoveInterval = useCallback(
    (page: number, pageLength: number, intervalIndex: number) => {
      const { shifts } = getValues()

      const newShifts = shifts.map((shift, index) => {
        if (
          index >= page * pageLength - pageLength &&
          index < page * pageLength
        ) {
          return {
            ...shift,
            intervals: shift.intervals.filter(
              (interval, intervalIndexInShift) =>
                intervalIndexInShift !== intervalIndex,
            ),
          }
        } else {
          return shift
        }
      })

      setValue('shifts', newShifts)
    },
    [setValue, getValues],
  )

  const handleAddInterval = useCallback(
    (page: number, pageLength: number) => {
      const newShifts = shifts.map((shift, index) => {
        if (
          index >= page * pageLength - pageLength &&
          index < page * pageLength
        ) {
          const oldIntervals = shift.intervals || []

          return {
            ...shift,
            intervals: [
              ...oldIntervals,
              {
                startTime: '00:00',
                duration: '00:00',
                endTime: '00:00',
              },
            ],
          }
        }

        return shift
      })
      replace(newShifts)
    },
    [replace, shifts],
  )

  const columns = React.useMemo<ColumnDef<ShiftData>[]>(() => {
    return buildShiftsColumns({
      shifts: sheetShifts,
      handleAddColumn: () => {
        handleAddInterval(page, pageLength)
      },
      handleRemoveColumn: (index) => {
        handleRemoveInterval(page, pageLength, index)
      },
    })
  }, [handleAddInterval, handleRemoveInterval, page, pageLength, sheetShifts])

  const table = useReactTable({
    data: (sheetShifts as ShiftData[]) ?? [],
    columns,
    getRowId: (row) => row.date,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex: number) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex()
        const correctDayIndex = rowIndex + (page - 1) * pageLength
        const formValues = getValues()

        const { dayHours, nightHours, total } = getWorkHours({
          entrance: formValues.shifts[correctDayIndex].startTime,
          exit: formValues.shifts[correctDayIndex].endTime,
          intervals: formValues.shifts[correctDayIndex].intervals,
          fromDayToNight: '20:00',
          fromNightToDay: '06:00',
        })

        setValue(
          `shifts.${correctDayIndex}.totalDaily`,
          formatMinutes(dayHours * 60),
        )
        setValue(
          `shifts.${correctDayIndex}.totalNight`,
          formatMinutes(nightHours * 60),
        )
        setValue(
          `shifts.${correctDayIndex}.totalHours`,
          formatMinutes(total * 60),
          { shouldTouch: true },
        )
      },
    },
  })

  return (
    <Flex css={{ overflow: 'scroll' }}>
      <ShiftsTable
        table={table as any}
        withPagination={false}
        numberOfRows={sheetShifts.length ?? 0}
        page={page}
        pageLength={pageLength}
      />
    </Flex>
  )
}

export const TableHeaderIcon = styled('button', {
  all: 'unset',
  cursor: 'pointer',

  variants: {
    positive: {
      true: {
        '& svg': {
          color: '$brand_primary_pure',
        },
      },
      false: {
        '& svg': {
          color: '$status_danger_pure',
        },
      },
    },
  },
})
