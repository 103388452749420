import React, { createContext, useEffect } from 'react'
import { freshChatService } from '@/services/freshchat'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ICompanyV2 } from '@/libs/react-query/types/company'

export type CompanyContextDataProps = {
  selectedCompany: ICompanyV2 | undefined
}

type CompanyContextProviderProps = {
  children: React.ReactNode
}

export const CompanyContext = createContext({} as CompanyContextDataProps)

export const CompanyContextProvider = ({
  children,
}: CompanyContextProviderProps) => {
  const { data: company } = useMyCompany()

  useEffect(() => {
    freshChatService.setCompanyId(company?.id || 'UNKNOWN')
    // }
  }, [company?.id])

  return (
    <CompanyContext.Provider
      value={{
        selectedCompany: company,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}
