import { useQuery } from 'react-query'
import { useMe } from '..'
import { apiV2 } from '@/services/api'
import { ICompanyV2 } from '../../types/company'

const getMyCompany = async () => {
  try {
    const response = await apiV2.get<ICompanyV2>('/company/get-company')
    const { data } = response

    return data
  } catch (error) {
    console.log('[ERROR FETCH COMPANIES]', error)
  }
}

export const useMyCompany = () => {
  const { data: me } = useMe()

  const query = useQuery(['my-company', me?.company_id], getMyCompany, {
    enabled: !!me?.id,
  })

  return query
}
