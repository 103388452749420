import { useCan } from '@/hooks'
import { ConfigFields } from '../../..'

export const useAllowedPunch = () => {
  const canCreatePunches = useCan(['configurations.punches'])
  const allowedPunchParams: ConfigFields = [
    {
      _type: 'checkbox',
      disabled: !canCreatePunches,
      label: 'Geolocalización con Face ID',
      name: 'punches.is_able_to_punch_geolocation_face_id',
    },
    {
      _type: 'checkbox',
      label: 'Face ID',
      disabled: !canCreatePunches,
      name: 'punches.is_able_to_punch_face_id',
    },
    // {
    //   _type: 'checkbox',
    //   label: 'Geolocalización',
    //   name: 'punches.is_able_to_punch_geolocation',
    // },
    {
      _type: 'checkbox',
      disabled: !canCreatePunches,
      label: 'Marcar solamente por Geolocalización',
      name: 'punches.is_able_to_punch_geolocation',
    },
    {
      disabled: !canCreatePunches,
      _type: 'checkbox',
      label: 'Geolocalización Movil',
      name: 'punches.is_able_to_punch_from_anywhere',
    },
    // {
    //   _type: 'checkbox',
    //   label: 'Face ID',
    //   name: 'punches.is_able_to_punch_face_id',
    // },
    {
      _type: 'checkbox',
      label: 'Marcación Offline',
      disabled: !canCreatePunches,
      name: 'punches.is_able_to_punch_offline',
    },
    {
      _type: 'checkbox',
      label: 'Presencia por padrón',
      disabled: !canCreatePunches,
      name: 'punches.is_presence_default',
    },
  ]

  return {
    data: allowedPunchParams,
  }
}

export const useAppConfig = () => {
  const canCreatePunches = useCan(['configurations.punches'])
  const allowedPunchParams: ConfigFields = [
    {
      disabled: !canCreatePunches,
      _type: 'checkbox',
      label: 'Permitir ver historial de marcaciones',
      name: 'punches.is_able_to_see_punch_history',
    },
    {
      disabled: !canCreatePunches,
      _type: 'checkbox',
      label: 'Mostrar marcaciones fuera del alcance',
      name: 'punches.should_show_invalid_geo_punches',
    },
    {
      disabled: !canCreatePunches,
      _type: 'checkbox',
      label: 'Permitir Registro Facial',
      name: 'punches.should_allow_facial_registration',
    },
    {
      disabled: !canCreatePunches,
      _type: 'checkbox',
      label: 'Permitir Registro Facial Único',
      name: 'punches.should_allow_unique_facial_registration',
    },
  ]

  return {
    data: allowedPunchParams,
  }
}

export const useCompanyAllowedPunch = () => {
  const canCreatePunches = useCan(['configurations.punches'])
  const companyAllowedPunchParams: ConfigFields = [
    {
      _type: 'input',
      disabled: !canCreatePunches,
      label: 'Nombre del Grupo',
      name: 'punches.name',
    },
    {
      _type: 'input',
      label: 'Precisión para marcar',
      disabled: !canCreatePunches,
      name: 'punches.required_aws_precision',
      inputType: 'percentage',
    },
  ]

  return {
    data: companyAllowedPunchParams,
  }
}
