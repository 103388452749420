import { z } from 'zod'

export const exportReceiptSchema = z.object({
  totalWorkedDaysCompensation: z.boolean(),
  morningExtraHourValue: z.boolean(),
  nightExtraHourValue: z.boolean(),
  morningHolidayHourValue: z.boolean(),
  nightHolidayHourValue: z.boolean(),
  morningDiscountValue: z.boolean(),
  nightDiscountValue: z.boolean(),
  familiarBonus: z.boolean(),
  payments: z.boolean(),
  discounts: z.boolean(),
  ips9: z.boolean(),
})

export const exportPayrollSchema = z.object({
  columns: z.object({
    document: z.boolean(),
    surname: z.boolean(),
    name: z.boolean(),
    bank: z.boolean(),
    bankAccount: z.boolean(),
    baseSalary: z.boolean(),
    workedDays: z.boolean(),
    paidDays: z.boolean(),
    totalWorkedDaysCompensation: z.boolean(),
    morningExtraHourValue: z.boolean(),
    morningHolidayHourValue: z.boolean(),
    nightHourValue: z.boolean(),
    nightExtraHourValue: z.boolean(),
    nightHolidayHourValue: z.boolean(),
    morningTotalHourValue: z.boolean(),
    nightTotalHourValue: z.boolean(),
    totalHourValue: z.boolean(),
    familiarBonus: z.boolean(),
    paymentsPreIps: z.boolean(),
    earningsTotal: z.boolean(),
    totalMorningDiscountValues: z.boolean(),
    totalNightDiscountValues: z.boolean(),
    discountsPreIps: z.boolean(),
    subtotalAfterDiscountsPreIps: z.boolean(),
    ipsBase: z.boolean(),
    ips16: z.boolean(),
    ips9: z.boolean(),
    totalIps: z.boolean(),
    finalSalary: z.boolean(),
    paymentsAfterIps: z.boolean(),
    discountsAfterIps: z.boolean(),

    totalPaymentsPreIps: z.boolean(),
    totalPaymentsAfterIps: z.boolean(),
    totalDiscountsPreIps: z.boolean(),
    totalDiscountsAfterIps: z.boolean(),

    finalRemuneration: z.boolean(),
  }),
})

export const exportSchema = z.object({
  selectedReport: z.enum(['payroll', 'salary_receipt']),
  receipt: exportReceiptSchema,
  payroll: exportPayrollSchema,
})

export type ExportModuleSchema = z.infer<typeof exportSchema>
export type ExportPayrollType = z.infer<typeof exportPayrollSchema>
export type ExportReceiptType = z.infer<typeof exportReceiptSchema>

export const DEFAULT_EXPORT_PAYROLL_FORM = {
  columns: {
    document: true,
    surname: true,
    name: true,
    bank: true,
    bankAccount: true,
    baseSalary: true,
    workedDays: true,
    paidDays: true,
    totalWorkedDaysCompensation: true,
    morningExtraHourValue: true,
    morningHolidayHourValue: true,
    nightHourValue: true,
    nightExtraHourValue: true,
    nightHolidayHourValue: true,
    morningTotalHourValue: true,
    nightTotalHourValue: true,
    totalHourValue: true,
    familiarBonus: true,
    paymentsPreIps: true,
    earningsTotal: true,
    totalMorningDiscountValues: true,
    totalNightDiscountValues: true,
    discountsPreIps: true,
    subtotalAfterDiscountsPreIps: true,
    ipsBase: true,
    ips16: true,
    ips9: true,
    totalIps: true,
    finalSalary: true,
    paymentsAfterIps: true,
    discountsAfterIps: true,

    totalPaymentsPreIps: true,
    totalPaymentsAfterIps: true,
    totalDiscountsPreIps: true,
    totalDiscountsAfterIps: true,

    finalRemuneration: true,
  },
}

export const DEFAULT_EXPORT_RECEIPT_FORM = {
  totalWorkedDaysCompensation: true,
  morningExtraHourValue: true,
  nightExtraHourValue: true,
  morningHolidayHourValue: true,
  nightHolidayHourValue: true,
  morningDiscountValue: true,
  nightDiscountValue: true,
  familiarBonus: true,
  payments: true,
  discounts: true,
  ips9: true,
}
