import { Text } from '@punto-ui/react'
import React from 'react'
import {
  PageHeaderActions,
  PageHeaderContainer,
  PageHeaderInfos,
} from './styles'

interface PageHeaderProps {
  title: string
  subtitle?: string
  children?: React.ReactNode
}

export const PageHeader = ({ title, subtitle, children }: PageHeaderProps) => {
  return (
    <PageHeaderContainer>
      <PageHeaderInfos>
        <Text
          as="h1"
          css={{ color: '$brand_primary_pure', fontWeight: 'bold' }}
          size="4xl"
          variant="subtitle1"
        >
          {title}
        </Text>
        {!!subtitle && (
          <Text
            as="span"
            css={{ color: '$interface_dark_down' }}
            variant="paragraph"
          >
            {subtitle}
          </Text>
        )}
      </PageHeaderInfos>
      <PageHeaderActions>{children}</PageHeaderActions>
    </PageHeaderContainer>
  )
}
