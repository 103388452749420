import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ConfigFields } from '../../..'
import { useMemo } from 'react'
import { useCan } from '@/hooks'

export const useGroupInfo = () => {
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])

  const groupInfoForm: ConfigFields = [
    {
      disabled: !canCreateAguinaldos,
      _type: 'input',
      label: 'Nombre del Grupo de Configuración',
      name: 'aguinaldo.name',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useAguinaldoGroupConcepts = () => {
  const { data: company } = useMyCompany()
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])

  const orderedVacationConcepts = useMemo(() => {
    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const groupInfoForm: ConfigFields = orderedCompanyConcepts.map(
      (c, index) => {
        return {
          _type: 'switch',
          label: c,
          disabled: !canCreateAguinaldos,
          name: `aguinaldo.concepts.${index}`,
        }
      },
    )

    return groupInfoForm
  }, [company])

  return {
    data: orderedVacationConcepts,
  }
}

export const useAguinaldoHoursConfiguration = () => {
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'switch',
      label: 'Ordinárias Nocturnas',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.ordinaryNightHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Extras Diurnas',
      name: 'aguinaldo.extraMorningHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Extras Nocturnas',
      name: 'aguinaldo.extraNightHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Feriado Diurnas',
      name: 'aguinaldo.holidayMorningHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Feriado Nocturnas',
      name: 'aguinaldo.holidayNightHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Descuento Diurno',
      name: 'aguinaldo.discountMorningHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Descuento Nocturno',
      name: 'aguinaldo.discountNightHours',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useAguinaldoEarningsConfiguration = () => {
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Vacaciones Proporcionales',
      name: 'aguinaldo.proportional_vacations',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Vacaciones',
      name: 'aguinaldo.vacation_value',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Bonificación Familiar',
      name: 'aguinaldo.familiarBonus',
    },
    {
      _type: 'switch',
      label: 'Movilidad',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.mobility',
    },
    {
      _type: 'switch',
      label: 'Bonos',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.bonus',
    },
    {
      _type: 'switch',
      label: 'Comisión',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.commission',
    },
    {
      _type: 'switch',
      label: 'Gratificaciones',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.gift',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Otros Ingressos',
      name: 'aguinaldo.otherEarns',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useAguinaldoDiscountsConfiguration = () => {
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'IPS 9%',
      name: 'aguinaldo.ips9',
    },
    {
      disabled: !canCreateAguinaldos,
      _type: 'switch',
      label: 'Anticipo',
      name: 'aguinaldo.antecipate',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Prestamo',
      name: 'aguinaldo.loan',
    },
    {
      _type: 'switch',
      label: 'Perdidas y Averias',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.lost',
    },
    {
      _type: 'switch',
      label: 'Compras',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.purchase',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Desc. Judiciales',
      name: 'aguinaldo.judicial',
    },
    {
      _type: 'switch',
      label: 'Pagos en excesso',
      disabled: !canCreateAguinaldos,
      name: 'aguinaldo.excess',
    },
    {
      _type: 'switch',
      disabled: !canCreateAguinaldos,
      label: 'Otros Descuentos',
      name: 'aguinaldo.otherDiscount',
    },
  ]

  return {
    data: groupInfoForm,
  }
}
