import { queryClient } from '@/libs/react-query'
import { IUpdateTemplateDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleUpdateTemplate = async (data: IUpdateTemplateDTO) => {
  await apiV2.post<any>(`/report-builder/update-template`, data)
  queryClient.invalidateQueries('templates')
}

export const useHandleUpdateTemplate = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateTemplate'],
    handleUpdateTemplate,
  )

  return mutation
}
