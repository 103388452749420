import { withSSRAuth } from '@/pages/utils/withSSRAuth'

import { ActionsMenu, CreateShiftsPageHeader } from './components'
import { ShiftsTabs } from '../components'
import { Div } from '@/components'
import { CreateShiftsProvider } from './context'

export default function CreateShift() {
  return (
    <CreateShiftsProvider>
      <Div
        css={{
          padding: 16,
        }}
      >
        <CreateShiftsPageHeader />
        <Div
          css={{
            background: '$interface_light_pure',
            borderRadius: '$md',
          }}
        >
          <ShiftsTabs />
        </Div>
        <ActionsMenu />
      </Div>
    </CreateShiftsProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
