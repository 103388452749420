import { useInputDatePicker } from '..'
import {
  HoursContainer,
  HoursHeader,
  HoursInfoContainer,
  HoursInfos,
} from './styles'
import { useState } from 'react'
import dayjs from 'dayjs'
import { Divider, Switch, Text, encodeByPattern } from '@punto-ui/react'

// hour = '14:55'
export const fullfillHours = (hour: string) => {
  if (!hour.length) {
    return '00:00'
  }

  if (hour.length === 1) {
    return `${hour}0:00`
  }

  if (hour.length === 2) {
    return `${hour}:00`
  }

  if (hour.length === 3) {
    return `${hour}00`
  }

  if (hour.length === 4) {
    return `${hour}0`
  }

  return hour
}

export const Hours = () => {
  const {
    firstHour,
    secondHour,
    setFirstHour,
    setSecondHour,
    firstDate,
    secondDate,
    onChange,
    isHoursOpen,
    setIsHoursOpen,
    disabled,
  } = useInputDatePicker()

  const firstDateLabel = dayjs(firstDate).locale('es-us').format('DD MMMM YYYY')
  const secondDateLabel = dayjs(secondDate)
    .locale('es-us')
    .format('DD MMMM YYYY')

  const [isFirstFocused, setIsFirstFocused] = useState(false)
  const [isSecondFocused, setIsSecondFocused] = useState(false)

  // newHour = '14:55'
  const onChangeHour = (isFirstHour: boolean, newHourRaw: string) => {
    const hourSafe = encodeByPattern(newHourRaw, '99:99')

    if (isFirstHour) {
      setFirstHour(hourSafe)
    } else {
      setSecondHour(hourSafe)
    }

    const newHourSafe = fullfillHours(hourSafe)

    const newDate = isFirstHour
      ? dayjs(firstDate)
          .set('hour', Number(newHourSafe.split(':')[0]))
          .set('minute', Number(newHourSafe.split(':')[1]))
      : dayjs(secondDate)
          .set('hour', Number(newHourSafe.split(':')[0]))
          .set('minute', Number(newHourSafe.split(':')[1]))

    const newFirstDate = isFirstHour ? newDate : dayjs(firstDate)
    const newSecondDate = isFirstHour ? dayjs(secondDate) : newDate

    onChange(
      [
        newFirstDate.isValid() ? newFirstDate.toDate() : null,
        newSecondDate.isValid() ? newSecondDate.toDate() : null,
      ],
      false,
    )
  }

  return (
    <HoursContainer>
      <HoursHeader>
        <Text variant={'caption'} css={{ color: '$interface_dark_deep' }}>
          Incluir Horas
        </Text>
        <Switch
          checked={isHoursOpen}
          onCheckedChange={(v) => setIsHoursOpen(v)}
        />
      </HoursHeader>
      {isHoursOpen && (
        <HoursInfos>
          <HoursInfoContainer
            css={{
              borderColor:
                isFirstFocused || firstHour ? '$brand_primary_pure' : undefined,

              '> input': {
                color:
                  isFirstFocused || firstHour
                    ? '$interface_dark_deep'
                    : undefined,
              },
            }}
          >
            <input value={firstDate ? firstDateLabel : ''} readOnly />
            <Divider orientation="vertical" />
            <input
              onFocus={() => setIsFirstFocused(true)}
              onBlur={() => setIsFirstFocused(false)}
              placeholder="--:--"
              value={firstHour}
              onChange={(e) => onChangeHour(true, e.target.value)}
              disabled={!firstDate || disabled}
            />
          </HoursInfoContainer>
          <HoursInfoContainer
            css={{
              borderColor:
                isSecondFocused || secondHour
                  ? '$brand_primary_pure'
                  : undefined,
              '> input': {
                color:
                  isSecondFocused || secondHour
                    ? '$interface_dark_deep'
                    : undefined,
              },
            }}
          >
            <input value={secondDate ? secondDateLabel : ''} readOnly />
            <Divider orientation="vertical" />
            <input
              disabled={!secondDate || disabled}
              onFocus={() => setIsSecondFocused(true)}
              onBlur={() => setIsSecondFocused(false)}
              value={secondHour}
              placeholder="--:--"
              onChange={(e) => onChangeHour(false, e.target.value)}
            />
          </HoursInfoContainer>
        </HoursInfos>
      )}
    </HoursContainer>
  )
}
