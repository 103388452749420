import { Div } from '@/components'
import { Progress, Tabs } from '@punto-ui/react'
import { HoursDayDetail } from './Hours'
import { DayDrawerSummary } from './DayDrawerSummary'
import { AddMovement, MovementsTab } from './movements'
import { DayDrawerHeader } from './DayDrawerHeader'
import { usePayrollPlaygroundStore } from '../../../store'
import { usePayrollDay } from './usePayrollDay'
import { useDayDetail } from './store'
import { useEffect } from 'react'

export const DayDetail = () => {
  const { drawer } = usePayrollPlaygroundStore((state) => ({
    drawer: state.drawer.data,
  }))
  const { updateView } = useDayDetail((state) => ({
    updateView: state.updateView,
  }))
  const { isFetching: isLoadingPayrollDetail, isLoading } = usePayrollDay()

  useEffect(() => {
    return () => {
      updateView('tabs', {})
    }
  }, [updateView])

  return (
    <Div css={{ minWidth: 660, position: 'relative' }}>
      {isLoadingPayrollDetail && (
        <Div
          css={{
            position: 'absolute',
            top: 8,
            left: 8,
          }}
        >
          <Progress size="sm" />
        </Div>
      )}
      {!isLoading && !!drawer && (
        <>
          <DayDrawerHeader />
          <DayTabs />
        </>
      )}
    </Div>
  )
}

export const DayTabs = () => {
  const { view } = useDayDetail((state) => ({
    view: state.view,
  }))

  if (view === 'add-movement') {
    return <AddMovement />
  }

  if (view === 'edit-movement') {
    return <AddMovement />
  }

  return (
    <Tabs
      tabs={[
        {
          label: 'Resumen',
          content: <DayDrawerSummary />,
        },
        {
          label: 'Horas',
          content: <HoursDayDetail />,
        },
        {
          label: 'Movimientos',
          content: <MovementsTab />,
        },
      ]}
    />
  )
}
