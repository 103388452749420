import {
  SmartColumnType,
  SubmitValueProps,
} from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { PayrollPlaygroundTableType } from '../../types'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { usePayrollPlaygroundStore } from '../../store'
import { useCan } from '@/hooks'

export const usePayrollCalendarTable = () => {
  const { handleOpenDrawer } = usePayrollPlaygroundStore()
  const { control, getValues } = useFormContext<PayrollPlaygroundTableType>()
  const { data: company } = useMyCompany()

  const periods = useWatch({
    control,
    name: 'periods',
  })

  const canEditPayroll = useCan(['payroll.edit', 'payroll.closePayroll'])

  const columns: SmartColumnType[] = useMemo(() => {
    const daysBetweenDates =
      dayjs(periods[1])
        .endOf('day')
        .diff(dayjs(periods[0]).startOf('day'), 'day') + 1
    const daysHeaders = Array.from({ length: daysBetweenDates }, (_, index) => {
      return dayjs(periods[0]).add(index, 'day').date().toString()
    })

    const paymentConfigOptions = company?.paymentConfigGroups.map((config) => ({
      label: config.name,
      value: config.id,
    }))
    const punchConfigOptions = company?.punchConfigGroups.map((config) => ({
      label: config.name,
      value: config.id,
    }))

    return [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Configuraciones',
        name: 'Configuraciones',
        items: [
          {
            type: 'dropdown',
            width: 150,
            name: 'punchConfigId',
            header: 'Config. Puntos',
            options: punchConfigOptions,
            handleSubmitValue: async (props: SubmitValueProps) => null,
          },
          {
            type: 'dropdown',
            width: 150,
            name: 'paymentConfigId',
            header: 'Config. Pagos',
            options: paymentConfigOptions,
            handleSubmitValue: async (props: SubmitValueProps) => null,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Calendário',
        name: 'Calendário',
        items: [
          {
            type: 'tags',
            name: 'daysSummary',
            array: true,
            length: daysBetweenDates,
            onTagClick: (
              index: number,
              name: string,
              previousValue: string,
            ) => {
              const data = getValues()
              if (data.status === 'closed' || !canEditPayroll) return

              const userId = data.data[index].userId
              handleOpenDrawer('DAY_DETAIL', {
                userId,
                date: name,
              })
            },
            headers: daysHeaders,
            header: 'Calendário',
            width: 25,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Dias',
        name: 'Dias',
        items: [
          {
            type: 'input-text',
            name: 'naturalPaidDays',
            draggable: null,
            header: 'Natur. Pagos',
            disabled: true,
            inputType: 'no-mask',
            width: 140,
          },
          {
            type: 'input-text',
            name: 'naturalUnpaidDays',
            draggable: null,
            header: 'Natur. No Pagos',
            disabled: true,
            inputType: 'no-mask',
            width: 140,
          },
          {
            type: 'input-text',
            name: 'vacationDays',
            draggable: null,
            header: 'D. de Vacaciones',
            disabled: true,
            inputType: 'no-mask',
            width: 160,
          },
          {
            type: 'input-text',
            name: 'countablePaidDays',
            draggable: null,
            header: 'Contab. Pagos',
            disabled: true,
            inputType: 'no-mask',
            width: 140,
          },
          {
            type: 'input-text',
            name: 'countableUnpaidDays',
            draggable: null,
            header: 'Contab. No Pagos',
            disabled: true,
            inputType: 'no-mask',
            width: 160,
          },
        ],
      },
    ] as SmartColumnType[]
  }, [periods, canEditPayroll])

  return columns
}
