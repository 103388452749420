import {
  IRequestMovementCreation,
  IRequestPaymentCreation,
  IRequestVacationCreation,
} from '@/libs/react-query/types/requests/modules-dtos'
import { useMemo } from 'react'
import { RequestsInfosTableType } from '../../../form'

interface IUsersIdsInRequestParams {
  selectedRequest: RequestsInfosTableType['data'][0]
}

export const useRequestTitle = ({
  selectedRequest,
}: IUsersIdsInRequestParams) => {
  const requestTitle = useMemo(() => {
    if (selectedRequest.module.original === 'movement') {
      const requestData: IRequestMovementCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return requestData.title
    }

    if (selectedRequest.module.original === 'payments') {
      const requestData: IRequestPaymentCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return requestData.title
    }

    if (selectedRequest.module.original === 'payments') {
      const requestData: IRequestVacationCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return requestData.name
    }

    return ''
  }, [selectedRequest])

  return {
    requestTitle,
  }
}
