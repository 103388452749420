import { Div, FormRenderer, PageHeader } from '@/components'
import { useHandleWorkerImportActions } from './actions'
import { useImportWorkersForm } from './useImportForm'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ImportWorkersSchema, importWorkersSchema } from './form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { handleImportXlsx } from '@/utils/documents/files'
import {
  parseImportedDocumentToWorkers,
  parseImportedWorkersToTable,
} from './parser'
import { ImportWorkersTable } from './Table'

export const ImportWorkers = () => {
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm<ImportWorkersSchema>({
    resolver: zodResolver(importWorkersSchema),
  })

  const { data: form } = useImportWorkersForm()

  useHandleWorkerImportActions({ methods, isLoading })

  const uploadedFile = useWatch({
    control: methods.control,
    name: 'uploadedFile',
  })

  useEffect(() => {
    if (uploadedFile) {
      setIsLoading(true)
      handleImportXlsx(uploadedFile, (result: any) => {
        console.log('file imported')

        const importedWorkers = parseImportedDocumentToWorkers(result)
        const workersToImport = parseImportedWorkersToTable(importedWorkers)

        methods.reset({
          data: workersToImport,
          uploadedFile,
        })

        setIsLoading(false)
      })
    }
  }, [uploadedFile, methods])

  return (
    <FormProvider {...methods}>
      <Div css={{ padding: '$4', overflow: 'hidden' }}>
        <PageHeader
          title={'Importar Colaboradores'}
          subtitle={'Importe todos sus colaboradores por medio de planillas'}
        />

        <Div css={{ marginBottom: '$4' }} />

        <FormRenderer items={form} />

        <ImportWorkersTable />
      </Div>
    </FormProvider>
  )
}
