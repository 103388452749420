import { AddShiftFields } from '../../../forms'

export const useHoursAmountForm = () => {
  const data: AddShiftFields = [
    {
      _type: 'input',
      label: 'Cantidad de horas flexibles diurnas',
      name: 'config.flexible_morning_hours',
      placeholder: '00:00',
      inputType: 'hour',
    },
    {
      _type: 'input',
      label: 'Cantidad de horas flexibles mixtas',
      placeholder: '00:00',
      name: 'config.flexible_mixed_hours',
      inputType: 'hour',
    },
    {
      _type: 'input',
      label: 'Cantidad de horas flexibles nocturnas',
      placeholder: '00:00',
      name: 'config.flexible_night_hours',
      inputType: 'hour',
    },
  ]

  return {
    data,
  }
}
