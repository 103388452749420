import { Div } from '@/components'
import {
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline'
import { styled, Text } from '@punto-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  PdfBuilderContentDataSchemaType,
  PdfBuilderSchemaType,
} from '../../types'
import { extractVariablesFromText } from '../../pdf-builder'
import { useOutsideAlerter } from '@/hooks'
import { SketchPicker } from 'react-color'

const StyledTextArea = styled('textarea', {
  all: 'unset',
  fontFamily: '$default',
  fontSize: '12px',
  lineHeight: '18px',
  overflowY: 'hidden',
  resize: 'none', // Prevents manual resizing
})

export const TextContent = ({
  contentIndex,
  dataIndex,
  contentData,
}: {
  contentIndex: number
  dataIndex: number

  contentData: PdfBuilderContentDataSchemaType
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const methods = useFormContext<PdfBuilderSchemaType>()

  const [isOverTextArea, setIsOverTextArea] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [textAreaHeight, setTextAreaHeight] = useState(34)

  const {
    text,
    color,
    fontSize,
    isItalic,
    isBold,
    isUnderline,
    alignment,
    bordered,
  } = contentData

  useEffect(() => {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`
      setTextAreaHeight(textArea.scrollHeight)
    }
  }, [isFocused])

  const handleInput = (e: any) => {
    const textArea = e.target
    textArea.style.height = 'auto'
    textArea.style.height = `${textArea.scrollHeight}px`
    setTextAreaHeight(textArea.scrollHeight)
    methods.setValue(
      `pdf.content.${contentIndex}.data.${dataIndex}.text`,
      textArea.value,
    )
  }

  const fragmentedText = extractVariablesFromText(text || '')

  const contentRef = useRef<HTMLDivElement>(null)

  useOutsideAlerter(contentRef, () => {
    setIsFocused(false)
  })

  return (
    <Div
      ref={contentRef}
      css={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        height: '100%',
        position: 'relative',
      }}
      onMouseEnter={() => setIsOverTextArea(true)}
      onMouseLeave={() => setIsOverTextArea(false)}
      onClick={() => {
        setIsFocused(true)
      }}
    >
      {isFocused && (
        <StyledTextArea
          ref={textAreaRef}
          onInput={handleInput}
          placeholder=""
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={text}
          css={{
            flex: 1,
            color: color || '$interface_dark_deep',
            fontSize,
            fontStyle: isItalic ? 'italic' : 'normal',
            fontWeight: isBold ? 700 : 400,
            textDecoration: isUnderline ? 'underline' : 'none',
            textAlign: alignment,

            height: '100%',
            minHeight: textAreaHeight,
          }}
        />
      )}
      {!isFocused && (
        <Div
          placeholder=""
          onClick={() => {
            setIsFocused(true)
            setTimeout(() => {
              textAreaRef.current?.focus()
            }, 1)
          }}
          css={{
            cursor: 'pointer',
            flex: 1,
            width: '100%',
            height: '100%',
            minHeight: textAreaHeight,
            textAlign: alignment,

            overflow: 'hidden',

            span: {
              color,
              fontSize: fontSize || 12,
              fontStyle: isItalic ? 'italic' : 'normal',
              fontWeight: isBold ? 700 : 400,
              textDecoration: isUnderline ? 'underline' : 'none',
              // textAlign: alignment,

              fontFamily: '$default',
              lineHeight: '18px',

              whiteSpace: 'pre-wrap',
            },

            overflowY: 'hidden',
          }}
        >
          {fragmentedText.map((part, index) => {
            return (
              <span
                key={index}
                style={{
                  color: part.type === 'variable' ? 'blue' : undefined,
                }}
              >
                {`${part.text}`}
              </span>
            )
          })}
        </Div>
      )}

      <Div
        onMouseEnter={() => setIsOverTextArea(true)}
        onMouseLeave={() => setIsOverTextArea(false)}
        css={{
          paddingTop: '$2',
          position: 'absolute',
          zIndex: 3,
          top: 'calc(100%)',
          height: 50,
          // left: 0,
          // right: 0,
          opacity: isOverTextArea || isFocused ? 1 : 0,
          pointerEvents: isOverTextArea || isFocused ? 'all' : 'none',
          transition: 'opacity 0.2s',

          display: 'flex',
        }}
      >
        <Div
          css={{
            height: 34,
            boxShadow: '0px 0px 24px 0px #343A4029',
            background: '$interface_light_pure',

            // padding: '$4',

            borderRadius: '$md',

            paddingLeft: '$2',
            paddingRight: '$2',
            display: 'flex',
            gap: '$3',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <ColorSelector
            contentIndex={contentIndex}
            dataIndex={dataIndex}
            contentData={contentData}
          />

          <Div
            css={{
              height: 16,
              width: 1,
              marginLeft: '$1',
              marginRight: '$1',
              background: '$interface_light_deep',
            }}
          />
          <Text
            css={{
              fontFamily: '$default',
              fontSize: '12px',
              fontWeight: 700,
              cursor: 'pointer',
              padding: '$1',
              color: isBold ? '$brand_primary_pure' : '$interface_dark_down',
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.isBold`,
                !isBold,
              )
            }}
          >
            B
          </Text>
          <Text
            css={{
              fontFamily: '$default',
              fontSize: '12px',
              fontStyle: 'italic',
              padding: '$1',
              cursor: 'pointer',
              color: isItalic ? '$brand_primary_pure' : '$interface_dark_down',
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.isItalic`,
                !isItalic,
              )
            }}
          >
            I
          </Text>
          <Text
            css={{
              fontFamily: '$default',
              fontSize: '12px',
              textDecoration: 'underline',
              padding: '$1',
              cursor: 'pointer',
              color: isUnderline
                ? '$brand_primary_pure'
                : '$interface_dark_down',
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.isUnderline`,
                !isUnderline,
              )
            }}
          >
            U
          </Text>
          <Div
            css={{
              height: 16,
              width: 1,
              background: '$interface_light_deep',
              marginLeft: '$1',
              marginRight: '$1',
            }}
          />
          <Div
            css={{
              '> svg': {
                height: 16,
                width: 16,
                color:
                  alignment === 'left'
                    ? '$brand_primary_pure'
                    : '$interface_dark_down',
              },
              cursor: 'pointer',
              padding: '$1',
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.alignment`,
                'left',
              )
            }}
          >
            <Bars3BottomLeftIcon />
          </Div>
          <Div
            css={{
              '> svg': {
                color:
                  alignment === 'center'
                    ? '$brand_primary_pure'
                    : '$interface_dark_down',
                height: 16,
                width: 16,
              },
              cursor: 'pointer',
              padding: '$1',
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.alignment`,
                'center',
              )
            }}
          >
            <Bars3Icon />
          </Div>
          <Div
            css={{
              '> svg': {
                height: 16,
                width: 16,
                color:
                  alignment === 'right'
                    ? '$brand_primary_pure'
                    : '$interface_dark_down',
              },
              cursor: 'pointer',
              padding: '$1',
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.alignment`,
                'right',
              )
            }}
          >
            <Bars3BottomRightIcon />
          </Div>
          <Div
            css={{
              height: 16,
              width: 1,
              background: '$interface_light_deep',
              marginLeft: '$1',
              marginRight: '$1',
            }}
          />
          <Div
            css={{
              padding: '$1',

              display: 'flex',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            <Text
              css={{
                minWidth: 16,
              }}
              variant={'caption'}
            >
              {(fontSize || 12) - 3}
            </Text>
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                flexDirection: 'column',
                gap: '$1',

                svg: {
                  cursor: 'pointer',
                  height: 12,
                  width: 12,
                },

                div: {
                  cursor: 'pointer',
                  height: 14,
                  width: 14,
                },
              }}
            >
              <Div
                onClick={() => {
                  methods.setValue(
                    `pdf.content.${contentIndex}.data.${dataIndex}.fontSize`,
                    fontSize ? fontSize + 1 : 13,
                  )
                }}
              >
                <ChevronUpIcon />
              </Div>
              <Div
                onClick={() => {
                  methods.setValue(
                    `pdf.content.${contentIndex}.data.${dataIndex}.fontSize`,
                    fontSize ? fontSize - 1 : 11,
                  )
                }}
              >
                <ChevronDownIcon />
              </Div>
            </Div>
          </Div>
          <Div
            css={{
              height: 16,
              width: 1,
              background: '$interface_light_deep',
              marginLeft: '$1',
              marginRight: '$1',
            }}
          />
          <Div
            css={{
              cursor: 'pointer',
              minWidth: 70,
            }}
            onClick={() => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.bordered`,
                !bordered,
              )
            }}
          >
            <Text
              variant={'caption'}
              css={{
                color: bordered
                  ? '$brand_primary_pure'
                  : '$interface_dark_down',
              }}
            >
              {bordered ? 'Con borda' : 'Sin borda'}
            </Text>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export const ColorSelector = ({
  contentIndex,
  dataIndex,
  contentData,
}: {
  contentIndex: number
  dataIndex: number
  contentData: PdfBuilderContentDataSchemaType
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const colorPickerRef = useRef<HTMLDivElement>(null)
  const methods = useFormContext<PdfBuilderSchemaType>()

  useOutsideAlerter(colorPickerRef, () => {
    setIsOpen(false)
  })

  return (
    <>
      <Div
        onClick={() => setIsOpen(!isOpen)}
        css={{
          minHeight: 10,
          minWidth: 10,
          background: contentData.color || '$interface_dark_up',
          borderRadius: '$full',
        }}
      />

      {isOpen && (
        <Div
          ref={colorPickerRef}
          css={{
            position: 'absolute',
            zIndex: 99999999,
            top: 'calc(100%)',
            left: 0,
          }}
        >
          <SketchPicker
            color={contentData.color || 'black'}
            onChange={(color) => {
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.color`,
                color.hex,
              )
            }}
          />
        </Div>
      )}
    </>
  )
}
