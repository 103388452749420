import { ControlledInput, Div, FormRenderer } from '@/components'
import { FormContainer } from '..'
import { Switch, Text } from '@punto-ui/react'
import { useAguinaldoPayrolls } from '../form/useAguinaldoPayrolls'
import { useAguinaldo } from '../form/useAguinaldo'
import { useState } from 'react'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'

interface IAguinaldoLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const AguinaldoLiquidation = ({
  isOpen,
  toggleOpen,
}: IAguinaldoLiquidationParams) => {
  const conceptKey = 'proportional_aguinaldo'

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [aguinaldoValue] = useWatch({
    control,
    name: ['data.aguinaldo', 'conceptsStatus'],
  })

  const [isPayrollSelection, setIsPayrollSelection] = useState(false)

  const { form: aguinaldoPayrollsForm } = useAguinaldoPayrolls()
  const { months: aguinaldoMonthsForm, total: aguinaldoTotalForm } =
    useAguinaldo()

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Aguinaldo'}
        total={`Valor en haber: ${formatCurrency(+aguinaldoValue)}`}
        conceptType="basic"
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
        isIpsDisabled={!concept.ips}
        otherButtons={
          <Div css={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              css={{ marginRight: '$4' }}
              checked={isPayrollSelection}
              onCheckedChange={(v) => setIsPayrollSelection(v)}
            />
            {isPayrollSelection ? (
              <Text css={{ color: '$interface_dark_deep' }}>
                Selecionar Valores
              </Text>
            ) : (
              <Text css={{ color: '$interface_dark_deep' }}>
                Selecionar Planillas
              </Text>
            )}
          </Div>
        }
      />
      {!isConceptClosed && (
        <>
          {!isPayrollSelection ? (
            <>
              <FormRenderer inline items={aguinaldoMonthsForm} cols={4} />
            </>
          ) : (
            <FormRenderer inline items={aguinaldoPayrollsForm} cols={4} />
          )}
          <Div
            css={{
              display: 'flex',
              // alignItems: 'center',
              // marginTop: '$6',
              // justifyContent: 'center',
            }}
          >
            <ControlledInput
              {...aguinaldoTotalForm}
              disabled={aguinaldoTotalForm.disabled || isPayrollSelection}
            />
          </Div>
        </>
      )}
    </FormContainer>
  )
}
