import { z } from 'zod'

export const vacationForm = z.object({
  create: z.object({
    id: z.string().optional(),
    name: z.string({
      required_error: 'Titulo requerido',
    }),
    description: z.string().optional(),
    should_pay_in_payroll: z.boolean().optional(),
    dates: z.date().array().nonempty({
      message: 'Debe seleccionar al menos un día',
    }),
    period: z.string(),
    communication_date: z
      .date({
        required_error: 'Fecha de comunicación requerida',
      })
      .array(),
    reintegration_date: z
      .date({
        required_error: 'Fecha de reintegro requerida',
      })
      .array(),
    payment_date: z
      .date({
        required_error: 'Fecha de pago requerida',
      })
      .array(),
    users_ids: z.array(z.string()),
  }),
})

export type VacationFormType = z.infer<typeof vacationForm>

export const defaultCreateVacationValues = {
  description: '',
  should_pay_in_payroll: false,
  dates: [],
  period: '',
  communication_date: [],
  reintegration_date: [],
  payment_date: [],
  users_ids: [],
}
