import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useWorkerCreation } from '../context/hooks'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const usePasswordData = () => {
  const { isCreation } = useWorkerCreation()
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const datosObrigatoriosForm: AddWorkerFields = useMemo(() => {
    const formFields: AddWorkerFields = []

    // if (isCreation) {
    formFields.push(
      {
        _type: 'input',
        label: 'Contraseña',
        disabled: !isWorkerCreationAvailable,
        placeholder: '********',
        type: 'password',
        name: 'password',
      },
      {
        _type: 'input',
        label: 'Confirmar Contraseña',
        placeholder: '********',
        disabled: !isWorkerCreationAvailable,
        type: 'password',
        name: 'confirm_password',
      },
    )
    // }

    return formFields
  }, [isCreation, isWorkerCreationAvailable])

  return {
    data: datosObrigatoriosForm,
  }
}
