import {
  SmartColumnType,
  SmartTableFormDataSchemaType,
  SmartTableFormItemValueType,
} from '@/components/SmartTable/types'
import { ReactNode, useMemo, useRef, useState } from 'react'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'
import { Column, Table as ReactTable, Row } from '@tanstack/react-table'
import { InputText } from './InputText'
import { InputCheckbox } from './InputCheckbox'
import { InputDropdown } from './InputDropdown'
import { InputTag } from './InputTag'
import { Div } from '@/components/Div'
import { useOutsideAlerter } from '@/hooks'
import Draggable from 'react-draggable'
import { InputAvatar } from './InputAvatar'
import { InputCustom } from './InputCustom'
import ReactDOM from 'react-dom'
import { InputDraggableTag } from './InputDraggableTag'
import { InputExpandable } from './InputExpandable'
import { keyframes } from '@punto-ui/react'

type EditingCellProps = {
  row: Row<SmartTableFormDataSchemaType>
  column: Column<SmartTableFormDataSchemaType>
  columnRef?: SmartColumnType
  table: ReactTable<SmartTableFormDataSchemaType>
  subRowName?: string
}

export const ROW_HEIGHT = 32

// Give our default column cell renderer editing superpowers!
export const EditingCell = ({
  row,
  column,
  columnRef,
  subRowName,
}: EditingCellProps) => {
  // when field is subelement, we need to get:
  // `data.${row.index}.${subelementFieldName}.${internal_index}`

  const fieldName = useMemo(() => {
    const parentRow = row.getParentRow()
    const isSubElement = row.depth !== 0 && parentRow

    const baseFieldName = isSubElement
      ? `data.${parentRow.index}.${subRowName}.${row.index}.${column.id}`
      : (`data.${row.index}.${column.id}` as unknown as FieldPath<SmartTableFormDataSchemaType>)

    // if (isSubElement && column.id === 'name') {
    //   console.log('parentRow', baseFieldName, parentRow, row)
    // }

    return baseFieldName
  }, [column.id, row])

  const fieldValueName = useMemo(() => {
    const nonValueTypes = ['avatar', 'tags', 'draggable-tags']

    if (nonValueTypes.includes(columnRef?.type || '')) {
      return fieldName as unknown as FieldPath<SmartTableFormDataSchemaType>
    }
    return `${fieldName}.value` as unknown as FieldPath<SmartTableFormDataSchemaType>
  }, [columnRef?.type, fieldName])

  const statusName = useMemo(() => {
    const name =
      `${fieldName}.status` as unknown as FieldPath<SmartTableFormDataSchemaType>

    return name
  }, [fieldName])

  if (!columnRef) {
    return null
  }

  if (columnRef.type === 'input-text') {
    return (
      <EditingCellForm
        column={columnRef}
        fieldName={fieldName}
        index={row.index}
        columnId={column.id}
      >
        <InputText
          fieldName={fieldName}
          column={columnRef}
          fieldValueName={fieldValueName}
          statusName={statusName}
          index={row.index}
          columnId={column.id}
          subIndex={column.columnDef.meta?.subarrayIndex}
          row={row}
        />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'custom') {
    return (
      <EditingCellForm
        column={columnRef}
        index={row.index}
        fieldName={fieldName}
        columnId={column.id}
      >
        <InputCustom index={row.index} column={columnRef} />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'checkbox') {
    return (
      <EditingCellForm
        column={columnRef}
        index={row.index}
        columnId={column.id}
        fieldName={fieldName}
      >
        <InputCheckbox
          index={row.index}
          column={columnRef}
          fieldValueName={fieldValueName}
          fieldName={fieldName}
        />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'expandable') {
    return (
      <EditingCellForm
        column={columnRef}
        index={row.index}
        columnId={column.id}
        fieldName={fieldName}
      >
        <InputExpandable
          row={row}
          index={row.index}
          column={columnRef}
          fieldValueName={fieldValueName}
          fieldName={fieldName}
        />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'dropdown') {
    return (
      <EditingCellForm
        column={columnRef}
        fieldName={fieldName}
        index={row.index}
        columnId={column.id}
      >
        <InputDropdown
          options={columnRef.options || []}
          column={columnRef}
          fieldValueName={fieldValueName}
          fieldName={fieldName}
          statusName={statusName}
          index={row.index}
          subIndex={column.columnDef.meta?.subarrayIndex}
        />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'tags') {
    return (
      <EditingCellForm
        column={columnRef}
        index={row.index}
        columnId={column.id}
        fieldName={fieldName}
      >
        <InputTag
          rowIndex={row.index}
          column={columnRef}
          callback={columnRef.onTagClick}
          fieldValueName={fieldValueName}
        />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'draggable-tags') {
    return (
      <EditingCellForm
        column={columnRef}
        index={row.index}
        columnId={column.id}
        fieldName={fieldName}
      >
        <InputDraggableTag
          rowIndex={row.index}
          column={columnRef}
          callback={columnRef.onTagClick}
          fieldValueName={fieldValueName}
        />
      </EditingCellForm>
    )
  }

  if (columnRef.type === 'avatar') {
    return (
      <EditingCellForm
        column={columnRef}
        fieldName={fieldName}
        index={row.index}
        columnId={column.id}
      >
        <InputAvatar column={columnRef} fieldValueName={fieldValueName} />
      </EditingCellForm>
    )
  }

  return null
}

export const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.96)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

const EditingCellForm = ({
  children,
  column,
  columnId,
  fieldName,
  index,
}: {
  children: ReactNode
  column: SmartColumnType
  columnId: string
  fieldName: string
  index: number
}) => {
  const { control } = useFormContext()

  const field: SmartTableFormItemValueType = useWatch({
    control,
    name: fieldName,
  })

  const addColumnPopoverReference = useRef(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  useOutsideAlerter(addColumnPopoverReference, () => {
    setIsPopoverOpen(false)
  })

  const arrayIndex = columnId.split('.')[1]

  return (
    <Div
      onClick={(e) => {
        e.stopPropagation()
      }}
      onContextMenu={(e) => {
        e.preventDefault()

        if (column.draggable) {
          setIsPopoverOpen(true)
        }
      }}
      css={{
        display: 'flex',
        alignItems: 'center',
        height: `${ROW_HEIGHT} !important`,
        width: column.width ? column.width : undefined,
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>{children}</form>

      {isPopoverOpen && !field?.metadata?.draggableDisabled && (
        <>
          {ReactDOM.createPortal(
            <Draggable handle=".handle" bounds={'parent'}>
              <Div
                ref={addColumnPopoverReference}
                css={{
                  animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
                  position: 'absolute',
                  right: 8,
                  bottom: 8,
                  zIndex: 999,
                }}
              >
                <column.draggable
                  index={index}
                  fieldName={column.name}
                  isArray={column.array}
                  arrayIndex={arrayIndex}
                />
              </Div>
            </Draggable>,
            document.body,
          )}
        </>
      )}
    </Div>
  )
}
