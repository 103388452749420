import { IRouteAction, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import {
  // BanknotesIcon,
  CurrencyDollarIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import {
  ContextProvider as PaymentsProvider,
  usePaymentContext,
} from './context'

// import { useRouter } from 'next/router'
import { useCan } from '@/hooks'
// import { BatchList } from './components/BatchList'
import { PagosList } from './components'
import { useReportReceipts } from '@/libs/react-query/hooks/useReportReceipts'

export default function Pagos({ onlyTabs = false }: { onlyTabs?: boolean }) {
  return (
    <PaymentsProvider>
      <PagosContent onlyTabs={onlyTabs} />
    </PaymentsProvider>
  )
}

const PagosContent = ({ onlyTabs = false }: { onlyTabs?: boolean }) => {
  const canCreate = useCan(['payments.create', 'payments.all'])
  const canRequest = useCan(['payments.request'], true)
  const { handleOpenPopover } = usePaymentContext()

  useReportReceipts({
    page: 0,
    perPage: 50,
    types: ['payment'],
  })

  // const router = useRouter()

  const pageActions: IRouteAction[] = []
  if (canCreate || canRequest) {
    pageActions.push({
      label: canRequest ? 'Solicitar pago' : 'Crear pago',
      width: canRequest ? 150 : 120,
      variant: 'primary' as const,
      icon: <PlusIcon />,
      disabled: false,
      action: () => handleOpenPopover('add'),
    })

    // pageActions.push({
    //   label: 'Crear Lote',
    //   variant: 'secondary' as const,
    //   icon: <PlusIcon />,
    //   disabled: true,
    //   callback: () => router.push('/pagos/create-batch'),
    // })
  }

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="list"
      actions={pageActions}
      tabs={[
        {
          icon: <CurrencyDollarIcon />,
          label: 'Pagos',
          value: 'list',
          component: (
            <>
              <PagosList />
            </>
          ),
        },
        // {
        //   icon: <BanknotesIcon />,
        //   label: 'Lotes',
        //   value: 'batches',
        //   component: (
        //     <>
        //       <BatchList />
        //     </>
        //   ),
        // },
      ]}
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
