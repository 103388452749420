import React from 'react'
import { useInputDatePicker } from '.'
import { styled } from '../../styles'
import { Button } from '@punto-ui/react'

const ActionsContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  justifyContent: 'flex-end',
  padding: '$2',
})

interface ActionsProps {
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const Actions = ({ setIsCalendarOpen }: ActionsProps) => {
  const { onChange } = useInputDatePicker()

  const handleCancel = () => {
    onChange([null, null])
    setIsCalendarOpen(false)
  }

  return (
    <ActionsContainer>
      <Button variant="tertiary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="tertiary" onClick={() => setIsCalendarOpen(false)}>
        Ok
      </Button>
    </ActionsContainer>
  )
}
