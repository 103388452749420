import { Div } from '@/components'
import { ReportStepper } from './components/ReportStepper'
import { ReportBuilderForm } from './components/ReportBuilderForm'
import { ReportBuilderPreview } from './components/ReportBuilderPreview'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
  ReportBuilderSchema,
  ReportBuilderSchemaType,
} from './type'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect } from 'react'
import {
  fromReportTypeAndRowTypesToColumns,
  fromReportTypeToRowsV2,
} from './report-builder'
import { useHandleReportBuilderActions } from './actions'
import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { reportBuilderFilterTypes } from './constants/report-builder-filter-types'
import { IReportBuilderColumnFilterTypes } from './filter-types'

export const ReportBuilder = (props: { id?: string }) => {
  const { data: company } = useMyCompany()

  const methods = useForm<ReportBuilderSchemaType>({
    resolver: zodResolver(ReportBuilderSchema),
    defaultValues: {
      configurations: {
        name: '',
        description: '',
        isFavorite: false,
        reportType: '',
      },
      columns: {},
      rows: [],
      rowsOptions: [],
      filters: [],
      selectedRow: '',
      selectedRowDetails: '',
      currentStep: 0,
      loadedTemplate: !props.id,
      id: props.id || '',
    },
  })

  useHandleReportBuilderActions({
    methods,
  })

  const [reportType, selectedRow, selectedRowDetails, selectedRowDetailsArray] =
    useWatch({
      control: methods.control,
      name: [
        'configurations.reportType',
        'selectedRow',
        'selectedRowDetails',
        'selectedRowDetailsArray',
      ],
    })

  const { data: template } = useReportTemplate(props.id || '')

  const isFirstRender = React.useRef(true)

  useEffect(() => {
    if (!template) return

    methods.setValue('identifier_id', template.identifier_id)
    methods.setValue('id', template.id)
    methods.setValue('configurations', {
      name: template.name,
      description: template.description,
      isFavorite: template.favorite,
      reportType: template.type,
    })

    methods.setValue('selectedRowDetails', template.rowDetails)
    methods.setValue('selectedRow', template.row)
    methods.setValue('loadedTemplate', true)
    methods.setValue(
      'filters',
      template.filters.map((f) => {
        const filtersOperators =
          reportBuilderFilterTypes[
            (f.valueType as IReportBuilderColumnFilterTypes) || 'string'
          ]
        const operationsOptions = filtersOperators.operations.map((f) => ({
          label: f.label,
          value: f.value,
          requiredInput: f.requiredInput,
        }))

        const selectedOperationHasValue = !!operationsOptions.find(
          (c) => c.value === f.condition,
        )?.requiredInput

        return {
          id: (Date.now() + Math.random() * 100).toString(),
          name: `${f.type}.${f.name}`,
          type: f.type,
          valueType: f.valueType,
          condition: f.condition,

          hasValue: selectedOperationHasValue !== null,

          value: f.value ? f.value : undefined,
          date: f.date ? f.date : undefined,

          options: operationsOptions,
        }
      }),
    )
  }, [template, methods])

  useEffect(() => {
    if (isFirstRender.current && props.id) {
      isFirstRender.current = false
      return
    }

    const rowsOptions = fromReportTypeToRowsV2(
      reportType as IReportBuilderTypes,
    )

    const currentRowsOptions = methods.getValues('rowsOptions')

    methods.setValue('rowsOptions', rowsOptions)

    if (currentRowsOptions.length) {
      methods.setValue('selectedRow', '')
      methods.setValue('selectedRowDetails', '')
    }
  }, [props.id, reportType, methods])

  useEffect(() => {
    if (!selectedRow || (props.id && !template)) {
      return
    }

    const row = {
      detailSelectedKeys: selectedRowDetailsArray,
      detailKey: selectedRowDetails,
      type: selectedRow as IReportBuilderRowTypes,
    }

    const newColumns = fromReportTypeAndRowTypesToColumns(
      reportType as IReportBuilderTypes,
      [row],
      company?.concepts || [],
      template?.columns || [],
    )
    const allColumnsKeys = Object.keys(newColumns).reduce((acc, key) => {
      return {
        ...acc,
        [key]: true,
      }
    }, {})
    methods.setValue('columns', newColumns)
    methods.setValue('openColumns', allColumnsKeys)
  }, [
    selectedRow,
    selectedRowDetails,
    selectedRowDetailsArray,
    reportType,
    methods,
    template,
    props.id,
    company?.concepts,
  ])

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          margin: 20,
          background: '$interface_light_pure',
          borderRadius: '$lg',
          border: '1px solid $interface_light_down',
        }}
      >
        <ReportStepper />
        <Div
          css={{
            display: 'flex',

            height: 'calc(100vh - 54px - 20px - 60px - 24px)',
          }}
        >
          <ReportBuilderForm />
          <ReportBuilderPreview />
        </Div>
      </Div>
    </FormProvider>
  )
}
