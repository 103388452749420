import { apiV2 } from '@/services/api'
import { UseMutationOptions, useMutation } from 'react-query'
import { queryClient } from '@/libs/react-query'

const handleDeletePolicy = async (id: string) => {
  await apiV2.delete<void>('/permission/delete-policy?policyId=' + id)
}

export const useHandleDeletePolicy = (config: UseMutationOptions) => {
  const mutation = useMutation(['useHandleDeletePolicy'], handleDeletePolicy, {
    ...config,
    onSuccess: (data: any, variables: any, context: any) => {
      queryClient.invalidateQueries()
      config?.onSuccess?.(data, variables, context)
    },
  } as any)

  return mutation
}
