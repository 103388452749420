import { FaceIdIcon } from '@/assets/icons/FaceIdIcon'
import { Div, Touchable } from '@/components'
import { LIVENESS_THRESHOLD } from '@/pages/marcaciones/parser'
import { getFormattedTimezone } from '@/utils/date/timezone'
import {
  ClockIcon,
  DevicePhoneMobileIcon,
  GlobeAmericasIcon,
  InformationCircleIcon,
  MapIcon,
  MapPinIcon,
  NoSymbolIcon,
  PhotoIcon,
  PlusCircleIcon,
  ShieldCheckIcon,
  WifiIcon,
} from '@heroicons/react/24/outline'
import { Text } from '@punto-ui/react'
import dayjs from 'dayjs'
import React from 'react'

interface DetailsProps {
  date: string
  precision: number
  createdAt: string | null
  url: string
  tags: string[]
  timezone?: string
  hasFaceId: boolean

  totemName?: string

  distance?: number
  minAllowedDistance?: number
  isValidByDistance?: boolean

  livenessPrecision?: number
  isLiveness?: boolean | null
}

const PUNCH_TAG_TO_LABEL: Record<string, string> = {
  AUTOMATIC_CREATION_CONFIRMED: 'Creación automática confirmada',
  WITHOUT_LOCATION: 'Sin ubicación',
}

export const DetailsComponent = (props: DetailsProps) => {
  const isOffline = props.tags.some((tag) =>
    tag.toLowerCase().includes('offline'),
  )
  const nonOfflineTags = props.tags.filter(
    (tag) => !tag.toLowerCase().includes('offline'),
  )

  const data: Array<{
    icon: React.ReactNode
    label: string
    type?: 'warning' | 'neutral' | 'danger'
    onClick?: () => void
  }> = [
    {
      icon: <ClockIcon />,
      label: `Marcación a las ${dayjs(props.date).format(
        'HH:mm:ss',
      )} del ${dayjs(props.date).format('DD/MM/YYYY')}.`,
    },
    // {
    //   icon: <ShieldCheckIcon />,
    //   label: '96% precisión liveness. ',
    // },
    // {
    //   icon: <MapPinIcon />,
    //   label: 'Marcado en Av. Gral Aquino 2091.',
    // },
    {
      icon: <GlobeAmericasIcon />,
      label: props.timezone
        ? getFormattedTimezone(props.timezone)
        : 'Huso horario GMT-4 , Assunción, Paraguay',
    },
  ]

  if (props.hasFaceId) {
    data.push({
      icon: <FaceIdIcon />,
      label: `Marcación por face ID con ${props.precision.toFixed(
        2,
      )} % de precisión.`,
    })
  } else if (!props.createdAt) {
    data.push({
      icon: <MapIcon />,
      label: `Marcación con ${props.precision.toFixed(2)} metros de precisión.`,
    })
  }

  if (!props.createdAt && !props.totemName) {
    data.push({
      icon: <DevicePhoneMobileIcon />,
      label: 'Marcado por app PuntoOK',
    })
  } else if (props.totemName) {
    data.push({
      icon: <DevicePhoneMobileIcon />,
      label: `Marcado en totem: ${props.totemName}`,
    })
  }

  if (props.url) {
    data.push({
      icon: <PhotoIcon />,
      label: 'Generado 1 documento de foto.',
      onClick: () => {
        window.open(props.url, '_blank')
      },
    })
  }

  if (props.createdAt) {
    data.push({
      icon: <PlusCircleIcon />,
      label: `Marcación creada a las ${dayjs(props.createdAt).format(
        'HH:mm:ss',
      )} del ${dayjs(props.createdAt).format('DD/MM/YYYY')}.`,
    })
  }

  if (isOffline) {
    data.push({
      icon: <WifiIcon />,
      label: `Marcación offline.`,
    })
  }

  nonOfflineTags.forEach((tag) => {
    data.push({
      icon: <InformationCircleIcon />,
      label: PUNCH_TAG_TO_LABEL[tag] || tag,
    })
  })

  if (
    props.minAllowedDistance !== undefined &&
    !Number.isNaN(props.minAllowedDistance) &&
    props.minAllowedDistance
  ) {
    data.unshift({
      icon: <MapPinIcon />,
      label: `Máxima distancia permitida de ${props.minAllowedDistance?.toFixed(
        0,
      )} metros.`,
      type: 'neutral',
    })
  }

  if (
    props.distance !== undefined &&
    !Number.isNaN(props.distance) &&
    props.distance
  ) {
    data.unshift({
      icon: <NoSymbolIcon />,
      label: `Marcación ${
        props.isValidByDistance ? 'válida' : 'inválida'
      } por distancia de ${props.distance
        ?.toFixed(1)
        .replace('.', ',')} metros.`,
      type: props.isValidByDistance ? 'neutral' : 'danger',
    })
  }

  if (
    props.isLiveness !== null &&
    props.isLiveness !== undefined &&
    props.livenessPrecision
  ) {
    const livenessPrecision =
      Math.round(
        (Number.isNaN(+props.livenessPrecision)
          ? 0
          : +props.livenessPrecision) * 10000,
      ) / 100

    if (props.isLiveness) {
      data.unshift({
        icon: <ShieldCheckIcon />,
        label: `${livenessPrecision}% precisión liveness.`,
        type:
          livenessPrecision / 100 < LIVENESS_THRESHOLD ? 'warning' : 'neutral',
      })
    } else {
      data.unshift({
        type: 'danger',
        icon: <ShieldCheckIcon />,
        label: `Liveness no detectado con ${
          Math.round(
            (Number.isNaN(+props.livenessPrecision)
              ? 0
              : +props.livenessPrecision) * 10000,
          ) / 100
        } % de precisión.`,
      })
    }
  }

  return (
    <Div css={{ padding: '$2', height: 400, width: 400 }}>
      {data.map((item) => (
        <>
          {item.onClick ? (
            <Touchable onClick={item.onClick}>
              <Div
                key={item.label}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '$2',
                  color: '$interface_dark_down',
                  svg: {
                    height: 20,
                    width: 20,
                    marginRight: '$2',
                  },
                }}
              >
                {item.icon}
                <Text
                  variant={'description'}
                  css={{
                    color:
                      item.type === 'danger'
                        ? '$status_danger_deep'
                        : item.type === 'warning'
                        ? '$status_warning_deep'
                        : '$interface_dark_down',
                  }}
                >
                  {item.label}
                </Text>
              </Div>
            </Touchable>
          ) : (
            <Div
              key={item.label}
              css={{
                display: 'flex',
                alignItems: 'center',
                padding: '$2',
                color:
                  item.type === 'danger'
                    ? '$status_danger_deep'
                    : item.type === 'warning'
                    ? '$status_warning_deep'
                    : '$interface_dark_down',
                svg: {
                  height: 20,
                  width: 20,
                  marginRight: '$2',
                },
              }}
            >
              {item.icon}
              <Text
                variant={'description'}
                css={{
                  color:
                    item.type === 'danger'
                      ? '$status_danger_deep'
                      : item.type === 'warning'
                      ? '$status_warning_deep'
                      : '$interface_dark_down',
                }}
              >
                {item.label}
              </Text>
            </Div>
          )}
        </>
      ))}
    </Div>
  )
}
