import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { Div } from '@/components'
import { Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'

export const useReasons = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const form = useMemo(() => {
    const reasonForm: InactiveWorkerField[] = [
      // {
      //   _type: 'dropdown',
      //   name: 'data.inactivation_type',
      //   label: 'Tipo de liquidación',
      //   options: [
      //     {
      //       label: 'Despido Injustificado',
      //       value: 'unjustified_dismissal',
      //     },
      //   ],
      //   defaultOption: {
      //     label: 'Despido Injustificado',
      //     value: 'unjustified_dismissal',
      //   },
      // },
      {
        _type: 'dropdown',
        name: 'data.contract_type',
        label: 'Tipo de contracto',
        options: [
          {
            label: 'Tiempo Indefinido',
            value: 'indefinite',
          },
        ],
        defaultOption: {
          label: 'Tiempo Indefinido',
          value: 'indefinite',
        },
      },
      {
        _type: 'calendar',
        name: 'data.inactivation_date',
        disabled: isLoadingLiquidation,
        label: 'Fecha desvinculación',
        calendarType: 'single',
        maxDates: 999,
        disclaimer: 'Antiguedad: 0 años, 0 meses y 0 días',
      },
    ]

    return reasonForm
  }, [isLoadingLiquidation])

  return {
    reasons: form,
    complement: {
      _type: 'input',
      name: 'data.complement',
      label: 'Observaciones',
      disabled: isLoadingLiquidation,
    },
  }
}

export const useLiquidationDates = (antiquity: {
  years: number | string
  months: number | string
  days: number | string
}) => {
  const methods = useFormContext<InactivateWorkerSchema>()
  const [status] = useWatch({
    control: methods.control,
    name: ['data.status'],
  })

  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const form = useMemo(() => {
    const reasonForm: InactiveWorkerField[] = [
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'calendar',
            name: 'data.signature_date',
            label: 'Fecha de Firma',
            calendarType: 'single',
            maxDates: 999,
          },
          {
            _type: 'input',
            name: 'data.reason',
            label: 'Motivo',
            disabled: isLoadingLiquidation,
          },
        ],
      },
      {
        _type: 'calendar',
        name: 'data.entrance_date',
        label: 'Fecha de Ingresso',
        calendarType: 'single',
        disabled: status !== 'done' || isLoadingLiquidation,
        maxDates: 999,
      },
      {
        _type: 'calendar',
        name: 'data.entrance_date_ips',
        label: 'Fecha de Ingresso IPS',
        calendarType: 'single',
        disabled: status !== 'done' || isLoadingLiquidation,
        maxDates: 999,
      },
      {
        _type: 'custom',
        component: (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '$5',
              paddingLeft: '$4',
            }}
          >
            <Text
              variant={'description'}
            >{`${antiquity.years} años ${antiquity.months} meses ${antiquity.days} dias`}</Text>
          </Div>
        ),
      },
    ]

    return reasonForm
  }, [antiquity, isLoadingLiquidation])

  return {
    reasons: form,
    complement: {
      _type: 'input',
      name: 'data.complement',
      label: 'Observaciones',
      disabled: isLoadingLiquidation,
    },
  }
}
