import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IChangeFoldersSettings } from '@/libs/react-query/types/documents'

const handleChangeFolderSettings = async (data: IChangeFoldersSettings) => {
  try {
    await apiV2.post<void>(`/documents/change-folders-settings`, {
      ...data,
    })
    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}

export const useHandleChangeFolderSettings = () => {
  const mutation = useCustomMutation(
    ['useHandleChangeFolderSettings'],
    handleChangeFolderSettings,
  )

  return mutation
}
