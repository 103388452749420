import { useAuth } from '@/contexts/hooks'
import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IWorkerUser } from '../../types/worker'
import { destroyCookie } from 'nookies'
import * as Sentry from '@sentry/browser'

let configuredSentry = false

export const useMe = () => {
  const { isAuthenticated } = useAuth()

  const query = useQuery(
    ['me'],
    async () => {
      const response = await apiV2.get<
        IWorkerUser & { company_id: string; isAdmin: boolean }
      >('/user/me?platform=web')

      const data = response.data

      if (!configuredSentry) {
        // freshChatService.setEmail(data.email)
        // freshChatService.setFirstName(`${data.name}`)
        Sentry.setUser({ email: data.email, username: data.name })

        configuredSentry = true
      }

      return data
    },
    {
      enabled: isAuthenticated,
      onError: (err) => {
        console.log(err)
        destroyCookie(undefined, '@PuntoOk:token', {
          path: '/',
        })
        location.reload()
      },
    },
  )

  return query
}
