import { IRouteAction, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { ListBulletIcon, PlusIcon } from '@heroicons/react/24/outline'
import { ContextProvider as MovementsProvider } from './context'
import { MovesList } from './components'
import { useCan } from '@/hooks'
import { useMovementsContext } from './context/hooks/useMovementsContext'

export default function Moves({ onlyTabs = false }: { onlyTabs?: boolean }) {
  return (
    <MovementsProvider>
      <MovesContent onlyTabs={onlyTabs} />
    </MovementsProvider>
  )
}

const MovesContent = ({ onlyTabs = false }: { onlyTabs?: boolean }) => {
  const canCreate = useCan(['moves.create', 'moves.request'])
  const shouldRequest = useCan(['moves.request'], true)

  const { handleOpenMovementDrawer } = useMovementsContext()

  const pageActions: IRouteAction[] = []
  if (canCreate) {
    pageActions.push({
      label: shouldRequest ? 'Solicitar movimiento' : 'Crear movimiento',
      variant: 'primary' as const,
      icon: <PlusIcon />,
      disabled: false,
      width: shouldRequest ? 200 : 180,
      action: () => handleOpenMovementDrawer(),
    })
  }

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="list"
      actions={pageActions}
      tabs={[
        {
          icon: <ListBulletIcon />,
          label: 'Listado',
          value: 'list',
          component: <MovesList />,
        },
      ]}
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx: any) => {
  return {
    props: {},
  }
})
