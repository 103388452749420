import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdatePayrollConcept } from '@/libs/react-query/types/payroll'

dayjs.extend(utc)

const handleUpdatePayrollConcept = async (payload: IUpdatePayrollConcept) => {
  await apiV2.post<void>('/payroll/update-payroll-concept', payload)
  queryClient.invalidateQueries()
}

export const useHandleUpdatePayrollConcept = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdatePayrollConcept'],
    handleUpdatePayrollConcept,
  )

  return mutation
}
