import { api } from '@/services/api'
import { useMutation } from 'react-query'
import { RemoveCellPayload } from '../types'

export const useHandleRemoveCell = () => {
  const handleRemoveCell = async ({
    parentId,
    usersIds,
    cellId,
  }: RemoveCellPayload) => {
    const apiPayload = {
      usersIds,
      cellId,
      parentId,
    }

    await api.post<void>('/iam/delete-cell', apiPayload)
  }

  const mutation = useMutation(['useHandleRemoveCell'], handleRemoveCell)

  return mutation
}
