import { Div, FormGroup } from '@/components'
import { ISalaryGroup } from '@/libs/react-query/types/company'
import { useSalaryGroupForm } from '../form'

interface ISalaryFormProps {
  selectedSalaryGroup: ISalaryGroup | null
  isCreation: boolean
}

export const SalaryForm = ({
  selectedSalaryGroup,
  isCreation,
}: ISalaryFormProps) => {
  const { data: salaryGroupForm } = useSalaryGroupForm(isCreation)

  return (
    <Div
      css={{
        overflow: 'scroll',
        paddingBottom: 32,
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
      }}
    >
      <Div
        css={{
          maxWidth: 300,
        }}
      >
        <FormGroup
          type="fixed"
          title={
            isCreation
              ? 'Creacion de Grupo de Salario'
              : 'Actualizar dados del Grupo'
          }
          items={salaryGroupForm}
          cols={1}
        />
      </Div>
    </Div>
  )
}
