import { Div } from '@/components'
import { FormGroup } from '@/components/Forms/FormGroup'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ConfigSchema, configSchema } from '@/pages/configuraciones/components'
import {
  useGroupInfo,
  useHoursAuthorizations,
  useHoursParams,
  useIpsParams,
  useMonetaryInfo,
  usePayrollParams,
} from '@/pages/configuraciones/components/PaymentGroups/components/PaymentConfigDrawer/form'
import { formatMinutes } from '@/utils/date'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface PaymentConfigProps {
  id: string
}

export const PaymentConfig = ({ id }: PaymentConfigProps) => {
  const { data: company } = useMyCompany()

  const methods = useForm<ConfigSchema>({
    resolver: zodResolver(configSchema),
    defaultValues: {},
  })

  const { data: groupInfo } = useGroupInfo()
  const { data: moneyForm } = useMonetaryInfo()
  const { data: payrollParams } = usePayrollParams()
  const { data: hoursParams } = useHoursParams()
  const { data: ipsParams } = useIpsParams()
  const { data: hoursAuthorization } = useHoursAuthorizations()

  useEffect(() => {
    const paymentConfig = company?.paymentConfigGroups.find(
      (group) => group.id === id,
    )

    if (!paymentConfig) {
      return
    }

    methods.setValue('payment', {
      currency: paymentConfig.currency,
      name: paymentConfig.name,
      hours_morning_extra_value: paymentConfig.hours_morning_extra_value,
      hours_morning_holiday_value: paymentConfig.hours_morning_holiday_value,
      hours_morning_ordinary_value: paymentConfig.hours_morning_ordinary_value,
      hours_night_extra_value: paymentConfig.hours_night_extra_value,
      hours_night_holiday_value: paymentConfig.hours_night_holiday_value,
      hours_night_ordinary_value: paymentConfig.hours_night_ordinary_value,
      is_daily_payment: paymentConfig.is_daily_payment,
      is_subscribed_ips: paymentConfig.is_subscribed_ips,
      number_hours_per_day: formatMinutes(
        paymentConfig.number_hours_per_day * 60,
      ),
      should_consider_company_holidays:
        paymentConfig.should_consider_company_holidays,
      should_consider_presence_default:
        paymentConfig.should_consider_presence_default,
      should_include_morning_extra_in_ips:
        paymentConfig.should_include_morning_extra_in_ips,
      should_include_morning_holiday_in_ips:
        paymentConfig.should_include_morning_holiday_in_ips,
      should_include_morning_hours_discount_in_ips:
        paymentConfig.should_include_morning_hours_discount_in_ips,
      should_include_night_extra_in_ips:
        paymentConfig.should_include_night_extra_in_ips,
      should_include_night_holiday_in_ips:
        paymentConfig.should_include_night_holiday_in_ips,
      should_include_night_hours_discount_in_ips:
        paymentConfig.should_include_night_hours_discount_in_ips,
      should_include_night_ordinary_in_ips:
        paymentConfig.should_include_night_ordinary_in_ips,
      should_include_ordinary_salary_in_night_hours:
        paymentConfig.should_include_ordinary_salary_in_night_hours,
      should_pay_as_natural_days: paymentConfig.should_pay_as_natural_days,
      should_pay_children_bonus: paymentConfig.should_pay_children_bonus,
      should_include_children_bonus_in_ips:
        paymentConfig.should_include_children_bonus_in_ips,
      should_include_payments_in_ips:
        paymentConfig.should_include_payments_in_ips,
      should_consider_31_days_month_partially_natural:
        paymentConfig.should_consider_31_days_month_partially_natural,
      should_authorize_night_hours:
        !!paymentConfig.should_authorize_night_hours,
      should_authorize_extra_morning_hours:
        !!paymentConfig.should_authorize_extra_morning_hours,
      should_authorize_extra_night_hours:
        !!paymentConfig.should_authorize_extra_night_hours,
      should_authorize_holiday_morning_hours:
        !!paymentConfig.should_authorize_holiday_morning_hours,
      should_authorize_holiday_night_hours:
        !!paymentConfig.should_authorize_holiday_night_hours,
      should_authorize_morning_hours_discount:
        !!paymentConfig.should_authorize_morning_hours_discount,
      should_authorize_night_hours_discount:
        !!paymentConfig.should_authorize_night_hours_discount,
      should_pay_hourly: !!paymentConfig.should_pay_hourly,
    })
  }, [id, company, methods])

  const disabledForms = useMemo(() => {
    return {
      groupInfo: groupInfo.map((c) => ({
        ...c,
        disabled: true,
      })),
      moneyForm: moneyForm.map((c) => ({
        ...c,
        disabled: true,
      })),
      payrollParams: payrollParams.map((c) => ({
        ...c,
        disabled: true,
      })),
      hoursParams: hoursParams.map((c) => ({
        ...c,
        disabled: true,
      })),
      ipsParams: ipsParams.map((c) => ({
        ...c,
        disabled: true,
      })),
      authorizedHours: hoursAuthorization.map((c) => ({
        ...c,
        disabled: true,
      })),
    }
  }, [
    groupInfo,
    moneyForm,
    payrollParams,
    hoursParams,
    ipsParams,
    hoursAuthorization,
  ])

  const payment = methods.watch('payment')

  if (!payment) {
    return null
  }

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          marginTop: '$10',
          border: '2px solid',
          borderColor: '$interface_dark_up',
          padding: '$6',
          borderRadius: '$md',

          '> div + div': {
            marginTop: '$4',
          },
        }}
      >
        <FormGroup
          type="fixed"
          title="Informaciones del Grupo"
          items={disabledForms.groupInfo}
          cols={3}
        />
        <FormGroup
          type="fixed"
          title="Parametros para calculo"
          items={disabledForms.moneyForm}
          cols={3}
        />
        <FormGroup
          type="fixed"
          title="Parametros para salario"
          items={disabledForms.payrollParams}
          cols={3}
        />
        <FormGroup
          type="fixed"
          title="Parametros para calculo de horas"
          items={disabledForms.hoursParams}
          cols={3}
        />
        <FormGroup
          type="fixed"
          title="Parametros de IPS"
          items={disabledForms.ipsParams}
          cols={2}
        />
        <FormGroup
          type="fixed"
          title="Autorización de Horas"
          items={disabledForms.authorizedHours}
          cols={2}
        />
      </Div>
    </FormProvider>
  )
}
