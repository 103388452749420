import React, { useMemo } from 'react'
import {
  FieldValues,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { getDeepVal } from '@/utils/object'
import { ControlledSalaryConceptOptionsP } from './types'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ControlledDropdown } from '..'

export const ControlledSalaryConcept = <TFieldValues extends FieldValues>(
  props: ControlledSalaryConceptOptionsP<TFieldValues>,
) => {
  const { data: company } = useMyCompany()

  const {
    control,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useFormContext()

  const {
    field: { onChange, value },
  } = useController({
    name: props.name,
    control,
    defaultValue: getValues(props.name),
  })

  const dropdownValue = useWatch({
    control,
    name: props.name,
  }) as string

  const onValueChange = (s: string, index?: number) => {
    onChange(s)
    props.onValueChange?.(s, index)
  }

  const inputError = getDeepVal(errors, props.name)

  const conceptOptionsList = useMemo(() => {
    const companyConcepts =
      company?.concepts.map((c) => ({
        label: c,
        value: c,
      })) || []

    const creatingConceptExists = companyConcepts?.find(
      (c) => c.label.toLowerCase() === dropdownValue.toLowerCase(),
    )
    if (!creatingConceptExists && dropdownValue) {
      companyConcepts.unshift({
        label: dropdownValue.toUpperCase() || '',
        value: dropdownValue.toUpperCase() || '',
      })
    }

    return companyConcepts
  }, [company?.concepts, dropdownValue])

  return (
    <ControlledDropdown
      {...props}
      // transformValue={(v) => v.toUpperCase()}
      alwaysFilter
      onChangeValue={async (s) => {
        onValueChange(s.value)
        await trigger(props.name)
      }}
      errorMessage={inputError ? inputError.message : undefined}
      defaultValue={value}
      onChange={(e) => {
        setValue(props.name, e.target.value.toUpperCase() as any)
      }}
      name={props.name}
      options={conceptOptionsList}
      label="Tipo del concepto"
      placeholder="Seleccione un concepto..."
      defaultOption={{
        label: '',
        value: '',
      }}
    />
  )
}
