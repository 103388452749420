import { IDocumentStructure } from '@/libs/react-query/types/documents'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDocumentsStateSchema } from '../../documents-state'
import { Div } from '@/components'
import {
  ChevronDownIcon,
  FolderIcon,
  FolderOpenIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Checkbox, Progress, Switch, Tag, Text, Tooltip } from '@punto-ui/react'
import { OpenDrawerIcon } from '@/assets/icons/OpenDrawerIcon'
import { useDocumentsStructure } from '@/libs/react-query/hooks/useDocumentsStructure'
import { useHandleChangeFolderSettings } from '@/libs/react-query/mutations/documents/useHandleChangeFolderSettings'
import { flattenDocumentTree } from '../../documents-utils'

const MIN_COLUMNS_WIDTH = 300

export const FolderStructureComponent = () => {
  const { data: structure, isLoading: isLoadingDocumentsStructure } =
    useDocumentsStructure()
  const methods = useFormContext<IDocumentsStateSchema>()

  useEffect(() => {
    if (!structure) return

    const hiddenFolders = flattenDocumentTree(structure).reduce((acc, curr) => {
      acc[curr.id] = curr.is_hidden

      return acc
    }, {} as Record<string, boolean>)

    methods.setValue('structure.hidden', hiddenFolders)
  }, [structure])

  if (isLoadingDocumentsStructure) {
    return (
      <Div>
        <Progress />
      </Div>
    )
  }

  if (!structure) {
    return (
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '$2',
        }}
      >
        <Tag>Funcionalidad Beta</Tag>
        <Text variant={'caption'}>
          Habla con un administrador para entrar en el grupo de teste
        </Text>
      </Div>
    )
  }

  return (
    <Div
      css={{
        background: '$interface_light_pure',
        borderRadius: '$lg',

        maxHeight: 'calc(100vh - 120px)',
        overflow: 'scroll',

        position: 'relative',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          background: '$interface_light_pure',

          zIndex: 2,
          position: 'sticky',
          top: 0,

          height: 44,
        }}
      >
        <Div
          css={{
            flex: 1,
            minWidth: 1.2 * MIN_COLUMNS_WIDTH,
            paddingLeft: 36,
          }}
        >
          <Text variant="caption">Estrutura de Carpetas</Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'center',
            }}
          >
            Tipo de archivos
          </Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            css={{
              textAlign: 'center',
            }}
            variant="caption"
          >
            Ocultar
          </Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            css={{
              textAlign: 'right',
              paddingRight: 36,
            }}
            variant="caption"
          >
            Opciones
          </Text>
        </Div>
      </Div>
      {/* <Div> */}
      <FolderComponent structure={structure} index={1} />
      {/* </Div> */}
    </Div>
  )
}

const FolderComponent = ({
  structure,
  index,
}: {
  structure: IDocumentStructure
  index: number
}) => {
  const [isOpen, setIsOpen] = useState(!!structure.children.length)

  const methods = useFormContext<IDocumentsStateSchema>()
  const checked = useWatch({
    control: methods.control,
    name: `structure.checked.${structure.id}`,
  })

  const hidden = useWatch({
    control: methods.control,
    name: `structure.hidden.${structure.id}`,
  })

  const { mutateAsync: handleChangeFolderSettings } =
    useHandleChangeFolderSettings()

  return (
    <Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          '&:hover': {
            background: '$interface_light_up',
          },

          opacity: hidden ? 0.5 : 1,
        }}
      >
        <Div
          onClick={() => {
            if (structure.children.length && !hidden) {
              setIsOpen(!isOpen)
            }
          }}
          css={{
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
            gap: '$2',

            flex: 1,
            minWidth: 1.2 * MIN_COLUMNS_WIDTH,

            paddingTop: 6,
            paddingBottom: 6,

            paddingLeft: index * 24,
          }}
        >
          {structure.children?.length ? (
            <Div
              css={{
                height: 20,
                width: 20,

                svg: {
                  height: 16,
                  width: 16,

                  rotate: isOpen ? '0deg' : '-90deg',
                },
              }}
            >
              <ChevronDownIcon />
            </Div>
          ) : (
            <Div
              css={{
                height: 20,
                width: 20,
              }}
            />
          )}
          <Div
            css={{
              svg: {
                height: 20,
                width: 20,
              },
            }}
          >
            {isOpen ? <FolderOpenIcon /> : <FolderIcon />}
          </Div>
          <Text variant="caption">{structure.name}</Text>
        </Div>

        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        ></Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Switch
            checked={hidden}
            onCheckedChange={async (v) => {
              await handleChangeFolderSettings({
                foldersIds: [structure.id],
                isHidden: v,
              })
              // methods.setValue(`structure.hidden.${structure.id}`, Boolean(v))
            }}
          />
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 34,
            gap: '$2',
          }}
        >
          <Div
            css={{
              width: 1,
              height: 10,
              background: '$interface_light_up',
            }}
          />
          <Div
            onClick={() => {
              methods.setValue('addFolder.isAddFolderDrawerOpen', true)
              methods.setValue('addFolder.folderId', structure.id)
            }}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              cursor: 'pointer',

              svg: {
                height: 16,
                width: 16,
                color: '$interface_dark_down',
              },
            }}
          >
            <Tooltip message={`Agregar carpeta en "${structure.name}"`} arrow>
              <PlusIcon />
            </Tooltip>
          </Div>
          <Div
            onClick={() => {
              methods.setValue('editFolder', {
                id: structure.id,
                parentId: structure.parent_id,
                isEditFolderDrawerOpen: true,
                name: structure.name,
              })
            }}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              cursor: 'pointer',

              svg: {
                height: 16,
                width: 16,
                color: '$interface_dark_down',
              },
            }}
          >
            <Tooltip message={`Editar carpeta "${structure.name}"`} arrow>
              <OpenDrawerIcon />
            </Tooltip>
          </Div>
          <Div
            css={{
              width: 1,
              height: 10,
              background: '$interface_light_up',
            }}
          />
          <Checkbox
            checked={checked}
            onCheckedChange={(v) => {
              methods.setValue(`structure.checked.${structure.id}`, Boolean(v))
            }}
          />
        </Div>
      </Div>

      {isOpen && !hidden && (
        <Div>
          {structure.children.map((c) => (
            <FolderComponent structure={c} key={c.id} index={index + 1} />
          ))}
        </Div>
      )}
    </Div>
  )
}
