import { PdfIcon } from '@/assets/icons/PdfIcon'
import { TxtIcon } from '@/assets/icons/TxtIcon'
import { XlsxIcon } from '@/assets/icons/XlsxIcon'
import { Div } from '@/components'
import {
  IDocumentStructure,
  IUserDocumentStructure,
} from '@/libs/react-query/types/documents'
import { DocumentIcon, PhotoIcon } from '@heroicons/react/24/outline'

type IFlattenDocument = Omit<IDocumentStructure, 'children'>

export function flattenDocumentTree(
  tree: IDocumentStructure,
): IFlattenDocument[] {
  const result: IFlattenDocument[] = []

  // Recursive function to process each node
  function processNode(node: IDocumentStructure, parentId: string | null) {
    const { children, ...nodeWithoutChildren } = node
    result.push({ ...nodeWithoutChildren, parent_id: parentId })

    children.forEach((child) => {
      processNode(child, node.id)
    })
  }

  const initial = [tree]

  // Process each root node
  initial.forEach((rootNode) => {
    processNode(rootNode, null)
  })

  return result
}

export const fileTypesToLabels: any = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
  'application/pdf': 'PDF',
  'text/plain': 'TXT',
}

export const getFileLabelFromType = (type?: string) => {
  if (!type) return 'DOCUMENT'

  if (type.includes('image')) {
    return 'IMAGE'
  }

  return fileTypesToLabels[type] || 'DOCUMENT'
}

export const fileTypesToIcons = {
  PDF: <PdfIcon />,
  XLSX: <XlsxIcon />,
  TXT: <TxtIcon />,
  IMAGE: <PhotoIcon />,
}

export const getFileTypeIcon = (str?: string) => {
  const type = getFileLabelFromType(str)

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        svg: {
          height: 20,
          width: 20,

          color: type === 'IMAGE' ? '$brand_primary_pure' : undefined,
        },
      }}
    >
      {(str ? (fileTypesToIcons as any)[type] : null) || <DocumentIcon />}
    </Div>
  )
}

export function findBreadcrumb(
  tree: IUserDocumentStructure[],
  targetId: string,
): string[] {
  console.log(tree, targetId)
  // Recursive function to traverse the tree and find the path
  function traverse(
    node: IUserDocumentStructure,
    path: string[],
  ): string[] | null {
    // Add the current node name to the path
    const newPath = [...path, node.name]
    // Check if the current node is the target
    if (node.id === targetId) {
      return newPath
    }
    // Recurse through each child
    for (const child of node.children) {
      const result = traverse(child, newPath)
      if (result) return result // If target is found in the subtree, return the path
    }
    return null // Target not found in this path
  }

  // Start from each root node and look for the target ID
  for (const rootNode of tree) {
    const result = traverse(rootNode, [])
    if (result) return result
  }

  return [] // Target ID not found in any subtree
}
