import { z } from 'zod'

export const smartShiftsFormSchema = z.object({
  shiftsSelection: z.boolean().array().array(),
  usersIds: z.string().array(),

  cellsIds: z.string().array(),
  name: z.string(),
  dates: z.string().array(),
})

export type SmartShiftsFormSchema = z.infer<typeof smartShiftsFormSchema>
