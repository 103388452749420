import dayjs from 'dayjs'
import { ImportPunchesContent } from './form'
import { ICreateManyPunchesFromDocuments } from '@/libs/react-query/mutations/punchclock/types'
import { identifyDateFormat, isDateInstance } from '@/utils/date'
import { safeOnlyDigits } from '@/components/Forms/ControlledInput/toPattern'

export interface ImportedPunch {
  document: string
  date: string
  marcacion_id: string
}

export const parseImportedDocumentToPunches = (
  data: any[],
): ImportedPunch[] => {
  const users = data.map((row) => {
    const dateKey =
      Object.keys(row).find((key) => key.toLowerCase().includes('fecha')) ||
      'Fecha'

    return {
      marcacion_id: row.ID ? String(row.ID) : '',
      document: row.CI ? String(row.CI) : '',
      date: row[dateKey] ? String(row[dateKey]) : '',
    } as ImportedPunch
  })

  return users
}

export const parseImportedPunchesToTable = (
  workers: ImportedPunch[],
): ImportPunchesContent[] => {
  const data: ImportPunchesContent[] = []

  workers
    .filter((w) => !!w.document || !!w.marcacion_id)
    .forEach((worker) => {
      let parsedDate =
        (isDateInstance(worker.date) || isDateInstance(worker.date)) &&
        dayjs(worker.date).isValid()
          ? dayjs(worker.date)
          : null

      const format = identifyDateFormat(worker.date)
      if (!parsedDate && format !== 'no-format') {
        parsedDate = dayjs(worker.date, format)
      } else if (!parsedDate && format === 'no-format') {
        parsedDate = dayjs(worker.date)
      }

      data.push({
        marcacion_id: {
          value: worker.marcacion_id,
          status: '',
        },
        document: {
          value: worker.document,
          status: '',
        },
        date: {
          value: !parsedDate?.isValid()
            ? `Data Invalida ${worker.date}`
            : parsedDate.format('DD/MM/YYYY HH:mm'),
        },
      })
    })

  return data.filter((d) => d.date.value)
}

export const parsePunchesCreationStructure = (
  props: ImportPunchesContent[],
): ICreateManyPunchesFromDocuments => {
  const apiPayload: ICreateManyPunchesFromDocuments['punches'] = []

  props.forEach((p) => {
    apiPayload.push({
      date: dayjs(p.date.value as string, 'DD/MM/YYYY HH:mm').toISOString(),
      document: safeOnlyDigits(p.document.value as string),
      marcacion_id: p.marcacion_id.value as string,
    })
  })

  return {
    punches: apiPayload,
  }
}
