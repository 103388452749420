import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const handleDeletePayments = async (ids: string[]) => {
  await apiV2.post<void>('/payment/delete-payments', {
    ids,
  })

  queryClient.invalidateQueries()
}

export const useHandleDeletePayments = () => {
  const mutation = useCustomMutation(
    ['useHandleDeletePayments'],
    handleDeletePayments,
  )

  return mutation
}
