import React, { useEffect, useState } from 'react'
import { Avatar, Div, Table as TableComponent } from '@/components'
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useWorkers } from '@/libs/react-query/hooks'
import {
  Box,
  Checkbox,
  Dialog,
  DialogContainer,
  Menu,
  MenuProps,
  Progress,
  Tag,
  Text,
  styled,
} from '@punto-ui/react'
import { IWorkerUser } from '@/libs/react-query/types/worker'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCan, useDebounce } from '@/hooks'
import { useTabStore } from '@/store'
import { useWorkerPage } from '../context/hooks/useWorkerPage'
import { AddWorkerSimpleData } from '../forms'
import { QuickWorkerLiquidation } from './QuickWorkerLiquidation'
import { SimpleDialog } from '@/components/Dialogs/SimpleDialogs'
import { useHandleDeactivateUser } from '@/libs/react-query/mutations'
import {
  ExclamationCircleIcon,
  EyeIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { getLiquidationLabel } from '@/utils/workers/liquidation'

export const WorkersList = () => {
  const { filter, setFilter } = useWorkerPage()
  const [deleteWorkerId, setDeleteWorkerId] = React.useState('')
  const [deactivateWorkerId, setDeactivateWorkerId] = React.useState('')
  const [rowSelection, setRowSelection] = React.useState({})

  const { control } = useFormContext<AddWorkerSimpleData>()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: filter.page,
      pageSize: filter.pageSize ? filter.pageSize : 20,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  useEffect(() => {
    setFilter((state) => ({
      ...state,
      page: pageIndex,
      pageSize,
    }))
  }, [pageIndex, pageSize, setFilter])

  useEffect(() => {
    if (filter.name) {
      setPagination((state) => ({
        ...state,
        pageIndex: 0,
      }))
      setFilter((state) => ({
        ...state,
        page: 0,
      }))
    }
  }, [filter.name, setFilter])

  const [name, cellsIds] = useWatch({
    control,
    name: ['filters.name', 'filters.cellsIds'],
  })

  const debouncedName = useDebounce(name, 200)

  const { data: workers, isFetching: isFetchingWorkers } = useWorkers({
    ...filter,
    name: debouncedName,
    cellsIds,
  })

  const columns = React.useMemo<ColumnDef<IWorkerUser>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        header: 'Foto',
        id: 'photo',
        footer: (info) => info.column.id,
        cell: ({ row }) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 32,
            }}
          >
            <Avatar
              src={row?.original?.photo_url || ''}
              height={32}
              width={32}
              alt=""
              unoptimized={true}
            />
          </Div>
        ),
      },
      {
        header: 'CI',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.document,
        id: 'doc',
      },
      {
        header: 'Nombre',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.firstName,
        id: 'name',
      },
      {
        header: 'Apellido',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.surname,
        id: 'nickname',
      },
      {
        header: 'E-mail',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.email,
        id: 'email',
      },
      {
        header: 'Teléfono',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.phone1,
        id: 'sector',
      },
      {
        header: () => null,
        cell: (info) => (
          <StyledContentText>
            <DropdownMenu
              rowItem={info.row.original as IWorkerUser}
              setIsDeleteOpen={setDeleteWorkerId}
              setDeactivateWorkerId={setDeactivateWorkerId}
            />
          </StyledContentText>
        ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        id: '_id',
      },
    ],
    [],
  )

  const { mutateAsync: handleDeactivateUser } = useHandleDeactivateUser()

  const table = useReactTable({
    data: workers?.data ?? [],
    columns,
    pageCount: workers?.totalPages ? workers.totalPages : -1,
    state: {
      pagination,
      rowSelection,
    },
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <WorkersListContainer>
      <TableComponent<IWorkerUser>
        totalNumberOfRows={workers?.total ?? 0}
        numberOfRows={workers?.data?.length ?? 0}
        table={table}
        isLoading={isFetchingWorkers}
      />
      <DialogContainer
        open={!!deleteWorkerId}
        onOpenChange={() => setDeleteWorkerId('')}
      >
        {deleteWorkerId && (
          <Dialog
            content={
              <QuickWorkerLiquidation
                closeModal={() => setDeleteWorkerId('')}
                workerId={deleteWorkerId}
              />
            }
            overlayCss={{
              zIndex: 999,
            }}
            containerCss={{
              zIndex: 9999,
              padding: 0,
            }}
          />
        )}
      </DialogContainer>
      <DialogContainer
        open={!!deactivateWorkerId}
        onOpenChange={() => setDeactivateWorkerId('')}
      >
        {deactivateWorkerId && (
          <SimpleDialog
            description={
              '¿Está seguro que desea desactivar el colaborador sin liquidación?'
            }
            title={'Desactivar Colaborador sin Liquidar'}
            handleClose={() => setDeactivateWorkerId('')}
            handleConfirm={() => {
              handleDeactivateUser({ user_id: deactivateWorkerId })
              setDeactivateWorkerId('')
            }}
            icon={<TrashIcon />}
            overlayCss={{
              zIndex: 999,
            }}
            containerCSS={{
              zIndex: 9999,
            }}
            confirmLabel="Excluir"
          />
        )}
      </DialogContainer>
    </WorkersListContainer>
  )
}

interface DropdownMenuProps {
  rowItem: IWorkerUser
  setIsDeleteOpen: (value: string) => void
  setDeactivateWorkerId: (value: string) => void
}

const DropdownMenu = ({
  rowItem,
  setIsDeleteOpen,
  setDeactivateWorkerId,
}: DropdownMenuProps) => {
  const canEditLiquidation = useCan([
    'workers.all',
    'workers.liquidate',
    'workers.editLiquidation',
  ])
  const canLiquidate = useCan(['workers.all', 'workers.liquidate'])
  const canEdit = useCan(['workers.edit'])

  const [isLoading, setIsLoading] = useState(false)
  const { setActiveTab, addTab } = useTabStore((state) => ({
    setActiveTab: state.setActiveTab,
    addTab: state.addTab,
  }))
  const handleEditWorker = () => {
    addTab('worker', [
      {
        id: rowItem.id,
        type: 'edit-worker',
        label: rowItem.name,
      },
    ])
    setActiveTab('worker', rowItem.id)
  }

  const handleInactivateWorker = (liquidationId?: string) => {
    setIsLoading(true)
    const inactivateWorkerTabId = `${rowItem.id}-inactivate`
    const liquidation = rowItem.liquidations.find(
      (l) => l.liquidation_id === liquidationId,
    )
    const label = getLiquidationLabel(liquidation, rowItem.name)

    addTab('worker', [
      {
        id: inactivateWorkerTabId,
        type: 'inactivate-worker',
        label,
        metadata: {
          liquidationId,
        },
      },
    ])
    setIsLoading(false)
    setActiveTab('worker', inactivateWorkerTabId)
  }

  const openLiquidation =
    rowItem.liquidations.length &&
    rowItem.liquidations.find((l) => l.status !== 'done')

  const liquidationsSortedByHiringDate = rowItem.liquidations.sort((a, b) => {
    const bHiring = b.hiring_date
    const aHiring = a.hiring_date

    if (aHiring < bHiring) {
      return 1
    }

    if (aHiring > bHiring) {
      return -1
    }

    return 0
  })

  const liquidationsOptions: MenuProps['items'] =
    liquidationsSortedByHiringDate.map((l) => {
      const label = getLiquidationLabel(l)

      return {
        onClick: () => handleInactivateWorker(l.liquidation_id),
        id: l.liquidation_id,
        rightSlot:
          l.status === 'done' ? (
            <Tag variant={'positive'}>Liquidado</Tag>
          ) : (
            <Tag variant={'neutral'}>Em progresso</Tag>
          ),
        label,
      }
    })

  if (!openLiquidation) {
    liquidationsOptions.push({
      onClick: () => handleInactivateWorker(),
      id: 'new',
      label: 'Nueva Liquidación',
      icon: <PlusCircleIcon />,
      rightSlot: isLoading ? <Progress /> : null,
    })
  }

  const items: MenuProps['items'] = [
    {
      id: '1',
      divideBellow: true,
      label: canEdit ? 'Editar' : 'Ver',
      icon: canEdit ? <PencilSquareIcon /> : <EyeIcon />,
      onClick: () => handleEditWorker(),
    },
    // {
    //   id: '2',
    //   label: 'Fotos',
    //   onClick: () => handleOpenPopover('faceId', rowItem),
    // },
    {
      disabled: !canLiquidate,
      id: '3',
      icon: <ExclamationCircleIcon />,
      label: 'Liquidación Rapida',
      onClick: () => {
        setTimeout(() => setIsDeleteOpen(rowItem.id), 1)
      },
    },
    {
      disabled: !canEditLiquidation,
      divideBellow: true,
      id: '5',
      label: 'Liquidaciones',
      onClick: () => {
        if (!openLiquidation) {
          handleInactivateWorker()
        }
      },
      children: liquidationsOptions,
    },
    {
      id: '3',
      label: 'Desactivar',
      onClick: () => {
        setTimeout(() => setDeactivateWorkerId(rowItem.id), 1)
      },
    },
  ]

  return (
    <>
      <Menu items={items} />
    </>
  )
}

export const WorkersListContainer = styled('div', {
  marginTop: '$2',
  border: 'solid 1px $interface_light_down',
  borderRadius: '$md',
})

export const StyledContentText = styled(Text, {
  color: '$interface_dark_deep',
  fontSize: '$sm',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
})
