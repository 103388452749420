import { useEffect } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { useTabStore } from '@/store'
import {
  BookmarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline'
import { IRouteAction } from '@/pages/router'
import { AguinaldoPlaygroundTableType } from '../types'
import { useAguinaldoPlaygroundStore } from '../store'
import { useHandleCreateAguinaldo } from '@/libs/react-query/mutations/aguinaldo/useHandleCreateAguinaldo'
import { useHandleUpdateAguinaldoConfig } from '@/libs/react-query/mutations/aguinaldo/useHandleUpdateAguinaldoConfig'
import { useCan } from '@/hooks'

export const useHandleStepActions = (
  methods: UseFormReturn<AguinaldoPlaygroundTableType>,
  id?: string,
  raiseConfirmCloseModal?: () => void,
) => {
  const {
    currentStep,
    handleNextStep,
    handlePreviousStep,
    canGoBack,
    canGoNext,
    handleOpenDrawer,
  } = useAguinaldoPlaygroundStore((state) => ({
    handleNextStep: state.handleNextStep,
    handlePreviousStep: state.handlePreviousStep,
    canGoBack: state.canGoBack,
    currentStep: state.currentStepValue,
    handleOpenDrawer: state.handleOpenDrawer,
    canGoNext: state.canGoNext,
  }))

  const { control, getValues } = methods
  const {
    mutateAsync: handleCreateAguinaldo,
    isLoading: isLoadingCreateAguinaldo,
  } = useHandleCreateAguinaldo()

  const {
    mutateAsync: handleUpdateAguinaldoSettings,
    isLoading: isLoadingAguinaldoSettingsUpdate,
  } = useHandleUpdateAguinaldoConfig()

  const { addActions, removeTab, changeTab } = useTabStore((state) => ({
    addActions: state.setActionsTab,
    removeTab: state.removeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const [name, usersIds] = useWatch({
    control,
    name: ['name', 'users_ids'],
  })

  const canClosePayroll = useCan(['payroll.aguinaldo'])

  useEffect(() => {
    const values = getValues()

    const errorMessage = !name
      ? 'Nombre es requerido'
      : !usersIds.length
      ? 'Debe seleccionar al menos un colaborador'
      : ''

    if (id) {
      const actions: IRouteAction[] = [
        {
          label: 'Volver',
          icon: <ChevronLeftIcon />,
          variant: 'secondary' as const,
          action: handlePreviousStep,
          disabled: !canGoBack,
        },
        {
          label: 'Avanzar',
          icon: <ChevronRightIcon />,
          disabled: !canGoNext,
          variant: 'secondary' as const,
          action: handleNextStep,
        },
      ]

      if (values.status === 'closed') {
        actions.unshift({
          label: 'Planilla Cerrada',
          icon: <LockOpenIcon />,
          width: 150,
          disabled: true,
          disableMessage: 'No se puede editar una planilla cerrada',
          variant: 'primary' as const,
          action: () => null,
        })
      }

      if (currentStep === 'CONFIG' && values.status === 'open') {
        actions.push({
          label: 'Actualizar',
          icon: <BookmarkIcon />,
          variant: 'primary' as const,
          disabled: !!errorMessage || isLoadingAguinaldoSettingsUpdate,
          disableMessage: errorMessage,
          isLoading: isLoadingAguinaldoSettingsUpdate,
          width: 120,
          action: async () => {
            const values = getValues()

            await handleUpdateAguinaldoSettings({
              id,
              description: values.description || undefined,
              isFavorite: !!values.isFavorite,
              name: values.name,
              usersIds: values.users_ids,
              antecipate: values.configuration.antecipate,
              bonus: values.configuration.bonus,
              commission: values.configuration.commission,
              excess: values.configuration.excess,
              extraMorningHours: values.configuration.morningExtraHours,
              extraNightHours: values.configuration.nightExtraHours,
              discountMorningHours: values.configuration.morningDiscountHours,
              discountNightHours: values.configuration.nightDiscountHours,
              familiarBonus: values.configuration.familyBonus,
              gift: values.configuration.gift,
              holidayMorningHours: values.configuration.morningHolidayHours,
              holidayNightHours: values.configuration.nightHolidayHours,
              ips16: values.configuration.ips16,
              ips9: values.configuration.ips9,
              judicial: values.configuration.judicial,
              loan: values.configuration.loan,
              lost: values.configuration.lost,
              mobility: values.configuration.mobility,
              ordinaryMorningHours: values.configuration.morningOrdinaryHours,
              ordinaryNightHours: values.configuration.nightOrdinaryHours,
              otherDiscount: values.configuration.otherDiscount,
              otherEarns: values.configuration.otherPayments,
              payments: values.configuration.payments,
              purchase: values.configuration.purchase,
              year: values.year,
            })
          },
        })
      } else if (values.status === 'open' && canClosePayroll) {
        actions.push({
          label: 'Cerrar Aguinaldo',
          icon: <LockClosedIcon />,
          variant: 'primary' as const,
          disabled: true,
          disableMessage: errorMessage,
          isLoading: isLoadingAguinaldoSettingsUpdate,
          width: 150,
          action: async () => {
            raiseConfirmCloseModal?.()
          },
        })
      }
      // actions.push({
      //   label: 'Exportar',
      //   icon: <ArrowDownOnSquareIcon />,
      //   variant: 'secondary' as const,
      //   disabled: !!errorMessage || isLoadingAguinaldoSettingsUpdate,
      //   disableMessage: errorMessage,
      //   isLoading: isLoadingAguinaldoSettingsUpdate,
      //   width: 150,
      //   action: async () => {
      //     handleOpenDrawer('EXPORT', {})
      //   },
      // })
      actions.push({
        label: 'Actualizar en Lote',
        icon: <QueueListIcon />,
        variant: 'secondary' as const,
        disabled: !!errorMessage || isLoadingAguinaldoSettingsUpdate,
        disableMessage: errorMessage,
        isLoading: isLoadingAguinaldoSettingsUpdate,
        width: 200,
        action: async () => {
          handleOpenDrawer('BATCH_EDITING', {})
        },
      })

      addActions('payroll', actions)
    } else {
      addActions('payroll', [
        {
          label: 'Crear',
          icon: <PlusIcon />,
          variant: 'secondary' as const,
          disabled: !!errorMessage,
          disableMessage: errorMessage,
          isLoading: isLoadingCreateAguinaldo,
          action: async () => {
            const values = getValues()

            await handleCreateAguinaldo({
              description: values.description,
              antecipate: values.configuration.antecipate,
              bonus: values.configuration.bonus,
              commission: values.configuration.commission,
              excess: values.configuration.excess,
              extraMorningHours: values.configuration.morningExtraHours,
              extraNightHours: values.configuration.nightExtraHours,
              discountMorningHours: values.configuration.morningDiscountHours,
              discountNightHours: values.configuration.nightDiscountHours,
              familiarBonus: values.configuration.familyBonus,
              gift: values.configuration.gift,
              holidayMorningHours: values.configuration.morningHolidayHours,
              holidayNightHours: values.configuration.nightHolidayHours,
              ips16: values.configuration.ips16,
              ips9: values.configuration.ips9,
              judicial: values.configuration.judicial,
              loan: values.configuration.loan,
              lost: values.configuration.lost,
              mobility: values.configuration.mobility,
              ordinaryMorningHours: values.configuration.morningOrdinaryHours,
              ordinaryNightHours: values.configuration.nightOrdinaryHours,
              otherDiscount: values.configuration.otherDiscount,
              otherEarns: values.configuration.otherPayments,
              payments: values.configuration.payments,
              purchase: values.configuration.purchase,
              isFavorite: !!values.isFavorite,
              name: values.name,
              year: values.year,
              usersIds: values.users_ids,
            })

            changeTab('payroll', 'list')
            removeTab('payroll', 'new-aguinaldo')
          },
        },
      ])
    }
  }, [
    canClosePayroll,
    addActions,
    control,
    getValues,
    name,
    usersIds,
    handleCreateAguinaldo,
    isLoadingCreateAguinaldo,
    isLoadingAguinaldoSettingsUpdate,
    changeTab,
    removeTab,
    id,
    canGoBack,
    canGoNext,
    handleNextStep,
    handlePreviousStep,
    currentStep,
    handleUpdateAguinaldoSettings,
  ])
}
