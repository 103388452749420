import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import {
  fromReportTypeAndRowTypesToColumns,
  fromRowTypeToLabel,
} from '@/pages/reportes-v2/ReportBuilder/report-builder'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
} from '@/pages/reportes-v2/ReportBuilder/type'
import { useMemo } from 'react'

export const useColumnOptions = (props: { templateId: string }) => {
  const { data: template } = useReportTemplate(props.templateId)

  const columns = useMemo(() => {
    if (!template?.type && !template?.columns) {
      return []
    }

    const row = {
      detailSelectedKeys: [],
      detailKey: template.rowDetails,
      type: template.row as IReportBuilderRowTypes,
    }

    const newColumns = fromReportTypeAndRowTypesToColumns(
      template?.type as IReportBuilderTypes,
      [row],
      [],
      template?.columns || [],
    )

    const columnsArray = Object.values(newColumns).flatMap((key) => key)

    return columnsArray
  }, [template])

  const columnsOptions = useMemo(() => {
    return columns
      .filter((s) => s.selected)
      .map((c) => ({
        label: `${c.label} - ${
          fromRowTypeToLabel[c.column_type as IReportBuilderRowTypes]
        }`,
        value: `${c.column_type}.${c.value}`,
        type: c.valueType,
      }))
  }, [columns])

  return {
    columnsOptions,
    columns,
  }
}
