import React from 'react'
import { Div } from '../Div'
import { keyframes } from '@punto-ui/react'

interface AppBarProps {
  children: React.ReactNode
  isShowing?: boolean
}

const overlayShow = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(.5) translateY(50%) translateX(-50%)',
    // bottom: -32,
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1) translateY(0) translateX(-50%)',
    // bottom: 32,
  },
})

const contentShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const AppBar = ({ isShowing, children }: AppBarProps) => {
  if (!isShowing) {
    return null
  }

  return (
    <Div
      css={{
        animation: `${overlayShow} 500ms cubic-bezier(0.16, 1, 0.3, 1)`,
        position: 'absolute',
        bottom: 32,
        zIndex: 999,
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <Div
        css={{
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          animation: `${contentShow} 50ms cubic-bezier(0.16, 1, 0.3, 1)`,
          background: '$interface_light_pure',
          borderRadius: '$md',
          display: 'flex',
          flexDirection: 'row',
          // border: '1px solid $interface_light_down',
        }}
      >
        {children}
      </Div>
    </Div>
  )
}
