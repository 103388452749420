import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsInactivateWorkerFormDisabled } from '../hooks'

export const useIndemnification = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [indemnification] = useWatch({
    control,
    name: ['data.indemnification'],
  })
  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = [
      {
        _type: 'input',
        name: `data.indemnification.corresponding_days.value`,
        disabled: isLoadingLiquidation,
        inputType: 'only-numbers-or-empty',
        disclaimer: `Antiguedad: ${indemnification.corresponding_days.disclaimer}`,
        label: 'Días de indemnización',
      },
      {
        _type: 'input',
        disabled: isLoadingLiquidation,
        inputType: 'money-guarani',
        label: 'Valor en haber',
        name: `data.indemnification.value`,
      },
    ]
    return lastSalaryForm
  }, [indemnification, isLoadingLiquidation])

  return {
    form,
  }
}
