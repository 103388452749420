import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IChangePasswordDTO } from '../types'
import { queryClient } from '@/libs/react-query'

const handleChangePassword = async (data: IChangePasswordDTO) => {
  await apiV2.post<void>('/user/change-password', data)

  queryClient.invalidateQueries()
}

export const useHandleChangePassword = () => {
  const mutation = useMutation(['useHandleCreateWorker'], handleChangePassword)

  return mutation
}
