import { formatCurrency } from '../numbers/currency'

function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length)
  return array[randomIndex]
}

export function generateRandomName(): string {
  const firstNames = ['John', 'Jane', 'Emily', 'Michael', 'Sarah', 'Alex']
  const surnames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia']

  const randomFirstName = getRandomElement(firstNames)
  const randomSurname = getRandomElement(surnames)

  return `${randomFirstName} ${randomSurname}`
}

export function generateRandomNamePair() {
  const firstNames = ['John', 'Jane', 'Emily', 'Michael', 'Sarah', 'Alex']
  const surnames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia']

  const randomFirstName = getRandomElement(firstNames)
  const randomSurname = getRandomElement(surnames)

  return {
    name: randomFirstName,
    surname: randomSurname,
  }
}

function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function formatNumberToTwoDigits(n: number): string {
  return n < 10 ? `0${n}` : `${n}`
}

export function generateRandomHour(): string {
  const hour = getRandomNumber(0, 23)
  const minute = getRandomNumber(0, 59)

  const formattedHour = formatNumberToTwoDigits(hour)
  const formattedMinute = formatNumberToTwoDigits(minute)

  if (Math.random() < 0.2) {
    return ''
  }

  return `${formattedHour}:${formattedMinute}`
}

export function generateRandomWorkerPaymentType(): string {
  if (Math.random() < 0.4) {
    return 'Jornal'
  }

  return 'Mensual'
}

export const generateRandomBoolean = (): boolean => Math.random() < 0.5

export const generateRandomNumberUnder100 = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  return getRandomNumber(0, 100).toString()
}

export const generateRandomNumberUnder10 = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  return getRandomNumber(0, 10).toString()
}

export const generateRandomNumberUnder10000000 = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  return getRandomNumber(0, 10_000_000).toString()
}

export const generateRandomNumberUnderBigMoney = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  return formatCurrency(getRandomNumber(0, 10_000_000))
}

export const generateRandomTitle = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const titles = [
    'Indemnización',
    'Bonificación exclusiva',
    'Movimiento',
    'Incluso ferias',
    'Feriado Valentín',
    'Ayuda compras',
    'Vale compras',
    'Graficaciones',
    'Atracion',
    'Efermedades',
  ]

  return getRandomElement(titles)
}

export const generateRandomDescription = (): string => {
  if (Math.random() < 0.5) {
    return ''
  }

  const descriptions = [
    'Lorem ipsum dolor sit amet.',
    'Vivamus nec metus nec nunc euismod.',
    'Sed auctor, odio sit amet bibendum.',
    'Pellentesque habitant morbi .',
  ]

  return getRandomElement(descriptions)
}

export const generateRandomSiOrEmpty = (): string =>
  Math.random() < 0.5 ? 'Si' : ''

export const generateRandomMovementCategory = (): string => {
  if (Math.random() < 0.5) {
    return ''
  }

  const categories = [
    'Feriado',
    'Vacaciones',
    'Licencia',
    'Incapacidad',
    'Permiso',
    'Ausencia',
    'Otro',
  ]

  return getRandomElement(categories)
}

export const generateRandomPaymentCategory = (): string => {
  if (Math.random() < 0.5) {
    return ''
  }

  const categories = [
    'Salario',
    'Bonificación',
    'Indemnización',
    'Comisión',
    'Aguinaldo',
    'Vacaciones',
    'Licencia',
    'Incapacidad',
    'Permiso',
    'Otro',
  ]

  return getRandomElement(categories)
}

export const generateRandomSectors = (): string => {
  const sectors = [
    'Administración',
    'Producción',
    'Ventas',
    'Marketing',
    'Recursos Humanos',
    'Finanzas',
    'Logística',
  ]

  return getRandomElement(sectors)
}

export const generateRandomPayrollStatus = (): string => {
  if (Math.random() < 0.5) {
    return ''
  }

  const statuses = ['Vacaciones', 'Movimiento', 'Presencia', 'Falta']

  return getRandomElement(statuses)
}

export const generateRandomCodes = (): string => {
  if (Math.random() < 0.1) {
    return ''
  }

  const codes = ['1234', '5678', '9101', '1121', '3141', '5161']

  return getRandomElement(codes)
}

export const generateRandomShiftNames = (): string => {
  if (Math.random() < 0.1) {
    return ''
  }

  const names = ['Turno Mañana', 'Turno Tarde', 'Turno Noche']

  return getRandomElement(names)
}

export const generateRandomTimezones = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const timezones = [
    'America/Asuncion',
    'America/Argentina/Buenos_Aires',
    'America/Santiago',
    'America/Montevideo',
    'America/Sao_Paulo',
  ]

  return getRandomElement(timezones)
}

export const generateRandomVacationTitles = (): string => {
  if (Math.random() < 0.5) {
    return ''
  }

  const titles = ['Vacaciones', 'Vacaciones 2022', 'Vacaciones 2023']

  return getRandomElement(titles)
}

export const generateRandomDateArray = (): string => {
  const dates = ['2022-01-01', '2022-02-01', '2022-03-01']

  return dates.join(', ')
}

export const generateRandomEmails = (): string => {
  const emails = Array.from({ length: 10 }).map(() => {
    const nameData = generateRandomNamePair()
    const name = `${nameData.name.toLowerCase()}_${nameData.surname.toLowerCase()}`

    return `${name}@teste.com.py`
  })

  return getRandomElement(emails)
}

export const generateRandomDocuments = (): string => {
  const documents = ['55913431', '55472874', '55452343', '12138981']

  return getRandomElement(documents)
}

export const generateRandomPhones = (): string => {
  if (Math.random() < 0.3) {
    return ''
  }

  const phones = [
    '55 91431 3431',
    '55 47274 3431',
    '55 45231 3433',
    '12 13898 1332',
  ]

  return getRandomElement(phones)
}

export const generateRandomCivilState = (): string => {
  const civilStates = ['Casado', 'Soltero']

  return getRandomElement(civilStates)
}

export const generateRandomGender = (): string => {
  const genders = ['Masculino', 'Femenino']

  return getRandomElement(genders)
}

export const generateRandomNationalities = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const nacionalities = ['Paraguayo', 'Argentino', 'Brasileiro']

  return getRandomElement(nacionalities)
}

export const generateRandomDate = (): string => {
  const date = new Date(
    getRandomNumber(2010, 2022),
    getRandomNumber(0, 11),
    getRandomNumber(1, 28),
  )

  const under20 = Math.random() < 0.2

  if (under20) {
    return ''
  }

  return date.toISOString().split('T')[0]
}

export const generateRandomBank = (): string => {
  if (Math.random() < 0.5) {
    return ''
  }

  const banks = ['Banco Nacional', 'Banco Itaú', 'Banco do Brasil']

  return getRandomElement(banks)
}

export const generateRandomCountry = (): string => {
  const countries = ['Paraguay', 'Argentina', 'Brasil']

  return getRandomElement(countries)
}

export const generateRandomState = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const states = ['Central', 'Asunción', 'San Lorenzo']

  return getRandomElement(states)
}

export const generateGeneralNeighborhood = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const neighborhoods = ['Barrio Obrero', 'Villa Morra', 'Lambaré']

  return getRandomElement(neighborhoods)
}

export const generateRandomCity = (): string => {
  const cities = ['Asunción', 'Luque', 'Capiatá']

  return getRandomElement(cities)
}

export const generateRandomAddress = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const addresses = ['Av. Mariscal López', 'Av. España', 'Av. Eusebio Ayala']

  return getRandomElement(addresses)
}

export const generateRandomConfiguration = (): string => {
  if (Math.random() < 0.2) {
    return ''
  }

  const configurations = [
    'Configuración Inscripto IPS',
    'Configuración Aguinaldo +',
    'Configuración Jornaleros',
    'Trabajadores de la Construcción',
  ]

  return getRandomElement(configurations)
}

export const generateRandomStatus = (): string => {
  const statuses = [
    'Presencia',
    'Presencia',
    'Presencia',
    'Falta',
    'Movimiento',
    'Vacaciones',
    'Descanso',
  ]

  return getRandomElement(statuses)
}
