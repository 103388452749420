import { Avatar, Div, SmartTable } from '@/components'
import { Tag, Text } from '@punto-ui/react'
import { useShiftOrganization } from './shift-organization'
import { usePunchInDate } from './punch-in-date'
import { useMemo } from 'react'
import { MovementsDayDetail } from './movements'
import { usePayrollPlaygroundStore } from '../../../store'
import dayjs from 'dayjs'
// import { usePayrollPlaygroundStore } from '../../../store'
import {
  fromWorkedDayStatusToLabel,
  fromWorkedDayStatusToTagVariant,
} from '@/libs/react-query/types'
import { VacationDayDetail } from './vacations'
import { usePayrollDay } from './usePayrollDay'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekdays: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ],
})

export const DayDrawerSummary = () => {
  const { columns: shiftColumns, data: shiftData } = useShiftOrganization({
    totalWidth: 600,
  })
  const { columns: punchColumns, data: punchData } = usePunchInDate({
    totalWidth: 600,
  })
  const { drawer } = usePayrollPlaygroundStore((state) => ({
    drawer: state.drawer.data,
  }))

  const { data: payrollDayDetail } = usePayrollDay()

  const shiftInDay = useMemo(() => {
    return payrollDayDetail?.schedule.shifts.find((s) =>
      dayjs(s.date).isSame(drawer?.date, 'day'),
    )
  }, [drawer?.date, payrollDayDetail?.schedule.shifts])

  const styles = useMemo(() => {
    return {
      borderInLines: true,
      centered: true,
    }
  }, [])

  return (
    <Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
          marginBottom: '$4',
        }}
      >
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Text
            variant={'heading4'}
            css={{
              color: '$interface_dark_down',
              paddingLeft: '$4',
            }}
          >
            Resumen de {payrollDayDetail?.schedule.worker.name} -{' '}
            {dayjs(drawer?.date).format('dddd')},{' '}
            {dayjs(drawer?.date).format('DD/MM/YYYY')}
          </Text>
          <Div
            css={{
              paddingLeft: '$4',
              paddingTop: '$4',
              paddingBottom: '$4',
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
            }}
          >
            <Div css={{ display: 'flex', alignItems: 'center' }}>
              {!payrollDayDetail?.movements.length && shiftInDay?.shiftName && (
                <Text
                  css={{
                    color: '$interface_dark_down',
                    marginRight: '$4',
                  }}
                  variant="description"
                >
                  {shiftInDay?.shiftName}
                </Text>
              )}
              <Tag
                variant={fromWorkedDayStatusToTagVariant(
                  payrollDayDetail?.hours.status || 'FREE',
                )}
              >
                {fromWorkedDayStatusToLabel(
                  payrollDayDetail?.hours.status || 'FREE',
                )}
              </Tag>
            </Div>
          </Div>
        </Div>
        <Div css={{ marginRight: '$4' }}>
          <Avatar
            src={payrollDayDetail?.schedule.worker.avatar_url || ''}
            alt={payrollDayDetail?.schedule.worker.name || ''}
            width={80}
            height={80}
          />
        </Div>
      </Div>
      <Div
        css={{
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            paddingLeft: '$4',
            paddingBottom: '$4',
            color: '$interface_dark_down',
          }}
        >
          Marcaciones
        </Text>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingLeft: '$4',
            paddingRight: '$4',
          }}
        >
          <SmartTable
            columns={shiftColumns}
            data={shiftData}
            uncontrolled
            style={styles}
            noPadding
            selectedIndex={3}
          />
          <SmartTable
            columns={punchColumns}
            data={punchData}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
      {!!payrollDayDetail?.movements.length && (
        <Div>
          <Text
            variant={'heading4'}
            css={{
              paddingLeft: '$4',
              paddingTop: '$4',
              paddingBottom: '$4',
              color: '$interface_dark_down',
            }}
          >
            Movimientos
          </Text>
          <Div>
            {payrollDayDetail?.movements.map((mov) => (
              <MovementsDayDetail movement={mov} key={mov.id} />
            ))}
          </Div>
        </Div>
      )}
      {!!payrollDayDetail?.vacations.length && (
        <Div>
          <Text
            variant={'heading4'}
            css={{
              paddingLeft: '$4',
              paddingTop: '$4',
              paddingBottom: '$4',
              color: '$interface_dark_down',
            }}
          >
            Vacaciones
          </Text>
          <Div>
            {payrollDayDetail?.vacations.map((mov) => (
              <VacationDayDetail vacation={mov} key={mov.id} />
            ))}
          </Div>
        </Div>
      )}
    </Div>
  )
}
