import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  SmartTableFormItemValue,
  getSmartTableFormItemValue,
} from '@/components/SmartTable/types'
import { parseFormattedDate } from '@/utils/date'
import { z } from 'zod'

export const InvoiceManagerSchema = z.object({
  companyName: z.string(),
  date: z.date().nullable().array(),
  data: z
    .object({
      id: z.string(),
      invoice_id: z.string(),

      status: SmartTableFormItemValue,
      plan: SmartTableFormItemValue,
      count: SmartTableFormItemValue,
      name: SmartTableFormItemValue,
      document: SmartTableFormItemValue,
      email: SmartTableFormItemValue,
      legal_representative: SmartTableFormItemValue,
      activeEmployees: SmartTableFormItemValue,
      inactiveEmployees: SmartTableFormItemValue,
      totalEmployees: SmartTableFormItemValue,
      created_at: SmartTableFormItemValue,

      test_period_start: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      test_period_end: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      payroll_import_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      app_setup_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      face_register_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      punch_register_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),

      entrance_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      cancelation_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),

      contact_email: SmartTableFormItemValue,
      contact_name: SmartTableFormItemValue,
      contact_phone: SmartTableFormItemValue,

      payment_month_day: SmartTableFormItemValue,
      default_external_product_code: SmartTableFormItemValue,
      default_month_invoice_value: SmartTableFormItemValue,
      default_month_invoice_quantity: SmartTableFormItemValue,

      invoice_month_quantity: SmartTableFormItemValue,
      invoice_month_value: SmartTableFormItemValue,
      invoice_external_product_code: SmartTableFormItemValue,
      invoice_payment_status: SmartTableFormItemValue,
      invoice_payment_automatic_status: SmartTableFormItemValue,
    })
    .array(),
})

export type InvoiceManagerSchemaType = z.infer<typeof InvoiceManagerSchema>
export type InvoiceManagerFields = Array<
  ControlledFieldProps<InvoiceManagerSchemaType>
>
