import { Div } from '@/components'
import {
  CloudArrowDownIcon,
  Cog6ToothIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import { Button, Tag, Text } from '@punto-ui/react'

interface ITemplateCardProps {
  title: string
  description: string
  isPublic: boolean
  favorite: boolean
  type: string
  handleEdit: () => void
  handleDownload: () => void
}

export const TemplateCard = (props: ITemplateCardProps) => {
  return (
    <Div
      css={{
        background: '$interface_light_pure',
        padding: '$4',
        borderRadius: '$md',

        display: 'flex',
        flexDirection: 'column',
        gap: '$4',
      }}
    >
      <Div
        css={{
          minWidth: 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Text
            variant={'paragraph'}
            css={{
              color: '$interface_dark_deep',
            }}
          >
            {props.title}
          </Text>
          <Text
            variant={'description'}
            css={{
              color: '$interface_dark_down',
            }}
          >
            {props.description}
          </Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',

            svg: {
              fill: '$brand_primary_pure',
              color: '$brand_primary_pure',
            },
          }}
        >
          <Tag variant={'positive'}>{props.type}</Tag>
          {props.favorite && (
            <Div
              css={{
                svg: {
                  height: '24px',
                  width: '24px',
                },
              }}
            >
              <StarIcon />
            </Div>
          )}
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$4',
        }}
      >
        {!props.isPublic && (
          <Button
            variant={'secondary'}
            onClick={props.handleEdit}
            icon={<Cog6ToothIcon />}
          >
            Editar
          </Button>
        )}
        <Button
          variant={'secondary'}
          icon={<CloudArrowDownIcon />}
          onClick={props.handleDownload}
        >
          Descargar
        </Button>
      </Div>
    </Div>
  )
}
