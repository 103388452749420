import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const handleDeleteMovements = async (ids: string[]) => {
  await apiV2.post<void>('/movement/delete-movements', {
    ids,
  })

  queryClient.invalidateQueries()
}

export const useHandleDeleteMovements = () => {
  const mutation = useCustomMutation(
    ['useHandleDeleteMovements'],
    handleDeleteMovements,
  )

  return mutation
}
