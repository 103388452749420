import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleToggleFavoriteTemplate = async (templateId: string) => {
  await apiV2.post<any>(`/report-builder/toggle-favorite-template`, {
    template_id: templateId,
  })
  queryClient.invalidateQueries('templates')
}

export const useHandleToggleFavoriteTemplate = () => {
  const mutation = useCustomMutation(
    ['useHandleToggleFavoriteTemplate'],
    handleToggleFavoriteTemplate,
  )

  return mutation
}
