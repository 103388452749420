import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const ExportTemplateFilter = z.object({
  id: z.string(),
  column: z.string(),
  condition: z.string(),
  value: z.string().or(z.number()),
  dates: z.date().array(),
  hasValue: z.boolean(),
  columnType: z.string(),
  options: z
    .object({
      label: z.string(),
      value: z.string(),
      requiredInput: z.string().nullable().optional(),
    })
    .array(),
})

export const ExportTemplateSchema = z.object({
  usersIds: z.string().array(),
  payrollIds: z.string().array(),
  aguinaldoIds: z.string().array(),

  day: z.date().array(),
  month: z.string(),
  year: z.string(),
  dates: z.date().array(),

  withHours: z.boolean(),

  groupByColumn: z.string(),

  filters: ExportTemplateFilter.array(),
})

export type ExportTemplateSchemaType = z.infer<typeof ExportTemplateSchema>
export type ExportTemplateFilterType = z.infer<typeof ExportTemplateFilter>
export type ExportTemplateFields = Array<
  ControlledFieldProps<ExportTemplateSchemaType>
>
