import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { useFormContext, useWatch } from 'react-hook-form'

export const useProportionalVacations = (isDiscount = false) => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [proportionalVacation] = useWatch({
    control,
    name: ['data.proportional_vacations'],
  })
  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = isDiscount
      ? [
          {
            _type: 'input',
            name: 'data.discount_proportional_vacations.corresponding_days.value',
            inputType: 'decimal-or-empty',
            disabled: isLoadingLiquidation,
            disclaimer: `Antiguedad: ${proportionalVacation.corresponding_days.disclaimer}`,
            label: 'Días anticipados',
          },
          {
            _type: 'input',
            label: 'Vacaciones a descontar',
            inputType: 'money-guarani',
            name: 'data.discount_proportional_vacations.value',
            disabled: isLoadingLiquidation,
          },
        ]
      : [
          {
            _type: 'input',
            name: 'data.proportional_vacations.corresponding_days.value',
            inputType: 'decimal-or-empty',
            disabled: isLoadingLiquidation,
            disclaimer: `Antiguedad: ${proportionalVacation.corresponding_days.disclaimer}`,
            label: 'Dias que corresponden',
          },
          {
            _type: 'input',
            label: 'Valor a pagar',
            inputType: 'money-guarani',
            name: `data.${
              isDiscount
                ? 'discount_proportional_vacations'
                : 'proportional_vacations'
            }.value`,
            disabled: isLoadingLiquidation,
          },
        ]
    return lastSalaryForm
  }, [isLoadingLiquidation, proportionalVacation, isDiscount])

  return {
    form,
  }
}
