import { FormGroup } from './FormGroup'
import { MarcacionConfig } from './MarcacionConfig'
import { ChildrenData } from './ChildrenData'
import { ContactsData } from './ContactsData'
import { FaceIdPreview } from './FaceIdPreview'
import { PaymentConfig } from './PaymentConfig'
import { ProfilePicturePreview } from './ProfilePicPreview'

export {
  FormGroup,
  MarcacionConfig,
  ChildrenData,
  ContactsData,
  FaceIdPreview,
  PaymentConfig,
  ProfilePicturePreview,
}
