import React, { createContext, useState } from 'react'
import { IMovementContextProps } from '@/libs/react-query/types'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AddMovementSimpleData,
  useMovementFields,
  createMovementSchema,
} from '../form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Div, FormDrawer } from '@/components'
import { BookmarkIcon, PlusIcon } from '@heroicons/react/24/outline'
import {
  useHandleCreateMovements,
  useHandleUpdateMovements,
} from '@/libs/react-query/mutations/movements/'
import useToast from '@/hooks/useToast'
import { useCan, useDebounce } from '@/hooks'
import dayjs from 'dayjs'
import { IMovementEnvelope } from '@/libs/react-query/types/movements'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useSetAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'

interface MovementsDrawerState {
  isOpen: boolean
  params: IMovementEnvelope | null
}

export interface IMovementContextData {
  filter: IMovementContextProps
  handleSubmitCreateForm: any
  setFilter: React.Dispatch<React.SetStateAction<IMovementContextProps>>
  handleClosePopover: () => void
  handleOpenMovementDrawer: (params?: IMovementEnvelope) => void
  popovers: MovementsDrawerState
}

export const Context = createContext<IMovementContextData>(
  {} as IMovementContextData,
)

interface ProviderProps {
  children: React.ReactNode
}

export const ContextProvider = ({ children }: ProviderProps) => {
  const { addToast } = useToast()
  const { mutateAsync: handleCreateMovement } = useHandleCreateMovements()
  const { mutateAsync: handleUpdate } = useHandleUpdateMovements()
  const shouldRequest = useCan(['moves.request'], true)

  const [filter, setFilter] = useState<IMovementContextProps>({
    page: 0,
    types: [],
    endDate: undefined,
    startDate: undefined,
    pageSize: 20,
  })

  const debounceFilter = useDebounce(filter, 500)
  const [popovers, setPopovers] = useState<MovementsDrawerState>({
    isOpen: false,
    params: null,
  })

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('moves')),
  )

  const handleOpenMovementDrawer = (params?: IMovementEnvelope) => {
    const formValues = methods.getValues()

    methods.reset({
      title: '',
      description: '',
      periods: [new Date(), new Date()],
      category: 'absence',
      type: 'SINGLE_PERIOD',
      ips: false,
      remuneration: false,
      period_type: 'range',

      filters: {
        name: formValues.filters?.name || '',
        cellsIds: formValues.filters?.cellsIds || [],
        categories: formValues.filters?.categories || [],
        date: formValues.filters?.date || [],
      },
    })

    if (params) {
      const firstParamPeriod = params.movement.periods?.[0]
      const withHours = params.movement.type === 'SINGLE_DAY'

      const newPeriods =
        firstParamPeriod?.period_start && !withHours
          ? [
              dayjs(
                dayjs(firstParamPeriod?.period_start)
                  .utc()
                  .format('YYYY-MM-DD'),
                'YYYY-MM-DD',
              ).toDate(),
              dayjs(
                dayjs(firstParamPeriod?.period_end).utc().format('YYYY-MM-DD'),
                'YYYY-MM-DD',
              ).toDate(),
            ]
          : withHours
          ? [
              dayjs(
                dayjs(firstParamPeriod?.period_start).format(
                  'YYYY-MM-DD HH:mm:ss',
                ),
                'YYYY-MM-DD HH:mm:ss',
              ).toDate(),
              dayjs(
                dayjs(firstParamPeriod?.period_end).format(
                  'YYYY-MM-DD HH:mm:ss',
                ),
                'YYYY-MM-DD HH:mm:ss',
              ).toDate(),
            ]
          : params.movement.periods.map((period) =>
              dayjs(period.date).utc(true).add(8, 'hours').toDate(),
            )

      methods.reset({
        id: params.movement.id,
        title: params.movement.title,
        description: params.movement.description,
        periods: newPeriods,
        users: params.users.map((u) => u.id),
        remuneration: params.movement.should_pay_period,
        ips: params.movement.should_include_in_ips,
        should_include_hours: !!params.movement.should_include_hours,
        type: params.movement.type,
        category: params.movement.category,
        withHours,
        period_type: params.movement.periods?.[0]?.period_start
          ? 'range'
          : 'multiple',

        filters: {
          name: formValues.filters?.name || '',
          cellsIds: formValues.filters?.cellsIds || [],
          categories: formValues.filters?.categories || [],
          date: formValues.filters?.date || [],
        },
      })
    }

    setPopovers({
      isOpen: true,
      params: params || null,
    })
  }

  const handleClosePopover = () => {
    setPopovers({
      isOpen: false,
      params: null,
    })
  }

  const methods = useForm<AddMovementSimpleData>({
    resolver: zodResolver(createMovementSchema),
    defaultValues: {
      filters: {
        name: '',
        cellsIds: availableCells?.map((c) => c.id) || [],
        categories: [],
        date: [],
      },
    },
  })

  const handleSubmitCreateForm = async (data: AddMovementSimpleData) => {
    try {
      const values = methods.getValues()
      methods.setValue(
        'periods',
        values.periods.filter((p) => p !== null && dayjs(p).isValid()),
      )

      const validatedValues = methods.getValues()

      const isValid = await methods.trigger()

      if (!isValid) {
        // console.log(methods.formState.errors)
        return
      }

      if (popovers?.params && popovers?.params.movement.id) {
        await handleUpdate({
          ...validatedValues,
          movement_id: popovers?.params.movement.id,
        })
        addToast({
          title: shouldRequest
            ? 'Movimiento solicitado'
            : 'Movimiento actualizado',
          description: shouldRequest
            ? 'Soliciatión de actualizacion de movimiento creada correctamente'
            : 'El movimiento se actualizo correctamente',
          type: 'positive',
          id: 1,
        })
      } else {
        await handleCreateMovement(validatedValues)
        addToast({
          title: shouldRequest
            ? 'Movimiento solicitado'
            : 'Movimiento actualizado',
          description: shouldRequest
            ? 'El movimiento fue solicitado'
            : 'El movimiento se creo correctamente',
          type: 'positive',
          id: 1,
        })
      }
      handleClosePopover()
    } catch (error) {
      addToast({
        title: 'Error',
        description: 'Ocurrio un error al crear el movimiento',
        type: 'negative',
        id: 1,
      })
    }
  }

  useSetAvailableCells({
    methods,
    name: 'filters.cellsIds',
    permission: 'moves',
  })

  return (
    <FormProvider {...methods}>
      <Context.Provider
        value={{
          filter: debounceFilter,
          setFilter,
          handleClosePopover,
          handleOpenMovementDrawer,
          popovers,
          handleSubmitCreateForm,
        }}
      >
        {/* <Div> */}
        {children}
        <CreateMovementFormDrawer
          popovers={popovers}
          handleClosePopover={handleClosePopover}
          movementEnvelope={popovers?.params}
          handlePrimaryButton={() =>
            handleSubmitCreateForm(methods.getValues())
          }
        />
        {/* </Div> */}
      </Context.Provider>
    </FormProvider>
  )
}

const CreateMovementFormDrawer = ({
  popovers,
  handleClosePopover,
  handlePrimaryButton,
  movementEnvelope,
}: any) => {
  const { data: movementFields } = useMovementFields()
  const shouldRequest = useCan(['moves.request'], true)
  return (
    <Div>
      <FormDrawer
        title={
          shouldRequest
            ? 'Solicitar Movimiento'
            : movementEnvelope
            ? 'Actualizar Movimiento'
            : 'Agregar Movimiento'
        }
        description={
          movementEnvelope
            ? 'Actualizar Movimiento'
            : 'Adicione um novo movimento'
        }
        icon={
          shouldRequest || movementEnvelope ? (
            <BookmarkIcon width={30} color={'$brand_primary_pure'} />
          ) : (
            <PlusIcon width={30} color={'$brand_primary_pure'} />
          )
        }
        isOpen={popovers.isOpen}
        handleCloseDrawer={() => handleClosePopover()}
        primaryButtonProps={{
          label: shouldRequest
            ? 'Solicitar'
            : movementEnvelope
            ? 'Actualizar'
            : 'Agregar',
        }}
        handlePrimaryButton={handlePrimaryButton}
        secondaryButtonProps={{ label: 'Cancelar' }}
        handleSecondaryButton={() => handleClosePopover()}
        formItems={movementFields}
      />
    </Div>
  )
}
