import { useMemo } from 'react'
import { AguinaldoPlaygroundFields } from '../types'

export const useAguinaldoBasicInfos = (isClosed?: boolean) => {
  const configuration: AguinaldoPlaygroundFields = useMemo(() => {
    const form: AguinaldoPlaygroundFields = [
      {
        _type: 'input',
        label: 'Nombre del aguinaldo',
        name: 'name',
        disabled: isClosed,
        placeholder: 'Aguinaldo 2023.',
        inputType: 'name',
      },
      {
        _type: 'input',
        label: 'Descripción',
        name: 'description',
        disabled: isClosed,
        placeholder: '',
        inputType: 'name',
      },
      {
        _type: 'dropdown',
        disabled: isClosed,
        name: 'year',
        options: [
          {
            label: '2023',
            value: '2023',
          },
          {
            label: '2024',
            value: '2024',
          },
          {
            label: '2025',
            value: '2025',
          },
        ],
        defaultOption: {
          label: '2023',
          value: '2023',
        },
      },
      {
        _type: 'checkbox',
        disabled: isClosed,
        name: 'isFavorite',
        label: 'Favorita',
      },
    ]

    return form
  }, [isClosed])

  return {
    data: configuration,
  }
}
