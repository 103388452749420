import { generateRandomNumberUnderBigMoney } from '@/utils/random'
import { IReportBuilderColumnType, IReportBuilderColumnTypes } from '../type'
import { capitalizeFirstLetter } from '@/utils/workers/name'

export const getConceptColumnByType = (
  concepts: string[],
  type: IReportBuilderColumnTypes,
  ips: boolean,
  subgroup = 'Informaciones del trabajador',
): IReportBuilderColumnType[] => {
  return concepts.map((c) => ({
    type,
    valueType: 'number' as const,
    label: `${capitalizeFirstLetter(c)}${ips ? ' (IPS)' : ''}`,
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    value: `${c.toLowerCase()}_salary_concept${ips ? '_ips' : ''}`,
    subgroup,
  }))
}
