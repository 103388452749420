import { keyframes, styled } from '../../styles'

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateX(100%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translateX(0%) scale(1)' },
})

const dialogContentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, 0%) scale(.3)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const DrawerContainer = styled('div', {
  backgroundColor: '$interface_light_pure',

  pointerEvents: 'all',

  borderTopLeftRadius: '$sm',
  borderBottomLeftRadius: '$sm',
  position: 'fixed',

  zIndex: 9999,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  // position: 'fixed',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  padding: '$8',

  variants: {
    noPadding: {
      true: {
        padding: 0,
      },
    },
    dialog: {
      true: {
        top: '50%',
        left: '50%',
        animation: `${dialogContentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        transform: 'translate(-50%, -50%)',
      },
      false: {
        right: 0,
        top: 0,
        bottom: 0,
        animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
      },
    },
  },
})

export const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$interface_dark_up',
  position: 'absolute',
  top: 8,
  left: 8,
  cursor: 'pointer',

  svg: {
    rotate: '180deg',
  },
})

export const DrawerOverlay = styled('div', {
  zIndex: 9997,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
})
