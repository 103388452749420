import { useHandleUpdatePayrollConcept } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollConcept'
import { useHandleUpdatePayrollFields } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollFields'
import { useHandleUpdatePayrollPayment } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollPayment'
import { useHandleUpdatePayrollSettings } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollSettings'

export const useIsUpdatingPayrollField = () => {
  const { isLoading: isLoadingPayrollConcept } = useHandleUpdatePayrollConcept()
  const { isLoading: isLoadingPayrollFields } = useHandleUpdatePayrollFields()
  const { isLoading: isLoadingPayrollPayment } = useHandleUpdatePayrollPayment()
  const { isLoading: isLoadingPayrollSettings } =
    useHandleUpdatePayrollSettings()

  return (
    isLoadingPayrollConcept ||
    isLoadingPayrollFields ||
    isLoadingPayrollPayment ||
    isLoadingPayrollSettings
  )
}
