import {
  fromDayMonthYearFormatToDateString,
  getLocalStringFromDate,
} from '@/utils/date'
import { z } from 'zod'
import { createCompleteWorkerData } from '../types'
import { safeOnlyDigitsWithoutNegative } from '@/components/Forms/ControlledInput/toPattern'
import dayjs from 'dayjs'

const updateWorkerPayload = z.object({
  user: z.object({
    id: z.string(),
    user: z.object({
      name: z.string().optional(),

      firstName: z.string(),
      surname: z.string(),

      // Temporary
      position: z.string().optional(),
      carreer_plan: z.string().optional(),
      profession: z.string().optional(),

      document: z.string(),
      email: z.string(),
      birthday: z.string().optional(),
      phone1: z.string().optional(),
      phone2: z.string().optional(),
      civilStatus: z.string().optional(),
      nationality: z.string().optional(),
      gender: z.string().optional(),
    }),
    bankAccount: z
      .object({
        bank_name: z.string(),
        bank_account: z.string(),
        isMainAccount: z.boolean(),
      })
      .optional(),
    contacts: z.array(
      z.object({
        id: z.string().optional(),
        name: z.string(),
        document: z.string(),
        phone_number: z.string(),
        relationship: z.string(),
        birthday: z.string(),
        isEmergencyContact: z.boolean(),
      }),
    ),
    children: z.array(
      z.object({
        id: z.string().optional(),
        name: z.string(),
        document: z.string(),
        birthday: z.string(),
        shouldReceiveChildrenBonus: z.boolean(),
      }),
    ),
    address: z
      .object({
        street: z.string().optional(),
        number: z.string().optional(),
        complement: z.string().optional(),
        neighborhood: z.string().optional(),
        city: z.string().optional(),
        state: z.string().optional(),
        country: z.string().optional(),
        latitude: z.string().optional(),
        longitude: z.string().optional(),
      })
      .optional(),
  }),
  companyUser: z.object({
    hiring_date: z.string(),
    ips_registration_date: z.string(),
    punch_config_ids: z.array(z.string()),
    payment_config_id: z.string().nullable(),
    vacation_config_id: z.string().optional().nullable(),
    aguinaldo_config_id: z.string().optional().nullable(),
    liquidation_config_id: z.string().optional().nullable(),

    salaryHistory: z
      .object({
        group_id: z.string().nullable(),
        value: z.number().nullable(),

        start_date: z.string().nullable(),
        end_date: z.string().nullable(),

        concepts: z
          .object({
            name: z.string(),
            value: z.number(),
            ips: z.boolean(),
            description: z.string().optional(),
          })
          .array(),
      })
      .array(),

    externalCodes: z
      .object({
        label: z.string(),
        value: z.string(),
      })
      .array(),
  }),
  cell_id: z.string().optional(),
})

export type UpdateWorkerPayloadType = z.infer<typeof updateWorkerPayload>

export const parseUpdateWorkerStructure = (
  props: createCompleteWorkerData,
): UpdateWorkerPayloadType => {
  const externalCodes: Array<{
    label: string
    value: string
  }> = []

  if (props.datapar_id) {
    externalCodes.push({
      label: 'datapar_user_id',
      value: props.datapar_id,
    })
  }

  if (props.default_config_id) {
    externalCodes.push({
      label: 'default_config_id',
      value: props.default_config_id,
    })
  }

  if (props.marcacion_id) {
    externalCodes.push({
      label: 'marcacion_id',
      value: props.marcacion_id,
    })
  }

  const apiPayload: UpdateWorkerPayloadType = {
    user: {
      id: props.id || '',
      user: {
        document: safeOnlyDigitsWithoutNegative(props.ci),
        email: props.email,
        // name: props.name,

        firstName: props.name,
        surname: props.surname,

        carreer_plan: props.carreer_plan,
        profession: props.profession,
        position: props.position,

        birthday: fromDayMonthYearFormatToDateString(props.birthday_date),
        civilStatus: props.marital_state,
        gender: props.gender,
        nationality: props.nationality,
        phone1: props.phone,
      },
      children:
        props.children?.map((children) => ({
          id: children.id,
          name: children.name,
          birthday: fromDayMonthYearFormatToDateString(children.birthday),
          document: children.document,
          shouldReceiveChildrenBonus: children.shouldReceiveChildrenBonus,
        })) || [],
      contacts:
        props.contacts?.map((contact) => ({
          id: contact.id,
          name: contact.name,
          birthday: contact.birthday,
          document: contact.document,
          isEmergencyContact: true,
          phone_number: contact.phone,
          relationship: contact.relationship,
        })) || [],
      address: {
        city: props.city,
        complement: props.complement,
        country: props.country,
        latitude: '0',
        longitude: '0',
        neighborhood: props.neighborhood,
        number: props.number,
        state: props.state,
        street: props.street,
      },
      bankAccount: {
        bank_name: props.bank_name || '',
        bank_account: props.bank_account || '',
        isMainAccount: true,
      },
    },
    companyUser: {
      payment_config_id: props.payment_config_id || null,
      punch_config_ids: props.punch_config_ids ? [props.punch_config_ids] : [],
      vacation_config_id: props.vacation_config_id || null,
      aguinaldo_config_id: props.aguinaldo_config_id || null,
      liquidation_config_id: props.liquidation_config_id || null,

      // salary_group_id:
      //   props.salary_group_id !== 'RAW_VALUE' ? props.salary_group_id : null,
      // salary_raw_value: props.salary_raw_value,
      salaryHistory:
        props.salaryHistory?.map((salary, index) => {
          const nextStartDate = props.salaryHistory?.[index + 1]
            ?.start_date?.[0]
            ? getLocalStringFromDate(
                dayjs(props.salaryHistory[index + 1]?.start_date?.[0])
                  .startOf('day')
                  .toISOString(),
              )
            : null

          const salaryStartDate = salary.start_date[0]
            ? getLocalStringFromDate(
                dayjs(salary.start_date[0]).startOf('day').toISOString(),
              )
            : null

          return {
            group_id: salary.is_raw_value ? null : salary.group_id || null,
            value: salary.is_raw_value ? salary.value || null : null,

            start_date: salaryStartDate,
            end_date: nextStartDate,

            concepts: salary.concepts.map((concept) => ({
              name: concept.name,
              value: concept.value,
              ips: concept.ips,
              description: '',
            })),
          }
        }) || [],

      hiring_date: fromDayMonthYearFormatToDateString(props.hiring_date),
      ips_registration_date: fromDayMonthYearFormatToDateString(
        props.entrance_ips_date,
      ),
      externalCodes,
    },
    cell_id: props.cell_id,
  }

  return apiPayload
}
