import { queryClient } from '@/libs/react-query'
import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'

const handleDeleteByScheduleIds = async ({ ids }: { ids: string[] }) => {
  await apiV2.post('/shift/delete-schedules', {
    ids,
  })
  queryClient.invalidateQueries()
}

export const useHandleDeleteByScheduleIds = () => {
  const mutation = useMutation(
    ['handleDeleteByScheduleIds'],
    handleDeleteByScheduleIds,
  )

  return mutation
}
