import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '@/libs/react-query/hooks/useMe'

import { IPayment } from '../../types'

const fetchPayment = async (id: string) => {
  const response = await apiV2.get<IPayment>(`/payment/get-payment/${id}`)

  return response.data
}

export const usePayment = (id: string | undefined) => {
  const { data: me } = useMe()

  const query = useQuery(
    ['payment', me?.company_id, id],
    () => fetchPayment(id!),
    {
      keepPreviousData: true,
      enabled: !!me && !!me.company_id && !!id,
    },
  )
  return query
}
