import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { useAvailableCells, usePayments } from '@/libs/react-query/hooks'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { IPayment, paymentsDictionary } from '@/libs/react-query/types'
import dayjs from 'dayjs'
import { Progress } from '@punto-ui/react'
import { formatCurrency } from '@/utils/numbers/currency'
import { shortenName } from '@/utils/workers/name'

export const PaymentStep = () => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payments')),
  )
  const { data: payments, isFetching: isFetchingPayments } = usePayments({
    cellsIds: availableCells?.map((c) => c.id) || [],
    name: '',
    page: 1,
    pageSize: 200,
    types: [],
  })

  const methods = useFormContext<ExportPdfSchemaType>()

  const paymentId = useWatch({
    control: methods.control,
    name: 'paymentId',
  })

  const selectedPayment = payments?.data.find((s) => s.id === paymentId)

  const getPaymentLabel = (s: IPayment) => {
    return `${paymentsDictionary[s.payment_type]} - ${dayjs(
      s.payment_date,
    ).format('DD/MM/YYYY')} - ${shortenName(
      s.users[0]?.name,
    )} - ${formatCurrency(s.users[0]?.payment_final_value)}${
      s.users.length > 1
        ? ` + ${s.users.length - 1} ${
            s.users.length - 1 === 1
              ? `otro colaborador`
              : `otros colaboradores`
          }`
        : ''
    }`
  }

  return (
    <Div>
      <DropdownV2
        value={paymentId || ''}
        defaultValue={paymentId || ''}
        onChangeValue={(value) => {
          if (!value.value) {
            return
          }

          methods.setValue('paymentId', value.value)
          const payment = payments?.data.find((s) => s.id === value.value)
          methods.setValue(
            'usersIds',
            payment?.users.map((u) => u.user_id) || [],
          )
        }}
        defaultOption={{
          label: selectedPayment
            ? getPaymentLabel(selectedPayment)
            : 'Selecciona un pago',
          value: selectedPayment?.id || '',
        }}
        options={
          payments?.data.map((s) => {
            return {
              label: getPaymentLabel(s),
              value: s.id,
            }
          }) || []
        }
      />
      {isFetchingPayments && (
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Progress />
        </Div>
      )}
    </Div>
  )
}
