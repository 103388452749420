import {
  useAddressData,
  useBankForm,
  useCellData,
  useMarcacionAsistenciaForm,
  useOptionalDataForm,
  usePaymentConfigForm,
  useRequiredCompanyData,
  useRequiredDataForm,
  useVacationConfigForm,
} from '../forms'
import { useCallback } from 'react'
import { useFaceIdForm } from '../forms/useFaceIdForm'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useWorkerCreation } from '../context/hooks'
import { usePasswordData } from '../forms/usePasswordData'

export const useGetFormFieldNamesToValidate = () => {
  const { control } = useFormContext<createCompleteWorkerData>()

  const { data: optionalDataForm } = useOptionalDataForm()
  const { data: requiredDataForm } = useRequiredDataForm()
  const { data: marcacionAsistenciaForm } = useMarcacionAsistenciaForm()
  const { data: addressForm } = useAddressData()
  const { data: paymentConfigForm } = usePaymentConfigForm()
  const { data: passwordForm } = usePasswordData()
  const { data: vacationConfigForm } = useVacationConfigForm()
  const { data: companyDataForm } = useRequiredCompanyData()
  const { data: bankForm } = useBankForm()
  const { data: faceIdForm } = useFaceIdForm()
  const { data: cellDataForm } = useCellData()

  const [children, contacts] = useWatch({
    control,
    name: ['children', 'contacts'],
  })

  const { isCreation } = useWorkerCreation()

  const getNameFromInputs = (arr: any[]) => {
    return arr.map((x) => x.name).filter((x) => !!x)
  }

  const getFields = useCallback(
    (formStep?: number) => {
      if (formStep === undefined) {
        return [
          ...getNameFromInputs(optionalDataForm),
          ...getNameFromInputs(requiredDataForm),
          ...getNameFromInputs(addressForm),
          ...getNameFromInputs(bankForm),
          ...getNameFromInputs(marcacionAsistenciaForm),
          ...getNameFromInputs(faceIdForm),
          ...getNameFromInputs(paymentConfigForm),
          ...getNameFromInputs(vacationConfigForm),
          ...getNameFromInputs(companyDataForm),
          ...(children || [])
            ?.map((_, index) => getChildrenFieldNames(index))
            .filter((x) => !!x)
            .flat(),
          ...(contacts || [])
            ?.map((_, index) => getContactsFieldNames(index))
            .filter((x) => !!x)
            .flat(),
        ]
      }

      if (formStep === 0) {
        return [
          ...getNameFromInputs(optionalDataForm),
          ...getNameFromInputs(requiredDataForm),
          ...(isCreation ? getNameFromInputs(passwordForm) : []),
          ...getNameFromInputs(addressForm),
          ...getNameFromInputs(bankForm),
          ...getNameFromInputs(faceIdForm),
          ...(children || [])
            ?.map((_, index) => getChildrenFieldNames(index))
            .filter((x) => !!x)
            .flat(),
          ...(contacts || [])
            ?.map((_, index) => getContactsFieldNames(index))
            .filter((x) => !!x)
            .flat(),
        ]
      }

      if (formStep === 1) {
        return [
          ...getNameFromInputs(marcacionAsistenciaForm),
          ...getNameFromInputs(cellDataForm),
        ]
      }

      if (formStep === 2) {
        return [
          ...getNameFromInputs(paymentConfigForm),
          ...getNameFromInputs(companyDataForm),
        ]
      }

      // if (formStep === 3) {
      //   return [...getNameFromInputs(salaryConfigForm)]
      // }

      return [] as FieldPath<createCompleteWorkerData>[]
    },
    [
      addressForm,
      bankForm,
      companyDataForm,
      marcacionAsistenciaForm,
      optionalDataForm,
      paymentConfigForm,
      requiredDataForm,
      faceIdForm,
      children,
      contacts,
      cellDataForm,
      vacationConfigForm,
    ],
  )

  const getChildrenFieldNames = (index: number) => {
    return [
      `children.${index}.name`,
      `children.${index}.document`,
      `children.${index}.birthday`,
      `children.${index}.shouldReceiveChildrenBonus`,
    ]
  }

  const getContactsFieldNames = (index: number) => {
    return [
      `contacts.${index}.name`,
      `contacts.${index}.document`,
      `contacts.${index}.phone`,
      `contacts.${index}.relationship`,
      `contacts.${index}.birthday`,
    ]
  }

  return {
    data: getFields,
  }
}
