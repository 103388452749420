import { Div } from '@/components'
import { IAguinaldo } from '@/libs/react-query/types/aguinaldo'
import { useTabStore } from '@/store'
import { PencilSquareIcon, StarIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Menu } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { useMemo } from 'react'

export const useAguinaldoListingColumns = () => {
  const columns = useMemo<ColumnDef<IAguinaldo>[]>(
    () => [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        header: '',
        cell: ({ row }) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              svg: {
                color: '$status_warning_pure',
                height: 24,
                width: 24,
              },
            }}
          >
            {row.original.is_favorite ? <StarIcon /> : null}
          </Div>
        ),
        footer: (info) => info.column.id,
        id: 'favorite',
      },
      {
        header: 'Titulo',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.name,
        id: 'movement.title',
      },
      {
        header: 'Colaboradores',
        footer: (info) => info.column.id,
        accessorFn: (row) => row.users.length,
        id: 'workers_visibility',
      },
      {
        header: 'Creacion',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => dayjs(row.created_at).format('DD/MM/YYYY HH:mm'),
        id: 'createdAt',
      },
      {
        header: () => null,
        cell: (info) => <DropdownMenu rowItem={info.row.original} />,
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        id: '_id',
        meta: {
          content: 'component',
        },
      },
    ],
    [],
  )

  return columns
}

interface DropdownMenuProps {
  rowItem: IAguinaldo
}

const DropdownMenu = ({ rowItem }: DropdownMenuProps) => {
  const { addTab, setTab } = useTabStore((state) => ({
    addTab: state.addTab,
    setTab: state.setActiveTab,
  }))

  return (
    <Menu
      items={[
        // {
        //   id: '1',
        //   label: 'Detalles',
        //   icon: <EyeIcon />,
        //   onClick: () => handleOpenPopover('view', rowItem),
        // },
        {
          id: '2',
          label: rowItem.status === 'open' ? 'Editar' : 'Visualizar',
          icon: <PencilSquareIcon />,
          onClick: () => {
            setTimeout(() => {
              addTab('payroll', [
                {
                  id: rowItem.id,
                  type: 'aguinaldo',
                  label: rowItem.name,
                },
              ])
              setTab('payroll', rowItem.id)
            }, 20)
          },
        },
        // {
        //   id: '3',
        //   label: 'Excluir',
        //   icon: (
        //     <TrashIcon
        //       style={{
        //         color: 'red',
        //       }}
        //     />
        //   ),
        //   onClick: () => handleDeleteMovement(),
        // },
        // {
        //   id: '4',
        //   label: 'Excluir para todos',
        //   icon: (
        //     <TrashIcon
        //       style={{
        //         color: 'red',
        //       }}
        //     />
        //   ),
        //   onClick: () => handleDeleteMovementForAll(),
        // },
      ]}
    />
  )
}
