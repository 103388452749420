import { z } from 'zod'
export interface UserProps {
  name: string
  image_url: string
  id: string
  isLeader?: boolean
}

export interface UserChildren extends UserProps {
  _type: 'user'
}

export type GroupData =
  | UserChildren
  | {
      _type: 'group'
      id: string
      name: string
      children: GroupData[]
    }

export const policySchema = z
  .record(
    z.string(),
    z.record(z.string(), z.record(z.string(), z.boolean().optional())),
  )
  .optional()

export const createPolicySchema = z.object({
  name: z.string({ required_error: 'Nombre es requerido' }),
  hasAuthorizations: z.boolean().optional(),

  workers: policySchema,
  punches: policySchema,
  shifts: policySchema,
  moves: policySchema,
  payments: policySchema,
  vacation: policySchema,
  permisos: policySchema,
  configurations: policySchema,
  reports: policySchema,

  users: z.string().array(),
  usersToAuthorize: z.string().array(),
})

export type ICreateCompletePolicyData = z.infer<typeof createPolicySchema>
export type IPolicySchemaType = z.infer<typeof policySchema>
