import React from 'react'
import { CreateShiftContext } from '../index'

export const useShiftCreation = () => {
  const context = React.useContext(CreateShiftContext)

  if (!context) {
    throw new Error('useShiftCreation must be used within a CreateShiftContext')
  }

  return context
}
