import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { ReceiptTableSchema, ReceiptTableSchemaType } from './type'
import React, { useEffect } from 'react'
import { parseReceiptToRow } from './parser'
import { Div, SmartTable, useSmartTableColumnManipulation } from '@/components'
import { SmartColumnType } from '@/components/SmartTable/types'
import { Button } from '@punto-ui/react'
import {
  CloudArrowDownIcon,
  Cog6ToothIcon,
  EyeIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import { ReceiptHubSchemaType } from '../../type'
import { useTabStore } from '@/store'
import { useReportReceipts } from '@/libs/react-query/hooks/useReportReceipts'
import { useHandleToggleFavoriteReceipt } from '@/libs/react-query/mutations/reports/useHandleToggleFavoriteReceipt'
import { ExportReceipt } from '@/components/Drawers/ExportReceipt'

export const ReceiptList = () => {
  const [selectedReceiptId, setSelectedReceiptId] = React.useState('')

  const { addTab, changeTab } = useTabStore((state) => ({
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const methods = useForm<ReceiptTableSchemaType>({
    resolver: zodResolver(ReceiptTableSchema),
    defaultValues: {
      data: [],
      pagination: {
        page: 0,
        perPage: 20,
        total: 0,
        totalPages: 0,
      },
    },
  })

  const hubMethods = useFormContext<ReceiptHubSchemaType>()

  const [pagination] = useWatch({
    control: methods.control,
    name: ['pagination'],
  })

  const [name] = useWatch({
    control: hubMethods.control,
    name: ['configurations.name'],
  })

  const { data: receipts } = useReportReceipts({
    name,
    page: pagination.page,
    perPage: pagination.perPage,
  })

  useEffect(() => {
    if (!receipts) return

    const data = parseReceiptToRow(receipts || [])
    methods.reset(data)
  }, [receipts, methods])

  const columns: SmartColumnType[] = [
    {
      name: 'name',
      header: 'Nombre',
      type: 'input-text',
      width: 350,
      disabled: true,
    },
    {
      name: 'favorite',
      header: 'Fav.',
      width: 100,
      type: 'custom',
      component: ({ index }: { index: number }) => (
        <TemplateListFavorite index={index} />
      ),
    },
    {
      name: 'edit',
      header: 'Editar',
      width: 100,
      type: 'custom',
      component: ({ index }: { index: number }) => (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 100,
          }}
        >
          <Button
            icon={
              methods.getValues(`data.${index}.original.identifier_id`) ? (
                <Cog6ToothIcon />
              ) : (
                <EyeIcon />
              )
            }
            onClick={(e) => {
              addTab('reportsV2', [
                {
                  id: methods.getValues(`data.${index}.id`),
                  type: 'receipt',
                  label: `Editar ${methods.getValues(
                    `data.${index}.name.value`,
                  )}`,
                },
              ])
              changeTab('reportsV2', methods.getValues(`data.${index}.id`))
            }}
            variant={'tertiary'}
            css={{ height: 24, width: '90%' }}
          >
            {methods.getValues(`data.${index}.original.identifier_id`)
              ? 'Editar'
              : 'Ver'}
          </Button>
        </Div>
      ),
    },
    {
      name: 'download',
      header: 'Generar',
      width: 140,
      type: 'custom',
      component: ({ index }: { index: number }) => {
        return (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 140,
            }}
          >
            <Button
              icon={<CloudArrowDownIcon />}
              variant={'secondary'}
              onClick={() => {
                setSelectedReceiptId(methods.getValues(`data.${index}.id`))
              }}
              css={{ height: 24, width: '90%' }}
            >
              Generar
            </Button>
          </Div>
        )
      },
    },
    {
      name: 'description',
      header: 'Descripción',
      width: 200,
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'type',
      header: 'Tipo',
      type: 'input-text',
      width: 120,
      disabled: true,
    },
    {
      name: 'headerType',
      width: 150,
      header: 'Encabezado',
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'footerType',
      width: 250,
      header: 'Pie de página',
      type: 'input-text',
      disabled: true,
    },
    {
      width: 150,
      name: 'created_by',
      header: 'Creado por',
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'created_at',
      header: 'Creado en',
      width: 120,
      type: 'input-text',
      disabled: true,
    },
  ]

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'receiptListColumnsOrder',
    tableHiddenUniqueName: 'receiptListHiddenColumns',
  })

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          '> div': {
            minHeight: 100,
          },
        }}
      >
        <SmartTable
          columns={columns}
          withPagination
          style={{
            borderInLines: true,
          }}
          areColumnsDraggable
          defaultColumnOrder={defaultColumnsOrder}
          defaultHiddenColumns={defaultHiddenColumns}
          saveColumnsOrderCallback={saveColumnsOrderCallback}
          saveHiddenColumnsCallback={saveHiddenColumnsCallback}
          resetColumnOrder={resetStateColumnOrder}
          maxDepth={1}
          paginationProps={{
            onChange: (data) => {
              methods.setValue('pagination.page', data.pageIndex)
              methods.setValue('pagination.perPage', data.pageSize)
            },
            page: pagination.page,
            perPage: pagination.perPage,
            totalPages: pagination.totalPages,
            total: pagination.total,
          }}
        />
      </Div>

      <ExportReceipt
        receiptId={selectedReceiptId}
        isOpen={!!selectedReceiptId}
        setIsOpen={() => setSelectedReceiptId('')}
      />
    </FormProvider>
  )
}

interface TemplateListFavoriteProps {
  index: number
}

const TemplateListFavorite = (props: TemplateListFavoriteProps) => {
  const methods = useFormContext<ReceiptTableSchemaType>()

  const { mutateAsync: handleToggleFavorite } = useHandleToggleFavoriteReceipt()

  const favorite = useWatch({
    control: methods.control,
    name: `data.${props.index}.favorite`,
  })

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,

        svg: {
          fill: '$brand_primary_pure',
          color: '$brand_primary_pure',

          height: 20,
          width: 20,

          cursor: 'pointer',
        },
      }}
    >
      {favorite.value ? (
        <StarIcon
          onClick={() => {
            const item = methods.getValues(`data.${props.index}`)
            handleToggleFavorite(item.id)
          }}
        />
      ) : (
        <Div
          css={{
            height: 55,
            width: 40,

            cursor: 'pointer',
          }}
          onClick={() => {
            const item = methods.getValues(`data.${props.index}`)
            handleToggleFavorite(item.id)
          }}
        />
      )}
    </Div>
  )
}
