import { FormRenderer } from '@/components'
import { FormContainer } from '..'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { useProportionalVacations } from '../form/useProportionalVacations'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'

interface IAntecipateVacationLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const AntecipateVacationLiquidation = ({
  isOpen,
  toggleOpen,
}: IAntecipateVacationLiquidationParams) => {
  const conceptKey = 'vacations_antecipate'

  const { form: proportionalVacationForm } = useProportionalVacations(true)
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [totalVacationValue, vacationDays] = useWatch({
    control,
    name: [
      'data.discount_proportional_vacations.value',
      'data.discount_proportional_vacations.corresponding_days.value',
    ],
  })

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Anticipo de vacaciones'}
        totals={[
          `Dias antecipados: ${vacationDays} dias`,
          `Valor a descontar: ${formatCurrency(+totalVacationValue)}`,
        ]}
        isIpsDisabled={!concept.ips}
        conceptType="basic"
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && (
        <FormRenderer inline items={proportionalVacationForm} cols={4} />
      )}
    </FormContainer>
  )
}
