import { fromRowTypeToLabel } from '@/pages/reportes-v2/ReportBuilder/report-builder'
import {
  IReportBuilderRowTypes,
  ReportBuilderSchemaType,
} from '@/pages/reportes-v2/ReportBuilder/type'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export const useFilterColumnOptions = () => {
  const { control } = useFormContext<ReportBuilderSchemaType>()

  const [columns] = useWatch({
    control,
    name: ['columns'],
  })
  const columnsOptions = useMemo(() => {
    return Object.values(columns)
      .flatMap((c) => c)
      .filter((s) => s.selected)
      .map((c) => ({
        label: `${c.label} - ${
          fromRowTypeToLabel[c.column_type as IReportBuilderRowTypes]
        }`,
        value: `${c.column_type}.${c.value}`,
        type: c.valueType,
      }))
  }, [columns])

  return {
    columns: columnsOptions,
  }
}
