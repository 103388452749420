import { Drawer, DrawerContainer } from '@/components'
import { IRouteTab, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import {
  ChartBarIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  FolderArrowDownIcon,
  RectangleGroupIcon,
} from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import { ReportBuilder } from './ReportBuilder'
import { useTabStore } from '@/store'
import { TemplateHub } from './TemplateHub'
import { useAvailableCells, usePayrolls } from '@/libs/react-query/hooks'
import { ExportedTemplates } from './ExpotedTemplates'
import { useAguinaldos } from '@/libs/react-query/hooks/useAguinaldos/useAguinaldos'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { ReceiptBuilder } from './ReceiptBuilder'
import { ReceiptHub } from './ReceiptHub'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { PdfHub } from './PdfHub'
import { PdfBuilder } from './PdfBuilder'

export default function Reportes({ onlyTabs = false }: { onlyTabs?: boolean }) {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payroll')),
  )

  const { data: isReceiptFeatureAvailable, detail } =
    useIsFeatureInPlan('receipts')

  usePayrolls()
  useAguinaldos({
    cellsIds: availableCells?.map((v) => v.id) || [],
  })

  const possibleLeak = useRef<any>(null)
  const [isExportDrawerOpen, setIsExportDrawerOpen] = useState(false)
  const { actions, changeTab, removeTab, tabsInfos } = useTabStore((state) => ({
    actions: state.reportsV2.actions,

    tabsInfos: state.reportsV2,
    removeTab: state.removeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const changeableTabs: IRouteTab[] = []

  tabsInfos.tabs.forEach((tab) => {
    if (tab.type === 'add-template') {
      changeableTabs.push({
        component: <ReportBuilder />,
        icon: <Cog6ToothIcon />,
        label: tab.label || 'Planilla',
        value: tab.id,
        closeable: true,
        handleClose: () => {
          removeTab('reportsV2', tab.id)
          possibleLeak.current = setTimeout(() => {
            changeTab('reportsV2', 'template-hub')
          }, 20)
        },
      })
    }

    if (tab.type === 'add-receipt') {
      changeableTabs.push({
        component: <ReceiptBuilder />,
        icon: <Cog6ToothIcon />,
        label: tab.label || 'Planilla',
        value: tab.id,
        closeable: true,
        handleClose: () => {
          removeTab('reportsV2', tab.id)
          possibleLeak.current = setTimeout(() => {
            changeTab('reportsV2', 'template-hub')
          }, 20)
        },
      })
    }

    if (tab.type === 'add-pdf') {
      changeableTabs.push({
        component: (
          <PdfBuilder id={tab.metadata?.creation ? undefined : tab.id} />
        ),
        icon: <DocumentDuplicateIcon />,
        label: tab.label || 'PDF',
        value: tab.id,
        closeable: true,
        handleClose: () => {
          removeTab('reportsV2', tab.id)
          possibleLeak.current = setTimeout(() => {
            changeTab('reportsV2', 'pdf-hub')
          }, 20)
        },
      })
    }

    if (tab.type === 'template') {
      changeableTabs.push({
        component: <ReportBuilder id={tab.id} />,
        icon: <Cog6ToothIcon />,
        label: tab.label || 'Planilla',
        value: tab.id,
        closeable: true,
        handleClose: () => {
          removeTab('reportsV2', tab.id)
          possibleLeak.current = setTimeout(() => {
            changeTab('reportsV2', 'template-hub')
          }, 20)
        },
      })
    }

    if (tab.type === 'receipt') {
      changeableTabs.push({
        component: <ReceiptBuilder id={tab.id} />,
        icon: <Cog6ToothIcon />,
        label: tab.label || 'Receibo',
        value: tab.id,
        closeable: true,
        handleClose: () => {
          removeTab('reportsV2', tab.id)
          possibleLeak.current = setTimeout(() => {
            changeTab('reportsV2', 'receipt-hub')
          }, 20)
        },
      })
    }
  })

  // const { tabsInfos, addTab, changeTab, removeTab } = useTabStore((state) => ({
  //   tabsInfos: state.payroll,
  //   removeTab: state.removeTab,
  //   addTab: state.addTab,
  //   changeTab: state.setActiveTab,
  // }))

  useEffect(() => {
    return () => {
      clearTimeout(possibleLeak.current)
    }
  }, [])

  return (
    <DrawerContainer
      open={isExportDrawerOpen}
      onOpenChange={() => setIsExportDrawerOpen(false)}
    >
      <Router
        onlyTabs={onlyTabs}
        actions={actions}
        defaultTab="template-hub"
        setTab={(tab) => {
          changeTab('reportsV2', tab)
        }}
        tab={tabsInfos.activeTab || 'template-hub'}
        tabs={[
          {
            icon: <FolderArrowDownIcon />,
            label: 'Exportaciones',
            value: 'exports',
            component: <ExportedTemplates />,
          },
          {
            icon: <RectangleGroupIcon />,
            label: 'Mis Tablas',
            value: 'template-hub',
            component: <TemplateHub />,
          },
          {
            icon: <DocumentIcon />,
            label: 'Mis PDFs',
            value: 'pdf-hub',
            component: <PdfHub />,
          },
          {
            icon: <ClipboardDocumentListIcon />,
            label: 'Mis Recibos',
            value: 'receipt-hub',
            disabled: !isReceiptFeatureAvailable,
            disableMessage: detail,
            component: <ReceiptHub />,
          },
          {
            icon: <ChartBarIcon />,
            label: 'Mis Gráficos',
            disabled: true,
            value: 'graph-hub',
            component: null,
          },
          // {
          //   icon: <RectangleGroupIcon />,
          //   label: 'Generador de Templates',
          //   value: 'generator',
          //   component: <ReportBuilder />,
          // },
          // {
          //   icon: <DocumentTextIcon />,
          //   label: 'Generador de Recibos',
          //   value: 'receipts',
          //   component: <ReceiptBuilder />,
          // },
          ...changeableTabs,
        ]}
      />
      <Drawer open={isExportDrawerOpen} content={<>Hello</>} />
    </DrawerContainer>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx: any) => {
  return {
    props: {},
  }
})
