import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { ListBulletIcon } from '@heroicons/react/24/outline'
import { WorkersProvider } from './context'
import { WorkersList } from './components'
import { useWorkerPage } from './context/hooks/useWorkerPage'
import { ControlledFilterBar, Div } from '@/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddWorkerSimpleData } from './forms'
import { useDebounce } from '@/hooks'
import { useWorkers } from '@/libs/react-query/hooks'
import { useTabStore } from '@/store'
import CreateWorker from './create/index.page'
import { useEffect, useRef } from 'react'
import { useHandleDeactivateUser } from '@/libs/react-query/mutations'
import { useHandleWorkersActions } from './actions'
import { ImportWorkers } from './import'
import { InactivateWorker } from './inactivate'
import { LiquidatedWorkers } from './components/LiquidatedWorkers'

export default function Colaboradores({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  return (
    <WorkersProvider>
      <WorkersPage onlyTabs={onlyTabs} />
    </WorkersProvider>
  )
}

const WorkersPage = ({ onlyTabs = false }: { onlyTabs?: boolean }) => {
  const { filter } = useWorkerPage()
  const { control } = useFormContext<AddWorkerSimpleData>()

  useHandleWorkersActions()

  const {
    tabs: rawTabs,
    activeTab,
    setActiveTab,
    removeTab,
    actions,
  } = useTabStore((state) => ({
    tabs: state.worker.tabs,
    removeTab: state.removeTab,
    setActiveTab: state.setActiveTab,
    activeTab: state.worker.activeTab,
    actions: state.worker.actions,
  }))
  const remove = useRef<any>()

  useEffect(() => {
    return () => {
      clearTimeout(remove.current)
    }
  }, [])

  const [name, cellsIds] = useWatch({
    control,
    name: ['filters.name', 'filters.cellsIds'],
  })

  const debouncedName = useDebounce(name, 200)

  const { isFetching: isFetchingWorkers } = useWorkers({
    ...filter,
    name: debouncedName,
    cellsIds,
  })

  const { isLoading: isLoadingDeactivate } = useHandleDeactivateUser()

  const tabs = rawTabs.map((tab) => {
    const liquidationId = tab.metadata?.liquidationId

    return {
      icon: <ListBulletIcon />,
      label: tab.label || 'Nuevo Colaborador',
      value: tab.id,
      scrollable: tab.type !== 'inactivate-worker' && tab.type !== 'import',
      closeable: true,
      handleClose: () => {
        removeTab('worker', tab.id)
        remove.current = setTimeout(() => {
          setActiveTab(
            'worker',
            tab.type === 'inactivate-worker' ? 'liquidated-list' : 'list',
          )
        }, 1)
      },
      component:
        tab.type === 'create' ? (
          <CreateWorker />
        ) : tab.type === 'import' ? (
          <ImportWorkers />
        ) : tab.type === 'inactivate-worker' ? (
          <InactivateWorker liquidationId={liquidationId} />
        ) : tab.type === 'edit-worker' ? (
          <CreateWorker />
        ) : (
          <CreateWorker />
        ),
    }
  })

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="list"
      setTab={(id) => setActiveTab('worker', id)}
      actions={actions}
      tab={activeTab || 'list'}
      tabs={[
        {
          icon: <ListBulletIcon />,
          label: 'Listado',
          value: 'list',
          scrollable: true,
          component: (
            <>
              <ControlledFilterBar
                withAdvancedCells
                nameFilterName="filters.name"
                cellsIdsName="filters.cellsIds"
                isLoading={isFetchingWorkers || isLoadingDeactivate}
                policiesIds={permissionsArray.filter((p) =>
                  p.includes('workers'),
                )}
              />
              <Div css={{ marginBottom: '$4' }} />
              <Div css={{ marginLeft: '$4', marginRight: '$4' }}>
                <WorkersList />
              </Div>
            </>
          ),
        },
        {
          icon: <ListBulletIcon />,
          label: 'Colaboradores Liquidados',
          value: 'liquidated-list',
          scrollable: true,
          component: <LiquidatedWorkers />,
        },
        ...tabs,
      ]}
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
