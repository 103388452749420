import { FormContainer } from '..'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'
import { FamilyBonusLiquidation } from './FamilyBonusLiquidation'
import { ProportionalSalaryLiquidation } from './ProportionalSalaryLiquidation'
import { useState } from 'react'
import { LiquidationOtherConcept } from './LiquidationOtherConcept'
import { usePaymentsAndDiscountsConcepts } from '../hooks/usePaymentsAndDiscountsConcepts'
import { DiscountsProportionalSalaryLiquidation } from './DiscountsProportionalSalaryLiquidation'
import { useProportionalSalaryTotals } from '../hooks/useProportionalSalaryTotals'

interface ITotalProportionalSalaryLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const TotalProportionalSalaryLiquidation = ({
  isOpen,
  toggleOpen,
}: ITotalProportionalSalaryLiquidationParams) => {
  const [conceptsOpened, setConceptsOpened] = useState<{
    familyBonus: boolean
    proportional_salary: boolean
    proportional_discount_salary: boolean
    total_proportional_salary: boolean
    paymentConcepts: boolean[]
    discountConcepts: boolean[]
  }>({
    total_proportional_salary: false,
    proportional_discount_salary: false,
    familyBonus: false,
    proportional_salary: false,
    paymentConcepts: Array.from({ length: 99 }).map(() => false),
    discountConcepts: Array.from({ length: 99 }).map(() => false),
  })

  const conceptKey = 'total_proportional_salary'

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })

  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  const {
    discountConcepts: discountConceptsWithOldIndex,
    paymentConcepts: paymentConceptsWithOldIndex,
  } = usePaymentsAndDiscountsConcepts(['payment', 'salary_concept'])

  const { proportional_salary_total_value: totalValue } =
    useProportionalSalaryTotals()

  return (
    <FormContainer
      css={{
        '> div:not(:first-child)': {
          padding: 0,

          marginLeft: '$4',
          marginBottom: 0,

          borderBottom: '1px solid $interface_light_down',

          borderRadius: 0,
          paddingBottom: 16,
        },

        '> div:last-child': {
          borderBottom: 'none',
        },

        '> div:first-child': {
          paddingBottom: isOpen ? 14 : 0,
          borderBottom: isOpen ? '1px solid $interface_light_down' : undefined,
        },
      }}
    >
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Salário Proporcional'}
        total={formatCurrency(+totalValue)}
        withoutIpsButton
        withoutStatusButton
        withoutNameEditing
        conceptType="basic"
        disabled={isConceptDeactivated || false}
      />

      {!isConceptClosed && (
        <>
          <ProportionalSalaryLiquidation
            isOpen={conceptsOpened.proportional_salary}
            toggleOpen={
              function (v: boolean): void {
                setConceptsOpened({
                  ...conceptsOpened,
                  proportional_salary: v,
                })
              } as any
            }
          />
          <DiscountsProportionalSalaryLiquidation
            isOpen={conceptsOpened.proportional_discount_salary}
            toggleOpen={(b) =>
              setConceptsOpened({
                ...conceptsOpened,
                proportional_discount_salary: b,
              })
            }
          />

          <FamilyBonusLiquidation
            isOpen={conceptsOpened.familyBonus}
            toggleOpen={
              function (v: boolean): void {
                setConceptsOpened({
                  ...conceptsOpened,
                  familyBonus: v,
                })
              } as any
            }
          />
          {paymentConceptsWithOldIndex.map((conceptEnvelope) => (
            <LiquidationOtherConcept
              index={conceptEnvelope.index}
              key={`${conceptEnvelope.concept.id}${conceptEnvelope.concept.name}`}
              isOpen={conceptsOpened.paymentConcepts[conceptEnvelope.index]}
              toggleOpen={(b) =>
                setConceptsOpened({
                  ...conceptsOpened,
                  paymentConcepts: conceptsOpened.paymentConcepts.map((c, i) =>
                    i === conceptEnvelope.index ? b : c,
                  ),
                })
              }
              {...conceptEnvelope.concept}
            />
          ))}

          {discountConceptsWithOldIndex.map((conceptEnvelope) => (
            <LiquidationOtherConcept
              isDiscount
              index={conceptEnvelope.index}
              key={`${conceptEnvelope.concept.id}${conceptEnvelope.concept.name}`}
              isOpen={conceptsOpened.discountConcepts[conceptEnvelope.index]}
              toggleOpen={(b) =>
                setConceptsOpened({
                  ...conceptsOpened,
                  discountConcepts: conceptsOpened.discountConcepts.map(
                    (c, i) => (i === conceptEnvelope.index ? b : c),
                  ),
                })
              }
              {...conceptEnvelope.concept}
            />
          ))}
        </>
      )}
    </FormContainer>
  )
}
