import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'

import { ConfigProvider } from './context'

import { PaymentConfigurationGroups } from './components/PaymentGroups'
import { PunchesConfigurationGroups } from './components/PunchesGroups'
import { SalaryGroupsConfiguration } from './components/SalaryGroups'
import { CompanyPlaces } from './components/CompanyPlaces'
import {
  BanknotesIcon,
  CurrencyDollarIcon,
  DevicePhoneMobileIcon,
  FingerPrintIcon,
  HomeIcon,
  MapPinIcon,
  PaperClipIcon,
  ReceiptRefundIcon,
  SunIcon,
} from '@heroicons/react/24/outline'
import { HolidayConfigs } from './components/HolidaysConfig'
import { TotemsConfiguration } from './components/Totens'
import { VacationsConfigs } from './components/VacationsConfig'
import { AguinaldosConfigs } from './components/AguinaldosConfig'
import { useTabStore } from '@/store'
import { LiquidationsConfigs } from './components/LiquidationConfig'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useCan } from '@/hooks'

export default function Configuraciones({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const { setActiveTab, tab } = useTabStore((state) => ({
    tab: state.configurations.activeTab,
    setActiveTab: state.setActiveTab,
  }))

  const { data: isSalaryAvailable, detail: salaryDetail } = useIsFeatureInPlan(
    'configuration-salaries',
  )
  const { data: isVacationAvailable, detail: vacationDetail } =
    useIsFeatureInPlan('configuration-vacations')
  const { data: isAguinaldoAvailable, detail: aguinaldoDetail } =
    useIsFeatureInPlan('configuration-aguinaldos')
  const { data: isLiquidationAvailable, detail: liquidationDetail } =
    useIsFeatureInPlan('configuration-liquidation')

  const canSeePayments = useCan([
    'configurations.payments',
    'configurations.view',
  ])
  const canSeePunches = useCan([
    'configurations.punches',
    'configurations.view',
  ])
  const canSeeLocations = useCan([
    'configurations.locations',
    'configurations.view',
  ])
  const canSeeHolidays = useCan([
    'configurations.holidays',
    'configurations.view',
  ])
  const canSeeTotems = useCan(['configurations.totems', 'configurations.view'])
  const canSeeSalary = useCan(['configurations.salary', 'configurations.view'])
  const canSeeVacations = useCan([
    'configurations.vacations',
    'configurations.view',
  ])
  const canSeeAguinaldos = useCan([
    'configurations.aguinaldos',
    'configurations.view',
  ])
  const canSeeLiquidations = useCan([
    'configurations.liquidations',
    'configurations.view',
  ])

  const defaultTab = canSeePayments
    ? 'payment'
    : canSeePunches
    ? 'punch'
    : canSeeLocations
    ? 'location'
    : canSeeHolidays
    ? 'holiday'
    : canSeeTotems
    ? 'totem'
    : canSeeSalary
    ? 'salary'
    : canSeeVacations
    ? 'vacations'
    : canSeeAguinaldos
    ? 'aguinaldos'
    : canSeeLiquidations
    ? 'liquidation'
    : ''

  return (
    <ConfigProvider>
      <Router
        onlyTabs={onlyTabs}
        defaultTab={tab || defaultTab}
        tab={tab || defaultTab}
        setTab={(tab) => setActiveTab('configurations', tab)}
        tabs={[
          {
            icon: <CurrencyDollarIcon />,
            label: 'Pagos',
            value: 'payment',
            disabled: !canSeePayments,
            component: <PaymentConfigurationGroups />,
          },
          {
            icon: <FingerPrintIcon />,
            label: 'Marcación',
            value: 'punch',
            disabled: !canSeePunches,
            component: <PunchesConfigurationGroups />,
          },
          {
            icon: <MapPinIcon />,
            label: 'Localidades de la Empresa',
            value: 'location',
            disabled: !canSeeLocations,
            component: <CompanyPlaces />,
          },
          {
            icon: <HomeIcon />,
            label: 'Feriados',
            value: 'holiday',
            disabled: !canSeeHolidays,
            component: <HolidayConfigs />,
          },
          {
            icon: <DevicePhoneMobileIcon />,
            label: 'Totems',
            disabled: !canSeeTotems,
            value: 'totem',
            component: <TotemsConfiguration />,
          },
          {
            icon: <BanknotesIcon />,
            label: 'Salarios',
            value: 'salary',
            component: <SalaryGroupsConfiguration />,
            disabled: !isSalaryAvailable || !canSeeSalary,
            disableMessage: salaryDetail,
          },
          {
            icon: <SunIcon />,
            label: 'Vacaciones',
            value: 'vacations',
            component: <VacationsConfigs />,
            disabled: !isVacationAvailable || !canSeeVacations,
            disableMessage: vacationDetail,
          },
          {
            icon: <PaperClipIcon />,
            label: 'Aguinaldos',
            value: 'aguinaldos',
            component: <AguinaldosConfigs />,
            disabled: !isAguinaldoAvailable || !canSeeAguinaldos,
            disableMessage: aguinaldoDetail,
          },
          {
            icon: <ReceiptRefundIcon />,
            label: 'Liquidaciones',
            value: 'liquidation',
            component: <LiquidationsConfigs />,
            disabled: !isLiquidationAvailable || !canSeeLiquidations,
            disableMessage: liquidationDetail,
          },
        ]}
      />
    </ConfigProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
