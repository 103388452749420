import { Row, flexRender, Table as ReactTable } from '@tanstack/react-table'
import { GroupData } from '../../../../types'
import { useGroupBuilder } from '../../../../context'
import React, { useEffect, useRef } from 'react'
import { useOutsideAlerter } from '@/hooks'
import { Text, styled } from '@punto-ui/react'
import { useReorderRows } from '../../../../hooks'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { TableData } from '../../styles'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { EditingCell } from '../EditingCell'

export const DraggableRow: React.FC<{
  row: Row<GroupData>
  index: number
  table: ReactTable<GroupData>
}> = ({ row, index, table }) => {
  const { data: reorderRow } = useReorderRows()

  const { cancelAddOperation, editingRow, groups } = useGroupBuilder()

  const [isDragging, setIsDragging] = React.useState(false)
  const [isOver, setIsOver] = React.useState(false)
  const [canDrop, setCanDrop] = React.useState(false)

  const dragRef = useRef<HTMLTableRowElement | null>(null)
  const dropRef = useRef<HTMLTableRowElement | null>(null)

  useEffect(() => {
    if (!dragRef.current || !dropRef.current) return

    return combine(
      draggable({
        element: dragRef.current,
        getInitialData: () => {
          return {
            row,
            type: 'row',
          }
        },
        onDragStart: () => {
          setIsDragging(true)
        },
        onDrop: () => {
          setIsDragging(false)
        },
      }),
      dropTargetForElements({
        element: dropRef.current,
        canDrop: ({ source }) => {
          const draggedRow = source.data.row as Row<GroupData>
          const canDropVal =
            source.data.type === 'row' &&
            draggedRow.original.id !== row.original.id &&
            ((row.original._type === 'user' &&
              draggedRow.original._type === 'user') ||
              (row.original._type === 'group' &&
                draggedRow.original._type === 'user') ||
              (row.original._type === 'group' &&
                draggedRow.original._type === 'group'))

          setCanDrop(canDropVal)
          return canDropVal
        },
        onDragEnter: () => {
          setIsOver(true)
        },
        onDragLeave: () => {
          setIsOver(false)
        },
        onDrop: ({ source }) => {
          const draggedRow = source.data.row as Row<GroupData>
          previousIsExpanded.current = true
          reorderRow(draggedRow.original.id, row.original.id)

          setIsOver(false)
        },
      }),
    )
  }, [groups])

  const previousIsExpanded = useRef(false)
  const showCaseExpansion = useRef(false)

  useEffect(() => {
    if (!showCaseExpansion.current) {
      previousIsExpanded.current = row.getIsExpanded()
    }
  }, [row])

  // Keep Matriz opened
  useEffect(() => {
    if (row.original.name === 'Matriz') {
      row.toggleExpanded(true)
    }
  }, [row])

  // useEffect(() => {
  //   if (!isDragging && isOver && !isDroppingInSameRow) {
  //     showCaseExpansion.current = true
  //     row.toggleExpanded(true)
  //   } else if (!isDragging && !isDroppingInSameRow) {
  //     showCaseExpansion.current = false
  //     row.toggleExpanded(previousIsExpanded.current)
  //   } else if (isDragging) {
  //     showCaseExpansion.current = false
  //     row.toggleExpanded(false)
  //   }
  // }, [isOver, row, isDroppingInSameRow, isDragging])

  const isAddGroup = row.original._type === 'add'
  const isGroup = row.original._type === 'group'
  const tableDataRef = useRef<HTMLTableRowElement | null>(null)

  useOutsideAlerter(tableDataRef, () => {
    cancelAddOperation()
  })

  const isOverAndAllowed =
    isOver &&
    canDrop &&
    (row.original._type === 'user' || row.original._type === 'group')
  // console.log(
  //   isOver,
  //   canDrop,
  //   row.original._type === 'user' || row.original._type === 'group',
  // )
  return (
    <>
      <SimpleTableRow
        ref={(e) => {
          dragRef.current = e
          dropRef.current = e

          if (isAddGroup || editingRow?.id === row.original.id) {
            tableDataRef.current = e
          }
        }}
        css={{
          opacity: isDragging ? 0.3 : 1,
          // background: isOver ? '$interface_light_up' : '$interface_light_pure',
          // background: 'red',

          borderRadius: 8,
          borderWidth: 0,

          // '&:hover': {
          //   '> td': {
          //     backgroundColor: '$interface_light_down',
          //   },
          // },
        }}
      >
        <TableData
          css={{
            borderBottom: '1px solid',
            borderBottomColor: '$interface_dark_up',
            // backgroundColor:
            //   row.original._type === 'add'
            //     ? '$interface_light_up'
            //     : '$interface_light_pure',
          }}
        >
          {row.getVisibleCells().map((cell) => {
            if (
              !cell.column.columnDef.meta?.isEditable &&
              cell.column.columnDef.meta?.content !== 'component'
            ) {
              return (
                <TableData key={cell.id}>
                  <Text
                    variant="paragraph"
                    css={{
                      textAlign: 'left',
                      color: isGroup
                        ? '$brand_primary_pure'
                        : '$interface_dark_deep',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Text>
                </TableData>
              )
            } else if (!cell.column.columnDef.meta?.isEditable) {
              return (
                <TableData
                  key={cell.id}
                  css={{
                    ...cell.column.columnDef.meta?.tdStyles,
                    backgroundColor:
                      cell.row.original._type === 'add'
                        ? '$interface_light_up'
                        : '$interface_light_pure',
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              )
            } else {
              return (
                <TableData key={cell.id}>
                  <EditingCell
                    getValue={cell.getValue}
                    row={cell.row}
                    column={cell.column}
                    table={table}
                  />
                </TableData>
              )
            }
          })}
          <Div
            css={{
              height: 50,
              maxHeight: isOverAndAllowed ? 50 : 0,
              transition: 'all 0.1s ease-in-out',
              backgroundColor: '$interface_light_deep',
              opacity: 0.5,
              width: '100%',
            }}
          />
        </TableData>
      </SimpleTableRow>
    </>
  )
}

const SimpleTableRow = styled('tr', {})
const Div = styled('div', {})
