import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { allMonths } from '@/components/InputDatePicker/utils'
import { z } from 'zod'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
})

export const inactivateWorkerDataSchema = z.object({
  // reasons
  user_id: z.string().uuid(),

  payment_config_id: z.string(),
  aguinaldo_config_id: z.string(),
  liquidation_config_id: z.string(),
  vacation_config_id: z.string(),

  antiquity: z.object({
    years: z.number().or(z.string().length(0)),
    months: z.number().or(z.string().length(0)),
    days: z.number().or(z.string().length(0)),
  }),

  id: z.string().uuid(),
  inactivation_type: z.string(),
  use_ips_date: z.boolean(),
  contract_type: z.string(),
  entrance_date: z.date().nullable().array(),
  signature_date: z.date().nullable().array(),
  entrance_date_ips: z.date().nullable().array(),
  inactivation_date: z.date().nullable().array(),
  complement: z.string(),
  reason: z.string(),
  status: z.string(),

  // salary
  base_salary: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  payed_days: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  proportional_salary: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  extra_morning: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  holiday_morning: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  discount_morning: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  ordinary_night: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  extra_night: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  holiday_night: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  discount_night: z.object({
    disclaimer: z.string(),
    value: z.number().or(z.string().length(0)),
  }),
  total_positive_salary: z.number().or(z.string().length(0)),
  total_negative_salary: z.number().or(z.string().length(0)),

  total_positive_salary_ips: z.number().or(z.string().length(0)),
  total_negative_salary_ips: z.number().or(z.string().length(0)),
  // aguinaldo_config: z.object({

  // aguinaldo
  aguinaldo_months: z
    .object({
      label: z.string(),
      value: z.number().or(z.string().length(0)),
      disclaimer: z.string(),
      payrollId: z.string(),
      month: z.number().or(z.string().length(0)),
    })
    .array(),
  aguinaldo: z.number().or(z.string().length(0)),

  // salaries
  salaries: z
    .object({
      label: z.string(),
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
      payrollId: z.string(),
      month: z.number().or(z.string().length(0)),
    })
    .array(),
  salary_average: z.number().or(z.string().length(0)),
  daily_salary: z.number().or(z.string().length(0)),

  vacations: z
    .object({
      year: z.object({
        label: z.string(),
        value: z.number().or(z.string().length(0)),
      }),
      used_days: z.number().or(z.string().length(0)),
      earned_days: z.number().or(z.string().length(0)),
      value: z.number().or(z.string().length(0)),
    })
    .array(),
  accumulatedVacations: z
    .object({
      year: z.object({
        label: z.string(),
        value: z.number().or(z.string().length(0)),
      }),
      used_days: z.number().or(z.string().length(0)),
      earned_days: z.number().or(z.string().length(0)),
      value: z.number().or(z.string().length(0)),
    })
    .array(),
  total_vacations: z.object({
    total_caused_vacations: z.number().or(z.string().length(0)),
    used_days: z.number().or(z.string().length(0)),
    earned_days: z.number().or(z.string().length(0)),
    value: z.number().or(z.string().length(0)),
    valueIps: z.number().or(z.string().length(0)),
  }),
  total_accumulated_vacations: z.object({
    total_caused_vacations: z.number().or(z.string().length(0)),
    used_days: z.number().or(z.string().length(0)),
    earned_days: z.number().or(z.string().length(0)),
    value: z.number().or(z.string().length(0)),
    valueIps: z.number().or(z.string().length(0)),
  }),

  proportional_vacations: z.object({
    corresponding_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    received_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    missing_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    value: z.number().or(z.string().length(0)),
    valueIps: z.number().or(z.string().length(0)),
  }),
  discount_proportional_vacations: z.object({
    corresponding_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    received_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    missing_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    value: z.number().or(z.string().length(0)),
    valueIps: z.number().or(z.string().length(0)),
  }),
  pre_warning: z.object({
    totalDays: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    received_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    missing_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    value: z.number().or(z.string().length(0)),
  }),
  discount_pre_warning: z.object({
    totalDays: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string()),
    }),
    received_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string()),
    }),
    missing_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string()),
    }),
    value: z.number().or(z.string()),
  }),
  indemnification: z.object({
    corresponding_days: z.object({
      disclaimer: z.string(),
      value: z.number().or(z.string().length(0)),
    }),
    value: z.number().or(z.string().length(0)),
  }),
  other_discounts: z
    .object({
      label: z.string(),
      value: z.number().or(z.string().length(0)),
      id: z.string().nullable(),
    })
    .array(),
  paymentConcepts: z
    .object({
      id: z.string(),
      type: z.string(),
      name: z.string(),
      amount: z.number().or(z.string().length(0)),
      value: z.number().or(z.string().length(0)),
    })
    .array(),
  discountConcepts: z
    .object({
      id: z.string(),
      type: z.string(),
      name: z.string(),
      amount: z.number().or(z.string().length(0)),
      value: z.number().or(z.string().length(0)),
    })
    .array(),
  familiarBonus: z.object({
    value: z.number().or(z.string().length(0)),
    children: z.number().or(z.string().length(0)),
  }),
})

export const inactivateWorkerSchema = z.object({
  liquidationId: z.string().optional(),
  data: inactivateWorkerDataSchema,
  original: inactivateWorkerDataSchema,
  user_in_ips: z.boolean(),
  enabled_changes: z.boolean().optional(),

  conceptsStatus: z.record(
    z.string(),
    z.object({
      type: z.string(), // 'basic' | 'payment' | 'other_concepts'
      entity_id: z.string(),

      is_debit: z.boolean().nullable(),

      is_active: z.boolean().nullable(),
      name: z.string().nullable(),
      ips: z.boolean().nullable(),
    }),
  ),
  ips: z
    .object({
      label: z.string(),
      type: z.string(),
      value: z.number(),
      valueIps: z.number(),
      ips9: z.number(),
      ips16: z.number(),
    })
    .array(),
  ipsValues: z.object({
    totalValue: z.number(),
    ipsValue: z.number(),
    ips9Value: z.number(),
    ips16Value: z.number(),
  }),
})

export type InactivateWorkerSchema = z.infer<typeof inactivateWorkerSchema>
export type InactivateWorkerDataSchema = z.infer<
  typeof inactivateWorkerDataSchema
>
export type InactiveWorkerField = ControlledFieldProps<InactivateWorkerSchema>

export const DEFAULT_INACTIVATE_WORKER: InactivateWorkerDataSchema = {
  id: '',
  status: '',
  user_id: '',
  use_ips_date: false,
  inactivation_type: '',
  contract_type: '',
  entrance_date: [null],
  entrance_date_ips: [null],
  inactivation_date: [null],
  signature_date: [null],
  reason: '',
  complement: '',

  familiarBonus: {
    value: 0,
    children: 0,
  },

  antiquity: {
    years: 0,
    months: 0,
    days: 0,
  },

  aguinaldo_config_id: '',
  liquidation_config_id: '',
  payment_config_id: '',
  vacation_config_id: '',

  // salary
  base_salary: {
    disclaimer: '',
    value: 0,
  },
  payed_days: {
    disclaimer: '',
    value: 0,
  },
  proportional_salary: {
    disclaimer: '',
    value: 0,
  },
  extra_morning: {
    disclaimer: '',
    value: 0,
  },
  holiday_morning: {
    disclaimer: '',
    value: 0,
  },
  discount_morning: {
    disclaimer: '',
    value: 0,
  },
  ordinary_night: {
    disclaimer: '',
    value: 0,
  },
  extra_night: {
    disclaimer: '',
    value: 0,
  },
  holiday_night: {
    disclaimer: '',
    value: 0,
  },
  discount_night: {
    disclaimer: '',
    value: 0,
  },
  total_negative_salary: 0,
  total_positive_salary: 0,

  total_negative_salary_ips: 0,
  total_positive_salary_ips: 0,

  // aguinaldo
  aguinaldo_months: allMonths.map((month) => ({
    label: `${month.label} ${dayjs().year()}`,
    value: 0,
    disclaimer: '',
    payrollId: '',
    month: +month.value,
  })),
  aguinaldo: 0,

  // salaries
  salaries: Array.from({ length: 6 }).map((_, index) => {
    const date = dayjs().subtract(index, 'month')

    return {
      label: `${date.format('MMMM')} ${date.year()}`,
      disclaimer: '',
      value: 0,
      payrollId: '',
      month: date.month(),
    }
  }),
  salary_average: 0,
  daily_salary: 0,

  vacations: [
    {
      earned_days: 0,
      used_days: 0,
      value: 0,
      year: {
        label: dayjs().year().toString(),
        value: 0,
      },
    },
  ],
  accumulatedVacations: [
    {
      earned_days: 0,
      used_days: 0,
      value: 0,
      year: {
        label: dayjs().year().toString(),
        value: 0,
      },
    },
  ],
  total_vacations: {
    total_caused_vacations: 0,
    used_days: 0,
    earned_days: 0,
    valueIps: 0,
    value: 0,
  },
  total_accumulated_vacations: {
    total_caused_vacations: 0,
    used_days: 0,
    earned_days: 0,
    valueIps: 0,
    value: 0,
  },

  proportional_vacations: {
    corresponding_days: {
      disclaimer: '',
      value: 0,
    },
    received_days: {
      disclaimer: '',
      value: 0,
    },
    missing_days: {
      disclaimer: '',
      value: 0,
    },
    value: 0,
    valueIps: 0,
  },
  discount_proportional_vacations: {
    corresponding_days: {
      disclaimer: '',
      value: 0,
    },
    received_days: {
      disclaimer: '',
      value: 0,
    },
    missing_days: {
      disclaimer: '',
      value: 0,
    },
    valueIps: 0,
    value: 0,
  },
  pre_warning: {
    totalDays: {
      disclaimer: '',
      value: 0,
    },
    received_days: {
      disclaimer: '',
      value: 0,
    },
    missing_days: {
      disclaimer: '',
      value: 0,
    },
    value: 0,
  },
  discount_pre_warning: {
    totalDays: {
      disclaimer: '',
      value: 0,
    },
    received_days: {
      disclaimer: '',
      value: 0,
    },
    missing_days: {
      disclaimer: '',
      value: 0,
    },
    value: 0,
  },
  indemnification: {
    corresponding_days: {
      disclaimer: '',
      value: 0,
    },
    value: 0,
  },
  other_discounts: [
    {
      label: '',
      value: 0,
      id: null,
    },
  ],

  discountConcepts: [],
  paymentConcepts: [],
}
