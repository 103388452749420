import { Div, ExportTemplateDrawer } from '@/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import { useHandleTemplateHubActions } from '../TemplateHub/actions'
import { ExportedTemplatesSchema, ExportedTemplatesSchemaType } from './type'
import { ExportedTemplateList } from './components/ExportedTemplateList'

export const ExportedTemplates = () => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')
  const methods = useForm<ExportedTemplatesSchemaType>({
    resolver: zodResolver(ExportedTemplatesSchema),
  })

  useHandleTemplateHubActions()

  return (
    <FormProvider {...methods}>
      <Div>
        <ExportedTemplateList />
      </Div>
      <ExportTemplateDrawer
        templateId={selectedTemplateId}
        isOpen={!!selectedTemplateId}
        setIsOpen={() => setSelectedTemplateId('')}
      />
    </FormProvider>
  )
}
