import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { useMemo } from 'react'
import { usePaymentsAndDiscountsConcepts } from './usePaymentsAndDiscountsConcepts'

export interface IProportionalSalaryItem {
  name: string
  value: number
  valueIps: number
}

export interface IProportionalSalaryTotals {
  proportional_salary_total_value: number
  items: IProportionalSalaryItem[]
}

export const useProportionalSalaryTotals = (): IProportionalSalaryTotals => {
  const methods = useFormContext<InactivateWorkerSchema>()

  const { discountConcepts, paymentConcepts } = usePaymentsAndDiscountsConcepts(
    ['payment', 'salary_concept'],
  )

  const [totalPositiveSalary, totalNegativeSalary, familyBonusValue] = useWatch(
    {
      control: methods.control,
      name: [
        'data.total_positive_salary',
        'data.total_negative_salary',
        'data.familiarBonus.value',
      ],
    },
  )

  const totalPaymentConcepts = useMemo(() => {
    return paymentConcepts.reduce((acc, curr) => {
      return acc + +curr.concept.value
    }, 0)
  }, [paymentConcepts])

  const totalDiscountConcepts = useMemo(() => {
    return discountConcepts.reduce((acc, curr) => {
      return acc + +curr.concept.value
    }, 0)
  }, [discountConcepts])

  const totalFinalValue = useMemo(() => {
    const subtotal =
      +totalPositiveSalary -
      +totalNegativeSalary +
      +totalPaymentConcepts -
      +totalDiscountConcepts +
      +familyBonusValue

    return subtotal
  }, [
    totalPositiveSalary,
    totalNegativeSalary,
    totalPaymentConcepts,
    totalDiscountConcepts,
    familyBonusValue,
  ])

  return {
    items: [],
    proportional_salary_total_value: totalFinalValue,
  }
}
