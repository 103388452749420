import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { usePayrolls } from '@/libs/react-query/hooks'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import { Progress } from '@punto-ui/react'
import { PayrollListing } from '@/libs/react-query/types/payroll'
import { getUtcDateFromString } from '@/utils/date'

export const PayrollStep = () => {
  const { data: payrolls, isFetching: isFetchingPayrolls } = usePayrolls()

  const methods = useFormContext<ExportPdfSchemaType>()

  const payrollId = useWatch({
    control: methods.control,
    name: 'payrollId',
  })

  const selectedPayment = payrolls?.find((s) => s.id === payrollId)

  const getPayrollLabel = (s: PayrollListing) => {
    return `${s.name} - ${dayjs(getUtcDateFromString(s.start_date)).format(
      'DD/MM/YYYY',
    )} hasta ${dayjs(getUtcDateFromString(s.end_date)).format(
      'DD/MM/YYYY',
    )} - ${s.users.length} ${
      s.users.length === 1 ? `colaborador` : `colaboradores`
    }`
  }

  return (
    <Div>
      <DropdownV2
        value={payrollId || ''}
        defaultValue={payrollId || ''}
        onChangeValue={(value) => {
          if (!value.value) {
            return
          }

          methods.setValue('payrollId', value.value)
          const payroll = payrolls?.find((s) => s.id === value.value)
          methods.setValue('usersIds', payroll?.users.map((u) => u.id) || [])
        }}
        defaultOption={{
          label: selectedPayment
            ? getPayrollLabel(selectedPayment)
            : 'Selecciona una planilla',
          value: selectedPayment?.id || '',
        }}
        options={
          payrolls?.map((s) => {
            return {
              label: getPayrollLabel(s),
              value: s.id,
            }
          }) || []
        }
      />
      {isFetchingPayrolls && (
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Progress />
        </Div>
      )}
    </Div>
  )
}
