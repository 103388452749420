import React, {
  useState,
  createContext,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import { AlertProvider, Alert } from '@punto-ui/react'

// type AlertProps = Omit<AlertRootProps, 'open' | 'setOpen'>

interface IToast {
  id: number
  description: string
  title: string
  type: 'positive' | 'negative' | 'warning' | 'info'
  timeout?: number
  callback?: () => void
  buttonLabel?: string
}

interface IToastProviderProps {
  children: React.ReactNode
}

export const ToastContext = createContext({
  addToast: (props: IToast) => {
    /* no-op */
  },
  removeToast: (id: number) => {
    /* no-op */
  },
})

export const ToastContextProvider = ({ children }: IToastProviderProps) => {
  const [toasts, setToasts] = useState<IToast[]>([])
  const possibleLeaks = useRef<any>([])

  useEffect(() => {
    return () => {
      possibleLeaks.current.forEach((timeout: any) => {
        clearTimeout(timeout)
      })
    }
  }, [])

  const addToast = useCallback(
    (content: IToast) => {
      setToasts((toasts) => [...toasts, { ...content }])

      const timeout = setTimeout(() => {
        setToasts((prevToasts) => prevToasts.slice(1))
      }, content.timeout || 3000)

      possibleLeaks.current.push(timeout)
    },
    [setToasts],
  )

  const removeToast = useCallback(
    (id: number) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id))
    },
    [setToasts],
  )

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <AlertProvider direction="up">
        {toasts.map((toast) => (
          <Alert
            css={{ zIndex: 1000 }}
            key={toast.id}
            open={true}
            setOpen={() => removeToast(toast.id)}
            description={toast.description}
            title={toast.title}
            state={toast.type}
            action={() => toast.callback?.()}
            actionText={toast.buttonLabel}
            altText={toast.buttonLabel ? '.' : undefined}
          />
        ))}
        {children}
      </AlertProvider>
    </ToastContext.Provider>
  )
}
