import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { Div } from '@/components'

export const useInactivateDiscountSalary = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [discountMorning, discountNight] = useWatch({
    control,
    name: [
      'data.discount_morning.disclaimer',
      'data.discount_night.disclaimer',
    ],
  })

  const form = useMemo(() => {
    const reasonForm: InactiveWorkerField[] = [
      {
        _type: 'input',
        inputType: 'money-guarani',
        name: 'data.discount_morning.value',
        disabled: isLoadingLiquidation,
        label: 'Horas Descuento Diurnas',
        disclaimer: `Referente a ${discountMorning}`,
      },
      {
        _type: 'input',
        inputType: 'money-guarani',
        name: 'data.discount_night.value',
        label: 'Horas Descuento Nocturnas',
        disabled: isLoadingLiquidation,
        disclaimer: `Referente a ${discountNight}`,
      },
      {
        _type: 'input',
        inputType: 'money-guarani',
        name: 'data.total_negative_salary',
        label: 'Total descuentos',
        disabled: isLoadingLiquidation,
      },
    ]

    return reasonForm
  }, [discountMorning, discountNight, isLoadingLiquidation])

  return {
    form,
  }
}

export const useInactivateSalary = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [
    baseSalary,
    extraMorning,
    extraNight,
    holidayMorning,
    holidayNight,
    ordinaryNight,
    proportionalSalary,
  ] = useWatch({
    control,
    name: [
      'data.base_salary',
      'data.extra_morning.disclaimer',
      'data.extra_night.disclaimer',
      'data.holiday_morning.disclaimer',
      'data.holiday_night.disclaimer',
      'data.ordinary_night.disclaimer',
      'data.proportional_salary.disclaimer',
    ],
  })

  const form = useMemo(() => {
    const reasonForm: InactiveWorkerField[] = [
      {
        _type: 'input',
        name: 'data.base_salary.value',
        inputType: 'money-guarani',
        disabled: isLoadingLiquidation,
        disclaimer: `Valor diário: ${typeDecoder('money-guarani').input(
          baseSalary.disclaimer,
        )}`,
        label: 'Salario Base',
      },
      {
        _type: 'input',
        inputType: 'only-numbers-or-empty',
        name: 'data.payed_days.value',
        disabled: isLoadingLiquidation,
        label: 'Dias a pagar',
      },
      {
        _type: 'input',
        name: 'data.proportional_salary.value',
        inputType: 'money-guarani',
        label: 'Salário proporcional',
        disabled: isLoadingLiquidation,
        disclaimer: `Valor diário: ${typeDecoder('money-guarani').input(
          proportionalSalary,
        )}`,
      },
      {
        _type: 'custom',
        component: <Div css={{ width: 'calc((100% / 4) - 5%)' }} />,
      },
      {
        _type: 'input',
        inputType: 'money-guarani',
        disabled: isLoadingLiquidation,
        name: 'data.ordinary_night.value',
        disclaimer: `Referente a ${ordinaryNight}`,
        label: 'Adicional nocturno',
      },
      {
        _type: 'input',
        name: 'data.extra_morning.value',
        disabled: isLoadingLiquidation,
        inputType: 'money-guarani',
        label: 'Horas Extras Diurnas',
        disclaimer: `Referente a ${extraMorning}`,
      },
      {
        _type: 'input',
        name: 'data.extra_night.value',
        disabled: isLoadingLiquidation,
        inputType: 'money-guarani',
        label: 'Horas Extras Nocturnas',
        disclaimer: `Referente a ${extraNight}`,
      },
      {
        _type: 'input',
        name: 'data.holiday_morning.value',
        disabled: isLoadingLiquidation,
        inputType: 'money-guarani',
        label: 'Horas Feriado Diurnas',
        disclaimer: `Referente a ${holidayMorning}`,
      },
      {
        _type: 'input',
        inputType: 'money-guarani',
        name: 'data.holiday_night.value',
        disabled: isLoadingLiquidation,
        label: 'Horas Feriados Nocturnas',
        disclaimer: `Referente a ${holidayNight}`,
      },
      {
        _type: 'input',
        inputType: 'money-guarani',
        disabled: isLoadingLiquidation,
        name: `data.total_positive_salary`,
        label: 'Total a recibir',
      },
    ]

    return reasonForm
  }, [
    isLoadingLiquidation,
    baseSalary,
    extraMorning,
    extraNight,
    holidayMorning,
    holidayNight,
    ordinaryNight,
    proportionalSalary,
  ])

  return {
    form,
  }
}
