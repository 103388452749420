import { useCan } from '@/hooks'
import { useTabStore } from '@/store'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'

export const useHandlePermissionsActions = () => {
  const { addTab, changeTab, activeTab, setActions } = useTabStore((state) => ({
    setActions: state.setActionsTab,
    activeTab: state.permissions.activeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const canCreate = useCan(['permisos.create', 'permisos.all'])

  useEffect(() => {
    if (activeTab === 'policies' || activeTab === 'groups' || !activeTab) {
      setActions(
        'permissions',
        canCreate
          ? [
              {
                label: 'Crear Política',
                variant: 'primary' as const,
                icon: <PlusIcon />,
                disabled: false,
                width: 150,
                action: () => {
                  addTab('permissions', [
                    {
                      id: 'new',
                      type: 'new',
                    },
                  ])
                  changeTab('permissions', 'new')
                },
              },
            ]
          : [],
      )
    }
  }, [activeTab, addTab, changeTab, setActions, canCreate])
}
