import { IVacation } from '@/libs/react-query/types/vacations'
import { VacationListContentType } from './types'
import dayjs from 'dayjs'
import { getUserTimezone } from '@/utils/date/timezone'

export const parseVacationsToVacationsTable = (
  vacations: IVacation[],
  allSelectedIds: string[],
): VacationListContentType[] => {
  return (
    vacations?.map((vacation) => {
      const datesSorted = vacation?.dates?.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      })

      const timezone = getUserTimezone()

      return {
        id: vacation?.id || '',
        users: [],

        title: {
          value: vacation.name,
          status: '',
        },

        created_at: {
          value: dayjs(vacation.created_at).format('DD/MM/YYYY HH:mm'),
          status: '',
        },
        numberOfWorkers: {
          value: vacation.users.length || '',
          status: '',
        },
        checked: {
          value: !!allSelectedIds?.includes(vacation?.id),
          status: '',
        },
        avatar: {
          url: '',
          alt: '',
        },
        name: {
          value: vacation.first_user.firstName,
          status: '',
        },
        surname: {
          value: vacation.first_user.surname,
          status: '',
        },
        period: [
          {
            id: `${vacation.period}`,
            name: `${vacation.period - 1} - ${vacation.period}`,
            variant: 'neutral',
          },
        ],
        start_date: {
          value: datesSorted[0]
            ? dayjs.tz(datesSorted[0].date, timezone).format('DD/MM/YYYY')
            : '',
          status: '',
        },
        end_date: {
          status: '',
          value: datesSorted[0]
            ? dayjs
                .tz(datesSorted[datesSorted.length - 1].date, timezone)
                .format('DD/MM/YYYY')
            : '',
        },
        reintegration_date: {
          status: '',
          value:
            dayjs.tz(vacation?.comeback_date, timezone).format('DD/MM/YYYY') ||
            '',
        },
        communication_date: {
          value:
            dayjs
              .tz(vacation?.communication_date, timezone)
              .format('DD/MM/YYYY') || '',
          status: '',
        },
        payment_date: {
          value:
            dayjs.tz(vacation?.payment_date, timezone).format('DD/MM/YYYY') ||
            '',
          status: '',
        },
        totalDays: {
          value: vacation?.dates.length || '',
          status: '',
        },
      }
    }) || []
  )
}
