import { GrabIcon } from '@/assets/icons/GrabIcon'
import { Div } from '@/components'
import React, { useEffect, useMemo } from 'react'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

export const LayoutForm = () => {
  const numberOfColumnsOptions = useMemo(
    () => Array.from({ length: 5 }, (_, i) => i + 1),
    [],
  )
  return (
    <Div
      css={{
        padding: '$6',
        display: 'flex',
        flexDirection: 'column',
        gap: '$4',

        cursor: 'grab',
        // gap: '$1',
        // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        // overflow: 'scroll',
        // height: 'calc(100vh - 54px - 20px - 60px - 24px)',
      }}
    >
      {numberOfColumnsOptions.map((column) => (
        <LayoutOption key={column} column={column} />
      ))}
    </Div>
  )
}

const LayoutOption = ({ column }: { column: number }) => {
  const optionRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!optionRef.current) return

    return draggable({
      element: optionRef.current,
      canDrag: () => true,
      onDrag: ({ source }) => {
        optionRef.current?.style.setProperty('opacity', '0.2')
        // console.log('dragging')
      },
      onDrop: ({ source }) => {
        optionRef.current?.style.setProperty('opacity', '1')

        // methods.setValue('pdf.content', newContent)

        // optionRef.onDrop?.()
      },
      getInitialData: () => {
        return {
          columns: column,
          type: 'pdf-builder-layout-new-option',
        }
      },
    })
  }, [column])

  return (
    <Div
      ref={optionRef}
      key={column}
      css={{
        background: '$interface_light_down',
        height: 48,
        width: '100%',
        paddingLeft: '$2',
        paddingRight: '$2',
        display: 'flex',
        borderRadius: '$md',
        alignItems: 'center',
        justifyContent: 'space-between',

        gap: '$2',
      }}
    >
      <Div>
        <GrabIcon />
      </Div>
      {Array.from({ length: column }).map((row, index) => (
        <Div
          key={index}
          css={{
            background: '$interface_light_pure',
            height: 40,
            border: '1px solid $interface_light_deep',
            borderRadius: '$sm',
            width: '100%',
          }}
        ></Div>
      ))}
    </Div>
  )
}
