import { FormRenderer } from '@/components'
import { styled, Text } from '@punto-ui/react'
import { ComponentProps } from 'react'
import { AddWorkerData, AddWorkerFields } from '../../../forms'

const FormGroupContainer = styled('div', {})

interface FormGroupProps extends ComponentProps<typeof FormGroupContainer> {
  title?: string
  items: AddWorkerFields
  cols?: number
}

export const FormGroup = ({
  title,
  items,
  cols = 1,
  ...props
}: FormGroupProps) => {
  return (
    <FormGroupContainer {...props}>
      {!!title && (
        <Text
          size="xl"
          css={{
            color: '$brand_primary_pure',
            fontWeight: '$bold',
            marginBottom: '$2',
          }}
        >
          {title}
        </Text>
      )}
      <FormRenderer<AddWorkerData> items={items} inline cols={cols} />
    </FormGroupContainer>
  )
}
