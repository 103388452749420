import { useEffect } from 'react'
import { useTabStore } from '@/store'

export const useHandleInvoiceManagerActions = () => {
  const { setActions } = useTabStore((state) => ({
    setActions: state.setActionsTab,
  }))

  useEffect(() => {
    setActions('admin', [])
  }, [])
}
