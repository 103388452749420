import { z } from 'zod'
import { createCompleteWorkerData } from './types'
import { validateDateTimeString } from '@/utils/date'

export const salaryWorkerSchema = z.object({
  id: z.string(),
  group_id: z.string().nullable().optional(),
  value: z.number().nullable().optional(),

  is_raw_value: z.boolean(),

  start_date: z.date().nullable().array(),

  concepts: z
    .object({
      id: z.string(),
      name: z.string(),
      value: z.number(),
      ips: z.boolean(),
      description: z.string().optional(),
    })
    .array(),
})

export type SalaryWorkerType = z.infer<typeof salaryWorkerSchema>

export const createWorkerSchema = z
  .object({
    filters: z.object({
      name: z.string(),
      cellsIds: z.string().array(),
    }),
    liquidatedFilters: z.object({
      name: z.string(),
      cellsIds: z.string().array(),
    }),
    //  Required Personal Data
    id: z.string().optional(),
    name: z
      .string({ required_error: 'Nombre es requerido' })
      .min(2, { message: 'Nombre es requerido' }),

    firstName: z
      .string({ required_error: 'Nombre es requerido' })
      .min(2, { message: 'Nombre es requerido' }),
    surname: z
      .string({ required_error: 'Apellido es requerido' })
      .min(2, { message: 'Apellido es requerido' }),

    carreer_plan: z.string().optional(),
    profession: z.string().optional(),
    position: z.string().optional(),

    email: z
      .string({ required_error: 'E-mail inválido' })
      .email({ message: 'E-mail inválido' })
      .transform((email) => email.toLowerCase()),
    password: z
      .string({ required_error: 'Seña inválida' })
      .min(6, { message: 'Seña inválida' }),
    ci: z
      .string({ required_error: 'CI es obligatoria' })
      .min(1, { message: 'CI es obligatoria' }),
    confirm_password: z.string().min(6, { message: 'Seña inválida' }),
    // End Required Personal Data

    // Optional Personal Data
    phone: z.string().optional(), // Apenas o celular
    birthday_date: z
      .string()
      .refine((b) => validateDateTimeString(b), {
        message: 'Fecha de nacimiento inválida',
      })
      .optional(),
    marital_state: z.string().optional(),
    nationality: z.string().optional(),
    gender: z.string().optional(),
    // End Optional Personal Data

    // Company Data
    entrance_ips_date: z
      .string()
      .refine((b) => validateDateTimeString(b), {
        message: 'Fecha IPS inválida',
      })
      .optional(),
    hiring_date: z
      .string({
        required_error: 'Fecha de contratación es obligatoria',
      })
      .min(1, { message: 'Fecha de contratación es obligatoria' })
      .refine((b) => validateDateTimeString(b), {
        message: 'Fecha de contratación inválida',
      }),

    // End Company Data

    // Emergency Contacts Data
    contacts: z
      .object({
        isNew: z.boolean(),
        id: z.string().optional(),
        name: z.string(),
        document: z.string(),
        phone: z.string(),
        relationship: z.string(),
        birthday: z.string().refine((b) => validateDateTimeString(b), {
          message: 'Fecha inválida',
        }),
        isEmergencyContact: z.boolean(),
      })
      .array()
      .optional(),
    // End Contacts Data

    // Children Data
    children: z
      .object({
        isNew: z.boolean(),
        id: z.string().optional(),
        name: z.string(),
        document: z.string(),
        birthday: z.string().refine((b) => validateDateTimeString(b), {
          message: 'Fecha inválida',
        }),
        shouldReceiveChildrenBonus: z.boolean(),
      })
      .array()
      .optional(),
    // End Children Data

    // Optional Address Data
    street: z.string().optional(),
    number: z.string().optional(),
    complement: z.string().optional(),
    neighborhood: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    country: z.string().optional(),
    // End Optional Address Data

    // files
    face_id_photo: z.custom<File>().optional(),
    profile_photo: z.custom<File>().optional(),
    // End files

    // General config
    shift_id: z
      .string({ required_error: 'Turno es requerido' })
      .min(1, { message: 'Turno es requerido' }),
    punch_config_ids: z.string().nonempty({
      message: 'Configuración de Marcación requerido',
    }),
    payment_config_id: z
      .string({ required_error: 'Configuración de Pagos requerido' })
      .min(1, { message: 'Configuración de Pagos requerido' }),
    vacation_config_id: z.string(),
    aguinaldo_config_id: z.string(),
    liquidation_config_id: z.string(),
    cell_id: z.string().optional(),

    // Salary
    // salary_group_id: z.string().optional(),
    // salary_raw_value: z
    //   .number({ required_error: 'Salario requerido' })
    //   .nullable(),

    salaryHistory: salaryWorkerSchema.array().optional(),

    conceptToAdd: z.object({
      name: z.string(),
      value: z.number(),
      ips: z.boolean(),
    }),
    concepts: z
      .object({
        name: z.string(),
        value: z.number(),
        ips: z.boolean(),
      })
      .array(),

    // Bank infos
    bank_name: z.string().optional(),
    bank_account: z.string().optional(),
    currency: z
      .object({
        language: z.string(),
        currency: z.string(),
      })
      .optional(),

    // Integration
    datapar_id: z.string().optional(),
    marcacion_id: z.string().optional(),
    default_config_id: z.string().optional(),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Las contraseñas no coinciden',
    path: ['confirm_password'],
  })

export const createWorkerDefaultValues: createCompleteWorkerData = {
  filters: {
    cellsIds: [],
    name: '',
  },
  liquidatedFilters: {
    cellsIds: [],
    name: '',
  },
  salaryHistory: [],

  id: '',
  name: '',
  firstName: '',
  surname: '',
  email: '',
  password: '',
  ci: '',
  confirm_password: '',
  // End Required Personal Data

  carreer_plan: '',
  profession: '',
  position: '',

  // Optional Personal Data
  phone: '', // Apenas o celular
  birthday_date: '',
  marital_state: '',
  nationality: '',
  gender: '',
  // End Optional Personal Data

  // Company Data
  entrance_ips_date: '',
  hiring_date: '',
  // End Company Data

  // Emergency Contacts Data
  contacts: [],
  // End Contacts Data

  // Children Data
  children: [],
  // End Children Data

  // Optional Address Data
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  country: '',
  // End Optional Address Data

  // files
  // face_id_photo: {} as File,
  // profile_photo: {} as File,
  // End files

  // General config
  shift_id: '',
  punch_config_ids: '',
  payment_config_id: '',
  vacation_config_id: '',
  aguinaldo_config_id: '',
  liquidation_config_id: '',

  // Salary
  // salary_group_id: 'RAW_VALUE',
  // salary_raw_value: 0,

  concepts: [],
  conceptToAdd: {
    ips: false,
    name: '',
    value: 0,
  },

  // Bank infos
  bank_name: '',
  bank_account: '',
  currency: {
    language: '',
    currency: '',
  },
}
