import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import { FetchWorkerProps, IPaginatedWorkerUser } from '../../types/worker'

const fetchWorkers = async (props: FetchWorkerProps) => {
  try {
    if (props.allUsers) {
      const response = await apiV2.post<IPaginatedWorkerUser>(
        '/user/list-all-users',
        {
          usersIds: props.usersIds,
          page: props.page !== undefined ? props.page.toString() : undefined,
          perPage:
            props.pageSize !== undefined
              ? props.pageSize.toString()
              : undefined,
          name: props.name,
        },
        {
          params: {
            cellsIds: props.cellsIds?.join(','),
            policiesIds: props.policiesIds?.join(','),
            // perPage: props.pageSize,
            // usersIds: props.usersIds?.join(','),
          },
        },
      )

      return response.data
    } else {
      const response = await apiV2.post<IPaginatedWorkerUser>(
        '/user/list-users',
        {
          usersIds: props.usersIds,
          page: props.page !== undefined ? props.page.toString() : undefined,
          perPage:
            props.pageSize !== undefined
              ? props.pageSize.toString()
              : undefined,
          name: props.name,
          status: props.status,
          activeStartDate: props.activeStartDate,
          activeEndDate: props.activeEndDate,
        },
        {
          params: {
            policiesIds: props.policiesIds?.join(','),
            cellsIds: props.cellsIds?.join(','),
            // perPage: props.pageSize,
            // usersIds: props.usersIds?.join(','),
            // usersIdsFilter: props.usersIdsFilter?.join(','),
            // name: props.name,
          },
        },
      )

      return response.data
    }
  } catch (err) {
    console.log(err)
    return {
      data: [],
      total: 0,
      totalPages: 0,
      page: 0,
      pageSize: 0,
    }
  }
}

export const useWorkers = (
  props: FetchWorkerProps,
  options?: {
    enabled?: boolean
  },
) => {
  const { data: me } = useMe()

  const defaultEnabled =
    !!me &&
    !!me.company_id &&
    !!(props.cellsIds?.length || props.usersIds?.length)

  const query = useQuery(
    ['workers', props, me?.company_id, props.cellsIds],
    async () => {
      const workers = await fetchWorkers(props)

      return workers
    },
    {
      keepPreviousData: true,
      enabled: options?.enabled
        ? options?.enabled && defaultEnabled
        : defaultEnabled,
    },
  )

  return query
}
