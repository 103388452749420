import { Div } from '@/components/Div'
import { ExportTemplateBasicConcepts } from '@/components/Drawers/ExportTemplate/ExportTemplateBasicConcepts'
import { ExportTemplateFilters } from '@/components/Drawers/ExportTemplate/ExportTemplateFilters'
import { useFormContext, useWatch } from 'react-hook-form'
import { ExportPdfSchemaType } from '../../form'
import { useEffect } from 'react'
import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import { usePayrolls } from '@/libs/react-query/hooks'
import dayjs from 'dayjs'
import { useExportTemplateConfiguration } from '@/components/Drawers/ExportTemplate/useExportTemplateConfiguration'
import { IReportBuilderColumnFilterTypes } from '@/pages/reportes-v2/ReportBuilder/filter-types'
import { reportBuilderFilterTypes } from '@/pages/reportes-v2/ReportBuilder/constants/report-builder-filter-types'

export const TableStep = (props: { id: string; index: number }) => {
  const templateId = props.id.split(':')[1]
  const methods = useFormContext<ExportPdfSchemaType>()

  const { data: template } = useReportTemplate(templateId)
  const { data: payrolls } = usePayrolls()

  const [month, year] = useWatch({
    control: methods.control,
    name: [`templates.${props.index}.month`, `templates.${props.index}.year`],
  })

  const { payrollForm } = useExportTemplateConfiguration(template)

  useEffect(() => {
    if (payrollForm.length === 1) {
      const payrollInMonthAndYear = (payrolls || []).findIndex((p) => {
        const payrollMonth = dayjs(
          dayjs(p.start_date).utc().format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).month()
        const payrollYear = dayjs(
          dayjs(p.start_date).utc().format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).year()

        return payrollMonth === +month && payrollYear === +year
      })

      if (payrollInMonthAndYear !== -1) {
        methods.setValue(`templates.${props.index}.payrollIds`, [
          payrolls?.[payrollInMonthAndYear].id || '',
        ])
      } else {
        methods.setValue(`templates.${props.index}.payrollIds`, [])
      }
    }

    if (payrollForm.length > 1) {
      const months = Array.from({ length: 12 }).map((_, i) => i)
      const payrollIds = months.map((m) => {
        const payrollInMonthAndYear =
          payrolls?.findIndex((p) => {
            const payrollMonth = dayjs(p.start_date).month()
            const payrollYear = dayjs(p.start_date).year()

            return payrollMonth === m && payrollYear === +year
          }) || -1

        return payrollInMonthAndYear !== -1 &&
          payrolls?.[payrollInMonthAndYear].id
          ? payrolls?.[payrollInMonthAndYear].id
          : ''
      })
      console.log(payrollIds, payrolls)
      methods.setValue(`templates.${props.index}.payrollIds`, payrollIds)
    }
  }, [payrolls, templateId, methods, template, month, year])

  useEffect(() => {
    const templateValues = methods.getValues(`templates.${props.index}`)

    const defaultValues = {
      dates: [],
      day: [],
      month: '0',
      payrollIds: [],
      year: '2024',
      filters: [],
      templateId,
      aguinaldoIds: [],

      groupByColumn: '',
      withHours: false,
    }

    methods.setValue(`templates.${props.index}`, {
      ...defaultValues,
      ...templateValues,
    })
    const values = methods.getValues(`templates.${props.index}`)

    if (template && !values?.filters?.length) {
      methods.setValue(
        `templates.${props.index}.filters`,
        template.filters.map((f) => {
          const filtersOperators =
            reportBuilderFilterTypes[
              (f.valueType as IReportBuilderColumnFilterTypes) || 'string'
            ]
          const operationsOptions = filtersOperators.operations.map((f) => ({
            label: f.label,
            value: f.value,
            requiredInput: f.requiredInput,
          }))

          const selectedOperationHasValue = !!operationsOptions.find(
            (c) => c.value === f.condition,
          )?.requiredInput

          return {
            id: (Date.now() + Math.random() * 100).toString(),
            column: `${f.type}.${f.name}`,
            type: f.type,
            columnType: f.valueType,
            condition: f.condition,

            hasValue: selectedOperationHasValue !== null,

            value: f.value ? f.value : '',
            dates: f.date ? [dayjs(f.date).toDate()] : [],

            options: operationsOptions,
          }
        }),
      )
    }
  }, [templateId, methods, template, props.index])

  return (
    <Div
      css={{
        display: 'flex',

        '> div:first-child': {
          minWidth: '27vw',
          width: '27vw',

          paddingBottom: 48,
        },

        '> div:nth-child(2)': {
          minWidth: '1px !important',
          width: '1px !important',
        },
      }}
    >
      <ExportTemplateBasicConcepts
        templateId={templateId}
        prefix={`templates.${props.index}`}
        shouldNotAskUsers
        height={'575px'}
        noPadding
      />
      <Div
        css={{
          height: '575px',
          width: '1px',
          background: '$interface_light_deep',

          marginLeft: '$4',
          marginRight: '$4',
        }}
      />
      <ExportTemplateFilters
        templateId={templateId}
        prefix={`templates.${props.index}`}
        height={'575px'}
        noPadding
      />
    </Div>
  )
}
