import { SmartColumnType } from '../types'

export const getAllColumnNames = (columns: SmartColumnType[]) => {
  const result: string[] = []

  columns.forEach((column) => {
    if (!column.items && column.name) {
      result.push(column.name)
    } else if (column.items) {
      column.items.forEach((item) => {
        if (item.name) {
          result.push(item.name)
        }
      })
    }
  })

  return result
}
