import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import { SmartShift } from '@/pages/turnos/components/SmartShiftManagement/types'

const fetchShifts = async () => {
  const response = await apiV2.get<SmartShift[]>(`/shift/list-shift`)

  return response.data
}

export const useSmartShifts = () => {
  const { data: me } = useMe()

  const query = useQuery(['shifts', me?.company_id], () => fetchShifts(), {
    keepPreviousData: true,
    enabled: !!me && !!me?.company_id,
    retry: false,
  })
  return query
}
