import { Div } from '@/components'
import { Button, styled } from '@punto-ui/react'
import { useState } from 'react'
import {
  PdfBuilderContentDataSchemaType,
  PdfBuilderSchemaType,
} from '../../types'
import { useFormContext } from 'react-hook-form'

export const SimpleInput = styled('input', {
  all: 'unset',
  width: 50,
  height: 18,

  border: '1px solid $interface_light_deep',
  background: '$interface_light_up',

  fontFamily: '$default',
  fontSize: '12px',
  lineHeight: '18px',

  borderRadius: '$sm',
  textAlign: 'center',
})

export const DistanceContent = ({
  contentIndex,
  dataIndex,
  contentData,
}: {
  contentData: PdfBuilderContentDataSchemaType
  contentIndex: number
  dataIndex: number
}) => {
  const [isOverTextArea, setIsOverTextArea] = useState(false)

  const methods = useFormContext<PdfBuilderSchemaType>()
  // const height = useWatch({
  //   control: methods.control,
  //   name: `pdf.content.${contentIndex}.data.${dataIndex}.height`,
  // })

  const { height } = contentData

  return (
    <Div
      css={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        position: 'relative',
      }}
      onMouseEnter={() => setIsOverTextArea(true)}
      onMouseLeave={() => setIsOverTextArea(false)}
    >
      <Div
        css={{
          flex: 1,
          borderRadius: '$md',
          // minHeight: height || 34,
          // height: '100%',
          height: height && +height > 150 ? 100 : height || 34,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />

      <Div
        css={{
          paddingTop: '$4',
          position: 'absolute',
          zIndex: 2,
          top: 'calc(100%)',
          left: 0,
          opacity: isOverTextArea ? 1 : 0,
          pointerEvents: isOverTextArea ? 'all' : 'none',
          transition: 'opacity 0.2s',
        }}
      >
        <Div
          onMouseEnter={() => setIsOverTextArea(true)}
          onMouseLeave={() => setIsOverTextArea(false)}
          css={{
            height: 34,
            boxShadow: '0px 0px 24px 0px #343A4029',

            padding: '$4',

            borderRadius: '$md',

            background: '$interface_light_pure',

            display: 'flex',
            gap: '$2',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          {/* <ControlledInput
            InputComponent={SimpleInput}
            name={`pdf.content.${contentIndex}.data.${dataIndex}.height`}
            inputType="only-numbers"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          /> */}
          <Button
            variant={contentData.height === 16 ? 'secondary' : 'tertiary'}
            onClick={() =>
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.height`,
                16,
              )
            }
          >
            P
          </Button>
          <Button
            variant={contentData.height === 32 ? 'secondary' : 'tertiary'}
            onClick={() =>
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.height`,
                32,
              )
            }
          >
            M
          </Button>
          <Button
            variant={contentData.height === 64 ? 'secondary' : 'tertiary'}
            onClick={() =>
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.height`,
                48,
              )
            }
          >
            G
          </Button>
          <Button
            variant={contentData.height === 64 ? 'secondary' : 'tertiary'}
            onClick={() =>
              methods.setValue(
                `pdf.content.${contentIndex}.data.${dataIndex}.height`,
                64,
              )
            }
          >
            GG
          </Button>
        </Div>
      </Div>
    </Div>
  )
}
