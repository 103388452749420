import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsInactivateWorkerFormDisabled } from '../hooks'

export const useDiscountPreWarning = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [preWarning] = useWatch({
    control,
    name: ['data.discount_pre_warning'],
  })
  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = [
      {
        _type: 'input',
        name: `data.discount_pre_warning.totalDays.value`,
        disabled: isLoadingLiquidation,
        inputType: 'only-numbers-or-empty',
        disclaimer: `Antiguedad: ${preWarning.totalDays.disclaimer}`,
        label: 'Días correspondientes',
      },
      {
        _type: 'input',
        label: 'Días recibidos',
        disabled: isLoadingLiquidation,
        inputType: 'only-numbers-or-empty',
        name: `data.discount_pre_warning.received_days.value`,
      },
      {
        _type: 'input',
        label: 'Dias en haber',
        disabled: isLoadingLiquidation,
        inputType: 'only-numbers-or-empty',
        name: `data.discount_pre_warning.missing_days.value`,
      },
      {
        _type: 'input',
        label: 'Valor en haber',
        name: `data.discount_pre_warning.value`,
        disabled: isLoadingLiquidation,
        disclaimer:
          'Corresponde al 50% según Art. 90 segundo párrafo de la Ley Nro. 213/93',
        inputType: 'money-guarani',
      },
    ]
    return lastSalaryForm
  }, [preWarning, isLoadingLiquidation])

  return {
    form,
  }
}
