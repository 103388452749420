import { useFormContext, useWatch } from 'react-hook-form'
import { ReceiptBuilderSchemaType } from '../../type'
import { Div } from '@/components'
import { ReceiptContent } from './ReceiptContent'
import { ReceiptHeader } from './ReceiptHeader'
import { ReceiptFooter } from './ReceiptFooter'

export const ReceiptBuilderPreview = () => {
  const { control } = useFormContext<ReceiptBuilderSchemaType>()

  const [formStep] = useWatch({
    control,
    name: ['currentStep'],
  })

  return (
    <Div
      css={{
        // height: 550,
        width: '100%',
        // background: '$interface_light_down',
        overflow: 'auto',
      }}
    >
      {formStep === 0 && (
        <Div
          css={{
            padding: '$4',
          }}
        >
          <ReceiptHeader />
        </Div>
      )}
      {formStep === 1 && (
        <Div
          css={{
            padding: '$4',
          }}
        >
          <ReceiptContent />
        </Div>
      )}
      {formStep === 2 && (
        <Div
          css={{
            padding: '$4',
          }}
        >
          <ReceiptFooter />
        </Div>
      )}
    </Div>
  )
}
