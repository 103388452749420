import { IReceiptBuilderTypes } from '@/pages/reportes-v2/ReceiptBuilder/type'
import { Dialog, DialogContainer } from '@punto-ui/react'
import React, { useContext, useState } from 'react'
import { ExportReceiptDialog } from './ExportReceiptDialog'
import { useHandleRequestExportReceipt } from '@/libs/react-query/mutations/reports/useHandleRequestExportReceipt'
import useToast from '@/hooks/useToast'

interface IExportReceiptData {
  type: IReceiptBuilderTypes

  paymentId?: string
  payrollId?: string
  vacationId?: string
  liquidationId?: string

  title: string
  description: string

  usersIds: string[]
  value: number
  date: string
}

interface IExportReceiptContextData {
  handleOpenExportReceiptModal: (data: IExportReceiptData) => void
}

const ExportReceiptContext = React.createContext<IExportReceiptContextData>(
  {} as IExportReceiptContextData,
)

export const useExportReceiptModal = () => {
  return useContext(ExportReceiptContext)
}

export const ExportReceiptProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [receiptData, setReceiptData] = useState<IExportReceiptData | null>(
    null,
  )

  const toast = useToast()

  const { mutateAsync: handleRequestExportReceipt } =
    useHandleRequestExportReceipt()

  const handleOpenExportReceiptModal = (data: IExportReceiptData) => {
    setReceiptData(data)
    setIsOpen(true)
  }

  const handleExportReceipt = async (id: string) => {
    if (!receiptData) return

    try {
      await handleRequestExportReceipt({
        receipt_id: id,
        users_ids: receiptData.usersIds,
        payrollId: receiptData.payrollId,
        paymentId: receiptData.paymentId,
        vacationId: receiptData.vacationId,
        liquidationId: receiptData.liquidationId,
      })

      const toastId = Date.now()

      toast.addToast({
        title: 'Recibo solicitado',
        description:
          'El recibo se esta generando, va al modulo de reportes para conferir su estado.',
        type: 'positive',
        timeout: 12000,
        id: toastId,
        buttonLabel: 'Cerrar',
        callback: () => {
          toast.removeToast(toastId)
        },
      })
    } catch (err) {
      toast.addToast({
        title: 'Error',
        description: 'Ocurrio un error al solicitar el recibo',
        type: 'negative',
        id: Date.now(),
      })
    }
  }

  return (
    <ExportReceiptContext.Provider value={{ handleOpenExportReceiptModal }}>
      <DialogContainer open={!!isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        {children}
        <Dialog
          overlayCss={{
            zIndex: 99,
          }}
          containerCss={{
            zIndex: 100,
            padding: 0,
          }}
          content={
            <ExportReceiptDialog
              type={receiptData?.type || 'payment'}
              date={receiptData?.date || ''}
              description={receiptData?.description || ''}
              handleClose={() => setIsOpen(false)}
              handleConfirm={handleExportReceipt}
              title={receiptData?.title || ''}
              users={receiptData?.usersIds?.length || 0}
              value={receiptData?.value || 0}
            />
          }
        />
      </DialogContainer>
    </ExportReceiptContext.Provider>
  )
}
