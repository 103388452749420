import { Drawer, DrawerContainer } from '@/components/Drawer'
import { IDocumentsStateSchema } from '../../documents-state'
import { useFormContext, useWatch } from 'react-hook-form'
import { ControlledInput, Div } from '@/components'
import {
  CheckCircleIcon,
  LockOpenIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import React from 'react'
import { ControlledDropdown } from '@/components/Forms'
import { useDocumentsStructure } from '@/libs/react-query/hooks/useDocumentsStructure'
import { flattenDocumentTree } from '../../documents-utils'
import { useHandleCreateFolder } from '@/libs/react-query/mutations/documents/useHandleCreateFolder'

export const AddFolderDrawer = () => {
  const { data: structure } = useDocumentsStructure()

  const methods = useFormContext<IDocumentsStateSchema>()

  const isCreateFolderOpen = useWatch({
    control: methods.control,
    name: 'addFolder.isAddFolderDrawerOpen',
  })

  const setIsOpen = (v: boolean) => {
    methods.setValue('addFolder.isAddFolderDrawerOpen', v)
  }

  const documentOptions = structure ? flattenDocumentTree(structure) : []

  const {
    mutateAsync: handleExecuteCreateFolder,
    isLoading: isLoadingCreateFolder,
  } = useHandleCreateFolder()

  const handleCreateFolder = async () => {
    const isValid = await methods.trigger('addFolder')

    if (!isValid) return

    const data = methods.getValues('addFolder')

    await handleExecuteCreateFolder({
      folder_document_type_id: null,
      name: data.name,
      parent_id: data.folderId,
    })

    methods.setValue('addFolder', {
      folderId: '',
      isAddFolderDrawerOpen: false,
      name: '',
    })
  }

  return (
    <DrawerContainer open={isCreateFolderOpen} onOpenChange={setIsOpen}>
      <Drawer
        open={isCreateFolderOpen}
        onOpenChange={setIsOpen}
        noPadding
        content={
          <Div
            css={{
              minWidth: 500,
            }}
          >
            <Div
              css={{
                height: 44,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '$4',
                paddingRight: '$4',
              }}
            >
              <Button
                size={'sm'}
                variant="tertiary"
                onClick={() => setIsOpen(false)}
                disabled={isLoadingCreateFolder}
              >
                <XMarkIcon />
                Cancelar
              </Button>
              <Button
                size={'sm'}
                isLoading={isLoadingCreateFolder}
                onClick={handleCreateFolder}
              >
                <CheckCircleIcon />
                Crear
              </Button>
            </Div>

            <Div
              css={{
                padding: '$4',
              }}
            >
              <Text
                variant="heading3"
                css={{
                  marginBottom: '$8',
                  color: '$interface_dark_deep',
                }}
              >
                Informaciones de carpeta
              </Text>

              <DrawerItemContainer>
                <DrawerItemLabel>Ubicación</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                  }}
                >
                  <ControlledDropdown
                    name="addFolder.folderId"
                    options={documentOptions.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                </Div>
              </DrawerItemContainer>

              <DrawerItemContainer>
                <DrawerItemLabel>Nombre</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                  }}
                >
                  <ControlledInput
                    placeholder="Mi nueva carpeta"
                    name="addFolder.name"
                  />
                </Div>
              </DrawerItemContainer>

              <DrawerItemContainer>
                <DrawerItemLabel>Tipo</DrawerItemLabel>
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$2',
                    svg: {
                      height: 16,
                      width: 16,
                    },
                  }}
                >
                  <LockOpenIcon />
                  <Text variant="caption">Carpeta Personalizada</Text>
                </Div>
              </DrawerItemContainer>
            </Div>
          </Div>
        }
      />
    </DrawerContainer>
  )
}

const DrawerItemContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '$2',
      }}
    >
      {children}
    </Div>
  )
}

const DrawerItemLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      variant="caption"
      css={{
        color: '$interface_dark_down',
        minWidth: '50%',
      }}
    >
      {children}
    </Text>
  )
}
