import { Div, FormRenderer } from '@/components'
import { ButtonsContainer, Form } from '@/components/Drawers/Form/styles'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import useToast from '@/hooks/useToast'
import { useHandleRequestReport } from '@/libs/react-query/mutations/reports/useHandleRequestReport'
import dayjs from 'dayjs'
import { Button, Text } from '@punto-ui/react'
import {
  ControlledCalendarInput,
  ControlledFullWorkerSearchBar,
} from '@/components/Forms'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import {
  ArrowDownOnSquareIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'

const exportDrawerForm = z.object({
  users_ids: z.array(z.string()),
  dates: z.date().array(),
  hours: z.object({
    withDiscountHours: z.boolean(),
    withOrdinaryHours: z.boolean(),
    withExtraHours: z.boolean(),
    withHolidayHours: z.boolean(),
    withDiscountValues: z.boolean(),
    includeSalary: z.boolean(),
    totalHours: z.boolean(),
  }),
  filters: z.object({
    withDiscountHours: z.boolean(),
    withOrdinaryHours: z.boolean(),
    withExtraHours: z.boolean(),
    withHolidayHours: z.boolean(),
    allDays: z.boolean(),
  }),
})

export type ExportDrawerFormType = z.infer<typeof exportDrawerForm>
export type ExportDrawerFormFields = Array<
  ControlledFieldProps<ExportDrawerFormType>
>

export const MonthlyPunchReportForm = ({
  handleCloseDrawer,
}: {
  handleCloseDrawer: () => void
}) => {
  const toast = useToast()

  const methods = useForm<ExportDrawerFormType>({
    resolver: zodResolver(exportDrawerForm),
    defaultValues: {
      users_ids: [],
      dates: [
        dayjs().startOf('month').toDate(),
        dayjs().endOf('month').toDate(),
      ],
      filters: {
        allDays: true,
        withDiscountHours: false,
        withExtraHours: false,
        withHolidayHours: false,
        withOrdinaryHours: false,
      },
      hours: {
        includeSalary: false,
        totalHours: false,
        withDiscountHours: false,
        withExtraHours: false,
        withHolidayHours: false,
        withOrdinaryHours: false,
        withDiscountValues: false,
      },
    },
  })

  const {
    mutateAsync: handleRequestReport,
    isLoading: isLoadingRequestReport,
  } = useHandleRequestReport()

  const [usersIds, dates, allDays] = useWatch({
    control: methods.control,
    name: ['users_ids', 'dates', 'filters.allDays'],
  })

  const togglePunchOptions: ExportDrawerFormFields = [
    {
      _type: 'checkbox',
      name: 'hours.withOrdinaryHours',
      label: 'Horas ordinarias',
    },
    {
      _type: 'checkbox',
      name: 'hours.withExtraHours',
      label: 'Horas extras',
    },
    {
      _type: 'checkbox',
      name: 'hours.withHolidayHours',
      label: 'Horas feriado',
    },
    {
      _type: 'checkbox',
      name: 'hours.withDiscountHours',
      label: 'Descuento de horas',
    },
    {
      _type: 'checkbox',
      name: 'hours.withDiscountValues',
      label: 'Descuento (G$)',
    },
    {
      _type: 'checkbox',
      name: 'hours.includeSalary',
      label: 'Incluir salario (G$)',
    },
    {
      _type: 'checkbox',
      name: 'hours.totalHours',
      label: 'Totales de Horas del Periodo',
    },
  ]

  const filterPunchesOptions: ExportDrawerFormFields = [
    {
      _type: 'checkbox',
      name: 'filters.allDays',
      label: 'Incluir todos los dias',
    },
    {
      _type: 'checkbox',
      name: 'filters.withDiscountHours',
      label: 'Filtrar por dias con descuento de horas',
      disabled: allDays,
    },
    {
      _type: 'checkbox',
      name: 'filters.withExtraHours',
      disabled: allDays,
      label: 'Filtrar por dias con horas extras',
    },
    {
      _type: 'checkbox',
      name: 'filters.withHolidayHours',
      disabled: allDays,
      label: 'Filtrar por dias con horas feriado',
    },
    {
      _type: 'checkbox',
      name: 'filters.withOrdinaryHours',
      disabled: allDays,
      label: 'Filtrar por dias con horas ordinarias',
    },
  ]

  const submitExport = async (data: ExportDrawerFormType) => {
    try {
      const formattedData = {
        ...data,
        dates: data.dates.map((d) => dayjs(d).format('YYYY-MM-DD')),
      }

      await handleRequestReport({
        data: {
          filters: formattedData.filters,
          options: formattedData.hours,
          period_end: dayjs(formattedData.dates[1]).format('YYYY-MM-DD'),
          period_start: dayjs(formattedData.dates[0]).format('YYYY-MM-DD'),
        },
        type: 'MONTHLY_USER_REPORT',
        usersIds: formattedData.users_ids,
      })

      const toastId = Date.now()

      toast.addToast({
        title: 'Reporte solicitado',
        description:
          'El reporte se esta generando, va al modulo de reportes para conferir su estado.',
        type: 'positive',
        timeout: 12000,
        id: toastId,
        buttonLabel: 'Cerrar',
        callback: () => {
          toast.removeToast(toastId)
        },
      })

      // handleCloseDrawer()
    } catch (error) {
      console.log(error)
      toast.addToast({
        title: 'Error',
        description: 'Ocurrio un error al solicitar el reporte',
        type: 'negative',
        id: Date.now(),
      })
    }
  }

  console.log('form', methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(submitExport)}>
        <>
          <Div
            css={{
              marginBottom: '$4',
              width: '100%',
            }}
          >
            <Text
              css={{
                color: '$interface_dark_deep',
                fontWeight: '$bold',
                marginBottom: '$2',
              }}
            >
              Incluir en Reporte
            </Text>
            <FormRenderer<ExportDrawerFormType>
              items={togglePunchOptions || []}
              inline
              cols={3}
            />
          </Div>
          <Div css={{ width: '100%' }}>
            <Text
              css={{
                color: '$interface_dark_deep',
                fontWeight: '$bold',
                marginBottom: '$2',
                width: '100%',
              }}
            >
              Filtros
            </Text>
            <FormRenderer<ExportDrawerFormType>
              items={filterPunchesOptions || []}
              inline
              cols={2}
            />
          </Div>
          <Div css={{ zIndex: 3, width: '50%', marginTop: '$4' }}>
            <ControlledCalendarInput
              label="Rango de Fechas"
              name={'dates'}
              calendarType={'range'}
              defaultValues={[
                dayjs(dates?.[0]).toDate(),
                dayjs(dates?.[1]).toDate(),
              ]}
              maxDates={60}
            />
          </Div>
          <Div
            css={{
              width: '100%',
              flex: 1,
              overflow: 'hidden',
              marginTop: '$4',
              // minHeight: 400,
              // maxHeight: 400,
              maxHeight: 500,
            }}
          >
            <ControlledFullWorkerSearchBar<ExportDrawerFormType>
              css={{
                height: 500,
              }}
              name={'users_ids'}
              usersIds={usersIds}
              policiesIds={permissionsArray.filter((p) =>
                p.includes('workers'),
              )}
            />
          </Div>
          <ButtonsContainer css={{ width: '100%', marginTop: '$4' }}>
            <Button
              onClick={handleCloseDrawer}
              disabled={
                isLoadingRequestReport || !usersIds.length || !dates.length
              }
              variant="secondary"
              type="button"
              css={{ width: '100%', marginRight: '$2' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                window.open('/reportes-v1', '_blank')
              }}
              variant="tertiary"
              type="button"
              css={{
                width: '100%',
                marginRight: '$2',
                background: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }}
              icon={<ArrowTopRightOnSquareIcon />}
            >
              Abrir Reportes
            </Button>

            <Button
              isLoading={isLoadingRequestReport}
              disabled={
                isLoadingRequestReport || !usersIds.length || !dates.length
              }
              variant="primary"
              onClick={methods.handleSubmit(submitExport)}
              type="submit"
              css={{ width: '100%', marginLeft: '$2' }}
            >
              <ArrowDownOnSquareIcon />
              Exportar
            </Button>
          </ButtonsContainer>
        </>
      </Form>
    </FormProvider>
  )
}
