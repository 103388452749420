import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useRequiredCompanyData = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const datosObrigatoriosForm: AddWorkerFields = useMemo(() => {
    const x: AddWorkerFields = [
      {
        _type: 'input',
        label: 'Fecha de ingreso real',
        placeholder: '01/01/2023',
        disabled: !isWorkerCreationAvailable,
        inputType: 'datetime',
        name: 'hiring_date',
      },
      {
        _type: 'input',
        label: 'Fecha de ingreso al IPS',
        placeholder: '01/01/2023',
        disabled: !isWorkerCreationAvailable,
        inputType: 'datetime',
        name: 'entrance_ips_date',
      },
    ]

    return x
  }, [isWorkerCreationAvailable])

  return {
    data: datosObrigatoriosForm,
  }
}
