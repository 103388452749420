import { SmartTable, useSmartTableColumnManipulation } from '@/components'
import { usePayrollAccountPayments } from './table'
import { useMemo } from 'react'
import { PayrollTableContainer } from '../PayrollTableContainer'
import { useFormContext, useWatch } from 'react-hook-form'
import { PayrollPlaygroundTableType } from '../../types'

export const PayrollAccountPayments = () => {
  const { control, setValue } = useFormContext<PayrollPlaygroundTableType>()
  const columns = usePayrollAccountPayments()
  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
    }
  }, [])
  const pagination = useWatch({
    control,
    name: 'pagination',
  })

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'payrollAccountPaymentColumnsOrder',
    tableHiddenUniqueName: 'payrollAccountPaymentHiddenColumns',
    tableLeftFixedUniqueName: 'payrollAccountPaymentLeftFixedColumns',
    tableRightFixedUniqueName: 'payrollAccountPaymentRightFixedColumns',

    defaultLeftFixedColumns: ['avatar', 'name', 'surname'],
  })

  return (
    <PayrollTableContainer>
      <SmartTable
        style={tableStyles}
        columns={columns}
        withPagination
        areColumnsDraggable
        defaultColumnOrder={defaultColumnsOrder}
        defaultHiddenColumns={defaultHiddenColumns}
        saveColumnsOrderCallback={saveColumnsOrderCallback}
        saveHiddenColumnsCallback={saveHiddenColumnsCallback}
        resetColumnOrder={resetStateColumnOrder}
        maxDepth={2}
        defaultLeftFixedColumns={defaultLeftFixedColumns}
        defaultRightFixedColumns={defaultRightFixedColumns}
        saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
        saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
        paginationProps={{
          onChange: (data) => {
            setValue('pagination.page', data.pageIndex)
            setValue('pagination.perPage', data.pageSize)
          },
          page: pagination.page,
          perPage: pagination.perPage,
          totalPages: pagination.totalPages,
          total: pagination.total,
        }}
      />
    </PayrollTableContainer>
  )
}
