import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { useTabStore } from '@/store'
import { RequestList } from './components/RequestList'

export default function Solicitaciones({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const { setActiveTab, tabInfos } = useTabStore((state) => ({
    tabInfos: state.requests,
    setActiveTab: state.setActiveTab,
  }))

  const tabsConfig = useMemo(() => {
    const tabs = []

    tabs.push({
      icon: <ListBulletIcon />,
      label: 'Solicitaciones',
      value: 'requests',
      component: <RequestList />,
    })

    return tabs
  }, [])

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="requests"
      actions={tabInfos.actions}
      tabs={tabsConfig}
      setTab={(id) => setActiveTab('requests', id)}
      tab={tabInfos.activeTab || 'requests'}
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
