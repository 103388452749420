import { useCan } from '@/hooks'
import { useTabStore } from '@/store'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'

export const useHandleCreateShiftActions = ({
  isUpdating,
  handleSave,
  isLoading,
}: any) => {
  const { addTab, changeTab, activeTab, setActions } = useTabStore((state) => ({
    setActions: state.setActionsTab,
    activeTab: state.shifts.activeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const canCreate = useCan(['shifts.create', 'shifts.all'])

  useEffect(() => {
    const buttonLabel = isUpdating ? 'Actualizar' : 'Guardar'
    setActions('shifts', [
      {
        action: handleSave,
        icon: <CheckCircleIcon />,
        label: buttonLabel,
        variant: 'primary',
        disabled: false,
        isLoading,
        width: 100,
      },
    ])
  }, [
    activeTab,
    addTab,
    changeTab,
    setActions,
    canCreate,
    isUpdating,
    isLoading,
    handleSave,
  ])
}
