import { Div } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { ConfigurationForm } from './ConfigurationForm'
// import { RowBuilder } from './RowBuilder'
// import { FooterConfigurationForm } from './FooterConfigurationForm'
import { PdfBuilderSchemaType } from '../../types'
import { LayoutForm } from './LayoutForm'
import { ContentForm } from './ContentForm'
import { DataForm } from './DataForm'

export const PdfBuilderForm = () => {
  const { control } = useFormContext<PdfBuilderSchemaType>()

  const formStep = useWatch({
    control,
    name: 'currentStep',
  })

  return (
    <Div
      css={{
        borderBottomLeftRadius: '$lg',
        width: '30%',
        // padding: '$4',
        background: '$interface_light_pure',
        overflowY: 'auto',

        borderRight: '1px solid $interface_light_down',
      }}
    >
      {formStep === 0 && <ConfigurationForm />}
      {/* {formStep === 1 && <RowSelector />} */}
      {formStep === 1 && <LayoutForm />}
      {formStep === 2 && <ContentForm />}
      {formStep === 3 && <DataForm />}
    </Div>
  )
}
