import { queryClient } from '@/libs/react-query'
import { IExportPdfDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestExportPdf = async (data: IExportPdfDTO) => {
  await apiV2.post<any>(`/report-builder/request-export-pdf-template`, data)
  queryClient.invalidateQueries('pdfs')
}

export const useHandleRequestExportPdf = () => {
  const mutation = useCustomMutation(
    ['useHandleExportPdf'],
    handleRequestExportPdf,
  )

  return mutation
}
