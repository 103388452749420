import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const ReceiptHubSchema = z.object({
  configurations: z.object({
    name: z.string(),
  }),
})

export type ReceiptHubSchemaType = z.infer<typeof ReceiptHubSchema>
export type ReceiptHubFields = Array<ControlledFieldProps<ReceiptHubSchemaType>>
