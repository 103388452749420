import { apiV2 } from '@/services/api'
import { UpdateBatchScheduledShiftsParams } from '@/pages/turnos/components/SmartShiftManagement/context'
import { queryClient, useCustomMutation } from '@/libs/react-query'

const handleBatchUpdateShiftSchedule = async ({
  params,
}: {
  params: UpdateBatchScheduledShiftsParams
}) => {
  await apiV2.post('/shift/batch-update-scheduled-shifts', {
    ...params,
  })
  queryClient.invalidateQueries()
}

export const useHandleBatchUpdateShiftSchedule = () => {
  const mutation = useCustomMutation(
    ['useHandleBatchUpdateShiftSchedule'],
    handleBatchUpdateShiftSchedule,
  )

  return mutation
}
