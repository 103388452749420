import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'
// text | signature | image | divider | distance | table | graph

export type PdfBuilderContentDataType =
  | 'text'
  | 'signature'
  | 'image'
  | 'divider'
  | 'distance'
  | 'table'
  | 'graph'
  | 'empty'

export const PdfBuilderContentData = z.object({
  id: z.string(),
  width: z.number().optional(),
  type: z.enum([
    'text',
    'signature',
    'image',
    'divider',
    'distance',
    'table',
    'graph',
    'empty',
  ]),

  // Image
  height: z.number().optional(),

  // Text or signature
  text: z.string().optional(),
  color: z.string().optional(),
  fontSize: z.number().optional(),
  isItalic: z.boolean().optional(),
  isBold: z.boolean().optional(),
  isUnderline: z.boolean().optional(),
  alignment: z.string().optional(),

  bordered: z.boolean().optional(),
  borderColor: z.string().optional(),
})

export const PdfBuilderPdfContent = z.object({
  id: z.string(),
  numberOfColumns: z.number(),
  data: z.array(PdfBuilderContentData),
})

export const PdfBuilderSchema = z.object({
  id: z.string(),
  identifier_id: z.string().nullable(),

  configurations: z.object({
    name: z.string(),
    description: z.string(),
    size: z.string(),
    direction: z.string(),
    favorite: z.boolean(),
    has_two_per_page: z.boolean(),
  }),

  pdf: z.object({
    content: PdfBuilderPdfContent.array(),
  }),

  manualVariables: z.string().array(),

  currentStep: z.number(),
  openContents: z.record(z.string(), z.boolean()),
  openData: z.record(z.string(), z.boolean()),
  loadedTemplate: z.string().optional(),
})

export type PdfBuilderSchemaType = z.infer<typeof PdfBuilderSchema>
export type PdfBuilderContentDataSchemaType = z.infer<
  typeof PdfBuilderContentData
>
export type PdfBuilderPdfContentType = z.infer<typeof PdfBuilderPdfContent>
export type PdfBuilderFields = Array<ControlledFieldProps<PdfBuilderSchemaType>>
