import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

const requiredMessage = {
  required_error: 'Valor requerido',
}

const salaryGroupSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().nullable(),
  type: z.string().nullable(),

  history: z.array(
    z.object({
      id: z.string(),
      start_date: z.date().nullable().array(),
      value: z.number(),
      is_minimum_wage: z.boolean(),

      concepts: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          value: z.number(),
          description: z.string().nullable(),
          ips: z.boolean().nullable(),
        }),
      ),
    }),
  ),
})

export const configSchema = z.object({
  // Payment configuration
  payment: z.object({
    id: z.string().optional(),
    name: z.string().min(1, { message: 'Nombre requerida' }),
    currency: z.string().min(1, { message: 'Moneda requerida' }),
    is_subscribed_ips: z.boolean(),
    should_include_ordinary_salary_in_night_hours: z.boolean(),
    hours_night_ordinary_value: z.number(requiredMessage),
    hours_night_extra_value: z.number(requiredMessage),
    hours_night_holiday_value: z.number(requiredMessage),
    hours_morning_ordinary_value: z.number(requiredMessage),
    hours_morning_extra_value: z.number(requiredMessage),
    hours_morning_holiday_value: z.number(requiredMessage),
    number_hours_per_day: z.string(),
    is_daily_payment: z.boolean(),
    should_consider_company_holidays: z.boolean(),
    should_consider_presence_default: z.boolean(),
    should_pay_children_bonus: z.boolean(),
    should_include_children_bonus_in_ips: z.boolean(),
    should_pay_as_natural_days: z.boolean(),
    should_include_night_ordinary_in_ips: z.boolean(),
    should_include_night_extra_in_ips: z.boolean(),
    should_include_morning_extra_in_ips: z.boolean(),
    should_include_night_holiday_in_ips: z.boolean(),
    should_include_morning_holiday_in_ips: z.boolean(),
    should_include_morning_hours_discount_in_ips: z.boolean(),
    should_include_night_hours_discount_in_ips: z.boolean(),
    should_include_payments_in_ips: z.boolean(),
    should_consider_31_days_month_partially_natural: z.boolean(),
    should_pay_hourly: z.boolean(),

    // Authorization
    should_authorize_night_hours: z.boolean(),
    should_authorize_extra_morning_hours: z.boolean(),
    should_authorize_extra_night_hours: z.boolean(),
    should_authorize_holiday_morning_hours: z.boolean(),
    should_authorize_holiday_night_hours: z.boolean(),
    should_authorize_morning_hours_discount: z.boolean(),
    should_authorize_night_hours_discount: z.boolean(),
  }),

  // Punchclock configuration
  punches: z.object({
    id: z.string().optional(),
    name: z.string().min(1, { message: 'Nombre requerida' }),
    is_able_to_punch_geolocation: z.boolean(),
    is_able_to_punch_geolocation_face_id: z.boolean(),
    is_able_to_punch_face_id: z.boolean(),
    is_able_to_punch_offline: z.boolean(),
    is_presence_default: z.boolean(),
    is_able_to_punch_from_anywhere: z.boolean(),
    is_able_to_see_punch_history: z.boolean(),
    should_show_invalid_geo_punches: z.boolean(),
    should_allow_facial_registration: z.boolean(),
    should_allow_unique_facial_registration: z.boolean(),
    should_use_liveness: z.boolean(),
    required_liveness_precision: z.number(),
    required_aws_precision: z.number(),
    companyPlaceIds: z.array(z.string()),
  }),

  place: z.object({
    id: z.string().optional(),
    name: z.string(),
    description: z.string(),
    latitude: z.string(),
    longitude: z.string(),
    punches_radius: z.number({
      required_error: 'Valor requerido',
    }),
    punches_group_ids: z.array(z.string()),
  }),

  salaryGroup: salaryGroupSchema.optional(),

  holiday: z
    .object({
      id: z.string().optional(),
      name: z.string(),
      date: z.array(z.date().nullable()),
      should_repeat_every_year: z.boolean(),
      active: z.boolean(),
      day_of_week: z.string().optional(),
      isWeekDay: z.boolean(),
    })
    .optional(),

  totem: z
    .object({
      id: z.string().optional(),
      name: z.string(),
      login: z.string(),
      password: z.string(),
      is_facial_registration_enabled: z.boolean().optional(),
      is_location_obligatory: z.boolean().optional(),
      is_active_face_id_disabled: z.boolean().optional(),
      is_touch_face_id_disabled: z.boolean().optional(),
    })
    .optional(),

  vacation: z
    .object({
      id: z.string().optional(),
      name: z.string().min(1, { message: 'Nombre requerida' }),
      include_base_salary: z.boolean(),
      should_use_ips_date: z.boolean(),
      concepts: z.boolean().optional().array(),
    })
    .optional(),

  aguinaldo: z
    .object({
      id: z.string().optional(),
      name: z.string().min(1, { message: 'Nombre requerida' }),
      vacation_value: z.boolean(),
      proportional_vacations: z.boolean(),

      ordinaryNightHours: z.boolean(),

      extraNightHours: z.boolean(),
      extraMorningHours: z.boolean(),

      holidayMorningHours: z.boolean(),
      holidayNightHours: z.boolean(),

      discountMorningHours: z.boolean(),
      discountNightHours: z.boolean(),

      familiarBonus: z.boolean(),
      mobility: z.boolean(),
      bonus: z.boolean(),
      commission: z.boolean(),
      gift: z.boolean(),
      otherEarns: z.boolean(),

      ips9: z.boolean(),
      antecipate: z.boolean(),
      loan: z.boolean(),
      lost: z.boolean(),
      purchase: z.boolean(),
      judicial: z.boolean(),
      excess: z.boolean(),
      otherDiscount: z.boolean(),

      concepts: z.boolean().optional().array(),
    })
    .optional(),

  liquidation: z
    .object({
      id: z.string().optional(),
      name: z.string().min(1, { message: 'Nombre requerida' }),

      should_use_ips_date: z.boolean(),
      should_use_average_salary_in_vacations: z.boolean(),
      should_use_average_salary_in_proportional_salary: z.boolean(),
      vacation_value: z.boolean(),

      ordinaryNightHours: z.boolean(),

      extraNightHours: z.boolean(),
      extraMorningHours: z.boolean(),

      holidayMorningHours: z.boolean(),
      holidayNightHours: z.boolean(),

      discountMorningHours: z.boolean(),
      discountNightHours: z.boolean(),

      familiarBonus: z.boolean(),
      mobility: z.boolean(),
      bonus: z.boolean(),
      commission: z.boolean(),
      gift: z.boolean(),
      otherEarns: z.boolean(),

      ips9: z.boolean(),
      antecipate: z.boolean(),
      loan: z.boolean(),
      lost: z.boolean(),
      purchase: z.boolean(),
      judicial: z.boolean(),
      excess: z.boolean(),
      otherDiscount: z.boolean(),

      concepts: z.boolean().optional().array(),

      newConcept: z.object({
        id: z.string().optional(),
        isCredit: z.boolean(),
        name: z.string(),
        value: z.number(),
        amount: z.number(),
        ips: z.boolean(),
      }),

      basicConcepts: z
        .object({
          type: z.string(),
          name: z.string(),
          ips: z.boolean(),
        })
        .array(),
      newConcepts: z
        .object({
          id: z.string().optional(),
          isCredit: z.boolean(),
          name: z.string(),
          value: z.number(),
          amount: z.number(),
          ips: z.boolean(),
        })
        .array(),
    })
    .optional(),
})

export type ConfigSchema = z.infer<typeof configSchema>
export type ConfigFields = Array<ControlledFieldProps<ConfigSchema>>

export type SalaryGroupSchema = z.infer<typeof salaryGroupSchema>
