import { Button } from '@punto-ui/react'
import { usePunchGroupInfo } from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div } from '@/components'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '../../..'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import { useCan } from '@/hooks'
import { Drawer, DrawerContainer } from '@/components/Drawer'

interface PaymentConfigDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

const MAX_DRAWER_WIDTH = '40vw'

export const CompanyPlacesConfigDrawer = ({
  handleCloseDrawer,
  isOpen,
  isCreation = false,
}: PaymentConfigDrawerProps) => {
  const canCreatePunches = useCan(['configurations.locations'])
  const { data: groupInfo } = usePunchGroupInfo()

  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ConfigSchema>()

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()
  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreateCompanyPlacesConfig = async () => {
    const isValid = await trigger('place')

    if (!isValid) {
      console.log(errors)
      return
    }

    const data = getValues()

    if (isCreation) {
      await handleCreateCompanyConfig({
        place: {
          ...data.place,
          punchesConfigsIds: data.place.punches_group_ids,
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        place: {
          ...data.place,
          punchesConfigsIds: data.place.punches_group_ids,
          id: data.place.id || '',
        },
      })
    }

    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{
              overflow: 'scroll',
              height: '100%',
              maxHeight: '100vh',
              paddingBottom: 32,
            }}
          >
            <FormGroup
              type="fixed"
              title="Informaciones del Local"
              items={groupInfo}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH }}
            />
            <Button
              disabled={!canCreatePunches}
              css={{ marginTop: '$10', minWidth: 300 }}
              onClick={handleCreateCompanyPlacesConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear Localidade' : 'Actualizar Localidade'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
