import { useEffect } from 'react'
import { usePayrollPlaygroundStore } from '../store'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { PayrollPlaygroundTableType } from '../types'
import { useHandleCreatePayroll } from '@/libs/react-query/mutations/payroll/useHandleCreatePayroll'
import { useHandleUpdatePayrollSettings } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollSettings'
import { useTabStore } from '@/store'
import {
  ArrowDownOnSquareIcon,
  // ArrowDownOnSquareIcon,
  BookmarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { IRouteAction } from '@/pages/router'
import { useExportReceiptModal } from '@/components/ExportReceipt'
import { useCan } from '@/hooks'

export const useHandleStepActions = (
  methods: UseFormReturn<PayrollPlaygroundTableType>,
  id?: string,
  raiseConfirmCloseModal?: () => void,
) => {
  const {
    currentStep,
    handleNextStep,
    handlePreviousStep,
    canGoBack,
    canGoNext,
  } = usePayrollPlaygroundStore((state) => ({
    handleNextStep: state.handleNextStep,
    handlePreviousStep: state.handlePreviousStep,
    canGoBack: state.canGoBack,
    currentStep: state.currentStepValue,
    canGoNext: state.canGoNext,
  }))

  const { handleOpenDrawer } = usePayrollPlaygroundStore((state) => ({
    handleOpenDrawer: state.handleOpenDrawer,
  }))

  const { control, getValues } = methods
  const {
    mutateAsync: handleCreatePayroll,
    isLoading: isLoadingCreatePayroll,
  } = useHandleCreatePayroll()

  const {
    mutateAsync: handleUpdatePayrollSettings,
    isLoading: isLoadingPayrollSettingsUpdate,
  } = useHandleUpdatePayrollSettings()

  const { addActions, removeTab, changeTab } = useTabStore((state) => ({
    addActions: state.setActionsTab,
    removeTab: state.removeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const [name, periods, usersIds] = useWatch({
    control,
    name: ['name', 'periods', 'users_ids'],
  })

  const { handleOpenExportReceiptModal } = useExportReceiptModal()
  const canClosePayroll = useCan(['payroll.closePayroll'])

  useEffect(() => {
    const values = getValues()

    const errorMessage = !name
      ? 'Nombre es requerido'
      : periods.length !== 2
      ? 'Debe seleccionar el periodo'
      : !usersIds.length
      ? 'Debe seleccionar al menos un colaborador'
      : ''

    if (id) {
      const actions: IRouteAction[] = [
        {
          label: 'Volver',
          icon: <ChevronLeftIcon />,
          variant: 'secondary' as const,
          action: handlePreviousStep,
          disabled: !canGoBack,
        },
        {
          label: 'Avanzar',
          icon: <ChevronRightIcon />,
          disabled: !canGoNext,
          variant: 'secondary' as const,
          action: handleNextStep,
        },
      ]

      if (values.status === 'closed') {
        actions.unshift({
          label: 'Planilla Cerrada',
          icon: <LockOpenIcon />,
          width: 150,
          disabled: true,
          disableMessage: 'No se puede editar una planilla cerrada',
          variant: 'primary' as const,
          action: () => null,
        })
      }

      if (currentStep === 'CONFIG' && values.status === 'open') {
        actions.push({
          label: 'Actualizar',
          icon: <BookmarkIcon />,
          variant: 'primary' as const,
          disabled: !!errorMessage || isLoadingPayrollSettingsUpdate,
          disableMessage: errorMessage,
          isLoading: isLoadingPayrollSettingsUpdate,
          width: 120,
          action: async () => {
            const values = getValues()

            await handleUpdatePayrollSettings({
              id,
              description: values.description || undefined,
              signature_date: values.signatureDate?.[0]
                ? dayjs(values.signatureDate[0]).toISOString()
                : undefined,
              endDate: dayjs(values.periods[1]).startOf('day').toISOString(),
              startDate: dayjs(values.periods[0]).startOf('day').toISOString(),
              isFavorite: !!values.isFavorite,
              name: values.name,
              usersIds: values.users_ids,
            })
          },
        })
      } else if (values.status === 'open' && canClosePayroll) {
        actions.push({
          label: 'Cerrar Planilla',
          icon: <LockClosedIcon />,
          variant: 'primary' as const,
          disabled: !!errorMessage || isLoadingPayrollSettingsUpdate,
          disableMessage: errorMessage,
          isLoading: isLoadingPayrollSettingsUpdate,
          width: 150,
          action: async () => {
            raiseConfirmCloseModal?.()
          },
        })
      }
      actions.push({
        label: 'Exportar',
        icon: <ArrowDownOnSquareIcon />,
        variant: 'secondary' as const,
        disabled: !!errorMessage || isLoadingPayrollSettingsUpdate,
        disableMessage: errorMessage,
        isLoading: isLoadingPayrollSettingsUpdate,
        width: 150,
        action: async () => {
          handleOpenDrawer('EXPORT', {})
        },
      })

      actions.push({
        label: 'Exportar Recibo',
        icon: <DocumentTextIcon />,
        variant: 'secondary' as const,
        disabled: !!errorMessage || isLoadingPayrollSettingsUpdate,
        disableMessage: errorMessage,
        isLoading: false,
        width: 150,
        action: async () => {
          const values = getValues()
          const value = (values.data?.[0]?.finalSalaryValue?.value ||
            0) as number

          handleOpenExportReceiptModal({
            type: 'payroll',
            usersIds,
            date: values.periods[0].toISOString(),
            value,

            payrollId: values.id,
            title: values.name,
            description: values.description,
          })
        },
      })

      addActions('payroll', actions)
    } else {
      addActions('payroll', [
        {
          label: 'Crear',
          icon: <PlusIcon />,
          variant: 'secondary' as const,
          disabled: !!errorMessage,
          disableMessage: errorMessage,
          isLoading: isLoadingCreatePayroll,
          action: async () => {
            const values = getValues()

            await handleCreatePayroll({
              description: values.description,
              signature_date: values.signatureDate?.[0]
                ? dayjs(values.signatureDate[0]).toISOString()
                : undefined,
              endDate: dayjs(values.periods[1]).startOf('day').toISOString(),
              startDate: dayjs(values.periods[0]).startOf('day').toISOString(),
              isFavorite: !!values.isFavorite,
              name: values.name,
              usersIds: values.users_ids,
            })

            changeTab('payroll', 'list')
            removeTab('payroll', 'new')
          },
        },
      ])
    }
  }, [
    addActions,
    control,
    getValues,
    canClosePayroll,
    name,
    periods,
    usersIds,
    handleCreatePayroll,
    isLoadingCreatePayroll,
    isLoadingPayrollSettingsUpdate,
    changeTab,
    removeTab,
    id,
    canGoBack,
    canGoNext,
    handleNextStep,
    handlePreviousStep,
    handleUpdatePayrollSettings,
    currentStep,
  ])
}
