import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { VacationFormType } from '../../types'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import { useMemo } from 'react'

const nextYear = dayjs().year() + 1

const periodOptions = Array.from({ length: 60 }).map((y, index) => {
  return {
    label: `${nextYear - index - 1}-${nextYear - index}`,
    value: `${nextYear - index}`,
  }
})

export const useMainCreateVacationInputs = () => {
  const { control } = useFormContext<VacationFormType>()

  const communicationDate = useWatch({
    control,
    name: 'create.communication_date',
  })

  const vacationDates = useWatch({
    control,
    name: 'create.dates',
  })

  const periodInfos = useMemo(() => {
    const vacationDatesOrdered = vacationDates
      ? [...vacationDates].sort((a, b) => {
          return dayjs(a).isBefore(dayjs(b)) ? -1 : 1
        })
      : []

    const suggestedCommunicationDate = vacationDatesOrdered[0]
    const suggestedCommunicationDateFormatted = suggestedCommunicationDate
      ? dayjs(suggestedCommunicationDate).add(-15, 'day').format('DD/MM/YYYY')
      : ''

    const result: Array<ControlledFieldProps<VacationFormType>> = [
      {
        _type: 'calendar',
        name: 'create.communication_date',
        label: 'Fecha comunicación',
        calendarType: 'single',
        maxDates: 99999,
        help: suggestedCommunicationDateFormatted
          ? `Es recomendable que la fecha de comunicación sea el día ${suggestedCommunicationDateFormatted} o anterior, siendo el colaborador avisado 15 días antes de la fecha de vacaciones`
          : undefined,
      },
      {
        _type: 'calendar',
        name: 'create.reintegration_date',
        label: 'Fecha reintegro',
        calendarType: 'single',
        maxDates: 99999,
        isDisabled: (date) => {
          return communicationDate?.[0]
            ? dayjs(date).isBefore(dayjs(communicationDate[0]))
            : true
        },
      },
      {
        _type: 'calendar',
        name: 'create.payment_date',
        label: 'Fecha pago',
        calendarType: 'single',
        maxDates: 99999,
      },
    ]
    return result
  }, [communicationDate, vacationDates])

  const dateSelectorForm = useMemo(() => {
    const result: Array<ControlledFieldProps<VacationFormType>> = [
      {
        _type: 'calendar',
        name: 'create.dates',
        label: `Dias de vacaciones (${vacationDates.length} dias)`,
        stayOpen: true,
        hideInput: true,
        calendarType: 'multiple',
        help: 'Las vacaciones deben iniciar día Lunes, en caso de Feriado iniciará al siguiente día hábil',
        maxDates: 99999,
      },
    ]
    return result
  }, [vacationDates])

  const vacationTitleInfos = useMemo(() => {
    const result: Array<ControlledFieldProps<VacationFormType>> = [
      {
        _type: 'input',
        name: 'create.name',
        label: 'Titulo de las vacaciones',
      },
      {
        _type: 'input',
        name: 'create.description',
        label: 'Descripción',
      },
      {
        _type: 'checkbox',
        name: 'create.should_pay_in_payroll',
        label: 'Pagar junto a la liquidación de salário',
      },
      {
        _type: 'dropdown',
        name: 'create.period',
        label: 'Periodo',
        options: periodOptions,
        defaultOption: {
          label: '2022-2023',
          value: '2023',
        },
      },
    ]
    return result
  }, [])

  return {
    vacationTitleInfos,
    dateSelectorForm,
    periodInfos,
  }
}
