import { Progress, styled } from '@punto-ui/react'

export const FullPageLoader = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) return null

  return (
    <Loader>
      <Progress size="lg" />
    </Loader>
  )
}

const Loader = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  opacity: 0.5,
})
