import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  DEFAULT_EXPORT_PAYROLL_FORM,
  DEFAULT_EXPORT_RECEIPT_FORM,
  ExportModuleSchema,
  exportPayrollSchema,
} from './form'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { Button, Text } from '@punto-ui/react'
import { Div, FormRenderer } from '@/components'
import {
  ArrowDownOnSquareIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'
import { useHandleRequestReport } from '@/libs/react-query/mutations/reports/useHandleRequestReport'
import useToast from '@/hooks/useToast'
import { AguinaldoPlaygroundTableType } from '../../../types'

interface ExportPayrollParams {
  handleCloseDrawer: () => void
}

export const ExportPayroll = ({ handleCloseDrawer }: ExportPayrollParams) => {
  const toast = useToast()
  const { control } = useFormContext<AguinaldoPlaygroundTableType>()
  const {
    mutateAsync: handleRequestReport,
    isLoading: isLoadingRequestReport,
  } = useHandleRequestReport()

  const [name, id, usersIds] = useWatch({
    control,
    name: ['name', 'id', 'users_ids'],
  })

  const methods = useForm<ExportModuleSchema>({
    resolver: zodResolver(exportPayrollSchema),
    defaultValues: {
      payroll: DEFAULT_EXPORT_PAYROLL_FORM,
      receipt: DEFAULT_EXPORT_RECEIPT_FORM,
      selectedReport: 'payroll',
    },
  })

  const selectedReport = useWatch({
    control: methods.control,
    name: 'selectedReport',
  })

  const submitExport = async () => {
    const data = methods.getValues()
    const { payroll, receipt, selectedReport } = data

    try {
      if (selectedReport === 'payroll') {
        await handleRequestReport({
          data: {
            payrollId: id,
            columns: payroll.columns,
          },
          type: 'PAYROLL_REPORT',
          usersIds,
        })
      } else if (selectedReport === 'salary_receipt') {
        await handleRequestReport({
          data: {
            payrollId: id,
            filters: receipt,
          },
          type: 'SALARY_RECEIPT_REPORT',
          usersIds,
        })
      }

      const toastId = Date.now()

      toast.addToast({
        title: 'Reporte solicitado',
        description:
          'El reporte se esta generando, va al modulo de reportes para conferir su estado.',
        type: 'positive',
        timeout: 12000,
        id: toastId,
        buttonLabel: 'Cerrar',
        callback: () => {
          toast.removeToast(toastId)
        },
      })

      // handleCloseDrawer()
    } catch (error) {
      console.log(error)
      toast.addToast({
        title: 'Error',
        description: 'Ocurrio un error al solicitar el reporte',
        type: 'negative',
        id: Date.now(),
      })
    }
  }

  console.log(methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          maxWidth: 'calc(50vw)',
          minWidth: 'calc(50vw)',
          maxHeight: '100vh',
          overflow: 'scroll',
          padding: '$4',
          zIndex: 98,
        }}
      >
        <Text
          as="h3"
          variant={'subtitle1'}
          css={{
            color: '$brand_primary_pure',
            marginBottom: '$4',
          }}
        >
          Exportación de Planilla: <strong>{name}</strong>
        </Text>
        <Text
          variant={'description'}
          css={{
            marginBottom: '$8',
          }}
        >
          Selecione las columnas que desea exportar:
        </Text>
        <FormRenderer items={[dropdownField]} cols={3} inline />
        <Div css={{ marginBottom: '$8' }} />
        {selectedReport === 'payroll' && (
          <FormRenderer items={fields} cols={3} inline />
        )}
        {selectedReport === 'salary_receipt' && (
          <FormRenderer items={receiptFields} cols={2} inline />
        )}
        <Div
          css={{
            width: '100%',
            marginTop: '$12',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDrawer}
            disabled={isLoadingRequestReport}
            variant="secondary"
            type="button"
            css={{ width: '100%', marginRight: '$2' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              window.open('/reportes-v1', '_blank')
            }}
            variant="tertiary"
            type="button"
            css={{
              width: '100%',
              marginRight: '$2',
              background: 'transparent',
              boxShadow: 'none',
              cursor: 'pointer',
            }}
            icon={<ArrowTopRightOnSquareIcon />}
          >
            Abrir Reportes
          </Button>

          <Button
            isLoading={isLoadingRequestReport}
            disabled={isLoadingRequestReport}
            variant="primary"
            onClick={submitExport}
            type="submit"
            css={{ width: '100%', marginLeft: '$2' }}
          >
            <ArrowDownOnSquareIcon />
            Exportar
          </Button>
        </Div>
      </Div>
    </FormProvider>
  )
}

export const dropdownField: ControlledFieldProps<ExportModuleSchema> = {
  _type: 'dropdown',
  name: 'selectedReport',
  label: 'Reporte',
  options: [
    {
      label: 'Planilla',
      value: 'payroll',
    },
    {
      label: 'Recibo de Salario',
      value: 'salary_receipt',
    },
  ],
  defaultOption: {
    label: 'Planilla',
    value: 'payroll',
  },
}

export const fields: Array<ControlledFieldProps<ExportModuleSchema>> = [
  {
    _type: 'checkbox',
    name: 'payroll.columns.bank',
    label: 'Banco',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.bankAccount',
    label: 'Conta bancária',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.document',
    label: 'Documiento',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.name',
    label: 'Nombre',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.baseSalary',
    label: 'Salário Base',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.workedDays',
    label: 'Dias Naturales',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.paidDays',
    label: 'Dias Contables',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalWorkedDaysCompensation',
    label: 'Valor por dias trabajados',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.morningExtraHourValue',
    label: 'Horas Extras Diurnas',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.morningHolidayHourValue',
    label: 'Horas Feriado Diurnas',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.nightHourValue',
    label: 'Adicional nocturno',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.nightExtraHourValue',
    label: 'Horas Extras Nocturnas',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.nightHolidayHourValue',
    label: 'Horas Feriado Nocturnas',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.morningTotalHourValue',
    label: 'Valor Total Horas Diurno',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.nightTotalHourValue',
    label: 'Valor Total Horas Noturnas',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalHourValue',
    label: 'Valor Total Horas',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.familiarBonus',
    label: 'Bonificación Familiar',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.paymentsPreIps',
    label: 'Pagos en IPS',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.earningsTotal',
    label: 'Total Haberes',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalMorningDiscountValues',
    label: 'Total Descuento Diurno',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalNightDiscountValues',
    label: 'Total Descuento Nocturno',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.discountsPreIps',
    label: 'Descuentos en IPS',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.ipsBase',
    label: 'Base Imponible IPS',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.ips16',
    label: 'IPS 16%',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.ips9',
    label: 'IPS 9%',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalIps',
    label: 'Total IPS',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.finalSalary',
    label: 'Salário Final',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.paymentsAfterIps',
    label: 'Pagos (sin IPS)',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.discountsAfterIps',
    label: 'Descuentos (sin IPS)',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalPaymentsPreIps',
    label: 'Total Pagos (en IPS)',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalPaymentsAfterIps',
    label: 'Total Pagos (sin IPS)',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalDiscountsPreIps',
    label: 'Total Descuentos (en IPS)',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.totalDiscountsAfterIps',
    label: 'Total Descuentos (sin IPS)',
  },
  {
    _type: 'checkbox',
    name: 'payroll.columns.finalRemuneration',
    label: 'Pago en cuenta',
  },
]

export const receiptFields: Array<ControlledFieldProps<ExportModuleSchema>> = [
  {
    _type: 'checkbox',
    name: 'receipt.totalWorkedDaysCompensation',
    label: 'Total por dias trabajados (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.morningExtraHourValue',
    label: 'Horas Extras Diurnas (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.nightExtraHourValue',
    label: 'Horas Extras Nocturnas (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.morningHolidayHourValue',
    label: 'Horas Feriado Diurnas (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.nightHolidayHourValue',
    label: 'Horas Feriado Nocturnas (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.payments',
    label: 'Incluir pagos (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.morningDiscountValue',
    label: 'Descuento de Horas Diurno (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.nightDiscountValue',
    label: 'Descuento de Horas Nocturno (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.discounts',
    label: 'Incluir Descuentos (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.ips9',
    label: 'IPS 9% (G$)',
  },
  {
    _type: 'checkbox',
    name: 'receipt.familiarBonus',
    label: 'Bonificación Familiar',
  },
]
