import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { ICell, IOrganogram } from '../../types/organogram'
import { useMe } from '../useMe'

const fetchAvailableOrganogram = async (policiesIds: string[]) => {
  try {
    const response = await apiV2.post<{
      organogram: IOrganogram
      cells: ICell[]
    }>('/permission/get-organogram-permissions', {
      policiesIds,
    })
    return response.data
  } catch (err) {
    return null
  }
}

export const useAvailableOrganogram = (policiesIds: string[]) => {
  const { data: user } = useMe()

  const query = useQuery(
    ['available-organogram', policiesIds],
    () => fetchAvailableOrganogram(policiesIds),
    {
      enabled: !!user && !!user.company_id && !!policiesIds.length,
      refetchOnWindowFocus: true,
    },
  )
  return query
}
