import { Progress } from '@punto-ui/react'
import { Div } from '../Div'
import { Step } from './Step'

export interface StepProps {
  setFormStep: (index: number) => void
  text: string
  selectedIndex: number
  disabled?: boolean
  index: number
  error: boolean
}

interface StepperProps {
  stepsArray: StepProps[]
  isLoading?: boolean
}

export const Stepper = ({ stepsArray, isLoading }: StepperProps) => {
  return (
    <Div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {stepsArray.map((step, index) => (
        <Step key={index} {...step} isLast={index === stepsArray.length - 1} />
      ))}
      {isLoading && <Progress size="md" css={{ marginLeft: '$8' }} />}
    </Div>
  )
}
