import { Div, Radio } from '@/components'
import { InputDatePicker } from '@/components/InputDatePicker'
import { useWorker } from '@/libs/react-query/hooks'
import { useWorkerLiquidation } from '@/libs/react-query/hooks/useWorkerLiquidation'
import {
  CheckCircleIcon,
  LightBulbIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button, Progress, Text, Tooltip } from '@punto-ui/react'
import { useEffect, useState } from 'react'
import { useUpdateUserLiquidation } from '@/libs/react-query/mutations/company/useUpdateUserLiquidation'
import {
  inactivationLabelToType,
  inactivationTypeToLabel,
} from '../inactivate/parser'
import dayjs from 'dayjs'
import useToast from '@/hooks/useToast'
import { useHandleLiquidateUser } from '@/libs/react-query/mutations/company/useHandleLiquidateUser'

export const QuickWorkerLiquidation = ({
  workerId,
  closeModal,
}: {
  workerId: string
  closeModal: any
}) => {
  const { data: liquidation, isLoading: isLoadingLiquidation } =
    useWorkerLiquidation({
      userId: workerId,
    })

  const { data: worker, isLoading: isLoadingWorker } = useWorker(workerId)

  const [date, setDate] = useState<Date | null>(null)
  const [radioValue, setRadioValue] = useState<string>('')

  const isLoading = isLoadingWorker || isLoadingLiquidation

  const {
    mutateAsync: handleUpdateLiquidation,
    isLoading: isLoadingLiquidationAction,
  } = useUpdateUserLiquidation()
  const {
    mutateAsync: handleExecuteLiquidateUser,
    isLoading: isLoadingDeactivation,
  } = useHandleLiquidateUser()

  const toast = useToast()

  useEffect(() => {
    if (liquidation?.result.inactivation_date && !date) {
      setDate(
        dayjs(
          dayjs(liquidation?.result.inactivation_date)
            .utc()
            .format('YYYY-MM-DD'),
        ).toDate(),
      )
    }

    if (liquidation?.result.inactivation_type && !radioValue) {
      const inactivationType =
        inactivationTypeToLabel[liquidation?.result.inactivation_type]
      console.log('inactivationType', inactivationType, liquidation?.result)
      setRadioValue(inactivationType)
    }
  }, [liquidation])

  const handleLiquidateUser = async () => {
    try {
      const type = inactivationLabelToType[radioValue]

      if (!type || !date) return

      await handleUpdateLiquidation({
        user_id: workerId,
        inactivation_date: dayjs(date).startOf('day').toISOString(),
        inactivation_type: type,
      })

      await handleExecuteLiquidateUser(workerId)

      const toastId = Date.now()

      toast.addToast({
        title: 'Colaborador liquidado exitosamente',
        description:
          'El colaborador ha sido liquidado exitosamente y su cuenta ha sido desactivada.',
        type: 'positive',
        id: toastId,
        callback: () => {
          toast.removeToast(toastId)
        },
      })
    } catch (err) {
      const toastId = Date.now()

      toast.addToast({
        title: 'Ocurrió un error al liquidar al colaborador',
        description:
          'Ocurrió un error al liquidar al colaborador, por favor intenta nuevamente.',
        type: 'positive',
        id: toastId,
        callback: () => {
          toast.removeToast(toastId)
        },
      })
    } finally {
      closeModal()
    }
  }

  const invalidInputsText = !date
    ? 'Seleccione una fecha de desvinculación'
    : !radioValue
    ? 'Seleccione un motivo de desvinculación'
    : ''

  const isLoadingAction = isLoadingLiquidationAction || isLoadingDeactivation

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$4',
      }}
    >
      <Div
        css={{
          width: '100%',
          minHeight: 36,
          background: '$interface_light_up',
          borderTopRightRadius: '$md',
          borderTopLeftRadius: '$md',
        }}
      />
      {isLoading ? (
        <Div
          css={{
            height: 26,
            paddingLeft: '$4',
          }}
        >
          <Progress />
        </Div>
      ) : (
        <Text
          variant="subtitle1"
          css={{
            color: '$interface_dark_deep',
            padding: '0 $4',
          }}
        >
          Liquidación de {worker?.user?.name}
        </Text>
      )}

      <Div
        css={{
          height: 1,
          background: '$interface_light_down',
        }}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$4',

          padding: '0 $4',
        }}
      >
        <InputDatePicker
          disabled={isLoading}
          maxDates={60}
          type={'single'}
          firstDate={date}
          label="Seleccione una fecha de desvinculación"
          onChange={(dates) => {
            if (dates[0]) {
              setDate(dates[0])
            } else {
              setDate(null)
            }
          }}
        />

        <Radio
          disabled={isLoading}
          onValueChange={(value) => setRadioValue(value)}
          direction="vertical"
          value={radioValue}
          items={[
            'Despido injustificado',
            'Despido justificado',
            'Renuncia',
            'Mutuo Consentimento',
            'Abandono',
            'Periodo Prueba',
            'Muerte',
          ]}
        />

        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            svg: {
              height: 16,
              width: 16,
              color: '$brand_primary_pure',
            },
          }}
        >
          <LightBulbIcon />
          <Text
            variant="description"
            css={{
              color: '$interface_dark_deep',
            }}
          >
            Informaciones detalladas pueden ser añadidas postariormente la
            liquidación final de haberes completa.
          </Text>
        </Div>
      </Div>

      <Div
        css={{
          height: 1,
          background: '$interface_light_down',
        }}
      />

      <Div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '$4',
          padding: '0 $4',
        }}
      >
        <Button
          variant={'tertiary'}
          icon={<XMarkIcon />}
          disabled={isLoading}
          onClick={closeModal}
        >
          Cancelar
        </Button>
        {invalidInputsText ? (
          <Tooltip message={invalidInputsText} arrow side={'top'}>
            <Div>
              <Button disabled={true} icon={<CheckCircleIcon />}>
                Liquidar
              </Button>
            </Div>
          </Tooltip>
        ) : (
          <Button
            disabled={isLoading || isLoadingAction}
            isLoading={isLoadingAction}
            icon={<CheckCircleIcon />}
            onClick={handleLiquidateUser}
          >
            Liquidar
          </Button>
        )}
      </Div>
    </Div>
  )
}
