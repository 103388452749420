import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useTotemsColumns } from './table'
import { ICompanyTotem } from '@/libs/react-query/types/company'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { CreateTotemDrawer } from './components/CreateTotemDrawer'
import { useCan } from '@/hooks'

interface TotemsDrawerState {
  isOpen: boolean
  selectedTotem: ICompanyTotem | null
}

export const TotemsConfiguration = () => {
  const canCreateTotem = useCan(['configurations.totems'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] = React.useState<TotemsDrawerState>({
    isOpen: false,
    selectedTotem: null,
  })

  const columns = useTotemsColumns()

  const handleCreateTotem = () => {
    setValue('totem', {
      login: '',
      name: '',
      password: '',
      id: '',
      is_facial_registration_enabled: false,
      is_location_obligatory: false,
      is_active_face_id_disabled: false,
      is_touch_face_id_disabled: false,
    })

    setDrawerState({
      isOpen: true,
      selectedTotem: null,
    })
  }

  const handleSelectTotem = (row: ICompanyTotem) => {
    setValue('totem', {
      name: row.name,
      login: row.login,
      id: row.id,
      password: row.password,
      is_facial_registration_enabled: !!row.is_facial_registration_enabled,
      is_location_obligatory: !!row.is_location_obligatory,
      is_active_face_id_disabled: !!row.is_active_face_id_disabled,
      is_touch_face_id_disabled: !!row.is_touch_face_id_disabled,
    })

    setDrawerState({
      isOpen: true,
      selectedTotem: row,
    })
  }

  const table = useReactTable({
    data: company?.totems ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del grupo" placeholder="Nombre del grupo" />
        {canCreateTotem && (
          <Button onClick={() => handleCreateTotem()}>Crear totem</Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<ICompanyTotem>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectTotem}
        />
      </Div>
      <CreateTotemDrawer
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedTotem: null,
          })
        }
      />
    </Div>
  )
}
