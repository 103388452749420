import {
  IRequestMovementCreation,
  IRequestPaymentCreation,
  IRequestVacationCreation,
} from '@/libs/react-query/types/requests/modules-dtos'
import { useMemo } from 'react'
import { RequestsInfosTableType } from '../../../form'

interface IUsersIdsInRequestParams {
  selectedRequest: RequestsInfosTableType['data'][0]
}

export const useUsersIdsInRequest = ({
  selectedRequest,
}: IUsersIdsInRequestParams) => {
  const usersIdsInRequest = useMemo(() => {
    if (selectedRequest.module.original === 'movement') {
      const requestData: IRequestMovementCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return requestData.usersIds
    }
    if (selectedRequest.module.original === 'vacation') {
      const requestData: IRequestVacationCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return requestData.users_ids
    }
    if (selectedRequest.module.original === 'payments') {
      const requestData: IRequestPaymentCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return requestData.users.map((u) => u.id)
    }

    return []
  }, [selectedRequest])

  return {
    usersIdsInRequest,
  }
}
