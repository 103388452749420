import { IContentOption } from '../DataForm'

export const vacationsPdfData: IContentOption[] = [
  {
    label: 'Titulo del vacaciones',
    value: 'vacaciones.titulo',
  },
  {
    label: 'Descripción del vacaciones',
    value: 'vacaciones.descripcion',
  },
  {
    label: 'Periodo',
    value: 'vacaciones.periodo',
  },
  {
    label: 'Cantidad de dias',
    value: 'vacaciones.dias',
  },
  {
    label: 'Fecha Inicial',
    value: 'vacaciones.fecha_inicial',
  },
  {
    label: 'Fecha Final',
    value: 'vacaciones.fecha_final',
  },
  {
    label: 'Fecha de Comunicación',
    value: 'vacaciones.comunicación',
  },
  {
    label: 'Fecha reintegro',
    value: 'vacaciones.reintegro',
  },
  {
    label: 'Fecha Pago',
    value: 'vacaciones.fecha_pago',
  },
  {
    label: 'Valor (G$)',
    value: 'vacaciones.valor',
  },
  {
    label: 'Valor IPS (G$)',
    value: 'vacaciones.valor_ips',
  },
]
