import { AddShiftFields } from '../../../forms'

export const useIntervalDiscount = () => {
  const data: AddShiftFields = [
    {
      _type: 'input',
      label: 'Intervalo padrón jornadas diurnas',
      name: 'config.default_interval_morning',
      placeholder: '00:00',
      inputType: 'hour',
    },
    {
      _type: 'input',
      label: 'Intervalo padrón diurno jornadas mixtas',
      name: 'config.default_interval_mixed_morning',
      placeholder: '00:00',
      inputType: 'hour',
    },
    {
      _type: 'input',
      label: 'Intervalo padrón nocturno jornadas mixtas',
      name: 'config.default_interval_mixed_night',
      placeholder: '00:00',
      inputType: 'hour',
    },
    {
      _type: 'input',
      label: 'Intervalo padrón jornadas nocturnas',
      name: 'config.default_interval_night',
      placeholder: '00:00',
      inputType: 'hour',
    },
  ]

  return {
    data,
  }
}
