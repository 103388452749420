import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  Input,
  DropdownContainer,
  DropdownOptionsContainer,
  InputContainer,
  TextInputContainer,
  DropdownItem,
} from './styles'
import { Text, TextInputProps } from '@punto-ui/react'
import { useOutsideAlerter } from '@/hooks'

export interface ISelectOption {
  label: string
  value: string
}

export interface SelectV2Props extends TextInputProps {
  status?: 'error' | 'success' | 'warning'
  onChangeValue?: (s: ISelectOption) => void
  options: ISelectOption[]
  value: string
}

export const SearchableSelect = ({
  options,
  value: selectedOption,
  status,
  css,
  ...props
}: SelectV2Props) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const [inputOptionValue, setInputOptionValue] = React.useState(
    options.find((o) => o.value === selectedOption)?.label || '',
  )
  const [position, setPosition] = useState({ top: 0, left: 0 })

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const dropdownToggleRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const selected = options.find((o) => o.value === selectedOption)
    if (selected) {
      setInputOptionValue(selected.label)
    } else {
      setInputOptionValue('')
    }
  }, [selectedOption, options])

  useOutsideAlerter(containerRef, () => {
    setIsFocused(false)
  })

  useEffect(() => {
    if (isFocused && dropdownToggleRef.current) {
      const rect = dropdownToggleRef.current.getBoundingClientRect()
      if (typeof window !== 'undefined') {
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        })
      }
    }
  }, [isFocused])

  const currentOption = useMemo(() => {
    return options.find((o) => o.value === selectedOption)
  }, [options, selectedOption])

  const filteredOptions = useMemo(() => {
    return inputOptionValue !== currentOption?.label && inputOptionValue
      ? options?.filter((option) =>
          option.label.toLowerCase().includes(inputOptionValue.toLowerCase()),
        )
      : options
  }, [inputOptionValue, options, currentOption])

  return (
    <DropdownContainer
      ref={containerRef}
      css={{
        zIndex: 9999,
        border: '1px solid',
        borderColor: isFocused
          ? '$brand_primary_pure'
          : status === 'warning'
          ? '$status_warning_pure'
          : status === 'error'
          ? '$status_danger_deep'
          : status === 'success'
          ? '$status_success_pure'
          : 'transparent',
        ...css,
      }}
    >
      <InputContainer>
        <TextInputContainer
          ref={dropdownToggleRef}
          disabled={props.disabled}
          onClick={() => {
            setIsFocused(true)
            inputRef?.current?.focus()
            inputRef?.current?.setSelectionRange(0, inputOptionValue.length)
          }}
          type="button"
          css={{
            borderColor: props.errorMessage
              ? '$status_danger_deep'
              : '$interface_dark_up',
            '& svg': {
              color: props.errorMessage
                ? '$status_danger_deep'
                : '$interface_dark_up',
            },
          }}
        >
          <Input
            {...props}
            value={inputOptionValue}
            ref={inputRef}
            onChange={(e) => {
              setInputOptionValue(e.target.value)
              if (props.onChange) props.onChange(e)
            }}
            onFocus={(e) => {
              setIsFocused(true)
              if (props.onFocus) props.onFocus(e)
            }}
            onBlur={(e) => {
              if (e.relatedTarget?.id !== 'searchable-select-item-button') {
                setInputOptionValue(
                  options.find((o) => o.value === selectedOption)?.label || '',
                )
                setIsFocused(false)
                if (props.onBlur) props.onBlur(e)
              }
            }}
          />
          <ChevronDownIcon />
        </TextInputContainer>
        {props.errorMessage && (
          <Text size="xs" as="label" css={{ color: '$status_danger_deep' }}>
            {props.errorMessage}
          </Text>
        )}
      </InputContainer>
      {ReactDOM.createPortal(
        <DropdownOptionsContainer
          isOpen={isFocused && position.top !== 0 && position.left !== 0}
          ref={dropdownRef}
          css={{
            position: 'absolute',
            top: `${position.top + 12}px`,
            left: `${position.left - 12}px`,
          }}
        >
          {filteredOptions?.map((option) => (
            <DropdownItem
              key={option.value}
              isSelected={selectedOption === option.value}
            >
              <button
                type="button"
                id="searchable-select-item-button"
                onClick={() => {
                  props.onChangeValue?.(option)
                  setInputOptionValue(option.label)
                }}
              >
                <Text
                  css={{
                    textOverflow: 'ellipsis',
                    maxWidth: 250,
                    overflow: 'hidden',
                  }}
                >
                  {option.label}
                </Text>
              </button>
            </DropdownItem>
          ))}
        </DropdownOptionsContainer>,
        document.body,
      )}
    </DropdownContainer>
  )
}

SearchableSelect.displayName = 'SearchableSelect'
