import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { SmartTableFormItemValue } from '@/components/SmartTable/types'
import { z } from 'zod'

export const TemplateTableSchema = z.object({
  data: z
    .object({
      id: z.string(),
      name: SmartTableFormItemValue,
      description: SmartTableFormItemValue,
      type: SmartTableFormItemValue,
      favorite: SmartTableFormItemValue,
      lines: SmartTableFormItemValue,
      columns: SmartTableFormItemValue,
      details: SmartTableFormItemValue,
      created_by: SmartTableFormItemValue,
      created_at: SmartTableFormItemValue,

      original: z.any(),
    })
    .array(),

  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
})

export type TemplateTableSchemaType = z.infer<typeof TemplateTableSchema>
export type TemplateTableFields = Array<
  ControlledFieldProps<TemplateTableSchemaType>
>
