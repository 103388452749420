import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useLiquidationGroupsColumns } from './table'
import { LiquidationsConfigDrawer } from './components/LiquidationsConfigDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import {
  ILiquidationConfigGroup,
  LIQUIDATION_CONCEPTS,
  LIQUIDATION_LABEL_BY_CONCEPT,
} from '@/libs/react-query/types/company'
import { useCan } from '@/hooks'

interface LiquidationGroupDrawerState {
  isOpen: boolean
  selectedLiquidationConfig: ILiquidationConfigGroup | null
}

export const LiquidationsConfigs = () => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] =
    React.useState<LiquidationGroupDrawerState>({
      isOpen: false,
      selectedLiquidationConfig: null,
    })

  const columns = useLiquidationGroupsColumns()

  const handleCreateLiquidationConfig = () => {
    setValue('liquidation', {
      name: '',
      concepts: Array.from({ length: company?.concepts.length ?? 0 }).map(
        () => true,
      ),
      should_use_ips_date: false,
      should_use_average_salary_in_vacations: false,
      should_use_average_salary_in_proportional_salary: false,
      ordinaryNightHours: true,

      extraNightHours: true,
      extraMorningHours: true,

      holidayMorningHours: true,
      holidayNightHours: true,

      discountMorningHours: true,
      discountNightHours: true,

      familiarBonus: true,
      vacation_value: true,
      mobility: true,
      bonus: true,
      commission: true,
      gift: true,
      otherEarns: true,

      ips9: true,
      antecipate: true,
      loan: true,
      lost: true,
      purchase: true,
      judicial: true,
      excess: true,
      otherDiscount: true,

      basicConcepts: LIQUIDATION_CONCEPTS.map((c) => ({
        ips: false,
        name: LIQUIDATION_LABEL_BY_CONCEPT[c] || c,
        type: c,
      })),
      newConcepts: [],
      newConcept: {
        amount: 0,
        id: '',
        ips: false,
        isCredit: false,
        name: '',
        value: 0,
      },
    })
    setDrawerState({
      isOpen: true,
      selectedLiquidationConfig: null,
    })
  }

  const handleSelectLiquidationConfig = (row: ILiquidationConfigGroup) => {
    const concepts: boolean[] = []

    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    orderedCompanyConcepts.forEach((conceptName, index) => {
      if (row.concepts.find((c: any) => c.name === conceptName)) {
        concepts[index] = true
      }
    })

    const basicConcepts = LIQUIDATION_CONCEPTS.map((c) => {
      const concept = row.basicConcepts.find(
        (searchConcept) => searchConcept.name === c,
      )

      return {
        ips: concept?.ips || false,
        name: concept?.chosenName || LIQUIDATION_LABEL_BY_CONCEPT[c] || c,
        type: c,
      }
    })

    setValue('liquidation', {
      id: row.id,
      name: row.name,
      should_use_ips_date: row.should_use_ips_date,
      should_use_average_salary_in_vacations:
        !!row.should_use_average_salary_in_vacations,
      should_use_average_salary_in_proportional_salary:
        !!row.should_use_average_salary_in_proportional_salary,
      concepts,
      ordinaryNightHours: row.ordinaryNightHours,

      extraNightHours: row.extraNightHours,
      extraMorningHours: row.extraMorningHours,

      holidayMorningHours: row.holidayMorningHours,
      holidayNightHours: row.holidayNightHours,

      discountMorningHours: row.discountMorningHours,
      discountNightHours: row.discountNightHours,

      familiarBonus: row.familiarBonus,
      vacation_value: !!row.vacation_value,
      mobility: row.mobility,
      bonus: row.bonus,
      commission: row.commission,
      gift: row.gift,
      otherEarns: row.otherEarns,

      ips9: row.ips9,
      antecipate: row.antecipate,
      loan: row.loan,
      lost: row.lost,
      purchase: row.purchase,
      judicial: row.judicial,
      excess: row.excess,
      otherDiscount: row.otherDiscount,

      basicConcepts,
      newConcepts: row.otherConcepts.map((c) => ({
        amount: c.amount,
        ips: c.ips,
        isCredit: !c.isDebit,
        name: c.name,
        value: c.value,
      })),
      newConcept: {
        amount: 0,
        id: '',
        ips: false,
        isCredit: false,
        name: '',
        value: 0,
      },
    })
    setDrawerState({
      isOpen: true,
      selectedLiquidationConfig: row,
    })
  }

  const table = useReactTable({
    data: company?.liquidationConfigGroups ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del grupo" placeholder="Nombre del grupo" />
        {canCreateLiquidation && (
          <Button onClick={handleCreateLiquidationConfig}>
            Crear configuración de Liquidations
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<ILiquidationConfigGroup>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectLiquidationConfig}
        />
      </Div>

      <LiquidationsConfigDrawer
        isCreation={drawerState.selectedLiquidationConfig === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedLiquidationConfig: null,
          })
        }
      />
    </Div>
  )
}
