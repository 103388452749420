import { PageHeader } from '@/components'
import { styled } from '@punto-ui/react'
import { useWorkerCreation } from '../context/hooks'
import { useHandleWorkerCreationActions } from '../actions'

export const CreateWorkerPageHeader = () => {
  const { formStep } = useWorkerCreation()

  useHandleWorkerCreationActions()

  const stepInfos = [
    {
      title: 'Datos Personales',
      subtitle: 'Registro completo de colaboradores.',
    },
    {
      title: 'Datos de Marcación',
      subtitle: 'Cadastro completo de marcación del colaborador.',
    },
    {
      title: 'Datos de Pagamento',
      subtitle: 'Cadastro completo de colaboradores.',
    },
    {
      title: 'Datos salariales',
      subtitle: 'Cadastro completo del salario del colaborador.',
    },
  ]

  return (
    <PageHeaderContainer>
      <PageHeader
        title={stepInfos[formStep].title || ''}
        subtitle={stepInfos[formStep].subtitle || ''}
      />
    </PageHeaderContainer>
  )
}

const PageHeaderContainer = styled('div', {
  marginBottom: '$8',
})
