import { Button } from '@punto-ui/react'
import {
  useMonetaryInfo,
  usePayrollParams,
  useHoursParams,
  useIpsParams,
  useGroupInfo,
  useHoursAuthorizations,
} from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div, PremiumFeatureIcon, Drawer, DrawerContainer } from '@/components'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '../../..'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import { formattedHourToMinutes } from '@/utils/date'
import { useCan } from '@/hooks'

interface PaymentConfigDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

const MAX_DRAWER_WIDTH = '70vw'

export const PaymentConfigDrawer = ({
  handleCloseDrawer,
  isOpen,
  isCreation = false,
}: PaymentConfigDrawerProps) => {
  const canCreatePayments = useCan(['configurations.payments'])
  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ConfigSchema>()

  const { data: groupInfo } = useGroupInfo()
  const { data: moneyForm } = useMonetaryInfo()
  const { data: payrollParams } = usePayrollParams()
  const { data: hoursParams } = useHoursParams()
  const { data: ipsParams } = useIpsParams()
  const { data: hoursAuthorization } = useHoursAuthorizations()

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()
  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreatePaymentConfig = async () => {
    const isValid = await trigger('payment')
    if (!isValid) {
      console.log(errors)
      return
    }

    const data = getValues()

    if (isCreation) {
      await handleCreateCompanyConfig({
        payment: {
          ...data.payment,
          number_hours_per_day:
            formattedHourToMinutes(data.payment.number_hours_per_day) / 60,
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        payment: {
          id: data.payment.id || '',
          ...data.payment,
          number_hours_per_day:
            formattedHourToMinutes(data.payment.number_hours_per_day) / 60,
        },
      })
    }

    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{ overflow: 'scroll', maxHeight: '100vh', paddingBottom: 32 }}
          >
            <FormGroup
              type="fixed"
              title="Informaciones del Grupo"
              items={groupInfo}
              cols={3}
              css={{ maxWidth: MAX_DRAWER_WIDTH }}
            />
            <FormGroup
              type="fixed"
              title="Parametros para calculo"
              items={moneyForm}
              cols={3}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            <FormGroup
              type="fixed"
              title="Parametros para salarios"
              items={payrollParams}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            <FormGroup
              type="fixed"
              title="Parametros para calculo de horas"
              items={hoursParams}
              cols={3}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            <FormGroup
              type="fixed"
              title="Parametros de IPS"
              items={ipsParams}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            <FormGroup
              type="fixed"
              iconTitle={<PremiumFeatureIcon feature="hour-authorization" />}
              title={'Autorización de Horas'}
              items={hoursAuthorization}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            <Button
              disabled={!canCreatePayments}
              css={{ marginTop: '$10', minWidth: 300 }}
              onClick={handleCreatePaymentConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear' : 'Actualizar'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
