import { queryClient } from '@/libs/react-query'
import { IRequestReceiptExportDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestExportReceipt = async (data: IRequestReceiptExportDTO) => {
  await apiV2.post<any>(`/report-builder/request-export-receipt`, data)
  queryClient.invalidateQueries('receipts')
}

export const useHandleRequestExportReceipt = () => {
  const mutation = useCustomMutation(
    ['useHandleExportReceipt'],
    handleRequestExportReceipt,
  )

  return mutation
}
