import { Text, Button, ButtonProps } from '@punto-ui/react'
import { FormRenderer } from '@/components'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  FromDrawerContainer,
  Form,
  HeaderContainer,
  ButtonsContainer,
} from './styles'
import { FieldValues } from 'react-hook-form'
import { DrawerContainer, Drawer } from '@/components/Drawer'

interface FormDrawerProps<TFieldValues extends FieldValues> {
  title: string
  icon?: React.ReactNode
  description?: string
  isOpen: boolean
  handleCloseDrawer(): void
  primaryButtonProps?: ButtonProps
  handlePrimaryButton: () => Promise<void>
  secondaryButtonProps?: ButtonProps
  handleSecondaryButton?: () => void
  formItems?: Array<ControlledFieldProps<TFieldValues>>
  isLoadingSubmit?: boolean
}

export const FormDrawer = <TFieldValues extends FieldValues>({
  title,
  icon,
  description,
  isOpen,
  handleCloseDrawer,
  handlePrimaryButton,
  primaryButtonProps,
  handleSecondaryButton,
  secondaryButtonProps,
  formItems,
  isLoadingSubmit,
}: FormDrawerProps<TFieldValues>) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitForm = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    await handlePrimaryButton()
    setIsLoading(false)
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <FromDrawerContainer>
            <HeaderContainer>
              {icon || null}
              <Text
                as="h3"
                variant={'subtitle1'}
                css={{
                  color: '$brand_primary_pure',
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Text>
            </HeaderContainer>
            <Text
              as="p"
              variant={'paragraph'}
              css={{ color: '$interface_dark_deep' }}
            >
              {description}
            </Text>
            <Form onSubmit={handleSubmitForm}>
              <FormRenderer<TFieldValues>
                items={formItems || []}
                inline
                cols={1}
              />
              <ButtonsContainer>
                {secondaryButtonProps && handleSecondaryButton && (
                  <Button
                    onClick={handleSecondaryButton}
                    disabled={isLoading || isLoadingSubmit}
                    isLoading={isLoading || isLoadingSubmit}
                    variant="secondary"
                    type="button"
                    css={{ width: '100%' }}
                    title={
                      secondaryButtonProps?.title
                        ? secondaryButtonProps.title
                        : 'Cancelar'
                    }
                    icon={
                      secondaryButtonProps?.icon ? (
                        secondaryButtonProps.icon
                      ) : (
                        <XMarkIcon />
                      )
                    }
                    {...secondaryButtonProps}
                  ></Button>
                )}

                <Button
                  isLoading={isLoading || isLoadingSubmit}
                  disabled={isLoading || isLoadingSubmit}
                  variant="primary"
                  type="submit"
                  css={{ width: '100%' }}
                  title={
                    primaryButtonProps?.title
                      ? primaryButtonProps.title
                      : 'Agregar'
                  }
                  icon={
                    primaryButtonProps?.icon ? (
                      primaryButtonProps.icon
                    ) : (
                      <PlusCircleIcon />
                    )
                  }
                  {...primaryButtonProps}
                ></Button>
              </ButtonsContainer>
            </Form>
          </FromDrawerContainer>
        }
      />
    </DrawerContainer>
  )
}
