import { ControlledFilterBar, Div, ExportPDFDrawer } from '@/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import React from 'react'
import { PdfHubSchema, PdfHubSchemaType } from './types'
import { useHandlePdfHubActions } from './actions'
import { usePdfTemplates } from '@/libs/react-query/hooks/usePdfTemplates'
import { Button, Tag, TagProps, Text } from '@punto-ui/react'
import {
  AdjustmentsHorizontalIcon,
  Cog6ToothIcon,
  Square2StackIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { IPdfTemplate } from '@/libs/react-query/types/reports'
import { useHandleCreatePdf } from '@/libs/react-query/mutations/reports/useHandleCreatePdf'
import { getPdfVariablesFromLayouts } from '@/components/Drawers/ExportPDF/variable'
import { capitalizeFirstLetter } from '@/utils/workers/name'
import { useHandleDeletePdf } from '@/libs/react-query/mutations/reports/useHandleDeletePdf'

export const PdfHub = () => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')

  const methods = useForm<PdfHubSchemaType>({
    resolver: zodResolver(PdfHubSchema),
    defaultValues: {
      configurations: {
        name: '',
      },
      pagination: {
        page: 0,
        perPage: 20,
      },
    },
  })

  useHandlePdfHubActions()

  const [name, pagination] = useWatch({
    control: methods.control,
    name: ['configurations.name', 'pagination'],
  })

  const { data: templates } = usePdfTemplates({
    name,
    page: pagination.page,
    perPage: pagination.perPage,
  })

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <ControlledFilterBar
          policiesIds={permissionsArray.filter((p) => p.includes('reports'))}
          isLoading={false}
          nameFilterName="configuration.name"
        />
        <Div
          css={{
            padding: '$4',
          }}
        >
          <Div
            css={{
              marginTop: '$4',
            }}
          >
            {templates?.data.map((template) => {
              return (
                <PdfTemplateOption
                  key={template.id}
                  template={template}
                  setSelectedTemplateId={setSelectedTemplateId}
                />
              )
            })}
            {/* <TemplateList /> */}
          </Div>
        </Div>
      </Div>
      <ExportPDFDrawer
        pdfId={selectedTemplateId}
        isOpen={!!selectedTemplateId}
        setIsOpen={() => setSelectedTemplateId('')}
      />
    </FormProvider>
  )
}

const tagColorByType: Record<string, TagProps['variant']> = {
  Turno: 'warning',
  Colaborador: 'positive',
  Vacaciones: 'neutral',
  Movimiento: 'neutral',
  Pago: 'neutral',
  Liquidacion: 'negative',
  Nomina: 'positive',
}

const PdfTemplateOption = ({
  template,
  setSelectedTemplateId,
}: {
  template: IPdfTemplate
  setSelectedTemplateId: (id: string) => void
}) => {
  const [isConfirmingDeletion, setIsConfirmingDeletion] = React.useState(false)
  const [isLoadingDuplicity, setIsLoadingDuplicity] = React.useState(false)
  const [confirmDuplicate, setConfirmDuplicate] = React.useState(false)

  const { addTab, setActiveTab } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('reportsV2', 'generator'),
    setActiveTab: state.setActiveTab,
    tabs: state.reportsV2.tabs,
    setActions: state.setActionsTab,
    addTab: state.addTab,
  }))

  const { mutateAsync: handleCreatePdf } = useHandleCreatePdf()

  const variables = getPdfVariablesFromLayouts(template.layouts)

  const variableTypesDupped = variables
    .filter((variable) => {
      const hasDot = variable.includes('.')
      const [variableName] = variable.split('.')
      return hasDot && variableName
    })
    .map((variable) => capitalizeFirstLetter(variable.split('.')[0]))
  const variableTypes = [...(new Set(variableTypesDupped) as any)]

  const { mutateAsync: handleDeletePdf, isLoading: isLoadingDelete } =
    useHandleDeletePdf(template.id)

  return (
    <Div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        marginBottom: '$2',

        background: '$interface_light_pure',
        padding: '$2 $4',
        borderRadius: '$md',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$2',
        }}
      >
        <Div
          css={{
            svg: {
              color: '$brand_primary_pure',
              height: 20,
              width: 20,
            },
            height: 20,
            width: 20,
          }}
        >
          {template.favorite && <StarIcon />}
        </Div>
        <Text variant={'paragraph'}>{template.name}</Text>
        {variableTypes.map((v) => (
          <Tag key={v} variant={tagColorByType[v] || 'positive'}>
            {v}
          </Tag>
        ))}
      </Div>
      <Div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '$2',
        }}
      >
        <Button
          icon={<TrashIcon />}
          isLoading={isLoadingDelete}
          variant={isConfirmingDeletion ? 'secondaryCritical' : 'tertiary'}
          onClick={() => {
            if (!isConfirmingDeletion) {
              setIsConfirmingDeletion(true)
            }

            if (isConfirmingDeletion) {
              handleDeletePdf(template.id)
            }
          }}
        >
          Deletar
        </Button>
        <Button
          icon={<Square2StackIcon />}
          variant={confirmDuplicate ? 'secondaryInverse' : 'secondary'}
          isLoading={isLoadingDuplicity}
          onClick={async () => {
            if (!confirmDuplicate) {
              setConfirmDuplicate(true)
              return
            }

            setIsLoadingDuplicity(true)

            await handleCreatePdf({
              name: `${template.name} - Cópia`,
              description: template.description,
              direction: template.direction,
              favorite: template.favorite,
              size: template.size,
              has_two_per_page: !!template.has_two_per_page,
              layouts: template.layouts.map((layout) => {
                return {
                  numberOfColumns: layout.number_of_columns,
                  order: layout.order,
                  content: layout.content.map((c) => {
                    return {
                      type: c.type,
                      width: c.width || undefined,
                      order: c.order || undefined,

                      height: c.height || undefined,

                      value: c.value || undefined,
                      color: c.color || undefined,
                      is_bold: c.is_bold || undefined,
                      is_italic: c.is_italic || undefined,
                      is_underline: c.is_underline || undefined,
                      text_alignment: c.text_alignment || undefined,
                      font_size: c.font_size || undefined,

                      bordered: c.bordered || undefined,
                      border_color: c.borderColor || undefined,
                    }
                  }),
                }
              }),
            })

            setConfirmDuplicate(false)
            setIsLoadingDuplicity(false)
          }}
        >
          Duplicar
        </Button>
        <Button
          icon={<Cog6ToothIcon />}
          variant={'secondary'}
          onClick={() => {
            const existingTab = {
              id: template.id,
              type: 'add-pdf',
              label: `Editar ${template.name}`,
            }
            addTab('reportsV2', [existingTab])
            setActiveTab('reportsV2', existingTab.id)
          }}
        >
          Editar
        </Button>
        <Button
          icon={<AdjustmentsHorizontalIcon />}
          variant={'primary'}
          onClick={() => setSelectedTemplateId(template.id)}
        >
          Generar
        </Button>
      </Div>
    </Div>
  )
}
