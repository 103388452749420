import { Configurations, WeekShiftTable } from '../../create/components'
import { useShiftCreation } from '../../create/context/hooks'

export const ShiftsTabs = () => {
  const { currentStep } = useShiftCreation()

  if (currentStep === 0) {
    return <Configurations />
  }

  return <WeekShiftTable page={1} pageLength={7} />
}
