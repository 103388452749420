import { useCustomMutation } from './useCustomMutation'
import { QueryClient, UseQueryOptions } from 'react-query'

export type ReactQueryOptions<TData, TEerror = any> = Omit<
  UseQueryOptions<TData, TEerror>,
  'queryKey' | 'queryFn'
>

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      // retry: true,
      // retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
    },
  },
})

export { useCustomMutation }
