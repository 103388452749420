import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdateScheduledShiftsParams } from '@/pages/turnos/components/SmartShiftManagement/context'
import { queryClient } from '@/libs/react-query'

const handleUpdateShiftSchedule = async ({
  params,
}: {
  params: UpdateScheduledShiftsParams
}) => {
  await apiV2.post('/shift/update-scheduled-shift', {
    ...params,
  })
  queryClient.invalidateQueries()
}

export const useHandleUpdateShiftSchedule = () => {
  const mutation = useMutation(
    ['useHandleUpdateShiftSchedule'],
    handleUpdateShiftSchedule,
  )

  return mutation
}
