import { Div, SmartTable } from '@/components'
import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ImportWorkersSchema } from './form'

export const ImportWorkersTable = () => {
  const { control } = useFormContext<ImportWorkersSchema>()

  const importedData = useWatch({
    control,
    name: 'data',
  })

  const columns: SmartColumnType[] = useMemo(() => {
    const biggestNumberOfChildren =
      importedData?.reduce((acc, curr) => {
        if (curr.userChildren.length > acc) return curr.userChildren.length
        return acc
      }, 0) || 0

    const childrenHeaders = Array.from({
      length: biggestNumberOfChildren,
    })
      .map((_, index) => {
        return [
          `Nombre Hijo ${index + 1}`,
          `Fecha Nasc. Hijo ${index + 1}`,
          `CI Hijo ${index + 1}`,
          `Bonificación Hijo ${index + 1}`,
        ]
      })
      .flat()

    const NUMBER_OF_FIELDS_PER_CHILD = 4

    return [
      {
        type: 'subheader',
        header: 'Colaborador',
        name: 'teste',
        items: [
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            inputType: 'no-mask',
            width: 150,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            inputType: 'no-mask',
            width: 150,
          },
          {
            type: 'input-text',
            name: 'document',
            header: 'CI',
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'tags',
            name: 'externalIds',
            draggable: null,
            header: 'ID Externo',
            width: 150,
          },
          {
            type: 'input-text',
            name: 'email',
            draggable: null,
            header: 'E-mail',
            inputType: 'email',
            width: 200,
          },
        ],
      },
      {
        header: 'Informaciones Básicas',
        type: 'subheader',
        name: 'teste-2',
        items: [
          {
            type: 'input-text',
            name: 'position',
            header: 'Cargo',
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'phone',
            header: 'Teléfono',
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'tags',
            width: 100,
            disabled: true,
            name: 'gender',
            header: 'Género',
          },
          {
            type: 'tags',
            width: 100,
            name: 'nationality',
            disabled: true,
            header: 'Nacionalidad',
          },
          {
            type: 'tags',
            width: 100,
            name: 'civilState',
            disabled: true,
            header: 'Estado Civil',
          },
          {
            type: 'input-text',
            width: 125,
            disabled: true,
            name: 'birthday',
            header: 'Fecha Nascimiento',
            inputType: 'datetime',
          },
        ],
      },
      {
        header: 'Informaciones Bancarias',
        type: 'subheader',
        name: 'teste-3',
        items: [
          {
            type: 'input-text',
            width: 120,
            name: 'bank',
            header: 'Banco',
          },
          {
            type: 'input-text',
            width: 125,
            name: 'bankAccount',
            header: 'Cuenta Bancaria',
          },
        ],
      },
      {
        header: 'Informaciones de Domicilio',
        type: 'subheader',
        name: 'teste-4',
        items: [
          {
            type: 'input-text',
            width: 100,
            name: 'country',
            header: 'Pais Domicilio',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'state',
            header: 'Departamento',
          },
          {
            type: 'input-text',
            width: 150,
            name: 'city',
            header: 'Ciudad',
          },
          {
            type: 'input-text',
            width: 150,
            name: 'street',
            header: 'Calle',
          },
          {
            type: 'input-text',
            width: 150,
            name: 'neighborhood',
            header: 'Dirección',
          },
          {
            type: 'input-text',
            width: 200,
            name: 'complement',
            header: 'Complemento',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'number',
            header: 'Numero',
          },
        ],
      },
      {
        header: 'Informaciones de Contrato',
        type: 'subheader',
        name: 'teste-5',
        items: [
          {
            type: 'input-text',
            width: 120,
            name: 'cell',
            header: 'Sucursal',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 120,
            name: 'entranceDate',
            header: 'Fecha Ingresso',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 150,
            name: 'ipsEntranceDate',
            header: 'Fecha Ingresso IPS',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 120,
            name: 'baseSalary',
            inputType: 'money-guarani',
            header: 'Salario Base',
          },
          {
            type: 'input-text',
            width: 150,
            name: 'punchConfigId',
            header: 'Config. de Marcación',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 150,
            name: 'paymentConfigId',
            header: 'Config. de Pagos',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 150,
            name: 'vacationConfigId',
            header: 'Config. de Vacaciones',
            disabled: true,
          },
        ],
      },
      {
        header: 'Informaciones Parentales',
        type: 'subheader',
        name: 'teste-6',
        items: [
          {
            type: 'input-text',
            width: 125,
            length: biggestNumberOfChildren * NUMBER_OF_FIELDS_PER_CHILD,
            name: 'children',
            headers: childrenHeaders,
            disabled: true,
            array: true,
          },
        ],
      },
    ] as SmartColumnType[]
  }, [importedData])

  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
      height: 300,
    }
  }, [])

  return (
    <>
      {/* <ControlledFilterBar
        dateName={'date'}
        nameFilterName="name"
        cellsIdsName="cellsIds"
        dateType="single"
        isLoading={false}
        policiesIds={permissionsArray.filter((p) => p.includes('punches'))}
      /> */}
      <Div
        css={{
          position: 'relative',
          // height: '100%',
          height: 'calc(100vh - 200px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 16,
          paddingRight: 16,
          overflowX: 'hidden',
          marginTop: '$4',
        }}
      >
        <SmartTable style={tableStyles} columns={columns} />
      </Div>
    </>
  )
}
