import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { ICreateCompanyConfigDTO } from '@/libs/react-query/mutations/company/types'
import { queryClient } from '@/libs/react-query'

export const useHandleCreateCompanyConfig = () => {
  const mutation = useMutation(
    ['useHandleCreateCompanyConfig'],
    handleCreateCompanyConfig,
  )

  return mutation
}

const handleCreateCompanyConfig = async (props: ICreateCompanyConfigDTO) => {
  try {
    await apiV2.post<void>('/company/create-company-config', props)

    queryClient.invalidateQueries('my-company')
  } catch (error) {
    console.log(error)
  }
}
