import { useQuery } from 'react-query'
import { useMe } from '..'
import { apiV2 } from '@/services/api'
import { ICompanyBusinessWithStatistics } from '../../types/company'

const getCompaniesStatistics = async (props: {
  startDate: string | undefined
  endDate: string | undefined
}) => {
  try {
    const response = await apiV2.post<ICompanyBusinessWithStatistics[]>(
      '/admin/get-statistics',
      {
        startDate: props.startDate,
        endDate: props.endDate,
      },
    )

    return response.data
  } catch (error) {
    console.log('[ERROR FETCH COMPANIES]', error)
  }
}

export const useCompaniesStatistics = (props: {
  startDate: string | undefined
  endDate: string | undefined
}) => {
  const { data: me } = useMe()

  const query = useQuery(
    [
      'companies',
      'statistics',
      props.startDate,
      props.endDate,
      props.startDate,
      props.endDate,
    ],
    async () =>
      await getCompaniesStatistics({
        startDate: props.startDate!,
        endDate: props.endDate!,
      }),
    {
      enabled: !!me && !!props.startDate && !!props.endDate,
    },
  )

  return query
}
