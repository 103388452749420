import { sortArrayBasedOnOrder } from '@/utils/array'
import { findColumnByName, findColumnMainHeader } from '../columns'
import { SmartColumnType, SmartColumnTypeContent } from '../types'
import { getAllColumnNames } from './get-all-column-names'

const isInvalidEmptyArray = (column: SmartColumnType) => {
  return column.array && !column.length
}

export const reorderSmartTableColumns = (
  columns: SmartColumnType[],
  incompleteRawOrder: string[],
  hiddenColumns: string[],
  maxHeaderDepth: number,
  leftFixedColumns?: string[],
  rightFixedColumns?: string[],
): SmartColumnType[] => {
  if (!incompleteRawOrder.length) {
    return columns
  }

  const columnNames = getAllColumnNames(columns)
  const missingColumnNamesInOrder = columnNames.filter(
    (col) => !incompleteRawOrder.includes(col),
  )
  const rawOrder = [...incompleteRawOrder, ...missingColumnNamesInOrder]

  const orderedLeftFixedColumns = sortArrayBasedOnOrder(
    leftFixedColumns || [],
    rawOrder,
  )

  const orderedRightFixedColumns = sortArrayBasedOnOrder(
    rightFixedColumns || [],
    rawOrder,
  )

  const orderWithoutLeftAndRight = rawOrder.filter(
    (col) =>
      !orderedLeftFixedColumns?.includes(col) &&
      !orderedRightFixedColumns?.includes(col) &&
      col !== 'checked' &&
      col !== 'expandable',
  )

  const maybeDuppedOrder = [
    'checked',
    'expandable',
    ...(orderedLeftFixedColumns || []),
    ...orderWithoutLeftAndRight,
    ...(orderedRightFixedColumns || []),
  ]

  const order = Array.from(new Set(maybeDuppedOrder))

  const newSmartTableColumnsOrdered: SmartColumnType[] = []
  let lastSmartTableInsertingIndex = 0

  order.forEach((col) => {
    if (maxHeaderDepth === 1) {
      const column = findColumnByName(columns, col)

      if (column) {
        newSmartTableColumnsOrdered.push(column)
      }
    }

    if (maxHeaderDepth === 2) {
      const columnHeader = findColumnMainHeader(columns, col)

      if (!columnHeader) {
        return
      }

      const currentAddingHeader =
        newSmartTableColumnsOrdered[lastSmartTableInsertingIndex]

      if (!currentAddingHeader) {
        const columnHeaderItem = columnHeader?.items?.filter(
          (item) => item.name === col,
        )

        const isColumnHidden = hiddenColumns.includes(col)
        const isInvalidArray = columnHeaderItem?.[0]
          ? isInvalidEmptyArray(columnHeaderItem[0])
          : true

        const newColumnHeader = {
          ...columnHeader,
          items: isColumnHidden || isInvalidArray ? [] : columnHeaderItem,
        }

        const finalNewColumnLength = getNewColumnLength(
          newColumnHeader,
          hiddenColumns,
          col,
        )

        newColumnHeader.length = !finalNewColumnLength
          ? 0
          : newColumnHeader.length

        newSmartTableColumnsOrdered.push(newColumnHeader)
        return
      }

      if (currentAddingHeader.name?.includes(columnHeader.name || '')) {
        const column = findColumnByName(columns, col)
        const newColumnHeader = column
          ? {
              ...column,
            }
          : undefined

        const isColumnHidden = hiddenColumns.includes(col)
        const isInvalidArray = newColumnHeader
          ? isInvalidEmptyArray(newColumnHeader)
          : false

        if (newColumnHeader && !isColumnHidden && !isInvalidArray) {
          const finalNewColumnLength = getNewColumnLength(
            newColumnHeader,
            hiddenColumns,
            col,
          )

          newColumnHeader.length = !finalNewColumnLength
            ? 0
            : newColumnHeader.length

          currentAddingHeader.items?.push(newColumnHeader)
        }
      } else {
        const columnHeaderItem = columnHeader?.items?.filter(
          (item) => item.name === col,
        )

        const isColumnHidden = hiddenColumns.includes(col)

        const { newColumnHeader } = getNewColumnHeader(
          columnHeader,
          newSmartTableColumnsOrdered,
          columnHeaderItem || [],
        )

        const isInvalidArray = isInvalidEmptyArray(newColumnHeader)

        if (!isColumnHidden && !isInvalidArray) {
          const finalNewColumnLength = getNewColumnLength(
            newColumnHeader,
            hiddenColumns,
            col,
          )
          newColumnHeader.length = !finalNewColumnLength
            ? 0
            : newColumnHeader.length

          newSmartTableColumnsOrdered.push(newColumnHeader)
          lastSmartTableInsertingIndex += 1
        }
      }
    }
  })

  const newColumnsWithoutEmptyHeaders =
    maxHeaderDepth === 1
      ? newSmartTableColumnsOrdered
      : newSmartTableColumnsOrdered.filter((column) => column.items?.length)

  return newColumnsWithoutEmptyHeaders
}

const getNewColumnLength = (
  newColumnHeader: SmartColumnType,
  hiddenColumns: string[],
  col: string,
) => {
  const isNewColumnArray = newColumnHeader.array

  if (!isNewColumnArray) return 0

  const columnArrayItemsFiltered = hiddenColumns.filter(
    (s) => s.includes('.') && s.includes(col),
  )
  const columnArrayItemFilteredWithinLength = columnArrayItemsFiltered.filter(
    (s) => {
      const columnArrayItemIndex = s.split('.')[1]
      return +columnArrayItemIndex < (newColumnHeader.length || 0)
    },
  )
  const filteredLength = columnArrayItemFilteredWithinLength.length
  const finalNewColumnLength =
    isNewColumnArray && newColumnHeader.length
      ? newColumnHeader.length - filteredLength
      : newColumnHeader.length

  return finalNewColumnLength
}

const getNewColumnHeader = (
  columnHeader: SmartColumnType,
  newSmartTableColumnsOrdered: SmartColumnType[],
  columnHeaderItem: SmartColumnTypeContent[],
) => {
  const newColumnHeader = {
    ...columnHeader,
    items: columnHeaderItem,
  }

  const searchName = (name: string | undefined) => {
    const columnWithNameAlreadyIncluded = newSmartTableColumnsOrdered.find(
      (item) => item.name === name,
    )

    return columnWithNameAlreadyIncluded
  }
  let columnWithNameAlreadyIncluded = searchName(newColumnHeader.name)

  while (columnWithNameAlreadyIncluded) {
    newColumnHeader.name = `${newColumnHeader.name} - ${newColumnHeader.name}`
    columnWithNameAlreadyIncluded = searchName(newColumnHeader.name)
  }

  return {
    newColumnHeader,
  }
}
