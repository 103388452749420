import { useMemo } from 'react'
import { RequestsInfosTableType } from '../../../form'

interface IUsersIdsInRequestParams {
  selectedRequest: RequestsInfosTableType['data'][0]
}

export const useRequestType = ({
  selectedRequest,
}: IUsersIdsInRequestParams) => {
  const requestType = useMemo(() => {
    if (selectedRequest.module.original === 'movement') {
      return 'Movimiento'
    }

    if (selectedRequest.module.original === 'payments') {
      return 'Pago'
    }

    if (selectedRequest.module.original === 'vacation') {
      return 'Vacaciones'
    }

    return ''
  }, [selectedRequest])

  return {
    requestType,
  }
}
