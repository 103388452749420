import { IRoute, useRoutes } from '@/pages/router'
import { Tag, Text, Tooltip, styled } from '@punto-ui/react'
import { useRouter } from 'next/router'
import { useSideBarContext } from '@/contexts/hooks'
import {
  NavigatorContainer,
  RouteContainer,
  RouterContentContainer,
} from './styles'
import { useDebounce } from '@/hooks'
import Link from 'next/link'
import { Div } from '@/components'
import React, { useEffect } from 'react'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import {
  QuestionMarkCircleIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline'

const UnstyledLink = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
})

// const UnstyledA = styled('a', {
//   all: 'unset',
//   cursor: 'pointer',

//   variants: {
//     disabled: {
//       true: {
//         opacity: 0.5,
//       },
//     },
//   },
// })

export const Navigator = () => {
  const { routes, publicRoutes } = useRoutes()

  const { isOpen } = useSideBarContext()
  const debouncedIsOpen = useDebounce(isOpen, 200)

  return (
    <NavigatorContainer>
      {routes.map((route) => (
        <NavigationItem
          route={route}
          isOpen={isOpen}
          debouncedIsOpen={debouncedIsOpen}
          key={route.path}
        />
      ))}
      <Div
        css={{
          height: 1,
          width: '90%',
          margin: '$4 auto',
          background: '$interface_light_down',
        }}
      />
      {publicRoutes.map((route) => (
        <NavigationItem
          route={route}
          isOpen={isOpen}
          debouncedIsOpen={debouncedIsOpen}
          key={route.path}
        />
      ))}
      <Div
        css={{
          height: 1,
          width: '90%',
          margin: '$4 auto',
          background: '$interface_light_down',
        }}
      />
      <NavigationItem
        route={{
          icon: <QuestionMarkCircleIcon />,
          label: 'Ayuda',
          path: '/ayuda',
          disabled: true,
        }}
        isOpen={isOpen}
        debouncedIsOpen={debouncedIsOpen}
      />
      <NavigationItem
        route={{
          icon: <SparklesIcon />,
          label: 'Planes',
          path: '/ayuda',
          disabled: true,
        }}
        isOpen={isOpen}
        debouncedIsOpen={debouncedIsOpen}
      />
    </NavigatorContainer>
  )
}

const NavigationItem = ({
  route,
  isOpen,
  debouncedIsOpen,
}: {
  route: IRoute
  isOpen: boolean
  debouncedIsOpen: boolean
}) => {
  const router = useRouter()

  const simpleRouteRef = React.useRef<HTMLDivElement>(null)
  const routeRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!routeRef.current) return

    return draggable({
      element: routeRef.current,
      canDrag: () => router.pathname !== route.path,
      onDrag: ({ source }) => {
        // console.log('dragging')
      },
      getInitialData: () => {
        return {
          screen: route.permission,
          type: 'layout-screen',
        }
      },
    })
  }, [isOpen, route, router])

  useEffect(() => {
    if (!simpleRouteRef.current) return

    return draggable({
      element: simpleRouteRef.current,
      onDrag: ({ source }) => {
        // console.log('dragging')
      },
      getInitialData: () => {
        return {
          screen: route.permission,
          type: 'layout-screen',
        }
      },
    })
  }, [isOpen, route])

  return (
    <>
      {isOpen ? (
        <UnstyledLink
          href={route.disabled ? '' : route.path}
          passHref
          css={{
            opacity: route.disabled ? 0.5 : 1,
          }}
        >
          <RouteContainer isSelected={router.pathname.includes(route.path)}>
            <RouterContentContainer ref={routeRef}>
              {!!route.icon && route.icon}
              {isOpen && debouncedIsOpen && (
                <Text
                  css={{
                    width: '100%',
                  }}
                  variant="description"
                >
                  {route.label}
                </Text>
              )}
            </RouterContentContainer>

            <Div>
              {route.tag && (
                <Tag
                  css={{
                    whiteSpace: 'nowrap',
                    marginRight: '$2',
                  }}
                >
                  {route.tag}
                </Tag>
              )}
            </Div>
          </RouteContainer>
        </UnstyledLink>
      ) : (
        <Tooltip key={route.label} arrow message={route.label} side="right">
          <UnstyledLink href={route.disabled ? '' : route.path} passHref>
            <Div
              ref={simpleRouteRef}
              css={{
                opacity: route.disabled ? 0.5 : 1,
              }}
            >
              <RouteContainer isSelected={router.pathname === route.path}>
                {!!route.icon && route.icon}
                {isOpen && debouncedIsOpen && (
                  <Text variant="description">{route.label}</Text>
                )}
              </RouteContainer>
            </Div>
          </UnstyledLink>
        </Tooltip>
      )}
    </>
  )
}
