import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ConfigFields, ConfigSchema } from '../../..'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCan } from '@/hooks'

export const usePunchGroupInfo = () => {
  const canCreatePunches = useCan(['configurations.locations'])
  const { data: company } = useMyCompany()

  const { control } = useFormContext<ConfigSchema>()

  const punchConfigGroupIds = useWatch({
    control,
    name: 'place.punches_group_ids',
  })

  const groupInfoForm: ConfigFields = useMemo(() => {
    const punchGroups = company?.punchConfigGroups.map((group) => ({
      label: group.name,
      value: group.id,
      selected: punchConfigGroupIds?.includes(group.id),
    }))

    const groupInfoForm: ConfigFields = [
      {
        _type: 'input',
        label: 'Nombre del Local',
        disabled: !canCreatePunches,
        name: 'place.name',
      },
      {
        _type: 'input',
        label: 'Descrición',
        disabled: !canCreatePunches,
        name: 'place.description',
      },
      {
        _type: 'input',
        label: 'Latitude',
        disabled: !canCreatePunches,
        name: 'place.latitude',
      },
      {
        _type: 'input',
        label: 'Longitude',
        disabled: !canCreatePunches,
        name: 'place.longitude',
      },
      {
        _type: 'input',
        label: 'Radio de Marcación',
        name: 'place.punches_radius',
        disabled: !canCreatePunches,
        inputType: 'only-numbers',
      },
      {
        _type: 'chip',
        label: 'Selecione los grupos que pueden marcar en este local',
        placeholder: 'Grupos de Marcacion',
        disabled: !canCreatePunches,
        options: punchGroups,
        name: 'place.punches_group_ids',
        direction: 'left',
      },
    ]

    return groupInfoForm
  }, [canCreatePunches, company?.punchConfigGroups, punchConfigGroupIds])

  return {
    data: groupInfoForm,
  }
}
