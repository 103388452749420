import { ControlledInput, Div, Touchable } from '@/components'
import { ControlledSwitch } from '@/components/Forms'
import { useCan } from '@/hooks'
import { ConfigSchema } from '@/pages/configuraciones/components'
import { formatCurrency } from '@/utils/numbers/currency'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Button, Divider, Tag, Text, Tooltip } from '@punto-ui/react'
import { useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

type ILiquidationConcept = {
  id?: string
  isCredit: boolean
  name: string
  value: number
  amount: number
  ips: boolean
}

export const LiquidationOtherConceptsTab = () => {
  const { getValues, control, setValue } = useFormContext<ConfigSchema>()

  const [newConcept, newConcepts] = useWatch({
    control,
    name: ['liquidation.newConcept', 'liquidation.newConcepts'],
  })

  const handleAddNewConcept = () => {
    const newConceptValues = getValues('liquidation.newConcept')
    const concepts = getValues('liquidation.newConcepts')

    setValue('liquidation.newConcepts', [
      ...concepts,
      {
        ...newConceptValues,
        id: Date.now().toString(),
      },
    ])
    setValue('liquidation.newConcept', {
      amount: 0,
      ips: false,
      isCredit: false,
      name: '',
      value: 0,
      id: '',
    })
  }
  const canCreateLiquidation = useCan(['configurations.liquidations'])

  const errorMessage = useMemo(() => {
    if (!newConcept.name) {
      return 'El nombre del concepto es requerido'
    }

    return ''
  }, [newConcept])
  return (
    <Div>
      <Text
        size="xl"
        css={{
          color: '$interface_dark_deep',
          fontWeight: '$bold',
          marginBottom: '$2',
          marginTop: '$4',
        }}
      >
        Configuración de otros conceptos
        <Div
          css={{
            marginTop: '$4',
          }}
        >
          <Div
            css={{
              display: 'flex',
              gap: '$4',
              marginBottom: '$4',
            }}
          >
            <Div
              css={{
                flex: 1,
              }}
            >
              <ControlledInput
                name={'liquidation.newConcept.name'}
                label="Nombre del Concepto"
                shouldValidate={false}
                disabled={!canCreateLiquidation}
              />
            </Div>
            <Div
              css={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '$8',
              }}
            >
              <ControlledSwitch
                name={'liquidation.newConcept.ips'}
                label="IPS"
                disabled={!canCreateLiquidation}
              />
              <ControlledSwitch
                disabled={!canCreateLiquidation}
                name={'liquidation.newConcept.isCredit'}
                label={newConcept.isCredit ? 'Credito' : 'Debito'}
              />
            </Div>
            {/* <ControlledInput
              name={'liquidation.newConcept.amount'}
              label="Cantidad del Concepto"
              shouldValidate={false}
              inputType="only-numbers"
            /> */}
          </Div>
          <Div
            css={{
              marginTop: '$8',
              display: 'flex',
            }}
          >
            {errorMessage ? (
              <Tooltip message={errorMessage} arrow>
                <Div>
                  <Button disabled variant={'secondary'}>
                    Agregar Concepto
                  </Button>
                </Div>
              </Tooltip>
            ) : (
              <Button
                disabled={!canCreateLiquidation}
                onClick={handleAddNewConcept}
                variant={'secondary'}
              >
                Agregar Concepto
              </Button>
            )}
          </Div>
          <Divider
            dir="horizontal"
            css={{
              marginTop: '$4',
              width: '100%',
              height: 1,
            }}
          />
        </Div>
      </Text>

      <Div>
        {newConcepts?.map((c, index) => (
          <LiquidationConcept key={c.id} concept={c} index={index} />
        ))}
      </Div>
    </Div>
  )
}

interface SalaryGroupConceptProps {
  concept: ILiquidationConcept
  index: number
}

const LiquidationConcept = ({ concept, index }: SalaryGroupConceptProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { getValues, setValue } = useFormContext<ConfigSchema>()
  const canCreateLiquidation = useCan(['configurations.liquidations'])

  return (
    <Div>
      <Touchable onClick={() => setIsOpen(!isOpen)}>
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '$4',
            paddingBottom: '$4',
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$6',
                marginBottom: '$2',
              }}
            >
              <Text
                variant="paragraph"
                css={{
                  color: '$interface_dark_deep',
                }}
              >
                {isOpen ? (
                  <ControlledInput
                    disabled={!canCreateLiquidation}
                    name={`liquidation.newConcepts.${index}.name`}
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : (
                  <>{concept.name}</>
                )}
              </Text>
              {concept.ips && <Tag variant={'neutral'}>IPS</Tag>}
              {concept.isCredit && <Tag variant={'positive'}>Credito</Tag>}
              {!concept.isCredit && <Tag variant={'negative'}>Debito</Tag>}
            </Div>
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$4',

                svg: {
                  height: 20,
                  width: 20,
                  color: '$interface_dark_deep',
                },
              }}
            >
              <Text
                variant={'paragraph'}
                css={{
                  color: '$interface_dark_deep',
                }}
              >
                {formatCurrency(concept.value)}
              </Text>
              {!isOpen && <ChevronRightIcon />}
              {isOpen && <ChevronDownIcon />}
            </Div>
          </Div>
        </Div>
      </Touchable>
      <Div>
        {isOpen && (
          <Div
            css={{
              marginTop: '$4',
              marginBottom: '$4',
              display: 'flex',
              flexDirection: 'column',
              gap: '$4',
            }}
          >
            <Div
              css={{
                display: 'flex',
                gap: '$4',
              }}
            >
              {/* <Div
                css={{
                  flex: 1,
                }}
              >
                <ControlledInput
                  name={`liquidation.newConcepts.${index}.amount`}
                  label="Cantidad"
                  inputType="only-numbers"
                />
              </Div> */}
              {/* <Div
                css={{
                  width: '50%',
                  marginTop: '$4',
                  marginBottom: '$4',
                }}
              > */}
              {/* <Div
                css={{
                  flex: 1,
                }}
              >
                <ControlledInput
                  name={`liquidation.newConcepts.${index}.value`}
                  label="Valor"
                  inputType="money-guarani"
                />
              </Div> */}
            </Div>
            <Div
              css={{
                display: 'flex',
              }}
            >
              <Div
                css={{
                  flex: 1,
                }}
              >
                <ControlledSwitch
                  disabled={!canCreateLiquidation}
                  name={`liquidation.newConcepts.${index}.ips`}
                  label="IPS"
                />
              </Div>
              <Div
                css={{
                  flex: 1,
                }}
              >
                <ControlledSwitch
                  disabled={!canCreateLiquidation}
                  name={`liquidation.newConcepts.${index}.isCredit`}
                  label={concept.isCredit ? 'Credito' : 'Debito'}
                />
              </Div>
            </Div>
            {/* </Div> */}
            <Div
              css={{
                marginTop: '$8',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="secondaryCritical"
                icon={<TrashIcon />}
                disabled={!canCreateLiquidation}
                onClick={() => {
                  const concepts = getValues('liquidation.newConcepts')
                  const newConcepts: ILiquidationConcept[] = concepts.filter(
                    (c, i) => i !== index,
                  )
                  setValue('liquidation.newConcepts', newConcepts)
                }}
              >
                Eliminar
              </Button>
            </Div>
          </Div>
        )}
      </Div>

      <Divider
        dir="horizontal"
        css={{
          height: 1,
          width: '100%',
          // marginTop: '$4',
        }}
      />
    </Div>
  )
}
