import { queryClient } from '@/libs/react-query'
import { IExportTemplateDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestExportTemplate = async (data: IExportTemplateDTO) => {
  await apiV2.post<any>(`/report-builder/request-export-template`, data)
  queryClient.invalidateQueries('templates')
}

export const useHandleRequestExportTemplate = () => {
  const mutation = useCustomMutation(
    ['useHandleExportTemplate'],
    handleRequestExportTemplate,
  )

  return mutation
}
