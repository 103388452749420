import {
  generateRandomCodes,
  generateRandomHour,
  generateRandomShiftNames,
  generateRandomTimezones,
} from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const singleShiftColumns: IReportBuilderColumnType[] = [
  {
    type: 'shifts',
    value: 'code',
    valueType: 'string' as const,
    label: 'Código',
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomCodes,
  },
  {
    type: 'shifts',
    value: 'name',
    label: 'Nombre',
    valueType: 'string' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomShiftNames,
  },
  {
    type: 'shifts',
    value: 'timezone',
    label: 'Huso Horario',
    valueType: 'string' as const,
    getRandomPlaceholder: generateRandomTimezones,
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'entrance_tolerance_before',
    valueType: 'number' as const,
    getRandomPlaceholder: generateRandomHour,
    label: 'Tolerancia de Entrada - llegada antecipada',
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'entrance_tolerance_after',
    valueType: 'number' as const,
    getRandomPlaceholder: generateRandomHour,
    label: 'Tolerancia de Entrada - llegada tardia',
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'exit_tolerance_before',
    valueType: 'number' as const,
    getRandomPlaceholder: generateRandomHour,
    label: 'Tolerancia de Salida - retiro antecipado',
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'exit_tolerance_after',
    valueType: 'number' as const,
    getRandomPlaceholder: generateRandomHour,
    label: 'Tolerancia de Salida - retiro tardio',
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'total_hours',
    label: 'Total Horas',
    valueType: 'number' as const,
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'total_morning',
    valueType: 'number' as const,
    label: 'Total Diurno',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Fechas',
  },
  {
    type: 'shifts',
    value: 'total_night',
    valueType: 'number' as const,
    label: 'Total Nocturno',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Fechas',
  },
]
