import { Div } from '@/components'
import { FormContainer } from '..'
import { Text, styled } from '@punto-ui/react'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { InactivateWorkerSchema } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { CSS } from '@stitches/react'
import { formatCurrency } from '@/utils/numbers/currency'
import { capitalizeFirstLetter } from '@/utils/workers/name'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'

interface IIPSLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const IPSLiquidation = ({
  isOpen,
  toggleOpen,
}: IIPSLiquidationParams) => {
  const conceptKey = 'ips9'

  const { control } = useFormContext<InactivateWorkerSchema>()

  const [ips, ipsValues, isUserInIps] = useWatch({
    control,
    name: ['ips', 'ipsValues', 'user_in_ips'],
  })

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        withoutIpsButton
        withoutStatusButton={!isUserInIps}
        setIsOpen={toggleOpen}
        title={concept.name || 'IPS'}
        conceptType="basic"
        deactivateKey={conceptKey}
        totals={[
          `Bane imponible: ${formatCurrency(ipsValues.ipsValue)}`,
          `IPS colaborador (9%): ${formatCurrency(ipsValues.ips9Value)}`,
          `IPS patronal (16.5%): ${formatCurrency(ipsValues.ips16Value)}`,
        ]}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && (
        <Div>
          <TableLiquidationIPS>
            <TableRowLiquidationIPS
              css={{
                '*': {
                  fontWeight: 'bold',
                },
              }}
            >
              <TableContentLiquidationIPS>Concepto</TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                Valor (G$)
              </TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                Valor IPS (G$)
              </TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                Retención 9%
              </TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                Retención 16.5%
              </TableContentLiquidationIPS>
            </TableRowLiquidationIPS>
          </TableLiquidationIPS>

          <Div>
            {ips.map((i) => (
              <TableRowLiquidationIPS key={`${i.type}${i.label}`}>
                <TableContentLiquidationIPS>
                  {capitalizeFirstLetter(i.label)}
                </TableContentLiquidationIPS>
                <TableContentLiquidationIPS>
                  {formatCurrency(i.value)}
                </TableContentLiquidationIPS>
                <TableContentLiquidationIPS>
                  {formatCurrency(i.valueIps)}
                </TableContentLiquidationIPS>
                <TableContentLiquidationIPS>
                  {formatCurrency(i.ips9)}
                </TableContentLiquidationIPS>
                <TableContentLiquidationIPS>
                  {formatCurrency(i.ips16)}
                </TableContentLiquidationIPS>
              </TableRowLiquidationIPS>
            ))}
          </Div>

          <TableLiquidationIPS>
            <TableRowLiquidationIPS
              css={{
                '*': {
                  fontWeight: 'bold',
                },
              }}
            >
              <TableContentLiquidationIPS />
              <TableContentLiquidationIPS>
                {formatCurrency(
                  ipsValues.totalValue < 0 ? 0 : ipsValues.totalValue,
                )}
              </TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                {formatCurrency(
                  ipsValues.ipsValue < 0 ? 0 : ipsValues.ipsValue,
                )}
              </TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                {formatCurrency(
                  ipsValues.ips9Value < 0 ? 0 : ipsValues.ips9Value,
                )}
              </TableContentLiquidationIPS>
              <TableContentLiquidationIPS>
                {formatCurrency(
                  ipsValues.ips16Value < 0 ? 0 : ipsValues.ips16Value,
                )}
              </TableContentLiquidationIPS>
            </TableRowLiquidationIPS>
          </TableLiquidationIPS>
        </Div>
      )}
    </FormContainer>
  )
}

const TableRowLiquidationIPS = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const TableLiquidationIPS = styled('div', {})

const TableContentLiquidationIPS = ({
  children,
  css,
}: {
  children?: any
  css?: CSS
}) => {
  return (
    <Div
      css={{
        minWidth: '20%',
        padding: '$2',
        ...css,
      }}
    >
      <Text>{children}</Text>
    </Div>
  )
}
