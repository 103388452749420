export const PdfIcon = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="2" fill="#EA4336" />
      <path
        d="M2.972 5.496H4.7C5.07867 5.496 5.364 5.568 5.556 5.712C5.75333 5.85067 5.884 6.048 5.948 6.304C6.01733 6.56 6.052 6.89867 6.052 7.32C6.052 7.72533 6.02 8.05867 5.956 8.32C5.892 8.576 5.75867 8.77867 5.556 8.928C5.35867 9.07733 5.068 9.152 4.684 9.152H3.884V11H2.972V5.496ZM4.468 8.328C4.676 8.328 4.82533 8.30133 4.916 8.248C5.00667 8.19467 5.06533 8.10133 5.092 7.968C5.11867 7.83467 5.132 7.61867 5.132 7.32C5.132 7.02667 5.11867 6.81333 5.092 6.68C5.06533 6.54667 5.00667 6.45333 4.916 6.4C4.82533 6.34667 4.67867 6.32 4.476 6.32H3.884V8.328H4.468ZM6.72981 5.496H8.14581C8.70048 5.496 9.09248 5.57333 9.32181 5.728C9.55648 5.87733 9.70315 6.128 9.76181 6.48C9.82581 6.832 9.85781 7.42133 9.85781 8.248C9.85781 9.07467 9.82581 9.664 9.76181 10.016C9.70315 10.368 9.55648 10.6213 9.32181 10.776C9.09248 10.9253 8.70048 11 8.14581 11H6.72981V5.496ZM8.12181 10.176C8.40448 10.176 8.59648 10.144 8.69781 10.08C8.79915 10.016 8.86581 9.864 8.89781 9.624C8.92981 9.37867 8.94581 8.92 8.94581 8.248C8.94581 7.58133 8.92981 7.12533 8.89781 6.88C8.86581 6.63467 8.79915 6.48 8.69781 6.416C8.59648 6.352 8.40448 6.32 8.12181 6.32H7.64181V10.176H8.12181ZM10.6908 11V5.496H13.1868V6.32H11.6028V7.936H13.0108V8.76H11.6028V11H10.6908Z"
        fill="white"
      />
    </svg>
  )
}
