import { api } from '@/services/api'
import { useMutation } from 'react-query'
import { IShift } from '@/libs/react-query/types'

const handleDeleteShift = async ({ ...props }: any) => {
  const response = await api.put<IShift>(`/shift/${props.shift_id}`, {
    new_shift_id: props.new_shift_id,
  })

  return response.data
}

export const useHandleDeleteShifts = () => {
  const mutation = useMutation(['useHandleShift'], handleDeleteShift)

  return mutation
}
