import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'

export const useRequestListColumns = () => {
  const columns = useMemo(() => {
    const columns: SmartColumnType[] = [
      {
        type: 'checkbox',
        name: 'checked',
        draggable: null,
        header: '',
        width: 32,
      },
      {
        type: 'avatar',
        width: 32,
        name: 'avatar',
        draggable: null,
        header: '',
      },
      {
        type: 'input-text',
        width: 120,
        inputType: 'no-mask',
        name: 'name_requester',
        draggable: null,
        header: 'Solicitado por',
        disabled: true,
      },
      {
        type: 'input-text',
        width: 120,
        inputType: 'no-mask',
        name: 'name_authorizer',
        draggable: null,
        disabled: true,
        header: 'Autorizado por',
      },
      {
        type: 'tags',
        width: 100,
        name: 'status',
        draggable: null,
        disabled: true,
        header: 'Status',
      },
      {
        width: 100,
        type: 'input-text',
        name: 'module',
        inputType: 'no-mask',
        draggable: null,
        disabled: true,
        header: 'Modulo',
      },
      {
        type: 'input-text',
        width: 100,
        name: 'type',
        inputType: 'no-mask',
        draggable: null,
        disabled: true,
        header: 'Tipo',
      },
      {
        type: 'input-text',
        width: 150,
        name: 'request_date',
        inputType: 'no-mask',
        draggable: null,
        disabled: true,
        header: 'Fecha Solicitud',
      },
      {
        type: 'input-text',
        name: 'authorization_date',
        width: 150,
        inputType: 'no-mask',
        draggable: null,
        disabled: true,
        header: 'Fecha Autorización',
      },
    ]

    return columns
  }, [])

  return columns
}
