import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateBusinessInfosDTO } from '@/libs/react-query/types/admin'
dayjs.extend(utc)

const handleUpdateBusinessInfo = async (data: IUpdateBusinessInfosDTO) => {
  await apiV2.post<{ token: string }>('/admin/update-business-infos', data)

  queryClient.resetQueries('companies')
}

export const useHandleUpdateBusinessInfo = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateBusinessInfo'],
    handleUpdateBusinessInfo,
  )

  return mutation
}
