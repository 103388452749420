import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IReceipt } from '../../types/reports'

const fetchReportReceipt = async (id: string) => {
  try {
    const response = await apiV2.post<IReceipt>('/report-builder/get-receipt', {
      id,
    })

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const useReportReceipt = (id: string) => {
  const query = useQuery(
    ['receipt', id],
    async () => {
      const workers = await fetchReportReceipt(id)

      return workers
    },
    {
      keepPreviousData: true,
      enabled: !!id,
    },
  )

  return query
}
