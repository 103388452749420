import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { usePayrollDay } from './usePayrollDay'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekdays: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ],
})

export const useShiftOrganization = (props?: { totalWidth?: number }) => {
  const { data: payrollDay } = usePayrollDay()

  const shiftsOrganizationData = useMemo(() => {
    const result = payrollDay?.schedule.shifts.map((shift) => {
      const intervals: string[] = []
      if ((shift.currentDateShift?.intervals?.length || 0) > 1) {
        shift.currentDateShift?.intervals?.forEach((interval, index) => {
          if (shift.currentDateShift?.intervals[index + 1]) {
            intervals.push(
              shift.currentDateShift?.intervals[index].endTime +
                '/' +
                shift.currentDateShift?.intervals[index + 1].startTime,
            )
          }
        })
      }

      return {
        date: `${dayjs(shift.date).format('DD/MM')} - ${dayjs(
          shift.date,
        ).format('dddd')}`,
        entrance: shift.currentDateShift?.intervals[0].startTime,
        exit: shift.currentDateShift?.intervals[
          shift.currentDateShift.intervals.length - 1
        ].endTime,
        intervals,
      }
    })

    return result
  }, [payrollDay?.schedule.shifts])

  const greaterInterval = useMemo(() => {
    return (
      payrollDay?.schedule.shifts.reduce((acc, shift) => {
        if ((shift?.currentDateShift?.intervals?.length || 0) - 1 > acc) {
          return (shift.currentDateShift?.intervals?.length || 0) - 1
        }

        return acc
      }, 0) || 0
    )
  }, [payrollDay])

  const width = useMemo(() => {
    return props?.totalWidth ? props.totalWidth / (greaterInterval + 3) : 130
  }, [props?.totalWidth, greaterInterval])

  const shiftsOrganizationColumns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Programación de trabajo actual',
        name: 'teste',
        items: [
          {
            type: 'input-text',
            name: 'date',
            disabled: true,
            draggable: null,
            header: 'Fecha',
            width,
          },
          {
            type: 'input-text',
            name: 'entrance',
            draggable: null,
            disabled: true,
            header: 'Entrada',
            width,
          },
          {
            type: 'input-text',
            name: 'intervals',
            draggable: null,
            header: 'Intervalo',
            array: true,
            length: greaterInterval,
            disabled: true,
            inputType: 'no-mask',
            width,
          },
          {
            type: 'input-text',
            name: 'exit',
            draggable: null,
            header: 'Salida',
            disabled: true,
            inputType: 'no-mask',
            width,
          },
        ],
      },
    ]
  }, [greaterInterval, width])

  return {
    columns: shiftsOrganizationColumns,
    data: shiftsOrganizationData,
  }
}
