import { Div } from '@/components'
import { ICompanyTotem } from '@/libs/react-query/types/company'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Tag, Text } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import React from 'react'

export const useTotemsColumns = () => {
  const columns = React.useMemo<ColumnDef<ICompanyTotem>[]>(() => {
    const retunedColumns: ColumnDef<ICompanyTotem>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text css={{ whiteSpace: 'nowrap' }}>{row.original.name}</Text>
          </Div>
        ),
      },
      {
        id: 'login',
        header: 'Login',
        accessorFn: (row) => row.login,
      },
      {
        id: 'created_at',
        header: 'Creado en',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Text>
            {dayjs(row.original.created_at).format('DD/MM/YYYY HH:mm')}
          </Text>
        ),
      },
      {
        id: 'is_location_obligatory',
        header: 'Localización Obligatoria',
        meta: {
          content: 'component',
          thStyles: {
            textAlign: 'center',
          },
        },
        cell: ({ row }) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tag
              variant={
                row.original.is_location_obligatory ? 'positive' : 'negative'
              }
            >
              {row.original.is_location_obligatory ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'is_facial_registration_enabled',
        header: 'Registro Facial Habilitado',
        meta: {
          content: 'component',
          thStyles: {
            textAlign: 'center',
          },
        },
        cell: ({ row }) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tag
              variant={
                row.original.is_facial_registration_enabled
                  ? 'positive'
                  : 'negative'
              }
            >
              {row.original.is_facial_registration_enabled ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
