import { ICell, IOrganogram } from '@/libs/react-query/types/organogram'

export const getAllCellsUnderCellId = (
  organogram: IOrganogram,
  cellId: string,
) => {
  // Find the specified cell first
  const findCell = (
    cell: IOrganogram,
    targetId: string,
  ): IOrganogram | null => {
    if (cell.id === targetId) {
      return cell
    }
    for (const child of cell.children) {
      const foundCell = findCell(child, targetId)
      if (foundCell) {
        return foundCell
      }
    }
    return null
  }

  const targetCell = findCell(organogram, cellId)

  if (!targetCell) {
    throw new Error(`Cell not found with id: ${cellId}`)
  }

  // Now traverse from the target cell to get all descendant cell IDs
  const traverseDescendants = (cell: IOrganogram): string[] => {
    let descendantIds: string[] = [cell.id]
    for (const child of cell.children) {
      descendantIds.push(child.id)
      descendantIds = descendantIds.concat(traverseDescendants(child))
    }
    return descendantIds
  }

  return traverseDescendants(targetCell)
}

export const getMaxWidth = (organogram: IOrganogram, depth = 0): number => {
  const nameSize = organogram.name.length
  const currentWidth = nameSize * 9.5 + 80 + (depth + 1) * 22

  // Iterate through children to find the maximum width
  const childrenWidths = organogram.children.map((child) =>
    getMaxWidth(child, depth + 1),
  )
  const maxWidthFromChildren =
    childrenWidths.length > 0 ? Math.max(...childrenWidths) : 0

  return Math.max(currentWidth, maxWidthFromChildren)
}

export const getDepth = (organogram: IOrganogram): number => {
  if (!organogram.children || organogram.children.length === 0) {
    return 0
  }
  return 1 + Math.max(...organogram.children.map((child) => getDepth(child)))
}

export function getAllDescendantsFromCellIds(
  organogram: IOrganogram,
  cellIds: string[],
): string[] {
  // Reuse the findCell and traverseDescendants functions from the previous implementation

  const findCell = (
    cell: IOrganogram,
    targetId: string,
  ): IOrganogram | null => {
    if (cell.id === targetId) {
      return cell
    }
    for (const child of cell.children) {
      const foundCell = findCell(child, targetId)
      if (foundCell) {
        return foundCell
      }
    }
    return null
  }

  const traverseDescendants = (cell: IOrganogram): string[] => {
    let descendantIds: string[] = [cell.id]
    for (const child of cell.children) {
      descendantIds.push(child.id)
      descendantIds = descendantIds.concat(traverseDescendants(child))
    }
    return descendantIds
  }

  // Iterate over each cellId in the list, gathering all descendants
  let allDescendantIds: string[] = []
  for (const cellId of cellIds) {
    const targetCell = findCell(organogram, cellId)
    if (targetCell) {
      allDescendantIds = allDescendantIds.concat(
        traverseDescendants(targetCell),
      )
    }
  }

  // Optionally, remove duplicates if necessary
  return Array.from(new Set(allDescendantIds))
}

export function findAndBuildMatchingOrganograms(
  organogram: IOrganogram,
  cells: ICell[],
  name: string,
): IOrganogram[] {
  // Helper function to build a sub-organogram from a given cell
  const findCell = (
    cell: IOrganogram,
    targetId: string,
  ): IOrganogram | null => {
    if (cell.id === targetId) {
      return cell
    }
    for (const child of cell.children) {
      const foundCell = findCell(child, targetId)
      if (foundCell) {
        return foundCell
      }
    }
    return null
  }

  const buildSubOrganogram = (cell: ICell): IOrganogram | null => {
    const foundCell = findCell(organogram, cell.id)
    if (!foundCell) return null

    return foundCell
  }

  // Filter cells with the matching name
  const matchingCells = cells.filter((cell) =>
    cell.name.toLowerCase().includes(name.toLowerCase()),
  )

  // Build sub-organograms for each matching cell
  const matchingOrganograms: IOrganogram[] = []
  matchingCells.forEach((matchingCell) => {
    const subOrganogram = buildSubOrganogram(matchingCell)
    if (subOrganogram) {
      matchingOrganograms.push(subOrganogram)
    }
  })

  return matchingOrganograms
}
