import { styled } from '@punto-ui/react'

export const PayrollTableContainer = styled('div', {
  position: 'relative',
  height: 'calc(100vh - 120px)',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'scroll',
  paddingLeft: '$4',
  paddingRight: '$4',
})
