import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { ITemplate } from '../../types/reports'

const fetchReportTemplate = async (id: string) => {
  try {
    const response = await apiV2.post<ITemplate>(
      '/report-builder/get-template',
      {
        id,
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const useReportTemplate = (id: string) => {
  const query = useQuery(
    ['template', id],
    async () => {
      const workers = await fetchReportTemplate(id)

      return workers
    },
    {
      keepPreviousData: true,
      enabled: !!id,
    },
  )

  return query
}
