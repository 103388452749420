import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import { WorkersShifts } from '@/pages/turnos/components/SmartShiftManagement/types'

interface RequestUsersShiftsInDateParams {
  dates?: string[]
  usersIds?: string[]
  cellsIds: string[]
  filter: string
}

const fetchUsersShiftsInDate = async (
  params: RequestUsersShiftsInDateParams,
) => {
  try {
    const response = await apiV2.post<WorkersShifts[]>(
      `/shift/get-users-schedules?cellsIds=${params.cellsIds}`,
      {
        usersIds: params.usersIds,
        dates: params.dates,
        name: params.filter,
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
    return []
  }
}

export const useUsersShiftsInDate = (
  params: RequestUsersShiftsInDateParams,
) => {
  const { data: me } = useMe()

  return useQuery(
    ['usersShiftsInDate', params.dates, params.usersIds, params.filter],
    async () => {
      return fetchUsersShiftsInDate(params)
    },
    {
      enabled:
        !!me &&
        !!params.dates?.length &&
        !!params.usersIds?.length &&
        !!params.cellsIds?.length,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    },
  )
}
