import { styled } from '@punto-ui/react'

export const FiltersContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const FilterInput = styled('div', {
  width: '40%',
  display: 'flex',
  alignItems: 'center',
  gap: '$4',

  '& div:first-child': {
    width: '100%',
  },
})

export const FiltersChips = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$4',
  width: '40%',
})
