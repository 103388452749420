import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { DeletePunchPayload } from '../types'

const handleDeletePunch = async (props: DeletePunchPayload) => {
  const response = await apiV2.post<any>(
    '/punches/delete-punch-from-time',
    props,
  )

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleDeletePunch = () => {
  const mutation = useCustomMutation(['handleDeletePunch'], handleDeletePunch)

  return mutation
}
