type TextPart = {
  text: string
  type: 'word' | 'variable'
}

export const extractVariablesFromText = (
  input: string,
): Array<{
  text: string
  type: 'word' | 'variable'
}> => {
  const regex = /{([^}]+)}|([^{}]+)/g
  let match: RegExpExecArray | null
  const result: TextPart[] = []

  while ((match = regex.exec(input)) !== null) {
    if (match[1]) {
      // If match[1] is not undefined, it's the content inside the curly braces.
      result.push({ text: `{${match[1]}}`, type: 'variable' })
    } else if (match[2]) {
      // If match[2] is not undefined, it's the text outside the curly braces.
      result.push({ text: match[2], type: 'word' })
    }
  }

  return result
}
