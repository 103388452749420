import { Div } from '@/components'
import { queryClient } from '@/libs/react-query'
import { useMe } from '@/libs/react-query/hooks'
import { useLoggedUsers } from '@/libs/react-query/hooks/admin/useLoggedUsers'
import { useHandleUpdateCompanyId } from '@/libs/react-query/mutations/admin/useHandleUpdateCompanyId'
import { useTabStore } from '@/store'
import { WifiIcon } from '@heroicons/react/24/outline'
import {
  Button,
  Progress,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@punto-ui/react'
import dayjs from 'dayjs'

export const LoggedUsers = () => {
  const { data: me } = useMe()

  const { data: loggedUsers, isFetching: isFetchingCompany } = useLoggedUsers()

  const {
    mutateAsync: handleUpdateCompanyId,
    isLoading: isLoadingUpdateCompanyId,
  } = useHandleUpdateCompanyId()

  const { reset } = useTabStore((state) => ({
    reset: state.reset,
  }))

  const isLoading = isLoadingUpdateCompanyId || isFetchingCompany

  return (
    <TooltipProvider>
      <Div
        css={{
          marginLeft: '$4',
          marginRight: '$4',
          flex: 0.5,
          maxWidth: '50%',
          background: '$interface_light_pure',
          padding: '$4',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$4',
            paddingBottom: '$2',
          }}
        >
          <Div
            css={{
              flex: 1,

              display: 'flex',
              alignItems: 'center',
              gap: '$2',
              marginBottom: '$4',
              svg: { height: 16, width: 16, color: '$interface_dark_pure' },
            }}
          >
            <WifiIcon />
            <Text variant={'heading4'}>Gestores Online</Text>
          </Div>
          <Div
            css={{
              flex: 1,
            }}
          >
            {isLoading && <Progress />}
          </Div>
        </Div>

        <Div
          css={{
            display: 'flex',
            padding: '$4',

            maxHeight: 'calc(100vh - 324px)',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <Div
            css={{
              marginRight: '$2',
              width: '30%',
            }}
          >
            {loggedUsers?.map((user) => (
              <Tooltip key={user.id} message={user.name}>
                <RowItem key={user.id}>
                  <RowText>{user.name}</RowText>
                </RowItem>
              </Tooltip>
            ))}
          </Div>
          <Div
            css={{
              maxWidth: 350,
              marginRight: '$2',
              width: '30%',
            }}
          >
            {loggedUsers?.map((user) => (
              <Tooltip key={user.id} message={user.company_name}>
                <RowItem>
                  <RowText>{user.company_name}</RowText>
                </RowItem>
              </Tooltip>
            ))}
          </Div>
          <Div
            css={{
              width: '20%',
            }}
          >
            {loggedUsers?.map((user) => (
              <RowItem
                key={user.id}
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <RowText>
                  {dayjs(user.last_action_date).format('DD/MM HH:mm')}
                </RowText>
              </RowItem>
            ))}
          </Div>
          <Div
            css={{
              width: '20%',
              minWidth: 150,
            }}
          >
            {loggedUsers?.map((user) => (
              <RowItem key={user.id}>
                <Button
                  variant={'secondary'}
                  onClick={async () => {
                    if (me?.company_id !== user.company_id && user.company_id) {
                      await handleUpdateCompanyId(user.company_id)
                      reset()

                      queryClient.resetQueries()
                    }
                  }}
                  css={{
                    display:
                      me?.company_id === user.company_id ? 'none' : 'block',
                    marginLeft: 'auto',
                  }}
                >
                  Cambiar para
                </Button>
              </RowItem>
            ))}
          </Div>
          {/* {count?.map((c) => (
            <Div
              key={c.company_id}
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$4',
                marginBottom: '$4',
              }}
            >
              <Div>
                <Text>{c.name}</Text>
              </Div>
              <Div>
                <Text>{c.count}</Text>
              </Div>
              <Div>
                <Button variant={'secondary'}>Cambiar para</Button>
              </Div>
            </Div>
          ))} */}
        </Div>
      </Div>
    </TooltipProvider>
  )
}

const RowItem = styled('div', {
  height: 40,
  display: 'flex',
  alignItems: 'center',
})

export const RowText = styled(Text, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})
