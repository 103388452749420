import { SmartColumnType } from '@/components/SmartTable/types'

export const useVacationStatusColumns = () => {
  const columns: SmartColumnType[] = [
    {
      type: 'checkbox',
      name: 'checked',
      draggable: null,
      header: '',
      width: 32,
    },
    {
      type: 'expandable',
      name: 'expandable',
      draggable: null,
      header: '',
      width: 32,
    },
    {
      type: 'avatar',
      name: 'avatar',
      draggable: null,
      header: '',
      width: 32,
    },
    {
      type: 'input-text',
      name: 'name',
      draggable: null,
      header: 'Nombre',
      disabled: true,
      inputType: 'no-mask',
      width: 140,
    },
    {
      type: 'input-text',
      name: 'surname',
      draggable: null,
      header: 'Apellido',
      disabled: true,
      inputType: 'no-mask',
      width: 140,
    },
    {
      type: 'input-text',
      name: 'document',
      draggable: null,
      header: 'CI',
      disabled: true,
      inputType: 'no-mask',
      width: 100,
    },
    {
      type: 'input-text',
      header: 'Fecha Entrada',
      name: 'hiringDate',
      draggable: null,
      disabled: true,
      inputType: 'no-mask',
      width: 140,
    },
    {
      type: 'input-text',
      header: 'Antiguedad',
      name: 'antiquity',
      draggable: null,
      disabled: true,
      inputType: 'no-mask',
      width: 200,
    },
    {
      type: 'tags',
      header: 'Status',
      name: 'status',
      draggable: null,
      disabled: true,
      inputType: 'no-mask',
      width: 150,
    },
    {
      type: 'input-text',
      name: 'daysCaused',
      draggable: null,
      header: 'Causados',
      disabled: true,
      inputType: 'no-mask',
      width: 120,
    },
    {
      type: 'input-text',
      name: 'proportionalDays',
      draggable: null,
      header: 'Proporcionales',
      disabled: true,
      inputType: 'no-mask',
      width: 130,
    },
    {
      type: 'input-text',
      name: 'daysTaken',
      draggable: null,
      header: 'Cantidad de dias usufructuados',
      disabled: true,
      inputType: 'no-mask',
      width: 200,
    },
    {
      type: 'input-text',
      name: 'daysRemaining',
      draggable: null,
      header: 'Colaborador',
      disabled: true,
      inputType: 'no-mask',
      width: 140,
    },
    {
      type: 'input-text',
      name: 'daysOwed',
      draggable: null,
      header: 'Empresa',
      disabled: true,
      inputType: 'no-mask',
      width: 140,
    },
    // {
    //   type: 'custom',
    //   component: ({ index }: { index: number }) => {
    //     return (
    //       <Menu
    //         items={[
    //           {
    //             id: 'edit',
    //             label: 'Detalles',
    //             disabled: false,
    //             onClick: () => {
    //               openDrawer(index)
    //             },
    //             icon: <EyeIcon />,
    //           },
    //         ]}
    //       />
    //     )
    //   },
    //   name: 'dropdown',
    //   header: '',
    //   width: 50,
    // },
  ]

  return columns
}
