import { ColumnDef } from '@tanstack/react-table'
import { ShiftData } from '../../forms'
import { Tooltip } from '@punto-ui/react'
import { TableHeaderIcon } from '.'
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline'

interface IBuildShiftsColumnsParams {
  shifts: ShiftData[]
  handleRemoveColumn(index: number): void
  handleAddColumn(): void
}

export const buildShiftsColumns = ({
  handleAddColumn,
  handleRemoveColumn,
  shifts,
}: IBuildShiftsColumnsParams): ColumnDef<ShiftData>[] => {
  const intervalsInSheetShifts = shifts.reduce(
    (acc, shift) =>
      acc > shift.intervals.length ? acc : shift.intervals.length,
    0,
  )

  const intervalIndexAccess = Array.from(
    {
      length: intervalsInSheetShifts,
    },
    (_, i) => i,
  )

  const intervalColumns: ColumnDef<ShiftData>[] = intervalIndexAccess.map(
    (index) => {
      return {
        id: `interval_${index}`,
        header: () => (
          <Tooltip message="Remover intervalo" arrow dir="bottom">
            <TableHeaderIcon
              positive={false}
              onClick={() => handleRemoveColumn(index)}
            >
              <MinusCircleIcon height={16} width={16} />
            </TableHeaderIcon>
          </Tooltip>
        ),
        footer: (props) => props.column.id,
        columns: [
          {
            header: `Inicio Intervalo ${index + 1}`,
            cell: (info) => info.getValue(),
            footer: (info) => info.column.id,
            accessorFn: (row) => row.intervals[index].startTime,
            id: `start_interval_${index}`,
            meta: {
              isEditable: true,
              inputType: 'hour',
            },
          },
          {
            header: `Duración ${index + 1}`,
            cell: (info) => info.getValue(),
            footer: (info) => info.column.id,
            accessorFn: (row) => row.intervals[index].duration,
            id: `duration_interval_${index}`,
            meta: {
              isEditable: true,
              inputType: 'hour',
            },
          },
          {
            header: `Fim Intervalo ${index + 1}`,
            cell: (info) => info.getValue(),
            footer: (info) => info.column.id,
            accessorFn: (row) => row.intervals[index].endTime,
            id: `end_interval_${index}`,
            meta: {
              isEditable: true,
              inputType: 'hour',
            },
          },
        ],
      }
    },
  )

  intervalColumns.push({
    id: 'adicionar_intervalo',
    columns: [
      {
        header: () => (
          <Tooltip message="Agregar intervalo" arrow dir="bottom">
            <TableHeaderIcon positive onClick={() => handleAddColumn()}>
              <PlusCircleIcon height={16} width={16} />
            </TableHeaderIcon>
          </Tooltip>
        ),
        cell: () => '',
        footer: (info) => info.column.id,
        id: 'add-interval',
      },
    ],
  })

  const firstColumns: ColumnDef<ShiftData>[] = [
    {
      id: 'matriz',
      columns: [
        {
          header: 'Dia de la Semana',
          cell: (info) => info.getValue(),
          footer: (info) => info.column.id,
          accessorFn: (row) => row.dayOfWeek,
          id: 'name',
        },
        {
          header: 'Entrada',
          cell: (info) => info.getValue(),
          footer: (info) => info.column.id,
          accessorFn: (row) => row.startTime,
          id: 'startTime',
          meta: {
            isEditable: true,
            inputType: 'hour',
          },
        },
      ],
    },
  ]

  const endColumns: ColumnDef<ShiftData>[] = [
    {
      id: 'endColumns',
      columns: [
        {
          header: 'Salida',
          cell: (info) => info.getValue(),
          footer: (info) => info.column.id,
          accessorFn: (row) => row.endTime,
          id: 'endTime',
          meta: {
            isEditable: true,
            inputType: 'hour',
          },
        },
        {
          header: 'Cambio Jornada',
          cell: (info) => info.getValue(),
          footer: (info) => info.column.id,
          accessorFn: (row) => row.switchShiftsTime,
          id: 'switchShiftsTime',
          meta: {
            isEditable: true,
            inputType: 'hour',
          },
        },
        {
          header: 'Total Diurno',
          footer: (info) => info.column.id,
          accessorFn: (row) => row.totalDaily,
          id: 'totalDaily',
          meta: {
            isEditable: true,
            inputType: 'hour',
          },
        },
        {
          header: 'Total Nocturno',
          footer: (info) => info.column.id,
          accessorFn: (row) => row.totalNight,
          id: 'totalNight',
          meta: {
            isEditable: true,
            inputType: 'hour',
          },
        },
        {
          header: 'Total Horas',
          footer: (info) => info.column.id,
          accessorFn: (row) => row.totalHours,
          id: 'totalHours',
          meta: {
            isEditable: true,
            inputType: 'hour',
          },
        },
      ],
    },
  ]

  return [...firstColumns, ...intervalColumns, ...endColumns]
}
