import {
  BugAntIcon,
  BuildingLibraryIcon,
  ChartBarIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon,
  ExclamationCircleIcon,
  FireIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { Router } from '../router'
import { useMe } from '@/libs/react-query/hooks'
import { withSSRAuth } from '../utils/withSSRAuth'
import { AdminPage } from './Admin'
import { CompanyList } from './CompanyList'
import { useTabStore } from '@/store'
import { InvoiceManager } from './InvoiceManager'
import { StatisticsManager } from './StatisticsManager'

export default function Admin({ onlyTabs = false }: { onlyTabs?: boolean }) {
  const { data: me } = useMe()

  const { actions } = useTabStore((state) => ({
    actions: state.admin.actions,
  }))

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="admin"
      actions={actions}
      tabs={
        !me?.isAdmin
          ? []
          : [
              {
                icon: <BugAntIcon />,
                label: 'Administrativo',
                value: 'admin',
                scrollable: false,
                component: <AdminPage />,
              },
              {
                icon: <BuildingLibraryIcon />,
                label: 'Empresas Activas',
                value: 'active-company-list',
                scrollable: false,
                component: <CompanyList inStatus={['CLIENT']} />,
              },
              {
                icon: <FireIcon />,
                label: 'Empresas Nuevas',
                value: 'new-company-list',
                scrollable: false,
                component: <CompanyList inStatus={['NEW']} />,
              },
              {
                icon: <ExclamationCircleIcon />,
                label: 'Implementación',
                value: 'implementing-company-list',
                scrollable: false,
                component: <CompanyList inStatus={['IMPLEMENTING']} />,
              },
              {
                icon: <XCircleIcon />,
                label: 'Empresas Canceladas',
                value: 'canceled-company-list',
                scrollable: false,
                component: <CompanyList inStatus={['CANCELED']} />,
              },
              {
                icon: <ComputerDesktopIcon />,
                label: 'Empresas Prueba',
                value: 'test-company-list',
                scrollable: false,
                component: <CompanyList inStatus={['TEST']} />,
              },
              {
                icon: <CurrencyDollarIcon />,
                label: 'Gestión de Pagos',
                value: 'invoice-management',
                scrollable: false,
                component: <InvoiceManager />,
              },
              {
                icon: <ChartBarIcon />,
                label: 'Estadísticas de Empresas',
                value: 'statistics-management',
                scrollable: false,
                component: <StatisticsManager />,
              },
              {
                label: 'Empresas Deletadas',
                icon: <TrashIcon />,
                value: 'deleted-company-list',
                scrollable: false,
                component: <CompanyList inStatus={['DELETED']} />,
              },
              // {
              //   icon: <ChartBarIcon />,
              //   label: 'Playground',
              //   value: 'playground-admin',
              //   scrollable: false,
              //   component: <AdminPlayground />,
              // },
            ]
      }
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
