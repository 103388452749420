import { generateRandomNumberUnder10 } from '@/utils/random'
import { IReceiptBuilderRowDefinitionType, IReceiptBuilderTypes } from '../type'
import { payrollReceiptRows } from './payroll-receipt-rows'
import { liquidationReceiptRows } from './liquidation-receipt-rows'

export const rowsOptionsByReceiptType: Record<
  IReceiptBuilderTypes,
  IReceiptBuilderRowDefinitionType[]
> = {
  liquidation: liquidationReceiptRows,
  payment: [
    {
      label: 'Valor del Pago',
      value: 'value',
      subgroup: 'Valor (G$)',
      type: 'payment',
      isDebit: false,
      getRandomPlaceholder: generateRandomNumberUnder10,
    },
  ],
  payroll: payrollReceiptRows,
  vacations: [
    {
      label: 'Valor de Vacaciones',
      value: 'value',
      subgroup: 'Valor (G$)',
      type: 'vacations',
      isDebit: false,
      getRandomPlaceholder: generateRandomNumberUnder10,
    },
    // {
    //   label: 'Valor de Vacaciones (IPS)',
    //   value: 'value_ips',
    //   subgroup: 'Valor (G$)',
    //   type: 'vacations',
    //   isDebit: false,
    //   getRandomPlaceholder: generateRandomNumberUnder10,
    // },
    {
      label: 'IPS 9%',
      value: 'ips9',
      subgroup: 'Valor (G$)',
      type: 'vacations',
      isDebit: true,
      getRandomPlaceholder: generateRandomNumberUnder10,
    },
  ],
}
