import { useMemo } from 'react'
import { ImportWorkersFields } from './form'

export const useImportWorkersForm = () => {
  const optionalDataForm: ImportWorkersFields = useMemo(() => {
    const form: ImportWorkersFields = [
      {
        _type: 'file',
        label: 'Selecionar arquivo de importação',
        name: 'uploadedFile',
        accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      },
    ]

    return form
  }, [])

  return {
    data: optionalDataForm,
  }
}
