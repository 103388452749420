import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useCareerInfos = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const optionalDataForm: AddWorkerFields = useMemo(
    () => [
      {
        _type: 'input',
        label: 'Professión',
        disabled: !isWorkerCreationAvailable,
        name: 'profession',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Cargo',
        name: 'position',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Plan de Carrera',
        name: 'carreer_plan',
      },
    ],
    [isWorkerCreationAvailable],
  )

  return {
    data: optionalDataForm,
  }
}
