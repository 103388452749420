import { Div } from '@/components'
import { ProgressBar } from '@/components/ProgressBar'
import { useDebounce } from '@/hooks'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { createContext, useMemo, useRef, useState } from 'react'

interface IDownloadProviderProps {
  children: React.ReactNode
}

interface IDownloadContextProps {
  downloadFile: (url: string, name?: string) => void
}

export const DownloadContext = createContext<IDownloadContextProps>({
  downloadFile: () => null,
})

export const useDownload = () => React.useContext(DownloadContext)

export const DownloadContextProvider = ({
  children,
}: IDownloadProviderProps) => {
  const anchorRef = useRef<HTMLAnchorElement | null>(null)
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)

  const downloadFile = async (url: string, name?: string) => {
    setDownloadProgress(0)

    try {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            setDownloadProgress(percentCompleted)
          }
        },
      })

      const contentType = response.headers['content-type']
      const fileExtension = name ? name.split('.').pop() : url.split('.').pop()

      const fileName =
        name || `${dayjs().format('DD_MM_YYYY_HH_mm_ss')}.${fileExtension}`

      const blob = new Blob([response.data], { type: contentType })
      const downloadUrl = window.URL.createObjectURL(blob)
      const a = anchorRef.current
      if (a) {
        a.href = downloadUrl
        a.download = fileName // You can dynamically set the filename
        a.click()
        setTimeout(() => {
          window.URL.revokeObjectURL(downloadUrl)
          a.href = '' // Reset href
          setDownloadProgress(null)
        }, 100)
      }
    } catch (err) {
      console.error('Download failed:', err)
      setDownloadProgress(null)
    }
  }

  const isVisible = useMemo(() => downloadProgress !== null, [downloadProgress])
  const debouncedIsVisible = useDebounce(isVisible, 50)

  return (
    <DownloadContext.Provider
      value={{
        downloadFile,
      }}
    >
      {downloadProgress !== null && (
        <Div
          css={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 9999999,
            background: '$interface_light_pure',
            padding: '$4',
            borderRadius: '$md',
            border: '1px solid $brand_primary_pure',

            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            opacity: debouncedIsVisible ? 0.85 : 0,
            transform: debouncedIsVisible
              ? 'translateY(0)'
              : 'translateY(-20px)',
            pointerEvents: 'none',
          }}
        >
          <ProgressBar
            progressInPercent={downloadProgress || 0}
            text={`Downloading: ${downloadProgress}%`}
          />
        </Div>
      )}
      <a
        download // you can also set a specific filename e.g., download="filename.ext"
        ref={anchorRef}
        style={{ display: 'none' }}
        rel="noreferrer"
      />
      {children}
    </DownloadContext.Provider>
  )
}
