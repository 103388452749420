import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IPolicy } from '../../types'

const fetchPolicies = async () => {
  const response = await apiV2.get<IPolicy[]>('/permission/get-policies')

  return response.data
}

export const usePolicies = () => {
  return useQuery(['policies'], fetchPolicies)
}
