import { Div, FormRenderer } from '@/components'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
  ReportBuilderFields,
  ReportBuilderSchemaType,
} from '../../type'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  reportBuilderRowDetailsLabelByTypeAndRow,
  reportBuilderRowRelationsByTypeAndRow,
} from '../../constants/row-details-by-type-and-row'
import { Text } from '@punto-ui/react'

export const ConfigurationForm = () => {
  const { control, setValue } = useFormContext<ReportBuilderSchemaType>()

  const [rowsOptions, selectedRows, reportType] = useWatch({
    control,
    name: ['rowsOptions', 'selectedRow', 'configurations.reportType'],
  })

  const form: ReportBuilderFields = [
    {
      name: 'configurations.name',
      label: 'Nombre del template',
      _type: 'input',
    },
    {
      name: 'configurations.description',
      label: 'Descripción del template',
      _type: 'input',
    },
    {
      name: 'configurations.isFavorite',
      label: 'Reporte Favorito',
      _type: 'checkbox',
    },
    {
      name: 'configurations.reportType',
      label: 'Tipo de Reporte',
      _type: 'bullet-points',
      direction: 'vertical',
      defaultOption: {
        label: 'Individual',
        value: 'single',
      },
      options: [
        { label: 'Individual', value: 'single' },
        { label: 'Coletivo', value: 'collective' },
        // { label: 'Totales', value: 'totals' },
      ],
    },
    {
      name: 'selectedRow',
      label: 'Seleccione las lineas',
      _type: 'bullet-points',
      direction: 'vertical',
      defaultOption: {
        label: 'Individual',
        value: 'single',
      },
      options: rowsOptions.map((r) => ({
        label: r.label || '',
        value: r.value,
      })),
      onValueChange: () => {
        setValue('selectedRowDetails', '')
        setValue('selectedRowDetailsArray', [])
      },
    },
  ]

  const selectedRow = rowsOptions.find((r) => r.value === selectedRows)
  if (
    selectedRow?.details.length &&
    reportBuilderRowRelationsByTypeAndRow[reportType as IReportBuilderTypes]?.[
      selectedRows as IReportBuilderRowTypes
    ] === 'OR'
  ) {
    form.push({
      name: 'selectedRowDetails',
      label:
        reportBuilderRowDetailsLabelByTypeAndRow[
          reportType as IReportBuilderTypes
        ]?.[selectedRow.value as IReportBuilderRowTypes] || '',
      _type: 'bullet-points',
      direction: 'vertical',
      defaultOption: {
        label: '',
        value: '',
      },
      options: selectedRow?.details.map((r) => ({
        label: r.label || '',
        value: r.value,
      })),
    })
  } else if (
    selectedRow?.details.length &&
    reportBuilderRowRelationsByTypeAndRow[reportType as IReportBuilderTypes]?.[
      selectedRows as IReportBuilderRowTypes
    ] === 'AND'
  ) {
    form.push({
      component: (
        <Div css={{ marginBottom: -2, marginTop: 2 }}>
          <Text variant={'caption'}>
            {reportBuilderRowDetailsLabelByTypeAndRow[
              reportType as IReportBuilderTypes
            ]?.[selectedRow.value as IReportBuilderRowTypes] || ''}
          </Text>
        </Div>
      ),
      _type: 'custom',
    })
    selectedRow?.details.forEach((e, index) => {
      form.push({
        name: `selectedRowDetailsArray.${index}`,
        label: e.label,
        _type: 'checkbox',
      })
    })
  }

  // if (reportType === 'single') {
  //   form.push({
  //     name: 'configurations.singleReportType',
  //     label: 'Seleccione las lineas',
  //     _type: 'bullet-points',
  //     direction: 'vertical',
  //     defaultOption: {
  //       label: 'Individual',
  //       value: 'individual',
  //     },
  //     options: [
  //       { label: 'Individual', value: 'individual' },
  //       { label: 'Totales', value: 'totals' },
  //     ],
  //   })

  // }

  return (
    <Div
      css={{
        padding: '$6',
      }}
    >
      <FormRenderer items={form} />
    </Div>
  )
}
