import { IReportResponse } from '@/libs/react-query/types/reports'
import { TemplateExportTableSchemaType } from './type'
import dayjs from 'dayjs'
import {
  parseReportStatusToLabel,
  parseReportTypeToLabel,
} from '@/utils/reports/parseReportsType'

export const parseTemplateToRow = (
  templates: IReportResponse,
): TemplateExportTableSchemaType => {
  return {
    data: templates.data.map((template) => ({
      id: template.id,
      created_at: {
        value: dayjs(template.created_at).format('DD/MM/YYYY HH:mm'),
      },
      workers: {
        value: template.users.length,
      },
      name: {
        value: parseReportTypeToLabel(template.type as any)
          ? parseReportTypeToLabel(template.type as any)
          : template.type,
      },
      status: [
        {
          id: '1',
          name: parseReportStatusToLabel(template.status as string),
          variant:
            template.status === 'done'
              ? 'positive'
              : template.status === 'error'
              ? 'negative'
              : 'neutral',
        },
      ],
      expiration: {
        value: '',
      },
      link: {
        value: template.status === 'done' ? template.url || '' : '',
      },

      original: template,
    })),
    pagination: {
      page: templates.page,
      perPage: templates.pageSize,
      total: templates.total,
      totalPages: templates.totalPages,
    },
  }
}
