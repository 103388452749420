import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsInactivateWorkerFormDisabled } from '../hooks'

export const useAguinaldo = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [aguinaldoMonths] = useWatch({
    control,
    name: ['data.aguinaldo_months'],
  })
  const form = useMemo(() => {
    const aguinaldoForm: InactiveWorkerField[] = []

    aguinaldoMonths.forEach((aguinaldoMonth, index) => {
      const item = {
        _type: 'input',
        inputType: 'money-guarani',
        name: `data.aguinaldo_months.${index}.value`,
        label: aguinaldoMonth.label,
        disabled: isLoadingLiquidation,
        disclaimer: `Planilla: ${aguinaldoMonth.disclaimer}`,
      } as InactiveWorkerField
      aguinaldoForm.push(item)
    })

    return aguinaldoForm
  }, [aguinaldoMonths, isLoadingLiquidation])

  return {
    months: form,
    total: {
      _type: 'input',
      name: 'data.aguinaldo',
      disabled: isLoadingLiquidation,
      label: 'Aguinaldo Proporcional',
      inputType: 'money-guarani' as const,
    },
  }
}
