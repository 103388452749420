import { styled } from '@punto-ui/react'

export const TableHeadData = styled('td', {
  // padding: '$3 $0',
  whiteSpace: 'nowrap',
  // overflow: 'hidden',
  textOverflow: 'ellipsis',
  // border: 'solid 1px',

  // borderColor: '$interface_light_down',
})
