import { AppBar, ControlledFilterBar, Div, SmartTable } from '@/components'
import { useRequestListColumns } from './columns'
import { RequestsInfosTable, RequestsInfosTableType } from './form'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRequests } from '@/libs/react-query/hooks/useRequests'
import React, { useEffect, useState } from 'react'
import { parseUserRequestToRequestListing } from './parser'
import { RequestAppBar } from './components/RequestAppBar'
import { useHandleRespondRequest } from '@/libs/react-query/mutations/requests/useHandleRespondRequest'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { RequestDrawerDetail } from './components/RequestDrawerDetail'
import { useAllAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'

export const RequestList = () => {
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false)
  const { isLoading: isLoadingRespond, mutateAsync: handleRespondRequest } =
    useHandleRespondRequest()
  const columns = useRequestListColumns()

  const methods = useForm<RequestsInfosTableType>({
    resolver: zodResolver(RequestsInfosTable),
    defaultValues: {
      cellsIds: [],
      data: [],
      name: '',
      pagination: {
        page: 0,
        perPage: 20,
        total: 0,
        totalPages: 0,
      },
    },
  })

  const [allRequests, pagination, name, cellsIds] = useWatch({
    name: ['data', 'pagination', 'name', 'cellsIds'],
    control: methods.control,
  })

  const { data: requests, isFetching: isFetchingRequests } = useRequests({
    page: pagination.page,
    perPage: pagination.perPage,
    name,
    cellsIds,
  })

  useAllAvailableCells({
    methods,
    name: 'cellsIds',
    permission: 'requests',
  })

  useEffect(() => {
    if (requests) {
      methods.reset({
        cellsIds,
        data: parseUserRequestToRequestListing(requests.data),
        name: '',
        pagination: {
          page: requests.page,
          perPage: requests.pageSize,
          total: requests.total,
          totalPages: requests.totalPages,
        },
      })
    }
  }, [requests, methods])

  const someSelected = allRequests?.filter((request) => request.checked.value)

  const handleAcceptRequest = async () => {
    const allRequests = methods.getValues('data')
    handleRespondRequest({
      ids: allRequests
        .filter(
          (r) =>
            r.checked.value &&
            r.canAuthorizeRequest &&
            r.rawStatus === 'pending',
        )
        .map((r) => r.id),
      isAccepted: true,
    })
    setIsDetailsDrawerOpen(false)
  }

  const handleDenyRequest = async () => {
    const allRequests = methods.getValues('data')
    handleRespondRequest({
      ids: allRequests
        .filter(
          (r) =>
            r.checked.value &&
            r.canAuthorizeRequest &&
            r.rawStatus === 'pending',
        )
        .map((r) => r.id),
      isAccepted: false,
    })
    setIsDetailsDrawerOpen(false)
  }

  return (
    <FormProvider {...methods}>
      <ControlledFilterBar
        nameFilterName="name"
        cellsIdsName="cellsIds"
        isLoading={isLoadingRespond || isFetchingRequests}
        policiesIds={permissionsArray.filter((p) => p.includes('requests'))}
      />
      <Div
        css={{
          margin: '$4',
          // position: 'relative',
          // height: '100%',
          height: 'calc(100vh - 104px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 16,
          paddingRight: 16,
          overflowX: 'scroll',
          marginTop: '$4',
        }}
      >
        <SmartTable
          columns={columns}
          style={{
            borderInLines: true,
          }}
          withPagination
          paginationProps={{
            onChange: (data) => {
              methods.setValue('pagination.page', data.pageIndex)
              methods.setValue('pagination.perPage', data.pageSize)
            },
            page: pagination.page,
            perPage: pagination.perPage,
            totalPages: pagination.totalPages,
            total: pagination.total,
          }}
        />
      </Div>
      <AppBar isShowing={!!someSelected.length}>
        <RequestAppBar
          isLoading={isLoadingRespond}
          count={someSelected.length}
          handleDetails={() => setIsDetailsDrawerOpen(true)}
          handleAcceptRequest={handleAcceptRequest}
          handleDenyRequest={handleDenyRequest}
        />
      </AppBar>

      <RequestDrawerDetail
        isLoading={isLoadingRespond}
        handleAcceptRequest={handleAcceptRequest}
        handleDenyRequest={handleDenyRequest}
        isOpen={isDetailsDrawerOpen}
        handleCloseDrawer={() => {
          setIsDetailsDrawerOpen(false)
        }}
      />
    </FormProvider>
  )
}
