import { StepProps } from '@/components/Stepper'
import { IPdfTemplate } from '@/libs/react-query/types/reports'
import React from 'react'
import { getPdfVariablesFromLayouts } from './variable'

export type ExportPdfStepVariables = {
  hasShiftVariable: boolean
  hasMovementVariable: boolean
  hasVacationVariable: boolean
  hasPaymentVariable: boolean
  hasPayrollVariable: boolean
  hasLiquidationVariable: boolean
  customVariables: string[]
}

export const useExportPdfSteps = (pdf?: IPdfTemplate) => {
  const [step, setStep] = React.useState(0)

  const tableTemplates =
    pdf?.layouts?.filter((layout) =>
      layout.content.some((content) => content.type.includes('table:')),
    ) || []

  const variables = getPdfVariablesFromLayouts(pdf?.layouts)

  const hasShiftVariable = variables.some((variable) => {
    const variableHasDot = variable.includes('.')
    const [variableName] = variable.split('.')
    return variableName === 'turno' && variableHasDot
  })
  const hasMovementVariable = variables.some((variable) => {
    const variableHasDot = variable.includes('.')
    const [variableName] = variable.split('.')
    return variableName === 'movimiento' && variableHasDot
  })
  const hasVacationVariable = variables.some((variable) => {
    const variableHasDot = variable.includes('.')
    const [variableName] = variable.split('.')
    return variableName === 'vacaciones' && variableHasDot
  })
  const hasPaymentVariable = variables.some((variable) => {
    const variableHasDot = variable.includes('.')
    const [variableName] = variable.split('.')
    return variableName === 'pago' && variableHasDot
  })
  const hasPayrollVariable = variables.some((variable) => {
    const variableHasDot = variable.includes('.')
    const [variableName] = variable.split('.')
    return variableName === 'nomina' && variableHasDot
  })
  const hasLiquidationVariable = variables.some((variable) => {
    const variableHasDot = variable.includes('.')
    const [variableName] = variable.split('.')
    return variableName === 'liquidacion' && variableHasDot
  })
  const customVariables = variables.filter((variable) => {
    const variableHasDot = variable.includes('.')
    return !variableHasDot
  })

  const steps: Array<StepProps & { id: string; tableIndex?: number }> = []
  let stepIndex = 1

  if (
    !hasPaymentVariable &&
    !hasMovementVariable &&
    !hasVacationVariable &&
    !hasPayrollVariable &&
    !hasLiquidationVariable
  ) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Colaboradores`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'workers',
    })
    stepIndex += 1
  }

  if (hasLiquidationVariable) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Liquidaciones`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'liquidation',
    })
    stepIndex += 1
  }

  if (hasPayrollVariable) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Nómina`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'payroll',
    })
    stepIndex += 1
  }

  if (hasMovementVariable) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Movimientos`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'movements',
    })
    stepIndex += 1
  }

  if (hasVacationVariable) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Vacaciones`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'vacations',
    })
    stepIndex += 1
  }

  if (hasPaymentVariable) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Pagos`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'payments',
    })
    stepIndex += 1
  }

  if (hasShiftVariable) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Turnos`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'shifts',
    })
    stepIndex += 1
  }

  // tablas inseridas aqui

  if (tableTemplates?.length) {
    tableTemplates.forEach((table, index) => {
      const id = table.content[0].type.split(':')[1]

      steps.push({
        setFormStep: setStep,
        text: `Tabla ${index + 1}`,
        index: stepIndex - 1,
        error: false,
        selectedIndex: step,

        id: `table:${id}`,
        tableIndex: index,
      })
      stepIndex += 1
    })
  }

  if (customVariables.length > 0) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Variables Personalizadas`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'custom-variables',
    })
    stepIndex += 1
  }

  if (
    hasPaymentVariable ||
    hasMovementVariable ||
    hasVacationVariable ||
    hasPayrollVariable
  ) {
    steps.push({
      setFormStep: setStep,
      text: `${stepIndex} Colaboradores`,
      index: stepIndex - 1,
      error: false,
      selectedIndex: step,

      id: 'workers',
    })
    stepIndex += 1
  }

  const stepId = steps[step]?.id

  return {
    steps,
    stepId,
    step: steps[step],

    variables: {
      hasShiftVariable,
      hasMovementVariable,
      hasVacationVariable,
      hasPaymentVariable,
      hasLiquidationVariable,
      hasPayrollVariable,
      customVariables,
    } as ExportPdfStepVariables,
  }
}
