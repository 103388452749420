import { Div } from '@/components/Div'
import { IInputTag, SmartColumnType } from '@/components/SmartTable/types'
import { Tag } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ROW_HEIGHT } from '.'

interface InputTagProps {
  fieldValueName: string
  callback?: (index: number, id: string, name: string, data: any) => void
  rowIndex: number
  column: SmartColumnType
}

export const InputTag = ({
  fieldValueName,
  callback,
  rowIndex,
  column,
}: InputTagProps) => {
  const { control, getValues } = useFormContext()

  const tags: IInputTag[] = useWatch({
    control,
    name: fieldValueName,
    defaultValue: getValues(fieldValueName),
  })

  return (
    <>
      <Div
        css={{
          position: 'relative',
          overflow: 'scroll',

          minWidth: column.width ? column.width : 72,
          maxWidth: column.width ?? 200,
          width: column.width ?? undefined,

          height: ROW_HEIGHT,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
        }}
      >
        {Array.isArray(tags) && tags.length === 1 && (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {tags?.map((tag) => (
              <Tag
                key={tag.id}
                variant={tag.variant || 'neutral'}
                onClick={() => {
                  callback?.(rowIndex, tag.id, tag.name, tag.data)
                }}
                css={{
                  fontSize: '$xxs',
                  padding: '$1',
                  cursor: callback ? 'pointer' : 'undefined',

                  '&:not(:last-child)': {
                    marginRight: '$1',
                  },
                }}
              >
                {tag.name}
              </Tag>
            ))}
          </Div>
        )}
        {Array.isArray(tags) &&
          tags?.length > 1 &&
          tags?.map((tag) => (
            <Tag
              key={tag.id}
              variant={tag.variant || 'neutral'}
              onClick={() => {
                callback?.(rowIndex, tag.id, tag.name, tag.data)
              }}
              css={{
                fontSize: '$xxs',
                padding: '$1',
                cursor: callback ? 'pointer' : 'undefined',

                '&:not(:last-child)': {
                  marginRight: '$1',
                },
              }}
            >
              {tag.name}
            </Tag>
          ))}
      </Div>
    </>
  )
}
