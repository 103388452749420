import { Div } from '@/components'
import { IPaymentConfigGroup } from '@/libs/react-query/types/company'
import { formatMinutes } from '@/utils/date'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Tag, Text } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

export const usePaymentGroupsColumns = () => {
  const columns = React.useMemo<ColumnDef<IPaymentConfigGroup>[]>(() => {
    const retunedColumns: ColumnDef<IPaymentConfigGroup>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text css={{ whiteSpace: 'nowrap' }}>{row.original.name}</Text>
          </Div>
        ),
      },
      {
        id: 'is_daily_payment',
        header: 'Jornalero',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={row.original.is_daily_payment ? 'positive' : 'negative'}
            >
              {row.original.is_daily_payment ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'is_subscribed_ips',
        header: 'IPS',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={row.original.is_subscribed_ips ? 'positive' : 'negative'}
            >
              {row.original.is_subscribed_ips ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'should_pay_as_natural_days',
        header: 'Pagos por dias naturales',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={
                row.original.should_pay_as_natural_days
                  ? 'positive'
                  : 'negative'
              }
            >
              {row.original.should_pay_as_natural_days ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'number_hours_per_day',
        header: 'Horas por Jornada',
        accessorFn: (row) => formatMinutes(row.number_hours_per_day * 60),
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
