import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { ICreateManyPunchesFromDocuments } from './types'

const handleCreateManyFromDocument = async (
  props: ICreateManyPunchesFromDocuments,
) => {
  const response = await apiV2.post<any>(
    '/punches/create-multiple-punches-from-document',
    props,
  )

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleCreateManyPunchesFromDocument = () => {
  const mutation = useCustomMutation(
    ['create-many-punches-from-document'],
    handleCreateManyFromDocument,
  )

  return mutation
}
