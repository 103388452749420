import * as DropdownMenuComponent from '@radix-ui/react-dropdown-menu'
import { Div } from '../Div'
import { Text, keyframes, styled } from '@punto-ui/react'
import React, { useState } from 'react'
import { Avatar } from '../Avatar'

interface IVisibilityWorker {
  name: string
  id: string
  image_url: string
}

interface IWorkersVisibility {
  users: IVisibilityWorker[]
}

interface WorkerVisibilityContainerProps {
  users: IVisibilityWorker[]
  children: React.ReactNode
}

const UserInfosDropdown = ({ users }: { users: IVisibilityWorker[] }) => {
  return (
    <DropdownMenuComponent.Portal>
      <DropdownMenuComponent.Content sideOffset={12}>
        <PopoverContent>
          {users.map((user) => (
            <Div
              key={user.id}
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: users.length > 1 ? '$4' : 0,
              }}
            >
              <Avatar
                alt={user.name}
                src={user.image_url || ''}
                height={32}
                width={32}
              />
              <Text css={{ marginLeft: '$2' }}>{user.name}</Text>
            </Div>
          ))}
        </PopoverContent>
      </DropdownMenuComponent.Content>
    </DropdownMenuComponent.Portal>
  )
}

const AvatarContainer = ({
  children,
  users,
}: WorkerVisibilityContainerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Div>
      <DropdownMenuComponent.Root
        open={isOpen}
        onOpenChange={(b) => setIsOpen(b)}
        modal
      >
        <Div>
          <DropdownMenuComponent.Trigger asChild>
            <AvatarContainerDiv>
              {children}
              <UserInfosDropdown users={users} />
            </AvatarContainerDiv>
          </DropdownMenuComponent.Trigger>
        </Div>
      </DropdownMenuComponent.Root>
    </Div>
  )
}

const AvatarContainerDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const WorkersVisibility = ({ users }: IWorkersVisibility) => {
  const hasOne = users.length === 1
  const hasTwo = users.length === 2
  const hasNone = users.length === 0

  if (hasNone) {
    return (
      <Div>
        <Text variant="caption">Nenhum colaborador</Text>
      </Div>
    )
  }

  if (hasOne || hasTwo) {
    return (
      <AvatarContainer users={users}>
        {users.map((user, index) => (
          <Div key={user.id} css={{ marginLeft: index ? -8 : 0 }}>
            <Avatar
              src={user.image_url || ''}
              height={32}
              width={32}
              alt={user.name}
              unoptimized={true}
            />
          </Div>
        ))}
      </AvatarContainer>
    )
  }

  return (
    <AvatarContainer users={users}>
      <Div css={{ height: 32, width: 32 }}>
        <Avatar
          src={users[0].image_url || ''}
          height={32}
          width={32}
          alt={users[0].name}
          unoptimized={true}
          css={{ border: '2px solid black' }}
        />
      </Div>
      <Div css={{ marginLeft: -8, height: 32, width: 32 }}>
        <Avatar
          src={users[1].image_url || ''}
          height={32}
          width={32}
          alt={users[1].name}
          unoptimized={true}
          css={{ border: '2px solid black' }}
        />
      </Div>
      <Div
        css={{
          height: 32,
          width: 32,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          background: '$interface_dark_deep',
          borderRadius: '$full',
          marginLeft: -8,
        }}
      >
        <Text css={{ color: '$interface_light_deep' }} variant={'caption'}>
          +{users.length - 2}
        </Text>
      </Div>
    </AvatarContainer>
  )
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-12px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(12px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const PopoverContent = styled('div', {
  borderRadius: '$lg',
  padding: '$5',
  maxHeight: 300,
  overflowY: 'auto',
  backgroundColor: '$interface_light_pure',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
})
