import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'

const handleLiquidateUser = async (userId: string) => {
  try {
    await apiV2.post<void>(`/company/liquidate-user/${userId}`)
    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}

export const useHandleLiquidateUser = () => {
  const mutation = useCustomMutation(
    ['useHandleLiquidateUser'],
    handleLiquidateUser,
  )

  return mutation
}
