import { styled, Text } from '@punto-ui/react'

export const WorkersListContainer = styled('div', {
  marginTop: '$2',
  // border: 'solid 1px $interface_light_down',
  borderRadius: 8,
})

export const TableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
})

export const DomTable = styled('table', {
  flex: 1,
  borderCollapse: 'collapse',

  '& tbody': {
    '& tr:last-child': {
      '& td': {
        borderBottomWidth: '0px',
      },
    },
    '& tr:first-child': {
      '& td': {
        borderTopWidth: '0px',
      },
    },
  },

  '& tr': {
    '&:nth-child(even)': {
      background: '$interface_light_up',
    },
  },
})

export const TableHead = styled('th', {})

export const TableData = styled('td', {
  // borderBottom: '1px solid',
  // borderBottomColor: '$interface_dark_up',
})

export const StyledContentText = styled(Text, {
  color: '$interface_dark_deep',
  fontSize: '$sm',
  textAlign: 'left',
})

export const StyledHeadingText = styled(Text, {
  color: '$interface_dark_deep',
  fontWeight: 'bold',
  fontSize: '$sm',
  textAlign: 'left',
  padding: '$3 $2',
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  padding: '$6',
})

export const RowContainer = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '$2',

  '&:last-child': {
    borderBottom: 'none',
  },
  '&:first-child': {
    borderBottom: 'none',
  },
})

export const TableInput = styled('input', {
  all: 'unset',
  fontFamily: '$default',
  maxWidth: 75,

  borderRadius: 4,
  padding: 4,

  '&:focus': {
    background: '$interface_light_up',
  },
})
