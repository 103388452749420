import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { ControlledInput, Div } from '@/components'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { PdfBuilderPdfContentType, PdfBuilderSchemaType } from '../../types'
import { useFormContext } from 'react-hook-form'
import { SimpleInput } from './ImageContent'
import { Button, styled } from '@punto-ui/react'
import { GrabIcon } from '@/assets/icons/GrabIcon'
import { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'

export const LayoutContentMenu = ({
  children,
  index,
  content,
}: {
  children: React.ReactNode
  index: number
  content: PdfBuilderPdfContentType
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const [isDraggingMe, setIsDraggingMe] = useState(false)

  const [isOverTop, setIsOverTop] = useState(false)
  const [isDraggingLayout, setIsDraggingLayout] = useState(false)
  const [isDraggingNewLayout, setIsDraggingNewLayout] = useState(false)

  const methods = useFormContext<PdfBuilderSchemaType>()

  const shouldShowMenu =
    (isMouseOver || isFocused) && !isDraggingLayout && !isDraggingMe

  const topDropAreaRef = React.useRef<HTMLDivElement>(null)
  const layoutDragRef = React.useRef<HTMLDivElement>(null)
  const layoutDropRef = React.useRef<HTMLDivElement>(null)
  const dragIconRef = React.useRef<HTMLDivElement>(null)

  const handleAddDataContent = () => {
    const contentData = methods.getValues(`pdf.content.${index}.data`)
    const totalColumns = contentData.length

    const content = [...contentData]

    const emptyContent = {
      id: (Math.random() * 10000).toString(),
      type: 'empty' as const,
      width: Math.round(50),
    }

    content.push(emptyContent)

    methods.setValue(`pdf.content.${index}.data`, content)
    methods.setValue(`pdf.content.${index}.numberOfColumns`, totalColumns + 1)
  }

  const handleRemoveDataContent = (dataIndex: number) => {
    const contentData = methods.getValues(`pdf.content.${index}.data`)
    const newContent = [...contentData]

    newContent.splice(dataIndex, 1)

    methods.setValue(`pdf.content.${index}.data`, newContent)
    methods.setValue(
      `pdf.content.${index}.numberOfColumns`,
      contentData.length - 1,
    )
  }

  const swapContentToAbove = () => {
    if (index === 0) return

    const content = methods.getValues().pdf.content
    const newContent = [...content]

    const temp = newContent[index]
    newContent[index] = newContent[index - 1]
    newContent[index - 1] = temp

    methods.setValue('pdf.content', newContent)
  }

  const swapContentToBelow = () => {
    const content = methods.getValues().pdf.content
    const newContent = [...content]

    if (index === newContent.length - 1) return

    const temp = newContent[index]
    newContent[index] = newContent[index + 1]
    newContent[index + 1] = temp

    methods.setValue('pdf.content', newContent)
  }

  const changeContentIndex = (newIndex: number, oldIndex: number) => {
    console.log(newIndex)
    const content = methods.getValues().pdf.content

    const newContent = [...content]

    // remove element from "index"
    const [element] = newContent.splice(oldIndex, 1)
    // insert content in new index
    newContent.splice(newIndex, 0, element)

    methods.setValue('pdf.content', newContent)
  }

  useEffect(() => {
    const monitorForNewLayout = monitorForElements({
      canMonitor: ({ source }) =>
        source.data.type === 'pdf-builder-layout-new-option',
      onDrag: ({ source }) => {
        if (source.data.type !== 'pdf-builder-layout-new-option') {
          return
        }
        setIsDraggingNewLayout(true)
      },
      onDrop: ({ source }) => {
        if (source.data.type !== 'pdf-builder-layout-new-option') {
          return
        }
        setIsDraggingNewLayout(false)
      },
    })

    const monitorForLayout = monitorForElements({
      canMonitor: ({ source }) =>
        source.data.type === 'pdf-builder-layout-option',
      onDrop: ({ source }) => {
        setIsDraggingLayout(false)
      },
    })

    const layoutDragFn =
      layoutDragRef.current && dragIconRef.current
        ? draggable({
            onGenerateDragPreview: () => {
              setIsDraggingLayout(true)
              setIsDraggingMe(true)
            },
            element: layoutDragRef.current,
            dragHandle: dragIconRef.current,
            canDrag: () => true,
            onDrag: () => {
              setIsDraggingLayout(true)
              setIsDraggingMe(true)
            },
            onDrop: () => {
              setIsDraggingLayout(false)
              setIsDraggingMe(false)
            },
            getInitialData: () => {
              return {
                index,
                type: 'pdf-builder-layout-option',
              }
            },
          })
        : undefined

    const monitorLayoutDrag = monitorForElements({
      canMonitor: ({ source }) =>
        source.data.type === 'pdf-builder-layout-option',
      onDrag: ({ source }) => {
        setIsDraggingLayout(true)
      },
      onDrop: ({ source }) => {
        setIsDraggingLayout(false)
      },
    })

    const dropTargetLayout = layoutDropRef.current
      ? dropTargetForElements({
          element: layoutDropRef.current,
          canDrop: ({ source }) =>
            source.data.type === 'pdf-builder-layout-option',
          onDrop: ({ source }) => {
            setIsOverTop(false)
            const oldIndex = source.data.index as number
            changeContentIndex(index, oldIndex)
          },
          onDragEnter: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-option') {
              return
            }

            setIsOverTop(true)
          },
          onDragLeave: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-option') {
              return
            }

            setIsOverTop(false)
          },
        })
      : undefined

    const dropAreaForNewElements = topDropAreaRef.current
      ? dropTargetForElements({
          element: topDropAreaRef.current,
          canDrop: ({ source }) =>
            source.data.type === 'pdf-builder-layout-new-option',
          onDrop: ({ source }) => {
            setIsOverTop(false)
            setIsDraggingNewLayout(false)

            const columns = source.data.columns as number

            const content = methods.getValues('pdf.content')
            const emptyContent = {
              id: (Math.random() * 10000).toString(),
              numberOfColumns: columns,
              data: Array.from({ length: columns }).map(() => ({
                id: (Math.random() * 10000).toString(),
                type: 'empty' as const,
                width: Math.round(100 / columns),
              })),
            }

            const newContent = [...content]
            newContent.splice(index, 0, emptyContent)

            methods.setValue('pdf.content', newContent)
          },
          onDragEnter: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-new-option') {
              return
            }

            setIsOverTop(true)
          },
          onDragLeave: ({ source }) => {
            if (source.data.type !== 'pdf-builder-layout-new-option') {
              return
            }

            setIsOverTop(false)
          },
        })
      : undefined

    const functions = [
      layoutDragFn,
      monitorForNewLayout,
      monitorForLayout,
      dropTargetLayout,
      dropAreaForNewElements,
      monitorLayoutDrag,
    ]

    const functionsToClean = functions.filter((c) => !!c) as CleanupFn[]

    return combine(...functionsToClean)
  }, [
    isDraggingMe,
    isDraggingNewLayout,
    isOverTop,
    index,
    isDraggingLayout,
    isMouseOver,
  ])

  return (
    <Div
      ref={layoutDragRef}
      css={{
        position: 'relative',
        border: shouldShowMenu
          ? '1px dashed $brand_primary_pure'
          : '1px solid $interface_light_down',
        boxSizing: 'border-box',

        opacity: isDraggingMe ? 0.5 : 1,
      }}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Div>{children}</Div>

      {isMouseOver && (
        <Div
          ref={dragIconRef}
          css={{
            zIndex: 3,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: -32,
            width: 32,

            cursor: 'grab',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            svg: {
              color: '$interface_light_deep',
              height: 20,
              width: 20,
            },
          }}
        >
          <GrabIcon />
        </Div>
      )}

      {isOverTop && (
        <Div
          css={{
            position: 'absolute',
            top: -4,
            left: 0,
            right: 0,

            height: 4,
            background: '$brand_primary_deep',
          }}
        />
      )}

      {isDraggingNewLayout && (
        <>
          <Div
            ref={topDropAreaRef}
            css={{
              position: 'absolute',
              zIndex: 3,
              top: -5,
              bottom: '50%',
              left: 0,
              right: 0,
              // background: isOverTop ? '$brand_primary_deep' : undefined,
            }}
          />
        </>
      )}

      {isDraggingLayout && !isDraggingMe && (
        <>
          <Div
            ref={layoutDropRef}
            css={{
              position: 'absolute',
              zIndex: 3,
              top: 0,
              bottom: 0,
              left: -48,
              right: 0,
              // background: isOverTop ? '$brand_primary_deep' : undefined,
            }}
          />
        </>
      )}
      {shouldShowMenu && (
        <Div
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          css={{
            position: 'absolute',
            zIndex: 3,
            top: 'calc(-38px)',
            height: 38,
            // left: 0,
            // right: 0,
            opacity: shouldShowMenu ? 1 : 0,
            pointerEvents: shouldShowMenu ? 'all' : 'none',
            transition: 'opacity 0.2s',

            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Div
            css={{
              height: 34,
              boxShadow: '0px 0px 24px 0px #343A4029',
              background: '$interface_light_pure',

              // padding: '$4',

              borderRadius: '$md',

              paddingLeft: '$2',
              paddingRight: '$2',
              display: 'flex',
              gap: '$3',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            {index !== 0 && (
              <Button
                icon={<ArrowUpIcon />}
                variant="tertiary"
                onClick={swapContentToAbove}
              />
            )}
            <Button
              icon={<ArrowDownIcon />}
              variant="tertiary"
              onClick={swapContentToBelow}
            />
            <ItemDivider />
            <Button
              icon={<PlusIcon />}
              variant="tertiary"
              onClick={handleAddDataContent}
            />
            <Button
              icon={<MinusIcon />}
              variant="tertiary"
              onClick={() => {
                handleRemoveDataContent(content.numberOfColumns - 1)
              }}
            />
            <ItemDivider />
            {Array.from({ length: content?.numberOfColumns || 0 }).map(
              (_, columnIndex) => {
                return (
                  <Div key={columnIndex}>
                    <ControlledInput
                      InputComponent={SimpleInput}
                      name={`pdf.content.${index}.data.${columnIndex}.width`}
                      inputType="only-numbers"
                      css={{
                        borderColor: isFocused
                          ? '$brand_primary_deep'
                          : undefined,
                      }}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                    />
                  </Div>
                )
              },
            )}
            <Div
              css={{
                '> svg': {
                  color: '$status_danger_pure',
                  height: 16,
                  width: 16,
                },

                cursor: 'pointer',
              }}
              onClick={() => {
                const content = methods.getValues().pdf.content
                const newContent = [...content]
                newContent.splice(index, 1)
                methods.setValue('pdf.content', newContent)
              }}
            >
              <TrashIcon />
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  )
}

const ItemDivider = styled('div', {
  width: 1,
  background: '$interface_light_down',
  height: '70%',
})
