import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'

export const useLiquidationConceptDisabled = ({
  conceptKey,
}: {
  conceptKey: string
}) => {
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [conceptsStatus] = useWatch({
    control,
    name: ['conceptsStatus'],
  })

  const conceptStatus = conceptsStatus[conceptKey]

  const isConceptDeactivated =
    conceptStatus && conceptStatus.is_active !== null
      ? !conceptStatus.is_active
      : null

  return {
    concept: conceptStatus || {
      name: '',
      ips: false,
      is_active: false,
      entity_id: '',
      is_debit: false,
      type: '',
    },
    disabled: isConceptDeactivated,
  }
}
