import { create } from 'zustand'

export type LayoutTypes =
  | 'payroll'
  | 'punches'
  | 'worker'
  | 'shifts'
  | 'permissions'
  | 'reports'
  | 'vacations'
  | 'requests'
  | 'reportsV2'
  | 'configurations'

interface LayoutStoreTypes {
  setLayoutTab(tab: LayoutTypes, index: number): void
  layoutScreens: LayoutTypes[]
  closeLayoutTab(index: number): void
  layoutWidth: number
  setLayoutWidth(width: number): void
}

const INITIAL_STATE = {
  layoutScreens: [],
  setLayoutTab: () => null,
  closeLayoutTab: () => null,
  layoutWidth: 0,
  setLayoutWidth: () => null,
}

export const useLayoutStore = create<LayoutStoreTypes>((set, get) => {
  return {
    ...INITIAL_STATE,
    setLayoutTab: (tab: LayoutTypes, index: number) => {
      set((state) => {
        const newLayoutScreens = [...state.layoutScreens]
        newLayoutScreens[index] = tab
        return {
          layoutScreens: newLayoutScreens,
        }
      })
    },
    closeLayoutTab: (index: number) => {
      set((state) => {
        const newLayoutScreens = state.layoutScreens.filter(
          (_, i) => i !== index,
        )
        return {
          layoutScreens: newLayoutScreens,
        }
      })
    },
    layoutWidth: 0,
    setLayoutWidth: (width: number) => {
      set({ layoutWidth: width })
    },
  }
})
