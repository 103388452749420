import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
dayjs.extend(utc)

const handleClosePayroll = async (id: string) => {
  await apiV2.post<void>('/payroll/close-payroll', {
    id,
  })

  queryClient.invalidateQueries()
}

export const useHandleClosePayroll = () => {
  const mutation = useCustomMutation(
    ['useHandleClosePayroll'],
    handleClosePayroll,
  )

  return mutation
}
