import {
  SmartTableAvatarItemValue,
  SmartTableFormItemValue,
  SmartTableFormTagValue,
} from '@/components/SmartTable/types'
import { z } from 'zod'

export const VacationStatusContent = z.object({
  id: z.string(),
  original: z.any(),
  // Table props
  checked: SmartTableFormItemValue,
  expandable: SmartTableFormItemValue,
  avatar: SmartTableAvatarItemValue,

  status: SmartTableFormTagValue,
  name: SmartTableFormItemValue,
  surname: SmartTableFormItemValue,
  document: SmartTableFormItemValue,
  hiringDate: SmartTableFormItemValue,
  antiquity: SmartTableFormItemValue,
  daysCaused: SmartTableFormItemValue,
  daysTaken: SmartTableFormItemValue,
  daysRemaining: SmartTableFormItemValue,
  daysOwed: SmartTableFormItemValue,
  proportionalDays: SmartTableFormItemValue,

  yearsDetailed: z.array(
    z.object({
      id: z.string(),

      // Table props
      name: SmartTableFormItemValue,
      status: SmartTableFormTagValue,
      surname: SmartTableFormItemValue,
      antiquity: SmartTableFormItemValue,
      daysCaused: SmartTableFormItemValue,
      daysTaken: SmartTableFormItemValue,
      daysRemaining: SmartTableFormItemValue,
      daysOwed: SmartTableFormItemValue,
      proportionalDays: SmartTableFormItemValue,
    }),
  ),
})

export const VacationStatusTable = z.object({
  data: z.array(VacationStatusContent),
  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
  filters: z.object({
    name: z.string().optional(),
    cellsIds: z.array(z.string()).optional(),
    date: z.date().array(),

    antiquity: z.string().array(),
    periods: z.number().array(),
  }),
})

export type VacationStatusTableType = z.infer<typeof VacationStatusTable>
export type VacationStatusContentType = z.infer<typeof VacationStatusContent>
