import React, { useState } from 'react'

interface SideBarDataType {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SideBarContext = React.createContext({} as SideBarDataType)

interface SideBarProps {
  children: React.ReactNode
}

export const SideBarProvider = ({ children }: SideBarProps) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <SideBarContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </SideBarContext.Provider>
  )
}
