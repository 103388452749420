import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { ICreateUserDocument } from '@/libs/react-query/types/documents'
// import { v4 as uuid } from 'uuid'

const handleCreateUserDocument = async (data: ICreateUserDocument) => {
  try {
    const formData = new FormData()

    formData.append('name', data.name)
    formData.append('folderId', data.folderId)
    formData.append('userId', data.userId)

    data.files.forEach((file) => {
      formData.append(`file`, file, file.name)
    })

    await apiV2.post<void>('/documents/create-user-document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          )
          data.onProgress?.(percentCompleted)
        }
      },
    })

    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}

export const useHandleCreateUserDocument = () => {
  const mutation = useCustomMutation(
    ['useHandleCreateUserDocument'],
    handleCreateUserDocument,
  )

  return mutation
}
