import { z } from 'zod'

export const batchEditingSchema = z.object({
  usersIds: z.string().array(),
  month: z.string(),
  payrollId: z.string(),
  value: z.number(),
  isPayrollSelection: z.boolean(),
})

export type BatchEditingSchema = z.infer<typeof batchEditingSchema>

export const DEFAULT_BATCH_EDITING = {
  usersIds: [],
  month: '',
  payrollId: '',
  value: 0,
  isPayrollSelection: true,
}
