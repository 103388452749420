import { ComponentProps } from 'react'
import {
  RadioContainer,
  RadioIndicator,
  RadioItem,
  RadioItemContainer,
} from './styles'
import { Text, TextProps } from '@punto-ui/react'
import { Div } from '../Div'

export interface RadioProps extends ComponentProps<typeof RadioContainer> {
  items: string[]
  direction?: 'vertical' | 'horizontal'
  variant?: TextProps['variant']
}

export function Radio({
  items,
  direction = 'horizontal',
  variant,
  ...props
}: RadioProps) {
  return (
    <RadioContainer {...props} direction={direction}>
      {items.map((item) => (
        <RadioItemContainer key={item}>
          <Div>
            <RadioItem value={item}>
              <RadioIndicator />
            </RadioItem>
          </Div>
          <Text variant={variant} css={{ color: '$interface_dark_deep' }}>
            {item}
          </Text>
        </RadioItemContainer>
      ))}
    </RadioContainer>
  )
}

Radio.displayName = 'Radio'
