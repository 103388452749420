import { useMemo } from 'react'
import { InactiveWorkerField } from '.'
import { useIsInactivateWorkerFormDisabled } from '../hooks'

export const useFamiliarBonus = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()
  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = [
      {
        _type: 'input',
        name: `data.familiarBonus.children`,
        disabled: isLoadingLiquidation,
        inputType: 'only-numbers-or-empty',
        label: 'Hijos menores de 18 años',
      },
      {
        _type: 'input',
        disabled: isLoadingLiquidation,
        inputType: 'money-guarani',
        label: 'Valor en haber',
        name: `data.familiarBonus.value`,
      },
    ]
    return lastSalaryForm
  }, [isLoadingLiquidation])

  return {
    form,
  }
}
