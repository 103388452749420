import { IContentOption } from '../DataForm'

export const shiftsPdfData: IContentOption[] = [
  {
    label: 'Nombre',
    value: 'turno.nombre',
  },
  {
    label: 'Entrada (XX:XX)',
    value: 'turno.entrada',
  },
  {
    label: 'Intervalos (XX:XX - XX:XX)',
    value: 'turno.intervalo',
  },
  {
    label: 'Salida (XX:XX)',
    value: 'turno.salida',
  },
  {
    label: 'Cantidad de Horas Totales',
    value: 'turno.horas',
  },
  {
    label: 'Cantidad de Horas Diurnas',
    value: 'turno.horas_diurnas',
  },
  {
    label: 'Cantidad de Horas Nocturas',
    value: 'turno.horas_nocturnas',
  },
  {
    label: 'Cantidad de Horas Intervalos',
    value: 'turno.horas_intervalo',
  },
]
