import { IWorkerUser } from '@/libs/react-query/types'
import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'

const handleGetLoggedUsers = async () => {
  const response = await apiV2.post<
    Array<
      IWorkerUser & {
        company_id: string
        company_name: string
        last_action_date: string
      }
    >
  >('/admin/get-logged-users')

  return response.data
}

export const useLoggedUsers = () => {
  const query = useQuery(['useLoggedUsers'], handleGetLoggedUsers, {
    refetchInterval: 10000, // 10 seconds
  })

  return query
}
