import { ICompanyPlace } from '@/libs/react-query/types/company'
import { Box, Checkbox } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

export const useCompanyPlaceColumns = () => {
  const columns = React.useMemo<ColumnDef<ICompanyPlace>[]>(() => {
    const retunedColumns: ColumnDef<ICompanyPlace>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
      },
      {
        id: 'description',
        header: 'Descripción',
        accessorFn: (row) => row.description,
      },
      {
        id: 'punches_radius',
        header: 'Raio de marcación',
        accessorFn: (row) => `${row.punches_radius} m`,
      },
      {
        id: 'latitude',
        header: 'Latitude',
        accessorFn: (row) => row.latitude,
      },
      {
        id: 'longitude',
        header: 'Longitude',
        accessorFn: (row) => row.longitude,
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
