import { keyframes, Text } from '@punto-ui/react'
import { styled } from '../../styles'

export const DropdownContainer = styled('div', {
  position: 'relative',
})

export const DropdownItem = styled('li', {
  all: 'unset',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$md',

  '> a': {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '$2',
    cursor: 'pointer',
    width: '100%',
    fontFamily: '$default',
    fontSize: '$sm',
    fontWeight: 'regular',
    color: '$interface_dark_deep',
  },

  variants: {
    isSelected: {
      true: {
        '& a': {
          [`& ${Text}`]: {
            color: '$brand_primary_pure',
          },
        },
        backgroundColor: '$interface_light_up',
      },
    },
  },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(5%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translateY(0%) scale(1)' },
})

export const DropdownOptionsContainer = styled('ul', {
  all: 'unset',
  position: 'absolute',
  top: 'calc(100% + 4px)',
  left: 0,
  right: 0,
  zIndex: 1,

  maxHeight: 350,
  overflowY: 'auto',

  backgroundColor: '$interface_light_pure',
  borderRadius: '$md',

  display: 'none',
  flexDirection: 'column',
  alignItems: 'stretch',

  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
  border: 'solid 1px $interface_light_down',
  padding: '$2',
  opacity: 0,
  transition: 'opacity 0.5s ease-in-out',

  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  variants: {
    isOpen: {
      true: {
        display: 'flex',
        opacity: 1,
      },
      false: {
        display: 'none',
        opacity: 0,

        '&:hover': {
          opacity: 1,
          display: 'flex',
        },
      },
    },
  },

  '& button': {
    all: 'unset',
    padding: '$2',
    cursor: 'pointer',
    flex: 1,
    borderRadius: '$xs',

    [`& ${Text}`]: {
      color: '$interface_dark_down',
    },

    '&:hover': {
      [`& ${Text}`]: {
        color: '$brand_primary_pure',
      },

      backgroundColor: '$interface_light_up',
    },
  },
})

export const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  background: 'transparent',
})

export const TextInputContainer = styled('div', {
  backgroundColor: '$interface_light_pure',
  padding: '$4 $2',
  borderRadius: '$md',
  border: '2px solid $interface_dark_up',
  boxSizing: 'border-box',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',

  '& svg': {
    height: '$5',
    width: '$5',
    strokeWidth: 2,
    color: '$interface_dark_up',
    transition: 'transform 100ms ease-in-out',
  },

  '&:has(input:focus)': {
    borderColor: '$brand_primary_pure',

    '& svg': {
      color: '$brand_primary_pure',
      transform: 'rotate(180deg)',
    },
  },

  '&:has(input:disabled)': {
    opacity: 0.5,
    background: '$interface_light_down',
    cursor: 'not-allowed',

    '& svg': {
      color: '$interface_dark_up',
    },
  },
})

export const Input = styled('input', {
  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  border: 0,
  width: '100%',
  color: '$interface_dark_deep',
  minWidth: 200,

  '&:focus': {
    outline: 0,
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '&:placeholder': {
    color: '$interface_dark_up',
  },
})
