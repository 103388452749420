import { useMemo } from 'react'
import { InactivateWorkerSchema } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'

export const usePaymentsAndDiscountsConcepts = (concepts?: string[]) => {
  const { control } = useFormContext<InactivateWorkerSchema>()

  const [paymentConcepts, discountConcepts] = useWatch({
    control,
    name: ['data.paymentConcepts', 'data.discountConcepts'],
  })

  const paymentConceptsWithOldIndex = useMemo(() => {
    const result: Array<{
      index: number
      concept: InactivateWorkerSchema['data']['paymentConcepts'][0]
      type: string
    }> = paymentConcepts.map((paymentConcept, index) => ({
      index,
      concept: paymentConcept,
      type: paymentConcept.type,
    }))

    return result
  }, [paymentConcepts])

  const discountConceptsWithOldIndex = useMemo(() => {
    const result: Array<{
      index: number
      concept: InactivateWorkerSchema['data']['discountConcepts'][0]
      type: string
    }> = discountConcepts.map((paymentConcept, index) => ({
      index,
      concept: paymentConcept,
      type: paymentConcept.type,
    }))

    return result
  }, [discountConcepts])

  return {
    discountConcepts: !concepts?.length
      ? discountConceptsWithOldIndex
      : discountConceptsWithOldIndex.filter((c) =>
          concepts.includes(c.concept.type),
        ),
    paymentConcepts: !concepts?.length
      ? paymentConceptsWithOldIndex
      : paymentConceptsWithOldIndex.filter((c) =>
          concepts.includes(c.concept.type),
        ),
  }
}
