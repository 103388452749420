import { Div } from '@/components/Div'
import { ControlledCheckbox } from '@/components/Forms'
import {
  SmartColumnType,
  SmartTableFormItemValueType,
} from '@/components/SmartTable/types'
import { ROW_HEIGHT } from '.'
import { useFormContext, useWatch } from 'react-hook-form'

interface InputCheckboxProps {
  fieldValueName: string
  fieldName: string
  column: SmartColumnType
  index: number
}

export const InputCheckbox = ({
  fieldValueName,
  fieldName,
  column,
  index,
}: InputCheckboxProps) => {
  const { control } = useFormContext()

  const field: SmartTableFormItemValueType = useWatch({
    control,
    name: fieldName,
  })

  return (
    <>
      <Div
        css={{
          position: 'relative',
          padding: '$2',
          height: ROW_HEIGHT,
        }}
      >
        {field && (
          <ControlledCheckbox
            onValueChange={(b) => column.onCheckedChange?.(index, b)}
            disabled={!!column.disabled || field?.metadata?.disabled}
            name={fieldValueName}
          />
        )}
      </Div>
    </>
  )
}
