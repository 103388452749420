import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { usePaymentGroupsColumns } from './table'
import { ISalaryGroup } from '@/libs/react-query/types/company'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { useCan } from '@/hooks'
import { CreateSalaryGroupDialog } from './components/CreateSalaryGroupDialog'
import { getUtcDateFromString } from '@/utils/date'

interface SalaryGroupsDrawerState {
  isOpen: boolean
  selectedSalaryGroup: ISalaryGroup | null
}

export const SalaryGroupsConfiguration = () => {
  const canCreateSalary = useCan(['configurations.salary'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { reset, setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] = React.useState<SalaryGroupsDrawerState>(
    {
      isOpen: false,
      selectedSalaryGroup: null,
    },
  )

  const columns = usePaymentGroupsColumns()

  const handleCreateSalaryGroup = () => {
    reset()
    setValue('salaryGroup', {
      description: '',
      name: '',
      type: '',
      history: [],
    })

    setDrawerState({
      isOpen: true,
      selectedSalaryGroup: null,
    })
  }

  const handleSelectSalaryGroup = (row: ISalaryGroup) => {
    reset()
    setValue('salaryGroup', {
      id: row.id,
      description: row.description,
      name: row.name,
      type: row.type,
      history: row.values.map((value) => ({
        id: value.id,
        start_date: value.start_date
          ? [getUtcDateFromString(value.start_date)]
          : [null],
        end_date: value.end_date ? getUtcDateFromString(value.end_date) : null,
        is_minimum_wage: value.is_minimum_wage,
        value: value.value,
        concepts: value.concepts.map((concept) => ({
          id: concept.id,
          name: concept.name,
          value: concept.value,
          description: concept.description,
          ips: concept.ips,
        })),
      })),
    })

    setDrawerState({
      isOpen: true,
      selectedSalaryGroup: row,
    })
  }

  const table = useReactTable({
    data: company?.salaryGroups ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del grupo" placeholder="Nombre del grupo" />
        {canCreateSalary && (
          <Button onClick={() => handleCreateSalaryGroup()}>
            Crear grupo de salario
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<ISalaryGroup>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectSalaryGroup}
        />
      </Div>
      <CreateSalaryGroupDialog
        isCreation={drawerState.selectedSalaryGroup === null}
        isOpen={drawerState.isOpen}
        selectedSalaryGroup={drawerState.selectedSalaryGroup}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedSalaryGroup: null,
          })
        }
      />
    </Div>
  )
}
