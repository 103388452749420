import {
  useAddressData,
  useBankForm,
  useChildrenData,
  useContactsData,
  useMarcacionAsistenciaForm,
  useOptionalDataForm,
  usePaymentConfigForm,
  useRequiredCompanyData,
  useRequiredDataForm,
  useVacationConfigForm,
} from '../forms'
import { useCallback } from 'react'
import { useFaceIdForm } from '../forms/useFaceIdForm'

export const useGetFormFields = () => {
  const { data: optionalDataForm } = useOptionalDataForm()
  const { data: requiredDataForm } = useRequiredDataForm()
  const { data: contactsData } = useContactsData(0)
  const { data: marcacionAsistenciaForm } = useMarcacionAsistenciaForm()
  const { data: addressForm } = useAddressData()
  const { data: paymentConfigForm } = usePaymentConfigForm()
  const { data: vacationConfigForm } = useVacationConfigForm()
  const { data: childrenData } = useChildrenData(0)
  const { data: companyDataForm } = useRequiredCompanyData()
  const { data: bankForm } = useBankForm()
  const { data: faceIdForm } = useFaceIdForm()

  const getFields = useCallback(
    (formStep?: number) => {
      if (formStep === undefined) {
        return [
          ...optionalDataForm,
          ...requiredDataForm,
          ...addressForm,
          ...contactsData,
          ...childrenData,
          ...bankForm,
          ...marcacionAsistenciaForm,
          ...faceIdForm,
          ...paymentConfigForm,
          ...vacationConfigForm,
          ...companyDataForm,
        ]
      }

      if (formStep === 0) {
        return [
          ...optionalDataForm,
          ...requiredDataForm,
          ...addressForm,
          ...contactsData,
          ...childrenData,
          ...bankForm,
          ...faceIdForm,
        ]
      }

      if (formStep === 1) {
        return [...marcacionAsistenciaForm]
      }

      if (formStep === 2) {
        return [...paymentConfigForm, ...companyDataForm, ...vacationConfigForm]
      }

      // if (formStep === 3) {
      //   return [...salaryConfigForm]
      // }

      return []
    },
    [
      addressForm,
      bankForm,
      childrenData,
      companyDataForm,
      contactsData,
      marcacionAsistenciaForm,
      optionalDataForm,
      paymentConfigForm,
      vacationConfigForm,
      requiredDataForm,
      faceIdForm,
    ],
  )

  return {
    data: getFields,
  }
}
