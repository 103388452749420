import React from 'react'
import { SideBarContext } from '../SideBarContext'

export const useSideBarContext = () => {
  const context = React.useContext(SideBarContext)

  if (!context) {
    throw new Error('useSideBarContext must be used within a SideBarProvider')
  }

  return context
}
