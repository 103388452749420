import { createWorkerSchema } from '@/libs/react-query/mutations/workers'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'
import { useAvailableCells, useSmartShifts } from '@/libs/react-query/hooks'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'

export { createWorkerSchema }

export type AddWorkerSimpleData = z.infer<typeof createWorkerSchema>
export type AddWorkersFields = Array<ControlledFieldProps<AddWorkerSimpleData>>

export const useWorkerSimpleFields = () => {
  const { data: shifts } = useSmartShifts()
  const { control } = useFormContext<AddWorkerSimpleData>()
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('workers')),
  )

  const [shiftId, cellId] = useWatch({
    control,
    name: ['shift_id', 'cell_id'],
  })

  const selectedShift = shifts?.find((shift) => shift.shiftId === shiftId)
  const selectedCell = availableCells?.find((cell) => cell.id === cellId)

  const workerSimpleFields: AddWorkersFields = [
    {
      _type: 'dropdown',
      placeholder: 'Turno Diurno',
      label: 'Turno',
      name: 'shift_id',
      options:
        shifts?.map((shift) => ({
          label: shift.shiftName,
          value: shift.shiftId,
        })) || [],
      defaultOption: {
        value: selectedShift?.shiftId || '',
        label: selectedShift?.shiftName || 'Escolher turno',
      },
    },
    {
      _type: 'dropdown',
      placeholder: 'Escolher sucursal',
      label: 'Configuración de Sucursal',
      name: 'cell_id',
      options:
        availableCells?.map((cell) => ({
          label: cell.name,
          value: cell.id,
        })) || [],
      defaultOption: {
        value: selectedCell?.id || '',
        label: selectedCell?.name || 'Escolher sucursal',
      },
    },
    {
      _type: 'input',
      label: 'Nombre',
      name: 'name',
    },
    {
      _type: 'input',
      label: 'Apellido',
      name: 'surname',
    },
    {
      _type: 'input',
      label: 'Cédula de Identidad (CI)',
      name: 'ci',
    },
    {
      _type: 'input',
      label: 'E-mail',
      placeholder: 'email@puntook.com.py',
      name: 'email',
      inputType: 'email',
    },
    {
      _type: 'input',
      // type: 'password',
      label: 'Contraseña',
      placeholder: '********',
      name: 'password',
    },
  ]

  return {
    data: workerSimpleFields,
  }
}
