import { Div } from '@/components/Div'
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FunnelIcon,
  PlusCircleIcon,
  TrashIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import { useState } from 'react'
import { ExportTemplateFilterType, ExportTemplateSchemaType } from './form'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  ControlledBulletPoints,
  ControlledCalendarInput,
  ControlledInput,
} from '@/components/Forms'
import { useDebounce } from '@/hooks'
import { useColumnOptions } from './useColumnOptions'
import { reportBuilderFilterTypes } from '@/pages/reportes-v2/ReportBuilder/constants/report-builder-filter-types'
import { IReportBuilderColumnFilterTypes } from '@/pages/reportes-v2/ReportBuilder/filter-types'
import dayjs from 'dayjs'

export const ExportTemplateFilters = (props: {
  templateId: string
  prefix?: string
  height?: string
  noPadding?: boolean
}) => {
  const { columnsOptions } = useColumnOptions({
    templateId: props.templateId,
  })

  const methods = useFormContext<ExportTemplateSchemaType>()
  const name = `${props.prefix ? `${props.prefix}.` : ''}filters` as 'filters'
  const filters = useWatch({
    control: methods.control,
    name,
  })

  return (
    <Div
      css={{
        minWidth: '40vw',
        height: props.height || 'calc(80vh - 36px - 50px - 50px)',
        overflow: 'scroll',
      }}
    >
      {filters?.map((f, index) => (
        <ExportTemplateFilter
          filter={f}
          index={index}
          key={f.id}
          templateId={props.templateId}
          options={columnsOptions}
          name={name}
        />
      ))}
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          padding: props.noPadding ? 0 : '$4',
        }}
      >
        <Button
          onClick={() => {
            methods.setValue(name, [
              ...filters,
              {
                id: Date.now().toString(),
                column: '',
                condition: '',
                value: '',
                dates: [],
                options: [],
                hasValue: false,
                columnType: '',
              },
            ])
          }}
          variant="secondary"
          icon={<PlusCircleIcon />}
        >
          Agregar filtro
        </Button>
      </Div>
    </Div>
  )
}

interface IExportTemplateFilter {
  index: number
  templateId: string
  name: 'filters'
  filter: ExportTemplateFilterType
  options: Array<{
    label: string
    value: string
  }>
}

const ExportTemplateFilter = ({
  templateId,
  index,
  options,
  name,
  filter,
}: IExportTemplateFilter) => {
  const [isOpen, setIsOpen] = useState(false)
  const methods = useFormContext<ExportTemplateSchemaType>()

  const { columnsOptions } = useColumnOptions({
    templateId,
  })

  const isOpenDebounced = useDebounce(isOpen, 500)

  // const filter = useWatch({
  //   control: methods.control,
  //   name: `filters.${index}`,
  // })

  const isFilterFilled =
    filter.column &&
    filter.condition &&
    (!filter.hasValue || filter.dates[0] || filter.value !== '')

  const filterName =
    options.find((o) => o.value === filter.column)?.label?.split(' - ')?.[0] ||
    ''

  const filterCondition =
    filter?.options.find((o) => o.value === filter.condition)?.label || ''

  const filterValueOrDate =
    filter.columnType === 'date'
      ? filter.dates[0]
        ? dayjs(filter.dates[0]).format('DD/MM/YYYY')
        : ''
      : filter.value

  const filterLabel =
    `${filterName} ${filterCondition.toLowerCase()} ${filterValueOrDate}` ||
    `Filtro ${index + 1}`

  return (
    <Div
      css={{
        borderBottom: '1px solid',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        onClick={() => setIsOpen(!isOpen)}
        css={{
          cursor: 'pointer',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          color: isFilterFilled
            ? '$interface_dark_down'
            : '$status_warning_pure',

          background: isFilterFilled ? 'transparent' : '$status_warning_up',

          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 12,
          paddingBottom: 12,

          marginBottom: '$2',

          svg: {
            width: 20,
            height: 20,
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <Div
            css={{
              svg: {
                fill:
                  isOpen && !isFilterFilled
                    ? '$status_warning_pure'
                    : isOpen
                    ? '$interface_dark_down'
                    : 'transparent',
              },
            }}
          >
            <FunnelIcon />
          </Div>
          <Text
            variant={'description'}
            css={{
              color: isFilterFilled
                ? '$interface_dark_down'
                : '$status_warning_pure',
            }}
          >
            {`${filterLabel}` || `Filtro ${index + 1}`}
          </Text>
          <Div
            css={{
              cursor: 'pointer',
            }}
          >
            <TrashIcon
              onClick={() => {
                const filters = methods.getValues(name)
                methods.setValue(
                  name,
                  filters.filter((_, i) => i !== index),
                )
              }}
            />
          </Div>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              color: isFilterFilled
                ? '$status_success_deep'
                : '$status_warning_pure',
            }}
          >
            {isFilterFilled ? <CheckCircleIcon /> : <WrenchIcon />}
          </Div>
          <Div
            css={{
              marginLeft: '$2',
            }}
          >
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '1fr' : '0fr',
          display: 'grid',
        }}
      >
        <Div
          css={{
            padding: '$4',
            paddingTop: 0,
            paddingBottom: 0,
            overflow:
              !isOpenDebounced || (isOpenDebounced && !isOpen)
                ? 'hidden'
                : undefined,
            display: 'flex',
            alignItems: 'flex-start',
            // justifyContent: 'space-around',
          }}
        >
          <Div
            css={
              {
                // marginBottom: '$4',
                // marginTop: '$4',
              }
            }
          >
            <FilterLabel column={'Columna'} />
            <Div
              css={{
                display: 'flex',
                marginLeft: '$4',
                maxHeight: 250,
                overflow: 'scroll',
              }}
            >
              <ControlledBulletPoints
                variant={'caption'}
                name={`${name}.${index}.column`}
                direction="vertical"
                options={options}
                onValueChange={(value) => {
                  const filters = methods.getValues(name)

                  const columnType = columnsOptions.find(
                    (c) => c.value === value,
                  )?.type as IReportBuilderColumnFilterTypes

                  const filtersOperators =
                    reportBuilderFilterTypes[columnType || 'string']

                  filters[index].options = filtersOperators.operations.map(
                    (f) => ({
                      label: f.label,
                      value: f.value,
                      requiredInput: f.requiredInput,
                    }),
                  )

                  filters[index].columnType = columnType

                  methods.setValue(name, filters)
                }}
                defaultOption={{
                  label: '',
                  value: '',
                }}
              />
            </Div>
          </Div>
          <Div
            css={{
              marginBottom: '$4',
              marginLeft: 'auto',
              marginRight: 'auto',
              minWidth: 200,
            }}
          >
            <FilterLabel column={'Condición'} />
            <Div
              css={{
                display: 'flex',
                marginLeft: '$4',
              }}
            >
              <ControlledBulletPoints
                variant={'caption'}
                direction="vertical"
                name={`${name}.${index}.condition`}
                options={filter.options}
                onValueChange={(value) => {
                  const option = filter.options.find((o) => o.value === value)
                  const hasValue =
                    option?.requiredInput === undefined
                      ? true
                      : !!option?.requiredInput
                  methods.setValue(`${name}.${index}.hasValue`, hasValue)
                  methods.setValue(`${name}.${index}.value`, '')
                }}
                defaultOption={{
                  label: '',
                  value: '',
                }}
              />
            </Div>
          </Div>
          {filter.hasValue && (
            <>
              {filter.columnType === 'date' && (
                <Div
                  css={{
                    marginLeft: 'auto',
                    width: '30%',
                  }}
                >
                  <ControlledCalendarInput
                    name={`${name}.${index}.dates`}
                    calendarType={'single'}
                    maxDates={0}
                    label="Valor de comparación"
                    withHours
                    hourName={'withHours'}
                  />
                </Div>
              )}
              {filter.columnType !== 'date' && (
                <Div
                  css={{
                    marginLeft: 'auto',
                    width: '30%',
                  }}
                >
                  <FilterLabel column={'Valor de comparación'} />
                  <Div
                    css={{
                      display: 'flex',
                      marginLeft: '$4',
                    }}
                  >
                    <ControlledInput
                      inputType={
                        filter.columnType === 'number'
                          ? 'only-numbers-or-empty'
                          : 'no-mask'
                      }
                      name={`${name}.${index}.value`}
                    />
                  </Div>
                </Div>
              )}
            </>
          )}
          {!filter.hasValue && (
            <Div
              css={{
                marginLeft: 'auto',
                width: '30%',
              }}
            />
          )}
        </Div>
      </Div>
    </Div>
  )
}

const FilterLabel = ({ column }: { column: string }) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '$2',
      }}
    >
      <Div
        css={{
          height: 4,
          width: 4,
          borderRadius: '$full',
          background: '$interface_dark_down',
          marginRight: '$4',
          marginLeft: '$4',
        }}
      />
      <Div>
        <Text
          variant={'description'}
          css={{
            color: '$interface_dark_down',
          }}
        >
          {column}
        </Text>
      </Div>
    </Div>
  )
}
