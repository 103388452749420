import { Div } from '@/components'
import { ProgressBar } from '@/components/ProgressBar'
import { useDocumentsTotalSpace } from '@/libs/react-query/hooks/useDocumentsTotalSpace'
import { formatBytes } from '@/utils/documents/files'
import { Progress, Text } from '@punto-ui/react'

export const DocumentsSizeProgress = () => {
  const { data: totalSpace, isLoading: isLoadingTotalSpace } =
    useDocumentsTotalSpace()
  // Total size of 1 Gb
  const totalSize = 1024 * 1024 * 1024
  const progressInPercent =
    ((totalSpace || 0) / totalSize) * 100 > 100
      ? 100
      : ((totalSpace || 0) / totalSize) * 100

  return (
    <Div>
      {isLoadingTotalSpace && <Progress />}
      {!isLoadingTotalSpace && (
        <ProgressBar
          progressInPercent={progressInPercent}
          text={`${formatBytes(totalSpace || 0)} / ${formatBytes(totalSize)}`}
        />
      )}
    </Div>
  )
}
