import { usePayrollDayDetail } from '@/libs/react-query/hooks/usePayrolls/usePayrollDayDetails'
import { usePayrollPlaygroundStore } from '../../../store'
import { useTabStore } from '@/store'

export const usePayrollDay = () => {
  const { activePayrollId } = useTabStore((state) => ({
    activePayrollId: state.payroll.activeTab,
  }))
  const { drawer } = usePayrollPlaygroundStore((state) => ({
    drawer: state.drawer.data,
  }))

  const query = usePayrollDayDetail({
    date: drawer?.date,
    payrollId: activePayrollId,
    userId: drawer?.userId,
  })

  return { ...query, id: activePayrollId }
}
