import { queryClient } from '@/libs/react-query'
import { IUpdatePdfDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleUpdatePdf = async (data: IUpdatePdfDTO) => {
  await apiV2.post<any>(`/report-builder/update-pdf-template`, data)
  queryClient.invalidateQueries('pdfs')
}

export const useHandleUpdatePdf = () => {
  const mutation = useCustomMutation(['useHandleUpdatePdf'], handleUpdatePdf)

  return mutation
}
