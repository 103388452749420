import { Avatar, ContextualMenuLayout, Div } from '@/components'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button, Divider, Tag, Text, TextArea, styled } from '@punto-ui/react'
import * as ContextMenu from '@radix-ui/react-context-menu'
import dayjs from 'dayjs'
import { useState } from 'react'

interface IPunchMenuContentProps {
  users: {
    id: string
    name: string
    photo_url: string
  }[]
  date: string
  handleSubmit: (value: string) => void
}

export const CreatePunchMenu = (props: IPunchMenuContentProps) => {
  const options = [
    {
      id: '1',
      onClick: () => null,
      icon: <PencilSquareIcon />,
    },
    {
      id: '2',
      onClick: () => null,
      icon: <TrashIcon />,
    },
  ]
  return (
    <ContextMenu.Content>
      <ContextMenu.Portal>
        <ContextualMenuLayout options={options}>
          <CreatePunchMenuContent
            date={props.date}
            handleSubmit={props.handleSubmit}
            users={props.users}
          />
        </ContextualMenuLayout>
      </ContextMenu.Portal>
    </ContextMenu.Content>
  )
}

export const CreatePunchMenuContent = ({
  users,
  date,
  handleSubmit,
}: IPunchMenuContentProps) => {
  const [value, setValue] = useState('')

  return (
    <form>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '$4',
        }}
      >
        <Div css={{ padding: '$4', marginRight: '$8' }}>
          <PunchMenuInput
            placeholder="--:--"
            value={typeDecoder('hour').input(value || '')}
            onChange={(e) =>
              setValue(typeDecoder('hour').output(e.target.value) as string)
            }
          />
          <Text
            variant="description"
            css={{ marginTop: '$2', color: '$interface_dark_up' }}
          >
            {dayjs(date).format('DD/MM/YYYY')} - Agregando marcación para{' '}
          </Text>
        </Div>
        <Div>
          <Tag>Válido</Tag>
        </Div>
      </Div>
      <Div
        css={{
          maxHeight: 100,
          overflow: 'scroll',
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        {users.map((user) => (
          <Div
            key={user.id}
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt={user.name}
              src={user.photo_url}
              height={32}
              width={32}
              css={{ marginRight: '$4' }}
            />
            <Text css={{ marginLeft: '$2', color: '$interface_dark_down' }}>
              {user.name}
            </Text>
          </Div>
        ))}
      </Div>
      <Div css={{ paddingLeft: '$4', paddingRight: '$4' }}>
        <TextArea
          disabled
          placeholder="Escriba una justificativa para la creación..."
          css={{
            width: '100%',
            borderWidth: 1,
            borderColor: '$interface_dark_up',
          }}
        />
      </Div>
      {/* <ControlledFileInput /> */}
      <Div css={{ marginTop: '$4' }}>
        <Divider
          css={{
            height: 1,
            width: '100%',
            background: '$interface_light_down',
          }}
        />
      </Div>
      <Div
        css={{
          padding: '$4',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant={'primary'}
          type="submit"
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(value)
          }}
        >
          Guardar
        </Button>
      </Div>
    </form>
  )
}

const PunchMenuInput = styled('input', {
  all: 'unset',
  padding: '$2',
  background: 'transparent',
  fontFamily: '$default',
  borderBottom: '1px solid',
  borderColor: '$interface_dark_down',
})
