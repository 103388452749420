import { ControlledInput, Div } from '@/components'
import { styled, Text } from '@punto-ui/react'
import { useState } from 'react'
import { PdfBuilderContentDataSchemaType } from '../../types'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'

export const SimpleInput = styled('input', {
  all: 'unset',
  width: 50,
  height: 18,

  border: '1px solid $interface_light_deep',
  background: '$interface_light_up',

  fontFamily: '$default',
  fontSize: '12px',
  lineHeight: '18px',

  borderRadius: '$sm',
  textAlign: 'center',

  cursor: 'pointer',
})

export const ImageContent = ({
  contentIndex,
  dataIndex,
  contentData,
}: {
  contentData: PdfBuilderContentDataSchemaType
  contentIndex: number
  dataIndex: number
}) => {
  const { data: company } = useMyCompany()
  const [isOverTextArea, setIsOverTextArea] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  // const methods = useFormContext<PdfBuilderSchemaType>()
  // const height = useWatch({
  //   control: methods.control,
  //   name: `pdf.content.${contentIndex}.data.${dataIndex}.height`,
  // })

  const { height } = contentData

  return (
    <Div
      css={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        position: 'relative',
      }}
      onMouseEnter={() => setIsOverTextArea(true)}
      onMouseLeave={() => setIsOverTextArea(false)}
    >
      {company?.companyPictureUrl ? (
        <Div
          css={{
            height: height || 34,
            backgroundImage: `url(${company?.companyPictureUrl})`,
            width: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* <Image
            src={company?.companyPictureUrl}
            alt={'logo'}
            // width={'100%'}
            fill
            // height={height || 34}
          /> */}
        </Div>
      ) : (
        <Div
          css={{
            flex: 1,
            borderRadius: '$md',
            border: '2px solid',
            borderColor: '$brand_complementary_down',
            // minHeight: height || 34,
            // height: '100%',
            height: height && +height > 150 ? 100 : height || 34,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant={'caption'}
            css={{
              color: '$brand_complementary_down',
            }}
          >
            LOGO
          </Text>
        </Div>
      )}

      <Div
        css={{
          paddingTop: '$4',
          position: 'absolute',
          zIndex: 2,
          top: 'calc(100%)',
          left: 0,
          opacity: isOverTextArea || isFocused ? 1 : 0,
          pointerEvents: isOverTextArea || isFocused ? 'all' : 'none',
          transition: 'opacity 0.2s',
        }}
      >
        <Div
          onMouseEnter={() => setIsOverTextArea(true)}
          onMouseLeave={() => setIsOverTextArea(false)}
          css={{
            height: 34,
            boxShadow: '0px 0px 24px 0px #343A4029',

            padding: '$4',

            borderRadius: '$md',

            background: '$interface_light_pure',

            display: 'flex',
            gap: '$2',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <ControlledInput
            InputComponent={SimpleInput}
            name={`pdf.content.${contentIndex}.data.${dataIndex}.height`}
            inputType="only-numbers"
            css={{
              borderColor: isFocused ? '$brand_primary_deep' : undefined,
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </Div>
      </Div>
    </Div>
  )
}
