import { useEffect, useMemo } from 'react'
import { useWorkerCreation } from './context/hooks'
import {
  createCompleteWorkerData,
  useHandleCreateWorkerComplete,
  useHandleUpdateProfilePicture,
} from '@/libs/react-query/mutations'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'
import useToast from '@/hooks/useToast'
import { useHandleUpdateWorker } from '@/libs/react-query/mutations/workers/useHandleUpdate'
import { useHandleUpdateFaceId } from '@/libs/react-query/mutations/workers/useHandleUpdateFaceId'
import { useGetFormFields } from './hooks'
import { useTabStore } from '@/store'
import { UpdateFaceIdError } from '@/exceptions'
import { AxiosError } from 'axios'
import { IRouteAction } from '@/pages/router'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { useIsWorkerFormAvailable } from './hooks/useIsWorkerFormAvailable'

export const useHandleWorkerCreationActions = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const { formStep, setFormStep, isLastStep, isCreation, totalSteps } =
    useWorkerCreation()
  const { addToast } = useToast()
  const { trigger, getValues, formState, control } =
    useFormContext<createCompleteWorkerData>()

  const {
    mutateAsync: handleCreateWorkerComplete,
    isLoading: isLoadingCreateWorker,
  } = useHandleCreateWorkerComplete()
  const {
    mutateAsync: handleUpdateWorkerData,
    isLoading: isLoadingUpdateWorker,
  } = useHandleUpdateWorker()
  const { mutateAsync: handleUpdateFaceID, isLoading: isLoadingUpdateFaceId } =
    useHandleUpdateFaceId()
  const {
    mutate: handleUpdateProfilePicture,
    isLoading: isLoadingUpdateProfilePicture,
  } = useHandleUpdateProfilePicture()

  const { data: getFormFields } = useGetFormFields()

  const { setDefaultTab, removeTab, setActions } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('worker', 'list'),
    setActions: state.setActionsTab,
  }))

  const handleGoBack = () => {
    if (formStep) {
      setFormStep(formStep - 1)
    }
  }

  const salaryHistory = useWatch({
    control,
    name: 'salaryHistory',
  })

  const handleCreateWorker = async (data: createCompleteWorkerData) => {
    const newWorker = await handleCreateWorkerComplete(data)

    if (data.face_id_photo) {
      await handleUpdateFaceID({
        photo: data.face_id_photo,
        user_id: newWorker.id,
      })
    }

    if (data.profile_photo) {
      handleUpdateProfilePicture({
        photo: data.profile_photo,
        user_id: newWorker.id,
      })
    }

    addToast({
      title: '👌 Registro exitoso',
      description: '',
      type: 'positive',
      id: Date.now(),
    })

    removeTab('worker', 'create')
    setDefaultTab()
  }

  const handleUpdateWorker = async (data: createCompleteWorkerData) => {
    const children = data.children?.map((child) => ({
      ...child,
      id: child.isNew ? undefined : child.id,
    }))

    const contacts = data.contacts?.map((contact) => ({
      ...contact,
      id: contact.isNew ? undefined : contact.id,
    }))

    await handleUpdateWorkerData({
      ...data,
      children,
      contacts,
    })

    if (data.face_id_photo && data.id) {
      await handleUpdateFaceID({
        photo: data.face_id_photo,
        user_id: data.id,
      })
    }

    if (data.profile_photo && data.id) {
      handleUpdateProfilePicture({
        photo: data.profile_photo,
        user_id: data.id,
      })
    }

    addToast({
      title: '👌 Atualización exitosa',
      description: '',
      type: 'positive',
      id: Date.now(),
    })
  }

  const handleWithUpdateError = (err: unknown) => {
    if (err instanceof UpdateFaceIdError) {
      const message = isCreation
        ? 'Algun error ocurrió con el FaceID, pelo el colaborador fue creado.'
        : 'Algun error ocurrió con el FaceID, pelo el colaborador fue atualizado.'

      addToast({
        title: 'Uppz!',
        description: message,
        type: 'warning',
        id: Date.now(),
      })

      return
    }

    if (err instanceof AxiosError) {
      addToast({
        title: 'Uppz!',
        description:
          err.response?.data?.message ||
          'Algun error ocurrió, intenta de nuevo.',
        type: 'negative',
        id: Date.now(),
      })
    }
  }

  const salaryInconsistencyMessage = useMemo(() => {
    const hasEmptyConcept = (salaryHistory || [])?.findIndex(
      (salary, index) => {
        const hasEmptyValue = salary.concepts.some((concept) => !concept.value)

        return hasEmptyValue
      },
    )

    if (hasEmptyConcept !== -1) {
      return `Algunos valores de conceptos están vacíos en salário ${
        hasEmptyConcept + 1
      }, por favor rellena todos los campos.`
    }
    const hasEmptySalary = (salaryHistory || [])?.findIndex((salary) => {
      const salaryIsEmpty = salary.is_raw_value
        ? !salary.value
        : !salary.group_id

      return salaryIsEmpty
    })

    if (hasEmptySalary !== -1) {
      return `Algunos valores de salario están vacíos en salário ${
        hasEmptySalary + 1
      }, por favor rellena todos los campos.`
    }

    const hasEmptyDates = (salaryHistory || [])?.findIndex((salary, index) => {
      const salaryHasEmptyDates = index && !salary.start_date[0]

      return salaryHasEmptyDates
    })

    if (hasEmptyDates !== -1) {
      return `La fechas inicial en salário ${
        hasEmptyDates + 1
      } está vacío, por favor rellena todos los campos.`
    }

    return ''
  }, [salaryHistory])

  const handleSubmit = async () => {
    const data = getValues()

    const allFields = getFormFields()

    const inputNamesBasedOnStep = allFields.map(
      (field) => field.name || '',
    ) as unknown as FieldPath<createCompleteWorkerData>[]

    if (isCreation) {
      inputNamesBasedOnStep.push('password', 'confirm_password')
    }

    const isValid = await trigger(inputNamesBasedOnStep)

    if (!isValid) {
      console.log('not valid', formState.errors)
      return
    }

    try {
      if (isCreation) {
        await handleCreateWorker(data)
      } else {
        await handleUpdateWorker(data)
      }
    } catch (err) {
      console.log(err)
      handleWithUpdateError(err)
    }
  }

  const areCurrentFieldsValid = async () => {
    const fields = getFormFields(formStep)

    const inputNamesBasedOnStep = fields.map(
      (field) => field.name || '',
    ) as unknown as FieldPath<createCompleteWorkerData>

    const isValid = await trigger(inputNamesBasedOnStep)

    return isValid
  }

  const handleNextStep = async () => {
    const isValid = await areCurrentFieldsValid()

    if (!isValid) {
      console.log('not valid', formState.errors)
      return
    }

    if (formStep < totalSteps - 1) {
      setFormStep(formStep + 1)
    } else {
      await handleSubmit()
    }
  }

  const isLoading = useMemo(() => {
    return (
      isLoadingCreateWorker ||
      isLoadingUpdateWorker ||
      isLoadingUpdateFaceId ||
      isLoadingUpdateProfilePicture
    )
  }, [
    isLoadingCreateWorker,
    isLoadingUpdateWorker,
    isLoadingUpdateFaceId,
    isLoadingUpdateProfilePicture,
  ])
  useEffect(() => {
    const actions: IRouteAction[] = []
    actions.push(
      {
        label: 'Anterior',
        action: handleGoBack,
        icon: <ArrowLeftIcon />,
        variant: 'tertiary',
        disabled: !formStep,
      },
      {
        label: 'Próximo',
        action: handleNextStep,
        disabled: isLoading || isLastStep,
        isLoading: isLoading && isCreation,
        variant: 'tertiary',
        icon: <ArrowRightIcon />,
      },
      {
        label: isCreation ? 'Registrar' : 'Actualizar',
        action: isCreation ? handleNextStep : handleSubmit,
        disabled:
          !isWorkerCreationAvailable ||
          isLoading ||
          (isCreation && !isLastStep) ||
          !!salaryInconsistencyMessage,
        disableMessage: salaryInconsistencyMessage,
        isLoading,
        variant: 'primary',
        icon: <ArrowRightIcon />,
      },
    )

    setActions('worker', actions)
  }, [
    setActions,
    salaryInconsistencyMessage,
    formStep,
    isLastStep,
    isCreation,
    isLoading,
    isWorkerCreationAvailable,
  ])
}
