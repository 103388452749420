import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IUpdateCompanyConfigDTO } from '@/libs/react-query/mutations/company/types'
import { queryClient } from '@/libs/react-query'

export const useHandleUpdateCompanyConfig = () => {
  const mutation = useMutation(
    ['useHandleUpdateCompanyConfig'],
    handleUpdateCompanyConfig,
  )

  return mutation
}

const handleUpdateCompanyConfig = async (props: IUpdateCompanyConfigDTO) => {
  try {
    await apiV2.post<void>('/company/update-company-config', props)

    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}
