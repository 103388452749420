import React, { useCallback, useEffect, useState } from 'react'
import {
  GroupData,
  ICreateCompletePolicyData,
  createPolicySchema,
} from '../types'
import { useOrganogram, usePolicy } from '@/libs/react-query/hooks'
import { buildGroupDataFromOrganogram } from '@/pages/permisos/components/GroupBuilder/utils'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useHandleCreatePolicy,
  useHandleUpdatePolicy,
} from '@/libs/react-query/mutations'
import {
  fromStructureToCreatePolicyPayload,
  buildInitialCreatePolicyFields,
} from '../utils'
import { useRouter } from 'next/router'
import { useExecuteOnceWhen } from '@/hooks'
import { useTabStore } from '@/store'

type PolicyBuilderContextValue = {
  groups: GroupData[]
  isLoading: boolean
  isUpdating: boolean
  handleSubmit(): void
}

const PolicyBuilderContext = React.createContext<PolicyBuilderContextValue>({
  groups: [],
  isLoading: false,
  isUpdating: false,
  handleSubmit: () => null,
})

export const usePolicyBuilder = () => React.useContext(PolicyBuilderContext)

type GroupBuilderProviderProps = {
  children: React.ReactNode
  id?: string
}

export const PolicyBuilderProvider = ({
  children,
  id: paramsId,
}: GroupBuilderProviderProps) => {
  const { data: organogram } = useOrganogram()
  const { mutateAsync: handleCreatePolicy, isLoading: isLoadingCreatePolicy } =
    useHandleCreatePolicy()
  const { mutateAsync: handleUpdatePolicy, isLoading: isLoadingUpdatePolicy } =
    useHandleUpdatePolicy()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  const [groups, setGroups] = useState<GroupData[]>([])

  const { id: queryId } = router.query
  const id = (queryId || paramsId) as string | undefined

  const { data: policy, isLoading: isLoadingPolicy } = usePolicy(id?.toString())

  const { setActiveTab, remoteTab, activeTab } = useTabStore((state) => ({
    remoteTab: state.removeTab,
    setActiveTab: state.setActiveTab,
    activeTab: state.permissions.activeTab,
  }))

  useExecuteOnceWhen(() => {
    if (!isLoadingPolicy && organogram && !id) {
      const initialState = buildInitialCreatePolicyFields(
        {
          name: '',
          id: '',
          permissions: {},
          usersIds: [],
          hasAuthorizationRequest: false,
          usersAuthorizersIds: [],
        },
        organogram.cells,
      )

      Object.entries(initialState).forEach(([key, value]) => {
        methods.setValue(key as FieldPath<ICreateCompletePolicyData>, value)
      })
    }
  }, !isLoadingPolicy && !!organogram && !id)

  const methods = useForm<ICreateCompletePolicyData>({
    resolver: zodResolver(createPolicySchema),
    defaultValues:
      policy && organogram
        ? buildInitialCreatePolicyFields(policy, organogram.cells)
        : {},
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (organogram) {
      const builtGroups = buildGroupDataFromOrganogram(organogram) as GroupData
      const initialState = buildInitialCreatePolicyFields(
        {
          name: '',
          id: '',
          permissions: {},
          usersIds: [],
          hasAuthorizationRequest: false,
          usersAuthorizersIds: [],
        },
        organogram.cells,
      )

      Object.entries(initialState).forEach(([key, value]) => {
        methods.setValue(key as FieldPath<ICreateCompletePolicyData>, value)
      })

      setGroups([builtGroups])
    }
  }, [organogram, methods])

  useEffect(() => {
    if (id) {
      setIsLoading(true)
    }

    if (!isLoadingPolicy && policy && organogram) {
      const initialState = buildInitialCreatePolicyFields(
        policy,
        organogram.cells,
      )

      Object.entries(initialState).forEach(([key, value]) => {
        methods.setValue(key as FieldPath<ICreateCompletePolicyData>, value)
      })

      setIsLoading(false)
    }
  }, [isLoadingPolicy, policy, organogram, methods, id])

  const handleSubmit = useCallback(async () => {
    const data = methods.getValues()
    const valid = methods.trigger(['name', 'users'])
    if (!valid) {
      return
    }
    const payload = fromStructureToCreatePolicyPayload(
      data,
      data.name,
      data.users,
      data.usersToAuthorize,
      !!data.hasAuthorizations,
    )

    if (policy) {
      await handleUpdatePolicy({
        ...payload,
        policy_id: policy.id,
      })
      remoteTab('permissions', activeTab)
    } else {
      await handleCreatePolicy(payload)
      remoteTab('permissions', 'new')
    }

    setActiveTab('permissions', 'policies')
  }, [
    methods,
    handleCreatePolicy,
    handleUpdatePolicy,
    policy,
    setActiveTab,
    remoteTab,
    activeTab,
  ])

  return (
    <FormProvider {...methods}>
      <PolicyBuilderContext.Provider
        value={{
          groups,
          isLoading:
            isLoadingCreatePolicy || isLoadingUpdatePolicy || isLoading,
          // handleSubmit: methods.handleSubmit(handleSubmit),
          handleSubmit,
          isUpdating: !!id,
        }}
      >
        {children}
      </PolicyBuilderContext.Provider>
    </FormProvider>
  )
}
