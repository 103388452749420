import { AppBar, ControlledFilterBar, Div, SmartTable } from '@/components'
import {
  SmartColumnType,
  SubmitValueProps,
} from '@/components/SmartTable/types'
import { useCallback, useEffect, useMemo } from 'react'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { PlusIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { usePunchSummary } from './context'
import { useFormContext, useWatch } from 'react-hook-form'
import { PunchesInfosTableType } from './types'
import {
  PunchInfosMenu,
  PunchesAppBar,
  TotalPunchInfosMenu,
} from './components'
import { useMovementsContext } from '../movimientos/context/hooks/useMovementsContext'
import { IMovementV2, IWorkerUser } from '@/libs/react-query/types'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useCan } from '@/hooks'
import { useSmartTableColumnManipulation } from '@/components/SmartTable/hooks/useSmartTableColumnManipulation'

dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekdays: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ],
})

interface SubmitHoursParams {
  shouldCreate: boolean
  data: string
  userId: string
  previousData?: string
  punchDate?: Date
}

export const PunchesTable = () => {
  const { data: smartShifts } = useSmartShifts()
  const { handleOpenMovementDrawer } = useMovementsContext()
  const {
    handleCreatePunch,
    handleUpdatePunch,
    handleCreateShiftSchedule,
    isLoading,
  } = usePunchSummary()

  const { getValues, control, setValue } =
    useFormContext<PunchesInfosTableType>()

  const [numberOfIntervals, dates, pagination, name] = useWatch({
    control,
    name: ['numberOfIntervals', 'date', 'pagination', 'name'],
  })

  useEffect(() => {
    if (name.length > 0) {
      setValue('pagination.page', 0)
    }
  }, [name, setValue])

  const selectedDate = dayjs(dates?.[0]).toDate()
  const handleSubmitHour = useCallback(
    async ({
      data,
      shouldCreate,
      userId,
      previousData,
      punchDate,
    }: SubmitHoursParams) => {
      const correctPunchDate = punchDate || selectedDate

      if (shouldCreate) {
        const punchDateString = dayjs(correctPunchDate).format('YYYY-MM-DD')
        const newDate = dayjs(`${punchDateString} ${data}`).toISOString()
        await handleCreatePunch({
          date: newDate,
          user_id: userId,
        })
      } else {
        await handleUpdatePunch({
          newDate: dayjs(
            `${dayjs(correctPunchDate).format('YYYY-MM-DD')} ${data}`,
          ).toISOString(),
          previousDate: dayjs(
            `${dayjs(correctPunchDate).format('YYYY-MM-DD')} ${previousData}`,
          ).toISOString(),
          user_id: userId,
        })
      }
    },
    [handleCreatePunch, handleUpdatePunch, selectedDate],
  )

  const handleUpdateShift = useCallback(
    async (shiftId: string, userId: string) => {
      await handleCreateShiftSchedule({
        params: [
          {
            dates: [dayjs(selectedDate).startOf('day').toDate()],
            userId,
          },
        ],
        shiftId,
        isRest: false,
      })
    },
    [handleCreateShiftSchedule, selectedDate],
  )

  const canEditPunches = useCan(['punches.edit'])
  const canEditMovements = useCan(['moves.edit', 'moves.request'])
  const canManageShifts = useCan(['shifts.gestionar'])

  const columns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Informaciones del Usuario',
        name: 'teste-1',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 150,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 150,
          },
          {
            type: 'input-text',
            name: 'document',
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        header: 'Informaciones Adicionales',
        type: 'subheader',
        name: 'teste-2',
        items: [
          {
            type: 'tags',
            name: 'status',
            onTagClick: () => console.log('wtfff'),
            header: 'Status',
            width: 100,
          },
          {
            type: 'tags',
            width: 100,
            name: 'movements',
            onTagClick: (
              index: number,
              name: string,
              previousValue: string,
              movement: IMovementV2,
            ) => {
              if (!canEditMovements) {
                return
              }

              const user = {
                id: getValues().data[index].userId,
              } as IWorkerUser

              handleOpenMovementDrawer({
                users: movement.users?.map(
                  (u) =>
                    ({
                      id: u.user_id,
                    } as IWorkerUser),
                ) || [user],
                movement: {
                  id: movement.id,
                  batch_id: '',
                  description: movement.description,
                  identifier_id: movement.identifier_id,
                  periods: movement.periods.map((period) => ({
                    ...period,
                  })),
                  type: movement.type as any,
                  category: movement.category as any,
                  should_include_in_ips: movement.should_include_in_ips,
                  should_include_hours: !!movement.should_include_hours,
                  should_pay_period: movement.should_pay_period,
                  title: movement.title,
                  created_at: movement.created_at,
                  deleted_at: '',
                  updated_at: '',
                },
              })
            },
            header: 'Movim.',
          },
          {
            type: 'dropdown',
            width: 150,
            name: 'shiftId',
            header: 'Turno',
            disabled: !canManageShifts,
            options: smartShifts?.map((shift) => ({
              label: shift.shiftName,
              value: shift.shiftId,
            })),
            handleSubmitValue: async (props: SubmitValueProps) => {
              await handleUpdateShift(
                String(props.data.value),
                getValues().data[props.index].userId,
              )
            },
          },
        ],
      },
      {
        header: 'Marcaciones',
        type: 'subheader',
        name: 'teste-3',
        items: [
          {
            type: 'input-text',
            width: 100,
            name: 'add',
            enableSameSubmit: true,
            disabled: !canEditPunches,
            draggable: canEditPunches ? PunchInfosMenu : null,
            header: 'Agregar',
            placeholder: '--:--',
            icon: <PlusIcon />,
            inputType: 'hour',
            handleSubmitValue: async (props: SubmitValueProps) => {
              const { data } = getValues()
              const item = data[props.index]

              const date = dayjs(selectedDate).format('YYYY-MM-DD')

              const punchDateWithHour = dayjs(
                `${date} ${props.data.value}`,
                'YYYY-MM-DD HH:mm',
              ).toISOString()

              await handleCreatePunch({
                date: punchDateWithHour,
                user_id: item.userId,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
          {
            type: 'input-text',
            name: 'entrance',
            inputType: 'hour',
            disabled: !canEditPunches,
            width: 100,
            header: 'Entrada',
            draggable: canEditPunches ? PunchInfosMenu : null,
            enableSameSubmit: true,
            handleSubmitValue: async (props: SubmitValueProps) => {
              const item = getValues('data')[props.index]
              await handleSubmitHour({
                data: String(props.data.value),
                previousData: String(props.data.original),
                shouldCreate:
                  !props.data.original || props.data.metadata.isAutomatic,
                userId: item.userId,
                punchDate: props.data.metadata?.date,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
          {
            type: 'input-text',
            name: 'intervals',
            array: true,
            disabled: !canEditPunches,
            enableSameSubmit: true,
            length: numberOfIntervals,
            draggable: canEditPunches ? PunchInfosMenu : null,
            inputType: 'hour',
            width: 100,
            header: 'Inter.',
            handleSubmitValue: async (props: SubmitValueProps) => {
              const data = String(props.data.value)
              const previousValue = props.data.original
              const item = getValues('data')[props.index]

              await handleSubmitHour({
                data,
                previousData: String(previousValue),
                shouldCreate: !previousValue || props.data.metadata.isAutomatic,
                userId: item.userId,
                punchDate: props.data.metadata?.date,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
          {
            inputType: 'hour',
            type: 'input-text',
            width: 100,
            disabled: !canEditPunches,
            name: 'exit',
            enableSameSubmit: true,
            draggable: canEditPunches ? PunchInfosMenu : null,
            header: 'Salida',
            handleSubmitValue: async (props: SubmitValueProps) => {
              const data = String(props.data.value)
              const previousValue = String(props.data.original)
              const item = getValues('data')[props.index]

              await handleSubmitHour({
                data,
                previousData: previousValue,
                shouldCreate: !previousValue || props.data.metadata.isAutomatic,
                userId: item.userId,
                punchDate: props.data.metadata?.date,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
        ],
      },
      {
        type: 'subheader',
        name: 'teste-4',
        header: 'Totales',
        items: [
          {
            type: 'input-text',
            width: 100,
            name: 'totalMorningHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Total Diur.',
          },
          {
            type: 'input-text',
            name: 'totalNightHours',
            width: 100,
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Total Noct.',
          },
        ],
      },
      {
        header: 'Totales de Horas',
        type: 'subheader',
        name: 'teste-5',
        items: [
          {
            type: 'input-text',
            width: 100,
            inputType: 'hour',
            name: 'morningHours',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            header: 'Or. Diur.',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 100,
            inputType: 'hour',
            name: 'nightHours',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Or. Noct.',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'morningExtraHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Ext. Diur.',
          },
          {
            width: 100,
            type: 'input-text',
            name: 'nightExtraHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Ext. Noct',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'totalIntervalHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Intervalos',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'morningDiscountHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Desc. Diur.',
          },
          {
            type: 'input-text',
            name: 'nightDiscountHours',
            width: 100,
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Desc. Noct.',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'morningHolidayHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Fer. Diur.',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'nightHolidayHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Fer. Noct.',
          },
        ],
      },
    ]
  }, [
    canManageShifts,
    canEditMovements,
    canEditPunches,
    smartShifts,
    getValues,
    selectedDate,
    handleCreatePunch,
    numberOfIntervals,
    handleSubmitHour,
    handleOpenMovementDrawer,
    handleUpdateShift,
  ])

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'punchesColumnsOrder',
    tableHiddenUniqueName: 'punchesHiddenColumns',
    tableLeftFixedUniqueName: 'punchesLeftFixedColumns',
    tableRightFixedUniqueName: 'punchesRightFixedColumns',

    defaultLeftFixedColumns: ['avatar', 'name', 'surname'],
  })

  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
      height: 600,
    }
  }, [])

  return (
    <>
      <ControlledFilterBar
        dateName={'date'}
        nameFilterName="name"
        cellsIdsName="cellsIds"
        dateType="single"
        withAdvancedCells
        isLoading={isLoading}
        policiesIds={permissionsArray.filter((p) => p.includes('punches'))}
        tag={dayjs(selectedDate).format('dddd')}
      />
      <Div
        css={{
          position: 'relative',
          height: 'calc(100vh - 84px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 16,
          paddingRight: 16,
          overflowX: 'scroll',
          marginTop: '$4',
        }}
      >
        <SmartTable
          style={tableStyles}
          columns={columns}
          withPagination
          defaultColumnOrder={defaultColumnsOrder}
          resetColumnOrder={resetStateColumnOrder}
          defaultHiddenColumns={defaultHiddenColumns}
          saveColumnsOrderCallback={saveColumnsOrderCallback}
          saveHiddenColumnsCallback={saveHiddenColumnsCallback}
          saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
          saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
          defaultLeftFixedColumns={defaultLeftFixedColumns}
          defaultRightFixedColumns={defaultRightFixedColumns}
          maxDepth={2}
          areColumnsDraggable
          paginationProps={{
            onChange: (data) => {
              setValue('pagination.page', data.pageIndex)
              setValue('pagination.perPage', data.pageSize)
            },
            page: pagination.page,
            perPage: pagination.perPage,
            totalPages: pagination.totalPages,
            total: pagination.total,
          }}
        />
        <MaybeAppBar />
      </Div>
    </>
  )
}

const MaybeAppBar = () => {
  const { control } = useFormContext<PunchesInfosTableType>()

  const data = useWatch({
    control,
    name: 'data',
  })

  const isShowingAppBar = useMemo(
    () => data.some((p) => p.checked.value),
    [data],
  )

  return (
    <AppBar isShowing={isShowingAppBar}>
      <PunchesAppBar />
    </AppBar>
  )
}
