import { styled, Text } from '@punto-ui/react'

export const ShiftsListContainer = styled('div', {})

export const WorkersListContainer = styled('div', {
  margin: '$4',
  border: 'solid 1px $interface_light_down',
  borderRadius: '$md',
})

export const StyledContentText = styled(Text, {
  color: '$interface_dark_deep',
  fontSize: '$sm',
  textAlign: 'left',
})

export const StyledHeadingText = styled(Text, {
  color: '$interface_dark_deep',
  fontWeight: 'bold',
  fontSize: '$sm',
  textAlign: 'left',
  padding: '$3 $2',
})
