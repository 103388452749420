import { paymentOptions } from '@/libs/react-query/types'
import { IContentOption } from '../DataForm'

export const payrollsPdfData: IContentOption[] = [
  {
    label: 'Dias trabajados',
    value: 'nomina.numero_dias_trabajados',
  },
  {
    label: 'Valor dias trabajados (G$)',
    value: 'nomina.valor_dias_trabajados',
  },
  {
    label: 'Valor unitário ordinária diurna (G$)',
    value: 'nomina.unitario_horas_diurnas',
  },
  {
    label: 'Valor unitário ordinária nocturna (G$)',
    value: 'nomina.unitario_horas_nocturnas',
  },
  {
    label: 'Valor unitário extra diurna (G$)',
    value: 'nomina.unitario_horas_extras_diurnas',
  },
  {
    label: 'Valor unitário extra nocturna (G$)',
    value: 'nomina.unitario_horas_extras_nocturnas',
  },
  {
    label: 'Valor unitário feriado diurna (G$)',
    value: 'nomina.unitario_horas_feriado_diurnas',
  },
  {
    label: 'Valor unitário feriado nocturna (G$)',
    value: 'nomina.unitario_horas_feriado_nocturnas',
  },
  {
    label: 'Adicional Nocturno (G$)',
    value: 'nomina.adicional_nocturno',
  },
  {
    label: 'Horas extras diurnas (G$)',
    value: 'nomina.valor_horas_extras_diurnas',
  },
  {
    label: 'Horas extras nocturnas (G$)',
    value: 'nomina.valor_horas_extras_nocturnas',
  },
  {
    label: 'Total horas extras (G$)',
    value: 'nomina.valor_horas_extras_totales',
  },
  {
    label: 'Horas feriado diurnas (G$)',
    value: 'nomina.valor_horas_feriado_diurnas',
  },
  {
    label: 'Horas feriado nocturnas (G$)',
    value: 'nomina.valor_horas_feriado_nocturnas',
  },
  {
    label: 'Desc. de horas diurnas (G$)',
    value: 'nomina.valor_descuento_horas_diurnas',
  },
  {
    label: 'Desc. de horas nocturnas (G$)',
    value: 'nomina.valor_descuento_horas_nocturnas',
  },
  {
    label: 'Horas Ordinarias Diurnas (HH:mm)',
    value: 'nomina.horas_diurnas',
  },
  {
    label: 'Horas Ordinarias Nocturnas (HH:mm)',
    value: 'nomina.horas_nocturnas',
  },
  {
    label: 'Total horas ordinarias (HH:mm)',
    value: 'nomina.total_ordinarias',
  },
  {
    label: 'Horas extras diurnas (HH:mm)',
    value: 'nomina.horas_extras_diurnas',
  },
  {
    label: 'Horas extras nocturnas (HH:mm)',
    value: 'nomina.horas_extras_nocturnas',
  },
  {
    label: 'Total horas extras (HH:mm)',
    value: 'nomina.horas_extras_totales',
  },
  {
    label: 'Horas feriado diurnas (HH:mm)',
    value: 'nomina.horas_feriado_diurnas',
  },
  {
    label: 'Horas feriado nocturnas (HH:mm)',
    value: 'nomina.horas_feriado_nocturnas',
  },
  {
    label: 'Desc. de horas diurnas (HH:mm)',
    value: 'nomina.descuento_horas_diurnas',
  },
  {
    label: 'Desc. de horas nocturnas (HH:mm)',
    value: 'nomina.descuento_horas_nocturnas',
  },
  {
    label: 'Bonificación Familiar',
    value: 'nomina.bonificacion_familiar',
  },
  ...paymentOptions.map((m) => ({
    label: `${m.label} (IPS)`,
    value: `nomina.${m.value}_value_ips`,
  })),
  ...paymentOptions.map((m) => ({
    label: `${m.label} (Sin IPS)`,
    value: `nomina.${m.value}_value`,
  })),
  {
    label: 'IPS 9%',
    value: 'nomina.ips9',
  },
  {
    label: 'IPS 16%',
    value: 'nomina.ips16',
  },
  {
    label: 'Total Haberes de Horas (G$)',
    value: 'nomina.total_haberes_de_horas',
  },
  {
    label: 'Salario a pagar (G$)',
    value: 'nomina.salario_neto',
  },
]
