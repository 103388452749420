import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { useAvailableCells, useMovements } from '@/libs/react-query/hooks'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import {
  IMovementEnvelope,
  movementsDictionary,
} from '@/libs/react-query/types'
import dayjs from 'dayjs'
import { Progress } from '@punto-ui/react'
import { shortenName } from '@/utils/workers/name'
import { getUtcDateFromString } from '@/utils/date'

export const MovementStep = () => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('moves')),
  )
  const { data: movements, isFetching: isFetchingMovements } = useMovements({
    cellsIds: availableCells?.map((c) => c.id) || [],
    name: '',
    page: 1,
    pageSize: 200,
    types: [],
  })

  const methods = useFormContext<ExportPdfSchemaType>()

  const movementId = useWatch({
    control: methods.control,
    name: 'movementId',
  })

  const selectedMovement = movements?.data.find(
    (s) => s.movement.id === movementId,
  )

  const getMovementLabel = (s: IMovementEnvelope) => {
    let str = `${movementsDictionary[s.movement.category]}`

    if (s.users[0]?.name) {
      str += ` - ${shortenName(s.users[0].name)} ${
        s.users.length > 1
          ? ` + ${s.users.length - 1} ${
              s.users.length - 1 === 1 ? 'colaborador' : 'colaboradores'
            }`
          : ''
      }`
    }

    if (s.movement.type === 'MULTIPLE_DAYS') {
      const dates = s.movement.periods.sort((a, b) => {
        return dayjs(a.date).diff(dayjs(b.date))
      })

      str +=
        dates.length === 1
          ? `- ${dayjs(getUtcDateFromString(dates[0].date)).format(
              'DD/MM/YYYY',
            )}`
          : ` - ${dayjs(getUtcDateFromString(dates[0].date)).format(
              'DD/MM/YYYY',
            )} ~ ${dayjs(
              getUtcDateFromString(dates[dates.length - 1].date),
            ).format('DD/MM/YYYY')}`
    }

    if (s.movement.type === 'SINGLE_DAY') {
      str += ` - ${dayjs(s.movement.periods[0]?.period_start).format(
        'DD/MM/YYYY HH:mm',
      )}
      hasta ${dayjs(s.movement.periods[0]?.period_end).format(
        'DD/MM/YYYY HH:mm',
      )}`
    }

    if (s.movement.type === 'SINGLE_PERIOD') {
      str +=
        s.movement.periods?.[0]?.period_start ===
        s.movement.periods?.[0]?.period_end
          ? `- ${dayjs(
              getUtcDateFromString(s.movement.periods?.[0]?.period_start),
            ).format('DD/MM/YYYY')}`
          : ` - ${dayjs(
              getUtcDateFromString(s.movement.periods?.[0]?.period_start),
            ).format('DD/MM/YYYY')} hasta ${dayjs(
              getUtcDateFromString(s.movement.periods?.[0]?.period_end),
            ).format('DD/MM/YYYY')}`
    }

    return str
  }

  return (
    <Div>
      <DropdownV2
        value={movementId || ''}
        defaultValue={movementId || ''}
        onChangeValue={(value) => {
          if (!value.value) {
            return
          }

          methods.setValue('movementId', value.value)
          const movement = movements?.data.find(
            (s) => s.movement.id === value.value,
          )
          methods.setValue('usersIds', movement?.users.map((u) => u.id) || [])
        }}
        defaultOption={{
          label: selectedMovement
            ? getMovementLabel(selectedMovement)
            : 'Selecciona un movimiento',
          value: selectedMovement?.movement?.id || '',
        }}
        options={
          movements?.data.map((s) => {
            return {
              label: getMovementLabel(s),
              value: s.movement.id,
            }
          }) || []
        }
      />
      {isFetchingMovements && (
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Progress />
        </Div>
      )}
    </Div>
  )
}
