import { styled } from '@punto-ui/react'

export const FromDrawerContainer = styled('div', {
  padding: '$4 $3',
  maxWidth: 'min(50vw, 1000px)',
  marginRight: 24,
  paddingRight: 16,

  paddingBottom: 100,

  height: '100vh',
  overflowY: 'scroll',
  // height: 200,
})

export const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '$2',

  '& svg': {
    color: '$brand_primary_pure',
    marginRight: '$2',
  },
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  marginTop: 'auto',
})

export const Form = styled('form', {
  marginTop: '$4',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 16,
  gap: '$4',
})
