import { ControlledInput, Div } from '@/components'
import { Text } from '@punto-ui/react'
import { HeaderImportantText, ReceiptHeaderInput } from '../ReceiptHeader'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceiptBuilderSchemaType } from '../../../type'

export const NoImageHeaderOption = () => {
  const { control } = useFormContext<ReceiptBuilderSchemaType>()

  const title = useWatch({
    control,
    name: 'configurations.title',
  })

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 800,
        border: '2px solid $interface_dark_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: '2px solid $interface_dark_pure',
        }}
      >
        <HeaderImportantText>{title}</HeaderImportantText>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '25%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>Empleador</HeaderImportantText>
        </Div>
        <Div>
          <Text>CAV INC. S. A.</Text>
        </Div>
        <Div>
          <HeaderImportantText>N PATRONAL MTESS</HeaderImportantText>
        </Div>
        <Div>
          <Text>123123123</Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '50%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>PERIODO</HeaderImportantText>
        </Div>
        <Div>
          <Text>DEL 01/04/2024</Text>
        </Div>
        <Div>
          <Text>HASTA 30/04/2024</Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },
          '> div:last-child': {
            width: '25%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>NOMBRE TRABAJADOR</HeaderImportantText>
        </Div>
        <Div>
          <Text>JORGE GENEZ</Text>
        </Div>
        <Div>
          <HeaderImportantText>CI No</HeaderImportantText>
        </Div>
        <Div>
          <Text>123123123</Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
        }}
      >
        <Div>
          <HeaderImportantText>
            <ControlledInput
              InputComponent={ReceiptHeaderInput}
              name="headerConfig.title"
            />
          </HeaderImportantText>
        </Div>
      </Div>
    </Div>
  )
}
