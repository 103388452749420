import { Div } from '@/components/Div'
import { ControlledInput } from '@/components/Forms'
import {
  SmartCellStatus,
  SmartColumnType,
  SmartTableFormItemValueType,
  SmartTableFormSchemaType,
} from '@/components/SmartTable/types'
import { getDeepVal } from '@/utils/object'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button, Progress, styled } from '@punto-ui/react'
import { Row } from '@tanstack/react-table'
import { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ROW_HEIGHT } from '.'

interface InputTextProps {
  fieldValueName: string
  fieldName: string
  statusName: string
  index: number
  columnId: string
  column: SmartColumnType
  subIndex?: number
  row: Row<SmartTableFormSchemaType>
}

export const InputText = ({
  fieldName,
  fieldValueName,
  statusName,
  column,
  index,
  subIndex,
}: InputTextProps) => {
  const {
    trigger,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useFormContext()

  const status: SmartCellStatus = useWatch({
    control,
    name: statusName,
    defaultValue: getValues(statusName),
  })

  const field: SmartTableFormItemValueType = useWatch({
    control,
    name: fieldName,
  })

  const inputRef = useRef<HTMLInputElement>(null)

  const [isUpdatingFieldState, setIsUpdatingField] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const previousValue = useRef<string | null>(null)

  const isUpdatingField = isUpdatingFieldState && !column.freeWriting

  useEffect(() => {
    previousValue.current = null
    setIsUpdatingField(false)
  }, [])

  const inputError = getDeepVal(errors, fieldValueName)
  const alignOnRight = ['hour', 'long-hour']

  const textAlign = alignOnRight.includes(column.inputType || '')
    ? 'right'
    : 'left'

  // console.log('column', column, index)

  const handleSubmitValue = async () => {
    const isValid = await trigger(fieldValueName)
    const data = getValues(fieldValueName)
    const field = getValues(fieldName)

    if (!isValid || (!data && !column.enableEmptySubmit)) {
      return
    }

    setIsUpdatingField(false)

    await column.handleSubmitValue?.({
      data: field,
      index,
      subindex: subIndex,
    })

    previousValue.current = data
  }

  return (
    <>
      <Div
        css={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          minWidth: column.width ? column.width : undefined,
          maxWidth: column.width ?? undefined,
          width: column.width ?? undefined,

          height: ROW_HEIGHT,
        }}
      >
        <ControlledInput
          ref={inputRef}
          disabled={
            !!column.disabled ||
            status === 'loading' ||
            !!field?.metadata?.disabled
          }
          name={fieldValueName}
          shouldValidate={false}
          onChange={(e) => {
            if (e !== previousValue.current) {
              setIsUpdatingField(true)
            } else {
              setIsUpdatingField(false)
            }
          }}
          placeholder={column.placeholder || ''}
          onFocus={(e) => {
            if (previousValue.current === null) {
              previousValue.current = getValues(fieldValueName) as string
            }

            setIsFocused(true)
            inputRef.current?.setSelectionRange(0, e.currentTarget.value.length)
          }}
          inputType={
            field?.metadata?.inputType || column.inputType || 'no-mask'
          }
          InputComponent={TableInput}
          onBlur={async (e) => {
            e.preventDefault()

            const value = getValues(fieldValueName)
            if (value) {
              trigger(fieldValueName)
            }

            setIsFocused(false)
            column.onBlur?.({
              data: field,
              index,
              subindex: subIndex,
            })
          }}
          css={{
            transition: 'all 0.2s ease-in-out',
            fontSize: '$sm',
            paddingLeft: 8,
            paddingRight: 8,
            // minWidth: column.width
            //   ? column.width
            //   : minWidthsByType[column.inputType || ''] || 0,
            maxWidth: column.width ? column.width - 18 : undefined,
            width: '100%',
            height: ROW_HEIGHT - 2,
            // width: column.width ?? undefined,
            textAlign,
            border: 'solid 1px',
            borderRadius: '$xs',
            color:
              status === 'soft-error' || status === 'error'
                ? '$status_danger_deep'
                : status === 'soft-success' || status === 'success'
                ? '$status_success_deep'
                : status === 'soft-warning' || status === 'warning'
                ? '$status_warning_deep'
                : undefined,
            backgroundColor:
              status === 'soft-error' || status === 'error'
                ? '$status_danger_up'
                : status === 'soft-success' || status === 'success'
                ? '$status_success_up'
                : status === 'soft-warning' || status === 'warning'
                ? '$status_warning_up'
                : 'transparent',
            borderColor:
              (status === 'error' || inputError) &&
              (isFocused || isUpdatingField)
                ? '$status_danger_deep'
                : status === 'loading'
                ? '$brand_primary_pure'
                : status === 'success' && (isFocused || isUpdatingField)
                ? '$status_success_deep'
                : status === 'warning' && (isFocused || isUpdatingField)
                ? '$status_warning_pure'
                : status === 'warning'
                ? '$status_warning_pure'
                : status === 'success'
                ? '$status_success_deep'
                : isUpdatingField || isFocused
                ? '$brand_primary_pure'
                : status === 'error' || inputError
                ? '$status_danger_deep'
                : 'transparent',
          }}
        />
        {column.enableSameSubmit && !(isUpdatingField && !column.icon) && (
          <Button
            type="submit"
            css={{ display: 'none' }}
            onClick={(e) => {
              e.preventDefault()
              handleSubmitValue()
            }}
          />
        )}
        {isUpdatingField && !column.icon && (
          <InputIconDiv textAlign={textAlign}>
            <Button
              type="submit"
              css={{ display: 'none' }}
              onClick={(e) => {
                e.preventDefault()
                handleSubmitValue()
              }}
            />
            <ClearButton
              isError={!!(status === 'error' || inputError)}
              css={{
                opacity: 0.5,
              }}
              onClick={(e) => {
                e.preventDefault()
                setIsUpdatingField(false)
                setValue(fieldValueName, previousValue.current || '')
                trigger(fieldValueName)
              }}
              type="button"
            >
              <XCircleIcon />
            </ClearButton>
          </InputIconDiv>
        )}
        {column.icon && (
          <InputIconDiv textAlign={textAlign}>
            <Button
              type="submit"
              css={{ display: 'none' }}
              onClick={(e) => {
                e.preventDefault()
                handleSubmitValue()
              }}
            />
            <ClearButton
              css={{
                svg: {
                  height: 16,
                  width: 16,
                },
              }}
              isError={!!(status === 'error' || inputError)}
              type="button"
              onClick={(e) => {
                e.preventDefault()
                column.onIconClick?.(
                  index,
                  getValues(fieldValueName),
                  previousValue.current || '',
                )
              }}
            >
              {column.icon}
            </ClearButton>
          </InputIconDiv>
        )}
        {status === 'loading' && (
          <InputIconDiv textAlign={textAlign}>
            <Progress size="sm" />
          </InputIconDiv>
        )}
      </Div>
    </>
  )
}

const ClearButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 20,

  svg: {
    height: 20,
    width: 20,
    color: '$brand_primary_pure',
    marginLeft: '$2',
  },

  variants: {
    isError: {
      true: {
        svg: {
          color: '$status_danger_deep',
        },
      },
      false: {
        svg: {
          color: '$brand_primary_pure',
        },
      },
    },
  },
})

const TableInput = styled('input', {
  all: 'unset',
  background: 'transparent',
  fontFamily: '$default',

  '&:focus': {
    background: '$interface_light_up',
  },
})

const InputIconDiv = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    textAlign: {
      right: {
        left: 4,
      },
      left: {
        right: 4,
      },
    },
  },
})
