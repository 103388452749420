import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateAguinaldo } from '@/libs/react-query/types/aguinaldo'

dayjs.extend(utc)

const handleUpdateAguinaldo = async (payload: IUpdateAguinaldo) => {
  await apiV2.post<void>('/payroll/update-aguinaldo', payload)
  queryClient.invalidateQueries('aguinaldos')
  queryClient.invalidateQueries('aguinaldo-details')
}

export const useHandleUpdateAguinaldo = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateAguinaldo'],
    handleUpdateAguinaldo,
  )

  return mutation
}
