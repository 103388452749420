import { Button, Dialog, DialogContainer, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDocumentsStateSchema } from '../../../documents-state'
import { Div } from '@/components'
import { getFileLabelFromType, getFileTypeIcon } from '../../../documents-utils'
import {
  ArrowDownTrayIcon,
  ChevronRightIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline'
import { useDocumentsFiles } from '@/libs/react-query/hooks/useDocumentsFiles'

import dynamic from 'next/dynamic'
import { useDownload } from '@/contexts/DownloadContext'
import { DocumentXlsxViewer } from './DocumentXlsxViewer'
import { DocumentImageViewer } from './DocumentImageViewer'
import { Drawer, DrawerContainer } from '@/components/Drawer'

const DocumentPdfViewer = dynamic(() => import('./DocumentPdfViewer'), {
  ssr: false,
})

export const ViewUserDocumentModal = () => {
  const methods = useFormContext<IDocumentsStateSchema>()

  const documentView = useWatch({
    control: methods.control,
    name: 'management.documentView',
  })

  const isFullScreen = useWatch({
    control: methods.control,
    name: 'management.documentView.isFullscreen',
  })

  return (
    <DrawerContainer
      open={documentView.isViewDocumentOpen}
      onOpenChange={() => {
        methods.setValue('management.documentView.isViewDocumentOpen', false)
      }}
    >
      <Drawer
        dialog
        open={documentView.isViewDocumentOpen}
        onOpenChange={() => {
          methods.setValue('management.documentView.isViewDocumentOpen', false)
        }}
        overlayCss={{
          zIndex: 99,
        }}
        containerCss={{
          padding: 0,
          zIndex: 100,
        }}
        content={
          <Div
            css={{
              minWidth: isFullScreen ? '98vw' : '50vw',
              minHeight: isFullScreen ? '98vh' : undefined,
            }}
          >
            <DialogHeader />
            <DialogContent />
          </Div>
        }
      />
    </DrawerContainer>
  )
}

const DialogHeader = () => {
  const methods = useFormContext<IDocumentsStateSchema>()

  const documentView = useWatch({
    control: methods.control,
    name: 'management.documentView',
  })

  return (
    <Div
      css={{
        height: 38,
        background: '$interface_light_up',
        borderTopLeftRadius: '$sm',
        borderTopRightRadius: '$sm',
        display: 'flex',
        alignItems: 'center',
        gap: '$2',
        paddingLeft: '$2',
      }}
    >
      {getFileTypeIcon(documentView.documentType)}
      {documentView.breadcrumbPath.map((b, index) => (
        <>
          {!!index && (
            <Div
              css={{
                width: 16,
                height: 16,

                svg: {
                  width: 16,
                  height: 16,
                  color: '$interface_dark_up',
                },
              }}
            >
              <ChevronRightIcon />
            </Div>
          )}
          <Text
            variant="caption"
            css={{
              color:
                index === documentView.breadcrumbPath.length - 1
                  ? '$interface_dark_deep'
                  : '$interface_dark_up',
            }}
          >
            {b}
          </Text>
        </>
      ))}
    </Div>
  )
}

const DialogContent = () => {
  const methods = useFormContext<IDocumentsStateSchema>()

  const documentView = useWatch({
    control: methods.control,
    name: 'management.documentView',
  })

  const { data: documents } = useDocumentsFiles(
    documentView.documentId ? [documentView.documentId] : [],
  )

  const document = documents?.[0]
  const documentType = getFileLabelFromType(documentView.documentType)

  const { downloadFile } = useDownload()

  return (
    <Div>
      {documentType === 'PDF' ? (
        <DocumentPdfViewer url={document?.url} name={documentView?.name} />
      ) : documentType === 'XLSX' ? (
        <DocumentXlsxViewer url={document?.url} name={documentView?.name} />
      ) : documentType === 'IMAGE' ? (
        <DocumentImageViewer url={document?.url} name={documentView?.name} />
      ) : (
        <>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 500,
            }}
          >
            <Div
              css={{
                height: 80,
                width: 80,

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                background: '$brand_primary_pure',

                borderRadius: '$lg',
                svg: {
                  color: '$interface_light_pure',
                  height: 40,
                  width: 40,
                },
              }}
            >
              <DocumentIcon />
            </Div>
          </Div>
          <Div
            css={{
              background: '$interface_light_up',
              height: 48,

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '$4',
              paddingRight: '$4',
            }}
          >
            <Div />
            <Div>
              <Button
                onClick={() => {
                  const url = document?.url
                  if (url) {
                    downloadFile(url, document.name)
                  }
                }}
              >
                <ArrowDownTrayIcon />
                Descargar
              </Button>
            </Div>
          </Div>
        </>
      )}
    </Div>
  )
}
