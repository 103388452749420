import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateUserDocument } from '@/libs/react-query/types/documents'

const handleUpdateDocumentEntity = async (data: IUpdateUserDocument) => {
  try {
    const formData = new FormData()

    formData.append('id', data.id)
    formData.append('name', data.name)
    formData.append('folderId', data.folderId)

    data.files.forEach((file) => {
      formData.append(`file`, file, file.name)
    })

    formData.append('documentFiles[]', JSON.stringify(data.documentFiles))

    // formData.append('folderId', data.documentFiles)

    await apiV2.post<void>('/documents/update-user-document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          )
          data.onProgress?.(percentCompleted)
        }
      },
    })

    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}

export const useHandleUpdateDocumentEntity = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateDocumentEntity'],
    handleUpdateDocumentEntity,
  )

  return mutation
}
