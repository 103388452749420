import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'

const fertchPermissions = async () => {
  const response = await apiV2.get<string[]>('/permission/get-user-permissions')
  return response.data
}

export const useUserPermissions = () => {
  const { data: user } = useMe()

  const query = useQuery(
    ['user-permissions', user?.company_id],
    () => fertchPermissions(),
    {
      keepPreviousData: true,
      enabled: !!user && !!user.company_id,
      refetchOnWindowFocus: true,
      retry: false,
    },
  )
  return query
}
