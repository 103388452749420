import { Div } from '@/components'
import { TableHeadData } from './styles'
import { WorkersShifts } from '@/pages/turnos/components/SmartShiftManagement/types'
import { Header, Table } from '@tanstack/react-table'
import { Checkbox, Text, Tooltip } from '@punto-ui/react'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useFormContext, useWatch } from 'react-hook-form'
import { SmartShiftsFormSchema } from '@/pages/turnos/components/SmartShiftManagement/forms'
import React, { useMemo } from 'react'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { TagIcon } from '@heroicons/react/24/outline'
import { SHIFT_TABLE_CELL_WIDTH, SHIFT_TABLE_CELL_WORKER_WIDTH } from '../..'
import { IHoliday } from '@/libs/react-query/types/company'
import { useCan } from '@/hooks'

dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekdays: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ],
})

type ShiftHeaderProps = {
  column: Header<WorkersShifts, unknown>
  table: Table<WorkersShifts>
}

export const ShiftHeader = ({ column, table }: ShiftHeaderProps) => {
  const canManageShifts = useCan(['shifts.gestionar'])
  const { getValues, setValue, control } =
    useFormContext<SmartShiftsFormSchema>()

  const isWorkerColumn = column.id.includes('worker')
  const { shiftsSelection } = useWatch({
    control,
  })
  let allIndexedSelected = !!shiftsSelection?.length
  shiftsSelection?.forEach((userSelections) => {
    allIndexedSelected = allIndexedSelected && userSelections[column.index - 1]
  })

  const { data: company } = useMyCompany()

  const holidaysInDate = useMemo(() => {
    const holidaysInDate: IHoliday[] = []
    const dateToCheck = dayjs(column.id)
    company?.holidays
      .filter((holiday) => {
        return holiday.day_of_week === null
      })
      .forEach((holiday) => {
        let holidayDate = dayjs(holiday.date)

        if (holiday.should_repeat_every_year) {
          holidayDate = holidayDate.set('year', dateToCheck.year())
        }

        if (dateToCheck.isSame(holidayDate, 'day')) {
          holidaysInDate.push(holiday)
        }
      })

    company?.holidays
      .filter((holiday) => {
        return holiday.day_of_week !== null
      })
      .forEach((holiday) => {
        const isHolidayWeekday = dayjs(column.id).day() === holiday.day_of_week

        if (isHolidayWeekday) {
          holidaysInDate.push(holiday)
        }
      })
    return holidaysInDate
  }, [company?.holidays, column.id])

  if (isWorkerColumn) {
    return (
      <TableHeadData
        css={{
          width: SHIFT_TABLE_CELL_WORKER_WIDTH,
        }}
      >
        <Div
          as="label"
          css={{
            width: SHIFT_TABLE_CELL_WORKER_WIDTH,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '$2',
            padding: '$2',
          }}
        >
          {canManageShifts && (
            <Checkbox
              checked={allIndexedSelected}
              onCheckedChange={(check) => {
                const { shiftsSelection } = getValues()

                let newShiftsSelection: boolean[][] = []
                newShiftsSelection = shiftsSelection.map((userSelections) => {
                  return userSelections.map(() => {
                    return !!check
                  })
                })
                setValue('shiftsSelection', newShiftsSelection, {
                  shouldDirty: true,
                })
              }}
            />
          )}
          <Text css={{ color: '$interface_dark_down' }}>Selecionar todos</Text>
          {/* {cellUsage ? (
            <CellFilter
              selectedIds={selectedCellsIds}
              direction="left"
              defaultValues={false}
              callback={(cells) => {
                handleUpdateSmartShiftState({
                  selectedCellsIds: cells.map((c) => c.id),
                })
              }}
              policiesIds={permissionsArray.filter((p) => p.includes('shifts'))}
            />
          ) : (
            <ControlledWorkerSearchBar
              label={'Membros de la Politica'}
              name={'usersIds'}
              onValueChange={(s: string, users: string[]) => {
                handleUpdateSmartShiftState({
                  usersIds: users,
                })
              }}
              cellsIds={selectedCellsIds}
              switchable
              policiesIds={permissionsArray.filter((p) => p.includes('shifts'))}
            />
          )} */}
        </Div>
      </TableHeadData>
    )
  }

  const isToday = dayjs(column.id).isSame(dayjs(), 'day')

  return (
    <TableHeadData>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '$1',
          width: SHIFT_TABLE_CELL_WIDTH,
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            marginRight: '$2',
          }}
        >
          {canManageShifts && (
            <Checkbox
              onCheckedChange={() => {
                const { shiftsSelection } = getValues()

                let newShiftsSelection = []
                if (allIndexedSelected) {
                  newShiftsSelection = shiftsSelection.map((userSelections) => {
                    return userSelections.map((s, index) => {
                      return index === column.index - 1 ? false : s
                    })
                  })
                } else {
                  newShiftsSelection = shiftsSelection.map((userSelections) => {
                    return userSelections.map((s, index) => {
                      return index === column.index - 1 ? true : s
                    })
                  })
                }
                setValue('shiftsSelection', newShiftsSelection, {
                  shouldDirty: true,
                })
              }}
              checked={allIndexedSelected}
            />
          )}
          {!!holidaysInDate.length && (
            <Tooltip
              message={holidaysInDate.map((s) => s.name).join(', ')}
              arrow
              side="bottom"
            >
              <Div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  svg: { color: '$interface_light_pure' },
                  borderRadius: '$full',
                  height: 20,
                  width: 20,
                  backgroundColor: '$brand_primary_pure',
                }}
              >
                <TagIcon height={16} width={16} />
              </Div>
            </Tooltip>
          )}
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text
            variant="caption"
            css={{
              marginRight: '$2',
              color: isToday ? '$brand_primary_pure' : '$interface_dark_down',
              textAlign: 'center',
            }}
          >
            {dayjs(column.id).format('dddd')}
          </Text>
          <Text
            variant={'subtitle2'}
            css={{
              color: isToday ? '$brand_primary_pure' : '$interface_dark_down',
              textAlign: 'center',
            }}
          >
            {dayjs(column.id).format('DD/MM')}
          </Text>
        </Div>
      </Div>
    </TableHeadData>
  )
}

export const ShiftHeaderMemo = React.memo(ShiftHeader)
