import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PayrollPlaygroundTableType } from '../../types'
import { useHandleUpdatePayrollFields } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollFields'
import { formattedHourToMinutes } from '@/utils/date'
import { useHandleUpdatePayrollPayment } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollPayment'
import {
  debitPaymentsHeader,
  debitPaymentsHeaderRaw,
} from '@/libs/react-query/types'

export const usePayrollDiscounts = () => {
  const { control, getValues } = useFormContext<PayrollPlaygroundTableType>()
  const { mutateAsync: handleUpdatePayrollFields } =
    useHandleUpdatePayrollFields()
  const { mutateAsync: handleUpdatePayrollPayment } =
    useHandleUpdatePayrollPayment()

  const payrollId = useWatch({
    control,
    name: 'id',
  })

  const status = useWatch({
    control,
    name: 'status',
  })

  const columns: SmartColumnType[] = useMemo(() => {
    const isClosed = status === 'closed'
    return [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Subtotal',
        name: 'Subtotal',
        items: [
          {
            type: 'input-text',
            name: 'positiveSubtotal',
            inputType: 'money-guarani',
            header: '',
            width: 140,
            disabled: true,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Descuento Diurno',
        name: 'Descuento Diurno',
        items: [
          {
            type: 'input-text',
            name: 'totalMorningDiscountHours',
            inputType: 'long-hour',
            header: 'Horas',
            width: 80,
            disabled: isClosed,
            enableEmptySubmit: true,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                morningDiscountHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
          },
          {
            type: 'input-text',
            name: 'totalMorningDiscountHoursValue',
            inputType: 'money-guarani',
            header: 'Horas (G$)',
            width: 140,
            disabled: true,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Descuento Nocturno',
        name: 'Descuento Nocturno',
        items: [
          {
            type: 'input-text',
            name: 'totalNightDiscountHours',
            inputType: 'long-hour',
            header: 'Horas',
            width: 80,
            disabled: isClosed,
            enableEmptySubmit: true,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                nightDiscountHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
          },
          {
            type: 'input-text',
            name: 'totalNightDiscountHoursValue',
            inputType: 'money-guarani',
            header: 'Horas (G$)',
            width: 140,
            disabled: true,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Descuentos (pre IPS)',
        name: 'Descuentos (pre IPS)',
        items: [
          {
            type: 'input-text',
            name: 'discountsPreIPS',
            inputType: 'money-guarani',
            array: true,
            disabled: isClosed,
            length: debitPaymentsHeader.length,
            headers: debitPaymentsHeader,
            header: 'Descuentos (pre IPS)',
            width: 160,
            enableEmptySubmit: true,
            handleSubmitValue: async (value) => {
              const data = getValues()

              if (!data.data[value.index].userId || !data.id) {
                return
              }

              await handleUpdatePayrollPayment({
                paymentType: debitPaymentsHeaderRaw[value.subindex || 0],
                payroll_id: data.id,
                user_id: data.data[value.index].userId,
                should_include_in_ips: true,
                value:
                  !value.data.value && value.data.value !== 0
                    ? null
                    : Number(value.data.value),
              })
            },
          },
          {
            type: 'input-text',
            name: 'totalDiscount',
            inputType: 'money-guarani',
            header: 'Descu. Totales',
            width: 155,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'discountsSubtotal',
            inputType: 'money-guarani',
            header: 'Subtotal',
            width: 140,
            disabled: true,
          },
        ],
      },
    ] as SmartColumnType[]
  }, [
    status,
    handleUpdatePayrollPayment,
    getValues,
    payrollId,
    handleUpdatePayrollFields,
  ])

  return columns
}
