import { useCallback, useRef } from 'react'

export function useDebouncer() {
  const ref = useRef<any | null>(null)

  const debounce = useCallback((fn: () => any, delay?: number) => {
    ref.current = setTimeout(fn, delay || 500)

    return () => {
      clearTimeout(ref.current)
    }
  }, [])

  return { debounce }
}
