import { Box, Button, Dialog, DialogContainer, Text } from '@punto-ui/react'
import { Flex } from '@/components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useHandleDeletePolicy } from '@/libs/react-query/mutations/permissions'
import { queryClient } from '@/libs/react-query'

interface DeletePolicyModalProps {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  name: string
  id: string
}

export const DeletePolicyModal = ({
  isOpen,
  setIsOpen,
  name,
  id,
}: DeletePolicyModalProps) => {
  const { mutateAsync: deletePolicy, isLoading: isLoadingDeletePolicy } =
    useHandleDeletePolicy({
      onSuccess: () => {
        setIsOpen(false)
        queryClient.invalidateQueries('policies')
      },
    })

  return (
    <DialogContainer open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <Dialog
        content={
          <Box>
            <Flex
              css={{ flexDirection: 'column', marginBottom: '$4', gap: '$4' }}
            >
              <Text variant={'heading2'}>
                Deseja mesmo deletar a politica a seguir?
              </Text>
              <Text variant="heading4">{name}</Text>
            </Flex>

            <Flex css={{ flex: 1 }}>
              <Button
                css={{ flex: 1, marginRight: '$1' }}
                onClick={() => deletePolicy(id)}
                isLoading={isLoadingDeletePolicy}
                icon={<TrashIcon />}
              >
                Deletar
              </Button>
              <Button css={{ flex: 1, marginRight: '$1' }} variant="tertiary">
                Cancelar
              </Button>
            </Flex>
          </Box>
        }
      />
    </DialogContainer>
  )
}
