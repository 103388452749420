import { paymentOptions } from '@/libs/react-query/types'
import { IReportBuilderColumnType } from '../../type'
import {
  generateRandomHour,
  generateRandomNumberUnder10,
  generateRandomNumberUnder100,
  generateRandomNumberUnderBigMoney,
} from '@/utils/random'

export const singlePayrollColumns: IReportBuilderColumnType[] = [
  {
    type: 'payrolls',
    value: 'presence',
    label: 'Presencia',
    getRandomPlaceholder: generateRandomNumberUnder10,
    valueType: 'number' as const,
    subgroup: 'Status',
  },
  {
    type: 'payrolls',
    value: 'absence',
    label: 'Falta',
    getRandomPlaceholder: generateRandomNumberUnder10,
    valueType: 'number' as const,
    subgroup: 'Status',
  },
  {
    type: 'payrolls',
    value: 'movement',
    label: 'Movimiento',
    getRandomPlaceholder: generateRandomNumberUnder10,
    valueType: 'number' as const,
    subgroup: 'Status',
  },
  {
    type: 'payrolls',
    value: 'vacaciones',
    label: 'Vacaciones',
    getRandomPlaceholder: generateRandomNumberUnder10,
    valueType: 'number' as const,
    subgroup: 'Status',
  },
  {
    type: 'payrolls',
    value: 'holiday',
    label: 'Feriado',
    getRandomPlaceholder: generateRandomNumberUnder10,
    valueType: 'number' as const,
    subgroup: 'Status',
  },
  {
    type: 'payrolls',
    value: 'worked_days',
    label: 'Dias Natur. Pagos',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    subgroup: 'Totales Dias',
  },
  {
    type: 'payrolls',
    value: 'not_worked_days',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    label: 'Dias Natur. No Pagos',
    subgroup: 'Totales Dias',
  },
  {
    type: 'payrolls',
    value: 'vacation_days',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    label: 'D. Vacaciones',
    subgroup: 'Totales Dias',
  },
  {
    type: 'payrolls',
    value: 'paid_days',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    label: 'Contab. pagos',
    subgroup: 'Totales Dias',
  },
  {
    type: 'payrolls',
    value: 'not_paid_days',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    label: 'Contab. no pagos',
    subgroup: 'Totales Dias',
  },
  {
    type: 'payrolls',
    value: 'total_morning',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Diurno',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_night',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Nocturno',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_ordinary_morning',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Ord. Diur.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_ordinary_night',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Ord. Noc.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_extra',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Ext. Diur.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_night_extra',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Ext. Noc.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_holiday',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Fer. Diur.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_night_holiday',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Fer. Noc.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_discount',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Desc. Diur.',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_night_discount',
    label: 'T. Desc. Noc.',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_interval',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'T. Intervalo',
    subgroup: 'Totales Horas (HH:mm)',
  },
  {
    type: 'payrolls',
    value: 'total_night_value',
    label: 'T. Adicional Nocturno',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_ordinary_morning_value',
    label: 'T. Ord. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_ordinary_night_value',
    label: 'T. Ord. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_extra_value',
    label: 'T. Ext. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_night_extra_value',
    label: 'T. Ext. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_holiday_value',
    label: 'T. Fer. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_night_holiday_value',
    label: 'T. Fer. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_interval_value',
    label: 'T. Intervalo',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  ...paymentOptions.map((m) => ({
    type: 'payrolls' as const,
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Pagos (IPS) (G$)',
    value: `${m.value}_value_ips`,
    label: `${m.label} (IPS)`,
  })),
  {
    type: 'payrolls',
    value: 'total_children_bonification',
    label: 'Bonif. F. (Hijos)',
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
    subgroup: 'Totales Bonificaciones (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_children_bonification_value',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    label: 'Bonif. F. (G$)',
    subgroup: 'Totales Bonificaciones (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_discount_value',
    label: 'T. Desc. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Descuentos (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_night_discount_value',
    label: 'T. Desc. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Descuentos (G$)',
  },
  {
    type: 'payrolls',
    value: 'single_day_value',
    label: 'Importe Unitario',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_days_value',
    label: 'Valor Dias',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_vacation_value',
    label: 'Valor Vacaciones',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_days_ips_value',
    label: 'Valor IPS',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_positive_value',
    label: 'Valor Haberes',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_positive_ips_value',
    label: 'Valor Haberes IPS',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_negative_value',
    label: 'Valor Descuentos',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_negative_ips_value',
    label: 'Valor Descuentos IPS',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'subtotal',
    label: 'Subtotal',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'ips_base',
    label: 'Base Imponible IPS',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'ips16',
    label: 'IPS 16%',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'ips9',
    label: 'IPS 9%',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  {
    type: 'payrolls',
    value: 'totalIps',
    label: 'Total IPS',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
  ...paymentOptions.map((m) => ({
    type: 'payrolls' as const,
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Pagos (sin IPS) (G$)',
    value: `${m.value}_value`,
    label: `${m.label} (sin IPS)`,
  })),
  {
    type: 'payrolls',
    valueType: 'number' as const,
    label: '',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    value: 'salary_concepts',
    subgroup: 'Totales Conceptos (sin IPS) (G$)',
  },
  {
    type: 'payrolls',
    valueType: 'number' as const,
    label: '',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    value: 'salary_concepts_ips',
    subgroup: 'Totales Conceptos (IPS) (G$)',
  },
  {
    type: 'payrolls',
    value: 'finalSalary',
    label: 'Salario Neto',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Subtotales (G$)',
  },
]
