import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IAuthorizeHoursDTO } from './types'

const handleUpdateAuthorizedHours = async (props: IAuthorizeHoursDTO[]) => {
  const response = await apiV2.post<any>(
    '/punches/update-authorized-hours',
    props,
  )

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleUpdateAuthorizedHours = () => {
  const mutation = useCustomMutation(
    ['update-authorized-hours'],
    handleUpdateAuthorizedHours,
  )

  return mutation
}
