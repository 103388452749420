import { CompanyContext } from '@/contexts/CompanyContext'
import { useContext } from 'react'

export const useCompany = () => {
  const context = useContext(CompanyContext)

  if (!context) {
    throw new Error('useAuth must be used within an useCompany')
  }

  return context
}
