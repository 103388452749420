import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'

const handleDeleteDocumentEntity = async (id: string) => {
  try {
    await apiV2.post<void>('/documents/delete-user-document', {
      id,
    })
    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}

export const useHandleDeleteDocumentEntity = () => {
  const mutation = useCustomMutation(
    ['useHandleDeleteDocumentEntity'],
    handleDeleteDocumentEntity,
  )

  return mutation
}
