import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleToggleFavoriteReceipt = async (receiptId: string) => {
  await apiV2.post<any>(`/report-builder/toggle-favorite-receipt`, {
    receipt_id: receiptId,
  })
  queryClient.invalidateQueries('receipts')
}

export const useHandleToggleFavoriteReceipt = () => {
  const mutation = useCustomMutation(
    ['useHandleToggleFavoriteReceipt'],
    handleToggleFavoriteReceipt,
  )

  return mutation
}
