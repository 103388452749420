import { Div, Table as TableComponent } from '@/components'
import { usePayrolls } from '@/libs/react-query/hooks'
import { PayrollListing } from '@/libs/react-query/types/payroll'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { usePayrollListingColumns } from './column'
import { useEffect } from 'react'
import { useTabStore } from '@/store'
import { PlusIcon } from '@heroicons/react/24/outline'

export const PayrollList = () => {
  const { data: payrolls, isFetching: isFetchingPayrolls } = usePayrolls()
  const columns = usePayrollListingColumns()
  const { addActions, removeTab, changeTab, addTab } = useTabStore((state) => ({
    addActions: state.setActionsTab,
    removeTab: state.removeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const table = useReactTable({
    data: payrolls ?? [],
    columns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  useEffect(() => {
    addActions('payroll', [
      {
        label: 'Crear',
        icon: <PlusIcon />,
        action: () => {
          addTab('payroll', [
            {
              id: 'new',
              type: 'new',
            },
          ])
          changeTab('payroll', 'new')
        },
        variant: 'primary' as const,
      },
    ])
  }, [changeTab, removeTab, addActions, addTab])

  return (
    <Div
      css={{
        padding: 16,
        maxHeight: 'calc(100vh - 100px)',
        overflow: 'scroll',
      }}
    >
      <TableComponent<PayrollListing>
        numberOfRows={payrolls?.length ?? 0}
        table={table}
        isLoading={isFetchingPayrolls}
        withPagination={false}
      />
    </Div>
  )
}
