import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { SmartTableFormItemValue } from '@/components/SmartTable/types'
import { z } from 'zod'

const importPunchesContent = z.object({
  date: SmartTableFormItemValue,
  document: SmartTableFormItemValue,
  marcacion_id: SmartTableFormItemValue,
})

export const importPunchesSchema = z.object({
  // files
  uploadedFile: z.custom<File>().optional(),

  data: z.array(importPunchesContent),
})

export type ImportPunchesContent = z.infer<typeof importPunchesContent>
export type ImportPunchesSchema = z.infer<typeof importPunchesSchema>
export type ImportPunchesFields = Array<
  ControlledFieldProps<ImportPunchesSchema>
>
