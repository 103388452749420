import { Text, styled } from '@punto-ui/react'

export const Container = styled('div', {
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: '100%',
})

export const ListContainer = styled('div', {
  all: 'unset',
  overflow: 'scroll',
  zIndex: 1,

  position: 'relative',

  backgroundColor: '$interface_light_pure',

  display: 'flex',
  opacity: 1,
  flexDirection: 'column',
  alignItems: 'stretch',

  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
  border: 'solid 1px $interface_light_down',
  padding: '$2',
  transition: 'opacity 0.5s ease-in-out',

  '& button': {
    all: 'unset',
    padding: '$2',
    cursor: 'pointer',
    flex: 1,
    borderRadius: '$xs',

    [`& ${Text}`]: {
      color: '$interface_dark_down',
    },

    '&:hover': {
      [`& ${Text}`]: {
        color: '$brand_primary_pure',
      },

      backgroundColor: '$interface_light_up',
    },
  },
})

export const ListItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
})

export const SearchArea = styled('div', {
  background: '$interface_light_pure',
  border: 'solid 1px $interface_light_down',
  position: 'sticky',
  zIndex: 2,
  top: 0,
  marginTop: '$1',
})

export const CompleteSearchInputBar = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: 50,

  paddingLeft: 16,
  paddingRight: 16,

  '> svg': {
    height: 16,
    width: 16,
    minWidth: 16,
    minHeight: 16,

    marginRight: 10,
  },
})

export const SearchInput = styled('input', {
  all: 'unset',
  border: 0,
  background: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  width: '50%',
  flex: 1,
})

export const FiltersContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // width: '50%',

  '& > *': {
    marginLeft: '$2',
  },
})
