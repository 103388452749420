import React, { ReactNode, createContext, useContext, useState } from 'react'

type ShiftsContextData = {
  isFullscreen: boolean
  setIsFullscreen: (isFullscreen: boolean) => void
}

type ShiftsProviderProps = {
  children: ReactNode
}

const ShiftsContext = createContext({} as ShiftsContextData)

export function ShiftsProvider({ children }: ShiftsProviderProps) {
  const [isFullscreen, setIsFullscreen] = useState(false)
  return (
    <ShiftsContext.Provider
      value={{
        isFullscreen,
        setIsFullscreen,
      }}
    >
      {children}
    </ShiftsContext.Provider>
  )
}

export function useShiftsPage() {
  const context = useContext(ShiftsContext)

  return context
}
