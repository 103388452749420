import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const registerFormSchema = z.object({
  email: z
    .string({ required_error: 'Email inválido' })
    .min(6, { message: 'Email inválido' })
    .max(50, { message: 'Email inválido' })
    .transform((email) => email.toLowerCase().trim()),

  password: z
    .string({ required_error: 'Seña inválida' })
    .min(6, { message: 'Seña inválida' })
    .max(20, { message: 'Seña inválida' })
    .optional(),

  name: z
    .string({ required_error: 'Nombre inválido' })
    .min(6, { message: 'Nombre inválido' })
    .max(100, { message: 'Nombre inválido' }),

  businessName: z
    .string({ required_error: 'Nombre inválido' })
    .min(6, { message: 'Nombre inválido' })
    .max(100, { message: 'Nombre inválido' })
    .optional(),

  phone: z
    .string({ required_error: 'Teléfono inválido' })
    .min(6, { message: 'Teléfono inválido' })
    .max(50, { message: 'Teléfono inválido' }),

  ruc: z
    .string({ required_error: 'RUC inválido' })
    .min(6, { message: 'RUC inválido' })
    .max(20, { message: 'RUC inválido' }),

  ipsNumber: z
    .string({ required_error: 'Número IPS inválido' })
    .min(6, { message: 'Número IPS inválido' })
    .max(20, { message: 'Número IPS inválido' })
    .optional(),

  mtssNumber: z
    .string({ required_error: 'Número MTSS inválido' })
    .min(6, { message: 'Número MTSS inválido' })
    .max(20, { message: 'Número MTSS inválido' })
    .optional(),

  legalRepresentative: z
    .string({ required_error: 'Nombre inválido' })
    .min(6, { message: 'Nombre inválido' })
    .max(100, { message: 'Nombre inválido' })
    .optional(),

  contactName: z
    .string({ required_error: 'Nombre inválido' })
    .min(6, { message: 'Nombre inválido' })
    .max(100, { message: 'Nombre inválido' }),
})

export type RegisterFormData = z.infer<typeof registerFormSchema>
export type RegisterFormDataFields = Array<
  ControlledFieldProps<RegisterFormData>
>
