import { Button } from '@punto-ui/react'
import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import {
  ArrowsPointingInIcon,
  BookmarkIcon,
  CalendarDaysIcon,
  ListBulletIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Div } from '@/components'
import { ShiftsProvider, useShiftsPage } from './context'
import { SmartShiftManagement } from './components'
import { useCan } from '@/hooks'
import { ShiftsList } from './list/index.page'
import { useEffect, useMemo, useRef } from 'react'
import { useTabStore } from '@/store'
import CreateShift from './create/index.page'
import { useHandleShiftsActions } from './useHandleShiftsActions'

export default function Turnos({ onlyTabs = false }: { onlyTabs?: boolean }) {
  return (
    <ShiftsProvider>
      <Page onlyTabs={onlyTabs} />
    </ShiftsProvider>
  )
}

const Page = ({ onlyTabs = false }: { onlyTabs?: boolean }) => {
  const canUpdateShiftDays = useCan([
    'shifts.gestionar',
    'shifts.all',
    'shifts.view',
  ])

  const remove = useRef<any>(null)

  useEffect(() => {
    return () => {
      clearTimeout(remove.current)
    }
  }, [])

  const {
    shiftTabsLength,
    setActiveTab,
    removeTab,
    shiftTabs,
    activeTab,
    shiftsActions,
  } = useTabStore((state) => ({
    addTab: state.addTab,
    removeTab: state.removeTab,
    shiftTabs: state.shifts.tabs,
    shiftsActions: state.shifts.actions,
    shiftTabsLength: state.shifts.tabs.length,
    setActiveTab: state.setActiveTab,
    activeTab: state.shifts.activeTab,
  }))

  useHandleShiftsActions()

  const canSeeShifts = useCan(['shifts.view', 'shifts.gestionar', 'shifts.all'])

  const { isFullscreen, setIsFullscreen } = useShiftsPage()

  const tabsConfig = useMemo(() => {
    const tabs = []
    if (canSeeShifts) {
      tabs.push({
        icon: <CalendarDaysIcon />,
        label: 'Gerenciar Turnos',
        value: 'gerency',
        scrollable: true,
        component: (
          <Div
            css={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '$4',
            }}
          >
            <Div css={{ height: '88vh' }}>
              {canUpdateShiftDays ? (
                <SmartShiftManagement />
              ) : canSeeShifts ? (
                <ShiftsList />
              ) : null}
            </Div>
          </Div>
        ),
      })
    }

    if (canSeeShifts) {
      tabs.push({
        icon: <ListBulletIcon />,
        label: 'Turnos',
        value: 'shifts',
        component: <ShiftsList />,
      })
    }

    if (shiftTabsLength) {
      shiftTabs.forEach((tab) => {
        if (tab.type === 'create') {
          tabs.push({
            icon: <PlusIcon />,
            label: tab.label,
            closeable: true,
            handleClose: () => {
              removeTab('shifts', tab.id)
              remove.current = setTimeout(() => {
                setActiveTab('shifts', 'gerency')
              }, 1)
            },
            value: tab.id,
            component: <CreateShift />,
          })
        }
        if (tab.type === 'edit') {
          tabs.push({
            icon: <BookmarkIcon />,
            label: tab.label,
            closeable: true,
            handleClose: () => {
              removeTab('shifts', tab.id)
              remove.current = setTimeout(() => {
                setActiveTab('shifts', 'gerency')
              }, 1)
            },
            value: tab.id,
            component: <CreateShift />,
          })
        }
      })
    }
    return tabs
  }, [
    canSeeShifts,
    canUpdateShiftDays,
    shiftTabsLength,
    shiftTabs,
    removeTab,
    setActiveTab,
  ])

  if (isFullscreen) {
    return (
      <Div css={{ padding: '$4' }}>
        <Button
          css={{
            position: 'absolute',
            top: '$4',
            left: '$4',
            zIndex: 2,
          }}
          variant={'tertiary'}
          icon={<ArrowsPointingInIcon />}
          onClick={() => setIsFullscreen(false)}
        >
          Salir del Fullscreen
        </Button>
        <SmartShiftManagement />
      </Div>
    )
  }

  // const pageActions = []

  // pageActions.push({
  //   label: 'Fullscreen',
  //   variant: 'tertiary' as const,
  //   icon: <ArrowsPointingOutIcon />,
  //   action: () => setIsFullscreen(true),
  // })

  // if (canCreate) {
  //   pageActions.push({
  //     label: 'Crear',
  //     variant: 'primary' as const,
  //     icon: <PlusIcon />,
  //     action: () => {
  //       addTab('shifts', [
  //         {
  //           id: 'create',
  //           type: 'create',
  //           label: 'Crear Turno',
  //         },
  //       ])
  //       remove.current = setTimeout(() => {
  //         setActiveTab('shifts', 'create')
  //       }, 1)
  //     },
  //   })
  // }

  const pageActions = shiftsActions

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="gerency"
      actions={pageActions}
      tabs={tabsConfig}
      setTab={(id) => setActiveTab('shifts', id)}
      tab={activeTab || 'gerency'}
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
