import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdatePolicyPayload } from '../types'
import { queryClient } from '@/libs/react-query'

const handleUpdatePolicy = async (apiPayload: UpdatePolicyPayload) => {
  await apiV2.put<void>('/permission/update-policy', apiPayload)
  queryClient.invalidateQueries()
}

export const useHandleUpdatePolicy = () => {
  const mutation = useMutation(['useHandleUpdatePolicy'], handleUpdatePolicy)

  return mutation
}
