import dayjs from 'dayjs'
import { ImportWorkersContent } from './form'
import { fromDayMonthYearFormatToDateString } from '@/utils/date'
import { CreateWorkerPayloadType } from '@/libs/react-query/mutations/workers/useHandleCreate/parseStructure'
import { ICell } from '@/libs/react-query/types/organogram'
import { ICompanyV2 } from '@/libs/react-query/types/company'
import { CreateManyWorkersPayloadType } from '@/libs/react-query/mutations/workers/useHandleCreateMany/parseStructure'

export interface ImportedWorker {
  lastName: string
  document: string
  bank: string
  address: string
  phone: string
  city: string
  complement: string
  punchConfig: string
  paymentConfig: string
  vacationConfig: string
  bankAccount: string
  state: string
  neighborhood: string
  email: string
  civilState: string
  entranceDate: Date
  ipsEntranceDate: Date
  birthday: Date
  id: string
  externalIds: string[]
  nationality: string
  name: string
  number: string
  country: string
  base_salary: number
  gender: string
  cell: string
  position: string

  children: {
    name: string
    birthday: Date
    document: string
    shouldReceiveChildrenBonus: boolean
  }[]
}

export const parseImportedDocumentToWorkers = (
  data: any[],
): ImportedWorker[] => {
  const users = data.map((row) => {
    const childrenNumberKeys = [
      ...Object.keys(row)
        .filter((key) => key.includes('nombre_hijo'))
        .map((key) => key.replace('nombre_hijo', ''))
        .map((key) => Number(key)),
    ].sort()

    return {
      lastName: row.apellido ? String(row.apellido) : '',
      document: row.CI ? String(row.CI) : '',
      bank: row.banco ? String(row.banco) : '',
      address: row.calle ? String(row.calle) : '',
      phone: row.celular ? String(row.celular) : '',
      city: row.ciudad_domicilio ? String(row.ciudad_domicilio) : '',
      complement: row.complemento ? String(row.complemento) : '',
      punchConfig: row.config_marcacion ? String(row.config_marcacion) : '',
      paymentConfig: row.config_pago ? String(row.config_pago) : '',
      vacationConfig: row.config_vacaciones
        ? String(row.config_vacaciones)
        : '',
      bankAccount: row.cuenta_bancaria ? String(row.cuenta_bancaria) : '',
      state: row.departamento_domicilio
        ? String(row.departamento_domicilio)
        : '',
      neighborhood: row.direccion ? String(row.direccion) : '',
      email: row.email ? String(row.email) : '',
      civilState: row.estado_civil ? String(row.estado_civil) : '',
      entranceDate: row.fecha_ingresso,
      ipsEntranceDate: row.fecha_ingresso_ips,
      birthday: row.fecha_nascimiento,
      id: row.id ? String(row.id) : '',
      externalIds: row.id_externo ? `${row.id_externo}`.split(';') : [],
      nationality: row.nacionalidad ? String(row.nacionalidad) : '',
      name: row.nombre ? String(row.nombre) : '',
      number: row.numero ? String(row.numero) : '',
      country: row.pais_domicilio ? String(row.pais_domicilio) : '',
      base_salary: row.salario_base,
      gender: row.sexo ? String(row.sexo) : '',
      cell: row.sucursal ? String(row.sucursal) : '',
      position: row.cargo ? String(row.cargo) : '',
      children: childrenNumberKeys.map((keyNumber) => {
        const childName = row[`nombre_hijo ${keyNumber}`]
          ? String(row[`nombre_hijo ${keyNumber}`])
          : ''
        const birthdayDate = row[`nasc_hijo ${keyNumber}`]
        const childrenBonus = !!row[`bonif_hijo ${keyNumber}`]
        const childDocument = row[`ci_hijo ${keyNumber}`]
          ? String(row[`ci_hijo ${keyNumber}`])
          : ''

        return {
          birthday: birthdayDate,
          document: childDocument,
          name: childName,
          shouldReceiveChildrenBonus: childrenBonus,
        }
      }),
    }
  })

  return users
}

export const parseImportedWorkersToTable = (
  workers: ImportedWorker[],
): ImportWorkersContent[] => {
  const data: ImportWorkersContent[] = []

  workers
    .filter((w) => !!w.name)
    .forEach((worker) => {
      const children = worker.children.flatMap((child) => {
        return [
          {
            value: child.name,
          },
          {
            value: dayjs(child.birthday).format('DD/MM/YYYY'),
          },
          {
            value: child.document,
          },
          {
            value: child.shouldReceiveChildrenBonus ? 'Si' : 'No',
          },
        ]
      })

      data.push({
        userChildren: worker.children,
        externalIds: worker.externalIds.map((rawId) => {
          return {
            id: rawId,
            name: rawId,
            variant: 'neutral',
          }
        }),
        document: {
          value: worker.document,
          status: '',
        },
        email: {
          value: worker.email,
          status: '',
        },
        name: {
          value: worker.name,
          status: '',
        },
        surname: {
          value: worker.lastName,
          status: '',
        },
        phone: {
          value: worker.phone,
          status: '',
        },
        civilState: [
          {
            id: worker.civilState,
            name: worker.civilState,
            variant: 'neutral',
          },
        ],
        nationality: [
          {
            id: worker.nationality,
            name: worker.nationality,
            variant: 'neutral',
          },
        ],
        birthday: {
          value: dayjs(worker.birthday).format('DD/MM/YYYY'),
        },
        gender: [
          {
            id: worker.gender,
            name: worker.gender,
            variant: 'neutral',
          },
        ],
        bank: {
          value: worker.bank,
        },
        bankAccount: {
          value: worker.bankAccount,
        },
        country: {
          value: worker.country,
        },
        state: {
          value: worker.state,
        },
        city: {
          value: worker.city,
        },
        street: {
          value: worker.address,
        },
        number: {
          value: worker.number,
        },
        complement: {
          value: worker.complement,
        },
        neighborhood: {
          value: worker.neighborhood,
        },
        cell: {
          value: worker.cell,
        },
        entranceDate: {
          value: dayjs(worker.entranceDate).format('DD/MM/YYYY'),
        },
        ipsEntranceDate: {
          value: dayjs(worker.ipsEntranceDate).format('DD/MM/YYYY'),
        },
        baseSalary: {
          value: worker.base_salary,
        },
        punchConfigId: {
          value: worker.punchConfig,
        },
        paymentConfigId: {
          value: worker.paymentConfig,
        },
        vacationConfigId: {
          value: worker.vacationConfig,
        },
        position: {
          value: worker.position,
        },
        children,
      })
    })

  return data
}

export const parseWorkersCreationStructure = (
  props: ImportWorkersContent[],
  cells: ICell[],
  company: ICompanyV2 | undefined,
): CreateManyWorkersPayloadType[] => {
  const apiPayload: CreateManyWorkersPayloadType[] = []

  props.forEach((worker) => {
    const externalCodes: Array<{
      label: string
      value: string
    }> = []

    if (worker.externalIds.length > 0) {
      worker.externalIds.forEach((rawId) => {
        const [label, value] = rawId.id.split(':')
        externalCodes.push({
          label,
          value,
        })
      })
    }

    const cell = cells.find((cell) => cell.name === worker.cell.value)
    const paymentConfig = company?.paymentConfigGroups.find(
      (paymentConfig) => paymentConfig.name === worker.paymentConfigId.value,
    )
    const punchConfig = company?.punchConfigGroups.find(
      (punchConfig) => punchConfig.name === worker.punchConfigId.value,
    )

    const vacationConfig = company?.vacationConfigGroups.find(
      (conf) => conf.name === worker.vacationConfigId.value,
    )

    const userChildren: CreateWorkerPayloadType['user']['children'] =
      worker.userChildren.map((child) => {
        return {
          name: child.name,
          birthday: child.birthday
            ? fromDayMonthYearFormatToDateString(
                dayjs(child.birthday).toISOString(),
              )
            : '',
          document: child.document || '',
          shouldReceiveChildrenBonus: child.shouldReceiveChildrenBonus,
        }
      })

    apiPayload.push({
      user: {
        user: {
          document: worker.document.value as string,
          email: worker.email.value as string,

          firstName: worker.name.value as string,
          surname: worker.surname.value as string,

          name: `${worker.name.value} ${worker.surname.value}`,
          password: worker.document.value as string,
          birthday: fromDayMonthYearFormatToDateString(
            worker.birthday.value as string,
          ),
          civilStatus: worker.civilState[0].name,
          gender: worker.gender[0].name,
          nationality: worker.nationality[0].name,
          phone1: worker.phone.value as string,
          position: worker.position.value as string,
        },
        // TODO: Add children
        children: userChildren,
        contacts: [],
        address: {
          city: worker.city.value as string,
          complement: worker.complement.value as string,
          country: worker.country.value as string,
          latitude: '0',
          longitude: '0',
          neighborhood: worker.neighborhood.value as string,
          number: worker.number.value as string,
          state: worker.state.value as string,
          street: worker.street.value as string,
        },
        bankAccount: {
          bank_name: (worker.bank.value || '') as string,
          bank_account: (worker.bankAccount.value || '') as string,
          isMainAccount: true,
        },
      },
      companyUser: {
        payment_config_id: paymentConfig?.id || null,
        punch_config_ids: punchConfig?.id ? [punchConfig?.id] : [],
        vacation_config_id: vacationConfig?.id ? vacationConfig.id : null,
        aguinaldo_config_id: null,
        liquidation_config_id: null,
        salary_group_id: null,
        salary_raw_value: worker.baseSalary.value as number,
        hiring_date: fromDayMonthYearFormatToDateString(
          worker.entranceDate.value as string,
        ),
        ips_registration_date: fromDayMonthYearFormatToDateString(
          worker.ipsEntranceDate.value as string,
        ),
        externalCodes,
      },
      cell_id: cell?.id || cells?.[0]?.id,
    })
  })

  return apiPayload
}
