import { Button, Progress, Text, styled } from '@punto-ui/react'
import {
  ButtonsContainer,
  DomTable,
  TableContainer,
  TableData,
  TableHead,
} from './styles'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import {
  Column,
  Table as ReactTable,
  Row,
  flexRender,
} from '@tanstack/react-table'
import React, { useMemo, useState } from 'react'
import { AddShiftData } from '../../forms'
import { FieldPath, useFormContext, useFormState } from 'react-hook-form'
import { ControlledInput } from '@/components'
import { getDeepVal } from '@/utils/object'

export type TableProps = {
  table: ReactTable<AddShiftData>
  isLoading?: boolean
  withPagination?: boolean
  numberOfRows: number
  page: number
  pageLength: number
}

export const Table = ({
  table,
  isLoading,
  withPagination = true,
  numberOfRows,
  page,
  pageLength,
}: TableProps) => {
  if (!numberOfRows) {
    return null
  }

  return (
    <TableContainer>
      <DomTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((column) => {
                return (
                  <TableHead key={column.id} colSpan={column.colSpan}>
                    {typeof column.column.columnDef.header === 'string' ? (
                      <Text
                        variant="paragraph"
                        css={{
                          fontSize: '$sm',
                          textAlign: 'left',
                          fontWeight: 700,
                        }}
                      >
                        {flexRender(
                          column.column.columnDef.header,
                          column.getContext(),
                        )}
                      </Text>
                    ) : (
                      flexRender(
                        column.column.columnDef.header,
                        column.getContext(),
                      )
                    )}
                  </TableHead>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  if (
                    !cell.column.columnDef.meta?.isEditable &&
                    cell.column.columnDef.meta?.content !== 'component'
                  ) {
                    return (
                      <TableData key={cell.id}>
                        <Text
                          variant="paragraph"
                          css={{
                            textAlign: 'left',
                            color: '$interface_dark_deep',
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Text>
                      </TableData>
                    )
                  } else if (!cell.column.columnDef.meta?.isEditable) {
                    return (
                      <TableData key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableData>
                    )
                  } else {
                    return (
                      <TableData key={cell.id}>
                        <EditingCell
                          getValue={cell.getValue}
                          row={cell.row}
                          column={cell.column}
                          table={table}
                          page={page}
                          pageLength={pageLength}
                          isDisabled={cell.column.columnDef.meta?.isDisabled}
                        />
                      </TableData>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </DomTable>
      {withPagination && (
        <ButtonsContainer>
          <Button
            onClick={() => table.setPageIndex(0)}
            disabled={table.getState().pagination.pageIndex === 0}
          >
            <ChevronDoubleLeftIcon />
          </Button>
          <Button
            onClick={() => table.previousPage()}
            disabled={table.getState().pagination.pageIndex === 0}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon />
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon />
          </Button>
          <span>
            <Text as="strong">
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </Text>
          </span>
          {isLoading ? <Progress /> : null}
        </ButtonsContainer>
      )}
    </TableContainer>
  )
}

type EditingCellProps = {
  getValue: any
  row: Row<AddShiftData>
  column: Column<AddShiftData>
  table: ReactTable<AddShiftData>
  page: number
  pageLength: number
  isDisabled?: boolean
}

// Give our default column cell renderer editing superpowers!
const EditingCell = ({
  row,
  column,
  table,
  page,
  pageLength,
  isDisabled,
}: EditingCellProps) => {
  const {
    trigger,
    // formState: { errors },
  } = useFormContext()

  const { errors } = useFormState()
  // Using state to manage error because we are not using DS component
  // This way, we only want to show error when user has interacted with the input
  const [isError, setIsError] = useState(false)

  const name = useMemo(() => {
    const correctDayIndex = row.index + (page - 1) * pageLength
    let name =
      `shifts.${correctDayIndex}.${column.id}` as unknown as FieldPath<AddShiftData>

    if (column.id.includes('interval')) {
      const isStart = column.id.includes('start')
      const isEnd = column.id.includes('end')
      const isDuration = column.id.includes('duration')

      if (!isStart && !isEnd && !isDuration) {
        return 'no-cell'
      }

      const intervalIndex = Number(column.id.split('_')[2])
      // const fixedIndex = intervalIndex === 0 ? 0 : 1
      name = `shifts.${correctDayIndex}.intervals.${intervalIndex}.${
        isStart ? 'startTime' : isDuration ? 'duration' : 'endTime'
      }` as unknown as FieldPath<AddShiftData>
    }

    return name
  }, [column.id, page, pageLength, row.index])

  if (name === 'no-cell') {
    return null
  }

  const hasError = getDeepVal(errors, name)
  return (
    <ControlledInput
      name={name}
      inputType={'hour'}
      InputComponent={TableInput}
      onChange={(formattedValue) => {
        table.options.meta?.updateData(row.index, column.id, formattedValue)
      }}
      disabled={isDisabled}
      onBlur={async () => {
        const isError = await trigger(name)

        setIsError(!isError)
      }}
      css={{
        background: isError || hasError ? '$status_danger_up' : 'transparent',
      }}
    />
  )
}

const TableInput = styled('input', {
  all: 'unset',
  background: 'transparent',
  fontFamily: '$default',
  maxWidth: 75,

  borderRadius: 4,
  padding: 4,

  '&:focus': {
    background: '$interface_light_up',
  },
})
