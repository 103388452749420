import { useFormContext, useWatch } from 'react-hook-form'
import { ReceiptBuilderSchemaType } from '../../../type'
import { Div } from '@/components'
import { HeaderImportantText } from '../ReceiptFooter'
import { Text } from '@punto-ui/react'

export const TwoSignatureFooter = () => {
  const { control } = useFormContext<ReceiptBuilderSchemaType>()

  const city = useWatch({
    control,
    name: 'footerConfig.city',
  })

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 800,
        border: '2px solid $interface_dark_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '50%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '50%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>Saldo a cobrar</HeaderImportantText>
        </Div>
        <Div>
          <HeaderImportantText>0</HeaderImportantText>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '100%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '100%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>
            {city || 'Ciudad del Este'}, 4 de Marzo de 2024
          </HeaderImportantText>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '50%',
            height: 40,
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '50%',
            height: 40,
            paddingLeft: '$2',
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Text
            css={{
              fontWeight: 'bold',
            }}
          >
            ...
          </Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Text
            css={{
              fontWeight: 'bold',
            }}
          >
            ...
          </Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '> div:not(:last-child)': {
            width: '50%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '50%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '2px solid $interface_dark_pure',
          }}
        >
          <HeaderImportantText>Firma del trabajador</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            borderBottom: '2px solid $interface_dark_pure',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>Afonso Pena</HeaderImportantText>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '> div:not(:last-child)': {
            width: '50%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '50%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            borderRight: '2px solid $interface_dark_pure',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>.</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>123431</HeaderImportantText>
        </Div>
      </Div>
    </Div>
  )
}
