import { Button, Progress, Text, styled } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { RequestsInfosTableType } from '../../form'
import { Avatar, Div, Drawer, DrawerContainer } from '@/components'
import { InputDatePicker } from '@/components/InputDatePicker'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useAvailableCells, useWorkers } from '@/libs/react-query/hooks'
import { useUsersIdsInRequest } from './hooks/useUsersIdsInRequest'
import { useRequestTitle } from './hooks/useRequestTitle'
import { useRequestCategory } from './hooks/useRequestCategory'
import { useRequestDetails } from './hooks/useRequestDetails'
import { useRequestCalendar } from './hooks/useRequestCalendar'
import { useRequestType } from './hooks/useRequestType'

interface IRequestDrawerDetailProps {
  isOpen: boolean
  isLoading: boolean
  handleCloseDrawer: () => void
  handleAcceptRequest: () => void
  handleDenyRequest: () => void
}

export const RequestDrawerDetail = ({
  isOpen,
  handleCloseDrawer,
  handleAcceptRequest,
  handleDenyRequest,
  isLoading,
}: IRequestDrawerDetailProps) => {
  const { control } = useFormContext<RequestsInfosTableType>()

  const allRequests = useWatch({
    name: 'data',
    control,
  })

  const selectedRequest = allRequests.find((r) => r.checked.value)

  if (!selectedRequest) {
    return null
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{
              minWidth: '25vw',
              maxWidth: '80vw',
              maxHeight: '100vh',
              paddingBottom: '$10',
              overflowY: 'scroll',
            }}
          >
            <RequestDrawerContent selectedRequest={selectedRequest} />
            {selectedRequest.canAuthorizeRequest &&
              selectedRequest.rawStatus === 'pending' && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    isLoading={isLoading}
                    type="button"
                    css={{
                      width: '50%',
                      marginRight: '$2',
                    }}
                    variant="primary"
                    onClick={handleAcceptRequest}
                  >
                    Aprovar
                  </Button>
                  <Button
                    isLoading={isLoading}
                    type="button"
                    variant="primaryCritical"
                    onClick={handleDenyRequest}
                    css={{
                      marginLeft: '$2',
                      width: '50%',
                    }}
                  >
                    Recusar
                  </Button>
                </Div>
              )}
          </Div>
        }
      />
    </DrawerContainer>
  )
}

interface IRequestDrawerContent {
  selectedRequest: RequestsInfosTableType['data'][0]
}

const RequestDrawerContent = ({ selectedRequest }: IRequestDrawerContent) => {
  const { usersIdsInRequest } = useUsersIdsInRequest({
    selectedRequest,
  })
  const { requestTitle } = useRequestTitle({
    selectedRequest,
  })
  const { requestCategory } = useRequestCategory({
    selectedRequest,
  })
  const { requestDetails } = useRequestDetails({
    selectedRequest,
  })
  const { calendarData } = useRequestCalendar({
    selectedRequest,
  })
  const { requestType } = useRequestType({
    selectedRequest,
  })

  const requestPermissions = permissionsArray.filter((p) =>
    p.includes('requests'),
  )

  const { data: availableCells } = useAvailableCells(requestPermissions)

  const { data: workers, isFetching: isFetchingWorker } = useWorkers({
    page: 0,
    pageSize: 10000,
    cellsIds: usersIdsInRequest.length ? availableCells?.map((c) => c.id) : [],
    policiesIds: requestPermissions,
    usersIdsFilter: usersIdsInRequest,
  })

  return (
    <>
      <Text
        variant="heading2"
        css={{
          color: '$brand_primary_pure',
          marginBottom: '$4',
        }}
      >
        Solicitación de {requestType}
      </Text>
      {requestTitle && (
        <ItemContainer>
          <ItemTitle variant={'heading3'}>Titulo</ItemTitle>
          <ItemDescription variant={'paragraph'}>
            {requestTitle}
          </ItemDescription>
        </ItemContainer>
      )}
      {requestCategory && (
        <ItemContainer>
          <ItemTitle variant={'heading3'}>Categoria</ItemTitle>
          <ItemDescription variant={'paragraph'}>
            {requestCategory}
          </ItemDescription>
        </ItemContainer>
      )}
      <ItemContainer>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '$2',
          }}
        >
          <ItemTitle
            css={{ margin: 0, marginRight: '$4' }}
            variant={'heading3'}
          >
            Colaboradores
          </ItemTitle>
          {isFetchingWorker && <Progress />}
        </Div>
        <Div
          css={{
            maxHeight: 150,
            overflowY: 'scroll',
          }}
        >
          {workers?.data.map((w) => (
            <Div
              key={w.id}
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$2',
                padding: '$2',
                border: 'solid 1px',
                borderColor: '$brand_primary_up',
                borderRadius: '$md',
                marginBottom: '$2',
                maxWidth: 300,
              }}
            >
              <Avatar src={w.photo_url || ''} alt="" height={16} width={16} />
              <Text
                variant={'caption'}
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '$interface_dark_down',
                }}
              >
                {w.name}
              </Text>
            </Div>
          ))}
        </Div>
      </ItemContainer>
      {calendarData && (
        <ItemContainer>
          <ItemTitle variant={'heading3'}>Datas</ItemTitle>
          <Div css={{ minHeight: 300 }}>
            <InputDatePicker
              maxDates={0}
              disabled
              label={''}
              stayOpen
              onChange={() => null}
              hideInput
              {...calendarData}
            />
          </Div>
        </ItemContainer>
      )}
      {!!requestDetails.length && (
        <ItemContainer>
          <ItemTitle variant={'heading3'}>Detalhes</ItemTitle>
          {requestDetails.map((d) => (
            <ItemDescription key={d} variant={'paragraph'}>
              {d}
            </ItemDescription>
          ))}
        </ItemContainer>
      )}
    </>
  )
}

const ItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$4',
})

const ItemTitle = styled(Text, {
  color: '$brand_primary_pure',
  marginBottom: '$2',
})

const ItemDescription = styled(Text, {
  color: '$interface_dark_down',
  marginBottom: '$2',
})
