import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IWorkerUser, RehireWorkerDTO } from '@/libs/react-query/types'
import { queryClient } from '@/libs/react-query'

const handleRehireWorker = async (data: RehireWorkerDTO) => {
  const response = await apiV2.post<IWorkerUser>('/user/rehire', data)

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleRehireWorker = () => {
  const mutation = useMutation(['useHandleRehireWorker'], handleRehireWorker)

  return mutation
}
