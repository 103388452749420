import { z } from 'zod'

export const auditStateSchema = z.object({
  auditList: z.object({
    name: z.string(),
    cellsIds: z.array(z.string()),
    page: z.number(),
    perPage: z.number(),
    type: z.string().array(),
  }),
})

export type IAuditStateSchema = z.infer<typeof auditStateSchema>

export const INITIAL_AUDIT_STATE: IAuditStateSchema = {
  auditList: {
    name: '',
    cellsIds: [],
    page: 0,
    perPage: 20,
    type: [],
  },
}
