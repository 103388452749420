import { Div } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReportBuilderSchemaType } from '../../type'
import { ConfigurationForm } from './ConfigurationForm'
import { ColumnSelector } from './ColumnSelector'
import { FilterCreator } from './FilterCreator'

export const ReportBuilderForm = () => {
  const { control } = useFormContext<ReportBuilderSchemaType>()

  const formStep = useWatch({
    control,
    name: 'currentStep',
  })

  return (
    <Div
      css={{
        borderBottomLeftRadius: '$lg',
        width: '30%',
        minWidth: 350,
        // padding: '$4',
        background: '$interface_light_pure',
        overflowY: 'auto',
      }}
    >
      {formStep === 0 && <ConfigurationForm />}
      {/* {formStep === 1 && <RowSelector />} */}
      {formStep === 1 && <ColumnSelector />}
      {formStep === 2 && <FilterCreator />}
    </Div>
  )
}
