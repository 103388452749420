import { ContextualMenuLayout, Div, FormGroup } from '@/components'
import { Button, Divider, Tabs, Tag, Text } from '@punto-ui/react'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import {
  FormProvider,
  UseFormReturn,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { formatMinutes, formattedHourToMinutes } from '@/utils/date'
import { v4 as uuid } from 'uuid'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePunchSummary } from '@/pages/marcaciones/context'
import {
  EditTotalHoursData,
  HourDetailComponentProps,
  TotalPunchInfos,
  editTotalHoursSchema,
} from './types'
import {
  PunchesInfosContentType,
  PunchesInfosTableType,
} from '@/pages/marcaciones/types'
import { DraggableParams } from '@/components/SmartTable/types'
import { useHandleUpdateAuthorizedHours } from '@/libs/react-query/mutations/punchclock/useHandleUpdateAuthorizedHours'

export const TotalPunchInfosMenu = ({
  index,
}: DraggableParams<PunchesInfosContentType>) => {
  const { control } = useFormContext<PunchesInfosTableType>()

  const [allRows] = useWatch({
    control,
    name: ['data', 'date'],
  })

  const data = useMemo(() => allRows[index], [allRows, index])
  const selectedDate = data.date.metadata.date

  const punch: TotalPunchInfos = useMemo(
    () => ({
      name: `${data.name.value} ${data.surname.value}`,
      userId: data.userId,
      details: data.details,
      hours: {
        dailyDiscount: data.morningDiscountHours.value as string,
        dailyExtra: data.morningExtraHours.value as string,
        dailyHoliday: data.morningHolidayHours.value as string,
        dailyOrdinary: data.morningHours.value as string,
        nightDiscount: data.nightDiscountHours.value as string,
        nightExtra: data.nightExtraHours.value as string,
        nightHoliday: data.nightHolidayHours.value as string,
        nightOrdinary: data.nightHours.value as string,
      },
      saved: {
        dailyDiscount: data.savedHours.morningDiscountHours,
        dailyExtra: data.savedHours.morningExtraHours,
        dailyHoliday: data.savedHours.morningHolidayHours,
        dailyOrdinary: data.savedHours.morningHours,
        nightDiscount: data.savedHours.nightDiscountHours,
        nightExtra: data.savedHours.nightExtraHours,
        nightHoliday: data.savedHours.nightHolidayHours,
        nightOrdinary: data.savedHours.nightHours,
      },
    }),
    [data],
  )

  const methods = useForm<EditTotalHoursData>({
    resolver: zodResolver(editTotalHoursSchema),
    defaultValues: {
      totalHours: {
        morningDiscountHours:
          punch.saved.dailyDiscount !== null
            ? formatMinutes(punch.saved.dailyDiscount * 60)
            : '',
        morningExtraHours:
          punch.saved.dailyExtra !== null
            ? formatMinutes(punch.saved.dailyExtra * 60)
            : '',
        morningHolidayHours:
          punch.saved.dailyHoliday !== null
            ? formatMinutes(punch.saved.dailyHoliday * 60)
            : '',
        morningHours:
          punch.saved.dailyOrdinary !== null
            ? formatMinutes(punch.saved.dailyOrdinary * 60)
            : '',
        nightDiscountHours:
          punch.saved.nightDiscount !== null
            ? formatMinutes(punch.saved.nightDiscount * 60)
            : '',
        nightExtraHours:
          punch.saved.nightExtra !== null
            ? formatMinutes(punch.saved.nightExtra * 60)
            : '',
        nightHolidayHours:
          punch.saved.nightHoliday !== null
            ? formatMinutes(punch.saved.nightHoliday * 60)
            : '',
        nightHours:
          punch.saved.nightOrdinary !== null
            ? formatMinutes(punch.saved.nightOrdinary * 60)
            : '',
      },
      authorizedHours: {
        morningDiscountHours:
          data.authorizedHours.morningDiscountHours === null
            ? null
            : formatMinutes(data.authorizedHours.morningDiscountHours * 60),
        morningExtraHours:
          data.authorizedHours.morningExtraHours === null
            ? null
            : formatMinutes(data.authorizedHours.morningExtraHours * 60),
        morningHolidayHours:
          data.authorizedHours.morningHolidayHours === null
            ? null
            : formatMinutes(data.authorizedHours.morningHolidayHours * 60),
        nightDiscountHours:
          data.authorizedHours.nightDiscountHours === null
            ? null
            : formatMinutes(data.authorizedHours.nightDiscountHours * 60),
        nightExtraHours:
          data.authorizedHours.nightExtraHours === null
            ? null
            : formatMinutes(data.authorizedHours.nightExtraHours * 60),
        nightHolidayHours:
          data.authorizedHours.nightHolidayHours === null
            ? null
            : formatMinutes(data.authorizedHours.nightHolidayHours * 60),
        nightHours:
          data.authorizedHours.nightHours === null
            ? null
            : formatMinutes(data.authorizedHours.nightHours * 60),
      },
    },
  })

  const tabs = [
    {
      label: 'Editar',
      content: (
        <EditTotalHours
          hours={punch.hours}
          methods={methods}
          selectedDate={selectedDate}
          userId={punch.userId}
        />
      ),
    },
    {
      label: 'Detalhes',
      content: <Details punch={punch} />,
    },
    {
      label: 'Auditoria',
      content: (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '$4',
            paddingBottom: '$4',
          }}
        >
          <Tag>En breve</Tag>
        </Div>
      ),
    },
  ]

  if (Object.values(data.authorizedHours).some((value) => value !== null)) {
    tabs.splice(1, 0, {
      label: 'Autorizaciones',
      content: (
        <AuthorizedHours
          methods={methods}
          selectedDate={selectedDate}
          userId={punch.userId}
          hours={punch.hours}
          authorizations={data.authorizedHours}
        />
      ),
    })
  }

  return (
    <ContextualMenuLayout options={[]}>
      <FormProvider {...methods}>
        <Div
          css={{
            minHeight: 640,
            minWidth: 500,
            // maxHeight: 550,
            overflow: 'auto',
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '$4',
            }}
          >
            <Div css={{ padding: '$4' }}>
              <Text variant={'subtitle2'}>
                {dayjs(selectedDate).format('DD/MM/YYYY')}
              </Text>
              <Text
                variant="description"
                css={{ marginTop: '$2', color: '$interface_dark_down' }}
              >
                Horas totales de {punch.name}
              </Text>
            </Div>
          </Div>
          <Tabs tabs={tabs} />
          {/* <Div css={{ marginTop: '$4', marginBottom: '$4' }}>
            <Divider
              css={{
                height: 1,
                width: '100%',
                background: '$interface_light_down',
              }}
            />
          </Div> */}
          {/* <Div css={{ paddingLeft: '$4', paddingRight: '$4' }}>
            <TextArea
              placeholder="Escriba una justificativa para la creación..."
              disabled
              css={{
                width: '100%',
                borderWidth: 1,
                borderColor: '$interface_dark_up',
              }}
            />
          </Div> */}
          {/* <ControlledFileInput /> */}
        </Div>
      </FormProvider>
    </ContextualMenuLayout>
  )
}

interface EditTotalHoursProps {
  hours: {
    dailyDiscount: string
    dailyExtra: string
    dailyHoliday: string
    dailyOrdinary: string
    nightDiscount: string
    nightExtra: string
    nightHoliday: string
    nightOrdinary: string
  }
  selectedDate: string
  methods: UseFormReturn<EditTotalHoursData>
  userId: string
}

const EditTotalHours = ({
  hours,
  selectedDate,
  methods,
  userId,
}: EditTotalHoursProps) => {
  const { handleUpdateTotalHours, isLoading } = usePunchSummary()

  const handleSubmitUpdateTotalHours = async (data: EditTotalHoursData) => {
    await handleUpdateTotalHours({
      date: dayjs(selectedDate).toISOString(),
      morningDiscountHours:
        data.totalHours.morningDiscountHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.morningDiscountHours) / 60,
      morningExtraHours:
        data.totalHours.morningExtraHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.morningExtraHours) / 60,
      morningHolidayHours:
        data.totalHours.morningHolidayHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.morningHolidayHours) / 60,
      morningHours:
        data.totalHours.morningHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.morningHours) / 60,
      nightDiscountHours:
        data.totalHours.nightDiscountHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.nightDiscountHours) / 60,
      nightExtraHours:
        data.totalHours.nightExtraHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.nightExtraHours) / 60,
      nightHolidayHours:
        data.totalHours.nightHolidayHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.nightHolidayHours) / 60,
      nightHours:
        data.totalHours.nightHours?.length === 0
          ? null
          : formattedHourToMinutes(data.totalHours.nightHours) / 60,
      users_ids: [userId],
    })
  }

  const form = useMemo(() => {
    const value: Array<ControlledFieldProps<EditTotalHoursData>> = [
      {
        _type: 'input',
        label: 'Horas ordinárias diurnas',
        name: 'totalHours.morningHours',
        inputType: 'hour',
        disclaimer: `Resultado calculo automático: ${hours.dailyOrdinary}`,
      },
      {
        _type: 'input',
        label: 'Horas ordinárias nocturnas',
        name: 'totalHours.nightHours',
        disclaimer: `Resultado calculo automático: ${hours.nightOrdinary}`,
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Horas extras diurnas',
        name: 'totalHours.morningExtraHours',
        disclaimer: `Resultado calculo automático: ${hours.dailyExtra}`,
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Horas extras nocturas',
        disclaimer: `Resultado calculo automático: ${hours.nightExtra}`,
        name: 'totalHours.nightExtraHours',
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Horas feriado diurnas',
        name: 'totalHours.morningHolidayHours',
        disclaimer: `Resultado calculo automático: ${hours.dailyHoliday}`,
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Horas feriado nocturas',
        name: 'totalHours.nightHolidayHours',
        disclaimer: `Resultado calculo automático: ${hours.nightHoliday}`,
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Horas descuento diurnas',
        name: 'totalHours.morningDiscountHours',
        disclaimer: `Resultado calculo automático: ${hours.dailyDiscount}`,
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Horas descuento nocturas',
        name: 'totalHours.nightDiscountHours',
        disclaimer: `Resultado calculo automático: ${hours.nightDiscount}`,
        inputType: 'hour',
      },
    ]

    return value
  }, [hours])

  return (
    <Div css={{ maxWidth: 500 }}>
      <Div
        css={{
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <Text
          variant="paragraph"
          css={{ fontWeight: '$bold', paddingTop: '$2' }}
        >
          Horas Totales Editadas
        </Text>
        <FormGroup css={{ marginTop: '$2' }} items={form} cols={2} />
      </Div>
      <Div css={{ marginTop: '$4', marginBottom: '$4' }}>
        <Divider
          css={{
            height: 1,
            width: '100%',
            background: '$interface_light_down',
          }}
        />
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingRight: '$4',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button variant={'secondary'} type="button">
          Cancelar
        </Button>
        <Button
          variant={'primary'}
          type="button"
          onClick={methods.handleSubmit(handleSubmitUpdateTotalHours)}
          isLoading={isLoading}
        >
          Guardar
        </Button>
      </Div>
    </Div>
  )
}

export const Details = ({ punch }: { punch: TotalPunchInfos }) => {
  return (
    <Div css={{ display: 'flex', flexWrap: 'wrap', maxWidth: 400 }}>
      <HourDetailComponent
        label="Horas Ordinárias"
        details={punch.details?.ordinaryHours}
      />
      <HourDetailComponent
        label="Horas Descontadas"
        details={punch.details?.discountHours}
      />

      <HourDetailComponent
        label="Horas Intervalo"
        details={punch.details?.intervalHours}
      />
      <HourDetailComponent
        label="Horas Extras"
        details={punch.details?.extraHours}
      />
      <HourDetailComponent
        label="Horas Feriado"
        details={punch.details?.holidayHours}
      />
      <HourDetailComponent
        label="Horas por Movim."
        details={punch.details?.movementsHours}
      />
    </Div>
  )
}

const HourDetailComponent = ({ details, label }: HourDetailComponentProps) => {
  const detailsStrings = useMemo(() => {
    return details.map((hour) => {
      const hourLabel = `${dayjs(hour.startTime).format('HH:mm')} -${' '}
      ${dayjs(hour.endTime).format('HH:mm')}`

      return {
        label: hourLabel,
        id: uuid(),
      }
    })
  }, [details])

  return (
    <Div css={{ padding: '$4', width: '48%' }}>
      <Text variant="subtitle2">{label}</Text>
      {detailsStrings.map((hour) => {
        return <Text key={hour.id}>{hour.label}</Text>
      })}
    </Div>
  )
}

interface AuthorizedHoursProps {
  hours: {
    dailyDiscount: string
    dailyExtra: string
    dailyHoliday: string
    dailyOrdinary: string
    nightDiscount: string
    nightExtra: string
    nightHoliday: string
    nightOrdinary: string
  }
  authorizations: {
    morningDiscountHours: number | null
    morningExtraHours: number | null
    morningHolidayHours: number | null
    nightDiscountHours: number | null
    nightExtraHours: number | null
    nightHolidayHours: number | null
    nightHours: number | null
  }
  methods: UseFormReturn<EditTotalHoursData>
  selectedDate: string
  userId: string
}

const AuthorizedHours = ({
  hours,
  authorizations,
  methods,
  selectedDate,
  userId,
}: AuthorizedHoursProps) => {
  const {
    mutateAsync: handleUpdateAuthorizedHours,
    isLoading: isLoadingUpdateAuthorizedHours,
  } = useHandleUpdateAuthorizedHours()
  const { isLoading } = usePunchSummary()

  const form = useMemo(() => {
    const value: Array<ControlledFieldProps<EditTotalHoursData>> = [
      {
        _type: 'input',
        label: 'Autorización ordinárias nocturnas',
        name: 'authorizedHours.nightHours',
        disclaimer: `Horas realizadas: ${hours.nightOrdinary}`,
        inputType: 'hour',
        disabled: authorizations.nightHours === null,
      },
      {
        _type: 'input',
        label: 'Autorización extras diurnas',
        name: 'authorizedHours.morningExtraHours',
        disclaimer: `Horas realizadas: ${hours.dailyExtra}`,
        inputType: 'hour',
        disabled: authorizations.morningExtraHours === null,
      },
      {
        _type: 'input',
        label: 'Autorización extras nocturas',
        disclaimer: `Horas realizadas: ${hours.nightExtra}`,
        name: 'authorizedHours.nightExtraHours',
        inputType: 'hour',
        disabled: authorizations.nightExtraHours === null,
      },
      {
        _type: 'input',
        label: 'Autorización feriado diurna',
        name: 'authorizedHours.morningHolidayHours',
        disclaimer: `Horas realizadas: ${hours.dailyHoliday}`,
        inputType: 'hour',
        disabled: authorizations.morningHolidayHours === null,
      },
      {
        _type: 'input',
        label: 'Autorización feriado nocturno',
        name: 'authorizedHours.nightHolidayHours',
        disclaimer: `Horas realizadas: ${hours.nightHoliday}`,
        inputType: 'hour',
        disabled: authorizations.nightHolidayHours === null,
      },
      {
        _type: 'input',
        label: 'Autorización descuento diurno',
        name: 'authorizedHours.morningDiscountHours',
        disclaimer: `Horas realizadas: ${hours.dailyDiscount}`,
        inputType: 'hour',
        disabled: authorizations.morningDiscountHours === null,
      },
      {
        _type: 'input',
        label: 'Autorización descuento nocturno',
        name: 'authorizedHours.nightDiscountHours',
        disclaimer: `Resultado calculo automático: ${hours.nightDiscount}`,
        inputType: 'hour',
        disabled: authorizations.nightDiscountHours === null,
      },
    ]

    return value
  }, [authorizations, hours])

  const handleSubmitAuthorizedHours = () => {
    const authorization = methods.getValues('authorizedHours')
    handleUpdateAuthorizedHours([
      {
        date: dayjs(selectedDate).toISOString(),
        user_id: userId,
        morningDiscountHours:
          authorization.morningDiscountHours === null
            ? undefined
            : formattedHourToMinutes(authorization.morningDiscountHours) / 60,
        morningExtraHours:
          authorization.morningExtraHours === null
            ? undefined
            : formattedHourToMinutes(authorization.morningExtraHours) / 60,
        morningHolidayHours:
          authorization.morningHolidayHours === null
            ? undefined
            : formattedHourToMinutes(authorization.morningHolidayHours) / 60,
        nightDiscountHours:
          authorization.nightDiscountHours === null
            ? undefined
            : formattedHourToMinutes(authorization.nightDiscountHours) / 60,
        nightExtraHours:
          authorization.nightExtraHours === null
            ? undefined
            : formattedHourToMinutes(authorization.nightExtraHours) / 60,
        nightHolidayHours:
          authorization.nightHolidayHours === null
            ? undefined
            : formattedHourToMinutes(authorization.nightHolidayHours) / 60,
        nightHours:
          authorization.nightHours === null
            ? undefined
            : formattedHourToMinutes(authorization.nightHours) / 60,
      },
    ])
  }

  return (
    <Div css={{ maxWidth: 500 }}>
      <Div
        css={{
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <Text
          variant="paragraph"
          css={{ fontWeight: '$bold', paddingTop: '$2' }}
        >
          Horas Autorizadas
        </Text>
        <FormGroup css={{ marginTop: '$2' }} items={form} cols={2} />
      </Div>
      <Div css={{ marginTop: '$4', marginBottom: '$4' }}>
        <Divider
          css={{
            height: 1,
            width: '100%',
            background: '$interface_light_down',
          }}
        />
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingRight: '$4',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button variant={'secondary'} type="button">
          Cancelar
        </Button>
        <Button
          variant={'primary'}
          type="button"
          onClick={methods.handleSubmit(handleSubmitAuthorizedHours)}
          isLoading={isLoadingUpdateAuthorizedHours || isLoading}
        >
          Guardar
        </Button>
      </Div>
    </Div>
  )
}
