import { useMemo } from 'react'
import { ConfigFields } from '../../..'
import { useCan } from '@/hooks'

export const useTotemForm = () => {
  const canCreateTotem = useCan(['configurations.totems'])
  const form = useMemo(() => {
    const moneyForm: ConfigFields = [
      {
        _type: 'input',
        label: 'Nombre del Totem',
        name: 'totem.name',
        disabled: !canCreateTotem,
      },
      {
        _type: 'input',
        label: 'Login',
        disabled: !canCreateTotem,
        name: 'totem.login',
      },
      {
        _type: 'input',
        disabled: !canCreateTotem,
        label: 'Contraseña',
        name: 'totem.password',
      },
      {
        _type: 'checkbox',
        disabled: !canCreateTotem,
        label: 'Registro Facial Habilitado',
        name: 'totem.is_facial_registration_enabled',
      },
      {
        _type: 'checkbox',
        disabled: !canCreateTotem,
        label: 'Localización Obligatoria',
        name: 'totem.is_location_obligatory',
      },
      {
        _type: 'checkbox',
        disabled: !canCreateTotem,
        label: 'Face ID Activo Deshabilitado',
        name: 'totem.is_active_face_id_disabled',
      },
      {
        _type: 'checkbox',
        disabled: !canCreateTotem,
        label: 'Face ID con toque Deshabilitado',
        name: 'totem.is_touch_face_id_disabled',
      },
    ]

    return moneyForm
  }, [canCreateTotem])

  return {
    data: form,
  }
}
