import { Div } from '@/components/Div'
import { IPdfTemplate } from '@/libs/react-query/types/reports'
import { getPdfVariablesFromLayouts } from '../variable'
import { ControlledInput } from '@/components/Forms'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

export const CustomVariableStep = (props: { pdf?: IPdfTemplate }) => {
  const variables = getPdfVariablesFromLayouts(props.pdf?.layouts)

  const customVariables = variables.filter((variable) => {
    const variableHasDot = variable.includes('.')
    return !variableHasDot
  })

  return (
    <Div
      css={{
        maxHeight: 'calc(100vh - 400px)',
        paddingBottom: '$4',
        overflowY: 'auto',
      }}
    >
      {customVariables.map((variable, index) => {
        return (
          <Div
            key={variable}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '$4',

              gap: '$4',
            }}
          >
            <Div
              css={{
                flex: 1,
              }}
            >
              <ControlledInput
                name={`variables.${index}.name`}
                label={variable}
                disabled
              />
            </Div>
            <Div
              css={{
                height: 16,
                width: 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                svg: {
                  marginTop: 16,
                  color: '$interface_dark_deep',
                },
              }}
            >
              <ChevronRightIcon />
            </Div>
            <Div
              css={{
                flex: 1,
              }}
            >
              <ControlledInput
                name={`variables.${index}.value`}
                label={'Valor'}
              />
            </Div>
          </Div>
        )
      })}
    </Div>
  )
}
