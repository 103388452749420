import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateUserLiquidationConceptDTO } from '@/libs/react-query/types/company'

export const useUpdateUserLiquidationConcept = () => {
  const mutation = useCustomMutation(
    ['useUpdateUserLiquidationConcept'],
    handleUpdateUserLiquidationConcept,
  )

  return mutation
}

const handleUpdateUserLiquidationConcept = async (
  props: IUpdateUserLiquidationConceptDTO,
) => {
  try {
    await apiV2.post<void>('/company/update-user-liquidation-concept', props)

    queryClient.invalidateQueries('worker-liquidation')
  } catch (error) {
    console.log(error)
  }
}
