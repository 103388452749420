import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IAguinaldo } from '../../types/aguinaldo'

const fetchAguinaldos = async ({ cellsIds }: { cellsIds: string[] }) => {
  const response = await apiV2.get<IAguinaldo[]>('/payroll/list-aguinaldos', {
    params: {
      cellsIds: cellsIds.join(','),
    },
  })

  return response.data
}

export const useAguinaldos = ({ cellsIds }: { cellsIds: string[] }) => {
  return useQuery(
    ['aguinaldos', cellsIds],
    () => fetchAguinaldos({ cellsIds }),
    {
      enabled: !!cellsIds?.length,
    },
  )
}
