import { keyframes } from '@punto-ui/react'

export const disableAllAnimations = () => {
  return {
    animationDuration: '0s !important',
    '-webkit-animation-duration': '0s !important',
    transition:
      'background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important',
  }
}

export const defaultAppearingAnimationKeyframe = keyframes({
  '0%': { opacity: 0, transform: 'translateY(5%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translateY(0%) scale(1)' },
})
