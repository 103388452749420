import { Avatar } from '@/components'
import { Div } from '@/components/Div'
import { SmartColumnType } from '@/components/SmartTable/types'
import { useFormContext, useWatch } from 'react-hook-form'
import { ROW_HEIGHT } from '.'

interface InputAvatarProps {
  column: SmartColumnType
  fieldValueName: string
}

export const InputAvatar = ({ fieldValueName, column }: InputAvatarProps) => {
  const { control, getValues } = useFormContext()

  const avatar = useWatch({
    control,
    name: fieldValueName,
    defaultValue: getValues(fieldValueName),
  })

  return (
    <>
      <Div
        css={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: column.width ? column.width : undefined,
          height: ROW_HEIGHT,
        }}
      >
        {avatar && (
          <Avatar
            src={avatar?.url || ''}
            height={24}
            width={24}
            alt={avatar?.alt}
            unoptimized={true}
          />
        )}
      </Div>
    </>
  )
}
