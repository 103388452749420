import { styled, Text } from '@punto-ui/react'

export const MoveListContainer = styled('div', {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
})

export const MovesListContainer = styled('div', {
  margin: '$4',
  height: '100%',
})

export const StyledContentText = styled(Text, {
  color: '$interface_dark_deep',
  fontSize: '$sm',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
})

export const StyledHeadingText = styled(Text, {
  color: '$interface_dark_deep',
  fontWeight: 'bold',
  fontSize: '$sm',
  textAlign: 'left',
  padding: '$3 $2',
})
