import { Div, SmartTable } from '@/components'
import { Text } from '@punto-ui/react'
import { usePunchInDate } from '../punch-in-date'
import { useMemo } from 'react'
import { useOrdinaryHours } from './ordinary'
import { usePayrollDay } from '../usePayrollDay'
import dayjs from 'dayjs'
import { usePayrollPlaygroundStore } from '@/pages/planilla/payroll-playground/store'

export const HoursDayDetail = () => {
  const { columns: punchColumns, data: punchData } = usePunchInDate()
  const { morning: ordinaryMorning, night: ordinaryNight } = useOrdinaryHours({
    name: 'ordinaryHours',
  })
  const { morning: extraMorning, night: extraNight } = useOrdinaryHours({
    name: 'extraHours',
  })
  const { morning: discountMorning, night: discountNight } = useOrdinaryHours({
    name: 'discountHours',
  })
  const { morning: holidayMorning, night: holidayNight } = useOrdinaryHours({
    name: 'holidayHours',
  })
  const { morning: movementMorning, night: movementNight } = useOrdinaryHours({
    name: 'movementsHours',
  })
  const { data: payrollDay } = usePayrollDay()
  const { drawer } = usePayrollPlaygroundStore((state) => ({
    drawer: state.drawer.data,
  }))

  const styles = useMemo(() => {
    return {
      borderInLines: true,
      centered: true,
    }
  }, [])

  return (
    <Div
      css={{
        overflow: 'auto',
        paddingBottom: '$2',
        maxHeight: 'calc(100vh - 80px)',
      }}
    >
      <Div
        css={{
          paddingTop: '$4',
          paddingBottom: '$4',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            color: '$interface_dark_down',
            paddingLeft: '$4',
          }}
        >
          Resumen de {payrollDay?.schedule.worker.name} -{' '}
          {dayjs(drawer?.date).format('dddd')},{' '}
          {dayjs(drawer?.date).format('DD/MM/YYYY')}
        </Text>
        <Div
          css={{
            paddingLeft: '$4',
            paddingTop: '$4',
            paddingRight: '$4',
            paddingBottom: '$4',
            borderBottom: '1px solid',
            borderBottomColor: '$interface_light_down',
          }}
        >
          <Text
            css={{
              color: '$interface_dark_down',
              marginBottom: '$2',
            }}
            variant="description"
          >
            Turno Diurno
          </Text>
          <SmartTable
            columns={punchColumns}
            data={punchData}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingBottom: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            color: '$interface_dark_down',
            paddingLeft: '$4',
            marginBottom: '$4',
          }}
        >
          Ordinárias
        </Text>

        <Div
          css={{
            display: 'flex',
          }}
        >
          <SmartTable
            columns={ordinaryMorning.columns}
            data={ordinaryMorning.data}
            uncontrolled
            style={styles}
            noPadding
          />
          <SmartTable
            columns={ordinaryNight.columns}
            data={ordinaryNight.data}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingTop: '$4',
          paddingBottom: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            color: '$interface_dark_down',
            marginBottom: '$4',
            paddingLeft: '$4',
          }}
        >
          Extras
        </Text>
        <Div
          css={{
            display: 'flex',
          }}
        >
          <SmartTable
            columns={extraMorning.columns}
            data={extraMorning.data}
            uncontrolled
            style={styles}
            noPadding
          />
          <SmartTable
            columns={extraNight.columns}
            data={extraNight.data}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingTop: '$4',
          paddingBottom: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            color: '$interface_dark_down',
            marginBottom: '$4',
            paddingLeft: '$4',
          }}
        >
          Feriado
        </Text>
        <Div
          css={{
            display: 'flex',
          }}
        >
          <SmartTable
            columns={holidayMorning.columns}
            data={holidayMorning.data}
            uncontrolled
            style={styles}
            noPadding
          />
          <SmartTable
            columns={holidayNight.columns}
            data={holidayNight.data}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingTop: '$4',
          paddingBottom: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            color: '$interface_dark_down',
            marginBottom: '$4',
            paddingLeft: '$4',
          }}
        >
          Descuentos
        </Text>
        <Div
          css={{
            display: 'flex',
          }}
        >
          <SmartTable
            columns={discountMorning.columns}
            data={discountMorning.data}
            uncontrolled
            style={styles}
            noPadding
          />
          <SmartTable
            columns={discountNight.columns}
            data={discountNight.data}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
      <Div
        css={{
          paddingLeft: '$4',
          paddingTop: '$4',
          paddingBottom: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Text
          variant={'heading4'}
          css={{
            color: '$interface_dark_down',
            marginBottom: '$4',
            paddingLeft: '$4',
          }}
        >
          Movimientos
        </Text>
        <Div
          css={{
            display: 'flex',
          }}
        >
          <SmartTable
            columns={movementMorning.columns}
            data={movementMorning.data}
            uncontrolled
            style={styles}
            noPadding
          />
          <SmartTable
            columns={movementNight.columns}
            data={movementNight.data}
            uncontrolled
            style={styles}
            noPadding
          />
        </Div>
      </Div>
    </Div>
  )
}
