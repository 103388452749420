import { Div } from '@/components'
import { HeaderImportantText } from '../ReceiptFooter'
import { Text } from '@punto-ui/react'

export const LiquidationTwoSignatureFooter = () => {
  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 800,
        border: '2px solid $interface_dark_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '50%',
            paddingLeft: '$2',
            borderRight: '2px solid $interface_dark_pure',
          },

          '> div:last-child': {
            width: '50%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>Saldo a cobrar</HeaderImportantText>
        </Div>
        <Div>
          <HeaderImportantText>123321</HeaderImportantText>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div': {
            width: '100%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <Text>
            Recibí de S.A. la suma de Gs. 6.231.729 (Guaranies seis millones
            doscientos treinta y un mil setecientos veintinueve.), por los
            conceptos expresados en la liquidación por TERMINACIÓN DEL CONTRATO
            INDIVIDUAL DE TRABAJO POR DESPIDO INJUSTIFICADO que antecede,
            calculada en base a mi antigüedad y salario en la empresa, dando mi
            total conformidad con la suma percibida, sin tener nada que reclamar
            en las Instancias Civil, Laboral, Administrativa y Penal
          </Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div': {
            width: '100%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <Text
            css={{
              fontWeight: 'bold',
            }}
          >
            FIRMO DOS EJEMPLARES DE UN MISMO TENOR Y UN SOLO EFECTO.-
          </Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '100%',
            height: 40,
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '100%',
            height: 40,
            paddingLeft: '$2',
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Text
            css={{
              fontWeight: 'bold',
            }}
          >
            ...
          </Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Text
            css={{
              fontWeight: 'bold',
            }}
          >
            ...
          </Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '> div:not(:last-child)': {
            width: '50%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },

          '> div:last-child': {
            width: '50%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '2px solid $interface_dark_pure',
          }}
        >
          <HeaderImportantText>Aclaración</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '2px solid $interface_dark_pure',
          }}
        >
          <HeaderImportantText>.</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            borderBottom: '2px solid $interface_dark_pure',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>Aclaración</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            borderBottom: '2px solid $interface_dark_pure',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>Afonso Pena</HeaderImportantText>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '> div:first-child': {
            width: '50%',
            paddingLeft: '$2',
          },

          '> div:not(:first-child)': {
            width: '25%',
            paddingLeft: '$2',
          },

          '> div:not(:last-child)': {
            borderRight: '2px solid $interface_dark_pure',
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>
            Firma del empleador o representante
          </HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>CI No</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HeaderImportantText>1243</HeaderImportantText>
        </Div>
      </Div>
    </Div>
  )
}
