import {
  SmartTableAvatarItemValue,
  SmartTableFormItemValue,
  SmartTableFormTagValue,
  getSmartTableFormArrayItemValue,
  getSmartTableFormItemValue,
} from '@/components/SmartTable/types'
import {
  validateFormattedMinutesOrEmpty,
  validateTotalMinutesOrEmpty,
} from '@/utils/date'
import { z } from 'zod'

const HourDetailSchema = z.object({
  startTime: z.string(),
  endTime: z.string(),
})

export const PunchesInfosContent = z.object({
  scheduled_shift_id: z.string(),
  id: z.string(),
  userId: z.string(),
  rawStatus: z.string(),
  timezone: z.string(),
  isRest: z.boolean(),
  date: z.object({
    metadata: z.object({
      date: z.string(),
    }),
  }),
  details: z.object({
    ordinaryHours: HourDetailSchema.array(),
    extraHours: HourDetailSchema.array(),
    discountHours: HourDetailSchema.array(),
    holidayHours: HourDetailSchema.array(),
    intervalHours: HourDetailSchema.array(),
    movementsHours: HourDetailSchema.array(),
  }),

  savedHours: z.object({
    morningHours: z.number().nullable(),
    nightHours: z.number().nullable(),
    morningExtraHours: z.number().nullable(),
    nightExtraHours: z.number().nullable(),
    morningDiscountHours: z.number().nullable(),
    nightDiscountHours: z.number().nullable(),
    morningHolidayHours: z.number().nullable(),
    nightHolidayHours: z.number().nullable(),
    intervalHours: z.number().nullable(),
  }),

  authorizedHours: z.object({
    nightHours: z.number().nullable(),
    morningExtraHours: z.number().nullable(),
    nightExtraHours: z.number().nullable(),
    morningDiscountHours: z.number().nullable(),
    nightDiscountHours: z.number().nullable(),
    morningHolidayHours: z.number().nullable(),
    nightHolidayHours: z.number().nullable(),
  }),

  // Table props
  checked: SmartTableFormItemValue,
  avatar: SmartTableAvatarItemValue,
  name: SmartTableFormItemValue,
  surname: SmartTableFormItemValue,
  document: SmartTableFormItemValue,
  status: SmartTableFormTagValue,
  movements: SmartTableFormTagValue,
  shiftId: SmartTableFormItemValue,
  // add: SmartTableFormItemValue,
  add: getSmartTableFormItemValue((data) =>
    validateFormattedMinutesOrEmpty(data),
  ),
  entrance: getSmartTableFormItemValue((data) =>
    validateFormattedMinutesOrEmpty(data),
  ),
  exit: getSmartTableFormItemValue((data) =>
    validateFormattedMinutesOrEmpty(data),
  ),
  intervals: getSmartTableFormArrayItemValue((data) =>
    validateFormattedMinutesOrEmpty(data),
  ),
  morningHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  nightHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  morningExtraHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  nightExtraHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  morningDiscountHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  nightDiscountHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  morningHolidayHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  nightHolidayHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  totalIntervalHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  totalMorningHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
  totalNightHours: getSmartTableFormItemValue((data) =>
    validateTotalMinutesOrEmpty(data),
  ),
})

export const PunchesInfosTable = z.object({
  data: z.array(PunchesInfosContent),
  numberOfIntervals: z.number(),

  cellsIds: z.array(z.string()),
  name: z.string(),
  date: z.string().array(),
  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),

  map: z.object({
    date: z.string().array(),
    name: z.string(),
  }),
})

export type PunchesInfosTableType = z.infer<typeof PunchesInfosTable>
export type PunchesInfosContentType = z.infer<typeof PunchesInfosContent>
