import { useQuery } from 'react-query'
import { IGetVacationDTO, IVacation } from '../../types/vacations'
import { apiV2 } from '@/services/api'

export const fetchVacation = async (data: IGetVacationDTO) => {
  const vacations = await apiV2.get<IVacation>(
    `/vacation/get-vacation/${data.id}`,
  )
  return vacations.data
}

export const useVacation = (props: { id?: string }) => {
  const query = useQuery(
    ['vacation', props.id],
    () => fetchVacation({ id: props.id! }),
    {
      keepPreviousData: true,
      enabled: !!props.id,
    },
  )
  return query
}
