import { Div } from '@/components'
import { Text, styled } from '@punto-ui/react'
import { ReceiptBuilderSchemaType } from '../../type'
import { useFormContext, useWatch } from 'react-hook-form'
import { RadioSelector } from '@/components/RadioSelector'
import React from 'react'
import { HeaderOptionContainer } from './ReceiptFooter'
import { ThreePictureHeaderOption } from './ReceiptHeaders/ThreePictureHeaderOption'
import { LogoAndPictureHeaderOption } from './ReceiptHeaders/LogoAndPictureHeaderOption'
import { BigLogoHeaderOption } from './ReceiptHeaders/BigLogoHeaderOption'
import { LogoHeaderOption } from './ReceiptHeaders/LogoHeaderOption'
import { NoImageHeaderOption } from './ReceiptHeaders/NoImageHeader'
import { LiquidationHeaderOption } from './ReceiptHeaders/LiquidationHeaderOption'
import { LiquidationLogoHeaderOption } from './ReceiptHeaders/LiquidationWithLogoHeaderOption'

export const ReceiptHeader = () => {
  const { control, setValue } = useFormContext<ReceiptBuilderSchemaType>()

  const [headerType, reportType] = useWatch({
    control,
    name: ['headerConfig.type', 'configurations.type'],
  })

  return (
    <Div>
      <Text>Seleccione el encabezado:</Text>

      <Div
        css={{
          marginTop: '$4',
          borderTop: '1px solid $interface_light_down',
        }}
      >
        {reportType === 'liquidation' && (
          <>
            <HeaderOptionContainer>
              <RadioSelector
                textCSS={{
                  width: 300,
                }}
                label="Encabezado para Liquidación"
                isSelected={headerType === 'liquidation-header'}
                onClick={() => {
                  setValue('headerConfig.type', 'liquidation-header')
                }}
              />
              <LiquidationHeaderOption />
            </HeaderOptionContainer>
            <HeaderOptionContainer>
              <RadioSelector
                textCSS={{
                  width: 300,
                }}
                label="Encabezado para Liquidación (con logo)"
                isSelected={headerType === 'liquidation-logo-header'}
                onClick={() => {
                  setValue('headerConfig.type', 'liquidation-logo-header')
                }}
              />
              <LiquidationLogoHeaderOption />
            </HeaderOptionContainer>
          </>
        )}
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label="Sin imagen"
            isSelected={headerType === 'no-image'}
            onClick={() => {
              setValue('headerConfig.type', 'no-image')
            }}
          />
          <NoImageHeaderOption />
        </HeaderOptionContainer>
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label="Logo Empresarial"
            isSelected={headerType === 'with-logo'}
            onClick={() => {
              setValue('headerConfig.type', 'with-logo')
            }}
          />
          <LogoHeaderOption />
        </HeaderOptionContainer>
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label="Logo Empresarial (Grande)"
            isSelected={headerType === 'with-big-logo'}
            onClick={() => {
              setValue('headerConfig.type', 'with-big-logo')
            }}
          />
          <BigLogoHeaderOption />
        </HeaderOptionContainer>
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label="Logo Empresarial + MTESS"
            isSelected={headerType === 'with-logo-and-mtess'}
            onClick={() => {
              setValue('headerConfig.type', 'with-logo-and-mtess')
            }}
          />
          <LogoAndPictureHeaderOption />
        </HeaderOptionContainer>
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label="Logo Empresarial + MTESS + Gobierno"
            isSelected={headerType === 'with-3-images'}
            onClick={() => {
              setValue('headerConfig.type', 'with-3-images')
            }}
          />
          <ThreePictureHeaderOption />
        </HeaderOptionContainer>
      </Div>
    </Div>
  )
}

export const ReceiptHeaderInput = styled('input', {
  all: 'unset',
  textAlign: 'center',
  width: 800,
})

export const HeaderImportantText = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Text
      variant="paragraph"
      css={{
        fontWeight: 'bold',
      }}
    >
      {children}
    </Text>
  )
}
