import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '@/libs/react-query/hooks/useMe'

import { IPaymentBatch } from '../../types/payment'

const fetchPaymentBatch = async (id: string) => {
  const response = await apiV2.get<IPaymentBatch>(
    `/payment/get-payment-batch/${id}`,
  )

  return response.data
}

export const usePaymentBatch = (id: string | undefined) => {
  const { data: me } = useMe()

  const query = useQuery(
    ['payment', 'batch', me?.company_id, id],
    () => fetchPaymentBatch(id!),
    {
      keepPreviousData: true,
      enabled: !!me && !!me.company_id && !!id,
    },
  )
  return query
}
