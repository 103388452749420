import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { GroupData, ICreateCompletePolicyData } from '../types'
import { RowContainer } from '../components/IamTable/styles'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Text } from '@punto-ui/react'
import { ControlledCheckbox } from '@/components/Forms'
import { useOrganogram } from '@/libs/react-query/hooks'
import { toggleAllCells, togglePermission } from '../utils'
import { FieldPath, useFormContext } from 'react-hook-form'
import { findGroupParentById } from '@/pages/permisos/components/GroupBuilder/utils'
import { usePolicyBuilder } from '../context'
import { Avatar, Div, SearchableSelect } from '@/components'
import {
  PermissionModule,
  PermissionType,
  permissionNamesByType,
  systemModuleLabelToValue,
  useGetAllPermissions,
} from '@/hooks/useGetAllPermissions'

export const usePolicyColumns = (
  systemModule: FieldPath<ICreateCompletePolicyData>,
  setSelectedModule?: (str: string) => void,
) => {
  const { setValue, watch } = useFormContext<ICreateCompletePolicyData>()
  const { data: organogram } = useOrganogram()
  const { groups } = usePolicyBuilder()
  const permissions = useGetAllPermissions()

  const columns = React.useMemo<ColumnDef<GroupData>[]>(() => {
    const mainColumns: ColumnDef<GroupData>[] = [
      {
        id: 'name',
        footer: (props) => props.column.id,
        header: () => (
          <Div
            css={{
              display: 'flex',
              paddingLeft: 12,
            }}
          >
            {/* <Text
                  variant={'heading2'}
                  css={{ color: '$brand_primary_pure' }}
                >
                  Permisos de {moduleName}
                </Text> */}

            <SearchableSelect
              value={systemModule}
              css={{
                height: 32,
                borderColor: '$interface_dark_up',
              }}
              // options={}
              options={systemModuleLabelToValue}
              defaultValue="Colaboradores"
              onChangeValue={(e) => {
                const toValue = systemModuleLabelToValue.find(
                  (x) => x.value === e.value,
                )

                if (!toValue) return

                if (setSelectedModule) {
                  setSelectedModule(
                    toValue.value as FieldPath<ICreateCompletePolicyData>,
                  )
                }
              }}
            />
          </Div>
        ),
        accessorFn: (row) => row.name,
        size: 500,
        meta: {
          content: 'component',
          thStyles: {
            position: 'sticky',
            left: 0,
            zIndex: 3,
            backgroundColor: '$interface_light_pure',
            opacity: 0.85,
          },
          tdStyles: {
            position: 'sticky',
            left: 0,
            zIndex: 1,
            backgroundColor: '$interface_light_pure',
            opacity: 0.85,
            overflow: 'hidden',

            '&:hover': {
              overflow: 'visible',
            },
          },
        },
        cell: ({ getValue, row, column }) => (
          <RowContainer
            {...{
              onClick: row.getCanExpand()
                ? row.getToggleExpandedHandler()
                : () => null,
              css: {
                cursor: 'pointer',
                paddingLeft: `${row.depth * 2 + 0.7}rem`,
                '> svg': {
                  strokeWidth: 2.5,
                  color: row.getCanExpand()
                    ? '$brand_primary_pure'
                    : '$interface_light_pure',
                },
              },
            }}
          >
            <>
              {row.getIsExpanded() ? (
                <ChevronDownIcon height={16} width={16} />
              ) : (
                <ChevronRightIcon height={16} width={16} />
              )}
            </>{' '}
            {row.original._type === 'user' && (
              <Div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt=""
                  src={row.original.image_url}
                  height={16}
                  width={16}
                />
              </Div>
            )}
            <Text
              css={{
                marginLeft: 8,
                whiteSpace: 'nowrap',

                fontWeight:
                  row.original._type === 'group' ? '$bold' : '$regular',
                color:
                  row.original._type === 'group'
                    ? '$brand_primary_pure'
                    : '$interface_dark_deep',
              }}
            >
              <>{getValue()}</>
            </Text>
          </RowContainer>
        ),
      },
      // {
      //   id: 'leader-indication',
      //   meta: {
      //     content: 'component',
      //   },
      //   header: '',
      //   cell: ({ row }) =>
      //     row.original._type === 'user' && row.original.isLeader === true ? (
      //       <Box
      //         css={{
      //           padding: 0,
      //           display: 'flex',
      //           alignItems: 'center',
      //           justifyContent: 'center',
      //           height: 30,
      //           width: 30,
      //           borderRadius: 1000,
      //           background: '#7CF9FF',
      //           svg: {
      //             width: 16,
      //             height: 16,
      //             color: '$brand_primary_pure',
      //           },
      //         }}
      //       >
      //         <TrophyIcon color="" />
      //       </Box>
      //     ) : null,
      // },
    ]

    const modulePermissions = permissions[systemModule as PermissionModule]

    const permissionsInModule = Object.keys(modulePermissions).map(
      (permission) => ({
        id: permission,
        name: permissionNamesByType[permission as PermissionType],
        type: permission as PermissionType,
      }),
    )

    const permissionsHeaders: ColumnDef<GroupData>[] = permissionsInModule.map(
      (permission) => ({
        // size: 100,
        header: ({ table }) => {
          const form = watch(systemModule)
          const allCellsSelected = (form as any)?.cell?.cell?.all
          const allCellsSelectedForRequest = (form as any)?.cell?.cell?.request

          const shouldDisableButton =
            (allCellsSelected && permission.type !== 'all') ||
            (allCellsSelectedForRequest && permission.type !== 'request')

          return (
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1,

                // minWidth: 100,
                // zIndex: 0,
              }}
            >
              <ControlledCheckbox
                disabled={shouldDisableButton}
                name={`${systemModule}.cell.cell.${permission.type}`}
                css={{
                  marginBottom: '$2',
                  opacity: shouldDisableButton ? 0.5 : 1,
                }}
                label=""
                onValueChange={(value) => {
                  const newStructure = toggleAllCells(
                    systemModule as any,
                    permission.type,
                    {
                      [`${systemModule}`]: form,
                    },
                    value,
                  )

                  setValue(systemModule, (newStructure as any)[systemModule])
                }}
              />
              <Text
                variant={'paragraph'}
                css={{
                  fontWeight: 'bold',

                  whiteSpace: 'nowrap',
                  // padding: '0 16px',
                }}
              >
                {permission.name}
              </Text>
            </Div>
          )
        },
        id: `colaborators-permissions-${permission.type}`,
        meta: {
          content: 'component',
          tdStyles: {
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        cell: ({ row }) => {
          const form = watch(systemModule)
          const isUser = row.original._type === 'user'
          let cellParentValue = false

          const allCellsSelected =
            (form as any)?.cell?.cell?.[permission.type] ||
            (form as any)?.cell?.cell?.all ||
            (form as any)?.cell?.cell?.request

          let pathName = `${systemModule}.${row.original.id}.cell.${permission.type}`

          if (isUser) {
            const cellParent = findGroupParentById(groups, row.original.id)

            if (cellParent && form && (form as any)[cellParent.id]) {
              cellParentValue = (form as any)[cellParent.id]?.cell[
                permission.type
              ]
              pathName = `${systemModule}.${cellParent.id}.${row.original.id}.${permission.type}`
            }
          }

          return (
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ControlledCheckbox
                name={pathName}
                label={''}
                onValueChange={(value) => {
                  const newStructure = togglePermission(
                    organogram?.cells || [],
                    row.original.id,
                    permission.type,
                    value,
                    systemModule as any,
                    {
                      [`${systemModule}`]: form,
                    },
                  )

                  setValue(systemModule, (newStructure as any)[systemModule])
                }}
                css={{
                  opacity: allCellsSelected ? 0.5 : 1,
                }}
                disabled={allCellsSelected}
                unknown={cellParentValue}
              />
            </Div>
          )
        },
      }),
    )

    return [...mainColumns, ...permissionsHeaders]
  }, [organogram, watch, groups, setValue, systemModule])

  return { data: columns }
}
