import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const CompanyInfosSchema = z.object({
  name: z.string(),
  document: z.string().min(3, {
    message: 'El RUC es requerido.',
  }),
  ips: z.string().optional(),
  mtss: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  business_name: z.string(),

  profile_photo: z.custom<File>().optional(),
})

export type CompanyInfosSchemaType = z.infer<typeof CompanyInfosSchema>
export type CompanyInfosSchemaField =
  ControlledFieldProps<CompanyInfosSchemaType>
