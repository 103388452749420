import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient } from '@/libs/react-query'
import { ICreatePayrollDTO } from '@/libs/react-query/types/payroll'
dayjs.extend(utc)

const handleCreatePayroll = async (payload: ICreatePayrollDTO) => {
  await apiV2.post<void>('/payroll/create', payload)
  queryClient.invalidateQueries('payrolls')
}

export const useHandleCreatePayroll = () => {
  const mutation = useMutation(['useHandleCreatePayroll'], handleCreatePayroll)

  return mutation
}
