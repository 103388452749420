import { z } from 'zod'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { createMovementSchema } from '@/libs/react-query/mutations/movements'
import { movementOptions } from '@/libs/react-query/types/movements'
import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import { Div } from '@/components'

export { createMovementSchema }
export type AddMovementSimpleData = z.infer<typeof createMovementSchema>
export type AddMovementsFields = Array<
  ControlledFieldProps<AddMovementSimpleData>
>

export const useMovementFields = () => {
  const { control, setValue } = useFormContext<AddMovementSimpleData>()

  const [periodType, movementType, periods, id] = useWatch({
    control,
    name: ['period_type', 'type', 'periods', 'id'],
  })

  const movementCategory = useWatch({
    control,
    name: 'category',
  })

  useEffect(() => {
    if (movementCategory === 'presence') {
      setValue('remuneration', true)
    }
  }, [movementCategory])

  const movementFields: AddMovementsFields = useMemo(() => {
    const periodTypes = [
      {
        label: 'Periodo Unico',
        value: 'range',
      },
      {
        label: 'Multiplos Dias',
        value: 'multiple',
      },
      // {
      //   label: 'Periodo com Horario',
      //   value: 'withHours',
      // },
    ]

    const defaultMovementOption =
      movementOptions.find((option) => option.value === movementType) ||
      movementOptions[0]

    const movementsPolicies = ['moves.all', 'moves.edit', 'moves.request']

    if (!id) {
      movementsPolicies.push('moves.create')
    }

    const numberOfDaysInMovements =
      periodType === 'range' &&
      periods[0] &&
      periods[1] &&
      dayjs(periods[0]).isSame(dayjs(periods[1]), 'day')
        ? 1
        : periodType === 'range' && periods[0] && periods[1]
        ? dayjs(periods[1]).diff(periods[0], 'days') + 1
        : periodType === 'multiple'
        ? periods.length
        : 0

    const isPresenceMovement = movementCategory === 'presence'
    // const isPresenceMovement = false

    const result: AddMovementsFields = [
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'input',
            label: 'Titulo del movimiento',
            name: 'title',
          },
          {
            _type: 'dropdown',
            label: 'Tipo de movimiento',
            name: 'category',
            options: movementOptions,
            defaultOption: defaultMovementOption,
            autoComplete: 'off',
          },
        ],
      },
      {
        _type: 'input',
        label: 'Descripcion del movimiento',
        name: 'description',
      },
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'dropdown',
            label: 'Periodo del Movimiento',
            name: 'period_type',
            defaultOption: periodTypes.find(
              (option) => option.value === periodType,
            ) || {
              label: 'Periodo Unico',
              value: 'range',
            },
            options: periodTypes,
          },
          {
            label: 'Data del movimiento',
            name: 'periods',
            hourName: 'withHours',
            calendarType: periodType,
            multipleDates: periods,
            _type: 'calendar',
            maxDates: 99999,
            withHours: periodType === 'range',
            firstDate: new Date(),
            secondDate: new Date(),
          },
        ],
      },
      {
        _type: 'worker_search_bar',
        policiesIds: movementsPolicies,
        activeDateFilter: true,
        activeSelection: true,
        label: 'Colaboradores',
        name: 'users',
        usersIds: [],
        maxHeight: 200,
      },
      {
        _type: 'checkbox',
        label: `Movimiento contabiliza como dia remunerado (+ ${numberOfDaysInMovements} dias naturales)`,
        name: 'remuneration',
        disabled: isPresenceMovement,
      },
      {
        _type: 'checkbox',
        label: `Movimiento contabiliza en la bases imponible del IPS (+ ${numberOfDaysInMovements} dias ao IPS)`,
        name: 'ips',
      },
    ]

    if (isPresenceMovement) {
      result.push({
        _type: 'checkbox',
        label: `Contabilizar horas del movimiento`,
        name: 'should_include_hours',
      })
    } else {
      result.push({
        _type: 'custom',
        component: (
          <Div
            css={{
              height: 64,
            }}
          />
        ),
      })
    }

    return result
  }, [periodType, movementType, periods, id, movementCategory])

  return {
    data: movementFields,
  }
}
