import { Text } from '@punto-ui/react'
import { ControlledChipP } from './types'
import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { useMemo } from 'react'
import { Div } from '@/components/Div'
import { getDeepVal } from '@/utils/object'
import { Chip } from '@/components/Chip'

export const ControlledChip = <TFieldValues extends FieldValues>(
  props: ControlledChipP<TFieldValues>,
) => {
  // const [options, setOptions] = useState<ChipProps['options']>([
  //   ...(props.options || []),
  // ])

  const {
    setValue,
    formState: { errors },
    trigger,
    control,
  } = useFormContext<any>()

  const chipValues = useWatch({
    control,
    name: props.name,
  })

  const optionsWithSelection = useMemo(() => {
    return (
      props.options?.map((option) => {
        return {
          ...option,
          selected: chipValues?.includes(option.value),
        }
      }) || []
    )
  }, [props.options, chipValues])

  const inputError = getDeepVal(errors, props.name)

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {!props.withoutLabel && (
        <Text
          variant="caption"
          css={{ color: '$interface_dark_deep', marginBottom: '$2' }}
        >
          {props.label}
        </Text>
      )}
      <Chip
        dropdownSide={props.direction || 'right'}
        label={props.placeholder || props.label}
        options={optionsWithSelection || []}
        onChangeValue={async (newOptions) => {
          await trigger(props.name)

          const selectedValues = newOptions.filter(
            (option: any) => option.selected,
          )
          const selectdValuesValues = selectedValues.map(
            (selectedValue: any) => selectedValue.value,
          )
          setValue(props.name, selectdValuesValues as any)
        }}
      />
      <Text variant={'caption'} css={{ color: '$status_danger_deep' }}>
        {' '}
        {inputError ? inputError.message : undefined}{' '}
      </Text>
    </Div>
  )
}
