import {
  IPunch,
  IWorkedHourEnvelope,
  fromWorkedDayStatusToLabel,
  fromWorkedDayStatusToTagVariant,
} from '@/libs/react-query/types'
import { PunchesInfosContentType, PunchesInfosTableType } from './types'
import { SmartTableFormItemValueType } from '@/components/SmartTable/types'
import { fromDateToFormattedHour, minuteToHour } from '@/utils/date'
import dayjs from 'dayjs'
import { getNumberWith3Decimals } from '@/utils/math/number'

export const fromPunchesToPunchTable = (
  workedHoursEnvelopes: IWorkedHourEnvelope[],
  savedSelectedIds: string[],
  previousDate: string[],
  previousName: string,
  previousCellsIds: string[],
): PunchesInfosTableType => {
  const data: PunchesInfosContentType[] = []

  workedHoursEnvelopes?.map((workedHoursEnvelope) => {
    const intervals: SmartTableFormItemValueType[] = []

    workedHoursEnvelope.info.intervals.forEach((interval) => {
      intervals.push({
        value: fromDateToFormattedHour(interval.entrance?.date) || '',
        original: fromDateToFormattedHour(interval.entrance?.date) || '',
        status: getPunchStatus(interval.entrance),
        metadata: {
          date: dayjs(interval.entrance?.date).toDate(),
          isAutomatic: isPunchAutomatic(interval.entrance),
        },
      })
      intervals.push({
        value: fromDateToFormattedHour(interval.exit?.date) || '',
        original: fromDateToFormattedHour(interval.exit?.date) || '',
        status: getPunchStatus(interval.exit),
        metadata: {
          date: dayjs(interval.exit?.date).toDate(),
          isAutomatic: isPunchAutomatic(interval.exit),
        },
      })
    })

    data.push({
      rawStatus: workedHoursEnvelope.info.status,
      scheduled_shift_id: workedHoursEnvelope.info.scheduled_shift_id,
      id: workedHoursEnvelope.user.id,
      userId: workedHoursEnvelope.user.id,
      details: workedHoursEnvelope.info.details,
      timezone: workedHoursEnvelope.info.timezone,
      isRest: workedHoursEnvelope.shift.isRestDay,
      date: {
        metadata: {
          date: workedHoursEnvelope.info.date,
        },
      },

      // Table props
      checked: {
        value: !!savedSelectedIds?.includes(workedHoursEnvelope.user.id),
        status: '',
      },
      avatar: {
        url: workedHoursEnvelope.user.photo_url || '',
        alt: workedHoursEnvelope.user.name || '',
      },
      shiftId: {
        value: workedHoursEnvelope.shift.shiftId || '',
        status: !workedHoursEnvelope.shift.shiftId ? 'warning' : '',
      },
      name: {
        value: workedHoursEnvelope.user.firstName,
        status: '',
      },
      surname: {
        value: workedHoursEnvelope.user.surname,
        status: '',
      },
      document: {
        value: workedHoursEnvelope.user.document || '',
        status: '',
      },
      status: [
        {
          id: workedHoursEnvelope.user.document || `${Date.now()}`,
          name: fromWorkedDayStatusToLabel(
            workedHoursEnvelope.info.status,
            true,
          ),
          variant:
            (fromWorkedDayStatusToTagVariant(
              workedHoursEnvelope.info.status,
            ) as string) || 'neutral',
        },
      ],
      entrance: {
        value: fromDateToFormattedHour(
          workedHoursEnvelope.info.firstPunch?.date,
        ),
        original: fromDateToFormattedHour(
          workedHoursEnvelope.info.firstPunch?.date,
        ),
        status: getPunchStatus(workedHoursEnvelope.info.firstPunch),
        metadata: {
          date: dayjs(workedHoursEnvelope.info.firstPunch?.date).toDate(),
          isAutomatic: isPunchAutomatic(workedHoursEnvelope.info.firstPunch),
        },
      },
      exit: {
        value: fromDateToFormattedHour(
          workedHoursEnvelope.info.lastPunch?.date,
        ),
        original: fromDateToFormattedHour(
          workedHoursEnvelope.info.lastPunch?.date,
        ),
        status: getPunchStatus(workedHoursEnvelope.info.lastPunch),
        metadata: {
          date: dayjs(workedHoursEnvelope.info.lastPunch?.date).toDate(),
          isAutomatic: isPunchAutomatic(workedHoursEnvelope.info.lastPunch),
        },
      },
      movements: workedHoursEnvelope.movements.map((movement) => ({
        id: movement.id,
        name: movement.title,
        variant: 'neutral',
        data: movement,
      })),
      intervals,
      add: {
        value: '',
        status: '',
      },
      morningDiscountHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningDiscountHours * 60),
        original: minuteToHour(
          workedHoursEnvelope.info.morningDiscountHours * 60,
        ),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedDiscountMorningHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedDiscountMorningHours,
              ) ===
              getNumberWith3Decimals(
                workedHoursEnvelope.info.morningDiscountHours,
              )
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedDiscountMorningHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      morningExtraHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningExtraHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.morningExtraHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedExtraMorningHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedExtraMorningHours,
              ) ===
              getNumberWith3Decimals(workedHoursEnvelope.info.morningExtraHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedExtraMorningHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      morningHolidayHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningHolidayHours * 60),
        original: minuteToHour(
          workedHoursEnvelope.info.morningHolidayHours * 60,
        ),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedHolidayMorningHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedHolidayMorningHours,
              ) ===
              getNumberWith3Decimals(
                workedHoursEnvelope.info.morningHolidayHours,
              )
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedHolidayMorningHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      morningHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.morningHours * 60),
        status: '',
      },
      nightDiscountHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightDiscountHours * 60),
        original: minuteToHour(
          workedHoursEnvelope.info.nightDiscountHours * 60,
        ),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedDiscountNightHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedDiscountNightHours,
              ) ===
              getNumberWith3Decimals(
                workedHoursEnvelope.info.nightDiscountHours,
              )
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedDiscountNightHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      nightExtraHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightExtraHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.nightExtraHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours.authorizedExtraNightHours ===
          null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedExtraNightHours,
              ) ===
              getNumberWith3Decimals(workedHoursEnvelope.info.nightExtraHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedExtraNightHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      nightHolidayHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightHolidayHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.nightHolidayHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedHolidayNightHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedHolidayNightHours,
              ) ===
              getNumberWith3Decimals(workedHoursEnvelope.info.nightHolidayHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedHolidayNightHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      nightHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.nightHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours.authorizedNightHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours.authorizedNightHours,
              ) === getNumberWith3Decimals(workedHoursEnvelope.info.nightHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours.authorizedNightHours ===
              0
            ? 'soft-error'
            : 'soft-warning',
      },
      totalMorningHours: {
        value: minuteToHour(workedHoursEnvelope.info.totalMorningHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.totalMorningHours * 60),
        status: '',
      },
      totalNightHours: {
        value: minuteToHour(workedHoursEnvelope.info.totalNightHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.totalNightHours * 60),
        status: '',
      },
      totalIntervalHours: {
        value: minuteToHour(workedHoursEnvelope.info.intervalHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.intervalHours * 60),
        status: '',
      },
      savedHours: {
        morningHours:
          workedHoursEnvelope.info.savedTotalHours?.morningHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.morningHours !== undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningHours
            : null,
        nightHours:
          workedHoursEnvelope.info.savedTotalHours?.nightHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.nightHours !== undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightHours
            : null,
        morningExtraHours:
          workedHoursEnvelope.info.savedTotalHours?.morningExtraHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.morningExtraHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningExtraHours
            : null,
        nightExtraHours:
          workedHoursEnvelope.info.savedTotalHours?.nightExtraHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.nightExtraHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightExtraHours
            : null,
        morningDiscountHours:
          workedHoursEnvelope.info.savedTotalHours?.morningDiscountHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.morningDiscountHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningDiscountHours
            : null,
        nightDiscountHours:
          workedHoursEnvelope.info.savedTotalHours?.nightDiscountHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.nightDiscountHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightDiscountHours
            : null,
        morningHolidayHours:
          workedHoursEnvelope.info.savedTotalHours?.morningHolidayHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.morningHolidayHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningHolidayHours
            : null,
        nightHolidayHours:
          workedHoursEnvelope.info.savedTotalHours?.nightHolidayHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.nightHolidayHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightHolidayHours
            : null,
        intervalHours:
          workedHoursEnvelope.info.savedTotalHours?.intervalHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.intervalHours !== undefined
            ? workedHoursEnvelope.info.savedTotalHours?.intervalHours
            : null,
      },
      authorizedHours: {
        morningDiscountHours:
          workedHoursEnvelope.info.authorizedHours
            ?.authorizedDiscountMorningHours,
        morningExtraHours:
          workedHoursEnvelope.info.authorizedHours.authorizedExtraMorningHours,
        morningHolidayHours:
          workedHoursEnvelope.info.authorizedHours
            .authorizedHolidayMorningHours,
        nightDiscountHours:
          workedHoursEnvelope.info.authorizedHours.authorizedDiscountNightHours,
        nightExtraHours:
          workedHoursEnvelope.info.authorizedHours.authorizedExtraNightHours,
        nightHolidayHours:
          workedHoursEnvelope.info.authorizedHours.authorizedHolidayNightHours,
        nightHours:
          workedHoursEnvelope.info.authorizedHours.authorizedNightHours,
      },
    })

    return data
  })

  const greaterArrayLength = data?.reduce((acc, curr) => {
    const arrayLength = curr.intervals.length || 0
    return arrayLength > acc ? arrayLength : acc
  }, 0)

  return {
    data,
    map: {
      date: previousDate,
      name: previousName,
    },
    numberOfIntervals: greaterArrayLength,
    cellsIds: previousCellsIds,
    date: previousDate,
    name: previousName,
    pagination: {} as any,
  }
}

export const isPunchAutomatic = (punch: IPunch | null) => {
  return !!punch?.tags.some((tag) => tag.tag === 'AUTOMATIC_CREATION')
}

export const isPunchGeolocationValid = (punch: IPunch | null) => {
  return punch?.isValidByDistance !== undefined
    ? punch?.isValidByDistance
    : true
}

export const LIVENESS_THRESHOLD = 0.6

export const isPunchLivenessValid = (punch: IPunch | null) => {
  if (punch?.is_real_face === undefined || punch?.is_real_face === null) {
    return true
  }

  if (!punch.is_real_face) {
    return false
  }

  if (
    punch.is_real_face &&
    (punch?.liveness_precision || 0) < LIVENESS_THRESHOLD
  ) {
    return false
  }

  return true
}

export const isPunchLivenessNotSuspect = (punch: IPunch | null) => {
  if (punch?.is_real_face === undefined || punch?.is_real_face === null) {
    return true
  }

  if (!punch.is_real_face) {
    return true
  }

  if (
    punch.is_real_face &&
    (punch?.liveness_precision || 0) < LIVENESS_THRESHOLD
  ) {
    return false
  }

  return true
}

export const getPunchStatus = (punch: IPunch | null) => {
  if (!punch) return ''

  if (isPunchAutomatic(punch)) return 'success'

  if (!isPunchGeolocationValid(punch)) return 'error'

  if (!isPunchLivenessNotSuspect(punch)) return 'warning'

  if (!isPunchLivenessValid(punch)) return 'error'

  return ''
}
