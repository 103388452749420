import { Div } from '@/components'
import { IPunchConfigGroup } from '@/libs/react-query/types/company'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Tag, Text } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

export const usePunchGroupsColumns = () => {
  const columns = React.useMemo<ColumnDef<IPunchConfigGroup>[]>(() => {
    const retunedColumns: ColumnDef<IPunchConfigGroup>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text>{row.original.name}</Text>
          </Div>
        ),
      },
      {
        id: 'is_able_to_punch_geolocation_face_id',
        header: 'Geolocalización y FaceID',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={
                row.original.is_able_to_punch_geolocation_face_id
                  ? 'positive'
                  : 'negative'
              }
            >
              {row.original.is_able_to_punch_geolocation_face_id ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'is_able_to_punch_offline',
        header: 'Offline',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={
                row.original.is_able_to_punch_offline ? 'positive' : 'negative'
              }
            >
              {row.original.is_able_to_punch_offline ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'is_able_to_punch_from_anywhere',
        header: 'Localización Movil',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={
                row.original.is_able_to_punch_from_anywhere
                  ? 'positive'
                  : 'negative'
              }
            >
              {row.original.is_able_to_punch_from_anywhere ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'required_aws_precision',
        header: 'Precision en FaceID',
        accessorFn: (row) => row.required_aws_precision,
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
