import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateUserLiquidationDTO } from '@/libs/react-query/types/company'

export const useUpdateUserLiquidation = () => {
  const mutation = useCustomMutation(
    ['useUpdateUserLiquidation'],
    handleUpdateUserLiquidation,
  )

  return mutation
}

const handleUpdateUserLiquidation = async (
  props: IUpdateUserLiquidationDTO,
) => {
  try {
    await apiV2.post<void>('/company/update-user-liquidation', props)

    queryClient.invalidateQueries('worker-liquidation')
    queryClient.invalidateQueries('workers')
  } catch (error) {
    console.log(error)
  }
}
