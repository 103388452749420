import { Div } from '@/components'
import { Audit } from '@/libs/react-query/types/audits'
import { capitalizeFirstLetters } from '@/utils/workers/name'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { Text } from '@punto-ui/react'
import dayjs from 'dayjs'

export const Authorizations = ({ audit }: { audit: Audit }) => {
  return (
    <Div
      css={{
        flex: 1,
        minWidth: 300,
      }}
    >
      <Text
        variant="caption"
        css={{
          marginBottom: '$2',
        }}
      >
        Autorizaciones
      </Text>

      <Div>
        <Div
          css={{
            display: 'flex',
            gap: '$2',
          }}
        >
          <Div
            css={{
              svg: {
                height: 16,
                width: 16,
                color: '$status_success_deep',
              },
            }}
          >
            <CheckCircleIcon />
          </Div>
          <Div
            css={{
              width: 290,
            }}
          >
            <Text
              variant="caption"
              css={{
                color: '$interface_dark_down',
                fontWeight: 600,
              }}
            >
              Executado por{' '}
              <strong>{capitalizeFirstLetters(audit.user.name)}</strong> en{' '}
              <strong>
                {dayjs(audit.created_at).format('DD/MM/YYYY HH:mm')}
              </strong>
            </Text>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
