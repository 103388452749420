import {
  IMovementLabels,
  movementsDictionary,
  IMovementCategories,
} from '@/libs/react-query/types'

export function parseMovementTypeToLabel(
  movementType: IMovementCategories,
): IMovementLabels {
  return movementsDictionary[movementType]
}

export const parseMovementLabelToType = (
  movementLabel: IMovementLabels,
): IMovementCategories => {
  if (!movementLabel) return 'presence'

  const movementTypeValue = Object.keys(movementsDictionary).find(
    (key) => (movementsDictionary as any)[key] === movementLabel,
  ) as IMovementCategories

  return movementTypeValue
}
