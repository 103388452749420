import { styled } from '../../../styles'

export const HoursContainer = styled('div', {
  paddingLeft: '$4',
  paddingRight: '$4',
})

export const HoursHeader = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '$2',
  paddingBottom: '$2',
})

export const HoursInfos = styled('div', {})

export const HoursInfoContainer = styled('div', {
  padding: '$2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 'solid 1px',
  borderColor: '$interface_light_down',
  borderRadius: '$md',
  marginBottom: '$2',

  transition: 'all 0.2s ease-in-out',

  '> input': {
    all: 'unset',
    width: '48%',
    color: '$interface_dark_up',
    fontFamily: '$default',
    fontSize: '$sm',
    fontWeight: '$regular',
  },
})
