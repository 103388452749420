import {
  ControlledFilterBar,
  Div,
  SmartTable,
  useSmartTableColumnManipulation,
} from '@/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { VacationStatusContent, VacationStatusTableType } from './types'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { useAllAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
import { useVacationsStatus } from '@/libs/react-query/hooks/useVacations/useVacationsStatus'
import { useVacationStatusColumns } from './columns'
import { useEffect, useMemo } from 'react'
import { parseVacationsToVacationsStatusTable } from './parser'
import { useDebounce } from '@/hooks'
import { VacationAntiquityType } from '@/libs/react-query/types/vacations'

export const VacationsStatus = () => {
  const methods = useForm<VacationStatusTableType>({
    resolver: zodResolver(VacationStatusContent),
    defaultValues: {
      data: [],
      filters: {
        cellsIds: [],
        name: '',
        date: [dayjs().toDate()],
        antiquity: [],
        periods: [],
      },
      pagination: {
        page: 0,
        perPage: 30,
        total: 0,
        totalPages: 0,
      },
    },
  })

  const [filters, pagination] = useWatch({
    control: methods.control,
    name: ['filters', 'pagination'],
  })

  const debouncedName = useDebounce(filters.name, 500)

  const { data: vacationsStatusEnvelope, isFetching: isLoadingStatus } =
    useVacationsStatus({
      name: debouncedName,
      cellsIds: filters.cellsIds,
      page: pagination.page,
      perPage: pagination.perPage,
      date: dayjs(filters.date[0]).startOf('day').toISOString(),
      antiquity: filters.antiquity as VacationAntiquityType[],
      periods: filters.periods.map((p) => +p),
    })

  useAllAvailableCells({
    name: 'filters.cellsIds',
    methods,
    permission: 'vacation',
  })

  const columns = useVacationStatusColumns()

  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
    }
  }, [])

  useEffect(() => {
    if (!vacationsStatusEnvelope?.data.length) {
      return
    }

    const data = methods.getValues('data')
    const allSelectedIds: string[] = []
    const allExpanded: Record<string, boolean> = {}
    data.forEach((d) => {
      if (d.checked.value) {
        allSelectedIds.push(d.id)
      }

      if (d.expandable.value) {
        allExpanded[d.id] = true
      }
    })

    const parsedVacationsStatus = parseVacationsToVacationsStatusTable(
      vacationsStatusEnvelope.data,
      allSelectedIds,
      allExpanded,
    )
    methods.reset({
      data: parsedVacationsStatus,
      filters: {
        name: filters.name,
        cellsIds: filters.cellsIds,
        date: filters.date,
        antiquity: filters.antiquity,
        periods: filters.periods,
      },
      pagination: {
        page: vacationsStatusEnvelope.page,
        perPage: vacationsStatusEnvelope.pageSize,
        total: vacationsStatusEnvelope.total,
        totalPages: vacationsStatusEnvelope.totalPages,
      },
    })
  }, [vacationsStatusEnvelope?.data, methods])

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'vacationsStatusColumnsOrder',
    tableHiddenUniqueName: 'vacationsStatusHiddenColumns',
    tableLeftFixedUniqueName: 'vacationsStatusLeftFixedColumns',
    tableRightFixedUniqueName: 'vacationsStatusRightFixedColumns',
  })

  const defaultPeriodsOptions = Array.from({ length: 10 }).map(
    (n, index) => +dayjs().year() - index,
  )

  const periodsOptions = (vacationsStatusEnvelope?.data || [])
    .reduce((acc, curr) => {
      curr.yearsDetailed.forEach((y) => {
        if (!acc.includes(y.year)) {
          acc.push(y.year)
        }
      })

      return acc
    }, defaultPeriodsOptions)
    .sort((a, b) => {
      return b - a
    })

  return (
    <FormProvider {...methods}>
      <Div>
        <ControlledFilterBar
          withAdvancedCells
          nameFilterName="filters.name"
          cellsIdsName="filters.cellsIds"
          genericFilters={[
            {
              label: 'Antigüedad',
              name: 'filters.antiquity',
              defaultValues: [],
              options: [
                { label: 'Menos de 6 meses', value: 'less_then_6_months' },
                { label: 'Entre 1 y 5 años', value: 'between_1_and_5_years' },
                { label: 'Entre 5 y 10 años', value: 'between_5_and_10_years' },
                { label: 'Más de 10 años', value: 'more_then_10_years' },
              ],
            },
            {
              label: 'Periodos',
              name: 'filters.periods',
              defaultValues: [],
              options:
                periodsOptions?.map((p) => {
                  return {
                    label: `${p - 1}-${p}`,
                    value: `${p}`,
                  }
                }) || [],
            },
          ]}
          isLoading={isLoadingStatus}
          dateType={'single'}
          dateName="filters.date"
          policiesIds={permissionsArray.filter((p) => p.includes('vacation'))}
        />
        <Div
          css={{
            position: 'relative',
            height: 'calc(100vh - 104px)',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 16,
            paddingRight: 16,
            overflowX: 'scroll',
            marginTop: '$4',
          }}
        >
          <SmartTable
            columns={columns}
            style={tableStyles}
            withPagination
            areColumnsDraggable
            defaultColumnOrder={defaultColumnsOrder}
            defaultHiddenColumns={defaultHiddenColumns}
            maxDepth={1}
            subRowName="yearsDetailed"
            resetColumnOrder={resetStateColumnOrder}
            saveColumnsOrderCallback={saveColumnsOrderCallback}
            saveHiddenColumnsCallback={saveHiddenColumnsCallback}
            defaultLeftFixedColumns={defaultLeftFixedColumns}
            defaultRightFixedColumns={defaultRightFixedColumns}
            saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
            saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
            paginationProps={{
              onChange: (data) => {
                methods.setValue('pagination.page', data.pageIndex)
                methods.setValue('pagination.perPage', data.pageSize)
              },
              page: pagination.page,
              perPage: pagination.perPage,
              totalPages: pagination.totalPages,
              total: pagination.total,
            }}
          />
        </Div>
      </Div>
    </FormProvider>
  )
}
