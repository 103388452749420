import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useAvailableCells } from '.'
import { useEffect } from 'react'
import { useExecuteOnceWhen } from '@/hooks'

interface UseAllAvailableCellsProps {
  permission: string
  methods: any
  name: string
}

export const useAllAvailableCells = ({
  methods,
  name,
  permission,
}: UseAllAvailableCellsProps) => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes(permission)),
  )
  useEffect(() => {
    if (availableCells?.length) {
      const cellsIds = availableCells?.map((c) => c.id) || []
      methods.setValue(name, cellsIds)
    }
  }, [availableCells, methods, name])
}

export const useSetAvailableCells = ({
  methods,
  name,
  permission,
}: UseAllAvailableCellsProps) => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes(permission)),
  )
  useExecuteOnceWhen(() => {
    if (availableCells?.length) {
      const cellsIds = availableCells?.map((c) => c.id) || []
      methods.setValue(name, cellsIds)
    }
  }, !!availableCells?.length)
}
