import { styled } from '@punto-ui/react'

export const PageHeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const PageHeaderInfos = styled('div', {})

export const PageHeaderActions = styled('div', {
  display: 'flex',
})
