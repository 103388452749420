import { Div, Flex, FormGroup, FormRenderer } from '@/components'
import {
  useHoursAmountForm,
  useJourneyForm,
  useShiftGeneralInfo,
  useToleranceForm,
  useIntervalDiscount,
  useDiscountOptions,
} from './forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddShiftData } from '../../forms'

export const Configurations = () => {
  const { control } = useFormContext<AddShiftData>()

  const { data: hoursAmountForm } = useHoursAmountForm()
  const { data: intervalDiscountForm } = useIntervalDiscount()
  const { data: discountOptions } = useDiscountOptions()
  const { data: journeyConfigForm } = useJourneyForm()
  // const { data: managementConfigForm } = useManagementConfigForm()
  const { data: shiftGeneralInfoForm } = useShiftGeneralInfo()
  const { data: toleranceForm } = useToleranceForm()

  const isFlexible = useWatch({
    control,
    name: 'config.is_flexible_journey',
  })

  return (
    <Flex
      css={{
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '$4',
      }}
    >
      <FormGroup items={shiftGeneralInfoForm} cols={4} />
      <Div css={{ marginBottom: '$4' }} />
      {/* <FormRenderer items={managementConfigForm} cols={3} inline /> */}
      <FormGroup items={toleranceForm} cols={4} />
      <Div css={{ marginBottom: '$4' }} />
      <FormGroup items={journeyConfigForm} cols={3} />
      {isFlexible && (
        <>
          <Div css={{ marginBottom: '$4' }} />

          <FormRenderer items={hoursAmountForm} cols={4} inline />
          <Div css={{ marginBottom: '$4' }} />

          <FormRenderer items={intervalDiscountForm} cols={4} inline />
          <Div css={{ marginBottom: '$4' }} />

          <FormRenderer items={discountOptions} cols={4} inline />
        </>
      )}
    </Flex>
  )
}
