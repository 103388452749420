import {
  IReportLabel,
  IReportType,
  defaultReportTypesDictionary,
  reportTypesDictionary,
} from '@/libs/react-query/types/reports'

export function parseReportTypeToLabel(
  movementType: IReportType,
): IReportLabel {
  return (
    reportTypesDictionary[movementType] ||
    defaultReportTypesDictionary[movementType]
  )
}

export const parseReportLabelToType = (
  movementLabel: IReportLabel,
): IReportType => {
  if (!movementLabel) return 'MONTHLY_USER_REPORT'

  const movementTypeValue = Object.keys(reportTypesDictionary).find(
    (key) => (reportTypesDictionary as any)[key] === movementLabel,
  ) as IReportType

  return movementTypeValue
}

export const parseReportStatusToLabel = (status: string): string => {
  if (status === 'pending') {
    return 'Procesando'
  }

  if (status === 'done') {
    return 'Disponible'
  }

  if (status === 'error') {
    return 'Error al generar'
  }

  if (status === 'expired') {
    return 'Expirado'
  }

  return 'Desconocido'
}
