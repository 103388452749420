import { Div } from '@/components'
import { Text } from '@punto-ui/react'
import React from 'react'

interface AguinaldoCardProps {
  label: string
  children: React.ReactNode
}

export const AguinaldoCard = ({ label, children }: AguinaldoCardProps) => {
  return (
    <Div>
      <Text css={{ marginBottom: '$1' }}>{label}</Text>
      <Div>{children}</Div>
    </Div>
  )
}
