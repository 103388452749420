import { FormProvider, useForm } from 'react-hook-form'
import { ExportReceiptSchemaType } from './form'
import { Button, Text } from '@punto-ui/react'
import { Div } from '@/components/Div'
import { IDefaultDrawerProps } from '../types'
import { useHandleRequestExportReceipt } from '@/libs/react-query/mutations/reports/useHandleRequestExportReceipt'
import useToast from '@/hooks/useToast'
import { useExportReceiptConfiguration } from './useExportReceiptConfiguration'
import { FormRenderer } from '@/components/Forms'
import { useReportReceipt } from '@/libs/react-query/hooks/useReportReceipt'
import {
  useAvailableCells,
  usePayments,
  usePayrolls,
  useVacations,
} from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { Drawer, DrawerContainer } from '@/components/Drawer'

export const ExportReceipt = (
  props: IDefaultDrawerProps & {
    receiptId: string
    onClickExport?: () => void
  },
) => {
  const { data: payrolls, isLoading: isLoadingPayrolls } = usePayrolls()

  const methods = useForm<ExportReceiptSchemaType>({
    defaultValues: {
      payrollId: '',
      usersIds: [],
    },
  })

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payments')),
  )
  const { data: availableCellsVacations } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('vacation')),
  )

  const { data: payments, isFetching: isFetchingPayments } = usePayments({
    cellsIds: availableCells?.map((c) => c.id) || [],
    name: '',
    page: 1,
    pageSize: 200,
    types: [],
  })

  const { data: receipt, isLoading: isLoadingReceipt } = useReportReceipt(
    props.receiptId,
  )

  const { data: vacations, isFetching: isFetchingVacations } = useVacations({
    cellsIds: availableCellsVacations?.map((c) => c.id) || [],
    page: 0,
    perPage: 200,
  })

  const {
    isLoading: isLoadingRequestExport,
    mutateAsync: handleRequestExportReceipt,
  } = useHandleRequestExportReceipt()

  const toast = useToast()

  const configurationForm = useExportReceiptConfiguration(receipt?.type || '')

  return (
    <FormProvider {...methods}>
      <DrawerContainer open={props.isOpen} onOpenChange={props.setIsOpen}>
        <Drawer
          onOpenChange={props.setIsOpen}
          noPadding
          content={
            <Div
              css={{
                position: 'relative',
                minWidth: 900,
                height: '100vh',
              }}
            >
              <Div
                css={{
                  padding: '$4',
                }}
              >
                <Text
                  variant={'paragraph'}
                  size="xl"
                  css={{
                    color: '$brand_primary_pure',
                    fontWeight: 'bold',
                  }}
                >
                  Exportación de Recibo
                </Text>
              </Div>
              <Div css={{ padding: '0 $4' }}>
                <Text
                  variant={'paragraph'}
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Selecciona los colaboradores a exportar
                </Text>
                <Div
                  css={{
                    padding: '$4 0',
                  }}
                >
                  <FormRenderer items={configurationForm.data} />
                </Div>
              </Div>
              <Div
                css={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 56,
                  // background: '$interface_light_pure',

                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  css={{ marginLeft: '$4' }}
                  disabled={
                    isLoadingRequestExport ||
                    isLoadingPayrolls ||
                    isLoadingReceipt ||
                    isFetchingPayments ||
                    isFetchingVacations
                  }
                  isLoading={isLoadingRequestExport}
                  onClick={
                    props.onClickExport
                      ? props.onClickExport
                      : () => {
                          const values = methods.getValues()

                          const selectedPayroll = payrolls?.find(
                            (p) => p.id === values.payrollId,
                          )

                          const selectedPayment = payments?.data?.find(
                            (p) => p.id === values.paymentId,
                          )

                          const selectedVacation = vacations?.data?.find(
                            (v) => v.id === values.vacationId,
                          )

                          const usersIds =
                            receipt?.type === 'payroll'
                              ? selectedPayroll?.users.map((u) => u.id) || []
                              : receipt?.type === 'payment'
                              ? selectedPayment?.users.map((u) => u.user_id) ||
                                []
                              : receipt?.type === 'vacations'
                              ? selectedVacation?.users.map((u) => u.user_id) ||
                                []
                              : values.usersIds

                          handleRequestExportReceipt({
                            receipt_id: props.receiptId,
                            users_ids: usersIds,
                            payrollId: values.payrollId,
                            paymentId: values.paymentId,
                            vacationId: values.vacationId,
                          })

                          const toastId = Date.now()

                          toast.addToast({
                            title: 'Reporte solicitado',
                            description:
                              'El reporte se esta generando, va al modulo de reportes para conferir su estado.',
                            type: 'positive',
                            timeout: 12000,
                            id: toastId,
                            buttonLabel: 'Cerrar',
                            callback: () => {
                              toast.removeToast(toastId)
                            },
                          })
                        }
                  }
                >
                  Exportar reporte
                </Button>
              </Div>
            </Div>
          }
        />
      </DrawerContainer>
    </FormProvider>
  )
}
