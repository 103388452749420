import { Div } from '@/components'
import { InputDatePicker } from '@/components/InputDatePicker'
import { useWorker } from '@/libs/react-query/hooks'
import {
  CheckCircleIcon,
  LightBulbIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button, Progress, Text, Tooltip } from '@punto-ui/react'
import { useCallback, useState } from 'react'
import useToast from '@/hooks/useToast'
import { useHandleRehireWorker } from '@/libs/react-query/mutations/workers/useHandleRehireWorker'
import dayjs from 'dayjs'

export const QuickWorkerRehire = ({
  workerId,
  closeModal,
}: {
  workerId: string
  closeModal: any
}) => {
  const { data: worker, isLoading: isLoadingWorker } = useWorker(workerId)

  const [hiringDate, setHiringDate] = useState<Date | null>(null)
  const [ipsHiringDate, setIpsHiringDate] = useState<Date | null>(null)

  const toast = useToast()

  const { mutateAsync: handleRehireWorker, isLoading: isLoadingRehire } =
    useHandleRehireWorker()

  const handleRehireUser = async () => {
    if (!worker || !hiringDate) return

    try {
      const toastId = Date.now()

      await handleRehireWorker({
        entrance_date: hiringDate
          ? dayjs(hiringDate).startOf('day').toISOString()
          : '',
        entrance_date_ips: ipsHiringDate
          ? dayjs(ipsHiringDate).startOf('day').toISOString()
          : '',
        user_id: worker.user.id,
      })

      toast.addToast({
        title: 'Colaborador liquidado exitosamente',
        description:
          'El colaborador ha sido liquidado exitosamente y su cuenta ha sido desactivada.',
        type: 'positive',
        id: toastId,
        callback: () => {
          toast.removeToast(toastId)
        },
      })
    } catch (err) {
      const toastId = Date.now()

      toast.addToast({
        title: 'Ocurrió un error al liquidar al colaborador',
        description:
          'Ocurrió un error al liquidar al colaborador, por favor intenta nuevamente.',
        type: 'positive',
        id: toastId,
        callback: () => {
          toast.removeToast(toastId)
        },
      })
    } finally {
      closeModal()
    }
  }

  const lastWorkerLiquidation = worker?.liquidations?.[0]
  const lastWorkerLiquidationInactivationDate =
    lastWorkerLiquidation?.inactivation_date

  const invalidInputsText = !hiringDate
    ? 'Seleccione una fecha de recontratación'
    : ''

  const isRehiringDateDisabled = useCallback(
    (date: Date) => {
      if (!lastWorkerLiquidationInactivationDate) return false

      return dayjs(date).isBefore(
        dayjs(lastWorkerLiquidationInactivationDate).endOf('month'),
      )
    },
    [lastWorkerLiquidationInactivationDate],
  )

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$4',

        maxWidth: 500,
      }}
    >
      <Div
        css={{
          width: '100%',
          minHeight: 36,
          background: '$interface_light_up',
          borderTopRightRadius: '$md',
          borderTopLeftRadius: '$md',
        }}
      />
      {isLoadingWorker ? (
        <Div
          css={{
            height: 26,
            paddingLeft: '$4',
          }}
        >
          <Progress />
        </Div>
      ) : (
        <Text
          variant="subtitle1"
          css={{
            color: '$interface_dark_deep',
            padding: '0 $4',
          }}
        >
          Recontratación de {worker?.user?.name}
        </Text>
      )}

      <Div
        css={{
          height: 1,
          background: '$interface_light_down',
        }}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$4',

          padding: '0 $4',
        }}
      >
        <InputDatePicker
          disabled={isLoadingWorker}
          maxDates={60}
          type={'single'}
          isDisabled={isRehiringDateDisabled}
          firstDate={hiringDate}
          label="Seleccione una nueva fecha de contratación"
          onChange={(dates) => {
            if (dates[0]) {
              setHiringDate(dates[0])
            } else {
              setHiringDate(null)
            }
          }}
          disclaimer={`Fecha mínima: ${
            lastWorkerLiquidationInactivationDate
              ? dayjs(lastWorkerLiquidationInactivationDate)
                  .endOf('month')
                  .add(1, 'day')
                  .format('DD/MM/YYYY')
              : ''
          }`}
        />

        <InputDatePicker
          disabled={isLoadingWorker}
          maxDates={60}
          type={'single'}
          firstDate={ipsHiringDate}
          label="Seleccione una nueva fecha de contratación IPS"
          onChange={(dates) => {
            if (dates[0]) {
              setIpsHiringDate(dates[0])
            } else {
              setIpsHiringDate(null)
            }
          }}
        />

        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            svg: {
              height: 16,
              width: 16,
              color: '$brand_primary_pure',
            },
          }}
        >
          <Div>
            <LightBulbIcon />
          </Div>
          <Text
            variant="description"
            css={{
              color: '$interface_dark_deep',
            }}
          >
            Informaciones basicas del colaborador serán salvas, y nuevas pueden
            ser editadas en los detalles del colaborador..
          </Text>
        </Div>
      </Div>

      <Div
        css={{
          height: 1,
          background: '$interface_light_down',
        }}
      />

      <Div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '$4',
          padding: '0 $4',
        }}
      >
        <Button
          variant={'tertiary'}
          onClick={closeModal}
          icon={<XMarkIcon />}
          disabled={isLoadingWorker || isLoadingRehire}
        >
          Cancelar
        </Button>
        {invalidInputsText ? (
          <Tooltip message={invalidInputsText} arrow side={'top'}>
            <Div>
              <Button disabled={true} icon={<CheckCircleIcon />}>
                Recontratar
              </Button>
            </Div>
          </Tooltip>
        ) : (
          <Button
            disabled={isLoadingWorker || isLoadingRehire}
            isLoading={isLoadingWorker || isLoadingRehire}
            icon={<CheckCircleIcon />}
            onClick={handleRehireUser}
          >
            Recontratar
          </Button>
        )}
      </Div>
    </Div>
  )
}
