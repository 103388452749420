import { SmartColumnType } from '@/components/SmartTable/types'
import { useHandleUpdatePayrollFields } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollFields'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { PayrollPlaygroundTableType } from '../../types'
import { formattedHourToMinutes } from '@/utils/date'
import { useCan } from '@/hooks'

export const usePayrollPaidHoursTable = () => {
  const { control, getValues } = useFormContext<PayrollPlaygroundTableType>()
  const { mutateAsync: handleUpdatePayrollFields } =
    useHandleUpdatePayrollFields()

  const payrollId = useWatch({
    control,
    name: 'id',
  })

  const status = useWatch({
    control,
    name: 'status',
  })

  const canEditPayroll = useCan(['payroll.edit', 'payroll.closePayroll'])

  // const { control } = useFormContext<PayrollPlaygroundTableType>()
  const columns: SmartColumnType[] = useMemo(() => {
    const isClosed = status === 'closed' || !canEditPayroll

    return [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Dias',
        name: 'Dias',
        items: [
          {
            type: 'input-text',
            name: 'naturalPaidDays',
            draggable: null,
            header: 'Natur. Pagos',
            disabled: true,
            inputType: 'no-mask',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'countablePaidDays',
            draggable: null,
            header: 'Contab. Pagos',
            disabled: true,
            inputType: 'no-mask',
            width: 140,
          },
        ],
      },
      {
        type: 'subheader',
        header: '.',
        name: '.',
        items: [
          {
            type: 'input-text',
            name: 'baseSalary',
            draggable: null,
            header: 'Salario Base (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 170,
          },
          {
            type: 'input-text',
            name: 'totalWorkedDaysCompensation',
            draggable: null,
            header: 'Total Dias (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 140,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Horas Diurnas',
        name: 'Horas Diurnas',
        items: [
          {
            type: 'input-text',
            name: 'totalMorningOrdinaryHours',
            draggable: null,
            header: 'Ord.',
            disabled: true,
            inputType: 'long-hour',
            width: 80,
          },
          {
            type: 'input-text',
            inputType: 'long-hour',
            name: 'totalMorningExtraHours',
            draggable: null,
            header: 'Extra',
            enableEmptySubmit: true,
            disabled: isClosed,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                morningExtraHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
            width: 80,
          },
          {
            type: 'input-text',
            name: 'totalMorningExtraValue',
            draggable: null,
            header: 'Extra (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'totalMorningHolidayHours',
            draggable: null,
            header: 'Feriado',
            disabled: isClosed,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                morningHolidayHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
            enableEmptySubmit: true,
            inputType: 'long-hour',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'totalMorningHolidayValue',
            draggable: null,
            header: 'Feriado (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'totalMorningHours',
            draggable: null,
            header: 'Totales',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'totalMorningValue',
            draggable: null,
            header: 'Totales (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 125,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Horas Nocturnas',
        name: 'Horas Nocturnas',
        items: [
          {
            type: 'input-text',
            name: 'totalNightOrdinaryHours',
            draggable: null,
            header: 'Ord.',
            disabled: isClosed,
            enableEmptySubmit: true,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                nightHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
            inputType: 'long-hour',
            width: 80,
          },
          {
            type: 'input-text',
            name: 'additionalNightHours',
            draggable: null,
            header: 'Adc. Noct.',
            disabled: true,
            inputType: 'money-guarani',
            width: 120,
          },
          {
            type: 'input-text',
            name: 'totalNightExtraHours',
            draggable: null,
            header: 'Extra',
            enableEmptySubmit: true,
            disabled: isClosed,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                nightExtraHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
            inputType: 'long-hour',
            width: 80,
          },
          {
            type: 'input-text',
            name: 'totalNightExtraValue',
            draggable: null,
            header: 'Extra (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 120,
          },
          {
            type: 'input-text',
            name: 'totalNightHolidayHours',
            draggable: null,
            header: 'Feriado',
            disabled: isClosed,
            enableEmptySubmit: true,
            handleSubmitValue: async (value) => {
              const { data } = getValues()
              await handleUpdatePayrollFields({
                payroll_id: payrollId,
                user_id: data[value.index].userId,
                nightHolidayHours: value.data.value
                  ? formattedHourToMinutes(value.data.value as string) / 60
                  : null,
              })
            },
            inputType: 'long-hour',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'totalNightHolidayValue',
            draggable: null,
            header: 'Feriado (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 125,
          },
          {
            type: 'input-text',
            name: 'totalNightHours',
            draggable: null,
            header: 'Totales',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'totalNightValue',
            draggable: null,
            header: 'Totales (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 125,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Totales em Haber',
        name: 'Totales em Haber',
        items: [
          {
            type: 'input-text',
            name: 'totalHours',
            draggable: null,
            header: 'Totales',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'totalHoursValue',
            draggable: null,
            header: 'Totales (G$)',
            disabled: true,
            inputType: 'money-guarani',
            width: 125,
          },
        ],
      },
    ] as SmartColumnType[]
  }, [status, getValues, payrollId, handleUpdatePayrollFields])

  return columns
}
