import { useMemo, useRef } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useFormContext, useWatch } from 'react-hook-form'
import { useUpdateUsersCompanyConfigs } from '@/libs/react-query/mutations/company/useHandleUpdateUsersCompanyConfigs'
import { inactivationLabelToType, inactivationTypeToLabel } from '../parser'
import { useHandleChangeLiquidationType } from '../hooks/useHandleChangeLiquidationType'
import { useCan } from '@/hooks'

export const useWorkerConfigs = () => {
  const isMakingRequest = useRef(false)
  const { data: company } = useMyCompany()
  const isLiquidationDisabled = useIsInactivateWorkerFormDisabled()
  const {
    mutateAsync: handleUpdateUsersConfigs,
    isLoading: isFetchingUsersConfigs,
  } = useUpdateUsersCompanyConfigs()
  const { control, getValues } = useFormContext<InactivateWorkerSchema>()
  const handleChangeLiquidationType = useHandleChangeLiquidationType()
  const canChangeConfig = useCan(['workers.changeConfig'])
  const [
    paymentConfigId,
    liquidationConfigId,
    aguinaldoConfigId,
    vacationConfigId,
    inactivationType,
  ] = useWatch({
    control,
    name: [
      'data.payment_config_id',
      'data.liquidation_config_id',
      'data.aguinaldo_config_id',
      'data.vacation_config_id',
      'data.inactivation_type',
    ],
  })

  const form = useMemo(() => {
    const paymentConfigOptions =
      company?.paymentConfigGroups.map((group) => ({
        label: group.name,
        value: group.id,
      })) || []
    const vacationConfigOptions =
      company?.vacationConfigGroups.map((group) => ({
        label: group.name,
        value: group.id,
      })) || []
    const liquidationConfigOptions =
      company?.liquidationConfigGroups.map((group) => ({
        label: group.name,
        value: group.id,
      })) || []
    const aguinaldoConfigOptions =
      company?.aguinaldoConfigGroups.map((group) => ({
        label: group.name,
        value: group.id,
      })) || []

    const isFormEnabled = !isLiquidationDisabled && canChangeConfig

    const reasonForm: InactiveWorkerField[] = [
      {
        disabled: !isFormEnabled,
        _type: 'dropdown',
        name: 'data.payment_config_id',
        label: 'Configuración de Pagos',
        options: paymentConfigOptions,
        defaultOption: paymentConfigOptions.find(
          (p) => p.value === paymentConfigId,
        ) || {
          label: '',
          value: '',
        },
        onValueChange: async (option) => {
          if (isFetchingUsersConfigs || isMakingRequest.current) return
          const data = getValues('data')
          const original = getValues('original')

          if (original.liquidation_config_id === option) return
          isMakingRequest.current = true
          await handleUpdateUsersConfigs({
            users_ids: [data.user_id],
            payment_config_id: option,
          })
          isMakingRequest.current = false
        },
      },
      {
        disabled: !isFormEnabled,
        _type: 'dropdown',
        name: 'data.liquidation_config_id',
        label: 'Configuración de Liquidación',
        options: liquidationConfigOptions,
        defaultOption: liquidationConfigOptions.find(
          (p) => p.value === liquidationConfigId,
        ) || {
          label: '',
          value: '',
        },
        onValueChange: async (option) => {
          if (isFetchingUsersConfigs || isMakingRequest.current) return
          const data = getValues('data')
          const original = getValues('original')

          if (original.liquidation_config_id === option) return
          isMakingRequest.current = true
          await handleUpdateUsersConfigs({
            users_ids: [data.user_id],
            liquidation_config_id: option,
          })
          isMakingRequest.current = false
        },
      },
      {
        disabled: !isFormEnabled,
        _type: 'dropdown',
        name: 'data.aguinaldo_config_id',
        label: 'Configuración de Aguinaldo',
        options: aguinaldoConfigOptions,
        defaultOption: aguinaldoConfigOptions.find(
          (p) => p.value === aguinaldoConfigId,
        ) || {
          label: '',
          value: '',
        },
        onValueChange: async (option) => {
          if (isFetchingUsersConfigs || isMakingRequest.current) return
          const data = getValues('data')
          const original = getValues('original')

          if (original.liquidation_config_id === option) return
          isMakingRequest.current = true
          await handleUpdateUsersConfigs({
            users_ids: [data.user_id],
            aguinaldo_config_id: option,
          })
          isMakingRequest.current = false
        },
      },
      {
        disabled: !isFormEnabled,
        _type: 'dropdown',
        name: 'data.vacation_config_id',
        label: 'Configuración de Vacaciones',
        options: vacationConfigOptions,
        defaultOption: vacationConfigOptions.find(
          (p) => p.value === vacationConfigId,
        ) || {
          label: '',
          value: '',
        },
        onValueChange: async (option) => {
          console.log(option)
          if (isFetchingUsersConfigs || isMakingRequest.current) return
          const data = getValues('data')
          const original = getValues('original')

          if (original.liquidation_config_id === option) return
          isMakingRequest.current = true
          await handleUpdateUsersConfigs({
            users_ids: [data.user_id],
            vacation_config_id: option,
          })
          isMakingRequest.current = false
        },
      },
      {
        disabled: isLiquidationDisabled,
        _type: 'dropdown',
        name: 'data.inactivation_type',
        label: 'Tipo de Liquidación',
        options: Object.entries(inactivationLabelToType).map(
          ([key, value]) => ({
            label: key,
            value,
          }),
        ),
        defaultOption: {
          value: inactivationLabelToType[inactivationType] || '',
          label: inactivationType || '',
        },
        onValueChange: async (option) => {
          if (isFetchingUsersConfigs || isMakingRequest.current) return
          const original = getValues('original')
          if (original.inactivation_type === inactivationTypeToLabel[option])
            return

          isMakingRequest.current = true
          await handleChangeLiquidationType(option)
          isMakingRequest.current = false
        },
      },
    ]

    return reasonForm
  }, [
    canChangeConfig,
    inactivationType,
    handleChangeLiquidationType,
    isLiquidationDisabled,
    company,
    paymentConfigId,
    liquidationConfigId,
    aguinaldoConfigId,
    vacationConfigId,
    getValues,
    isFetchingUsersConfigs,
    handleUpdateUsersConfigs,
  ])

  return {
    reasons: form,
  }
}
