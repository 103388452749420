import { useUpdateUserLiquidationConcepts } from '@/libs/react-query/mutations/company/useHandleUpdateLiquidationConcepts'
import { useHandleUpdateLiquidation } from './useHandleUpdateLiquidation'
import { useFormContext } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { IUpdateUserLiquidationConceptsDTO } from '@/libs/react-query/types/company'

export const useHandleChangeLiquidationType = () => {
  const methods = useFormContext<InactivateWorkerSchema>()
  const { mutateAsync: handleUpdateUserLiquidationConcepts } =
    useUpdateUserLiquidationConcepts()
  const { handleUpdateUserLiquidation } = useHandleUpdateLiquidation()
  const handleChangeLiquidationType = async (liquidationType: string) => {
    const liquidationId = methods.getValues('liquidationId')
    const data = methods.getValues('data')
    const original = methods.getValues('original')
    const oldInactivationType = original.inactivation_type

    if (!liquidationType) return

    const liquidationActivationTypeType: Record<
      string,
      IUpdateUserLiquidationConceptsDTO['entities']
    > = {
      mutual_consent: [
        {
          type: 'basic',
          id: 'discount_prewarning',
        },
      ],
      testing_period: [
        {
          type: 'basic',
          id: 'prewarning',
        },
        {
          type: 'basic',
          id: 'indemnification',
        },
        {
          type: 'basic',
          id: 'caused_vacations',
        },
        {
          type: 'basic',
          id: 'discount_prewarning',
        },
      ],
      unjustified_dismissal: [
        {
          type: 'basic',
          id: 'discount_prewarning',
        },
      ],
      justified_dismissal: [
        {
          type: 'basic',
          id: 'prewarning',
        },
        {
          type: 'basic',
          id: 'indemnification',
        },
        {
          type: 'basic',
          id: 'discount_prewarning',
        },
      ],
      resignation: [
        {
          type: 'basic',
          id: 'prewarning',
        },
        {
          type: 'basic',
          id: 'indemnification',
        },
        {
          type: 'basic',
          id: 'proportional_vacations',
        },
      ],
    }

    const entities = liquidationActivationTypeType[liquidationType] || []
    const entitiesToActivate =
      liquidationActivationTypeType[oldInactivationType] || []

    const entitiesToActivateNotConflicting = entitiesToActivate.filter(
      (entity) => {
        const isConflicting = entities.some(
          (entityToDeactivate) => entityToDeactivate.id === entity.id,
        )

        return !isConflicting
      },
    )

    methods.setValue('data.inactivation_type', liquidationType)

    if (entities.length) {
      await handleUpdateUserLiquidationConcepts({
        entities: [...entities],
        user_id: data.user_id,
        liquidation_id: liquidationId,
        is_active: false,

        shouldNotInvalidate: true,
      })
    }

    if (entitiesToActivateNotConflicting.length) {
      await handleUpdateUserLiquidationConcepts({
        entities: [...entitiesToActivateNotConflicting],
        liquidation_id: liquidationId,
        user_id: data.user_id,
        is_active: true,

        shouldNotInvalidate: true,
      })
    }

    await handleUpdateUserLiquidation()
  }

  return handleChangeLiquidationType
}
