import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { IdentificationIcon } from '@heroicons/react/24/outline'
import { CompanyInfos } from './CompanyInfos'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'

export default function Empresa({ onlyTabs = false }: { onlyTabs?: boolean }) {
  const { isLoading: isLoadingCompany } = useMyCompany()

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="basic-infos"
      actions={[]}
      tabs={
        isLoadingCompany
          ? []
          : [
              {
                icon: <IdentificationIcon />,
                label: 'Informaciones Basicas',
                value: 'basic-infos',
                scrollable: true,
                component: <CompanyInfos />,
              },
            ]
      }
    />
  )
}

export const getServerSideProps = withSSRAuth(async () => {
  return {
    props: {},
  }
})
