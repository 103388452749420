import { Button } from '@punto-ui/react'
import {
  useGroupInfo,
  useAguinaldoGroupConcepts,
  useAguinaldoDiscountsConfiguration,
  useAguinaldoEarningsConfiguration,
  useAguinaldoHoursConfiguration,
} from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div } from '@/components'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '../../..'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useCan } from '@/hooks'
import { Drawer, DrawerContainer } from '@/components/Drawer'

interface AguinaldosConfigDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

const MAX_DRAWER_WIDTH = '70vw'

export const AguinaldosConfigDrawer = ({
  handleCloseDrawer,
  isOpen,
  isCreation = false,
}: AguinaldosConfigDrawerProps) => {
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])

  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ConfigSchema>()

  const { data: company } = useMyCompany()

  const { data: groupInfo } = useGroupInfo()
  const { data: aguinaldoGroupConcepts } = useAguinaldoGroupConcepts()
  const { data: aguinaldoHours } = useAguinaldoHoursConfiguration()
  const { data: aguinaldoEarnings } = useAguinaldoEarningsConfiguration()
  const { data: aguinaldoDiscounts } = useAguinaldoDiscountsConfiguration()

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()

  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreateAguinaldoConfig = async () => {
    const isValid = await trigger('aguinaldo')

    if (!isValid) {
      console.log('error', errors)
      return
    }

    const data = getValues()

    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const includedConcepts = orderedCompanyConcepts.filter(
      (_, index) => data.aguinaldo?.concepts[index],
    )

    if (isCreation) {
      await handleCreateCompanyConfig({
        aguinaldo: {
          concepts: includedConcepts,
          name: data.aguinaldo?.name || '',

          ordinaryNightHours: !!data.aguinaldo?.ordinaryNightHours,

          extraNightHours: !!data.aguinaldo?.extraNightHours,
          extraMorningHours: !!data.aguinaldo?.extraMorningHours,

          holidayMorningHours: !!data.aguinaldo?.holidayMorningHours,
          holidayNightHours: !!data.aguinaldo?.holidayNightHours,

          discountMorningHours: !!data.aguinaldo?.discountMorningHours,
          discountNightHours: !!data.aguinaldo?.discountNightHours,

          familiarBonus: !!data.aguinaldo?.familiarBonus,
          vacation_value: !!data.aguinaldo?.vacation_value,
          proportional_vacations: !!data.aguinaldo?.proportional_vacations,
          mobility: !!data.aguinaldo?.mobility,
          bonus: !!data.aguinaldo?.bonus,
          commission: !!data.aguinaldo?.commission,
          gift: !!data.aguinaldo?.gift,
          otherEarns: !!data.aguinaldo?.otherEarns,

          ips9: !!data.aguinaldo?.ips9,
          antecipate: !!data.aguinaldo?.antecipate,
          loan: !!data.aguinaldo?.loan,
          lost: !!data.aguinaldo?.lost,
          purchase: !!data.aguinaldo?.purchase,
          judicial: !!data.aguinaldo?.judicial,
          excess: !!data.aguinaldo?.excess,
          otherDiscount: !!data.aguinaldo?.otherDiscount,
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        aguinaldo: {
          id: data.aguinaldo?.id || '',
          concepts: includedConcepts,
          name: data.aguinaldo?.name || '',

          ordinaryNightHours: !!data.aguinaldo?.ordinaryNightHours,

          extraNightHours: !!data.aguinaldo?.extraNightHours,
          extraMorningHours: !!data.aguinaldo?.extraMorningHours,

          holidayMorningHours: !!data.aguinaldo?.holidayMorningHours,
          holidayNightHours: !!data.aguinaldo?.holidayNightHours,

          discountMorningHours: !!data.aguinaldo?.discountMorningHours,
          discountNightHours: !!data.aguinaldo?.discountNightHours,

          familiarBonus: !!data.aguinaldo?.familiarBonus,
          vacation_value: !!data.aguinaldo?.vacation_value,
          proportional_vacations: !!data.aguinaldo?.proportional_vacations,
          mobility: !!data.aguinaldo?.mobility,
          bonus: !!data.aguinaldo?.bonus,
          commission: !!data.aguinaldo?.commission,
          gift: !!data.aguinaldo?.gift,
          otherEarns: !!data.aguinaldo?.otherEarns,

          ips9: !!data.aguinaldo?.ips9,
          antecipate: !!data.aguinaldo?.antecipate,
          loan: !!data.aguinaldo?.loan,
          lost: !!data.aguinaldo?.lost,
          purchase: !!data.aguinaldo?.purchase,
          judicial: !!data.aguinaldo?.judicial,
          excess: !!data.aguinaldo?.excess,
          otherDiscount: !!data.aguinaldo?.otherDiscount,
        },
      })
    }

    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{ overflow: 'scroll', maxHeight: '100vh', paddingBottom: 32 }}
          >
            <FormGroup
              type="fixed"
              title="Informaciones del Grupo"
              items={groupInfo}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH }}
            />
            <FormGroup
              type="fixed"
              title="Horas para Calculo de Aguinaldos"
              items={aguinaldoHours}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$8' }}
            />
            <FormGroup
              type="fixed"
              title="Pagos para Calculo de Aguinaldos"
              items={aguinaldoEarnings}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$8' }}
            />
            <FormGroup
              type="fixed"
              title="Descuentos para Calculo de Aguinaldos"
              items={aguinaldoDiscounts}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$8' }}
            />
            <FormGroup
              type="fixed"
              title="Conceptos para Calculo de Aguinaldos"
              items={aguinaldoGroupConcepts}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$8' }}
            />
            <Button
              disabled={!canCreateAguinaldos}
              css={{ marginTop: '$10', minWidth: 300 }}
              onClick={handleCreateAguinaldoConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear' : 'Actualizar'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
