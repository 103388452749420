import { useEffect } from 'react'
import { useTabStore } from '@/store'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useCan } from '@/hooks'

export const useHandlePdfHubActions = () => {
  const canCreatePdf = useCan(['reports.createTemplate'])

  const { setActions, addTab, setActiveTab, tabs } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('reportsV2', 'generator'),
    setActiveTab: state.setActiveTab,
    tabs: state.reportsV2.tabs,
    setActions: state.setActionsTab,
    addTab: state.addTab,
  }))

  useEffect(() => {
    if (canCreatePdf) {
      setActions('reportsV2', [
        {
          action: () => {
            const id = Date.now().toString()

            let existingTab = tabs.find((tab) => tab.type === 'add-pdf')
            if (!existingTab) {
              existingTab = {
                id,
                type: 'add-pdf',
                label: 'Crear PDF',
                metadata: {
                  creation: true,
                },
              }
              addTab('reportsV2', [existingTab])
            }
            setActiveTab('reportsV2', existingTab.id)
          },
          label: 'Crear PDF',
          width: 180,
          disabled: !canCreatePdf,
          variant: 'primary',
          icon: <PlusIcon />,
        },
      ])
    } else {
      setActions('reportsV2', [])
    }
  }, [canCreatePdf])
}
