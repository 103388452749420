import React from 'react'
import { Context } from '../index'

export const useMovementsContext = () => {
  const context = React.useContext(Context)

  if (!context) {
    throw new Error('useMovements must be used within a context')
  }

  return context
}
