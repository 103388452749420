import { useAuth } from '@/contexts/hooks'
import { useUserPermissions } from '@/libs/react-query/hooks/useUserPermissions'
import { useMemo } from 'react'

export function useCan(params: string[], only?: boolean) {
  const { isAuthenticated } = useAuth()
  const { data: userPermissions } = useUserPermissions()

  const userHasValidPermissions = useMemo(() => {
    const firstParam = params[0]
    const firstParamsModule = firstParam.split('.')[0]
    const allPermissionInModule = `${firstParamsModule}.all`

    const paramsToCompare = only
      ? [...params]
      : [...params, allPermissionInModule]

    return userPermissions?.some((permission) =>
      paramsToCompare.includes(permission),
    )
  }, [params, userPermissions])

  if (!isAuthenticated) {
    return false
  }

  return userHasValidPermissions
}
