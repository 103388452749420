import {
  generateRandomBoolean,
  generateRandomDate,
  generateRandomDescription,
  generateRandomNumberUnder10000000,
  generateRandomPaymentCategory,
  generateRandomSiOrEmpty,
  generateRandomTitle,
} from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const singlePaymentColumns: IReportBuilderColumnType[] = [
  {
    type: 'payments',
    value: 'title',
    valueType: 'string' as const,
    label: 'Titulo',
    getRandomPlaceholder: generateRandomTitle,
    subgroup: 'Fechas',
  },
  {
    type: 'payments',
    value: 'description',
    getRandomPlaceholder: generateRandomDescription,
    label: 'Descripcion',
    valueType: 'string' as const,
    subgroup: 'Fechas',
  },
  {
    type: 'payments',
    value: 'ips',
    getRandomPlaceholder: generateRandomSiOrEmpty,
    valueType: 'boolean' as const,
    label: 'IPS',
    subgroup: 'Fechas',
  },
  {
    type: 'payments',
    value: 'type',
    getRandomPlaceholder: generateRandomPaymentCategory,
    label: 'Tipo',
    valueType: 'string' as const,
    subgroup: 'Fechas',
  },
  {
    type: 'payments',
    value: 'date',
    getRandomPlaceholder: generateRandomDate,
    label: 'Fecha',
    valueType: 'date' as const,
    subgroup: 'Fechas',
  },
  {
    type: 'payments',
    value: 'value_type',
    valueType: 'string' as const,
    label: 'Tipo de valor',
    getRandomPlaceholder: () => {
      return generateRandomBoolean() ? 'Percentage' : 'Valor nominal'
    },
    subgroup: 'Fechas',
  },
  {
    type: 'payments',
    valueType: 'number' as const,
    value: 'value',
    label: 'Valor',
    getRandomPlaceholder: generateRandomNumberUnder10000000,
    subgroup: 'Fechas',
  },
]
