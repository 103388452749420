import { useQuery } from 'react-query'
import { useMe } from '../../useMe'
import { apiV2 } from '@/services/api'
import {
  FetchHoursProps,
  IPaginatedWorkedHours,
} from '@/libs/react-query/types'
import dayjs from 'dayjs'

export const fetchWorkersHours = async (props: FetchHoursProps) => {
  const response = await apiV2.post<IPaginatedWorkedHours>(
    '/punches/v2/get-worked-hours',
    {
      date: props.date,
      name: props.name,
      page: props.page,
      perPage: props.perPage,
      autocomplete: props.autocomplete,
    },
    {
      params: {
        cellsIds: props?.cellsIds?.join(','),
      },
    },
  )

  return response.data
}

export const useWorkersHours = (props: FetchHoursProps) => {
  const { data: user } = useMe()
  const query = useQuery(
    [
      'workers-hours',
      props.date,
      props.name,
      props.cellsIds,
      props.page,
      props.perPage,
      props.autocomplete,
    ],
    () => fetchWorkersHours(props),
    {
      keepPreviousData: true,
      enabled:
        !!props?.date &&
        !!user?.company_id &&
        !!props.cellsIds?.length &&
        dayjs(props.date).isValid(),
    },
  )
  return query
}
