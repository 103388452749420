import { Button } from '@punto-ui/react'
import { useHolidayColumns } from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div } from '@/components'
import { ConfigSchema } from '../../..'
import { useFormContext } from 'react-hook-form'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { queryClient } from '@/libs/react-query'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import dayjs from 'dayjs'
import { useCan } from '@/hooks'
import { Drawer, DrawerContainer } from '@/components/Drawer'

interface HolidayDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

const MAX_DRAWER_WIDTH = '70vw'

export const HolidayDrawer = ({
  handleCloseDrawer,
  isCreation,
  isOpen,
}: HolidayDrawerProps) => {
  const canCreateHolidays = useCan(['configurations.holidays'])
  const { data: companyAllowedPunch } = useHolidayColumns()

  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ConfigSchema>()

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()
  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreatePunchConfig = async () => {
    const isValid = await trigger('holiday')

    if (!isValid) {
      console.log(errors)
      return
    }

    const data = getValues()

    if (isCreation) {
      await handleCreateCompanyConfig({
        holiday: {
          active: !!data.holiday?.active,
          should_repeat_every_year: !!data.holiday?.should_repeat_every_year,
          date: dayjs(data.holiday?.date?.[0]).startOf('day').toISOString(),
          name: data.holiday?.name || '',
          day_of_week: data.holiday?.isWeekDay
            ? Number(data.holiday?.day_of_week || 0)
            : null,
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        holiday: {
          id: data.holiday?.id || '',
          active: !!data.holiday?.active,
          should_repeat_every_year: !!data.holiday?.should_repeat_every_year,
          date: dayjs(data.holiday?.date?.[0]).startOf('day').toISOString(),
          name: data.holiday?.name || '',
          day_of_week: data.holiday?.isWeekDay
            ? Number(data.holiday?.day_of_week || 0)
            : null,
        },
      })
    }

    queryClient.invalidateQueries('my-company')
    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{
              overflow: 'scroll',
              maxHeight: '100vh',
              height: '100%',
              paddingBottom: 32,
              maxWidth: 500,
            }}
          >
            <FormGroup
              type="fixed"
              title="Informaciones del Feriado"
              items={companyAllowedPunch}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH }}
            />
            <Button
              disabled={!canCreateHolidays}
              css={{ marginTop: '$10', minWidth: 300 }}
              onClick={handleCreatePunchConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear' : 'Actualizar'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
