import { InactiveWorkerDTO } from '@/libs/react-query/types'
import { InactivateWorkerDataSchema, InactivateWorkerSchema } from './form'
import dayjs from 'dayjs'
import { formatMinutes, sortRawMonths } from '@/utils/date'
import { IUpdateUserLiquidationDTO } from '@/libs/react-query/types/company'

export const parseLiquidateUserDTO = (
  inactiveWorker: InactiveWorkerDTO,
): InactivateWorkerDataSchema => {
  const salaries = [
    ...Array.from({ length: 6 }).map((_, index) => {
      const salary = inactiveWorker.salaries.at(index)

      if (!salary) {
        return {
          label: '',
          value: 0,
          payrollId: '',
          month: 0,
          disclaimer: '',
        }
      }

      return {
        label: dayjs().set('month', salary.month).format('MMMM'),
        value: salary?.value,
        disclaimer: `${salary?.payrollName}`,

        month: salary.month,
        payrollId: salary.payrollId,
      }
    }),
  ]

  const salariesMonthsSorted = sortRawMonths(salaries.map((s) => s.month))
  const salariesSorted: InactivateWorkerDataSchema['salaries'] = []

  salariesMonthsSorted.forEach((month) => {
    const salary = salaries.find((s) => s.month === month)
    if (salary) {
      salariesSorted.push(salary)
    }
  })

  const aguinaldoMonths = [
    ...inactiveWorker.aguinaldo_months.map((aguinaldoMonth) => {
      return {
        label: dayjs().set('month', aguinaldoMonth.month).format('MMMM'),
        value: aguinaldoMonth.value,
        disclaimer: `${aguinaldoMonth.payrollName}`,
        payrollId: aguinaldoMonth.payrollId,
        month: aguinaldoMonth.month,
      }
    }),
  ].sort((a, b) => a.month - b.month)

  const vacationValues = [
    ...inactiveWorker.vacations.map((v) => {
      return {
        earned_days: v.earned_days,
        used_days: v.used_days,
        value: v.value,
        year: {
          label: v.year.label,
          value: v.year.value,
        },
      }
    }),
  ].sort((a, b) => a.year.value - b.year.value)

  const accumulatedVacations = [
    ...inactiveWorker.accumulated_vacations.map((v) => {
      return {
        earned_days: v.earned_days,
        used_days: v.used_days,
        value: v.value,
        year: {
          label: v.year.label,
          value: v.year.value,
        },
      }
    }),
  ].sort((a, b) => a.year.value - b.year.value)

  const result: InactivateWorkerDataSchema = {
    antiquity: {
      days: inactiveWorker.antiquity.days,
      months: inactiveWorker.antiquity.month,
      years: inactiveWorker.antiquity.year,
    },
    id: inactiveWorker.user_id,
    status: inactiveWorker.status,
    user_id: inactiveWorker.user_id,
    inactivation_type: inactiveWorker.inactivation_type,
    contract_type: 'indefinite',
    entrance_date: inactiveWorker.entrance_date
      ? [
          dayjs(
            dayjs(inactiveWorker.entrance_date).utc().format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).toDate(),
        ]
      : [],
    entrance_date_ips: inactiveWorker.entrance_date_ips
      ? [
          dayjs(
            dayjs(inactiveWorker.entrance_date_ips).utc().format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).toDate(),
        ]
      : [],
    use_ips_date: false,
    inactivation_date: inactiveWorker.inactivation_date
      ? [
          dayjs(
            dayjs(inactiveWorker.inactivation_date).utc().format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).toDate(),
        ]
      : [],
    complement: inactiveWorker.complement,
    reason: inactiveWorker.reason || '',
    signature_date: inactiveWorker.signature_date
      ? [
          dayjs(
            dayjs(inactiveWorker.signature_date).utc().format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).toDate(),
        ]
      : [],

    aguinaldo_config_id: inactiveWorker.aguinaldo_config_id,
    liquidation_config_id: inactiveWorker.liquidation_config_id,
    payment_config_id: inactiveWorker.payment_config_id,
    vacation_config_id: inactiveWorker.vacation_config_id,

    // salary
    base_salary: {
      disclaimer: inactiveWorker.daily_salary.toString(),
      value: inactiveWorker.salary.base_salary,
    },
    payed_days: {
      disclaimer: '',
      value: inactiveWorker.salary.payed_days,
    },
    proportional_salary: {
      disclaimer: inactiveWorker.daily_salary.toString(),
      value: inactiveWorker.salary.proportional_salary,
    },
    extra_morning: {
      disclaimer: formatMinutes(inactiveWorker.salary.extra_morning_hours * 60),
      value: inactiveWorker.salary.extra_morning,
    },
    holiday_morning: {
      disclaimer: formatMinutes(
        inactiveWorker.salary.holiday_morning_hours * 60,
      ),
      value: inactiveWorker.salary.holiday_morning,
    },
    discount_morning: {
      disclaimer: formatMinutes(
        inactiveWorker.salary.discount_morning_hours * 60,
      ),
      value: inactiveWorker.salary.discount_morning,
    },
    ordinary_night: {
      disclaimer: formatMinutes(
        inactiveWorker.salary.ordinary_night_hours * 60,
      ),
      value: inactiveWorker.salary.ordinary_night,
    },
    extra_night: {
      disclaimer: formatMinutes(inactiveWorker.salary.extra_night_hours * 60),
      value: inactiveWorker.salary.extra_night,
    },
    holiday_night: {
      disclaimer: formatMinutes(inactiveWorker.salary.holiday_night_hours * 60),
      value: inactiveWorker.salary.holiday_night,
    },
    discount_night: {
      disclaimer: formatMinutes(
        inactiveWorker.salary.discount_night_hours * 60,
      ),
      value: inactiveWorker.salary.discount_night,
    },
    total_negative_salary: inactiveWorker.salary.total_negative_salary,
    total_positive_salary: inactiveWorker.salary.total_positive_salary,

    total_negative_salary_ips: inactiveWorker.salary.total_negative_salary_ips,
    total_positive_salary_ips: inactiveWorker.salary.total_positive_salary_ips,

    // aguinaldo
    aguinaldo_months: aguinaldoMonths,
    aguinaldo: inactiveWorker.aguinaldo,

    // salaries
    salaries: salariesSorted,
    salary_average: inactiveWorker.salary_average,
    daily_salary: inactiveWorker.last_salaries_daily,

    vacations: vacationValues,
    accumulatedVacations,

    total_vacations: {
      total_caused_vacations:
        inactiveWorker.total_vacations.total_caused_vacations,
      used_days: inactiveWorker.total_vacations.used_days,
      earned_days: inactiveWorker.total_vacations.earned_days,
      value: inactiveWorker.total_vacations.value,
      valueIps: inactiveWorker.total_vacations.valueIps,
    },
    total_accumulated_vacations: {
      total_caused_vacations:
        inactiveWorker.total_accumulated_vacations.total_caused_vacations,
      used_days: inactiveWorker.total_accumulated_vacations.used_days,
      earned_days: inactiveWorker.total_accumulated_vacations.earned_days,
      value: inactiveWorker.total_accumulated_vacations.value,
      valueIps: inactiveWorker.total_accumulated_vacations.valueIps,
    },

    proportional_vacations: {
      corresponding_days: {
        disclaimer: `${inactiveWorker.vacation_antiquity.year} años ${inactiveWorker.vacation_antiquity.month} meses ${inactiveWorker.antiquity.days} dias`,
        value: inactiveWorker.proportional_vacations.corresponding_days,
      },
      received_days: {
        disclaimer: '',
        value: 0,
      },
      missing_days: {
        disclaimer: '',
        value: 0,
      },
      value: inactiveWorker.proportional_vacations.value,
      valueIps: inactiveWorker.proportional_vacations.valueIps,
    },
    discount_proportional_vacations: {
      corresponding_days: {
        disclaimer: `${inactiveWorker.vacation_antiquity.year} años ${inactiveWorker.vacation_antiquity.month} meses ${inactiveWorker.antiquity.days} dias`,
        value:
          inactiveWorker.discount_proportional_vacations.corresponding_days,
      },
      received_days: {
        disclaimer: '',
        value: 0,
      },
      missing_days: {
        disclaimer: '',
        value: 0,
      },
      value: inactiveWorker.discount_proportional_vacations.value,
      valueIps: inactiveWorker.discount_proportional_vacations.valueIps,
    },
    pre_warning: {
      totalDays: {
        disclaimer: `${inactiveWorker.liquidation_antiquity.year} años ${inactiveWorker.liquidation_antiquity.month} meses`,
        value: inactiveWorker.pre_warning.totalDays,
      },
      received_days: {
        disclaimer: '',
        value: inactiveWorker.pre_warning.received_days,
      },
      missing_days: {
        disclaimer: '',
        value: inactiveWorker.pre_warning.missing_days,
      },
      value: inactiveWorker.pre_warning.value,
    },
    discount_pre_warning: {
      totalDays: {
        disclaimer: `${inactiveWorker.liquidation_antiquity.year} años ${inactiveWorker.liquidation_antiquity.month} meses ${inactiveWorker.antiquity.days} dias`,
        value: inactiveWorker.discount_pre_warning.totalDays,
      },
      received_days: {
        disclaimer: '',
        value: inactiveWorker.discount_pre_warning.received_days,
      },
      missing_days: {
        disclaimer: '',
        value: inactiveWorker.discount_pre_warning.missing_days,
      },
      value: inactiveWorker.discount_pre_warning.value,
    },
    indemnification: {
      corresponding_days: {
        disclaimer: `${inactiveWorker.liquidation_antiquity.year} años ${inactiveWorker.liquidation_antiquity.month} meses ${inactiveWorker.antiquity.days} dias`,
        value: inactiveWorker.indemnification.corresponding_days,
      },
      value: inactiveWorker.indemnification.value,
    },
    other_discounts: [
      {
        label: '',
        value: 0,
        id: null,
      },
    ],
    paymentConcepts: inactiveWorker.otherConcepts
      .filter((o) => !o.is_debit)
      .map((o) => ({
        amount: o.amount,
        id: o.entity_id,
        name: o.name || '',
        type: o.type,
        value: o.value,
      })),
    discountConcepts: inactiveWorker.otherConcepts
      .filter((o) => o.is_debit)
      .map((o) => ({
        amount: o.amount,
        id: o.entity_id,
        name: o.name || '',
        type: o.type,
        value: o.value,
      })),
    familiarBonus: {
      value: inactiveWorker.familiar_bonus.value,
      children: inactiveWorker.familiar_bonus.children,
    },
  }

  return result
}

export function isDifferent(value1: any, value2: any): boolean {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return true
    return value1.some((item, index) => isDifferent(item, value2[index]))
  } else if (
    typeof value1 === 'object' &&
    value2 !== null &&
    typeof value2 === 'object'
  ) {
    const keys = new Set([...Object.keys(value1), ...Object.keys(value2)])
    return Array.from(keys).some((key) => isDifferent(value1[key], value2[key]))
  } else {
    return value1 !== value2
  }
}

function compareArrays(array1: any[], array2: any[]): any[] {
  return array1
    .map((item, index) =>
      isDifferent(item, array2[index]) ? array2[index] : undefined,
    )
    .filter((item) => item !== undefined)
}

function isEmptyString(str?: number | string | null): boolean {
  return typeof str === 'string' && str.trim() === ''
}

function getValueOrNullIfEmptyString(
  str?: number | string | null,
): number | null {
  return isEmptyString(str) || str === null || str === undefined
    ? null
    : Number.isNaN(+str)
    ? 0
    : (+str as number)
}

export const inactivationLabelToType: Record<string, string> = {
  'Mutuo Consentimento': 'mutual_consent',
  'Despido injustificado': 'unjustified_dismissal',
  'Despido justificado': 'justified_dismissal',
  Renuncia: 'resignation',
  Abandono: 'abandonment',
  'Periodo Prueba': 'testing_period',
  Muerte: 'death',
}

export const inactivationTypeToLabel: Record<string, string> = {
  mutual_consent: 'Mutuo Consentimento',
  unjustified_dismissal: 'Despido injustificado',
  justified_dismissal: 'Despido justificado',
  resignation: 'Renuncia',
  abandonment: 'Abandono',
  testing_period: 'Periodo Prueba',
  death: 'Muerte',
}

export function compareAndConstructUserLiquidation(
  original: InactivateWorkerDataSchema,
  edited: InactivateWorkerDataSchema,
): IUpdateUserLiquidationDTO {
  const result: IUpdateUserLiquidationDTO = {
    user_id: original.user_id,
  } as IUpdateUserLiquidationDTO

  if (edited?.inactivation_date?.length === 1) {
    result.inactivation_date = dayjs(edited.inactivation_date[0])
      .startOf('day')
      .toISOString()
  }

  if (edited?.signature_date?.length === 1) {
    result.signature_date = dayjs(edited.signature_date[0])
      .startOf('day')
      .toISOString()
  }

  if (edited?.entrance_date?.length === 1) {
    const maybeDate = dayjs(edited.entrance_date[0])
    const maybeOriginalDate = dayjs(original.entrance_date[0])

    const isDifferent = !maybeDate.isSame(maybeOriginalDate, 'day')

    if (maybeDate && isDifferent) {
      result.entrance_date = maybeDate.startOf('day').toISOString()
    }
  }

  if (edited?.entrance_date_ips?.length === 1) {
    const maybeDate = dayjs(edited.entrance_date_ips[0])
    const maybeOriginalDate = dayjs(original.entrance_date_ips[0])

    const isDifferent = !maybeDate.isSame(maybeOriginalDate, 'day')

    if (maybeDate && isDifferent) {
      result.entrance_date_ips = maybeDate.startOf('day').toISOString()
    }
  }

  if (isDifferent(original.inactivation_type, edited.inactivation_type)) {
    result.inactivation_type = edited.inactivation_type
  }

  if (isDifferent(original.contract_type, edited.contract_type)) {
    result.contract_type = edited.contract_type
  }

  if (isDifferent(original.complement, edited.complement)) {
    result.complement = edited.complement
  }

  if (isDifferent(original.reason, edited.reason)) {
    result.reason = edited.reason
  }

  // Comparing and constructing the salary structure
  if (isDifferent(original.base_salary.value, edited.base_salary.value)) {
    result.salary = result.salary || {}
    result.salary.base_salary = getValueOrNullIfEmptyString(
      edited.base_salary.value,
    )
  }

  if (isDifferent(original.payed_days.value, edited.payed_days.value)) {
    result.salary = result.salary || {}
    result.salary.payed_days = getValueOrNullIfEmptyString(
      edited.payed_days.value,
    )
  }

  if (
    isDifferent(original.total_positive_salary, edited.total_positive_salary)
  ) {
    result.salary = result.salary || {}
    result.salary.total_positive_salary = getValueOrNullIfEmptyString(
      edited.total_positive_salary,
    )
  }
  if (
    isDifferent(original.total_negative_salary, edited.total_negative_salary)
  ) {
    result.salary = result.salary || {}
    result.salary.total_negative_salary = getValueOrNullIfEmptyString(
      edited.total_negative_salary,
    )
  }

  if (
    isDifferent(
      original.proportional_salary.value,
      edited.proportional_salary.value,
    )
  ) {
    result.salary = result.salary || {}
    result.salary.proportional_salary = getValueOrNullIfEmptyString(
      edited.proportional_salary.value,
    )
  }

  if (isDifferent(original.extra_morning.value, edited.extra_morning.value)) {
    result.salary = result.salary || {}
    result.salary.extra_morning = getValueOrNullIfEmptyString(
      edited.extra_morning.value,
    )
  }

  if (
    isDifferent(original.holiday_morning.value, edited.holiday_morning.value)
  ) {
    result.salary = result.salary || {}
    result.salary.holiday_morning = getValueOrNullIfEmptyString(
      edited.holiday_morning.value,
    )
  }

  if (
    isDifferent(original.discount_morning.value, edited.discount_morning.value)
  ) {
    result.salary = result.salary || {}
    result.salary.discount_morning = getValueOrNullIfEmptyString(
      edited.discount_morning.value,
    )
  }

  if (isDifferent(original.ordinary_night.value, edited.ordinary_night.value)) {
    result.salary = result.salary || {}
    result.salary.ordinary_night = getValueOrNullIfEmptyString(
      edited.ordinary_night.value,
    )
  }

  if (isDifferent(original.extra_night.value, edited.extra_night.value)) {
    result.salary = result.salary || {}
    result.salary.extra_night = getValueOrNullIfEmptyString(
      edited.extra_night.value,
    )
  }

  if (isDifferent(original.holiday_night.value, edited.holiday_night.value)) {
    result.salary = result.salary || {}
    result.salary.holiday_night = getValueOrNullIfEmptyString(
      edited.holiday_night.value,
    )
  }

  if (isDifferent(original.discount_night.value, edited.discount_night.value)) {
    result.salary = result.salary || {}
    result.salary.discount_night = getValueOrNullIfEmptyString(
      edited.discount_night.value,
    )
  }

  // Comparing and constructing the aguinaldo structure
  if (isDifferent(original.aguinaldo, edited.aguinaldo)) {
    result.aguinaldo = getValueOrNullIfEmptyString(edited.aguinaldo)
  }

  if (isDifferent(original.aguinaldo_months, edited.aguinaldo_months)) {
    result.aguinaldo_months = compareArrays(
      original.aguinaldo_months,
      edited.aguinaldo_months,
    ).map((p) => {
      const originalAguinaldoMonth = original.aguinaldo_months.find(
        (item) => item.month === p.month,
      )

      const changedValue = isDifferent(
        originalAguinaldoMonth?.value,
        p.value,
      ) as boolean
      const changedPayrollId = isDifferent(
        originalAguinaldoMonth?.payrollId,
        p.payrollId,
      ) as boolean

      return {
        month: p.month,
        value: changedValue ? getValueOrNullIfEmptyString(p.value) : null,
        payrollId: changedPayrollId && !changedValue ? p.payrollId : null,
      }
    })
  }

  // Comparing and constructing the salaries structure
  if (isDifferent(original.salary_average, edited.salary_average)) {
    result.salary_average = getValueOrNullIfEmptyString(edited.salary_average)
  }

  if (isDifferent(original.daily_salary, edited.daily_salary)) {
    result.daily_salary = getValueOrNullIfEmptyString(edited.daily_salary)
  }

  if (isDifferent(original.salaries, edited.salaries)) {
    result.salaries = compareArrays(original.salaries, edited.salaries).map(
      (p) => {
        const originalSalary = original.salaries.find(
          (item) => item.month === p.month,
        )

        const changedValue = isDifferent(
          originalSalary?.value,
          p.value,
        ) as boolean
        const changedPayrollId = isDifferent(
          originalSalary?.payrollId,
          p.payrollId,
        ) as boolean

        return {
          month: p.month,
          value: changedValue ? getValueOrNullIfEmptyString(p.value) : null,
          payrollId: changedPayrollId && !changedValue ? p.payrollId : null,
        }
      },
    )
  }

  // Comparing and constructing the vacations structure
  if (isDifferent(original.vacations, edited.vacations)) {
    result.vacations = compareArrays(original.vacations, edited.vacations).map(
      (p) => {
        const originalVacation = original.vacations.find(
          (item) => item.year.label === p.year.label,
        )

        const changedUsedDays = isDifferent(
          originalVacation?.used_days,
          p.used_days,
        ) as boolean
        const changedEarnedDays = isDifferent(
          originalVacation?.earned_days,
          p.earned_days,
        ) as boolean

        const changedValue = isDifferent(originalVacation?.value, p.value)
        const changedYearValue = isDifferent(
          originalVacation?.year.value,
          p.year.value,
        )

        return {
          year: {
            label: p.year.label,
            value: changedYearValue
              ? getValueOrNullIfEmptyString(p.year.value)
              : undefined,
          },
          used_days: changedUsedDays
            ? getValueOrNullIfEmptyString(p.used_days)
            : undefined,
          earned_days: changedEarnedDays
            ? getValueOrNullIfEmptyString(p.earned_days)
            : undefined,
          value: changedValue
            ? getValueOrNullIfEmptyString(p.value)
            : undefined,
        }
      },
    )
  }

  if (isDifferent(original.accumulatedVacations, edited.accumulatedVacations)) {
    result.vacations = result.vacations || []
    const accumulatedResult = compareArrays(
      original.accumulatedVacations,
      edited.accumulatedVacations,
    ).map((p) => {
      const originalVacation = original.vacations.find(
        (item) => item.year.label === p.year.label,
      )

      const changedUsedDays = isDifferent(
        originalVacation?.used_days,
        p.used_days,
      ) as boolean
      const changedEarnedDays = isDifferent(
        originalVacation?.earned_days,
        p.earned_days,
      ) as boolean

      const changedValue = isDifferent(originalVacation?.value, p.value)
      const changedYearValue = isDifferent(
        originalVacation?.year.value,
        p.year.value,
      )

      return {
        year: {
          label: p.year.label,
          value: changedYearValue
            ? getValueOrNullIfEmptyString(p.year.value)
            : undefined,
        },
        used_days: changedUsedDays
          ? getValueOrNullIfEmptyString(p.used_days)
          : undefined,
        earned_days: changedEarnedDays
          ? getValueOrNullIfEmptyString(p.earned_days)
          : undefined,
        value: changedValue ? getValueOrNullIfEmptyString(p.value) : undefined,
      }
    })

    result.vacations = [...result.vacations, ...accumulatedResult]
  }

  // Comparing and constructing the vacations structure
  if (isDifferent(original.paymentConcepts, edited.paymentConcepts)) {
    result.concepts = compareArrays(
      original.paymentConcepts,
      edited.paymentConcepts,
    ).map((p) => {
      const originalPayment = original.paymentConcepts.find(
        (item) => item.id === p.id,
      )

      const valueChanged = isDifferent(
        originalPayment?.value,
        p.value,
      ) as boolean
      const amountChanged = isDifferent(
        originalPayment?.amount,
        p.amount,
      ) as boolean

      return {
        id: p.id,
        type: p.type,
        value: valueChanged ? getValueOrNullIfEmptyString(p.value) : undefined,
        amount: amountChanged
          ? getValueOrNullIfEmptyString(p.amount)
          : undefined,
      }
    })
  }

  if (isDifferent(original.discountConcepts, edited.discountConcepts)) {
    const discountConcepts = compareArrays(
      original.discountConcepts,
      edited.discountConcepts,
    ).map((p) => {
      const originalPayment = original.discountConcepts.find(
        (item) => item.id === p.id,
      )

      const valueChanged = isDifferent(
        originalPayment?.value,
        p.value,
      ) as boolean
      const amountChanged = isDifferent(
        originalPayment?.amount,
        p.amount,
      ) as boolean

      return {
        id: p.id,
        type: p.type,
        value: valueChanged ? getValueOrNullIfEmptyString(p.value) : undefined,
        amount: amountChanged
          ? getValueOrNullIfEmptyString(p.amount)
          : undefined,
      }
    })

    result.concepts = [...(result.concepts || []), ...discountConcepts]
  }

  if (
    isDifferent(
      original.total_vacations.earned_days,
      edited.total_vacations.earned_days,
    )
  ) {
    result.total_vacations = result.total_vacations || {}
    result.total_vacations.earned_days = getValueOrNullIfEmptyString(
      edited.total_vacations.earned_days,
    )
  }

  if (
    isDifferent(
      original.total_vacations.used_days,
      edited.total_vacations.used_days,
    )
  ) {
    result.total_vacations = result.total_vacations || {}
    result.total_vacations.used_days = getValueOrNullIfEmptyString(
      edited.total_vacations.used_days,
    )
  }

  if (
    isDifferent(original.total_vacations.value, edited.total_vacations.value)
  ) {
    result.total_vacations = result.total_vacations || {}
    result.total_vacations.value = getValueOrNullIfEmptyString(
      edited.total_vacations.value,
    )
  }

  if (
    isDifferent(
      original.total_accumulated_vacations.total_caused_vacations,
      edited.total_accumulated_vacations.total_caused_vacations,
    )
  ) {
    result.total_accumulated_vacations =
      result.total_accumulated_vacations || {}
    result.total_accumulated_vacations.total_caused_vacations =
      getValueOrNullIfEmptyString(
        edited.total_accumulated_vacations.total_caused_vacations,
      )
  }

  if (
    isDifferent(
      original.total_accumulated_vacations.earned_days,
      edited.total_accumulated_vacations.earned_days,
    )
  ) {
    result.total_accumulated_vacations =
      result.total_accumulated_vacations || {}
    result.total_accumulated_vacations.earned_days =
      getValueOrNullIfEmptyString(
        edited.total_accumulated_vacations.earned_days,
      )
  }

  if (
    isDifferent(
      original.total_accumulated_vacations.used_days,
      edited.total_accumulated_vacations.used_days,
    )
  ) {
    result.total_accumulated_vacations =
      result.total_accumulated_vacations || {}
    result.total_accumulated_vacations.used_days = getValueOrNullIfEmptyString(
      edited.total_accumulated_vacations.used_days,
    )
  }

  if (
    isDifferent(
      original.total_accumulated_vacations.value,
      edited.total_accumulated_vacations.value,
    )
  ) {
    result.total_accumulated_vacations =
      result.total_accumulated_vacations || {}
    result.total_accumulated_vacations.value = getValueOrNullIfEmptyString(
      edited.total_accumulated_vacations.value,
    )
  }

  if (
    isDifferent(
      original.total_accumulated_vacations.total_caused_vacations,
      edited.total_accumulated_vacations.total_caused_vacations,
    )
  ) {
    result.total_accumulated_vacations =
      result.total_accumulated_vacations || {}
    result.total_accumulated_vacations.total_caused_vacations =
      getValueOrNullIfEmptyString(
        edited.total_accumulated_vacations.total_caused_vacations,
      )
  }

  // Comparing and constructing the proportional vacations structure
  if (
    isDifferent(
      original.proportional_vacations.corresponding_days.value,
      edited.proportional_vacations.corresponding_days.value,
    )
  ) {
    result.proportional_vacations = result.proportional_vacations || {}
    result.proportional_vacations.corresponding_days =
      getValueOrNullIfEmptyString(
        edited.proportional_vacations.corresponding_days.value,
      )
  }

  if (
    isDifferent(
      original.proportional_vacations.received_days.value,
      edited.proportional_vacations.received_days.value,
    )
  ) {
    result.proportional_vacations = result.proportional_vacations || {}
    result.proportional_vacations.received_days = getValueOrNullIfEmptyString(
      edited.proportional_vacations.received_days.value,
    )
  }

  if (
    isDifferent(
      original.proportional_vacations.missing_days.value,
      edited.proportional_vacations.missing_days.value,
    )
  ) {
    result.proportional_vacations = result.proportional_vacations || {}
    result.proportional_vacations.missing_days = getValueOrNullIfEmptyString(
      edited.proportional_vacations.missing_days.value,
    )
  }

  if (
    isDifferent(
      original.proportional_vacations.value,
      edited.proportional_vacations.value,
    )
  ) {
    result.proportional_vacations = result.proportional_vacations || {}
    result.proportional_vacations.value = getValueOrNullIfEmptyString(
      edited.proportional_vacations.value,
    )
  }

  // Comparing and constructing the discount proportional vacations structure
  if (
    isDifferent(
      original.discount_proportional_vacations.corresponding_days.value,
      edited.discount_proportional_vacations.corresponding_days.value,
    )
  ) {
    result.discount_proportional_vacations =
      result.discount_proportional_vacations || {}
    result.discount_proportional_vacations.corresponding_days =
      getValueOrNullIfEmptyString(
        edited.discount_proportional_vacations.corresponding_days.value,
      )
  }

  if (
    isDifferent(
      original.discount_proportional_vacations.received_days.value,
      edited.discount_proportional_vacations.received_days.value,
    )
  ) {
    result.discount_proportional_vacations =
      result.discount_proportional_vacations || {}
    result.discount_proportional_vacations.received_days =
      getValueOrNullIfEmptyString(
        edited.discount_proportional_vacations.received_days.value,
      )
  }

  if (
    isDifferent(
      original.discount_proportional_vacations.missing_days.value,
      edited.discount_proportional_vacations.missing_days.value,
    )
  ) {
    result.discount_proportional_vacations =
      result.discount_proportional_vacations || {}
    result.discount_proportional_vacations.missing_days =
      getValueOrNullIfEmptyString(
        edited.discount_proportional_vacations.missing_days.value,
      )
  }

  if (
    isDifferent(
      original.discount_proportional_vacations.value,
      edited.discount_proportional_vacations.value,
    )
  ) {
    result.discount_proportional_vacations =
      result.discount_proportional_vacations || {}
    result.discount_proportional_vacations.value = getValueOrNullIfEmptyString(
      edited.discount_proportional_vacations.value,
    )
  }

  // Comparing and constructing the pre warning structure
  if (
    isDifferent(
      original.pre_warning.totalDays.value,
      edited.pre_warning.totalDays.value,
    )
  ) {
    result.pre_warning = result.pre_warning || {}
    result.pre_warning.totalDays = getValueOrNullIfEmptyString(
      edited.pre_warning.totalDays.value,
    )
  }

  if (
    isDifferent(
      original.pre_warning.received_days.value,
      edited.pre_warning.received_days.value,
    )
  ) {
    result.pre_warning = result.pre_warning || {}
    result.pre_warning.received_days = getValueOrNullIfEmptyString(
      edited.pre_warning.received_days.value,
    )
  }

  if (
    isDifferent(
      original.pre_warning.missing_days.value,
      edited.pre_warning.missing_days.value,
    )
  ) {
    result.pre_warning = result.pre_warning || {}
    result.pre_warning.missing_days = getValueOrNullIfEmptyString(
      edited.pre_warning.missing_days.value,
    )
  }

  if (isDifferent(original.pre_warning.value, edited.pre_warning.value)) {
    result.pre_warning = result.pre_warning || {}
    result.pre_warning.value = getValueOrNullIfEmptyString(
      edited.pre_warning.value,
    )
  }

  // Comparing and constructing the discount pre warning structure
  if (
    isDifferent(
      original.discount_pre_warning.totalDays.value,
      edited.discount_pre_warning.totalDays.value,
    )
  ) {
    const totalDays = getValueOrNullIfEmptyString(
      edited.discount_pre_warning.totalDays.value,
    )

    result.discount_pre_warning = result.discount_pre_warning || {}
    result.discount_pre_warning.totalDays =
      totalDays !== null ? +totalDays : null
  }

  if (
    isDifferent(
      original.discount_pre_warning.received_days.value,
      edited.discount_pre_warning.received_days.value,
    )
  ) {
    result.discount_pre_warning = result.discount_pre_warning || {}
    result.discount_pre_warning.received_days = getValueOrNullIfEmptyString(
      edited.discount_pre_warning.received_days.value,
    )
  }

  if (
    isDifferent(
      original.discount_pre_warning.missing_days.value,
      edited.discount_pre_warning.missing_days.value,
    )
  ) {
    result.discount_pre_warning = result.discount_pre_warning || {}
    result.discount_pre_warning.missing_days = getValueOrNullIfEmptyString(
      edited.discount_pre_warning.missing_days.value,
    )
  }

  if (
    isDifferent(
      original.discount_pre_warning.value,
      edited.discount_pre_warning.value,
    )
  ) {
    result.discount_pre_warning = result.discount_pre_warning || {}
    result.discount_pre_warning.value = getValueOrNullIfEmptyString(
      edited.discount_pre_warning.value,
    )
  }

  // Comparing and constructing the indemnification structure
  if (
    isDifferent(
      original.indemnification.corresponding_days.value,
      edited.indemnification.corresponding_days.value,
    )
  ) {
    result.indemnification = result.indemnification || {}
    result.indemnification.corresponding_days = getValueOrNullIfEmptyString(
      edited.indemnification.corresponding_days.value,
    )
  }

  if (
    isDifferent(original.indemnification.value, edited.indemnification.value)
  ) {
    result.indemnification = result.indemnification || {}
    result.indemnification.value = getValueOrNullIfEmptyString(
      edited.indemnification.value,
    )
  }

  // Comparing and constructing the family bonus structure
  if (isDifferent(original.familiarBonus.value, edited.familiarBonus.value)) {
    result.family_bonus = result.family_bonus || {}
    result.family_bonus.value = getValueOrNullIfEmptyString(
      edited.familiarBonus.value,
    )
  }

  if (
    isDifferent(original.familiarBonus.children, edited.familiarBonus.children)
  ) {
    result.family_bonus = result.family_bonus || {}
    result.family_bonus.children = getValueOrNullIfEmptyString(
      edited.familiarBonus.children,
    )
  }

  // Comparing and constructing the other discounts structure
  // if (isDifferent(original.other_discounts, edited.other_discounts)) {
  //   result.other_discounts = compareArrays(
  //     original.other_discounts,
  //     edited.other_discounts,
  //   ).map((p) => ({
  //     label: p.label,
  //     value: p.value,
  //   }))
  // }

  return result
}

interface ILiquidationReceiptValue {
  amount: number | string
  value: number | string
  name?: string | null
  disabled?: boolean | null
}

export type GenerateLiquidationReceiptOptions = {
  proportionalSalary: ILiquidationReceiptValue
  nightHoursValue: ILiquidationReceiptValue
  morningExtraHourValue: ILiquidationReceiptValue
  nightExtraHourValue: ILiquidationReceiptValue
  morningHolidayHourValue: ILiquidationReceiptValue
  nightHolidayHourValue: ILiquidationReceiptValue
  morningDiscountValue: ILiquidationReceiptValue
  nightDiscountValue: ILiquidationReceiptValue
  familiarBonus: ILiquidationReceiptValue
  payments: ILiquidationReceiptValue[]
  discounts: ILiquidationReceiptValue[]
  salaryConcepts: ILiquidationReceiptValue[]

  proportionalAguinaldo: ILiquidationReceiptValue
  causedVacations: ILiquidationReceiptValue
  accumulatedVacations: ILiquidationReceiptValue
  proportionalVacations: ILiquidationReceiptValue
  preWarning: ILiquidationReceiptValue
  indemnification: ILiquidationReceiptValue

  paymentLiquidationConcepts: ILiquidationReceiptValue[]
  discountLiquidationConcepts: ILiquidationReceiptValue[]

  vacationAntecipate: ILiquidationReceiptValue
  preWarningDiscount: ILiquidationReceiptValue

  ips9: ILiquidationReceiptValue
}

export const buildLiquidationReceiptParams = (
  fullLiquidationState: InactivateWorkerSchema,
): GenerateLiquidationReceiptOptions => {
  const paymentLiquidationConcepts =
    fullLiquidationState.data.paymentConcepts.filter(
      (p) => p.type === 'liquidation_concept',
    )
  const discountLiquidationConcepts =
    fullLiquidationState.data.discountConcepts.filter(
      (p) => p.type === 'liquidation_concept',
    )

  const paymentConcepts = fullLiquidationState.data.paymentConcepts.filter(
    (p) => p.type === 'payment',
  )
  const discountConcepts = fullLiquidationState.data.discountConcepts.filter(
    (p) => p.type === 'payment',
  )
  const salaryConcepts = fullLiquidationState.data.paymentConcepts.filter(
    (p) => p.type === 'salary_concept',
  )

  const exportOptions = {
    proportionalSalary: {
      amount: fullLiquidationState.data.payed_days.value,
      value: fullLiquidationState.data.proportional_salary.value,
      disabled: false,
    },
    nightHoursValue: {
      amount: fullLiquidationState.data.ordinary_night.disclaimer,
      value: fullLiquidationState.data.ordinary_night.value,
      disabled: false,
    },
    morningExtraHourValue: {
      amount: fullLiquidationState.data.extra_morning.disclaimer,
      value: fullLiquidationState.data.extra_morning.value,
      disabled: false,
    },
    nightExtraHourValue: {
      amount: fullLiquidationState.data.extra_night.disclaimer,
      value: fullLiquidationState.data.extra_night.value,
      disabled: false,
    },
    morningHolidayHourValue: {
      amount: fullLiquidationState.data.holiday_morning.disclaimer,
      value: fullLiquidationState.data.holiday_morning.value,
      disabled: false,
    },
    nightHolidayHourValue: {
      amount: fullLiquidationState.data.holiday_night.disclaimer,
      value: fullLiquidationState.data.holiday_night.value,
      disabled: false,
    },
    morningDiscountValue: {
      amount: fullLiquidationState.data.discount_morning.disclaimer,
      value: fullLiquidationState.data.discount_morning.value,
      disabled: false,
    },
    nightDiscountValue: {
      amount: fullLiquidationState.data.discount_night.disclaimer,
      value: fullLiquidationState.data.discount_night.value,
      disabled: false,
    },
    familiarBonus: {
      amount: fullLiquidationState.data.familiarBonus.children,
      value: fullLiquidationState.data.familiarBonus.value,
      disabled: !fullLiquidationState.conceptsStatus.familiar_bonus?.is_active,
      name: fullLiquidationState.conceptsStatus.familiar_bonus?.name,
    },
    payments: paymentConcepts.map((p) => ({
      amount: +p.amount,
      value: +p.value,
      disabled: fullLiquidationState.conceptsStatus[p.id]?.is_active,
      name: p.name || 'Pago',
    })),
    discounts: discountConcepts.map((p) => ({
      amount: +p.amount,
      value: +p.value,
      disabled: fullLiquidationState.conceptsStatus[p.id]?.is_active,
      name: p.name || 'Descuento',
    })),
    salaryConcepts: salaryConcepts.map((p) => ({
      amount: +p.amount,
      value: +p.value,
      disabled: fullLiquidationState.conceptsStatus[p.id]?.is_active,
      name: p.name || 'Concepto de Salario',
    })),

    proportionalAguinaldo: {
      amount: '1',
      value: fullLiquidationState.data.aguinaldo,
      disabled:
        !fullLiquidationState.conceptsStatus.proportional_aguinaldo?.is_active,
      name: fullLiquidationState.conceptsStatus.proportional_aguinaldo?.name,
    },
    causedVacations: {
      amount: fullLiquidationState.data.total_vacations.earned_days,
      value: fullLiquidationState.data.total_vacations.value,
      disabled:
        !fullLiquidationState.conceptsStatus.caused_vacations?.is_active,
      name: fullLiquidationState.conceptsStatus.caused_vacations?.name,
    },
    accumulatedVacations: {
      amount: fullLiquidationState.data.total_accumulated_vacations.earned_days,
      value: fullLiquidationState.data.total_accumulated_vacations.value,
      disabled:
        !fullLiquidationState.conceptsStatus.accumulated_vacations?.is_active,
      name: fullLiquidationState.conceptsStatus.accumulated_vacations?.name,
    },
    proportionalVacations: {
      amount:
        fullLiquidationState.data.proportional_vacations.missing_days.value,
      value: fullLiquidationState.data.proportional_vacations.value,
      disabled:
        !fullLiquidationState.conceptsStatus.proportional_vacations?.is_active,
      name: fullLiquidationState.conceptsStatus.proportional_vacations?.name,
    },
    preWarning: {
      amount: fullLiquidationState.data.pre_warning.missing_days.value,
      value: fullLiquidationState.data.pre_warning.value,
      disabled: !fullLiquidationState.conceptsStatus.prewarning?.is_active,
      name: fullLiquidationState.conceptsStatus.prewarning?.name,
    },
    indemnification: {
      amount:
        fullLiquidationState.data.indemnification.corresponding_days.value,
      value: fullLiquidationState.data.indemnification.value,
      disabled: !fullLiquidationState.conceptsStatus.indemnification?.is_active,
      name: fullLiquidationState.conceptsStatus.indemnification?.name,
    },

    paymentLiquidationConcepts: paymentLiquidationConcepts.map((p) => ({
      amount: +p.amount,
      value: +p.value,
      disabled: !fullLiquidationState.conceptsStatus[p.id]?.is_active,
      name: p.name || 'Pago',
    })),
    discountLiquidationConcepts: discountLiquidationConcepts.map((p) => ({
      amount: +p.amount,
      value: +p.value,
      disabled: !fullLiquidationState.conceptsStatus[p.id]?.is_active,
      name: p.name || 'Descuento',
    })),

    vacationAntecipate: {
      amount:
        fullLiquidationState.data.discount_proportional_vacations
          .corresponding_days.value,
      value: fullLiquidationState.data.discount_proportional_vacations.value,
      disabled:
        !fullLiquidationState.conceptsStatus.vacations_antecipate?.is_active,
      name: fullLiquidationState.conceptsStatus.vacations_antecipate?.name,
    },
    preWarningDiscount: {
      amount: fullLiquidationState.data.discount_pre_warning.missing_days.value,
      value: fullLiquidationState.data.discount_pre_warning.value,
      disabled:
        !fullLiquidationState.conceptsStatus.discount_prewarning?.is_active,
      name: fullLiquidationState.conceptsStatus.discount_prewarning?.name,
    },

    ips9: {
      amount: '1',
      value: fullLiquidationState.ipsValues.ips9Value,
      disabled: !fullLiquidationState.conceptsStatus.ips9?.is_active,
      name: fullLiquidationState.conceptsStatus.ips9?.name,
    },
  }

  return exportOptions
}
