import { useQuery } from 'react-query'
import {
  IGetVacationsStatusDTO,
  IPaginatedVacationsStatus,
} from '../../types/vacations'
import { apiV2 } from '@/services/api'

const fetchVacationsStatus = async (data: IGetVacationsStatusDTO) => {
  const vacations = await apiV2.post<IPaginatedVacationsStatus>(
    '/vacation/get-vacations-status',
    {
      date: data.date,
      name: data.name,
      page: data.page !== undefined ? data.page?.toString() : undefined,
      perPage: data.perPage !== undefined ? data.perPage.toString() : undefined,

      antiquity: data.antiquity,
      status: data.status,
      periods: data.periods,

      simulation: data.simulation,
    },
    {
      params: {
        cellsIds: (data.cellsIds || []).join(','),
      },
    },
  )
  return vacations.data
}

export const useVacationsStatus = (props: IGetVacationsStatusDTO) => {
  const query = useQuery(
    [
      'vacations-status',
      props.date,
      props.page,
      props.perPage,
      props.cellsIds,
      props.name,
      props.antiquity,
      props.periods,
      props.status,
      props.simulation,
    ],
    () => fetchVacationsStatus(props),
    {
      // keepPreviousData: true,
      enabled: !!props.cellsIds?.length && props.cellsIds?.length > 0,
    },
  )
  return query
}
