import { z } from 'zod'
import { ControlledFieldProps } from '../Forms/FormRenderer/types'
import { toPatternTypes } from '../Forms/ControlledInput/toPattern'
import { TagProps } from '@punto-ui/react'
import React from 'react'

export type SmartTableType =
  | 'input-text'
  | 'dropdown'
  | 'menu'
  | 'tags'
  | 'draggable-tags'
  | 'avatar'
  | 'checkbox'
  | 'expandable'
  | 'subheader'
  | 'custom'

export type SmartColumnOption = {
  label: string
  value: string
}

export interface IInputTag {
  id: string
  name: string
  variant: TagProps['variant']
  data?: any
}

export type SmartCellStatus =
  | 'success'
  | 'error'
  | 'loading'
  | 'warning'
  | 'soft-error'
  | 'soft-warning'
  | 'soft-success'

export const SmartTableFormItemValue = z.object({
  value: z.string().or(z.number()).or(z.boolean()),
  original: z.string().or(z.number()).or(z.boolean()).optional(),
  status: z.string().optional(),
  metadata: z.any().optional(),
})

export const SmartTableAvatarItemValue = z.object({
  url: z.string(),
  alt: z.string(),
})

export const SmartTableFormArrayItem = z.array(SmartTableFormItemValue)

export const getSmartTableFormItemValue = (validation: (x: any) => boolean) => {
  return z.object({
    value: z.string().or(z.number()).or(z.boolean()).refine(validation),
    original: z.string().or(z.number()).or(z.boolean()).optional(),
    status: z.string().optional(),
    metadata: z.any().optional(),
  })
}

export const getSmartTableFormArrayItemValue = (
  validation: (x: any) => boolean,
) => {
  return z.array(getSmartTableFormItemValue(validation))
}

export type SmartTableFormItemValueType = z.infer<
  typeof SmartTableFormItemValue
>

export type SmartTableAvatarItemValueType = z.infer<
  typeof SmartTableAvatarItemValue
>

export const SmartTableFormTagValue = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    variant: z.string().optional(),
    data: z.any().optional(),
  }),
)

export const SmartTableFormDraggableTagValue = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    variant: z.string().optional(),
    data: z.any().optional(),
  }),
)

export const SmartTableFormItemSchema = z.record(
  z.string(),
  SmartTableFormItemValue,
)

export const SmartTableFormSchema = z.object({
  data: z.array(z.any()),
})

export const SmartTableFormDataSchema = z.any()

export type SmartTableFormSchemaType = z.infer<typeof SmartTableFormSchema>
export type SmartTableFormDataSchemaType = z.infer<
  typeof SmartTableFormDataSchema
>
export type SmartTableFields = Array<
  ControlledFieldProps<SmartTableFormSchemaType>
>

export interface SmartTableStyles {
  borderInLines?: boolean
  height?: number
  centered?: boolean
}

export interface SubmitValueProps {
  index: number
  subindex?: number
  data: SmartTableFormItemValueType
}

export type SmartColumnTypeContent = {
  type: SmartTableType
  name?: string
  onTagClick?: (
    index: number,
    name: string,
    previousValue: string,
    data: any,
  ) => void
  onCloseTag?: (index: number, name: string) => void
  onCheckedChange?: (index: number, b: boolean) => void
  onAllCheckedChange?: (b: boolean) => void
  onDropTag?: (index: number, name: string, data: any) => void
  handleSubmitValue?: (props: SubmitValueProps) => Promise<void> | void
  component?: any
  onIconClick?: (index: number, data: string, previousValue: string) => void
  onBlur?: (props: SubmitValueProps) => void
  draggable?: any
  inputType?: toPatternTypes
  disabled?: boolean
  enableEmptySubmit?: boolean
  enableSameSubmit?: boolean
  options?: SmartColumnOption[]
  array?: boolean
  length?: number
  width?: number
  placeholder?: string
  icon?: React.ReactNode
  freeWriting?: boolean

  // eslint-disable-next-line no-undef
  header?: string | (() => JSX.Element)
  headers?: string[]
}

export type SmartColumnType = {
  items?: Array<SmartColumnTypeContent>
} & SmartColumnTypeContent

export interface IPaginationProps {
  page: number
  perPage: number
  total: number
  totalPages: number
  onChange: (data: { pageIndex: number; pageSize: number }) => void
}

export interface SmartTableProps {
  columns: SmartColumnType[]

  style?: SmartTableStyles
  uncontrolled?: boolean
  data?: Record<string, any>[]
  noPadding?: boolean
  maxDepth?: number
  selectedIndex?: number

  subRowName?: string

  defaultColumnOrder?: string[]
  defaultHiddenColumns?: string[]
  resetColumnOrder?: string[]
  defaultLeftFixedColumns?: string[]
  defaultRightFixedColumns?: string[]

  areColumnsDraggable?: boolean
  saveColumnsOrderCallback?: (order: string[]) => void
  saveHiddenColumnsCallback?: (order: string[]) => void
  saveLeftFixedColumnsCallback?: (order: string[]) => void
  saveRightFixedColumnsCallback?: (order: string[]) => void

  withPagination?: boolean
  paginationProps?: IPaginationProps
}

export type DraggableParams<T> = {
  index: number
  fieldName: keyof T
  isArray: boolean
  arrayIndex: number
}
