import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useAvailableCells, useSmartShifts } from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useFormContext, useWatch } from 'react-hook-form'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useWorkerCreation } from '../context/hooks'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useCellData = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const { data: shifts } = useSmartShifts()
  const { isCreation } = useWorkerCreation()
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('workers')),
  )

  const { control } = useFormContext<createCompleteWorkerData>()

  const cellId = useWatch({
    control,
    name: 'cell_id',
  })

  const shiftId = useWatch({
    control,
    name: 'shift_id',
  })

  const optionalDataForm: AddWorkerFields = useMemo(() => {
    const selectedCell = availableCells?.find((cell) => cell.id === cellId)
    const selectedShift = shifts?.find((shift) => shift.shiftId === shiftId)
    const dataForm: AddWorkerFields = isCreation
      ? [
          {
            _type: 'dropdown',
            placeholder: 'Turno Diurno',
            label: 'Turno',
            name: 'shift_id',
            disabled: !isWorkerCreationAvailable,
            options:
              shifts?.map((shift) => ({
                label: shift.shiftName,
                value: shift.shiftId,
              })) || [],
            defaultOption: {
              value: selectedShift?.shiftId || '',
              label: selectedShift?.shiftName || 'Escolher turno',
            },
          },
        ]
      : []

    dataForm.push({
      _type: 'dropdown',
      placeholder: 'Sucursal',
      label: 'Configuración de Sucursal',
      disabled: !isWorkerCreationAvailable,
      name: 'cell_id',
      options:
        availableCells?.map((cell) => ({
          label: cell.name,
          value: cell.id,
        })) || [],
      defaultOption: {
        value: selectedCell?.id || '',
        label: selectedCell?.name || 'Escolher sucursal',
      },
    })
    return dataForm
  }, [
    availableCells,
    cellId,
    shifts,
    shiftId,
    isCreation,
    isWorkerCreationAvailable,
  ])

  return {
    data: optionalDataForm,
  }
}
