import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useChildrenData = (index: number) => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const optionalDataForm: AddWorkerFields = useMemo(() => {
    const form: AddWorkerFields = [
      {
        _type: 'input',
        label: 'Nombre',
        name: `children.${index}.name`,
        disabled: !isWorkerCreationAvailable,
        inputType: 'name',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Documento',
        name: `children.${index}.document`,
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Fecha de nacimiento',
        name: `children.${index}.birthday`,
        inputType: 'datetime',
      },
      {
        _type: 'checkbox',
        disabled: !isWorkerCreationAvailable,
        label: 'Debe recibir bonificación familiar',
        name: `children.${index}.shouldReceiveChildrenBonus`,
      },
    ]

    return form
  }, [index, isWorkerCreationAvailable])

  return {
    data: optionalDataForm,
  }
}
