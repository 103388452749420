import React, { ComponentProps } from 'react'
import * as RadixDialog from '@radix-ui/react-dialog'
import { styled } from '../../styles'

import {
  IconButton,
  DrawerContainer as DrawerInternalContainer,
  DrawerOverlay,
} from './styles'
import { CSS } from '@stitches/react'
import { OpenDrawerIcon } from '@/assets/icons/OpenDrawerIcon'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const DrawerContainer = styled(RadixDialog.Root, {})
export const DrawerCloseButton = styled(RadixDialog.Close, {})

interface DrawerTriggerProps {
  children: React.ReactNode
}

export interface DrawerProps extends ComponentProps<typeof DrawerContainer> {
  content: React.ReactNode
  noPadding?: boolean
  noClose?: boolean
  dialog?: boolean
  overlayCss?: CSS
  containerCss?: CSS
}

export const DrawerTrigger = ({ children }: DrawerTriggerProps) => (
  <RadixDialog.Trigger asChild>{children}</RadixDialog.Trigger>
)

export const Drawer = ({
  noPadding,
  noClose,
  dialog = false,
  containerCss,
  overlayCss,
  ...props
}: DrawerProps) => {
  const escFunction = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      props.onOpenChange?.(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  return (
    <RadixDialog.Portal>
      <DrawerOverlay
        css={{ ...overlayCss }}
        onClick={() => {
          props.onOpenChange?.(false)
        }}
      />
      <DrawerInternalContainer
        dialog={dialog}
        noPadding={noPadding}
        css={{ ...containerCss }}
      >
        {props.content}

        {!noClose && !dialog && (
          <RadixDialog.Close asChild>
            <IconButton type="button" aria-label="Close">
              <OpenDrawerIcon />
            </IconButton>
          </RadixDialog.Close>
        )}
        {!noClose && dialog && (
          <RadixDialog.Close asChild>
            <IconButton
              type="button"
              aria-label="Close"
              css={{
                right: 8,
                left: 'auto',
                top: 6,
              }}
            >
              <XMarkIcon />
            </IconButton>
          </RadixDialog.Close>
        )}
      </DrawerInternalContainer>
    </RadixDialog.Portal>
  )
}

Drawer.displayName = 'Drawer'
