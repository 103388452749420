import { useEffect } from 'react'
import { IRouteAction } from '@/pages/router'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { UseFormReturn } from 'react-hook-form'
import { ImportWorkersSchema } from './form'
import { useHandleCreateManyWorkers } from '@/libs/react-query/mutations/workers/useHandleCreateMany'
import { parseWorkersCreationStructure } from './parser'
import { useOrganogram } from '@/libs/react-query/hooks'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import useToast from '@/hooks/useToast'
import { AxiosError } from 'axios'

export const useHandleWorkerImportActions = ({
  methods,
  isLoading,
}: {
  methods: UseFormReturn<ImportWorkersSchema>
  isLoading?: boolean
}) => {
  const toast = useToast()

  const { data: organogram, isLoading: isLoadingOrganogram } = useOrganogram()
  const { data: company, isLoading: isLoadingCompany } = useMyCompany()

  const { setActions } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('worker', 'list'),
    setActions: state.setActionsTab,
  }))

  const {
    isLoading: isLoadingCreateWorkers,
    mutateAsync: handleCreateManyWorkers,
  } = useHandleCreateManyWorkers()

  useEffect(() => {
    const actions: IRouteAction[] = []

    const isLoadingOrDisabled =
      isLoading ||
      isLoadingCompany ||
      isLoadingOrganogram ||
      isLoadingCreateWorkers

    actions.push({
      label: 'Importar',
      action: async () => {
        const values = methods.getValues()
        if (!values.data.length) return

        const createManyWorkersDTO = parseWorkersCreationStructure(
          values.data,
          organogram?.cells || [],
          company,
        )

        try {
          await handleCreateManyWorkers(createManyWorkersDTO)
          toast.addToast({
            title: '👌 Creación de colaboradores exitosa',
            description: 'Colaboradores importados con éxito',
            type: 'positive',
            id: 1,
          })
        } catch (err) {
          if (err instanceof AxiosError) {
            console.log('err', err?.response?.data)
            toast.addToast({
              title: '❌ Uppz!',
              description: err?.response?.data.message,
              type: 'negative',
              id: 1,
            })
          } else {
            toast.addToast({
              title: '❌ Uppz!',
              description: '¡Fracasado! Inténtalo de nuevo',
              type: 'negative',
              id: 1,
            })
          }
        }
      },
      disabled: isLoadingOrDisabled,
      isLoading: isLoadingOrDisabled,
      variant: 'primary',
      icon: <DocumentArrowDownIcon />,
    })

    setActions('worker', actions)
  }, [
    isLoading,
    methods,
    organogram,
    company,
    setActions,
    isLoadingCompany,
    isLoadingOrganogram,
    handleCreateManyWorkers,
    isLoadingCreateWorkers,
  ])
}
