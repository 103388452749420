import { Div } from '@/components'
import { IHoliday } from '@/libs/react-query/types/company'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Tag, Text } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import React from 'react'

export const useHolidaysColumns = () => {
  const columns = React.useMemo<ColumnDef<IHoliday>[]>(() => {
    const retunedColumns: ColumnDef<IHoliday>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text>{row.original.name}</Text>
          </Div>
        ),
      },
      {
        id: 'date',
        header: 'Fecha',
        accessorFn: (row) =>
          `${
            row.day_of_week !== null && row.day_of_week !== undefined
              ? dayjs().day(row.day_of_week).format('dddd')
              : dayjs(row.date).format('DD/MM/YYYY')
          }`,
      },
      {
        id: 'active',
        header: 'Activo',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag variant={row.original.active ? 'positive' : 'negative'}>
              {row.original.active ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
      {
        id: 'should_repeat_every_year',
        header: 'Repetir anualmente',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag
              variant={
                row.original.should_repeat_every_year ? 'positive' : 'negative'
              }
            >
              {row.original.should_repeat_every_year ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
