import { Text, Button, ButtonProps } from '@punto-ui/react'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import {
  ViewDrawerContainer,
  View,
  HeaderContainer,
  ButtonsContainer,
} from './styles'
import { Drawer, DrawerContainer } from '@/components/Drawer'

interface FormDrawerProps {
  title: string
  icon?: React.ReactNode
  description?: string
  isOpen: boolean
  handleCloseDrawer(): void
  primaryButtonProps?: ButtonProps
  handlePrimaryButton?: () => Promise<void>
  secondaryButtonProps?: ButtonProps
  handleSecondaryButton?: () => void
  children: React.ReactNode
}

export const ViewDrawer = ({
  title,
  icon,
  description,
  isOpen,
  handleCloseDrawer,
  handlePrimaryButton,
  primaryButtonProps,
  handleSecondaryButton,
  secondaryButtonProps,
  children,
}: FormDrawerProps) => {
  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <ViewDrawerContainer>
            <HeaderContainer>
              {icon || null}
              <Text
                as="h3"
                variant={'heading3'}
                css={{
                  color: '$brand_primary_pure',
                  marginBottom: '8px',
                }}
              >
                {title}
              </Text>
            </HeaderContainer>
            <Text
              as="p"
              variant={'paragraph'}
              css={{ color: '$interface_dark_deep' }}
            >
              {description}
            </Text>
            <View>
              {children}
              <ButtonsContainer>
                {secondaryButtonProps && handleSecondaryButton && (
                  <Button
                    onClick={handleSecondaryButton}
                    variant="secondary"
                    css={{ width: '100%' }}
                    title={
                      secondaryButtonProps?.title
                        ? secondaryButtonProps.title
                        : 'Cancelar'
                    }
                    icon={
                      secondaryButtonProps?.icon ? (
                        secondaryButtonProps.icon
                      ) : (
                        <XMarkIcon />
                      )
                    }
                    {...secondaryButtonProps}
                  />
                )}
                {primaryButtonProps && handlePrimaryButton && (
                  <Button
                    onClick={handlePrimaryButton}
                    variant="primary"
                    type="submit"
                    css={{ width: '100%' }}
                    title={
                      primaryButtonProps?.title
                        ? primaryButtonProps.title
                        : 'Agregar'
                    }
                    icon={
                      primaryButtonProps?.icon ? (
                        primaryButtonProps.icon
                      ) : (
                        <PlusCircleIcon />
                      )
                    }
                    {...primaryButtonProps}
                  />
                )}
              </ButtonsContainer>
            </View>
          </ViewDrawerContainer>
        }
      />
    </DrawerContainer>
  )
}
