import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateUserLiquidationConceptsDTO } from '@/libs/react-query/types/company'

export const useUpdateUserLiquidationConcepts = () => {
  const mutation = useCustomMutation(
    ['useUpdateUserLiquidationConcepts'],
    handleUpdateUserLiquidationConcepts,
  )

  return mutation
}

const handleUpdateUserLiquidationConcepts = async (
  props: IUpdateUserLiquidationConceptsDTO & {
    shouldNotInvalidate?: boolean
  },
) => {
  try {
    await apiV2.post<void>('/company/update-user-liquidation-concepts', {
      ...props,
      shouldInvalidate: undefined,
    })

    if (!props.shouldNotInvalidate) {
      queryClient.invalidateQueries('worker-liquidation')
    }
  } catch (error) {
    console.log(error)
  }
}
