import { Div } from '@/components/Div'
import { Text, styled } from '@punto-ui/react'
import { TableHead } from '../styles'
import { CheckboxHeader } from './EditingCell/CheckboxHeader'
import { findColumnByName } from '@/components/SmartTable/columns'
import ReactDOM from 'react-dom'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  SmartColumnType,
  SmartTableStyles,
} from '@/components/SmartTable/types'
import { Header, flexRender } from '@tanstack/react-table'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { GrabIcon } from '@/assets/icons/GrabIcon'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'

export const TableHeadCell = ({
  column,
  columns,
  styles,
  isDraggable,

  setColumnOrders,
  setHiddenColumns,

  leftFixedColumns,
  rightFixedColumns,

  setLeftFixedColumns,
  setRightFixedColumns,
}: {
  columns: SmartColumnType[]
  styles?: SmartTableStyles
  column: Header<any, unknown>
  isDraggable?: boolean
  setColumnOrders?: React.Dispatch<React.SetStateAction<string[]>>
  setHiddenColumns?: React.Dispatch<React.SetStateAction<string[]>>
  setLeftFixedColumns?: React.Dispatch<React.SetStateAction<string[]>>
  setRightFixedColumns?: React.Dispatch<React.SetStateAction<string[]>>

  leftFixedColumns?: string[]
  rightFixedColumns?: string[]
}) => {
  const [isMouseInsideHeader, setIsMouseInsideHeader] = React.useState(false)

  const handleDropColumn = (direction: 'left' | 'right') => {
    setColumnOrders?.((state) => {
      const currentOrder = [...state]
      const columnPositionReference = columnIdConsideringArray
      const columnToMove = item.column.column.columnDef.meta?.isArray
        ? item.column.column.columnDef.id?.split('.')[0] || ''
        : item.column.column.columnDef.id || ''

      if (columnIdConsideringArray === columnToMove) {
        return currentOrder
      }

      const newOrder = currentOrder.filter((col) => col !== columnToMove)
      const columnPositionIndex = newOrder.indexOf(columnPositionReference)
      const newColumnPositionIndex =
        direction === 'left' ? columnPositionIndex : columnPositionIndex + 1
      newOrder.splice(newColumnPositionIndex, 0, columnToMove)

      return newOrder
    })
  }

  const columnId = column.column.columnDef.id || ''
  const columnIdConsideringArray = column.column.columnDef.meta?.isArray
    ? columnId.split('.')[0]
    : columnId

  const getColumnDefinition = () => {
    if (column.column.columnDef.meta?.isArray) {
      const correctId = column.id.split('.')[0]
      return findColumnByName(columns, correctId)
    } else {
      return findColumnByName(columns, column.id)
    }
  }

  const columnDefinition = getColumnDefinition()
  const headColumnRef = columns.find((c) => {
    if (c.name === columnId) {
      return true
    }

    if (c.items) {
      const columnItemRef = c.items.find((item) => item.name === columnId)
      if (columnItemRef) {
        return true
      }
    }

    return false
  })

  const [isDragging, setIsDragging] = useState(false)
  const draggableColumnRef = useRef<any | null>(null)

  const dropRightRef = useRef<HTMLDivElement | null>(null)
  const dropLeftRef = useRef<HTMLDivElement | null>(null)

  const [element, setElement] = useState<any>(null)
  const [isOverLeft, setIsOverLeft] = useState(false)
  const [isOverRight, setIsOverRight] = useState(false)

  const item = element

  const lastColumnRefItem =
    headColumnRef?.items?.[headColumnRef?.items?.length - 1]

  const columnDefinitionWidth = headColumnRef?.items?.reduce((acc, item) => {
    if (item.array && item.length === 0) return acc

    return acc + (item?.width || 0)
  }, 0)

  const columnHeaderWidth =
    column.column.depth === 0
      ? columnDefinitionWidth || columnDefinition?.width
      : columnDefinition?.width || columnDefinitionWidth

  // console.log(headColumnRef?.items)

  useEffect(() => {
    if (!draggableColumnRef.current) {
      return
    }
    if (!dropRightRef.current) {
      return
    }
    if (!dropLeftRef.current) {
      return
    }

    const headerDraggable = draggable({
      canDrag: () => {
        return !!isDraggable
      },
      element: draggableColumnRef.current,
      onDrop: (args) => {
        setElement(null)
      },
      getInitialData: () => ({
        column,
        columnDefinition,
        type: 'smart-table-header',
      }),
    })

    const leftDropTarget = dropTargetForElements({
      element: dropLeftRef.current,
      canDrop: ({ source }) =>
        !!isDraggable && source.data.type === 'smart-table-header',
      // onDrag: () => {
      //   setIsOverLeft(true)
      // },
      onDragEnter: () => {
        setIsOverLeft(true)
      },
      onDragLeave: () => {
        setIsOverLeft(false)
      },
      onDrop: () => {
        handleDropColumn('left')
        setIsOverLeft(false)
      },
    })

    const rightDropTarget = dropTargetForElements({
      canDrop: ({ source }) =>
        !!isDraggable && source.data.type === 'smart-table-header',
      onDrop: () => {
        handleDropColumn('right')
        setIsOverRight(false)
      },
      element: dropRightRef.current,
      // onDrag: () => {
      //   setIsOverRight(true)
      // },
      onDragEnter: () => {
        setIsOverRight(true)
      },
      onDragLeave: () => {
        setIsOverRight(false)
      },
    })

    const monitor = monitorForElements({
      canMonitor: ({ source }) => source.data.type === 'smart-table-header',
      onDrag: ({ source }) => {
        if (source.data.type !== 'smart-table-header') {
          return
        }

        const draggingElement = {
          column: source.data.column,
          columnDefinition: source.data.columnDefinition,
        }

        setIsDragging(true)
        setElement(draggingElement)
      },
      onDrop: ({ source }) => {
        if (source.data.type !== 'smart-table-header') {
          return
        }

        setIsDragging(false)
        setElement(null)
      },
    })

    return combine(headerDraggable, rightDropTarget, leftDropTarget, monitor)
  }, [column, columnDefinition, isDraggable, element])

  const isColumnFixed = useMemo(() => {
    if (
      !columnDefinition ||
      !columnDefinition?.name ||
      columnDefinition.items?.length
    )
      return false

    return (
      leftFixedColumns?.includes(columnDefinition.name) ||
      rightFixedColumns?.includes(columnDefinition.name) ||
      (leftFixedColumns?.length &&
        (columnDefinition?.name === 'checked' ||
          columnDefinition?.name === 'expandable'))
    )
  }, [leftFixedColumns, rightFixedColumns, columnDefinition])

  const shouldApplyBorder =
    lastColumnRefItem?.name === columnId ||
    column.column.columnDef.meta?.subarrayIndex ===
      (columnDefinition?.length || 0) - 1 ||
    isColumnFixed

  const leftPosition = useMemo(() => {
    const leftPositionIndex = leftFixedColumns?.findIndex(
      (col) => col === columnDefinition?.name,
    )
    const checkedColumn = findColumnByName(columns, 'checked')
    const expandableColumn = findColumnByName(columns, 'expandable')

    if (columnDefinition?.name === 'checked' && leftFixedColumns?.length) {
      return 0
    }

    if (columnDefinition?.name === 'expandable' && leftFixedColumns?.length) {
      return checkedColumn?.width ? (checkedColumn.width || 0) + 2 : 0
    }

    if (leftPositionIndex === undefined || leftPositionIndex === -1) return

    const defaultCheckedColumnWidth = checkedColumn?.width
      ? checkedColumn.width + 2
      : 0
    const defaultExpansibleColumn = expandableColumn?.width
      ? expandableColumn.width + 2
      : 0

    let left = defaultCheckedColumnWidth + defaultExpansibleColumn

    leftFixedColumns?.forEach((col, index) => {
      if (index >= leftPositionIndex) return
      const column = findColumnByName(columns, col)
      left += (column?.width || 0) + 2
    })

    return left
  }, [leftFixedColumns, columnDefinition, columns])

  const rightPosition = useMemo(() => {
    const reversedRightFixedColumns = [...(rightFixedColumns || [])].reverse()
    const rightPositionIndex = reversedRightFixedColumns?.findIndex(
      (col) => col === columnDefinition?.name,
    )
    if (rightPositionIndex === undefined || rightPositionIndex === -1) return

    let right = 0

    reversedRightFixedColumns?.forEach((col, index) => {
      if (index >= rightPositionIndex) return
      const column = findColumnByName(columns, col)
      right += (column?.width || 0) + 2
    })

    return right
  }, [rightFixedColumns, columnDefinition, columns])

  if (
    columnDefinition?.type === 'checkbox' ||
    columnDefinition?.type === 'expandable'
  ) {
    return (
      <TableHead
        borderInLines={!!styles?.borderInLines}
        key={column.id}
        colSpan={column.colSpan}
        css={{
          position: isColumnFixed ? 'sticky' : undefined,
          left: isColumnFixed ? leftPosition : undefined,
          zIndex: isColumnFixed ? 3 : 2,

          backgroundColor: isColumnFixed
            ? '$interface_light_down'
            : '$interface_light_pure',
          borderRight: shouldApplyBorder
            ? '1px solid $interface_light_down'
            : undefined,
          overflow: 'hidden',
          paddingLeft: '$2',
          minWidth: columnHeaderWidth ? columnHeaderWidth + 2 : undefined,
          maxWidth: columnHeaderWidth ? columnHeaderWidth + 2 : undefined,
          width: columnHeaderWidth ? columnHeaderWidth + 2 : undefined,
        }}
      >
        {columnDefinition?.type === 'checkbox' && (
          <CheckboxHeader
            name={columnDefinition?.name}
            column={columnDefinition}
          />
        )}
      </TableHead>
    )
  }

  // console.log(
  //   'columnIdConsideringArray',
  //   columnId,
  //   columnIdConsideringArray,
  //   columnDefinitionWidth,
  //   columnHeaderWidth,
  // )

  return (
    <>
      <TableHead
        ref={draggableColumnRef}
        onMouseEnter={() => {
          if (isDraggable) {
            setIsMouseInsideHeader(true)
          }
        }}
        onMouseLeave={() => {
          if (isDraggable) {
            setIsMouseInsideHeader(false)
          }
        }}
        borderInLines={!!styles?.borderInLines}
        key={column.id}
        colSpan={column.colSpan}
        css={{
          cursor: isDraggable ? 'grab' : undefined,
          backgroundColor: isColumnFixed
            ? '$interface_light_down'
            : '$interface_light_pure',
          borderRight: shouldApplyBorder
            ? '1px solid $interface_light_down'
            : undefined,
          zIndex: isColumnFixed ? 3 : 2,
          overflow: 'hidden',
          minWidth: columnHeaderWidth ? columnHeaderWidth + 2 : undefined,
          maxWidth: columnHeaderWidth ? columnHeaderWidth + 2 : undefined,
          width: columnHeaderWidth ? columnHeaderWidth + 2 : undefined,

          position: isColumnFixed ? 'sticky' : undefined,
          left: isColumnFixed ? leftPosition : undefined,
          right: isColumnFixed ? rightPosition : undefined,
        }}
      >
        <Div
          ref={dropLeftRef}
          css={{
            width: '50%',
            // background: 'blue',
            opacity: 0.1,
            height: 50,

            position: 'absolute',
            left: 0,
          }}
        />
        {isDragging && isDraggable && isOverLeft && (
          <Div
            // ref={dropLeftTempRef}
            css={{
              width: 4,
              zIndex: -1,
              background: '$brand_primary_deep',
              // height: 34,

              position: 'absolute',
              left: -2,
              top: 0,
              bottom: 0,
            }}
          />
        )}
        <Div
          css={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HeaderCellDropdownOptions
            shouldShow={isMouseInsideHeader}
            isArray={column.column.columnDef.meta?.isArray}
            isLeftFixed={
              leftFixedColumns?.includes(columnIdConsideringArray) || false
            }
            isRightFixed={
              rightFixedColumns?.includes(columnIdConsideringArray) || false
            }
            removeFixedColumn={() => {
              setLeftFixedColumns?.((state) => {
                return state.filter((col) => col !== columnIdConsideringArray)
              })
              setRightFixedColumns?.((state) => {
                return state.filter((col) => col !== columnIdConsideringArray)
              })
            }}
            hideColumn={() => {
              // console.log(
              //   'columnIdConsideringArray',
              //   columnId,
              //   columnIdConsideringArray,
              // )
              setHiddenColumns?.((state) => {
                return [...state, columnIdConsideringArray]
              })
            }}
            fixInLeft={() => {
              setRightFixedColumns?.((state) => {
                const newState = state.filter(
                  (col) => col !== columnIdConsideringArray,
                )

                return [...newState]
              })
              setLeftFixedColumns?.((state) => {
                const newState = state.filter(
                  (col) => col !== columnIdConsideringArray,
                )

                return [...newState, columnIdConsideringArray]
              })
            }}
            fixInRight={() => {
              setLeftFixedColumns?.((state) => {
                const newState = state.filter(
                  (col) => col !== columnIdConsideringArray,
                )

                return [...newState]
              })
              setRightFixedColumns?.((state) => {
                const newState = state.filter(
                  (col) => col !== columnIdConsideringArray,
                )

                return [columnIdConsideringArray, ...newState]
              })
            }}
            hideArrayColumn={() => {
              // console.log(
              //   'columnIdConsideringArray',
              //   columnId,
              //   columnIdConsideringArray,
              // )
              setHiddenColumns?.((state) => {
                return [...state, columnId]
              })
            }}
          />
          <HeaderCellTextContent column={column} />
          <Div
            css={{
              svg: {
                color: '$interface_dark_up',
                width: 16,
                height: 16,
                display: isDraggable && isMouseInsideHeader ? 'block' : 'none',
              },

              width: 16,
              height: 16,
              display: 'flex',
              alignItems: 'center',
              marginLeft: '$2',
            }}
          >
            <GrabIcon />
          </Div>
          <Div
            ref={dropRightRef}
            css={{
              width: '50%',
              // background: 'red',
              opacity: 0.1,
              height: 50,

              position: 'absolute',
              right: 0,
            }}
          />
          {isDragging && isDraggable && isOverRight && (
            <Div
              // ref={dropRightTempRef}
              css={{
                width: 4,
                background: '$brand_primary_deep',
                zIndex: -1,
                // opacity: 0.1,
                height: 34,

                position: 'absolute',
                right: -2,
                bottom: 0,
              }}
            />
          )}
        </Div>
      </TableHead>
    </>
  )
}

const UnstyledButton = styled('button')

const HeaderCellTextContent = ({
  column,
  maxWidth,
}: {
  column: any
  maxWidth?: number
}) => {
  if (!column) return null
  return (
    <>
      {typeof column.column.columnDef.header === 'string' ? (
        <Text
          variant="description"
          css={{
            textAlign: 'center',
            padding: '$2 0',
            textOverflow: 'ellipsis',
            maxWidth,
          }}
        >
          {flexRender(column.column.columnDef.header, column.getContext())}
        </Text>
      ) : (
        flexRender(column.column.columnDef.header, column.getContext())
      )}
    </>
  )
}

const HeaderCellDropdownOptions = ({
  shouldShow,
  hideColumn,
  fixInLeft,
  fixInRight,
  isLeftFixed,
  isRightFixed,
  removeFixedColumn,
  hideArrayColumn,
  isArray,
}: {
  shouldShow: boolean
  hideColumn?: () => void
  hideArrayColumn?: () => void
  fixInLeft?: () => void
  fixInRight?: () => void
  isLeftFixed?: boolean
  isRightFixed?: boolean
  removeFixedColumn?: () => void
  isArray?: boolean
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  const dropdownToggleRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useOutsideAlerters([containerRef, dropdownRef], () => {
    setIsMenuOpen(false)
  })

  useEffect(() => {
    if (isMenuOpen && dropdownToggleRef.current) {
      const rect = dropdownToggleRef.current.getBoundingClientRect()
      if (typeof window !== 'undefined') {
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        })
      }
    }
  }, [isMenuOpen])

  if (!shouldShow && !isMenuOpen) {
    return (
      <Div
        css={{
          width: 16,
          marginRight: '$2',
          height: 16,
        }}
      />
    )
  }

  return (
    <Div
      ref={containerRef}
      css={{
        // display: 'grid',
        // transition: 'grid-template-rows 250ms',
        // gridTemplateRows: shouldShow ? '1fr' : '0fr',
        marginRight: '$2',
      }}
    >
      <Div
        css={{
          // overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Div
          ref={dropdownToggleRef}
          onClick={() => {
            setIsMenuOpen((state) => !state)
          }}
          css={{
            height: 16,
            width: 16,
            background: '$interface_light_down',
            borderRadius: '$xs',
            cursor: 'pointer !important',

            '> svg': {
              height: 16,
              width: 16,
              color: '$interface_dark_deep',
            },
          }}
        >
          <EllipsisVerticalIcon />
        </Div>
        {isMenuOpen && (
          <>
            {ReactDOM.createPortal(
              <Div
                ref={dropdownRef}
                css={{
                  position: 'absolute',
                  top: `${position.top + 12}px`,
                  left: `${position.left - 12}px`,
                  zIndex: 1,
                  background: '$interface_light_pure',
                  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
                  border: '1px solid $interface_light_down',
                  borderRadius: '$xs',
                  padding: '$1',

                  '> button + button': {
                    marginTop: '$1',
                  },

                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <HeadCellDropdownOption
                  hideColumn={hideColumn}
                  label={`Colapsar ${isArray ? 'grupo' : ''}`}
                  icon={
                    <ChevronUpDownIcon
                      style={{
                        rotate: '90deg',
                      }}
                    />
                  }
                />
                {isArray && (
                  <HeadCellDropdownOption
                    hideColumn={hideArrayColumn}
                    label={`Colapsar esta columna`}
                    icon={
                      <ChevronUpDownIcon
                        style={{
                          rotate: '90deg',
                        }}
                      />
                    }
                  />
                )}
                <Div
                  css={{
                    height: 1,
                    width: '100%',
                    background: '$interface_light_down',
                    margin: '$2 0',
                  }}
                />
                {!isLeftFixed && (
                  <HeadCellDropdownOption
                    hideColumn={() => {
                      fixInLeft?.()
                      setIsMenuOpen(false)
                    }}
                    label={`Fijar a la izquierda`}
                    icon={<ChevronDoubleLeftIcon />}
                  />
                )}
                {!isRightFixed && (
                  <HeadCellDropdownOption
                    hideColumn={() => {
                      fixInRight?.()
                      setIsMenuOpen(false)
                    }}
                    label={`Fijar a la derecha`}
                    icon={<ChevronDoubleRightIcon />}
                  />
                )}
                {(isLeftFixed || isRightFixed) && (
                  <HeadCellDropdownOption
                    hideColumn={() => {
                      removeFixedColumn?.()
                      setIsMenuOpen(false)
                    }}
                    label={`Desfijar columna`}
                    icon={<XMarkIcon />}
                  />
                )}
              </Div>,
              document.body,
            )}
          </>
        )}
      </Div>
    </Div>
  )
}

const HeadCellDropdownOption = (props: {
  hideColumn?: () => void
  label: string
  icon?: React.ReactNode
}) => {
  return (
    <UnstyledButton
      onClick={props.hideColumn}
      css={{
        all: 'unset',

        padding: '$1',

        '&:hover': {
          background: '$interface_light_down',
        },

        '> svg': {
          color: '$interface_dark_up',
          height: 16,
          width: 16,

          marginRight: '$2',
        },

        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {props.icon || <ChevronUpDownIcon />}
      <Text
        variant="caption"
        css={{
          color: '$interface_dark_deep',
        }}
      >
        {props.label}
      </Text>
    </UnstyledButton>
  )
}
