import { PlanType } from './useIsFeatureInPlan'

export type PermissionModule =
  | 'company'
  | 'workers'
  | 'punches'
  | 'shifts'
  | 'moves'
  | 'payments'
  | 'payroll'
  | 'vacation'
  | 'permisos'
  | 'configurations'
  | 'documents'
  | 'reports'
  | 'requests'
  | 'audit'

export type PermissionType = 'all' | 'view' | 'edit' | 'request' | 'import'

export type Permissions = Record<PermissionModule, Record<string, string>>

export const systemModuleLabelToValue = [
  {
    label: 'Empresa',
    value: 'company',
  },
  {
    label: 'Colaboradores',
    value: 'workers',
  },
  {
    label: 'Turnos',
    value: 'shifts',
  },
  {
    label: 'Puntos',
    value: 'punches',
  },
  {
    label: 'Movimientos',
    value: 'moves',
  },
  {
    label: 'Pagos',
    value: 'payments',
  },
  {
    label: 'Planilla nominal',
    value: 'payroll',
  },
  {
    label: 'Vacaciones',
    value: 'vacation',
  },
  {
    label: 'Permisos y Seguridad',
    value: 'permisos',
  },
  {
    label: 'Configuraciones',
    value: 'configurations',
  },
  {
    label: 'Solicitaciones',
    value: 'requests',
  },
  {
    label: 'Documentos',
    value: 'documents',
  },
  {
    label: 'Reportes',
    value: 'reports',
  },
  {
    label: 'Auditoria',
    value: 'audit',
  },
]

export const allModules: PermissionModule[] = [
  'company',
  'workers',
  'punches',
  'shifts',
  'moves',
  'payments',
  'payroll',
  'vacation',
  'permisos',
  'configurations',
  'documents',
  'reports',
  'requests',
  'audit',
]

export const permissions: Permissions = {
  company: {
    all: 'company.all',
  },
  workers: {
    view: 'workers.view',
    fastCreation: 'workers.fastCreation',
    fullCreation: 'workers.fullCreation',
    edit: 'workers.edit',
    liquidate: 'workers.liquidate',
    editLiquidation: 'workers.editLiquidation',
    rehire: 'workers.rehire',
    changeConfig: 'workers.changeConfig',
    changeSalary: 'workers.changeSalary',
    import: 'workers.import',
    all: 'workers.all',
  },
  punches: {
    view: 'punches.view',
    edit: 'punches.edit',
    import: 'punches.import',
    authorize: 'punches.authorize',
    all: 'punches.all',
  },
  shifts: {
    view: 'shifts.view',
    create: 'shifts.create',
    edit: 'shifts.edit',
    gestionar: 'shifts.gestionar',
    all: 'shifts.all',
  },
  moves: {
    request: 'moves.request',
    view: 'moves.view',
    create: 'moves.create',
    edit: 'moves.edit',
    delete: 'moves.delete',
    all: 'moves.all',
  },
  payments: {
    request: 'payments.request',
    view: 'payments.view',
    create: 'payments.create',
    edit: 'payments.edit',
    delete: 'payments.delete',
    all: 'payments.all',
  },
  payroll: {
    view: 'payroll.view',
    edit: 'payroll.edit',
    closePayroll: 'payroll.closePayroll',
    aguinaldo: 'payroll.aguinaldo',
    all: 'payroll.all',
  },
  vacation: {
    request: 'vacation.request',
    view: 'vacation.view',
    create: 'vacation.create',
    edit: 'vacation.edit',
    delete: 'vacation.delete',
    all: 'vacation.all',
  },
  permisos: {
    all: 'permisos.all',
  },
  configurations: {
    payments: 'configurations.payments',
    punches: 'configurations.punches',
    locations: 'configurations.locations',
    holidays: 'configurations.holidays',
    totems: 'configurations.totems',
    salary: 'configurations.salary',
    vacations: 'configurations.vacations',
    aguinaldos: 'configurations.aguinaldos',
    liquidations: 'configurations.liquidations',
    view: 'configurations.view',
    all: 'configurations.all',
  },
  requests: {
    all: 'requests.all',
  },
  documents: {
    view: 'documents.view',
    create: 'documents.create',
    all: 'documents.all',
  },
  reports: {
    export: 'reports.export',
    createTemplate: 'reports.createTemplate',
    createReceipt: 'reports.createReceipt',
    all: 'reports.all',
  },
  audit: {
    all: 'audit.all',
  },
}

export const permissionNamesByType: Record<string, string> = {
  delete: 'Deletar',
  view: 'Ver',
  export: 'Exportar',
  edit: 'Editar',
  all: 'Acesso Completo',
  gestionar: 'Gestionar',
  create: 'Crear',
  request: 'Solicitar',
  authorize: 'Autorizar',

  fastCreation: 'Creac. Rápida',
  fullCreation: 'Creac. Completa',
  liquidate: 'Liquidar',
  editLiquidation: 'Editar Liquidación',
  rehire: 'Recontratar',
  changeConfig: 'Cambiar Config.',
  changeSalary: 'Cambiar Salario',
  import: 'Importar',

  payments: 'Conf. de Pagos',
  punches: 'Conf. de Marcación',
  locations: 'Conf. de Localidades',
  holidays: 'Conf. de Feriados',
  totems: 'Conf. de Tótems',
  salary: 'Conf. de Salarios',
  vacations: 'Conf. de Vacaciones',
  aguinaldos: 'Conf. de Aguinaldos',
  liquidations: 'Conf. de Liquidaciones',

  createTemplate: 'Editar Templates',
  createReceipt: 'Editar Recibos',

  closePayroll: 'Cerrar Planilla',
  aguinaldo: 'Aguinaldos',
}

export const permissionsArray = Object.values(permissions).reduce(
  (acc, permission) => [...acc, ...Object.values(permission)],
  [] as string[],
)

export const useGetAllPermissions = () => {
  return permissions
}

export const PermissionsModulesByPlanType: Record<
  PlanType,
  PermissionModule[]
> = {
  punches: [
    'company',
    'workers',
    'configurations',
    'moves',
    'punches',
    'shifts',
    'permisos',
    'reports',
  ],
  payroll: [
    'company',
    'workers',
    'configurations',
    'moves',
    'punches',
    'shifts',
    'permisos',
    'reports',

    'payments',
    'payroll',
    'vacation',
  ],
  enterprise: allModules,
}
