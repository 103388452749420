import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IUpdateCompanyDTO } from '@/libs/react-query/mutations/company/types'
import { queryClient } from '@/libs/react-query'

export const useHandleUpdateCompany = () => {
  const mutation = useMutation(['useHandleUpdateCompany'], handleUpdateCompany)

  return mutation
}

const handleUpdateCompany = async (props: IUpdateCompanyDTO) => {
  try {
    await apiV2.post<void>('/company/update-company', props)

    queryClient.invalidateQueries('my-company')
  } catch (error) {
    console.log(error)
  }
}
