import { zodResolver } from '@hookform/resolvers/zod'
import React, { createContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ConfigSchema, configSchema } from '../components/index'

export const ConfigContext = createContext<any>({})

interface ConfigProviderProps {
  children: React.ReactNode
}

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const methods = useForm<ConfigSchema>({
    resolver: zodResolver(configSchema),
    defaultValues: {},
  })

  return (
    <FormProvider {...methods}>
      <ConfigContext.Provider value={{}}>{children}</ConfigContext.Provider>
    </FormProvider>
  )
}
