import { ControlledInput, Div, FormRenderer, Radio } from '@/components'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import { useState } from 'react'
import { InactivateWorkerSubtotal } from './Subtotal'
import { InactivateWorkerSchema } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { useLiquidationDates, useReasons } from '../form/useReasons'
import { useWorkerConfigs } from '../form/useWorkerConfigs'
import { useTotalLiquidationValues } from '../hooks/useTotalLiquidationValues'
import { formatCurrency } from '@/utils/numbers/currency'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { useHandleChangeLiquidationType } from '../hooks/useHandleChangeLiquidationType'
import { inactivationLabelToType, inactivationTypeToLabel } from '../parser'
import { useDebounce } from '@/hooks'

export const LiquidationOptions = () => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem('liquidation-menu-state') === 'true' || false,
  )
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()
  const methods = useFormContext<InactivateWorkerSchema>()
  const [inactivationType, antiquity] = useWatch({
    control: methods.control,
    name: ['data.inactivation_type', 'data.antiquity'],
  })
  const { reasons: reasonsForm, complement: complementForm } = useReasons()
  const { reasons: liquidationDates } = useLiquidationDates(antiquity)
  const { reasons: workerConfigs } = useWorkerConfigs()

  const handleChangeLiquidationType = useHandleChangeLiquidationType()

  const { totalFinalValue } = useTotalLiquidationValues()

  const debouncedIsOpen = useDebounce(isOpen, 500)

  return (
    <Div
      css={{
        background: '$interface_light_pure',
        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.05)',
        padding: '$4',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: '$interface_light_down',
      }}
    >
      <Div
        css={{
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '1fr' : '0fr',
          display: 'grid',

          marginBottom: isOpen ? '$4' : 0,
        }}
      >
        <Div
          css={{
            display: 'flex',
            gap: '$8',
            overflow: isOpen && debouncedIsOpen ? undefined : 'hidden',
          }}
        >
          <Div
            css={{
              width: '100%',
            }}
          >
            <Div
              css={{
                marginBottom: '$4',
              }}
            >
              <Radio
                disabled={isLoadingLiquidation}
                items={[
                  'Despido injustificado',
                  'Despido justificado',
                  'Renuncia',
                  'Mutuo Consentimento',
                  'Abandono',
                  'Periodo Prueba',
                  'Muerte',
                ]}
                value={inactivationTypeToLabel[inactivationType]}
                onValueChange={(e) =>
                  handleChangeLiquidationType(inactivationLabelToType[e])
                }
              />
            </Div>
            <FormRenderer inline items={reasonsForm} cols={3} />
            <Div css={{ marginBottom: '$4' }} />
            <FormRenderer inline items={workerConfigs} cols={2} />
            <Div css={{ marginBottom: '$4' }} />
            <FormRenderer inline items={liquidationDates} cols={3} />
            <Div css={{ marginBottom: '$4' }} />
            <ControlledInput {...complementForm} />
          </Div>
          <Div
            css={{
              width: '70%',
            }}
          >
            <InactivateWorkerSubtotal />
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          marginBottom: '$4',
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '0fr' : '1fr',
          display: 'grid',
        }}
      >
        <Div
          css={{
            overflow: isOpen ? 'hidden' : undefined,
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormRenderer inline items={workerConfigs} cols={5} />
            <Text
              variant={'paragraph'}
              size="xl"
              css={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
            >
              Total {formatCurrency(totalFinalValue.total)}
            </Text>
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant={'tertiary'}
          onClick={() => {
            setIsOpen(!isOpen)
            localStorage.setItem('liquidation-menu-state', String(!isOpen))
          }}
          icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {isOpen ? 'Ocultar detalles' : 'Ver detalles'}
        </Button>
      </Div>
    </Div>
  )
}
