import { useCallback } from 'react'

export function useSafeCallback() {
  const callbackFn = useCallback((callback: () => any) => {
    setTimeout(() => {
      callback()
    }, 1)
  }, [])

  return callbackFn
}
