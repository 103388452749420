import { AddShiftFields } from '../../../forms'

export const useJourneyForm = () => {
  const data: AddShiftFields = [
    {
      _type: 'switch',
      label: 'Jornada Flexible',
      name: 'config.is_flexible_journey',
    },
  ]

  return {
    data,
  }
}
