import { Progress, Text } from '@punto-ui/react'
import { useWorkerCreation } from '../context/hooks'
import { Div, Touchable } from '@/components'
import { useFormContext } from 'react-hook-form'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useCallback, useMemo } from 'react'
import { useGetFormFieldNamesToValidate } from '../hooks/useGetFormFieldNamesToValidate'

export const FormStepper = () => {
  const {
    formStep,
    setFormStep,
    isLoading: isLoadingWorker,
    totalSteps,
  } = useWorkerCreation()
  const { trigger, getValues, formState } =
    useFormContext<createCompleteWorkerData>()
  const { data: getFormNames } = useGetFormFieldNamesToValidate()

  const handleNextStep = useCallback(
    async (index: number) => {
      const inputsToValidate = getFormNames(formStep)
      const isValid = await trigger(inputsToValidate)

      if (!isValid) {
        console.log(formState.errors)
      }

      if (index < totalSteps) {
        setFormStep(index)
      } else if (isValid) {
        console.log('SUBMITTING', getValues())
      }
    },
    [
      formState.errors,
      formStep,
      getValues,
      setFormStep,
      trigger,
      totalSteps,
      getFormNames,
    ],
  )

  const stepHasError = useCallback(
    (index: number) => {
      const inputsToValidate = getFormNames(index)

      const errorKeys = Object.keys(formState.errors)
      const hasError = errorKeys.some((key) => inputsToValidate.includes(key))

      return hasError
    },
    [formState.errors, getFormNames],
  )
  const stepsArray = useMemo(() => {
    return [
      {
        setFormStep: handleNextStep,
        text: 'Datos Básicos',
        selectedIndex: formStep,
        index: 0,
        error: stepHasError(0),
      },
      {
        setFormStep: handleNextStep,
        text: 'Datos de Marcación',
        selectedIndex: formStep,
        error: stepHasError(1),
        index: 1,
      },
      // {
      //   setFormStep: handleNextStep,
      //   text: 'Face ID',
      //   selectedIndex: formStep,
      //   error: stepHasError(2),
      //   index: 2,
      // },
      {
        setFormStep: handleNextStep,
        text: 'Datos de Pagos',
        selectedIndex: formStep,
        error: stepHasError(2),
        index: 2,
      },
      {
        setFormStep: handleNextStep,
        text: 'Datos salariales',
        selectedIndex: formStep,
        isLast: true,
        error: stepHasError(3),
        index: 3,
      },
    ]
  }, [formStep, handleNextStep, stepHasError])

  return (
    <Div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {stepsArray.map((step, index) => (
        <Step
          disabled={isLoadingWorker}
          key={index}
          {...step}
          isLast={index === stepsArray.length - 1}
        />
      ))}
      {isLoadingWorker && <Progress size="lg" css={{ marginLeft: '$8' }} />}
    </Div>
  )
}

const Step = ({
  error,
  selectedIndex,
  text,
  isLast,
  index,
  setFormStep,
  disabled,
}: any) => {
  const selected = selectedIndex === index
  const passed = selectedIndex > index

  return (
    <>
      <Touchable
        onClick={() => (disabled ? null : setFormStep(index))}
        disabled={disabled}
      >
        <Div
          css={{
            paddingLeft: '$4',
            paddingRight: '$4',
            border: '1px solid',
            borderColor: error
              ? '$status_danger_deep'
              : selected
              ? '$brand_primary_pure'
              : passed
              ? '$brand_primary_pure'
              : '$interface_dark_up',
            background:
              passed && !error ? '$brand_primary_pure' : 'transparent',
            borderRadius: '$lg',
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <Text
            variant="caption"
            css={{
              // fontWeight: '$bold',
              color: error
                ? '$status_danger_deep'
                : selected
                ? '$brand_primary_pure'
                : passed
                ? '$interface_light_pure'
                : '$interface_dark_up',
            }}
          >
            {text}
          </Text>
        </Div>
      </Touchable>
      {!isLast && (
        <Div
          css={{
            height: 1,
            background: passed ? '$brand_primary_pure' : '$interface_dark_up',
            opacity: disabled ? 0.5 : 1,
            width: 12,
          }}
        />
      )}
    </>
  )
}
