import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { TemplateTableSchema, TemplateTableSchemaType } from './type'
import React, { useEffect } from 'react'
import { parseTemplateToRow } from './parser'
import {
  Div,
  ExportTemplateDrawer,
  SmartTable,
  useSmartTableColumnManipulation,
} from '@/components'
import { SmartColumnType } from '@/components/SmartTable/types'
import { Button, Tooltip } from '@punto-ui/react'
import {
  CloudArrowDownIcon,
  Cog6ToothIcon,
  EyeIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import { useReportTemplates } from '@/libs/react-query/hooks/useReportTemplates'
import { TemplateHubSchemaType } from '../../type'
import { useTabStore } from '@/store'
import { useHandleToggleFavoriteTemplate } from '@/libs/react-query/mutations/reports/useHandleToggleFavoriteTemplate'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'

const laborBooksInPunches = [
  'monthly-workers-ips',
  'monthly-sueldos',
  'yearly-sueldos',
  'general-workers-status-summary',
]

export const TemplateList = () => {
  const {
    data: isTemplateBuilderFullyAvailable,
    detail: templateBuilderDetail,
  } = useIsFeatureInPlan('payroll')

  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')

  const { addTab, changeTab } = useTabStore((state) => ({
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const methods = useForm<TemplateTableSchemaType>({
    resolver: zodResolver(TemplateTableSchema),
    defaultValues: {
      data: [],
      pagination: {
        page: 0,
        perPage: 20,
        total: 0,
        totalPages: 0,
      },
    },
  })

  const hubMethods = useFormContext<TemplateHubSchemaType>()

  const [pagination] = useWatch({
    control: methods.control,
    name: ['pagination'],
  })

  const [name] = useWatch({
    control: hubMethods.control,
    name: ['configurations.name'],
  })

  const { data: templates } = useReportTemplates({
    name,
    page: pagination.page,
    perPage: pagination.perPage,
  })

  useEffect(() => {
    if (!templates) return

    const data = parseTemplateToRow(templates || [])
    methods.reset(data)
  }, [templates, methods])

  const columns: SmartColumnType[] = [
    {
      name: 'name',
      header: 'Nombre',
      type: 'input-text',
      width: 350,
      disabled: true,
    },
    {
      name: 'favorite',
      header: 'Fav.',
      width: 100,
      type: 'custom',
      component: ({ index }: { index: number }) => (
        <TemplateListFavorite index={index} />
      ),
    },
    {
      name: 'edit',
      header: 'Editar',
      width: 100,
      type: 'custom',
      component: ({ index }: { index: number }) => {
        const id = methods.getValues(`data.${index}.original.id`)

        if (id?.includes('default')) {
          return null
        }

        return (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 100,
            }}
          >
            <Button
              icon={
                methods.getValues(`data.${index}.original.identifier_id`) ? (
                  <Cog6ToothIcon />
                ) : (
                  <EyeIcon />
                )
              }
              onClick={(e) => {
                addTab('reportsV2', [
                  {
                    id: methods.getValues(`data.${index}.id`),
                    type: 'template',
                    label: `Editar ${methods.getValues(
                      `data.${index}.name.value`,
                    )}`,
                  },
                ])
                changeTab('reportsV2', methods.getValues(`data.${index}.id`))
              }}
              variant={'tertiary'}
              css={{ height: 24, width: '90%' }}
            >
              {methods.getValues(`data.${index}.original.identifier_id`)
                ? 'Editar'
                : 'Ver'}
            </Button>
          </Div>
        )
      },
    },
    {
      name: 'download',
      header: 'Bajar',
      width: 140,
      type: 'custom',
      component: ({ index }: { index: number }) => {
        const id = methods.getValues(`data.${index}.original.id`)

        const isDisabled =
          laborBooksInPunches.some((l) => id?.includes(l)) &&
          !isTemplateBuilderFullyAvailable

        return (
          <Tooltip message={templateBuilderDetail} arrow>
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 140,
              }}
            >
              <Button
                disabled={isDisabled}
                icon={<CloudArrowDownIcon />}
                variant={'secondary'}
                onClick={() => {
                  setSelectedTemplateId(methods.getValues(`data.${index}.id`))
                }}
                css={{ height: 24, width: '90%' }}
              >
                Generar
              </Button>
            </Div>
          </Tooltip>
        )
      },
    },
    {
      name: 'description',
      header: 'Descripción',
      width: 425,
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'type',
      header: 'Tipo',
      type: 'input-text',
      width: 120,
      disabled: true,
    },
    {
      name: 'lines',
      width: 120,
      header: 'Lineas',
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'columns',
      width: 120,
      header: 'Columnas',
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'details',
      width: 120,
      header: 'Detalles',
      type: 'input-text',
      disabled: true,
    },
    {
      width: 150,
      name: 'created_by',
      header: 'Creado por',
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'created_at',
      header: 'Creado en',
      width: 120,
      type: 'input-text',
      disabled: true,
    },
  ]

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'templateListColumnsOrder',
    tableHiddenUniqueName: 'templateListHiddenColumns',
  })

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto',

          '> div': {
            maxHeight: 'calc(100vh - 400px)',
            minHeight: 100,
          },
        }}
      >
        <SmartTable
          columns={columns}
          withPagination
          style={{
            borderInLines: true,
          }}
          areColumnsDraggable
          defaultColumnOrder={defaultColumnsOrder}
          defaultHiddenColumns={defaultHiddenColumns}
          saveColumnsOrderCallback={saveColumnsOrderCallback}
          saveHiddenColumnsCallback={saveHiddenColumnsCallback}
          resetColumnOrder={resetStateColumnOrder}
          maxDepth={1}
          paginationProps={{
            onChange: (data) => {
              methods.setValue('pagination.page', data.pageIndex)
              methods.setValue('pagination.perPage', data.pageSize)
            },
            page: pagination.page,
            perPage: pagination.perPage,
            totalPages: pagination.totalPages,
            total: pagination.total,
          }}
        />
      </Div>

      <ExportTemplateDrawer
        templateId={selectedTemplateId}
        isOpen={!!selectedTemplateId}
        setIsOpen={() => setSelectedTemplateId('')}
      />
    </FormProvider>
  )
}

interface TemplateListFavoriteProps {
  index: number
}

const TemplateListFavorite = (props: TemplateListFavoriteProps) => {
  const methods = useFormContext<TemplateTableSchemaType>()

  const { mutateAsync: handleToggleFavorite } =
    useHandleToggleFavoriteTemplate()

  const [id, favorite] = useWatch({
    control: methods.control,
    name: [`data.${props.index}.id`, `data.${props.index}.favorite`],
  })

  if (id?.includes('default')) {
    return null
  }

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,

        svg: {
          fill: '$brand_primary_pure',
          color: '$brand_primary_pure',

          height: 20,
          width: 20,

          cursor: 'pointer',
        },
      }}
    >
      {favorite.value ? (
        <StarIcon
          onClick={() => {
            const item = methods.getValues(`data.${props.index}`)
            handleToggleFavorite(item.id)
          }}
        />
      ) : (
        <Div
          css={{
            height: 55,
            width: 40,

            cursor: 'pointer',
          }}
          onClick={() => {
            const item = methods.getValues(`data.${props.index}`)
            handleToggleFavorite(item.id)
          }}
        />
      )}
    </Div>
  )
}
