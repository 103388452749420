import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IPdfTemplate } from '../../types/reports'

const fetchPdfTemplate = async (id: string) => {
  try {
    const response = await apiV2.post<IPdfTemplate>(
      '/report-builder/get-pdf-template',
      {
        id,
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const usePdfTemplate = (id: string) => {
  const query = useQuery(
    ['pdf', id],
    async () => {
      const workers = await fetchPdfTemplate(id)

      return workers
    },
    {
      keepPreviousData: true,
      enabled: !!id,
    },
  )

  return query
}
