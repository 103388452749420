import { AddShiftFields } from '../../../forms'

export const useToleranceForm = () => {
  const data: AddShiftFields = [
    {
      _type: 'input',
      label: 'Tolerancia de Entrada - llegada anticipada (minutos)',
      name: 'config.entrance_tolerance_before',
      inputType: 'only-numbers',
    },
    {
      _type: 'input',
      label: 'Tolerancia de Entrada - llegada tardia (minutos)',
      name: 'config.entrance_tolerance_after',
      inputType: 'only-numbers',
    },
    {
      _type: 'input',
      label: 'Tolerancia de Salida - retiro anteicipado (minutos)',
      name: 'config.leave_tolerance_before',
      inputType: 'only-numbers',
    },
    {
      _type: 'input',
      label: 'Tolerancia de Salida (minutos) - retiro tardio',
      name: 'config.leave_tolerance_after',
      inputType: 'only-numbers',
    },
  ]

  return {
    data,
  }
}
