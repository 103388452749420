import { generateRandomNumberUnder100 } from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const multipleVacationsColumns: IReportBuilderColumnType[] = [
  {
    type: 'vacations' as const,
    subgroup: 'Totales',
    valueType: 'number' as const,
    value: 'total_days',
    getRandomPlaceholder: generateRandomNumberUnder100,
    label: 'Dias totales de vacaciones',
  },
]
