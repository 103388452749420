import { styled } from '@punto-ui/react'

export const SearchInput = styled('input', {
  all: 'unset',
  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  paddingLeft: '$2',
})
