import { Div } from '@/components'
import { MainContent } from './components'
import { SmartShiftManagementProvider } from './context'

export const SmartShiftManagement = () => {
  return (
    <SmartShiftManagementProvider>
      <Div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <MainContent />
        </Div>
      </Div>
    </SmartShiftManagementProvider>
  )
}
