import { XlsxIcon } from '@/assets/icons/XlsxIcon'
import { Div } from '@/components'
import { DropdownV2 } from '@/components/DropdownV2'
import { ProgressBar } from '@/components/ProgressBar'
import { useDownload } from '@/contexts/DownloadContext'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Button, styled, Text } from '@punto-ui/react'
import { useVirtualizer } from '@tanstack/react-virtual'
import axios from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as XLSX from 'xlsx'

const Table = styled('table', {
  borderCollapse: 'collapse',
  width: '100%',
  fontFamily: '$sans',
  fontSize: '$sm',
})

const Th = styled('th', {
  backgroundColor: '$interface_light_up',
  padding: '$2',
  fontWeight: 'bold',
  textAlign: 'left',
})

const Td = styled('td', {
  padding: '$2',
})

const Tr = styled('tr', {
  '&:nth-child(even)': {
    backgroundColor: '$interface_light_down',
  },
  '&:hover': {
    backgroundColor: '$interface_light_deep',
  },
})

const VirtualRow = styled('div', {
  display: 'flex',
  borderBottom: '1px solid $interface_dark_down',
})

const VirtualCell = styled('div', {
  flex: 1,
  padding: '$2',
  borderRight: '1px solid $interface_dark_down',
  whiteSpace: 'nowrap',
  // overflow: 'hidden',
})

export const DocumentXlsxViewer = ({
  url,
  name,
}: {
  url?: string
  name?: string
}) => {
  const { downloadFile } = useDownload()
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null)
  const [currentSheet, setCurrentSheet] = useState<string>('')
  const [sheetData, setSheetData] = useState<any[][]>([])
  const [loadingProgress, setLoadingProgress] = useState<number>(0)

  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: sheetData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  })

  const biggestSheetColumn = useMemo(() => {
    return sheetData.reduce((max, row) => Math.max(max, row.length), 0)
  }, [sheetData])

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: biggestSheetColumn,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 150,
    overscan: 5,
  })

  useEffect(() => {
    const fetchXlsxData = async () => {
      if (url) {
        try {
          const response = await axios.get(url, {
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                )
                setLoadingProgress(percentCompleted)
              }
            },
          })

          const data = new Uint8Array(response.data)
          const wb = XLSX.read(data, { type: 'array' })
          setWorkbook(wb)
          if (wb.SheetNames.length > 0) {
            setCurrentSheet(wb.SheetNames[0])
          }
        } catch (error) {
          console.error('Error fetching XLSX file:', error)
        }
      }
    }

    fetchXlsxData()
  }, [url])

  useEffect(() => {
    if (workbook && currentSheet) {
      const sheet = workbook.Sheets[currentSheet]
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      setSheetData(data as any[][])
    }
  }, [workbook, currentSheet])

  return (
    <Div>
      <Div
        ref={parentRef}
        css={{
          minHeight: 500,
          maxHeight: 500,
          maxWidth: 1200,
          overflow: 'auto',
          padding: '$4',

          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',

          // background: '$status_success_up',
          background: sheetData.length > 0 ? '$interface_light_up' : '#E0F5D3',

          svg: {
            height: 64,
            width: 64,
          },
        }}
      >
        {sheetData.length > 0 ? (
          <Div
            css={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: `${columnVirtualizer.getTotalSize()}px`,
              position: 'relative',
              border: '1px solid $interface_dark_down',
              borderRight: 0,
              borderBottom: 0,
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => (
              <VirtualRow
                key={virtualRow.index}
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,

                  '&:hover': {
                    background: '$interface_light_deep',
                  },
                }}
              >
                {columnVirtualizer.getVirtualItems().map((virtualCol) => (
                  <VirtualCell
                    key={virtualCol.index}
                    css={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: '100%',
                      width: `${virtualCol.size}px`,
                      transform: `translateX(${virtualCol.start}px)`,
                    }}
                  >
                    <Text
                      variant="caption"
                      css={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {sheetData[virtualRow.index][virtualCol.index]}
                    </Text>
                  </VirtualCell>
                ))}
              </VirtualRow>
            ))}
          </Div>
        ) : loadingProgress < 100 ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <XlsxIcon />
            <ProgressBar
              progressInPercent={loadingProgress}
              text={`${loadingProgress}%`}
            />
          </Div>
        ) : (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <XlsxIcon />
          </Div>
        )}
      </Div>
      <Div
        css={{
          background: '$interface_light_up',
          height: 48,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <Div
          css={{
            minWidth: '30%',
          }}
        >
          {workbook && (
            <DropdownV2
              value={currentSheet}
              onChangeValue={(value) => {
                setCurrentSheet(value.value)
              }}
              options={workbook.SheetNames.map((sheetName) => ({
                label: sheetName,
                value: sheetName,
              }))}
            />
          )}
        </Div>
        <Div>
          <Button
            onClick={() => {
              if (url) {
                setTimeout(() => {
                  if (url) {
                    downloadFile(url, name)
                  }
                }, 10)
              }
            }}
          >
            <ArrowDownTrayIcon />
            Descargar
          </Button>
        </Div>
      </Div>
    </Div>
  )
}
