import { queryClient } from '@/libs/react-query'
import { ICreatePdfDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleCreatePdf = async (data: ICreatePdfDTO) => {
  await apiV2.post<any>(`/report-builder/create-pdf-template`, data)
  queryClient.invalidateQueries('pdfs')
}

export const useHandleCreatePdf = () => {
  const mutation = useCustomMutation(['useHandleCreatePdf'], handleCreatePdf)

  return mutation
}
