import { useFormContext } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { compareAndConstructUserLiquidation } from '../parser'
import { useUpdateUserLiquidation } from '@/libs/react-query/mutations/company/useUpdateUserLiquidation'

export const useHandleUpdateLiquidation = () => {
  const methods = useFormContext<InactivateWorkerSchema>()
  const { mutateAsync: handleUpdateLiquidation } = useUpdateUserLiquidation()

  const handleUpdateUserLiquidation = async () => {
    const values = methods.getValues()

    const dto = compareAndConstructUserLiquidation(values.original, values.data)

    await handleUpdateLiquidation({
      ...dto,
      liquidation_id: values.liquidationId,
    })
  }
  return { handleUpdateUserLiquidation }
}
