import { FormRenderer } from '@/components'
import { FormContainer } from '..'
import { useInactivateSalary } from '../form/useSalaries'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'

interface IProportionalSalaryLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const ProportionalSalaryLiquidation = ({
  isOpen,
  toggleOpen,
}: IProportionalSalaryLiquidationParams) => {
  const conceptKey = 'proportional_salary'
  const { form: inactivateSalaryForm } = useInactivateSalary()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const positiveSalaryPart = useWatch({
    control,
    name: 'data.total_positive_salary',
  })

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer
    // css={{
    //   marginBottom: 0,
    //   padding: 0,
    //   marginLeft: '$4',
    // }}
    >
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Haberes Salário Proporcional'}
        total={formatCurrency(+positiveSalaryPart)}
        isIpsDisabled={!concept.ips}
        conceptType="basic"
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && (
        <FormRenderer inline items={inactivateSalaryForm} cols={4} />
      )}
    </FormContainer>
  )
}
