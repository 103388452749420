import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useCompanyPlaceColumns } from './table'
import { ICompanyPlace } from '@/libs/react-query/types/company'
import { CompanyPlacesConfigDrawer } from './components/CompanyPlacesConfigDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { useCan } from '@/hooks'

interface CompanyPlacesDrawerState {
  isOpen: boolean
  selectedCompanyPlace: ICompanyPlace | null
}

export const CompanyPlaces = () => {
  const canCreateLocations = useCan(['configurations.locations'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] =
    React.useState<CompanyPlacesDrawerState>({
      isOpen: false,
      selectedCompanyPlace: null,
    })

  const columns = useCompanyPlaceColumns()

  const handleCreatePaymentConfig = () => {
    setValue('place', {
      description: '',
      latitude: '',
      longitude: '',
      name: '',
      punches_radius: 50,
      punches_group_ids: [],
    })
    setDrawerState({
      isOpen: true,
      selectedCompanyPlace: null,
    })
  }

  const handleSelectCompanyPlace = (row: ICompanyPlace) => {
    setValue('place', {
      id: row.id,
      description: row.description,
      latitude: row.latitude,
      longitude: row.longitude,
      name: row.name,
      punches_radius: row.punches_radius,
      punches_group_ids: row.companyConfigPlaces.map(
        (config) => config.punch_config_group_id,
      ),
    })
    setDrawerState({
      isOpen: true,
      selectedCompanyPlace: row,
    })
  }

  const table = useReactTable({
    data: company?.companyPlaces ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del Local" placeholder="Nombre del Local" />
        {canCreateLocations && (
          <Button onClick={() => handleCreatePaymentConfig()}>
            Crear Localidade
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<ICompanyPlace>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectCompanyPlace}
        />
      </Div>

      <CompanyPlacesConfigDrawer
        isCreation={drawerState.selectedCompanyPlace === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedCompanyPlace: null,
          })
        }
      />
    </Div>
  )
}
