import { Div, FormRenderer } from '@/components'
import { ReceiptBuilderFields } from '../../type'

export const FooterConfigurationForm = () => {
  const form: ReceiptBuilderFields = [
    {
      name: 'footerConfig.city',
      label: 'Ciudad de firma',
      _type: 'input',
    },
  ]

  return (
    <Div
      css={{
        padding: '$6',
      }}
    >
      <FormRenderer items={form} />
    </Div>
  )
}
