import {
  differenceInIntervalsInHours,
  formattedHourToMinutes,
  isWithinInterval,
} from '../date'
import { getNumberModule } from '../math/number'

interface IWorkHoursParams {
  entrance: string
  exit: string
  intervals: Array<{
    startTime: string
    endTime: string
    duration: string
  }>
  fromDayToNight: string
  fromNightToDay: string
}

interface IWorkHoursResponse {
  dayHours: number
  nightHours: number
  total: number
}

export const getWorkHours = ({
  entrance,
  exit,
  intervals,
  fromDayToNight,
  fromNightToDay,
}: IWorkHoursParams): IWorkHoursResponse => {
  const totalWorkHours = getTotalWorkHours({
    entrance,
    exit,
    fromDayToNight,
    fromNightToDay,
    intervals,
  })

  const intervalHours = getTotalIntervalHours({
    intervals,
    fromDayToNight,
    fromNightToDay,
  })

  const total = totalWorkHours.total - intervalHours.total

  return {
    dayHours: getNumberModule(totalWorkHours.dayHours - intervalHours.dayHours),
    nightHours: getNumberModule(
      totalWorkHours.nightHours - intervalHours.nightHours,
    ),
    total: getNumberModule(total),
  }
}

const isEntranceAndExitFromDifferentPeriods = (
  entrance: string,
  exit: string,
) => {
  const entranceIsInNight = isWithinInterval(entrance, '20:00', '05:59')
  const exitIsInNight = isWithinInterval(exit, '20:00', '05:59')

  if (entranceIsInNight && exitIsInNight) {
    return differenceInIntervalsInHours(entrance, exit) > 10
  } else if (!entranceIsInNight && !exitIsInNight) {
    return differenceInIntervalsInHours(entrance, exit) > 14 || entrance > exit
  }

  return false
}

export function getTotalWorkHours({
  entrance,
  exit,
  fromDayToNight,
  fromNightToDay,
}: IWorkHoursParams): IWorkHoursResponse {
  let values = {
    dayHours: 0,
    nightHours: 0,
    total: 0,
  }

  const entranceIsInNight = isWithinInterval(
    entrance,
    fromDayToNight,
    fromNightToDay,
  )
  const exitIsInNight = isWithinInterval(exit, fromDayToNight, fromNightToDay)
  const fromOtherPeriod = isEntranceAndExitFromDifferentPeriods(entrance, exit)

  if (entranceIsInNight && exitIsInNight && fromOtherPeriod) {
    const entranceToDayTurn = differenceInIntervalsInHours(
      entrance,
      fromNightToDay,
    )
    const dayTurnToNightTurn = differenceInIntervalsInHours(
      fromNightToDay,
      fromDayToNight,
    )
    const nightTurnToExit = differenceInIntervalsInHours(fromDayToNight, exit)

    const total = entranceToDayTurn + dayTurnToNightTurn + nightTurnToExit

    values = {
      dayHours: dayTurnToNightTurn,
      nightHours: entranceToDayTurn + nightTurnToExit,
      total,
    }
  } else if (entranceIsInNight && exitIsInNight && !fromOtherPeriod) {
    const entranceToExit = differenceInIntervalsInHours(entrance, exit)

    values = {
      dayHours: 0,
      nightHours: entranceToExit,
      total: entranceToExit,
    }
  } else if (entranceIsInNight && !exitIsInNight) {
    const entranceToDayTurn = differenceInIntervalsInHours(
      entrance,
      fromNightToDay,
    )
    const dayTurnToExit = differenceInIntervalsInHours(fromNightToDay, exit)

    const total = entranceToDayTurn + dayTurnToExit

    values = {
      dayHours: dayTurnToExit,
      nightHours: entranceToDayTurn,
      total,
    }
  } else if (!entranceIsInNight && exitIsInNight) {
    const entranceToDayTurn = differenceInIntervalsInHours(
      entrance,
      fromDayToNight,
    )

    const dayTurnToExit = differenceInIntervalsInHours(fromDayToNight, exit)

    const total = entranceToDayTurn + dayTurnToExit

    values = {
      dayHours: entranceToDayTurn,
      nightHours: dayTurnToExit,
      total,
    }
  } else if (!entranceIsInNight && !exitIsInNight && !fromOtherPeriod) {
    const entranceToExit = differenceInIntervalsInHours(entrance, exit)

    values = {
      dayHours: entranceToExit,
      nightHours: 0,
      total: entranceToExit,
    }
  } else if (!entranceIsInNight && !exitIsInNight) {
    const entranceToDayTurn = differenceInIntervalsInHours(
      entrance,
      fromDayToNight,
    )

    const nightHours = differenceInIntervalsInHours(
      fromDayToNight,
      fromNightToDay,
    )

    const otherDayHours = differenceInIntervalsInHours(fromNightToDay, exit)

    const total = entranceToDayTurn + otherDayHours + nightHours

    values = {
      dayHours: entranceToDayTurn + otherDayHours,
      nightHours,
      total,
    }
  }

  return values
}

export const getTotalIntervalHours = ({
  intervals,
  fromDayToNight,
  fromNightToDay,
}: {
  intervals: Array<{
    startTime: string
    endTime: string
    duration: string
  }>
  fromDayToNight: string
  fromNightToDay: string
}): IWorkHoursResponse => {
  const values = {
    dayHours: 0,
    nightHours: 0,
    total: 0,
  }

  intervals.forEach((interval) => {
    const isMorningInterval = isWithinInterval(
      interval.startTime,
      fromNightToDay,
      fromDayToNight,
    )
    const isNightInterval = isWithinInterval(
      interval.startTime,
      fromDayToNight,
      fromNightToDay,
    )

    if (isMorningInterval) {
      values.dayHours += formattedHourToMinutes(interval.duration) / 60
    } else if (isNightInterval) {
      values.nightHours += formattedHourToMinutes(interval.duration) / 60
    }
  })

  values.total = values.dayHours + values.nightHours
  return values
}
