import { IContentOption } from '../DataForm'

export const paymentsPdfData: IContentOption[] = [
  {
    label: 'Titulo del pago',
    value: 'pago.titulo',
  },
  {
    label: 'Tipo de pago',
    value: 'pago.tipo',
  },
  {
    label: 'Descripción del pago',
    value: 'pago.descripcion',
  },
  {
    label: 'Fecha del Pago',
    value: 'pago.fecha',
  },
  {
    label: 'Valor del Pago',
    value: 'pago.valor',
  },
]
