import { PunchesTable } from '@/pages/marcaciones/components/UserTab/Table'
import { UserWorkedHoursProvider } from '@/pages/marcaciones/components/UserTab/context'
import { PunchMatrixProvider } from '@/pages/marcaciones/context'
import { ContextProvider as MovementsProvider } from '@/pages/movimientos/context'
import { useTabStore } from '@/store'
import { useEffect } from 'react'

export const UserTab = ({ id, date }: { id: string; date: Date }) => {
  const { addActions } = useTabStore((state) => ({
    addActions: state.setActionsTab,
  }))

  useEffect(() => {
    addActions('payroll', [])
  }, [addActions])

  return (
    <MovementsProvider>
      <PunchMatrixProvider>
        <UserWorkedHoursProvider id={id} defaultDate={date}>
          <PunchesTable />
        </UserWorkedHoursProvider>
      </PunchMatrixProvider>
    </MovementsProvider>
  )
}
