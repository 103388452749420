import { styled, Text } from '@punto-ui/react'

export const WorkersListContainer = styled('div', {
  marginTop: '$2',
  // borderRadius: '$lg',
})

export const TableContainer = styled('div', {
  borderRadius: '$lg',
  // paddingRight: 60,

  border: '2px solid',
  borderColor: '$interface_light_down',

  variants: {
    noPadding: {
      true: {
        paddingRight: 0,
      },
    },
  },
})

export const DomTable = styled('table', {
  tableLayout: 'fixed',
  overflow: 'visible',
  whiteSpace: 'nowrap',
  boxShadow: '0px 0px 16px 0px #343A4014',

  borderCollapse: 'separate',
  borderSpacing: 0,
  backgroundColor: '$interface_light_pure',

  borderRadius: '$lg',

  // '& div': {
  //   '&:nth-child(even)': {
  //     background: '$interface_light_up',
  //   },
  // },

  // borderTop: '2px solid',
  // borderLeft: '2px solid',

  borderColor: '$interface_light_down',

  variants: {
    borderInLines: {
      false: {
        borderRightWidth: 'none',
      },
      true: {
        borderRight: '2px solid',
        borderColor: '$interface_light_down',
      },
    },
  },
})

export const DomTHead = styled('thead', {
  backgroundColor: '$interface_light_pure',
})

export const TableHead = styled('th', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  borderBottom: '1px solid',
  borderColor: '$interface_light_down',

  backgroundColor: '$interface_light_pure',
  position: 'sticky',
  top: 0,

  variants: {
    borderInLines: {
      false: {
        borderRight: '2px solid',
        borderColor: '$interface_light_down',
      },
      true: {
        borderRightWidth: 'none',
      },
    },
  },
})

export const TableData = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  borderBottom: '1px solid',
  // borderTop: '1px solid',
  borderColor: '$interface_light_down',

  variants: {
    borderInLines: {
      false: {
        borderRight: '2px solid',
        borderColor: '$interface_light_down',
      },
      true: {
        borderRight: 'none',
      },
    },
  },
})

export const StyledContentText = styled(Text, {
  color: '$interface_dark_deep',
  fontSize: '$sm',
  textAlign: 'left',
})

export const StyledHeadingText = styled(Text, {
  color: '$interface_dark_deep',
  fontWeight: 'bold',
  fontSize: '$sm',
  textAlign: 'left',
  padding: '$3 $2',
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  padding: '$6',
})
