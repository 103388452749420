import { IContentOption } from '../DataForm'

export const movementsPdfData: IContentOption[] = [
  {
    label: 'Titulo del movimiento',
    value: 'movimiento.titulo',
  },
  {
    label: 'Tipo de movimiento',
    value: 'movimiento.tipo',
  },
  {
    label: 'Descripción del movimiento',
    value: 'movimiento.descripcion',
  },
  {
    label: 'Fecha inicial',
    value: 'movimiento.fecha_inicial',
  },
  {
    label: 'Fecha final',
    value: 'movimiento.fecha_final',
  },
  {
    label: 'Hora Inicial',
    value: 'movimiento.hora_inicio',
  },
  {
    label: 'Hora Final',
    value: 'movimiento.hora_final',
  },
]
