import { Text, styled } from '@punto-ui/react'
import { DomTable, TableHead } from './styles'
import { Table as ReactTable, flexRender } from '@tanstack/react-table'
import React from 'react'
import { GroupData } from '../../types'
import { TableRow } from './components/TableRow'
import { Div } from '@/components'
import { useVirtualizer } from '@tanstack/react-virtual'

export type TableProps = {
  table: ReactTable<GroupData>
  isLoading?: boolean
}

const Tr = styled('th', {
  // position: 'sticky',
  // top: 0,
  background: '$interface_light_pure',
  // zIndex: 3,

  // borderTopLeftRadius: 8,
  // borderTopRightRadius: 8,
})

export const PolicyTable = ({ table }: TableProps) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => ref.current,
    estimateSize: React.useCallback(() => 40, []),
    overscan: 15,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  })

  return (
    <Div
      ref={ref}
      css={{
        border: '1px solid $interface_dark_up',

        borderRadius: 8,
        // overflowX: 'scroll',
        // width: '100%',
        // maxWidth: '700px',
        // maxHeight: '900px',
        overflow: 'scroll',
        position: 'relative',
      }}
    >
      <DomTable
        css={{
          tableLayout: 'fixed',
          display: 'grid',
          borderCollapse: 'collapse',
        }}
      >
        <thead
          style={{
            position: 'sticky',
            top: 0,
            background: '$interface_light_pure',
            zIndex: 3,

            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,

            display: 'grid',
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Tr
                key={headerGroup.id}
                css={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                {headerGroup.headers.map((column) => {
                  if (column.column.columnDef?.meta?.headless) {
                    return null
                  }

                  return (
                    <TableHead
                      key={column.id}
                      colSpan={column.colSpan}
                      css={{
                        display: 'flex',
                        width: column.getSize(),
                        ...column.column.columnDef?.meta?.thStyles,
                      }}
                    >
                      {typeof column.column.columnDef.header === 'string' ? (
                        <Text
                          variant="paragraph"
                          css={{ textAlign: 'left', fontWeight: 700 }}
                        >
                          {flexRender(
                            column.column.columnDef.header,
                            column.getContext(),
                          )}
                        </Text>
                      ) : (
                        flexRender(
                          column.column.columnDef.header,
                          column.getContext(),
                        )
                      )}
                    </TableHead>
                  )
                })}
              </Tr>
            )
          })}
        </thead>
        <tbody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            // overflow: 'hidden',
            position: 'relative',
            display: 'grid',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = table.getRowModel().rows[virtualRow.index]
            return (
              <TableRow
                data-index={virtualRow.index}
                key={row.id}
                row={row}
                table={table}
                css={{
                  // display: 'grid',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: `${virtualRow.size}px`,
                  width: '100%',
                  transform: `translateY(calc(${virtualRow.start}px))`,
                }}
              />
            )
          })}
          {/* {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} row={row} table={table} />
          ))} */}
        </tbody>
      </DomTable>
    </Div>
  )
}
