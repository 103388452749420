import { TagProps } from '@punto-ui/react'
import { IMovementV2 } from '../movements'
import { IWorkerUser } from '../worker'
import { IDefaultApiPagination } from '../pagination'
import { IPunchMetadata } from '../punch'

export interface IPunch {
  id: string
  identifier_id: string
  user_id: string
  date: string
  latitude: number
  longitude: number
  created_at: string
  updated_at: string
  tags: any[]

  liveness_precision?: number
  is_real_face?: boolean

  metadata: IPunchMetadata

  distance?: number
  isValidByDistance?: boolean
}

export interface IHoursDetail {
  startTime: string
  endTime: string
}

export type WorkedDayStatus =
  | 'HOLIDAY'
  | 'WORKING'
  | 'FREE'
  | 'REST'
  | 'ABSENCE'
  | 'MOVEMENT'
  | 'ALL-DAY-MOVEMENT'
  | 'INCONSISTENCY'
  | 'VACATION'
  | 'NOT_HIRED'

export const fromWorkedDayStatusToLabel = (
  status: WorkedDayStatus,
  short?: boolean,
) => {
  switch (status) {
    case 'ABSENCE':
      return 'Ausencia'
    case 'NOT_HIRED':
      return 'No contratado'
    case 'ALL-DAY-MOVEMENT':
      return 'Movimiento'
    case 'FREE':
      return 'Libre'
    case 'HOLIDAY':
      return 'Feriado'
    case 'MOVEMENT':
      return 'Movimiento'
    case 'REST':
      return 'Descanso'
    case 'WORKING':
      return 'Presencia'
    case 'VACATION':
      return 'Vacaciones'
    case 'INCONSISTENCY':
      return short
        ? 'Inconsistencia'
        : 'Inconsistencia en la marcación (presencia)'
  }
}

export const fromWorkedDayStatusToTagVariant = (
  status: WorkedDayStatus,
): TagProps['variant'] => {
  switch (status) {
    case 'ABSENCE':
      return 'negative'
    case 'ALL-DAY-MOVEMENT':
      return 'warning'
    case 'MOVEMENT':
      return 'neutral'
    case 'FREE':
      return 'neutral'
    case 'HOLIDAY':
      return 'neutral'
    case 'REST':
      return 'neutral'
    case 'WORKING':
      return 'positive'
    case 'VACATION':
      return 'neutral'
    case 'INCONSISTENCY':
      return 'warning'
    case 'NOT_HIRED':
      return 'warning'
  }
}

export interface SavedHourInfos {
  morningHours: number | null
  nightHours: number | null
  morningExtraHours: number | null
  nightExtraHours: number | null
  morningDiscountHours: number | null
  nightDiscountHours: number | null
  morningHolidayHours: number | null
  nightHolidayHours: number | null
  intervalHours: number | null
}

export interface AuthorizedHours {
  authorizedNightHours: number | null
  authorizedExtraMorningHours: number | null
  authorizedExtraNightHours: number | null
  authorizedDiscountMorningHours: number | null
  authorizedDiscountNightHours: number | null
  authorizedHolidayMorningHours: number | null
  authorizedHolidayNightHours: number | null
}

export interface IWorkedHours {
  user_id: string
  scheduled_shift_id: string
  date: string
  details: {
    ordinaryHours: IHoursDetail[]
    extraHours: IHoursDetail[]
    discountHours: IHoursDetail[]
    holidayHours: IHoursDetail[]
    intervalHours: IHoursDetail[]
    movementsHours: IHoursDetail[]
  }
  morningHours: number
  nightHours: number
  intervalHours: number
  morningDiscountHours: number
  morningExtraHours: number
  morningHolidayHours: number
  nightDiscountHours: number
  nightExtraHours: number
  nightHolidayHours: number
  totalMorningHours: number
  totalNightHours: number
  status: WorkedDayStatus
  firstPunch: IPunch | null
  lastPunch: IPunch | null
  intervals: Array<{
    entrance: IPunch | null
    exit: IPunch | null
  }>
  savedTotalHours?: SavedHourInfos
  authorizedHours: AuthorizedHours
  timezone: string
}

export interface IWorkedHourEnvelope {
  info: IWorkedHours
  user: IWorkerUser
  shift: {
    shiftId: string
    // id: string
    name: string
    isRestDay: boolean
    shiftTurn: string
  }
  movements: IMovementV2[]
}

export type IPaginatedWorkedHours = IDefaultApiPagination<IWorkedHourEnvelope>

export interface FetchHoursProps {
  cellsIds?: string[]
  name?: string
  date: string
  page?: number
  perPage?: number
  autocomplete?: boolean
}

export interface FetchPunchListProps {
  cellsIds?: string[]
  name?: string
  startDate: string
  endDate: string
}

export interface FetchMonthlyHoursProps {
  user_id: string
  date: string
  autocomplete?: boolean
}

export interface FetchPunchInfosProps {
  date: string
  user_id: string
}
