import { ControlledFilterBar, Div, Table as TableComponent } from '@/components'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useAguinaldoListingColumns } from './column'
import { useAguinaldos } from '@/libs/react-query/hooks/useAguinaldos/useAguinaldos'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { IAguinaldo } from '@/libs/react-query/types/aguinaldo'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { useEffect } from 'react'

export const AguinaldoList = () => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payroll')),
  )

  const methods = useForm<{
    cellIds: string[]
  }>({
    defaultValues: {
      cellIds: availableCells?.map((cell) => cell.id) ?? [],
    },
  })

  useEffect(() => {
    methods.setValue('cellIds', availableCells?.map((v) => v.id) || [])
  }, [availableCells])

  const cellsIds = useWatch({
    control: methods.control,
    name: 'cellIds',
  })

  const { data: aguinaldos, isFetching: isFetchingAguinaldos } = useAguinaldos({
    cellsIds,
  })

  const columns = useAguinaldoListingColumns()

  const table = useReactTable({
    data: aguinaldos ?? [],
    columns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <FormProvider {...methods}>
      <ControlledFilterBar
        policiesIds={permissionsArray.filter((p) => p.includes('payroll'))}
        withAdvancedCells
        cellsIdsName="cellIds"
        isLoading={isFetchingAguinaldos}
      />
      <Div
        css={{
          padding: 16,
          maxHeight: 'calc(100vh - 100px)',
          overflow: 'scroll',
        }}
      >
        <TableComponent<IAguinaldo>
          numberOfRows={aguinaldos?.length ?? 0}
          table={table}
          isLoading={isFetchingAguinaldos}
          withPagination={false}
        />
      </Div>
    </FormProvider>
  )
}
