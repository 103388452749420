import { useEffect } from 'react'

interface UseWindowFocus {
  onFocus: () => void
  onBlur: () => void
}

export const useWindowFocus = ({ onFocus, onBlur }: UseWindowFocus) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('focus', onFocus)
      window.addEventListener('blur', onBlur)
    }

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [onBlur, onFocus])
}
