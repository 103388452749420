import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import { IListRequestParams, IPaginatedRequests } from '../../types/requests'

const fetchRequests = async (data: IListRequestParams) => {
  const response = await apiV2.post<IPaginatedRequests>(
    `/request/list-requests`,
    {
      page: data.page,
      name: data.name,
      perPage: data.perPage,
    },
    {
      params: {
        cellsIds: data.cellsIds.join(','),
      },
    },
  )

  return response.data
}

export const useRequests = (options: IListRequestParams) => {
  const { data: me } = useMe()

  const query = useQuery(
    [
      'requests',
      options.page,
      options.perPage,
      options.cellsIds,
      options.name,
      me?.company_id,
    ],
    () =>
      fetchRequests({
        ...options,
      }),
    {
      keepPreviousData: true,
      enabled: !!me && !!me?.company_id && !!options.cellsIds.length,
      retry: false,
    },
  )
  return query
}
