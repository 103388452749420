import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { usePaymentGroupsColumns } from './table'
import { IVacationConfigGroup } from '@/libs/react-query/types/company'
import { VacationsConfigDrawer } from './components/VacationsConfigDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { useCan } from '@/hooks'

interface VacationGroupDrawerState {
  isOpen: boolean
  selectedVacationConfig: IVacationConfigGroup | null
}

export const VacationsConfigs = () => {
  const canCreateVacation = useCan(['configurations.vacations'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] =
    React.useState<VacationGroupDrawerState>({
      isOpen: false,
      selectedVacationConfig: null,
    })

  const columns = usePaymentGroupsColumns()

  const handleCreateVacationConfig = () => {
    setValue('vacation', {
      name: '',
      concepts: Array.from({ length: company?.concepts.length ?? 0 }).map(
        () => true,
      ),
      include_base_salary: true,
      should_use_ips_date: true,
    })
    setDrawerState({
      isOpen: true,
      selectedVacationConfig: null,
    })
  }

  const handleSelectVacationConfig = (row: IVacationConfigGroup) => {
    const concepts: boolean[] = []

    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    orderedCompanyConcepts.forEach((conceptName, index) => {
      if (row.concepts.find((c) => c.name === conceptName)) {
        concepts[index] = true
      }
    })

    setValue('vacation', {
      name: row.name,
      concepts,
      include_base_salary: row.include_base_salary,
      should_use_ips_date: row.should_use_ips_date,
      id: row.id,
    })
    setDrawerState({
      isOpen: true,
      selectedVacationConfig: row,
    })
  }

  const table = useReactTable({
    data: company?.vacationConfigGroups ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del grupo" placeholder="Nombre del grupo" />
        {canCreateVacation && (
          <Button onClick={handleCreateVacationConfig}>
            Crear configuración de vacaciones
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<IVacationConfigGroup>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectVacationConfig}
        />
      </Div>

      <VacationsConfigDrawer
        isCreation={drawerState.selectedVacationConfig === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedVacationConfig: null,
          })
        }
      />
    </Div>
  )
}
