import { apiV2 } from '@/services/api'
import { useCustomMutation } from '../../useCustomMutation'
import { queryClient } from '../..'

const handleRespondRequests = async ({
  ids,
  isAccepted,
}: {
  ids: string[]
  isAccepted: boolean
}) => {
  await apiV2.post('/request/respond-requests', {
    ids,
    isAccepted,
  })
  queryClient.invalidateQueries('requests')
}

export const useHandleRespondRequest = () => {
  const mutation = useCustomMutation(
    ['handleRespondRequests'],
    handleRespondRequests,
  )

  return mutation
}
