import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useRequiredDataForm = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const datosObrigatoriosForm: AddWorkerFields = useMemo(() => {
    const formFields: AddWorkerFields = [
      {
        _type: 'input',
        placeholder: 'ejemplo@empresa.com.py',
        label: 'Correo electrónico',
        disabled: !isWorkerCreationAvailable,
        inputType: 'email',
        name: 'email',
      },
      {
        _type: 'input',
        label: 'CI',
        disabled: !isWorkerCreationAvailable,
        name: 'ci',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Nombre',
        inputType: 'name',
        name: 'name',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Apellido',
        inputType: 'name',
        name: 'surname',
      },
    ]

    return formFields
  }, [isWorkerCreationAvailable])

  return {
    data: datosObrigatoriosForm,
  }
}
