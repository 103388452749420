import { Div, Stepper } from '@/components'
import { StepProps } from '@/components/Stepper'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReportBuilderSchemaType } from '../type'
import { Tag } from '@punto-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export const ReportStepper = () => {
  const { control, setValue } = useFormContext<ReportBuilderSchemaType>()

  const [
    formStep,
    configurations,
    selectedRow,
    selectedRowDetails,
    selectedRowDetailsArray,
    rowsOptions,
  ] = useWatch({
    control,
    name: [
      'currentStep',
      'configurations',
      'selectedRow',
      'selectedRowDetails',
      'selectedRowDetailsArray',
      'rowsOptions',
    ],
  })

  const stepsArray = useMemo(() => {
    const setFormStep = (index: number) => setValue('currentStep', index)

    const isConfigurationsValid =
      configurations?.name && configurations?.reportType
    const isRowsValid = rowsOptions.find(
      (r) =>
        r.value === selectedRow &&
        (!r.details.length ||
          r.details?.some((r) => r.value === selectedRowDetails) ||
          selectedRowDetailsArray?.some((e) => e)),
    )

    return [
      {
        setFormStep,
        text: '1 Configuraciones',
        selectedIndex: formStep,
        index: 0,
        error: false,
      },
      // {
      //   setFormStep,
      //   text: '2 Lineas',
      //   selectedIndex: formStep,
      //   index: 1,
      //   disabled: !isConfigurationsValid,
      //   error: false,
      // },
      {
        setFormStep,
        text: '2 Columnas',
        selectedIndex: formStep,
        disabled: !isConfigurationsValid || !isRowsValid,
        index: 1,
        error: false,
      },
      {
        setFormStep,
        text: '3 Filtros',
        selectedIndex: formStep,
        disabled: !isConfigurationsValid || !isRowsValid,
        index: 2,
        error: false,
      },
    ] as StepProps[]
  }, [
    formStep,
    setValue,
    configurations,
    rowsOptions,
    selectedRowDetails,
    selectedRow,
    selectedRowDetailsArray,
  ])

  return (
    <Div
      css={{
        display: 'flex',
        paddingLeft: '$4',
        alignItems: 'center',
        height: 60,
        borderBottom: '1px solid $interface_light_down',
      }}
    >
      <Div
        css={{
          flex: 1,
        }}
      >
        <Stepper stepsArray={stepsArray} />
      </Div>
      <Div
        css={{
          marginRight: '$4',
        }}
      >
        <Tag variant={'warning'}>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',

              svg: {
                height: 12,
                width: 12,

                marginRight: '$2',
              },
            }}
          >
            <ExclamationTriangleIcon />
            Datos ficticios para vizualicación
          </Div>
        </Tag>
      </Div>
    </Div>
  )
}
