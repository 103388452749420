import { Div, SmartTable } from '@/components'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
  ReportBuilderSchemaType,
} from '../../type'
import { useFormContext, useWatch } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { SmartColumnType } from '@/components/SmartTable/types'
import { months } from '@/utils/date'
import { generateRandomName, generateRandomSectors } from '@/utils/random'
import dayjs from 'dayjs'
import { fromRowTypeToLabel } from '../../report-builder'
import { Tag } from '@punto-ui/react'

export const templateColumnPlaceholder: Record<
  string,
  Record<string, (s: number) => any>
> = {
  single: {
    day: (i: number) => {
      return dayjs().add(i, 'day').format('DD/MM/YYYY')
    },
    month: (i: number) => months[i].name,
    year: (i: number) => {
      return `${i + 2020}`
    },
  },
  collective: {
    workers: generateRandomName,
    sectors: generateRandomSectors,
    entities: () => 'Entidades',
  },
  totals: {
    day: (i: number) => {
      return dayjs().add(i, 'day').format('DD/MM/YYYY')
    },
    month: (i: number) => months[i].name,
    year: (i: number) => {
      return `${i + 2020}`
    },
  },
}

const templateColumnTitle: Record<string, Record<string, string>> = {
  single: {
    day: 'Fecha',
    month: 'Fecha',
    year: 'Fecha',
  },
  collective: {
    workers: 'Colaboradores',
    sectors: 'Sectores',
    entities: 'Entidades',
  },
  totals: {
    day: 'Fecha',
    month: 'Fecha',
    year: 'Fecha',
  },
}

export const ReportBuilderPreview = () => {
  const { control } = useFormContext<ReportBuilderSchemaType>()

  const [columns, selectedRow, reportType] = useWatch({
    control,
    name: ['columns', 'selectedRow', 'configurations.reportType'],
  })

  const [currentTableData, setCurrentTableData] = useState<any>([])

  useEffect(() => {
    const returning: any = []

    const mainRandomizer =
      templateColumnPlaceholder[reportType as IReportBuilderTypes]?.[
        selectedRow as IReportBuilderRowTypes
      ]

    const cachedResults = {} as any

    Array.from({ length: 12 }).forEach((x, index) => {
      const result = {} as any

      Object.entries(columns).forEach(([key, columns], index) => {
        return columns
          .filter((c) => c.selected)
          .forEach((detail) => {
            const detailKey = `${detail.value}_${detail.column_type}`
            const newRandomValue = detail.getRandomPlaceholder
              ? detail.getRandomPlaceholder()
              : ''
            const cachedValue = cachedResults[detailKey] as any
            if (
              (key === 'workers' || key === 'permissions') &&
              reportType === 'single' &&
              cachedValue
            ) {
              result[`${detail.value}_${detail.column_type}`] = cachedValue
              return
            }

            result[`${detail.value}_${detail.column_type}`] = newRandomValue
            cachedResults[detailKey] = newRandomValue
          })
      })

      if (mainRandomizer) {
        result.name = mainRandomizer(index)
      }

      returning.push(result)
    })
    setCurrentTableData(returning)
  }, [columns, selectedRow, reportType])

  const columnsDefinition = useMemo(() => {
    const data: SmartColumnType[] = Object.entries(columns)
      .flatMap(([key, columns], index) => {
        const piece: SmartColumnType[] = columns
          .filter((c) => c.selected)
          .map((detail, detailIndex) => ({
            type: 'input-text',
            disabled: true,
            draggable: null,
            header: `${detail.label} - ${
              fromRowTypeToLabel[key as IReportBuilderRowTypes]
            }`,
            name: `${detail.value}_${detail.column_type}`,
            // name: 'teste',
            width: 300,
          }))

        return piece
      })
      .slice(0, 30)

    if (selectedRow !== 'entities' && selectedRow !== 'workers') {
      data.unshift({
        type: 'input-text',
        disabled: true,
        draggable: null,
        header:
          templateColumnTitle[reportType as IReportBuilderTypes]?.[
            selectedRow as IReportBuilderRowTypes
          ],
        name: 'name',
        width: 200,
      })
    }

    return data
  }, [columns, selectedRow, reportType])

  return (
    <Div
      css={{
        borderBottomRightRadius: '$lg',
        // flex: 1,
        width: '100%',
        height: 550,
        padding: '$4',
        overflowY: 'auto',
        background: '$interface_light_up',
      }}
    >
      {!!selectedRow && (
        <SmartTable
          columns={columnsDefinition}
          data={currentTableData}
          uncontrolled
          style={{
            borderInLines: true,
            centered: true,
          }}
          noPadding
        />
      )}
      <Div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {columnsDefinition.length >= 30 && (
          <Tag>Previsualización tiene un limite de 30 columnas</Tag>
        )}
      </Div>
    </Div>
  )
}
