import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { PdfBuilderSchema, PdfBuilderSchemaType } from './types'
import { useHandlePdfBuilderActions } from './actions'
import { Div, ExportPDFDrawer } from '@/components'
import { PdfStepper } from './components/PdfStepper'
import { PdfBuilderForm } from './components/PdfBuilderForm'
import { PdfBuilderPreview } from './components/PdfBuilderPreview'
import { usePdfTemplate } from '@/libs/react-query/hooks/usePdfTemplate'
import { buildPdfDataSchema } from './parser'

export const PdfBuilder = (props: { id?: string }) => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')

  const methods = useForm<PdfBuilderSchemaType>({
    resolver: zodResolver(PdfBuilderSchema),
    defaultValues: {
      configurations: {
        name: '',
        description: '',
        direction: 'portrait',
        size: 'a4',
        favorite: false,
      },
      pdf: {
        content: [],
      },
      manualVariables: [],
      currentStep: 0,
      id: props.id || '',
      loadedTemplate: '',
    },
  })

  useHandlePdfBuilderActions({
    methods,
    isLoading: false,
    setSelectedTemplateId,
  })

  const { data: template } = usePdfTemplate(props.id || '')

  useEffect(() => {
    if (!template) return

    const loadedTemplate = methods.getValues('loadedTemplate')

    if (loadedTemplate === template.updated_at) return
    const data = buildPdfDataSchema(template)

    methods.reset({
      ...data,
      loadedTemplate: template.updated_at,
    })
  }, [template])

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          margin: 20,
          background: '$interface_light_pure',
          borderRadius: '$lg',
          border: '1px solid $interface_light_down',
        }}
      >
        <PdfStepper />
        <Div
          css={{
            display: 'flex',

            height: 'calc(100vh - 54px - 20px - 60px - 24px)',
          }}
        >
          <PdfBuilderForm />
          <PdfBuilderPreview />
        </Div>
      </Div>
      <ExportPDFDrawer
        pdfId={selectedTemplateId}
        isOpen={!!selectedTemplateId}
        setIsOpen={() => setSelectedTemplateId('')}
      />
    </FormProvider>
  )
}
