import { useEffect } from 'react'
import { IRouteAction } from '@/pages/router'
import {
  BookmarkIcon,
  DocumentArrowDownIcon,
  DocumentTextIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { useUpdateUserLiquidation } from '@/libs/react-query/mutations/company/useUpdateUserLiquidation'
import { InactivateWorkerSchema } from './form'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  buildLiquidationReceiptParams,
  compareAndConstructUserLiquidation,
} from './parser'
import { useHandleRequestReport } from '@/libs/react-query/mutations/reports/useHandleRequestReport'
import { useExportReceiptModal } from '@/components/ExportReceipt'
import { useTotalLiquidationValues } from './hooks/useTotalLiquidationValues'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useCan } from '@/hooks'

export const useHandleWorkerLiquidationActions = ({
  isLoading,
  handleLiquidate,
}: {
  isLoading?: boolean
  handleLiquidate(): void
}) => {
  const canLiquidate = useCan(['workers.all', 'workers.liquidate'])
  const canEditLiquidation = useCan(['workers.all', 'workers.editLiquidation'])

  const { data: isLiquidationAvailable, detail: liquidationDetail } =
    useIsFeatureInPlan('liquidation')

  const { setActions } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('worker', 'list'),
    setActions: state.setActionsTab,
  }))

  const methods = useFormContext<InactivateWorkerSchema>()
  const { handleOpenExportReceiptModal } = useExportReceiptModal()

  const [status, enabledChanges, inactivationDate] = useWatch({
    control: methods.control,
    name: ['data.status', 'enabled_changes', 'data.inactivation_date'],
  })

  const {
    mutateAsync: handleUpdateLiquidation,
    isLoading: isLoadingUpdateLiquidation,
  } = useUpdateUserLiquidation()

  const {
    mutateAsync: handleRequestReport,
    isLoading: isLoadingRequestReport,
  } = useHandleRequestReport()

  const { totalFinalValue } = useTotalLiquidationValues()

  useEffect(() => {
    const actions: IRouteAction[] = []

    const isLoadingOrDisabled =
      isLoading || isLoadingUpdateLiquidation || isLoadingRequestReport

    const isDisabled = !isLiquidationAvailable

    if ((status !== 'done' && status !== '') || enabledChanges) {
      actions.push({
        label: 'Guardar y Recalcular',
        disableMessage:
          liquidationDetail ||
          'Puedes presionar enter para guardar y recalcular la liquidación.',
        action: async () => {
          const values = methods.getValues()

          const dto = compareAndConstructUserLiquidation(
            values.original,
            values.data,
          )

          await handleUpdateLiquidation({
            ...dto,
            liquidation_id: values.liquidationId,
          })
        },
        disabled: !canEditLiquidation || isLoadingOrDisabled || isDisabled,
        isLoading: isLoadingOrDisabled,
        variant: 'secondary',
        width: 200,
        icon: <BookmarkIcon />,
      })
    }

    if (status !== 'done' && status !== '') {
      actions.push({
        label: 'Liquidar',
        action: handleLiquidate,
        disabled:
          !canLiquidate ||
          isLoadingOrDisabled ||
          !inactivationDate[0] ||
          isDisabled,
        isLoading: false,
        variant: 'primary',
        disableMessage:
          liquidationDetail ||
          (!inactivationDate[0]
            ? 'Debes seleccionar una fecha de desvinculación, guardar y recalcular.'
            : ''),
        icon: <DocumentArrowDownIcon />,
      })
    }

    if (isLoading) {
      actions.unshift({
        label: 'Cargando',
        action: () => null,
        disabled: false,
        isLoading: true,
        variant: 'tertiary',
        icon: null,
      })
    } else {
      actions.unshift({
        label: 'Exportar',
        action: () => {
          const values = methods.getValues()

          const exportOptions = buildLiquidationReceiptParams(values)
          handleRequestReport({
            usersIds: [values.original.user_id],
            type: 'LIQUIDATION_REPORT',
            data: exportOptions,
          })
        },
        disabled: isLoadingOrDisabled || isDisabled,
        disableMessage: liquidationDetail,
        isLoading: isLoadingOrDisabled,
        variant: 'secondary',
        icon: <DocumentArrowDownIcon />,
      })
      actions.unshift({
        label: 'Exportar Recibo',
        action: () => {
          const values = methods.getValues()

          handleOpenExportReceiptModal({
            type: 'liquidation',
            usersIds: [values.data.user_id],
            date: values.data.inactivation_date[0]
              ? values.data.inactivation_date[0].toISOString()
              : '',
            value: totalFinalValue.total,
            liquidationId: values.liquidationId,

            title: 'Liquidación final de haberes',
            description: values.data.complement,
          })
        },
        disabled: isLoadingOrDisabled || isDisabled,
        disableMessage: liquidationDetail,
        isLoading: isLoadingOrDisabled,
        variant: 'secondary',
        width: 150,
        icon: <DocumentTextIcon />,
      })
    }

    if (status === 'done' && !isLoading) {
      actions.unshift({
        label: enabledChanges ? 'Desabilitar cambios' : 'Habilitar cambios',
        disableMessage: !enabledChanges
          ? 'Puedes habilitar cambios temporariamente para colaboradores ya liquidados.'
          : '',
        action: async () => {
          methods.setValue('enabled_changes', !enabledChanges)
        },
        disabled: isLoadingOrDisabled,
        isLoading: isLoadingOrDisabled,
        variant: enabledChanges ? 'secondaryCritical' : 'secondary',
        width: enabledChanges ? 180 : 150,
        icon: enabledChanges ? <LockOpenIcon /> : <LockClosedIcon />,
      })
    }

    setActions('worker', actions)
  }, [
    canLiquidate,
    isLoading,
    isLoadingUpdateLiquidation,
    status,
    handleUpdateLiquidation,
    isLoadingRequestReport,
    enabledChanges,
    totalFinalValue.total,
    inactivationDate,
  ])
}
