import { GrabIcon } from '@/assets/icons/GrabIcon'
import { Div } from '@/components'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ReactNode, useEffect, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceiptBuilderSchemaType } from '../type'
import { Text } from '@punto-ui/react'

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
interface IDraggableExpandableSelector {
  label: string
  isOpenName: string
  icon: ReactNode
  options: Array<{
    label: string
    value: string
    isDebit: boolean
  }>
}

export const DraggableExpandableSelector = (
  props: IDraggableExpandableSelector,
) => {
  const { control, setValue } = useFormContext<ReceiptBuilderSchemaType>()

  const [isOpen] =
    useWatch({
      control,
      name: [props.isOpenName as any],
    }) || false

  const data = useWatch({
    control,
    name: 'data',
  })

  const selectedConcepts: Record<string, boolean> = {}
  let totalSelectedInConcept = 0
  data?.forEach((row) => {
    row.includedConceptsInRow.forEach((concept) => {
      selectedConcepts[concept] = true

      if (props.options.some((o) => o.value === concept)) {
        totalSelectedInConcept++
      }
    })
  })

  return (
    <Div>
      <Div
        css={{
          padding: '$2',
          borderBottom: '1px solid $interface_light_down',

          cursor: 'pointer',
        }}
        onClick={() => {
          setValue(props.isOpenName as any, !isOpen)
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Div
            css={{
              svg: {
                marginRight: '$2',
                color: '$interface_dark_down',
                height: 20,
                width: 20,
              },
            }}
          >
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Div>
          <Text
            variant={'description'}
            css={{
              color: '$interface_dark_down',
            }}
          >
            {props.label}
          </Text>
          <Div
            css={{
              marginLeft: 'auto',
            }}
          >
            {!totalSelectedInConcept ? (
              <Div
                css={{
                  borderRadius: '100%',
                  height: 16,
                  width: 16,
                  border: '1px solid $interface_dark_down',

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  css={{
                    color: '$interface_dark_down',
                    fontWeight: 'bold',
                  }}
                  variant="caption"
                >
                  {totalSelectedInConcept}
                </Text>
              </Div>
            ) : (
              <Div
                css={{
                  borderRadius: '100%',
                  height: 16,
                  width: 16,
                  background: '$brand_primary_pure',

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  css={{
                    color: '$interface_light_pure',
                    fontWeight: 'bold',
                  }}
                  variant="caption"
                >
                  {totalSelectedInConcept}
                </Text>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
      {isOpen && (
        <Div>
          {props.options.map((option) => {
            return (
              <DraggableSelectorOption
                option={option}
                selectedConcepts={selectedConcepts}
                key={option.value}
              />
            )
          })}
        </Div>
      )}
    </Div>
  )
}

const DraggableSelectorOption = ({
  selectedConcepts,
  option,
}: {
  selectedConcepts: Record<string, boolean>
  option: { label: string; value: string }
}) => {
  const draggableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!draggableRef.current) return

    return draggable({
      element: draggableRef.current,
      getInitialData: () => {
        return {
          option,
          type: 'smart-table-tag',
        }
      },
    })
  }, [])

  return (
    <Div
      key={option.label}
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Div
        css={{
          display: 'flex',
          padding: '$2',
          alignItems: 'center',
          cursor: 'grab',
        }}
        ref={draggableRef}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',

            width: 12,
            height: 20,

            marginRight: '$2',

            svg: {
              height: 25,
              // width: 6,
              strokeWidth: 2,
              color: '$interface_dark_down',
            },
          }}
        >
          <GrabIcon />
        </Div>
        <Text
          variant={'caption'}
          css={{
            color: '$interface_dark_down',
          }}
        >
          {option.label}
        </Text>
      </Div>
      <Div
        css={{
          padding: '$2',
          marginLeft: 'auto',
        }}
      >
        {selectedConcepts[option.value] && (
          <Div
            css={{
              borderRadius: '100%',
              height: 16,
              width: 16,
              background: '$brand_primary_pure',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              svg: {
                color: '$interface_light_pure',
                height: 14,
                width: 14,
                strokeWidth: 2,
              },
            }}
          >
            <CheckIcon />
          </Div>
        )}
        {!selectedConcepts[option.value] && (
          <Div
            css={{
              borderRadius: '100%',
              height: 16,
              width: 16,
              border: '1px solid $interface_dark_down',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              svg: {
                color: '$interface_dark_down',
                height: 12,
                width: 12,
                strokeWidth: 2,
              },
            }}
          >
            <XMarkIcon />
          </Div>
        )}
      </Div>
    </Div>
  )
}
