import { IRouteAction, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { BarsArrowDownIcon } from '@heroicons/react/24/outline'
import { IAuditStateSchema, INITIAL_AUDIT_STATE } from './audit-state'
import { FormProvider, useForm } from 'react-hook-form'
import { AuditList } from './components/AuditList'

export default function PagosContent({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const methods = useForm<IAuditStateSchema>({
    defaultValues: INITIAL_AUDIT_STATE,
  })

  const pageActions: IRouteAction[] = []

  return (
    <FormProvider {...methods}>
      <Router
        onlyTabs={onlyTabs}
        defaultTab="list"
        actions={pageActions}
        tabs={[
          {
            icon: <BarsArrowDownIcon />,
            label: 'Auditoría',
            value: 'list',
            component: <AuditList />,
          },
        ]}
      />
    </FormProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
