import { FormRenderer } from '@/components'
import { FormContainer } from '..'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { InactiveWorkerField } from '../form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'

interface ILastSalariesLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void

  isDiscount?: boolean

  id: string
  name: string
  value: number | string
  amount: number | string
  index: number
}

export const LiquidationOtherConcept = ({
  isOpen,
  toggleOpen,

  isDiscount,

  name,
  value,
  amount,
  id: conceptKey,
  index,
}: ILastSalariesLiquidationParams) => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const form: InactiveWorkerField[] = [
    {
      _type: 'input',
      inputType: 'only-numbers',
      disabled: isLoadingLiquidation,
      name: `data.${
        isDiscount ? 'discountConcepts' : 'paymentConcepts'
      }.${index}.amount`,
      label: 'Cantidad',
    },
    {
      _type: 'input',
      inputType: 'money-guarani',
      disabled: isLoadingLiquidation,
      name: `data.${
        isDiscount ? 'discountConcepts' : 'paymentConcepts'
      }.${index}.value`,
      label: 'Valor',
    },
  ]

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || name}
        totals={[
          `Cantidad ${Number.isNaN(amount) ? 0 : amount}`,
          `Valor ${formatCurrency(Number.isNaN(value) ? 0 : +value)}`,
        ]}
        conceptType="payment"
        isIpsDisabled={!concept.ips}
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && <FormRenderer inline items={form} cols={4} />}
    </FormContainer>
  )
}
