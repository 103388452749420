import React, { useEffect, useRef, useState } from 'react'
import {
  FieldValues,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { ControlledFullWorkerSearchBarP } from './types'
import { useAvailableCells, useWorkers } from '@/libs/react-query/hooks'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  Container,
  ListContainer,
  ListItemContainer,
  SearchArea,
  CompleteSearchInputBar,
  SearchInput,
  FiltersContainer,
} from './styles'
import { Button, Progress, Tag, Text } from '@punto-ui/react'
import { useDebounce } from '@/hooks'
import { Avatar, Div } from '@/components'
import { useVirtualizer } from '@tanstack/react-virtual'
import { OrganogramFilter } from '@/components/Filters/OrganogramFilter'

export const ControlledFullWorkerSearchBar = <TFieldValues extends FieldValues>(
  props: ControlledFullWorkerSearchBarP<TFieldValues>,
) => {
  const alreadySetCellsIds = useRef<boolean>(false)
  const [cellsIds, setCellsIds] = useState<string[]>([])
  const { data: cells } = useAvailableCells(props.policiesIds || [])

  const { control, register, getValues } = useFormContext()

  const [filterValue, setFilterValue] = useState('')
  const debouncedValue = useDebounce<string>(filterValue, 500)

  const {
    field: { onChange, value },
  } = useController({
    name: props.name,
    control,
    defaultValue: getValues(props.name),
  })

  const updatedValue = useWatch({
    control,
    name: props.name,
  })

  const { data: workers, isFetching: isFetchingWorker } = useWorkers({
    page: 0,
    pageSize: 10000,
    name: debouncedValue,
    cellsIds,
    policiesIds: props.policiesIds,
    usersIds: props.usersIds,
    usersIdsFilter: props.usersIdsFilter,
  })

  useEffect(() => {
    if (alreadySetCellsIds.current) return

    if (cells?.length) {
      setCellsIds(cells.map((cell) => cell.id))
      alreadySetCellsIds.current = true
    }
  }, [cells])

  const handleWorkerClick = (workerId: string) => {
    if (props.disabled) return

    if (value?.includes(workerId)) {
      const newArray = value.filter((id: string) => id !== workerId)

      onChange(newArray)
      props.onValueChange?.(filterValue, newArray)
    } else {
      const newArray = [workerId]

      if (value) {
        newArray.push(...value)
      }

      onChange(newArray)
      props.onValueChange?.(filterValue, newArray)
    }
  }

  const isAllSelected = workers?.data?.every((w) => value?.includes(w.id))

  const handleSelectAll = () => {
    if (props.disabled) return

    if (isAllSelected) {
      onChange([])
      props.onValueChange?.(filterValue, [])
    } else {
      const newArray = workers?.data?.map((w) => w.id) ?? []

      onChange(newArray)
      props.onValueChange?.(filterValue, newArray)
    }
  }

  const handleUnselectAll = () => {
    if (props.disabled) return

    onChange([])
    props.onValueChange?.(filterValue, [])
  }

  const handleUnselectWorker = (workerId: string) => {
    if (props.disabled) return

    const newArray = value.filter((id: string) => id !== workerId)

    onChange(newArray)
    props.onValueChange?.(filterValue, newArray)
  }

  const selectedWorkers = workers?.data?.filter((w) => value?.includes(w.id))

  const reference = useRef<HTMLDivElement>(null)
  register(props.name)

  const rowVirtualizer = useVirtualizer({
    count: workers?.data?.length || 0,
    getScrollElement: () => reference.current,
    estimateSize: React.useCallback(() => 36, []),
    overscan: 15,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  })

  return (
    <Container css={{ ...props.css }}>
      <Div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Div>
          {props.withoutLabel ? null : (
            <Text
              variant="caption"
              as="label"
              css={{ color: '$interface_dark_deep' }}
            >
              {props.label || 'Seleccione los colaboradores'}
            </Text>
          )}
          <SearchArea css={{ marginTop: props.withoutLabel ? 0 : undefined }}>
            <CompleteSearchInputBar>
              <MagnifyingGlassIcon />
              <SearchInput
                placeholder="Buscar..."
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
              {isFetchingWorker ? (
                <Div css={{ width: 20 }}>
                  <Progress size={'sm'} />
                </Div>
              ) : (
                <Div css={{ width: 20 }} />
              )}
              <FiltersContainer>
                <Button
                  css={{
                    // whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minWidth: 120,
                  }}
                  type="button"
                  variant={'tertiary'}
                  onClick={handleSelectAll}
                >
                  Seleccionar todos
                </Button>
                <Div
                  css={{
                    minWidth: 120,
                  }}
                >
                  <OrganogramFilter
                    selectedIds={cellsIds}
                    defaultValues={true}
                    policiesIds={props.policiesIds}
                    callback={(options) => {
                      const cellsIds = options.map((cell) => cell.id)

                      setCellsIds(cellsIds)
                    }}
                  />
                </Div>
              </FiltersContainer>
            </CompleteSearchInputBar>
          </SearchArea>
        </Div>
        <ListContainer
          ref={reference}
          css={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            overflow: 'scroll',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
            const worker = workers?.data?.[virtualRow.index]
            const isSelected = updatedValue?.includes(worker?.id) || false

            return (
              <ListItemContainer
                css={{
                  display: 'flex',

                  paddingLeft: '$4',

                  borderRadius: '$sm',

                  position: 'absolute',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualRow.start}px)`,
                  height: `${virtualRow.size}px`,

                  backgroundColor: isSelected ? '#EAF4FF' : 'white',
                  border: isSelected ? '1px solid #C4D9FF' : 'none',
                }}
                key={worker?.id}
                onClick={() => handleWorkerClick(worker?.id || '')}
              >
                <Avatar
                  alt="avatar"
                  src={worker?.photo_url || ''}
                  height={24}
                  width={24}
                />
                <Text
                  variant={'description'}
                  css={{
                    marginLeft: 8,
                    color: isSelected
                      ? '$brand_primary_pure'
                      : '$interface_dark_down',
                  }}
                >
                  {' '}
                  {worker?.name ?? ''}{' '}
                </Text>
              </ListItemContainer>
            )
          })}
        </ListContainer>
      </Div>
      <Div
        css={{
          maxWidth: '30%',
          minWidth: '30%',
          paddingLeft: 16,
          overflow: 'hidden',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '$1',
            svg: {
              cursor: 'pointer',
              height: 16,
              width: 16,
              marginRight: 16,
              minWidth: 16,
              minHeight: 16,
              color: '$status_danger_down',
            },
          }}
        >
          <Text
            variant="caption"
            as="label"
            css={{ color: '$interface_dark_deep', marginBottom: '$1' }}
          >
            Seleccionados
          </Text>
          <XMarkIcon onClick={handleUnselectAll} />
        </Div>
        <Div
          css={{
            maxHeight:
              props.css?.height ||
              props.css?.maxHeight ||
              `${rowVirtualizer.getTotalSize()}px`,
            overflow: 'scroll',
            paddingBottom: 12,
          }}
        >
          {selectedWorkers?.map((user) => (
            <Div
              key={user.id}
              css={{
                marginRight: '$2',
                marginBottom: '$2',
              }}
            >
              <Tag variant={'neutral'}>
                <Div
                  css={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    gap: '$2',
                    svg: {
                      color: '$brand_primary_pure',
                    },
                  }}
                >
                  <Div css={{ minWidth: 16 }}>
                    <Avatar
                      alt="avatar"
                      src={user?.photo_url || ''}
                      height={20}
                      width={20}
                    />
                  </Div>
                  <Text
                    css={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: '$brand_primary_pure',
                      flex: 1,
                    }}
                    variant={'caption'}
                  >
                    {user.name}
                  </Text>
                  <Div css={{ minWidth: 12 }}>
                    <XMarkIcon
                      onClick={() => handleUnselectWorker(user.id)}
                      style={{ cursor: 'pointer', strokeWidth: 3 }}
                      height={12}
                      width={12}
                    />
                  </Div>
                </Div>
              </Tag>
            </Div>
          ))}
        </Div>
      </Div>
    </Container>
  )
}
