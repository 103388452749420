export const XlsxIcon = (props: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="2" fill="#3B840E" />
      <path
        d="M5.34 13L4.484 10.928H4.428L3.588 13H2.612L3.86 10.232L2.628 7.496H3.628L4.46 9.504H4.516L5.356 7.496H6.316L5.108 10.208L6.34 13H5.34ZM6.92513 13V7.496H7.83713V12.152H9.35713V13H6.92513ZM11.2446 13.064C11.0259 13.064 10.7859 13.0533 10.5246 13.032C10.2632 13.0107 10.0366 12.9813 9.84456 12.944V12.168C10.2979 12.216 10.6792 12.24 10.9886 12.24C11.3619 12.24 11.5992 12.1813 11.7006 12.064C11.7539 12.0107 11.7886 11.9387 11.8046 11.848C11.8206 11.752 11.8286 11.624 11.8286 11.464C11.8286 11.24 11.8152 11.08 11.7886 10.984C11.7619 10.888 11.7086 10.8187 11.6286 10.776C11.5539 10.728 11.4206 10.688 11.2286 10.656L10.4366 10.52C10.1699 10.4773 9.9859 10.336 9.88456 10.096C9.78856 9.856 9.74056 9.50933 9.74056 9.056C9.74056 8.62933 9.8019 8.29867 9.92456 8.064C10.0472 7.824 10.2206 7.65867 10.4446 7.568C10.6686 7.47733 10.9539 7.432 11.3006 7.432C11.5246 7.432 11.7512 7.448 11.9806 7.48C12.2152 7.50667 12.4019 7.53867 12.5406 7.576V8.336C12.2632 8.28267 11.9379 8.256 11.5646 8.256C11.3512 8.256 11.1859 8.26667 11.0686 8.288C10.9512 8.30933 10.8632 8.34667 10.8046 8.4C10.7406 8.45333 10.6979 8.52533 10.6766 8.616C10.6606 8.70667 10.6526 8.84267 10.6526 9.024C10.6526 9.216 10.6632 9.35467 10.6846 9.44C10.7059 9.52 10.7432 9.57867 10.7966 9.616C10.8552 9.65333 10.9512 9.68267 11.0846 9.704L11.8286 9.832C12.0366 9.864 12.2046 9.92533 12.3326 10.016C12.4659 10.1067 12.5672 10.2587 12.6366 10.472C12.7059 10.6907 12.7406 10.9813 12.7406 11.344C12.7406 11.8187 12.6846 12.1813 12.5726 12.432C12.4606 12.6773 12.2979 12.8453 12.0846 12.936C11.8766 13.0213 11.5966 13.064 11.2446 13.064ZM15.8556 13L14.9996 10.928H14.9436L14.1036 13H13.1276L14.3756 10.232L13.1436 7.496H14.1436L14.9756 9.504H15.0316L15.8716 7.496H16.8316L15.6236 10.208L16.8556 13H15.8556Z"
        fill="white"
      />
    </svg>
  )
}
