import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdateConnectionPayload } from '../types'
import { queryClient } from '@/libs/react-query'

export const useHandleUpdateConnection = () => {
  const handleUpdateConnection = async ({
    cellId,
    newParentId,
    oldParentId,
  }: UpdateConnectionPayload) => {
    const apiPayload = {
      cellId,
      newParentId,
      oldParentId,
    }

    await apiV2.patch<void>('/permission/update-cell-connection', apiPayload)
    queryClient.invalidateQueries()
  }

  const mutation = useMutation(
    ['useHandleUpdateConnection'],
    handleUpdateConnection,
  )

  return mutation
}
