import { movementOptions } from '@/libs/react-query/types'
import { IReportBuilderColumnType } from '../../type'
import { generateRandomNumberUnder100 } from '@/utils/random'

export const multipleMovementColumns: IReportBuilderColumnType[] = [
  ...movementOptions.map((m) => ({
    type: 'movements' as const,
    subgroup: 'Total de movimientos',
    value: `${m.value}_count`,
    label: m.label,
    getRandomPlaceholder: generateRandomNumberUnder100,
    valueType: 'number' as const,
  })),
  ...movementOptions.map((m) => ({
    type: 'movements' as const,
    subgroup: 'Total de Dias',
    value: `${m.value}_days`,
    label: m.label,
    valueType: 'number' as const,
    getRandomPlaceholder: generateRandomNumberUnder100,
  })),
]
