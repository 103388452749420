import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useInputDatePicker } from '..'
import { CalendarContainer, DayCell, DayCellFill } from './styles'
import { Text, styled } from '@punto-ui/react'

interface ICell {
  id: string
  value: string
}

interface IRow {
  id: string
  cells: ICell[]
}

const THead = styled('thead', {})

export const Calendar = () => {
  const [isSelecting, setIsSelecting] = useState<'first' | 'second'>('first')
  const {
    disabled,
    calendarDate,
    firstDate,
    secondDate,
    onChange: handleChangeDates,
    type,
    multipleDates,
    maxDates,
    isDisabled,
  } = useInputDatePicker()

  const rows = useMemo(() => {
    let startOfMonth = calendarDate.startOf('month')
    const blankSpaces = startOfMonth.day()
    const daysInMonth = calendarDate.daysInMonth()

    const rows: IRow[] = []
    let cells: ICell[] = []

    for (let i = 1; i <= blankSpaces; i++) {
      cells.push({
        id: i.toString(),
        value: '',
      })
    }

    for (let i = 1; i <= daysInMonth; i++) {
      cells.push({
        id: startOfMonth.toString(),
        value: i.toString(),
      })

      if (cells.length === 7) {
        rows.push({
          id: startOfMonth.toString(),
          cells,
        })

        cells = []
      }

      startOfMonth = startOfMonth.add(1, 'day')
    }

    if (cells.length > 0) {
      rows.push({
        id: startOfMonth.toString(),
        cells,
      })
    }

    return rows
  }, [calendarDate])

  const daysLabels = ['D', 'L', 'M', 'M', 'J', 'V', 'S']

  const isDateSelected = (date: dayjs.Dayjs) => {
    if (type === 'multiple') {
      return multipleDates.some((d) => date.isSame(dayjs(d), 'date'))
    }

    if (type === 'single') {
      return !!(firstDate && date.isSame(dayjs(firstDate), 'date'))
    }

    return (
      !!(firstDate && dayjs(date).isSame(dayjs(firstDate), 'date')) ||
      !!(secondDate && dayjs(date).isSame(dayjs(secondDate), 'date'))
    )
  }

  const isDateDisabled = (date: dayjs.Dayjs) => {
    const disabled = isDisabled ? isDisabled(date.toDate()) : false

    if (disabled) {
      return true
    }

    const hasBothDates = firstDate && secondDate

    if (hasBothDates) {
      return false
    }

    if (!maxDates) {
      return false
    }

    // is exceed maxDates, is disabled
    if (type === 'single') {
      return false
    } else if (
      type === 'multiple' &&
      maxDates &&
      multipleDates.length >= maxDates
    ) {
      return true
    } else if (type === 'range' && maxDates) {
      const maximumAllowedDate = dayjs(firstDate).add(maxDates, 'days')
      const minimumAllowedDate = dayjs(firstDate).add(-1 * maxDates, 'days')
      return (
        maximumAllowedDate.isBefore(date, 'date') ||
        minimumAllowedDate.isAfter(date, 'date')
      )
    }
  }
  const isBetweenDates = (date: dayjs.Dayjs) => {
    return !!(
      firstDate &&
      date.isAfter(dayjs(firstDate), 'date') &&
      secondDate &&
      date.isBefore(dayjs(secondDate), 'date')
    )
  }

  const handleSelectDate = (date: dayjs.Dayjs) => {
    if (type === 'multiple') {
      handleChangeDates([date.toDate()])
      return
    }

    if (type === 'single') {
      handleChangeDates([date.toDate()])
    } else if (isSelecting === 'first' && type === 'range') {
      setIsSelecting('second')

      handleChangeDates([date.toDate()])
    } else if (isSelecting === 'second' && type === 'range') {
      setIsSelecting('first')

      if (date.isBefore(dayjs(firstDate), 'date')) {
        handleChangeDates([date.toDate(), firstDate])
      } else {
        handleChangeDates([firstDate, date.toDate()])
      }
    }
  }

  return (
    <CalendarContainer>
      <table>
        <THead
          css={{
            borderBottom: '1px solid',
            borderBottomColor: '$interface_dark_deep',
          }}
        >
          <tr>
            {daysLabels.map((day, index) => (
              <DayCell key={index} disabled isHeader>
                <DayCellFill>
                  <Text size="sm" css={{ color: '$interface_dark_down' }}>
                    {day}
                  </Text>
                </DayCellFill>
              </DayCell>
            ))}
          </tr>
        </THead>
        <tbody>
          {rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.cells.map((cell) => {
                  return (
                    <DayCell
                      key={cell.id}
                      isToday={
                        dayjs(cell.id).isSame(dayjs(), 'day') &&
                        !dayjs(cell.id).isSame(firstDate, 'day') &&
                        !dayjs(cell.id).isSame(secondDate, 'day')
                      }
                      selected={isDateSelected(dayjs(cell.id))}
                      isRange={type === 'range'}
                      isStartDate={
                        type === 'range' &&
                        !!(
                          firstDate &&
                          dayjs(cell.id).isSame(dayjs(firstDate), 'date')
                        )
                      }
                      isEndDate={
                        type === 'range' &&
                        !!(
                          secondDate &&
                          dayjs(cell.id).isSame(dayjs(secondDate), 'date')
                        )
                      }
                      between={
                        type !== 'multiple' && isBetweenDates(dayjs(cell.id))
                      }
                      disabled={
                        disabled ||
                        cell.value === '' ||
                        isDateDisabled(dayjs(cell.id))
                      }
                    >
                      <button
                        type="button"
                        disabled={
                          disabled ||
                          cell.value === '' ||
                          isDateDisabled(dayjs(cell.id))
                        }
                        onClick={
                          cell.value === ''
                            ? () => null
                            : () => handleSelectDate(dayjs(cell.id))
                        }
                      >
                        <DayCellFill>
                          <Text
                            variant="description"
                            css={{
                              color:
                                cell.value !== '' &&
                                isDateDisabled(dayjs(cell.id))
                                  ? '$interface_light_down'
                                  : '$interface_dark_down',
                            }}
                          >
                            {cell.value}
                          </Text>
                        </DayCellFill>
                      </button>
                    </DayCell>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </CalendarContainer>
  )
}
