import { Row, flexRender, Table as ReactTable } from '@tanstack/react-table'
import { GroupData } from '../../../../types'
import React from 'react'
import { Text, styled } from '@punto-ui/react'
import { TableData } from '../../styles'
import { EditingCell } from '../EditingCell'
import { CSS } from '@stitches/react'

export const TableRow: React.FC<{
  row: Row<GroupData>
  table: ReactTable<GroupData>
  css: CSS
}> = ({ row, table, css }) => {
  return (
    <SimpleTableRow
      css={{
        // background: '$interface_light_pure',
        // borderRadius: 8,
        borderWidth: 0,
        borderBottom: '1px solid',
        borderBottomColor: '$interface_dark_up',
        // backgroundColor: '$interface_light_pure',
        // backgroundColor: 'red',

        display: 'flex',

        '&:hover': {
          '> td': {
            backgroundColor: '$interface_light_down',
          },
        },

        ...css,
      }}
    >
      {row.getVisibleCells().map((cell) => {
        if (
          !cell.column.columnDef.meta?.isEditable &&
          cell.column.columnDef.meta?.content !== 'component'
        ) {
          return (
            <TableData
              key={cell.id}
              css={{
                display: 'flex',
                width: cell.column.getSize(),
              }}
            >
              <Text
                variant="paragraph"
                css={{
                  textAlign: 'left',
                  color: '$interface_dark_deep',
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Text>
            </TableData>
          )
        } else if (!cell.column.columnDef.meta?.isEditable) {
          return (
            <TableData
              key={cell.id}
              css={{
                display: 'flex',
                ...cell.column.columnDef.meta?.tdStyles,
                width: cell.column.getSize(),
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableData>
          )
        } else {
          return (
            <TableData
              key={cell.id}
              css={{
                width: cell.column.getSize(),
              }}
            >
              <EditingCell
                getValue={cell.getValue}
                row={cell.row}
                column={cell.column}
                table={table}
              />
            </TableData>
          )
        }
      })}
    </SimpleTableRow>
  )
}

const SimpleTableRow = styled('tr', {})
