import { Button, Tag, Tooltip } from '@punto-ui/react'
import { useTotemForm } from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div, Drawer, DrawerContainer } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { ConfigSchema } from '../../..'
import { useMemo } from 'react'
import {
  useHandleCreateCompanyConfig,
  useHandleUpdateCompanyConfig,
} from '@/libs/react-query/mutations/company'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useCan } from '@/hooks'

interface CreateTotemDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
}

const MAX_DRAWER_WIDTH = '70vw'

export const CreateTotemDrawer = ({
  handleCloseDrawer,
  isOpen,
}: CreateTotemDrawerProps) => {
  const canCreateTotem = useCan(['configurations.totems'])
  const { getValues, trigger, control } = useFormContext<ConfigSchema>()

  const { data: totemForm } = useTotemForm()

  const isCreation = !useWatch({
    control,
    name: 'totem.id',
  })

  const { mutateAsync: handleCreateCompanyConfig, isLoading: isLoadingCreate } =
    useHandleCreateCompanyConfig()
  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const isLoading = useMemo(() => {
    return isLoadingCreate || isLoadingUpdate
  }, [isLoadingCreate, isLoadingUpdate])

  const totem = useWatch({
    control,
    name: 'totem',
  })

  const handleCreateTotem = async () => {
    const isValid = await trigger('totem')

    if (!isValid) {
      return
    }
    const data = getValues()

    if (isCreation) {
      await handleCreateCompanyConfig({
        totem: {
          name: data.totem?.name || '',
          login: data.totem?.login || '',
          password: data.totem?.password || '',
          is_facial_registration_enabled:
            !!data.totem?.is_facial_registration_enabled,
          is_location_obligatory: !!data.totem?.is_location_obligatory,
          is_active_face_id_disabled: !!data.totem?.is_active_face_id_disabled,
          is_touch_face_id_disabled: !!data.totem?.is_touch_face_id_disabled,
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        totem: {
          id: totem?.id || '',
          name: data.totem?.name || '',
          login: data.totem?.login || '',
          password: data.totem?.password || '',
          is_facial_registration_enabled:
            !!data.totem?.is_facial_registration_enabled,
          is_location_obligatory: !!data.totem?.is_location_obligatory,
          is_active_face_id_disabled: !!data.totem?.is_active_face_id_disabled,
          is_touch_face_id_disabled: !!data.totem?.is_touch_face_id_disabled,
        },
      })
    }

    handleCloseDrawer()
  }

  const unavailableButtonMessage = useMemo(() => {
    if (!totem?.name) {
      return 'Debe ingresar un nombre'
    }

    if (!totem?.login) {
      return 'Debe ingresar un login'
    }

    if (!totem?.password && !isCreation) {
      return 'Debe ingresar una contraseña'
    }

    return ''
  }, [totem, isCreation])

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <form>
            <Div
              css={{
                overflow: 'scroll',
                maxHeight: '100vh',
                maxWidth: '40vw',
                paddingBottom: 32,
              }}
            >
              <FormGroup
                type="fixed"
                title={
                  isCreation
                    ? 'Creacion de Totem'
                    : 'Actualizar dados del Totem'
                }
                items={totemForm}
                cols={1}
                css={{ maxWidth: MAX_DRAWER_WIDTH, marginBottom: '$12' }}
              />
              <Tag variant={'neutral'}>
                <Div css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
                  <ExclamationTriangleIcon
                    style={{ cursor: 'pointer', strokeWidth: 3 }}
                    height={16}
                    width={16}
                  />
                  No utilize contraseñas que utilizas en otros locales! Esa
                  contraseña solamiente deve ser utilizada para acesso al totem,
                  y puede ser cambiada y lida por los administradores del
                  sistema.
                </Div>
              </Tag>
              {unavailableButtonMessage ? (
                <Tooltip message={unavailableButtonMessage} arrow>
                  <Div>
                    <Button
                      css={{ marginTop: '$6', minWidth: 300 }}
                      onClick={handleCreateTotem}
                      disabled={true}
                      isLoading={isLoading}
                    >
                      {isCreation ? 'Crear' : 'Actualizar'}
                    </Button>
                  </Div>
                </Tooltip>
              ) : (
                <Div>
                  <Button
                    isLoading={isLoading}
                    css={{ marginTop: '$6', minWidth: 300 }}
                    onClick={handleCreateTotem}
                    disabled={isLoading || !canCreateTotem}
                  >
                    {isCreation ? 'Crear' : 'Actualizar'}
                  </Button>
                </Div>
              )}
            </Div>
          </form>
        }
      />
    </DrawerContainer>
  )
}
