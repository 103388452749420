import { UserCircleIcon } from '@heroicons/react/24/outline'
import { Button, Text, styled } from '@punto-ui/react'
import { useSideBarContext } from '@/contexts/hooks'
import { DefaultProfilePic, EnterpriseInfosCardContainer } from './styles'
import { useMe } from '@/libs/react-query/hooks'
import { destroyCookie } from 'nookies'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import Image from 'next/image'
import { Div } from '@/components/Div'

export const UserInfo = () => {
  const { data: me } = useMe()
  const { data: company } = useMyCompany()
  const { isOpen } = useSideBarContext()

  return (
    <EnterpriseInfosCardContainer>
      <Div
        css={{
          width: 36,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 36,
        }}
      >
        {company?.companyPictureUrl ? (
          <CompanyProfilePic
            alt={'Company Profile Picture'}
            width={36}
            height={36}
            src={company.companyPictureUrl}
          />
        ) : (
          <DefaultProfilePic>
            <UserCircleIcon />
          </DefaultProfilePic>
        )}
      </Div>
      {isOpen && (
        <>
          <Text size="sm" css={{ marginLeft: '$2' }}>
            {me?.name}
          </Text>
          <Button
            variant={'tertiary'}
            onClick={() => {
              destroyCookie(undefined, '@PuntoOk:token', {
                path: '/',
              })
              location.reload()
            }}
          >
            Salir
          </Button>
        </>
      )}
    </EnterpriseInfosCardContainer>
  )
}

const CompanyProfilePic = styled(Image, {
  borderRadius: '$full',
  border: '2px solid',
  objectFit: 'cover',
  objectPosition: 'center',
  borderColor: '$interface_dark_pure',
  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
})
