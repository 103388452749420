import React, { createContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useMonthlyWorkersHours } from '@/libs/react-query/hooks'
import dayjs from 'dayjs'
import {
  useHandleCreateShiftSchedule,
  useHandleDeleteByScheduleIds,
  useHandleUpdateShiftSchedule,
} from '@/libs/react-query/mutations/shifts'
import {
  UpdateScheduledShiftsParams,
  UpdateShiftsParams,
} from '@/pages/turnos/components/SmartShiftManagement/context'
import {
  useHandleCreatePunch,
  useHandleCreatePunchBatch,
} from '@/libs/react-query/mutations/punchclock'
import {
  CreatePunchBatchPayload,
  CreatePunchPayload,
  DeletePunchPayload,
  UpdatePunchPayload,
  UpdateTotalHoursPayload,
} from '@/libs/react-query/mutations/punchclock/types'
import { useHandleUpdatePunch } from '@/libs/react-query/mutations/punchclock/useHandleUpdate'
import { useHandleDeletePunch } from '@/libs/react-query/mutations/punchclock/useHandleDelete'
import { useHandleUpdateTotalHours } from '@/libs/react-query/mutations/punchclock/useHandleUpdateTotalHours'
import { zodResolver } from '@hookform/resolvers/zod'
import { fromPunchesToPunchTable } from './parser'
import { PunchesInfosTable, PunchesInfosTableType } from './types'
import { useTabStore } from '@/store'

interface WorkedHoursProviderProps {
  children: React.ReactNode
  id: string
  defaultDate?: Date
}

interface WorkedHoursContextData {
  isLoading: boolean
  handleUpdateShiftSchedule: ({
    params,
  }: {
    params: UpdateScheduledShiftsParams
  }) => Promise<void>
  handleCreateShiftSchedule: ({
    params,
    shiftId,
    isRest,
  }: {
    params: UpdateShiftsParams[]
    shiftId: string
    isRest?: boolean
  }) => Promise<void>
  handleCreatePunch: (p: CreatePunchPayload) => Promise<void>
  handleUpdatePunch: (p: UpdatePunchPayload) => Promise<void>
  handleCreatePunchBatch: (p: CreatePunchBatchPayload) => Promise<void>
  handleDeletePunch: (p: DeletePunchPayload) => Promise<void>
  handleUpdateTotalHours: (p: UpdateTotalHoursPayload) => Promise<void>
  handleDeleteByScheduleIds(p: { ids: string[] }): Promise<void>
}

export const WorkedHoursContext = createContext<WorkedHoursContextData>(
  {} as WorkedHoursContextData,
)

export const useUserWorkedHours = () => {
  const context = React.useContext(WorkedHoursContext)

  if (!context) {
    throw new Error('usePunchMatrix must be used within a PunchMatrizContext')
  }

  return context
}

export const UserWorkedHoursProvider = ({
  children,
  id,
  defaultDate,
}: WorkedHoursProviderProps) => {
  const { autocompleteEnabled } = useTabStore((state) => ({
    autocompleteEnabled: state.punches.metadata?.isAutomaticCompleteEnabled,
  }))

  const { mutateAsync: handleCreatePunch, isLoading: isLoadingCreatePunch } =
    useHandleCreatePunch()
  const {
    mutateAsync: handleCreatePunchBatch,
    isLoading: isLoadingCreatePunchBatch,
  } = useHandleCreatePunchBatch()
  const {
    mutateAsync: handleDeletePunchBatch,
    isLoading: isLoadingDeletePunchBatch,
  } = useHandleDeletePunch()
  const { mutateAsync: handleUpdatePunch, isLoading: isLoadingUpdatePunch } =
    useHandleUpdatePunch()
  const {
    mutateAsync: handleCreateShiftSchedule,
    isLoading: isLoadingShiftSchedule,
  } = useHandleCreateShiftSchedule()
  const {
    mutateAsync: handleDeleteByScheduleIds,
    isLoading: isLoadingDeleteShiftSchedule,
  } = useHandleDeleteByScheduleIds()
  const {
    mutateAsync: handleUpdateShiftSchedule,
    isLoading: isLoadingUpdateSchedule,
  } = useHandleUpdateShiftSchedule()
  const {
    mutateAsync: handleUpdateTotalHours,
    isLoading: isLoadingUpdateTotalHours,
  } = useHandleUpdateTotalHours()

  const methods = useForm<PunchesInfosTableType>({
    resolver: zodResolver(PunchesInfosTable),
    defaultValues: {
      data: [],
      numberOfIntervals: 0,
      date: [defaultDate?.toISOString() || new Date().toISOString()],
      name: '',
    },
  })

  const dates = useWatch({
    control: methods.control,
    name: 'date',
  })

  const date = dates?.[0] || ''

  const {
    data: workedHours,
    isFetching: isFetchingWorkedHours,
    isLoading: isLoadingWorkedHours,
  } = useMonthlyWorkersHours({
    date: dayjs(date).format('YYYY-MM-DD'),
    user_id: id,
    autocomplete: autocompleteEnabled,
  })

  useEffect(() => {
    const { date: dateFilter, name } = methods.getValues()

    if (!dateFilter || !workedHours?.length) {
      return
    }

    const savedSelectedDates = methods
      .getValues('data')
      .filter((x) => x.checked.value)
      .map((x) => x.date.metadata.date)

    const newPunches = fromPunchesToPunchTable(
      workedHours,
      savedSelectedDates,
      name,
      dateFilter,
    )

    methods.reset({
      data: newPunches.data,
      numberOfIntervals: newPunches.numberOfIntervals,
      date: newPunches.date,
      name: newPunches.name,
      userId: newPunches.userId,
    })
  }, [methods, workedHours])

  return (
    <FormProvider {...methods}>
      <WorkedHoursContext.Provider
        value={{
          isLoading:
            isLoadingWorkedHours ||
            isLoadingUpdateSchedule ||
            isLoadingShiftSchedule ||
            isLoadingCreatePunch ||
            isLoadingUpdatePunch ||
            isLoadingCreatePunchBatch ||
            isLoadingDeletePunchBatch ||
            isLoadingUpdateTotalHours ||
            isLoadingDeleteShiftSchedule ||
            isFetchingWorkedHours,
          handleUpdateTotalHours,
          handleUpdateShiftSchedule,
          handleCreateShiftSchedule,
          handleCreatePunch,
          handleUpdatePunch,
          handleCreatePunchBatch,
          handleDeletePunch: handleDeletePunchBatch,
          handleDeleteByScheduleIds,
        }}
      >
        {children}
      </WorkedHoursContext.Provider>
    </FormProvider>
  )
}
