import { useMemo } from 'react'
import { SmartColumnType } from '../types'
import { getAllColumnNames } from '../utils/get-all-column-names'

interface IUseSmartTableColumnManipulation {
  resetStateColumnOrder?: string[]
  tableOrderUniqueName: string
  tableHiddenUniqueName: string
  tableLeftFixedUniqueName?: string
  tableRightFixedUniqueName?: string
  columns?: SmartColumnType[]

  defaultLeftFixedColumns?: string[]
  defaultRightFixedColumns?: string[]

  defaultHiddenColumns?: string[]
}

export const useSmartTableColumnManipulation = ({
  columns,
  resetStateColumnOrder: rawResetStateColumnOrder,
  tableOrderUniqueName,
  tableHiddenUniqueName,
  tableLeftFixedUniqueName,
  tableRightFixedUniqueName,

  defaultLeftFixedColumns: inputDefaultLeftFixedColumns,
  defaultRightFixedColumns: inputDefaultRightFixedColumns,
  defaultHiddenColumns: inputDefaultHiddenColumns,
}: IUseSmartTableColumnManipulation) => {
  const resetStateColumnOrder = useMemo(() => {
    return (
      rawResetStateColumnOrder ||
      (columns ? getAllColumnNames(columns) : ([] as string[]))
    )
  }, [columns, rawResetStateColumnOrder])

  const defaultColumnsOrder: string[] = useMemo(() => {
    const columnsOrder =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem(tableOrderUniqueName)
        : null

    return columnsOrder && !!JSON.parse(columnsOrder).length
      ? JSON.parse(columnsOrder)
      : resetStateColumnOrder
  }, [])

  const defaultHiddenColumns: string[] = useMemo(() => {
    const hiddenColumns =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem(tableHiddenUniqueName)
        : null

    return hiddenColumns && !!JSON.parse(hiddenColumns).length
      ? JSON.parse(hiddenColumns)
      : inputDefaultHiddenColumns || []
  }, [])

  const defaultLeftFixedColumns: string[] = useMemo(() => {
    if (!tableLeftFixedUniqueName) return []

    const leftFixedColumns =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem(tableLeftFixedUniqueName)
        : null

    return leftFixedColumns && !!JSON.parse(leftFixedColumns).length
      ? JSON.parse(leftFixedColumns)
      : inputDefaultLeftFixedColumns || []
  }, [])

  const defaultRightFixedColumns: string[] = useMemo(() => {
    if (!tableRightFixedUniqueName) return []

    const rightFixedColumns =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem(tableRightFixedUniqueName)
        : null

    return rightFixedColumns && !!JSON.parse(rightFixedColumns).length
      ? JSON.parse(rightFixedColumns)
      : inputDefaultRightFixedColumns || []
  }, [])

  const saveColumnsOrderCallback = (columnsOrder: string[]) => {
    localStorage?.setItem(tableOrderUniqueName, JSON.stringify(columnsOrder))
  }

  const saveHiddenColumnsCallback = (hiddenColumns: string[]) => {
    localStorage?.setItem(tableHiddenUniqueName, JSON.stringify(hiddenColumns))
  }

  const saveLeftFixedColumnsCallback = (leftFixedColumns: string[]) => {
    if (tableLeftFixedUniqueName) {
      localStorage?.setItem(
        tableLeftFixedUniqueName,
        JSON.stringify(leftFixedColumns),
      )
    }
  }

  const saveRightFixedColumnsCallback = (rightFixedColumns: string[]) => {
    if (tableRightFixedUniqueName) {
      localStorage?.setItem(
        tableRightFixedUniqueName,
        JSON.stringify(rightFixedColumns),
      )
    }
  }

  return {
    defaultColumnsOrder,
    resetStateColumnOrder,
    defaultHiddenColumns,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
  }
}
