export const formatCurrency = (value: number) => {
  const formatted = new Intl.NumberFormat('es-PY', {
    style: 'currency',
    currency: 'PYG',
  }).format(+value)

  return formatted
}

export function formatNumberWithDots(number: number): string {
  // Convert the number to a string
  const numberStr = number.toString()

  // Use a regular expression to insert dots as thousand separators
  return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
