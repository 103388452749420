import { useCan } from '@/hooks'
import { useTabStore } from '@/store'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useShiftCreation } from './create/context/hooks'

export const useHandleShiftsActions = () => {
  const { addTab, changeTab, activeTab, setActions } = useTabStore((state) => ({
    setActions: state.setActionsTab,
    activeTab: state.shifts.activeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const { isUpdating, handleSave, isLoading } = useShiftCreation()

  const canCreate = useCan(['shifts.create', 'shifts.all'])

  useEffect(() => {
    if (activeTab === 'gerency' || activeTab === 'shifts' || !activeTab) {
      const actions = []
      if (canCreate) {
        actions.push({
          label: 'Crear Turno',
          variant: 'primary' as const,
          icon: <PlusIcon />,
          disabled: false,
          width: 160,
          action: () => {
            addTab('shifts', [
              {
                id: 'create',
                type: 'create',
                label: 'Crear Turno',
              },
            ])
            changeTab('shifts', 'create')
          },
        })
      }

      setActions('shifts', actions)
    }
  }, [
    activeTab,
    addTab,
    changeTab,
    setActions,
    canCreate,
    isUpdating,
    handleSave,
    isLoading,
  ])
}
