import { Drawer, DrawerContainer } from '@/components/Drawer'
import { IDocumentsStateSchema } from '../../documents-state'
import { useFormContext, useWatch } from 'react-hook-form'
import { ControlledInput, Div } from '@/components'
import {
  CheckCircleIcon,
  LockOpenIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import React from 'react'
import { ControlledDropdown } from '@/components/Forms'
import { useDocumentsStructure } from '@/libs/react-query/hooks/useDocumentsStructure'
import { flattenDocumentTree } from '../../documents-utils'
import { useHandleChangeFolderSettings } from '@/libs/react-query/mutations/documents/useHandleChangeFolderSettings'

export const EditFolderDrawer = () => {
  const { data: structure } = useDocumentsStructure()

  const methods = useFormContext<IDocumentsStateSchema>()

  const isCreateFolderOpen = useWatch({
    control: methods.control,
    name: 'editFolder.isEditFolderDrawerOpen',
  })

  const folderParentId = useWatch({
    control: methods.control,
    name: 'editFolder.parentId',
  })

  const setIsOpen = (v: boolean) => {
    methods.setValue('editFolder.isEditFolderDrawerOpen', v)
  }

  const documentOptions = structure ? flattenDocumentTree(structure) : []

  const {
    mutateAsync: handleExecuteChangeFolderSettings,
    isLoading: isLoadingChangeFolderSettings,
  } = useHandleChangeFolderSettings()

  const handleCreateFolder = async () => {
    const isValid = await methods.trigger('editFolder')

    if (!isValid) return

    const data = methods.getValues('editFolder')

    await handleExecuteChangeFolderSettings({
      // folder_document_type_id: null,
      name: data.name,
      foldersIds: [data.id],
      parent_id: data.parentId !== null ? data.parentId : undefined,
    })

    methods.setValue('editFolder', {
      id: '',
      parentId: null,
      isEditFolderDrawerOpen: false,
      name: '',
    })
  }

  return (
    <DrawerContainer open={isCreateFolderOpen} onOpenChange={setIsOpen}>
      <Drawer
        open={isCreateFolderOpen}
        onOpenChange={setIsOpen}
        noPadding
        content={
          <Div
            css={{
              minWidth: 500,
            }}
          >
            <Div
              css={{
                height: 44,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '$4',
                paddingRight: '$4',
              }}
            >
              <Button
                size={'sm'}
                variant="tertiary"
                onClick={() => setIsOpen(false)}
                disabled={isLoadingChangeFolderSettings}
              >
                <XMarkIcon />
                Cancelar
              </Button>
              <Button
                size={'sm'}
                isLoading={isLoadingChangeFolderSettings}
                onClick={handleCreateFolder}
              >
                <CheckCircleIcon />
                Guardar
              </Button>
            </Div>

            <Div
              css={{
                padding: '$4',
              }}
            >
              <Text
                variant="heading3"
                css={{
                  marginBottom: '$8',
                  color: '$interface_dark_deep',
                }}
              >
                Informaciones de carpeta
              </Text>

              <DrawerItemContainer>
                <DrawerItemLabel>Ubicación</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                  }}
                >
                  {folderParentId === null ? (
                    <Div>
                      <Text
                        variant="caption"
                        css={{
                          color: '$interface_dark_up',
                        }}
                      >
                        Raiz
                      </Text>
                    </Div>
                  ) : (
                    <ControlledDropdown
                      disabled={folderParentId === null}
                      name="editFolder.parentId"
                      options={documentOptions.map((d) => ({
                        label: d.name,
                        value: d.id,
                      }))}
                    />
                  )}
                </Div>
              </DrawerItemContainer>

              <DrawerItemContainer>
                <DrawerItemLabel>Nombre</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                  }}
                >
                  <ControlledInput
                    placeholder="Mi nueva carpeta"
                    name="editFolder.name"
                  />
                </Div>
              </DrawerItemContainer>

              <DrawerItemContainer>
                <DrawerItemLabel>Tipo</DrawerItemLabel>
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$2',
                    svg: {
                      height: 16,
                      width: 16,
                    },
                  }}
                >
                  <LockOpenIcon />
                  <Text variant="caption">Carpeta Personalizada</Text>
                </Div>
              </DrawerItemContainer>
            </Div>
          </Div>
        }
      />
    </DrawerContainer>
  )
}

const DrawerItemContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '$2',
      }}
    >
      {children}
    </Div>
  )
}

const DrawerItemLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      variant="caption"
      css={{
        color: '$interface_dark_down',
        minWidth: '50%',
      }}
    >
      {children}
    </Text>
  )
}
