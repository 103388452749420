import {
  IRequestMovementCreation,
  IRequestPaymentCreation,
} from '@/libs/react-query/types/requests/modules-dtos'
import { useMemo } from 'react'
import { RequestsInfosTableType } from '../../../form'
import {
  IMovementCategories,
  IPaymentTypes,
  movementsDictionary,
  paymentsDictionary,
} from '@/libs/react-query/types'

interface IRequestCategoryParams {
  selectedRequest: RequestsInfosTableType['data'][0]
}

export const useRequestCategory = ({
  selectedRequest,
}: IRequestCategoryParams) => {
  const requestCategory = useMemo(() => {
    if (selectedRequest.module.original === 'movement') {
      const requestData: IRequestMovementCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return movementsDictionary[requestData.category as IMovementCategories]
    }
    if (selectedRequest.module.original === 'payments') {
      const requestData: IRequestPaymentCreation = JSON.parse(
        selectedRequest.request_data,
      )
      return paymentsDictionary[requestData.payment_type as IPaymentTypes]
    }
    if (selectedRequest.module.original === 'vacation') {
      return 'Vacaciones'
    }

    return ''
  }, [selectedRequest])

  return {
    requestCategory,
  }
}
