import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { PayrollDetailed, UsePayrollDetailsPayload } from '../../types/payroll'

export const fetchPayroll = async (
  payrollId: string,
  cellsIds: string[],
  name: string,
  page: number | undefined,
  perPage: number | undefined,
) => {
  const response = await apiV2.get<PayrollDetailed>(
    `/payroll/details/${payrollId}`,
    {
      params: {
        cellsIds: cellsIds.join(','),
        name,
        page,
        perPage,
      },
    },
  )
  return response.data
}

export const usePayrollDetails = (data: UsePayrollDetailsPayload) => {
  return useQuery(
    [
      'payroll-details',
      data.payrollId,
      data.cellsIds.join(','),
      data.name,
      data.page,
      data.perPage,
    ],
    () =>
      fetchPayroll(
        data.payrollId!,
        data.cellsIds,
        data.name,
        data.page,
        data.perPage,
      ),
    {
      enabled: !!data.payrollId && !!data.cellsIds.length,
      keepPreviousData: true,
    },
  )
}
