import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  SmartTableFormItemValue,
  SmartTableFormTagValue,
} from '@/components/SmartTable/types'
import { z } from 'zod'

export const TemplateExportTableSchema = z.object({
  data: z
    .object({
      id: z.string(),
      created_at: SmartTableFormItemValue,
      workers: SmartTableFormItemValue,
      name: SmartTableFormItemValue,
      status: SmartTableFormTagValue,
      expiration: SmartTableFormItemValue,
      link: SmartTableFormItemValue,

      original: z.any(),
    })
    .array(),

  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
})

export type TemplateExportTableSchemaType = z.infer<
  typeof TemplateExportTableSchema
>
export type TemplateExportTableFields = Array<
  ControlledFieldProps<TemplateExportTableSchemaType>
>
