import { paymentOptions } from '@/libs/react-query/types'
import { IContentOption } from '../DataForm'

export const liquidationsPdfData: IContentOption[] = [
  {
    label: 'Tipo de liquidación',
    value: 'liquidacion.tipo',
  },
  {
    label: 'Tipo de contracto',
    value: 'liquidacion.contracto',
  },
  {
    label: 'Fecha desvinculación',
    value: 'liquidacion.desvinculación',
  },
  {
    label: 'Fecha de Firma',
    value: 'liquidacion.firma',
  },
  {
    label: 'Motivo',
    value: 'liquidacion.motivo',
  },
  {
    label: 'Fecha de ingresso',
    value: 'liquidacion.ingresso',
  },
  {
    label: 'Fecha de ingresso IPS',
    value: 'liquidacion.ingresso_ips',
  },
  {
    label: 'Observaciones',
    value: 'liquidacion.observaciones',
  },

  {
    label: 'Valor dias trabajados',
    value: 'liquidacion.dias_trabajados',
  },
  {
    label: 'Adicional Nocturno',
    value: 'liquidacion.adicional_nocturno',
  },
  {
    label: 'Horas extras diurnas',
    value: 'liquidacion.horas_extras_diurnas',
  },
  {
    label: 'Horas extras nocturnas',
    value: 'liquidacion.horas_extras_nocturnas',
  },
  {
    label: 'Horas feriado diurnas',
    value: 'liquidacion.horas_feriado_diurnas',
  },
  {
    label: 'Horas feriado nocturnas',
    value: 'liquidacion.horas_feriado_nocturnas',
  },
  {
    label: 'Desc. de horas diurnas',
    value: 'liquidacion.descuento_horas_diurnas',
  },
  {
    label: 'Desc. de horas nocturnas',
    value: 'liquidacion.descuento_horas_nocturnas',
  },
  {
    label: 'Bonificación Familiar',
    value: 'liquidacion.bonificacion_familiar',
  },
  ...paymentOptions.map((m) => ({
    label: m.label,
    value: `liquidacion.${m.value}_value`,
  })),
  {
    label: 'Vacaciones causadas',
    value: 'liquidacion.vacaciones_causadas',
  },
  {
    label: 'Vacaciones acumuladas',
    value: 'liquidacion.vacaciones_acumuladas',
  },
  {
    label: 'Vacaciones proporcionales',
    value: 'liquidacion.vacaciones_proporcionales',
  },
  {
    label: 'Anticipo de vacaciones',
    value: 'liquidacion.vacaciones_antecipadas',
  },
  {
    label: 'Preaviso',
    value: 'liquidacion.preaviso',
  },
  {
    label: 'Preaviso Descuento',
    value: 'liquidacion.preaviso_descuento',
  },
  {
    label: 'Indemnización',
    value: 'liquidacion.indemnizacion',
  },
  {
    label: 'Aguinaldo Proporcional',
    value: 'liquidacion.aguinaldo',
  },
  {
    label: 'Subtotal Salário Proporcional',
    value: 'liquidacion.subtotal_salario_proporcional',
  },
  {
    label: 'IPS 9%',
    value: 'liquidacion.ips9',
  },
  {
    label: 'IPS 16%',
    value: 'liquidacion.ips16',
  },
  {
    label: 'Subtotal Liquidación',
    value: 'liquidacion.subtotal_liquidacion',
  },
]
