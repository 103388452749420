import {
  IReportBuilderColumnFilterBoolean,
  IReportBuilderColumnFilterDate,
  IReportBuilderColumnFilterNumber,
  IReportBuilderColumnFilterString,
} from '../filter-types'

export const reportBuilderFilterTypes: {
  string: IReportBuilderColumnFilterString
  number: IReportBuilderColumnFilterNumber
  date: IReportBuilderColumnFilterDate
  boolean: IReportBuilderColumnFilterBoolean
} = {
  string: {
    value: '',
    type: 'string',
    operations: [
      {
        label: 'Igual a',
        value: 'equal',
      },
      {
        label: 'Diferente de',
        value: 'not_equal',
      },
      {
        label: 'Contiene',
        value: 'contains',
      },
      {
        label: 'No contiene',
        value: 'not_contains',
      },
      {
        label: 'Empieza con',
        value: 'starts_with',
      },
      {
        label: 'Termina con',
        value: 'ends_with',
      },
      {
        label: 'Está vacío',
        value: 'is_empty',
        requiredInput: null,
      },
      {
        label: 'No está vacío',
        value: 'is_not_empty',
        requiredInput: null,
      },
    ],
  },

  boolean: {
    value: null,
    type: 'boolean',
    operations: [
      {
        label: 'Si',
        value: 'true',
        requiredInput: null,
      },
      {
        label: 'No',
        value: 'false',
        requiredInput: null,
      },
      {
        label: 'Está vacío',
        value: 'is_empty',
        requiredInput: null,
      },
      {
        label: 'No está vacío',
        value: 'is_not_empty',
        requiredInput: null,
      },
    ],
  },

  date: {
    value: null,
    type: 'date',
    operations: [
      {
        label: 'Igual a',
        value: 'equal',
      },
      {
        label: 'Diferente de',
        value: 'not_equal',
      },
      {
        label: 'Mayor que',
        value: 'greater_than',
      },
      {
        label: 'Menor que',
        value: 'less_than',
      },
      {
        label: 'Mayor o igual que',
        value: 'greater_than_or_equal',
      },
      {
        label: 'Menor o igual que',
        value: 'less_than_or_equal',
      },
      {
        label: 'Está vacío',
        value: 'is_empty',
        requiredInput: null,
      },
      {
        label: 'No está vacío',
        value: 'is_not_empty',
        requiredInput: null,
      },
    ],
  },

  number: {
    value: '',
    type: 'number',
    operations: [
      {
        label: 'Igual a',
        value: 'equal',
      },
      {
        label: 'Diferente de',
        value: 'not_equal',
      },
      {
        label: 'Mayor que',
        value: 'greater_than',
      },
      {
        label: 'Menor que',
        value: 'less_than',
      },
      {
        label: 'Mayor o igual que',
        value: 'greater_than_or_equal',
      },
      {
        label: 'Menor o igual que',
        value: 'less_than_or_equal',
      },
      {
        label: 'Está vacío',
        value: 'is_empty',
        requiredInput: null,
      },
      {
        label: 'No está vacío',
        value: 'is_not_empty',
        requiredInput: null,
      },
    ],
  },
}
