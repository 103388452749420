import {
  ComponentProps,
  ElementType,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import {
  ChipRoot,
  ChipActivatedNum,
  ChipOptionsContainer,
  ChipItem,
  ButtonsContainer,
  ChipScrollingContainer,
  ChipInput,
} from './styles'
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter'
import { Button, Checkbox, Text } from '@punto-ui/react'
import { Div } from '../Div'
import { CSS } from '@stitches/react'

interface OptionProps {
  value: any
  label: ReactNode | string
  selected: boolean
}

export interface ChipProps extends ComponentProps<typeof ChipRoot> {
  as?: ElementType
  label: ReactNode | string
  basicIcon?: ReactNode
  options?: OptionProps[]
  onChangeValue?: (value: OptionProps[]) => void
  defaultOptions?: OptionProps[]
  dropdownSide?: 'left' | 'right'
  withoutTotalButtons?: boolean
  withoutActivatedNum?: boolean
  inputStyle?: CSS
}

export function Chip({
  basicIcon,
  options: optionsParams,
  defaultOptions,
  label,
  onChangeValue,
  dropdownSide = 'left',
  withoutTotalButtons,
  inputStyle,
  withoutActivatedNum,
  ...props
}: ChipProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(optionsParams || defaultOptions)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const timeoutRef = useRef<any>(null)

  useEffect(() => {
    setOptions(optionsParams)
  }, [optionsParams])

  const chipRef = useRef<HTMLElement>(null)
  useOutsideAlerter(chipRef, () => {
    setIsMenuOpen(false)
  })

  const selectedOptions = optionsParams?.filter((option) => option.selected)

  const handleCleanSelection = () => {
    const newOptionsParams = optionsParams?.map((option) => {
      return { ...option, selected: false }
    })

    setInputValue('')
    setOptions(newOptionsParams)
    onChangeValue?.(newOptionsParams || [])
  }

  const handleSelectAll = () => {
    const newOptionsParams = optionsParams?.map((option) => {
      return { ...option, selected: true }
    })

    setInputValue('')
    setOptions(newOptionsParams)
    onChangeValue?.(newOptionsParams || [])
  }

  return (
    <ChipRoot
      ref={chipRef}
      variant={selectedOptions?.length ? 'activated' : 'default'}
      {...props}
    >
      <Button
        css={{
          backgroundColor: selectedOptions?.length
            ? '$interface_light_up'
            : '$interface_light_up',
          border: 'solid',
          borderColor: '$brand_primary_pure',
          borderWidth: 1,

          ...inputStyle,
        }}
        type="button"
        variant={selectedOptions?.length ? 'secondary' : 'secondary'}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen)
          timeoutRef.current = setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }, 15)
        }}
      >
        {typeof label === 'string' ? (
          <Text
            variant="description"
            css={{
              whiteSpace: 'nowrap',
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              minWidth: 80,
              color: '$brand_primary_pure',
            }}
          >
            {label}
          </Text>
        ) : (
          // <ChipInput
          //   ref={inputRef}
          //   placeholder={label}
          //   onChange={(e) => {
          //     const name = e.target.value

          //     const newOptions = optionsParams?.filter((option) => {
          //       return option.label
          //         ?.toString()
          //         .toLowerCase()
          //         .includes(name.toLowerCase())
          //     })

          //     setIsMenuOpen(true)
          //     setOptions(!newOptions?.length ? optionsParams : newOptions)
          //     setInputValue(name)
          //   }}
          //   value={inputValue}
          // />
          <>{label}</>
        )}
        {!withoutActivatedNum && (
          <Div css={{ minWidth: '$5' }}>
            <ChipActivatedNum hasContent={!!selectedOptions?.length}>
              {selectedOptions?.length ? (
                <Text
                  variant={'description'}
                  css={{ color: '$interface_light_down', fontWeight: 700 }}
                >
                  {selectedOptions?.length}
                </Text>
              ) : (
                <></>
              )}
            </ChipActivatedNum>
          </Div>
        )}
        <ChevronDownIcon />
      </Button>
      <ChipOptionsContainer isOpen={isMenuOpen} dropdownSide={dropdownSide}>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            background: '$interface_light_up',
            padding: '$4',
            svg: {
              height: 12,
              width: 12,
              color: '$brand_primary_pure',
              marginRight: '$2',
            },
          }}
        >
          <MagnifyingGlassIcon />
          <ChipInput
            ref={inputRef}
            placeholder={typeof label === 'string' ? label : 'Sector'}
            onChange={(e) => {
              const name = e.target.value

              const newOptions = optionsParams?.filter((option) => {
                return option.label
                  ?.toString()
                  .toLowerCase()
                  .includes(name.toLowerCase())
              })

              setIsMenuOpen(true)
              setOptions(!newOptions?.length ? optionsParams : newOptions)
              setInputValue(name)
            }}
            value={inputValue}
          />
        </Div>
        <ChipScrollingContainer>
          {options?.map((option) => (
            <ChipItem
              key={option.value}
              isSelected={
                !!options.find((op) => op.value === option.value && op.selected)
              }
            >
              <label>
                <Checkbox
                  defaultChecked={option.selected}
                  checked={option.selected}
                  onCheckedChange={() => {
                    const newOptions =
                      optionsParams?.map((op) => {
                        if (op.value === option.value) {
                          return { ...op, selected: !op.selected }
                        }
                        return op
                      }) || []
                    const newFilteredOptions =
                      options?.map((op) => {
                        if (op.value === option.value) {
                          return { ...op, selected: !op.selected }
                        }
                        return op
                      }) || []

                    setOptions(newFilteredOptions)
                    onChangeValue?.(newOptions)
                  }}
                />
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {option.label}
                </Text>
              </label>
            </ChipItem>
          ))}
        </ChipScrollingContainer>
        {!withoutTotalButtons && (
          <ButtonsContainer>
            <Button
              variant="secondary"
              onClick={handleCleanSelection}
              css={{ marginRight: 16 }}
              type="button"
            >
              Limpar
            </Button>
            <Button onClick={handleSelectAll} type="button">
              Todos
            </Button>
          </ButtonsContainer>
        )}
      </ChipOptionsContainer>
    </ChipRoot>
  )
}

Chip.displayName = 'Chip'
