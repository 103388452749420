import { CreateShiftsPageHeader } from './CreateShiftsPageHeader'
import { WeekShiftTable } from './WeekShiftTable'
import { ActionsMenu } from './ActionsMenu'
import { Configurations } from './Configurations'
import { Table as ShiftsTable } from './Table'

export {
  CreateShiftsPageHeader,
  WeekShiftTable,
  ActionsMenu,
  Configurations,
  ShiftsTable,
}
