import { useFormContext, useWatch } from 'react-hook-form'
import { IReceiptBuilderTypes, ReceiptBuilderSchemaType } from '../../type'
import { Div, SmartTable } from '@/components'
import { SmartColumnType } from '@/components/SmartTable/types'
import { Button, Menu } from '@punto-ui/react'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { getEmptyReceiptRow } from '../..'
import { rowsOptionsByReceiptType } from '../../constants/rows-options-by-receipt-type'
import { generateRandomNumberUnder10 } from '@/utils/random'

export const ReceiptContent = () => {
  const { control, setValue, getValues } =
    useFormContext<ReceiptBuilderSchemaType>()

  const [title, type] = useWatch({
    control,
    name: ['configurations.title', 'configurations.type'],
  })

  // const data = getValues('data')
  // console.log('sss', data)

  const columns: SmartColumnType[] = [
    {
      type: 'subheader',
      header: title,
      name: 'teste',
      items: [
        {
          type: 'input-text',
          name: 'amount',
          draggable: null,
          header: 'Cantidad',
          disabled: true,
          inputType: 'no-mask',
          width: 150,
        },
        {
          type: 'input-text',
          name: 'conceptLabel',
          draggable: null,
          header: 'Concepto',
          freeWriting: true,
          inputType: 'no-mask',
          width: 200,
        },
        {
          type: 'draggable-tags',
          name: 'concepts',
          onTagClick: (index: number, name: string) => {
            const rows = getValues('data')
            const newRows = [...rows]

            if (!newRows[index]) return

            newRows[index].concepts = newRows[index].concepts.filter(
              (c) => c.id !== name,
            )
            newRows[index].includedConceptsInRow = newRows[
              index
            ].includedConceptsInRow.filter((c) => c !== name)

            setValue('data', newRows)
          },
          onCloseTag: (index: number, name: string) => {
            const rows = getValues('data')
            const newRows = [...rows]

            if (!newRows[index]) return

            newRows[index].concepts = newRows[index].concepts.filter(
              (c) => c.id !== name,
            )
            newRows[index].includedConceptsInRow = newRows[
              index
            ].includedConceptsInRow.filter((c) => c !== name)

            setValue('data', newRows)
          },
          onDropTag: (index: number, name: string, data: any) => {
            const rows = getValues('data')
            const newRows = [...rows]

            if (!newRows[index]) return

            newRows[index].concepts.push({
              id: data.value,
              name: data.label,
              variant: data.isDebit ? 'negative' : 'positive',
              data,
            })

            newRows[index].includedConceptsInRow.push(data.value)
            newRows[index].conceptLabel.value = !newRows[index].conceptLabel
              .value
              ? data.label
              : newRows[index].conceptLabel.value

            const rowReceipt = rowsOptionsByReceiptType[
              type as IReceiptBuilderTypes
            ].find((c) => c.value === data.value)

            newRows[index].amount.value = !newRows[index].amount.value
              ? rowReceipt?.getRandomPlaceholder?.() ||
                generateRandomNumberUnder10()
              : newRows[index].amount.value || ''

            setValue('data', rows)
          },
          header: 'Valor',
          inputType: 'no-mask',
          width: 500,
        },
        {
          type: 'custom',
          component: ({ index }: { index: number }) => (
            <DropdownRowMenu index={index} />
          ),
          name: 'dropdown',
          header: '',
          width: 50,
        },
      ],
    },
  ]

  return (
    <Div>
      <SmartTable
        columns={columns}
        style={{
          borderInLines: true,
        }}
      />
      <Div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant={'secondary'}
          onClick={() => {
            const data = getValues('data')
            setValue('data', [...data, getEmptyReceiptRow()])
          }}
        >
          Agregar linea
        </Button>
      </Div>
    </Div>
  )
}

const DropdownRowMenu = ({ index }: { index: number }) => {
  const { control, setValue, getValues } =
    useFormContext<ReceiptBuilderSchemaType>()

  const row = useWatch({
    control,
    name: `data.${index}`,
  })

  return (
    <Menu
      items={[
        {
          id: '1',
          label: 'Conceptos',
          children: row.concepts.map((c, index) => ({
            id: `1.${index}`,
            label: c.name,
            icon: c.variant === 'positive' ? <PlusIcon /> : <MinusIcon />,
            rightSlot: (
              <TrashIcon
                type="button"
                onClick={(e) => {
                  const rows = getValues('data')
                  const newRows = [...rows]

                  if (!newRows[index]) return

                  newRows[index].concepts = newRows[index].concepts.filter(
                    (existingConcepts) => existingConcepts.id !== c.id,
                  )
                  newRows[index].includedConceptsInRow = newRows[
                    index
                  ].includedConceptsInRow.filter(
                    (includedConcept) => includedConcept !== c.id,
                  )

                  setValue('data', newRows)
                  e.stopPropagation()
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
            ),
            onClick: () => null,
          })),
          // [
          //   {
          //     id: '1.1',
          //     label: 'Salario',
          //     icon: <PlusIcon />,
          //     onClick: () => {
          //       const data = getValues('data')
          //       setValue('data', [...data, getEmptyReceiptRow()])
          //     },
          //   },
          // ],
          icon: <PlusIcon />,
          onClick: () => {
            const data = getValues('data')
            setValue('data', [...data, getEmptyReceiptRow()])
          },
        },
        {
          id: '5',
          label: 'Agregar linea',
          icon: <PlusIcon />,
          onClick: () => {
            const data = getValues('data')
            setValue('data', [...data, getEmptyReceiptRow()])
          },
        },
        {
          id: '4',
          label: 'Excluir linea',
          icon: <TrashIcon />,
          onClick: () => {
            const data = getValues('data')
            setValue(
              'data',
              data.filter((_, i) => i !== index),
            )
          },
        },
        {
          id: '2',
          label: 'Subir linea',
          icon: <ChevronUpIcon />,
          onClick: () => {
            const data = getValues('data')
            const newData = [...data]
            const temp = newData[index]
            newData[index] = newData[index - 1]
            newData[index - 1] = temp
            setValue('data', newData)
          },
        },
        {
          id: '3',
          label: 'Descer linea',
          icon: <ChevronDownIcon />,
          onClick: () => {
            const data = getValues('data')
            const newData = [...data]
            const temp = newData[index]
            newData[index] = newData[index + 1]
            newData[index + 1] = temp
            setValue('data', newData)
          },
        },
      ]}
    />
  )
}
