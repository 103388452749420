import { Progress, Text } from '@punto-ui/react'
import {
  ExpandedState,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from '@tanstack/react-table'
import React from 'react'
import { PolicyTable } from './components'
import { Div } from '@/components'
import { usePolicyBuilder } from './context'
import { usePolicyColumns } from './hooks'
import { useOrganogram } from '@/libs/react-query/hooks'
import { isObject } from '@/utils/object'
import { useExecuteOnceWhen } from '@/hooks'
import { FieldPath } from 'react-hook-form'
import { ICreateCompletePolicyData } from './types'

const PolicyBuilderContent = ({
  systemModule,
  setSelectedModule,
}: {
  systemModule: FieldPath<ICreateCompletePolicyData>
  setSelectedModule?: (str: string) => void
}) => {
  const { groups, isLoading } = usePolicyBuilder()
  const { isLoading: isLoadingOrganogram, isError: isErrorOrganogram } =
    useOrganogram()

  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const { data: columns } = usePolicyColumns(systemModule, setSelectedModule)

  const table = useReactTable({
    data: groups ?? [],
    columns,
    onExpandedChange: setExpanded,
    state: {
      expanded:
        isObject(expanded) && !!groups[0] && typeof expanded !== 'boolean'
          ? {
              ...expanded,
              [groups[0].id]: true,
            }
          : expanded,
    },
    getSubRows: (row) => (row._type === 'group' ? row.children : []),
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  useExecuteOnceWhen(() => {
    if (groups.length > 0) {
      table.toggleAllRowsExpanded(true)
    }
  }, !!table && !!groups.length)

  if (isLoadingOrganogram || isLoading) {
    return (
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 60,
        }}
      >
        <Progress />
      </Div>
    )
  }

  if (isErrorOrganogram) {
    return (
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 60,
        }}
      >
        <Text variant="paragraph">
          No organigrama disponible para la empresa, habla con un administrador
        </Text>
      </Div>
    )
  }

  return <PolicyTable table={table} />
}

export const PolicyBuilder = ({
  systemModule,
  setSelectedModule,
}: {
  systemModule: FieldPath<ICreateCompletePolicyData>
  setSelectedModule?: (str: string) => void
}) => {
  return (
    <PolicyBuilderContent
      systemModule={systemModule}
      setSelectedModule={setSelectedModule}
    />
  )
}
