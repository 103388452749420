import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline'
import { keyframes, styled } from '@punto-ui/react'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'

import logoImg from '@/assets/img/logo@3x.png'
import { useSideBarContext } from '@/contexts/hooks'
import { useIsMounted } from '@/hooks'
import { disableAllAnimations } from '@/pages/styles/animations'

export const SideBarHeader = () => {
  const { setIsOpen, isOpen } = useSideBarContext()
  const isMounted = useIsMounted()
  const [isFairlyMounted, setIsFairlyMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsFairlyMounted(true)
    }, 300)
  }, [])

  return (
    <SideBarHeaderContainer
      isOpen={isOpen}
      isMounting={!isFairlyMounted || !isMounted.current}
    >
      {isOpen && <Image src={logoImg} alt="PuntoOk" width={100} />}
      <ChevronDoubleLeftIcon onClick={() => setIsOpen((s) => !s)} />
    </SideBarHeaderContainer>
  )
}

const IconOpenningAnimation = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(180deg)',
  },
})

const IconClosingAnimation = keyframes({
  from: {
    transform: 'rotate(180deg)',
  },
  to: {
    transform: 'rotate(0deg)',
  },
})

export const SideBarHeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '$4',
  marginLeft: '$4',

  marginBottom: '$6',

  '& svg': {
    height: '$5',
    transform: 'rotate(0deg)',
    animation: `${IconClosingAnimation} 0.3s ease-in-out`,
    cursor: 'pointer',
    color: '$brand_primary_pure',
    marginRight: '$4',
  },

  variants: {
    isOpen: {
      true: {
        justifyContent: 'space-between',

        '& svg': {
          transform: 'rotate(180deg)',
          animation: `${IconOpenningAnimation} 0.3s ease-in-out`,
        },
      },
      false: {
        justifyContent: 'center',
      },
    },
    isMounting: {
      true: {
        '& svg': {
          ...disableAllAnimations(),
        },
      },
    },
  },
})
