import { zodResolver } from '@hookform/resolvers/zod'
import React, { createContext, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AddShiftData, addShiftSchema } from '../forms'
import dayjs from 'dayjs'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { SmartShift } from '../../components/SmartShiftManagement/types'
import { useExecuteOnceWhen } from '@/hooks'
import { getWorkHours } from '@/utils/shifts/getWorkHours'
import { formatMinutes } from '@/utils/date'
import { useTabStore } from '@/store'
import { useHandleCreateShifts } from '@/libs/react-query/mutations'
import { useHandleUpdateShifts } from '@/libs/react-query/mutations/shifts/useHandleUpdate'
import { validateShiftTurns } from '../components/CreateShiftsPageHeader'
import { useHandleCreateShiftActions } from '../useHandleCreateShiftActions'

interface CreateShiftContextData {
  setMonth: (month: number) => void
  shiftId?: string
  currentStep: number
  setStep: (step: number) => void
  isUpdating: boolean
  isLoading: boolean
  handleSave: () => void
}

export const CreateShiftContext = createContext<CreateShiftContextData>(
  {} as CreateShiftContextData,
)

interface CreateShiftsProviderProps {
  children: React.ReactNode
}

export const CreateShiftsProvider = ({
  children,
}: CreateShiftsProviderProps) => {
  const [currentStep, setStep] = React.useState(0)
  const { shiftTabs, activeTab, setTab, removeTab } = useTabStore((state) => ({
    shiftTabs: state.shifts.tabs,
    activeTab: state.shifts.activeTab,
    removeTab: state.removeTab,
    setTab: state.setActiveTab,
  }))

  const id = useMemo(() => {
    const tab = shiftTabs.find((t) => t.id === activeTab)
    return tab?.id
  }, [shiftTabs, activeTab])

  const { data: smartShifts, isLoading: isLoadingSmartShifts } =
    useSmartShifts()
  const updatingShift = smartShifts?.find((shift) => shift.shiftId === id)

  const methods = useForm<AddShiftData>({
    resolver: zodResolver(addShiftSchema),
    defaultValues: updatingShift
      ? convertSmartShiftToAddShiftData(updatingShift)
      : getInitialShiftWeeks(new Date()),
  })

  useExecuteOnceWhen(() => {
    if (updatingShift) {
      methods.reset(convertSmartShiftToAddShiftData(updatingShift))
    }
  }, !isLoadingSmartShifts && !!updatingShift)

  const setMonth = useCallback(
    (newMonthNumber: number) => {
      const shifts = methods.getValues('shifts')
      const newShiftsDates = shifts.map((shift) => {
        const date = dayjs(shift.date).month(newMonthNumber)
        return {
          ...shift,
          date: date.format('YYYY-MM-DD'),
          dayOfWeek: date.format('dddd'),
        }
      })

      methods.setValue('shifts', newShiftsDates)
    },
    [methods],
  )

  const { mutateAsync: createShift, isLoading: isLoadingShiftCreation } =
    useHandleCreateShifts()
  const { mutateAsync: updateShift, isLoading: isLoadingShiftUpdate } =
    useHandleUpdateShifts()

  const handleSave = useCallback(async () => {
    const data = methods.getValues()
    const isValid = await methods.trigger()
    const shiftId = updatingShift?.shiftId

    if (!isValid) {
      console.log('formHasErrors', methods.formState.errors)
      return
    }

    const invalidTurnTimeIndex = validateShiftTurns(data.shifts)

    if (invalidTurnTimeIndex !== -1) {
      methods.setError(
        `shifts.${invalidTurnTimeIndex}.switchShiftsTime`,
        {
          message: 'Cambio inválido',
        },
        {
          shouldFocus: true,
        },
      )
      return
    }

    if (shiftId) {
      await updateShift({
        shift_id: shiftId,
        ...data,
      })
      removeTab('shifts', shiftId)
    } else {
      await createShift(data)
      removeTab('shifts', 'create')
    }

    setTab('shifts', 'shifts')
  }, [
    createShift,
    methods,
    removeTab,
    setTab,
    updateShift,
    updatingShift?.shiftId,
  ])

  const isUpdating = useMemo(() => {
    return !!updatingShift
  }, [updatingShift])

  const isLoading = useMemo(() => {
    return (
      isLoadingShiftCreation || isLoadingShiftUpdate || isLoadingSmartShifts
    )
  }, [isLoadingShiftCreation, isLoadingShiftUpdate, isLoadingSmartShifts])

  useHandleCreateShiftActions({
    isUpdating,
    handleSave,
    isLoading,
  })

  return (
    <FormProvider {...methods}>
      <CreateShiftContext.Provider
        value={{
          setMonth,
          shiftId: updatingShift?.shiftId,
          isUpdating: !!updatingShift,
          currentStep,
          setStep,
          isLoading,
          handleSave,
        }}
      >
        {children}
      </CreateShiftContext.Provider>
    </FormProvider>
  )
}

const convertSmartShiftToAddShiftData = (
  smartShift: SmartShift,
): AddShiftData => {
  const shiftDaysOrdered = smartShift.days.sort((a, b) => {
    if (a.weekDay === 0) return 1
    if (b.weekDay === 0) return -1
    return a.weekDay - b.weekDay
  })

  const shifts = shiftDaysOrdered.map((day) => {
    const date = dayjs()
      .set('month', new Date().getMonth())
      .startOf('week')
      .add(day.weekDay, 'days')

    const intervals: any[] = []
    day.intervals.forEach((_, index) => {
      if (index === 0) return

      intervals.push({
        startTime: day.intervals[index - 1].endTime,
        endTime: day.intervals[index].startTime,
        duration: formatMinutes(day.intervals[index].maxMinutes),
      })
    })

    const { dayHours, nightHours, total } = getWorkHours({
      entrance: day.intervals[0]?.startTime,
      exit: day.intervals[day.intervals.length - 1]?.endTime,
      fromDayToNight: '20:00',
      fromNightToDay: '06:00',
      intervals,
    })

    return {
      date: date.format('YYYY-MM-DD'),
      dayOfWeek: date.format('dddd'), // You might need to adjust this
      startTime: day.intervals[0]?.startTime || '',
      endTime: day.intervals[day.intervals.length - 1]?.endTime || '',
      switchShiftsTime: day.shiftTurn,
      totalDaily: formatMinutes(dayHours * 60),
      totalNight: formatMinutes(nightHours * 60), // Adjust this as per your requirements
      totalHours: formatMinutes(total * 60), // Adjust this as per your requirements
      intervals,
    }
  })

  const addShiftData: AddShiftData = {
    shifts,
    name: smartShift.shiftName,
    code: smartShift.shiftCode,
    config: {
      timezone: smartShift.config.timezone,
      number_of_weeks: smartShift.config.number_of_weeks,
      entrance_tolerance_before:
        +smartShift.config.entrance_tolerance_before || 0,
      entrance_tolerance_after:
        +smartShift.config.entrance_tolerance_after || 0,
      leave_tolerance_before: +smartShift.config.leave_tolerance_before || 0,
      leave_tolerance_after: +smartShift.config.leave_tolerance_after || 0,
      is_smart_shift_change: smartShift.config.is_smart_shift_change,
      is_flexible_journey: smartShift.config.is_flexible_journey,
      daily_hours_amount: smartShift.config.daily_hours_amount,
      mixed_hours_amount: smartShift.config.mixed_hours_amount,
      night_hours_amount: smartShift.config.night_hours_amount,
      discount_time: smartShift.config.discount_time,
      consider_holidays: smartShift.config.consider_holidays,
      consider_tolerance: smartShift.config.consider_tolerance,
      consider_interval: smartShift.config.consider_interval,
      use_predefined_intervals: smartShift.config.use_predefined_intervals,
      flexible_morning_hours: formatMinutes(
        +smartShift.config.flexible_morning_hours * 60,
      ),
      flexible_mixed_hours: formatMinutes(
        +smartShift.config.flexible_mixed_hours * 60,
      ),
      flexible_night_hours: formatMinutes(
        +smartShift.config.flexible_night_hours * 60,
      ),
      default_interval_night: formatMinutes(
        +smartShift.config.default_interval_night * 60,
      ),
      default_interval_mixed_night: formatMinutes(
        +smartShift.config.default_interval_mixed_night * 60,
      ),
      default_interval_mixed_morning: formatMinutes(
        +smartShift.config.default_interval_mixed_morning * 60,
      ),
      default_interval_morning: formatMinutes(
        +smartShift.config.default_interval_morning * 60,
      ),
      should_consider_interval_credits:
        smartShift.config.should_consider_interval_credits,
      ignore_incomplete_punches: smartShift.config.ignore_incomplete_punches,
    },
  }

  return addShiftData
}
const getInitialShiftWeeks = (date: Date): AddShiftData => {
  const firstDay = dayjs(date).startOf('week').add(1, 'day')

  const shifts = []

  for (let i = 0; i < 7; i++) {
    const day = firstDay.add(i, 'day')
    if (i === 5 || i === 6) {
      shifts.push({
        date: day.format('YYYY-MM-DD'),
        startTime: '00:00',
        endTime: '00:00',
        dayOfWeek: day.format('dddd'),
        intervals: [],
        switchShiftsTime: '00:00',
        totalDaily: '00:00',
        totalNight: '00:00',
        totalHours: '10:00',
      })
    } else {
      shifts.push({
        date: day.format('YYYY-MM-DD'),
        startTime: '06:00',
        endTime: '18:00',
        dayOfWeek: day.format('dddd'),
        intervals: [],
        switchShiftsTime: '00:00',
        totalDaily: '00:00',
        totalNight: '00:00',
        totalHours: '10:00',
      })
    }
  }

  return {
    shifts,
    name: '',
    code: '',
    config: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      consider_holidays: false,
      consider_interval: false,
      consider_tolerance: false,

      discount_time: false,
      // tolerance:
      entrance_tolerance_before: 0,
      entrance_tolerance_after: 0,
      leave_tolerance_before: 0,
      leave_tolerance_after: 0,
      // Flexible shift
      is_flexible_journey: false,
      // Default interval
      default_interval_night: '',
      default_interval_mixed_night: '',
      default_interval_mixed_morning: '',
      default_interval_morning: '',
      // Default hours for flexible.
      flexible_morning_hours: '',
      flexible_mixed_hours: '',
      flexible_night_hours: '',

      is_smart_shift_change: false,

      daily_hours_amount: '',
      mixed_hours_amount: '',
      night_hours_amount: '',
      use_predefined_intervals: false,
      number_of_weeks: 1,

      should_consider_interval_credits: false,
      ignore_incomplete_punches: false,
    },
  }
}
