import { Div, Flex, Touchable } from '@/components'
import { InputDatePicker } from '@/components/InputDatePicker'
import { useDebounce, useOutsideAlerter } from '@/hooks'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'
import { useHandleBatchUpdateShiftSchedule } from '@/libs/react-query/mutations/shifts/useHandleBatchUpdateShiftSchedule'
import {
  UpdateShiftsParams,
  useSmartShift,
} from '@/pages/turnos/components/SmartShiftManagement/context'
import { SmartShiftsFormSchema } from '@/pages/turnos/components/SmartShiftManagement/forms'
import {
  ArrowPathIcon,
  Battery50Icon,
  CalendarIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/outline'
import { Checkbox, Text, keyframes, styled } from '@punto-ui/react'
import dayjs from 'dayjs'
import { memo, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.5)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})
export const HelpBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const reference = useRef(null)
  const optionsRef = useRef(null)
  const { control, getValues } = useFormContext<SmartShiftsFormSchema>()

  const { shiftsSelection } = useWatch({
    control,
  })

  // useOutsideAlerter(reference, () => {
  //   setIsOpen(false)
  // })

  useOutsideAlerters([reference, optionsRef], () => {
    setIsOpen(false)
  })

  const valueSelected = shiftsSelection?.some((current) => {
    return current.some((s) => s === true)
  })

  const numberOfSelected = getValues().shiftsSelection.reduce(
    (prev, current) => {
      const selected = current.reduce((acc, selected) => {
        return selected ? acc + 1 : acc
      }, 0)

      return prev + selected
    },
    0,
  )
  const numberOfSelectedWorkers = getValues().shiftsSelection.reduce(
    (prev, current) => {
      const selected = current.some((b) => b)

      return selected ? prev + 1 : prev
    },
    0,
  )

  if (!valueSelected) {
    return null
  }

  return (
    <Div
      css={{
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        position: 'absolute',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        top: 'calc(100% - 60px)',
        right: 120,
        zIndex: 999,
      }}
    >
      <Div
        css={{
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          animation: `${contentShow} 50ms cubic-bezier(0.16, 1, 0.3, 1)`,
          background: '$interface_light_pure',
          borderRadius: '$md',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid $interface_light_down',
          height: 50,
        }}
      >
        <Div
          css={{
            padding: 24,
            borderRight: '1px solid $interface_light_down',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
              fontWeight: '$bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {numberOfSelected} dias selecionados
          </Text>
        </Div>
        <Div
          css={{
            padding: 24,
            borderRight: '1px solid $interface_light_down',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant="caption"
            css={{ color: '$interface_dark_down', fontWeight: '$bold' }}
          >
            {numberOfSelectedWorkers} colaboradores selecionados
          </Text>
        </Div>
        <RestButton />
        <Touchable onClick={() => setIsOpen(!isOpen)}>
          <Div
            ref={reference}
            css={{
              borderRight: '1px solid $interface_light_down',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              paddingLeft: '$3',
              paddingRight: '$3',
              svg: {
                color: '$interface_dark_down',
                height: 14,
                width: 14,
              },
            }}
          >
            <ArrowPathIcon />
            <Text
              variant="caption"
              css={{
                color: '$interface_dark_down',
                fontWeight: '$bold',
                marginTop: '$1',
              }}
            >
              Cambiar Turno
            </Text>
          </Div>
        </Touchable>
        <Div ref={optionsRef}>{isOpen && <Options />}</Div>
        <DefineDateButtons isStart={true} />
        <DefineDateButtons isStart={false} />
      </Div>
    </Div>
  )
}

const DefineDateButtons = ({ isStart = true }: { isStart?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false)
  const reference = useRef(null)

  useOutsideAlerter(reference, () => {
    setIsOpen(false)
  })

  return (
    <>
      <Touchable onClick={() => setIsOpen(!isOpen)}>
        <Div
          css={{
            borderRight: '1px solid $interface_light_down',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            paddingLeft: '$3',
            paddingRight: '$3',
            svg: {
              color: '$interface_dark_down',
              height: 14,
              width: 14,
            },
          }}
        >
          <CalendarIcon />
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
              fontWeight: '$bold',
              marginTop: '$1',
            }}
          >
            {isStart ? 'Data de Inicio' : 'Data de Termino'}
          </Text>
        </Div>
      </Touchable>
      <Div ref={reference}>
        {isOpen && <DateSelectorOptions isStart={isStart} />}
      </Div>
    </>
  )
}

const DateSelectorOptions = ({ isStart = true }: { isStart?: boolean }) => {
  const { workersShifts } = useSmartShift()
  const { mutateAsync: handleBatchUpdateShiftSchedules } =
    useHandleBatchUpdateShiftSchedule()
  const { getValues } = useFormContext<SmartShiftsFormSchema>()

  const getSelectedScheduledShiftIds = () => {
    const shiftsSelection = getValues('shiftsSelection')
    const scheduledShiftIds: string[] = []

    workersShifts.forEach((workerShifts, workerIndex) => {
      const sortedShifts = workerShifts.shifts.sort((a, b) =>
        dayjs(a.date).diff(dayjs(b.date)),
      )

      sortedShifts.forEach((shift, index) => {
        if (
          shiftsSelection?.[workerIndex]?.[index] &&
          shift.schedule.scheduled_shift_id
        ) {
          scheduledShiftIds.push(shift.schedule.scheduled_shift_id)
        }
      })
    })

    const nonDuppedScheduledShiftIds = scheduledShiftIds.filter(
      (item, index) => scheduledShiftIds.indexOf(item) === index,
    )

    return nonDuppedScheduledShiftIds
  }

  return (
    <Div
      css={{
        background: '$interface_light_pure',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '$md',
        padding: 16,

        maxHeight: 400,
        overflowY: 'auto',

        position: 'absolute',
        bottom: '125%',
        right: 0,
      }}
    >
      <Div css={{ display: 'flex', alignItems: 'center' }} as="label">
        <Checkbox
          defaultChecked={false}
          onCheckedChange={async function (checked) {
            if (checked) {
              await handleBatchUpdateShiftSchedules({
                params: {
                  startDate: isStart ? null : undefined,
                  endDate: isStart ? undefined : null,
                  scheduled_shift_ids: getSelectedScheduledShiftIds(),
                },
              })
            }
          }}
        />
        <Text css={{ marginLeft: '$2', color: '$interface_dark_deep' }}>
          {isStart ? 'Sin inicio' : 'Sin termino'}
        </Text>
      </Div>
      <InputDatePicker
        maxDates={60}
        hideInput
        stayOpen
        type={'single'}
        onChange={async function (dates: (Date | null)[]) {
          if (dates.length) {
            await handleBatchUpdateShiftSchedules({
              params: {
                startDate: isStart ? dates[0] : undefined,
                endDate: isStart ? undefined : dates[0],
                scheduled_shift_ids: getSelectedScheduledShiftIds(),
              },
            })
          }
        }}
      />
    </Div>
  )
}

const RestButton = () => {
  const { handleUpdateShifts, workersShifts } = useSmartShift()
  const { control } = useFormContext<SmartShiftsFormSchema>()

  const { shiftsSelection } = useWatch({
    control,
  })

  const selectedWorkersShifts: UpdateShiftsParams[] = []

  workersShifts.forEach((workerShifts, workerIndex) => {
    const dates: Date[] = []

    const sortedShifts = workerShifts.shifts.sort((a, b) =>
      dayjs(a.date).diff(dayjs(b.date)),
    )

    sortedShifts.forEach((shift, index) => {
      if (shiftsSelection?.[workerIndex]?.[index]) {
        dates.push(shift.date)
      }
    })

    selectedWorkersShifts.push({
      dates,
      userId: workerShifts.worker.id,
    })
  })

  return (
    <Touchable
      onClick={() => handleUpdateShifts(selectedWorkersShifts, '', true)}
    >
      <Div
        css={{
          borderRight: '1px solid $interface_light_down',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',

          paddingLeft: '$3',
          paddingRight: '$3',

          svg: {
            color: '$interface_dark_down',
            height: 14,
            width: 14,
          },
        }}
      >
        <Battery50Icon />
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            fontWeight: '$bold',
          }}
        >
          Descanso
        </Text>
      </Div>
    </Touchable>
  )
}

const RawInput = styled('input', {
  all: 'unset',

  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  border: 0,
})

const Options = () => {
  const { shifts, handleUpdateShifts, workersShifts } = useSmartShift()
  const { control } = useFormContext<SmartShiftsFormSchema>()

  const [filterName, setFilterName] = useState('')

  const { shiftsSelection } = useWatch({
    control,
  })

  const selectedWorkersShifts: UpdateShiftsParams[] = []

  workersShifts.forEach((workerShifts, workerIndex) => {
    const dates: Date[] = []

    workerShifts.shifts.forEach((shift, index) => {
      if (shiftsSelection?.[workerIndex][index]) {
        dates.push(shift.date)
      }
    })

    selectedWorkersShifts.push({
      dates,
      userId: workerShifts.worker.id,
    })
  })

  const debouncedName = useDebounce(filterName, 300)

  const filteredShiftsByName = useMemo(
    () =>
      shifts?.filter((shift) =>
        shift.shiftName.toLowerCase().includes(debouncedName.toLowerCase()),
      ),
    [debouncedName, shifts],
  )

  return (
    <Div
      css={{
        background: '$interface_light_pure',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '$md',
        // padding: 16,

        position: 'absolute',
        bottom: '125%',
        right: 0,
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          background: '$interface_light_down',

          svg: {
            color: '$interface_dark_down',
            height: 24,
            width: 24,
            marginRight: '$2',
          },

          padding: '$4 $2',
        }}
      >
        <MagnifyingGlassCircleIcon />
        <RawInput
          onChange={(e) => setFilterName(e.target.value)}
          placeholder="Filtrar por nombre"
          css={{
            width: '100%',
          }}
        />
      </Div>
      <Div
        css={{
          padding: '$4',
          maxHeight: 400,
          overflowY: 'auto',
        }}
      >
        {filteredShiftsByName?.map((shift, index) => (
          <Touchable
            key={shift.shiftId}
            onClick={() =>
              handleUpdateShifts(selectedWorkersShifts, shift.shiftId)
            }
          >
            <Flex
              css={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                width: 300,
                background: shift.color,
                textAlign: 'left',
                borderRadius: '$md',
                marginBottom:
                  index === filteredShiftsByName?.length - 1 ? 0 : '$4',
                padding: 16,

                '&:hover': {
                  opacity: 0.8,
                  cursor: 'pointer',
                },
              }}
            >
              <Text variant="paragraph">{shift.shiftName}</Text>
            </Flex>
          </Touchable>
        ))}
      </Div>
    </Div>
  )
}

export const MemoOptions = memo(Options)
