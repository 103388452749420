import { Div } from '@/components'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { ISalaryGroup } from '@/libs/react-query/types/company'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Tag, Text } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

export const usePaymentGroupsColumns = () => {
  const columns = React.useMemo<ColumnDef<ISalaryGroup>[]>(() => {
    const retunedColumns: ColumnDef<ISalaryGroup>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text css={{ whiteSpace: 'nowrap' }}>{row.original.name}</Text>
          </Div>
        ),
      },
      {
        id: 'value',
        header: 'Valor actual del salario',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text>
              {typeDecoder('money-guarani').input(
                `${
                  row.original.values[row.original.values.length - 1].value ||
                  '0'
                }`,
              )}
            </Text>
          </Div>
        ),
      },
      {
        id: 'type',
        header: 'Tipo',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <>
            {row.original.type ? (
              <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
                <Tag>{row.original.type}</Tag>
              </Div>
            ) : (
              <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
                <Tag>SALARIO MÍNIMO</Tag>
              </Div>
            )}
          </>
        ),
      },
      {
        id: 'minimum_wage',
        header: 'Salario mínimo',
        meta: {
          content: 'component',
          thStyles: {
            textAlign: 'center',
          },
        },
        cell: ({ row }) => {
          const hasMinimumSalary = row.original.values.some(
            (v) => v.is_minimum_wage,
          )
          return (
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {hasMinimumSalary ? (
                <Tag variant={'positive'}>
                  <CheckIcon width={16} />
                </Tag>
              ) : (
                <Tag variant={'negative'}>
                  <XMarkIcon width={16} />
                </Tag>
              )}
            </Div>
          )
        },
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
