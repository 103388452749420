import { Div } from '@/components'
import { ChangeCompanyComponent } from './ChangeCompany'
import { CompaniesPunches } from './CompaniesPunches'
import { LoggedUsers } from './LoggedUsers'

export const AdminPage = () => {
  return (
    <Div>
      <Div
        css={{
          display: 'flex',
        }}
      >
        <ChangeCompanyComponent />
      </Div>
      <Div
        css={{
          display: 'flex',
        }}
      >
        <CompaniesPunches />
        <LoggedUsers />
      </Div>
    </Div>
  )
}
