import { createStitches } from '@stitches/react'
import React from 'react'

const { styled, keyframes } = createStitches()

// Define the keyframes for enter and exit animations
const slideInFromRight = keyframes({
  from: {
    transform: 'translateX(100%)',
  },
  to: {
    transform: 'translateX(0%)',
  },
})

const slideOutToRight = keyframes({
  from: {
    transform: 'translateX(0%)',
  },
  to: {
    transform: 'translateX(100%)',
  },
})

const AnimatedComponent = styled('div', {
  position: 'absolute',
  top: '8px',
  right: 0,
  zIndex: 999,
  opacity: 1,

  '&.enter': {
    animation: `${slideInFromRight} 500ms forwards`,
  },

  '&.exit': {
    animation: `${slideOutToRight} 500ms forwards`,
  },
})

type Props = {
  isVisible: boolean
  children: React.ReactNode
}

const AppearingComponent = ({ isVisible, children }: Props) => {
  if (!isVisible) return null

  return (
    <AnimatedComponent className={isVisible ? 'enter' : 'exit'}>
      {children}
    </AnimatedComponent>
  )
}

export default AppearingComponent
