import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { CreateMultiplePunchPayload } from '../types'

const handleCreateMultiplePunches = async (
  props: CreateMultiplePunchPayload,
) => {
  const response = await apiV2.post<any>(
    '/punches/create-multiple-punches',
    props,
  )

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleCreateMultiplePunches = () => {
  const mutation = useCustomMutation(
    ['useHandleCreateMultiplePunches'],
    handleCreateMultiplePunches,
  )

  return mutation
}
