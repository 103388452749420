import { useQuery } from 'react-query'
import { useMe } from '../../useMe'
import { apiV2 } from '@/services/api'
import {
  FetchMonthlyHoursProps,
  IWorkedHourEnvelope,
} from '@/libs/react-query/types'
import dayjs from 'dayjs'

const fetchWorkersHours = async (props: FetchMonthlyHoursProps) => {
  const response = await apiV2.post<IWorkedHourEnvelope[]>(
    '/punches/get-monthly-worked-hours',
    {
      date: props.date,
      user_id: props.user_id,
      autocomplete: props.autocomplete,
    },
  )

  return response.data
}

export const useMonthlyWorkersHours = (props: FetchMonthlyHoursProps) => {
  const { data: user } = useMe()

  const query = useQuery(
    [
      'workers-hours',
      dayjs(props.date).month(),
      dayjs(props.date).year(),
      props.user_id,
      props.autocomplete,
    ],
    () => fetchWorkersHours(props),
    {
      keepPreviousData: true,
      enabled:
        !!props?.date && !!user?.company_id && dayjs(props.date).isValid(),
    },
  )
  return query
}
