import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { TableInput } from '../../styles'
import React from 'react'

export const EditingCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue()
  const [value, setValue] = React.useState(initialValue)

  const inputType = column.columnDef.meta?.inputType

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <TableInput
      value={typeDecoder(inputType ?? 'no-mask').input(value)}
      onChange={(e) => {
        const decodedV = typeDecoder(inputType ?? 'no-mask').output(
          e.target.value,
        )

        setValue(decodedV)
        table.options.meta?.updateData(row.index, column.id, decodedV)
      }}
    />
  )
}
