import { ComponentProps, ElementType } from 'react'
import { Divider } from '@punto-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

import {
  AccordionRoot,
  AccordionItem,
  AccordionHeader,
  AccordionTrigger,
  AccordionContent,
} from './styles'

export interface AccordionItemProps {
  title: string
  content: any
  value: string
}

export interface AccordionRootProps extends ComponentProps<ElementType> {
  as?: ElementType
  accordions: AccordionItemProps[]
  value?: string
  type: 'single' | 'multiple'
}

export function Accordion({ ...props }: AccordionRootProps) {
  return (
    <AccordionRoot {...props}>
      {props?.accordions?.map((accordion, index) => (
        <>
          <AccordionItem value={accordion.value} key={index}>
            <AccordionHeader>
              <AccordionTrigger>{accordion.title}</AccordionTrigger>
              <ChevronDownIcon width={20} height={20} />
            </AccordionHeader>
            <AccordionContent>{accordion.content}</AccordionContent>
          </AccordionItem>

          {props?.accordions?.length - 1 !== index && (
            <Divider orientation={'horizontal'} />
          )}
        </>
      ))}
    </AccordionRoot>
  )
}
