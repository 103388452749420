import { useMemo } from 'react'
import { AguinaldoPlaygroundFields } from '../types'

// antecipate: 'Anticipo',
// aguinaldo_antecipate: 'Anticipo de Aguinaldo',
// loan: 'Prestamo',
// lost: 'Perdidas y Averias',
// purchase: 'Compras',
// judicial: 'Desc. Judiciales',
// excess: 'Pagos en excesso',
// otherDiscount: 'Otros Descuentos',

export const useAguinaldoDiscounts = (isClosed?: boolean) => {
  const configuration: AguinaldoPlaygroundFields = useMemo(() => {
    const form: AguinaldoPlaygroundFields = [
      {
        _type: 'switch',
        label: 'IPS 9%',
        name: 'configuration.ips9',
        disabled: isClosed,
      },
      // {
      //   _type: 'switch',
      //   label: 'IPS 16%',
      //   name: 'configuration.ips16',
      //   disabled: isClosed,
      // },
      {
        _type: 'switch',
        label: 'Anticipo',
        name: 'configuration.antecipate',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Prestamo',
        name: 'configuration.loan',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Perdidas y Averias',
        name: 'configuration.lost',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Compras',
        name: 'configuration.purchase',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Desc. Judiciales',
        name: 'configuration.judicial',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Pagos en excesso',
        name: 'configuration.excess',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Otros Descuentos',
        name: 'configuration.otherDiscount',
        disabled: isClosed,
      },
    ]

    return form
  }, [isClosed])

  return {
    data: configuration,
  }
}
