import { useSideBarContext } from '@/contexts/hooks'
import { disableAllAnimations } from '@/pages/styles/animations'
import { keyframes, styled } from '@punto-ui/react'

import React, { useEffect, useState } from 'react'
import { Navigator, SideBarHeader, UserInfo } from './components'
import { useIsMounted } from '@/hooks'

const SIDEBAR_MAX_WIDTH = 300
const SIDEBAR_MIN_WIDTH = 60

export const SideBar = () => {
  const isAlreadyMounted = useIsMounted()
  const { isOpen } = useSideBarContext()
  const sideBarState = isOpen ? 'open' : 'close'
  const [isFairlyMounted, setIsFairlyMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsFairlyMounted(true)
    }, 300)
  }, [])

  return (
    <SideBarContainer
      state={sideBarState}
      isMounting={!isFairlyMounted || !isAlreadyMounted.current}
    >
      <SideBarHeader />
      <Navigator />
      <UserInfo />
    </SideBarContainer>
  )
}

const sideBarCloseAnimation = keyframes({
  from: {
    maxWidth: SIDEBAR_MAX_WIDTH,
  },
  to: {
    maxWidth: SIDEBAR_MIN_WIDTH,
  },
})

const sideBarOpenAnimation = keyframes({
  from: {
    maxWidth: SIDEBAR_MIN_WIDTH,
  },
  to: {
    maxWidth: SIDEBAR_MAX_WIDTH,
  },
})

const SideBarContainer = styled('aside', {
  background: '$interface_light_pure',
  // background: '$interface_light_up',
  maxWidth: SIDEBAR_MAX_WIDTH,
  width: '100%',
  height: '100vh',

  borderRight: '1px solid',
  borderColor: '$interface_light_down',

  display: 'flex',
  flexDirection: 'column',
  '-webkit-transition': '0.3 ease-in-out',

  variants: {
    state: {
      open: {
        maxWidth: SIDEBAR_MAX_WIDTH,
        animation: `${sideBarOpenAnimation} 0.3s ease-in-out`,
        // minWidth: SIDEBAR_MAX_WIDTH,
      },
      close: {
        animation: `${sideBarCloseAnimation} 0.3s ease-in-out`,
        maxWidth: SIDEBAR_MIN_WIDTH,
      },
    },
    isMounting: {
      true: {
        ...disableAllAnimations(),
      },
    },
  },
})
