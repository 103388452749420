export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const timezoneOptions = [
  'America/Sao_Paulo',
  'America/Asuncion',
  'Europe/Madrid',
]

const timezoneMapping = {
  'America/Asuncion': 'Assunción, Paraguay',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Argentina',
  'America/Santiago': 'Santiago, Chile',
  'America/Montevideo': 'Montevideo, Uruguay',
  'America/Sao_Paulo': 'São Paulo, Brazil',
  'Europe/Madrid': 'Madrid, Spain',
}

export function getFormattedTimezone(timezone: string) {
  const options = { timeZone: timezone, timeZoneName: 'short' } as const
  const formatter = new Intl.DateTimeFormat('en-US', options)
  const parts = formatter.formatToParts(new Date())
  const offsetPart = parts.find((part) => part.type === 'timeZoneName')
  const location = (timezoneMapping as any)[timezone]

  if (offsetPart && location) {
    const offset = offsetPart.value
    return `Huso horario ${offset} , ${location}`
  } else {
    return `Huso horario ${timezone}`
  }
}
