import { Div, Touchable } from '@/components'
import { usePayrollPlaygroundStore } from '../../../store'
import {
  ClockIcon,
  // EllipsisVerticalIcon,
  // PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { usePayrollDay } from './usePayrollDay'
import dayjs from 'dayjs'
import { Text } from '@punto-ui/react'
import { useDayDetail } from './store'

export const DayDrawerHeader = () => {
  const { updateView } = useDayDetail((state) => ({
    updateView: state.updateView,
  }))
  const { data: payrollDay } = usePayrollDay()
  const { handleCloseDrawer } = usePayrollPlaygroundStore((state) => ({
    handleCloseDrawer: state.handleCloseDrawer,
  }))

  const { addTab } = useTabStore((state) => ({
    addTab: state.addTab,
  }))

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: '$2',
        paddingBottom: '$2',
        paddingRight: '$2',
        borderBottom: '1px solid',
        borderColor: '$interface_light_down',

        svg: {
          width: 20,
          height: 20,
          marginLeft: '$2',
          cursor: 'pointer',
        },
      }}
    >
      <Touchable
        onClick={() => {
          updateView('add-movement', {
            date: dayjs(payrollDay?.hours.date).toDate(),
            usersIds: [payrollDay?.schedule.worker.id],
          })
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            svg: {
              width: 12,
              height: 12,
              marginLeft: -2,
              strokeWidth: 3,
            },
          }}
        >
          <Text
            variant={'caption'}
            css={{
              width: 12,
              fontWeight: '$bold',
            }}
          >
            M
          </Text>
          <PlusIcon />
        </Div>
      </Touchable>
      <ClockIcon
        onClick={() => {
          addTab('payroll', [
            {
              id: payrollDay?.schedule.worker.id || '',
              type: 'user-detail',
              label: `Detalles ${payrollDay?.schedule.worker.name}`,
              metadata: {
                date: dayjs(payrollDay?.hours.date).startOf('month').toDate(),
              },
            },
          ])
        }}
      />
      {/* <PencilSquareIcon />
      <EllipsisVerticalIcon /> */}
      <XMarkIcon onClick={handleCloseDrawer} />
    </Div>
  )
}
