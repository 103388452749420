import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { ICreateAguinaldoDTO } from '@/libs/react-query/types/aguinaldo'
dayjs.extend(utc)

const handleCreateAguinaldo = async (payload: ICreateAguinaldoDTO) => {
  await apiV2.post<void>('/payroll/create-aguinaldo', payload)
  queryClient.invalidateQueries('aguinaldos')
}

export const useHandleCreateAguinaldo = () => {
  const mutation = useCustomMutation(
    ['useHandleCreateAguinaldo'],
    handleCreateAguinaldo,
  )

  return mutation
}
