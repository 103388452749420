import { useMemo } from 'react'
import { AddShiftData, AddShiftFields } from '../../../forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { Div } from '@/components'

export const useShiftGeneralInfo = () => {
  const { control } = useFormContext<AddShiftData>()
  const selectedTimezone = useWatch({
    control,
    name: 'config.timezone',
  })

  const formData = useMemo(() => {
    const timezoneOptions: { label: string; value: string }[] = [
      {
        label: 'Brasil, São Paulo',
        value: 'America/Sao_Paulo',
      },
      {
        label: 'Paraguay, Asunción',
        value: 'America/Asuncion',
      },
      {
        label: 'España, Madrid',
        value: 'Europe/Madrid',
      },
    ]

    const defaultOption = timezoneOptions.find(
      (tz) => tz.value === selectedTimezone,
    ) || {
      label: 'Seleccionar huso horario',
      value: '',
    }

    const data: AddShiftFields = [
      {
        _type: 'input',
        label: 'Código',
        name: 'code',
        placeholder: 'Código del Turno',
        inputType: 'name',
      },
      {
        _type: 'input',
        label: 'Nombre',
        name: 'name',
        placeholder: 'Nombre del Turno',
        inputType: 'name',
      },
      {
        _type: 'dropdown',
        label: 'Huso horario',
        name: 'config.timezone',
        defaultOption,
        options: timezoneOptions,
      },
      {
        _type: 'custom',
        component: <Div css={{ width: 'calc((100% / 4) - 5%)' }} />,
      },
      {
        _type: 'switch',
        label: 'Banco de intervalos',
        name: 'config.should_consider_interval_credits',
      },
      {
        _type: 'switch',
        label: 'Ignorar intervalos incompletos',
        name: 'config.ignore_incomplete_punches',
      },
    ]
    return data
  }, [selectedTimezone])

  return {
    data: formData,
  }
}
