import { Avatar, Div } from '@/components'
import { useDocumentsFiles } from '@/libs/react-query/hooks/useDocumentsFiles'
import {
  Audit,
  AUDIT_TYPES,
  AUDIT_VALUE_TYPES,
  AuditData,
} from '@/libs/react-query/types/audits'
import { formatMinutes, getUtcDateFromString } from '@/utils/date'
import { formatNumberWithDots } from '@/utils/numbers/currency'
import { EyeIcon } from '@heroicons/react/24/outline'
import { Text } from '@punto-ui/react'
import { CSS } from '@stitches/react'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'

const getAuditDataLabel = (data: AuditData, getNew = true) => {
  if (data.value_type === AUDIT_VALUE_TYPES.HOUR) {
    return getNew
      ? dayjs(data.new_value).format('HH:mm')
      : dayjs(data.old_value).format('HH:mm')
  }

  if (data.value_type === AUDIT_VALUE_TYPES.DATE) {
    // new value = YYYY-MM-DDT00:00:00.000Z
    return getNew
      ? data.new_value
        ? dayjs(getUtcDateFromString(data.new_value)).format('DD/MM/YYYY')
        : ''
      : data.old_value
      ? dayjs(getUtcDateFromString(data.old_value)).format('DD/MM/YYYY')
      : ''
  }

  if (data.value_type === AUDIT_VALUE_TYPES.DATETIME) {
    return getNew
      ? dayjs(data.new_value).format('DD/MM/YYYY HH:mm')
      : dayjs(data.old_value).format('DD/MM/YYYY HH:mm')
  }

  if (data.value_type === AUDIT_VALUE_TYPES.PURE_HOUR) {
    return getNew
      ? data.new_value && !isNaN(+data.new_value)
        ? formatMinutes(+data.new_value * 60)
        : 'Valor calculado'
      : data.old_value && !isNaN(+data.old_value)
      ? formatMinutes(+data.old_value * 60)
      : 'Valor calculado'
  }

  if (data.value_type === AUDIT_VALUE_TYPES.GENERIC_MONEY) {
    return getNew
      ? data.new_value && !isNaN(+data.new_value)
        ? formatNumberWithDots(+data.new_value)
        : 'Valor calculado'
      : data.old_value && !isNaN(+data.old_value)
      ? formatNumberWithDots(+data.old_value)
      : 'Valor calculado'
  }

  return getNew ? data.new_value_label : data.old_value_label
}

export const AuditItemData = ({
  audit,
  expanded,
}: {
  expanded: boolean
  audit: Audit
}) => {
  // const auditDataGrouped = Object.groupBy(
  //   audit.data,
  //   (auditData) => auditData.grouping_concept || 'Conceptos',
  // )
  const auditDataGrouped = audit.data.reduce((acc, auditData) => {
    const key = auditData.grouping_concept || 'Conceptos'
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(auditData)
    return acc
  }, {} as Record<string, AuditData[]>)

  return (
    <Div
      css={{
        minWidth: expanded ? 500 : 400,
        maxHeight: 600,
        overflowY: 'auto',

        '> div + div': {
          marginTop: '$2',
        },
      }}
    >
      {Object.entries(auditDataGrouped).map(([key, value]) => (
        <Div
          key={key}
          css={{
            paddingRight: '$2',
          }}
        >
          <Div
            css={{
              display: 'flex',
              gap: '$2',
              alignItems: 'center',
              marginBottom: '$2',
            }}
          >
            <Text
              variant="caption"
              css={{
                fontWeight: '$bold',
              }}
            >
              {key}
            </Text>
            <Div
              css={{
                height: 1,
                flex: 1,
                background: '$interface_light_down',
              }}
            />
          </Div>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$1',
            }}
          >
            {audit.type !== AUDIT_TYPES.SHIFT && (
              <Div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    width: audit.is_creation ? '60%' : '40%',
                    textDecoration: 'underline',
                    // fontWeight: '$bold',
                  }}
                >
                  Concepto
                </Text>
                {!audit.is_creation && (
                  <Text
                    css={{
                      textDecoration: 'underline',
                      textAlign: 'center',
                      width: '30%',
                      // fontWeight: '$bold',
                    }}
                    variant="caption"
                  >
                    Antes
                  </Text>
                )}
                <Text
                  variant="caption"
                  css={{
                    flex: 1,
                    // fontWeight: '$bold',
                    textDecoration: 'underline',
                    textAlign: 'right',
                  }}
                >
                  {audit.is_creation ? 'Valor' : 'Despues'}
                </Text>
              </Div>
            )}
            <Div>
              {value?.map((auditData) => (
                <Div
                  key={auditData.id}
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    variant="caption"
                    css={{
                      width: audit.is_creation ? '60%' : '40%',
                    }}
                  >
                    {auditData.field_label}
                  </Text>
                  {!audit.is_creation && (
                    <AuditDataLabel
                      auditData={auditData}
                      isNew={false}
                      css={{
                        textAlign: 'center',
                        width: '30%',
                      }}
                    />
                    // <Text
                    //   variant="caption"
                    //   css={{
                    //     width: '30%',
                    //     textAlign: 'center',
                    //   }}
                    // >
                    //   {getAuditDataLabel(auditData, false)}
                    // </Text>
                  )}
                  <AuditDataLabel
                    auditData={auditData}
                    isNew={true}
                    css={{
                      flex: 1,
                      textAlign: 'right',
                    }}
                  />
                  {/* <Text
                    variant="caption"
                    css={{
                      flex: 1,
                      textAlign: 'right',
                    }}
                  >
                    {getAuditDataLabel(auditData, true)}
                  </Text> */}
                </Div>
              ))}
            </Div>
          </Div>
        </Div>
      ))}
    </Div>
  )
}

const AuditDataLabel = ({
  auditData,
  isNew,
  css,
}: {
  auditData: AuditData
  isNew: boolean
  css: CSS
}) => {
  if (auditData.value_type === AUDIT_VALUE_TYPES.DOCUMENT) {
    return (
      <AuditDataDocument
        css={css}
        center={css.textAlign === 'center'}
        id={isNew ? auditData.new_value : auditData.old_value}
      />
    )
  }

  return (
    <Text variant="caption" css={css}>
      {getAuditDataLabel(auditData, isNew)}
    </Text>
  )
}

const AuditDataDocument = ({
  id,
  center,
  css,
}: {
  center: boolean
  css: CSS
  id: string | null
}) => {
  const { data: file, isLoading: isLoadingDoc } = useDocumentsFiles(
    id ? [id] : [],
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = React.useState({ top: 0, left: 0 })
  const [isMouseOver, setIsMouseOver] = React.useState(false)

  useEffect(() => {
    if (isMouseOver && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()

      if (typeof window !== 'undefined') {
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        })
      }
    }
  }, [isMouseOver])

  if (!id) {
    return null
  }

  return (
    <Div
      css={{
        flex: 1,

        display: 'flex',
        gap: '$2',
        alignItems: 'center',
        justifyContent: center ? 'center' : 'flex-end',

        svg: {
          height: 16,
          width: 16,
          color: '$interface_dark_down',
        },

        ...css,
      }}
    >
      <Div
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        ref={containerRef}
        onClick={() => {
          if (!isLoadingDoc && file?.[0] && file[0].url) {
            window.open(file[0].url)
          }
        }}
        css={{
          cursor: isLoadingDoc ? 'not-allowed' : 'pointer',
          display: 'flex',
          alignItems: 'center',
          background: '$interface_light_up',
          padding: '$1',
          height: 18,
          borderRadius: '$sm',
          gap: '$1',
          opacity: isLoadingDoc ? 0.5 : 1,
        }}
      >
        <EyeIcon />
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
          }}
        >
          Ver
        </Text>
      </Div>

      {isMouseOver && position.top && position.left && (
        <Div
          css={{
            position: 'absolute',
            zIndex: 10,
            top: `${position.top - 40}px`,
            left: `${position.left - 60}px`,
          }}
        >
          <Avatar
            src={file?.[0]?.url || ''}
            alt={'Documiento'}
            css={{
              opacity: isMouseOver ? 1 : 0,
              transition: 'opacity 0.3s',
            }}
          />
        </Div>
      )}
    </Div>
  )
}
