import { Div } from '@/components/Div'
import { IInputTag, SmartColumnType } from '@/components/SmartTable/types'
import { Tag } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ROW_HEIGHT } from '.'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { GrabIcon } from '@/assets/icons/GrabIcon'
import { theme } from '@/styles'
import React, { useEffect, useRef } from 'react'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

interface InputTagProps {
  fieldValueName: string
  callback?: (index: number, id: string, name: string, data: any) => void
  rowIndex: number
  column: SmartColumnType
}

export const InputDraggableTag = ({
  fieldValueName,
  callback,
  rowIndex,
  column,
}: InputTagProps) => {
  const { control, getValues } = useFormContext()
  const tags: IInputTag[] = useWatch({
    control,
    name: fieldValueName,
    defaultValue: getValues(fieldValueName),
  })

  const dropRef = useRef<HTMLDivElement | null>(null)

  const [isOver, setIsOver] = React.useState(false)

  useEffect(() => {
    if (!dropRef.current) return

    return dropTargetForElements({
      element: dropRef.current,
      onDrop: ({ source }) => {
        const draggedTag = source.data.option as any
        column?.onDropTag?.(rowIndex, draggedTag.id, draggedTag)
      },
      onDragEnter: () => {
        setIsOver(true)
      },
      onDragLeave: () => {
        setIsOver(false)
      },
    })
  }, [])

  return (
    <>
      <Div
        ref={dropRef}
        css={{
          position: 'relative',
          overflow: 'scroll',

          border: isOver ? '1px solid $brand_primary_pure' : undefined,

          minWidth: column.width ? column.width : 72,
          maxWidth: column.width ?? 200,
          width: column.width ?? undefined,

          height: ROW_HEIGHT,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
        }}
      >
        {Array.isArray(tags) && tags.length === 1 && (
          <DraggableTag
            callback={callback}
            rowIndex={rowIndex}
            column={column}
            tag={tags[0]}
          />
        )}
        {Array.isArray(tags) &&
          tags?.length > 1 &&
          tags?.map((tag) => (
            <DraggableTag
              rowIndex={rowIndex}
              column={column}
              tag={tag}
              callback={callback}
              key={tag.id}
            />
          ))}
      </Div>
    </>
  )
}

export const DraggableTag = ({
  tag,
  rowIndex,
  column,
  callback,
}: {
  tag: IInputTag
  column: SmartColumnType
  callback?: (index: number, id: string, name: string, data: any) => void
  rowIndex: number
}) => {
  return (
    <Tag
      key={tag.id}
      variant={tag.variant || 'neutral'}
      onClick={() => {
        callback?.(rowIndex, tag.id, tag.name, tag.data)
      }}
      css={{
        fontSize: '$xxs',
        padding: '$1',
        cursor: callback ? 'pointer' : 'undefined',

        '&:not(:last-child)': {
          marginRight: '$1',
        },
      }}
    >
      <Div
        css={{
          svg: {
            height: 12,
            width: 12,
            marginRight: '$2',
          },

          minWidth: 12,
          minHeight: 12,
        }}
        onClick={() => {
          column?.onCloseTag?.(rowIndex, tag.id)
        }}
      >
        <GrabIcon
          fill={
            tag.variant === 'negative'
              ? theme.colors.status_danger_deep.value
              : tag.variant === 'neutral'
              ? theme.colors.brand_primary_down.value
              : tag.variant === 'positive'
              ? theme.colors.status_success_deep.value
              : theme.colors.status_warning_deep.value
          }
        />
      </Div>
      {tag.name}
      <Div
        css={{
          svg: {
            height: 12,
            width: 12,
            marginLeft: '$2',
            cursor: 'pointer',
          },

          minWidth: 12,
          minHeight: 12,
        }}
      >
        <XMarkIcon
          onClick={() => {
            column?.onCloseTag?.(rowIndex, tag.id)
          }}
        />
      </Div>
    </Tag>
  )
}
