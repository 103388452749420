import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'

export const ShiftStep = () => {
  const { data: shifts } = useSmartShifts()
  const methods = useFormContext<ExportPdfSchemaType>()

  const shiftId = useWatch({
    control: methods.control,
    name: 'shiftId',
  })

  const selectedShift = shifts?.find((s) => s.shiftId === shiftId)

  return (
    <Div>
      <DropdownV2
        value={shiftId || ''}
        defaultValue={shiftId || ''}
        onChangeValue={(value) => {
          methods.setValue('shiftId', value.value)
        }}
        defaultOption={{
          label: selectedShift?.shiftName || 'Selecciona un turno',
          value: selectedShift?.shiftId || '',
        }}
        options={
          shifts?.map((s) => {
            return {
              label: s.shiftName,
              value: s.shiftId,
            }
          }) || []
        }
      />
    </Div>
  )
}
