import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import {
  PayrollDayDetailed,
  UsePayrollDayDetailsPayload,
} from '../../types/payroll'

const fetchPayrollDayDetail = async (
  payrollId: string,
  userId: string,
  date: string,
) => {
  const response = await apiV2.post<PayrollDayDetailed>(
    `/payroll/get-user-day-details`,
    {
      payrollId,
      userId,
      date,
    },
  )

  return response.data
}

export const usePayrollDayDetail = (data: UsePayrollDayDetailsPayload) => {
  return useQuery(
    ['payroll-details', data.payrollId, data.userId, data.date],
    () => fetchPayrollDayDetail(data.payrollId!, data.userId, data.date),
    {
      enabled: !!data.payrollId && !!data.userId && !!data.date,
    },
  )
}
