import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const ReportPreviewContent = z.object({
  id: z.string(),
  userId: z.string(),
})

export type IReportBuilderTypes = 'single' | 'collective' | 'totals'
export type IReportBuilderValueTypes = 'string' | 'number' | 'date' | 'boolean'
export type IReportBuilderRowTypes =
  | 'workers'
  | 'punches'
  | 'shifts'
  | 'movements'
  | 'payments'
  | 'vacations'
  | 'period'
  | 'payrolls'
  | 'sectors'
  | 'permissions'
  | 'day'
  | 'month'
  | 'year'
  | 'entities'
  | 'sueldos'

export type IReportBuilderColumnTypes =
  | 'permissions'
  | 'workers'
  | 'punches'
  | 'shifts'
  | 'movements'
  | 'payments'
  | 'vacations'
  | 'period'
  | 'payrolls'
  | 'sectors'
  | 'sueldos'

const ReportBuilderRow = z.object({
  row_type: z.string(),
  details: z
    .object({
      label: z.string(),
      detail_type: z.string().optional(),
      value: z.string(),
      selected: z.boolean(),
    })
    .array(),
  selected: z.boolean(),
  disabled: z.boolean(),
})

const ReportBuilderColumn = z.object({
  label: z.string(),
  column_type: z.string(),
  detail_type: z.string().optional(),
  valueType: z.string(),
  value: z.string(),
  selected: z.boolean(),
  disabled: z.boolean(),
  isArray: z.boolean().optional(),
  getRandomPlaceholder: z.function().optional(),
})

export const ReportBuilderFilter = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  valueType: z.string(),
  condition: z.string(),

  hasValue: z.boolean(),

  value: z.string().or(z.number()).optional(),
  date: z.string().optional(),

  options: z
    .object({
      label: z.string(),
      value: z.string(),
      requiredInput: z.string().nullable().optional(),
    })
    .array(),
})

export const ReportBuilderSchema = z.object({
  id: z.string(),
  identifier_id: z.string(),

  configurations: z.object({
    name: z.string(),
    description: z.string(),
    isFavorite: z.boolean(),
    reportType: z.string(),
  }),

  currentStep: z.number(),

  rows: ReportBuilderRow.array(),
  rowsOptions: z
    .object({
      label: z.string(),
      value: z.string(),
      details: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .array(),
    })
    .array(),
  selectedRowDetailsArray: z.boolean().array(),
  selectedRowDetails: z.string(),
  selectedRow: z.string(),
  columns: z.record(z.string(), ReportBuilderColumn.array()),
  filters: ReportBuilderFilter.array(),
  openColumns: z.record(z.string(), z.boolean()),

  loadedTemplate: z.boolean().optional(),
})

export interface IReportBuilderColumnType {
  type: IReportBuilderColumnTypes
  value: string
  label: string
  valueType: IReportBuilderValueTypes
  isArray?: boolean
  subgroup: string
  getRandomPlaceholder?: () => string
}

export type ReportBuilderSchemaType = z.infer<typeof ReportBuilderSchema>
export type ReportPreviewContentType = z.infer<typeof ReportPreviewContent>
export type ReportBuilderRowType = z.infer<typeof ReportBuilderRow>
export type ReportBuilderFilterType = z.infer<typeof ReportBuilderFilter>
export type ReportBuilderColumnType = z.infer<typeof ReportBuilderColumn>
export type ReportBuilderFields = Array<
  ControlledFieldProps<ReportBuilderSchemaType>
>
