import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleReactivateUser = async (data: { user_id: string }) => {
  await apiV2.post<void>(`/permission/reactivate-user`, data)
  queryClient.invalidateQueries()
}

export const useHandleReactivateUser = () => {
  const mutation = useCustomMutation(
    ['useHandleReactivateUser'],
    handleReactivateUser,
  )

  return mutation
}
