import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
dayjs.extend(utc)

const handleDeleteInvoice = async (id: string) => {
  await apiV2.post('/admin/delete-invoice', {
    invoice_id: id,
  })

  queryClient.invalidateQueries('companies')
}

export const useHandleDeleteInvoice = () => {
  const mutation = useCustomMutation(
    ['useHandleDeleteInvoice'],
    handleDeleteInvoice,
  )

  return mutation
}
