import { Div } from '@/components/Div'
import { findColumnByName } from '@/components/SmartTable/columns'
import { SmartColumnType } from '@/components/SmartTable/types'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'
import {
  ArrowPathIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Text } from '@punto-ui/react'
import React, { useMemo } from 'react'

export const AddRowDropdown = ({
  hiddenColumns,
  columns,
  setHiddenColumns,
  setColumnsOrder,
  setLeftFixedColumns,
  setRightFixedColumns,
}: {
  hiddenColumns: string[]
  columns: SmartColumnType[]
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>
  setColumnsOrder: React.Dispatch<React.SetStateAction<string[]>>
  setLeftFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
  setRightFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const [isMouseInside, setIsMouseInside] = React.useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const buttonMenuRef = React.useRef<HTMLDivElement>(null)

  useOutsideAlerters([dropdownRef, buttonMenuRef], () => {
    setIsDropdownOpen(false)
  })

  const rowsOptions = useMemo(() => {
    const result: Array<{
      label: string
      value: string
    }> = []

    hiddenColumns.forEach((hiddenColumn) => {
      const isHiddenColumnArray = hiddenColumn.includes('.')
      const columnLabel = isHiddenColumnArray
        ? hiddenColumn.split('.')[0]
        : hiddenColumn
      const maybeLabelNumber = isHiddenColumnArray
        ? Number.isNaN(hiddenColumn.split('.')[1])
          ? 0
          : +hiddenColumn.split('.')[1]
        : null

      const column = findColumnByName(columns, columnLabel)

      if (!column) {
        return
      }

      const columnHeader =
        isHiddenColumnArray &&
        column.headers?.length &&
        maybeLabelNumber !== null
          ? column.headers[+maybeLabelNumber]
          : typeof column.header === 'string'
          ? `${column.header} ${
              isHiddenColumnArray && maybeLabelNumber !== null
                ? +maybeLabelNumber + 1
                : ''
            }`
          : ''

      result.push({
        label: columnHeader,
        value: hiddenColumn || '',
      })
    })

    return result
  }, [columns, hiddenColumns])

  return (
    <>
      <Div
        css={{
          position: 'absolute',
          top: 0,
          left: 32,
          height: 38,
          width: 72,
          zIndex: 10,
        }}
        onMouseEnter={() => {
          setIsMouseInside(true)
        }}
        onMouseLeave={() => {
          setIsMouseInside(false)
        }}
      />
      {isMouseInside && (
        <Div
          onMouseEnter={() => {
            setIsMouseInside(true)
          }}
          css={{
            position: 'absolute',
            top: 7,
            left: 64,
            zIndex: 11,
          }}
        >
          <Div
            onClick={() => {
              setColumnsOrder([])
              setLeftFixedColumns([])
              setRightFixedColumns([])
            }}
            css={{
              cursor: 'pointer',
              height: 20,
              width: 20,
              borderRadius: 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '> svg': {
                height: 16,
                width: 16,
                color: '$interface_dark_deep',
              },

              background: '$interface_light_up',
            }}
          >
            <ArrowPathIcon />
          </Div>
        </Div>
      )}
      {!!hiddenColumns?.length && (
        <Div
          onMouseEnter={() => {
            setIsMouseInside(true)
          }}
          css={{
            position: 'absolute',
            top: 7,
            left: 40,
            zIndex: 11,
          }}
        >
          <Div
            ref={dropdownRef}
            onClick={() => {
              setIsDropdownOpen((prev) => !prev)
            }}
            css={{
              cursor: 'pointer',
              height: 20,
              width: 20,
              borderRadius: 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '> svg': {
                height: 16,
                width: 16,
                color: '$interface_dark_deep',
              },

              background: '$interface_light_up',
            }}
          >
            <PlusCircleIcon />
          </Div>
        </Div>
      )}
      {!!hiddenColumns?.length && isDropdownOpen && (
        <Div
          ref={buttonMenuRef}
          css={{
            position: 'absolute',
            top: 40,
            left: 8,
            zIndex: 10,
            background: '$interface_light_pure',
            padding: '$2',
            paddingRight: '$8',
            paddingLeft: '$2',
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'left',
              paddingBottom: '$2',
            }}
          >
            Agregar columnas:
          </Text>
          {rowsOptions.map((option) => {
            return (
              <Div
                onClick={() =>
                  setHiddenColumns((prev) => {
                    return prev.filter((column) => column !== option.value)
                  })
                }
                key={option.value}
                css={{
                  display: 'flex',
                  alignItems: 'center',

                  padding: '$1 $2',
                  borderRadius: 4,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '$interface_light_up',
                  },

                  '> svg': {
                    height: 16,
                    width: 16,
                    color: '$interface_dark_deep',
                    marginRight: '$2',
                  },
                }}
              >
                <PlusIcon strokeWidth={2} />
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_deep',
                  }}
                >
                  {option.label}
                </Text>
              </Div>
            )
          })}
        </Div>
      )}
    </>
  )
}
