import {
  IReportBuilderRowTypes,
  IReportBuilderValueTypes,
} from '@/pages/reportes-v2/ReportBuilder/type'
import { IDefaultApiPagination } from '../pagination'
import { IReceiptBuilderTypes } from '@/pages/reportes-v2/ReceiptBuilder/type'

export type IGeneratePunchReportDTO = {
  usersIds: string[]
  period_start: string
  period_end: string

  options: {
    withDiscountHours: boolean
    withOrdinaryHours: boolean
    withExtraHours: boolean
    withHolidayHours: boolean
  }
  filters: {
    allDays: boolean
    withDiscountHours: boolean
    withOrdinaryHours: boolean
    withExtraHours: boolean
    withHolidayHours: boolean
  }
}

export type ICreateTemplateDTO = {
  name: string
  description: string
  type: string
  favorite: boolean
  row: string
  rowDetails: string
  rowSubDetails: string
  columns: {
    type: string
    value: string
  }[]
  filters: {
    name: string
    type: string
    valueType: string
    condition: string

    value?: string
    dates?: string
  }[]
}

export type ICreatePdfDTO = {
  name: string
  description: string

  direction: string
  size: string

  favorite: boolean
  has_two_per_page: boolean

  layouts: {
    order: number
    numberOfColumns: number

    content: {
      type: string
      width?: number
      order?: number

      height?: number

      value?: string
      color?: string
      is_bold?: boolean
      is_italic?: boolean
      is_underline?: boolean
      text_alignment?: string
      font_size?: number

      bordered?: boolean
      borderColor?: string
    }[]
  }[]
}

export type ICreateReceiptDTO = {
  name: string
  title: string
  description: string
  type: string
  favorite: boolean
  headerType: string
  headerTitle?: string
  footerType: string
  footerCity?: string
  concepts: {
    label: string
    values: string[]
  }[]
}

export type IUpdateTemplateDTO = {
  id: string
  name: string
  description: string
  type: string
  favorite: boolean
  row: string
  rowDetails: string
  rowSubDetails: string
  columns: {
    type: string
    value: string
  }[]
  filters: {
    name: string
    type: string
    valueType: string
    condition: string

    value?: string
    date?: string
  }[]
}

export type IUpdatePdfDTO = {
  id: string
} & ICreatePdfDTO

export interface IListReportsParams {
  page?: number
  perPage?: number
  cellsIds?: string[]
  name?: string
}

export interface IListTemplatesParams {
  page?: number
  perPage?: number
  name?: string
  favorite?: boolean
  types?: IReceiptBuilderTypes[]
}

export type ReportUser = {
  id: string
  user_id: string
  identifier_id: string
  exported_report_id: string
  created_at: Date
  updated_at: Date
  updated_by: string | null
}

export type IReport = {
  id: string
  status: string
  type: string
  options: string
  identifier_id: string
  url: string | null
  created_at: Date
  updated_at: Date
  updated_by: string | null

  users: ReportUser[]
}

export interface IExportTemplateFilterDTO {
  name: string
  type: string
  valueType: string
  condition: string

  value?: string | number
  dates?: string
}

export type IExportTemplateDTO = {
  users_ids: string[]
  template_id: string
  payrollIds?: string[]
  aguinaldoIds?: string[]

  date?: {
    day?: string
    month?: string
    year?: string

    startDate?: string
    endDate?: string
  }

  filters: IExportTemplateFilterDTO[]
}

export type IExportPdfDTO = {
  pdf_template_id: string
  users_ids: string[]

  reportId?: string

  payrollId?: string
  movementId?: string
  paymentId?: string
  vacationId?: string
  liquidationId?: string

  shiftDate?: string
  shiftId?: string

  templates?: {
    template_id?: string
    data?: any
  }[]

  variables?: {
    variable?: string
    value?: string
  }[]
}

export type IReportType =
  | 'MONTHLY_USER_REPORT'
  | 'PAYROLL_REPORT'
  | 'SALARY_RECEIPT_REPORT'
  | 'DATAPAR_USER_REPORT'
  | 'TOTAL_HOURS_REPORT'
  | 'TOTAL_HOURS_MONTHLY_REPORT'
  | 'WORKERS_REPORT'
  | 'LIQUIDATION_REPORT'

export type IReportLabel =
  | 'Reporte individual de marcaciones'
  | 'Reporte de planilla de salários'
  | 'Reporte de recibos de salarios'
  | 'Reporte de Colaboradores'
  | 'Reporte de marcaciones para Datapar'
  | 'Planilla de totales de horas (diário)'
  | 'Planilla de totales de horas (mensual)'
  | 'Reporte de Liquidación'

export const reportTypesDictionary: Record<IReportType, IReportLabel> = {
  MONTHLY_USER_REPORT: 'Reporte individual de marcaciones',
  PAYROLL_REPORT: 'Reporte de planilla de salários',
  WORKERS_REPORT: 'Reporte de Colaboradores',
  SALARY_RECEIPT_REPORT: 'Reporte de recibos de salarios',
  DATAPAR_USER_REPORT: 'Reporte de marcaciones para Datapar',
  TOTAL_HOURS_REPORT: 'Planilla de totales de horas (diário)',
  TOTAL_HOURS_MONTHLY_REPORT: 'Planilla de totales de horas (mensual)',
  LIQUIDATION_REPORT: 'Reporte de Liquidación',
}

export const defaultReportTypesDictionary: Record<string, string> = {
  'default:general-workers-status-summary':
    'Resumen anual general de personas ocupadas, empleados',
  'default:yearly-sueldos': 'Planilla anual de Sueldos y Jornales',
  'default:monthly-sueldos': 'Libro de Sueldos y Jornales - Mensual',
  'default:yearly-workers-ips': 'Planilla anual de empleados y obreros',
  'default:monthly-workers-ips': 'Libro mensual de empleados y obreros',
  'default:monthly-vacation-ips': 'Libro mensual de vacaciones',
}

export const reportTypes: IReportType[] = [
  'MONTHLY_USER_REPORT',
  'PAYROLL_REPORT',
  'SALARY_RECEIPT_REPORT',
  'DATAPAR_USER_REPORT',
  'WORKERS_REPORT',
  'TOTAL_HOURS_REPORT',
  'TOTAL_HOURS_MONTHLY_REPORT',
]

export type IReportResponse = IDefaultApiPagination<IReport>

export interface IRequestReport {
  usersIds: string[]
  type: IReportType
  data: any
}

export interface IRequestReceiptExportDTO {
  receipt_id: string
  users_ids: string[]
  payrollId?: string
  paymentId?: string
  vacationId?: string
  liquidationId?: string
}

export interface ITemplateColumn {
  name: string
  type: IReportBuilderRowTypes
  valueType: IReportBuilderValueTypes
  id: string
}

export interface ITemplateFilter {
  name: string
  type: string
  valueType: string

  condition: string
  value?: string
  date?: string
}

export interface ITemplate {
  columns: ITemplateColumn[]
  filters: ITemplateFilter[]
  created_at: Date
  updated_at: Date
  favorite: boolean
  description: string
  id: string
  name: string
  row: string
  rowDetails: string
  rowSubDetails: string
  type: string
  identifier_id: string
}

interface IPdfLayoutContent {
  id: string
  type: string

  width?: number
  height?: number

  order?: number

  value?: string
  color?: string
  is_bold?: boolean
  is_italic?: boolean
  is_underline?: boolean
  text_alignment?: string

  font_size?: number

  bordered?: boolean
  borderColor?: string
}

interface IPdfLayout {
  id: string
  order: number
  number_of_columns: number
  content: IPdfLayoutContent[]
}

export interface IPdfTemplate {
  identifier_id: string

  id: string
  description: string
  name: string
  favorite: boolean
  has_two_per_page: boolean

  direction: string
  size: string

  layouts: IPdfLayout[]

  updated_at: string
}

export interface IReceipt {
  id: string
  identifier_id: string | null
  name: string
  title: string
  description: string
  type: string
  headerType: string
  headerTitle?: string
  favorite: boolean
  footerType: string
  footerCity?: string

  concepts: Array<{
    label: string
    values: Array<{
      value: string
    }>
  }>

  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export type IPdfTemplateResponse = IDefaultApiPagination<IPdfTemplate>
export type ITemplateResponse = IDefaultApiPagination<ITemplate>
export type IReceiptResponse = IDefaultApiPagination<IReceipt>
