import { useQuery } from 'react-query'
import { IUserDocumentStructure } from '../../types/documents'
import { apiV2 } from '@/services/api'

const fetchUserDocumentsStructure = async (userId: string) => {
  const response = await apiV2.post<IUserDocumentStructure>(
    '/documents/get-user-documents-structure',
    {
      user_id: userId,
    },
  )

  return response.data
}

export const useUserDocumentsStructure = (userId: string) => {
  const query = useQuery(
    ['user-documents-structure', userId],
    async () => await fetchUserDocumentsStructure(userId),
    {
      enabled: !!userId,
    },
  )

  return query
}
