import { Menu } from '@punto-ui/react'
import { useGroupBuilder } from '../../../context'
import { findGroupByIdInsideGroupData } from '../../../utils'
import { GroupData } from '../../../types'

interface DropdownMenuProps {
  id: string
}

export const GroupDropdownMenu = ({ id }: DropdownMenuProps) => {
  const { setEditingRow, groups } = useGroupBuilder()

  const node = findGroupByIdInsideGroupData(groups, id) as GroupData

  return (
    <Menu
      items={[
        {
          id: '1',
          label: 'Editar',
          onClick: () => {
            setEditingRow({
              id,
              name: node.name,
              parentName: '',
              _type: 'editing',
            })
          },
        },
        // {
        //   id: '6',
        //   label: 'Excluir',
        //   onClick: () => removeGroupFromGroups(id),
        //   disabled: node.name === 'Matriz',
        // },
      ]}
    />
  )
}
