import { Div } from '@/components'
import { ButtonsContainer, Form } from '@/components/Drawers/Form/styles'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import useToast from '@/hooks/useToast'
import { useHandleRequestReport } from '@/libs/react-query/mutations/reports/useHandleRequestReport'
import dayjs from 'dayjs'
import { Button } from '@punto-ui/react'
import {
  ControlledCalendarInput,
  ControlledFullWorkerSearchBar,
} from '@/components/Forms'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import {
  ArrowDownOnSquareIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'

const dataparIntegrationForm = z.object({
  users_ids: z.array(z.string()),
  dates: z.date().array(),
})

export type DataparIntegrationFormType = z.infer<typeof dataparIntegrationForm>
export type ExportDrawerFormFields = Array<
  ControlledFieldProps<DataparIntegrationFormType>
>

export const DataparReportForm = ({
  handleCloseDrawer,
}: {
  handleCloseDrawer: () => void
}) => {
  const toast = useToast()

  const methods = useForm<DataparIntegrationFormType>({
    resolver: zodResolver(dataparIntegrationForm),
    defaultValues: {
      users_ids: [],
      dates: [
        dayjs().startOf('month').toDate(),
        dayjs().endOf('month').toDate(),
      ],
    },
  })

  const {
    mutateAsync: handleRequestReport,
    isLoading: isLoadingRequestReport,
  } = useHandleRequestReport()

  const [usersIds, dates] = useWatch({
    control: methods.control,
    name: ['users_ids', 'dates'],
  })

  const submitExport = async (data: DataparIntegrationFormType) => {
    try {
      const formattedData = {
        ...data,
        dates: data.dates.map((d) => dayjs(d).format('YYYY-MM-DD')),
      }

      await handleRequestReport({
        data: {
          period_end: dayjs(formattedData.dates[1]).format('YYYY-MM-DD'),
          period_start: dayjs(formattedData.dates[0]).format('YYYY-MM-DD'),
        },
        type: 'DATAPAR_USER_REPORT',
        usersIds: formattedData.users_ids,
      })

      const toastId = Date.now()

      toast.addToast({
        title: 'Reporte solicitado',
        description:
          'El reporte se esta generando, va al modulo de reportes para conferir su estado.',
        type: 'positive',
        timeout: 12000,
        id: toastId,
        buttonLabel: 'Cerrar',
        callback: () => {
          toast.removeToast(toastId)
        },
      })

      // handleCloseDrawer()
    } catch (error) {
      console.log(error)
      toast.addToast({
        title: 'Error',
        description: 'Ocurrio un error al solicitar el reporte',
        type: 'negative',
        id: Date.now(),
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(submitExport)}>
        <>
          <Div css={{ zIndex: 3, width: '50%', marginTop: '$4' }}>
            <ControlledCalendarInput
              label="Rango de Fechas"
              name={'dates'}
              calendarType={'range'}
              defaultValues={[
                dayjs(dates?.[0]).toDate(),
                dayjs(dates?.[1]).toDate(),
              ]}
              maxDates={60}
            />
          </Div>
          <Div
            css={{
              width: '100%',
              flex: 1,
              overflow: 'hidden',
              marginTop: '$4',
              // minHeight: 400,
              // maxHeight: 400,
              maxHeight: 500,
            }}
          >
            <ControlledFullWorkerSearchBar<DataparIntegrationFormType>
              css={{
                height: 500,
              }}
              name={'users_ids'}
              usersIds={usersIds}
              policiesIds={permissionsArray.filter((p) =>
                p.includes('workers'),
              )}
            />
          </Div>
          <ButtonsContainer css={{ width: '100%', marginTop: '$4' }}>
            <Button
              onClick={handleCloseDrawer}
              disabled={
                isLoadingRequestReport || !usersIds.length || !dates.length
              }
              variant="secondary"
              type="button"
              css={{ width: '100%', marginRight: '$2' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                window.open('/reportes-v1', '_blank')
              }}
              variant="tertiary"
              type="button"
              css={{
                width: '100%',
                marginRight: '$2',
                background: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }}
              icon={<ArrowTopRightOnSquareIcon />}
            >
              Abrir Reportes
            </Button>

            <Button
              isLoading={isLoadingRequestReport}
              disabled={
                isLoadingRequestReport || !usersIds.length || !dates.length
              }
              variant="primary"
              onClick={methods.handleSubmit(submitExport)}
              type="submit"
              css={{ width: '100%', marginLeft: '$2' }}
            >
              <ArrowDownOnSquareIcon />
              Exportar
            </Button>
          </ButtonsContainer>
        </>
      </Form>
    </FormProvider>
  )
}
