import { apiV2 } from '@/services/api'
import { IMovementV2 } from '@/libs/react-query/types'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ICreateVacationDTO } from '@/libs/react-query/types/vacations'

dayjs.extend(utc)

const handleCreateVacation = async (data: ICreateVacationDTO) => {
  const response = await apiV2.post<IMovementV2>(
    '/vacation/create-vacation',
    data,
  )
  // console.log(data)
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleCreateVacations = () => {
  const mutation = useCustomMutation(
    ['handleCreateVacation'],
    handleCreateVacation,
  )

  return mutation
}
