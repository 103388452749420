import {
  ControlledFilterBar,
  Div,
  SmartTable,
  useSmartTableColumnManipulation,
} from '@/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { InvoiceManagerSchema, InvoiceManagerSchemaType } from './types'
import { useEffect } from 'react'
import { parseCompanyList } from './parser'
import { useHandleInvoiceManagerActions } from './actions'
import { useInvoiceManagerColumns } from './columns'
import { useCompaniesInvoices } from '@/libs/react-query/hooks/useCompaniesInvoices'
import dayjs from 'dayjs'
import { useHandleUpdateBusinessInfo } from '@/libs/react-query/mutations/admin/useHandleUpdateBusinessInfo'
import { useHandleDeleteInvoice } from '@/libs/react-query/mutations/admin/useHandleDeleteInvoice'
import { useHandleUpdateInvoice } from '@/libs/react-query/mutations/admin/useHandleUpdateInvoice'
import { useHandleUpdatePlan } from '@/libs/react-query/mutations/permissions/plans/useHandleUpdatePlan'

export const InvoiceManager = () => {
  const methods = useForm<InvoiceManagerSchemaType>({
    resolver: zodResolver(InvoiceManagerSchema),
    defaultValues: {
      companyName: '',
      data: [],
      date: [dayjs().startOf('day').toDate()],
    },
  })

  const [companyName, date] = useWatch({
    control: methods.control,
    name: ['companyName', 'date'],
  })

  const { data: companies, isFetching: isFetchingInvoices } =
    useCompaniesInvoices({
      date: date?.[0]?.toISOString() || dayjs().startOf('day').toISOString(),
    })

  const { isLoading: isLoadingUpdatePlan } = useHandleUpdatePlan()
  const { isLoading: isLoadingUpdateInvoice } = useHandleUpdateInvoice()
  const { isLoading: isLoadingDeleteInvoice } = useHandleDeleteInvoice()
  const { isLoading: isLoadingBusinessInfos } = useHandleUpdateBusinessInfo()

  useEffect(() => {
    if (!companies?.length) {
      return
    }

    const correctCompanies = companies
      .filter((company) => {
        let isValidName = false

        if (companyName?.length) {
          isValidName = (company.name as string)
            .toLowerCase()
            .includes(companyName.toLowerCase())
        } else {
          isValidName = true
        }

        return isValidName
      })
      .sort((a, b) => {
        return (b.activeEmployees || 0) - (a.activeEmployees || 0)
      })

    const data = parseCompanyList(correctCompanies)

    methods.reset({
      data,
      companyName,
      date,
    })
  }, [companies, companyName])

  const { data: columns } = useInvoiceManagerColumns({
    methods,
  })

  useHandleInvoiceManagerActions()

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'invoiceMaganerColumnsOrder',
    tableHiddenUniqueName: 'invoiceMaganerHiddenColumns',
    tableLeftFixedUniqueName: 'invoiceMaganerLeftFixedColumns',
    tableRightFixedUniqueName: 'invoiceMaganerRightFixedColumns',

    defaultLeftFixedColumns: ['download', 'count', 'name'],
    defaultHiddenColumns: [
      'legal_representative',
      'email',
      'payroll_import_date',
      'app_setup_date',
      'face_register_date',
      'punch_register_date',
      'status',
    ],
  })

  return (
    <FormProvider {...methods}>
      <ControlledFilterBar
        nameFilterName="companyName"
        policiesIds={[]}
        isLoading={
          isFetchingInvoices ||
          isLoadingUpdatePlan ||
          isLoadingUpdateInvoice ||
          isLoadingDeleteInvoice ||
          isLoadingBusinessInfos
        }
        dateName="date"
        dateType="single"
      />
      <Div
        css={{
          flex: 1,
          padding: '$4',

          position: 'relative',
          height: 'calc(100vh - 90px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <SmartTable
          style={{
            borderInLines: false,
          }}
          columns={columns}
          areColumnsDraggable
          defaultColumnOrder={defaultColumnsOrder}
          defaultHiddenColumns={defaultHiddenColumns}
          saveColumnsOrderCallback={saveColumnsOrderCallback}
          saveHiddenColumnsCallback={saveHiddenColumnsCallback}
          resetColumnOrder={resetStateColumnOrder}
          maxDepth={2}
          defaultLeftFixedColumns={defaultLeftFixedColumns}
          defaultRightFixedColumns={defaultRightFixedColumns}
          saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
          saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
        />
      </Div>
    </FormProvider>
  )
}
