import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { Div } from '@/components'
import { Text } from '@punto-ui/react'

const COLUMNS = 8

export const useVacations = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [vacations] = useWatch({
    control,
    name: ['data.vacations'],
  })
  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = []

    vacations.forEach((vacation, index) => {
      const items: InactiveWorkerField[] = [
        {
          _type: 'custom',
          component: (
            <Div
              css={{
                width: `calc((100% / ${COLUMNS}) - 5%)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 18,
              }}
            >
              <Text
                variant={'caption'}
                css={{
                  color: '$interface_dark_deep',
                }}
              >
                {vacation.year.label}
              </Text>
            </Div>
          ),
        },
        {
          _type: 'input',
          name: `data.vacations.${index}.year.value`,
          inputType: 'only-numbers-or-empty',
          disabled: isLoadingLiquidation,
          label: 'Dias causados',
        },
        {
          _type: 'input',
          name: `data.vacations.${index}.used_days`,
          inputType: 'only-numbers-or-empty',
          disabled: isLoadingLiquidation,
          label: 'Dias gozados',
        },
        {
          _type: 'input',
          disabled: isLoadingLiquidation,
          name: `data.vacations.${index}.earned_days`,
          inputType: 'only-numbers-or-empty',
          label: 'Dias en haber',
        },
        {
          _type: 'input',
          name: `data.vacations.${index}.value`,
          label: 'Valor a pagar',
          disabled: isLoadingLiquidation,
          inputType: 'money-guarani',
        },
        ...Array.from({
          length: COLUMNS - 5,
        }).map(() => {
          return {
            _type: 'custom',
            component: (
              <Div
                css={{
                  width: `calc((100% / ${COLUMNS}) - 5%)`,
                }}
              />
            ),
          }
        }),
      ] as InactiveWorkerField[]

      lastSalaryForm.push(...items)
    })

    // lastSalaryForm.push(
    //   ...([
    //     {
    //       _type: 'custom',
    //       component: (
    //         <Div
    //           css={{
    //             width: `calc((100% / ${COLUMNS}) - 5%)`,
    //           }}
    //         />
    //       ),
    //     },
    //     {
    //       _type: 'input',
    //       name: 'data.total_vacations.total_caused_vacations',
    //       inputType: 'only-numbers-or-empty',
    //       disabled: isLoadingLiquidation,
    //       label: 'Total vacaciones causadas',
    //     },
    //     {
    //       _type: 'input',
    //       name: 'data.total_vacations.used_days',
    //       inputType: 'only-numbers-or-empty',
    //       disabled: isLoadingLiquidation,
    //       label: 'Dias gozados',
    //     },
    //     {
    //       _type: 'input',
    //       name: 'data.total_vacations.earned_days',
    //       inputType: 'only-numbers-or-empty',
    //       disabled: isLoadingLiquidation,
    //       label: 'Dias en haber',
    //     },
    //     {
    //       _type: 'input',
    //       inputType: 'money-guarani',
    //       name: 'data.total_vacations.value',
    //       disabled: isLoadingLiquidation,
    //       label: 'Valor a pagar',
    //     },
    //   ] as InactiveWorkerField[]),
    // )

    return lastSalaryForm
  }, [vacations, isLoadingLiquidation])

  return {
    form,
  }
}
