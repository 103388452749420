import { deeplyCloneObject } from '@/utils/object'
import { findGroupByIdInsideGroupData, findGroupParentById } from '../utils'
import { useGroupBuilder } from '../context'

export const useReorderRows = () => {
  const { groups, handleUpdateConnection, handleUpdateUser } = useGroupBuilder()

  // Person A dragged to person B -> Insert person A right after person B is in the data structure
  // Person A dragged to group B -> Insert person A as the first child of group B
  // Group A dragged to person -> Not allowed
  // Group A dragged to group B -> Insert group A as the first child of group B
  const reorderRow = (draggedRowIndex: string, targetRowIndex: string) => {
    const dataToBeCloned = deeplyCloneObject(groups)

    const draggedRow = findGroupByIdInsideGroupData(
      dataToBeCloned,
      draggedRowIndex,
    )
    const targetRow = findGroupByIdInsideGroupData(
      dataToBeCloned,
      targetRowIndex,
    )

    if (!draggedRow || !targetRow) {
      return
    }

    const draggedIsGroup = draggedRow._type === 'group'
    const draggedIsUser = draggedRow._type === 'user'

    const targetIsGroup = targetRow._type === 'group'
    const targetIsUser = targetRow._type === 'user'

    if (draggedIsUser && targetIsUser) {
      const draggedRowParent = findGroupParentById(
        dataToBeCloned,
        draggedRow.id,
      )
      const targetRowParent = findGroupParentById(dataToBeCloned, targetRow.id)

      if (!draggedRowParent || !targetRowParent) {
        return
      }

      if (
        draggedRowParent._type === 'group' &&
        targetRowParent._type === 'group'
      ) {
        const draggedRowParentChildren = draggedRowParent.children
        const targetRowParentChildren = targetRowParent.children

        const draggedRowParentChildrenIndex =
          draggedRowParentChildren.findIndex(
            (child) => child.id === draggedRow.id,
          )
        const targetRowParentChildrenIndex = targetRowParentChildren.findIndex(
          (child) => child.id === targetRow.id,
        )

        draggedRowParentChildren.splice(draggedRowParentChildrenIndex, 1)
        targetRowParentChildren.splice(
          targetRowParentChildrenIndex + 1,
          0,
          draggedRow,
        )

        // console.log({
        //   newParentId: targetRowParent.id,
        //   oldParentId: draggedRowParent.id,
        //   userId: draggedRow.id,
        // })
        handleUpdateUser({
          newParentId: targetRowParent.id,
          oldParentId: draggedRowParent.id,
          userId: draggedRow.id,
        })
        // handleSetData(dataToBeCloned)
      }
    } else if (draggedIsUser && targetIsGroup) {
      const draggedRowParent = findGroupParentById(
        dataToBeCloned,
        draggedRow.id,
      )

      if (!draggedRowParent) {
        return
      }

      if (draggedRowParent._type === 'group') {
        const draggedRowParentChildren = draggedRowParent.children

        const draggedRowParentChildrenIndex =
          draggedRowParentChildren.findIndex(
            (child) => child.id === draggedRow.id,
          )

        draggedRowParentChildren.splice(draggedRowParentChildrenIndex, 1)
        targetRow.children.splice(0, 0, draggedRow)
      }

      // console.log({
      //   newParentId: targetRow.id,
      //   oldParentId: draggedRowParent.id,
      //   userId: draggedRow.id,
      // })
      handleUpdateUser({
        newParentId: targetRow.id,
        oldParentId: draggedRowParent.id,
        userId: draggedRow.id,
      })
      // handleSetData(dataToBeCloned)
    } else if (draggedIsGroup && targetIsGroup) {
      const draggedRowParent = findGroupParentById(
        dataToBeCloned,
        draggedRow.id,
      )

      if (!draggedRowParent) {
        return
      }

      if (draggedRowParent._type === 'group') {
        const draggedRowParentChildren = draggedRowParent.children

        const draggedRowParentChildrenIndex =
          draggedRowParentChildren.findIndex(
            (child) => child.id === draggedRow.id,
          )

        draggedRowParentChildren.splice(draggedRowParentChildrenIndex, 1)
        targetRow.children.splice(0, 0, draggedRow)

        // console.log({
        //   newParentId: targetRow.id,
        //   cellId: draggedRow.id,
        //   oldParentId: draggedRowParent.id,
        // })
        handleUpdateConnection({
          newParentId: targetRow.id,
          cellId: draggedRow.id,
          oldParentId: draggedRowParent.id,
        })
        // handleSetData(dataToBeCloned)
      }
    }
  }

  return { data: reorderRow }
}
