import { useFormContext, useWatch } from 'react-hook-form'
import { IDocumentsStateSchema } from '../../documents-state'
import { AppBar, Div } from '@/components'
import { Progress, Text } from '@punto-ui/react'
import { ArrowsRightLeftIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useHandleChangeFolderSettings } from '@/libs/react-query/mutations/documents/useHandleChangeFolderSettings'
import { useState } from 'react'
import { AppBarButton } from '@/pages/marcaciones/components/AppBar'
import { useDocumentsStructure } from '@/libs/react-query/hooks/useDocumentsStructure'
import { flattenDocumentTree } from '../../documents-utils'

export const DocumentsAppBar = () => {
  const methods = useFormContext<IDocumentsStateSchema>()
  const { data: structure } = useDocumentsStructure()

  const [isConfirmingHide, setIsConfirmingHide] = useState(false)

  const documentOptions = structure ? flattenDocumentTree(structure) : []

  const foldersChecked = useWatch({
    control: methods.control,
    name: 'structure.checked',
  })

  const {
    mutateAsync: handleChangeFolderSettings,
    isLoading: isLoadingChangeFolder,
  } = useHandleChangeFolderSettings()

  const isShowing = Object.values(foldersChecked).reduce((acc, curr) => {
    if (curr) {
      return acc + 1
    }

    return acc
  }, 0)

  return (
    <AppBar isShowing={!!isShowing}>
      <Div
        css={{
          borderRadius: '$md',
          backgroundColor: '$interface_light_pure',
          height: 50,
          display: 'flex',
          alignItems: 'center',
          border: '1px solid $interface_light_deep',
        }}
      >
        <Div
          css={{
            borderRight: '1px solid $interface_light_down',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '$brand_primary_pure',
            minHeight: 50,
            minWidth: 40,
            borderTopLeftRadius: '$md',
            borderBottomLeftRadius: '$md',
            height: 50,
          }}
        >
          <Text
            variant="subtitle2"
            css={{
              color: '$interface_light_pure',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isShowing}
          </Text>
        </Div>

        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 50,
            paddingLeft: '$2',
            paddingRight: '$2',
            borderRight: '1px solid $interface_light_deep',
          }}
        >
          <Text
            variant="description"
            css={{
              color: '$interface_dark_down',
              whiteSpace: 'nowrap',
            }}
          >
            Carpetas selecionadas
          </Text>
        </Div>
        <AppBarButton
          icon={<ArrowsRightLeftIcon />}
          text={'Mover'}
          options={documentOptions?.map((shift) => ({
            value: shift.id,
            label: shift.name,
          }))}
          handleSelectOption={async (value) => {
            if (isLoadingChangeFolder) {
              return
            }

            const data = methods.getValues('structure')
            const foldersIds = Object.entries(data.checked)
              .filter(([key, value]) => value)
              .map(([key, value]) => key)

            await handleChangeFolderSettings({
              foldersIds,
              parent_id: value,
            })
          }}
        />
        <Div
          onClick={async () => {
            if (isLoadingChangeFolder) {
              return
            }

            if (!isConfirmingHide) {
              setIsConfirmingHide(true)
              return
            }

            const data = methods.getValues('structure')
            const foldersIds = Object.entries(data.checked)
              .filter(([key, value]) => value)
              .map(([key, value]) => key)

            await handleChangeFolderSettings({
              foldersIds,
              isHidden: true,
            })
            setIsConfirmingHide(false)
          }}
          css={{
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            minHeight: 50,
            paddingLeft: '$4',
            paddingRight: '$4',

            opacity: isLoadingChangeFolder ? 0.5 : 1,

            svg: {
              height: 16,
              width: 16,
              color: isConfirmingHide
                ? '$status_warning_deep'
                : '$interface_dark_down',
              marginBottom: '$1',
            },
          }}
        >
          {isLoadingChangeFolder ? (
            <Progress
              css={{
                marginBottom: '$1',
              }}
            />
          ) : (
            <EyeSlashIcon />
          )}
          <Text
            variant="caption"
            css={{
              color: isConfirmingHide
                ? '$status_warning_deep'
                : '$interface_dark_down',
              fontWeight: '$bold',
            }}
          >
            {isConfirmingHide ? 'Confirmar Ocultar' : 'Ocultar'}
          </Text>
        </Div>
      </Div>
    </AppBar>
  )
}
