import { useRef, useEffect, RefObject } from 'react'

export function useHorizontalScroll<
  T extends HTMLElement = HTMLElement,
>(): RefObject<T> {
  const elRef = useRef<T>(null)
  useEffect(() => {
    const el = elRef.current
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY === 0) return
        e.preventDefault()
        // el.scrollTo({
        //   left: el.scrollLeft + 4 * e.deltaY,
        //   behavior: 'smooth',
        // })
        // el.scrollBy({
        //   left: e.deltaY,
        //   behavior: 'smooth',
        // })
        smoothScrollBy(el, 3 * e.deltaY, 250) // 250ms animation
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])
  return elRef
}

function smoothScrollBy(element: HTMLElement, delta: number, duration: number) {
  const start = element.scrollLeft
  const startTime = performance.now()

  function scroll() {
    const now = performance.now()
    const time = Math.min(1, (now - startTime) / duration)
    const timeFunction = time * (2 - time) // easeInOutQuad easing
    element.scrollLeft = start + timeFunction * delta

    if (time < 1) {
      requestAnimationFrame(scroll)
    }
  }

  requestAnimationFrame(scroll)
}
