import { Div } from '@/components'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useChildrenData } from '../../../forms'
import { FormGroup } from './FormGroup'
import { useIsWorkerFormAvailable } from '../../../hooks/useIsWorkerFormAvailable'

export const ChildrenData = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const { setValue, getValues, control } =
    useFormContext<createCompleteWorkerData>()

  const handleAddChildren = () => {
    const data = getValues()
    setValue('children', [
      ...(data.children || []),
      {
        birthday: '',
        document: '',
        id: String(Date.now()),
        name: '',
        shouldReceiveChildrenBonus: false,
        isNew: true,
      },
    ])
  }

  const children = useWatch({
    control,
    name: 'children',
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Text
        size="xl"
        css={{
          color: '$brand_primary_pure',
          fontWeight: '$bold',
          marginBottom: '$4',
        }}
      >
        Datos Parentales
      </Text>
      <Button
        disabled={!isWorkerCreationAvailable}
        onClick={handleAddChildren}
        variant="primary"
      >
        Agregar Hijo
      </Button>
      <Div
        css={{
          padding: '$4',
          border: 'solid',
          borderWidth: children?.length ? '2px' : 0,
          borderColor: '$interface_dark_up',
          borderRadius: '$md',
          marginTop: '$6',
        }}
      >
        {children?.map((child, index) => (
          <Div
            key={child.id}
            css={{ display: 'flex', flexDirection: 'column' }}
          >
            <ChildrenForm child={child} index={index} />
            {children?.length - 1 !== index && (
              <Div
                css={{
                  height: 2,
                  width: '100%',
                  backgroundColor: '$interface_dark_up',
                  marginTop: '$6',
                }}
              />
            )}
          </Div>
        ))}
      </Div>
    </Div>
  )
}

const ChildrenForm = ({ index, child }: any) => {
  const { data: contactsData } = useChildrenData(index)
  const { setValue, getValues } = useFormContext<createCompleteWorkerData>()

  const handleDeleteChild = () => {
    const data = getValues()

    setValue(
      'children',
      data.children?.filter((_, i) => i !== index),
    )
  }

  return (
    <Div css={{ marginTop: '$2' }}>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text
          size="md"
          css={{
            color: '$brand_primary_pure',
            fontWeight: '$bold',
          }}
        >
          {child.name || `Hijo ${index + 1}`}
        </Text>
        <Button
          onClick={handleDeleteChild}
          variant="primaryCritical"
          icon={<TrashIcon />}
        />
      </Div>
      <FormGroup items={contactsData} cols={3} />
    </Div>
  )
}
