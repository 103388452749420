import { TagProps } from '@punto-ui/react'
import { IDefaultApiPagination } from '../pagination'
import { IWorkerUser } from '../worker'

export type IVacationDay = {
  id: string
  identifier_id: string
  date: string
  vacation_id: string
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export interface IVacationUser {
  id: string
  identifier_id: string
  user_id: string
  vacation_id: string

  vacation_value: number
  vacation_value_ips: number

  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export interface IVacation {
  id: string
  identifier_id: string
  name: string
  description: string | null
  pay_within_payroll: boolean
  period: number
  communication_date: string
  comeback_date: string
  payment_date: string
  created_at: Date
  updated_at: Date
  deleted_at: Date | null

  dates: IVacationDay[]
  users: IVacationUser[]

  first_user: IWorkerUser
}

export type ICreateVacationDTO = {
  dates: string[]
  users_ids: string[]

  comeback_date: string
  communication_date: string
  name: string
  payment_date: string
  period: number
  description?: string
  pay_within_payroll: boolean
}

export type IUpdateVacationDTO = ICreateVacationDTO & {
  id: string
}

export type IListVacationsDTO = {
  cellsIds?: string[]
  name?: string

  page?: number
  perPage?: number
}

export type VacationAntiquityType =
  | 'less_then_6_months'
  | 'between_1_and_5_years'
  | 'between_5_and_10_years'
  | 'more_then_10_years'

type ListVacationSimulationParams = {
  days: string[]
  period: number
  usersIds: string[]

  excludeVacationId?: string
}

export type IGetVacationsStatusDTO = {
  cellsIds?: string[]
  name?: string
  date: string

  page?: number
  perPage?: number

  status?: string
  periods?: number[]
  antiquity?: VacationAntiquityType[]

  simulation?: ListVacationSimulationParams
}

export type IGetVacationDTO = {
  id: string
}

type VacationStatus =
  | 'EXPIRED'
  | 'USED'
  | 'USED-OUT-OF-PLACE'
  | 'PARTIAL'
  | 'CAUSED'
  | 'FULL-ADVANCE'
  | 'PARTIAL-ADVANCE'
  | 'TO-CAUSE'
  | 'PRESCRIBED'

export const vacationStatusToLabel = {
  EXPIRED: 'Vencidas',
  USED: 'Usufruct. dentro del plazo',
  'USED-OUT-OF-PLACE': 'Usufruct. fuera del plazo',
  PARTIAL: 'Parcial',
  CAUSED: 'Causadas',
  'FULL-ADVANCE': 'Anticipo Total',
  'PARTIAL-ADVANCE': 'Anticipo Parcial',
  'TO-CAUSE': 'A causar',
  PRESCRIBED: 'Sujeto a prescripción',
}

export const vacationStatusToTagVariant: Record<
  VacationStatus,
  TagProps['variant']
> = {
  EXPIRED: 'negative',
  USED: 'positive',
  'USED-OUT-OF-PLACE': 'warning',
  PARTIAL: 'neutral',
  CAUSED: 'warning',
  'FULL-ADVANCE': 'positive',
  'PARTIAL-ADVANCE': 'warning',
  'TO-CAUSE': 'positive',
  PRESCRIBED: 'warning',
}

interface IDetailedVacationYear {
  year: number
  status: VacationStatus
  antiquity: number
  antiquityMonths: number
  antiquityDays: number

  fromDate: string
  toDate: string

  days_caused: number
  days_taken: number
  days_remaining: number
  days_proportional: number
  days_owed: number
}

export interface IVacationStatusResult {
  user_id: string
  antiquity: number
  antiquityMonths: number
  antiquityDays: number
  hiring_date: string
  days_caused: number
  days_taken: number
  days_remaining: number
  days_owed: number
  days_proportional: number
  user: IWorkerUser | undefined
  yearsDetailed: IDetailedVacationYear[]
}

export type IPaginatedVacations = IDefaultApiPagination<IVacation>
export type IPaginatedVacationsStatus =
  IDefaultApiPagination<IVacationStatusResult>
