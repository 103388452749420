import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdatePayrollPayment } from '@/libs/react-query/types/payroll'
dayjs.extend(utc)

const handleUpdatePayrollPayment = async (payload: IUpdatePayrollPayment) => {
  await apiV2.post<void>('/payroll/update-payroll-payment', payload)
  queryClient.invalidateQueries()
}

export const useHandleUpdatePayrollPayment = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdatePayrollPayment'],
    handleUpdatePayrollPayment,
  )

  return mutation
}
