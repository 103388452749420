import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { CreatePolicyPayload } from '../types'

export const useHandleCreatePolicy = () => {
  const handleCreatePolicy = async (apiPayload: CreatePolicyPayload) => {
    await apiV2.post<void>('/permission/create-policy', apiPayload)
  }

  const mutation = useMutation(['useHandleCreatePolicy'], handleCreatePolicy)

  return mutation
}
