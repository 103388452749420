import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
  ReportBuilderRowType,
} from '../type'

export const reportBuilderRowDetailsByTypeAndRow: Record<
  IReportBuilderTypes,
  Partial<Record<IReportBuilderRowTypes, ReportBuilderRowType['details']>>
> = {
  single: {},
  collective: {
    workers: [
      {
        label: 'Dia',
        selected: false,
        value: 'day',
      },
      {
        label: 'Mes',
        selected: false,
        value: 'month',
      },
      // {
      //   label: 'Año',
      //   selected: false,
      //   value: 'year',
      // },
    ],
    entities: [
      {
        label: 'Pagos',
        selected: false,
        value: 'payments',
      },
      {
        label: 'Movimientos',
        selected: false,
        value: 'movements',
      },
      {
        label: 'Vacaciones',
        selected: false,
        value: 'vacations',
      },
    ],
  },
  totals: {},
}

export const reportBuilderRowDetailsLabelByTypeAndRow: Record<
  IReportBuilderTypes,
  Partial<Record<IReportBuilderRowTypes, string>>
> = {
  single: {},
  collective: {
    workers: 'Seleccione el periodo',
    entities: 'Seleccione los modulos',
  },
  totals: {},
}

export const reportBuilderRowRelationsByTypeAndRow: Record<
  IReportBuilderTypes,
  Partial<Record<IReportBuilderRowTypes, string>>
> = {
  single: {},
  collective: {
    workers: 'OR',
    entities: 'AND',
  },
  totals: {},
}
