import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { SmartColumnType, SmartTableFormSchemaType } from './types'
import { reorderSmartTableColumns } from './utils/reorder-columns'

export const useSmartTableColumns = (
  columnsRef: SmartColumnType[],
  order?: string[],
  hiddenColumns?: string[],
  maxDepth?: number,
  leftFixedColumns?: string[],
  rightFixedColumns?: string[],
) => {
  const columns = useMemo(() => {
    const entrancesColumns: ColumnDef<SmartTableFormSchemaType>[] = []

    const orderedColumnsRef = reorderSmartTableColumns(
      columnsRef,
      order || [],
      hiddenColumns || [],
      maxDepth || 1,
      leftFixedColumns,
      rightFixedColumns,
    )

    const arrayHiddenColumns = hiddenColumns?.filter((s) => s.includes('.'))

    orderedColumnsRef.forEach((item) => {
      if (item.items?.length) {
        const itemsColumns: ColumnDef<SmartTableFormSchemaType>[] = []

        item.items.forEach((x) => {
          const arrayLength = x.length || 0

          if (x.array) {
            for (let i = 0; i < arrayLength; i++) {
              const header = x.headers?.[i] || x.header

              const columnId = `${x.name}.${i}`

              const isArrayColumnHidden = arrayHiddenColumns?.find(
                (s) => s === columnId,
              )

              if (!isArrayColumnHidden) {
                itemsColumns.push({
                  header,
                  cell: (info) => info.getValue(),
                  footer: (info) => info.column.id,
                  id: columnId,
                  accessorFn: () => null,
                  meta: {
                    isArray: true,
                    subarrayIndex: i,
                  },
                })
              }
              // else {
              //   arrayLength += 1
              // }
            }
          } else {
            itemsColumns.push({
              header: x.header,
              cell: (info) => info.getValue(),
              footer: (info) => info.column.id,
              id: x.name || Math.random().toString(),
              accessorFn: () => null,
            })
          }
        })

        if (itemsColumns.length) {
          entrancesColumns.push({
            header: item.header,
            id: item.name || Math.random().toString(),
            columns: itemsColumns,
            accessorFn: () => null,
          })
        }
      } else {
        entrancesColumns.push({
          header: item.header,
          cell: (info) => info.getValue(),
          footer: (info) => info.column.id,
          id: item.name || Math.random().toString(),
          accessorFn: () => null,
        })
      }
    })
    return {
      entrancesColumns,
      orderedColumnsRef,
    }
  }, [
    columnsRef,
    order,
    maxDepth,
    hiddenColumns,
    leftFixedColumns,
    rightFixedColumns,
  ])

  return {
    columns: columns.entrancesColumns,
    orderedColumns: columns.orderedColumnsRef,
  }
}

export const findColumnByName = (
  columns: SmartColumnType[],
  name: string,
): SmartColumnType | undefined => {
  const column = columns.find((col) => {
    if (!col.items?.length) {
      return col.name === name
    }
    const foundColItem = col.items.find((item) => item.name === name)
    return foundColItem || col.name === name
  })
  if (column?.items?.length) {
    return (
      (column.items.find((col) => col.name === name) as SmartColumnType) ||
      column
    )
  } else {
    return column
  }
}

export const findColumnMainHeader = (
  columns: SmartColumnType[],
  name: string,
): SmartColumnType | undefined => {
  const column = columns.find((col) => {
    if (!col.items?.length) {
      return col.name === name
    }
    const foundColItem = col.items.find((item) => item.name === name)
    return foundColItem || col.name === name
  })
  return column
}
