import { ControlledInput, Div } from '@/components'
import {
  ControlledCalendarInput,
  ControlledCheckbox,
  ControlledSalaryConcept,
} from '@/components/Forms'
import {
  ConfigSchema,
  SalaryGroupSchema,
} from '@/pages/configuraciones/components'
import {
  PlusCircleIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Button, Divider, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'

export const SalaryHistory = () => {
  const methods = useFormContext<ConfigSchema>()

  const salaryGroupHistory = useWatch({
    control: methods.control,
    name: 'salaryGroup.history',
  })

  const handleAddSalaryGroup = () => {
    methods.setValue('salaryGroup.history', [
      ...salaryGroupHistory,
      {
        id: Date.now().toString(),
        start_date: [],
        is_minimum_wage: false,
        value: 0,
        concepts: [],
      },
    ])
  }

  return (
    <Div
      css={{
        padding: '$4',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '$4',
        }}
      >
        <Text
          size="xl"
          css={{
            color: '$interface_dark_deep',
            fontWeight: '$bold',
          }}
        >
          Historico Salarial del Grupo
        </Text>
      </Div>

      <Div
        css={{
          maxWidth: '50vw',
          overflowY: 'auto',
          display: 'flex',
          alignItems: 'flex-start',
          minHeight: 450,
        }}
      >
        {salaryGroupHistory.map((history, index) => {
          return (
            <SalaryGroupComponent
              history={history}
              index={index}
              key={history.id}
            />
          )
        })}

        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '$4',
            minWidth: 60,
          }}
        >
          <Div
            onClick={handleAddSalaryGroup}
            css={{
              cursor: 'pointer',

              height: 36,
              width: 36,
              background: '$brand_primary_pure',
              borderRadius: '$full',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              svg: {
                height: 24,
                width: 24,
                color: '$interface_light_pure',
              },
            }}
          >
            <PlusIcon />
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

const SalaryGroupComponent = ({
  history,
  index,
}: {
  index: number
  history: SalaryGroupSchema['history'][0]
}) => {
  const methods = useFormContext<ConfigSchema>()

  const handleAddConcept = () => {
    methods.setValue(`salaryGroup.history.${index}.concepts`, [
      ...methods.getValues(`salaryGroup.history.${index}.concepts`),
      {
        id: Date.now().toString(),
        name: '',
        value: 0,
        description: '',
        ips: false,
      },
    ])
  }

  return (
    <Div
      css={{
        display: 'flex',
        maxHeight: 450,
        // overflow: 'hidden',
        alignItems: 'flex-start',
        // minWidth: 550,
        // minWidth: index ? 500 : 300,
      }}
    >
      {!!index && (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              height: 1,
              minWidth: 50,
              maxWidth: 50,
              background: '$interface_light_down',
            }}
          />
          <Div
            css={{
              maxWidth: 130,
              minWidth: 130,
              marginBottom: 24,
            }}
          >
            <ControlledCalendarInput
              label="Fecha de cambio"
              name={`salaryGroup.history.${index}.start_date`}
              calendarType={'single'}
              maxDates={60}
            />
          </Div>
          <Div
            css={{
              height: 1,
              width: 50,
              background: '$interface_light_down',
            }}
          />
        </Div>
      )}

      <Div
        css={{
          maxHeight: 450,

          maxWidth: 300,
          minWidth: 300,
          // height: 400,
          border: '1px solid $interface_light_down',
          borderRadius: '$md',

          display: 'flex',
          flexDirection: 'column',
          padding: '$4',
          gap: '$4',
        }}
      >
        <ControlledInput
          label="Valor"
          name={`salaryGroup.history.${index}.value`}
          inputType="money-guarani"
        />
        <ControlledCheckbox
          label="Considerar salário mínimo"
          name={`salaryGroup.history.${index}.is_minimum_wage`}
        />

        <Div>
          <Divider
            css={{
              width: '100%',
              height: 1,
            }}
          />
        </Div>

        <Div
          css={{
            overflowY: 'auto',
            overflowX: 'hidden',

            // maxHeight: 300,
            flex: 1,
            paddingLeft: 8,
            paddingRight: 8,

            display: 'flex',
            flexDirection: 'column',
            gap: '$4',
          }}
        >
          {history.concepts.map((concept, conceptIndex) => {
            return (
              <>
                <SalaryConceptComponent
                  historyIndex={index}
                  conceptIndex={conceptIndex}
                  key={concept.id}
                />
                <Div>
                  <Divider
                    css={{
                      width: '100%',
                      height: 1,
                    }}
                  />
                </Div>
              </>
            )
          })}
          <Div
            css={{
              width: '100%',
            }}
          >
            <Button
              type="button"
              css={{
                width: '100%',
              }}
              onClick={handleAddConcept}
              variant="secondary"
              icon={<PlusCircleIcon />}
            >
              Agregar Concepto
            </Button>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

const SalaryConceptComponent = ({
  historyIndex,
  conceptIndex,
}: {
  conceptIndex: number
  historyIndex: number
}) => {
  const methods = useFormContext<ConfigSchema>()

  const handleRemoveConcept = () => {
    const concepts = methods.getValues(
      `salaryGroup.history.${historyIndex}.concepts`,
    )
    concepts.splice(conceptIndex, 1)
    methods.setValue(`salaryGroup.history.${historyIndex}.concepts`, concepts)
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$2',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <ControlledSalaryConcept
          name={`salaryGroup.history.${historyIndex}.concepts.${conceptIndex}.name`}
          options={[]}
          defaultOption={{
            label: '',
            value: '',
          }}
        />
        <Button
          type="button"
          onClick={handleRemoveConcept}
          css={{
            height: 34,
          }}
          icon={<TrashIcon />}
          variant={'secondaryCritical'}
        />
      </Div>
      <ControlledInput
        label="Valor del Concepto"
        name={`salaryGroup.history.${historyIndex}.concepts.${conceptIndex}.value`}
        inputType="money-guarani"
      />
      <ControlledCheckbox
        label="IPS"
        name={`salaryGroup.history.${historyIndex}.concepts.${conceptIndex}.ips`}
      />
    </Div>
  )
}
