import { styled } from '@punto-ui/react'

export const Touchable = styled('div', {
  all: 'unset',
  cursor: 'pointer',

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
    },
  },
})
