import React from 'react'
import { CreateWorkerContext } from '../index'

export const useWorkerCreation = () => {
  const context = React.useContext(CreateWorkerContext)

  if (!context) {
    throw new Error(
      'useWorkerCreation must be used within a CreateWorkerContext',
    )
  }

  return context
}
