import { ContextualMenuLayout, Div, Touchable } from '@/components'
import { Progress, Text, keyframes, styled, theme } from '@punto-ui/react'
import { useCan } from '@/hooks'
import React, { useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'

import Draggable from 'react-draggable'
import { PunchesInfosTableType } from '../../types'
import { usePunchSummary } from '../../context'
import { CreatePunchMenuContent } from '../menus/CreatePunchMenu'
import {
  ArrowPathIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  FireIcon,
  MagnifyingGlassCircleIcon,
  PlusIcon,
  SunIcon,
} from '@heroicons/react/24/outline'
import { useMovementsContext } from '@/pages/movimientos/context/hooks/useMovementsContext'
import { IWorkerUser } from '@/libs/react-query/types'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { useTabStore } from '@/store'
import { useHandleUpdateAuthorizedHours } from '@/libs/react-query/mutations/punchclock/useHandleUpdateAuthorizedHours'
import { formattedHourToMinutes } from '@/utils/date'
import { useHandleCreateMultiplePunches } from '@/libs/react-query/mutations/punchclock/useHandleCreateMultiple'
import { CreatePunchPayload } from '@/libs/react-query/mutations/punchclock/types'
import { useHandleDeleteByScheduleIds } from '@/libs/react-query/mutations'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'

export const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.5)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const PunchesAppBar = () => {
  const canEditPunches = useCan(['punches.edit'])
  const canManageShifts = useCan(['shifts.gestionar'])
  const canCreateMovements = useCan(['moves.create', 'moves.request'])
  const canAuthorizeHours = useCan(['punches.authorize'])

  const [isConfirmingAutocomplete, setIsConfirmingAutocomplete] =
    useState(false)

  const { addTab, setTab } = useTabStore((state) => ({
    addTab: state.addTab,
    setTab: state.setActiveTab,
  }))
  const { control, getValues } = useFormContext<PunchesInfosTableType>()
  const { handleCreatePunchBatch, handleCreateShiftSchedule } =
    usePunchSummary()

  const { mutateAsync: handleUpdateAuthorizedHours } =
    useHandleUpdateAuthorizedHours()
  const {
    mutateAsync: handleCreateMultiplePunches,
    isLoading: isLoadingCreateMultiplePunches,
  } = useHandleCreateMultiplePunches()
  const {
    mutateAsync: handleDeleteByScheduleIds,
    isLoading: isLoadingDeleteBySchedule,
  } = useHandleDeleteByScheduleIds()
  const { handleOpenMovementDrawer } = useMovementsContext()

  const [selectedDateString] = getValues('date')
  const selectedDate = dayjs(selectedDateString).toDate()

  const { data: smartShifts } = useSmartShifts()

  const data = useWatch({
    control,
    name: 'data',
  })

  const selectedPunches = useMemo(
    () => data?.filter((d) => d.checked.value),
    [data],
  )

  const shouldAddRest = useMemo(() => {
    return selectedPunches.every((p) => !p.isRest && p.rawStatus !== 'HOLIDAY')
  }, [selectedPunches])

  const shouldRemoveRest = useMemo(() => {
    return selectedPunches.every((p) => p.isRest && !p.shiftId.value)
  }, [selectedPunches])

  const shouldAddHourAuthorization = useMemo(() => {
    return selectedPunches.some((p) =>
      Object.values(p.authorizedHours).some((h) => h !== null),
    )
  }, [selectedPunches])

  const automaticPunchesToCreate = useMemo(() => {
    const automaticPunchesToCreate: CreatePunchPayload[] = []

    selectedPunches.forEach((p) => {
      if (p.entrance.metadata?.isAutomatic && p.entrance.metadata?.date) {
        automaticPunchesToCreate.push({
          date: dayjs(p.entrance.metadata?.date).toISOString(),
          user_id: p.userId || '',
          tags: ['AUTOMATIC_CREATION_CONFIRMED'],
        })
      }

      if (p.exit.metadata?.isAutomatic && p.exit.metadata?.date) {
        automaticPunchesToCreate.push({
          date: dayjs(p.exit.metadata?.date).toISOString(),
          user_id: p.userId || '',
          tags: ['AUTOMATIC_CREATION_CONFIRMED'],
        })
      }

      p.intervals.forEach((i) => {
        if (i.metadata?.isAutomatic && i.metadata?.date) {
          automaticPunchesToCreate.push({
            date: dayjs(i.metadata?.date).toISOString(),
            user_id: p.userId || '',
            tags: ['AUTOMATIC_CREATION_CONFIRMED'],
          })
        }
      })
    })

    return automaticPunchesToCreate
  }, [selectedPunches])

  return (
    <Div
      css={{
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        background: '$interface_light_pure',
        borderRadius: '$md',
        border: 'solid 1px',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        css={{
          animation: `${contentShow} 50ms cubic-bezier(0.16, 1, 0.3, 1)`,
          display: 'flex',
          alignItems: 'center',

          '*': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Div
          css={{
            borderRight: '1px solid $interface_light_down',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '$brand_primary_pure',
            minHeight: 50,
            minWidth: 40,
            borderTopLeftRadius: '$md',
            borderBottomLeftRadius: '$md',
          }}
        >
          <Text
            variant="caption"
            css={{
              color: '$interface_light_pure',
              fontWeight: '$bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {selectedPunches?.length || 0}
          </Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 50,
            paddingLeft: '$2',
            paddingRight: '$2',
            borderRight: '1px solid $interface_light_deep',
          }}
        >
          <Text
            variant="description"
            css={{
              color: '$interface_dark_down',
              whiteSpace: 'nowrap',
            }}
          >
            Personas selecionadas
          </Text>
        </Div>
        {canEditPunches && (
          <>
            <AppBarButton
              icon={<PlusIcon />}
              text={'Marcación'}
              Menu={
                <ContextualMenuLayout options={[]}>
                  <CreatePunchMenuContent
                    date={dayjs(selectedDate).toISOString()}
                    handleSubmit={async (value) => {
                      if (!selectedPunches?.length) return

                      const baseDate = dayjs(selectedDate).format('YYYY-MM-DD')
                      const punchDate = dayjs(`${baseDate} ${value}`)

                      await handleCreatePunchBatch({
                        dates: [dayjs(punchDate).toISOString()],
                        users_ids:
                          selectedPunches.map((s) => s.userId || '') || [],
                      })
                    }}
                    users={
                      selectedPunches?.map((p) => ({
                        id: p.userId || '',
                        name: `${p.name.value} ${p.surname.value}`,
                        photo_url: (p.avatar?.url as string) || '',
                      })) || []
                    }
                  />
                </ContextualMenuLayout>
              }
            />
          </>
        )}
        {canCreateMovements && (
          <AppBarButton
            icon={<PlusIcon />}
            text={'Movimiento'}
            callToAction={() => {
              const values = getValues()
              const users = values.data.filter((d) => d.checked.value)
              const usersIds = users.map(
                (u) =>
                  ({
                    id: u.userId,
                  } as IWorkerUser),
              )

              const dedupedUsersIds = usersIds.filter(
                (u, index) =>
                  usersIds.findIndex((u2) => u2.id === u.id) === index,
              )

              handleOpenMovementDrawer({
                users: dedupedUsersIds,
                movement: {
                  id: '',
                  batch_id: '',
                  description: '',
                  identifier_id: '',
                  periods: [
                    {
                      id: '',
                      identifier_id: '',
                      movement_id: '',
                      date: '',
                      period_start: selectedDate.toISOString(),
                      period_end: selectedDate.toISOString(),
                      created_at: '',
                      updated_at: '',
                      deleted_at: '',
                    },
                  ],
                  type: 'MULTIPLE_DAYS',
                  category: 'permission',
                  should_include_in_ips: false,
                  should_include_hours: false,
                  should_pay_period: false,
                  title: '',
                  created_at: '',
                  deleted_at: '',
                  updated_at: '',
                },
              })
            }}
          />
        )}
        {canManageShifts && (
          <>
            {shouldAddRest && (
              <AppBarButton
                icon={<SunIcon />}
                text={'Descanso'}
                callToAction={async () => {
                  const data = getValues('data')
                  const shouldAddRest = data.filter(
                    (d) => d.checked.value && !d.isRest,
                  )

                  const dates = [dayjs(selectedDate).startOf('day').toDate()]

                  const params = shouldAddRest.map((s) => ({
                    userId: s.userId,
                    dates,
                  }))

                  await handleCreateShiftSchedule({
                    params,
                    shiftId: '',
                    isRest: true,
                  })
                }}
              />
            )}
            {shouldRemoveRest && (
              <AppBarButton
                icon={<BuildingOffice2Icon />}
                text={'Remover Descanso'}
                isLoading={isLoadingDeleteBySchedule}
                callToAction={async () => {
                  const data = getValues('data')
                  const shouldAddRest = data.filter(
                    (d) => d.checked.value && d.isRest,
                  )
                  const params = shouldAddRest.map((s) => s.scheduled_shift_id)
                  handleDeleteByScheduleIds({
                    ids: params,
                  })
                }}
              />
            )}
            <AppBarButton
              icon={<CalendarIcon />}
              text={'Cambiar Turno'}
              options={smartShifts?.map((shift) => ({
                value: shift.shiftId,
                label: shift.shiftName,
              }))}
              handleSelectOption={(value) => {
                const data = getValues('data')
                const shouldAddRest = data.filter((d) => d.checked.value)
                const dates = [dayjs(selectedDate).startOf('day').toDate()]

                const params = shouldAddRest.map((s) => ({
                  userId: s.userId,
                  dates,
                }))

                handleCreateShiftSchedule({
                  params,
                  shiftId: value,
                  isRest: false,
                })
              }}
            />
          </>
        )}
        <AppBarButton
          icon={<ArrowPathIcon />}
          text={'Reporte Marcaciones'}
          callToAction={() => {
            const tabs = selectedPunches.map((p) => ({
              id: p.userId,
              type: 'worker',
            }))

            addTab('punches', tabs)
            setTimeout(() => {
              setTab('punches', tabs[0].id)
            }, 500)
          }}
        />
        {shouldAddHourAuthorization && canAuthorizeHours && (
          <>
            <Div
              css={{
                width: 1,
                height: 50,
                background: '$interface_light_deep',
              }}
            />
            <AppBarButton
              icon={<ClockIcon />}
              text={'Aut. Extras Diur.'}
              callToAction={async () => {
                const selectedWithMorningAuthorization = selectedPunches.filter(
                  (p) => p.authorizedHours.morningExtraHours !== null,
                )

                const date = dayjs(selectedDate).startOf('day').toISOString()
                await handleUpdateAuthorizedHours(
                  selectedWithMorningAuthorization
                    .filter((p) => p.userId)
                    .map((p) => ({
                      date,
                      user_id: p.userId as string,
                      morningExtraHours:
                        formattedHourToMinutes(
                          p.morningExtraHours.value as string,
                        ) / 60,
                    })),
                )
              }}
            />
            <AppBarButton
              icon={<ClockIcon />}
              text={'Aut. Extras Noc.'}
              callToAction={async () => {
                const selectedWithNightAuthorization = selectedPunches.filter(
                  (p) => p.authorizedHours.nightExtraHours !== null,
                )

                const date = dayjs(selectedDate).startOf('day').toISOString()
                await handleUpdateAuthorizedHours(
                  selectedWithNightAuthorization
                    .filter((p) => p.userId)
                    .map((p) => ({
                      date,
                      user_id: p.userId as string,
                      nightExtraHours:
                        formattedHourToMinutes(
                          p.nightExtraHours.value as string,
                        ) / 60,
                    })),
                )
              }}
            />
            <AppBarButton
              icon={<ClockIcon />}
              text={'Autorizar Horas'}
              options={[
                {
                  label: 'Ordinárias Nocturas',
                  value: 'nightHours',
                },
                {
                  label: 'Extras Diurnas',
                  value: 'morningExtraHours',
                },
                {
                  label: 'Extras Nocturnas',
                  value: 'nightExtraHours',
                },
                {
                  label: 'Fer. Diurnas',
                  value: 'morningHolidayHours',
                },
                {
                  label: 'Fer. Nocturnas',
                  value: 'nightHolidayHours',
                },
                {
                  label: 'Desc. Diurnas',
                  value: 'morningDiscountHours',
                },
                {
                  label: 'Desc. Nocturnas',
                  value: 'nightDiscountHours',
                },
              ]}
              handleSelectOption={(value) => {
                const selectedWithMorningAuthorization = selectedPunches.filter(
                  (p) => (p.authorizedHours as any)[value] !== null,
                )

                const date = dayjs(selectedDate).startOf('day').toISOString()

                const params = selectedWithMorningAuthorization
                  .filter((p) => p.userId)
                  .map((p) => ({
                    date,
                    user_id: p.userId as string,
                    [value]:
                      formattedHourToMinutes(
                        p[value as keyof typeof p.authorizedHours].value as
                          | string
                          | undefined,
                      ) / 60,
                  }))

                handleUpdateAuthorizedHours(params)
              }}
            />
          </>
        )}
        {!!automaticPunchesToCreate.length && (
          <>
            <Div
              css={{
                width: 1,
                height: 50,
                background: '$interface_light_deep',
              }}
            />
            <AppBarButton
              isLoading={isLoadingCreateMultiplePunches}
              color={
                isConfirmingAutocomplete
                  ? theme.colors.status_warning_pure.value
                  : undefined
              }
              icon={isConfirmingAutocomplete ? <CheckIcon /> : <FireIcon />}
              text={
                isConfirmingAutocomplete
                  ? 'Confirmar autocompletar'
                  : 'Autocompletar Puntos'
              }
              callToAction={async () => {
                if (!isConfirmingAutocomplete) {
                  setIsConfirmingAutocomplete(true)
                  return
                }

                setIsConfirmingAutocomplete(false)

                await handleCreateMultiplePunches({
                  punches: automaticPunchesToCreate,
                })
              }}
            />
          </>
        )}
      </Div>
    </Div>
  )
}

const RawInput = styled('input', {
  all: 'unset',

  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  border: 0,
})

interface ActionItem {
  value: string
  label: string
}

interface AppBarButtonProps {
  text: string
  isLoading?: boolean
  disabled?: boolean
  icon: React.ReactNode
  Menu?: React.ReactNode
  color?: string
  callToAction?: () => void
  options?: ActionItem[]
  handleSelectOption?: (value: string) => void
}

export const AppBarButton = (props: AppBarButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOptionsOpen, setOptionsOpen] = useState(false)

  const reference = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLDivElement>(null)

  const [optionsFilterName, setOptionsFilterName] = useState('')

  useOutsideAlerters([reference, buttonRef], () => {
    setIsOpen(false)
    setOptionsOpen(false)
  })

  const optionsFiltered = useMemo(() => {
    return props.options?.filter((o) =>
      o.label.toLowerCase().includes(optionsFilterName.toLowerCase()),
    )
  }, [props.options, optionsFilterName])

  return (
    <Div
      css={{
        opacity: props.disabled || props.isLoading ? 0.5 : 1,
      }}
    >
      <Touchable
        ref={buttonRef}
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          svg: {
            height: 16,
            width: 16,
            marginBottom: 6,

            color: props.color || '$interface_dark_down',
          },
        }}
        onClick={() => {
          if (props.disabled || props.isLoading) return

          if (props.callToAction) {
            props.callToAction()
          }

          if (props.Menu) {
            setIsOpen(!isOpen)
          }

          if (props.options) {
            setOptionsOpen(!isOptionsOpen)
          }
        }}
      >
        {props.isLoading ? (
          <Div
            css={{
              marginBottom: 6,
            }}
          >
            <Progress size={'sm'} />
          </Div>
        ) : (
          <>{props.icon}</>
        )}
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            paddingLeft: '$3',
            paddingRight: '$3',
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'center',
              fontWeight: '$bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: (props.color as any) || '$interface_dark_down',
            }}
          >
            {props.text}
          </Text>
        </Div>
      </Touchable>
      {isOpen && (
        <Draggable handle=".handle">
          <Div
            ref={reference}
            css={{
              animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
              position: 'absolute',
              bottom: 'calc(100% + 16px)',
              right: 0,
              maxWidth: 400,
              maxHeight: 450,
            }}
          >
            {props.Menu}
          </Div>
        </Draggable>
      )}
      {isOptionsOpen && props.options && (
        <Div
          ref={reference}
          css={{
            animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,

            position: 'absolute',
            bottom: 'calc(100% + 16px)',
            right: 0,
            background: '$interface_light_pure',
            minWidth: 300,
            borderRadius: '$md',

            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Div
            css={{
              borderTopLeftRadius: '$md',
              borderTopRightRadius: '$md',
              display: 'flex',
              alignItems: 'center',

              background: '$interface_light_down',

              svg: {
                color: '$interface_dark_down',
                height: 24,
                width: 24,
                marginRight: '$2',
              },

              padding: '$4 $2',
            }}
          >
            <MagnifyingGlassCircleIcon />
            <RawInput
              onChange={(e) => setOptionsFilterName(e.target.value)}
              placeholder="Filtrar por nombre"
              css={{
                width: '100%',
              }}
            />
          </Div>
          <Div
            css={{
              maxHeight: 500,
              overflow: 'auto',

              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {optionsFiltered?.map((option) => (
              <Touchable
                key={option.value}
                css={{
                  padding: '$2 $4',
                  borderRadius: '$md',
                  '&:hover': {
                    background: '$interface_light_up',
                  },
                }}
                onClick={() => {
                  props.handleSelectOption?.(option.value)
                  setOptionsOpen(false)
                }}
              >
                <Text>{option.label}</Text>
              </Touchable>
            ))}
          </Div>
        </Div>
      )}
    </Div>
  )
}
