import { ICountPunchesResult } from '@/libs/react-query/types/admin'
import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'

const handleCountPunchesByCompany = async (
  startDate: string,
  endDate: string,
) => {
  const response = await apiV2.post<ICountPunchesResult[]>(
    '/admin/count-punches-by-company',
    {
      startDate,
      endDate,
    },
  )

  return response.data
}

export const useCountPunchesByCompany = (
  startDate: string,
  endDate: string,
) => {
  const query = useQuery(
    ['useCountPunchesByCompany', startDate, endDate],
    async () => {
      const result = await handleCountPunchesByCompany(startDate, endDate)
      return result
    },
    {
      enabled: !!startDate && !!endDate,
    },
  )

  return query
}
