import { Div } from '@/components/Div'
import { SmartColumnType } from '@/components/SmartTable/types'
import { ROW_HEIGHT } from '.'

interface InputCustomProps {
  column: SmartColumnType
  index: number
}

export const InputCustom = ({ index, column }: InputCustomProps) => {
  if (!column.component) {
    return null
  }

  return (
    <>
      <Div
        css={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',

          height: ROW_HEIGHT,
        }}
      >
        <column.component index={index} />
      </Div>
    </>
  )
}
