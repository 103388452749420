import { Div } from '@/components/Div'
import {
  SmartColumnType,
  SmartTableFormItemValueType,
} from '@/components/SmartTable/types'
import { ROW_HEIGHT } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Row } from '@tanstack/react-table'

interface InputExpandableProps {
  fieldValueName: string
  fieldName: string
  index: number
  column: SmartColumnType
  row: Row<any>
}

export const InputExpandable = ({
  fieldValueName,
  fieldName,
  column,
  index,
  row,
}: InputExpandableProps) => {
  const { control, setValue } = useFormContext()

  const field: SmartTableFormItemValueType = useWatch({
    control,
    name: fieldName,
  })

  return (
    <>
      <Div
        css={{
          // position: 'relative',
          // padding: '$2',
          height: ROW_HEIGHT,
          width: column.width ? column.width : undefined,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          svg: {
            cursor: 'pointer',
            width: 15,
            height: 15,

            color: '$interface_dark_down',
            strokeWidth: 2.5,

            transform: field?.value ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease-in-out', // Specify the property to transition
          },
        }}
      >
        {!!field && row.getCanExpand() && (
          <ChevronRightIcon
            onClick={() => {
              // console.log(fieldValueName)
              setValue(fieldValueName, !field.value)
              row.toggleExpanded()
              // update(index, {
              //   ...rowContent,
              //   expandable: {
              //     ...field,
              //     value: true,
              //   },
              // })
            }}
            // disabled={!!column.disabled || field?.metadata?.disabled}
            // name={fieldValueName}
          />
        )}
      </Div>
    </>
  )
}
