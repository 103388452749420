import {
  IRequestMovementCreation,
  IRequestPaymentCreation,
  IRequestVacationCreation,
} from '@/libs/react-query/types/requests/modules-dtos'
import { useMemo } from 'react'
import { RequestsInfosTableType } from '../../../form'
import { formatCurrency } from '@/utils/numbers/currency'
import { IValueTypes, valueTypeDictionary } from '@/libs/react-query/types'
import { formatPercentage } from '@/utils/numbers/percentage'
import dayjs from 'dayjs'

interface IUsersIdsInRequestParams {
  selectedRequest: RequestsInfosTableType['data'][0]
}

export const useRequestDetails = ({
  selectedRequest,
}: IUsersIdsInRequestParams) => {
  const requestDetails = useMemo(() => {
    if (selectedRequest.module.original === 'movement') {
      const requestData: IRequestMovementCreation = JSON.parse(
        selectedRequest.request_data,
      )
      const details = []
      if (requestData.description) {
        details.push(`Descripción: ${requestData.description}`)
      }
      if (requestData.should_pay_period) {
        details.push('Movimiento contabiliza como dia remunerado')
      }
      if (requestData.should_include_in_ips) {
        details.push('Movimiento contabiliza en la bases imponible del IPS')
      }
      return details
    }
    if (selectedRequest.module.original === 'payments') {
      const requestData: IRequestPaymentCreation = JSON.parse(
        selectedRequest.request_data,
      )
      const details = []
      if (requestData.description) {
        details.push(`Descripción: ${requestData.description}`)
      }
      details.push(
        `${valueTypeDictionary[requestData.value_type as IValueTypes]}: ${
          requestData.value_type === 'VALUE'
            ? formatCurrency(requestData.value)
            : formatPercentage(requestData.value)
        }`,
      )
      if (requestData.users.some((u) => u.ips)) {
        details.push(`Pago contabiliza en la bases imponible del IPS`)
      }
      return details
    }
    if (selectedRequest.module.original === 'vacation') {
      const requestData: IRequestVacationCreation = JSON.parse(
        selectedRequest.request_data,
      )
      const details = []

      if (requestData.description) {
        details.push(`Descripción: ${requestData.description}`)
      }
      if (requestData.comeback_date) {
        details.push(
          `F. Reintegro: ${dayjs(requestData.comeback_date).format(
            'DD/MM/YYYY',
          )}`,
        )
      }
      if (requestData.communication_date) {
        details.push(
          `F. Comunicación: ${dayjs(requestData.communication_date).format(
            'DD/MM/YYYY',
          )}`,
        )
      }
      if (requestData.payment_date) {
        details.push(
          `F. Pago: ${dayjs(requestData.payment_date).format('DD/MM/YYYY')}`,
        )
      }
      if (requestData.pay_within_payroll) {
        details.push(`Pagamento dentro de la planilla de salários`)
      }
      if (requestData.period) {
        details.push(
          `Período: ${+requestData.period}/${+requestData.period + 1}`,
        )
      }

      return details
    }

    return []
  }, [selectedRequest])

  return {
    requestDetails,
  }
}
