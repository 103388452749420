import { styled, Text } from '@punto-ui/react'

export const WorkersListContainer = styled('div', {
  marginTop: '$2',
  // border: 'solid 1px $interface_light_down',
  borderRadius: '$md',
})

export const TableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  border: 'solid 1px $interface_light_deep',
})

export const DomTable = styled('table', {
  flex: 1,
  borderCollapse: 'collapse',

  '& tr': {
    '&:nth-child(even)': {
      background: '$interface_light_pure',
    },
    '&:nth-child(odd)': {
      background: '$interface_light_up',
    },
  },
})

export const TableHead = styled('th', {
  // paddingBottom: '$4',
  // paddingLeft: '$1',
  paddingBottom: '$2',
  background: '$interface_light_pure',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const TableData = styled('td', {
  padding: '$1 $1',
  borderTop: 'solid 1px $interface_light_deep',
})

export const StyledContentText = styled(Text, {
  color: '$interface_dark_deep',
  fontSize: '$sm',
  textAlign: 'left',
})

export const StyledHeadingText = styled(Text, {
  color: '$interface_dark_deep',
  fontWeight: 'bold',
  fontSize: '$sm',
  textAlign: 'left',
  padding: '$3 $2',
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  padding: '$6',
})
