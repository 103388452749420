import {
  IRequestMovementCreation,
  IRequestPaymentCreation,
  IRequestVacationCreation,
} from '@/libs/react-query/types/requests/modules-dtos'
import { useMemo } from 'react'
import { RequestsInfosTableType } from '../../../form'
import dayjs from 'dayjs'

interface IUsersIdsInRequestParams {
  selectedRequest: RequestsInfosTableType['data'][0]
}

interface ICalendarRequestOptions {
  type: 'range' | 'multiple' | 'single'
  multipleDates: Date[]
  hoursEnabled: boolean
  withHours: boolean
  firstDate: Date | null
  secondDate: Date | null
}

export const useRequestCalendar = ({
  selectedRequest,
}: IUsersIdsInRequestParams) => {
  const calendarData = useMemo(() => {
    if (selectedRequest.module.original === 'movement') {
      const requestData: IRequestMovementCreation = JSON.parse(
        selectedRequest.request_data,
      )

      const movementType =
        requestData.type === 'MULTIPLE_DAYS'
          ? 'multiple'
          : requestData.type === 'SINGLE_DAY'
          ? 'single'
          : 'range'

      return {
        type: movementType,
        multipleDates:
          movementType === 'multiple'
            ? requestData.periods
                .map((p) => dayjs(p.date).toDate())
                .filter((p) => dayjs(p).isValid())
            : [],
        hoursEnabled: movementType === 'single',
        withHours: movementType === 'single',
        firstDate:
          movementType !== 'multiple'
            ? requestData.periods[0].period_start
            : null,
        secondDate:
          movementType !== 'multiple'
            ? requestData.periods[0].period_end
            : null,
      } as ICalendarRequestOptions
    }
    if (selectedRequest.module.original === 'payments') {
      const requestData: IRequestPaymentCreation = JSON.parse(
        selectedRequest.request_data,
      )

      return {
        type: 'single',
        multipleDates: [],
        hoursEnabled: false,
        withHours: false,
        firstDate: dayjs(requestData.payment_date).toDate(),
        secondDate: null,
      } as ICalendarRequestOptions
    }
    if (selectedRequest.module.original === 'vacation') {
      const requestData: IRequestVacationCreation = JSON.parse(
        selectedRequest.request_data,
      )

      return {
        type: 'multiple',
        multipleDates: requestData.dates
          .map((p) => dayjs(p).toDate())
          .filter((p) => dayjs(p).isValid()),
        hoursEnabled: false,
        withHours: false,
        firstDate: null,
        secondDate: null,
      } as ICalendarRequestOptions
    }

    return null
  }, [selectedRequest])

  return {
    calendarData,
  }
}
