import { FormRenderer } from '@/components'
import { FormContainer } from '..'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { InactivateWorkerSchema } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'
import { useFamiliarBonus } from '../form/useFamiliarBonus'

interface IFamilyBonusLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const FamilyBonusLiquidation = ({
  isOpen,
  toggleOpen,
}: IFamilyBonusLiquidationParams) => {
  const conceptKey = 'familiar_bonus'

  const { form: familyBonusForm } = useFamiliarBonus()
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [value, children] = useWatch({
    control,
    name: ['data.familiarBonus.value', 'data.familiarBonus.children'],
  })

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer
    // css={{
    //   marginBottom: 0,
    //   padding: 0,
    //   marginLeft: '$4',
    // }}
    >
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Bonificación Familiar'}
        totals={[
          `Hijos: ${children}`,
          `Valor en haber: ${formatCurrency(+value)}`,
        ]}
        conceptType="basic"
        isIpsDisabled={!concept.ips}
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && (
        <FormRenderer inline items={familyBonusForm} cols={4} />
      )}
    </FormContainer>
  )
}
