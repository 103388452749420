import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

const nationalities = ['Paraguayo', 'Brasileño']

const maritalStates = ['Soltero', 'Casado', 'Divorciado', 'Viudo']

const genders = ['Masculino', 'Femenino']

export const useOptionalDataForm = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const { control } = useFormContext<createCompleteWorkerData>()
  const selectedMaritalState = useWatch({
    control,
    name: 'marital_state',
  })
  const selectedGenderState = useWatch({
    control,
    name: 'gender',
  })
  const selectedNationality = useWatch({
    control,
    name: 'nationality',
  })

  const optionalDataForm: AddWorkerFields = useMemo(() => {
    const maritalOptions = maritalStates.map((maritalState) => ({
      label: `${maritalState}(a)`,
      value: maritalState,
    }))
    const genderOptions = genders.map((gender) => ({
      label: gender,
      value: gender,
    }))
    const nationalitiesOptions = nationalities.map((gender) => ({
      label: gender,
      value: gender,
    }))

    const defaultMarital = maritalOptions.find(
      (m) => m.value === selectedMaritalState,
    ) || {
      label: 'Soltero',
      value: 'Soltero',
    }
    const defaultGender = genderOptions.find(
      (m) => m.value === selectedGenderState,
    ) || {
      label: 'Masculino',
      value: 'Masculino',
    }
    const defaultNationality = nationalitiesOptions.find(
      (m) => m.value === selectedNationality,
    ) || {
      label: 'Paraguayo',
      value: 'Paraguayo',
    }

    return [
      {
        _type: 'input',
        label: 'Celular',
        name: 'phone',
        disabled: !isWorkerCreationAvailable,
      },
      {
        _type: 'dropdown',
        disabled: !isWorkerCreationAvailable,
        placeholder: 'Soltero',
        label: 'Estado Civil',
        name: 'marital_state',
        options: maritalStates.map((maritalState) => ({
          label: maritalState,
          value: maritalState,
        })),
        defaultOption: defaultMarital,
      },
      {
        _type: 'dropdown',
        disabled: !isWorkerCreationAvailable,
        label: 'Nacionalidad',
        options: nationalitiesOptions,
        name: 'nationality',
        defaultOption: defaultNationality,
      },
      {
        _type: 'input',
        placeholder: '01/01/2023',
        disabled: !isWorkerCreationAvailable,
        label: 'Fecha de nacimiento',
        inputType: 'datetime',
        name: 'birthday_date',
      },
      {
        _type: 'dropdown',
        label: 'Sexo',
        disabled: !isWorkerCreationAvailable,
        name: 'gender',
        defaultOption: defaultGender,
        options: genderOptions,
      },
    ]
  }, [
    isWorkerCreationAvailable,
    selectedMaritalState,
    selectedGenderState,
    selectedNationality,
  ])

  return {
    data: optionalDataForm,
  }
}
