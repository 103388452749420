import { generateRandomHour } from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const multipleShiftsColumns: IReportBuilderColumnType[] = [
  {
    type: 'shifts',
    value: 'total_hours',
    valueType: 'number' as const,
    label: 'Total Horas',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales que deberia trabajar',
  },
  {
    type: 'shifts',
    value: 'total_morning',
    valueType: 'number' as const,
    label: 'Total Diurno',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales que deberia trabajar',
  },
  {
    type: 'shifts',
    value: 'total_night',
    valueType: 'number' as const,
    label: 'Total Nocturno',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales que deberia trabajar',
  },
]
