import React, { createContext, useCallback, useState } from 'react'
import { IPayment, IPaymentsContextProps } from '@/libs/react-query/types'
import { FormProvider, useForm } from 'react-hook-form'
import { AddPaymentSimpleData, usePaymentFields } from '../form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormDrawer } from '@/components'
import { PopoverTypes, PopoverState, ContextData } from './types'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import {
  useHandleCreatePayments,
  useHandleUpdatePayments,
} from '@/libs/react-query/mutations/payments/'
import useToast from '@/hooks/useToast'
import { useCan, useDebounce } from '@/hooks'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { paymentSchema } from '../create-batch/form'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useSetAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
dayjs.extend(utc)

export const Context = createContext<ContextData>({} as ContextData)

interface ProviderProps {
  children: React.ReactNode
}

export const usePaymentContext = () => {
  const context = React.useContext(Context)

  if (!context) {
    throw new Error('usePagos must be used within a context')
  }

  return context
}

export const ContextProvider = ({ children }: ProviderProps) => {
  const canRequest = useCan(['payments.request'], true)
  const { mutateAsync: handleCreatePayment } = useHandleCreatePayments()
  const { mutateAsync: handleUpdate } = useHandleUpdatePayments()
  const { addToast } = useToast()

  const [filter, setFilter] = useState<IPaymentsContextProps>({
    page: 0,
    types: [],
    endDate: undefined,
    startDate: undefined,
    pageSize: 20,
  })

  const debounceFilter = useDebounce(filter, 500)

  const [popovers, setPopovers] = useState<PopoverState>({
    add: {
      isOpen: false,
      params: null,
    },
  })

  const { data: availableCells } = useAvailableCells([
    ...permissionsArray.filter((p) => p.includes('payments')),
    'deleted',
  ])

  const methods = useForm<AddPaymentSimpleData>({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      filters: {
        name: '',
        cellsIds: availableCells?.map((c) => c.id) || [],
        dates: [],
        categories: [],
      },
    },
  })

  useSetAvailableCells({
    methods,
    name: 'filters.cellsIds',
    permission: 'payments',
  })

  const handleClosePopover = useCallback(
    (popover: PopoverTypes) => {
      const formValues = methods.getValues()

      methods.reset({
        title: '',
        description: '',
        dates: [],
        payment_date: [],
        payment_type: 'antecipate',
        payroll_label: '',
        period_type: 'range',
        value_type: 'VALUE',
        discount_ips9: false,
        id: '',
        ips: false,

        filters: {
          name: formValues.filters?.name || '',
          cellsIds: formValues.filters?.cellsIds || [],
          categories: formValues.filters?.categories || [],
          dates: formValues.filters?.dates || [],
        },
      })

      setPopovers({
        add: {
          isOpen: false,
          params: null,
        },
      })
    },
    [methods],
  )

  const handleOpenPopover = useCallback(
    (popover: PopoverTypes, params?: IPayment) => {
      const formValues = methods.getValues()

      methods.reset({
        title: '',
        description: '',
        dates: [],
        payment_date: [],
        payment_type: 'antecipate',
        value: 0,
        value_type: 'VALUE',
        discount_ips9: false,
        payroll_label: '',
        period_type: 'range',
        usersIds: [],
        id: '',
        ips: false,
        users: [],

        filters: {
          name: formValues.filters?.name || '',
          cellsIds: formValues.filters?.cellsIds || [],
          dates: formValues.filters?.dates || [],
          categories: formValues.filters?.categories || [],
        },
      })

      if (params) {
        methods.reset({
          ...formValues,
          dates: params.dates?.[0]?.date
            ? params.dates.map((date) => dayjs(date.date).toDate())
            : params.dates?.[0]
            ? [
                dayjs(params.dates[0].start_date).toDate(),
                dayjs(params.dates[0].end_date).toDate(),
              ]
            : [],
          description: params.description || '',
          payment_date: [dayjs(params.payment_date).toDate()],
          payment_type: params.payment_type,
          payroll_label: params.payment_type,
          period_type: 'range',
          id: params.id,
          title: params.title,
          users: params.users.map((user) => ({
            id: user.user_id,
            ips: user.should_include_in_ips,
          })),
          usersIds: params.users.map((user) => user.user_id),
          value: params.value,
          value_type: params.value_type,
          discount_ips9: params.discount_ips9,
          ips: params.users.every((user) => user.should_include_in_ips),
        })
      }

      setPopovers({
        ...popovers,
        [popover]: {
          isOpen: true,
          params,
        },
      })
    },
    [popovers, methods],
  )

  const handleSubmitCreateForm = async () => {
    const data = methods.getValues()

    const isValid = await methods.trigger()

    methods.setValue(
      'dates',
      data.dates.filter((p) => p !== null && dayjs(p).isValid()),
    )

    if (!isValid) {
      console.log(methods.formState.errors)
      return
    }

    const validatedValues = methods.getValues()

    try {
      if (popovers?.add.params) {
        const payload = {
          ...validatedValues,
          users: validatedValues.usersIds.map((userId) => ({
            id: userId,
            ips: !!validatedValues.ips,
          })),
        }

        await handleUpdate({ payments: [{ ...payload }] })
        addToast({
          title: 'Pagos actualizado',
          description: 'El pagos se actualizo correctamente',
          type: 'positive',
          id: 1,
        })
      } else {
        await handleCreatePayment(validatedValues)
        addToast({
          title: canRequest ? 'Pago solicitado' : 'Pagos creado',
          description: canRequest
            ? 'Solicitación de pago creada correctamente'
            : 'El pagos se creo correctamente',
          type: 'positive',
          id: 1,
        })
      }
      handleClosePopover('add')
    } catch (error) {
      addToast({
        title: 'Error',
        description: 'Ocurrio un error al crear el pagos',
        type: 'negative',
        id: 1,
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <Context.Provider
        value={{
          filter: debounceFilter,
          setFilter,
          handleClosePopover,
          handleOpenPopover,
        }}
      >
        {children}
        <AddPaymentFormDrawer
          handleClosePopover={handleClosePopover}
          popovers={popovers}
          handleSubmitCreateForm={handleSubmitCreateForm}
        />
      </Context.Provider>
    </FormProvider>
  )
}

const AddPaymentFormDrawer = ({
  handleClosePopover,
  popovers,
  handleSubmitCreateForm,
}: any) => {
  const canRequest = useCan(['payments.request'], true)
  const { data: paymentFields } = usePaymentFields()

  return (
    <FormDrawer
      title={canRequest ? 'Solicitar Pago' : 'Agregar Pago'}
      description={
        canRequest ? 'Solicitar un nuevo pago' : 'Agrega un nuevo pago'
      }
      icon={<PlusCircleIcon width={20} color={'$brand_primary_pure'} />}
      isOpen={popovers.add.isOpen}
      handleCloseDrawer={() => handleClosePopover('add')}
      primaryButtonProps={{ label: canRequest ? 'Solicitar' : 'Agregar' }}
      handlePrimaryButton={() => handleSubmitCreateForm()}
      secondaryButtonProps={{ label: 'Cancelar' }}
      handleSecondaryButton={() => handleClosePopover('add')}
      formItems={paymentFields}
    />
  )
}
