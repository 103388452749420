import { Button } from '@punto-ui/react'
import {
  FieldValues,
  useFormContext,
  useController,
  useWatch,
} from 'react-hook-form'
import { ControlledFileInputP } from './types'
import { useRef } from 'react'
import { CloudArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Flex } from '@/components/Flex'

export const ControlledFileInput = <TFieldValues extends FieldValues>({
  onChange,
  ...props
}: ControlledFileInputP<TFieldValues>) => {
  const { setValue, control, resetField, reset, getValues } = useFormContext()
  const ref = useRef<HTMLInputElement>(null)

  const { field } = useController({
    name: props.name,
    control,
  })

  const file = useWatch({
    control,
    name: props.name,
  })

  const clearInput = () => {
    if (!ref?.current) return
    const values = getValues()

    reset({
      ...values,
      [props.name]: undefined,
    })

    setValue(props.name, undefined as any)
    resetField(props.name) //  Limpa o input no RHF
    ref.current.value = '' // Limpa o valor do input
    ref.current.files = null // Reseta os arquivos selecionados, se houver
  }

  return (
    <>
      <Flex>
        <Button
          disabled={props.disabled}
          onClick={() => ref?.current?.click()}
          type="button"
          label={file ? field?.value?.name : props.label}
          icon={props.icon ? props.icon : <CloudArrowUpIcon />}
          variant={'secondary'}
        >
          <input
            ref={ref}
            type="file"
            onChange={(event) => {
              onChange?.(event?.target?.files?.[0])
              setValue(props.name, event?.target?.files?.[0] as any)
            }}
            style={{
              display: 'none',
            }}
            {...props}
          />
        </Button>
        {file ? (
          <Button
            onClick={() => clearInput()}
            variant={'tertiaryCritical'}
            icon={<XMarkIcon />}
            css={{ marginLeft: '$2' }}
          />
        ) : null}
      </Flex>
    </>
  )
}
