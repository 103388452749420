import { Div } from '@/components/Div'
import { Button } from '@punto-ui/react'
import React from 'react'

interface ContextualMenuLayoutProps {
  children: React.ReactNode
  options: {
    icon: React.ReactNode
    onClick: () => void
    id: string
    label?: string
  }[]
}

export const ContextualMenuLayout = ({
  children,
  options,
}: ContextualMenuLayoutProps) => {
  return (
    <Div
      css={{
        borderRadius: '$md',
        minWidth: 360,
        background: '$interface_light_pure',
        border: '1px solid',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        className="handle"
        css={{
          borderTopLeftRadius: '$md',
          borderTopRightRadius: '$md',
          paddingRight: '$2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: 48,
          gap: '$2',
          background: '$interface_light_down',
          svg: { color: '$interface_dark_down' },
          cursor: 'move',
        }}
      >
        {options.map((option) => (
          <Button
            type="button"
            key={option.id}
            variant={'tertiary'}
            onClick={option.onClick}
            icon={option.icon}
            css={{
              color: '$interface_dark_down',
            }}
          >
            {option.label}
          </Button>
        ))}
      </Div>
      <Div>{children}</Div>
    </Div>
  )
}
