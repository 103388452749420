import { useFormContext, useWatch } from 'react-hook-form'
import { AddWorkerFields } from '.'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useMemo } from 'react'
import { useCan } from '@/hooks'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useAguinaldoConfigForm = () => {
  const { data: company } = useMyCompany()
  const { control } = useFormContext<createCompleteWorkerData>()
  const canChangeConfig = useCan(['workers.changeConfig'])
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const aguinaldoConfigId = useWatch({
    control,
    name: 'aguinaldo_config_id',
  })

  const selectedConfig = useMemo(() => {
    if (!aguinaldoConfigId) return null

    return company?.aguinaldoConfigGroups?.find(
      (p) => p.id === aguinaldoConfigId,
    )
  }, [aguinaldoConfigId, company])

  const paymentForm: AddWorkerFields = [
    {
      _type: 'dropdown',
      placeholder: 'Configuración de Aguinaldo',
      label: 'Configuración de Aguinaldo',
      disabled: !canChangeConfig || !isWorkerCreationAvailable,
      name: 'aguinaldo_config_id',
      options:
        company?.aguinaldoConfigGroups?.map((punchConfig) => ({
          label: punchConfig.name,
          value: punchConfig.id,
        })) || [],
      defaultOption: {
        label: selectedConfig ? selectedConfig.name : 'Seleccione una opcion',
        value: selectedConfig ? selectedConfig.id : '',
      },
    },
  ]

  return {
    data: paymentForm,
  }
}
