import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { usePayrolls } from '@/libs/react-query/hooks'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { useIsInactivateWorkerFormDisabled } from '../hooks'

export const useAguinaldoPayrolls = () => {
  const { data: payrolls } = usePayrolls()
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [aguinaldoMonths] = useWatch({
    control,
    name: ['data.aguinaldo_months'],
  })
  const form = useMemo(() => {
    const aguinaldoForm: InactiveWorkerField[] = []

    aguinaldoMonths.forEach((aguinaldoMonth, index) => {
      const item = {
        _type: 'dropdown',
        name: `data.aguinaldo_months.${index}.payrollId`,
        disabled: isLoadingLiquidation,
        label: aguinaldoMonth.label,
        disclaimer: `Valor: ${typeDecoder('money-guarani').input(
          aguinaldoMonth.value.toString(),
        )}`,
        options: payrolls?.map((payroll) => ({
          label: payroll.name,
          value: payroll.id,
        })),
        defaultOption: !aguinaldoMonth.payrollId
          ? {
              label: 'Seleccionar',
              value: '',
            }
          : {
              label: aguinaldoMonth.disclaimer,
              value: aguinaldoMonth.payrollId,
            },
      } as InactiveWorkerField
      aguinaldoForm.push(item)
    })

    return aguinaldoForm
  }, [aguinaldoMonths, payrolls, isLoadingLiquidation])

  return {
    form,
  }
}
