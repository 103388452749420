import { Div } from '@/components'
import { useSmartShift } from '../../context'
import { Text } from '@punto-ui/react'
import { SmartShift } from '../../types'
import { CloudIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef } from 'react'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { useHorizontalScroll } from '@/hooks/useHorizontalScroll'
import { GrabIcon } from '@/assets/icons/GrabIcon'
import { useCan } from '@/hooks'

export const ShiftsTopBar = () => {
  const { shifts } = useSmartShift()

  const ref = useHorizontalScroll<HTMLDivElement>()

  return (
    <Div
      ref={ref}
      css={{
        display: 'flex',
        alignItems: 'stretch',
        maxWidth: '100vw',
        overflowX: 'hidden',
        overflowY: 'hidden',

        maxHeight: 200,
        height: 200,

        paddingLeft: 16,
        paddingTop: 16,

        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      }}
    >
      {shifts?.map((shift) => (
        <Shift
          shift={shift}
          label={shift.shiftName}
          id={shift.shiftId}
          key={shift.shiftId}
          color={shift.color}
          primaryColor={shift.primaryColor}
          type={shift.daily_type}
        />
      ))}
    </Div>
  )
}

interface IShiftProps {
  id: string
  label: string
  color: string
  primaryColor: string
  type: string
  shift: SmartShift
}

const Shift = ({
  shift,
  id,
  label,
  color,
  primaryColor,
  type,
}: IShiftProps) => {
  const canManageShifts = useCan(['shifts.gestionar'])
  const draggableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!draggableRef.current || !canManageShifts) return

    return draggable({
      element: draggableRef.current,
      getInitialData: () => ({
        shift,
        type: 'shift-management',
      }), // NEW
    })
  }, [shift, canManageShifts])

  const dayWithHours = shift?.days?.find(
    (day) =>
      day.intervals[0].startTime !==
      day.intervals[day.intervals.length - 1].endTime,
  )

  return (
    <Div
      ref={draggableRef}
      css={{
        marginRight: 16,
        minWidth: 240,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '$4',
        background: color,
        borderRadius: 8,
        marginBottom: '$2',
        cursor: 'grab',
        opacity: 0.8,
        boxShadow: '0px 0px 8px 0px #343A4014',

        '&:hover': {
          opacity: 1,
          boxShadow: '0px 0px 16px 0px #343A4014',
        },
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          height: 25,
          maxHeight: 25,
          svg: {
            marginRight: '$2',
            width: 24,
            height: 24,
            color: primaryColor,
          },
        }}
      >
        {type === 'morning' ? (
          <SunIcon />
        ) : type === 'night' ? (
          <MoonIcon />
        ) : type === 'mixed' ? (
          <CloudIcon />
        ) : (
          <SunIcon />
        )}
        <Text
          variant="paragraph"
          css={{
            color: primaryColor,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {label}
        </Text>
      </Div>
      <Div
        css={{
          // height: 100,
          maxHeight: 100,
          overflow: 'hidden',

          marginTop: 8,

          '&::-webkit-scrollbar-thumb': {
            background:
              type === 'morning'
                ? '$status_warning_deep'
                : type === 'night'
                ? '$brand_primary_deep'
                : type === 'mixed'
                ? '$status_success_deep'
                : '$status_warning_deep',
            borderRadius: 2,
          },

          /* Handle on hover */
          '&::-webkit-scrollbar-thumb:hover': {
            background: '$brand_primary_deep',
          },
        }}
      >
        {dayWithHours && (
          <Text
            variant="caption"
            css={{ fontWeight: '$bold', color: '$interface_dark_deep' }}
          >
            {dayWithHours.intervals.map((interval) => (
              <Text key={interval.startTime + interval.endTime}>
                {interval.startTime} - {interval.endTime}
              </Text>
            ))}
          </Text>
        )}
      </Div>
      <Div
        css={{
          height: 32,
          marginTop: '$4',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        }}
      >
        <GrabIcon />
      </Div>
    </Div>
  )
}
