import { useMemo } from 'react'
import { AguinaldoPlaygroundFields } from '../types'

export const useAguinaldoHours = (isClosed?: boolean) => {
  const configuration: AguinaldoPlaygroundFields = useMemo(() => {
    const form: AguinaldoPlaygroundFields = [
      {
        _type: 'switch',
        label: 'Ordinárias Nocturnas',
        name: 'configuration.nightOrdinaryHours',
        disabled: isClosed,
      },
      // {
      //   _type: 'switch',
      //   label: 'Ordinárias Diurnas',
      //   name: 'configuration.morningOrdinaryHours',
      //   disabled: isClosed,
      // },
      {
        _type: 'switch',
        label: 'Extras Diurnas',
        name: 'configuration.morningExtraHours',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Extras Nocturnas',
        name: 'configuration.nightExtraHours',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Feriado Diurnas',
        name: 'configuration.morningHolidayHours',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Feriado Nocturnas',
        name: 'configuration.nightHolidayHours',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Descuento Diurno',
        name: 'configuration.morningDiscountHours',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Descuento Nocturno',
        name: 'configuration.nightDiscountHours',
        disabled: isClosed,
      },
    ]

    return form
  }, [isClosed])

  return {
    data: configuration,
  }
}
