import { ReactNode } from 'react'
import { Div } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { IReportBuilderRowTypes, ReportBuilderSchemaType } from '../../type'
import { ExpandableSelector } from '../ExpandableSelector'
import { fromRowTypeToLabel } from '../../report-builder'
import {
  ArrowPathIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  SunIcon,
  TableCellsIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'

const fromRowTypeToIcon: Record<IReportBuilderRowTypes, ReactNode> = {
  movements: <ArrowPathIcon />,
  payments: <CurrencyDollarIcon />,
  vacations: <SunIcon />,
  period: <CalendarIcon />,
  shifts: <CalendarDaysIcon />,
  payrolls: <TableCellsIcon />,
  punches: <ClockIcon />,
  sectors: <BuildingOffice2Icon />,
  workers: <UserCircleIcon />,
  day: <CalendarIcon />,
  month: <CalendarIcon />,
  year: <CalendarIcon />,
  entities: <BuildingOffice2Icon />,
  permissions: <UsersIcon />,
  sueldos: <BanknotesIcon />,
}

export const ColumnSelector = () => {
  const { control } = useFormContext<ReportBuilderSchemaType>()

  const [columns] = useWatch({
    control,
    name: ['columns'],
  })

  return (
    <Div>
      {Object.entries(columns).map(([key, columns], index) => {
        return (
          <ExpandableSelector
            key={index}
            onChange={(b: boolean) => null}
            name={`openColumns.${key}`}
            label={fromRowTypeToLabel[key as IReportBuilderRowTypes]}
            icon={fromRowTypeToIcon[key as IReportBuilderRowTypes]}
            options={columns.map((detail, detailIndex) => ({
              label: detail.label,
              name: `columns.${key}.${detailIndex}.selected`,
              value: detail.value,
              category: detail.detail_type,
            }))}
            // isCheckbox={key === 'payrolls'}
          />
        )
      })}
    </Div>
  )
}
