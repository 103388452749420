import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { PayrollListing } from '../../types/payroll'

const fetchPayrolls = async () => {
  const response = await apiV2.get<PayrollListing[]>('/payroll')

  return response.data
}

export const usePayrolls = () => {
  return useQuery(['payrolls'], fetchPayrolls)
}
