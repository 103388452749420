import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { validateFormattedMinutes } from '@/utils/date'
import { z } from 'zod'

const INVALID_HOUR_MESSAGE = 'Hora invalida'

const shiftSchema = z.object({
  date: z.string().min(1),
  dayOfWeek: z.string().min(1),
  startTime: z
    .string()
    .min(1)
    .refine((data) => validateFormattedMinutes(data), {
      message: INVALID_HOUR_MESSAGE,
    }),
  endTime: z
    .string()
    .min(1)
    .refine((data) => validateFormattedMinutes(data), {
      message: INVALID_HOUR_MESSAGE,
    }),
  switchShiftsTime: z
    .string()
    .min(1)
    .refine(
      (data) => {
        const validFormattedMinutes = validateFormattedMinutes(data)
        return validFormattedMinutes
      },
      {
        message: INVALID_HOUR_MESSAGE,
      },
    ),
  totalDaily: z
    .string()
    .min(1)
    .refine((data) => validateFormattedMinutes(data), {
      message: INVALID_HOUR_MESSAGE,
    }),
  totalNight: z
    .string()
    .min(1)
    .refine((data) => validateFormattedMinutes(data), {
      message: INVALID_HOUR_MESSAGE,
    }),
  totalHours: z
    .string()
    .min(1)
    .refine((data) => validateFormattedMinutes(data), {
      message: INVALID_HOUR_MESSAGE,
    }),
  intervals: z
    .object({
      startTime: z
        .string()
        .min(1)
        .refine((data) => validateFormattedMinutes(data), {
          message: INVALID_HOUR_MESSAGE,
        }),
      duration: z
        .string()
        .min(1)
        .refine((data) => validateFormattedMinutes(data), {
          message: INVALID_HOUR_MESSAGE,
        }),
      endTime: z
        .string()
        .min(1)
        .refine((data) => validateFormattedMinutes(data), {
          message: INVALID_HOUR_MESSAGE,
        }),
    })
    .array(),
})

export const addShiftSchema = z.object({
  shifts: shiftSchema.array(),
  name: z.string().min(1, { message: 'Nombre es requerido' }),
  code: z.string().min(1, { message: 'Código es requerido' }),
  config: z.object({
    timezone: z.string(),
    number_of_weeks: z.number(),
    entrance_tolerance_before: z.number(),
    entrance_tolerance_after: z.number(),
    leave_tolerance_before: z.number(),
    leave_tolerance_after: z.number(),
    is_smart_shift_change: z.boolean(),
    is_flexible_journey: z.boolean(),
    daily_hours_amount: z.string(),
    mixed_hours_amount: z.string(),
    night_hours_amount: z.string(),
    discount_time: z.boolean(),
    consider_holidays: z.boolean(),
    consider_tolerance: z.boolean(),
    consider_interval: z.boolean(),
    use_predefined_intervals: z.boolean(),
    flexible_morning_hours: z.string(),
    flexible_mixed_hours: z.string(),
    flexible_night_hours: z.string(),
    default_interval_night: z.string(),
    default_interval_mixed_night: z.string(),
    default_interval_mixed_morning: z.string(),
    default_interval_morning: z.string(),
    should_consider_interval_credits: z.boolean(),
    ignore_incomplete_punches: z.boolean(),
  }),
})

export type AddShiftData = z.infer<typeof addShiftSchema>
export type ShiftData = z.infer<typeof shiftSchema>
export type AddShiftFields = Array<ControlledFieldProps<AddShiftData>>
