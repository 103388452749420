import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { EditCellPayload } from '../types'
import { queryClient } from '@/libs/react-query'

export const useHandleEditCell = () => {
  const handleEditCell = async ({ name, cellId }: EditCellPayload) => {
    const apiPayload = {
      name,
      cellId,
    }

    await apiV2.patch<void>('/permission/update-cell', apiPayload)
    queryClient.invalidateQueries()
  }

  const mutation = useMutation(['useHandleEditCell'], handleEditCell)

  return mutation
}
