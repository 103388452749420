import { Chip } from '@/components/Chip'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { ICell } from '@/libs/react-query/types/organogram'
import React, { useEffect, useState } from 'react'

interface CellFilterProps {
  callback: (cell: ICell[]) => void
  policiesIds: string[]
  direction?: 'right' | 'left'
  defaultValues?: boolean
  selectedIds?: string[]
  withoutTotalButtons?: boolean
}

export const CellFilter = ({
  direction,
  callback,
  defaultValues,
  policiesIds,
  selectedIds,
  withoutTotalButtons,
}: CellFilterProps) => {
  const { data: cells } = useAvailableCells(policiesIds)
  const [options, setOptions] = useState<
    | Array<{
        label: React.ReactNode
        value: string
        selected: boolean
      }>
    | undefined
  >(
    cells?.map((cell) => {
      return {
        label: cell.name,
        value: cell.id,
        selected: defaultValues ?? false,
      }
    }),
  )

  useEffect(() => {
    const newOptions = cells?.map((cell) => {
      const isCellSelected = !!selectedIds?.find((id) => id === cell.id)
      const oldCellCopy = options?.find((option) => option.value === cell.id)

      if (!oldCellCopy)
        return {
          label: cell.name,
          value: cell.id,
          selected: isCellSelected ?? defaultValues ?? false,
        }

      return {
        ...oldCellCopy,
        selected: isCellSelected,
        label: cell.name,
      }
    })

    setOptions(newOptions)
  }, [selectedIds])

  useEffect(() => {
    const newOptions = cells?.map((cell) => {
      const selectedCell = !!selectedIds?.find((id) => id === cell.id)
      const oldCellCopy = options?.find((option) => option.value === cell.id)

      if (!oldCellCopy)
        return {
          label: cell.name,
          value: cell.id,
          selected: selectedCell ?? defaultValues ?? false,
        }

      return {
        ...oldCellCopy,
        selected: selectedCell || oldCellCopy.selected,
        label: cell.name,
      }
    })
    if (!options?.length) {
      callback?.(defaultValues ? cells || [] : [])
    } else {
      const callbackPayload =
        newOptions
          ?.filter((option) => {
            return option.selected
          })
          .map((option) => {
            return cells?.find((cell) => cell.id === option.value) as ICell
          })
          .filter((s) => Boolean(s)) ?? []
      callback?.(callbackPayload)
    }

    setOptions([...(newOptions ?? [])])
  }, [cells, defaultValues])

  return (
    <Chip
      withoutTotalButtons={withoutTotalButtons}
      dropdownSide={direction || 'right'}
      label={'Sector'}
      options={options || []}
      onChangeValue={(newOptions) => {
        setOptions(newOptions)

        const selectedCells = newOptions
          .filter((option) => option.selected)
          .map((option) => option.value)

        const cellsResult = cells?.filter((cell) => {
          return selectedCells.includes(cell.id)
        })

        callback?.(cellsResult || [])
      }}
    />
  )
}
