import {
  generateRandomDescription,
  generateRandomMovementCategory,
  generateRandomSiOrEmpty,
  generateRandomTitle,
} from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const singleMovementColumns: IReportBuilderColumnType[] = [
  {
    type: 'movements',
    value: 'title',
    label: 'Titulo',
    valueType: 'string' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomTitle,
  },
  {
    type: 'movements',
    value: 'description',
    label: 'Descripcion',
    valueType: 'string' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomDescription,
  },
  {
    type: 'movements',
    value: 'is_paid',
    label: 'Remunerado',
    valueType: 'boolean' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomSiOrEmpty,
  },
  {
    type: 'movements',
    value: 'ips',
    label: 'IPS',
    valueType: 'boolean' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomSiOrEmpty,
  },
  {
    type: 'movements',
    value: 'type',
    label: 'Tipo',
    valueType: 'string' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomMovementCategory,
  },
]
