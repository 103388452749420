import { Div } from '@/components'
import {
  ControlledCalendarInput,
  ControlledCheckbox,
  ControlledDropdown,
  ControlledInput,
  ControlledSalaryConcept,
  ControlledSwitch,
} from '@/components/Forms'
import { useCan } from '@/hooks'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { SalaryWorkerType } from '@/libs/react-query/mutations/workers/form'
import {
  PlusCircleIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Button, Divider, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsWorkerFormAvailable } from '../../../hooks/useIsWorkerFormAvailable'

export const SalaryHistory = () => {
  const methods = useFormContext<createCompleteWorkerData>()
  const isWorkerCreationAllowed = useCan(['workers.changeSalary'])
  const isWorkerCreationEnabled = useIsWorkerFormAvailable()

  const isWorkerCreationAvailable =
    isWorkerCreationAllowed && isWorkerCreationEnabled

  const salaryHistory =
    useWatch({
      control: methods.control,
      name: 'salaryHistory',
    }) || []

  const handleAddSalaryGroup = () => {
    methods.setValue('salaryHistory', [
      ...salaryHistory,
      {
        id: Date.now().toString(),
        start_date: [],
        is_raw_value: false,
        value: 0,
        group_id: null,
        concepts: [],
      },
    ])
  }

  return (
    <Div
      css={{
        padding: '$4',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '$4',
        }}
      >
        <Text
          size="xl"
          css={{
            color: '$interface_dark_deep',
            fontWeight: '$bold',
          }}
        >
          Historico Salarial del Colaborador
        </Text>
      </Div>

      <Div
        css={{
          maxWidth: 'calc(100vw - 80px)',
          overflowY: 'auto',
          display: 'flex',
          alignItems: 'flex-start',
          minHeight: 450,
        }}
      >
        {salaryHistory.map((history, index) => {
          return (
            <SalaryGroupComponent
              history={history}
              index={index}
              key={history.id}
            />
          )
        })}
        {isWorkerCreationAvailable && (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '$4',
              minWidth: 60,
            }}
          >
            <Div
              onClick={handleAddSalaryGroup}
              css={{
                cursor: 'pointer',

                height: 36,
                width: 36,
                background: '$brand_primary_pure',
                borderRadius: '$full',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                svg: {
                  height: 24,
                  width: 24,
                  color: '$interface_light_pure',
                },
              }}
            >
              <PlusIcon />
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  )
}

const SalaryGroupComponent = ({
  history,
  index,
}: {
  index: number
  history: SalaryWorkerType
}) => {
  const { data: company } = useMyCompany()
  const methods = useFormContext<createCompleteWorkerData>()

  const isWorkerCreationAllowed = useCan(['workers.changeSalary'])
  const isWorkerCreationEnabled = useIsWorkerFormAvailable()

  const isWorkerCreationAvailable =
    isWorkerCreationAllowed && isWorkerCreationEnabled

  const handleAddConcept = () => {
    methods.setValue(`salaryHistory.${index}.concepts`, [
      ...methods.getValues(`salaryHistory.${index}.concepts`),
      {
        id: Date.now().toString(),
        name: '',
        value: 0,
        description: '',
        ips: false,
      },
    ])
  }

  const selectedGroup = company?.salaryGroups?.find(
    (group) => group.id === history.group_id,
  )

  return (
    <Div
      css={{
        display: 'flex',
        maxHeight: 600,
        // overflow: 'hidden',
        alignItems: 'flex-start',
        // minWidth: 550,
        // minWidth: index ? 500 : 300,
      }}
    >
      {!!index && (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              height: 1,
              minWidth: 50,
              maxWidth: 50,
              background: '$interface_light_down',
            }}
          />
          <Div
            css={{
              maxWidth: 130,
              minWidth: 130,
              marginBottom: 24,
            }}
          >
            <ControlledCalendarInput
              label="Fecha de cambio"
              name={`salaryHistory.${index}.start_date`}
              calendarType={'single'}
              maxDates={60}
              disabled={!isWorkerCreationAvailable}
            />
          </Div>
          <Div
            css={{
              height: 1,
              width: 50,
              background: '$interface_light_down',
            }}
          />
        </Div>
      )}

      <Div
        css={{
          maxHeight: 600,

          maxWidth: 300,
          minWidth: 300,
          // height: 400,
          border: '1px solid $interface_light_down',
          borderRadius: '$md',

          background: '$interface_light_pure',

          display: 'flex',
          flexDirection: 'column',
          padding: '$4',
          gap: '$4',
        }}
      >
        {history.is_raw_value ? (
          <ControlledInput
            label="Valor"
            name={`salaryHistory.${index}.value`}
            inputType="money-guarani"
            disabled={!isWorkerCreationAvailable}
          />
        ) : (
          <ControlledDropdown
            name={`salaryHistory.${index}.group_id`}
            label="Grupo de Salario"
            disabled={!isWorkerCreationAvailable}
            options={
              company?.salaryGroups.map((s) => ({
                label: s.name,
                value: s.id,
              })) || []
            }
            defaultOption={{
              label: selectedGroup?.name ?? 'Seleccione un grupo',
              value: selectedGroup?.id ?? '',
            }}
          />
        )}
        <ControlledSwitch
          label="Valor personalizado"
          name={`salaryHistory.${index}.is_raw_value`}
          disabled={!isWorkerCreationAvailable}
        />

        <Div>
          <Divider
            css={{
              width: '100%',
              height: 1,
            }}
          />
        </Div>

        <Div
          css={{
            overflowY: 'auto',
            overflowX: 'hidden',

            // maxHeight: 300,
            flex: 1,
            paddingLeft: 8,
            paddingRight: 8,

            display: 'flex',
            flexDirection: 'column',
            gap: '$4',
          }}
        >
          {history.concepts.map((concept, conceptIndex) => {
            return (
              <>
                <SalaryConceptComponent
                  historyIndex={index}
                  conceptIndex={conceptIndex}
                  key={concept.id}
                />
                <Div>
                  <Divider
                    css={{
                      width: '100%',
                      height: 1,
                    }}
                  />
                </Div>
              </>
            )
          })}
          <Div
            css={{
              width: '100%',
            }}
          >
            <Button
              type="button"
              css={{
                width: '100%',
              }}
              onClick={handleAddConcept}
              variant="secondary"
              icon={<PlusCircleIcon />}
              disabled={!isWorkerCreationAvailable}
            >
              Agregar Concepto
            </Button>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

const SalaryConceptComponent = ({
  historyIndex,
  conceptIndex,
}: {
  conceptIndex: number
  historyIndex: number
}) => {
  const methods = useFormContext<createCompleteWorkerData>()
  const isWorkerCreationAllowed = useCan(['workers.changeSalary'])
  const isWorkerCreationEnabled = useIsWorkerFormAvailable()

  const isWorkerCreationAvailable =
    isWorkerCreationAllowed && isWorkerCreationEnabled

  const handleRemoveConcept = () => {
    const concepts = methods.getValues(`salaryHistory.${historyIndex}.concepts`)
    concepts.splice(conceptIndex, 1)
    methods.setValue(`salaryHistory.${historyIndex}.concepts`, concepts)
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$2',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <ControlledSalaryConcept
          name={`salaryHistory.${historyIndex}.concepts.${conceptIndex}.name`}
          options={[]}
          defaultOption={{
            label: '',
            value: '',
          }}
          disabled={!isWorkerCreationAvailable}
        />
        <Button
          type="button"
          onClick={handleRemoveConcept}
          css={{
            height: 34,
          }}
          disabled={!isWorkerCreationAvailable}
          icon={<TrashIcon />}
          variant={'secondaryCritical'}
        />
      </Div>
      <ControlledInput
        label="Valor del Concepto"
        name={`salaryHistory.${historyIndex}.concepts.${conceptIndex}.value`}
        inputType="money-guarani"
        disabled={!isWorkerCreationAvailable}
      />
      <ControlledCheckbox
        label="IPS"
        disabled={!isWorkerCreationAvailable}
        name={`salaryHistory.${historyIndex}.concepts.${conceptIndex}.ips`}
      />
    </Div>
  )
}
