import * as Accordion from '@radix-ui/react-accordion'
import { keyframes, styled } from '@/styles'

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
})

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
})

export const AccordionRoot = styled(Accordion.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '5px',
})

export const AccordionItem = styled(Accordion.Item, {
  width: '$full',
  flexDirection: 'column',
  overflow: 'hidden',
})

export const AccordionHeader = styled(Accordion.Header, {
  all: 'unset',
  margin: '$4 0',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  svg: {
    transition: 'transform 500ms cubic-bezier(0.87, 0, 0.13, 1)',
    '[data-state=open] &': {
      transform: 'rotate(180deg)',
    },
  },

  '&[data-state="open"]': {
    svg: {
      transform: 'rotate(180deg)',
      color: '$brand_primary_pure',
    },
  },
})

export const AccordionTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  display: 'flex',
  borderRadius: '5px',
  transition: '300ms cubic-bezier(0.87, 0, 0.13, 1)',
  color: '$interface_dark_deep',
  backgroundColor: 'transparent',
  border: 'none',
  fontWeight: '$bold',
  fontSize: '$3xl',
  fontFamily: '$default',
  width: '100%',
  margin: 0,

  '&[data-state="open"]': {
    color: '$brand_primary_pure',
  },
})

export const AccordionContent = styled(Accordion.Content, {
  overflow: 'hidden',
  width: '100%',
  color: '$interface_dark_down',
  margin: 0,
  marginTop: '$4',
  marginBottom: '$6',
  fontFamily: '$default',
  textAlign: 'justify',

  '&[data-state="open"]': {
    animation: `${slideDown} 400ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 400ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
})
