import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useInputDatePicker } from '..'
import {
  Filter,
  FiltersContainer,
  IconButton,
  OptionsContainer,
} from './styles'
import { useMemo, useState } from 'react'
import { allMonths } from '../utils'
import dayjs from 'dayjs'
import { Button, Text, styled } from '@punto-ui/react'

const Touchable = styled('button', {
  all: 'unset',
  cursor: 'pointer',
})

export const Filters = () => {
  const { setCalendarDate, calendarDate } = useInputDatePicker()

  const allYearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const last10Years = Array.from(
      { length: 10 },
      (_, i) => currentYear - i - 1,
    )
    const next10Years = Array.from(
      { length: 10 },
      (_, i) => currentYear + i + 1,
    )

    const allYears = [
      ...last10Years.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
      {
        value: currentYear.toString(),
        label: currentYear.toString(),
      },
      ...next10Years.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
    ]

    allYears.sort((a, b) => Number(a.value) - Number(b.value))

    return allYears
  }, [])

  const [isMonthFilterOpen, setIsMonthFilterOpen] = useState(false)
  const [isYearFilterOpen, setIsYearFilterOpen] = useState(false)

  return (
    <FiltersContainer>
      <Filter>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.subtract(1, 'month'))
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Touchable
          type="button"
          onClick={() => setIsMonthFilterOpen((state) => !state)}
        >
          <Text variant={'caption'} css={{ color: '$brand_primary_pure' }}>
            {calendarDate.format('MMM')}
          </Text>
        </Touchable>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.add(1, 'month'))
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {isMonthFilterOpen && (
          <OptionsContainer>
            {allMonths.map((month) => (
              <Button
                type="button"
                css={{ width: '100%', marginBottom: '$2' }}
                variant="secondary"
                key={month.value}
                onClick={() => {
                  setCalendarDate((prev) => prev.month(parseInt(month.value)))
                  setIsMonthFilterOpen(false)
                }}
              >
                {month.label}
              </Button>
            ))}
          </OptionsContainer>
        )}
      </Filter>
      <Filter>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.subtract(1, 'year'))
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Touchable
          type="button"
          onClick={() => setIsYearFilterOpen((state) => !state)}
        >
          <Text variant={'caption'}>{calendarDate.format('YYYY')}</Text>
        </Touchable>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.add(1, 'year'))
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {isYearFilterOpen && (
          <OptionsContainer>
            {allYearsOptions.map((year) => (
              <Button
                type="button"
                css={{ width: '100%', marginBottom: '$2' }}
                variant="secondary"
                key={year.value}
                onClick={() => {
                  setCalendarDate((prev) => prev.year(parseInt(year.value)))
                  setIsYearFilterOpen(false)
                }}
              >
                {year.label}
              </Button>
            ))}
          </OptionsContainer>
        )}
      </Filter>
      <Filter>
        <Touchable type="button" onClick={() => setCalendarDate(dayjs())}>
          <Text variant={'caption'}>Hoy</Text>
        </Touchable>
      </Filter>
    </FiltersContainer>
  )
}
