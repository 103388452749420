import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdateShiftsParams } from '@/pages/turnos/components/SmartShiftManagement/context'
import dayjs from 'dayjs'
import { queryClient } from '@/libs/react-query'

interface PeriodDate {
  startDate: Date
  endDate: Date
}
interface Periods {
  dates: Array<PeriodDate>
}

const handleCreateShiftSchedule = async ({
  params,
  shiftId,
  isRest,
}: {
  params: UpdateShiftsParams[]
  shiftId: string
  isRest?: boolean
}) => {
  const batches = params.map((c) => {
    const allDates = c.dates.map((date) => dayjs(date).toDate())
    const convertedDates = isRest
      ? ({
          dates: allDates.map((date) => {
            return {
              startDate: date,
              endDate: date,
            }
          }),
        } as Periods)
      : convertDatesToPeriods(allDates)
    return {
      dates: convertedDates.dates,
      userId: c.userId,
    }
  })

  const payload = {
    batches: batches.filter((b) => b.dates.length),
    shiftId,
    isRest,
  }

  await apiV2.post('/shift/create-scheduled-shift', {
    ...payload,
  })

  queryClient.invalidateQueries()
}

export const useHandleCreateShiftSchedule = () => {
  const mutation = useMutation(
    ['useHandleCreateShiftSchedule'],
    handleCreateShiftSchedule,
  )

  return mutation
}

function convertDatesToPeriods(dates: Date[]): Periods {
  // Convert JS dates to Day.js dates and sort them in ascending order.
  const dayjsDates = dates.map((date) => dayjs(date))
  dayjsDates.sort((a, b) => (a.isBefore(b) ? -1 : 1))

  const periods: Periods = { dates: [] }
  let currentPeriod: PeriodDate | null = null

  for (let i = 0; i < dayjsDates.length; i++) {
    // If there is no current period, start a new one.
    if (!currentPeriod) {
      currentPeriod = {
        startDate: dayjsDates[i].toDate(),
        endDate: dayjsDates[i].toDate(),
      }
    }
    // If the current date is not the next day after the end of the current period,
    // finish the current period and start a new one.
    else if (
      !dayjsDates[i].isSame(dayjs(currentPeriod.endDate).add(1, 'day'), 'day')
    ) {
      periods.dates.push(currentPeriod)
      currentPeriod = {
        startDate: dayjsDates[i].toDate(),
        endDate: dayjsDates[i].toDate(),
      }
    }
    // If the current date is the next day after the end of the current period, extend the current period.
    else {
      currentPeriod.endDate = dayjsDates[i].toDate()
    }
  }

  // Push the last period, if any.
  if (currentPeriod) {
    periods.dates.push(currentPeriod)
  }

  return periods
}
