import { useMemo } from 'react'
import { ConfigFields, ConfigSchema } from '../../..'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCan } from '@/hooks'

export const useSalaryGroupForm = (isCreation = false) => {
  const canCreateSalary = useCan(['configurations.salary'])
  const { control } = useFormContext<ConfigSchema>()
  const type = useWatch({
    control,
    name: 'salaryGroup.type',
  })

  const form = useMemo(() => {
    const moneyForm: ConfigFields = [
      {
        _type: 'input',
        label: 'Nombre del grupo de salarios',
        name: 'salaryGroup.name',
        disabled: !canCreateSalary,
      },
      {
        _type: 'input',
        disabled: !canCreateSalary,
        label: 'Descripción',
        name: 'salaryGroup.description',
      },
    ]

    if (type || isCreation) {
      moneyForm.push({
        _type: 'input',
        disabled: !canCreateSalary,
        label: 'Tipo del Salario',
        name: 'salaryGroup.type',
        placeholder: 'Costurero, Administrativo, etc',
      })
    } else {
      moneyForm.push({
        _type: 'input',
        label: 'Tipo del Salario',
        name: 'salaryGroup.type',
        placeholder: 'SALARIO MÍNIMO VITAL',
        disabled: true,
      })
    }

    return moneyForm
  }, [type, isCreation, canCreateSalary])

  return {
    data: form,
  }
}
