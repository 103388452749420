import { useWorkerLiquidation } from '@/libs/react-query/hooks/useWorkerLiquidation'
import { useUpdateUserLiquidation } from '@/libs/react-query/mutations/company/useUpdateUserLiquidation'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { useUpdateUsersCompanyConfigs } from '@/libs/react-query/mutations/company/useHandleUpdateUsersCompanyConfigs'
import { useUpdateUserLiquidationConcepts } from '@/libs/react-query/mutations/company/useHandleUpdateLiquidationConcepts'
import { useUpdateUserLiquidationConcept } from '@/libs/react-query/mutations/company/useHandleUpdateLiquidationConcept'
import { validate as validateUuid } from 'uuid'
import { useTabStore } from '@/store'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useCan } from '@/hooks'

export const useIsInactivateWorkerFormDisabled = () => {
  const canEditLiquidation = useCan([
    'workers.all',
    'workers.editLiquidation',
    'workers.liquidation',
  ])
  const { data: isLiquidationAvailable } = useIsFeatureInPlan('liquidation')
  const methods = useFormContext<InactivateWorkerSchema>()
  const [status, enabledChanges, liquidationId] = useWatch({
    control: methods.control,
    name: ['data.status', 'enabled_changes', 'liquidationId'],
  })

  const { activeTab } = useTabStore((state) => ({
    activeTab: state.worker.activeTab,
  }))
  const { isFetching: isLoadingLiquidation } = useWorkerLiquidation({
    userId: validateUuid(activeTab.replace('-inactivate', ''))
      ? activeTab.replace('-inactivate', '')
      : '',
    liquidationId,
  })

  const { isLoading: isLoadingUpdateLiquidation } = useUpdateUserLiquidation()
  const { isLoading: isFetchingUsersConfigs } = useUpdateUsersCompanyConfigs()
  const { isLoading: isLoadingConceptsUpdate } =
    useUpdateUserLiquidationConcepts()
  const { isLoading: isLoadingConceptUpdate } =
    useUpdateUserLiquidationConcept()

  const isLoadingAnyActivity =
    isFetchingUsersConfigs ||
    isLoadingLiquidation ||
    isLoadingUpdateLiquidation ||
    isLoadingConceptUpdate ||
    isLoadingConceptsUpdate

  return (
    !canEditLiquidation ||
    isLoadingAnyActivity ||
    (status === 'done' && !enabledChanges) ||
    !isLiquidationAvailable
  )
}
