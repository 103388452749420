import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IDocument } from '../../types/documents'

const fetchDocuments = async (ids: string[]) => {
  const response = await apiV2.post<IDocument[]>(
    '/documents/get-documents-by-ids',
    {
      ids,
    },
  )

  return response.data
}

export const useDocumentsFiles = (ids: string[]) => {
  const query = useQuery(
    ['documents-files', ids],
    async () => await fetchDocuments(ids),
    {
      enabled: !!ids.length,
    },
  )

  return query
}
