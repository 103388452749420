import { match } from 'ts-pattern'

function getDefaultTranslation() {
  return {
    // eslint-disable-next-line quote-props
    '9': function (val: string) {
      return val.replace(/[^0-9]+/g, '')
    },
    A: function (val: string) {
      return val.replace(/[^a-zA-Z]+/g, '')
    },
    S: function (val: string) {
      return val.replace(/[^a-zA-Z0-9]+/g, '')
    },
    '*': function (val: string) {
      return val
    },
  } as const
}

export type MaskPattern = 'no-mask' | string

export function encodeByPattern(value: string, mask: MaskPattern) {
  if (mask === 'no-mask') {
    return value
  }
  let result = ''
  let maskCharIndex = 0
  let valueCharIndex = 0

  while (true) {
    // if mask is ended, break.
    if (maskCharIndex === mask.length) {
      break
    }

    // if value is ended, break.
    if (valueCharIndex === value.length) {
      break
    }

    const maskChar: keyof ReturnType<typeof getDefaultTranslation> = mask[
      maskCharIndex
    ] as any
    const valueChar = value[valueCharIndex]

    // value equals mask, just set
    if (maskChar === valueChar) {
      result += maskChar
      valueCharIndex += 1
      maskCharIndex += 1
      continue
    }

    // apply translator if match
    const translationHandler = getDefaultTranslation()[maskChar]

    if (translationHandler) {
      const resolverValue = translationHandler(valueChar || '')
      if (resolverValue === '') {
        // valueChar replaced so don't add it to result, keep the mask at the same point and continue to next value char
        valueCharIndex += 1
        continue
      } else if (resolverValue !== null) {
        result += resolverValue
        valueCharIndex += 1
      } else {
        result += maskChar
      }
      maskCharIndex += 1
      continue
    }

    // not masked value, fixed char on mask
    result += maskChar
    maskCharIndex += 1
    continue
  }

  return result
}
export type toPatternTypes =
  | 'credit-card'
  | 'email'
  | 'zip-code'
  | 'only-numbers'
  | 'money'
  | 'money-usd'
  | 'cel-phone'
  | 'datetime'
  | 'no-mask'
  | 'credit-card-expiration-date'
  | 'name'
  | 'account'
  | 'money-guarani'
  | 'percentage'
  | 'percentage-with-decimal'
  | 'hour'
  | 'long-hour'
  | 'only-numbers-or-empty'
  | 'decimal-or-empty'
  | 'formatted-number'

export const safeOnlyDigits = (value: string | number, length = 0) => {
  const regex = /[^0-9-]/g // Exclude negative symbol

  if (typeof value === 'number') {
    if (length) return String(value).replace(regex, '').slice(0, length)
    return String(value).replace(regex, '')
  }
  if (length) return value.replace(regex, '').slice(0, length)
  return value.replace(regex, '')
}

const emailMask = (value: string): string => {
  // This regex allows for lowercase and uppercase letters, digits, dots, hyphens, underscores, and the @ symbol
  // Adjust the regex if additional characters need to be supported based on your specific requirements
  return value.replace(/[^a-zA-Z0-9@._-]+/g, '')
}

export const safeOnlyDigitsWithoutNegative = (
  value: string | number,
  length = 0,
) => {
  const regex = /[^0-9]/g // Exclude negative symbol

  if (typeof value === 'number') {
    if (length) return String(value).replace(regex, '').slice(0, length)
    return String(value).replace(regex, '')
  }
  if (length) return value.replace(regex, '').slice(0, length)
  return value.replace(regex, '')
}

export const typeDecoder = (type: toPatternTypes) => {
  return match(type)
    .with('datetime', () => ({
      input: (value: string) => encodeByPattern(value, '99/99/9999'),
      output: (value: string) => encodeByPattern(value, '99/99/9999'),
    }))
    .with('percentage', () => ({
      input: (value: string | number) => {
        if (!value || value === '%') {
          return ''
        } else {
          // const pureValue = (
          //   parseInt(value.replace(/[^0-9]/g, '')) * 0.01
          // ).toLocaleString('pt-BR', { style: 'percent' })

          return String(value).replace(/[^0-9]/g, '') + ' %'
        }
      },
      output: (pureValue: string) => {
        const originalValue = pureValue.replace(/%/g, '').replace(/\./g, '')
        return +originalValue
      },
    }))
    .with('email', () => ({
      input: (value: string) => emailMask(value),
      output: (value: string) => emailMask(value),
    }))
    .with('formatted-number', () => ({
      input: (value: string | number): string => {
        if (value === '-' || value === '') return value.toString()
        const formatted = new Intl.NumberFormat('es-PY', {
          useGrouping: true,
        }).format(Number(value))
        return formatted
      },
      output: (value: string): number => {
        const cleaned = value.replace(/,/g, '')
        return Number(cleaned)
      },
    }))
    .with('percentage-with-decimal', () => ({
      input: (value: string | number) => {
        if (!value || value === '%' || value === ' %') {
          return ''
        } else {
          const pureValue =
            String(value)
              .replace(/[^0-9.,]/g, '')
              .replace('.', ',') + ' %'

          return pureValue
        }
      },
      output: (pureValue: string) => {
        const originalValue = pureValue
          .replace(/%/g, '')
          .replace(',', '.')
          .replaceAll(' ', '')

        return originalValue
      },
    }))
    .with('zip-code', () => ({
      input: (value: string) => encodeByPattern(value, '99999-999'),
      output: (value: string) => safeOnlyDigits(value, 8),
    }))
    .with('credit-card', () => ({
      input: (value: string) => encodeByPattern(value, '9999 9999 9999 9999'),
      output: (value: string) => encodeByPattern(value, '9999 9999 9999 9999'),
    }))
    .with('money', () => ({
      input: (value: string | number = ''): string => {
        if (!value && value !== 0) return ''
        const formatted = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(+value)

        return formatted
      },
      output: (value = '') => {
        const onlyDigits = value.replace(/[^0-9,-]/g, '').replace(',', '.')
        const [, afterDot] = onlyDigits.split('.')

        if (!afterDot) {
          return parseFloat(onlyDigits) / 100
        }

        if (afterDot.length === 3) {
          return parseFloat(onlyDigits) * 10
        } else if (afterDot.length === 1) {
          return parseFloat(onlyDigits) / 10
        }

        return parseFloat(onlyDigits)
      },
    }))
    .with('money-usd', () => ({
      input: (value: string | number = ''): string => {
        if (!value && value !== 0) return ''
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(+value)

        return formatted
      },
      output: (value = '') => {
        const onlyDigits = value.replace(/[^0-9.-]/g, '')
        const [, afterDot] = onlyDigits.split('.')

        if (!afterDot) {
          return parseFloat(onlyDigits)
        }

        if (afterDot.length === 3) {
          return parseFloat(onlyDigits) * 10
        } else if (afterDot.length === 1) {
          return parseFloat(onlyDigits) / 10
        }

        return parseFloat(onlyDigits)
      },
    }))
    .with('money-guarani', () => ({
      input: (value: string | number = ''): string => {
        if (!value && value !== 0) return ''
        if (value === '-') return 'G$ -'
        const formatted = new Intl.NumberFormat('es-PY', {
          style: 'currency',
          currency: 'PYG',
        }).format(+value)

        return formatted
      },
      output: (value = '') => {
        const onlyDigits = value.replace(/[^0-9,-]/g, '')
        if (!onlyDigits.length) return ''
        if (!value) return 0
        const onlyNumbers = onlyDigits.replace(',', '.') || '0'

        if (onlyNumbers === '-') return onlyNumbers

        const parsed = parseFloat(onlyNumbers)
        return parsed
      },
    }))
    .with('only-numbers', () => ({
      input: (value: string) => {
        if (value === '-') return '-'
        return +safeOnlyDigits(value)
      },
      output: (value: string) => +value,
    }))
    .with('only-numbers-or-empty', () => ({
      input: (value: string) => {
        if (value === '-') return '-'
        return value === '' ? '' : safeOnlyDigits(value)
      },
      output: (value: string) => {
        if (value === '-') return '-'
        return value === '' ? '' : +value
      },
    }))
    .with('hour', () => ({
      input: (value: string) => encodeByPattern(value, '99:99'),
      output: (value: string) => encodeByPattern(value, '99:99'),
    }))
    .with('long-hour', () => ({
      input: (value: string) => {
        const twoDigitHour = value.length <= 5

        if (twoDigitHour) {
          return encodeByPattern(value, '99:99')
        }

        return encodeByPattern(value, '999:99')
      },
      output: (value: string) => {
        const twoDigitHour = value.length === 5

        if (twoDigitHour) {
          return encodeByPattern(value, '99:99')
        }

        return encodeByPattern(value, '999:99')
      },
    }))
    .with('no-mask', () => ({
      input: (value: string) => value,
      output: (value: string) => value,
    }))
    .with('credit-card-expiration-date', () => ({
      input: (value: string) => encodeByPattern(value, '99/99'),
      output: (value: string) => encodeByPattern(value, '99/99'),
    }))
    .with('account', () => ({
      input: (value: string) => encodeByPattern(value, '999.999-9'),
      output: (value: string) => {
        return safeOnlyDigits(value, 7)
      },
    }))
    .with('decimal-or-empty', () => ({
      input: (value: string) => {
        if (value === '-') return '-'
        return value === '' ? '' : `${value}`.replace('.', ',')
      },
      output: (value: string) => {
        if (value === '-') return '-'
        return value === '' ? '' : value.replace(',', '.')
      },
    }))
    .otherwise((value: string) => ({
      input: (value: string) => value,
      output: (value: string) => value,
    }))
}

export const effectDecoder = (type: toPatternTypes) => {
  return match(type)
    .with('percentage', () => ({
      execute: (ref: HTMLInputElement | null, value: string | number) => {
        ref?.setSelectionRange(
          value?.toString().length,
          value?.toString().length,
        )
      },
    }))
    .with('percentage-with-decimal', () => ({
      execute: (ref: HTMLInputElement | null, value: string | number) => {
        ref?.setSelectionRange(
          value?.toString().length,
          value?.toString().length,
        )
      },
    }))
    .with('formatted-number', () => ({
      execute: (ref: HTMLInputElement | null, value: string | number) => {
        ref?.setSelectionRange(
          value?.toString().length,
          value?.toString().length,
        )
      },
    }))
    .otherwise(() => ({
      execute: () => null,
    }))
}

export const blurDecoded = (type: toPatternTypes) => {
  return match(type)
    .with('money-guarani', () => ({
      execute: (value: string) => {
        if (value === '-') {
          return 0
        }
        return value
      },
    }))
    .with('only-numbers', () => ({
      execute: (value: string) => {
        if (value === '-') {
          return 0
        }
        return value
      },
    }))
    .with('percentage-with-decimal', () => ({
      execute: (value: string) => {
        console.log('on blur', value)
        return +value
      },
    }))
    .with('only-numbers-or-empty', () => ({
      execute: (value: string) => {
        if (value === '-') {
          return 0
        }
        return value
      },
    }))
    .otherwise(() => ({
      execute: null,
    }))
}
