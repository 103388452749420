import React, { createContext, useState } from 'react'
import { ContextData, PopoverState, PopoverTypes } from './types'
import { usePolicies } from '@/libs/react-query/hooks'
import { Div, Flex, ViewDrawer } from '@/components'
import { IPolicy } from '@/libs/react-query/types'
import { Avatar, DrawerContainer, Progress, Text } from '@punto-ui/react'
import dayjs from 'dayjs'
import { IWorkerUser } from '@/libs/react-query/types/worker'

export const Context = createContext<ContextData>({} as ContextData)

interface PoliciesProviderProps {
  children: React.ReactNode
}

export const PoliciesProvider = ({ children }: PoliciesProviderProps) => {
  const { data: policies, isLoading: isLoadingPolicies } = usePolicies()

  const [popovers, setPopovers] = useState<PopoverState>({
    view: {
      isOpen: false,
      params: undefined,
    },
  })

  const handleClosePopover = (popover: PopoverTypes) => {
    setPopovers({
      ...popovers,
      [popover]: {
        isOpen: false,
        params: {},
      },
    })
  }

  const handleOpenPopover = (popover: PopoverTypes, params?: IPolicy) => {
    setPopovers({
      ...popovers,
      [popover]: {
        isOpen: true,
        params,
      },
    })
  }

  if (isLoadingPolicies || !policies) {
    return (
      <Div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: 64,
        }}
      >
        <Progress />
      </Div>
    )
  }

  return (
    <DrawerContainer>
      <Context.Provider
        value={{
          policies,
          isLoadingPolicies,
          handleClosePopover,
          handleOpenPopover,
        }}
      >
        {children}
        <ViewDrawer
          title={popovers?.view?.params?.name || 'Detalle de la política'}
          description={
            'Politica creada en ' +
            dayjs(popovers?.view?.params?.created_at).format(
              'DD/MM/YYYY - HH:mm',
            )
          }
          isOpen={popovers.view.isOpen}
          handleCloseDrawer={() => handleClosePopover('view')}
        >
          <Text>Membros</Text>
          {popovers?.view?.params?.users?.map((worker) => {
            return <WorkerItem key={worker.id} worker={worker} />
          })}
        </ViewDrawer>
      </Context.Provider>
    </DrawerContainer>
  )
}

const WorkerItem = ({ worker }: { worker?: IWorkerUser }) => {
  return (
    <Flex
      css={{
        alignItems: 'center',
        padding: '$4',
        border: '1px solid $interface_dark_deep',
        borderRadius: '$sm',
      }}
    >
      <Avatar src={worker?.photo_url || ''} height={40} width={40} />
      <Div
        css={{
          marginLeft: '$2',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text>{worker?.name}</Text>
        <Text>{worker?.document}</Text>
      </Div>
    </Flex>
  )
}
