import {
  generateRandomBank,
  generateRandomCity,
  generateRandomCivilState,
  generateRandomCodes,
  generateRandomConfiguration,
  generateRandomCountry,
  generateRandomDate,
  generateRandomDocuments,
  generateRandomEmails,
  generateRandomGender,
  generateRandomNamePair,
  generateRandomNationalities,
  generateRandomNumberUnder100,
  generateRandomNumberUnder10000000,
  generateRandomNumberUnderBigMoney,
  generateRandomPhones,
  generateRandomSectors,
  generateRandomState,
} from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const singleWorkersColumns: IReportBuilderColumnType[] = [
  {
    type: 'workers',
    value: 'name',
    valueType: 'string' as const,
    label: 'Nombre',
    subgroup: 'Informaciones del trabajador',
    getRandomPlaceholder: () => generateRandomNamePair().name,
  },
  {
    type: 'workers',
    value: 'surname',
    valueType: 'string' as const,
    label: 'Apellido',
    subgroup: 'Informaciones del trabajador',
    getRandomPlaceholder: () => generateRandomNamePair().surname,
  },
  {
    type: 'workers',
    value: 'email',
    valueType: 'string' as const,
    label: 'E-mail',
    subgroup: 'Informaciones del trabajador',
    getRandomPlaceholder: generateRandomEmails,
  },
  {
    type: 'workers',
    value: 'document',
    valueType: 'string' as const,
    label: 'CI',
    getRandomPlaceholder: generateRandomDocuments,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    value: 'phone',
    valueType: 'string' as const,
    label: 'Celular',
    getRandomPlaceholder: generateRandomPhones,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Estado civil',
    valueType: 'string' as const,
    value: 'civil_state',
    getRandomPlaceholder: generateRandomCivilState,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Nacionalidad',
    valueType: 'string' as const,
    value: 'nacionality',
    getRandomPlaceholder: generateRandomNationalities,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'date' as const,
    label: 'Fecha nacimiento',
    getRandomPlaceholder: generateRandomDate,
    value: 'birthday',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'string' as const,
    label: 'Sexo',
    value: 'gender',
    getRandomPlaceholder: generateRandomGender,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'string' as const,
    label: 'Banco',
    value: 'bank',
    getRandomPlaceholder: generateRandomBank,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'string' as const,
    label: 'Cuenta Bancaria',
    value: 'bank_account',
    getRandomPlaceholder: generateRandomNumberUnder10000000,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'string' as const,
    label: 'Pais de domicilio',
    value: 'country',
    getRandomPlaceholder: generateRandomCountry,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Numero',
    valueType: 'string' as const,
    value: 'number',
    getRandomPlaceholder: generateRandomNumberUnder100,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'string' as const,
    label: 'Departamento de domicilio',
    value: 'state',
    getRandomPlaceholder: generateRandomState,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'string' as const,
    label: 'Complemento',
    value: 'complement',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Ciudad de domicilio',
    valueType: 'string' as const,
    value: 'city',
    getRandomPlaceholder: generateRandomCity,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Dirección',
    valueType: 'string' as const,
    value: 'neighborhood',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Numero de Hijos (Totales)',
    valueType: 'string' as const,
    value: 'children_total',
    subgroup: 'Hijos',
  },
  {
    type: 'workers',
    label: 'Numero de Hijos (< 18)',
    valueType: 'string' as const,
    value: 'children_under_18',
    subgroup: 'Hijos',
  },
  {
    type: 'workers',
    label: 'Código del Colaborador',
    valueType: 'string' as const,
    value: 'default_config_id',
    getRandomPlaceholder: generateRandomCodes,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'ID de Integración - Datapar',
    valueType: 'string' as const,
    value: 'datapar_id',
    getRandomPlaceholder: generateRandomCodes,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'ID de Integración - Marcación Externa',
    valueType: 'string' as const,
    value: 'external_punch_id',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Configuración de Sector',
    valueType: 'string' as const,
    value: 'cell',
    getRandomPlaceholder: generateRandomSectors,
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Fecha de ingresso real',
    value: 'entrance_date',
    valueType: 'date' as const,
    subgroup: 'Informaciones del trabajador',
    getRandomPlaceholder: generateRandomDate,
  },
  {
    type: 'workers',
    label: 'Fecha de ingresso al IPS',
    valueType: 'date' as const,
    getRandomPlaceholder: generateRandomDate,
    value: 'entrance_date_ips',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Fecha de liquidación',
    valueType: 'date' as const,
    getRandomPlaceholder: generateRandomDate,
    value: 'inactivation_date',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    valueType: 'number' as const,
    label: 'Salario',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    value: 'salary',
    subgroup: 'Salario y Conceptos',
  },
  {
    type: 'workers',
    valueType: 'number' as const,
    label: '',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    value: 'salary_concepts',
    subgroup: 'Salario y Conceptos',
  },
  // {
  //   type: 'workers',
  //   label: 'Configuracion de Salario',
  //   getRandomPlaceholder: generateRandomConfiguration,
  //   valueType: 'string' as const,
  //   value: 'salary_config',
  //   subgroup: 'Informaciones del trabajador',
  // },
  {
    type: 'workers',
    label: 'Configuracion de Pagos',
    getRandomPlaceholder: generateRandomConfiguration,
    valueType: 'string' as const,
    value: 'payment_config',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Configuracion de Aguinaldo',
    getRandomPlaceholder: generateRandomConfiguration,
    valueType: 'string' as const,
    value: 'aguinaldo_config',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Configuracion de Marcación',
    getRandomPlaceholder: generateRandomConfiguration,
    valueType: 'string' as const,
    value: 'punch_config',
    subgroup: 'Informaciones del trabajador',
  },
  {
    type: 'workers',
    label: 'Configuracion de Vacaciones',
    valueType: 'string' as const,
    value: 'vacation_config',
    subgroup: 'Informaciones del trabajador',
    getRandomPlaceholder: generateRandomConfiguration,
  },
]
