import { IHoursDetail } from '@/libs/react-query/types'
import { validateFormattedMinutesOrEmpty } from '@/utils/date'
import { z } from 'zod'

const INVALID_HOUR_MESSAGE = 'Hora invalida'

export const editTotalHoursSchema = z.object({
  totalHours: z.object({
    morningHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    nightHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    morningExtraHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    nightExtraHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    morningDiscountHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    nightDiscountHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    morningHolidayHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
    nightHolidayHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional(),
  }),
  authorizedHours: z.object({
    nightHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
    morningExtraHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
    nightExtraHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
    morningDiscountHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
    nightDiscountHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
    morningHolidayHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
    nightHolidayHours: z
      .string()
      .refine((data) => validateFormattedMinutesOrEmpty(data), {
        message: INVALID_HOUR_MESSAGE,
      })
      .optional()
      .nullable(),
  }),
})

export type EditTotalHoursData = z.infer<typeof editTotalHoursSchema>

export interface HourDetailComponentProps {
  label: string
  details: IHoursDetail[]
}

export interface TotalPunchInfos {
  name: string
  userId: string
  hours: {
    dailyDiscount: string
    dailyExtra: string
    dailyHoliday: string
    dailyOrdinary: string
    nightDiscount: string
    nightExtra: string
    nightHoliday: string
    nightOrdinary: string
  }
  saved: {
    dailyDiscount: number | null
    dailyExtra: number | null
    dailyHoliday: number | null
    dailyOrdinary: number | null
    nightDiscount: number | null
    nightExtra: number | null
    nightHoliday: number | null
    nightOrdinary: number | null
  }
  details: {
    ordinaryHours: IHoursDetail[]
    extraHours: IHoursDetail[]
    discountHours: IHoursDetail[]
    holidayHours: IHoursDetail[]
    intervalHours: IHoursDetail[]
    movementsHours: IHoursDetail[]
  }
}
