import { Div } from '@/components/Div'
import { ControlledSearchableSelect } from '@/components/Forms'
import {
  SmartColumnType,
  SmartTableFormItemValueType,
} from '@/components/SmartTable/types'
import { useFormContext, useWatch } from 'react-hook-form'
import { ROW_HEIGHT } from '.'

interface InputDropdownOption {
  label: string
  value: string
}

interface InputDropdownProps {
  fieldValueName: string
  fieldName: string
  statusName: string
  column: SmartColumnType
  options: InputDropdownOption[]
  index: number
  subIndex?: number
}

export const InputDropdown = ({
  fieldValueName,
  fieldName,
  statusName,
  column,
  options,
  index,
  subIndex,
}: InputDropdownProps) => {
  const { setValue, getValues, control } = useFormContext()

  const status = useWatch({
    control,
    name: statusName,
  })

  const field: SmartTableFormItemValueType = useWatch({
    control,
    name: fieldName,
  })

  return (
    <>
      <Div
        css={{
          minWidth: column.width ? column.width : 72,
          maxWidth: column.width ?? 200,
          width: column.width ?? undefined,
          height: ROW_HEIGHT,
        }}
      >
        <ControlledSearchableSelect
          status={status}
          name={fieldValueName}
          options={options}
          defaultValue=""
          onChange={(value) => {
            const field = getValues(fieldName)
            column.handleSubmitValue?.({
              data: field,
              index,
              subindex: subIndex,
            })
            setValue(fieldValueName, value)
          }}
          disabled={!!column.disabled || field?.metadata?.disabled}
        />
      </Div>
    </>
  )
}
