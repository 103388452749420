import { useCan } from '@/hooks'
import { useTabStore } from '@/store'
import {
  DocumentArrowUpIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useWorkerPage } from './context/hooks/useWorkerPage'
import { IRouteAction } from '../router'

export const useHandleWorkersActions = () => {
  const { handleOpenPopover } = useWorkerPage()

  const { addTab, setActiveTab, activeTab, setActions } = useTabStore(
    (state) => ({
      setActions: state.setActionsTab,
      activeTab: state.worker.activeTab,
      addTab: state.addTab,
      setActiveTab: state.setActiveTab,
    }),
  )

  const canCreate = useCan(['workers.create', 'workers.all'])
  const canQuickCreate = useCan(['workers.fastCreation'])
  const canFullCreate = useCan(['workers.fullCreation', 'workers.create'])
  const canImport = useCan(['workers.import'])

  useEffect(() => {
    if (activeTab === 'list' || !activeTab) {
      const actions: IRouteAction[] = []

      if (canImport) {
        actions.push({
          label: 'Importar',
          icon: <DocumentArrowUpIcon />,
          action: () => {
            addTab('worker', [
              {
                id: 'import',
                type: 'import',
                label: 'Importar Colaboradores',
              },
            ])
            setActiveTab('worker', 'import')
          },
          disabled: false,
          variant: 'secondary' as const,
        })
      }

      if (canQuickCreate) {
        actions.push({
          label: 'Sencillo',
          icon: <PlusIcon />,
          action: () => handleOpenPopover('add'),
          disabled: false,
          variant: 'secondary' as const,
        })
      }

      if (canFullCreate) {
        actions.push({
          label: 'Completo',
          icon: <PlusCircleIcon />,
          action: () => {
            addTab('worker', [
              {
                id: 'create',
                type: 'create',
                label: 'Nuevo colaborador',
              },
            ])
            setActiveTab('worker', 'create')
          },
          disabled: false,
          variant: 'primary' as const,
        })
      }

      setActions('worker', actions)
    } else if (activeTab === 'liquidated-list') {
      setActions('worker', [])
    }
  }, [activeTab, canCreate, canQuickCreate, canFullCreate, canImport])
}
