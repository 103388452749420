import axios from 'axios'
import { parseCookies } from 'nookies'

const cookies = parseCookies()

export const api = axios.create({
  // baseURL: 'http://localhost:8080',
  baseURL: 'https://api.puntook.com.py',
  headers: {
    Authorization: `Bearer ${cookies['@PuntoOk:token']}`,
  },
})

export const apiV2 = axios.create({
  // baseURL: 'http://localhost:8093/main-service',
  baseURL: 'https://api.puntook.com.py/main-service',
  headers: {
    Authorization: `Bearer ${cookies['@PuntoOk:token']}`,
  },
})
