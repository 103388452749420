import { Div, FormRenderer, PageHeader } from '@/components'
import { handleImportXlsx } from '@/utils/documents/files'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ImportPunchesTable } from './Table'
import { useImportWorkersForm } from './useImportForm'
import { useHandlePunchesImportActions } from './actions'
import { ImportPunchesSchema, importPunchesSchema } from './form'
import {
  parseImportedDocumentToPunches,
  parseImportedPunchesToTable,
} from './parser'

export const ImportPunches = () => {
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm<ImportPunchesSchema>({
    resolver: zodResolver(importPunchesSchema),
  })

  const { data: form } = useImportWorkersForm()

  useHandlePunchesImportActions({ methods, isLoading })

  const uploadedFile = useWatch({
    control: methods.control,
    name: 'uploadedFile',
  })

  useEffect(() => {
    if (uploadedFile) {
      setIsLoading(true)
      handleImportXlsx(uploadedFile, (result: any) => {
        const importedWorkers = parseImportedDocumentToPunches(result)
        const workersToImport = parseImportedPunchesToTable(importedWorkers)

        methods.reset({
          data: workersToImport,
          uploadedFile,
        })

        setIsLoading(false)
      })
    } else {
      methods.reset({
        data: [],
        uploadedFile,
      })
    }
  }, [uploadedFile, methods])

  return (
    <FormProvider {...methods}>
      <Div css={{ padding: '$4', overflow: 'hidden' }}>
        <PageHeader
          title={'Importar marcaciones'}
          subtitle={
            'Importe las  marcaciones de los colaboradores por medio de planillas, utilizando columnas con nombres ID o CI, y Fecha.'
          }
        />

        <Div css={{ marginBottom: '$4' }} />

        <FormRenderer items={form} />

        <ImportPunchesTable />
      </Div>
    </FormProvider>
  )
}
