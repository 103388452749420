import {
  ShiftsListContainer,
  StyledContentText,
  WorkersListContainer,
} from './styles'

import React, { useState } from 'react'
import { Table as TableComponent } from '@/components'
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { IPolicy } from '@/libs/react-query/types'
import { Box, Checkbox, Menu, Text } from '@punto-ui/react'
import dayjs from 'dayjs'
import { DeletePolicyModal } from './components'
import { PoliciesProvider } from './context'
import { usePoliciesContext } from './context/hooks/useContext'
import { useSafeCallback } from '@/hooks'
import { useTabStore } from '@/store'
import { PopoverTypes } from './context/types'

interface DropdownMenuProps {
  rowItem: IPolicy
  handleOpenPopover: (popover: PopoverTypes, params?: any) => void
}

const DropdownMenu = ({ rowItem, handleOpenPopover }: DropdownMenuProps) => {
  const { addTab, setTab } = useTabStore((state) => ({
    addTab: state.addTab,
    setTab: state.setActiveTab,
  }))

  const safeCallback = useSafeCallback()
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const policy = rowItem

  if (!policy) {
    return null
  }

  return (
    <>
      <Menu
        items={[
          {
            id: '1',
            label: 'Ver detalles',
            onClick: () =>
              safeCallback(() => handleOpenPopover('view', policy)),
          },
          {
            id: '2',
            label: 'Editar',
            onClick: () => {
              setTimeout(() => {
                addTab('permissions', [
                  {
                    id: rowItem.id,
                    type: 'policy',
                    label: rowItem.name,
                  },
                ])
                setTab('permissions', rowItem.id)
              }, 20)
            },
          },
          {
            id: '3',
            label: 'Excluir',
            onClick: () => safeCallback(() => setIsDeleteOpen(true)),
          },
        ]}
      />
      <DeletePolicyModal
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        name={policy.name}
        id={rowItem.id}
      />
    </>
  )
}

export const PermissionsContent = () => {
  const { handleOpenPopover, policies, isLoadingPolicies } =
    usePoliciesContext()

  const columns = React.useMemo<ColumnDef<IPolicy>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              // disabled={!row.getCanSelect()}
              // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        header: 'Politica',
        id: 'name',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.name,
      },
      {
        header: 'Asignalado a',
        footer: (info) => info.column.id,
        cell: (info) => (
          <Text
            variant="paragraph"
            css={{
              textAlign: 'left',
              color: '$interface_dark_deep',
            }}
          >
            {info.row.original.users.length} persona
            {info.row.original.users.length > 1 ? 's' : ''}
          </Text>
        ),
        meta: {
          content: 'component',
        },
        id: 'atribuidos',
      },
      {
        header: 'Creado por',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.updated_by,
        id: 'updated_by',
      },
      {
        header: 'Creado en',
        cell: (info) =>
          dayjs(String(info.getValue())).format('DD/MM/YYYY hh:mm'),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.created_at,
        id: 'created_at',
      },
      {
        header: () => null,
        cell: (info) => (
          <StyledContentText>
            <DropdownMenu
              rowItem={info.row.original}
              handleOpenPopover={handleOpenPopover}
            />
          </StyledContentText>
        ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        id: 'dropdown-menu',
        meta: {
          content: 'component',
        },
      },
    ],
    [],
  )

  const table = useReactTable({
    data: policies ?? [],
    columns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <ShiftsListContainer>
      <WorkersListContainer>
        <TableComponent<IPolicy>
          numberOfRows={policies?.length ?? 0}
          table={table}
          isLoading={isLoadingPolicies}
          withPagination={false}
        />
      </WorkersListContainer>
    </ShiftsListContainer>
  )
}

export const PermissionsList = () => {
  return (
    <PoliciesProvider>
      <PermissionsContent />
    </PoliciesProvider>
  )
}
