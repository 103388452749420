import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IShift } from '@/libs/react-query/types'
import { queryClient } from '@/libs/react-query'

const handleDuplicateShift = async (shiftId: string) => {
  const response = await apiV2.post<IShift>('/shift/duplicate-shift', {
    id: shiftId,
  })

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleDuplicateShift = () => {
  const mutation = useMutation(['handleDuplicateShift'], handleDuplicateShift)

  return mutation
}
