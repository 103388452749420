import { Div } from '@/components'
import { Text } from '@punto-ui/react'
import React from 'react'

interface PayrollCardProps {
  label: string
  children: React.ReactNode
}

export const PayrollCard = ({ label, children }: PayrollCardProps) => {
  return (
    <Div>
      <Text css={{ marginBottom: '$1' }}>{label}</Text>
      <Div>{children}</Div>
    </Div>
  )
}
