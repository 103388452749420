import { DrawerContainer, styled } from '@punto-ui/react'
import React, { createContext, useEffect, useState } from 'react'
import { FormDrawer } from '@/components'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import {
  useWorkerSimpleFields,
  createWorkerSchema,
  AddWorkerSimpleData,
} from '../forms'
import { PopoverTypes, PopoverState, ContextData } from './types'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHandleCreateWorkerComplete } from '@/libs/react-query/mutations/workers/'
import useToast from '@/hooks/useToast'
import { FetchWorkerProps } from '@/libs/react-query/types/worker'
import { AxiosError } from 'axios'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'

export const WorkersContext = createContext<ContextData>({} as ContextData)

interface WorkersProviderProps {
  children: React.ReactNode
}

export const WorkersProvider = ({ children }: WorkersProviderProps) => {
  const [popovers, setPopovers] = useState<PopoverState>({
    add: {
      isOpen: false,
    },
    edit: {
      isOpen: false,
    },
    view: {
      isOpen: false,
    },
    faceId: {
      isOpen: false,
    },
  })

  const [filter, setFilter] = useState<FetchWorkerProps>({
    page: 0,
    pageSize: 20,
    usersIds: [],
  })

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('workers')),
  )

  const handleCloseAllPopovers = () => {
    const newPopovers: any = Object.fromEntries(
      Object.keys(popovers).map((key) => [
        key,
        { isOpen: false, params: null },
      ]),
    )
    setPopovers(newPopovers)
  }

  const handleOpenPopover = (popover: PopoverTypes, params?: any) => {
    if (popover === 'edit' && params?._id) {
      methods.reset(
        {
          id: params.id,
          name: params.name,

          shift_id: params.shift,
          ci: params.doc,
          email: params.email,
          password: '',
        },
        {
          keepValues: true,
        },
      )
    } else {
      methods.reset({}, { keepValues: true })
    }

    setPopovers({
      ...popovers,
      [popover]: {
        isOpen: true,
        params,
      },
    })
  }

  const handleClosePopover = (popover: PopoverTypes) => {
    setPopovers({
      ...popovers,
      [popover]: {
        isOpen: false,
        params: {},
      },
    })
  }

  const methods = useForm<AddWorkerSimpleData>({
    resolver: zodResolver(createWorkerSchema),
    defaultValues: {
      filters: {
        cellsIds: availableCells?.map((c) => c.id) || [],
        name: '',
      },
      punch_config_ids: '',
    },
  })

  useEffect(() => {
    if (availableCells?.length) {
      methods.setValue('filters', {
        cellsIds: availableCells?.map((c) => c.id) || [],
        name: '',
      })
    }
  }, [availableCells, methods])

  return (
    <DrawerContainer>
      <FormProvider {...methods}>
        <WorkersContext.Provider
          value={{
            filter,
            setFilter,
            handleClosePopover,
            handleOpenPopover,
          }}
        >
          {children}
          <SimpleWorkerFieldDrawer
            popovers={popovers}
            handleCloseAllPopovers={handleCloseAllPopovers}
          />
        </WorkersContext.Provider>
      </FormProvider>
    </DrawerContainer>
  )
}

export const FlexColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const SimpleWorkerFieldDrawer = ({ popovers, handleCloseAllPopovers }: any) => {
  const { data: workerFields } = useWorkerSimpleFields()
  const { mutate: handleCreateWorker, isLoading: isLoadingCreateWorker } =
    useHandleCreateWorkerComplete()
  const { addToast } = useToast()

  const { trigger, getValues } = useFormContext<AddWorkerSimpleData>()

  const handleSubmitCreateForm = async () => {
    const data = getValues()
    const isValid = await trigger([
      'name',
      'email',
      'ci',
      'shift_id',
      'password',
    ])

    if (!isValid) {
      return
    }

    handleCreateWorker(data, {
      onError: (error) => {
        if (error instanceof AxiosError) {
          addToast({
            title: ' Upps!',
            description: `${error?.response?.data?.message}`,
            type: 'negative',
            id: 3,
          })
        }
      },
      onSuccess: (data) => {
        addToast({
          title: 'Colaborador creado 👌',
          description: 'Bienvenido al nuevo miembro del equipo!',
          type: 'positive',
          id: 2,
        })
      },
    })
  }

  return (
    <FormDrawer
      title={popovers.add.isOpen ? 'Agregar colaborador' : 'Editar colaborador'}
      icon={<PlusCircleIcon width={30} color={'$brand_primary_pure'} />}
      isOpen={popovers.add.isOpen || popovers.edit.isOpen}
      handleCloseDrawer={handleCloseAllPopovers}
      primaryButtonProps={{ label: 'Agregar' }}
      handlePrimaryButton={() => handleSubmitCreateForm()}
      secondaryButtonProps={{ label: 'Cancelar' }}
      handleSecondaryButton={handleCloseAllPopovers}
      formItems={workerFields}
      isLoadingSubmit={isLoadingCreateWorker}
    />
  )
}
