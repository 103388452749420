import { v4 as uuid } from 'uuid'
import { importXlsxFile } from './import'

export function formatBytes(bytes: number, decimals?: number) {
  if (!bytes) return '0 Bytes'
  const k = 1024
  const dm = decimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const handleImportXlsx = async (
  file: File,
  callback: (v: any) => void,
) => {
  if (!file) return

  const readFile = () => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        const fileContent = reader.result as string

        if (!fileContent) return

        const result = importXlsxFile(fileContent)

        const resultWithEmptyColumn = result.map((r: any) => ({
          ...r,
          '': '',
          id: uuid(),
        }))

        if (resultWithEmptyColumn.length) {
          resolve(resultWithEmptyColumn)
        }
      }

      reader.readAsBinaryString(file)
    })
  }

  const result = await readFile()
  callback(result)
}
