import { ControlledInput, Div } from '@/components'
import { ControlledSwitch } from '@/components/Forms'
import { useCan } from '@/hooks'
import { ConfigSchema } from '@/pages/configuraciones/components'
import { Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'

export const LiquidationConceptsTab = () => {
  const { control } = useFormContext<ConfigSchema>()

  const basicConcepts = useWatch({
    control,
    name: 'liquidation.basicConcepts',
  })
  const canCreateLiquidation = useCan(['configurations.liquidations'])

  return (
    <Div
      css={{
        paddingTop: 16,
      }}
    >
      <Text
        size="xl"
        css={{
          color: '$interface_dark_deep',
          fontWeight: '$bold',
          marginBottom: '$4',
        }}
      >
        Configuración de Conceptos fixos
      </Text>
      {basicConcepts?.map((c, index) => (
        <Div
          key={c.type}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '$4',
          }}
        >
          <ControlledInput
            disabled={!canCreateLiquidation}
            name={`liquidation.basicConcepts.${index}.name`}
          />
          <ControlledSwitch
            disabled={!canCreateLiquidation}
            label={'IPS'}
            name={`liquidation.basicConcepts.${index}.ips`}
          />
          <Text>{c.ips}</Text>
        </Div>
      ))}
    </Div>
  )
}
