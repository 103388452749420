import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useShiftManagementColumns } from './useShiftManagementColumns'
import { useSmartShift } from '../../../context'

export const useShiftManagementTable = () => {
  const [rowSelection, setRowSelection] = React.useState({})
  const { workersShifts } = useSmartShift()
  const { data: columns } = useShiftManagementColumns()

  const table = useReactTable({
    data: workersShifts ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.worker.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
  })

  return table
}
