import { Div } from '@/components'
import {
  ControlledCalendarInput,
  ControlledWorkerSearchBar,
  FormRenderer,
} from '@/components/Forms'
import { PayrollPlaygroundTableType } from '../../types'
import { usePayrollConfiguration } from '../../form/usePayrollConfiguration'
import { PayrollCard } from '../PayrollCard'
import { useFormContext, useWatch } from 'react-hook-form'
import { useRef } from 'react'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useCan } from '@/hooks'

export const PayrollConfigurations = () => {
  const { control } = useFormContext<PayrollPlaygroundTableType>()

  const divRef = useRef<HTMLDivElement | null>(null)
  const containerDivRef = useRef<HTMLDivElement | null>(null)

  const usersIds = useWatch({
    control,
    name: 'users_ids',
  })

  const status = useWatch({
    control,
    name: 'status',
  })

  const canEditPayroll = useCan(['workers.edit', 'workers.closePayroll'])

  const { data: payrollConfigurationForm } = usePayrollConfiguration(
    status === 'closed' || !canEditPayroll,
  )

  const policiesIds = permissionsArray.filter((p) => p.includes('workers'))

  return (
    <Div
      ref={containerDivRef}
      css={{
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 52px)',
        paddingLeft: '$4',
        paddingRight: '$4',
        gap: '$4',
      }}
    >
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$4',
        }}
      >
        <PayrollCard label="Informaciones básicas">
          <Div
            css={{
              background: '$interface_light_pure',
              padding: '$4',
              borderRadius: '$md',
            }}
          >
            <FormRenderer items={payrollConfigurationForm} />
          </Div>
        </PayrollCard>
        <PayrollCard label="Selecione el periodo">
          <ControlledCalendarInput<PayrollPlaygroundTableType>
            name={'periods'}
            calendarType={'range'}
            maxDates={31}
            stayOpen
            disabled={status === 'closed' || !canEditPayroll}
            hideInput
          />
        </PayrollCard>
      </Div>
      <Div css={{ flex: 1, marginTop: '$7', overflow: 'hidden' }} ref={divRef}>
        <ControlledWorkerSearchBar<PayrollPlaygroundTableType>
          activeDateFilter
          activeSelection
          name={'users_ids'}
          usersIds={usersIds}
          maxHeight={
            typeof window !== 'undefined' ? window.innerHeight - 324 : 0
          }
          // maxHeight={300}
          disabled={status === 'closed' || !canEditPayroll}
          policiesIds={policiesIds}
          css={{
            height: 'calc(100vh - 180px)',
          }}
        />
      </Div>
    </Div>
  )
}
