import {
  CalendarIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React, {
  useRef,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'
import { Actions } from './Actions'
import { Calendar } from './Calendar'
import { Filters } from './Filters'
import {
  AllInputContainer,
  CalendarContainer,
  InputContainer,
  InputDatePickerContainer,
  Touchable,
} from './styles'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Hours, fullfillHours } from './Hours'
import {
  Divider,
  Text,
  TextInput,
  Tooltip,
  encodeByPattern,
  keyframes,
} from '@punto-ui/react'
import { Div } from '../Div'
import { CSS } from '@stitches/react'
import ReactDOM from 'react-dom'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'

dayjs.extend(customParseFormat)

export interface InputDatePickerProps {
  onToggleHours?: (hours: boolean) => void
  firstDate?: Date | null
  secondDate?: Date | null
  type: 'range' | 'multiple' | 'single'
  onChange: (dates: Array<Date | null>, withHours: boolean) => void
  stayOpen?: boolean
  hideInput?: boolean
  maxDates: number
  isDisabled?: (date: Date) => boolean
  disabled?: boolean
  withoutSubmitButton?: boolean
  modern?: boolean
  errorMessage?: string
  multipleDates?: Date[]
  label?: string
  withHours?: boolean
  hoursEnabled?: boolean
  help?: React.ReactNode
  disclaimer?: string

  inputStyles?: CSS
  placeholder?: string
  priorityPlaceholder?: string
}

interface InputDatePickerContextType {
  isDisabled?: (date: Date) => boolean
  calendarDate: dayjs.Dayjs
  setCalendarDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  firstDate: Date | null
  secondDate: Date | null
  firstHour: string
  secondHour: string
  setFirstHour: React.Dispatch<React.SetStateAction<string>>
  setSecondHour: React.Dispatch<React.SetStateAction<string>>
  isHoursOpen: boolean
  setIsHoursOpen: (x: boolean) => void
  type: 'range' | 'multiple' | 'single'
  onChange: (dates: Array<Date | null>, shouldIncludeHours?: boolean) => void
  multipleDates: Date[]
  maxDates?: number
  disabled?: boolean
}

const InputDatePickerContext = createContext({} as InputDatePickerContextType)

export const useInputDatePicker = () => {
  const context = useContext(InputDatePickerContext)

  if (!context) {
    throw new Error(
      'useInputDatePicker must be used within an InputDatePickerProvider',
    )
  }

  return context
}

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translateY(0%) scale(1)' },
})

export const InputDatePicker = ({
  firstDate,
  onChange,
  secondDate,
  multipleDates = [],
  type = 'range',
  disclaimer,
  stayOpen,
  hideInput,
  maxDates,
  isDisabled,
  withoutSubmitButton = true,
  label,
  modern,
  errorMessage: errorMessageParam,
  withHours,
  onToggleHours,
  hoursEnabled,
  disabled,
  help,
  inputStyles,
  placeholder,
  priorityPlaceholder,
}: InputDatePickerProps) => {
  const isHoursOpen = !!hoursEnabled
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [calendarDate, setCalendarDate] = useState(dayjs())
  const [firstDateState, setFirstDateState] = useState<Date | null>(
    firstDate || null,
  )
  const [previousSelected, setPreviousSelected] = useState({
    start: 0,
    end: 0,
    direction: 'forward',
  })
  const [secondDateState, setSecondDateState] = useState<Date | null>(
    secondDate || null,
  )
  const [multipleDateState, setMultipleDateState] = useState<Date[]>(
    multipleDates || [],
  )

  const [firstHour, setFirstHour] = useState(
    firstDate ? dayjs(firstDate).format('HH:mm') : '',
  )
  const [secondHour, setSecondHour] = useState(
    secondDate ? dayjs(secondDate).format('HH:mm') : '',
  )

  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState(errorMessageParam)

  useEffect(() => {
    if (errorMessageParam) {
      setErrorMessage(errorMessageParam)
    }
  }, [errorMessageParam])

  useEffect(() => {
    if (type === 'range') {
      let inputString = ''
      firstDate && (inputString += `${dayjs(firstDate).format('DD/MM/YYYY')}`)
      secondDate &&
        (inputString += ` - ${dayjs(secondDate).format('DD/MM/YYYY')}`)

      setInputValue(inputString)
      onChange([firstDate || null, secondDate || null], isHoursOpen)
      setCalendarDate(dayjs(firstDate || secondDate || new Date()))
    } else if (type === 'multiple') {
      setInputValue(`${multipleDateState || 0} datas selecionadas`)
      onChange(multipleDateState, isHoursOpen)
      setCalendarDate(dayjs(multipleDateState[0] || new Date()))
    } else if (type === 'single' && firstDate) {
      setInputValue(`${dayjs(firstDate).format('DD/MM/YYYY')}`)
      onChange([firstDate], isHoursOpen)
      setCalendarDate(dayjs(firstDate))
    }
  }, [type])

  useEffect(() => {
    if (type === 'range') {
      let inputString = ''
      firstDate && (inputString += `${dayjs(firstDate).format('DD/MM/YYYY')}`)
      secondDate &&
        (inputString += ` - ${dayjs(secondDate).format('DD/MM/YYYY')}`)

      setInputValue(inputString)
      setFirstDateState(firstDate || null)
      setSecondDateState(secondDate || null)
    } else if (type === 'multiple') {
      setInputValue(`${multipleDates.length || 0} datas selecionadas`)
      if (multipleDates.length) setMultipleDateState(multipleDates)
    } else if (type === 'single' && firstDate) {
      setInputValue(`${dayjs(firstDate).format('DD/MM/YYYY')}`)
      setFirstDateState(firstDate || null)
    }
  }, [type, firstDate, secondDate, multipleDates])

  useEffect(() => {
    if ((isCalendarOpen || stayOpen) && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      if (typeof window !== 'undefined') {
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        })
      }
    }
  }, [isCalendarOpen, stayOpen])

  const handleChangeInputData = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault()

    const { value } = event.target
    const pattern =
      type === 'single'
        ? '99/99/9999'
        : type === 'range'
        ? '99/99/9999 - 99/99/9999'
        : 'no-mask'
    const maskedValue = encodeByPattern(value, pattern)
    const erasedSomething = maskedValue.length < inputValue.length
    const erasingLastCharacter =
      previousSelected.start === maskedValue.length + 1

    if (erasedSomething && !erasingLastCharacter) {
      setInputValue('')
      onChange([null, null], isHoursOpen)
      return
    }

    setInputValue(maskedValue)

    if (maskedValue.length === 10) {
      const firstDateFormatted = dayjs(maskedValue, 'DD/MM/YYYY', true)

      const isFirstDateValid = firstDateFormatted.isValid()
      const validFirstDate = isFirstDateValid
        ? firstDateFormatted.toDate()
        : null

      setErrorMessage(
        !validFirstDate ? `La fecha ${maskedValue} no es válida.` : '',
      )

      onChange([validFirstDate, secondDateState], isHoursOpen)
      setFirstDateState(validFirstDate)

      return
    }

    if (maskedValue.length === 23) {
      const [firstDate, secondDate] = maskedValue.split(' - ')

      const firstDateFormatted = dayjs(firstDate, 'DD/MM/YYYY', true)
      const secondDateFormatted = dayjs(secondDate, 'DD/MM/YYYY', true)

      const isFirstDateValid = firstDateFormatted.isValid()
      const isSecondDateValid = secondDateFormatted.isValid()

      const validFirstDate = isFirstDateValid
        ? firstDateFormatted.toDate()
        : null

      const validSecondDate = isSecondDateValid
        ? secondDateFormatted.toDate()
        : null

      const errorMessage = !validSecondDate
        ? `La fecha ${secondDate} no es válida.`
        : !validFirstDate
        ? `La fecha ${firstDate} no es válida.`
        : ''

      setErrorMessage(errorMessage)
      setFirstDateState(validFirstDate)
      setSecondDateState(validSecondDate)
      onChange([validFirstDate, validSecondDate], isHoursOpen)
    }
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useOutsideAlerters([containerRef, dropdownRef], () => {
    setIsCalendarOpen(false)
  })

  const handleChangeDates = (
    dates: Array<Date | null>,
    shouldIncludeHours = true,
  ) => {
    if (type === 'multiple') {
      const validDates = dates.filter(
        (d) => d !== null && dayjs(d).isValid(),
      ) as Date[]
      const newValidDates = validDates.filter(
        (d) =>
          !multipleDateState.find((alreadySelectedDate) =>
            dayjs(alreadySelectedDate).isSame(d, 'day'),
          ) && dayjs(d).isValid(),
      )
      const validDatesToRemove = validDates.filter((d) =>
        multipleDateState.find((alreadySelectedDate) =>
          dayjs(alreadySelectedDate).isSame(d, 'day'),
        ),
      )
      const newDates = [...multipleDateState, ...newValidDates]
      const newDatesWithoutRemoved = newDates.filter(
        (d) => !validDatesToRemove.find((date) => dayjs(date).isSame(d, 'day')),
      )

      setMultipleDateState(newDatesWithoutRemoved)
      onChange(newDatesWithoutRemoved, isHoursOpen)
      setInputValue(`${newDatesWithoutRemoved.length} datas selecionadas`)
      return
    }

    const firstDate = dates[0] || null
    const secondDate = dates[1] || null

    const firstHourSafe = fullfillHours(firstHour)
    const secondHourSafe = fullfillHours(secondHour)

    const firstDateWithHour = shouldIncludeHours
      ? dayjs(firstDate)
          .set('hour', Number(firstHourSafe.split(':')[0]))
          .set('minute', Number(firstHourSafe.split(':')[1]))
      : dayjs(firstDate)

    const secondDateWithHour = shouldIncludeHours
      ? dayjs(secondDate)
          .set('hour', Number(secondHourSafe.split(':')[0]))
          .set('minute', Number(secondHourSafe.split(':')[1]))
      : dayjs(secondDate)

    const finalDates = []

    if (firstDateWithHour.isValid()) {
      finalDates.push(firstDateWithHour.toDate())
    }

    if (secondDateWithHour.isValid()) {
      finalDates.push(secondDateWithHour.toDate())
    }

    setFirstDateState(
      firstDateWithHour.isValid() ? firstDateWithHour.toDate() : null,
    )
    setSecondDateState(
      secondDateWithHour.isValid() ? secondDateWithHour.toDate() : null,
    )

    onChange(finalDates, isHoursOpen)
    setErrorMessage('')

    let inputString = ''
    firstDate && (inputString += `${dayjs(firstDate).format('DD/MM/YYYY')}`)
    secondDate &&
      (inputString += ` - ${dayjs(secondDate).format('DD/MM/YYYY')}`)

    setInputValue(inputString)
  }

  const resetDates = () => {
    setFirstDateState(null)
    setSecondDateState(null)
    setMultipleDateState([])
    onChange([null, null], isHoursOpen)
    setInputValue('')
  }

  return (
    <InputDatePickerContext.Provider
      value={{
        maxDates,
        multipleDates: multipleDateState,
        calendarDate,
        setCalendarDate,
        firstDate: firstDateState,
        secondDate: secondDateState,
        onChange: handleChangeDates,
        type,
        isDisabled,
        disabled,
        firstHour,
        secondHour,
        setFirstHour,
        setSecondHour,
        isHoursOpen,
        setIsHoursOpen: (value) => {
          onToggleHours?.(value)
        },
      }}
    >
      <InputDatePickerContainer ref={containerRef}>
        {!hideInput && (
          <>
            {modern ? (
              <AllInputContainer>
                <Touchable
                  css={{
                    width: '100%',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsCalendarOpen(true)
                  }}
                >
                  <InputContainer css={inputStyles || undefined}>
                    <Text variant={'description'}>
                      {priorityPlaceholder || inputValue || placeholder}
                    </Text>
                    {!!help && (
                      <Tooltip message={help} arrow>
                        <Div
                          css={{
                            height: 24,
                            width: 24,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            marginRight: 4,

                            svg: {
                              color: '$brand_primary_pure',
                              height: 20,
                              width: 20,
                            },
                          }}
                        >
                          <QuestionMarkCircleIcon />
                        </Div>
                      </Tooltip>
                    )}
                    <Touchable onClick={(e) => e.preventDefault()}>
                      <XMarkIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          resetDates()
                        }}
                      />
                    </Touchable>
                  </InputContainer>
                </Touchable>
                {!!errorMessage && (
                  <Text
                    variant={'caption'}
                    css={{
                      color: '$status_danger_down',
                      marginTop: '$1',
                      paddingLeft: '$2',
                    }}
                  >
                    {errorMessage}
                  </Text>
                )}
              </AllInputContainer>
            ) : (
              <TextInput
                disclaimer={disclaimer}
                help={help}
                label={label}
                onSelect={(e) =>
                  setPreviousSelected({
                    start: e.currentTarget.selectionStart || 0,
                    end: e.currentTarget.selectionEnd || 0,
                    direction: e.currentTarget.selectionDirection || 'forward',
                  })
                }
                placeholder={
                  type === 'single'
                    ? 'DD/MM/YYYY'
                    : type === 'multiple'
                    ? 'Fechas'
                    : 'DD/MM/YYYY - DD/MM/YYYY'
                }
                icon={<CalendarIcon />}
                onFocus={() => setIsCalendarOpen(true)}
                onChange={(e) => {
                  e.preventDefault()
                  if (type === 'multiple') {
                    return null
                  } else {
                    handleChangeInputData(e)
                  }
                }}
                value={inputValue}
                disabled={disabled}
                errorMessage={errorMessage}
              />
            )}
          </>
        )}
        {hideInput && label && (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text variant={'caption'}>{label}</Text>
            {!!help && (
              <Tooltip message={help} arrow>
                <Div
                  css={{
                    height: 24,
                    width: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    marginRight: 4,

                    svg: {
                      color: '$brand_primary_pure',
                      height: 20,
                      width: 20,
                    },
                  }}
                >
                  <QuestionMarkCircleIcon />
                </Div>
              </Tooltip>
            )}
          </Div>
        )}
        {stayOpen && (
          <CalendarContainer
            ref={dropdownRef}
            css={{
              // position: 'absolute',
              // top: `${position.top + 12}px`,
              // left: `${position.left - 12}px`,
              // zIndex: 101,
              pointerEvents: 'all',
              display: 'flex',
            }}
            // isOpen={isCalendarOpen || stayOpen}
            stayOpen={stayOpen}
          >
            <Filters />
            <Calendar />
            {!withoutSubmitButton && (
              <Actions setIsCalendarOpen={setIsCalendarOpen} />
            )}
            {withHours && (
              <>
                <Divider
                  orientation={'horizontal'}
                  css={{ marginLeft: '$5', marginRight: '$5' }}
                />
                <Hours />
              </>
            )}
          </CalendarContainer>
        )}
        {isCalendarOpen && !stayOpen && !!position.top && !!position.left && (
          <>
            {ReactDOM.createPortal(
              <CalendarContainer
                ref={dropdownRef}
                css={{
                  position: 'absolute',
                  top: `${position.top + 12}px`,
                  left: `${position.left - 12}px`,
                  zIndex: 9999,
                  pointerEvents: 'all',
                  display: 'flex',

                  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
                }}
                // isOpen={isCalendarOpen || stayOpen}
                stayOpen={stayOpen}
              >
                <Filters />
                <Calendar />
                {!withoutSubmitButton && (
                  <Actions setIsCalendarOpen={setIsCalendarOpen} />
                )}
                {withHours && (
                  <>
                    <Divider
                      orientation={'horizontal'}
                      css={{ marginLeft: '$5', marginRight: '$5' }}
                    />
                    <Hours />
                  </>
                )}
              </CalendarContainer>,
              document.body,
            )}
          </>
        )}
      </InputDatePickerContainer>
    </InputDatePickerContext.Provider>
  )
}

InputDatePicker.displayName = 'InputDatePicker'
