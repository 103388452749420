import { useQuery } from 'react-query'
import { useMe } from '..'
import { apiV2 } from '@/services/api'
import { ICompanyBusiness } from '../../types/company'

const getCompanies = async () => {
  try {
    const response = await apiV2.get<ICompanyBusiness[]>(
      '/company/list-companies',
    )

    return response.data
  } catch (error) {
    console.log('[ERROR FETCH COMPANIES]', error)
  }
}

export const useCompanies = () => {
  const { data: me } = useMe()

  const query = useQuery(['companies', me?.id], getCompanies, {
    enabled: !!me,
  })

  return query
}
