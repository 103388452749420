import { FormRenderer } from '@/components'
import { FormContainer } from '..'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'
import { useDiscountPreWarning } from '../form/useDiscountPreWarning'

interface IDiscountPreWarningLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const DiscountPreWarningLiquidation = ({
  isOpen,
  toggleOpen,
}: IDiscountPreWarningLiquidationParams) => {
  const conceptKey = 'discount_prewarning'

  const { form: preWarningForm } = useDiscountPreWarning()
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [totalVacationValue, vacationDays] = useWatch({
    control,
    name: [
      'data.discount_pre_warning.value',
      'data.discount_pre_warning.totalDays.value',
    ],
  })

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Descuento Pré-aviso'}
        totals={[
          `Dias a descontar: ${vacationDays} dias`,
          `Valor a descontar: ${formatCurrency(+totalVacationValue)}`,
        ]}
        isIpsDisabled={!concept.ips}
        conceptType="basic"
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && (
        <FormRenderer inline items={preWarningForm} cols={4} />
      )}
    </FormContainer>
  )
}
