import { Text } from '@punto-ui/react'
import { Div } from '../Div'
import { Touchable } from '../Touchable'

interface StepComponentProps {
  error: boolean
  selectedIndex: number
  text: string
  isLast: boolean
  index: number
  disabled?: boolean
  setFormStep: (index: number) => void
}

export const Step = ({
  error,
  selectedIndex,
  text,
  isLast,
  disabled,
  index,
  setFormStep,
}: StepComponentProps) => {
  const selected = selectedIndex === index
  const passed = selectedIndex > index

  return (
    <>
      <Touchable
        onClick={() => (disabled ? null : setFormStep(index))}
        disabled={disabled}
      >
        <Div
          css={{
            paddingLeft: '$4',
            paddingRight: '$4',
            border: '1px solid',
            borderColor: error
              ? '$status_danger_deep'
              : selected
              ? '$brand_primary_down'
              : passed
              ? '$brand_primary_down'
              : '$interface_dark_up',
            background:
              passed && !error ? '$brand_primary_down' : 'transparent',
            borderRadius: '$lg',
          }}
        >
          <Text
            variant="caption"
            css={{
              // fontWeight: '$bold',
              color: error
                ? '$status_danger_deep'
                : selected
                ? '$brand_primary_down'
                : passed
                ? '$interface_light_pure'
                : '$interface_dark_up',
            }}
          >
            {text}
          </Text>
        </Div>
      </Touchable>
      {!isLast && (
        <Div
          css={{
            height: 1,
            background: passed ? '$brand_primary_pure' : '$interface_dark_up',
            width: 12,
          }}
        />
      )}
    </>
  )
}
