import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { setCookie } from 'nookies'
dayjs.extend(utc)

const handleUpdateCompanyId = async (companyId: string) => {
  const response = await apiV2.post<{ token: string }>(
    '/admin/change-company',
    {
      company_id: companyId,
    },
  )

  apiV2.defaults.headers.Authorization = `Bearer ${response.data.token}`

  setCookie(undefined, '@PuntoOk:token', response.data.token, {
    maxAge: 60 * 60 * 24 * 30 * 12, // 1 year
    path: '/',
  })

  // queryClient.invalidateQueries()
  queryClient.resetQueries()
}

export const useHandleUpdateCompanyId = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateCompanyId'],
    handleUpdateCompanyId,
  )

  return mutation
}
