import { IRouteAction, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { ContextProvider as MovementsProvider } from '../movimientos/context'
import {
  ClockIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  FireIcon,
  LightBulbIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline'
import { PunchMatrixProvider } from './context'
import { PunchesTable } from './Table'
import { UserTab } from './components/UserTab'
import { useWorkers } from '@/libs/react-query/hooks'
import { Avatar } from '@/components'
import { useTabStore } from '@/store'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import React, { useEffect, useRef } from 'react'
import { ExportDrawer } from './components/ExportDrawer'
import { ImportPunches } from './components/ImportPunches'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useCan } from '@/hooks'
import { PunchesMap } from './Map'

export default function Marcaciones({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const { data: isAiFeatureAvailable, detail } = useIsFeatureInPlan('punto-ia')
  const [isExportDrawerOpen, setIsExportDrawerOpen] = React.useState(false)
  const {
    setTabMetadata,
    pageTabs,
    removeTab,
    tab,
    setActiveTab,
    addTab,
    definedActions,
    tabMetadata,
  } = useTabStore((state) => ({
    pageTabs: state.punches.tabs,
    tab: state.punches.activeTab,
    setActiveTab: state.setActiveTab,
    removeTab: state.removeTab,
    addTab: state.addTab,
    definedActions: state.punches.actions,
    tabMetadata: state.punches.metadata,
    setTabMetadata: state.setMetadata,
  }))

  const timeout = useRef<any>(null)

  const ids: string[] = []

  pageTabs.forEach((t) => {
    if (t.type === 'worker') {
      ids.push(t.id)
    }
  })

  const { data: workers, isFetching: isLoadingWorker } = useWorkers({
    policiesIds: [
      ...permissionsArray.filter((p) => p.includes('punches')),
      'deleted',
    ],
    usersIds: ids,
    page: -1,
    allUsers: true,
  })

  const validWorkers =
    workers?.data.filter((w) => {
      return ids.includes(w.id)
    }) || []

  const tabs = validWorkers?.map((worker) => ({
    component: <UserTab id={worker.id} />,
    closeable: true,
    handleClose: () => {
      removeTab('punches', worker.id)
      timeout.current = setTimeout(() => {
        setActiveTab('punches', 'summary')
      }, 2)
    },
    icon: (
      <Avatar
        css={{ marginRight: '$2' }}
        src={worker?.photo_url || ''}
        height={32}
        width={32}
        alt=""
        unoptimized={true}
      />
    ),
    label: worker.name,
    value: worker.id,
  }))

  const importTab = pageTabs.find((t) => t.type === 'import')
  if (importTab) {
    tabs.push({
      component: <ImportPunches />,
      closeable: true,
      handleClose: () => {
        removeTab('punches', 'import')
        timeout.current = setTimeout(() => {
          setActiveTab('punches', 'summary')
        }, 2)
      },
      icon: <DocumentArrowUpIcon />,
      label: 'Importación de marcaciones',
      value: 'import',
    })
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [setActiveTab])

  const canEditPunches = useCan(['punches.edit'])
  const canImportPunches = useCan(['punches.import'])

  const pageActions: IRouteAction[] = [
    {
      label: 'PuntoIA',
      disabled: !isAiFeatureAvailable || !canEditPunches,
      variant: tabMetadata?.isAutomaticCompleteEnabled
        ? 'primary'
        : ('secondary' as const),
      disableMessage: (
        <div>
          {isAiFeatureAvailable ? '' : detail}
          {isAiFeatureAvailable ? null : (
            <>
              <br />
              <br />
            </>
          )}
          Con la Inteligencia Artificial del PuntoOK es posible <br />
          autocompletar los puntos faltantes de los colaboradores de manera{' '}
          <br />
          inteligente.
        </div>
      ) as any,
      icon: tabMetadata?.isAutomaticCompleteEnabled ? (
        <FireIcon />
      ) : (
        <LightBulbIcon />
      ),
      action: () => {
        setTabMetadata('punches', {
          isAutomaticCompleteEnabled: !tabMetadata?.isAutomaticCompleteEnabled,
        })
      },
    },
    {
      label: 'Importar',
      disabled: !canImportPunches,
      variant: 'secondary' as const,
      icon: <DocumentArrowUpIcon />,
      action: () => {
        addTab('punches', [
          {
            id: 'import',
            type: 'import',
            label: 'Importación de marcaciones',
          },
        ])
        setActiveTab('punches', 'import')
      },
    },
    {
      label: 'Reporte',
      variant: 'primary' as const,
      icon: <DocumentArrowDownIcon />,
      action: () => setIsExportDrawerOpen(true),
    },
  ]

  return (
    <MovementsProvider>
      <PunchMatrixProvider>
        <Router
          onlyTabs={onlyTabs}
          actions={definedActions.length ? definedActions : pageActions}
          tab={tab || 'summary'}
          setTab={(id) => setActiveTab('punches', id)}
          isLoading={isLoadingWorker}
          defaultTab="summary"
          tabs={[
            {
              component: <PunchesTable />,
              icon: <ClockIcon />,
              label: 'Marcaciones',
              value: 'summary',
            },
            {
              component: <PunchesMap />,
              icon: <MapPinIcon />,
              label: 'Mapa',
              value: 'map',
            },
            ...tabs,
          ]}
        />

        <ExportDrawer
          isOpen={isExportDrawerOpen}
          setIsOpen={setIsExportDrawerOpen}
        />
      </PunchMatrixProvider>
    </MovementsProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
