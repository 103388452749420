import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const handleDeleteVacations = async (ids: string[]) => {
  await apiV2.post<void>('/vacation/delete-vacations', {
    ids,
  })
  queryClient.invalidateQueries()
}

export const useHandleDeleteVacations = () => {
  const mutation = useCustomMutation(
    ['handleDeleteVacations'],
    handleDeleteVacations,
  )

  return mutation
}
