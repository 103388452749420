import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { DocumentsManagement, DocumentsStructure } from './components'
import {
  DocumentDuplicateIcon,
  FolderPlusIcon,
} from '@heroicons/react/24/outline'
import { FormProvider, useForm } from 'react-hook-form'
import {
  documentsStateSchema,
  IDocumentsStateSchema,
  INITIAL_DOCUMENT_STATE,
} from './documents-state'
import { zodResolver } from '@hookform/resolvers/zod'
import { DocumentsSizeProgress } from './components/DocumentsSizeProgress'

export default function Documents({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const form = useForm<IDocumentsStateSchema>({
    defaultValues: {
      ...INITIAL_DOCUMENT_STATE,
    },
    resolver: zodResolver(documentsStateSchema),
  })

  return (
    <FormProvider {...form}>
      <Router
        onlyTabs={onlyTabs}
        defaultTab="structure"
        actions={[]}
        customActions={[
          {
            component: <DocumentsSizeProgress />,
          },
        ]}
        tabs={[
          {
            icon: <FolderPlusIcon />,
            label: 'Estructura de Pastas',
            value: 'structure',
            scrollable: false,
            component: <DocumentsStructure />,
          },
          {
            icon: <DocumentDuplicateIcon />,
            label: 'Documentos de Colaboradores',
            value: 'workers-documents',
            scrollable: false,
            component: <DocumentsManagement />,
          },
        ]}
      />
    </FormProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
