import {
  PayrollDetailed,
  PayrollDetailedDayStatus,
  PayrollDetailedRow,
} from '@/libs/react-query/types/payroll'
import {
  PayrollPlaygroundContentType,
  PayrollPlaygroundTableType,
} from './types'
import dayjs from 'dayjs'
import { TagProps } from '@punto-ui/react'
import { formatMinutes } from '@/utils/date'
import {
  debitPaymentsHeaderRaw,
  paymentsHeaderRaw,
} from '@/libs/react-query/types'

const CURRENCY_PATTERN_BY_CURRENCY: Record<string, string> = {
  PYG: 'money-guarani',
  BRL: 'money',
  USD: 'money-usd',
}

export const fromPayrollDetailedToPlayground = (
  payroll: PayrollDetailed,
  nameFilterPreviousValue: string,
  cellsIdsPreviousValue: string[],
  salaryConcepts: string[],
): PayrollPlaygroundTableType => {
  const fromDayStatusToDayLabel: Record<
    PayrollDetailedDayStatus,
    {
      label: string
      variant: TagProps['variant']
    }
  > = {
    WORKING: {
      label: 'P',
      variant: 'positive',
    },
    ABSENCE: {
      label: 'F',
      variant: 'negative',
    },
    MOVEMENT: {
      label: 'M',
      variant: 'neutral',
    },
    'ALL-DAY-MOVEMENT': {
      label: 'M',
      variant: 'neutral',
    },
    REST: {
      label: 'D',
      variant: 'neutral',
    },
    HOLIDAY: {
      label: 'Fe',
      variant: 'neutral',
    },
    INCONSISTENCY: {
      label: '?',
      variant: 'warning',
    },
    VACATION: {
      label: 'V',
      variant: 'neutral',
    },
    NOT_HIRED: {
      label: 'N',
      variant: 'warning',
    },
  }

  const sortedConcepts = salaryConcepts.sort()

  const payrollData: PayrollPlaygroundContentType[] = payroll.data.map(
    (data) => {
      const payrollCurrencyPattern =
        CURRENCY_PATTERN_BY_CURRENCY[data.currency || 'PYG'] || 'money-guarani'

      const orderedDays = [...data.days].sort((a, b) => {
        return dayjs(a.date).diff(dayjs(b.date))
      })

      return {
        userId: data.worker.id,
        checked: {
          value: false,
          status: '',
        },
        name: {
          value: data.worker.firstName,
          status: '',
        },
        avatar: {
          url: data.worker.photo_url || '',
          alt: data.worker.name || '',
        },
        surname: {
          value: data.worker.surname,
          status: '',
        },
        ci: {
          value: data.worker.document || '',
          status: '',
        },
        punchConfigId: {
          value: data.punchConfigId,
          status: '',
        },
        paymentConfigId: {
          value: data.paymentConfigId,
          status: '',
        },

        // Calendario
        daysSummary: orderedDays.map((day) => {
          return [
            {
              id: day.date,
              name: fromDayStatusToDayLabel[day.status]?.label || 'F',
              variant:
                fromDayStatusToDayLabel[day.status]?.variant || 'negative',
            },
          ]
        }),
        naturalPaidDays: {
          value: `${data.workedDays?.normal} dias`,
          status: '',
        },
        naturalUnpaidDays: {
          value: `${
            payroll.data?.[0].days.length - data.workedDays?.normal
          } dias`,
          status: '',
        },
        countablePaidDays: {
          value: `${data.paidDays?.normal} dias`,
          status: '',
        },
        countableUnpaidDays: {
          value: `${
            payroll.data?.[0].days.length === 31
              ? 30 - data.paidDays?.normal
              : payroll.data?.[0].days.length - data.paidDays?.normal
          } dias`,
          status: '',
        },

        // Horas en Haber
        totalMorningOrdinaryHours: {
          value: formatMinutes(data.hours.morning.hours * 60),
          status: '',
          original: formatMinutes(data.hours.morning.hours * 60),
        },
        totalWorkedDaysCompensation: {
          value: data.values.normal,
          status: '',
          original: data.values.normal,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalWorkedDaysCompensationIps: {
          value: data.values.ips,
          status: '',
          original: data.values.ips,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalMorningExtraHours: {
          value: formatMinutes(data.hours.morning.extraHours * 60),
          status: '',
          original: formatMinutes(data.hours.morning.extraHours * 60),
        },
        totalMorningExtraValue: {
          value: data.hours.morning.extraHoursValue,
          status: '',
          original: data.hours.morning.extraHoursValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalMorningHolidayHours: {
          value: formatMinutes(data.hours.morning.holidayHours * 60),
          status: '',
          original: formatMinutes(data.hours.morning.holidayHours * 60),
        },
        totalMorningHolidayValue: {
          value: data.hours.morning.holidayHoursValue,
          status: '',
          original: data.hours.morning.holidayHoursValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalMorningHours: {
          value: formatMinutes(data.hours.morning.totalHours * 60),
          status: '',
          original: formatMinutes(data.hours.morning.totalHours * 60),
        },
        totalMorningValue: {
          value: data.hours.morning.totalMorningValue,
          status: '',
          original: data.hours.morning.totalMorningValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        additionalNightHours: {
          value: data.hours.night.hoursValues,
          status: '',
          original: data.hours.night.hoursValues,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalNightHours: {
          value: formatMinutes(data.hours.night.totalHours * 60),
          status: '',
          original: formatMinutes(data.hours.night.totalHours * 60),
        },
        totalHours: {
          value: formatMinutes(data.hours.totalHours * 60),
          status: '',
          original: formatMinutes(data.hours.totalHours * 60),
        },
        totalHoursValue: {
          value: data.hours.totalHoursValue,
          status: '',
          original: data.hours.totalHoursValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalNightValue: {
          value: data.hours.night.totalNightValue,
          status: '',
          original: data.hours.night.totalNightValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalNightExtraHours: {
          value: formatMinutes(data.hours.night.extraHours * 60),
          status: '',
          original: formatMinutes(data.hours.night.extraHours * 60),
        },
        totalNightExtraValue: {
          value: data.hours.night.extraHoursValue,
          status: '',
          original: data.hours.night.extraHoursValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalNightHolidayHours: {
          value: formatMinutes(data.hours.night.holidayHours * 60),
          status: '',
          original: formatMinutes(data.hours.night.holidayHours * 60),
        },
        totalNightHolidayValue: {
          value: data.hours.night.holidayHoursValue,
          status: '',
          original: data.hours.night.holidayHoursValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalNightOrdinaryHours: {
          value: formatMinutes(data.hours.night.hours * 60),
          status: '',
          original: formatMinutes(data.hours.night.hours * 60),
        },
        vacationDays: {
          value: `${data.vacationInfos.days} dias`,
          status: '',
          original: `${data.vacationInfos.days} dias`,
        },
        vacationDaysValue: {
          value: data.vacationInfos.value,
          status: '',
          original: data.vacationInfos.value,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        childrenBonus: {
          value: !data.paymentsPreIPS.familyMembersForBonus
            ? ''
            : data.paymentsPreIPS.familyMembersForBonus,
          status: '',
          original: !data.paymentsPreIPS.familyMembersForBonus
            ? ''
            : data.paymentsPreIPS.familyMembersForBonus,
        },
        childrenBonusValue: {
          value: !data.paymentsPreIPS.familyBonus
            ? ''
            : data.paymentsPreIPS.familyBonus,
          status: '',
          original: !data.paymentsPreIPS.familyBonus
            ? ''
            : data.paymentsPreIPS.familyBonus,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        creditPaymentsPreIPS: paymentsHeaderRaw.map((header) => {
          const totalPaymentIPSByType = data.paymentsPreIPS.payments.find(
            (payment) => payment.name === header,
          )

          return (
            {
              value: !totalPaymentIPSByType?.value
                ? ''
                : totalPaymentIPSByType?.value,
              status: '',
              original: !totalPaymentIPSByType?.value
                ? ''
                : totalPaymentIPSByType?.value,
              metadata: {
                inputType: payrollCurrencyPattern,
              },
            } || 0
          )
        }),
        conceptsPreIPS: sortedConcepts.map((header, index) => {
          const salaryConcept = data.conceptsInfos.concepts.find((c) => {
            return c.name === header && c.ips
          })

          return {
            value:
              !salaryConcept?.value && salaryConcept?.value !== 0
                ? ''
                : salaryConcept.value,
            status: '',
            original:
              !salaryConcept?.value && salaryConcept?.value !== 0
                ? ''
                : salaryConcept.value,
            metadata: {
              inputType: payrollCurrencyPattern,
            },
          }
        }),
        conceptsAfterIPS: sortedConcepts.map((header) => {
          const salaryConcept = data.conceptsInfos.concepts.find((c) => {
            return c.name === header && !c.ips
          })

          return {
            value:
              !salaryConcept?.value && salaryConcept?.value !== 0
                ? ''
                : salaryConcept.value,
            status: '',
            original:
              !salaryConcept?.value && salaryConcept?.value !== 0
                ? ''
                : salaryConcept.value,
            metadata: {
              inputType: payrollCurrencyPattern,
            },
          }
        }),
        creditPaymentsAfterIPS: paymentsHeaderRaw.map((header) => {
          const totalPaymentIPSByType = data.paymentsAfterIPS.payments.find(
            (payment) => payment.name === header,
          )

          return (
            {
              value: !totalPaymentIPSByType?.value
                ? ''
                : totalPaymentIPSByType?.value,
              status: '',
              original: !totalPaymentIPSByType?.value
                ? ''
                : totalPaymentIPSByType?.value,
              metadata: {
                inputType: payrollCurrencyPattern,
              },
            } || 0
          )
        }),
        paymentsSubtotal: {
          value: !data.paymentsPreIPS.subtotal
            ? ''
            : data.paymentsPreIPS.subtotal,
          original: !data.paymentsPreIPS.subtotal
            ? ''
            : data.paymentsPreIPS.subtotal,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        positiveSubtotal: {
          value: !data.values.positiveSubtotal
            ? ''
            : data.values.positiveSubtotal,
          original: !data.values.positiveSubtotal
            ? ''
            : data.values.positiveSubtotal,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalMorningDiscountHours: {
          value: formatMinutes(data.discountsPreIPS.morningHourDiscount * 60),
          status: '',
          original: formatMinutes(
            data.discountsPreIPS.morningHourDiscount * 60,
          ),
        },
        totalMorningDiscountHoursValue: {
          value: !data.discountsPreIPS.morningHourDiscountValue
            ? ''
            : data.discountsPreIPS.morningHourDiscountValue,
          status: '',
          original: !data.discountsPreIPS.morningHourDiscountValue
            ? ''
            : data.discountsPreIPS.morningHourDiscountValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalNightDiscountHours: {
          value: formatMinutes(data.discountsPreIPS.nightHourDiscount * 60),
          status: '',
          original: formatMinutes(data.discountsPreIPS.nightHourDiscount * 60),
        },
        totalNightDiscountHoursValue: {
          value: !data.discountsPreIPS.nightHourDiscountValue
            ? ''
            : data.discountsPreIPS.nightHourDiscountValue,
          status: '',
          original: !data.discountsPreIPS.nightHourDiscountValue
            ? ''
            : data.discountsPreIPS.nightHourDiscountValue,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        discountsPreIPS: debitPaymentsHeaderRaw.map((header) => {
          const totalDiscountIPSByType = data.discountsPreIPS.discounts.find(
            (payment) => payment.name === header,
          )

          return (
            {
              value: !totalDiscountIPSByType?.value
                ? ''
                : totalDiscountIPSByType?.value,
              status: '',
              original: !totalDiscountIPSByType?.value ? '' : 0,
              metadata: {
                inputType: payrollCurrencyPattern,
              },
            } || 0
          )
        }),
        discountsSubtotal: {
          value: !data.discountsPreIPS.subtotal
            ? ''
            : data.discountsPreIPS.subtotal,
          status: '',
          original: !data.discountsPreIPS.subtotal
            ? ''
            : data.discountsPreIPS.subtotal,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalDiscount: {
          value: !data.discountsPreIPS.totalDiscounts
            ? ''
            : data.discountsPreIPS.totalDiscounts,
          status: '',
          original: !data.discountsPreIPS.totalDiscounts
            ? ''
            : data.discountsPreIPS.totalDiscounts,
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        ipsBase: {
          value: !data.ips.ipsBase ? '' : data.ips.ipsBase,
          original: !data.ips.ipsBase ? '' : data.ips.ipsBase,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        ips16: {
          value: !data.ips.ips16 ? '' : data.ips.ips16,
          original: data.ips.ips16,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        ips9: {
          value: !data.ips.ips9 ? '' : data.ips.ips9,
          original: data.ips.ips9,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        salaryAfterIps: {
          value: !data.ips.subtotalAfterIPS ? '' : data.ips.subtotalAfterIPS,
          original: data.ips.subtotalAfterIPS,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalIps: {
          value: !data.ips.totalIps ? '' : data.ips.totalIps,
          original: data.ips.totalIps,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        discountsAfterIPS: debitPaymentsHeaderRaw.map((header) => {
          const totalDiscountIPSByType = data.discountsAfterIPS.discounts.find(
            (payment) => payment.name === header,
          )

          return (
            {
              value: !totalDiscountIPSByType?.value
                ? ''
                : totalDiscountIPSByType?.value,
              status: '',
              original: !totalDiscountIPSByType?.value
                ? ''
                : totalDiscountIPSByType?.value,
              metadata: {
                inputType: payrollCurrencyPattern,
              },
            } || 0
          )
        }),
        finalSalaryValue: {
          value: !data.totalToReceive ? '' : data.totalToReceive,
          original: data.totalToReceive,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalDiscountsAfterIPS: {
          value: !data.discountsAfterIPS.totalDiscounts
            ? ''
            : data.discountsAfterIPS.totalDiscounts,
          original: data.discountsAfterIPS.totalDiscounts,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalPaymentsAfterIPS: {
          value: !data.paymentsAfterIPS.subtotal
            ? ''
            : data.paymentsAfterIPS.subtotal,
          original: data.paymentsAfterIPS.subtotal,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        negativeIpsSubtotal: {
          value: !data.ips.negativeSubtotal ? '' : data.ips.negativeSubtotal,
          original: data.ips.negativeSubtotal,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        positiveIpsSubtotal: {
          value: !data.ips.positiveSubtotal ? '' : data.ips.positiveSubtotal,
          original: data.ips.positiveSubtotal,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        totalConceptsAfterIPS: {
          value: !data.conceptsInfos.totalConceptsAfterIPS
            ? ''
            : data.conceptsInfos.totalConceptsAfterIPS,
          original: data.conceptsInfos.totalConceptsAfterIPS,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
        baseSalary: {
          value: !data.baseSalary ? '' : data.baseSalary,
          original: data.baseSalary,
          status: '',
          metadata: {
            inputType: payrollCurrencyPattern,
          },
        },
      } as PayrollPlaygroundContentType
    },
  )

  const totalLine = buildTotalLine(payroll.data, salaryConcepts)

  const periods = [
    dayjs(
      dayjs(payroll.payroll.start_date).utc().format('YYYY-MM-DD'),
      'YYYY-MM-DD',
    ).toDate(),
    dayjs(
      dayjs(payroll.payroll.end_date).utc().format('YYYY-MM-DD'),
      'YYYY-MM-DD',
    ).toDate(),
  ]

  const signatureDate = payroll.payroll.signature_date
    ? [
        dayjs(
          dayjs(payroll.payroll.signature_date).utc().format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).toDate(),
      ]
    : []

  payrollData.push(...totalLine)

  return {
    id: payroll.payroll.id,
    description: payroll.payroll.description || '',
    cell: '',
    status: payroll.payroll.status,
    cellsIds: cellsIdsPreviousValue,
    nameFilter: nameFilterPreviousValue,
    isFavorite: payroll.payroll.is_favorite || false,
    name: payroll.payroll.name || '',
    signatureDate,
    periods,
    users_ids: payroll.payroll.users.map((user) => user.user_id),
    data: payrollData,
    pagination: {
      page: 1,
      perPage: 10,
      total: payrollData.length,
      totalPages: payroll.totalPages,
    },
  }
}

interface TotalLineValues {
  naturalUnpaidDays: number
  countableUnpaidDays: number
  naturalPaidDays: number
  countablePaidDays: number
  totalMorningOrdinaryHours: number
  totalWorkedDaysCompensation: number
  totalWorkedDaysCompensationIps: number
  totalMorningExtraHours: number
  totalMorningExtraValue: number
  totalMorningHolidayHours: number
  totalMorningHolidayValue: number
  totalMorningHours: number
  totalMorningValue: number
  additionalNightHours: number
  totalNightHours: number
  totalHours: number
  totalHoursValue: number
  totalNightValue: number
  totalNightExtraHours: number
  totalNightExtraValue: number
  totalNightHolidayHours: number
  totalNightHolidayValue: number
  totalNightOrdinaryHours: number
  vacationDays: number
  vacationDaysValue: number
  childrenBonus: number
  childrenBonusValue: number
  creditPaymentsPreIPS: number[]
  conceptsPreIPS: number[]
  conceptsAfterIPS: number[]
  creditPaymentsAfterIPS: number[]
  paymentsSubtotal: number
  positiveSubtotal: number
  totalMorningDiscountHours: number
  totalMorningDiscountHoursValue: number
  totalNightDiscountHours: number
  totalNightDiscountHoursValue: number
  discountsPreIPS: number[]
  discountsSubtotal: number
  totalDiscount: number
  ipsBase: number
  ips16: number
  ips9: number
  salaryAfterIps: number
  totalIps: number
  discountsAfterIPS: number[]
  finalSalaryValue: number
  totalDiscountsAfterIPS: number
  totalPaymentsAfterIPS: number
  negativeIpsSubtotal: number
  positiveIpsSubtotal: number
  totalConceptsAfterIPS: number
  baseSalary: number
}

type TotalLineByCurrency = Record<string, TotalLineValues>

const EMPTY_TOTAL_LINE = {
  naturalUnpaidDays: 0,
  countableUnpaidDays: 0,
  naturalPaidDays: 0,
  countablePaidDays: 0,
  totalMorningOrdinaryHours: 0,
  totalWorkedDaysCompensation: 0,
  totalWorkedDaysCompensationIps: 0,
  totalMorningExtraHours: 0,
  totalMorningExtraValue: 0,
  totalMorningHolidayHours: 0,
  totalMorningHolidayValue: 0,
  totalMorningHours: 0,
  totalMorningValue: 0,
  additionalNightHours: 0,
  totalNightHours: 0,
  totalHours: 0,
  totalHoursValue: 0,
  totalNightValue: 0,
  totalNightExtraHours: 0,
  totalNightExtraValue: 0,
  totalNightHolidayHours: 0,
  totalNightHolidayValue: 0,
  totalNightOrdinaryHours: 0,
  vacationDays: 0,
  vacationDaysValue: 0,
  childrenBonus: 0,
  childrenBonusValue: 0,
  creditPaymentsPreIPS: [],
  conceptsPreIPS: [],
  conceptsAfterIPS: [],
  creditPaymentsAfterIPS: [],
  paymentsSubtotal: 0,
  positiveSubtotal: 0,
  totalMorningDiscountHours: 0,
  totalMorningDiscountHoursValue: 0,
  totalNightDiscountHours: 0,
  totalNightDiscountHoursValue: 0,
  discountsPreIPS: [],
  discountsSubtotal: 0,
  totalDiscount: 0,
  ipsBase: 0,
  ips16: 0,
  ips9: 0,
  salaryAfterIps: 0,
  totalIps: 0,
  discountsAfterIPS: [],
  finalSalaryValue: 0,
  totalDiscountsAfterIPS: 0,
  totalPaymentsAfterIPS: 0,
  negativeIpsSubtotal: 0,
  positiveIpsSubtotal: 0,
  totalConceptsAfterIPS: 0,
  baseSalary: 0,
} as TotalLineValues

const buildTotalLine = (
  payrollData: PayrollDetailedRow[],
  salaryConcepts: string[],
): PayrollPlaygroundContentType[] => {
  const sortedConcepts = salaryConcepts.sort()

  const totalByCurrency = payrollData.reduce(
    (acc, data) => {
      const currency = data.currency || 'PYG'

      if (!acc[currency]) {
        acc[currency] = { ...EMPTY_TOTAL_LINE }
      }

      const item = acc[currency]

      item.naturalUnpaidDays += data.days.length - data.workedDays?.normal
      item.countableUnpaidDays +=
        data.days.length === 31
          ? 30 - data.paidDays?.normal
          : data.days.length - data.paidDays?.normal
      item.naturalPaidDays += data.workedDays?.normal
      item.countablePaidDays += data.paidDays?.normal
      item.totalMorningOrdinaryHours += data.hours.morning.hours
      item.totalWorkedDaysCompensation += data.values.normal
      item.totalWorkedDaysCompensationIps += data.values.ips
      item.totalMorningExtraHours += data.hours.morning.extraHours
      item.totalMorningExtraValue += data.hours.morning.extraHoursValue
      item.totalMorningHolidayHours += data.hours.morning.holidayHours
      item.totalMorningHolidayValue += data.hours.morning.holidayHoursValue
      item.totalMorningHours += data.hours.morning.totalHours
      item.totalMorningValue += data.hours.morning.totalMorningValue
      item.additionalNightHours += data.hours.night.hoursValues
      item.totalNightHours += data.hours.night.totalHours
      item.totalHours += data.hours.totalHours
      item.totalHoursValue += data.hours.totalHoursValue
      item.totalNightValue += data.hours.night.totalNightValue
      item.totalNightExtraHours += data.hours.night.extraHours
      item.totalNightExtraValue += data.hours.night.extraHoursValue
      item.totalNightHolidayHours += data.hours.night.holidayHours
      item.totalNightHolidayValue += data.hours.night.holidayHoursValue
      item.totalNightOrdinaryHours += data.hours.night.hours
      item.vacationDays += data.vacationInfos.days
      item.vacationDaysValue += data.vacationInfos.value
      item.childrenBonus += data.paymentsPreIPS.familyMembersForBonus
      item.childrenBonusValue += data.paymentsPreIPS.familyBonus

      item.creditPaymentsPreIPS = paymentsHeaderRaw.map((header, index) => {
        const totalPaymentIPSByType = data.paymentsPreIPS.payments.find(
          (payment) => payment.name === header,
        )
        const value = totalPaymentIPSByType?.value || 0

        const previousValue = item.creditPaymentsPreIPS[index] || 0

        return previousValue + value
      })

      item.conceptsPreIPS = sortedConcepts.map((header, index) => {
        const salaryConcept = data.conceptsInfos.concepts.find((c) => {
          return c.name === header && c.ips
        })

        const salaryValue = salaryConcept?.value || 0
        const oldValue = item.conceptsPreIPS[index] || 0

        return oldValue + salaryValue
      })
      item.conceptsAfterIPS = sortedConcepts.map((header, index) => {
        const salaryConcept = data.conceptsInfos.concepts.find((c) => {
          return c.name === header && !c.ips
        })

        const salaryValue = salaryConcept?.value || 0
        const oldValue = item.conceptsAfterIPS[index] || 0

        return oldValue + salaryValue
      })
      item.creditPaymentsAfterIPS = paymentsHeaderRaw.map((header, index) => {
        const totalPaymentIPSByType = data.paymentsAfterIPS.payments.find(
          (payment) => payment.name === header,
        )
        const value = totalPaymentIPSByType?.value || 0

        const previousValue = item.creditPaymentsAfterIPS[index] || 0

        return previousValue + value
      })

      item.paymentsSubtotal += data.paymentsPreIPS.subtotal
      item.positiveSubtotal += data.values.positiveSubtotal
      item.totalMorningDiscountHours += data.discountsPreIPS.morningHourDiscount
      item.totalMorningDiscountHoursValue +=
        data.discountsPreIPS.morningHourDiscountValue
      item.totalNightDiscountHours += data.discountsPreIPS.nightHourDiscount

      item.totalNightDiscountHoursValue +=
        data.discountsPreIPS.nightHourDiscountValue
      item.discountsPreIPS = item.discountsPreIPS.map((value, index) => {
        return value + data.discountsPreIPS.discounts[index].value
      })
      item.discountsSubtotal += data.discountsPreIPS.subtotal
      item.totalDiscount += data.discountsPreIPS.totalDiscounts
      item.ipsBase += data.ips.ipsBase
      item.ips16 += data.ips.ips16
      item.ips9 += data.ips.ips9
      item.salaryAfterIps += data.ips.subtotalAfterIPS
      item.totalIps += data.ips.totalIps
      item.discountsAfterIPS = item.discountsAfterIPS.map((value, index) => {
        return value + data.discountsAfterIPS.discounts[index].value
      })
      item.finalSalaryValue += data.totalToReceive
      item.totalDiscountsAfterIPS += data.discountsAfterIPS.totalDiscounts
      item.totalPaymentsAfterIPS += data.paymentsAfterIPS.subtotal
      item.negativeIpsSubtotal += data.ips.negativeSubtotal
      item.positiveIpsSubtotal += data.ips.positiveSubtotal
      item.totalConceptsAfterIPS += data.conceptsInfos.totalConceptsAfterIPS
      item.baseSalary += data.baseSalary

      return acc
    },

    {} as TotalLineByCurrency,
  )

  const result = Object.entries(totalByCurrency).map(([currency, total]) => {
    const payrollCurrencyPattern =
      CURRENCY_PATTERN_BY_CURRENCY[currency || 'PYG'] || 'money-guarani'

    return {
      userId: '',
      checked: {
        value: false,
        status: '',
      },
      name: {
        value: '',
        status: '',
      },
      avatar: {
        url: '',
        alt: '',
      },
      surname: {
        value: '',
        status: '',
      },
      ci: {
        value: '',
        status: '',
      },
      punchConfigId: {
        value: '',
        status: '',
      },
      paymentConfigId: {
        value: '',
        status: '',
      },

      // Calendario
      daysSummary: payrollData?.[0]?.days?.map((day) => {
        return []
      }),
      naturalPaidDays: {
        value: `${total.naturalPaidDays} dias`,
        status: '',
      },
      naturalUnpaidDays: {
        value: `${total.naturalUnpaidDays} dias`,
        status: '',
      },
      countablePaidDays: {
        value: `${total.countablePaidDays} dias`,
        status: '',
      },
      countableUnpaidDays: {
        value: `${total.countableUnpaidDays} dias`,
        status: '',
      },

      // Horas en Haber
      totalMorningOrdinaryHours: {
        value: formatMinutes(total.totalMorningOrdinaryHours * 60),
        status: '',
        original: formatMinutes(total.totalMorningOrdinaryHours * 60),
      },
      totalWorkedDaysCompensation: {
        value: total.totalWorkedDaysCompensation,
        status: '',
        original: total.totalWorkedDaysCompensation,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalWorkedDaysCompensationIps: {
        value: total.totalWorkedDaysCompensationIps,
        status: '',
        original: total.totalWorkedDaysCompensationIps,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalMorningExtraHours: {
        value: formatMinutes(total.totalMorningExtraHours * 60),
        status: '',
        original: formatMinutes(total.totalMorningExtraHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalMorningExtraValue: {
        value: total.totalMorningExtraValue,
        status: '',
        original: total.totalMorningExtraValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalMorningHolidayHours: {
        value: formatMinutes(total.totalMorningHolidayHours * 60),
        status: '',
        original: formatMinutes(total.totalMorningHolidayHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalMorningHolidayValue: {
        value: total.totalMorningHolidayValue,
        status: '',
        original: total.totalMorningHolidayValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalMorningHours: {
        value: formatMinutes(total.totalMorningHours * 60),
        status: '',
        original: formatMinutes(total.totalMorningHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalMorningValue: {
        value: total.totalMorningValue,
        status: '',
        original: total.totalMorningValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      additionalNightHours: {
        value: total.additionalNightHours,
        status: '',
        original: total.additionalNightHours,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalNightHours: {
        value: formatMinutes(total.totalNightHours * 60),
        status: '',
        original: formatMinutes(total.totalNightHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalHours: {
        value: formatMinutes(total.totalHours * 60),
        status: '',
        original: formatMinutes(total.totalHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalHoursValue: {
        value: total.totalHoursValue,
        status: '',
        original: total.totalHoursValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalNightValue: {
        value: total.totalNightValue,
        status: '',
        original: total.totalNightValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalNightExtraHours: {
        value: formatMinutes(total.totalNightExtraHours * 60),
        status: '',
        original: formatMinutes(total.totalNightExtraHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalNightExtraValue: {
        value: total.totalNightExtraValue,
        status: '',
        original: total.totalNightExtraValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalNightHolidayHours: {
        value: formatMinutes(total.totalNightHolidayHours * 60),
        status: '',
        original: formatMinutes(total.totalNightHolidayHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalNightHolidayValue: {
        value: total.totalNightHolidayValue,
        status: '',
        original: total.totalNightHolidayValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalNightOrdinaryHours: {
        value: formatMinutes(total.totalNightOrdinaryHours * 60),
        status: '',
        original: formatMinutes(total.totalNightOrdinaryHours * 60),
        metadata: {
          disabled: true,
        },
      },
      vacationDays: {
        value: `${total.vacationDays} dias`,
        status: '',
        original: `${total.vacationDays} dias`,
        metadata: {
          disabled: true,
        },
      },
      vacationDaysValue: {
        value: total.vacationDaysValue,
        status: '',
        original: total.vacationDaysValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      childrenBonus: {
        value: !total.childrenBonus ? '' : total.childrenBonus,
        status: '',
        original: !total.childrenBonus ? '' : total.childrenBonus,
        metadata: {
          disabled: true,
        },
      },
      childrenBonusValue: {
        value: !total.childrenBonusValue ? '' : total.childrenBonusValue,
        status: '',
        original: !total.childrenBonusValue ? '' : total.childrenBonusValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      creditPaymentsPreIPS: total.creditPaymentsPreIPS.map((credit) => {
        return {
          value: !credit ? '' : credit,
          status: '',
          original: !credit ? '' : credit,
          metadata: {
            disabled: true,
            inputType: payrollCurrencyPattern,
          },
        }
      }),
      conceptsPreIPS: total.conceptsPreIPS.map((concept) => {
        return {
          value: !concept ? '' : concept,
          status: '',
          original: !concept ? '' : concept,
          metadata: {
            disabled: true,
            inputType: payrollCurrencyPattern,
          },
        }
      }),
      conceptsAfterIPS: total.conceptsAfterIPS.map((concept) => {
        return {
          value: !concept ? '' : concept,
          status: '',
          original: !concept ? '' : concept,
          metadata: {
            disabled: true,
            inputType: payrollCurrencyPattern,
          },
        }
      }),
      creditPaymentsAfterIPS: total.creditPaymentsAfterIPS.map((credit) => {
        return {
          value: !credit ? '' : credit,
          status: '',
          original: !credit ? '' : credit,
          metadata: {
            disabled: true,
            inputType: payrollCurrencyPattern,
          },
        }
      }),
      paymentsSubtotal: {
        value: !total.paymentsSubtotal ? '' : total.paymentsSubtotal,
        original: !total.paymentsSubtotal ? '' : total.paymentsSubtotal,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      positiveSubtotal: {
        value: !total.positiveSubtotal ? '' : total.positiveSubtotal,
        original: !total.positiveSubtotal ? '' : total.positiveSubtotal,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalMorningDiscountHours: {
        value: formatMinutes(total.totalMorningDiscountHours * 60),
        status: '',
        original: formatMinutes(total.totalMorningDiscountHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalMorningDiscountHoursValue: {
        value: !total.totalMorningDiscountHoursValue
          ? ''
          : total.totalMorningDiscountHoursValue,
        status: '',
        original: !total.totalMorningDiscountHoursValue
          ? ''
          : total.totalMorningDiscountHoursValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalNightDiscountHours: {
        value: formatMinutes(total.totalNightDiscountHours * 60),
        status: '',
        original: formatMinutes(total.totalNightDiscountHours * 60),
        metadata: {
          disabled: true,
        },
      },
      totalNightDiscountHoursValue: {
        value: !total.totalNightDiscountHoursValue
          ? ''
          : total.totalNightDiscountHoursValue,
        status: '',
        original: !total.totalNightDiscountHoursValue
          ? ''
          : total.totalNightDiscountHoursValue,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      discountsPreIPS: total.discountsPreIPS.map((discount) => {
        return {
          value: !discount ? '' : discount,
          status: '',
          original: !discount ? '' : discount,
          metadata: {
            disabled: true,
            inputType: payrollCurrencyPattern,
          },
        }
      }),
      discountsSubtotal: {
        value: !total.discountsSubtotal ? '' : total.discountsSubtotal,
        status: '',
        original: !total.discountsSubtotal ? '' : total.discountsSubtotal,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalDiscount: {
        value: !total.totalDiscount ? '' : total.totalDiscount,
        status: '',
        original: !total.totalDiscount ? '' : total.totalDiscount,
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      ipsBase: {
        value: !total.ipsBase ? '' : total.ipsBase,
        original: !total.ipsBase ? '' : total.ipsBase,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      ips16: {
        value: !total.ips16 ? '' : total.ips16,
        original: total.ips16,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      ips9: {
        value: !total.ips9 ? '' : total.ips9,
        original: total.ips9,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      salaryAfterIps: {
        value: !total.salaryAfterIps ? '' : total.salaryAfterIps,
        original: total.salaryAfterIps,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalIps: {
        value: !total.totalIps ? '' : total.totalIps,
        original: total.totalIps,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      discountsAfterIPS: total.discountsAfterIPS.map((discount) => {
        return {
          value: !discount ? '' : discount,
          status: '',
          original: !discount ? '' : discount,
          metadata: {
            disabled: true,
            inputType: payrollCurrencyPattern,
          },
        }
      }),
      finalSalaryValue: {
        value: !total.finalSalaryValue ? '' : total.finalSalaryValue,
        original: total.finalSalaryValue,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalDiscountsAfterIPS: {
        value: !total.totalDiscountsAfterIPS
          ? ''
          : total.totalDiscountsAfterIPS,
        original: total.totalDiscountsAfterIPS,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalPaymentsAfterIPS: {
        value: !total.totalPaymentsAfterIPS ? '' : total.totalPaymentsAfterIPS,
        original: total.totalPaymentsAfterIPS,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      negativeIpsSubtotal: {
        value: !total.negativeIpsSubtotal ? '' : total.negativeIpsSubtotal,
        original: total.negativeIpsSubtotal,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      positiveIpsSubtotal: {
        value: !total.positiveIpsSubtotal ? '' : total.positiveIpsSubtotal,
        original: total.positiveIpsSubtotal,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      totalConceptsAfterIPS: {
        value: !total.totalConceptsAfterIPS ? '' : total.totalConceptsAfterIPS,
        original: total.totalConceptsAfterIPS,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
      baseSalary: {
        value: !total.baseSalary ? '' : total.baseSalary,
        original: total.baseSalary,
        status: '',
        metadata: {
          disabled: true,
          inputType: payrollCurrencyPattern,
        },
      },
    } as PayrollPlaygroundContentType
  })
  return result
}
