import { Div } from '@/components'
import { DropdownV2 } from '@/components/DropdownV2'
import { queryClient } from '@/libs/react-query'
import { useCompanies, useMe } from '@/libs/react-query/hooks'
import { useHandleUpdateCompanyId } from '@/libs/react-query/mutations/admin/useHandleUpdateCompanyId'
import { useTabStore } from '@/store'
import { KeyIcon } from '@heroicons/react/24/outline'
import { Progress, Text } from '@punto-ui/react'
import { useState } from 'react'

export const ChangeCompanyComponent = () => {
  const { data: companies, isFetching: isFetchingCompany } = useCompanies()
  const { data: me } = useMe()
  const [selectedCompany, setSelectedCompany] = useState(me?.company_id || '')

  const {
    mutateAsync: handleUpdateCompanyId,
    isLoading: isLoadingUpdateCompanyId,
  } = useHandleUpdateCompanyId()

  const { reset } = useTabStore((state) => ({
    reset: state.reset,
  }))

  const isLoading = isLoadingUpdateCompanyId || isFetchingCompany

  if (!me || !companies?.length) {
    return (
      <Div
        css={{
          margin: '$4',
          background: '$interface_light_pure',
          padding: '$4',
          height: 150,
        }}
      >
        <Progress />
      </Div>
    )
  }

  return (
    <Div>
      <Div
        css={{
          margin: '$4',
          background: '$interface_light_pure',
          padding: '$4',
          height: 150,
        }}
      >
        <Div
          css={{
            flex: 1,

            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            marginBottom: '$4',
            svg: { height: 16, width: 16, color: '$interface_dark_pure' },
          }}
        >
          <KeyIcon />
          <Text variant={'heading4'}>Cambiar Empresa</Text>
        </Div>

        <Text css={{ marginBottom: '$2' }}>Seleccione la empresa</Text>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$4',
          }}
        >
          <Div css={{ width: 400 }}>
            <DropdownV2
              containerCss={{
                zIndex: 4,
              }}
              value={selectedCompany}
              options={
                companies
                  ?.filter((c) => c.businessInfos?.status !== 'DELETED')
                  .map((c) => ({
                    label: c.name,
                    value: c.id,
                  })) || []
              }
              onChangeValue={async (v) => {
                if (me?.company_id !== v.value && v.value) {
                  setSelectedCompany(v.value)
                  await handleUpdateCompanyId(v.value)
                  reset()

                  queryClient.resetQueries()
                }
              }}
              defaultOption={{
                label:
                  companies?.find((c) => c.id === selectedCompany)?.name || '',
                value: selectedCompany,
              }}
            />
          </Div>
          {isLoading && <Progress />}
        </Div>
      </Div>
    </Div>
  )
}
