import Router from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { api, apiV2 } from '../services/api'
import { RegisterFormData } from '@/pages/auth/register/forms'

type SignInCredentials = {
  email: string
  password: string
}

type SignUpCredentials = RegisterFormData

type AuthContextData = {
  signOut: () => Promise<void>
  signUp: (data: SignUpCredentials) => Promise<void>
  signIn: (data: SignInCredentials) => Promise<void>
  isAuthenticated: boolean
}

export const AuthContext = createContext({} as AuthContextData)

type AuthProviderProps = {
  children: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const { '@PuntoOk:token': token } = parseCookies()

    if (token) {
      setIsAuthenticated(true)
    }
  }, [])

  async function signIn({ email, password }: SignInCredentials) {
    try {
      const response = await apiV2.post('/auth/sessions', { email, password })

      apiV2.defaults.headers.Authorization = `Bearer ${response.data.token}`
      setCookie(undefined, '@PuntoOk:token', response.data.token, {
        maxAge: 60 * 60 * 24 * 30 * 12, // 1 year
        path: '/',
      })

      setIsAuthenticated(true)

      // Router.push('/')
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async function signUp({
    email,
    ipsNumber,
    mtssNumber,
    name,
    password,
    phone,
    ruc,
    legalRepresentative,
    businessName,
    contactName,
  }: SignUpCredentials) {
    try {
      await apiV2.post('/company/create', {
        business_name: name,
        email,
        phone,
        name: contactName,
        document: ruc,
      })

      Router.push('/')
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async function signOut() {
    setIsAuthenticated(false)

    setCookie(undefined, '@PuntoOk:token', '', {
      maxAge: 60 * 60 * 24 * 30 * 12, // 1 year
      path: '/',
    })

    api.defaults.headers.Authorization = null

    Router.push('/')
  }

  return (
    <AuthContext.Provider value={{ signIn, signUp, signOut, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}
