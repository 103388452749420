import { Div } from '@/components'
import { useWorkerCreation } from '../../context/hooks'
import {
  useRequiredDataForm,
  useOptionalDataForm,
  useMarcacionAsistenciaForm,
  useAddressData,
  useBankForm,
  usePaymentConfigForm,
  useRequiredCompanyData,
  useCellData,
  useIntegrationData,
  useVacationConfigForm,
} from '../../forms'
import {
  FormGroup,
  MarcacionConfig,
  ChildrenData,
  ContactsData,
  FaceIdPreview,
  PaymentConfig,
  ProfilePicturePreview,
} from './components'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  createCompleteWorkerData,
  useHandleChangePassword,
} from '@/libs/react-query/mutations'
import { useFaceIdForm } from '../../forms/useFaceIdForm'
import { useProfilePictureForm } from '../../forms/useProfilePictureForm'
import { usePasswordData } from '../../forms/usePasswordData'
import { Button, Text, styled } from '@punto-ui/react'
import useToast from '@/hooks/useToast'
import { useAguinaldoConfigForm } from '../../forms/useAguinaldoConfigForm'
import { useLiquidationConfigForm } from '../../forms/useLiquidationConfigForm'
import { useCareerInfos } from '../../forms/useCareerInfos'
import { SalaryHistory } from './components/SalaryHistory'
import { useIsWorkerFormAvailable } from '../../hooks/useIsWorkerFormAvailable'

export const FormContent = () => {
  const { addToast } = useToast()
  const {
    mutateAsync: handleChangePassword,
    isLoading: isLoadingChangePassword,
  } = useHandleChangePassword()
  const { formStep, isLoading, isCreation } = useWorkerCreation()

  const { data: requiredDataForm } = useRequiredDataForm()
  const { data: careerInfosForm } = useCareerInfos()
  const { data: passwordData } = usePasswordData()
  const { data: optionalDataForm } = useOptionalDataForm()
  const { data: marcacionAsistencia } = useMarcacionAsistenciaForm()
  const { data: faceIdForm } = useFaceIdForm()
  const { data: profilePictureForm } = useProfilePictureForm()

  const { data: addressForm } = useAddressData()
  const { data: paymentConfigForm } = usePaymentConfigForm()
  const { data: vacationConfigForm } = useVacationConfigForm()
  const { data: aguinaldoConfigForm } = useAguinaldoConfigForm()
  const { data: liquidationConfigForm } = useLiquidationConfigForm()
  const { data: companyDataForm } = useRequiredCompanyData()
  const { data: bankForm } = useBankForm()
  const { data: cellData } = useCellData()
  const { data: integrationData } = useIntegrationData()

  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const { control, getValues, trigger } =
    useFormContext<createCompleteWorkerData>()

  const punchConfigIds = useWatch({
    control,
    name: 'punch_config_ids',
  })

  const paymentConfigId = useWatch({
    control,
    name: 'payment_config_id',
  })

  const formStepType: Record<number, string> = {
    0: 'BASIC_INFOS',
    1: 'PUNCH_CONFIG',
    2: 'PAYMENT_CONFIG',
    3: 'SALARY_CONFIG',
  }

  const handleSubmitChangePassword = async () => {
    const isValid = trigger(['password', 'confirm_password'])
    if (!isValid) return

    const { id, password } = getValues()
    try {
      await handleChangePassword({
        password,
        user_id: id || '',
      })
      addToast({
        title: 'Contraseña cambiada',
        description: 'Nueva contraseña guardada correctamente',
        type: 'positive',
        id: 1,
      })
    } catch (err) {
      addToast({
        title: 'Error',
        description: 'Ocurrio un error al cambiar la contraseña',
        type: 'negative',
        id: 1,
      })
    }
  }

  const currentStep = formStepType[formStep]

  if (isLoading) {
    return null
  }

  return (
    <div>
      {currentStep === 'BASIC_INFOS' && (
        <>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CardBox
              css={{
                marginRight: '$4',
              }}
            >
              <FormGroup
                title={'Datos obligatorios'}
                items={requiredDataForm}
                // css={{ marginBottom: '$3' }}
                cols={2}
              />
            </CardBox>
            <CardBox
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
            >
              <FormGroup
                css={{
                  flex: 1,
                  justifyContent: 'space-between',
                  [`${Text}`]: {
                    alingSelf: 'flex-start',
                  },
                }}
                title={'Contraseña'}
                items={passwordData}
                cols={1}
              />
              {!isCreation && (
                <Button
                  onClick={handleSubmitChangePassword}
                  isLoading={isLoadingChangePassword}
                  disabled={!isWorkerCreationAvailable}
                >
                  Cambiar
                </Button>
              )}
            </CardBox>
          </Div>
          <CardBox>
            <FormGroup
              title={'Datos Opcionales'}
              items={optionalDataForm}
              cols={3}
            />
          </CardBox>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$4',
            }}
          >
            <CardBox
              css={{
                minHeight: 250,
              }}
            >
              <FormGroup title={'Foto de Perfil'} items={profilePictureForm} />
              <Div css={{ marginBottom: '$4' }} />
              <ProfilePicturePreview />
            </CardBox>
            <CardBox
              css={{
                minHeight: 250,
              }}
            >
              <FormGroup title={'Foto de FaceID'} items={faceIdForm} />
              <Div css={{ marginBottom: '$4' }} />
              <FaceIdPreview
                css={{
                  height: 100,
                  width: 100,
                  border: 'solid 2px',
                  borderColor: '$brand_primary_pure',
                  borderRadius: '$full',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                  [`${Text}`]: {
                    color: '$brand_primary_pure',
                    fontSize: '$sm',
                    textAlign: 'center',
                  },
                }}
              />
            </CardBox>
            <CardBox
              css={{
                minHeight: 250,
                minWidth: '33%',
              }}
            >
              <FormGroup title={'Datos Bancarios'} items={bankForm} cols={1} />
            </CardBox>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$4',
            }}
          >
            <CardBox
              css={{
                minHeight: 320,
              }}
            >
              <FormGroup
                title={'Datos de domicilio'}
                items={addressForm}
                cols={3}
              />
            </CardBox>
            <CardBox
              css={{
                minHeight: 320,
                maxWidth: '25%',
              }}
            >
              <FormGroup
                title={'Datos de Integración'}
                items={integrationData}
                cols={1}
              />
            </CardBox>
            <CardBox
              css={{
                minHeight: 320,
                maxWidth: '25%',
              }}
            >
              <FormGroup
                title={'Datos de Carrera'}
                items={careerInfosForm}
                cols={1}
              />
            </CardBox>
          </Div>
          <ContactsData />
          <ChildrenData />
        </>
      )}

      {currentStep === 'PUNCH_CONFIG' && (
        <>
          <Div
            css={{
              display: 'flex',
              gap: '$4',
            }}
          >
            <CardBox>
              <FormGroup title={'Sucursal'} items={cellData} />
            </CardBox>
            <CardBox>
              <FormGroup
                title={'Marcación de Asistencia'}
                items={marcacionAsistencia}
              />
            </CardBox>
          </Div>
          <MarcacionConfig id={punchConfigIds} />
        </>
      )}

      {currentStep === 'PAYMENT_CONFIG' && (
        <>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Div
              css={{
                display: 'flex',
                gap: '$4',
              }}
            >
              <CardBox
                css={{
                  minHeight: 170,
                }}
              >
                <FormGroup
                  title={'Configuración de Ingreso'}
                  items={companyDataForm}
                  cols={2}
                />
              </CardBox>
              <CardBox
                css={{
                  minHeight: 170,
                }}
              >
                <FormGroup
                  title={'Configuración de Pagamento'}
                  items={paymentConfigForm}
                />
              </CardBox>
            </Div>
            <Div
              css={{
                display: 'flex',
                gap: '$4',
              }}
            >
              <CardBox
                css={{
                  minHeight: 170,
                }}
              >
                <FormGroup
                  title={'Configuración de Vacaciones'}
                  items={vacationConfigForm}
                />
              </CardBox>
              <CardBox
                css={{
                  minHeight: 170,
                }}
              >
                <FormGroup
                  title={'Configuración de Aguinaldo'}
                  items={aguinaldoConfigForm}
                />
              </CardBox>
              <CardBox
                css={{
                  minHeight: 170,
                }}
              >
                <FormGroup
                  title={'Configuración de Liquidación'}
                  items={liquidationConfigForm}
                />
              </CardBox>
            </Div>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Div
              css={{
                width: '50%',
              }}
            >
              <PaymentConfig id={paymentConfigId} />
            </Div>
          </Div>
        </>
      )}

      {currentStep === 'SALARY_CONFIG' && <SalaryHistory />}
    </div>
  )
}

export const CardBox = styled('div', {
  marginTop: '$4',
  width: '100%',
  background: '$interface_light_pure',
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 20,
  paddingBottom: 20,
  borderRadius: '$md',
  minHeight: 170,
})
