import { withSSRAuth } from '@/pages/utils/withSSRAuth'

import { CreateWorkerPageHeader, FormContent, FormStepper } from './components'
import { Div } from '@/components'
import { CreateWorkersProvider } from './context'

export default function CreateWorker() {
  return (
    <CreateWorkersProvider>
      <Div css={{ padding: '$4' }}>
        <CreateWorkerPageHeader />
        <FormStepper />
        <Div css={{ marginBottom: '$4' }} />
        <FormContent />
      </Div>
    </CreateWorkersProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
