import { getDeepVal } from '@/utils/object'
import { TextInput } from '@punto-ui/react'
import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { blurDecoded, effectDecoder, typeDecoder } from './toPattern'
import { ControlledInputP } from './types'
import { useEffect, forwardRef, useRef } from 'react'

export const ControlledInput = forwardRef(
  <TFieldValues extends FieldValues>(
    {
      inputType,
      InputComponent = TextInput,
      onChange,
      shouldValidate = true,
      defaultValue,
      ...props
    }: ControlledInputP<TFieldValues>,
    reference: any,
  ) => {
    const {
      control,
      setValue,
      trigger,
      formState: { errors },
      getValues,
    } = useFormContext()

    const ref = useRef<HTMLInputElement | null>(null)

    const value = useWatch({
      control,
      name: props.name,
      defaultValue: getValues(props.name),
    })

    const inputError = getDeepVal(errors, props.name)

    useEffect(() => {
      const effect = effectDecoder(inputType ?? 'no-mask')
      effect.execute(ref.current, value)
    }, [value, inputType])
    return (
      <InputComponent
        ref={(el) => {
          ref.current = el
          if (reference) {
            reference.current = el
          }
        }}
        onChange={(event) => {
          const effect = effectDecoder(inputType ?? 'no-mask')
          let text = event.target.value

          if (
            inputType?.includes('percentage') &&
            +text.replace(',', '.') === +value
          ) {
            text = text.substring(0, text.length - 2)
          }

          const decodedV = typeDecoder(inputType ?? 'no-mask').output(text)
          effect.execute(ref.current, decodedV)

          const maybeTransformedV = props.transformValue
            ? props.transformValue(decodedV)
            : decodedV
          setValue(props.name, maybeTransformedV as any)
          onChange?.(maybeTransformedV as any)

          effect.execute(ref.current, decodedV)
        }}
        value={typeDecoder(inputType ?? 'no-mask').input(
          !value && value !== 0 ? '' : value,
        )}
        {...props}
        onFocus={(e) => {
          props.onFocus?.(e)
          const effect = effectDecoder(inputType ?? 'no-mask')
          effect.execute(ref.current, value)
        }}
        onBlur={async (e) => {
          props.onBlur?.(e)
          const blurEffect = blurDecoded(inputType ?? 'no-mask')

          if (blurEffect.execute) {
            const decodedV = blurEffect.execute(value)
            setValue(props.name, decodedV as any)
            onChange?.(decodedV as any)
          }

          if (shouldValidate) {
            await trigger(props.name)
          }
        }}
        errorMessage={inputError ? inputError.message : undefined}
      />
    )
  },
)

ControlledInput.displayName = 'ControlledInput'
