import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

// const currencys = [
//   { language: 'pt-br', currency: 'BRL' },
//   { language: 'en-US', currency: 'USD' },
//   { language: 'es-py', currency: 'PYG' },
// ]

export const useBankForm = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const basicDataForm: AddWorkerFields = [
    {
      _type: 'input',
      disabled: !isWorkerCreationAvailable,
      label: 'Banco',
      name: 'bank_name',
      inputType: 'name',
    },
    {
      _type: 'input',
      label: 'Cuenta bancaria',
      disabled: !isWorkerCreationAvailable,
      name: 'bank_account',
      inputType: 'no-mask',
    },
    // {
    //   _type: 'dropdown',
    //   label: 'Moneda Estándar',
    //   name: 'currency',
    //   options: [
    //     {
    //       label: 'G$',
    //       value: 'G$',
    //     },
    //   ],
    //   defaultOption: {
    //     label: 'G$',
    //     value: 'G$',
    //   },
    // },
  ]

  return {
    data: basicDataForm,
  }
}
