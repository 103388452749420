import { useEffect } from 'react'
import { IRouteAction } from '@/pages/router'
import { CheckIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { UseFormReturn, useWatch } from 'react-hook-form'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
  ReportBuilderRowType,
  ReportBuilderSchemaType,
} from './type'
import { useHandleCreateTemplate } from '@/libs/react-query/mutations/reports/useHandleCreateTemplate'
import { reportBuilderRowDetailsByTypeAndRow } from './constants'
import { useHandleUpdateTemplate } from '@/libs/react-query/mutations/reports/useHandleUpdateTemplate'
import useToast from '@/hooks/useToast'
import { useMe } from '@/libs/react-query/hooks'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useCan } from '@/hooks'

export const useHandleReportBuilderActions = ({
  methods,
  isLoading,
}: {
  methods: UseFormReturn<ReportBuilderSchemaType>
  isLoading?: boolean
}) => {
  const canCreateTemplate = useCan(['reports.createTemplate'])
  const { data: me } = useMe()

  const { setTableTab, setActions, removeTab, tabs } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setTableTab: () => state.setActiveTab('reportsV2', 'template-hub'),
    tabs: state.reportsV2.tabs,
    setActions: state.setActionsTab,
  }))

  const toast = useToast()

  const {
    isLoading: isLoadingCreateTemplate,
    mutateAsync: handleCreateTemplate,
  } = useHandleCreateTemplate()

  const {
    isLoading: isLoadingUpdateTemplate,
    mutateAsync: handleUpdateTemplate,
  } = useHandleUpdateTemplate()

  const [isUpdating, isMyReport] = useWatch({
    control: methods.control,
    name: ['id', 'identifier_id'],
  })

  const { data: isTemplateBuilderAvailable, detail } =
    useIsFeatureInPlan('template-builder')

  useEffect(() => {
    const actions: IRouteAction[] = []

    const isLoadingOrDisabled =
      isLoading || isLoadingCreateTemplate || isLoadingUpdateTemplate

    const isDisabled = !isTemplateBuilderAvailable

    if (isUpdating) {
      actions.push({
        action: async () => {
          const values = methods.getValues()
          const columns = Object.entries(values.columns).flatMap(
            ([key, columns]) =>
              columns
                .filter((c) => c.selected)
                .map((column) => {
                  return {
                    type: key,
                    value: column.value,
                    valueType: column.valueType,
                  }
                }),
          )

          const details =
            reportBuilderRowDetailsByTypeAndRow[
              values.configurations.reportType as IReportBuilderTypes
            ]?.[values.selectedRow as IReportBuilderRowTypes] || []

          const selectedDetailsBasedOnDetailsArray: ReportBuilderRowType['details'] =
            []
          details.forEach((detail, index) => {
            if (values.selectedRowDetailsArray?.[index]) {
              selectedDetailsBasedOnDetailsArray.push(detail)
            }
          })

          await handleCreateTemplate({
            columns,
            description: values.configurations.description,
            name: `${values.configurations.name} - Copia`,
            row: values.selectedRow,
            favorite: values.configurations.isFavorite,
            rowDetails: values.selectedRowDetails,
            rowSubDetails:
              selectedDetailsBasedOnDetailsArray.length !== 1
                ? ''
                : selectedDetailsBasedOnDetailsArray[0].value,
            type: values.configurations.reportType,
            filters: values.filters.map((f) => {
              return {
                condition: f.condition,
                name: f.name.split('.')[1],
                type: f.name.split('.')[0],
                valueType: f.valueType,
                date: f.date,
                value: f.value !== undefined ? String(f.value) : f.value,
              }
            }),
          })

          toast.addToast({
            title: 'Template duplicado con éxito',
            type: 'positive',
            description: 'El template ha sido duplicado con éxito',
            id: Date.now(),
          })
        },
        label: 'Duplicar Template',
        width: 180,
        disabled: !canCreateTemplate || isLoadingOrDisabled || isDisabled,
        disableMessage: isDisabled ? detail : undefined,
        isLoading: isLoadingOrDisabled,
        variant: 'secondary',
        icon: isUpdating ? <CheckIcon /> : <DocumentArrowDownIcon />,
      })
    }

    if (isMyReport || me?.isAdmin || !isUpdating) {
      actions.push({
        action: async () => {
          const values = methods.getValues()
          const columns = Object.entries(values.columns).flatMap(
            ([key, columns]) =>
              columns
                .filter((c) => c.selected)
                .map((column) => {
                  return {
                    type: key,
                    value: column.value,
                    valueType: column.valueType,
                  }
                }),
          )

          const details =
            reportBuilderRowDetailsByTypeAndRow[
              values.configurations.reportType as IReportBuilderTypes
            ]?.[values.selectedRow as IReportBuilderRowTypes] || []

          const selectedDetailsBasedOnDetailsArray: ReportBuilderRowType['details'] =
            []
          details.forEach((detail, index) => {
            if (values.selectedRowDetailsArray?.[index]) {
              selectedDetailsBasedOnDetailsArray.push(detail)
            }
          })
          if (isUpdating) {
            await handleUpdateTemplate({
              id: values.id,
              columns,
              description: values.configurations.description,
              name: values.configurations.name,
              row: values.selectedRow,
              favorite: values.configurations.isFavorite,
              rowDetails: values.selectedRowDetails,
              rowSubDetails:
                selectedDetailsBasedOnDetailsArray.length !== 1
                  ? ''
                  : selectedDetailsBasedOnDetailsArray[0].value,
              type: values.configurations.reportType,
              filters: values.filters.map((f) => {
                return {
                  condition: f.condition,
                  name: f.name.split('.')[1],
                  type: f.name.split('.')[0],
                  valueType: f.valueType,
                  date: f.date,
                  value: f.value !== undefined ? String(f.value) : f.value,
                }
              }),
            })
          } else {
            await handleCreateTemplate({
              columns,
              description: values.configurations.description,
              name: values.configurations.name,
              row: values.selectedRow,
              favorite: values.configurations.isFavorite,
              rowDetails: values.selectedRowDetails,
              rowSubDetails:
                selectedDetailsBasedOnDetailsArray.length !== 1
                  ? ''
                  : selectedDetailsBasedOnDetailsArray[0].value,
              type: values.configurations.reportType,
              filters: values.filters.map((f) => {
                return {
                  condition: f.condition,
                  name: f.name.split('.')[1],
                  type: f.name.split('.')[0],
                  valueType: f.valueType,
                  date: f.date,
                  value: f.value !== undefined ? String(f.value) : f.value,
                }
              }),
            })
          }

          toast.addToast({
            title: isUpdating
              ? 'Template actualizado'
              : 'Template creado con éxito',
            type: 'positive',
            description: isUpdating
              ? 'El template ha sido actualizado con éxito'
              : 'El template ha sido creado con éxito',
            id: Date.now(),
          })

          const addTab = tabs.find((t) => t.type === 'add-template')

          if (addTab?.id) {
            removeTab('reportsV2', addTab.id)
            setTableTab()
          }
        },
        label: isUpdating ? 'Actualizar Template' : 'Crear Template',
        width: 180,
        disabled: !canCreateTemplate || isLoadingOrDisabled || isDisabled,
        disableMessage: isDisabled ? detail : undefined,
        isLoading: isLoadingOrDisabled,
        variant: 'primary',
        icon: isUpdating ? <CheckIcon /> : <DocumentArrowDownIcon />,
      })
    }

    setActions('reportsV2', actions)
  }, [
    isTemplateBuilderAvailable,
    detail,
    canCreateTemplate,
    me?.isAdmin,
    isMyReport,
    toast,
    isUpdating,
    isLoading,
    methods,
    setActions,
    isLoadingCreateTemplate,
    isLoadingUpdateTemplate,
    handleCreateTemplate,
    handleUpdateTemplate,
  ])
}
