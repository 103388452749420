import { create } from 'zustand'

export const payrollPlaygroundSteps = [
  {
    name: 'Configuraciones',
    value: 'CONFIG',
  },
  {
    name: 'Calendário',
    value: 'CALENDAR',
  },
  {
    name: 'Horas en haber',
    value: 'PAID_HOURS',
  },
  {
    name: 'Otros Ingressos',
    value: 'OTHER_EARNS',
  },
  {
    name: 'Resumo (haberes)',
    value: 'SUMMARY_EARNS',
  },
  {
    name: 'Descontos',
    value: 'DISCOUNTS',
  },
  {
    name: 'Calculo IPS',
    value: 'IPS',
  },
  {
    name: 'Pagos en cuenta',
    value: 'ACCOUNT_PAYMENT',
  },
  // {
  //   name: 'Geral',
  //   value: 'GENERAL_SUMMARY',
  // },
]

type PayrollDrawerType = 'DAY_DETAIL' | 'EXPORT'

interface PayrollPlaygroundStoreTypes {
  drawer: {
    isOpen: boolean
    type: PayrollDrawerType
    data: any
  }

  currentStepValue: string
  currentStepNumber: number
  canGoBack: boolean
  canGoNext: boolean

  handleNextStep: () => void
  handlePreviousStep: () => void
  handleChangeStep: (step: string) => void
  handleChangeStepIndex: (stepIndex: number) => void
  handleOpenDrawer: (type: PayrollDrawerType, data: any) => void
  handleCloseDrawer: () => void
}

export const usePayrollPlaygroundStore = create<PayrollPlaygroundStoreTypes>(
  (set, get) => {
    return {
      currentStepValue: 'CONFIG',
      currentStepNumber: 0,
      drawer: {
        isOpen: false,
        type: 'DAY_DETAIL',
        data: null,
      },

      canGoBack: false,
      canGoNext: true,

      handleCloseDrawer: () => {
        set({
          drawer: {
            isOpen: false,
            type: 'DAY_DETAIL',
            data: null,
          },
        })
      },
      handleChangeStep: (stepName: string) => {
        const newStepIndex = payrollPlaygroundSteps.findIndex(
          (s) => s.value === stepName,
        )

        set({
          currentStepValue: payrollPlaygroundSteps[newStepIndex].value,
          currentStepNumber: newStepIndex,
          canGoBack: newStepIndex > 0,
          canGoNext: newStepIndex < payrollPlaygroundSteps.length - 1,
        })
      },
      handleChangeStepIndex: (stepIndex: number) => {
        set({
          currentStepValue: payrollPlaygroundSteps[stepIndex].value,
          currentStepNumber: stepIndex,
          canGoBack: stepIndex > 0,
          canGoNext: stepIndex < payrollPlaygroundSteps.length - 1,
        })
      },
      handleNextStep: () => {
        const nextStep = get().currentStepNumber + 1

        if (nextStep >= payrollPlaygroundSteps.length) {
          return
        }

        set({
          currentStepValue: payrollPlaygroundSteps[nextStep].value,
          currentStepNumber: nextStep,
          canGoBack: nextStep > 0,
          canGoNext: nextStep < payrollPlaygroundSteps.length - 1,
        })
      },
      handlePreviousStep: () => {
        const previousStep = get().currentStepNumber - 1

        if (previousStep < 0) {
          return
        }

        set({
          currentStepValue: payrollPlaygroundSteps[previousStep].value,
          currentStepNumber: previousStep,
          canGoBack: previousStep > 0,
          canGoNext: previousStep < payrollPlaygroundSteps.length - 1,
        })
      },
      handleOpenDrawer: (type, data) => {
        set({
          drawer: {
            isOpen: true,
            type,
            data,
          },
        })
      },
    }
  },
)
