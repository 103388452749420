import {
  ControlledFilterBar,
  Div,
  SmartTable,
  useSmartTableColumnManipulation,
} from '@/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { StatisticsManagerSchema, StatisticsManagerSchemaType } from './types'
import { useEffect } from 'react'
import { parseCompanyList } from './parser'
import { useHandleStatisticsManagerActions } from './actions'
import { useStatisticsManagerColumns } from './columns'
import dayjs from 'dayjs'
import { useHandleUpdateBusinessInfo } from '@/libs/react-query/mutations/admin/useHandleUpdateBusinessInfo'
import { useHandleDeleteInvoice } from '@/libs/react-query/mutations/admin/useHandleDeleteInvoice'
import { useHandleUpdateInvoice } from '@/libs/react-query/mutations/admin/useHandleUpdateInvoice'
import { useHandleUpdatePlan } from '@/libs/react-query/mutations/permissions/plans/useHandleUpdatePlan'
import { useCompaniesStatistics } from '@/libs/react-query/hooks/useCompaniesStatistics'

export const StatisticsManager = () => {
  const methods = useForm<StatisticsManagerSchemaType>({
    resolver: zodResolver(StatisticsManagerSchema),
    defaultValues: {
      companyName: '',
      data: [],
      filterStatus: ['CLIENT', 'IMPLEMENTING', 'NEW'],
      dates: [
        dayjs().startOf('month').toDate(),
        dayjs().endOf('month').toDate(),
      ],
    },
  })

  const [companyName, dates, filterStatus] = useWatch({
    control: methods.control,
    name: ['companyName', 'dates', 'filterStatus'],
  })

  const { data: companies, isFetching: isFetchingInvoices } =
    useCompaniesStatistics({
      startDate:
        dates?.[0]?.toISOString() || dayjs().startOf('month').toISOString(),
      endDate:
        dates?.[1]?.toISOString() || dayjs().endOf('month').toISOString(),
    })

  const { isLoading: isLoadingUpdatePlan } = useHandleUpdatePlan()
  const { isLoading: isLoadingUpdateInvoice } = useHandleUpdateInvoice()
  const { isLoading: isLoadingDeleteInvoice } = useHandleDeleteInvoice()
  const { isLoading: isLoadingBusinessInfos } = useHandleUpdateBusinessInfo()

  useEffect(() => {
    if (!companies?.length) {
      return
    }

    const correctCompanies = companies
      .filter((company) => {
        const inStatus = filterStatus?.length
          ? filterStatus.includes(company.businessInfos?.status || '')
          : true

        let isValidName = false

        if (companyName?.length) {
          isValidName = (company.name as string)
            .toLowerCase()
            .includes(companyName.toLowerCase())
        } else {
          isValidName = true
        }

        return inStatus && isValidName
      })
      .sort((a, b) => {
        return (b.activeEmployees || 0) - (a.activeEmployees || 0)
      })

    const { data, weeksLength } = parseCompanyList(correctCompanies)

    methods.setValue('data', data)
    methods.setValue('weeksLength', weeksLength)
  }, [companies, companyName, filterStatus])

  const { data: columns } = useStatisticsManagerColumns({
    methods,
  })

  useHandleStatisticsManagerActions()

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'invoiceMaganerColumnsOrder',
    tableHiddenUniqueName: 'invoiceMaganerHiddenColumns',
    tableLeftFixedUniqueName: 'invoiceMaganerLeftFixedColumns',
    tableRightFixedUniqueName: 'invoiceMaganerRightFixedColumns',

    defaultLeftFixedColumns: ['download', 'count', 'name'],
    defaultHiddenColumns: [
      'legal_representative',
      'email',
      'payroll_import_date',
      'app_setup_date',
      'face_register_date',
      'punch_register_date',
      'status',
    ],
  })

  return (
    <FormProvider {...methods}>
      <ControlledFilterBar
        nameFilterName="companyName"
        policiesIds={[]}
        isLoading={
          isFetchingInvoices ||
          isLoadingUpdatePlan ||
          isLoadingUpdateInvoice ||
          isLoadingDeleteInvoice ||
          isLoadingBusinessInfos
        }
        multipleFilterOptionName="filterStatus"
        defaultMultipleFilterValue={['CLIENT', 'IMPLEMENTING', 'NEW']}
        maxDates={365}
        multipleFilterOptionLabel={'Status'}
        multipleFilterOptions={[
          {
            label: 'Cliente',
            value: 'CLIENT',
          },
          {
            label: 'Implementación',
            value: 'IMPLEMENTING',
          },
          {
            label: 'Nueva Empresa',
            value: 'NEW',
          },
          {
            label: 'Cancelado',
            value: 'CANCELED',
          },
          {
            label: 'Deletada',
            value: 'DELETED',
          },
          {
            label: 'Prueba',
            value: 'TEST',
          },
        ]}
        dateName="dates"
        dateType="range"
      />
      <Div
        css={{
          flex: 1,
          padding: '$4',

          position: 'relative',
          height: 'calc(100vh - 90px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <SmartTable
          style={{
            borderInLines: false,
          }}
          columns={columns}
          areColumnsDraggable
          defaultColumnOrder={defaultColumnsOrder}
          defaultHiddenColumns={defaultHiddenColumns}
          saveColumnsOrderCallback={saveColumnsOrderCallback}
          saveHiddenColumnsCallback={saveHiddenColumnsCallback}
          resetColumnOrder={resetStateColumnOrder}
          maxDepth={2}
          defaultLeftFixedColumns={defaultLeftFixedColumns}
          defaultRightFixedColumns={defaultRightFixedColumns}
          saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
          saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
        />
      </Div>
    </FormProvider>
  )
}
