import { IRouteAction, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import {
  Cog6ToothIcon,
  ListBulletIcon,
  PlusIcon,
  SunIcon,
} from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { useEffect, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  VacationFormType,
  defaultCreateVacationValues,
  vacationForm,
} from './types'
import { VacationsList } from './components/VacationsList'
import { VacationsStatus } from './components/VacationsStatus'
import { useCan } from '@/hooks'
import { useReportReceipts } from '@/libs/react-query/hooks/useReportReceipts'
import { CreateVacationsV2 } from './components/CreateVacationsV2'

export default function Vacations({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const canCreate = useCan(['vacation.create', 'vacation.all'])
  const canRequest = useCan(['vacation.request'], true)

  const methods = useForm<VacationFormType>({
    defaultValues: {
      create: defaultCreateVacationValues,
    },
    resolver: zodResolver(vacationForm),
  })

  const {
    tabs: rawTabs,
    activeTab,
    setActiveTab,
    addTab,
    removeTab,
    actions,
  } = useTabStore((state) => ({
    actions: state.vacations.actions,
    tabs: state.vacations.tabs,
    removeTab: state.removeTab,
    setActiveTab: state.setActiveTab,
    activeTab: state.vacations.activeTab,
    addTab: state.addTab,
  }))

  const remove = useRef<any>()

  useReportReceipts({
    page: 0,
    perPage: 50,
    types: ['vacations'],
  })

  useEffect(() => {
    return () => {
      clearTimeout(remove.current)
    }
  }, [])

  const pageActions: IRouteAction[] = useMemo(() => {
    if (activeTab && activeTab !== 'list' && activeTab !== 'status') {
      return []
    }

    const actions: IRouteAction[] = [
      {
        label: 'Configuraciones',
        icon: <Cog6ToothIcon />,
        action: () => console.log('hi'),
        disabled: true,
        variant: 'secondary',
      },
    ]

    if (canCreate || canRequest) {
      actions.push({
        label: canRequest ? 'Solicitar vacaciones' : 'Crear vacaciones',
        width: canRequest ? 180 : 150,
        icon: <PlusIcon />,
        action: () => {
          methods.reset({
            create: defaultCreateVacationValues,
          })
          addTab('vacations', [
            {
              id: 'create',
              type: 'create',
              label: canRequest ? 'Solicitar vacaciones' : 'Crear Vacaciones',
            },
          ])
          setActiveTab('vacations', 'create')
        },
        disabled: false,
        variant: 'primary' as const,
      })
    }

    return actions
  }, [activeTab, addTab, setActiveTab, canCreate, canRequest])

  const tabs = rawTabs.map((tab) => {
    if (tab.type === 'create') {
      return {
        icon: <ListBulletIcon />,
        label: tab.label || 'Nueva Vacaciones',
        value: tab.id,
        scrollable: true,
        closeable: true,
        handleClose: () => {
          methods.resetField('create')
          removeTab('vacations', tab.id)
          remove.current = setTimeout(() => {
            setActiveTab('vacations', 'status')
          }, 1)
        },
        component: (
          <>
            <CreateVacationsV2 />
          </>
        ),
      }
    } else {
      return {
        icon: <ListBulletIcon />,
        label: tab.label || 'Nueva Vacaciones',
        value: tab.id,
        scrollable: true,
        closeable: true,
        handleClose: () => {
          removeTab('vacations', tab.id)
          remove.current = setTimeout(() => {
            setActiveTab('vacations', 'status')
          }, 1)
        },
        component: (
          <>
            <CreateVacationsV2 />
          </>
        ),
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <Router
        onlyTabs={onlyTabs}
        defaultTab="status"
        setTab={(id) => setActiveTab('vacations', id)}
        actions={!pageActions.length ? actions : pageActions}
        tab={activeTab || 'list'}
        tabs={[
          {
            icon: <ListBulletIcon />,
            label: 'Listado',
            value: 'list',
            scrollable: false,
            component: (
              <>
                <VacationsList />
              </>
            ),
          },
          {
            icon: <SunIcon />,
            label: 'Status de Vacaciones',
            value: 'status',
            scrollable: false,
            component: (
              <>
                <VacationsStatus />
              </>
            ),
          },
          ...tabs,
        ]}
      />
    </FormProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
