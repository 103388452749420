import { useFormContext, useWatch } from 'react-hook-form'
import { ConfigFields, ConfigSchema } from '../../..'
import { useMemo } from 'react'
import { useCan } from '@/hooks'

const WEEK_DAYS = [
  { label: 'Domingo', value: '0' },
  { label: 'Lunes', value: '1' },
  { label: 'Martes', value: '2' },
  { label: 'Miercoles', value: '3' },
  { label: 'Jueves', value: '4' },
  { label: 'Viernes', value: '5' },
  { label: 'Sabado', value: '6' },
]

export const useHolidayColumns = () => {
  const { control } = useFormContext<ConfigSchema>()
  const canCreateHolidays = useCan(['configurations.holidays'])

  const isWeekDay = useWatch({
    control,
    name: 'holiday.isWeekDay',
  })
  const data = useMemo(() => {
    const allowedPunchParams: ConfigFields = [
      {
        _type: 'input',
        disabled: !canCreateHolidays,
        label: 'Nombre del Feriado',
        name: 'holiday.name',
      },
      {
        _type: 'switch',
        label: isWeekDay ? 'Dia de Semana' : 'Fecha',
        disabled: !canCreateHolidays,
        name: 'holiday.isWeekDay',
      },
    ]

    if (isWeekDay) {
      allowedPunchParams.push({
        _type: 'dropdown',
        label: 'Dia de Semana',
        disabled: !canCreateHolidays,
        name: 'holiday.day_of_week',
        options: WEEK_DAYS,
        defaultOption: {
          label: 'Domingo',
          value: '0',
        },
      })
    } else {
      allowedPunchParams.push({
        _type: 'calendar',
        label: 'Data del Feriado',
        disabled: !canCreateHolidays,
        name: 'holiday.date',
        calendarType: 'single',
        maxDates: 3,
        firstDate: new Date(),
        secondDate: new Date(),
      })
    }

    allowedPunchParams.push({
      _type: 'checkbox',
      disabled: !canCreateHolidays,
      label: 'Ativa',
      name: 'holiday.active',
    })
    allowedPunchParams.push({
      disabled: !canCreateHolidays,
      _type: 'checkbox',
      label: 'Repetir todos los años',
      name: 'holiday.should_repeat_every_year',
    })
    return allowedPunchParams
  }, [isWeekDay, canCreateHolidays])

  return {
    data,
  }
}
