import { IReportBuilderRowTypes, IReportBuilderTypes } from '../type'

export const reportBuilderRowsByType: Record<
  IReportBuilderTypes,
  IReportBuilderRowTypes[]
> = {
  single: ['day', 'month'],
  // collective: ['workers', 'sectors', 'entities'],
  collective: ['workers'],
  totals: ['month', 'year'],
}
