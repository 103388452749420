import { z } from 'zod'

export const documentsStateSchema = z.object({
  documentList: z.object({
    name: z.string(),
    cellsIds: z.array(z.string()),
    page: z.number(),
    perPage: z.number(),
    type: z.string().array(),
  }),
  structure: z.object({
    checked: z.record(z.string(), z.boolean()),
    hidden: z.record(z.string(), z.boolean()),
  }),
  management: z.object({
    userId: z.string(),
    checked: z.record(z.string(), z.boolean()),
    checkedDocuments: z.record(z.string(), z.boolean()),

    documentView: z.object({
      isViewDocumentOpen: z.boolean(),
      documentId: z.string(),
      name: z.string(),
      breadcrumbPath: z.string().array(),
      documentType: z.string().optional(),
      isFullscreen: z.boolean(),
    }),
  }),
  addFolder: z.object({
    isAddFolderDrawerOpen: z.boolean(),

    name: z
      .string({ required_error: 'Nombre es requerido' })
      .min(1, 'Nombre es requerido'),
    folderId: z
      .string({ required_error: 'Carpeta es requerida' })
      .min(1, 'Carpeta es requerida'),
  }),
  addDocument: z.object({
    isAddDocumentDrawerOpen: z.boolean(),
    userId: z.string(),
    name: z
      .string({ required_error: 'Nombre es requerido' })
      .min(1, 'Nombre es requerido'),
    folderId: z
      .string({ required_error: 'Carpeta es requerida' })
      .min(1, 'Carpeta es requerida'),

    files: z.custom<File>().array(),
  }),
  editDocumentEntity: z.object({
    userId: z.string(),
    id: z.string(),
    name: z
      .string({ required_error: 'Nombre es requerido' })
      .min(1, 'Nombre es requerido'),
    folderId: z
      .string({ required_error: 'Carpeta es requerida' })
      .min(1, 'Carpeta es requerida'),
    isEditDocumentOpen: z.boolean(),
    documentFiles: z
      .object({
        id: z.string(),
        name: z.string(),
        document_type: z.string().optional(),
      })
      .array(),
    newFiles: z.custom<File>().array(),
  }),
  editFolder: z.object({
    id: z.string(),
    isEditFolderDrawerOpen: z.boolean(),

    name: z
      .string({ required_error: 'Nombre es requerido' })
      .min(1, 'Nombre es requerido'),
    parentId: z
      .string({ required_error: 'Carpeta es requerida' })
      .min(1, 'Carpeta es requerida')
      .nullable(),
  }),
})

export type IDocumentsStateSchema = z.infer<typeof documentsStateSchema>

export const INITIAL_DOCUMENT_STATE: IDocumentsStateSchema = {
  documentList: {
    name: '',
    cellsIds: [],
    page: 0,
    perPage: 20,
    type: [],
  },
  structure: {
    checked: {},
    hidden: {},
  },
  addFolder: {
    isAddFolderDrawerOpen: false,

    name: '',
    folderId: '',
  },
  addDocument: {
    isAddDocumentDrawerOpen: false,

    name: '',
    folderId: '',

    userId: '',
    files: [],
  },
  editFolder: {
    id: '',
    isEditFolderDrawerOpen: false,

    name: '',
    parentId: '',
  },
  management: {
    userId: '',
    checked: {},
    checkedDocuments: {},

    documentView: {
      name: '',
      isViewDocumentOpen: false,
      documentId: '',
      breadcrumbPath: [],
      documentType: '',
      isFullscreen: false,
    },
  },
  editDocumentEntity: {
    id: '',
    userId: '',
    name: '',
    folderId: '',
    isEditDocumentOpen: false,
    documentFiles: [],
    newFiles: [],
  },
}
