import { SmartTable } from '@/components'
import { useAguinaldoValues } from './table'
import { useMemo } from 'react'
import { AguinaldoTableContainer } from '../AguinaldoTableContainer'
import { useFormContext, useWatch } from 'react-hook-form'
import { AguinaldoPlaygroundTableType } from '../../types'

export const AguinaldoValues = () => {
  const { control, setValue } = useFormContext<AguinaldoPlaygroundTableType>()
  const columns = useAguinaldoValues()
  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
    }
  }, [])

  const pagination = useWatch({
    control,
    name: 'pagination',
  })

  return (
    <AguinaldoTableContainer>
      <SmartTable
        style={tableStyles}
        columns={columns}
        withPagination
        paginationProps={{
          onChange: (data) => {
            setValue('pagination.page', data.pageIndex)
            setValue('pagination.perPage', data.pageSize)
          },
          page: pagination.page,
          perPage: pagination.perPage,
          totalPages: pagination.totalPages,
          total: pagination.total,
        }}
      />
    </AguinaldoTableContainer>
  )
}
