import { Div, Touchable } from '@/components'
import { Checkbox, Text, styled } from '@punto-ui/react'
import React, { useMemo } from 'react'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'
import { ReportBuilderSchemaType } from '../../type'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

interface IExpandableSelectorOptions {
  label: string
  value: string
  category?: string
  name: FieldPath<ReportBuilderSchemaType>
}

interface IExpandableSelectorProps {
  label: string
  icon: React.ReactNode

  name: FieldPath<ReportBuilderSchemaType>
  disableName?: FieldPath<ReportBuilderSchemaType>
  detailsName?: FieldPath<ReportBuilderSchemaType>

  onChange?: (isOpen: boolean) => void
  onDetailChange?: (isOpen: boolean) => void

  options: IExpandableSelectorOptions[]
  isCheckbox?: boolean
}

export const ExpandableSelector = ({
  icon,
  isCheckbox,
  label,
  name,
  disableName,
  detailsName,
  onDetailChange,
  onChange,
  options,
}: IExpandableSelectorProps) => {
  const { setValue, control } = useFormContext<ReportBuilderSchemaType>()

  const [isOpen, isDisabled] =
    useWatch({
      control,
      name: [name as any, disableName as any],
    }) || false

  const setIsOpen = (isOpen: boolean) => {
    setValue(name, isOpen)
  }

  const optionsGroups = useMemo(() => {
    return options.reduce((acc, option) => {
      if (option.category && !acc[option.category]) {
        acc[option.category] = []
      }

      if (option.category) {
        acc[option.category].push(option)
      }

      return acc
    }, {} as Record<string, IExpandableSelectorOptions[]>)
  }, [options])

  const noGroupOptions = options.filter((option) => !option.category)

  return (
    <Div>
      <Touchable
        onClick={() => {
          const checked = !isOpen
          setIsOpen(!!checked ?? false)
          onChange?.(!!checked ?? false)
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8,
            paddingBottom: 8,
            background: isDisabled
              ? 'transparent'
              : isOpen
              ? '$interface_light_down'
              : '$interface_light_up',
          }}
        >
          <Div
            css={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',

              svg: {
                width: 20,
                height: 20,
                color: isDisabled
                  ? '$interface_light_down'
                  : isOpen
                  ? '$brand_primary_pure'
                  : '$interface_dark_down',
              },
            }}
          >
            {icon}
            <Text
              css={{
                marginLeft: '$2',
                color: isDisabled
                  ? '$interface_light_deep'
                  : isOpen
                  ? '$brand_primary_pure'
                  : '$interface_dark_down',
              }}
            >
              {label}
            </Text>
          </Div>
          <Div
            css={{
              opacity: isDisabled ? 0.5 : 1,
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            {!!options.length && (
              <Div
                css={{
                  minWidth: 16,
                  minHeight: 16,

                  svg: {
                    marginTop: 2,

                    width: 16,
                    height: 16,
                    color: isOpen
                      ? '$brand_primary_pure'
                      : '$interface_dark_down',
                  },
                }}
              >
                {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              </Div>
            )}
            {/* <Div
              css={{
                height: 16,
                width: 16,
              }}
            >
              {!isDisabled && (
                <Checkbox
                  checked={isOpen}
                  onCheckedChange={(checked) => {
                    console.log(checked)
                    setIsOpen(!!checked ?? false)
                    onChange?.(!!checked ?? false)
                  }}
                />
              )}
            </Div> */}
          </Div>
        </Div>
      </Touchable>
      {!!noGroupOptions.length && isOpen && (
        <Div>
          {noGroupOptions.map((option, index) => (
            <ExpandableOption
              onDetailChange={onDetailChange}
              detailsName={detailsName}
              isCheckbox={isCheckbox}
              label={option.label}
              name={option.name}
              key={index}
            />
          ))}
        </Div>
      )}
      {isOpen &&
        Object.entries(optionsGroups).map(([category, options]) => (
          <Div key={category}>
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: 8,
                paddingLeft: 24,
                paddingBottom: 8,
                paddingRight: 24,
              }}
            >
              <Div
                css={{
                  height: 4,
                  width: 4,
                  borderRadius: '$full',
                  background: '$interface_dark_deep',
                  marginRight: '$4',
                }}
              />
              <Text
                variant={'caption'}
                css={{
                  color: '$interface_dark_deep',
                  marginRight: '$4',
                  fontWeight: '$bold',
                }}
              >
                {category}
              </Text>
              <Div
                css={{
                  background: '$interface_light_down',
                  // width: '100%',
                  flex: 1,
                  height: 2,
                }}
              />
            </Div>
            {options.map((option, index) => (
              <ExpandableOption
                onDetailChange={onDetailChange}
                detailsName={detailsName}
                isCheckbox={isCheckbox}
                label={option.label}
                name={option.name}
                key={index}
              />
            ))}
          </Div>
        ))}
    </Div>
  )
}

const ExpandableOption = ({
  label,
  name,
  isCheckbox = true,
  detailsName,
  onDetailChange,
}: {
  label: string
  name: string
  onDetailChange?: (isOpen: boolean) => void
  detailsName?: string
  isCheckbox?: boolean
}) => {
  const { setValue, control, getValues } =
    useFormContext<ReportBuilderSchemaType>()

  const [isSelected] =
    useWatch({
      control,
      name: [name as any],
    }) || false

  const setIsSelected = (isOpen: boolean) => {
    if (!isCheckbox && detailsName) {
      const details = getValues(detailsName as any)

      if (details) {
        setValue(
          detailsName as any,
          details.map((d: any) => ({ ...d, selected: false })),
        )
      }
    }
    setValue(name as any, isOpen)

    if (onDetailChange) {
      onDetailChange(isOpen)
    }
  }

  return (
    <label>
      <Div
        css={{
          paddingLeft: 44,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text
          variant={'caption'}
          css={{
            color: '$interface_dark_down',
          }}
        >
          {label}
        </Text>
        {isCheckbox ? (
          <Checkbox
            checked={isSelected}
            onCheckedChange={(e) => setIsSelected(!!e)}
          />
        ) : (
          <UnstyledButton
            onClick={() => setIsSelected(!isSelected)}
            isOpen={isSelected}
          >
            {isSelected && (
              <Div
                css={{
                  height: 8,
                  width: 8,
                  borderRadius: '$full',
                  background: '$interface_light_pure',
                }}
              />
            )}
          </UnstyledButton>
        )}
      </Div>
    </label>
  )
}

const UnstyledButton = styled('button', {
  all: 'unset',
  width: 16,
  height: 16,
  background: 'transparent',
  border: '1px solid',
  borderColor: '$interface_dark_up',
  borderRadius: '$full',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    isOpen: {
      true: {
        background: '$brand_primary_pure',
      },
    },
  },
})
