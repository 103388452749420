import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { WorkersShifts } from '../../../types'
import { useSmartShift } from '../../../context'

export const useShiftManagementColumns = () => {
  const { workersShifts } = useSmartShift()
  const columns = React.useMemo<ColumnDef<WorkersShifts>[]>(() => {
    const workerColumn: ColumnDef<WorkersShifts> = {
      id: 'worker',
      footer: (props) => props.column.id,
      accessorFn: (row) => row.worker,
      cell: (info) => JSON.stringify(info.getValue()),
    }

    const allDaysInWorkersShifts: Date[] = []
    workersShifts.forEach((workerShift) => {
      workerShift.shifts.forEach((shift) => {
        const alreadyExists = allDaysInWorkersShifts.find((day) => {
          return day === shift.date
          // const comparingDate = dayjs(day).startOf('day').toDate()
          // const shiftDate = dayjs(shift.date).endOf('day').toDate()

          // return dayjs(shiftDate).diff(comparingDate, 'days') === 0
        })
        if (!alreadyExists) {
          allDaysInWorkersShifts.push(shift.date)
        }
      })
    })

    const daysColumns: ColumnDef<WorkersShifts>[] = allDaysInWorkersShifts.map(
      (day) => {
        return {
          id: day.toString(),
          cell: (info) => JSON.stringify(info.getValue()),
          accessorFn: (row, index) => row.shifts[index],
          footer: (info) => info.column.id,
        }
      },
    )

    const result = [workerColumn, ...daysColumns]
    const workerCell = result.shift()
    if (!workerCell) return []

    result.sort((a, b) => {
      if ((a?.id || 0) < (b?.id || 0)) return -1
      if ((a?.id || 0) > (b?.id || 0)) return 1
      return 0
    })

    return [workerCell, ...result]
  }, [workersShifts])

  return { data: columns }
}
