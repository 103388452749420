import { Div } from '@/components'
import { InputDatePicker } from '@/components/InputDatePicker'
import { queryClient } from '@/libs/react-query'
import { useMe } from '@/libs/react-query/hooks'
import { useCountPunchesByCompany } from '@/libs/react-query/hooks/admin/useCountPunchesByCompany'
import { useHandleUpdateCompanyId } from '@/libs/react-query/mutations/admin/useHandleUpdateCompanyId'
import { useTabStore } from '@/store'
import { ClockIcon } from '@heroicons/react/24/outline'
import { Button, Progress, Text, styled } from '@punto-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'

export const CompaniesPunches = () => {
  const { data: me } = useMe()

  const [firstDate, setFirstDate] = useState<Date | null>(new Date())
  const [secondDate, setSecondDate] = useState<Date | null>(new Date())

  const { data: count, isFetching: isFetchingCompany } =
    useCountPunchesByCompany(
      firstDate ? dayjs(firstDate).startOf('day').toISOString() : '',
      secondDate ? dayjs(secondDate).startOf('day').toISOString() : '',
    )

  const {
    mutateAsync: handleUpdateCompanyId,
    isLoading: isLoadingUpdateCompanyId,
  } = useHandleUpdateCompanyId()

  const { reset } = useTabStore((state) => ({
    reset: state.reset,
  }))

  const isLoading = isLoadingUpdateCompanyId || isFetchingCompany

  return (
    <Div
      css={{
        marginLeft: '$4',
        marginRight: '$4',
        flex: 0.5,
        background: '$interface_light_pure',
        padding: '$4',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$4',
          paddingBottom: '$2',
        }}
      >
        <Div
          css={{
            flex: 1,

            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            marginBottom: '$4',
            svg: { height: 16, width: 16, color: '$interface_dark_pure' },
          }}
        >
          <ClockIcon />
          <Text variant={'heading4'}>Marcaciones</Text>
        </Div>
        <Div
          css={{
            flex: 1,
          }}
        >
          {isLoading && <Progress />}
        </Div>
        <Div
          css={{
            minWidth: '40%',
          }}
        >
          <InputDatePicker
            type={'range'}
            firstDate={firstDate}
            secondDate={secondDate}
            onChange={(dates) => {
              if (dates) {
                setFirstDate(dates[0])
                setSecondDate(dates[1])
              }
            }}
            maxDates={60}
          />
        </Div>
      </Div>

      <Div
        css={{
          display: 'flex',
          padding: '$4',

          maxHeight: 'calc(100vh - 324px)',
          overflow: 'scroll',
        }}
      >
        <Div
          css={{
            flex: 1,
          }}
        >
          {count?.map((count) => (
            <RowItem key={count.company_id}>
              <Text
                css={{
                  color:
                    count.company_id === me?.company_id
                      ? '$brand_primary_pure'
                      : undefined,
                }}
              >
                {count.name}
              </Text>
            </RowItem>
          ))}
          <RowItem>
            <Text variant={'subtitle2'}>Totales</Text>
          </RowItem>
        </Div>
        <Div
          css={{
            minWidth: 100,
          }}
        >
          {count?.map((count) => (
            <RowItem key={count.company_id}>
              <Text
                css={{
                  color:
                    count.company_id === me?.company_id
                      ? '$brand_primary_pure'
                      : undefined,
                }}
              >
                {count.count.toLocaleString('pt-BR')}
              </Text>
            </RowItem>
          ))}
          <RowItem>
            <Text>
              {count
                ?.reduce<number>((acc, curr) => acc + curr.count, 0)
                .toLocaleString('pt-BR')}
            </Text>
          </RowItem>
        </Div>
        <Div
          css={{
            minWidth: 100,
          }}
        >
          {count?.map((count) => (
            <RowItem key={count.company_id}>
              <Button
                variant={'secondary'}
                onClick={async () => {
                  if (me?.company_id !== count.company_id && count.company_id) {
                    await handleUpdateCompanyId(count.company_id)
                    reset()

                    queryClient.resetQueries()
                  }
                }}
                css={{
                  display:
                    me?.company_id === count.company_id ? 'none' : 'block',
                  marginLeft: 'auto',
                }}
              >
                Cambiar para
              </Button>
            </RowItem>
          ))}
          <RowItem />
        </Div>
        {/* {count?.map((c) => (
          <Div
            key={c.company_id}
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$4',
              marginBottom: '$4',
            }}
          >
            <Div>
              <Text>{c.name}</Text>
            </Div>
            <Div>
              <Text>{c.count}</Text>
            </Div>
            <Div>
              <Button variant={'secondary'}>Cambiar para</Button>
            </Div>
          </Div>
        ))} */}
      </Div>
    </Div>
  )
}

const RowItem = styled('div', {
  height: 40,
  display: 'flex',
  alignItems: 'center',
})
