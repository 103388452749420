import { TagProps } from '@punto-ui/react'
import { IDefaultApiPagination } from '../pagination'
import { IWorkerUser } from '../worker'

export const AUDIT_ACTIONS = {
  // Punches
  UPDATE_PUNCH: 'update-punch',
  CREATE_PUNCH: 'create-punch',
  DELETE_PUNCH: 'delete-punch',
  CREATE_PUNCH_BATCH: 'create-punch-batch',
  UPDATE_DAY_TOTAL_HOURS: 'update-day-total-hours',
  AUTHORIZE_DAY_TOTAL_HOURS: 'authorize-day-total-hours',

  // Workers
  REHIRE_WORKER: 'rehire-worker',
  CHANGE_PASSWORD: 'change-password',
  UPDATE_PROFILE_PICTURE: 'update-profile-picture',
  UPDATE_FACE_ID: 'update-face-id',
  UPDATE_WORKER: 'update-worker',
  CREATE_WORKER: 'create-worker',
  DEACTIVATE_WORKER: 'deactivate-worker',
  REACTIVATE_WORKER: 'reactivate-worker',
  CREATE_WORKERS: 'create-workers',

  // Nomina
  CREATE_PAYROLL: 'create-payroll',
  CLOSE_PAYROLL: 'close-payroll',
  UPDATE_PAYROLL_SETTING: 'update-payroll-setting',
  UPDATE_PAYROLL_FIELDS: 'update-payroll-fields',

  // Payments
  CREATE_PAYMENT: 'create-payment',
  UPDATE_PAYMENT: 'update-payment',
  DELETE_PAYMENT: 'delete-payment',

  // Movements
  CREATE_MOVEMENT: 'create-movement',
  UPDATE_MOVEMENT: 'update-movement',
  DELETE_MOVEMENT: 'delete-movement',

  // Vacations
  CREATE_VACATION: 'create-vacation',
  UPDATE_VACATION: 'update-vacation',
  DELETE_VACATION: 'delete-vacation',

  // Liquidation
  LIQUIDATE_WORKER: 'liquidate-worker',

  // Shifts
  CREATE_SHIFT: 'create-shift',
  UPDATE_SHIFT: 'update-shift',
  DUPLICATE_SHIFT: 'duplicate-shift',
  UPDATE_SHIFT_SCHEDULE: 'update-shift-schedule', // 3 use cases: update shift schedule, create shift schedule and update schedule batch
  REMOVE_REST: 'remove-rest',

  // Configs
  UPDATE_CONFIG: 'update-config',
  CREATE_CONFIG: 'create-config',
  DELETE_CONFIG: 'delete-config',

  // Auth
  AUTHENTICATE: 'authenticate',

  // Company
  // Aguinaldo
  // Reports
  // IAM
}

export const AUDIT_TYPES = {
  PUNCH: 'PUNCH',
  WORKER: 'WORKER',
  SHIFT: 'SHIFT',
  PAYROLL: 'PAYROLL',
  MOVEMENT: 'MOVEMENT',
  PAYMENT: 'PAYMENT',
  VACATION: 'VACATION',
  CONFIG: 'CONFIG',
  AUTH: 'AUTH',
}

export const AUDIT_VALUE_TYPES = {
  DATE: 'date',
  HOUR: 'hour',
  PURE_HOUR: 'pure-hour',
  DOCUMENT: 'document',
  DATETIME: 'datetime',
  GENERIC_MONEY: 'generic-money',
}

export const AUDIT_TYPE_TO_LABEL = {
  [AUDIT_TYPES.PUNCH]: 'Marcaciones',
  [AUDIT_TYPES.WORKER]: 'Colaborador',
  [AUDIT_TYPES.SHIFT]: 'Turnos',
  [AUDIT_TYPES.PAYROLL]: 'Nomina',
  [AUDIT_TYPES.MOVEMENT]: 'Movimiento',
  [AUDIT_TYPES.PAYMENT]: 'Pago',
  [AUDIT_TYPES.VACATION]: 'Vacaciones',
  [AUDIT_TYPES.CONFIG]: 'Configuración',
  [AUDIT_TYPES.AUTH]: 'Autenticación',
}

export const AUDIT_TYPE_TO_VARIANT: Record<string, TagProps['variant']> = {
  [AUDIT_TYPES.PUNCH]: 'warning',
  [AUDIT_TYPES.WORKER]: 'neutral',
  [AUDIT_TYPES.SHIFT]: 'warning',
  [AUDIT_TYPES.PAYROLL]: 'negative',
  [AUDIT_TYPES.MOVEMENT]: 'positive',
  [AUDIT_TYPES.PAYMENT]: 'positive',
  [AUDIT_TYPES.VACATION]: 'neutral',
  [AUDIT_TYPES.CONFIG]: 'neutral',
  [AUDIT_TYPES.AUTH]: 'positive',
}

export type FetchAuditsProps = {
  page: number
  perPage: number

  name?: string
  types?: string[]
  cellsIds: string[]
}

/**
 * Model AuditData
 *
 */
export type AuditData = {
  id: string
  identifier_id: string
  audit_id: string
  field_name: string
  field_label: string
  old_value: string | null
  old_value_label: string | null
  new_value: string | null
  new_value_label: string | null
  value_type: string | null
  grouping_concept: string | null
  created_at: Date
  updated_at: Date
}

/**
 * Model AuditEntities
 *
 */
export type AuditEntities = {
  id: string
  identifier_id: string
  audit_id: string
  entity_id: string
  entity_name: string | null
  created_at: Date
  updated_at: Date
  updated_by: string | null
}

/**
 * Model AuditDatesOld
 *
 */
export type AuditDatesOld = {
  id: string
  identifier_id: string
  audit_id: string
  date: string
  created_at: string
  updated_at: string
}

/**
 * Model AuditDatesNew
 *
 */
export type AuditDatesNew = {
  id: string
  identifier_id: string
  audit_id: string
  date: string
  created_at: string
  updated_at: string
}

/**
 * Model AuditRemovedUser
 *
 */
export type AuditRemovedUser = {
  id: string
  identifier_id: string
  audit_id: string
  user_id: string
  created_at: string
  updated_at: string
  user: IWorkerUser
}

/**
 * Model AuditNewUser
 *
 */
export type AuditNewUser = {
  id: string
  identifier_id: string
  audit_id: string
  user_id: string
  created_at: string
  updated_at: string
  user: IWorkerUser
}

/**
 * Model Audit
 *
 */
export type Audit = {
  id: string
  identifier_id: string
  type: string
  action: string
  entity_name: string
  user_id: string
  is_creation: boolean
  commited_at: string | null
  created_at: string
  updated_at: string

  user: IWorkerUser

  data: AuditData[]
  entities: AuditEntities[]
  oldDates: AuditDatesOld[]
  newDates: AuditDatesNew[]
  oldUsers: AuditRemovedUser[]
  newUsers: AuditNewUser[]
}

export type IPaginatedAudits = IDefaultApiPagination<Audit>
