import { api } from '@/services/api'
import { useMutation } from 'react-query'

export const useHandleDownload = () => {
  const handleCreateMovement = async (fileId: string) => {
    const response = await api.post<{ url: string }>(
      `/documents/file?file_id=${fileId}`,
    )

    const url = response.data.url
    if (url) {
      window.open(url, '_blank')
    }
  }

  const mutation = useMutation(['useHandleDownload'], handleCreateMovement)
  return mutation
}
