import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { ControlledCalendarP } from './types'
import dayjs from 'dayjs'
import { getDeepVal } from '@/utils/object'
import { InputDatePicker } from '@/components/InputDatePicker'

export const ControlledCalendarInput = <TFieldValues extends FieldValues>({
  calendarType,
  onValueChange,
  ...props
}: ControlledCalendarP<TFieldValues>) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const value = useWatch({
    name: props.name,
    control,
  })

  const hoursEnabled = useWatch({
    name: props.hourName || '',
    control,
  })

  const handleOnChange = (dates: (Date | null)[]) => {
    const filteredValidDates = dates.filter(
      (d) => d && dayjs(d).isValid(),
    ) as Date[]

    setValue(props.name, filteredValidDates as any)
  }

  const handleToggleHour = (v: boolean) => {
    if (props.hourName) {
      setValue(props.hourName, v as any)
    }
  }

  const inputError = getDeepVal(errors, props.name)

  return (
    <InputDatePicker
      {...props}
      errorMessage={inputError ? inputError.message : undefined}
      hoursEnabled={hoursEnabled}
      type={calendarType}
      multipleDates={calendarType === 'multiple' ? value : undefined}
      onChange={handleOnChange}
      onToggleHours={handleToggleHour}
      firstDate={value?.[0] ?? props?.defaultValues?.[0]}
      secondDate={value?.[1] ?? props?.defaultValues?.[1]}
    />
  )
}
