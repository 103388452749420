import { Button } from '@punto-ui/react'
import { useGroupInfo, useVacationGroupConcepts } from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div, Drawer, DrawerContainer } from '@/components'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '../../..'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useCan } from '@/hooks'

interface VacationsConfigDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

const MAX_DRAWER_WIDTH = '70vw'

export const VacationsConfigDrawer = ({
  handleCloseDrawer,
  isOpen,
  isCreation = false,
}: VacationsConfigDrawerProps) => {
  const canCreateVacation = useCan(['configurations.vacations'])
  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ConfigSchema>()

  const { data: company } = useMyCompany()

  const { data: groupInfo } = useGroupInfo()
  const { data: vacationGroupConcepts } = useVacationGroupConcepts()

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()

  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreateVacationConfig = async () => {
    const isValid = await trigger('vacation')

    if (!isValid) {
      console.log('error', errors)
      return
    }

    const data = getValues()

    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const includedConcepts = orderedCompanyConcepts.filter(
      (_, index) => data.vacation?.concepts[index],
    )

    if (isCreation) {
      await handleCreateCompanyConfig({
        vacation: {
          concepts: includedConcepts,
          include_base_salary: data.vacation?.include_base_salary || false,
          should_use_ips_date: data.vacation?.should_use_ips_date || false,
          name: data.vacation?.name || '',
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        vacation: {
          id: data.vacation?.id || '',
          concepts: includedConcepts,
          include_base_salary: data.vacation?.include_base_salary || false,
          should_use_ips_date: data.vacation?.should_use_ips_date || false,
          name: data.vacation?.name || '',
        },
      })
    }

    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{ overflow: 'scroll', maxHeight: '100vh', paddingBottom: 32 }}
          >
            <FormGroup
              type="fixed"
              title="Informaciones del Grupo"
              items={groupInfo}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH }}
            />
            <FormGroup
              type="fixed"
              title="Informaciones para Calculo de Vacaciones"
              items={vacationGroupConcepts}
              cols={2}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$8' }}
            />
            <Button
              disabled={!canCreateVacation}
              css={{ marginTop: '$10', minWidth: 300 }}
              onClick={handleCreateVacationConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear' : 'Actualizar'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
