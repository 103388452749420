import { Div, Touchable } from '@/components'
import {
  CheckCircleIcon,
  MagnifyingGlassCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { Text, keyframes } from '@punto-ui/react'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { RequestsInfosTableType } from '../form'

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.5)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const RequestAppBar = ({
  count,
  isLoading,
  handleDetails,
  handleAcceptRequest,
  handleDenyRequest,
}: {
  isLoading: boolean
  count: number
  handleDetails: () => void
  handleAcceptRequest: () => void
  handleDenyRequest: () => void
}) => {
  const { control } = useFormContext<RequestsInfosTableType>()

  const allRequests = useWatch({
    name: 'data',
    control,
  })

  const shouldShowAuthorizationButtons = allRequests?.filter(
    (request) =>
      request.checked.value &&
      request.canAuthorizeRequest &&
      request.rawStatus === 'pending',
  )
  const moreThanOneSelected =
    allRequests?.filter((request) => request.checked.value).length > 1

  return (
    <Div
      css={{
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        background: '$interface_light_pure',
        borderRadius: '$md',
        border: 'solid 1px',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        css={{
          animation: `${contentShow} 50ms cubic-bezier(0.16, 1, 0.3, 1)`,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Div
          css={{
            borderRight: '1px solid $interface_light_down',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '$brand_primary_pure',
            minHeight: 50,
            minWidth: 40,
            borderTopLeftRadius: '$md',
            borderBottomLeftRadius: '$md',
          }}
        >
          <Text
            variant="caption"
            css={{
              color: '$interface_light_pure',
              fontWeight: '$bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {count}
          </Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 50,
            paddingLeft: '$2',
            paddingRight: '$2',
            borderRight: '1px solid $interface_light_deep',
          }}
        >
          <Text variant="description" css={{ color: '$interface_dark_down' }}>
            Solicitaciones selecionadas
          </Text>
        </Div>
        <>
          <Div
            css={{
              width: 1,
              height: 50,
              background: '$interface_light_deep',
            }}
          />
          {!!shouldShowAuthorizationButtons.length && (
            <>
              <RequestAppBarButton
                disabled={isLoading}
                icon={<CheckCircleIcon />}
                text={'Autorizar'}
                callToAction={handleAcceptRequest}
              />
              <RequestAppBarButton
                disabled={isLoading}
                icon={<XCircleIcon />}
                text={'Recusar'}
                callToAction={handleDenyRequest}
              />
            </>
          )}
          <RequestAppBarButton
            disabled={moreThanOneSelected || isLoading}
            icon={<MagnifyingGlassCircleIcon />}
            text={'Ver detalles'}
            callToAction={handleDetails}
          />
        </>
      </Div>
    </Div>
  )
}

interface RequestAppBarButtonProps {
  text: string
  icon: React.ReactNode
  callToAction: () => void
  disabled?: boolean
}

const RequestAppBarButton = (props: RequestAppBarButtonProps) => {
  return (
    <Div>
      <Touchable
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: props.disabled ? 0.5 : 1,
          cursor: props.disabled ? 'not-allowed' : 'pointer',
          svg: {
            height: 16,
            width: 16,
            marginBottom: 6,
            color: '$interface_dark_down',
          },
        }}
        onClick={() => {
          if (props.disabled) return

          if (props.callToAction) {
            props.callToAction()
          }
        }}
      >
        {props.icon}
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            paddingLeft: '$3',
            paddingRight: '$3',
            svg: {
              color: '$interface_dark_down',
              height: 14,
              width: 14,
            },
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'center',
              color: '$interface_dark_down',
              fontWeight: '$bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.text}
          </Text>
        </Div>
      </Touchable>
    </Div>
  )
}
