import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '@/libs/react-query/hooks/useMe'

import { IPaymentsContextProps } from '../../types'
import { IPaginatedPaymentBatches } from '../../types/payment'

const fetchPaymentBatches = async (props: IPaymentsContextProps) => {
  const response = await apiV2.post<IPaginatedPaymentBatches>(
    '/payment/list-payment-batches',
    {
      page: props.page,
      perPage: props.pageSize,
      name: props.name,
    },
    {
      params: {
        cellsIds: props.cellsIds?.join(','),
      },
    },
  )

  return response.data
}

export const usePaymentBatches = (props: IPaymentsContextProps) => {
  const { data: me } = useMe()

  const query = useQuery(
    ['payment', 'batch', me?.company_id, props],
    () => fetchPaymentBatches(props),
    {
      keepPreviousData: true,
      enabled: !!me && !!me.company_id && !!props.cellsIds?.length,
    },
  )
  return query
}
