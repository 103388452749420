import { ControlledInput, Div } from '@/components'
import { HeaderImportantText, ReceiptHeaderInput } from '../ReceiptHeader'
import { Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReceiptBuilderSchemaType } from '../../../type'

export const LogoAndPictureHeaderOption = () => {
  const { control } = useFormContext<ReceiptBuilderSchemaType>()

  const title = useWatch({
    control,
    name: 'configurations.title',
  })

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 800,
        border: '2px solid $interface_dark_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '2px solid $interface_dark_pure',
            borderBottom: '2px solid $interface_dark_pure',
            width: 180,
            background: '$brand_primary_up',
          }}
        >
          <HeaderImportantText>LOGO</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '2px solid $interface_dark_pure',
            borderBottom: '2px solid $interface_dark_pure',
            width: 180,
            background: '$brand_complementary_up',
          }}
        >
          <HeaderImportantText>FOTO</HeaderImportantText>
        </Div>
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '2px solid $interface_dark_pure',
            }}
          >
            <HeaderImportantText>{title}</HeaderImportantText>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '2px solid $interface_dark_pure',

              '> div:not(:last-child)': {
                width: '50%',
                borderRight: '2px solid $interface_dark_pure',
                paddingLeft: '$2',
              },
              '> div:last-child': {
                width: '50%',
                paddingLeft: '$2',
              },
            }}
          >
            <Div>
              <HeaderImportantText>Empleador</HeaderImportantText>
            </Div>
            <Div>
              <Text>CAV INC. S. A.</Text>
            </Div>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '2px solid $interface_dark_pure',

              '> div:not(:last-child)': {
                width: '50%',
                borderRight: '2px solid $interface_dark_pure',
                paddingLeft: '$2',
              },
              '> div:last-child': {
                width: '50%',
                paddingLeft: '$2',
              },
            }}
          >
            <Div>
              <HeaderImportantText>N PATRONAL MTESS</HeaderImportantText>
            </Div>
            <Div>
              <Text>123123123</Text>
            </Div>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '2px solid $interface_dark_pure',

              '> div:not(:last-child)': {
                width: '50%',
                borderRight: '2px solid $interface_dark_pure',
                paddingLeft: '$2',
              },

              '> div:last-child': {
                width: '50%',
                paddingLeft: '$2',
              },
            }}
          >
            <Div>
              <HeaderImportantText>PERIODO</HeaderImportantText>
            </Div>
            <Div>
              <Text>01/04/2024 - 30/04/2024</Text>
            </Div>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '2px solid $interface_dark_pure',

              '> div:not(:last-child)': {
                width: '50%',
                borderRight: '2px solid $interface_dark_pure',
                paddingLeft: '$2',
              },
              '> div:last-child': {
                width: '50%',
                paddingLeft: '$2',
              },
            }}
          >
            <Div>
              <HeaderImportantText>CI: 123123123</HeaderImportantText>
            </Div>
            <Div>
              <Text>JORGE GENEZ</Text>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
        }}
      >
        <Div>
          <HeaderImportantText>
            <ControlledInput
              InputComponent={ReceiptHeaderInput}
              name="headerConfig.title"
            />
          </HeaderImportantText>
        </Div>
      </Div>
    </Div>
  )
}
