import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
  useWatch,
} from 'react-hook-form'
import React, { useEffect } from 'react'
import { parseTemplateToRow } from './parser'
import {
  ControlledFilterBar,
  Div,
  ExportTemplateDrawer,
  SmartTable,
} from '@/components'
import { SmartColumnType } from '@/components/SmartTable/types'
import { Button } from '@punto-ui/react'
import { CloudArrowDownIcon } from '@heroicons/react/24/outline'
import { ExportedTemplatesSchemaType } from '../../type'
import {
  TemplateExportTableSchema,
  TemplateExportTableSchemaType,
} from './type'
import { useDownload } from '@/contexts/DownloadContext'
import { useReports } from '@/libs/react-query/hooks/useReports'
import { useAllAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
import { useHandleUpdateTemplate } from '@/libs/react-query/mutations/reports/useHandleUpdateTemplate'
import { permissionsArray } from '@/hooks/useGetAllPermissions'

export const ExportedTemplateList = () => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')
  const { isLoading: isLoadingUpdateTemplate } = useHandleUpdateTemplate()

  const methods = useForm<TemplateExportTableSchemaType>({
    resolver: zodResolver(TemplateExportTableSchema),
    defaultValues: {
      data: [],
      pagination: {
        page: 0,
        perPage: 20,
        total: 0,
        totalPages: 0,
      },
    },
  })

  const hubMethods = useFormContext<ExportedTemplatesSchemaType>()

  const [pagination] = useWatch({
    control: methods.control,
    name: ['pagination'],
  })

  const [name, cellsIds] = useWatch({
    control: hubMethods.control,
    name: ['configurations.name', 'configurations.cellsIds'],
  })

  useAllAvailableCells({
    methods: hubMethods,
    name: 'configurations.cellsIds',
    permission: 'reports',
  })

  const { data: reports, isFetching: isFetchingReports } = useReports({
    name,
    page: pagination.page,
    cellsIds,
    perPage: pagination.perPage,
  })

  useEffect(() => {
    if (!reports) return

    const data = parseTemplateToRow(reports || [])
    methods.reset(data)
  }, [reports, methods])

  const columns: SmartColumnType[] = [
    {
      name: 'name',
      header: 'Creado en',
      type: 'input-text',
      width: 300,
      disabled: true,
    },
    {
      name: 'workers',
      header: 'Colaboradores',
      type: 'input-text',
      width: 150,
      disabled: true,
    },
    {
      name: 'name',
      header: 'Nombre',
      width: 200,
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'expiration',
      header: 'Expiración',
      width: 200,
      type: 'input-text',
      disabled: true,
    },
    {
      name: 'status',
      header: 'Status',
      width: 120,
      type: 'tags',
      disabled: true,
    },
    {
      name: 'download',
      header: 'Descargar',
      width: 140,
      type: 'custom',
      component: ({ index }: { index: number }) => (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 140,
          }}
        >
          <DownloadButton methods={methods} index={index} />
        </Div>
      ),
    },
  ]

  return (
    <>
      <ControlledFilterBar
        policiesIds={permissionsArray.filter((p) => p.includes('reports'))}
        isLoading={isLoadingUpdateTemplate || isFetchingReports}
        nameFilterName="configuration.name"
        cellsIdsName="configuration.cellsIds"
      />
      <FormProvider {...methods}>
        <Div
          css={{
            position: 'relative',
            height: 'calc(100vh - 84px)',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 16,
            paddingRight: 16,
            overflowX: 'scroll',
            marginTop: '$4',
          }}
        >
          <SmartTable
            columns={columns}
            withPagination
            style={{
              borderInLines: true,
              height: 600,
            }}
            paginationProps={{
              onChange: (data) => {
                methods.setValue('pagination.page', data.pageIndex)
                methods.setValue('pagination.perPage', data.pageSize)
              },
              page: pagination.page,
              perPage: pagination.perPage,
              totalPages: pagination.totalPages,
              total: pagination.total,
            }}
          />
        </Div>

        <ExportTemplateDrawer
          templateId={selectedTemplateId}
          isOpen={!!selectedTemplateId}
          setIsOpen={() => setSelectedTemplateId('')}
        />
      </FormProvider>
    </>
  )
}

const DownloadButton = ({
  index,
  methods,
}: {
  index: number
  methods: UseFormReturn<any>
}) => {
  const { downloadFile } = useDownload()

  const hasLink = useWatch({
    control: methods.control,
    name: `data.${index}.link.value`,
  })

  if (!hasLink) {
    return null
  }

  return (
    <Button
      icon={<CloudArrowDownIcon />}
      variant={'secondary'}
      onClick={() => {
        const url = methods.getValues(`data.${index}.link.value`)
        const name = methods.getValues(`data.${index}.name.value`)
        downloadFile(url as string, name as string)
      }}
      css={{ height: 24, width: '90%' }}
    >
      Descargar
    </Button>
  )
}
