export const FaceIdIcon = (props: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path
        d="M4.25 2C3.00736 2 2 3.00736 2 4.25V6.25C2 6.66421 2.33579 7 2.75 7C3.16421 7 3.5 6.66421 3.5 6.25V4.25C3.5 3.83579 3.83579 3.5 4.25 3.5H6.25C6.66421 3.5 7 3.16421 7 2.75C7 2.33579 6.66421 2 6.25 2H4.25Z"
        fill="currentColor"
      />
      <path
        d="M13.75 2C13.3358 2 13 2.33579 13 2.75C13 3.16421 13.3358 3.5 13.75 3.5H15.75C16.1642 3.5 16.5 3.83579 16.5 4.25V6.25C16.5 6.66421 16.8358 7 17.25 7C17.6642 7 18 6.66421 18 6.25V4.25C18 3.00736 16.9926 2 15.75 2H13.75Z"
        fill="currentColor"
      />
      <path
        d="M3.5 13.75C3.5 13.3358 3.16421 13 2.75 13C2.33579 13 2 13.3358 2 13.75V15.75C2 16.9926 3.00736 18 4.25 18H6.25C6.66421 18 7 17.6642 7 17.25C7 16.8358 6.66421 16.5 6.25 16.5H4.25C3.83579 16.5 3.5 16.1642 3.5 15.75V13.75Z"
        fill="currentColor"
      />
      <path
        d="M18 13.75C18 13.3358 17.6642 13 17.25 13C16.8358 13 16.5 13.3358 16.5 13.75V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H13.75C13.3358 16.5 13 16.8358 13 17.25C13 17.6642 13.3358 18 13.75 18H15.75C16.9926 18 18 16.9926 18 15.75V13.75Z"
        fill="currentColor"
      />
      <path
        d="M6.63158 6C6.98039 6 7.26316 6.25584 7.26316 6.57143V7.33334C7.26316 7.64893 6.98039 7.90477 6.63158 7.90477C6.28277 7.90477 6 7.64893 6 7.33334V6.57143C6 6.25584 6.28277 6 6.63158 6ZM10 6C10.3488 6 10.6316 6.25584 10.6316 6.57143V8.47621C10.6316 8.75813 10.5433 9.16829 10.2599 9.52439C9.95335 9.90962 9.44795 10.1905 8.73684 10.1905C8.38803 10.1905 8.10526 9.93466 8.10526 9.61907C8.10526 9.30348 8.38803 9.04764 8.73684 9.04764C9.03626 9.04764 9.16244 8.94756 9.23482 8.85661C9.33039 8.73651 9.36842 8.57524 9.36842 8.47621V6.57143C9.36842 6.25584 9.65119 6 10 6ZM13.3684 6C13.7172 6 14 6.25584 14 6.57143V7.33334C14 7.64893 13.7172 7.90477 13.3684 7.90477C13.0196 7.90477 12.7368 7.64893 12.7368 7.33334V6.57143C12.7368 6.25584 13.0196 6 13.3684 6ZM13.0393 12.3334C13.2428 12.5897 13.178 12.9468 12.8947 13.1309C11.6989 13.9079 10.449 14.0808 9.40001 13.9693C8.37555 13.8604 7.50682 13.4762 7.04286 13.0846C6.7878 12.8693 6.77392 12.5077 7.01186 12.2769C7.2498 12.0461 7.64945 12.0336 7.90451 12.2489C8.1423 12.4496 8.74726 12.7492 9.54736 12.8342C10.3229 12.9167 11.2485 12.7934 12.1579 12.2026C12.4412 12.0185 12.8359 12.077 13.0393 12.3334Z"
        fill="currentColor"
      />
    </svg>
  )
}
