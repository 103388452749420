import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { createCompleteWorkerData } from '../types'
import { CreateWorkerPayloadType } from './parseStructure'
import { queryClient } from '@/libs/react-query'
import {
  fromDayMonthYearFormatToDateString,
  getLocalStringFromDate,
} from '@/utils/date'
import { IWorkerUser } from '@/libs/react-query/types/worker'
import { safeOnlyDigitsWithoutNegative } from '@/components/Forms/ControlledInput/toPattern'
import dayjs from 'dayjs'

const handleCreateWorker = async ({ ...props }: createCompleteWorkerData) => {
  const parsedData = parseWorkerStructure(props)

  const response = await apiV2.post<IWorkerUser>('/user/create', parsedData)

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleCreateWorkerComplete = () => {
  const mutation = useMutation(['useHandleCreateWorker'], handleCreateWorker)

  return mutation
}

export const parseWorkerStructure = (
  props: createCompleteWorkerData,
): CreateWorkerPayloadType => {
  const contacts = props.contacts?.filter((contact) => !!contact.name) || []
  const children = props.children?.filter((children) => !!children.name) || []

  const externalCodes: Array<{
    label: string
    value: string
  }> = []

  if (props.datapar_id) {
    externalCodes.push({
      label: 'datapar_user_id',
      value: props.datapar_id,
    })
  }

  if (props.default_config_id) {
    externalCodes.push({
      label: 'default_config_id',
      value: props.default_config_id,
    })
  }

  if (props.marcacion_id) {
    externalCodes.push({
      label: 'marcacion_id',
      value: props.marcacion_id,
    })
  }

  const apiPayload: CreateWorkerPayloadType = {
    shift_id: props.shift_id,
    user: {
      user: {
        document: safeOnlyDigitsWithoutNegative(props.ci),
        email: props.email,
        // name: props.name,

        firstName: props.name,
        surname: props.surname,

        carreer_plan: props.carreer_plan,
        profession: props.profession,
        position: props.position,

        password: props.password,
        birthday: fromDayMonthYearFormatToDateString(props.birthday_date),
        civilStatus: props.marital_state,
        gender: props.gender,
        nationality: props.nationality,
        phone1: props.phone,
      },
      children:
        children.map((children) => ({
          name: children.name,
          birthday: fromDayMonthYearFormatToDateString(children.birthday),
          document: children.document,
          shouldReceiveChildrenBonus: children.shouldReceiveChildrenBonus,
        })) || [],
      contacts:
        contacts.map((contact) => ({
          name: contact.name,
          birthday: contact.birthday,
          document: contact.document,
          isEmergencyContact: true,
          phone_number: contact.phone,
          relationship: contact.relationship,
        })) || [],
      address: {
        city: props.city,
        complement: props.complement,
        country: props.country,
        latitude: '0',
        longitude: '0',
        neighborhood: props.neighborhood,
        number: props.number,
        state: props.state,
        street: props.street,
      },
      bankAccount: {
        bank_name: props.bank_name || '',
        bank_account: props.bank_account || '',
        isMainAccount: true,
      },
    },
    companyUser: {
      payment_config_id: props.payment_config_id || null,
      punch_config_ids: props.punch_config_ids ? [props.punch_config_ids] : [],
      vacation_config_id: props.vacation_config_id || null,
      aguinaldo_config_id: props.aguinaldo_config_id || null,
      liquidation_config_id: props.liquidation_config_id || null,

      // salary_group_id: props.salary_group_id || null,
      // salary_raw_value: props.salary_raw_value,
      salaryHistory:
        props.salaryHistory?.map((salary, index) => {
          const nextStartDate = props.salaryHistory?.[index + 1]
            ?.start_date?.[0]
            ? getLocalStringFromDate(
                dayjs(props.salaryHistory[index + 1]?.start_date?.[0])
                  .startOf('day')
                  .toISOString(),
              )
            : null

          const salaryStartDate = salary.start_date[0]
            ? getLocalStringFromDate(
                dayjs(salary.start_date[0]).startOf('day').toISOString(),
              )
            : null

          return {
            group_id: salary.is_raw_value ? null : salary.group_id || null,
            value: salary.is_raw_value ? salary.value || null : null,

            start_date: salaryStartDate,
            end_date: nextStartDate,

            concepts: salary.concepts.map((concept) => ({
              name: concept.name,
              value: concept.value,
              ips: concept.ips,
              description: '',
            })),
          }
        }) || [],

      hiring_date: fromDayMonthYearFormatToDateString(props.hiring_date),
      ips_registration_date: fromDayMonthYearFormatToDateString(
        props.entrance_ips_date,
      ),
      externalCodes,
    },
    cell_id: props.cell_id,
  }

  return apiPayload
}
