import { Div } from '@/components'
import { useMemo } from 'react'
import { PdfBuilderContentDataSchemaType } from '../../types'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import { Progress, Text } from '@punto-ui/react'
import { fromReportTypeAndRowTypesToColumns } from '@/pages/reportes-v2/ReportBuilder/report-builder'
import { v4 as uuid } from 'uuid'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
} from '@/pages/reportes-v2/ReportBuilder/type'
import { templateColumnPlaceholder } from '@/pages/reportes-v2/ReportBuilder/components/ReportBuilderPreview'

const MAXIMUM_COLUMNS = 10

const MAXIMUM_COLUMN_WIDTH = 100
const MINIMUM_COLUMN_WIDTH = 70

export const TableContent = ({
  contentIndex,
  dataIndex,
  contentData,
}: {
  contentData: PdfBuilderContentDataSchemaType
  contentIndex: number
  dataIndex: number
}) => {
  const templateId = contentData.type.split(':')[1]

  const { data: template, isFetching: isLoadingTemplate } =
    useReportTemplate(templateId)

  // const methods = useFormContext<PdfBuilderSchemaType>()
  // const height = useWatch({
  //   control: methods.control,
  //   name: `pdf.content.${contentIndex}.data.${dataIndex}.height`,
  // })

  const table = usePresentationTable(templateId)

  if (!templateId) {
    return null
  }

  return (
    <Div
      css={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        position: 'relative',
        minHeight: 36,

        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isLoadingTemplate && (
        <Div
          css={{
            position: 'absolute',
            zIndex: 2,
            top: 4,
            left: 4,
          }}
        >
          <Progress />
        </Div>
      )}
      {/* {templateId} */}
      <Div
        css={{
          border: '1px solid',
          borderColor: '$interface_dark_up',
          borderRadius: '$md',
        }}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',

            padding: '$1',

            borderBottom: '1px solid',
            borderColor: '$interface_dark_up',
          }}
        >
          <Text
            variant={'caption'}
            css={{
              flex: 1,
              textAlign: 'center',
            }}
          >
            {template?.name}
          </Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',

            padding: '$1',

            borderBottom: '1px solid',
            borderColor: '$interface_dark_up',
          }}
        >
          {Object.keys(table?.[0] || {}).map((header, index) => {
            if (index > MAXIMUM_COLUMNS - 1) return null
            if (header === 'id') return null

            const length = Math.min(
              Object.keys(table?.[0] || {}).length,
              MAXIMUM_COLUMNS,
            )

            const width =
              MAXIMUM_COLUMN_WIDTH +
              (MINIMUM_COLUMN_WIDTH - MAXIMUM_COLUMN_WIDTH) *
                ((length - 1) / (10 - 1))

            return (
              <Div
                css={{
                  width,
                  textAlign: 'center',
                }}
                key={header}
              >
                <Text variant={'caption'}>{header}</Text>
              </Div>
            )
          })}
        </Div>
        <Div>
          {table?.map((t, index) => {
            const entries = Object.entries(t)
            return (
              <Div
                key={t.id}
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {entries.map(([key, value], index) => {
                  if (index > MAXIMUM_COLUMNS - 1) return null
                  if (key === 'id') return null

                  const length = Math.min(entries.length, MAXIMUM_COLUMNS)

                  const width =
                    MAXIMUM_COLUMN_WIDTH +
                    (MINIMUM_COLUMN_WIDTH - MAXIMUM_COLUMN_WIDTH) *
                      ((length - 1) / (10 - 1))

                  return (
                    <Div
                      css={{
                        width,
                        height: 18,
                        textAlign: 'center',
                        overflow: 'hidden',
                      }}
                      key={value}
                    >
                      <Text variant={'caption'}>{value}</Text>
                    </Div>
                  )
                })}
              </Div>
            )
          })}
        </Div>
      </Div>
    </Div>
  )
}

const usePresentationTable = (templateId: string) => {
  const { data: template } = useReportTemplate(templateId)
  const { data: company } = useMyCompany()

  const table = useMemo(() => {
    if (!template) {
      return
    }

    const row = {
      detailSelectedKeys: [],
      detailKey: template.rowDetails,
      type: template.row as IReportBuilderRowTypes,
    }

    const newColumns = fromReportTypeAndRowTypesToColumns(
      template.type as IReportBuilderTypes,
      [row],
      company?.concepts || [],
      template?.columns || [],
    )

    // return newColumns

    const returning: Record<string, string>[] = []
    const mainRandomizer =
      templateColumnPlaceholder[template.type as IReportBuilderTypes]?.[
        template.row as IReportBuilderRowTypes
      ]

    const cachedResults = {} as any

    Array.from({ length: 12 }).forEach((x, index) => {
      const result = {} as any

      Object.entries(newColumns).forEach(([key, columns], index) => {
        return columns
          .filter((c) => c.selected)
          .forEach((detail) => {
            const detailKey = `${detail.value}_${detail.column_type}`
            const newRandomValue = detail.getRandomPlaceholder
              ? detail.getRandomPlaceholder()
              : ''
            const cachedValue = cachedResults[detailKey] as any
            if (
              (key === 'workers' || key === 'permissions') &&
              template.type === 'single' &&
              cachedValue
            ) {
              result[`${detail.label}`] = cachedValue
              return
            }

            result[`${detail.label}`] = newRandomValue
            cachedResults[detailKey] = newRandomValue
          })
      })

      if (mainRandomizer) {
        result.name = mainRandomizer(index)
      }

      result.id = uuid()
      returning.push(result)
    })

    return returning
  }, [template, company])

  return table
}
