import { Text, styled } from '@punto-ui/react'
import { Div } from '../Div'
import { CSS } from '@stitches/react'

export interface IRadioSelectorProps {
  label: string
  isSelected: boolean
  onClick: () => void
  css?: CSS
  textCSS?: CSS
}

const Input = styled('input', {})

export const RadioSelector = (props: IRadioSelectorProps) => {
  return (
    <Div
      as="label"
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$2',
      }}
    >
      <Input type="radio" checked={props.isSelected} onClick={props.onClick} />
      <Text
        css={{
          whiteSpace: 'nowrap',
          ...props.textCSS,
        }}
      >
        {props.label}
      </Text>
    </Div>
  )
}
