import { styled, Text } from '@punto-ui/react'

export const EnterpriseInfosCardContainer = styled('div', {
  all: 'unset',

  padding: '$4 $2',

  borderTop: '2px solid',
  borderColor: '$interface_light_down',

  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  '> svg': {
    height: '$6',
    width: '$6',
    color: '$brand_primary_pure',
  },

  [`> ${Text}`]: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '$interface_dark_down',
    paddingRight: '$4',
  },
})

export const DefaultProfilePic = styled('div', {
  '& svg': {
    height: '$5',
    width: '$5',
    color: '$interface_light_pure',
  },

  backgroundColor: '$brand_primary_down',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$1',
  borderRadius: '$sm',
})
