import { ICell } from '@/libs/react-query/types/organogram'
import { ICreateCompletePolicyData } from './types'
import { CreatePolicyPayload } from '@/libs/react-query/mutations/permissions/policies/types'
import { PermissionConfig } from '@/libs/react-query/types'
import { permissionsArray } from '@/hooks/useGetAllPermissions'

export function toggleAllCells(
  systemModule: keyof ICreateCompletePolicyData,
  policy: string,
  currentSchema: Partial<ICreateCompletePolicyData>,
  value: boolean,
): Partial<ICreateCompletePolicyData> {
  const updatedSchema = { ...currentSchema }
  const moduleInfos = updatedSchema[systemModule] || {}

  Object.keys(moduleInfos).forEach((cellId) => {
    if (cellId === 'cell') {
      return
    }

    const cellPermissions = (moduleInfos as any)[cellId]
    Object.keys(cellPermissions).forEach((userId) => {
      ;(updatedSchema[systemModule] as any)[cellId][userId][policy] = value
    })
    ;(updatedSchema[systemModule] as any)[cellId].cell[policy] = value
  })

  return updatedSchema
}

export function togglePermission(
  cells: ICell[],
  targetId: string,
  policy: string,
  value: boolean,
  systemModule: keyof ICreateCompletePolicyData,
  currentSchema: Partial<ICreateCompletePolicyData>,
): Partial<ICreateCompletePolicyData> {
  const updatedSchema = { ...currentSchema }
  const isUser = !cells.some((cell) => cell.id === targetId)

  if (isUser) {
    const cell = cells.find((cell) =>
      cell.users.some((user) => user.user_id === targetId),
    )

    if (!cell) {
      throw new Error(`User ${targetId} not found in any cell`)
    }

    ;(updatedSchema[systemModule] as any)[cell.id][targetId][policy] = value

    if (value) {
      const allUsersInCellHavePermission = cell.users.every(
        (user) =>
          (updatedSchema[systemModule] as any)[cell.id][user.user_id][policy],
      )
      if (allUsersInCellHavePermission) {
        ;(updatedSchema[systemModule] as any)[cell.id].cell[policy] = true
        cell.users.forEach((user) => {
          ;(updatedSchema[systemModule] as any)[cell.id][user.user_id][policy] =
            true
        })
      }
    } else {
      if ((updatedSchema[systemModule] as any)[cell.id].cell[policy]) {
        ;(updatedSchema[systemModule] as any)[cell.id].cell[policy] = false
        cell.users.forEach((user) => {
          if (user.user_id !== targetId) {
            ;(updatedSchema[systemModule] as any)[cell.id][user.user_id][
              policy
            ] = false
          }
        })
      }
    }
  } else {
    ;(updatedSchema[systemModule] as any)[targetId].cell[policy] = value
    const cell = cells.find((cell) => cell.id === targetId)
    if (!cell) {
      throw new Error('Cell not found')
    }

    cell.users.forEach((user) => {
      ;(updatedSchema[systemModule] as any)[cell.id][user.user_id][policy] =
        true
    })
  }

  return updatedSchema
}

export function fromStructureToCreatePolicyPayload(
  structure: ICreateCompletePolicyData,
  name: string,
  usersIds: string[],
  usersToAuthorizeIds: string[],
  shouldRequestAuthorization: boolean,
): CreatePolicyPayload {
  const entireCellsPermissions: CreatePolicyPayload['permissions'] = []
  const usersCellsPermissions: CreatePolicyPayload['permissions'] = []

  // Loop through the properties of the structure object (e.g., workers, punches, etc.)
  Object.entries(structure).forEach(([moduleName, moduleContent]) => {
    if (
      moduleName === 'name' ||
      moduleName === 'users' ||
      moduleName === 'hasAuthorizations' ||
      moduleName === 'usersToAuthorize'
    ) {
      return
    }

    Object.keys(moduleContent).forEach((cellId) => {
      if (cellId === 'cell') {
        return
      }

      const cellPermissions = (moduleContent as any)[cellId]
      Object.keys(cellPermissions).forEach((userId) => {
        const permissions = Object.keys((cellPermissions as any)[userId])

        if (userId === 'cell') {
          permissions.forEach((permission) => {
            if (!(cellPermissions as any)[userId][permission]) {
              return
            }

            const entireCell = entireCellsPermissions.find(
              (entireCell) =>
                entireCell.permission === `${moduleName}.${permission}`,
            )

            if (entireCell) {
              entireCell.cells.push({
                cell_id: cellId,
                all_users: true,
                all_users_under: false,
                usersIds: [],
              })
            } else {
              entireCellsPermissions.push({
                permission: `${moduleName}.${permission}`,
                usersIds: [],
                allCells: false,
                cells: [
                  {
                    cell_id: cellId,
                    all_users: true,
                    all_users_under: false,
                    usersIds: [],
                  },
                ],
              })
            }
          })
        }

        permissions.forEach((permission) => {
          if (!(cellPermissions as any)[userId][permission]) {
            return
          }

          const usersCell = usersCellsPermissions.find(
            (userCell) => userCell.permission === `${moduleName}.${permission}`,
          )

          if (usersCell) {
            const userCell = usersCell.cells.find(
              (cell) => cell.cell_id === cellId,
            )

            if (userCell) {
              userCell.usersIds.push(userId)
            } else {
              usersCell.cells.push({
                cell_id: cellId,
                all_users: false,
                all_users_under: false,
                usersIds: [userId],
              })
            }
          } else {
            usersCellsPermissions.push({
              permission: `${moduleName}.${permission}`,
              usersIds: [],
              allCells: false,
              cells: [
                {
                  cell_id: cellId,
                  all_users: false,
                  all_users_under: false,
                  usersIds: [userId],
                },
              ],
            })
          }
        })
      })
    })
  })

  Object.entries(structure).forEach(([moduleName, moduleContent]) => {
    if (moduleName === 'name' || moduleName === 'users') {
      return
    }

    Object.keys(moduleContent).forEach((cellId) => {
      if (cellId === 'cell') {
        const permissions = Object.keys((moduleContent as any)[cellId].cell)

        permissions.forEach((permission) => {
          if (!(moduleContent as any)[cellId].cell[permission]) {
            return
          }

          const entireCell = entireCellsPermissions.find(
            (entireCell) =>
              entireCell.permission === `${moduleName}.${permission}`,
          )

          if (entireCell) {
            entireCell.allCells = true
            entireCell.cells = []
            entireCell.usersIds = []
          } else {
            entireCellsPermissions.push({
              permission: `${moduleName}.${permission}`,
              usersIds: [],
              allCells: true,
              cells: [],
            })
          }
        })
      }
    })
  })

  // Merge entireCellsPermissions and usersCellsPermissions by permission, merging cells and usersIds
  const allPermissionTypesDupped = [
    ...entireCellsPermissions.map((permission) => permission.permission),
    ...usersCellsPermissions.map((permission) => permission.permission),
  ]

  const allPermissionsTypes = allPermissionTypesDupped.filter(
    (permission, index) =>
      allPermissionTypesDupped.indexOf(permission) === index,
  )

  const permissions = allPermissionsTypes
    .map((permission) => {
      const entireCell = entireCellsPermissions.find(
        (entireCell) => entireCell.permission === permission,
      )

      const usersCell = usersCellsPermissions.find(
        (userCell) => userCell.permission === permission,
      )

      const entireCellUsersIds =
        entireCell && !entireCell?.allCells ? entireCell.usersIds : []
      const usersCellUsersIds =
        usersCell && !entireCell?.allCells ? usersCell.usersIds : []

      const usersIds = [
        ...entireCellUsersIds,
        ...usersCellUsersIds.filter(
          (userId) =>
            !entireCellUsersIds.some(
              (entireCellUserId) => userId === entireCellUserId,
            ),
        ),
      ]

      const entireCellCells =
        entireCell && !entireCell?.allCells ? entireCell.cells : []
      const usersCellCells =
        usersCell && !entireCell?.allCells ? usersCell.cells : []

      const cells = [
        ...entireCellCells,
        ...usersCellCells.filter(
          (cell) =>
            !entireCellCells.some(
              (entireCell) => entireCell.cell_id === cell.cell_id,
            ),
        ),
      ]

      if (entireCell && usersCell) {
        return {
          permission,
          allCells: entireCell.allCells,
          cells,
          usersIds,
        }
      }

      if (entireCell) {
        return {
          permission,
          allCells: entireCell.allCells,
          cells: entireCell.cells,
          usersIds: entireCell.usersIds,
        }
      }

      if (usersCell) {
        return {
          permission,
          allCells: usersCell.allCells,
          cells: usersCell.cells,
          usersIds: usersCell.usersIds,
        }
      }

      return {
        cells: [],
        permission,
        usersIds: [],
        allCells: false,
      }
    })
    .filter((permission) => permission.cells.length > 0 || permission.allCells)

  return {
    permissions,
    name,
    usersIds,
    is_authorization_required: shouldRequestAuthorization,
    usersToAuthorizeIds: shouldRequestAuthorization ? usersToAuthorizeIds : [],
  }
}

export const buildInitialCreatePolicyFields = (
  policy: PermissionConfig,
  cells: ICell[],
): ICreateCompletePolicyData => {
  const permissionsMapper: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          [key: string]: boolean | undefined
        }
      }
    }
  } = {}

  // For each cell in the policy, we create a cellMap
  for (const cell of cells) {
    for (const permission of permissionsArray) {
      const [beforeDot, afterDot] = permission.split('.')

      if (!permissionsMapper[beforeDot]) {
        permissionsMapper[beforeDot] = {
          cell: {
            cell: {
              all: false,
              edit: false,
              view: false,
            },
          },
        }
      }

      permissionsMapper[beforeDot].cell.cell[afterDot] =
        policy.permissions[permission]?.allCells

      if (!permissionsMapper[beforeDot][cell.id]) {
        permissionsMapper[beforeDot][cell.id] = {}
      }

      if (!permissionsMapper[beforeDot][cell.id].cell) {
        permissionsMapper[beforeDot][cell.id].cell = {}
      }

      if (policy.permissions[permission]?.cells?.includes(cell.id)) {
        permissionsMapper[beforeDot][cell.id].cell[afterDot] = true
      } else if (policy.permissions[permission]?.allCells) {
        permissionsMapper[beforeDot][cell.id].cell[afterDot] = true
      } else {
        permissionsMapper[beforeDot][cell.id].cell[afterDot] = false
      }
    }
    // For each user in the cell, we create a userMap
    for (const user of cell.users) {
      // For each permission in the policy, we create a permissionMap
      for (const permission of permissionsArray) {
        const [beforeDot, afterDot] = permission.split('.')
        if (!permissionsMapper[beforeDot][cell.id][user.user_id]) {
          permissionsMapper[beforeDot][cell.id][user.user_id] = {}
        }
        if (policy.permissions[permission]?.usersIds.includes(user.user_id)) {
          permissionsMapper[beforeDot][cell.id][user.user_id][afterDot] = true
        } else if (policy.permissions[permission]?.allCells) {
          permissionsMapper[beforeDot][cell.id][user.user_id][afterDot] = true
        } else {
          permissionsMapper[beforeDot][cell.id][user.user_id][afterDot] = false
        }

        if (policy.permissions[permission]?.cells?.includes(cell.id)) {
          permissionsMapper[beforeDot][cell.id][user.user_id][afterDot] = true
        }
      }
    }
  }
  return {
    name: policy.name,
    users: policy.usersIds,

    usersToAuthorize: policy.usersAuthorizersIds,
    hasAuthorizations: policy.hasAuthorizationRequest,
    ...permissionsMapper,
  }
}
