import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { queryClient } from '@/libs/react-query'
import { UpdateTotalHoursPayload } from '../types'

const handleUpdateTotalHours = async (props: UpdateTotalHoursPayload) => {
  const response = await apiV2.post<any>('/punches/update-total-hours', props)

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleUpdateTotalHours = () => {
  const mutation = useMutation(['updateTotalHours'], handleUpdateTotalHours)

  return mutation
}
