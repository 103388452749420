import { useEffect } from 'react'
import { useTabStore } from '@/store'
import { BuildingLibraryIcon } from '@heroicons/react/24/outline'

export const useHandleCompanyListActions = ({
  setOpenCompanyCreation,
}: {
  setOpenCompanyCreation: (value: boolean) => void
}) => {
  const { setActions } = useTabStore((state) => ({
    setActions: state.setActionsTab,
  }))

  useEffect(() => {
    setActions('admin', [
      {
        action: () => {
          setOpenCompanyCreation(true)
        },
        label: 'Crear Empresa',
        width: 180,
        variant: 'primary',
        icon: <BuildingLibraryIcon />,
      },
    ])
  }, [])
}
