import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateInvoicesInfosParams } from '@/libs/react-query/types/admin'
dayjs.extend(utc)

const handleUpdateInvoice = async (data: IUpdateInvoicesInfosParams) => {
  await apiV2.post('/admin/update-invoice', {
    ...data,
  })

  queryClient.invalidateQueries('companies')
}

export const useHandleUpdateInvoice = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateInvoice'],
    handleUpdateInvoice,
  )

  return mutation
}
