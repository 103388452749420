import { Div } from '@/components/Div'
import { IPaginationProps } from '@/components/SmartTable/types'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'

export const SmartTablePagination = ({
  table,
  totalNumberOfRows,
  paginationProps,
}: {
  paginationProps?: IPaginationProps
  totalNumberOfRows?: number
  table: any
}) => {
  // const pageIndex = table.getState().pagination.pageIndex
  const pageIndex =
    paginationProps?.page || table.getState().pagination.pageIndex
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$4',
        padding: '$6',
      }}
    >
      <Button onClick={() => table.setPageIndex(0)} disabled={pageIndex === 0}>
        <ChevronDoubleLeftIcon />
      </Button>
      <Button onClick={() => table.previousPage()} disabled={pageIndex === 0}>
        <ChevronLeftIcon />
      </Button>
      <Button
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRightIcon />
      </Button>
      <Button
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <ChevronDoubleRightIcon />
      </Button>
      <span>
        <Text as="strong">
          {pageIndex + 1} de {table.getPageCount()}
        </Text>
      </span>
      {(paginationProps?.total || totalNumberOfRows) && (
        <span
          style={{
            marginLeft: 'auto',
          }}
        >
          <Text as="strong">
            {' '}
            Total: {paginationProps?.total || totalNumberOfRows}{' '}
          </Text>
        </span>
      )}
    </Div>
  )
}
