import { useQuery } from 'react-query'
import { IDocumentStructure } from '../../types/documents'
import { apiV2 } from '@/services/api'

const fetchDocumentsStructure = async () => {
  const response = await apiV2.post<IDocumentStructure>(
    '/documents/get-folders-structure',
  )

  return response.data
}

export const useDocumentsStructure = () => {
  const query = useQuery(['documents-structure'], fetchDocumentsStructure)

  return query
}
