import { Box, Button, Dialog, Text } from '@punto-ui/react'
import { Flex } from '../Flex'
import { CSS } from '@stitches/react'

interface SimpleDialogProps {
  overlayCss?: CSS
  containerCSS?: CSS
  title: string
  description: string
  handleConfirm?: () => void
  icon?: any
  isLoading?: boolean
  handleClose?: () => void
  confirmLabel?: string
  cancelLabel?: string
}

export const SimpleDialog = ({
  description,
  title,
  containerCSS,
  overlayCss,
  handleClose,
  icon,
  isLoading,
  cancelLabel,
  confirmLabel,
  handleConfirm,
}: SimpleDialogProps) => {
  return (
    <Dialog
      overlayCss={overlayCss}
      containerCss={containerCSS}
      content={
        <Box>
          <Flex
            css={{ flexDirection: 'column', marginBottom: '$4', gap: '$4' }}
          >
            <Text variant={'heading2'}>{title}</Text>
            <Text
              variant="paragraph"
              css={{ width: 500, textAlign: 'justify' }}
            >
              {description}
            </Text>
          </Flex>

          <Flex css={{ flex: 1 }}>
            <Button
              css={{ flex: 1, marginRight: '$1' }}
              onClick={handleConfirm}
              isLoading={isLoading}
              icon={icon}
            >
              {confirmLabel || 'Cerrar'}
            </Button>
            <Button
              onClick={handleClose}
              css={{ flex: 1, marginRight: '$1' }}
              variant="tertiary"
            >
              {cancelLabel || 'Cancelar'}
            </Button>
          </Flex>
        </Box>
      }
    />
  )
}
