import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient } from '@/libs/react-query'
import { IUpdatePayrollSettings } from '@/libs/react-query/types/payroll'
dayjs.extend(utc)

const handleUpdatePayrollSettings = async (payload: IUpdatePayrollSettings) => {
  await apiV2.post<void>('/payroll/update-settings', payload)
  queryClient.invalidateQueries()
}

export const useHandleUpdatePayrollSettings = () => {
  const mutation = useMutation(
    ['useHandleUpdatePayrollSettings'],
    handleUpdatePayrollSettings,
  )

  return mutation
}
