import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'

export const usePayrollIPS = () => {
  const columns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Valores Totales',
        name: 'Valores Totales',
        items: [
          {
            type: 'input-text',
            name: 'totalWorkedDaysCompensation',
            inputType: 'money-guarani',
            header: 'Val. Dias (G$)',
            width: 140,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'totalWorkedDaysCompensationIps',
            inputType: 'money-guarani',
            header: 'Val. Dias IPS (G$)',
            width: 160,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'positiveSubtotal',
            inputType: 'money-guarani',
            header: 'Tot. Haberes (G$)',
            width: 180,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'positiveIpsSubtotal',
            inputType: 'money-guarani',
            header: 'Tot. Haberes IPS (G$)',
            width: 190,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'totalDiscount',
            inputType: 'money-guarani',
            header: 'Tot. Desc. (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'negativeIpsSubtotal',
            inputType: 'money-guarani',
            header: 'Tot. Desc. IPS (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'discountsSubtotal',
            inputType: 'money-guarani',
            header: 'Subtotal',
            width: 160,
            disabled: true,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Valores IPS',
        name: 'Valores IPS',
        items: [
          {
            type: 'input-text',
            name: 'ipsBase',
            inputType: 'money-guarani',
            header: 'Base Imponible IPS (G$)',
            width: 210,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'ips16',
            inputType: 'money-guarani',
            header: 'IPS 16,5% (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'ips9',
            inputType: 'money-guarani',
            header: 'IPS 9% (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'totalIps',
            inputType: 'money-guarani',
            header: 'Total IPS (G$)',
            width: 170,
            disabled: true,
          },
          {
            type: 'input-text',
            name: 'salaryAfterIps',
            inputType: 'money-guarani',
            header: 'Salario Neto (G$)',
            width: 160,
            disabled: true,
          },
        ],
      },
    ] as SmartColumnType[]
  }, [])

  return columns
}
