import { Div, FormRenderer } from '@/components'
import { PdfBuilderFields } from '../../types'

export const ConfigurationForm = () => {
  const form: PdfBuilderFields = [
    {
      name: 'configurations.name',
      label: 'Nombre del recibo',
      _type: 'input',
    },
    {
      name: 'configurations.description',
      label: 'Descripción del Recibo',
      _type: 'input',
    },
    {
      _type: 'row',
      numberOfColumns: 2,
      fields: [
        {
          name: 'configurations.size',
          label: 'Tamaño',
          _type: 'dropdown',
          defaultOption: {
            label: 'A4',
            value: 'a4',
          },
          options: [
            {
              label: 'A4',
              value: 'a4',
            },
          ],
        },
        {
          name: 'configurations.direction',
          label: 'Dirección',
          _type: 'dropdown',
          defaultOption: {
            label: 'Retrato',
            value: 'portrait',
          },
          options: [
            {
              label: 'Retrato',
              value: 'portrait',
            },
          ],
        },
      ],
    },
    {
      name: 'configurations.favorite',
      label: 'PDF Favorito',
      _type: 'checkbox',
    },
    {
      name: 'configurations.has_two_per_page',
      label: 'Duplicar en una hoja',
      _type: 'checkbox',
    },
  ]

  return (
    <Div
      css={{
        padding: '$6',
      }}
    >
      <FormRenderer items={form} />
    </Div>
  )
}
