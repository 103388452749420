import { Div } from '@/components'
import { Text } from '@punto-ui/react'
import { ReceiptBuilderSchemaType } from '../../type'
import { useFormContext, useWatch } from 'react-hook-form'
import { RadioSelector } from '@/components/RadioSelector'
import React from 'react'
import { fromReceiptFooterTypeToLabel } from '../../receipt-builder'
import { TwoSignatureFooter } from './ReceiptFooters/TwoSignatureFooter'
import { OneSignatureFooter } from './ReceiptFooters/OneSignatureFooter'
import { LiquidationOneSignatureFooter } from './ReceiptFooters/LiquidationOneSignatureFooter'
import { LiquidationTwoSignatureFooter } from './ReceiptFooters/LiquidationTwoSignatureFooter'

export const ReceiptFooter = () => {
  const { control, setValue } = useFormContext<ReceiptBuilderSchemaType>()

  const [footerConfigType, receiptType] = useWatch({
    control,
    name: ['footerConfig.type', 'configurations.type'],
  })

  return (
    <Div>
      <Text>Seleccione el pie de página:</Text>

      <Div
        css={{
          marginTop: '$4',
          borderTop: '1px solid $interface_light_down',
        }}
      >
        {receiptType === 'liquidation' && (
          <>
            <HeaderOptionContainer>
              <RadioSelector
                textCSS={{
                  width: 300,
                }}
                label={fromReceiptFooterTypeToLabel(
                  'liquidation-one-signature',
                )}
                isSelected={footerConfigType === 'liquidation-one-signature'}
                onClick={() => {
                  setValue('footerConfig.type', 'liquidation-one-signature')
                }}
              />
              <Div
                css={{
                  paddingRight: '$4',
                }}
              >
                <LiquidationOneSignatureFooter />
              </Div>
            </HeaderOptionContainer>
            <HeaderOptionContainer>
              <RadioSelector
                textCSS={{
                  width: 300,
                }}
                label={fromReceiptFooterTypeToLabel(
                  'liquidation-two-signatures',
                )}
                isSelected={footerConfigType === 'liquidation-two-signatures'}
                onClick={() => {
                  setValue('footerConfig.type', 'liquidation-two-signatures')
                }}
              />
              <LiquidationTwoSignatureFooter />
            </HeaderOptionContainer>
          </>
        )}
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label={fromReceiptFooterTypeToLabel('one-signature')}
            isSelected={footerConfigType === 'one-signature'}
            onClick={() => {
              setValue('footerConfig.type', 'one-signature')
            }}
          />
          <OneSignatureFooter />
        </HeaderOptionContainer>
        <HeaderOptionContainer>
          <RadioSelector
            textCSS={{
              width: 300,
            }}
            label={fromReceiptFooterTypeToLabel('two-signatures')}
            isSelected={footerConfigType === 'two-signatures'}
            onClick={() => {
              setValue('footerConfig.type', 'two-signatures')
            }}
          />
          <TwoSignatureFooter />
        </HeaderOptionContainer>
      </Div>
    </Div>
  )
}

export const HeaderOptionContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$40',
        padding: '$2',
        paddingTop: '$6',
        paddingBottom: '$6',

        justifyContent: 'space-between',

        borderBottom: '1px solid $interface_light_down',
      }}
    >
      {children}
    </Div>
  )
}

export const HeaderImportantText = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Text
      variant="paragraph"
      css={{
        fontWeight: 'bold',
      }}
    >
      {children}
    </Text>
  )
}
