import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useProfilePictureForm = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const optionalDataForm: AddWorkerFields = useMemo(() => {
    const form: AddWorkerFields = [
      {
        _type: 'file',
        label: 'Selecionar foto de Perfil',
        disabled: !isWorkerCreationAvailable,
        name: 'profile_photo',
        accept: 'image/*',
      },
    ]

    return form
  }, [isWorkerCreationAvailable])

  return {
    data: optionalDataForm,
  }
}
