import { Div, FormRenderer } from '@/components'
import { styled, Text } from '@punto-ui/react'
import { Accordion } from './components'
import React, { ComponentProps } from 'react'

const FormGroupContainer = styled('div', {})

interface FormGroupProps extends ComponentProps<typeof FormGroupContainer> {
  title?: string
  iconTitle?: React.ReactNode
  items: any
  cols?: number
  type?: 'fixed' | 'collapsable'
}

export const FormGroup = ({
  title,
  iconTitle,
  items,
  cols = 1,
  type = 'fixed',
  ...props
}: FormGroupProps) => {
  return (
    <>
      {type === 'fixed' ? (
        <FormGroupContainer {...props}>
          {!!title && (
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '$4',
              }}
            >
              <Text
                size="xl"
                css={{
                  color: '$interface_dark_deep',
                  fontWeight: '$bold',
                }}
              >
                {title}
              </Text>
              <Div css={{ marginLeft: '$2' }}>{!!iconTitle && iconTitle}</Div>
            </Div>
          )}
          <FormRenderer<any> items={items} inline cols={cols} />
        </FormGroupContainer>
      ) : (
        <Accordion
          title={title}
          accordions={[
            {
              title: title ?? '',
              content: <FormRenderer items={items} inline cols={cols} />,
              value: 'test',
            },
          ]}
          type={'single'}
        />
      )}
    </>
  )
}
