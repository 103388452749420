import { useRequiredDataForm } from './useRequiredPersonalData'
import { useOptionalDataForm } from './useOptionalPersonalDataForm'
import { useMarcacionAsistenciaForm } from './useMarcacionAsistenciaForm'
import { useBankForm } from './useBankForm'
import { useAddressData } from './useAddressData'
import { useChildrenData } from './useChildrenData'
import { useContactsData } from './useContactsData'
import { usePaymentConfigForm } from './usePaymentConfigForm'
import { useRequiredCompanyData } from './useRequiredCompanyData'
import { useCellData } from './useCellData'
import { useIntegrationData } from './useIntegrationData'
import { useVacationConfigForm } from './useVacationConfigForm'

import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { createWorkerSchema } from '@/libs/react-query/mutations/workers'
import { z } from 'zod'

export type AddWorkerData = z.infer<typeof createWorkerSchema>
export type AddWorkerFields = Array<ControlledFieldProps<AddWorkerData>>

export {
  useRequiredDataForm,
  useOptionalDataForm,
  useMarcacionAsistenciaForm,
  useBankForm,
  useAddressData,
  useChildrenData,
  useContactsData,
  usePaymentConfigForm,
  useRequiredCompanyData,
  useCellData,
  useVacationConfigForm,
  useIntegrationData,
}
