import { Text } from '@punto-ui/react'
import { styled } from '../../../styles'

export const FiltersContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '$md',
  borderTopRightRadius: '$md',
  minWidth: 250,
})

export const IconButton = styled('button', {
  all: 'unset',
  height: 24,
  width: 24,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    height: 20,
    width: 20,
  },

  cursor: 'pointer',
})

export const Filter = styled('div', {
  height: '$8',
  paddingTop: '$1',
  paddingBottom: '$1',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  background: '$interface_light_up',

  // borderRadius: '$md',

  // '& button': {
  //   all: 'unset',
  //   cursor: 'pointer',
  // },

  '& svg': {
    height: '$6',
    width: '$6',
    color: '$brand_primary_pure',
    strokeWidth: 2,
  },

  [`& ${Text}`]: {
    color: '$brand_primary_pure',
    fontWeight: '$bold',
    padding: '0 $2',
  },
})

export const OptionsContainer = styled('div', {
  position: 'absolute',
  top: 48,
  right: 0,
  left: 0,
  maxHeight: 300,
  overflowY: 'scroll',
  background: '$interface_light_pure',
  zIndex: 9999,
})

export const Option = styled('div', {})
