import { Avatar, Div } from '@/components'
import {
  Audit,
  AUDIT_ACTIONS,
  AUDIT_TYPE_TO_LABEL,
  AUDIT_TYPE_TO_VARIANT,
} from '@/libs/react-query/types/audits'
import { capitalizeFirstLetters, shortenName } from '@/utils/workers/name'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Divider, Tag, Text } from '@punto-ui/react'
import React from 'react'
import { AuditItemData } from './AuditListData'
import { AuditDates } from './AuditListDates'
import { Authorizations } from './AuditItemAuthorizations'
import { AuditItemWorkers } from './AuditListWorkers'
import dayjs from 'dayjs'

const getAuditItemTitle = (audit: Audit) => {
  const label = {
    [AUDIT_ACTIONS.UPDATE_PUNCH]: 'Actualización de marcación',
    [AUDIT_ACTIONS.CREATE_PUNCH]: 'Creación de marcación',
    [AUDIT_ACTIONS.DELETE_PUNCH]: 'Deleción de marcación',
    [AUDIT_ACTIONS.CREATE_PUNCH_BATCH]: 'Creación de múltiplas marcaciones',
    [AUDIT_ACTIONS.UPDATE_DAY_TOTAL_HOURS]: 'Actualización de horas totales',
    [AUDIT_ACTIONS.AUTHORIZE_DAY_TOTAL_HOURS]: 'Autorización de horas totales',

    [AUDIT_ACTIONS.REHIRE_WORKER]: 'Recontratación de colaborador',
    [AUDIT_ACTIONS.CHANGE_PASSWORD]: 'Cambio de contraseña',
    [AUDIT_ACTIONS.UPDATE_PROFILE_PICTURE]: 'Actualización de foto de perfil',
    [AUDIT_ACTIONS.UPDATE_FACE_ID]: 'Actualización de Face ID',
    [AUDIT_ACTIONS.UPDATE_WORKER]: 'Actualización de colaborador',
    [AUDIT_ACTIONS.CREATE_WORKER]: 'Creación de colaborador',
    [AUDIT_ACTIONS.DEACTIVATE_WORKER]: 'Desactivación de colaborador',
    [AUDIT_ACTIONS.REACTIVATE_WORKER]: 'Reactivación de colaborador',
    [AUDIT_ACTIONS.CREATE_WORKERS]: 'Creación de múltiples colaboradores',

    [AUDIT_ACTIONS.LIQUIDATE_WORKER]: 'Liquidación de colaborador',

    [AUDIT_ACTIONS.CREATE_PAYROLL]: 'Creación de nómina',
    [AUDIT_ACTIONS.UPDATE_PAYROLL_SETTING]:
      'Actualización de configuración de nómina',
    [AUDIT_ACTIONS.UPDATE_PAYROLL_FIELDS]: 'Actualización de campos de nómina',
    [AUDIT_ACTIONS.CLOSE_PAYROLL]: 'Cierre de nómina',

    [AUDIT_ACTIONS.CREATE_MOVEMENT]: 'Creación de movimiento',
    [AUDIT_ACTIONS.UPDATE_MOVEMENT]: 'Actualización de movimiento',
    [AUDIT_ACTIONS.DELETE_MOVEMENT]: 'Deleción de movimiento',

    [AUDIT_ACTIONS.CREATE_PAYMENT]: 'Creación de pago',
    [AUDIT_ACTIONS.UPDATE_PAYMENT]: 'Actualización de pago',
    [AUDIT_ACTIONS.DELETE_PAYMENT]: 'Deleción de pago',

    [AUDIT_ACTIONS.CREATE_VACATION]: 'Creación de vacación',
    [AUDIT_ACTIONS.UPDATE_VACATION]: 'Actualización de vacación',
    [AUDIT_ACTIONS.DELETE_VACATION]: 'Deleción de vacación',

    [AUDIT_ACTIONS.CREATE_SHIFT]: 'Creación de turno',
    [AUDIT_ACTIONS.UPDATE_SHIFT]: 'Actualización de turno',
    [AUDIT_ACTIONS.DUPLICATE_SHIFT]: 'Duplicación de turno',
    [AUDIT_ACTIONS.UPDATE_SHIFT_SCHEDULE]: 'Actualización de horario de turno',
    [AUDIT_ACTIONS.REMOVE_REST]: 'Eliminación de descanso',

    [AUDIT_ACTIONS.CREATE_CONFIG]: 'Creación de configuración',
    [AUDIT_ACTIONS.UPDATE_CONFIG]: 'Actualización de configuración',
    [AUDIT_ACTIONS.DELETE_CONFIG]: 'Deleción de configuración',

    [AUDIT_ACTIONS.AUTHENTICATE]: 'Autenticación',
  }

  return label[audit.action]
}

export const AuditItem = ({
  audit,
  index,
}: {
  audit: Audit
  index: number
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const showDateField = audit.oldDates.length > 0 || audit.newDates.length > 0
  const showDataField = audit.data.length > 0
  const showUserField = audit.oldUsers.length > 0 || audit.newUsers.length > 0

  const hasAnyMissingField = !showDateField || !showDataField || !showUserField

  return (
    <Div
      css={{
        paddingLeft: '$3',
        paddingRight: '$3',
        display: 'flex',
        flexDirection: 'column',
        gap: '$2',
      }}
    >
      <Div
        key={audit.id}
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Div
          css={{
            display: 'flex',
            gap: '$2',
            alignItems: 'center',
            minWidth: 316,
          }}
        >
          <Div
            onClick={() => setIsOpen(!isOpen)}
            css={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              svg: {
                height: 16,
                width: 16,
                color: '$interface_dark_up',

                transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              },
            }}
          >
            <ChevronRightIcon />
          </Div>
          <Div>
            <Text
              variant="caption"
              css={{
                fontWeight: 600,
              }}
            >
              {getAuditItemTitle(audit)}
            </Text>
          </Div>
        </Div>
        <Div
          css={{
            minWidth: 200,
            display: 'flex',
            justifyContent: 'center',
            center: 'center',
          }}
        >
          <Tag variant={AUDIT_TYPE_TO_VARIANT[audit.type]}>
            {AUDIT_TYPE_TO_LABEL[audit.type]}
          </Tag>
        </Div>
        <Div
          css={{
            minWidth: 200,
            display: 'flex',
            justifyContent: 'center',
            center: 'center',
          }}
        >
          <Tag>Executado</Tag>
        </Div>
        <Div
          css={{
            minWidth: 200,
          }}
        />
        <Div
          css={{
            minWidth: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              marginBottom: '$2',
              padding: '$2',
              background: '$interface_light_up',

              borderRadius: '$md',

              display: 'flex',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            <Avatar
              alt="avatar"
              src={audit.user?.photo_url || ''}
              height={24}
              width={24}
            />
            <Text
              variant="caption"
              css={{
                color: '$interface_dark_deep',
              }}
            >
              {capitalizeFirstLetters(shortenName(audit.user.name))}
            </Text>
          </Div>
        </Div>
        <Div
          css={{
            minWidth: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {audit.newUsers.length + audit.oldUsers.length === 1 && (
            <Div
              css={{
                marginBottom: '$2',
                padding: '$2',
                background: '$status_danger_up',

                borderRadius: '$md',

                display: 'flex',
                alignItems: 'center',
                gap: '$2',
              }}
            >
              <Avatar
                alt="avatar"
                src={
                  audit.newUsers[0]?.user?.photo_url ||
                  audit.oldUsers[0]?.user?.photo_url ||
                  ''
                }
                height={24}
                width={24}
              />
              <Text variant="description">
                {capitalizeFirstLetters(
                  shortenName(
                    audit.newUsers[0]?.user?.name ||
                      audit.oldUsers[0]?.user?.name,
                  ),
                )}
              </Text>
            </Div>
          )}
          {!!(audit.newUsers.length + audit.oldUsers.length) &&
            audit.newUsers.length + audit.oldUsers.length !== 1 && (
              <Text variant="caption">
                {audit.newUsers.length + audit.oldUsers.length} colaborador
                {audit.newUsers.length + audit.oldUsers.length > 1 ? 'es' : ''}
              </Text>
            )}
        </Div>
        <Div
          css={{
            minWidth: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text variant="caption">
            {dayjs(audit.created_at).format('DD/MM/YYYY HH:mm')}
          </Text>
        </Div>
      </Div>
      <Div
        css={{
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '1fr' : '0fr',
          display: 'grid',
          zIndex: 1,
          minWidth: 1600,
        }}
      >
        <DivWithSeparatorBetweenChildren>
          {showDataField && (
            <AuditItemData audit={audit} expanded={hasAnyMissingField} />
          )}
          {showUserField && (
            <AuditItemWorkers audit={audit} expanded={hasAnyMissingField} />
          )}
          {showDateField && (
            <AuditDates audit={audit} expanded={hasAnyMissingField} />
          )}
          <Authorizations audit={audit} />
        </DivWithSeparatorBetweenChildren>
      </Div>
    </Div>
  )
}

const DivWithSeparatorBetweenChildren = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Div
      css={{
        overflow: 'hidden',
        display: 'flex',
        gap: '$4',
      }}
    >
      {React.Children.map(children, (child, index) => (
        <React.Fragment key={index}>
          {child}
          {!!child && index !== React.Children.count(children) - 1 && (
            <Divider
              orientation={'vertical'}
              css={{
                maxWidth: '1px',
                background: '$interface_light_down',
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Div>
  )
}
