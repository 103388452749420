import { Text } from '@punto-ui/react'
import { styled } from '../../styles'
import { defaultAppearingAnimationKeyframe } from '@/pages/styles/animations'

export const DropdownContainer = styled('div', {
  position: 'relative',
  border: '1px solid',
  borderColor: 'transparent',

  transition: 'border-color 0.2s ease-in-out',
  borderRadius: '$sm',
  padding: '0 $2',
})

export const DropdownItem = styled('li', {
  all: 'unset',
  flex: 1,
  display: 'flex',
  textOverflow: 'ellipsis',

  variants: {
    isSelected: {
      true: {
        '& button': {
          [`& ${Text}`]: {
            color: '$brand_primary_pure',
          },
        },
        backgroundColor: '$interface_light_up',
      },
    },
  },
})

export const DropdownOptionsContainer = styled('ul', {
  all: 'unset',
  position: 'absolute',
  // top: 'calc(100% + 4px)',
  // left: 0,
  // right: 0,
  zIndex: 9999,

  animation: `${defaultAppearingAnimationKeyframe} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  textOverflow: 'ellipsis',

  maxWidth: 250,
  minWidth: 250,
  maxHeight: 500,
  overflowY: 'scroll',
  overflowX: 'hidden',

  backgroundColor: '$interface_light_pure',
  borderRadius: '$md',

  display: 'none',
  flexDirection: 'column',
  alignItems: 'stretch',

  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
  border: 'solid 1px $interface_light_down',
  padding: '$2',
  opacity: 0,
  transition: 'opacity 0.5s ease-in-out',

  variants: {
    isOpen: {
      true: {
        display: 'flex',
        opacity: 1,
        zIndex: 9999,
      },
      false: {
        display: 'none',
        opacity: 0,

        '&:hover': {
          opacity: 1,
          display: 'flex',
        },
      },
    },
  },

  '& button': {
    all: 'unset',
    padding: '$2',
    cursor: 'pointer',
    flex: 1,
    borderRadius: '$xs',

    [`& ${Text}`]: {
      color: '$interface_dark_down',
    },

    '&:hover': {
      [`& ${Text}`]: {
        color: '$brand_primary_pure',
      },

      backgroundColor: '$interface_light_up',
    },
  },
})

export const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent',
  height: 30,
  alignItems: 'center',
  justifyContent: 'center',
})

export const TextInputContainer = styled('button', {
  all: 'unset',

  background: 'transparent',
  borderRadius: '$sm',
  boxSizing: 'border-box',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',

  '::placeholder': {
    color: '$interface_dark_up',
  },

  '&:focus': {
    borderColor: '$brand_primary_pure',
  },

  '& svg': {
    height: '$5',
    width: '$5',
    strokeWidth: 2,
    color: '$interface_dark_up',
  },

  '&:has(input:focus)': {
    borderColor: '$brand_primary_pure',

    '& svg': {
      color: '$brand_primary_pure',
    },
  },

  '&:has(input:disabled)': {
    background: 'transparent',

    '& svg': {
      color: '$interface_dark_up',
    },
  },
})

export const Input = styled('input', {
  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: '$regular',
  border: 0,
  width: '100%',
  color: '$interface_dark_deep',

  '&:focus': {
    outline: 0,
  },

  '&:placeholder': {
    color: '$interface_dark_up',
  },
})
