import { PunchesTable } from './Table'
import { UserWorkedHoursProvider } from './context'

export const UserTab = ({ id }: { id: string }) => {
  return (
    <UserWorkedHoursProvider id={id}>
      <PunchesTable />
    </UserWorkedHoursProvider>
  )
}
