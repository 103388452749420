import { Avatar, Div } from '@/components'
import { Audit } from '@/libs/react-query/types/audits'
import { capitalizeFirstLetters, shortenName } from '@/utils/workers/name'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { styled, Text } from '@punto-ui/react'

const UnstyledInput = styled('input', {
  all: 'unset',
})

export const AuditItemWorkers = ({
  expanded,
  audit,
}: {
  expanded: boolean
  audit: Audit
}) => {
  return (
    <Div
      css={{
        minWidth: expanded ? 500 : 400,
        maxWidth: 500,
      }}
    >
      <Text
        variant="caption"
        css={{
          marginBottom: '$2',
        }}
      >
        Colaboradores
      </Text>

      <Div
        css={{
          display: 'flex',
          background: '$interface_light_up',
          alignItems: 'center',
          borderRadius: '$md',
          gap: '$2',
          height: 26,
        }}
      >
        <Div
          css={{
            paddingLeft: '$2',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            svg: {
              height: 16,
              width: 16,
              color: '$interface_dark_down',
            },
          }}
        >
          <MagnifyingGlassIcon />
        </Div>
        <UnstyledInput
          css={{
            backgroundColor: 'transparent',
            fontFamily: '$default',
            fontSize: '$xs',
            fontWeight: 'bold',
          }}
          placeholder="Busquear colaborador"
        />
      </Div>

      <Div
        css={{
          marginTop: '$2',
          flexWrap: 'wrap',
          display: 'flex',
          gap: '$1',

          maxHeight: 300,
          overflowY: 'auto',
        }}
      >
        {audit.newUsers.map((user) => (
          <Div
            key={user.id}
            css={{
              marginBottom: '$2',
              padding: '$2',
              background: '$interface_light_up',

              borderRadius: '$md',

              display: 'flex',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            <Avatar
              alt="avatar"
              src={user.user?.photo_url || ''}
              height={24}
              width={24}
            />
            <Text variant="description">
              {capitalizeFirstLetters(shortenName(user.user.name))}
            </Text>
          </Div>
        ))}
        {audit.oldUsers.map((user) => (
          <Div
            key={user.id}
            css={{
              marginBottom: '$2',
              padding: '$2',
              background: '$status_danger_up',

              borderRadius: '$md',

              display: 'flex',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            <Avatar
              alt="avatar"
              src={user.user?.photo_url || ''}
              height={24}
              width={24}
            />
            <Text variant="description">
              {capitalizeFirstLetters(shortenName(user.user.name))}
            </Text>
          </Div>
        ))}
      </Div>
    </Div>
  )
}
