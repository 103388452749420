import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GroupData, AddRow, EditingRow, UserChildren } from '../types'
import {
  buildGroupDataFromOrganogram,
  findGroupByIdInsideGroupData,
  findGroupParentById,
  removeGroupById,
  reorderGroup,
} from '../utils'
import { deeplyCloneObject, isDeeplyEqual } from '@/utils/object'
import { useOrganogram } from '@/libs/react-query/hooks'
import {
  UpdateConnectionPayload,
  UpdateUserPayload,
  useHandleAddCell,
  useHandleEditCell,
  useHandleRemoveCell,
  useHandleUpdateConnection,
  useHandleUpdateUser,
} from '@/libs/react-query/mutations/permissions'

type GroupBuilderContextValue = {
  groups: GroupData[]
  handleSetData: (e: GroupData[], unsaved?: boolean) => void
  addRow: AddRow | null
  editingRow: EditingRow | null
  cancelAddOperation: () => void
  hasPreviousState: boolean
  restorePreviousData: () => void
  setAddRow: React.Dispatch<React.SetStateAction<AddRow | null>>
  setEditingRow: React.Dispatch<React.SetStateAction<EditingRow | null>>
  handleAddAddRow: (e: GroupData) => void
  handleAddGroup: (e: string) => void
  toggleUserLeadership: (e: string, isL: boolean) => void
  removeGroupFromGroups: (e: string) => void
  handleUpdateGroupName: (e: string) => void
  handleUpdateConnection: (e: UpdateConnectionPayload) => void
  handleUpdateUser: (e: UpdateUserPayload) => void
  isLoading: boolean
}

const GroupBuilderContext = React.createContext<GroupBuilderContextValue>({
  groups: [],
  handleSetData: () => null,
  addRow: null,
  editingRow: null,
  cancelAddOperation: () => null,
  restorePreviousData: () => null,
  setAddRow: () => null,
  setEditingRow: () => null,
  hasPreviousState: false,
  handleAddAddRow: () => null,
  handleAddGroup: () => null,
  toggleUserLeadership: () => null,
  removeGroupFromGroups: () => null,
  handleUpdateGroupName: () => null,
  handleUpdateConnection: () => null,
  handleUpdateUser: () => null,
  isLoading: false,
})

export const useGroupBuilder = () => React.useContext(GroupBuilderContext)

type GroupBuilderProviderProps = {
  children: React.ReactNode
}

export const GroupBuilderProvider = ({
  children,
}: GroupBuilderProviderProps) => {
  const {
    data: organogram,
    isError: isErrorOrganogram,
    isFetching: isFetchingOrganogram,
  } = useOrganogram()

  const { mutateAsync: handleAddCell, isLoading: isLoadingAddCell } =
    useHandleAddCell()
  const { mutateAsync: handleEditCell, isLoading: isLoadingEditCell } =
    useHandleEditCell()
  const { mutateAsync: handleRemoveCell, isLoading: isLoadingRemoveCell } =
    useHandleRemoveCell()
  const {
    mutateAsync: handleUpdateConnection,
    isLoading: isLoadingUpdateConnection,
  } = useHandleUpdateConnection()
  const { mutateAsync: handleUpdateUser, isLoading: isLoadingUpdateUser } =
    useHandleUpdateUser()

  const isLoading =
    isLoadingAddCell ||
    isLoadingEditCell ||
    isLoadingRemoveCell ||
    isLoadingUpdateConnection ||
    isLoadingUpdateUser ||
    isFetchingOrganogram

  const [addRow, setAddRow] = useState<AddRow | null>(null)
  const [editingRow, setEditingRow] = useState<EditingRow | null>(null)
  const [groups, setGroups] = useState<GroupData[]>([])
  const previousDataState = useRef<GroupData[][]>([])

  useEffect(() => {
    if (organogram && !isErrorOrganogram) {
      const builtGroups = buildGroupDataFromOrganogram(organogram)
      setGroups([builtGroups])
    }
  }, [organogram, isErrorOrganogram])

  const removeAddRow = useCallback(
    (groups: GroupData[]) => {
      if (!addRow) {
        return
      }

      const dataToBeCloned = deeplyCloneObject(groups)
      const addRowParent = findGroupParentById(dataToBeCloned, addRow.id)

      if (addRowParent && addRowParent._type === 'group') {
        const addRowParentChildren = addRowParent.children
        const addRowParentChildrenIndex = addRowParentChildren.findIndex(
          (child) => child.id === addRow.id,
        )

        addRowParentChildren.splice(addRowParentChildrenIndex, 1)
        return dataToBeCloned
      }
    },
    [addRow],
  )

  const handleSetData = useCallback(
    (newData: GroupData[]) => {
      const newGroupWithoutAdd = removeAddRow(newData)
      const groupsAreEqual = isDeeplyEqual(newGroupWithoutAdd, newData)

      if (groupsAreEqual) {
        return
      }

      previousDataState.current.push(groups)
      const reorderedData = reorderGroup([...newData])
      setGroups(reorderedData)
    },
    [removeAddRow, groups],
  )

  // Recursively get all users under the removed group
  const getAllUsersUnderGroup = (groupId: string): UserChildren[] => {
    const group = findGroupByIdInsideGroupData(groups, groupId)
    if (!group || group._type !== 'group') {
      return []
    }

    const users = group.children.filter(
      (child) => child._type === 'user',
    ) as UserChildren[]

    const groupGroups = group.children.filter(
      (child) => child._type === 'group',
    ) as GroupData[]

    const usersUnderGroups = groupGroups.map((group) =>
      getAllUsersUnderGroup(group.id),
    )

    return [...users, ...usersUnderGroups.flat()]
  }

  const removeGroupFromGroups = useCallback(
    (groupId: string) => {
      const allUsersUnderRemovedGroup = getAllUsersUnderGroup(groupId)
      const usersIds = allUsersUnderRemovedGroup?.map((user) => user.id)
      const groupParent = findGroupParentById(groups, groupId)

      const newGroups = removeGroupById(groups, groupId)

      if (!newGroups) {
        return
      }

      if (newGroups) {
        handleSetData(newGroups)
        handleRemoveCell({
          cellId: groupId,
          usersIds,
          parentId: groupParent?.id || '',
        })
      }
    },
    [groups, handleSetData, handleRemoveCell],
  )

  const cancelAddOperation = useCallback(() => {
    const newDate = removeAddRow(groups)
    setEditingRow(null)
    if (newDate) {
      handleSetData(newDate)
      setAddRow(null)
    }
  }, [groups, handleSetData, removeAddRow])

  const toggleUserLeadership = useCallback(
    (userId: string, isLeader: boolean) => {
      const dataToBeCloned = deeplyCloneObject(groups)
      const user = findGroupByIdInsideGroupData(dataToBeCloned, userId)

      if (user?._type === 'user' && user) {
        user.isLeader = isLeader
        handleSetData(dataToBeCloned)
      }
    },
    [handleSetData, groups],
  )

  const restorePreviousData = useCallback(() => {
    if (previousDataState.current.length) {
      const lastData =
        previousDataState.current[previousDataState.current.length - 1]

      previousDataState.current = previousDataState.current.slice(
        0,
        previousDataState.current.length - 1,
      )

      if (lastData) {
        const foundAddRow = addRow
          ? findGroupByIdInsideGroupData(lastData, addRow.id)
          : null

        if (!foundAddRow && addRow) {
          setAddRow(null)
        }

        const reorderedData = reorderGroup([...lastData])
        setGroups(reorderedData)
      }
    }
  }, [addRow])

  const handleAddGroup = useCallback(
    async (groupName: string) => {
      if (!addRow || !groupName) {
        return
      }

      const dataToBeCloned = deeplyCloneObject(groups)
      const addRowParent = findGroupParentById(dataToBeCloned, addRow.id)
      if (addRowParent && addRowParent._type === 'group') {
        const addRowParentChildren = addRowParent.children
        const addRowParentChildrenIndex = addRowParentChildren.findIndex(
          (child) => child.id === addRow.id,
        )

        const createdCell = await handleAddCell({
          name: groupName,
          parentId: addRowParent.id,
        })

        const newGroup = {
          _type: 'group' as const,
          name: groupName,
          id: createdCell.id,
          children: [],
        }

        addRowParentChildren.splice(addRowParentChildrenIndex, 1, newGroup)
        handleSetData(dataToBeCloned)
        setAddRow(null)
      }
    },
    [addRow, groups, handleSetData, setAddRow, handleAddCell],
  )

  const handleAddAddRow = useCallback(
    (row: GroupData) => {
      const dataToBeCloned = deeplyCloneObject(groups)
      const newRow = findGroupByIdInsideGroupData(dataToBeCloned, row.id)

      if (newRow && newRow._type === 'group') {
        const addRow = {
          _type: 'add' as const,
          name: '',
          id: Date.now().toString(),
          parentName: newRow.name,
        }

        newRow.children.push({
          ...addRow,
        })

        handleSetData(dataToBeCloned)
        setAddRow({
          ...addRow,
        })
      }
    },
    [groups, handleSetData, setAddRow],
  )

  const handleUpdateGroupName = useCallback(
    (groupName: string) => {
      if (!editingRow || !groupName) {
        return
      }

      const dataToBeCloned = deeplyCloneObject(groups)
      const group = findGroupByIdInsideGroupData(dataToBeCloned, editingRow.id)

      if (group && group._type === 'group') {
        group.name = groupName
        handleSetData(dataToBeCloned)
        handleEditCell({
          name: groupName,
          cellId: group.id,
        })
        setEditingRow(null)
      }
    },
    [groups, handleSetData, editingRow, setEditingRow, handleEditCell],
  )

  return (
    <GroupBuilderContext.Provider
      value={{
        isLoading,
        handleUpdateConnection,
        handleUpdateUser,
        handleSetData,
        groups,
        cancelAddOperation,
        removeGroupFromGroups,
        addRow,
        setAddRow,
        restorePreviousData,
        hasPreviousState: previousDataState.current.length > 0,
        handleAddAddRow,
        toggleUserLeadership,
        handleAddGroup,
        editingRow,
        setEditingRow,
        handleUpdateGroupName,
      }}
    >
      {children}
    </GroupBuilderContext.Provider>
  )
}

// const mockedData = [
//   {
//     _type: 'group',
//     id: 'asdffd',
//     name: 'Matriz',
//     children: [
//       {
//         _type: 'user',
//         name: 'Julio',
//         image_url:
//           'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//         id: '123123232',
//       },
//       {
//         _type: 'group',
//         name: 'Sucursal 1',
//         id: '3131435145415',
//         children: [
//           {
//             _type: 'user',
//             name: 'Jorge',
//             image_url:
//               'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//             id: '245145145544444',
//             isLeader: true,
//           },
//           {
//             _type: 'user',
//             name: 'Cristian',
//             image_url:
//               'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//             id: 'sdfds331dd',
//           },
//           {
//             _type: 'group',
//             name: 'Producción',
//             id: '145144467677773',
//             children: [
//               {
//                 _type: 'user',
//                 name: 'Mateus',
//                 image_url:
//                   'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//                 id: 'ddddfsdfadsf',
//               },
//               {
//                 _type: 'user',
//                 name: 'Lio',
//                 image_url:
//                   'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//                 id: 'ajklsdfjaklsdf..fdd',
//                 isLeader: true,
//               },
//             ],
//           },
//           {
//             _type: 'group',
//             name: 'Administrativo',
//             id: '4vbryuwfg',
//             children: [
//               {
//                 _type: 'user',
//                 name: 'Gabi',
//                 image_url:
//                   'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//                 id: 'ddd134rthggdf..fdd',
//                 isLeader: true,
//               },
//               {
//                 _type: 'user',
//                 name: 'Vale',
//                 image_url:
//                   'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//                 id: 'ddd134rthgddd333gdf..fdd',
//               },
//             ],
//           },
//         ],
//       },
//       {
//         _type: 'group',
//         name: 'Sucursal 2',
//         id: '24780890245890245',
//         children: [
//           {
//             _type: 'user',
//             name: 'Gabi',
//             image_url:
//               'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//             id: 'ddd1341351545rthggdf..fdd',
//           },
//           {
//             _type: 'user',
//             name: 'Bellen',
//             image_url:
//               'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//             id: 'ddd134rthg414513332ddd333gdf..fdd',
//             isLeader: true,
//           },
//           {
//             _type: 'user',
//             name: 'Gabi',
//             image_url:
//               'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//             id: 'ddd13112346664rthggdf..fdd',
//           },
//           {
//             _type: 'user',
//             name: 'Marcos',
//             image_url:
//               'https://puntook-main.s3.sa-east-1.amazonaws.com/public/profile/64146ac6ad2f7932ed54be3a/profile/1680181492098.jpeg',
//             id: 'ddd134rthgdddfsde33dd333gdf..fdd',
//           },
//         ],
//       },
//     ],
//   },
// ]
