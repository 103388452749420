import {
  SmartTableAvatarItemValue,
  SmartTableFormItemValue,
  SmartTableFormTagValue,
} from '@/components/SmartTable/types'
import { z } from 'zod'

export const RequestsInfosContent = z.object({
  id: z.string(),
  canAuthorizeRequest: z.boolean(),
  rawStatus: z.string(),
  request_data: z.string(),

  // Table props
  checked: SmartTableFormItemValue,
  avatar: SmartTableAvatarItemValue,
  name_requester: SmartTableFormItemValue,
  name_authorizer: SmartTableFormItemValue,
  status: SmartTableFormTagValue,
  module: SmartTableFormItemValue,
  type: SmartTableFormItemValue,
  request_date: SmartTableFormItemValue,
  authorization_date: SmartTableFormItemValue,
})

export const RequestsInfosTable = z.object({
  data: z.array(RequestsInfosContent),

  cellsIds: z.array(z.string()),
  name: z.string(),
  date: z.string().array(),

  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
})

export type RequestsInfosTableType = z.infer<typeof RequestsInfosTable>
export type RequestsInfosContentType = z.infer<typeof RequestsInfosContent>
