import { Button, Tabs } from '@punto-ui/react'
import { Div } from '@/components'
import { ConfigSchema } from '../../..'
import { useFormContext } from 'react-hook-form'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { LiquidationOtherConceptsTab } from './tabs/LiquidationOtherConceptsTab'
import { LiquidationConfigGroupTab } from './tabs/LiquidationConfigGroupTab'
import { LiquidationConceptsTab } from './tabs/LiquidationConceptsTab'
import { useCan } from '@/hooks'
import { Drawer, DrawerContainer } from '@/components/Drawer'

interface LiquidationsConfigDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

export const MAX_LIQUIDATION_DRAWER_WIDTH = 800

export const LiquidationsConfigDrawer = ({
  handleCloseDrawer,
  isOpen,
  isCreation = false,
}: LiquidationsConfigDrawerProps) => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])

  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<ConfigSchema>()

  const { data: company } = useMyCompany()

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()

  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreateLiquidationConfig = async () => {
    const isValid = await trigger('liquidation')

    if (!isValid) {
      console.log('error', errors)
      return
    }

    const data = getValues()

    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const includedConcepts = orderedCompanyConcepts.filter(
      (_, index) => data.liquidation?.concepts[index],
    )

    if (isCreation) {
      await handleCreateCompanyConfig({
        liquidation: {
          concepts: includedConcepts,
          basicConcepts:
            data.liquidation?.basicConcepts.map((c) => ({
              chosenName: c.name,
              name: c.type,
              ips: c.ips,
            })) || [],
          otherConcepts:
            data.liquidation?.newConcepts.map((c) => ({
              amount: c.amount,
              ips: c.ips,
              isDebit: !c.isCredit,
              name: c.name,
              value: c.value,
            })) || [],

          name: data.liquidation?.name || '',
          should_use_ips_date: !!data.liquidation?.should_use_ips_date,
          should_use_average_salary_in_vacations:
            !!data.liquidation?.should_use_average_salary_in_vacations,
          should_use_average_salary_in_proportional_salary:
            !!data.liquidation
              ?.should_use_average_salary_in_proportional_salary,

          ordinaryNightHours: !!data.liquidation?.ordinaryNightHours,

          extraNightHours: !!data.liquidation?.extraNightHours,
          extraMorningHours: !!data.liquidation?.extraMorningHours,

          holidayMorningHours: !!data.liquidation?.holidayMorningHours,
          holidayNightHours: !!data.liquidation?.holidayNightHours,

          discountMorningHours: !!data.liquidation?.discountMorningHours,
          discountNightHours: !!data.liquidation?.discountNightHours,

          familiarBonus: !!data.liquidation?.familiarBonus,
          vacation_value: !!data.liquidation?.vacation_value,
          mobility: !!data.liquidation?.mobility,
          bonus: !!data.liquidation?.bonus,
          commission: !!data.liquidation?.commission,
          gift: !!data.liquidation?.gift,
          otherEarns: !!data.liquidation?.otherEarns,

          ips9: !!data.liquidation?.ips9,
          antecipate: !!data.liquidation?.antecipate,
          loan: !!data.liquidation?.loan,
          lost: !!data.liquidation?.lost,
          purchase: !!data.liquidation?.purchase,
          judicial: !!data.liquidation?.judicial,
          excess: !!data.liquidation?.excess,
          otherDiscount: !!data.liquidation?.otherDiscount,
        },
      })
    } else {
      await handleUpdateCompanyConfig({
        liquidation: {
          id: data.liquidation?.id || '',
          concepts: includedConcepts,
          basicConcepts:
            data.liquidation?.basicConcepts.map((c) => ({
              chosenName: c.name,
              name: c.type,
              ips: c.ips,
            })) || [],
          otherConcepts:
            data.liquidation?.newConcepts.map((c) => ({
              amount: c.amount,
              ips: c.ips,
              isDebit: !c.isCredit,
              name: c.name,
              value: c.value,
            })) || [],

          name: data.liquidation?.name || '',
          should_use_ips_date: !!data.liquidation?.should_use_ips_date,
          should_use_average_salary_in_vacations:
            !!data.liquidation?.should_use_average_salary_in_vacations,
          should_use_average_salary_in_proportional_salary:
            !!data.liquidation
              ?.should_use_average_salary_in_proportional_salary,

          ordinaryNightHours: !!data.liquidation?.ordinaryNightHours,

          extraNightHours: !!data.liquidation?.extraNightHours,
          extraMorningHours: !!data.liquidation?.extraMorningHours,

          holidayMorningHours: !!data.liquidation?.holidayMorningHours,
          holidayNightHours: !!data.liquidation?.holidayNightHours,

          discountMorningHours: !!data.liquidation?.discountMorningHours,
          discountNightHours: !!data.liquidation?.discountNightHours,

          familiarBonus: !!data.liquidation?.familiarBonus,
          vacation_value: !!data.liquidation?.vacation_value,
          mobility: !!data.liquidation?.mobility,
          bonus: !!data.liquidation?.bonus,
          commission: !!data.liquidation?.commission,
          gift: !!data.liquidation?.gift,
          otherEarns: !!data.liquidation?.otherEarns,

          ips9: !!data.liquidation?.ips9,
          antecipate: !!data.liquidation?.antecipate,
          loan: !!data.liquidation?.loan,
          lost: !!data.liquidation?.lost,
          purchase: !!data.liquidation?.purchase,
          judicial: !!data.liquidation?.judicial,
          excess: !!data.liquidation?.excess,
          otherDiscount: !!data.liquidation?.otherDiscount,
        },
      })
    }

    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div>
            <Tabs
              css={{
                minWidth: MAX_LIQUIDATION_DRAWER_WIDTH,
                height: '90vh',
                overflowY: 'auto',
              }}
              tabs={[
                {
                  label: 'Grupo de Configuración',
                  content: <LiquidationConfigGroupTab />,
                },
                {
                  label: 'Conceptos de Liquidación',
                  content: <LiquidationConceptsTab />,
                },
                {
                  label: 'Otros Conceptos',
                  content: <LiquidationOtherConceptsTab />,
                },
              ]}
            />
            <Button
              disabled={!canCreateLiquidation}
              css={{ marginTop: 24, minWidth: 300 }}
              onClick={handleCreateLiquidationConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear' : 'Actualizar'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
