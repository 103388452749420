import { ComponentProps } from 'react'
import { AvatarContainer, AvatarImage } from './styles'
import { Div } from '../Div'
import { UserIcon } from '@heroicons/react/24/outline'

export type AvatarProps = ComponentProps<typeof AvatarImage>

export function Avatar({ src, height, width, css, ...props }: AvatarProps) {
  return (
    <AvatarContainer
      css={{
        height: height || '$12',
        width: width || '$12',
      }}
    >
      {src ? (
        <Div
          css={{
            border: 'solid 2px $interface_dark_deep',
            height: height || 48,
            width: width || 48,

            borderRadius: '$full',

            backgroundImage: `url(${src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ) : (
        // <AvatarImage
        //   unoptimized={true}
        //   src={src}
        //   height={height || 48}
        //   width={width || 48}
        //   css={{
        //     border: 'solid 2px $interface_dark_deep',
        //   }}
        //   {...props}
        // />
        <Div
          css={{
            height: height || 48,
            width: width || 48,
            background: '$interface_light_down',
            border: '1px solid $brand_primary_pure',
            borderRadius: '$full',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            svg: {
              color: '$brand_primary_pure',
            },
          }}
        >
          <UserIcon width={20} height={20} />
        </Div>
      )}
    </AvatarContainer>
  )
}
