import { Text, styled } from '@punto-ui/react'
import { DomTable, TableContainer, TableHead } from './styles'
import { Table as ReactTable, flexRender } from '@tanstack/react-table'
import React from 'react'
import { GroupData } from '../../types'
import { DraggableRow } from './components/DraggableRow'

export type TableProps = {
  table: ReactTable<GroupData>
  isLoading?: boolean
}

export const Tr = styled('tr', {
  position: 'sticky',
  top: 0,

  background: '$interface_light_pure',

  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
})

export const GroupTable = ({ table }: TableProps) => {
  return (
    <TableContainer>
      <DomTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((column) => {
                  if (column.column.columnDef?.meta?.headless) {
                    return null
                  }

                  return (
                    <TableHead key={column.id} colSpan={column.colSpan}>
                      {typeof column.column.columnDef.header === 'string' ? (
                        <Text
                          variant="paragraph"
                          css={{ textAlign: 'left', fontWeight: 700 }}
                        >
                          {flexRender(
                            column.column.columnDef.header,
                            column.getContext(),
                          )}
                        </Text>
                      ) : (
                        flexRender(
                          column.column.columnDef.header,
                          column.getContext(),
                        )
                      )}
                    </TableHead>
                  )
                })}
              </Tr>
            )
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <DraggableRow key={row.id} row={row} index={index} table={table} />
          ))}
        </tbody>
      </DomTable>
    </TableContainer>
  )
}
