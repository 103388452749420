import { ColumnDef } from '@tanstack/react-table'
import React, { useRef } from 'react'
import { GroupData } from '../types'
import { SimpleTransparentInput } from '../styles'
import { RowContainer } from '../components/IamTable/styles'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline'
import { Avatar, Button, Tag, Text } from '@punto-ui/react'
import { useGroupBuilder } from '../context'
import { GroupDropdownMenu } from '../components'
import { UserDropdownMenu } from '../components/Dropdowns/UserDropdown'
import { Div, Flex } from '@/components'

export const useGroupColumns = () => {
  const rowName = useRef('')
  const editingName = useRef('')
  const {
    addRow,
    editingRow,
    handleAddAddRow,
    handleAddGroup,
    cancelAddOperation,
    // restorePreviousData,
    handleUpdateGroupName,
  } = useGroupBuilder()

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (addRow) {
          handleAddGroup(rowName.current)
        } else {
          handleUpdateGroupName(editingName.current)
        }
      }

      if (event.ctrlKey && event.key === 'z') {
        // restorePreviousData()
      }

      if (event.key === 'Escape') {
        cancelAddOperation()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  const columns = React.useMemo<ColumnDef<GroupData>[]>(
    () => [
      {
        id: 'name',
        footer: (props) => props.column.id,
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
          headless: true,
          tdStyles: {
            width: '100%',
          },
        },
        cell: ({ getValue, row }) =>
          editingRow && editingRow?.id === row.original.id ? (
            <Flex
              css={{
                paddingLeft: `${row.depth * 2 + 0.7}rem`,
                svg: {
                  color: row.getCanExpand()
                    ? '$interface_dark_pure'
                    : '$interface_light_pure',
                },
              }}
            >
              <ChevronRightIcon height={16} width={16} />
              <SimpleTransparentInput
                onChange={(e) => {
                  editingName.current = e.target.value
                }}
                placeholder={`${editingRow.name}`}
                defaultValue={editingRow.name}
                autoFocus
              />
            </Flex>
          ) : row.original._type === 'add' ? (
            <Flex
              css={{
                paddingLeft: `${row.depth * 2 + 0.7}rem`,
                svg: {
                  color: row.getCanExpand()
                    ? '$interface_dark_pure'
                    : '$interface_light_pure',
                },
              }}
            >
              <SimpleTransparentInput
                onChange={(e) => {
                  rowName.current = e.target.value
                }}
                placeholder={`Nuevo grupo de ${row.original.parentName}`}
                autoFocus
              />
            </Flex>
          ) : (
            <RowContainer
              {...{
                onClick: row.getCanExpand()
                  ? row.getToggleExpandedHandler()
                  : () => null,
                css: {
                  cursor: 'pointer',
                  paddingLeft: `${row.depth * 2 + 0.7}rem`,
                  svg: {
                    strokeWidth: 2.5,
                    color: row.getCanExpand()
                      ? '$brand_primary_pure'
                      : '$interface_light_pure',
                  },
                },
              }}
            >
              <>
                {row.getIsExpanded() ? (
                  <ChevronDownIcon height={16} width={16} />
                ) : (
                  <ChevronRightIcon height={16} width={16} />
                )}
              </>{' '}
              {row.original._type === 'user' && (
                <Avatar src={row.original.image_url} height={24} width={24} />
              )}
              <Text
                css={{
                  marginLeft: 8,
                  fontWeight:
                    row.original._type === 'group' ? '$bold' : '$regular',
                  color:
                    row.original._type === 'group'
                      ? '$brand_primary_pure'
                      : '$interface_dark_deep',
                }}
              >
                <>{getValue()}</>
              </Text>
              {row.original._type === 'user' && row.original.isLeader && (
                <Div
                  css={{
                    marginLeft: '$2',
                  }}
                >
                  <Tag
                    variant="positive"
                    css={{
                      display: 'flex',
                      alignItems: 'center',

                      svg: {
                        height: 10,
                        width: 10,
                        color: '$status_success_deep',
                        marginRight: '$1',
                      },
                    }}
                  >
                    <TrophyIcon />
                    Lider
                  </Tag>
                </Div>
              )}
            </RowContainer>
          ),
      },
      {
        cell: (info) =>
          editingRow &&
          editingRow?.id !== info.row.original.id ? null : addRow &&
            addRow?.id !== info.row.original.id ? null : info.row.original
              ._type === 'add' ||
            (editingRow && editingRow?.id === info.row.original.id) ? (
            <Button
              variant="secondary"
              size={'sm'}
              type="submit"
              onClick={() => {
                editingRow
                  ? handleUpdateGroupName(editingName.current)
                  : handleAddGroup(rowName.current)
              }}
            >
              <PlusIcon height={16} width={16} />
              {editingRow ? 'Editar' : 'Agregar'}
            </Button>
          ) : info.row.original._type === 'group' ? (
            <Button
              variant="tertiary"
              size={'sm'}
              css={{
                svg: {
                  color: '$interface_dark_deep',
                },
              }}
              onClick={() => {
                handleAddAddRow(info.row.original)
                info.row.toggleExpanded(true)
              }}
            >
              <PlusIcon height={16} width={16} />
            </Button>
          ) : null,
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        meta: {
          content: 'component',
          headless: true,
          tdStyles: {
            width: '4%',
          },
        },
        id: 'add-row',
      },
      {
        cell: (info) =>
          editingRow &&
          editingRow?.id !== info.row.original.id ? null : addRow &&
            addRow?.id !== info.row.original.id ? null : info.row.original
              ._type === 'user' ? (
            <UserDropdownMenu id={info.row.original.id} />
          ) : info.row.original._type === 'group' && !editingRow?.id ? (
            <GroupDropdownMenu id={info.row.original.id} />
          ) : (
            <Button variant="tertiary" size={'sm'} onClick={cancelAddOperation}>
              Cancelar
            </Button>
          ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        meta: {
          content: 'component',
          headless: true,
          tdStyles: {
            width: '4%',
          },
        },
        id: 'dropdown',
      },
    ],
    [
      handleAddAddRow,
      cancelAddOperation,
      addRow,
      handleAddGroup,
      handleUpdateGroupName,
      editingRow,
    ],
  )

  return { data: columns }
}
