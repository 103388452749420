import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import {
  FetchLiquidatedWorkerProps,
  IPaginatedWorkerUser,
} from '../../types/worker'

const fetchLiquidatedWorkers = async (props: FetchLiquidatedWorkerProps) => {
  try {
    const response = await apiV2.post<IPaginatedWorkerUser>(
      '/user/list-liquidated-users',
      {
        usersIds: props.usersIds,
        page: props.page !== undefined ? props.page.toString() : undefined,
        perPage:
          props.pageSize !== undefined ? props.pageSize.toString() : undefined,
        name: props.name,
      },
      {
        params: {
          cellsIds: props.cellsIds?.join(','),
          policiesIds: props.policiesIds?.join(','),
          // ...props,
          // perPage: props.pageSize,
          // usersIds: props.usersIds?.join(','),
          // usersIdsFilter: props.usersIdsFilter?.join(','),
          // name: props.name,
        },
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
    return {
      data: [],
      total: 0,
      totalPages: 0,
      page: 0,
      pageSize: 0,
    }
  }
}

export const useLiquidatedWorkers = (props: FetchLiquidatedWorkerProps) => {
  const { data: me } = useMe()

  const defaultEnabled =
    !!me &&
    !!me.company_id &&
    !!(props.cellsIds?.length || props.usersIds?.length)

  const query = useQuery(
    ['workers', 'liquidated-workers', props, me?.company_id, props.cellsIds],
    async () => {
      const workers = await fetchLiquidatedWorkers(props)

      return workers
    },
    {
      keepPreviousData: true,
      enabled: defaultEnabled,
    },
  )

  return query
}
