import {
  generateRandomDateArray,
  generateRandomDescription,
  generateRandomSiOrEmpty,
  generateRandomVacationTitles,
} from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const singleVacationColumns: IReportBuilderColumnType[] = [
  {
    type: 'vacations',
    value: 'title',
    label: 'Titulo',
    valueType: 'string' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomVacationTitles,
  },
  {
    type: 'vacations',
    value: 'description',
    label: 'Descripcion',
    valueType: 'string' as const,
    subgroup: 'Fechas',
    getRandomPlaceholder: generateRandomDescription,
  },
  {
    type: 'vacations',
    value: 'paid_in_liquidation',
    getRandomPlaceholder: generateRandomSiOrEmpty,
    label: 'Pago en la liquidación',
    valueType: 'boolean' as const,
    subgroup: 'Fechas',
  },
  {
    type: 'vacations',
    value: 'period',
    valueType: 'date' as const,
    label: 'Periodo',
    getRandomPlaceholder: generateRandomDateArray,
    subgroup: 'Fechas',
  },
]
