import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IListTemplatesParams, IReceiptResponse } from '../../types/reports'

const fetchReportReceipts = async (props: IListTemplatesParams) => {
  try {
    const response = await apiV2.post<IReceiptResponse>(
      '/report-builder/list-receipts',
      {
        page: props.page,
        perPage: props.perPage,
        name: props.name,
        favorite: props.favorite,
        types: props.types,
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const useReportReceipts = (props: IListTemplatesParams) => {
  const query = useQuery(
    ['receipts', props],
    async () => {
      const workers = await fetchReportReceipts(props)

      return workers
    },
    {
      keepPreviousData: true,
    },
  )

  return query
}
