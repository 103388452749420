import { IPdfTemplate } from '@/libs/react-query/types/reports'
import { extractVariablesFromText } from '@/pages/reportes-v2/PdfBuilder/pdf-builder'

export const getPdfVariablesFromLayouts = (
  layouts?: IPdfTemplate['layouts'],
): string[] => {
  return (
    layouts
      ?.flatMap((c) => c.content.map((d) => d))
      .filter((c) => c.type === 'text')
      .flatMap((c) => {
        const variablesInText = extractVariablesFromText(c.value || '')
        const variablesDupped = variablesInText
          .filter((v) => v.type === 'variable')
          .map((v) => v.text.replace('{', '').replace('}', ''))

        const variables = [...(new Set(variablesDupped) as any)]

        return variables
      }) || ([] as string[])
  )
}
