import { Button, Dialog, DialogContainer, Tabs, Tooltip } from '@punto-ui/react'
import { Div } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { ConfigSchema } from '../../..'
import { ISalaryGroup } from '@/libs/react-query/types/company'
import { useMemo } from 'react'
import {
  useHandleCreateCompanyConfig,
  useHandleUpdateCompanyConfig,
} from '@/libs/react-query/mutations/company'
import { SalaryForm } from './tabs/SalaryForm'
import { SalaryHistory } from './tabs/SalaryHistory'
import { useCan } from '@/hooks'
import { getLocalStringFromDate } from '@/utils/date'
import dayjs from 'dayjs'

interface CreateSalaryGroupDialogProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
  selectedSalaryGroup: ISalaryGroup | null
}

export const MAX_DRAWER_CONFIG_WIDTH = '90vw'

export const CreateSalaryGroupDialog = ({
  handleCloseDrawer,
  isOpen,
  isCreation = false,
  selectedSalaryGroup,
}: CreateSalaryGroupDialogProps) => {
  const canCreateSalary = useCan(['configurations.salary'])
  const { getValues, trigger, control, formState } =
    useFormContext<ConfigSchema>()

  const { mutateAsync: handleCreateCompanyConfig, isLoading: isLoadingCreate } =
    useHandleCreateCompanyConfig()
  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const isLoading = useMemo(() => {
    return isLoadingCreate || isLoadingUpdate
  }, [isLoadingCreate, isLoadingUpdate])

  const salaryGroup = useWatch({
    control,
    name: 'salaryGroup',
  })

  const handleCreateSalaryGroup = async () => {
    const isValid = await trigger('salaryGroup')

    if (!isValid) {
      console.log('Form is not valid', formState.errors)
      return
    }

    const data = getValues()

    if (isCreation && data.salaryGroup) {
      await handleCreateCompanyConfig({
        salaryGroup: {
          name: data.salaryGroup.name,
          type: data.salaryGroup.type || '',
          description: data.salaryGroup.description || '',
          history: data.salaryGroup.history.map((history, index) => {
            const start =
              index === 0
                ? null
                : history.start_date[0]
                ? getLocalStringFromDate(
                    dayjs(history.start_date[0]).startOf('day').toISOString(),
                  )
                : null
            const nextHistoryStartOrNull = data.salaryGroup?.history?.[
              index + 1
            ]
              ? data.salaryGroup?.history?.[index + 1].start_date?.[0]
                ? getLocalStringFromDate(
                    dayjs(
                      data.salaryGroup?.history?.[index + 1].start_date?.[0],
                    )
                      .startOf('day')
                      .toISOString() || '',
                  )
                : null
              : null

            return {
              start_date: start,
              end_date: nextHistoryStartOrNull,
              is_minimum_wage: history.is_minimum_wage,
              value: history.value,
              concepts: history.concepts
                .filter((c) => c.name)
                .map((c) => {
                  return {
                    name: c.name,
                    value: c.value || 0,
                    description: c.description,
                    ips: !!c.ips,
                  }
                }),
            }
          }),
        },
      })
    } else if (data.salaryGroup && data.salaryGroup.id) {
      await handleUpdateCompanyConfig({
        salaryGroup: {
          id: data.salaryGroup.id,
          name: data.salaryGroup.name,
          type: data.salaryGroup.type || '',
          description: data.salaryGroup.description || '',
          history: data.salaryGroup.history.map((history, index) => {
            const start =
              index === 0
                ? null
                : history.start_date[0]
                ? getLocalStringFromDate(
                    dayjs(history.start_date[0]).startOf('day').toISOString(),
                  )
                : null
            const nextHistoryStartOrNull = data.salaryGroup?.history?.[
              index + 1
            ]
              ? data.salaryGroup?.history?.[index + 1].start_date?.[0]
                ? getLocalStringFromDate(
                    dayjs(
                      data.salaryGroup?.history?.[index + 1].start_date?.[0],
                    )
                      .startOf('day')
                      .toISOString() || '',
                  )
                : null
              : null

            return {
              start_date: start,
              end_date: nextHistoryStartOrNull,
              is_minimum_wage: history.is_minimum_wage,
              value: history.value,
              concepts: history.concepts
                .filter((c) => c.name)
                .map((c) => {
                  return {
                    name: c.name,
                    value: c.value || 0,
                    description: c.description,
                    ips: !!c.ips,
                  }
                }),
            }
          }),
        },
      })
    }

    handleCloseDrawer()
  }

  const unavailableButtonMessage = useMemo(() => {
    if (!salaryGroup?.name) {
      return 'Debe ingresar un nombre'
    }

    if (!salaryGroup?.type && isCreation) {
      return 'Debe ingresar un tipo'
    }

    if (!salaryGroup?.history?.length) {
      return 'Debe ingresar un valor de salario'
    }

    const historyGroupWithoutValue = salaryGroup?.history?.findIndex(
      (history) => !history.value,
    )

    if (historyGroupWithoutValue !== -1) {
      return `Debe ingresar un valor al salario ${historyGroupWithoutValue + 1}`
    }

    const salaryHistoryWithoutDate = salaryGroup?.history?.findIndex(
      (history, index) => index && !history.start_date[0],
    )

    if (salaryHistoryWithoutDate !== -1) {
      return `Debe ingresar una fecha de inicio al salario ${
        salaryHistoryWithoutDate + 1
      }`
    }

    return ''
  }, [salaryGroup, isCreation])

  const tabs = [
    {
      label: isCreation ? 'Crear Salário' : 'Editar Salário',
      content: (
        <SalaryForm
          selectedSalaryGroup={selectedSalaryGroup}
          isCreation={isCreation}
        />
      ),
    },
    {
      label: 'Salario',
      content: <SalaryHistory />,
    },
  ]

  return (
    <DialogContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Dialog
        overlayCss={{
          zIndex: 99,
        }}
        containerCss={{
          zIndex: 100,
          padding: 0,
        }}
        content={
          <Div>
            <Div
              css={{
                height: 36,
                background: '$interface_light_up',
                borderTopRightRadius: '$md',
                borderTopLeftRadius: '$md',
              }}
            />
            <Tabs css={{ minWidth: '50vw' }} tabs={tabs} />
            <Div
              css={{
                display: 'flex',
                alignItems: 'flex-start',
                paddingBottom: '$2',
                paddingLeft: '$2',
              }}
            >
              {unavailableButtonMessage ? (
                <Tooltip message={unavailableButtonMessage} arrow>
                  <Div>
                    <Button
                      css={{ marginTop: '$6', minWidth: 300 }}
                      onClick={handleCreateSalaryGroup}
                      disabled={true}
                      isLoading={isLoading}
                    >
                      {isCreation ? 'Crear' : 'Actualizar'}
                    </Button>
                  </Div>
                </Tooltip>
              ) : (
                <Div>
                  <Button
                    isLoading={isLoading}
                    css={{ marginTop: '$6', minWidth: 300 }}
                    onClick={handleCreateSalaryGroup}
                    disabled={isLoading || !canCreateSalary}
                  >
                    {isCreation ? 'Crear' : 'Actualizar'}
                  </Button>
                </Div>
              )}
            </Div>
          </Div>
        }
      />
    </DialogContainer>
  )
}
