import { styled } from '@punto-ui/react'

export const Container = styled('div', {
  border: '1px solid',
  borderColor: '$interface_dark_up',
  borderRadius: 8,
  padding: 0,
  marginTop: 32,
})

export const SimpleTransparentInput = styled('input', {
  all: 'unset',
  height: '$12',
  padding: '0 $8',
  color: '$interface_dark_deep',
  fontFamily: '$default',
  width: '100%',
})
