import {
  useAvailableCells,
  usePayments,
  usePayrolls,
  useVacations,
} from '@/libs/react-query/hooks'
import { ExportReceiptFields } from './form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'

export const useExportReceiptConfiguration = (reportType: string) => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payments')),
  )
  const { data: availableCellsVacations } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('vacation')),
  )

  const { data: payrolls } = usePayrolls()
  const { data: payments } = usePayments({
    cellsIds: availableCells?.map((c) => c.id) || [],
    name: '',
    page: 1,
    pageSize: 200,
    types: [],
  })
  const { data: vacations } = useVacations({
    cellsIds: availableCellsVacations?.map((c) => c.id) || [],
    page: 0,
    perPage: 200,
  })

  const onePayrollForm: ExportReceiptFields = [
    {
      name: 'payrollId',
      _type: 'dropdown',
      label: 'Nómina',
      options:
        payrolls?.map((p) => ({
          label: p.name,
          value: p.id,
        })) || [],
      defaultOption: {
        label: payrolls?.[0]?.name || '',
        value: payrolls?.[0]?.id || '',
      },
    },
  ]

  const paymentForm: ExportReceiptFields = [
    {
      name: 'paymentId',
      _type: 'dropdown',
      label: 'Pago',
      options:
        payments?.data?.map((p) => ({
          label: p.title,
          value: p.id,
        })) || [],
      defaultOption: {
        label: payments?.data?.[0]?.title || '',
        value: payments?.data?.[0]?.id || '',
      },
    },
  ]

  const vacationsForm: ExportReceiptFields = [
    {
      name: 'vacationId',
      _type: 'dropdown',
      label: 'Vacationes',
      options:
        vacations?.data?.map((p) => ({
          label: p.name,
          value: p.id,
        })) || [],
      defaultOption: {
        label: vacations?.data?.[0]?.name || '',
        value: vacations?.data?.[0]?.id || '',
      },
    },
  ]

  return {
    data:
      reportType === 'payroll'
        ? onePayrollForm
        : reportType === 'payment'
        ? paymentForm
        : reportType === 'vacations'
        ? vacationsForm
        : [],
  }
}
