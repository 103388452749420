import { z } from 'zod'
import {
  movementsDictionary,
  MovementTypesArray,
  IMovementCategories,
} from '../../types'

const availableMovementsTypes = Object.entries(movementsDictionary).map(
  ([key, value]) => key,
) as [IMovementCategories, ...IMovementCategories[]]

export const createMovementSchema = z.object({
  id: z.string().optional(),
  title: z
    .string({ required_error: 'Ingrese un titulo' })
    .min(1, { message: 'Ingrese un titulo' }),
  description: z.string().optional(),
  type: z.enum(MovementTypesArray),
  category: z.enum(availableMovementsTypes),
  periods: z.array(z.date()),
  period_type: z.enum(['range', 'multiple']),
  withHours: z.boolean().optional(),
  remuneration: z.boolean(),
  ips: z.boolean(),
  should_include_hours: z.boolean().optional(),
  users: z.string({ required_error: 'Selecione 1 colaborador' }).array(),
  defaultMultiple: z.date().array().optional(),

  filters: z
    .object({
      name: z.string().optional(),
      cellsIds: z.string().array().optional(),
      categories: z.string().array().optional(),
      date: z.date().array().optional(),
    })
    .optional(),
})

export const createMovementDefaultValues = {
  title: 'Titulo del movimiento',
  description: 'Descripcion del movimiento',
  type: 'presence',
  remuneration: false,
  ips: false,
  users: [''],
  periods: [new Date(), new Date()],
}
