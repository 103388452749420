import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  SmartTableFormArrayItem,
  SmartTableFormItemValue,
  getSmartTableFormItemValue,
} from '@/components/SmartTable/types'
import { parseFormattedDate } from '@/utils/date'
import { z } from 'zod'

export const StatisticsManagerSchema = z.object({
  companyName: z.string(),
  dates: z.date().nullable().array(),
  weeksLength: z.number(),
  filterStatus: z.string().array(),
  data: z
    .object({
      id: z.string(),

      status: SmartTableFormItemValue,
      plan: SmartTableFormItemValue,
      count: SmartTableFormItemValue,
      name: SmartTableFormItemValue,
      document: SmartTableFormItemValue,
      email: SmartTableFormItemValue,
      legal_representative: SmartTableFormItemValue,
      activeEmployees: SmartTableFormItemValue,
      inactiveEmployees: SmartTableFormItemValue,
      totalEmployees: SmartTableFormItemValue,
      created_at: SmartTableFormItemValue,

      test_period_start: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      test_period_end: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      payroll_import_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      app_setup_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      face_register_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      punch_register_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),

      entrance_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),
      cancelation_date: getSmartTableFormItemValue(
        (data) => !!parseFormattedDate(data),
      ),

      contact_email: SmartTableFormItemValue,
      contact_name: SmartTableFormItemValue,
      contact_phone: SmartTableFormItemValue,

      payment_month_day: SmartTableFormItemValue,
      default_external_product_code: SmartTableFormItemValue,
      default_month_invoice_value: SmartTableFormItemValue,
      default_month_invoice_quantity: SmartTableFormItemValue,

      weeksPunches: SmartTableFormArrayItem,
      numberOfPayrolls: SmartTableFormItemValue, // done
      activeWorkers: SmartTableFormItemValue,
      totalPunches: SmartTableFormItemValue, // done
      totalMovements: SmartTableFormItemValue, // done
      totalVacations: SmartTableFormItemValue, // done
      totalPayments: SmartTableFormItemValue, // done
    })
    .array(),
})

export type StatisticsManagerSchemaType = z.infer<
  typeof StatisticsManagerSchema
>
export type InvoiceManagerFields = Array<
  ControlledFieldProps<StatisticsManagerSchemaType>
>
