import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useAguinaldoGroupsColumns } from './table'
import { IAguinaldoConfigGroup } from '@/libs/react-query/types/company'
import { AguinaldosConfigDrawer } from './components/AguinaldosConfigDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { useCan } from '@/hooks'

interface AguinaldoGroupDrawerState {
  isOpen: boolean
  selectedAguinaldoConfig: IAguinaldoConfigGroup | null
}

export const AguinaldosConfigs = () => {
  const canCreateAguinaldos = useCan(['configurations.aguinaldos'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] =
    React.useState<AguinaldoGroupDrawerState>({
      isOpen: false,
      selectedAguinaldoConfig: null,
    })

  const columns = useAguinaldoGroupsColumns()

  const handleCreateAguinaldoConfig = () => {
    setValue('aguinaldo', {
      name: '',
      concepts: Array.from({ length: company?.concepts.length ?? 0 }).map(
        () => true,
      ),
      ordinaryNightHours: true,
      vacation_value: true,
      proportional_vacations: false,
      extraNightHours: true,
      extraMorningHours: true,

      holidayMorningHours: true,
      holidayNightHours: true,

      discountMorningHours: true,
      discountNightHours: true,

      familiarBonus: true,
      mobility: true,
      bonus: true,
      commission: true,
      gift: true,
      otherEarns: true,

      ips9: true,
      antecipate: true,
      loan: true,
      lost: true,
      purchase: true,
      judicial: true,
      excess: true,
      otherDiscount: true,
    })
    setDrawerState({
      isOpen: true,
      selectedAguinaldoConfig: null,
    })
  }

  const handleSelectAguinaldoConfig = (row: IAguinaldoConfigGroup) => {
    const concepts: boolean[] = []

    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    orderedCompanyConcepts.forEach((conceptName, index) => {
      if (row.concepts.find((c) => c.name === conceptName)) {
        concepts[index] = true
      }
    })

    setValue('aguinaldo', {
      id: row.id,
      name: row.name,
      concepts,
      ordinaryNightHours: row.ordinaryNightHours,
      vacation_value: !!row.vacation_value,
      extraNightHours: row.extraNightHours,
      extraMorningHours: row.extraMorningHours,
      proportional_vacations: !!row.proportional_vacations,

      holidayMorningHours: row.holidayMorningHours,
      holidayNightHours: row.holidayNightHours,

      discountMorningHours: row.discountMorningHours,
      discountNightHours: row.discountNightHours,

      familiarBonus: row.familiarBonus,
      mobility: row.mobility,
      bonus: row.bonus,
      commission: row.commission,
      gift: row.gift,
      otherEarns: row.otherEarns,

      ips9: row.ips9,
      antecipate: row.antecipate,
      loan: row.loan,
      lost: row.lost,
      purchase: row.purchase,
      judicial: row.judicial,
      excess: row.excess,
      otherDiscount: row.otherDiscount,
    })
    setDrawerState({
      isOpen: true,
      selectedAguinaldoConfig: row,
    })
  }

  const table = useReactTable({
    data: company?.aguinaldoConfigGroups ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del grupo" placeholder="Nombre del grupo" />
        {canCreateAguinaldos && (
          <Button onClick={handleCreateAguinaldoConfig}>
            Crear configuración de aguinaldos
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<IAguinaldoConfigGroup>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectAguinaldoConfig}
        />
      </Div>

      <AguinaldosConfigDrawer
        isCreation={drawerState.selectedAguinaldoConfig === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedAguinaldoConfig: null,
          })
        }
      />
    </Div>
  )
}
