import { z } from 'zod'

export const createPunchclockSchema = z.object({
  date: z.string(),
  timeIn: z.object({
    time: z.number(),
    platform: z.enum([
      'phone',
      'qrCode',
      'companyPhone',
      'companyQr',
      'byAdmin',
      'excell',
      'faceId',
    ]),
  }),
  timeOut: z
    .object({
      time: z.number(),
      platform: z.enum([
        'phone',
        'qrCode',
        'companyPhone',
        'companyQr',
        'byAdmin',
        'excell',
        'faceId',
      ]),
    })
    .optional(),
  user: z.string(),
  intervals: z.array(
    z.object({
      enterTime: z.number(),
      leaveTime: z.number(),
      maxMinutes: z.number(),
      totalMinutes: z.number(),
      id: z.number(),
    }),
  ),
})

export const updatePunchclockSchema = z.object({
  punchclockId: z.string().min(1),
  action: z.enum(['timeIn', 'timeOut', 'intervals', 'hours']),
  justify: z.string().optional(),
  intervals: z
    .array(
      z.object({
        enterTime: z.number(),
        leaveTime: z.number(),
      }),
    )
    .optional(),
  time: z.number().optional(),
  hours: z
    .object({
      total: z.object({
        morning: z.number(),
        night: z.number(),
      }),
      required: z.object({
        morning: z.number(),
        night: z.number(),
      }),
      discount: z.object({
        morning: z.number(),
        night: z.number(),
      }),
      extra: z.object({
        morning: z.number(),
        night: z.number(),
        holidayMorning: z.number().optional(),
        holidayNight: z.number().optional(),
        holiday: z.number().optional(),
      }),
      holiday: z.object({
        morning: z.number(),
        night: z.number(),
      }),
    })
    .optional(),
})

export const createPunchclockDefaultValues = {
  date: new Date(),
}
