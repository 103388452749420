import { apiV2 } from '@/services/api'
// import { useMutation } from 'react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdatePayrollFields } from '@/libs/react-query/types/payroll'
dayjs.extend(utc)

const handleUpdatePayrollFields = async (payload: IUpdatePayrollFields) => {
  await apiV2.post<void>('/payroll/update-payroll-fields', payload)
  queryClient.invalidateQueries()
}

export const useHandleUpdatePayrollFields = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdatePayrollFields'],
    handleUpdatePayrollFields,
  )

  return mutation
}
