import { Button, Progress, Text, Tooltip, styled } from '@punto-ui/react'
import {
  ExpandedState,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from '@tanstack/react-table'
import React from 'react'
import { GroupTable } from './components'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { Flex } from '@/components'
import { Container } from './styles'
import { GroupBuilderProvider, useGroupBuilder } from './context'
import { useGroupColumns } from './hooks'
import { useOrganogram } from '@/libs/react-query/hooks'
import { isObject } from '@/utils/object'

const GroupBuilderContent = () => {
  const {
    groups,
    restorePreviousData,
    isLoading: isLoadingTableUpdate,
  } = useGroupBuilder()
  const { isLoading: isLoadingOrganogram, isError: isErrorOrganogram } =
    useOrganogram()

  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  const { data: columns } = useGroupColumns()

  const table = useReactTable({
    data: groups ?? [],
    columns,
    onExpandedChange: setExpanded,
    state: {
      expanded:
        isObject(expanded) && !!groups[0] && typeof expanded !== 'boolean'
          ? {
              ...expanded,
              [groups[0].id]: true,
            }
          : expanded,
    },
    getSubRows: (row) => (row._type === 'group' ? row.children : []),
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  if (isLoadingOrganogram) {
    return (
      <Container
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 60,
        }}
      >
        <Progress />
      </Container>
    )
  }

  if (isErrorOrganogram) {
    return (
      <Container
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 60,
        }}
      >
        <Text variant="paragraph">
          No organigrama disponible para la empresa, habla con un administrador
        </Text>
      </Container>
    )
  }
  return (
    <>
      <Container
        css={{
          position: 'relative',
          overflow: 'scroll',
          overflowX: 'hidden',
          marginLeft: '$4',
          marginRight: '$4',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <GroupTable table={table} />
      </Container>
      <Flex css={{ width: '100%', justifyContent: 'space-between' }}>
        <div>
          <Tooltip message="Proximamente" arrow side={'bottom'}>
            <Button
              disabled={true}
              // disabled={!hasPreviousState}
              css={{ marginTop: 32 }}
              variant="secondary"
              icon={<ArrowUturnLeftIcon />}
              onClick={restorePreviousData}
            >
              Reverter ultima alteracion
            </Button>
          </Tooltip>
        </div>
        {isLoadingTableUpdate && <Progress size="md" />}
      </Flex>
      {isLoadingTableUpdate && <LoadingLayer />}
    </>
  )
}

export const GroupBuilder = () => {
  return (
    <GroupBuilderProvider>
      <GroupBuilderContent />
    </GroupBuilderProvider>
  )
}

const LoadingLayer = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  // height: 10000000,
  backgroundColor: '$interface_light_down',
  // backgroundColor: 'red',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
  opacity: 0.5,
})
