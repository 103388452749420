import { styled, Text } from '@punto-ui/react'

export const NavigatorContainer = styled('div', {
  marginTop: '$6',
  flex: 1,
  overflowY: 'scroll',

  overflowX: 'hidden',

  display: 'flex',
  flexDirection: 'column',

  '&::-webkit-scrollbar': {
    width: 0,
  },
})

export const RouteContainer = styled('div', {
  all: 'unset',
  cursor: 'pointer',
  paddingRight: '0',
  // paddingTop: '$2',
  // paddingBottom: '$2',
  paddingLeft: '$4',
  height: 36,

  '& svg': {
    height: 20,
    width: 20,
    color: '$interface_dark_down',
  },

  [`& ${Text}`]: {
    color: '$interface_dark_down',
  },

  display: 'flex',
  alignItems: 'center',
  gap: '$2',

  borderLeft: '3px solid',
  borderColor: 'transparent',

  transition: 'all 1s',

  variants: {
    isSelected: {
      true: {
        [`& ${Text}`]: {
          color: '$brand_primary_pure',
        },

        '& svg': {
          color: '$brand_primary_pure',
        },

        backgroundColor: '$interface_light_down',
        borderColor: '$brand_primary_pure',
      },
    },
  },
})

export const RouterContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '$2',
  height: 36,
})
