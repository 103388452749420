import { Button, Progress, Text, styled } from '@punto-ui/react'
import {
  ButtonsContainer,
  DomTable,
  TableContainer,
  TableData,
  TableHead,
} from './styles'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Table as ReactTable, flexRender } from '@tanstack/react-table'
import React from 'react'
import { typeDecoder } from '../Forms/ControlledInput/toPattern'
import { CSS } from '@stitches/react'
import { Div } from '../Div'

export type TableProps<T> = {
  table: ReactTable<T>
  isLoading?: boolean
  withPagination?: boolean
  numberOfRows?: number
  totalNumberOfRows?: number
  onRowClick?: (row: T) => void
  css?: CSS
  domTableCss?: CSS
}

export const Table = <T,>({
  table,
  isLoading,
  withPagination = true,
  numberOfRows,
  totalNumberOfRows,
  onRowClick,
  css,
  domTableCss,
}: TableProps<T>) => {
  if (!numberOfRows) {
    return null
  }

  return (
    <TableContainer
      css={{
        ...css,
      }}
    >
      <Div
        css={{
          ...domTableCss,

          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          borderRadius: '$lg',

          position: 'relative',

          overflowX: 'scroll',
        }}
      >
        <DomTable>
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: '$interface_light_pure',
              borderBottom: 'solid 1px $interface_light_deep',
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableHead key={column.id} colSpan={column.colSpan}>
                      {typeof column.column.columnDef.header === 'string' ? (
                        <Text
                          variant="paragraph"
                          css={{
                            textAlign: 'left',
                            fontWeight: 700,
                            ...(column.column.columnDef.meta?.thStyles || {}),
                          }}
                        >
                          {flexRender(
                            column.column.columnDef.header,
                            column.getContext(),
                          )}
                        </Text>
                      ) : (
                        flexRender(
                          column.column.columnDef.header,
                          column.getContext(),
                        )
                      )}
                    </TableHead>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  isClickable={onRowClick !== undefined}
                  onClick={() => (onRowClick ? onRowClick(row.original) : null)}
                >
                  {row.getVisibleCells().map((cell) => {
                    if (
                      !cell.column.columnDef.meta?.isEditable &&
                      cell.column.columnDef.meta?.content !== 'component'
                    ) {
                      return (
                        <TableData key={cell.id}>
                          <Text
                            variant="paragraph"
                            css={{
                              textAlign: 'left',
                              color: '$interface_dark_deep',
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Text>
                        </TableData>
                      )
                    } else if (!cell.column.columnDef.meta?.isEditable) {
                      return (
                        <TableData key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableData>
                      )
                    } else {
                      return (
                        <TableData key={cell.id}>
                          <EditingCell
                            getValue={cell.getValue}
                            row={cell.row}
                            column={cell.column}
                            table={table}
                          />
                        </TableData>
                      )
                    }
                  })}
                </TableRow>
              )
            })}
          </tbody>
        </DomTable>
      </Div>
      {withPagination && (
        <ButtonsContainer>
          <Button
            onClick={() => table.setPageIndex(0)}
            disabled={table.getState().pagination.pageIndex === 0}
          >
            <ChevronDoubleLeftIcon />
          </Button>
          <Button
            onClick={() => table.previousPage()}
            disabled={table.getState().pagination.pageIndex === 0}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon />
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon />
          </Button>
          <span>
            <Text as="strong">
              {table.getState().pagination.pageIndex + 1} de{' '}
              {table.getPageCount()}
            </Text>
          </span>
          {totalNumberOfRows && (
            <span
              style={{
                marginLeft: 'auto',
              }}
            >
              <Text as="strong"> Total: {totalNumberOfRows} </Text>
            </span>
          )}

          {isLoading ? <Progress /> : null}
        </ButtonsContainer>
      )}
    </TableContainer>
  )
}

// Give our default column cell renderer editing superpowers!
const EditingCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue()
  const [value, setValue] = React.useState(initialValue)

  const inputType = column.columnDef.meta?.inputType

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <TableInput
      value={typeDecoder(inputType ?? 'no-mask').input(value)}
      onChange={(e) => {
        const decodedV = typeDecoder(inputType ?? 'no-mask').output(
          e.target.value,
        )
        setValue(decodedV)
        table.options.meta?.updateData(row.index, column.id, decodedV)
      }}
    />
  )
}

const TableInput = styled('input', {
  all: 'unset',
  fontFamily: '$default',
  maxWidth: 75,

  borderRadius: 4,
  padding: 4,

  '&:focus': {
    background: '$interface_light_up',
  },
})

const TableRow = styled('tr', {
  variants: {
    isClickable: {
      true: {
        '&:hover': {
          background: '$interface_light_down !important',
          // boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.1)',
          cursor: 'pointer',
        },
      },
    },
  },
})
