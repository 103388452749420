import {
  ShiftsListContainer,
  StyledContentText,
  WorkersListContainer,
} from './styles'

import React, { useRef } from 'react'
import { Div, PageHeader, Table as TableComponent } from '@/components'
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Filters } from '../components'
import { Box, Button, Checkbox, Menu, Tag } from '@punto-ui/react'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { SmartShift } from '../components/SmartShiftManagement/types'
import { Router } from '@/pages/router'
import { useRouter } from 'next/router'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { useHandleDuplicateShift } from '@/libs/react-query/mutations/shifts/userHandleDuplicate'
import { useCan } from '@/hooks'

export const ShiftsList = () => {
  const { data: shifts, isFetching: isFetchingShifts } = useSmartShifts()

  const shiftsSortedByCode = React.useMemo(() => {
    if (!shifts) return []

    const newShifts = [...shifts]

    return newShifts.sort((a, b) => {
      if (a.shiftCode < b.shiftCode) return -1
      if (a.shiftCode > b.shiftCode) return 1
      return 0
    })
  }, [shifts])

  const columns = React.useMemo<ColumnDef<SmartShift>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        header: 'Código',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.shiftCode,
        id: 'code',
      },
      {
        header: 'Nombre de turno',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.shiftName,
        id: 'name',
      },
      {
        header: 'Status',
        footer: (info) => info.column.id,
        accessorFn: (row) => row.shiftName,
        id: 'status',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tag variant={'positive'}>Activo</Tag>
          </Div>
        ),
      },

      {
        header: () => null,
        cell: (info) => (
          <StyledContentText>
            <DropdownMenu
              id={info.getValue() as any}
              shift={info.row.original}
            />
          </StyledContentText>
        ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.shiftId,
        id: '_id',
        meta: {
          content: 'component',
        },
      },
    ],
    [],
  )

  const table = useReactTable({
    data: shiftsSortedByCode ?? [],
    columns,
    getRowId: (row) => row.shiftId,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <ShiftsListContainer>
      <Filters />
      <Div
        css={{
          marginLeft: '$4',
          marginRight: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 90px)',
        }}
      >
        <WorkersListContainer>
          <TableComponent<SmartShift>
            table={table}
            isLoading={isFetchingShifts}
            numberOfRows={shifts?.length ?? 0}
            withPagination={false}
          />
        </WorkersListContainer>
      </Div>
    </ShiftsListContainer>
  )
}

interface DropdownMenuProps {
  id: string
  shift: SmartShift
}

const DropdownMenu = ({ id, shift }: DropdownMenuProps) => {
  const canCreateShift = useCan(['shifts.create'])
  const canEditShift = useCan(['shifts.edit'])

  const { mutateAsync: handleDuplicateShift } = useHandleDuplicateShift()

  const { setActiveTab, addTab } = useTabStore((state) => ({
    addTab: state.addTab,
    setActiveTab: state.setActiveTab,
  }))

  const remove = useRef<any>(null)

  React.useEffect(() => {
    return () => {
      clearTimeout(remove.current)
    }
  }, [])

  return (
    <Menu
      items={[
        {
          id: '1',
          disabled: !canEditShift,
          label: 'Editar',
          onClick: () => {
            addTab('shifts', [
              {
                id,
                type: 'edit',
                label: shift.shiftName,
              },
            ])
            remove.current = setTimeout(() => {
              setActiveTab('shifts', id)
            }, 1)
          },
        },
        // {
        //   id: '2',
        //   label: 'Ver Detalles',
        //   onClick: () => console.log('Detalles'),
        // },
        {
          id: '3',
          label: 'Duplicar',
          disabled: !canCreateShift && !canEditShift,
          onClick: async () => {
            await handleDuplicateShift(id)
          },
        },
        // {
        //   id: '4',
        //   label: 'Exportar Turno',
        //   onClick: () => console.log('Exportar'),
        // },
        // {
        //   id: '5',
        //   label: 'Arquivar',
        //   onClick: () => console.log('Arquivar'),
        // },
        // {
        //   id: '6',
        //   label: 'Excluir',
        //   onClick: () => console.log('Excluir'),
        // },
      ]}
    />
  )
}

const ShiftsListPage = () => {
  const router = useRouter()

  return (
    <Router>
      <PageHeader title="Turnos" subtitle={'Mira los turnos de tu empresa'}>
        <Button
          variant={'tertiary'}
          onClick={() => router.push('/turnos/create')}
        >
          <PlusIcon />
          Crear
        </Button>
      </PageHeader>
      <ShiftsList />
    </Router>
  )
}

export default ShiftsListPage
