import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import { Progress } from '@punto-ui/react'
import { getUtcDateFromString } from '@/utils/date'
import { useLiquidations } from '@/libs/react-query/hooks/useLiquidations/useLiquidations'
import { IRawLiquidation } from '@/libs/react-query/types/liquidation'
import { shortenName } from '@/utils/workers/name'

export const LiquidationStep = () => {
  const { data: liquidations, isFetching: isFetchingLiquidations } =
    useLiquidations({
      page: 1,
      perPage: 100,
    })

  const methods = useFormContext<ExportPdfSchemaType>()

  const liquidationId = useWatch({
    control: methods.control,
    name: 'liquidationId',
  })

  const selectedLiquidation = liquidations?.data.find(
    (s) => s.id === liquidationId,
  )

  const getLiquidationLabel = (s: IRawLiquidation) => {
    return `${shortenName(s.user?.name)} - ${
      s.inactivation_date
        ? dayjs(getUtcDateFromString(s.inactivation_date)).format('DD/MM/YYYY')
        : 'Sin Fecha de Liquidación'
    } - ${s.status === 'done' ? 'Liquidada' : 'Pendiente'}`
  }

  return (
    <Div>
      <DropdownV2
        value={liquidationId || ''}
        defaultValue={liquidationId || ''}
        onChangeValue={(value) => {
          if (!value.value) {
            return
          }

          methods.setValue('liquidationId', value.value)
          const liquidation = liquidations?.data?.find(
            (s) => s.id === value.value,
          )
          if (liquidation?.user_id) {
            methods.setValue('usersIds', [liquidation?.user_id])
          }
        }}
        defaultOption={{
          label: selectedLiquidation
            ? getLiquidationLabel(selectedLiquidation)
            : 'Selecciona una liquidación',
          value: selectedLiquidation?.id || '',
        }}
        options={
          liquidations?.data?.map((s) => {
            return {
              label: getLiquidationLabel(s),
              value: s.id,
            }
          }) || []
        }
      />
      {isFetchingLiquidations && (
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Progress />
        </Div>
      )}
    </Div>
  )
}
