import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import {
  AguinaldoDetailed,
  UseAguinaldoDetailsPayload,
} from '../../types/aguinaldo'

export const fetchAguinaldo = async (
  aguinaldoId: string,
  cellsIds: string[],
  name: string,
  page: number | undefined,
  perPage: number | undefined,
) => {
  const response = await apiV2.get<AguinaldoDetailed>(
    `/payroll/get-aguinaldo/${aguinaldoId}`,
    {
      params: {
        cellsIds: cellsIds.join(','),
        name,
        page,
        perPage,
      },
    },
  )
  return response.data
}

export const useAguinaldoDetails = (data: UseAguinaldoDetailsPayload) => {
  return useQuery(
    [
      'aguinaldo-details',
      data.aguinaldoId,
      data.cellsIds.join(','),
      data.name,
      data.page,
      data.perPage,
    ],
    () =>
      fetchAguinaldo(
        data.aguinaldoId!,
        data.cellsIds,
        data.name,
        data.page,
        data.perPage,
      ),
    {
      enabled: !!data.aguinaldoId && !!data.cellsIds.length,
    },
  )
}
