// Disable typescript in file
// Opera 8.0+
export const isOpera =
  typeof window !== 'undefined' &&
  ((!!(window as any).opr && !!(window as any).opr.addons) ||
    !!(window as any).opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0)

// Firefox 1.0+
export const isFirefox =
  typeof window !== 'undefined'
    ? typeof (window as any).InstallTrigger !== 'undefined'
    : false

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  typeof window !== 'undefined'
    ? /constructor/i.test((window as any).HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(
        !(window as any).safari ||
          (typeof (window as any).safari !== 'undefined' &&
            (window as any).safari?.pushNotification),
      )
    : false

// Internet Explorer 6-11
export const isIE =
  typeof document !== 'undefined' &&
  /* @cc_on!@ */ (false || !!(document as any).documentMode)

// Edge 20+
export const isEdge =
  typeof window !== 'undefined' && !isIE && !!(window as any).StyleMedia

// Chrome 1 - 71
export const isChrome =
  typeof window !== 'undefined' &&
  !!(window as any).chrome &&
  (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime)

// Blink engine detection
export const isBlink =
  typeof window !== 'undefined'
    ? (isChrome || isOpera) && !!(window as any).CSS
    : false
