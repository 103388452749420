import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const TemplateHubSchema = z.object({
  configurations: z.object({
    name: z.string(),
  }),
})

export type TemplateHubSchemaType = z.infer<typeof TemplateHubSchema>
export type TemplateHubFields = Array<
  ControlledFieldProps<TemplateHubSchemaType>
>
