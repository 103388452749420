import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import {
  IListLiquidationsParams,
  IPaginatedLiquidations,
} from '../../types/liquidation'

const fetchLiquidations = async (data: IListLiquidationsParams) => {
  const response = await apiV2.post<IPaginatedLiquidations>(
    '/company/list-company-liquidations',
    {
      page: data.page,
      perPage: data.perPage,
    },
  )

  return response.data
}

export const useLiquidations = (data: IListLiquidationsParams) => {
  return useQuery(
    ['liquidations', data.page, data.perPage],
    () => fetchLiquidations(data),
    {
      keepPreviousData: true,
    },
  )
}
