import { Text } from '@punto-ui/react'
import { Div } from '../Div'

const pulseAnimation = {
  '0%': { opacity: 1 },
  '50%': { opacity: 0.7 },
  '100%': { opacity: 1 },
}

export const ProgressBar = ({
  progressInPercent,
  text,
}: {
  progressInPercent: number
  text: string
}) => {
  return (
    <Div
      css={{
        width: 200,
        height: 20,
        borderRadius: '$md',
        background: '$interface_light_up',
        position: 'relative',
        overflow: 'hidden', // Add this to ensure the inner bar doesn't overflow
      }}
    >
      <Div
        css={{
          width: `${progressInPercent}%`,
          borderTopLeftRadius: '$md',
          borderBottomLeftRadius: '$md',
          borderTopRightRadius: progressInPercent === 100 ? '$md' : 0,
          borderBottomRightRadius: progressInPercent === 100 ? '$md' : 0,
          background: '$brand_primary_down',
          height: 20,
          transition: 'width 0.3s ease-in-out',
          animation:
            progressInPercent === 100
              ? `${pulseAnimation} 1s infinite`
              : 'none',
        }}
      />

      <Div
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text
          variant="caption"
          css={{
            fontWeight: '$bold',
            color: '$interface_dark_deep',
          }}
        >
          {text}
        </Text>
      </Div>
    </Div>
  )
}
