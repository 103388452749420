import { Div } from '@/components'
import { ProgressBar } from '@/components/ProgressBar'
import { useDownload } from '@/contexts/DownloadContext'
import { IDocumentsStateSchema } from '@/pages/documents/documents-state'
import {
  ArrowDownTrayIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline'
import { Button, Progress, Text } from '@punto-ui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export const DocumentImageViewer = ({
  url,
  name,
}: {
  url?: string
  name?: string
}) => {
  const methods = useFormContext<IDocumentsStateSchema>()
  const { downloadFile } = useDownload()
  const [scale, setScale] = useState(1)
  const isFullScreen = useWatch({
    control: methods.control,
    name: 'management.documentView.isFullscreen',
  })

  const [loadingProgress, setLoadingProgress] = useState<number>(0)
  const [imageData, setImageData] = useState<string | null>(null)

  useEffect(() => {
    let isMounted = true
    let imageUrl: string | null = null

    const fetchImage = async () => {
      if (url) {
        try {
          const response = await axios.get(url, {
            responseType: 'arraybuffer',
            onDownloadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                )
                setLoadingProgress(percentCompleted)
              }
            },
          })

          if (isMounted) {
            const blob = new Blob([response.data], { type: 'image/jpeg' })
            imageUrl = URL.createObjectURL(blob)
            setImageData(imageUrl)
          }
        } catch (error) {
          console.error('Error fetching image:', error)
        }
      }
    }

    fetchImage()

    return () => {
      isMounted = false
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl)
      }
    }
  }, [url])

  return (
    <Div>
      <Div
        css={{
          height: isFullScreen ? '90vh' : 600,
          maxWidth: isFullScreen ? '100vw' : '100%',
          overflow: 'auto',
          backgroundColor: '$brand_primary_up',
        }}
      >
        {loadingProgress < 100 ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <ProgressBar
              progressInPercent={loadingProgress}
              text={`Loading: ${loadingProgress}%`}
            />
          </Div>
        ) : imageData ? (
          <Div
            css={{
              height: `${100 * scale}%`,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <img
              src={imageData}
              alt={name || 'Document image'}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
              }}
            />
          </Div>
        ) : (
          <Progress />
        )}
      </Div>
      <Div
        css={{
          background: '$interface_light_up',
          height: 48,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <Button
            variant="secondary"
            disabled={scale <= 0.5}
            onClick={() =>
              setScale((prevScale) => Math.max(prevScale - 0.1, 0.5))
            }
          >
            <MagnifyingGlassMinusIcon />
          </Button>
          <Button
            variant="secondary"
            onClick={() => setScale((prevScale) => prevScale + 0.1)}
          >
            <MagnifyingGlassPlusIcon />
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              methods.setValue(
                'management.documentView.isFullscreen',
                !isFullScreen,
              )
            }}
          >
            {isFullScreen ? (
              <ArrowsPointingInIcon />
            ) : (
              <ArrowsPointingOutIcon />
            )}
          </Button>
        </Div>
        <Div>
          <Button
            onClick={() => {
              if (url) {
                downloadFile(url, name)
              }
            }}
          >
            <ArrowDownTrayIcon />
            Descargar
          </Button>
        </Div>
      </Div>
    </Div>
  )
}
