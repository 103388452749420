import { z } from 'zod'

export const paymentSchema = z.object({
  title: z.string().min(1, 'Titulo es requerido'),
  description: z.string().optional(),
  payment_type: z.string(),
  payroll_label: z.string(),

  payment_date: z.array(z.date().nullable()),
  value: z.number(),
  value_type: z.string(),

  period_type: z.enum(['range', 'multiple']),
  dates: z.array(z.date().nullable()),
  usersIds: z.string({ required_error: 'Selecione 1 colaborador' }).array(),

  users: z.array(z.object({ id: z.string(), ips: z.boolean() })),

  id: z.string(),
  ips: z.boolean().optional(),
  discount_ips9: z.boolean().optional(),

  filters: z
    .object({
      name: z.string().optional(),
      cellsIds: z.string().array().optional(),
      dates: z.array(z.date().nullable()).optional(),
      categories: z.array(z.string()).optional(),
    })
    .optional(),
})

export const createPaymentBatchSchema = z.object({
  id: z.string().optional(),
  name: z.string({ required_error: 'Nombre es requerido' }),
  description: z.string().optional(),

  payments: z.array(paymentSchema),
})

export type CreatePaymentBatchPayloadType = z.infer<
  typeof createPaymentBatchSchema
>

export type IPaymentSchema = z.infer<typeof paymentSchema>
