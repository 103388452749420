import { useQuery } from 'react-query'
import { useMe } from '..'
import { apiV2 } from '@/services/api'
import { ICompanyBusinessWithInvoice } from '../../types/company'
import dayjs from 'dayjs'

const getCompaniesInvoices = async (props: { date: string }) => {
  try {
    const response = await apiV2.post<ICompanyBusinessWithInvoice[]>(
      '/admin/list-invoices',
      {
        date: props.date,
      },
    )

    return response.data
  } catch (error) {
    console.log('[ERROR FETCH COMPANIES]', error)
  }
}

export const useCompaniesInvoices = (props: { date: string | undefined }) => {
  const { data: me } = useMe()

  const query = useQuery(
    [
      'companies',
      'invoices',
      props.date,
      dayjs(props.date).month(),
      dayjs(props.date).year(),
    ],
    async () =>
      await getCompaniesInvoices({
        date: props.date!,
      }),
    {
      enabled: !!me && !!props.date,
    },
  )

  return query
}
