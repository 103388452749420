import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from '@tanstack/react-table'
import { Table } from './components/Table'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useSmartTableColumns } from './columns'
import { SmartTableFormSchemaType, SmartTableProps } from './types'
import React, { useEffect } from 'react'

const TableMemo = React.memo(Table)

const SmartTableNonMemo = ({
  columns,
  style,
  uncontrolled,
  data,
  noPadding,
  withPagination,
  paginationProps,
  selectedIndex,
  defaultColumnOrder,
  defaultHiddenColumns,
  defaultLeftFixedColumns,
  defaultRightFixedColumns,
  maxDepth,
  areColumnsDraggable,
  saveColumnsOrderCallback,
  saveHiddenColumnsCallback,
  saveLeftFixedColumnsCallback,
  saveRightFixedColumnsCallback,
  resetColumnOrder,
  subRowName,
}: SmartTableProps) => {
  const [columnOrders, setColumnOrders] = React.useState<string[]>(
    defaultColumnOrder || [],
  )
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(
    defaultHiddenColumns || [],
  )
  const [leftFixedColumns, setLeftFixedColumns] = React.useState<string[]>(
    defaultLeftFixedColumns || [],
  )
  const [rightFixedColumns, setRightFixedColumns] = React.useState<string[]>(
    defaultRightFixedColumns || [],
  )

  useEffect(() => {
    if (!columnOrders.length && (resetColumnOrder || defaultColumnOrder)) {
      setColumnOrders(resetColumnOrder || defaultColumnOrder || [])
    }

    if (saveColumnsOrderCallback) {
      saveColumnsOrderCallback(columnOrders)
    }
  }, [columnOrders])

  useEffect(() => {
    if (saveHiddenColumnsCallback) {
      saveHiddenColumnsCallback(hiddenColumns)
    }
  }, [hiddenColumns])

  useEffect(() => {
    if (saveLeftFixedColumnsCallback) {
      saveLeftFixedColumnsCallback(leftFixedColumns)
    }
  }, [leftFixedColumns])

  useEffect(() => {
    if (saveRightFixedColumnsCallback) {
      saveRightFixedColumnsCallback(rightFixedColumns)
    }
  }, [rightFixedColumns])

  if (!uncontrolled || !data) {
    return (
      <ControlledSmartTable
        columns={columns}
        style={style}
        withPagination={withPagination}
        paginationProps={paginationProps}
        columnOrders={columnOrders}
        setColumnOrders={setColumnOrders}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        maxDepth={maxDepth}
        areColumnsDraggable={areColumnsDraggable}
        leftFixedColumns={leftFixedColumns}
        rightFixedColumns={rightFixedColumns}
        setLeftFixedColumns={setLeftFixedColumns}
        setRightFixedColumns={setRightFixedColumns}
        subRowName={subRowName}
      />
    )
  }

  return (
    <UncontrolledSmartTable
      setLeftFixedColumns={setLeftFixedColumns}
      setRightFixedColumns={setRightFixedColumns}
      withPagination={withPagination}
      paginationProps={paginationProps}
      noPadding={noPadding}
      columns={columns}
      style={style}
      data={data}
      selectedIndex={selectedIndex}
      columnOrders={columnOrders}
      hiddenColumns={hiddenColumns}
      setHiddenColumns={setHiddenColumns}
      setColumnOrders={setColumnOrders}
      areColumnsDraggable={areColumnsDraggable}
      maxDepth={maxDepth}
      subRowName={subRowName}
    />
  )
}

const UncontrolledSmartTable = ({
  columns,
  style,
  data: rawData,
  noPadding,
  withPagination,
  selectedIndex,
  paginationProps,
  maxDepth,
  areColumnsDraggable,
  columnOrders,
  setColumnOrders,
  hiddenColumns,
  setHiddenColumns,
  leftFixedColumns,
  rightFixedColumns,
  setLeftFixedColumns,
  setRightFixedColumns,
}: SmartTableProps & {
  columnOrders: string[]
  setColumnOrders: React.Dispatch<React.SetStateAction<string[]>>
  hiddenColumns: string[]
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>
  leftFixedColumns?: string[]
  rightFixedColumns?: string[]
  setLeftFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
  setRightFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: paginationProps?.page || 0,
      pageSize: paginationProps ? paginationProps.perPage : 20,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const methods = useForm<SmartTableFormSchemaType>({
    defaultValues: makeDefaultTableFromData(rawData!),
  })

  const { fields: data } = useFieldArray({
    name: 'data',
    control: methods.control,
  })

  useEffect(() => {
    methods.setValue('data', makeDefaultTableFromData(rawData!).data)
  }, [rawData])

  const { columns: tableColumns, orderedColumns } = useSmartTableColumns(
    columns || [],
    columnOrders,
    hiddenColumns,
    maxDepth,
  )
  const table = useReactTable({
    data: data ?? [],
    columns: tableColumns,
    pageCount: paginationProps?.totalPages,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: paginationProps ? setPagination : undefined,
    state: paginationProps
      ? {
          pagination,
        }
      : undefined,
    manualPagination: !!paginationProps,
  })

  return (
    <FormProvider {...methods}>
      <TableMemo
        setLeftFixedColumns={setLeftFixedColumns}
        setRightFixedColumns={setRightFixedColumns}
        selectedIndex={selectedIndex}
        noPadding={noPadding}
        styles={style}
        columns={orderedColumns}
        areColumnsDraggable={areColumnsDraggable}
        table={table}
        withPagination={withPagination}
        numberOfRows={data.length}
        setColumnOrders={setColumnOrders}
        setHiddenColumns={setHiddenColumns}
        allRawColumns={columns}
        hiddenColumns={hiddenColumns}
      />
    </FormProvider>
  )
}

export const makeDefaultTableFromData = (
  arrayValues: Record<string, any>[],
) => {
  const finalResult: Record<string, any>[] = []

  arrayValues.forEach((data) => {
    const result: Record<string, any> = {}

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        result[key] = value.map((v) => ({
          value: v,
          original: v,
          status: '',
        }))
      } else {
        result[key] = {
          value,
          original: value,
          status: '',
        }
      }
    })

    finalResult.push(result)
  })

  return { data: finalResult }
}

export const ControlledSmartTable = ({
  columns,
  style,
  withPagination,
  paginationProps,
  maxDepth,
  areColumnsDraggable,
  columnOrders,
  setColumnOrders,
  hiddenColumns,
  setHiddenColumns,
  leftFixedColumns,
  rightFixedColumns,
  setLeftFixedColumns,
  setRightFixedColumns,
  subRowName,
}: SmartTableProps & {
  columnOrders: string[]
  setColumnOrders: React.Dispatch<React.SetStateAction<string[]>>
  hiddenColumns: string[]
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>
  leftFixedColumns?: string[]
  rightFixedColumns?: string[]
  setLeftFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
  setRightFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: paginationProps?.page || 0,
      pageSize: paginationProps ? paginationProps.perPage : 20,
    })
  useEffect(() => {
    if (paginationProps) {
      paginationProps.onChange({
        pageIndex,
        pageSize,
      })
    }
  }, [pageIndex, pageSize])

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const { control } = useFormContext<SmartTableFormSchemaType>()

  // const data = useWatch({
  //   name: 'data',
  //   control,
  // })

  const { fields: data } = useFieldArray({
    name: 'data',
    control,
  })

  // const data = useWatch({
  //   control,
  //   name: 'data',
  // })

  // const expanded = useMemo(() => {
  // console.log('RELOADING MEMO')
  // }, [data])
  const expanded = data.reduce((acc, row) => {
    if (row.expandable?.value && row.id) {
      acc[row.id] = true
    }

    return acc
  }, {})

  const { columns: tableColumns, orderedColumns } = useSmartTableColumns(
    columns || [],
    columnOrders,
    hiddenColumns,
    maxDepth || 1,
    leftFixedColumns,
    rightFixedColumns,
  )

  // console.log('tableColumns', tableColumns, columnOrders)
  // console.log(
  //   'orderedColumns',
  //   orderedColumns,
  //   leftFixedColumns,
  //   rightFixedColumns,
  // )

  // console.log('expanded', data, expanded)

  const table = useReactTable({
    data: data ?? [],
    columns: tableColumns,
    getRowId: (row) => row.id,
    getSubRows: (row) => (subRowName ? row[subRowName] : []),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange: paginationProps ? setPagination : undefined,
    pageCount: paginationProps?.totalPages,
    state: paginationProps
      ? {
          pagination,
          expanded,
        }
      : undefined,
    manualPagination: !!paginationProps,
  })

  return (
    <>
      <Table
        paginationProps={paginationProps}
        styles={style}
        columns={orderedColumns}
        table={table}
        withPagination={withPagination}
        numberOfRows={table.getRowModel().rows.length}
        setColumnOrders={setColumnOrders}
        setHiddenColumns={setHiddenColumns}
        areColumnsDraggable={areColumnsDraggable}
        allRawColumns={columns}
        hiddenColumns={hiddenColumns}
        maxDepth={maxDepth}
        leftFixedColumns={leftFixedColumns}
        rightFixedColumns={rightFixedColumns}
        setLeftFixedColumns={setLeftFixedColumns}
        setRightFixedColumns={setRightFixedColumns}
        subRowName={subRowName}
      />
    </>
  )
}

export const SmartTable = React.memo(SmartTableNonMemo)
