import { queryClient } from '@/libs/react-query'
import { IReport, IRequestReport } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleRequestReport = async (data: IRequestReport) => {
  const response = await apiV2.post<IReport>(
    `/report-builder/request-report-generation`,
    data,
  )
  queryClient.invalidateQueries('reports')

  return response.data
}

export const useHandleRequestReport = () => {
  const mutation = useCustomMutation(
    ['useHandleRequestReport'],
    async (data: IRequestReport) => {
      const report = await handleRequestReport(data)

      return report
    },
  )

  return mutation
}
