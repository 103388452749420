export const TxtIcon = (props: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="2" fill="#1C40FC" />
      <path
        d="M5.7 13V8.344H4.596V7.496H7.708V8.344H6.612V13H5.7ZM10.6525 13L9.7965 10.928H9.7405L8.9005 13H7.9245L9.1725 10.232L7.9405 7.496H8.9405L9.7725 9.504H9.8285L10.6685 7.496H11.6285L10.4205 10.208L11.6525 13H10.6525ZM12.9656 13V8.344H11.8616V7.496H14.9736V8.344H13.8776V13H12.9656Z"
        fill="white"
      />
    </svg>
  )
}
