import {
  ControlledFilterBar,
  Div,
  SmartTable,
  useSmartTableColumnManipulation,
} from '@/components'
import { useCompanies } from '@/libs/react-query/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CompanyListSchema, CompanyListSchemaType } from './types'
import { useEffect, useState } from 'react'
import { BusinessCompanyInfoStatus } from '@/libs/react-query/types/company'
import { parseCompanyList } from './parser'
import { useHandleCompanyListActions } from './actions'
import { useCompanyListColumns } from './columns'
import { Dialog, DialogContainer } from '@punto-ui/react'
import { CreateCompanyDialog } from './CreateCompanyDialog'

export const CompanyList = ({
  notInStatus,
  inStatus,
}: {
  notInStatus?: BusinessCompanyInfoStatus[]
  inStatus?: BusinessCompanyInfoStatus[]
}) => {
  const [isCreateCompanyOpen, setIsCreateCompanyOpen] = useState(false)
  const { data: companies, isFetching: isFetchingCompanies } = useCompanies()

  const methods = useForm<CompanyListSchemaType>({
    resolver: zodResolver(CompanyListSchema),
    defaultValues: {
      companyName: '',
      data: [],
    },
  })

  const companyName = useWatch({
    control: methods.control,
    name: 'companyName',
  })

  useEffect(() => {
    if (!companies?.length) {
      return
    }

    const correctCompanies = companies
      .filter((company) => {
        let isValidName = false

        if (companyName?.length) {
          isValidName = (company.name as string)
            .toLowerCase()
            .includes(companyName.toLowerCase())
        } else {
          isValidName = true
        }

        if (notInStatus?.length && company.businessInfos?.status) {
          return (
            !notInStatus.includes(company.businessInfos.status) && isValidName
          )
        }

        if (inStatus?.length && company.businessInfos?.status) {
          return inStatus.includes(company.businessInfos.status) && isValidName
        }

        return isValidName
      })
      .sort((a, b) => {
        return (b.activeEmployees || 0) - (a.activeEmployees || 0)
      })

    const data = parseCompanyList(correctCompanies)

    methods.reset({
      data,
      companyName,
    })
  }, [companies, companyName])

  const { data: columns } = useCompanyListColumns({
    methods,
  })

  useHandleCompanyListActions({
    setOpenCompanyCreation: setIsCreateCompanyOpen,
  })

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'activeCompaniesColumnsOrder',
    tableHiddenUniqueName: 'activeCompaniesHiddenColumns',
    tableLeftFixedUniqueName: 'activeCompaniesLeftFixedColumns',
    tableRightFixedUniqueName: 'activeCompaniesRightFixedColumns',

    defaultLeftFixedColumns: ['download', 'count', 'name'],
  })

  return (
    <DialogContainer
      open={isCreateCompanyOpen}
      onOpenChange={setIsCreateCompanyOpen}
    >
      <FormProvider {...methods}>
        <ControlledFilterBar
          nameFilterName="companyName"
          policiesIds={[]}
          isLoading={isFetchingCompanies}
        />
        <Div
          css={{
            flex: 1,
            padding: '$4',

            position: 'relative',
            height: 'calc(100vh - 90px)',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '$4',
            paddingRight: '$4',
          }}
        >
          <SmartTable
            style={{
              borderInLines: false,
            }}
            columns={columns}
            areColumnsDraggable
            defaultColumnOrder={defaultColumnsOrder}
            defaultHiddenColumns={defaultHiddenColumns}
            saveColumnsOrderCallback={saveColumnsOrderCallback}
            saveHiddenColumnsCallback={saveHiddenColumnsCallback}
            resetColumnOrder={resetStateColumnOrder}
            maxDepth={2}
            defaultLeftFixedColumns={defaultLeftFixedColumns}
            defaultRightFixedColumns={defaultRightFixedColumns}
            saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
            saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
          />
        </Div>

        <Dialog
          overlayCss={{
            zIndex: 99,
          }}
          containerCss={{
            zIndex: 100,
            padding: 0,
          }}
          content={
            <CreateCompanyDialog
              handleCloseDialog={() => setIsCreateCompanyOpen(false)}
            />
          }
        />
      </FormProvider>
    </DialogContainer>
  )
}
