import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { InactiveWorkerDTO } from '../../types/worker'
import { PayrollListing } from '../../types/payroll'

export const fetchWorkerLiquidation = async ({
  userId,
  liquidationId,
}: {
  userId: string
  liquidationId?: string
}) => {
  const response = await apiV2.post<{
    result: InactiveWorkerDTO
    payrolls: PayrollListing[]
  }>(`/company/v2/get-liquidation-details`, {
    user_id: userId,
    liquidation_id: liquidationId,
  })

  return response.data
}

export const useWorkerLiquidation = ({
  userId,
  liquidationId,
}: {
  userId: string
  liquidationId?: string
}) => {
  const query = useQuery(
    ['worker-liquidation', userId, liquidationId],
    async () => {
      const workers = await fetchWorkerLiquidation({
        userId,
        liquidationId,
      })

      return workers
    },
    {
      keepPreviousData: true,
      enabled: !!userId,
    },
  )

  return query
}
