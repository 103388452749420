import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { usePaymentGroupsColumns } from './table'
import { IPaymentConfigGroup } from '@/libs/react-query/types/company'
import { PaymentConfigDrawer } from './components/PaymentConfigDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { formatMinutes } from '@/utils/date'
import { useCan } from '@/hooks'

interface PaymentGroupDrawerState {
  isOpen: boolean
  selectedPaymentConfig: IPaymentConfigGroup | null
}

export const PaymentConfigurationGroups = () => {
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()
  const canCreatePayment = useCan(['configurations.payments'])
  const [drawerState, setDrawerState] = React.useState<PaymentGroupDrawerState>(
    {
      isOpen: false,
      selectedPaymentConfig: null,
    },
  )

  const columns = usePaymentGroupsColumns()

  const handleCreatePaymentConfig = () => {
    setValue('payment', {
      currency: 'PYG',
      name: '',
      hours_morning_extra_value: 100,
      hours_morning_holiday_value: 100,
      hours_morning_ordinary_value: 100,
      hours_night_extra_value: 100,
      hours_night_holiday_value: 100,
      hours_night_ordinary_value: 100,
      is_daily_payment: false,
      is_subscribed_ips: false,
      number_hours_per_day: '08:00',
      should_consider_company_holidays: false,
      should_consider_presence_default: false,
      should_include_morning_extra_in_ips: false,
      should_include_morning_holiday_in_ips: false,
      should_include_morning_hours_discount_in_ips: false,
      should_include_night_extra_in_ips: false,
      should_include_night_holiday_in_ips: false,
      should_include_night_hours_discount_in_ips: false,
      should_include_night_ordinary_in_ips: false,
      should_include_ordinary_salary_in_night_hours: false,
      should_pay_as_natural_days: false,
      should_pay_children_bonus: false,
      should_include_children_bonus_in_ips: false,
      should_include_payments_in_ips: false,
      should_consider_31_days_month_partially_natural: false,
      should_pay_hourly: false,

      should_authorize_night_hours: false,
      should_authorize_extra_morning_hours: false,
      should_authorize_extra_night_hours: false,
      should_authorize_holiday_morning_hours: false,
      should_authorize_holiday_night_hours: false,
      should_authorize_morning_hours_discount: false,
      should_authorize_night_hours_discount: false,
    })
    setDrawerState({
      isOpen: true,
      selectedPaymentConfig: null,
    })
  }

  const handleSelectPaymentConfig = (row: IPaymentConfigGroup) => {
    setValue('payment', {
      id: row.id,
      currency: row.currency,
      name: row.name,
      hours_morning_extra_value: row.hours_morning_extra_value,
      hours_morning_holiday_value: row.hours_morning_holiday_value,
      hours_morning_ordinary_value: row.hours_morning_ordinary_value,
      hours_night_extra_value: row.hours_night_extra_value,
      hours_night_holiday_value: row.hours_night_holiday_value,
      hours_night_ordinary_value: row.hours_night_ordinary_value,
      is_daily_payment: row.is_daily_payment,
      is_subscribed_ips: row.is_subscribed_ips,
      number_hours_per_day: formatMinutes(row.number_hours_per_day * 60),
      should_consider_company_holidays: row.should_consider_company_holidays,
      should_consider_presence_default: row.should_consider_presence_default,
      should_include_morning_extra_in_ips:
        row.should_include_morning_extra_in_ips,
      should_include_morning_holiday_in_ips:
        row.should_include_morning_holiday_in_ips,
      should_include_morning_hours_discount_in_ips:
        row.should_include_morning_hours_discount_in_ips,
      should_include_night_extra_in_ips: row.should_include_night_extra_in_ips,
      should_include_night_holiday_in_ips:
        row.should_include_night_holiday_in_ips,
      should_include_night_hours_discount_in_ips:
        row.should_include_night_hours_discount_in_ips,
      should_include_night_ordinary_in_ips:
        row.should_include_night_ordinary_in_ips,
      should_include_ordinary_salary_in_night_hours:
        row.should_include_ordinary_salary_in_night_hours,
      should_pay_as_natural_days: row.should_pay_as_natural_days,
      should_pay_children_bonus: row.should_pay_children_bonus,
      should_include_children_bonus_in_ips:
        !!row.should_include_children_bonus_in_ips,
      should_include_payments_in_ips: !!row.should_include_payments_in_ips,
      should_consider_31_days_month_partially_natural:
        !!row.should_consider_31_days_month_partially_natural,
      should_authorize_night_hours: !!row.should_authorize_night_hours,
      should_authorize_extra_morning_hours:
        !!row.should_authorize_extra_morning_hours,
      should_authorize_extra_night_hours:
        !!row.should_authorize_extra_night_hours,
      should_authorize_holiday_morning_hours:
        !!row.should_authorize_holiday_morning_hours,
      should_authorize_holiday_night_hours:
        !!row.should_authorize_holiday_night_hours,
      should_authorize_morning_hours_discount:
        !!row.should_authorize_morning_hours_discount,
      should_authorize_night_hours_discount:
        !!row.should_authorize_night_hours_discount,
      should_pay_hourly: !!row.should_pay_hourly,
    })
    setDrawerState({
      isOpen: true,
      selectedPaymentConfig: row,
    })
  }

  const table = useReactTable({
    data: company?.paymentConfigGroups ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del grupo" placeholder="Nombre del grupo" />
        {canCreatePayment && (
          <Button onClick={() => handleCreatePaymentConfig()}>
            Crear configuración de pagos
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<IPaymentConfigGroup>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectPaymentConfig}
        />
      </Div>

      <PaymentConfigDrawer
        isCreation={drawerState.selectedPaymentConfig === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedPaymentConfig: null,
          })
        }
      />
    </Div>
  )
}
