interface Attempt {
  value: string
  attempts: number
}

interface AttemptsDTO {
  companyId?: Attempt
  firstName?: Attempt
  email?: Attempt
}

const MAXIMUM_ALLOWED_ATTEMPTS = 10

class FreshChat {
  private attempts: AttemptsDTO

  constructor() {
    this.attempts = {}
  }

  buildScript() {
    if (typeof window === 'undefined') {
      return ''
    }

    return `
    function initFreshChat() {
      window.fcWidget.init({
           token: "81e24889-b299-4d7e-83d2-3d9fcedd4d49",
     host: "https://puntook-help.freshchat.com"
      });
    }
    function initialize(i,t){var e;i.getElementById(t)?
    initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
    e.src="https://puntook-help.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
    function initiateCall(){initialize(document,"Freshchat-js-sdk")}
    window.addEventListener?window.addEventListener("load",initiateCall,!1):
    window.attachEvent("load",initiateCall,!1);
  `
  }

  setCompanyId(id: string) {
    const attemptingSameCompany = this.attempts.companyId?.value === id
    const maximumAttemps =
      (this.attempts.companyId?.attempts || 0) > MAXIMUM_ALLOWED_ATTEMPTS

    if (!window.fcWidget && attemptingSameCompany && !maximumAttemps) {
      setTimeout(() => {
        this.setCompanyId(id)

        if (this.attempts.companyId) {
          this.attempts.companyId.attempts += 1
        } else {
          this.attempts.companyId = {
            value: id,
            attempts: 1,
          }
        }
      }, 1500)
    } else if (window.fcWidget) {
      window.fcWidget?.setExternalId(id)
    }
  }

  setFirstName(firstName: string) {
    const attemptingSameName = this.attempts.firstName?.value === firstName
    const maximumAttemps =
      (this.attempts.firstName?.attempts || 0) > MAXIMUM_ALLOWED_ATTEMPTS

    if (!window.fcWidget && attemptingSameName && !maximumAttemps) {
      setTimeout(() => {
        this.setFirstName(firstName)

        if (this.attempts.firstName) {
          this.attempts.firstName.attempts += 1
        } else {
          this.attempts.firstName = {
            value: firstName,
            attempts: 1,
          }
        }
      }, 1500)
    } else if (window.fcWidget) {
      window.fcWidget?.user.setFirstName(firstName)
    }
  }

  setEmail(email: string) {
    const attemptingSameEmail = this.attempts.email?.value === email
    const maximumAttemps =
      (this.attempts.email?.attempts || 0) > MAXIMUM_ALLOWED_ATTEMPTS

    if (typeof window === 'undefined') {
      return
    }

    if (!window.fcWidget && attemptingSameEmail && !maximumAttemps) {
      setTimeout(() => {
        this.setEmail(email)

        if (this.attempts.email) {
          this.attempts.email.attempts += 1
        } else {
          this.attempts.email = {
            value: email,
            attempts: 1,
          }
        }
      }, 1500)
    } else if (window.fcWidget) {
      window.fcWidget?.user.setEmail(email)
    }
  }
}

const freshChatService = new FreshChat()

export { freshChatService }
