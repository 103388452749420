import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { ICreateFolder } from '@/libs/react-query/types/documents'

const handleCreateFolder = async (data: ICreateFolder) => {
  try {
    await apiV2.post<void>(`/documents/create-folder`, {
      ...data,
    })
    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}

export const useHandleCreateFolder = () => {
  const mutation = useCustomMutation(
    ['useHandleCreateFolder'],
    handleCreateFolder,
  )

  return mutation
}
