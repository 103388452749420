import { UserRequest } from '@/libs/react-query/types/requests'
import { RequestsInfosContentType } from './form'
import dayjs from 'dayjs'

export const fromRequestStatusToStatusLabel = (status: string) => {
  switch (status) {
    case 'pending':
      return 'Pendiente'
    case 'accepted':
      return 'Autorizado'
    case 'rejected':
      return 'Rechazado'
    default:
      return ''
  }
}

export const fromRequestStatusToTagVariant = (status: string) => {
  switch (status) {
    case 'pending':
      return 'warning'
    case 'accepted':
      return 'positive'
    case 'rejected':
      return 'negative'
    default:
      return ''
  }
}

export const fromRequestModuleToLabel = (module: string) => {
  switch (module) {
    case 'vacation':
      return 'Vacaciones'
    case 'movement':
      return 'Movimiento'
    case 'payments':
      return 'Pago'
    default:
      return ''
  }
}

export const fromRequestTypeToLabel = (type: string) => {
  switch (type) {
    case 'create':
      return 'Creación'
    case 'update':
      return 'Actualización'
    default:
      return ''
  }
}

export const parseUserRequestToRequestListing = (
  usersRequest: UserRequest[],
): RequestsInfosContentType[] => {
  return usersRequest.map((userRequest) => {
    const { requestedUser, ...request } = userRequest
    return {
      id: request.id,
      canAuthorizeRequest: request.canAuthorizeRequest,
      rawStatus: request.status,
      request_data: request.data,

      authorization_date: {
        value: request.authorized_at
          ? dayjs(request.authorized_at).format('DD/MM/YYYY HH:mm')
          : '-',
        status: '',
      },
      avatar: {
        url: requestedUser.photo_url || '',
        alt: requestedUser.name,
      },
      checked: {
        value: false,
      },
      module: {
        value: fromRequestModuleToLabel(request.module),
        original: request.module,
        status: '',
      },
      name_authorizer: {
        value: '-',
        status: '',
      },
      name_requester: {
        value: requestedUser?.name,
        status: '',
      },
      status: [
        {
          id: '1',
          name: fromRequestStatusToStatusLabel(userRequest.status as string),
          variant:
            (fromRequestStatusToTagVariant(
              userRequest.status as string,
            ) as string) || 'neutral',
        },
      ],
      type: {
        value: fromRequestTypeToLabel(request.type),
        status: '',
      },
      request_date: {
        value: dayjs(request.created_at).format('DD/MM/YYYY HH:mm'),
        status: '',
      },
    }
  })
}
