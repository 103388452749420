import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ConfigFields } from '../../..'
import { useMemo } from 'react'
import { useCan } from '@/hooks'

export const useGroupInfo = () => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'input',
      label: 'Nombre del Grupo de Configuración',
      name: 'liquidation.name',
      disabled: !canCreateLiquidation,
    },
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Usar fecha IPS',
      name: 'liquidation.should_use_ips_date',
    },
    {
      _type: 'switch',
      label: 'Utilizar salario promedio para calculo de vacaciones',
      disabled: !canCreateLiquidation,
      name: 'liquidation.should_use_average_salary_in_vacations',
    },
    {
      _type: 'switch',
      label: 'Utilizar salario promedio para calculo de salario proporcional',
      disabled: !canCreateLiquidation,
      name: 'liquidation.should_use_average_salary_in_proportional_salary',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useLiquidationGroupConcepts = () => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])
  const { data: company } = useMyCompany()

  const orderedVacationConcepts = useMemo(() => {
    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const groupInfoForm: ConfigFields = orderedCompanyConcepts.map(
      (c, index) => {
        return {
          _type: 'switch',
          disabled: !canCreateLiquidation,
          label: c,
          name: `liquidation.concepts.${index}`,
        }
      },
    )

    return groupInfoForm
  }, [company, canCreateLiquidation])

  return {
    data: orderedVacationConcepts,
  }
}

export const useLiquidationHoursConfiguration = () => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Ordinárias Nocturnas',
      name: 'liquidation.ordinaryNightHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Extras Diurnas',
      name: 'liquidation.extraMorningHours',
    },
    {
      _type: 'switch',
      label: 'Extras Nocturnas',
      name: 'liquidation.extraNightHours',
      disabled: !canCreateLiquidation,
    },
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Feriado Diurnas',
      name: 'liquidation.holidayMorningHours',
    },
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Feriado Nocturnas',
      name: 'liquidation.holidayNightHours',
    },
    {
      _type: 'switch',
      label: 'Descuento Diurno',
      name: 'liquidation.discountMorningHours',
      disabled: !canCreateLiquidation,
    },
    {
      _type: 'switch',
      label: 'Descuento Nocturno',
      disabled: !canCreateLiquidation,
      name: 'liquidation.discountNightHours',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useLiquidationEarningsConfiguration = () => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Vacaciones',
      name: 'liquidation.vacation_value',
    },
    {
      _type: 'switch',
      label: 'Bonificación Familiar',
      disabled: !canCreateLiquidation,
      name: 'liquidation.familiarBonus',
    },
    {
      _type: 'switch',
      label: 'Movilidad',
      disabled: !canCreateLiquidation,
      name: 'liquidation.mobility',
    },
    {
      _type: 'switch',
      label: 'Bonos',
      disabled: !canCreateLiquidation,
      name: 'liquidation.bonus',
    },
    {
      _type: 'switch',
      label: 'Comisión',
      name: 'liquidation.commission',
      disabled: !canCreateLiquidation,
    },
    {
      _type: 'switch',
      label: 'Gratificaciones',
      disabled: !canCreateLiquidation,
      name: 'liquidation.gift',
    },
    {
      _type: 'switch',
      label: 'Otros Ingressos',
      disabled: !canCreateLiquidation,
      name: 'liquidation.otherEarns',
    },
  ]

  return {
    data: groupInfoForm,
  }
}

export const useLiquidationDiscountsConfiguration = () => {
  const canCreateLiquidation = useCan(['configurations.liquidations'])
  const groupInfoForm: ConfigFields = [
    {
      _type: 'switch',
      label: 'IPS 9%',
      disabled: !canCreateLiquidation,
      name: 'liquidation.ips9',
    },
    {
      _type: 'switch',
      label: 'Anticipo',
      name: 'liquidation.antecipate',
      disabled: !canCreateLiquidation,
    },
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Prestamo',
      name: 'liquidation.loan',
    },
    {
      _type: 'switch',
      disabled: !canCreateLiquidation,
      label: 'Perdidas y Averias',
      name: 'liquidation.lost',
    },
    {
      _type: 'switch',
      label: 'Compras',
      disabled: !canCreateLiquidation,
      name: 'liquidation.purchase',
    },
    {
      disabled: !canCreateLiquidation,
      _type: 'switch',
      label: 'Desc. Judiciales',
      name: 'liquidation.judicial',
    },
    {
      _type: 'switch',
      label: 'Pagos en excesso',
      disabled: !canCreateLiquidation,
      name: 'liquidation.excess',
    },
    {
      _type: 'switch',
      label: 'Otros Descuentos',
      disabled: !canCreateLiquidation,
      name: 'liquidation.otherDiscount',
    },
  ]

  return {
    data: groupInfoForm,
  }
}
