export const GrabIcon = (props: any) => {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill={props.fill}
    >
      <path
        d="M9 3C9.82843 3 10.5 3.67157 10.5 4.5C10.5 5.32843 9.82843 6 9 6C8.17157 6 7.5 5.32843 7.5 4.5C7.5 3.67157 8.17157 3 9 3Z"
        fill={props.fill || '#626C77'}
      />
      <path
        d="M9 8.5C9.82843 8.5 10.5 9.17157 10.5 10C10.5 10.8284 9.82843 11.5 9 11.5C8.17157 11.5 7.5 10.8284 7.5 10C7.5 9.17157 8.17157 8.5 9 8.5Z"
        fill={props.fill || '#626C77'}
      />
      <path
        d="M10.5 15.5C10.5 14.6716 9.82843 14 9 14C8.17157 14 7.5 14.6716 7.5 15.5C7.5 16.3284 8.17157 17 9 17C9.82843 17 10.5 16.3284 10.5 15.5Z"
        fill={props.fill || '#626C77'}
      />
      <path
        d="M3 3C3.82843 3 4.5 3.67157 4.5 4.5C4.5 5.32843 3.82843 6 3 6C2.17157 6 1.5 5.32843 1.5 4.5C1.5 3.67157 2.17157 3 3 3Z"
        fill={props.fill || '#626C77'}
      />
      <path
        d="M3 8.5C3.82843 8.5 4.5 9.17157 4.5 10C4.5 10.8284 3.82843 11.5 3 11.5C2.17157 11.5 1.5 10.8284 1.5 10C1.5 9.17157 2.17157 8.5 3 8.5Z"
        fill={props.fill || '#626C77'}
      />
      <path
        d="M4.5 15.5C4.5 14.6716 3.82843 14 3 14C2.17157 14 1.5 14.6716 1.5 15.5C1.5 16.3284 2.17157 17 3 17C3.82843 17 4.5 16.3284 4.5 15.5Z"
        fill={props.fill || '#626C77'}
      />
    </svg>
  )
}
