export const OpenDrawerIcon = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M0.799951 3.20005V12.8M9.35867 4.16005L4.57148 8.00005M4.57148 8.00005L9.35867 11.84M4.57148 8.00005H15.2"
        stroke="#626C77"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="currentColor"
      />
    </svg>
  )
}
