import { IReceiptResponse } from '@/libs/react-query/types/reports'
import { ReceiptTableSchemaType } from './type'
import dayjs from 'dayjs'
import {
  fromReceiptFooterTypeToLabel,
  fromReceiptHeaderTypeToLabel,
  fromReceiptTypeToLabel,
} from '@/pages/reportes-v2/ReceiptBuilder/receipt-builder'

export const parseReceiptToRow = (
  templates: IReceiptResponse,
): ReceiptTableSchemaType => {
  // const rowType: any = {
  //   single: 'Individual',
  //   collective: 'Coletivo',
  //   totals: 'Totales',
  // }

  return {
    data: templates.data.map((template) => ({
      id: template.id,
      name: {
        value: template.name,
      },
      description: {
        value: template.description,
      },
      type: {
        value: fromReceiptTypeToLabel(template.type),
      },
      favorite: {
        value: template.favorite,
      },
      footerType: {
        value: fromReceiptFooterTypeToLabel(template.footerType),
      },
      headerType: {
        value: fromReceiptHeaderTypeToLabel(template.headerType),
      },
      created_by: {
        value: '-',
      },
      created_at: {
        value: dayjs(template.created_at).format('DD/MM/YYYY'),
      },

      original: template,
    })),
    pagination: {
      page: templates.page,
      perPage: templates.pageSize,
      total: templates.total,
      totalPages: templates.totalPages,
    },
  }
}
