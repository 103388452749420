import { queryClient } from '@/libs/react-query'
import { ICreateTemplateDTO } from '@/libs/react-query/types/reports'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleCreateTemplate = async (data: ICreateTemplateDTO) => {
  await apiV2.post<any>(`/report-builder/create-template`, data)
  queryClient.invalidateQueries('templates')
}

export const useHandleCreateTemplate = () => {
  const mutation = useCustomMutation(
    ['useHandleCreateTemplate'],
    handleCreateTemplate,
  )

  return mutation
}
