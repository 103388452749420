import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { useAvailableCells, useVacations } from '@/libs/react-query/hooks'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import dayjs from 'dayjs'
import { Progress } from '@punto-ui/react'
import { shortenName } from '@/utils/workers/name'
import { getUtcDateFromString } from '@/utils/date'
import { IVacation } from '@/libs/react-query/types/vacations'

export const VacationStep = () => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('vacation')),
  )
  const { data: vacations, isFetching: isFetchingVacations } = useVacations({
    cellsIds: availableCells?.map((c) => c.id) || [],
    page: 0,
    perPage: 200,
  })

  const methods = useFormContext<ExportPdfSchemaType>()

  const vacationId = useWatch({
    control: methods.control,
    name: 'vacationId',
  })

  const selectedVacation = vacations?.data.find((s) => s.id === vacationId)

  const getVacationLabel = (s: IVacation) => {
    let str = `${shortenName(s.first_user.name)}`

    if (s.users.length > 1) {
      str += ` + ${s.users.length - 1} ${
        s.users.length - 1 === 1 ? 'colaborador' : 'colaboradores'
      }`
    }

    str += ` - ${s.period}/${s.period + 1}`

    const dates = s.dates.sort((a, b) => {
      return dayjs(a.date).diff(dayjs(b.date))
    })

    str +=
      dates.length === 1
        ? `- ${dayjs(getUtcDateFromString(dates[0].date)).format('DD/MM/YYYY')}`
        : ` - ${dayjs(getUtcDateFromString(dates[0].date)).format(
            'DD/MM/YYYY',
          )} ~ ${dayjs(
            getUtcDateFromString(dates[dates.length - 1].date),
          ).format('DD/MM/YYYY')}`

    str += ` - ${dates.length} días`

    return str
  }

  return (
    <Div>
      <DropdownV2
        value={vacationId || ''}
        defaultValue={vacationId || ''}
        onChangeValue={(value) => {
          if (!value.value) {
            return
          }

          methods.setValue('vacationId', value.value)
          const vacation = vacations?.data.find((s) => s.id === value.value)
          methods.setValue(
            'usersIds',
            vacation?.users.map((u) => u.user_id) || [],
          )
        }}
        defaultOption={{
          label: selectedVacation
            ? getVacationLabel(selectedVacation)
            : 'Selecciona una vacación',
          value: selectedVacation?.id || '',
        }}
        options={
          vacations?.data.map((s) => {
            return {
              label: getVacationLabel(s),
              value: s.id,
            }
          }) || []
        }
      />
      {isFetchingVacations && (
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Progress />
        </Div>
      )}
    </Div>
  )
}
