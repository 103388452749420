import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdatePaymentPayload } from '../types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient } from '@/libs/react-query'
import { IPaymentSchema } from '@/pages/pagos/create-batch/form'
dayjs.extend(utc)

export interface IUpdatePaymentsDTO {
  payments: IPaymentSchema[]
  batch_id?: string
  title?: string
  description?: string
}

const handleUpdatePayments = async (props: IUpdatePaymentsDTO) => {
  const apiPayload = handleParsePaymentSchemaToPayload(props.payments)

  await apiV2.post<void>(`/payment/update-payment`, {
    payments: apiPayload,
    batch_id: props.batch_id,
    title: props.title,
    description: props.description,
  })

  queryClient.invalidateQueries()
}

export const useHandleUpdatePayments = () => {
  const mutation = useMutation(
    ['useHandleUpdatePayments'],
    handleUpdatePayments,
  )

  return mutation
}

const handleParsePaymentSchemaToPayload = (
  props: IPaymentSchema[],
): UpdatePaymentPayload[] => {
  return props.map((payment) => {
    return {
      id: payment.id,
      title: payment.title,
      description: payment.description,
      payment_type: payment.payment_type,
      value_type: payment.value_type,
      discount_ips9: payment.discount_ips9,
      value: payment.value,
      payment_date: dayjs(payment.payment_date[0]).utc().format(),
      dates: [],
      payroll_label: payment.payment_type,
      users: payment.users.map((user, index) => ({
        id: user.id,
        ips: user.ips || !!payment.ips,
      })),
    }
  })
}
