import { Div } from '@/components'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { Text, styled } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useMemo } from 'react'
import { useWorkerCreation } from '../../../context/hooks'

export const ProfilePicturePreview = () => {
  const { control } = useFormContext<createCompleteWorkerData>()
  const { worker } = useWorkerCreation()

  const profilePhoto = useWatch({
    control,
    name: 'profile_photo',
  })

  const previewUrl = useMemo(() => {
    if (profilePhoto) {
      return URL.createObjectURL(profilePhoto)
    } else {
      return worker?.user.photo_url
    }
  }, [profilePhoto, worker])

  if (!previewUrl) {
    return (
      <Div
        css={{
          height: 100,
          width: 100,
          border: 'solid 2px',
          borderColor: '$brand_primary_pure',
          borderRadius: '$full',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text
          variant={'caption'}
          css={{
            color: '$brand_primary_pure',
            fontWeight: '$bold',
            marginBottom: '$4',
            textAlign: 'center',
          }}
        >
          Sin foto de Perfil
        </Text>
      </Div>
    )
  }

  return <PreviewProfileImage src={previewUrl} />
}

const PreviewProfileImage = styled('img', {
  height: 100,
  width: 100,
  objectFit: 'cover',
  objectPosition: 'center',
  border: 'solid 2px',
  borderColor: '$brand_primary_pure',
  borderRadius: '$full',
})
