import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { z } from 'zod'

export const PdfHubSchema = z.object({
  configurations: z.object({
    name: z.string(),
  }),
  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
  }),
})

export type PdfHubSchemaType = z.infer<typeof PdfHubSchema>
export type PdfHubFields = Array<ControlledFieldProps<PdfHubSchemaType>>
