import { styled } from '@punto-ui/react'

export const TableContainer = styled('div', {})

export const DomTable = styled('table', {
  flex: '1 1 auto',
  borderCollapse: 'collapse',
})

export const DomTHead = styled('thead', {
  position: 'sticky',
  top: 0,
  zIndex: 9,

  backgroundColor: '$interface_light_up',
})
