import { UpdateFaceIdError } from '@/exceptions'
import { queryClient } from '@/libs/react-query'
import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'

interface UploadFaceIdFormData {
  user_id: string
  photo: File
}

export const useHandleUpdateFaceId = () => {
  const mutation = useMutation(
    ['useHandleUpdateFaceIdWorker'],
    handleUpdateFaceId,
  )

  return mutation
}

const handleUpdateFaceId = async ({ ...props }: UploadFaceIdFormData) => {
  try {
    const formData = new FormData()
    formData.append('user_id', props.user_id)
    formData.append('file', props.photo)

    const response = await apiV2.post('/punches/update-face-id', formData)
    queryClient.invalidateQueries()
    return response.data
  } catch (err) {
    throw new UpdateFaceIdError()
  }
}
