import { ControlledInput, Div } from '@/components'
import { Button, Tag, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReportBuilderFilterType, ReportBuilderSchemaType } from '../../type'
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FunnelIcon,
  PlusCircleIcon,
  TrashIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import { ControlledBulletPoints } from '@/components/Forms'
import { IReportBuilderColumnFilterTypes } from '../../filter-types'
import { reportBuilderFilterTypes } from '../../constants/report-builder-filter-types'
import { useState } from 'react'
import { useDebounce } from '@/hooks'
import { useFilterColumnOptions } from './useFilterColumnOptions'

export const FilterCreator = () => {
  const { control, setValue } = useFormContext<ReportBuilderSchemaType>()

  const [filters] = useWatch({
    control,
    name: ['filters'],
  })

  return (
    <Div>
      <Text
        css={{
          padding: '$2',
        }}
      >
        Filtros
      </Text>
      {filters.map((f, index) => (
        <ExportTemplateFilter key={f.id} index={index} filter={f} />
      ))}
      <Div
        css={{
          padding: '$2',
        }}
      >
        <Button
          onClick={() => {
            setValue('filters', [
              ...filters,
              {
                id: Date.now().toString(),
                condition: '',
                value: '',
                hasValue: false,
                name: '',
                type: '',
                valueType: '',
                date: '',
                options: [],
              },
            ])
          }}
          variant="secondary"
          icon={<PlusCircleIcon />}
        >
          Agregar filtro
        </Button>
      </Div>
    </Div>
  )
}

interface IExportTemplateFilter {
  index: number
  filter: ReportBuilderFilterType
}

const ExportTemplateFilter = ({ index, filter }: IExportTemplateFilter) => {
  const [isOpen, setIsOpen] = useState(false)
  const methods = useFormContext<ReportBuilderSchemaType>()

  const { columns } = useFilterColumnOptions()

  const isOpenDebounced = useDebounce(isOpen, 500)

  const isFilterFilled =
    filter.name &&
    filter.condition &&
    (filter.value !== '' || !filter.hasValue || filter.valueType === 'date')

  const filterLabel =
    columns.find((o) => o.value === filter.name)?.label?.split(' - ')?.[0] ||
    `Filtro ${index + 1}`

  // console.log(columns)

  return (
    <Div
      css={{
        borderBottom: '1px solid',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        onClick={() => setIsOpen(!isOpen)}
        css={{
          cursor: 'pointer',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          color: isFilterFilled
            ? '$interface_dark_down'
            : '$status_warning_pure',

          background: isFilterFilled ? 'transparent' : '$status_warning_up',

          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 12,
          paddingBottom: 12,

          svg: {
            width: 20,
            height: 20,
          },
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <Div
            css={{
              svg: {
                fill:
                  isOpen && !isFilterFilled
                    ? '$status_warning_pure'
                    : isOpen
                    ? '$interface_dark_down'
                    : 'transparent',
              },
            }}
          >
            <FunnelIcon />
          </Div>
          <Text
            variant={'description'}
            css={{
              color: isFilterFilled
                ? '$interface_dark_down'
                : '$status_warning_pure',
            }}
          >
            {`${filterLabel}` || `Filtro ${index + 1}`}
          </Text>
          <Div
            css={{
              cursor: 'pointer',
            }}
          >
            <TrashIcon
              onClick={() => {
                const filters = methods.getValues('filters')
                methods.setValue(
                  'filters',
                  filters.filter((_, i) => i !== index),
                )
              }}
            />
          </Div>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              color: isFilterFilled
                ? '$status_success_deep'
                : '$status_warning_pure',
            }}
          >
            {isFilterFilled ? <CheckCircleIcon /> : <WrenchIcon />}
          </Div>
          <Div
            css={{
              marginLeft: '$2',
            }}
          >
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '1fr' : '0fr',
          display: 'grid',

          marginTop: isOpen ? '$4' : 0,
          marginBottom: isOpen ? '$4' : 0,
        }}
      >
        <Div
          css={{
            padding: '$4',
            paddingTop: 0,
            paddingBottom: 0,
            overflow:
              !isOpenDebounced || (isOpenDebounced && !isOpen)
                ? 'hidden'
                : undefined,
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'flex-start',
            // justifyContent: 'space-around',
          }}
        >
          <Div
            css={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Div
              css={{
                width: '100%',
              }}
            >
              <FilterLabel column={'Columna'} />
              <Div
                css={{
                  display: 'flex',
                  marginLeft: '$4',
                  maxHeight: 250,
                  overflow: 'scroll',
                }}
              >
                <ControlledBulletPoints
                  variant={'caption'}
                  name={`filters.${index}.name`}
                  direction="vertical"
                  options={columns}
                  onValueChange={(value) => {
                    const filters = methods.getValues('filters')

                    const columnType = columns.find((c) => c.value === value)
                      ?.type as IReportBuilderColumnFilterTypes

                    const filtersOperators =
                      reportBuilderFilterTypes[columnType || 'string']

                    filters[index].options = filtersOperators.operations.map(
                      (f) => ({
                        label: f.label,
                        value: f.value,
                        requiredInput: f.requiredInput,
                      }),
                    )

                    filters[index].valueType = columnType
                    filters[index].type = columnType

                    methods.setValue(`filters.${index}.condition`, '')
                    methods.setValue(`filters.${index}.hasValue`, false)
                    methods.setValue('filters', filters)
                  }}
                  defaultOption={{
                    label: '',
                    value: '',
                  }}
                />
              </Div>
            </Div>
            <Div
              css={{
                marginBottom: '$4',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
            >
              <FilterLabel column={'Condición'} />
              <Div
                css={{
                  display: 'flex',
                  marginLeft: '$4',
                }}
              >
                <ControlledBulletPoints
                  variant={'caption'}
                  direction="vertical"
                  name={`filters.${index}.condition`}
                  options={filter.options}
                  onValueChange={(value) => {
                    const option = filter.options.find((o) => o.value === value)
                    const hasValue =
                      option?.requiredInput === undefined
                        ? true
                        : !!option?.requiredInput

                    methods.setValue(`filters.${index}.hasValue`, hasValue)
                    methods.setValue(`filters.${index}.value`, '')
                  }}
                  defaultOption={{
                    label: '',
                    value: '',
                  }}
                />
              </Div>
            </Div>
          </Div>
          <Div>
            {filter.hasValue && (
              <>
                {filter.valueType !== 'date' && (
                  <Div
                    css={{
                      marginTop: '$4',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',

                      '> div:nth-child(2)': {
                        marginLeft: '$4',
                      },
                    }}
                  >
                    <FilterLabel column={'Valor de comparación'} />
                    <ControlledInput
                      inputType={
                        filter.valueType === 'number'
                          ? 'only-numbers-or-empty'
                          : 'no-mask'
                      }
                      name={`filters.${index}.value`}
                    />
                  </Div>
                )}
              </>
            )}
            {filter.valueType === 'date' && (
              <Div
                css={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <Tag variant="neutral">
                  Filtros de tipo data son definidos al exportar el template.
                </Tag>
              </Div>
            )}
            {!filter.hasValue && (
              <Div
                css={{
                  height: 64,
                }}
              />
            )}
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

const FilterLabel = ({ column }: { column: string }) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '$2',
      }}
    >
      <Div
        css={{
          height: 4,
          width: 4,
          borderRadius: '$full',
          background: '$interface_dark_down',
          marginRight: '$4',
          marginLeft: '$4',
        }}
      />
      <Div>
        <Text
          variant={'description'}
          css={{
            color: '$interface_dark_down',
          }}
        >
          {column}
        </Text>
      </Div>
    </Div>
  )
}
