import { Div } from '@/components'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  DEFAULT_INACTIVATE_WORKER,
  InactivateWorkerSchema,
  inactivateWorkerSchema,
} from './form'
import { Progress, Text } from '@punto-ui/react'
import { styled, theme } from '@/styles'
import { useWorkerLiquidation } from '@/libs/react-query/hooks/useWorkerLiquidation'
import { useTabStore } from '@/store'
import { useEffect, useState } from 'react'
import { parseLiquidateUserDTO } from './parser'
import { useHandleWorkerLiquidationActions } from './actions'
import { InactivateWorkerModal } from './components/InactivateWorkerModal'
import { useWorker } from '@/libs/react-query/hooks'
import { AguinaldoLiquidation } from './components/AguinaldoLiquidation'
import { LastSalariesLiquidation } from './components/LastSalariesLiquidation'
import { LiquidationOptions } from './components/LiquidationOptions'
import { CausedVacationLiquidation } from './components/CausedVacationLiquidation'
import { ProportionalVacationLiquidation } from './components/ProportionalVacationLiquidation'
import { PreWarningLiquidation } from './components/PreWarningLiquidation'
import { IndemnificationLiquidation } from './components/IndemnificationLiquidation'
import { LiquidationOtherConcept } from './components/LiquidationOtherConcept'
import { AntecipateVacationLiquidation } from './components/VacationAntecipateLiquidation'
import { IPSLiquidation } from './components/IpsLiquidation'

import { validate as validateUuid } from 'uuid'

import { useHandleUpdateLiquidation } from './hooks/useHandleUpdateLiquidation'
import { DiscountPreWarningLiquidation } from './components/DiscountPreWarningLiquidation'
import { AccumulatedVacationLiquidation } from './components/AccumulatedVacationLiquidation'
import { TotalProportionalSalaryLiquidation } from './components/TotalProportionalSalaryLiquidation'
import { usePaymentsAndDiscountsConcepts } from './hooks/usePaymentsAndDiscountsConcepts'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { CrownIcon } from '@/assets/icons/CrownIcon'
import { useCan } from '@/hooks'

type InactivateWorkerProps = {
  liquidationId?: string
}

export const InactivateWorker = (props: InactivateWorkerProps) => {
  const methods = useForm<InactivateWorkerSchema>({
    resolver: zodResolver(inactivateWorkerSchema),
    defaultValues: {
      liquidationId: props.liquidationId,
      data: {
        ...DEFAULT_INACTIVATE_WORKER,
      },
      original: {
        ...DEFAULT_INACTIVATE_WORKER,
      },
      conceptsStatus: {},
      user_in_ips: false,
      ips: [],
      ipsValues: {
        ips16Value: 0,
        ips9Value: 0,
        ipsValue: 0,
        totalValue: 0,
      },
    },
  })
  return (
    <FormProvider {...methods}>
      <InactivateWorkerContent />
    </FormProvider>
  )
}

export const InactivateWorkerContent = () => {
  const { data: isLiquidationAvailable, detail: liquidationDetail } =
    useIsFeatureInPlan('liquidation')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [conceptsOpened, setConceptsOpened] = useState<{
    caused_vacation: boolean
    accumulated_vacation: boolean
    familyBonus: boolean
    proportional_vacation: boolean
    pre_warning: boolean
    discount_pre_warning: boolean
    indemnification: boolean
    aguinaldo: boolean
    proportional_salary: boolean
    last_salaries: boolean
    proportional_discount_salary: boolean
    total_proportional_salary: boolean
    vacation_antecipate: boolean
    ips: boolean
    paymentConcepts: boolean[]
    discountConcepts: boolean[]
  }>({
    total_proportional_salary: true,
    proportional_discount_salary: true,
    accumulated_vacation: true,
    aguinaldo: true,
    familyBonus: true,
    last_salaries: true,
    caused_vacation: true,
    discount_pre_warning: true,
    proportional_salary: true,
    proportional_vacation: true,
    vacation_antecipate: true,
    pre_warning: true,
    indemnification: true,
    ips: true,
    paymentConcepts: Array.from({ length: 99 }).map(() => true),
    discountConcepts: Array.from({ length: 99 }).map(() => true),
  })

  const methods = useFormContext<InactivateWorkerSchema>()
  const { reset } = methods
  const { activeTab } = useTabStore((state) => ({
    activeTab: state.worker.activeTab,
  }))

  const { data: workerLiquidation, isFetching: isLoadingLiquidation } =
    useWorkerLiquidation({
      userId: validateUuid(activeTab.replace('-inactivate', ''))
        ? activeTab.replace('-inactivate', '')
        : '',
      liquidationId: methods.getValues('liquidationId'),
    })

  useHandleWorkerLiquidationActions({
    isLoading: isLoadingLiquidation,
    handleLiquidate: () => setIsDialogOpen(true),
  })

  const { data: worker } = useWorker(activeTab.replace('-inactivate', ''))
  const canEditLiquidation = useCan([
    'workers.liquidate',
    'workers.editLiquidation',
  ])

  useEffect(() => {
    if (workerLiquidation && !isLoadingLiquidation) {
      const data = parseLiquidateUserDTO(workerLiquidation.result)
      const { enabled_changes: enabledChanges, liquidationId } =
        methods.getValues()

      const enabledChangesNotDefined = enabledChanges === undefined
      const newEnabledChanges = enabledChangesNotDefined
        ? canEditLiquidation && data.status !== 'done'
        : enabledChanges

      reset({
        liquidationId,
        data,
        original: data,
        enabled_changes: newEnabledChanges,
        ipsValues: {
          ips16Value: workerLiquidation.result.totalIps.ips16,
          ips9Value: workerLiquidation.result.totalIps.ips9,
          ipsValue: workerLiquidation.result.totalIps.valueIps,
          totalValue: workerLiquidation.result.totalIps.value,
        },
        user_in_ips: workerLiquidation.result.user_in_ips,
        ips: workerLiquidation.result.ips.map((i) => ({
          ips16: i.ips16,
          ips9: i.ips9,
          label: i.label,
          type: i.type,
          value: i.value,
          valueIps: i.valueIps,
        })),
        conceptsStatus: workerLiquidation.result.conceptsStatus.reduce(
          (acc, curr) => {
            acc[curr.entity_id] = curr
            return acc
          },
          {} as Record<string, InactivateWorkerSchema['conceptsStatus'][0]>,
        ),
      })
    }
  }, [workerLiquidation, reset, isLoadingLiquidation])

  const handleToggleOpen = (
    key: keyof typeof conceptsOpened,
    b: boolean | undefined,
    index?: number,
  ) => {
    const newConcepts = {
      ...conceptsOpened,
    }

    if (index !== undefined) {
      ;(newConcepts[key] as any)[index] = (
        b !== undefined ? b : !(conceptsOpened[key] as any)[index]
      ) as any

      setConceptsOpened(newConcepts)
      return
    }

    newConcepts[key] = b !== undefined ? b : (!conceptsOpened[key] as any)
    setConceptsOpened(newConcepts)
  }

  const { handleUpdateUserLiquidation } = useHandleUpdateLiquidation()

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      const enabledChanges = methods.getValues('enabled_changes')

      if (event.key === 'Enter') {
        if (enabledChanges) {
          handleUpdateUserLiquidation()
        }
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [methods, handleUpdateUserLiquidation])

  const {
    discountConcepts: discountConceptsWithOldIndex,
    paymentConcepts: paymentConceptsWithOldIndex,
  } = usePaymentsAndDiscountsConcepts(['liquidation_concept'])

  if (!workerLiquidation) {
    return (
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '50vh',

          width: '100%',
        }}
      >
        <Progress />
      </Div>
    )
  }

  return (
    <Div
      css={{
        height: 'calc(100vh - 60px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <LiquidationOptions />
      <Div
        css={{
          padding: '$4',

          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 70px)',
          // backgroundColor: '$interface_light_pure',
          backgroundColor: 'transparent',
          overflowY: 'scroll',
          width: '100%',
          marginRight: '$4',
          borderRadius: '$md',
        }}
      >
        <TotalProportionalSalaryLiquidation
          isOpen={conceptsOpened.total_proportional_salary}
          toggleOpen={(b) => handleToggleOpen('total_proportional_salary', b)}
        />
        {/* <ProportionalSalaryLiquidation
          isOpen={conceptsOpened.proportional_salary}
          toggleOpen={(b) => handleToggleOpen('proportional_salary', b)}
        /> */}

        <AguinaldoLiquidation
          isOpen={conceptsOpened.aguinaldo}
          toggleOpen={(b) => handleToggleOpen('aguinaldo', b)}
        />

        <LastSalariesLiquidation
          isOpen={conceptsOpened.last_salaries}
          toggleOpen={(b) => handleToggleOpen('last_salaries', b)}
        />

        <CausedVacationLiquidation
          isOpen={conceptsOpened.caused_vacation}
          toggleOpen={(b) => handleToggleOpen('caused_vacation', b)}
        />

        <AccumulatedVacationLiquidation
          isOpen={conceptsOpened.accumulated_vacation}
          toggleOpen={(b) => handleToggleOpen('accumulated_vacation', b)}
        />

        <ProportionalVacationLiquidation
          isOpen={conceptsOpened.proportional_vacation}
          toggleOpen={(b) => handleToggleOpen('proportional_vacation', b)}
        />

        <PreWarningLiquidation
          isOpen={conceptsOpened.pre_warning}
          toggleOpen={(b) => handleToggleOpen('pre_warning', b)}
        />

        <IndemnificationLiquidation
          isOpen={conceptsOpened.indemnification}
          toggleOpen={(b) => handleToggleOpen('indemnification', b)}
        />

        {!!paymentConceptsWithOldIndex.length && (
          <LiquidationSessionLabel title={'OTROS CONCEPTOS: CRÉDITOS'} />
        )}

        {paymentConceptsWithOldIndex.map((conceptEnvelope) => (
          <LiquidationOtherConcept
            index={conceptEnvelope.index}
            key={`${conceptEnvelope.concept.id}${conceptEnvelope.concept.name}`}
            isOpen={conceptsOpened.discountConcepts[conceptEnvelope.index]}
            toggleOpen={(b) =>
              handleToggleOpen('discountConcepts', b, conceptEnvelope.index)
            }
            {...conceptEnvelope.concept}
          />
        ))}

        <LiquidationSessionLabel title={'INICIO DEBITOS'} />

        <AntecipateVacationLiquidation
          isOpen={conceptsOpened.vacation_antecipate}
          toggleOpen={(b) => handleToggleOpen('vacation_antecipate', b)}
        />

        <DiscountPreWarningLiquidation
          isOpen={conceptsOpened.discount_pre_warning}
          toggleOpen={(b) => handleToggleOpen('discount_pre_warning', b)}
        />

        {!!discountConceptsWithOldIndex.length && (
          <LiquidationSessionLabel title={'OTROS CONCEPTOS: DEBITOS'} />
        )}

        {discountConceptsWithOldIndex.map((conceptEnvelope) => (
          <LiquidationOtherConcept
            isDiscount
            index={conceptEnvelope.index}
            key={`${conceptEnvelope.concept.id}${conceptEnvelope.concept.name}`}
            isOpen={conceptsOpened.discountConcepts[conceptEnvelope.index]}
            toggleOpen={(b) =>
              handleToggleOpen('discountConcepts', b, conceptEnvelope.index)
            }
            {...conceptEnvelope.concept}
          />
        ))}

        <LiquidationSessionLabel title={'IPS'} />

        <IPSLiquidation
          isOpen={conceptsOpened.ips}
          toggleOpen={(b) => handleToggleOpen('ips', b)}
        />
      </Div>
      <InactivateWorkerModal
        name={worker?.user?.name || ''}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

      {!isLiquidationAvailable && (
        <Div
          css={{
            position: 'absolute',
            right: 16,
            bottom: 16,
            background: '$interface_light_pure',
            padding: '$4',
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            border: '1px solid $brand_primary_pure',
            borderRadius: '$md',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
            svg: {
              color: '$brand_primary_pure',
              height: 32,
              width: 32,
            },
          }}
        >
          <CrownIcon fill={theme.colors.brand_primary_pure} />
          <Text
            variant={'description'}
            css={{
              color: '$brand_primary_pure',
            }}
          >
            Esta funcionalidad esta {liquidationDetail?.toLowerCase()}
          </Text>
        </Div>
      )}
    </Div>
  )
}

export const FormContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginBottom: '$4',
  background: '$interface_light_pure',
  padding: '$4',
  gap: '$4',
  borderRadius: '$lg',
})

export const ContainerTitle = styled(Text, {
  color: '$brand_primary_pure',
  fontWeight: 'bold',
})

export const ContainerInputTitle = styled('input', {
  all: 'unset',

  color: '$brand_primary_pure',

  fontSize: '$xl',
  lineHeight: '110%',
  fontFamily: '$default',
  fontWeight: 600,
  height: 36,

  minWidth: 300,

  borderBottom: '1px solid transparent',

  transition: 'border-color 0.2s ease-in-out',

  '&:hover': {
    borderBottom: '1px solid $brand_primary_pure',
  },

  variants: {
    disabled: {
      true: {
        '&:hover': {
          borderBottom: '1px solid transparent',
        },
      },
    },
  },
})

export const HorizontalDivider = styled('div', {
  width: '100%',
  minHeight: 1,
  borderRadius: '$sm',
  background: '$interface_light_deep',
  marginBottom: '$6',
  marginTop: '$4',
})

const LiquidationSessionLabel = ({ title }: { title: string }) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        marginTop: 24,
        marginBottom: 32,
      }}
    >
      <Text
        variant="subtitle2"
        css={{
          color: '$interface_dark_pure',
        }}
      >
        {title}
      </Text>
    </Div>
  )
}
