import { ITemplateResponse } from '@/libs/react-query/types/reports'
import { TemplateTableSchemaType } from './type'
import dayjs from 'dayjs'

export const parseTemplateToRow = (
  templates: ITemplateResponse,
): TemplateTableSchemaType => {
  const rowType: any = {
    single: 'Individual',
    collective: 'Coletivo',
    totals: 'Totales',
  }

  const linesType: any = {
    day: 'Dia',
    month: 'Mes',
    year: 'Año',
    entities: 'Entidades',
    sectors: 'Sectores',
    workers: 'Colaboradores',
  }

  return {
    data: templates.data.map((template) => ({
      id: template.id,
      name: {
        value: template.name,
      },
      description: {
        value: template.description,
      },
      type: {
        value: rowType[template.type],
      },
      favorite: {
        value: template.favorite,
      },
      lines: {
        value: linesType[template.row],
      },
      columns: {
        value: template.columns.length,
      },
      details: {
        value: linesType[template.rowDetails],
      },
      created_by: {
        value: '-',
      },
      created_at: {
        value: dayjs(template.created_at).format('DD/MM/YYYY'),
      },

      original: template,
    })),
    pagination: {
      page: templates.page,
      perPage: templates.pageSize,
      total: templates.total,
      totalPages: templates.totalPages,
    },
  }
}
