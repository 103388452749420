import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { CreateManyWorkersPayloadType } from './parseStructure'

const handleCreateManyWorkers = async (
  data: CreateManyWorkersPayloadType[],
) => {
  await apiV2.post<void>('/user/create-many', data)
  queryClient.invalidateQueries()
}

export const useHandleCreateManyWorkers = () => {
  const mutation = useCustomMutation(
    ['handleCreateManyWorkers'],
    handleCreateManyWorkers,
  )
  return mutation
}
