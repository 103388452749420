import {
  ICreatePdfDTO,
  IPdfTemplate,
  IUpdatePdfDTO,
} from '@/libs/react-query/types/reports'
import { PdfBuilderContentDataType, PdfBuilderSchemaType } from './types'

export const buildPdfCreationDTO = (
  data: PdfBuilderSchemaType,
): IUpdatePdfDTO | ICreatePdfDTO => {
  return {
    id: data.id,
    name: data.configurations.name,
    description: data.configurations.description,

    direction: data.configurations.direction,
    size: data.configurations.size,

    favorite: data.configurations.favorite,
    has_two_per_page: !!data.configurations.has_two_per_page,

    layouts: data.pdf.content.map((content, index) => {
      return {
        order: index + 1,
        numberOfColumns: content.numberOfColumns,

        content: content.data.map((c, index) => {
          return {
            id: c.id || undefined,
            type: c.type || undefined,
            width: c.width || undefined,
            order: index + 1 || undefined,

            height: c.height || undefined,

            value: c.text || undefined,
            color: c.color || undefined,
            is_bold: c.isBold || undefined,
            is_italic: c.isItalic || undefined,
            is_underline: c.isUnderline || undefined,
            text_alignment: c.alignment || undefined,
            font_size: c.fontSize || undefined,

            borderColor: c.borderColor || undefined,
            bordered: c.bordered || undefined,
          }
        }),
      }
    }),
  }
}

export const buildPdfDataSchema = (
  data: IPdfTemplate,
): PdfBuilderSchemaType => {
  const orderedLayouts = [...(data.layouts || [])].sort(
    (a, b) => a.order - b.order,
  )

  return {
    configurations: {
      name: data.name,
      description: data.description,
      direction: data.direction,
      size: data.size,
      favorite: data.favorite,
      has_two_per_page: !!data.has_two_per_page,
    },
    pdf: {
      content: orderedLayouts.map((layout) => {
        const orderedContent = [...layout.content].sort(
          (a, b) => (a.order || 0) - (b.order || 0),
        )

        return {
          id: layout.id,
          numberOfColumns: layout.number_of_columns,
          data: orderedContent.map((content) => {
            return {
              id: content.id,
              type: content.type as PdfBuilderContentDataType,
              width: content.width || undefined,
              height: content.height || undefined,
              text: content.value || undefined,
              color: content.color || undefined,
              isBold: content.is_bold || undefined,
              isItalic: content.is_italic || undefined,
              isUnderline: content.is_underline || undefined,
              alignment: content.text_alignment || undefined,
              fontSize: content.font_size || undefined,

              bordered: content.bordered || undefined,
              borderColor: content.borderColor || undefined,
            }
          }),
        }
      }),
    },
    currentStep: 0,
    id: data.id,
    identifier_id: data.identifier_id,
    openContents: {},
    openData: {},
    loadedTemplate: '',
    manualVariables: [],
  }
}
