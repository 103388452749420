import { useFormContext, useWatch } from 'react-hook-form'
import { AddWorkerFields } from '.'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { useMemo } from 'react'
import { useCan } from '@/hooks'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useMarcacionAsistenciaForm = () => {
  const { data: company } = useMyCompany()
  const { control } = useFormContext<createCompleteWorkerData>()
  const canChangeConfig = useCan(['workers.changeConfig'])
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const punchConfigIds = useWatch({
    control,
    name: 'punch_config_ids',
  })

  const data = useMemo(() => {
    const defaultData = {
      value: company?.punchConfigGroups[0].id || '',
      label: company?.punchConfigGroups[0].name || '',
    }

    const marcacionAsistenciaForm: AddWorkerFields = [
      {
        _type: 'dropdown',
        placeholder: 'Configuración de Marcacion',
        label: 'Configuración de Marcacion',
        name: 'punch_config_ids',
        defaultOption: defaultData,
        disabled: !canChangeConfig || !isWorkerCreationAvailable,
        options:
          company?.punchConfigGroups?.map((punchConfig) => ({
            label: punchConfig.name,
            value: punchConfig.id,
            selected: punchConfigIds?.includes(punchConfig.id) || false,
          })) || [],
      },
    ]
    return marcacionAsistenciaForm
  }, [company, canChangeConfig, isWorkerCreationAvailable])

  return {
    data,
  }
}
