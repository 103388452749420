import { Div, SmartTable } from '@/components'
import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'

export const ImportPunchesTable = () => {
  const columns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Colaborador',
        name: 'teste',
        items: [
          // {
          //   type: 'input-text',
          //   name: 'name',
          //   draggable: null,
          //   header: 'Nombre',
          //   inputType: 'no-mask',
          //   width: 150,
          // },
          // {
          //   type: 'input-text',
          //   name: 'surname',
          //   draggable: null,
          //   header: 'Appellido',
          //   inputType: 'no-mask',
          //   width: 150,
          // },
          {
            type: 'input-text',
            name: 'document',
            header: 'CI',
            inputType: 'no-mask',
            width: 200,
          },
          {
            type: 'input-text',
            name: 'marcacion_id',
            header: 'ID de Marcación',
            inputType: 'no-mask',
            width: 200,
          },
          // {
          //   type: 'tags',
          //   name: 'externalIds',
          //   draggable: null,
          //   header: 'ID Externo',
          //   width: 150,
          // },
          // {
          //   type: 'input-text',
          //   name: 'email',
          //   draggable: null,
          //   header: 'E-mail',
          //   width: 200,
          // },
        ],
      },
      {
        header: 'Informaciones de Marcación',
        type: 'subheader',
        name: 'teste-2',
        items: [
          // {
          //   type: 'input-text',
          //   name: 'phone',
          //   header: 'Teléfono',
          //   inputType: 'no-mask',
          //   width: 100,
          // },
          // {
          //   type: 'tags',
          //   width: 100,
          //   disabled: true,
          //   name: 'gender',
          //   header: 'Género',
          // },
          // {
          //   type: 'tags',
          //   width: 100,
          //   name: 'nationality',
          //   disabled: true,
          //   header: 'Nacionalidad',
          // },
          // {
          //   type: 'tags',
          //   width: 100,
          //   name: 'civilState',
          //   disabled: true,
          //   header: 'Estado Civil',
          // },
          {
            type: 'input-text',
            width: 300,
            disabled: true,
            name: 'date',
            header: 'Fecha de Marcación',
            // inputType: 'datetime',
          },
        ],
      },
    ] as SmartColumnType[]
  }, [])

  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
      height: 300,
    }
  }, [])

  return (
    <>
      {/* <ControlledFilterBar
        dateName={'date'}
        nameFilterName="name"
        cellsIdsName="cellsIds"
        dateType="single"
        isLoading={false}
        policiesIds={permissionsArray.filter((p) => p.includes('punches'))}
      /> */}
      <Div
        css={{
          position: 'relative',
          // height: '100%',
          height: 'calc(100vh - 200px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 16,
          paddingRight: 16,
          overflowX: 'hidden',
          marginTop: '$4',
        }}
      >
        <SmartTable style={tableStyles} columns={columns} />
      </Div>
    </>
  )
}
