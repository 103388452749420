import { FiltersContainer } from './styles'

import React from 'react'

export const Filters = () => {
  // const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setShiftsFilters((state) => ({
  //     ...state,
  //     filter: e.target.value,
  //     page: 1,
  //   }))
  // }

  return (
    <FiltersContainer>
      {/* <FilterInput>
        <TextInput
          placeholder="Buscar turno"
          icon={<MagnifyingGlassIcon />}
          onChange={handleFilterChange}
        />
      </FilterInput> */}

      {/* <FiltersChips>
        <CellFilter
          policiesIds={permissionsArray.filter((p) => p.includes('shifts'))}
          callback={(options) => {
            const cellsIds = options.map((cell) => cell.id)
            debounce(() => {
              setShiftsFilters((state) => ({
                ...state,
                cellsIds,
              }))
            }, 500)
          }}
        />
      </FiltersChips> */}
    </FiltersContainer>
  )
}
