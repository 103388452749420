import { Stepper } from '@/components'
import { useMemo } from 'react'
import {
  aguinaldoPlaygroundSteps,
  useAguinaldoPlaygroundStore,
} from '../../store'

interface PayrollStepsProps {
  isLoading?: boolean
}

export const AguinaldoSteps = ({ isLoading }: PayrollStepsProps) => {
  const { currentStep, setStep } = useAguinaldoPlaygroundStore((state) => ({
    currentStep: state.currentStepNumber,
    setStep: state.handleChangeStepIndex,
  }))

  const stepsArray = useMemo(() => {
    return aguinaldoPlaygroundSteps.map((step, index) => {
      return {
        setFormStep: setStep,
        text: step.name,
        selectedIndex: currentStep,
        index,
        error: false,
      }
    })
  }, [currentStep, setStep])

  return <Stepper stepsArray={stepsArray} isLoading={isLoading} />
}
