import { styled } from '../../styles'
import Image from 'next/image'

export const AvatarContainer = styled('div', {
  borderRadius: '$full',
  display: 'inline-block',
  width: '$12',
  height: '$12',
  overflow: 'hidden',
})

export const AvatarImage = styled(Image, {
  objectFit: 'cover',
  borderRadius: 'inherit',
})
