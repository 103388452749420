import { AddShiftData } from '../forms'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Div, Stepper } from '@/components'
import { useShiftCreation } from '../context/hooks'
import { useMemo } from 'react'
import { StepProps } from '@/components/Stepper'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const CreateShiftsPageHeader = () => {
  const { currentStep, setStep, isLoading } = useShiftCreation()

  const stepsArray = useMemo(() => {
    const result: StepProps[] = [
      {
        error: false,
        index: 0,
        selectedIndex: currentStep,
        setFormStep: setStep,
        text: 'Configurar Turno',
      },
    ]

    result.push({
      error: false,
      index: 1,
      selectedIndex: currentStep,
      setFormStep: setStep,
      text: 'Cronogramas',
    })

    return result
  }, [currentStep, setStep])

  return (
    <Div css={{ marginBottom: '$4' }}>
      <Stepper stepsArray={stepsArray} isLoading={isLoading} />
    </Div>
  )
}

export const validateShiftTurns = (shifts: AddShiftData['shifts']) => {
  const wrongSwitchTime = shifts.findIndex((shift) => {
    return (
      shift.switchShiftsTime > shift.startTime &&
      shift.switchShiftsTime < shift.endTime
    )
  })

  return wrongSwitchTime
}
