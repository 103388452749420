import { IReportBuilderColumnType } from '../../type'
import { generateRandomSectors } from '@/utils/random'

export const permissionsColumns: IReportBuilderColumnType[] = [
  {
    type: 'permissions' as const,
    subgroup: 'Permisos',
    value: 'sector',
    label: 'Sector',
    valueType: 'string' as const,
    getRandomPlaceholder: generateRandomSectors,
  },
]
