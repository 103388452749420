import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { usePayrollDay } from '../usePayrollDay'
import { IWorkedHours } from '@/libs/react-query/types'
import { getWorkHours } from '@/utils/shifts/getWorkHours'
import dayjs from 'dayjs'
import { formatMinutes } from '@/utils/date'

interface OrdinaryHoursProps {
  name: keyof IWorkedHours['details']
}

export const useOrdinaryHours = (props: OrdinaryHoursProps) => {
  const { data: payrollDay } = usePayrollDay()

  const widthSize = useMemo(() => {
    const TOTAL_SIZE = 610 / 2 - 15

    return TOTAL_SIZE / 2
  }, [])

  const morningColumns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'input-text',
        name: 'period',
        draggable: null,
        disabled: true,
        header: 'Ord. Diurnas',
        width: widthSize,
      },
      {
        type: 'input-text',
        name: 'result',
        draggable: null,
        header: 'Resultado',
        disabled: true,
        inputType: 'no-mask',
        width: widthSize,
      },
    ]
  }, [widthSize])

  const nightColumns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'input-text',
        name: 'period',
        draggable: null,
        disabled: true,
        header: 'Ord. Nocturnas',
        width: widthSize,
      },
      {
        type: 'input-text',
        name: 'result',
        draggable: null,
        header: 'Resultado',
        disabled: true,
        inputType: 'no-mask',
        width: widthSize,
      },
    ]
  }, [widthSize])

  const morningData = useMemo(() => {
    let total = 0
    const returningResult: {
      period: string
      result: string
    }[] = []
    payrollDay?.hours.details[props.name].forEach((item) => {
      const { dayHours } = getWorkHours({
        entrance: dayjs(item.startTime).format('HH:mm'),
        exit: dayjs(item.endTime).format('HH:mm'),
        intervals: [],
        fromDayToNight: '20:00',
        fromNightToDay: '06:00',
      })

      if (!dayHours) {
        return
      }

      const period =
        dayjs(item.startTime).format('HH:mm') +
        ' - ' +
        dayjs(item.endTime).format('HH:mm')

      const result = formatMinutes(dayHours * 60)
      total += dayHours

      returningResult.push({
        period,
        result,
      })
    })

    returningResult.push({
      period: 'Total',
      result: total ? formatMinutes(total * 60) : '00:00',
    })

    return returningResult
  }, [payrollDay, props.name])

  const nightData = useMemo(() => {
    let total = 0
    const returningResult = []
    payrollDay?.hours.details[props.name].forEach((item) => {
      const { nightHours } = getWorkHours({
        entrance: dayjs(item.startTime).format('HH:mm'),
        exit: dayjs(item.endTime).format('HH:mm'),
        intervals: [],
        fromDayToNight: '20:00',
        fromNightToDay: '06:00',
      })

      if (!nightHours) {
        return
      }

      const period =
        dayjs(item.startTime).format('HH:mm') +
        ' - ' +
        dayjs(item.endTime).format('HH:mm')

      const result = formatMinutes(nightHours * 60)
      total += nightHours

      returningResult.push({
        period,
        result,
      })
    })

    returningResult.push({
      period: 'Total',
      result: total ? formatMinutes(total * 60) : '00:00',
    })

    return returningResult
  }, [payrollDay, props.name])

  return {
    morning: {
      columns: morningColumns,
      data: morningData,
    },
    night: {
      columns: nightColumns,
      data: nightData,
    },
  }
}
