import { styled } from '@punto-ui/react'

export const ViewDrawerContainer = styled('div', {
  padding: '$4 $3',
  minWidth: '25vw',
})

export const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',
  marginTop: 'auto',
})

export const View = styled('form', {
  marginTop: '$4',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
})
