import { useFormContext, useWatch } from 'react-hook-form'
import { ReceiptBuilderSchemaType } from '../../../type'
import { ControlledInput, Div } from '@/components'
import { HeaderImportantText, ReceiptHeaderInput } from '../ReceiptHeader'
import { Text } from '@punto-ui/react'

export const LiquidationHeaderOption = () => {
  const { control } = useFormContext<ReceiptBuilderSchemaType>()

  const title = useWatch({
    control,
    name: 'configurations.title',
  })

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 800,
        minWidth: 800,
        border: '2px solid $interface_dark_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '2px solid $interface_dark_pure',
            }}
          >
            <HeaderImportantText>{title}</HeaderImportantText>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '2px solid $interface_dark_pure',

              '> div:not(:last-child)': {
                width: '50%',
                borderRight: '2px solid $interface_dark_pure',
                paddingLeft: '$2',
              },
              '> div:last-child': {
                width: '50%',
                paddingLeft: '$2',
              },
            }}
          >
            <Div>
              <HeaderImportantText>Empleador</HeaderImportantText>
            </Div>
            <Div>
              <Text>CAV INC. S. A.</Text>
            </Div>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '2px solid $interface_dark_pure',

              '> div:not(:last-child)': {
                width: '50%',
                borderRight: '2px solid $interface_dark_pure',
                paddingLeft: '$2',
              },
              '> div:last-child': {
                width: '50%',
                paddingLeft: '$2',
              },
            }}
          >
            <Div>
              <HeaderImportantText>N PATRONAL MTESS</HeaderImportantText>
            </Div>
            <Div>
              <Text>123123123</Text>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingRight: '$2',
          height: 30,
        }}
      >
        <Text>Ciudad del Este, 24 de enero 2024</Text>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: '$2',
          height: 12,
        }}
      >
        <Text>Señor Colaborador, CI Nº 1231230</Text>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '$2',
          justifyContent: 'flex-start',
          height: 72,
          borderBottom: '2px solid $interface_dark_pure',
        }}
      >
        <Text>
          De conformidad a la notificación presentada se ha procedido a la
          liquidación de sus haberes a la presente fecha, cuyos resultados
          desglosados son los siguientes:
        </Text>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
          borderBottom: '2px solid $interface_dark_pure',
        }}
      >
        <Div>
          <HeaderImportantText>
            <ControlledInput
              InputComponent={ReceiptHeaderInput}
              name="headerConfig.title"
            />
          </HeaderImportantText>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },
          '> div:last-child': {
            width: '25%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>Trabajador</HeaderImportantText>
        </Div>
        <Div>
          <Text>JORGE GENEZ</Text>
        </Div>
        <Div>
          <HeaderImportantText>CI No</HeaderImportantText>
        </Div>
        <Div>
          <Text>123123123</Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },
          '> div:last-child': {
            width: '25%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>ANTIGUEDAD</HeaderImportantText>
        </Div>
        <Div>
          <Text>7 años 30 dias</Text>
        </Div>
        <Div>
          <HeaderImportantText>Remuneración base:</HeaderImportantText>
        </Div>
        <Div>
          <Text>3.000.000</Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid $interface_dark_pure',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },
          '> div:last-child': {
            width: '25%',
            paddingLeft: '$2',
          },
        }}
      >
        <Div>
          <HeaderImportantText>Fecha de Entrada</HeaderImportantText>
        </Div>
        <Div>
          <Text>25/07/2010</Text>
        </Div>
        <Div>
          <HeaderImportantText>Fecha de Salida</HeaderImportantText>
        </Div>
        <Div>
          <Text>24/01/2024</Text>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          '> div:not(:last-child)': {
            width: '25%',
            borderRight: '2px solid $interface_dark_pure',
            paddingLeft: '$2',
          },
          '> div:last-child': {
            width: '75%',
            paddingLeft: '$2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <Div>
          <HeaderImportantText>Motivo de Salida</HeaderImportantText>
        </Div>
        <Div>
          <Text>Despido injustificado</Text>
        </Div>
      </Div>
    </Div>
  )
}
