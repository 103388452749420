import { Div, FormRenderer } from '@/components'
import useToast from '@/hooks/useToast'
import { queryClient } from '@/libs/react-query'
import {
  RegisterFormData,
  registerFormSchema,
} from '@/pages/auth/register/forms'
import { useRegisterForm } from '@/pages/auth/register/register-form'
import { apiV2 } from '@/services/api'
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Text } from '@punto-ui/react'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

export const CreateCompanyDialog = ({
  handleCloseDialog,
}: {
  handleCloseDialog: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { data: items } = useRegisterForm()

  const { addToast } = useToast()

  const methods = useForm<RegisterFormData>({
    resolver: zodResolver(registerFormSchema),
  })

  const handleSignUp = async (data: RegisterFormData) => {
    try {
      setIsLoading(true)
      await apiV2.post('/company/create', {
        business_name: data.name,
        email: data.email,
        phone: data.phone,
        name: data.contactName,
        document: data.ruc,
      })

      addToast({
        title: '👌éxitos',
        description: 'Serás redirigido a la página de inicio.',
        type: 'positive',
        id: 1,
      })

      queryClient.invalidateQueries('companies')

      handleCloseDialog()
    } catch (err) {
      console.log(err)
      addToast({
        title: '❌ Uppz!',
        description: '¡Fracasado! Inténtalo de nuevo',
        type: 'negative',
        id: 1,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          padding: '$4',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
            marginBottom: '$4',

            svg: {
              width: '20px',
              height: '20px',
            },
          }}
        >
          <BuildingStorefrontIcon />
          <Text variant="subtitle2">Nueva Empresa</Text>
        </Div>
        <FormRenderer<RegisterFormData> items={items} inline cols={2} />
        <Div
          css={{
            marginTop: '$4',
          }}
        >
          <Button
            variant="primary"
            css={{
              width: '100%',
              '&:hover': {
                backgroundColor: '$brand_primary_pure',
              },
            }}
            onClick={methods.handleSubmit(handleSignUp)}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Crear Nueva Empresa
          </Button>
        </Div>
      </Div>
    </FormProvider>
  )
}
