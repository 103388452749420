import { Div } from '@/components'
import { ILiquidationConfigGroup } from '@/libs/react-query/types/company'
// import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Checkbox, Tag, Text } from '@punto-ui/react'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import React from 'react'

export const useLiquidationGroupsColumns = () => {
  const columns = React.useMemo<ColumnDef<ILiquidationConfigGroup>[]>(() => {
    const retunedColumns: ColumnDef<ILiquidationConfigGroup>[] = [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
            // checked={table.getIsAllRowsSelected()}
            // onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
            // onClick={row.getToggleSelectedHandler()}
            // disabled={!row.getCanSelect()}
            // checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        id: 'name',
        header: 'Nombre',
        accessorFn: (row) => row.name,
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
            <Text css={{ whiteSpace: 'nowrap' }}>{row.original.name}</Text>
          </Div>
        ),
      },
      {
        id: 'is_subscribed_ips',
        header: 'Conceptos Incluidos',
        meta: {
          content: 'component',
        },
        cell: ({ row }) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '30vw',
              overflow: 'auto',

              '&::-webkit-scrollbar': {
                width: 4,
                height: 4,
              },
            }}
          >
            {row.original.concepts.map((concept) => (
              <Tag
                key={concept.name}
                variant={'neutral'}
                css={{
                  marginRight: '$4',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {concept.name}
              </Tag>
            ))}
          </Div>
        ),
      },
      {
        id: 'should_pay_as_natural_days',
        header: 'Creado en',
        accessorFn: (row) => dayjs(row.created_at).format('DD/MM/YYYY'),
      },
    ]

    return retunedColumns
  }, [])

  return columns
}
