import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdateMovementPayload } from '../types'
import { IMovementV2 } from '@/libs/react-query/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient } from '@/libs/react-query'
dayjs.extend(utc)

const handleUpdateMovement = async (params: UpdateMovementPayload) => {
  const newPeriodType =
    params.period_type === 'multiple'
      ? 'MULTIPLE_DAYS'
      : params.withHours
      ? 'SINGLE_DAY'
      : 'SINGLE_PERIOD'

  const payload = {
    id: params.movement_id,
    title: params.title,
    description: params.description,
    type: newPeriodType,
    category: params.category,
    should_pay_period: !!params.remuneration,
    should_include_in_ips: !!params.ips,
    should_include_hours: !!params.should_include_hours,
    usersIds: params.users,
    periods:
      params.period_type === 'range'
        ? [
            {
              period_start: params.withHours
                ? dayjs(params.periods[0])
                : dayjs(params.periods[0]).startOf('day'),
              period_end: params.withHours
                ? dayjs(params.periods[1])
                : dayjs(params.periods[1]).startOf('day'),
            },
          ]
        : params.period_type === 'multiple'
        ? params.periods.map((period) => {
            return {
              date: dayjs(period).startOf('day'),
            }
          })
        : [],
  }

  const response = await apiV2.post<IMovementV2>(
    '/movement/update-movement',
    payload,
  )
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleUpdateMovements = () => {
  const mutation = useMutation(
    ['useHandleMovementUpdate'],
    handleUpdateMovement,
  )

  return mutation
}
