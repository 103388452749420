import React from 'react'
import { FieldValues, useController, useFormContext } from 'react-hook-form'
import { ControlledBulletPointsP } from './types'
import { Radio } from '@/components/Radio'
import { Div } from '@/components/Div'
import { Text } from '@punto-ui/react'

export const ControlledBulletPoints = <TFieldValues extends FieldValues>(
  props: ControlledBulletPointsP<TFieldValues>,
) => {
  const { control, getValues } = useFormContext()

  const {
    field: { onChange, value },
  } = useController({
    name: props.name,
    control,
    defaultValue: getValues(props.name),
  })

  const onValueChange = (s: string, index?: number) => {
    const selectedOption = props.options.find((o) => o.label === s)

    if (selectedOption) {
      onChange(selectedOption.value)
      props.onValueChange?.(selectedOption.value, index)
    }
  }

  // const inputError = getDeepVal(errors, props.name)
  const selectedOption = props.options.find((o) => o.value === value)

  return (
    <Div>
      {props.label && (
        <Text variant="caption" css={{ marginBottom: '$2' }}>
          {props.label}
        </Text>
      )}
      <Radio
        variant={props.variant}
        direction={props.direction}
        disabled={props.disabled}
        items={props.options.map((o) => o.label)}
        value={selectedOption?.label || ''}
        onValueChange={(e) => {
          onValueChange(e)
        }}
      />
    </Div>
  )
}
