import { generateRandomNumberUnder10 } from '@/utils/random'
import { IReceiptBuilderRowDefinitionType, IReceiptBuilderTypes } from '../type'
import { capitalizeFirstLetter } from '@/utils/workers/name'

export const getLiquidationConceptRowByType = (
  concepts: {
    id: string
    name: string
  }[],
  type: IReceiptBuilderTypes,
  ips: boolean,
  subgroup = 'Informaciones del trabajador',
): IReceiptBuilderRowDefinitionType[] => {
  return concepts.map((c) => ({
    label: `${capitalizeFirstLetter(c.name)}${ips ? ' (IPS)' : ''}`,
    value: `${c.id.toLowerCase()}_liquidation_concept${ips ? '_ips' : ''}`,
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup,
    type,
    isDebit: false,
  }))
}
