import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useContactsData = (index: number) => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const optionalDataForm: AddWorkerFields = useMemo(
    () => [
      {
        _type: 'input',
        label: 'Nombre del Contacto',
        disabled: !isWorkerCreationAvailable,
        inputType: 'name',
        name: `contacts.${index}.name`,
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Documento Contacto',
        name: `contacts.${index}.document`,
      },
      {
        _type: 'input',
        label: 'Teléfono del Contacto',
        disabled: !isWorkerCreationAvailable,
        name: `contacts.${index}.phone`,
      },
      {
        _type: 'input',
        label: 'Relación con el Contacto',
        disabled: !isWorkerCreationAvailable,
        name: `contacts.${index}.relationship`,
      },
      {
        _type: 'input',
        label: 'Fecha de nacimiento del Contacto',
        disabled: !isWorkerCreationAvailable,
        name: `contacts.${index}.birthday`,
        inputType: 'datetime',
      },
      {
        disabled: !isWorkerCreationAvailable,
        _type: 'checkbox',
        label: 'Es un contacto de emergencia',
        name: `contacts.${index}.isEmergencyContact`,
      },
    ],
    [index, isWorkerCreationAvailable],
  )

  return {
    data: optionalDataForm,
  }
}
