import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { SmartShift, WorkersShifts } from '../types'
import { ICell } from '@/libs/react-query/types/organogram'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { smartShiftsFormSchema, SmartShiftsFormSchema } from '../forms'
import { useSideBarContext } from '@/contexts/hooks'
import { useDebounce, useInsideAlerter } from '@/hooks'
import {
  useAvailableCells,
  useSmartShifts,
  useUsersShiftsInDate,
  useWorkers,
} from '@/libs/react-query/hooks'
import dayjs from 'dayjs'

import {
  useHandleCreateShiftSchedule,
  useHandleDeleteByScheduleIds,
  useHandleUpdateShiftSchedule,
} from '@/libs/react-query/mutations/shifts'
import { Div } from '@/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { HelpBar } from '../components/MainContent/components/HelpBar'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { useHandleBatchUpdateShiftSchedule } from '@/libs/react-query/mutations/shifts/useHandleBatchUpdateShiftSchedule'
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export interface UpdateShiftsParams {
  dates: Date[]
  userId: string
}

export interface UpdateScheduledShiftsParams {
  scheduled_shift_id: string
  startDate: Date | null
  endDate: Date | null
  isRestDay?: boolean
}

export interface UpdateBatchScheduledShiftsParams {
  scheduled_shift_ids: string[]
  startDate?: Date | null
  endDate?: Date | null
  isRestDay?: boolean
}

type SmartShiftData = {
  isLoadingFreshData: boolean
  isLoading: boolean
  isFetching: boolean
  usersIds: string[]
  workersShifts: WorkersShifts[]
  selectedCellsIds: string[]
  shifts?: SmartShift[]

  draggedShift: SmartShift | null
  canDrop: boolean
  setDraggedShift: (value: SmartShift | null) => void
  setCanDrop: (value: boolean) => void

  minimalistVision: boolean
  cellUsage: boolean
  filteredBySector: boolean
  selectedCell: ICell | null
  isOverSelected: boolean
  setIsOverSelected: (value: boolean) => void
  handleUpdateScheduledShifts: (
    params: UpdateScheduledShiftsParams,
  ) => Promise<void>
  handleUpdateShifts: (
    params: UpdateShiftsParams[],
    shiftId: string,
    isRest?: boolean,
  ) => void
  handleDeleteByScheduleIds: (data: { ids: string[] }) => Promise<void>
}

type SmartShiftContextData = {
  handleUpdateSmartShiftState: (data: Partial<SmartShiftData>) => void
} & SmartShiftData

type SmartShiftProviderProps = {
  children: ReactNode
}

const SmartShiftContext = createContext({} as SmartShiftContextData)

const datesBetween = (startDate: Date, endDate: Date) => {
  const dates = []
  let currDate = dayjs(startDate).startOf('day').set('hour', 12)
  const lastDate = dayjs(endDate).startOf('day').set('hour', 12)

  const diff = lastDate.diff(currDate, 'days')

  for (let i = 0; i <= diff; i++) {
    dates.push(currDate.clone().format('YYYY-MM-DD'))
    currDate = currDate.add(1, 'day')
  }

  return dates
}

export function SmartShiftManagementProvider({
  children,
}: SmartShiftProviderProps) {
  const smartShiftReference = useRef(null)
  useInsideAlerter(smartShiftReference, () => {
    setIsSidebarOpen(false)
  })
  const { setIsOpen: setIsSidebarOpen } = useSideBarContext()
  const {
    mutateAsync: handleDeleteByScheduleIds,
    isLoading: isLoadingDeleteShiftSchedule,
  } = useHandleDeleteByScheduleIds()
  const { isLoading: isLoadingBatchUpdates } =
    useHandleBatchUpdateShiftSchedule()
  const {
    mutateAsync: handleCreateShiftSchedule,
    isLoading: isLoadingShiftSchedule,
  } = useHandleCreateShiftSchedule()
  const {
    mutateAsync: handleUpdateShiftSchedule,
    isLoading: isLoadingUpdateShiftSchedule,
  } = useHandleUpdateShiftSchedule()

  const { data: cells, isLoading: isLoadingCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('shifts')),
  )

  const [minimalistVision, setMinimalistVision] = useState<boolean>(false)
  const [isOverSelected, setIsOverSelected] = useState<boolean>(false)
  const [selectedCell, setSelectedCell] = useState<ICell | null>(null)
  const [cellUsage, setCellUsage] = useState<boolean>(true)
  const [draggedShift, setDraggedShift] = useState<SmartShift | null>(null)
  const [canDrop, setCanDrop] = useState<boolean>(true)
  const [selectedUserIds, setSelectedUsersIds] = useState<string[]>([])

  const { data: shifts, isLoading: isLoadingSmartShifts } = useSmartShifts()

  const methods = useForm<SmartShiftsFormSchema>({
    resolver: zodResolver(smartShiftsFormSchema),
    defaultValues: {
      shiftsSelection: [],
      usersIds: [],

      cellsIds: cells?.length ? [cells?.[0]?.id] : [],
      dates: [
        dayjs().startOf('month').toISOString(),
        dayjs().endOf('month').toISOString(),
      ],
      name: '',
    },
  })

  const [selectedCellsIds, name, dates] = useWatch({
    control: methods.control,
    name: ['cellsIds', 'name', 'dates'],
  })

  const debouncedCellsIds = useDebounce(selectedCellsIds, 100)
  const debouncedDates = useDebounce(dates, 150)
  const debouncedName = useDebounce(name, 300)

  const { data: workers } = useWorkers(
    {
      page: 0,
      pageSize: 9999,
      name: '',
      cellsIds: debouncedCellsIds ?? [],
      policiesIds: permissionsArray.filter((p) => p.includes('shifts')),
    },
    {
      enabled: cellUsage,
    },
  )

  useEffect(() => {
    if (!isLoadingCells && cells?.length) {
      methods.setValue('cellsIds', [cells[0].id])
    }
  }, [isLoadingCells, cells, methods])

  const handleUpdateSmartShiftState = useCallback(
    (data: Partial<SmartShiftData>) => {
      if (data.minimalistVision !== undefined)
        setMinimalistVision(data.minimalistVision)
      if (data.selectedCell !== undefined) setSelectedCell(data.selectedCell)
      // if (data.period !== undefined) setPeriod(data.period)
      if (data.cellUsage !== undefined) setCellUsage(data.cellUsage)
      if (data.selectedCellsIds !== undefined)
        methods.setValue('cellsIds', data.selectedCellsIds)
      if (data.usersIds !== undefined) setSelectedUsersIds(data.usersIds)
    },
    [],
  )

  const shiftsFilterValues = useMemo(() => {
    if (!debouncedDates[0] || !debouncedDates[1]) {
      return {
        dates: [],
        usersIds: selectedUserIds,
        name: debouncedName,
      }
    }

    const startDate = dayjs(debouncedDates[0]).startOf('day').toDate()
    const endDate = dayjs(debouncedDates[1]).startOf('day').toDate()

    return {
      dates: datesBetween(startDate, endDate),
      usersIds: selectedUserIds,
    }
  }, [selectedUserIds, debouncedName, debouncedDates])

  const {
    data: usersShiftsInDate,
    isLoading: isLoadingUsersShifts,
    isFetching: isFetchingUsersShifts,
  } = useUsersShiftsInDate({
    dates: shiftsFilterValues.dates,
    usersIds: shiftsFilterValues.usersIds,
    cellsIds: selectedCellsIds,
    filter: debouncedName,
  })

  useEffect(() => {
    methods.setValue(
      'shiftsSelection',
      usersShiftsInDate?.map((x) => x.shifts.map(() => false)) ?? [],
    )
  }, [usersShiftsInDate, methods])

  useEffect(() => {
    if (workers && cellUsage) {
      setSelectedUsersIds(workers?.data.map((x) => x.id))
    }
  }, [workers, cellUsage, methods])

  const handleUpdateShifts = useCallback(
    async (params: UpdateShiftsParams[], shiftId: string, isRest?: boolean) => {
      await handleCreateShiftSchedule({
        params,
        shiftId,
        isRest,
      })
    },
    [handleCreateShiftSchedule],
  )

  const handleUpdateScheduledShifts = useCallback(
    async (params: UpdateScheduledShiftsParams) => {
      await handleUpdateShiftSchedule({
        params,
      })
    },
    [handleUpdateShiftSchedule],
  )

  const isLoading =
    isLoadingSmartShifts ||
    isLoadingUsersShifts ||
    isLoadingShiftSchedule ||
    isLoadingUpdateShiftSchedule ||
    isLoadingDeleteShiftSchedule ||
    isLoadingBatchUpdates

  const isLoadingFreshData = isLoadingSmartShifts || isLoadingUsersShifts
  const isFetching = isLoading || isFetchingUsersShifts

  return (
    <FormProvider {...methods}>
      <SmartShiftContext.Provider
        value={{
          isLoadingFreshData,
          cellUsage,
          usersIds: selectedUserIds,
          selectedCellsIds,
          handleUpdateShifts,
          isLoading,
          isFetching,
          shifts,
          workersShifts: usersShiftsInDate ?? [],
          minimalistVision,
          filteredBySector: false,
          selectedCell,
          handleUpdateSmartShiftState,
          isOverSelected,
          setIsOverSelected,
          handleUpdateScheduledShifts,
          handleDeleteByScheduleIds,
          draggedShift,
          setDraggedShift,
          canDrop,
          setCanDrop,
        }}
      >
        <Div
          ref={smartShiftReference}
          css={{
            flex: 1,
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          }}
        >
          {children}
          <HelpBar />
        </Div>
      </SmartShiftContext.Provider>
    </FormProvider>
  )
}

export function useSmartShift() {
  const context = useContext(SmartShiftContext)

  if (!context) {
    throw new Error('useSmartShift must be used within an SmartShiftProvider')
  }

  return context
}
