import { ControlledFilterBar, Div } from '@/components'
import { FolderStructureComponent } from './FolderComponent'
import { DocumentsAppBar } from './DocumentsAppBar'
import { AddFolderDrawer } from './AddFolderDrawer'
import { useDocumentsStructure } from '@/libs/react-query/hooks/useDocumentsStructure'
import { useHandleChangeFolderSettings } from '@/libs/react-query/mutations/documents/useHandleChangeFolderSettings'
import { useHandleCreateFolder } from '@/libs/react-query/mutations/documents/useHandleCreateFolder'
import { EditFolderDrawer } from './EditFolderDrawer'

export const DocumentsStructure = () => {
  const { isFetching: isFetchingDocuments } = useDocumentsStructure()
  const { isLoading: isLoadingChangeFolder } = useHandleChangeFolderSettings()
  const { isLoading: isLoadingCreateFolder } = useHandleCreateFolder()

  return (
    <>
      <ControlledFilterBar
        policiesIds={[]}
        isLoading={
          isLoadingCreateFolder || isFetchingDocuments || isLoadingChangeFolder
        }
      />
      <Div
        css={{
          padding: '$4',
          position: 'relative',
          height: 'calc(100vh - 90px)',
        }}
      >
        <FolderStructureComponent />
        <DocumentsAppBar />
        <AddFolderDrawer />
        <EditFolderDrawer />
      </Div>
    </>
  )
}
