import { IVacation } from '@/libs/react-query/types/vacations'
// import { useDayDetail } from '../store'
import { usePayrollDay } from '../usePayrollDay'
import { Div, Touchable } from '@/components'
import { Tag, Text } from '@punto-ui/react'

export const VacationDayDetail = ({ vacation }: { vacation: IVacation }) => {
  const { isFetching: isLoadingPayrollDay } = usePayrollDay()
  // const { updateView } = useDayDetail((state) => ({
  //   updateView: state.updateView,
  // }))

  return (
    <Touchable
      css={{
        cursor: isLoadingPayrollDay ? 'progress' : 'unset',
      }}
      onClick={() => null}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '$4',
          paddingRight: '$4',
          paddingBottom: '$4',
          paddingTop: '$4',
          borderBottom: '1px solid',
          borderBottomColor: '$interface_light_down',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text variant={'paragraph'} css={{ color: '$interface_dark_deep' }}>
              {vacation.name}
            </Text>
            <Text
              variant={'description'}
              css={{ color: '$interface_dark_down' }}
            >
              {vacation.description}
            </Text>
          </Div>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            {vacation.pay_within_payroll && <Tag>Incluso en Planilla</Tag>}
            <Tag>Autorizado</Tag>
          </Div>
        </Div>
      </Div>
    </Touchable>
  )
}
