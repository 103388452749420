import { useMemo } from 'react'
import { ExportTemplateFields } from './form'
import { months } from '@/utils/date'
import { IMonth } from '@/utils/date/types'

export const yearsOptions = Array.from({ length: 10 }, (_, i) => ({
  label: (new Date().getFullYear() - i).toString(),
  value: (new Date().getFullYear() - i).toString(),
}))

export const useExportTemplateDateForm = ({
  selectedYear,
  selectedMonth,
  prefix,
}: {
  prefix?: string
  selectedYear?: {
    label: string
    value: string
  }
  selectedMonth?: IMonth
}) => {
  const name = prefix ? `${prefix}.` : ''
  const data = useMemo(() => {
    const monthAndYearForm: ExportTemplateFields = [
      {
        name: `${name}month` as 'month',
        _type: 'dropdown',
        label: 'Mes del reporte',
        options: months.map((month) => ({
          label: month.name,
          value: month.jsNumber.toString(),
        })),
        defaultOption: {
          label: selectedMonth?.name || '',
          value: selectedMonth?.jsNumber.toString() || '',
        },
      },
      {
        name: `${name}year` as 'year',
        _type: 'dropdown',
        label: 'Año del reporte',
        options: yearsOptions,
        defaultOption: {
          label: selectedYear?.label || '',
          value: selectedYear?.value || '',
        },
      },
    ]

    const dateFormByType: Record<string, ExportTemplateFields> = {
      day: [
        {
          name: `${name}day` as 'day',
          _type: 'calendar',
          label: 'Rango de tiempo del reporte',
          calendarType: 'single',
          maxDates: 999,
        },
      ],
      year: [
        {
          name: `${name}year` as 'year',
          _type: 'dropdown',
          label: 'Año del reporte',
          options: yearsOptions,
          defaultOption: {
            label: selectedYear?.label || '',
            value: selectedYear?.value || '',
          },
        },
      ],
      month: [
        {
          name: `${name}year` as 'year',
          _type: 'dropdown',
          label: 'Año del reporte',
          options: yearsOptions,
          defaultOption: {
            label: selectedYear?.label || '',
            value: selectedYear?.value || '',
          },
        },
      ],
      days: monthAndYearForm,
      workersMonthly: monthAndYearForm,
      months: [
        {
          name: `${name}year` as 'year',
          _type: 'dropdown',
          label: 'Mes del reporte',
          options: yearsOptions,
          defaultOption: {
            label: selectedYear?.label || '',
            value: selectedYear?.value || '',
          },
        },
      ],
      sectors: monthAndYearForm,
      entities: [
        {
          name: `${name}dates` as 'dates',
          _type: 'calendar',
          label: 'Rango de tiempo del reporte',
          calendarType: 'range',
          maxDates: 90,
        },
      ],
    }

    return dateFormByType
  }, [selectedYear, selectedMonth])

  return {
    data,
  }
}
