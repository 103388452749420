import { insertArrayAndReplace } from '@/utils/array'
import { reportBuilderColumnsByRowAndType } from './constants'
import { reportBuilderColumnsByRowAndTypeAndSubtype } from './constants/columns-by-row-and-type-and-subtype'
import { getConceptColumnByType } from './constants/concepts-columns-by-row-and-type'
// import { reportBuilderDetailColumnsByRowAndType } from './constants/detail-columns-by-row-and-type'
import { reportBuilderRowDetailsByTypeAndRow } from './constants/row-details-by-type-and-row'
import { reportBuilderRowsByType } from './constants/rows-by-type'
import {
  IReportBuilderColumnType,
  IReportBuilderRowTypes,
  IReportBuilderTypes,
  ReportBuilderColumnType,
} from './type'

export const fromRowTypeToLabel: Record<IReportBuilderRowTypes, string> = {
  punches: 'Marcaciones',
  shifts: 'Turnos',
  movements: 'Movimientos',
  payments: 'Pagos',
  vacations: 'Vacaciones',
  period: 'Periodos',
  payrolls: 'Nomina',
  workers: 'Colaboradores',
  sectors: 'Sectores',
  day: 'Dia',
  month: 'Mes',
  year: 'Año',
  entities: 'Entidades',
  permissions: 'Grupos',
  sueldos: 'Sueldos y Jornales',
}

export const fromReportTypeToRowType = (type: IReportBuilderTypes) => {
  return reportBuilderRowsByType[type]
}

export const fromReportTypeToRows = (type: IReportBuilderTypes) => {
  return (
    reportBuilderRowsByType[type]?.map((rowType) => {
      const rowDetail =
        reportBuilderRowDetailsByTypeAndRow[type]?.[rowType] || []

      return {
        row_type: rowType,
        details: rowDetail,
        selected: false,
        disabled: true,
      }
    }) || []
  )
}

export const fromReportTypeToRowsV2 = (type: IReportBuilderTypes) => {
  return (
    reportBuilderRowsByType[type]?.map((rowType) => {
      return {
        value: rowType,
        label: fromRowTypeToLabel[rowType],
        details: (
          reportBuilderRowDetailsByTypeAndRow[type]?.[rowType] || []
        ).map((detail) => ({
          label: detail.label,
          value: detail.value,
        })),
      }
    }) || []
  )
}

export const fromReportTypeAndRowTypesToColumns = (
  type: IReportBuilderTypes,
  rowTypes: {
    type: IReportBuilderRowTypes
    detailKey?: string
    detailSelectedKeys?: boolean[]
  }[],
  allCompanyConcepts: string[],
  defaultSelected: {
    name: string
    type: IReportBuilderRowTypes
  }[],
) => {
  const result: Record<string, ReportBuilderColumnType[]> = {}
  rowTypes.forEach((rowType) => {
    let reportColumns: IReportBuilderColumnType[] = []

    if (!rowType.detailKey) {
      reportColumns =
        reportBuilderColumnsByRowAndType[type]?.[rowType.type] || []
    } else if (rowType.detailKey) {
      reportColumns =
        reportBuilderColumnsByRowAndTypeAndSubtype[type]?.[rowType.type]?.[
          rowType.detailKey as IReportBuilderRowTypes
        ] || []
    }

    if (rowType.detailSelectedKeys?.length) {
      const rowDetails =
        reportBuilderRowDetailsByTypeAndRow[type]?.[rowType.type] || []

      rowType.detailSelectedKeys.forEach((selected, index) => {
        if (selected) {
          reportColumns = reportColumns.concat(
            reportBuilderColumnsByRowAndTypeAndSubtype[type]?.[rowType.type]?.[
              rowDetails[index].value as IReportBuilderRowTypes
            ] || [],
          )
        }
      })
    }

    const conceptsColumns = reportColumns.filter((column) =>
      column.value.includes('salary_concepts'),
    )

    conceptsColumns.forEach((conceptColumn) => {
      const reportColumnConceptIndex = reportColumns.findIndex((column) =>
        column.value.includes(conceptColumn.value),
      )

      if (reportColumnConceptIndex === -1) {
        return
      }

      const isIps = conceptColumn.value.includes('ips')

      const conceptColumns = getConceptColumnByType(
        allCompanyConcepts,
        conceptColumn.type,
        isIps,
        conceptColumn.subgroup || 'Salario del Colaborador',
      )

      reportColumns = insertArrayAndReplace(
        reportColumns,
        conceptColumns,
        reportColumnConceptIndex,
      )
    })

    reportColumns.forEach((column) => {
      const isSelected =
        type === 'collective' &&
        rowType.type === 'workers' &&
        column.type === 'workers' &&
        (column.value === 'name' || column.value === 'surname')
      const isDefaultSelected = defaultSelected?.find(
        (d) => d.name === column.value && d.type === column.type,
      )

      if (!result[column.type]) {
        result[column.type] = [
          {
            label: column.label,
            detail_type: column.subgroup,
            value: column.value,
            valueType: column.valueType,
            selected: isSelected || !!isDefaultSelected,
            disabled: true,
            column_type: column.type,
            getRandomPlaceholder: column.getRandomPlaceholder,
          },
        ]
      } else {
        result[column.type].push({
          label: column.label,
          detail_type: column.subgroup,
          valueType: column.valueType,
          value: column.value,
          selected: isSelected || !!isDefaultSelected,
          disabled: true,
          column_type: column.type,
          getRandomPlaceholder: column.getRandomPlaceholder,
        })
      }
    })
  })

  return result
}

export const fromReportTypeAndRowTypeToColumns = (
  type: IReportBuilderTypes,
  rowType: IReportBuilderRowTypes,
) => null
