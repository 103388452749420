import {
  debitPaymentsHeaderRaw,
  paymentOptions,
} from '@/libs/react-query/types'
import { IReceiptBuilderRowDefinitionType } from '../type'
import { generateRandomHour, generateRandomNumberUnder10 } from '@/utils/random'

export const payrollReceiptRows: IReceiptBuilderRowDefinitionType[] = [
  {
    label: 'Valor dias trabajados',
    value: 'worked_days',
    subgroup: 'Dias',
    type: 'payroll',
    isDebit: false,
    getRandomPlaceholder: generateRandomNumberUnder10,
  },
  {
    label: 'Adicional Nocturno',
    value: 'night_value',
    subgroup: 'Horas',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    type: 'payroll',
  },
  {
    label: 'Horas extras diurnas',
    value: 'morning_extra_value',
    subgroup: 'Horas',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    type: 'payroll',
  },
  {
    label: 'Horas extras nocturnas',
    value: 'night_extra_value',
    subgroup: 'Horas',
    getRandomPlaceholder: generateRandomHour,
    isDebit: false,
    type: 'payroll',
  },
  {
    label: 'Horas feriado diurnas',
    value: 'morning_holiday_value',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Horas',
    type: 'payroll',
  },
  {
    label: 'Horas feriado nocturnas',
    value: 'night_holiday_value',
    isDebit: false,
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Horas',
    type: 'payroll',
  },
  {
    label: 'Desc. de horas diurnas',
    value: 'morning_discount_hours_value',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Horas',
    isDebit: true,
    type: 'payroll',
  },
  {
    label: 'Desc. de horas nocturnas',
    value: 'night_discount_hours_value',
    getRandomPlaceholder: generateRandomHour,
    isDebit: true,
    subgroup: 'Horas',
    type: 'payroll',
  },
  {
    label: 'Bonificación Familiar',
    value: 'family_bonus',
    subgroup: 'Otros ingressos',
    getRandomPlaceholder: generateRandomNumberUnder10,
    isDebit: false,
    type: 'payroll',
  },
  {
    label: 'Valor de Vacaciones',
    value: 'vacation_value',
    subgroup: 'Otros ingressos',
    getRandomPlaceholder: generateRandomNumberUnder10,
    isDebit: false,
    type: 'payroll',
  },
  {
    label: '',
    value: 'salary_concepts',
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup: 'Conceptos de Salário',
    isDebit: false,
    type: 'payroll',
  },
  {
    label: '',
    value: 'salary_concepts_ips',
    getRandomPlaceholder: generateRandomNumberUnder10,
    isDebit: false,
    subgroup: 'Conceptos de Salário (IPS)',
    type: 'payroll',
  },
  ...paymentOptions.map((m) => ({
    label: m.label,
    value: `${m.value}_value_ips`,
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup: 'Pagos (IPS)',
    isDebit: debitPaymentsHeaderRaw.includes(m.value as any),
    type: 'payroll' as const,
  })),
  ...paymentOptions.map((m) => ({
    label: m.label,
    value: `${m.value}_value`,
    getRandomPlaceholder: generateRandomNumberUnder10,
    subgroup: 'Pagos (sin IPS)',
    isDebit: debitPaymentsHeaderRaw.includes(m.value as any),
    type: 'payroll' as const,
  })),
  {
    label: 'IPS 9%',
    value: 'ips9',
    isDebit: true,
    getRandomPlaceholder: () => '-',
    subgroup: 'IPS',
    type: 'payroll',
  },
  {
    label: 'IPS 16%',
    value: 'ips16',
    isDebit: true,
    getRandomPlaceholder: () => '-',
    subgroup: 'IPS',
    type: 'payroll',
  },
]
