import { create } from 'zustand'

export const aguinaldoPlaygroundSteps = [
  {
    name: 'Configuraciones',
    value: 'CONFIG',
  },
  {
    name: 'Planillas',
    value: 'PAYROLLS',
  },
  {
    name: 'Valores',
    value: 'VALUES',
  },
]

type AguinaldoDrawerType = 'EXPORT' | 'BATCH_EDITING'

interface AguinaldoPlaygroundStoreTypes {
  drawer: {
    isOpen: boolean
    type: AguinaldoDrawerType
    data: any
  }

  currentStepValue: string
  currentStepNumber: number
  canGoBack: boolean
  canGoNext: boolean

  handleNextStep: () => void
  handlePreviousStep: () => void
  handleChangeStep: (step: string) => void
  handleChangeStepIndex: (stepIndex: number) => void
  handleOpenDrawer: (type: AguinaldoDrawerType, data: any) => void
  handleCloseDrawer: () => void
}

export const useAguinaldoPlaygroundStore =
  create<AguinaldoPlaygroundStoreTypes>((set, get) => {
    return {
      currentStepValue: 'CONFIG',
      currentStepNumber: 0,
      drawer: {
        isOpen: false,
        type: 'EXPORT',
        data: null,
      },

      canGoBack: false,
      canGoNext: true,

      handleCloseDrawer: () => {
        set({
          drawer: {
            isOpen: false,
            type: 'EXPORT',
            data: null,
          },
        })
      },
      handleChangeStep: (stepName: string) => {
        const newStepIndex = aguinaldoPlaygroundSteps.findIndex(
          (s) => s.value === stepName,
        )

        set({
          currentStepValue: aguinaldoPlaygroundSteps[newStepIndex].value,
          currentStepNumber: newStepIndex,
          canGoBack: newStepIndex > 0,
          canGoNext: newStepIndex < aguinaldoPlaygroundSteps.length - 1,
        })
      },
      handleChangeStepIndex: (stepIndex: number) => {
        set({
          currentStepValue: aguinaldoPlaygroundSteps[stepIndex].value,
          currentStepNumber: stepIndex,
          canGoBack: stepIndex > 0,
          canGoNext: stepIndex < aguinaldoPlaygroundSteps.length - 1,
        })
      },
      handleNextStep: () => {
        const nextStep = get().currentStepNumber + 1

        if (nextStep >= aguinaldoPlaygroundSteps.length) {
          return
        }

        set({
          currentStepValue: aguinaldoPlaygroundSteps[nextStep].value,
          currentStepNumber: nextStep,
          canGoBack: nextStep > 0,
          canGoNext: nextStep < aguinaldoPlaygroundSteps.length - 1,
        })
      },
      handlePreviousStep: () => {
        const previousStep = get().currentStepNumber - 1

        if (previousStep < 0) {
          return
        }

        set({
          currentStepValue: aguinaldoPlaygroundSteps[previousStep].value,
          currentStepNumber: previousStep,
          canGoBack: previousStep > 0,
          canGoNext: previousStep < aguinaldoPlaygroundSteps.length - 1,
        })
      },
      handleOpenDrawer: (type, data) => {
        set({
          drawer: {
            isOpen: true,
            type,
            data,
          },
        })
      },
    }
  })
