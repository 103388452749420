import { useEffect } from 'react'
import { useTabStore } from '@/store'
import {
  AdjustmentsHorizontalIcon,
  CheckBadgeIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline'
import { useCan } from '@/hooks'
import { useHandleCreatePdf } from '@/libs/react-query/mutations/reports/useHandleCreatePdf'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { PdfBuilderSchemaType } from './types'
import { buildPdfCreationDTO } from './parser'
import useToast from '@/hooks/useToast'
import { useHandleUpdatePdf } from '@/libs/react-query/mutations/reports/useHandleUpdatePdf'
import { IUpdatePdfDTO } from '@/libs/react-query/types/reports'
import { IRouteAction } from '@/pages/router'

export const useHandlePdfBuilderActions = ({
  methods,
  isLoading,
  setSelectedTemplateId,
}: {
  methods: UseFormReturn<PdfBuilderSchemaType>
  isLoading?: boolean
  setSelectedTemplateId: (id: string) => void
}) => {
  const canCreatePdf = useCan(['reports.createTemplate'])
  const toast = useToast()

  const { setActions, setActiveTab, tabs, removeTab } = useTabStore(
    (state) => ({
      removeTab: state.removeTab,
      setActiveTab: state.setActiveTab,
      tabs: state.reportsV2.tabs,
      setActions: state.setActionsTab,
      addTab: state.addTab,
    }),
  )

  const { mutateAsync: handleCreatePdf, isLoading: isLoadingCreatePdf } =
    useHandleCreatePdf()
  const { mutateAsync: handleUpdatePdf, isLoading: isLoadingUpdatePdf } =
    useHandleUpdatePdf()

  const isUpdate = useWatch({
    control: methods.control,
    name: 'id',
  })

  useEffect(() => {
    if (canCreatePdf) {
      const actions: IRouteAction[] = []

      if (isUpdate) {
        actions.push({
          action: () => {
            const id = methods.getValues().id
            setSelectedTemplateId(id)
          },
          label: 'Generar PDF',
          width: 140,
          isLoading: isLoading || isLoadingCreatePdf || isLoadingUpdatePdf,
          disabled: false,
          variant: 'primary',
          icon: <AdjustmentsHorizontalIcon />,
        })
      }

      if (isUpdate) {
        actions.push({
          action: async () => {
            const data = methods.getValues()
            const dto = buildPdfCreationDTO(data)

            try {
              await handleCreatePdf({
                ...dto,
                name: `${dto.name} (Copia)`,
              })

              toast.addToast({
                title: 'PDF duplicado con éxito',
                type: 'positive',
                description: 'El template ha sido duplicado con éxito',
                id: Date.now(),
              })

              const existingTab = tabs.find((tab) => tab.type === 'add-pdf')
              if (existingTab) {
                removeTab('reportsV2', existingTab.id)
                setActiveTab('reportsV2', 'pdf-hub')
              }
            } catch (err) {
              console.error(err)

              toast.addToast({
                title: 'Error al duplicar PDF',
                type: 'negative',
                description: '',
                id: Date.now(),
              })
            }
          },
          label: 'Duplicar',
          width: 100,
          isLoading: isLoading || isLoadingCreatePdf || isLoadingUpdatePdf,
          disabled: !canCreatePdf,
          variant: 'secondary',
          icon: <Square2StackIcon />,
        })
      }

      actions.push({
        action: async () => {
          const data = methods.getValues()
          const dto = buildPdfCreationDTO(data)

          try {
            if (data.id) {
              await handleUpdatePdf(dto as IUpdatePdfDTO)
            } else {
              await handleCreatePdf(dto)
            }

            toast.addToast({
              title: isUpdate
                ? 'PDF actualizado con éxito'
                : 'PDF creado con éxito',
              type: 'positive',
              description: 'El template ha sido actualizado con éxito',
              id: Date.now(),
            })

            if (!isUpdate) {
              const existingTab = tabs.find((tab) => tab.type === 'add-pdf')
              if (existingTab) {
                removeTab('reportsV2', existingTab.id)
                setActiveTab('reportsV2', 'pdf-hub')
              }
            }
          } catch (err) {
            console.error(err)

            toast.addToast({
              title: isUpdate
                ? 'Error al actualizar PDF'
                : 'Error al crear PDF',
              type: 'negative',
              description: '',
              id: Date.now(),
            })
          }
        },
        label: isUpdate ? 'Actualizar PDF' : 'Crear PDF',
        width: 160,
        isLoading: isLoading || isLoadingCreatePdf || isLoadingUpdatePdf,
        disabled: !canCreatePdf,
        variant: 'primary',
        icon: <CheckBadgeIcon />,
      })

      setActions('reportsV2', actions)
    } else {
      setActions('reportsV2', [])
    }
  }, [
    canCreatePdf,
    isLoading,
    isUpdate,
    toast,
    isLoadingCreatePdf,
    isLoadingUpdatePdf,
  ])
}
