import { IRouteTab, Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { ContextProvider as MovementsProvider } from '../movimientos/context'
import {
  BanknotesIcon,
  ListBulletIcon,
  PlusIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { PayrollPlayground } from './payroll-playground'
import { PayrollList } from './payroll-listing'
import React, { useEffect, useMemo, useRef } from 'react'
import { UserTab } from './user-details'
import { AguinaldoList } from './aguinaldo-listing'
import { AguinaldoPlayground } from './aguinaldo-playground'
import { useCan } from '@/hooks'

const PayrollPlaygroundMemo = React.memo(PayrollPlayground)

const PayrollTabs = {
  payroll: <PayrollPlayground />,
}

export default function Planilla({ onlyTabs = false }: { onlyTabs?: boolean }) {
  const possibleLeak = useRef<any>(null)
  const { tabsInfos, addTab, changeTab, removeTab } = useTabStore((state) => ({
    tabsInfos: state.payroll,
    removeTab: state.removeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const tabs = useMemo(() => {
    const changeableTabs: IRouteTab[] = []
    tabsInfos.tabs.forEach((tab) => {
      if (tab.type === 'new') {
        changeableTabs.push({
          component: PayrollTabs.payroll,
          icon: <PlusIcon />,
          label: 'Crear Planilla',
          value: 'new',
          closeable: true,
          handleClose: () => {
            removeTab('payroll', 'new')
            possibleLeak.current = setTimeout(() => {
              changeTab('payroll', 'list')
            }, 20)
          },
        })
      }

      if (tab.type === 'new-aguinaldo') {
        changeableTabs.push({
          component: <AguinaldoPlayground />,
          icon: <PlusIcon />,
          label: 'Crear Aguinaldo',
          value: 'new-aguinaldo',
          closeable: true,
          handleClose: () => {
            removeTab('payroll', 'new-aguinaldo')
            possibleLeak.current = setTimeout(() => {
              changeTab('payroll', 'aguinaldo')
            }, 20)
          },
        })
      }

      if (tab.type === 'payroll') {
        changeableTabs.push({
          component: <PayrollPlaygroundMemo id={tab.id} />,
          icon: <TableCellsIcon />,
          label: tab.label || 'Planilla',
          value: tab.id,
          closeable: true,
          handleClose: () => {
            removeTab('payroll', tab.id)
            possibleLeak.current = setTimeout(() => {
              changeTab('payroll', 'list')
            }, 20)
          },
        })
      }

      if (tab.type === 'aguinaldo') {
        changeableTabs.push({
          component: <AguinaldoPlayground id={tab.id} />,
          icon: <TableCellsIcon />,
          label: tab.label || 'Aguinaldo',
          value: tab.id,
          closeable: true,
          handleClose: () => {
            removeTab('payroll', tab.id)
            possibleLeak.current = setTimeout(() => {
              changeTab('payroll', 'aguinaldo')
            }, 20)
          },
        })
      }

      if (tab.type === 'user-detail') {
        changeableTabs.push({
          component: <UserTab id={tab.id} date={tab.metadata.date} />,
          icon: <TableCellsIcon />,
          label: tab.label || 'Planilla',
          value: tab.id,
          closeable: true,
          handleClose: () => {
            removeTab('payroll', tab.id)
            possibleLeak.current = setTimeout(() => {
              changeTab('payroll', 'list')
            }, 20)
          },
        })
      }
    })

    return changeableTabs
  }, [tabsInfos, removeTab, changeTab])

  const defaultActionsPerTab =
    tabsInfos.activeTab === 'list'
      ? [
          {
            label: 'Crear Planilla',
            icon: <PlusIcon />,
            width: 150,
            action: () => {
              addTab('payroll', [
                {
                  id: 'new',
                  type: 'new',
                },
              ])
              changeTab('payroll', 'new')
            },
            variant: 'primary' as const,
          },
        ]
      : tabsInfos.activeTab === 'aguinaldo'
      ? [
          {
            label: 'Crear Aguinaldo',
            icon: <PlusIcon />,
            width: 150,
            action: () => {
              addTab('payroll', [
                {
                  id: 'new-aguinaldo',
                  type: 'new-aguinaldo',
                },
              ])
              changeTab('payroll', 'new-aguinaldo')
            },
            variant: 'primary' as const,
          },
        ]
      : []

  useEffect(() => {
    return () => {
      clearTimeout(possibleLeak.current)
    }
  }, [])

  const currentValidAction = [...tabsInfos.actions]

  const canManageAguinaldos = useCan(['payroll.aguinaldo'])

  return (
    <MovementsProvider>
      <Router
        onlyTabs={onlyTabs}
        actions={
          defaultActionsPerTab.length
            ? defaultActionsPerTab
            : currentValidAction
        }
        defaultTab="list"
        setTab={(tab) => {
          changeTab('payroll', tab)
        }}
        tab={tabsInfos.activeTab || 'list'}
        tabs={[
          {
            component: <PayrollList />,
            icon: <BanknotesIcon />,
            label: 'Planillas',
            value: 'list',
          },
          {
            component: <AguinaldoList />,
            icon: <ListBulletIcon />,
            disabled: !canManageAguinaldos,
            label: 'Aguinaldos',
            value: 'aguinaldo',
          },
          ...tabs,
        ]}
      />
    </MovementsProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
