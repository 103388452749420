import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  BatchEditingSchema,
  DEFAULT_BATCH_EDITING,
  batchEditingSchema,
} from './form'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { Button, Tag, Text } from '@punto-ui/react'
import { Div, FormRenderer } from '@/components'
import { CheckIcon } from '@heroicons/react/24/outline'
import useToast from '@/hooks/useToast'
import { allMonths } from '@/components/InputDatePicker/utils'
import { usePayrolls } from '@/libs/react-query/hooks'
import { ControlledFullWorkerSearchBar } from '@/components/Forms'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useMemo } from 'react'
import { AguinaldoPlaygroundTableType } from '../../../types'
import { useHandleUpdateAguinaldo } from '@/libs/react-query/mutations/aguinaldo/useHandleUpdateAguinaldo'

interface BatchEditingParams {
  handleCloseDrawer: () => void
}

export const BatchEditing = ({ handleCloseDrawer }: BatchEditingParams) => {
  const { data: payrolls } = usePayrolls()
  const toast = useToast()

  const methods = useForm<BatchEditingSchema>({
    resolver: zodResolver(batchEditingSchema),
    defaultValues: {
      ...DEFAULT_BATCH_EDITING,
    },
  })

  const { getValues, control } = useFormContext<AguinaldoPlaygroundTableType>()

  const aguinaldoUsersIds = useWatch({
    control,
    name: 'users_ids',
  })

  const [isPayrollSelection, usersIds] = useWatch({
    control: methods.control,
    name: ['isPayrollSelection', 'usersIds'],
  })

  const {
    mutateAsync: handleUpdateAguinaldo,
    isLoading: isLoadingUpdateAguinaldo,
  } = useHandleUpdateAguinaldo()

  const submitAguinaldoEditing = async () => {
    try {
      const toastId = Date.now()
      const data = methods.getValues()
      const aguinaldoData = getValues()

      await handleUpdateAguinaldo({
        id: aguinaldoData.id,
        month: Number(data.month),
        payrollId: isPayrollSelection ? data.payrollId : null,
        usersIds: data.usersIds,
        value: isPayrollSelection ? null : Number(data.value),
      })

      toast.addToast({
        title: 'Edición completa',
        description: '',
        type: 'positive',
        timeout: 2000,
        id: toastId,
        buttonLabel: 'Cerrar',
        callback: () => {
          toast.removeToast(toastId)
        },
      })

      methods.reset()

      // handleCloseDrawer()
    } catch (error) {
      console.log(error)
      toast.addToast({
        title: 'Error',
        description: 'Ocurrio un error al solicitar el reporte',
        type: 'negative',
        id: Date.now(),
      })
    }
  }

  const formFields: ControlledFieldProps<BatchEditingSchema>[] = useMemo(() => {
    const closedPayrolls = payrolls?.filter((p) => p.status === 'closed')

    const result: ControlledFieldProps<BatchEditingSchema>[] = [
      {
        _type: 'dropdown',
        name: 'month',
        label: 'Mes',
        options: allMonths.map((month) => ({
          label: month.label,
          value: month.value,
        })),
        defaultOption: {
          label: allMonths[0].label,
          value: allMonths[0].value,
        },
      },
      {
        _type: 'switch',
        name: 'isPayrollSelection',
        label: isPayrollSelection ? 'Seleción de Planilla' : 'Valor Fijo',
      },
    ]

    if (!isPayrollSelection) {
      result.push({
        _type: 'input',
        name: 'value',
        label: 'Valor',
        inputType: 'money-guarani',
      })
    } else {
      result.push({
        _type: 'dropdown',
        name: 'payrollId',
        label: 'Planilla',
        options:
          payrolls
            ?.filter((p) => p.status === 'closed')
            ?.map((payroll) => ({
              label: payroll.name,
              value: payroll.id,
            })) || [],
        defaultOption: {
          label: closedPayrolls?.[0]?.name || '',
          value: closedPayrolls?.[0]?.id || '',
        },
      })

      result.push({
        _type: 'custom',
        name: 'payrollId',
        component: (
          <Tag variant={'neutral'}>
            Solamente planillas cerradas pueden ser utilizadas para la edición
            en lote
          </Tag>
        ),
      })
    }

    return result
  }, [isPayrollSelection, payrolls])
  console.log(methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          maxWidth: 'calc(50vw)',
          minWidth: 'calc(50vw)',
          // maxHeight: '100vh',
          // overflow: 'scroll',
          // zIndex: 98,
        }}
      >
        <Div
          css={{
            padding: '$4',
            overflow: 'scroll',
            maxHeight: 'calc(100vh)',
          }}
        >
          <Text
            as="h3"
            variant={'subtitle1'}
            css={{
              color: '$brand_primary_pure',
              marginBottom: '$4',
            }}
          >
            Edición de Aguinaldo en Lote
          </Text>
          <Text
            variant={'description'}
            css={{
              marginBottom: '$8',
            }}
          >
            Selecione las informaciones de edición en lote.
          </Text>
          <FormRenderer items={formFields} cols={1} inline />
          <Div css={{ marginBottom: '$8' }} />
          <ControlledFullWorkerSearchBar<BatchEditingSchema>
            css={{
              height: 'calc(60vh)',
              zIndex: 0,
            }}
            name={'usersIds'}
            usersIds={usersIds}
            policiesIds={permissionsArray.filter((p) => p.includes('payroll'))}
            usersIdsFilter={aguinaldoUsersIds}
          />
          <Div
            css={{
              width: '100%',
              marginTop: '$12',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDrawer}
              disabled={isLoadingUpdateAguinaldo}
              variant="secondary"
              type="button"
              css={{ width: '100%', marginRight: '$2' }}
            >
              Cancelar
            </Button>
            <Button
              isLoading={isLoadingUpdateAguinaldo}
              disabled={isLoadingUpdateAguinaldo}
              variant="primary"
              onClick={submitAguinaldoEditing}
              type="submit"
              css={{ width: '100%', marginLeft: '$2' }}
            >
              <CheckIcon />
              Guardar
            </Button>
          </Div>
        </Div>
      </Div>
    </FormProvider>
  )
}
