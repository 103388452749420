import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const useIntegrationData = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const optionalDataForm: AddWorkerFields = useMemo(
    () => [
      {
        _type: 'input',
        label: 'Código del Colaborador',
        name: 'default_config_id',
        disabled: !isWorkerCreationAvailable,
      },
      {
        _type: 'input',
        label: 'ID de Integración - Datapar',
        name: 'datapar_id',
        disabled: !isWorkerCreationAvailable,
      },
      {
        _type: 'input',
        label: 'ID de Integración - Marcación Externa',
        disabled: !isWorkerCreationAvailable,
        name: 'marcacion_id',
      },
    ],
    [isWorkerCreationAvailable],
  )

  return {
    data: optionalDataForm,
  }
}
