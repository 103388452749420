import { Div } from '@/components'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormGroup } from './FormGroup'
import { useContactsData } from '../../../forms'
import { useWorkerCreation } from '../../../context/hooks'
import { useIsWorkerFormAvailable } from '../../../hooks/useIsWorkerFormAvailable'

export const ContactsData = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()
  const { setValue, getValues, control } =
    useFormContext<createCompleteWorkerData>()

  const handleAddContact = () => {
    const data = getValues()
    setValue('contacts', [
      ...(data.contacts || []),
      {
        birthday: '',
        id: String(Date.now()),
        document: '',
        isEmergencyContact: false,
        name: '',
        phone: '',
        relationship: '',
        isNew: true,
      },
    ])
  }

  const contacts = useWatch({
    control,
    name: 'contacts',
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Text
        size="xl"
        css={{
          color: '$brand_primary_pure',
          fontWeight: '$bold',
          marginBottom: '$4',
        }}
      >
        Datos de Contacto
      </Text>

      <Button
        disabled={!isWorkerCreationAvailable}
        onClick={handleAddContact}
        variant="primary"
      >
        Agregar Contato
      </Button>
      <Div
        css={{
          padding: '$4',
          border: 'solid',
          borderWidth: contacts?.length ? '2px' : 0,
          borderColor: '$interface_dark_up',
          borderRadius: '$md',
          marginTop: '$6',
        }}
      >
        {contacts?.map((contact, index) => (
          <Div
            key={contact.id}
            css={{ display: 'flex', flexDirection: 'column' }}
          >
            <ContactForm contact={contact} index={index} />
            {contacts?.length - 1 !== index && (
              <Div
                css={{
                  height: 2,
                  width: '100%',
                  backgroundColor: '$interface_dark_up',
                  marginTop: '$6',
                }}
              />
            )}
          </Div>
        ))}
      </Div>
    </Div>
  )
}

const ContactForm = ({ index, contact }: any) => {
  const { data: contactsData } = useContactsData(index)
  const { setValue, getValues } = useFormContext<createCompleteWorkerData>()
  const { isCreation } = useWorkerCreation()

  const handleDeleteChild = () => {
    const data = getValues()

    const newContacts = data.contacts?.map((item: any) => {
      if (item.id === contact.id) {
        return {
          ...item,
          id: `DELETED_${item.id}`,
        }
      } else {
        return item
      }
    })

    if (isCreation) {
      setValue(
        'contacts',
        data.contacts?.filter((item: any) => item.id !== contact.id),
      )
    } else {
      setValue('contacts', newContacts)
    }
  }

  return (
    <Div css={{ marginTop: '$2' }}>
      <Text
        size="md"
        css={{
          color: '$brand_primary_pure',
          fontWeight: '$bold',
        }}
      ></Text>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text
          size="md"
          css={{
            color: '$brand_primary_pure',
            fontWeight: '$bold',
          }}
        >
          {contact.name || `Contato ${index + 1}`}
        </Text>
        <Button
          onClick={handleDeleteChild}
          variant="primaryCritical"
          icon={<TrashIcon />}
        />
      </Div>
      <FormGroup items={contactsData} cols={3} />
    </Div>
  )
}
