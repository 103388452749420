import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdateShiftPayload } from '../types'
import { AddShiftData } from '@/pages/turnos/create/forms'
import { fromAddShiftDataToCreationPayload } from '@/utils/shifts/parseShiftToMutateV2'
import { queryClient } from '@/libs/react-query'

type UpdateShiftData = {
  shift_id: string
} & AddShiftData

export const useHandleUpdateShifts = () => {
  const handleUpdateShift = async ({ ...props }: UpdateShiftData) => {
    const parsedProps = fromAddShiftDataToCreationPayload(props)

    const apiPayload: UpdateShiftPayload = {
      ...parsedProps,
      shift_id: props.shift_id,
    }

    const response = await apiV2.post('/shift/update-shift', apiPayload)
    queryClient.invalidateQueries()
    return response.data
  }

  const mutation = useMutation(['useUpdateShift'], handleUpdateShift)

  return mutation
}
