import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { PermissionConfig } from '../../types'

const fetchPolicy = async (id: string) => {
  const response = await apiV2.get<PermissionConfig>(
    `/permission/get-policy?id=${id}`,
  )

  return response.data
}

export const usePolicy = (id?: string) => {
  return useQuery(['policy', id], () => (id ? fetchPolicy(id) : null), {
    enabled: !!id,
  })
}
