import { IRouteAction } from '@/pages/router'
import { useTabStore } from '@/store'
import { BookmarkIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { usePolicyBuilder } from './components/PolicyBuilder/context'

export const useHandlePermissionCreationActions = () => {
  const { isLoading, handleSubmit, isUpdating } = usePolicyBuilder()

  const { addTab, setActiveTab, activeTab, setActions } = useTabStore(
    (state) => ({
      setActions: state.setActionsTab,
      activeTab: state.worker.activeTab,
      addTab: state.addTab,
      setActiveTab: state.setActiveTab,
    }),
  )

  useEffect(() => {
    const actions: IRouteAction[] = []

    actions.push({
      label: isUpdating ? 'Guardar' : 'Crear',
      icon: isUpdating ? <BookmarkIcon /> : <PlusIcon />,
      action: () => {
        handleSubmit()
      },
      disabled: isLoading,
      isLoading,
      variant: 'primary' as const,
    })

    setActions('permissions', actions)
  }, [
    activeTab,
    addTab,
    setActiveTab,
    setActions,
    handleSubmit,
    isLoading,
    isUpdating,
  ])
}
