import { Div } from '@/components'
import { styled, Text } from '@punto-ui/react'
import { PdfBuilderContentDataSchemaType } from '../../types'
import { ColorSelector } from './TextContent'
import { useState } from 'react'

export const SimpleInput = styled('input', {
  all: 'unset',
  width: 50,
  height: 18,

  border: '1px solid $interface_light_deep',
  background: '$interface_light_up',

  fontFamily: '$default',
  fontSize: '12px',
  lineHeight: '18px',

  borderRadius: '$sm',
  textAlign: 'center',
})

export const DividerContent = ({
  contentIndex,
  dataIndex,
  contentData,
}: {
  contentData: PdfBuilderContentDataSchemaType
  contentIndex: number
  dataIndex: number
}) => {
  const [isOverTextArea, setIsOverTextArea] = useState(false)

  // const methods = useFormContext<PdfBuilderSchemaType>()
  // const height = useWatch({
  //   control: methods.control,
  //   name: `pdf.content.${contentIndex}.data.${dataIndex}.height`,
  // })

  return (
    <Div
      css={{
        display: 'flex',
        cursor: 'pointer',
        flex: 1,
        height: 32,
        position: 'relative',

        alignItems: 'center',
        justifyContent: 'center',
      }}
      onMouseEnter={() => setIsOverTextArea(true)}
      onMouseLeave={() => setIsOverTextArea(false)}
    >
      <Div
        css={{
          flex: 1,
          borderRadius: '$md',
          // minHeight: height || 34,
          // height: '100%',
          height: 2,

          background: contentData.color || '$interface_light_deep',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />

      <Div
        onMouseEnter={() => setIsOverTextArea(true)}
        onMouseLeave={() => setIsOverTextArea(false)}
        css={{
          paddingTop: '$4',
          position: 'absolute',
          zIndex: 2,
          top: 'calc(100%)',

          minWidth: 250,

          left: 0,
          opacity: isOverTextArea ? 1 : 0,
          pointerEvents: isOverTextArea ? 'all' : 'none',
          transition: 'opacity 0.2s',
        }}
      >
        <Div
          css={{
            height: 34,
            boxShadow: '0px 0px 24px 0px #343A4029',
            background: '$interface_light_pure',

            // padding: '$4',

            borderRadius: '$md',

            paddingLeft: '$2',
            paddingRight: '$2',
            display: 'flex',
            gap: '$3',
            alignItems: 'center',
            // justifyContent: 'space-around',
          }}
        >
          <Text
            css={{
              color: contentData.color || '$interface_light_deep',
            }}
            variant={'caption'}
          >
            Color
          </Text>
          <ColorSelector
            contentIndex={contentIndex}
            dataIndex={dataIndex}
            contentData={contentData}
          />
        </Div>
      </Div>
    </Div>
  )
}
