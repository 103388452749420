import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { CreateCellPayload } from '../types'
import { ICell } from '@/libs/react-query/types/organogram'
import { queryClient } from '@/libs/react-query'

export const useHandleAddCell = () => {
  const handleAddCell = async ({
    name,
    parentId,
  }: CreateCellPayload): Promise<ICell> => {
    const apiPayload = {
      name,
      parentId,
    }

    const response = await apiV2.post<{ cell: ICell }>(
      '/permission/create-cell',
      apiPayload,
    )

    queryClient.invalidateQueries()
    return response.data.cell
  }

  const mutation = useMutation(['useHandleAddCell'], handleAddCell)

  return mutation
}
