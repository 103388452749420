import { styled } from '@punto-ui/react'

export const AguinaldoTableContainer = styled('div', {
  position: 'relative',
  height: 'calc(100vh - 188px)',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 $4',
  overflowX: 'scroll',
})
