import { Div } from '@/components'
import { allMonths } from '@/components/InputDatePicker/utils'
import { Audit } from '@/libs/react-query/types/audits'
import { getUtcDateFromString } from '@/utils/date'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button, styled, Text } from '@punto-ui/react'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'

export const AuditDates = ({
  audit,
  expanded,
}: {
  expanded: boolean
  audit: Audit
}) => {
  return (
    <Div
      css={{
        minWidth: expanded ? 380 : 225,
        maxWidth: 250,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Div
        css={{
          minWidth: 250,
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Text
          variant="caption"
          css={{
            marginBottom: '$2',
          }}
        >
          Fechas
        </Text>
      </Div>

      <Calendar
        newDates={audit.newDates.map((d) =>
          getUtcDateFromString(d.date).toISOString(),
        )}
        oldDates={audit.oldDates.map((d) =>
          getUtcDateFromString(d.date).toISOString(),
        )}
      />

      <Div
        css={{
          marginTop: '$2',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '$2',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$1',
          }}
        >
          <Div
            css={{
              height: 8,
              width: 8,
              background: '$status_success_deep',
              borderRadius: '100%',
            }}
          />
          <Text variant="caption">Nuevas datas</Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$1',
          }}
        >
          <Div
            css={{
              height: 8,
              width: 8,
              background: '$status_warning_pure',
              borderRadius: '100%',
            }}
          />
          <Text variant="caption">Datas excluidas</Text>
        </Div>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$1',
          }}
        >
          <Div
            css={{
              height: 8,
              width: 8,
              background: '$brand_primary_pure',
              borderRadius: '100%',
            }}
          />
          <Text variant="caption">Datas mantenidas</Text>
        </Div>
      </Div>
    </Div>
  )
}

interface ICell {
  id: string
  value: string
}

interface IRow {
  id: string
  cells: ICell[]
}

const THead = styled('thead', {})

export const Calendar = ({
  oldDates,
  newDates,
}: {
  oldDates: string[]
  newDates: string[]
}) => {
  const [calendarDate, setCalendarDate] = useState(dayjs(newDates[0]))

  const rows = useMemo(() => {
    let startOfMonth = calendarDate.startOf('month')
    const blankSpaces = startOfMonth.day()
    const daysInMonth = calendarDate.daysInMonth()

    const rows: IRow[] = []
    let cells: ICell[] = []

    for (let i = 1; i <= blankSpaces; i++) {
      cells.push({
        id: i.toString(),
        value: '',
      })
    }

    for (let i = 1; i <= daysInMonth; i++) {
      cells.push({
        id: startOfMonth.toString(),
        value: i.toString(),
      })

      if (cells.length === 7) {
        rows.push({
          id: startOfMonth.toString(),
          cells,
        })

        cells = []
      }

      startOfMonth = startOfMonth.add(1, 'day')
    }

    if (cells.length > 0) {
      rows.push({
        id: startOfMonth.toString(),
        cells,
      })
    }

    return rows
  }, [calendarDate])

  const daysLabels = ['D', 'L', 'M', 'M', 'J', 'V', 'S']

  const isDateNewDate = (date: dayjs.Dayjs) => {
    return newDates.some((d) => date.isSame(dayjs(d), 'date'))
  }

  const isDateOldDate = (date: dayjs.Dayjs) => {
    return oldDates.some((d) => date.isSame(dayjs(d), 'date'))
  }

  return (
    <Div
      css={{
        maxWidth: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        minHeight: 274,
        background: '$interface_light_up',

        border: '1px solid',
        borderColor: '$interface_light_down',
        borderRadius: '$md',
      }}
    >
      <Filters calendarDate={calendarDate} setCalendarDate={setCalendarDate} />
      <CalendarContainer
        css={{
          background: '$interface_light_up',
          maxWidth: 250,
        }}
      >
        <table>
          <THead
            css={{
              borderBottom: '1px solid',
              borderBottomColor: '$interface_dark_deep',
            }}
          >
            <tr>
              {daysLabels.map((day, index) => (
                <DayCell key={index}>
                  <DayCellFill>
                    <Text size="sm" css={{ color: '$interface_dark_down' }}>
                      {day}
                    </Text>
                  </DayCellFill>
                </DayCell>
              ))}
            </tr>
          </THead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.cells.map((cell) => {
                    return (
                      <DayCell
                        key={cell.id}
                        isToday={
                          dayjs(cell.id).isSame(dayjs(), 'day') &&
                          !isDateNewDate(dayjs(cell.id)) &&
                          !isDateOldDate(dayjs(cell.id))
                        }
                        isNewDate={isDateNewDate(dayjs(cell.id))}
                        isOldDate={isDateOldDate(dayjs(cell.id))}
                        oldAndNewDate={
                          isDateNewDate(dayjs(cell.id)) &&
                          isDateOldDate(dayjs(cell.id))
                        }
                        isRange={false}
                        isStartDate={false}
                        isEndDate={false}
                        between={false}
                      >
                        <button type="button">
                          <DayCellFill>
                            <Text
                              variant="description"
                              css={{
                                color: '$interface_dark_down',
                              }}
                            >
                              {cell.value}
                            </Text>
                          </DayCellFill>
                        </button>
                      </DayCell>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </CalendarContainer>
    </Div>
  )
}

export const CalendarContainer = styled('div', {
  padding: '$4',
  paddingTop: '$2',

  transition: 'all 0.2s ease-in-out',

  '& table': {
    width: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
  },
})

export const DayCellFill = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  aspectRatio: '1 / 1',
  zIndex: 2,
})

export const DayCell = styled('td', {
  width: '100%',
  aspectRatio: '1 / 1',
  position: 'relative',

  transition: 'all 0.2s ease-in-out',
  '& button': {
    all: 'unset',
    width: '100%',
    aspectRatio: '1 / 1',
    cursor: 'default',
  },

  variants: {
    isRange: {
      false: {
        '& button': {
          borderRadius: '$full',
        },
      },
    },
    isStartDate: {
      true: {
        '& button': {
          borderTopLeftRadius: '$md',
          borderBottomLeftRadius: '$md',
        },
      },
    },
    isEndDate: {
      true: {
        '& button': {
          zIndex: 2,
          borderTopRightRadius: '$md',
          borderBottomRightRadius: '$md',
        },
      },
    },
    isNewDate: {
      true: {
        border: 'none',
        [`& ${DayCellFill}`]: {
          zIndex: 1,
        },

        '& button': {
          zIndex: 2,
          backgroundColor: '$status_success_deep',
        },

        [`& ${Text}`]: {
          color: '$interface_light_pure',
        },
      },
      false: {
        '&:hover': {
          '& button': {
            borderRadius: '$full',
          },
        },
      },
    },
    isOldDate: {
      true: {
        border: 'none',
        [`& ${DayCellFill}`]: {
          zIndex: 1,
        },

        '& button': {
          zIndex: 2,
          backgroundColor: '$status_warning_pure',
        },

        [`& ${Text}`]: {
          color: '$interface_light_pure',
        },
      },
    },
    oldAndNewDate: {
      true: {
        border: 'none',
        [`& ${DayCellFill}`]: {
          zIndex: 1,
        },

        '& button': {
          zIndex: 2,
          backgroundColor: '$brand_primary_pure !important',
        },

        [`& ${Text}`]: {
          color: '$interface_light_pure',
        },
      },
    },
    between: {
      true: {
        backgroundColor: '$interface_light_down',
      },
    },
    isToday: {
      true: {
        border: 'solid 1px $brand_primary_pure',
        borderRadius: '$full',
        [`& ${Text}`]: {
          color: '$brand_primary_pure',
        },
      },
    },
  },
})

export const BgCellFill = styled('div', {
  backgroundColor: '$interface_light_down',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '50%',
  zIndex: 1,
})

const Touchable = styled('button', {
  all: 'unset',
  cursor: 'pointer',
})

export const Filters = ({
  setCalendarDate,
  calendarDate,
}: {
  setCalendarDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  calendarDate: dayjs.Dayjs
}) => {
  const allYearsOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const last10Years = Array.from(
      { length: 10 },
      (_, i) => currentYear - i - 1,
    )
    const next10Years = Array.from(
      { length: 10 },
      (_, i) => currentYear + i + 1,
    )

    const allYears = [
      ...last10Years.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
      {
        value: currentYear.toString(),
        label: currentYear.toString(),
      },
      ...next10Years.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
    ]

    allYears.sort((a, b) => Number(a.value) - Number(b.value))

    return allYears
  }, [])

  const [isMonthFilterOpen, setIsMonthFilterOpen] = useState(false)
  const [isYearFilterOpen, setIsYearFilterOpen] = useState(false)

  return (
    <FiltersContainer>
      <Filter>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.subtract(1, 'month'))
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Touchable
          type="button"
          onClick={() => setIsMonthFilterOpen((state) => !state)}
        >
          <Text variant={'caption'} css={{ color: '$brand_primary_pure' }}>
            {calendarDate.format('MMM')}
          </Text>
        </Touchable>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.add(1, 'month'))
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {isMonthFilterOpen && (
          <OptionsContainer>
            {allMonths.map((month) => (
              <Button
                type="button"
                css={{ width: '100%', marginBottom: '$2' }}
                variant="secondary"
                key={month.value}
                onClick={() => {
                  setCalendarDate((prev) => prev.month(parseInt(month.value)))
                  setIsMonthFilterOpen(false)
                }}
              >
                {month.label}
              </Button>
            ))}
          </OptionsContainer>
        )}
      </Filter>
      <Filter>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.subtract(1, 'year'))
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Touchable
          type="button"
          onClick={() => setIsYearFilterOpen((state) => !state)}
        >
          <Text variant={'caption'}>{calendarDate.format('YYYY')}</Text>
        </Touchable>
        <IconButton
          type="button"
          onClick={() => {
            setCalendarDate((prev) => prev.add(1, 'year'))
          }}
        >
          <ChevronRightIcon />
        </IconButton>

        {isYearFilterOpen && (
          <OptionsContainer>
            {allYearsOptions.map((year) => (
              <Button
                type="button"
                css={{ width: '100%', marginBottom: '$2' }}
                variant="secondary"
                key={year.value}
                onClick={() => {
                  setCalendarDate((prev) => prev.year(parseInt(year.value)))
                  setIsYearFilterOpen(false)
                }}
              >
                {year.label}
              </Button>
            ))}
          </OptionsContainer>
        )}
      </Filter>
    </FiltersContainer>
  )
}

export const FiltersContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '$md',
  borderTopRightRadius: '$md',

  '> div:first-child': {
    borderTopLeftRadius: '$md',
  },

  '> div:last-child': {
    borderTopRightRadius: '$md',
  },

  minWidth: 250,
})

export const IconButton = styled('button', {
  all: 'unset',
  height: 24,
  width: 24,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    height: 16,
    width: 16,
  },

  cursor: 'pointer',
})

export const Filter = styled('div', {
  height: '$8',
  paddingTop: '$1',
  paddingBottom: '$1',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  background: '$interface_light_down',

  // borderRadius: '$md',

  // '& button': {
  //   all: 'unset',
  //   cursor: 'pointer',
  // },

  '& svg': {
    height: 16,
    width: 16,
    color: '$brand_primary_pure',
    strokeWidth: 2.5,
  },

  [`& ${Text}`]: {
    color: '$brand_primary_pure',
    fontWeight: '$bold',
    padding: '0 $2',
  },
})

export const OptionsContainer = styled('div', {
  position: 'absolute',
  top: 48,
  right: 0,
  left: 0,
  maxHeight: 300,
  overflowY: 'scroll',
  background: '$interface_light_pure',
  zIndex: 9999,
})

export const Option = styled('div', {})
