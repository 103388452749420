import { apiV2 } from '@/services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { IUpdateAguinaldoConfigDTO } from '@/libs/react-query/types/aguinaldo'

dayjs.extend(utc)

const handleUpdateAguinaldoConfig = async (
  payload: IUpdateAguinaldoConfigDTO,
) => {
  await apiV2.post<void>('/payroll/update-aguinaldo-config', payload)
  queryClient.invalidateQueries('aguinaldos')
  queryClient.invalidateQueries('aguinaldo-details')
}

export const useHandleUpdateAguinaldoConfig = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateAguinaldoConfig'],
    handleUpdateAguinaldoConfig,
  )

  return mutation
}
