import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { CreatePunchBatchPayload } from '../types'

const handleCreatePunchBatch = async (props: CreatePunchBatchPayload) => {
  const response = await apiV2.post<any>('/punches/create-punch-batch', props)

  queryClient.invalidateQueries()

  return response.data
}

export const useHandleCreatePunchBatch = () => {
  const mutation = useCustomMutation(
    ['useCreatePunchBatch'],
    handleCreatePunchBatch,
  )

  return mutation
}
