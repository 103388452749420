import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'

export const useTotalLiquidationValues = () => {
  const methods = useFormContext<InactivateWorkerSchema>()

  const [
    aguinaldoValue,
    takenVacationsValue,
    proportionalVacationsValue,
    discountProportionalVacationsValue,
    preWarningValue,
    discountPreWarningValue,
    indemnificationValue,
    totalPositiveSalary,
    totalNegativeSalary,
    paymentConcepts,
    discountConcepts,
    ipsValue,
    familyBonusValue,
    accumulatedVacationsValue,
  ] = useWatch({
    control: methods.control,
    name: [
      'data.aguinaldo',
      'data.total_vacations.value',
      'data.proportional_vacations.value',
      'data.discount_proportional_vacations.value',
      'data.pre_warning.value',
      'data.discount_pre_warning.value',
      'data.indemnification.value',
      'data.total_positive_salary',
      'data.total_negative_salary',
      'data.paymentConcepts',
      'data.discountConcepts',
      'ipsValues.ips9Value',
      'data.familiarBonus.value',
      'data.total_accumulated_vacations.value',
    ],
  })

  const totalPaymentConcepts = useMemo(() => {
    return paymentConcepts.reduce((acc, curr) => {
      return acc + +curr.value
    }, 0)
  }, [paymentConcepts])

  const totalDiscountConcepts = useMemo(() => {
    return discountConcepts.reduce((acc, curr) => {
      return acc + +curr.value
    }, 0)
  }, [discountConcepts])

  const totalFinalValue = useMemo(() => {
    const subtotal =
      +aguinaldoValue +
      +takenVacationsValue +
      +proportionalVacationsValue -
      +discountProportionalVacationsValue +
      +preWarningValue -
      +discountPreWarningValue +
      +indemnificationValue +
      +totalPositiveSalary -
      +totalNegativeSalary +
      +totalPaymentConcepts -
      +totalDiscountConcepts +
      +familyBonusValue +
      +accumulatedVacationsValue

    const total = subtotal - +ipsValue

    return {
      total,
      subtotal,
    }
  }, [
    aguinaldoValue,
    takenVacationsValue,
    proportionalVacationsValue,
    preWarningValue,
    discountPreWarningValue,
    indemnificationValue,
    totalPositiveSalary,
    totalNegativeSalary,
    totalPaymentConcepts,
    totalDiscountConcepts,
    discountProportionalVacationsValue,
    ipsValue,
    familyBonusValue,
    accumulatedVacationsValue,
  ])

  return {
    totalPaymentConcepts,
    totalDiscountConcepts,
    totalFinalValue,
  }
}
