import { useFormContext } from 'react-hook-form'
import { useHandleClosePayroll } from '@/libs/react-query/mutations/payroll/useHandleClosePayroll'
import { Div, Flex } from '@/components'
import { Box, Button, Dialog, DialogContainer, Text } from '@punto-ui/react'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { AguinaldoPlaygroundTableType } from '../../types'

export const ClosePayrollDialog = ({
  isOpen,
  name,
  setIsOpen,
}: {
  isOpen: boolean
  name: string
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { getValues } = useFormContext<AguinaldoPlaygroundTableType>()
  const { mutateAsync: handleClosePayroll, isLoading: isLoadingClosePayroll } =
    useHandleClosePayroll()

  return (
    <Div>
      <DialogContainer open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <Dialog
          overlayCss={{
            zIndex: 99,
          }}
          containerCss={{
            zIndex: 100,
          }}
          content={
            <Box>
              <Flex
                css={{ flexDirection: 'column', marginBottom: '$4', gap: '$4' }}
              >
                <Text variant={'heading2'}>
                  ¿Está seguro de cerrar la planilla de salarios {name}?
                </Text>
                <Text
                  variant="paragraph"
                  css={{ width: 500, textAlign: 'justify' }}
                >
                  Las planillas cerradas ya no sufrirán alteraciones como
                  consecuencia de alteraciones de movimientos, pagos y puntos.
                </Text>
              </Flex>

              <Flex css={{ flex: 1 }}>
                <Button
                  css={{ flex: 1, marginRight: '$1' }}
                  onClick={async () => {
                    const values = getValues()
                    await handleClosePayroll(values.id)
                    setIsOpen(false)
                  }}
                  isLoading={isLoadingClosePayroll}
                  icon={<LockClosedIcon />}
                >
                  Cerrar
                </Button>
                <Button css={{ flex: 1, marginRight: '$1' }} variant="tertiary">
                  Cancelar
                </Button>
              </Flex>
            </Box>
          }
        />
      </DialogContainer>
    </Div>
  )
}
