import { Div } from '@/components'
import { createCompleteWorkerData } from '@/libs/react-query/mutations'
import { Text, styled } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useMemo } from 'react'
import { useWorkerCreation } from '../../../context/hooks'
import { CSS } from '@stitches/react'

interface FaceIDPreview {
  css?: CSS
}

export const FaceIdPreview = (props: FaceIDPreview) => {
  const { control } = useFormContext<createCompleteWorkerData>()
  const { worker } = useWorkerCreation()

  const faceIdPhoto = useWatch({
    control,
    name: 'face_id_photo',
  })

  const previewUrl = useMemo(() => {
    if (faceIdPhoto) {
      return URL.createObjectURL(faceIdPhoto)
    } else {
      return worker?.faceId?.url
    }
  }, [faceIdPhoto, worker])

  if (!previewUrl) {
    return (
      <Div
        css={{
          height: 500,
          width: 500,
          border: 'solid 2px',
          borderColor: '$brand_primary_pure',
          borderRadius: '$md',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          ...props.css,
        }}
      >
        <Text
          size="xl"
          css={{
            color: '$brand_primary_pure',
            fontWeight: '$bold',
            marginBottom: '$4',
          }}
        >
          Sin foto de Face ID
        </Text>
      </Div>
    )
  }

  return (
    <PreviewFaceIdImage
      src={previewUrl}
      css={{
        ...props.css,
      }}
    />
  )
}

const PreviewFaceIdImage = styled('img', {
  height: 325,
  width: 325,
  objectFit: 'cover',
  objectPosition: 'center',
  border: 'solid 2px',
  borderColor: '$brand_primary_pure',
  borderRadius: '$md',
})
