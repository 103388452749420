import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { Div } from '@/components'

export const useLastSalary = () => {
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [salaries] = useWatch({
    control,
    name: ['data.salaries'],
  })
  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = []

    salaries.forEach((salary, index) => {
      const item = {
        _type: 'input',
        name: `data.salaries.${index}.value`,
        inputType: 'money-guarani',
        disabled: isLoadingLiquidation,
        label: salary.label,
        disclaimer: `Planilla: ${salary.disclaimer}`,
      } as InactiveWorkerField
      lastSalaryForm.push(item)
    })

    lastSalaryForm.push({
      _type: 'custom',
      component: <Div css={{ width: 'calc((100% / 4) - 5%)' }} />,
    })
    lastSalaryForm.push({
      _type: 'custom',
      component: <Div css={{ width: 'calc((100% / 4) - 5%)' }} />,
    })
    lastSalaryForm.push({
      _type: 'input',
      inputType: 'money-guarani',
      disabled: isLoadingLiquidation,
      name: 'data.salary_average',
      label: 'Salário Promedio',
    })

    lastSalaryForm.push({
      _type: 'input',
      inputType: 'money-guarani',
      disabled: isLoadingLiquidation,
      name: 'data.daily_salary',
      label: 'Valor diário',
    })

    return lastSalaryForm
  }, [salaries, isLoadingLiquidation])

  return {
    form,
  }
}
