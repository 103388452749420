import * as RadioGroup from '@radix-ui/react-radio-group'
import { styled } from '../../styles'

export const RadioContainer = styled(RadioGroup.Root, {
  display: 'flex',
  alignItems: 'center',

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        opacity: 0.5,
      },
    },
    direction: {
      vertical: {
        flexDirection: 'column',
        alignItems: 'flex-start',

        '& label': {
          marginBottom: '$2',
          marginLeft: '0 !important',
        },
      },
      horizontal: {
        flexDirection: 'row',
      },
    },
  },
})

export const RadioItemContainer = styled('label', {
  '& + &': {
    marginLeft: '$4',
  },

  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '$2',
})

export const RadioItem = styled(RadioGroup.Item, {
  all: 'unset',
  width: '$4',
  height: '$4',

  minWidth: '$4',
  minHeight: '$4',

  border: '1px solid $interface_dark_up',
  backgroundColor: '$interface_light_pure',
  borderRadius: '$full',

  "&[data-state='checked']": {
    backgroundColor: '$brand_primary_pure',
  },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const RadioIndicator = styled(RadioGroup.Indicator, {
  width: '$2',
  height: '$2',
  backgroundColor: '$interface_light_pure',
  borderRadius: '$full',
})
