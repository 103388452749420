import { ICompanyBusinessWithStatistics } from '@/libs/react-query/types/company'
import dayjs from 'dayjs'
import { StatisticsManagerSchemaType } from './types'

export const parseCompanyList = (
  companies: ICompanyBusinessWithStatistics[],
): {
  data: StatisticsManagerSchemaType['data']
  weeksLength: number
} => {
  let weeksLength = 0

  return {
    data: companies.map((company, index) => {
      if (company.weeksPunches.length > weeksLength) {
        weeksLength = company.weeksPunches.length
      }

      return {
        id: company.id,

        weeksPunches: company.weeksPunches.map((week) => {
          return {
            status: '',
            value: week,
          }
        }),
        numberOfPayrolls: {
          status: '',
          value: company.numberOfPayrolls,
        }, // done
        activeWorkers: {
          status: '',
          value: company.activeEmployees || 0,
        },
        totalPunches: {
          status: '',
          value: company.totalPunches,
        }, // done
        totalMovements: {
          status: '',
          value: company.totalMovements,
        }, // done
        totalVacations: {
          status: '',
          value: company.totalVacations,
        }, // done
        totalPayments: {
          status: '',
          value: company.totalPayments,
        }, // done

        status: {
          value: company.businessInfos?.status || '',
          status: '',
          original: company.businessInfos?.status || '',
        },

        count: {
          value: index + 1,
          status: '',
          original: index + 1,
        },

        name: {
          value: company.name,
          status: '',
          original: company.name,
        },
        plan: {
          value: company.plan,
          status: '',
          original: company.plan,
        },
        document: {
          value: company.document,
          status: '',
          original: company.document,
        },
        email: {
          value: company.email,
          status: '',
          original: company.email,
        },
        legal_representative: {
          value: company.legal_representative,
          status: '',
          original: company.legal_representative,
        },
        totalEmployees: {
          value: company.totalEmployees || 0,
          status: '',
          original: company.totalEmployees || 0,
        },
        activeEmployees: {
          value: company.activeEmployees || 0,
          status: '',
          original: company.activeEmployees || 0,
        },
        inactiveEmployees: {
          value: company.inactiveEmployees || 0,
          status: '',
          original: company.inactiveEmployees || 0,
        },
        created_at: {
          value: dayjs(company.created_at).format('DD/MM/YYYY HH:mm'),
          status: '',
          original: dayjs(company.created_at).format('DD/MM/YYYY HH:mm'),
        },

        test_period_start: {
          value: company.businessInfos?.test_period_start
            ? dayjs(company.businessInfos?.test_period_start).format(
                'DD/MM/YYYY',
              )
            : '',
          status: '',
          original: company.businessInfos?.test_period_start
            ? dayjs(company.businessInfos?.test_period_start).format(
                'DD/MM/YYYY',
              )
            : '',
        },
        test_period_end: {
          value: company.businessInfos?.test_period_end
            ? dayjs(company.businessInfos?.test_period_end).format('DD/MM/YYYY')
            : '',
          status: '',
          original: company.businessInfos?.test_period_end
            ? dayjs(company.businessInfos?.test_period_end).format('DD/MM/YYYY')
            : '',
        },
        payroll_import_date: {
          value: company.businessInfos?.payroll_import_date
            ? dayjs(company.businessInfos?.payroll_import_date).format(
                'DD/MM/YYYY',
              )
            : '',
          status: '',
          original: company.businessInfos?.payroll_import_date
            ? dayjs(company.businessInfos?.payroll_import_date).format(
                'DD/MM/YYYY',
              )
            : '',
        },
        app_setup_date: {
          value: company.businessInfos?.app_setup_date
            ? dayjs(company.businessInfos?.app_setup_date).format('DD/MM/YYYY')
            : '',
          status: '',
          original: company.businessInfos?.app_setup_date
            ? dayjs(company.businessInfos?.app_setup_date).format('DD/MM/YYYY')
            : '',
        },
        face_register_date: {
          value: company.businessInfos?.face_register_date
            ? dayjs(company.businessInfos?.face_register_date).format(
                'DD/MM/YYYY',
              )
            : '',
          status: '',
          original: company.businessInfos?.face_register_date
            ? dayjs(company.businessInfos?.face_register_date).format(
                'DD/MM/YYYY',
              )
            : '',
        },
        punch_register_date: {
          value: company.businessInfos?.punch_register_date
            ? dayjs(company.businessInfos?.punch_register_date).format(
                'DD/MM/YYYY',
              )
            : '',
          status: '',
          original: company.businessInfos?.punch_register_date
            ? dayjs(company.businessInfos?.punch_register_date).format(
                'DD/MM/YYYY',
              )
            : '',
        },
        cancelation_date: {
          value: company.businessInfos?.cancelation_date
            ? dayjs(company.businessInfos?.cancelation_date).format(
                'DD/MM/YYYY',
              )
            : '',
          status: '',
          original: company.businessInfos?.cancelation_date
            ? dayjs(company.businessInfos?.cancelation_date).format(
                'DD/MM/YYYY',
              )
            : '',
        },
        entrance_date: {
          value: company.businessInfos?.entrance_date
            ? dayjs(company.businessInfos?.entrance_date).format('DD/MM/YYYY')
            : '',
          status: '',
          original: company.businessInfos?.entrance_date
            ? dayjs(company.businessInfos?.entrance_date).format('DD/MM/YYYY')
            : '',
        },

        contact_email: {
          value: company.businessInfos?.email || '',
          status: '',
          original: company.businessInfos?.email || '',
        },
        contact_name: {
          value: company.businessInfos?.contact_name || '',
          status: '',
          original: company.businessInfos?.contact_name || '',
        },
        contact_phone: {
          value: company.businessInfos?.phone || '',
          status: '',
          original: company.businessInfos?.phone || '',
        },

        payment_month_day: {
          value: company.businessInfos?.payment_month_day || '',
          status: '',
          original: company.businessInfos?.payment_month_day || '',
        },
        default_external_product_code: {
          value: company.businessInfos?.default_external_product_code || '',
          status: '',
          original: company.businessInfos?.default_external_product_code || '',
        },
        default_month_invoice_value: {
          value: company.businessInfos?.default_month_invoice_value || '',
          status: '',
          original: company.businessInfos?.default_month_invoice_value || '',
        },
        default_month_invoice_quantity: {
          value: company.businessInfos?.default_month_invoice_quantity || '',
          status: '',
          original: company.businessInfos?.default_month_invoice_quantity || '',
        },
      }
    }),
    weeksLength,
  }
}
