import { MoveListContainer, StyledContentText } from './styles'
import { parseMovementTypeToLabel } from '@/utils/movements/parseMovementType'
import React, { useEffect } from 'react'

import {
  ControlledFilterBar,
  Div,
  Table as TableComponent,
  WorkersVisibility,
} from '@/components'
import {
  CheckIcon,
  // CheckIcon,
  // TrashIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { useMovements } from '@/libs/react-query/hooks'

import {
  Box,
  Checkbox,
  DialogContainer,
  Menu,
  Tag,
  Text,
} from '@punto-ui/react'
import { useMovementsContext } from '../../context/hooks/useMovementsContext'
import {
  IMovementEnvelope,
  movementOptions,
} from '@/libs/react-query/types/movements'
import dayjs from 'dayjs'
import { useCan, useDebounce } from '@/hooks'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddMovementSimpleData } from '../../form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useHandleDeleteMovements } from '@/libs/react-query/mutations/movements/useHandleDelete'
import { SimpleDialog } from '@/components/Dialogs/SimpleDialogs'
import { capitalizeFirstLetters } from '@/utils/workers/name'

interface IMovesListProps {
  handleOpenDrawer?: (id: string) => void
  handleOpenEditDrawer?: (id: string) => void
  onClick?: (id: string) => void
}

export const MovesList = (props: IMovesListProps) => {
  const { filter: movesFilters, setFilter: setMovesFilters } =
    useMovementsContext()

  const { control } = useFormContext<AddMovementSimpleData>()

  const [cellsIds, nameFilter, categories, movementDate] = useWatch({
    control,
    name: [
      'filters.cellsIds',
      'filters.name',
      'filters.categories',
      'filters.date',
    ],
  })

  const debouncedCellsIds = useDebounce(cellsIds, 250)
  const debouncedNameFilter = useDebounce(nameFilter, 250)
  const debouncedCategories = useDebounce(categories, 250)
  const debouncedDate = useDebounce(movementDate, 250)

  const { data: movements, isFetching: isFetchingMovements } = useMovements({
    ...movesFilters,
    page: movesFilters.page + 1,
    startDate: debouncedDate?.[0]
      ? dayjs(debouncedDate[0]).startOf('day').toISOString()
      : undefined,
    endDate: debouncedDate?.[0]
      ? dayjs(debouncedDate[0]).endOf('day').toISOString()
      : undefined,
    name: debouncedNameFilter,
    cellsIds: debouncedCellsIds,
    categories: debouncedCategories,
  })

  const { isLoading: isLoadingDeleteMovements } = useHandleDeleteMovements()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: movesFilters.page,
      pageSize: movesFilters.pageSize ? movesFilters.pageSize : 20,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )
  useEffect(() => {
    setMovesFilters((state) => ({
      ...state,
      page: pageIndex,
      pageSize,
    }))
  }, [pageIndex, pageSize, setMovesFilters])

  useEffect(() => {
    setPagination((state) => ({
      ...state,
      pageIndex: 0,
    }))
    setMovesFilters((state) => ({
      ...state,
      page: 0,
    }))
  }, [setMovesFilters, categories, movementDate, nameFilter])

  const columns = React.useMemo<ColumnDef<IMovementEnvelope>[]>(
    () => [
      {
        id: 'select',
        meta: {
          content: 'component',
        },
        header: ({ table }) => (
          <Div
            css={{
              padding: '$3',
              background: 'transparent',
            }}
          >
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Div>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        header: 'Titulo',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.movement.title,
        id: 'movement.title',
      },
      {
        header: 'Colaboradores',
        footer: (info) => info.column.id,
        cell: (info) => (
          <WorkersVisibility
            users={info.row.original.users.map((user) => ({
              id: user.id,
              image_url: user.photo_url || '',
              name: user.name,
            }))}
          />
        ),
        id: 'workers_visibility',
        meta: {
          content: 'component',
        },
      },
      {
        header: '',
        footer: (info) => info.column.id,
        cell: (info) => (
          <Div>
            <Text
              css={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {capitalizeFirstLetters(info.row.original.users[0]?.name)}
            </Text>
          </Div>
        ),
        id: 'worker_name',
        meta: {
          content: 'component',
        },
      },
      {
        header: 'Tipo',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => parseMovementTypeToLabel(row.movement.category),
        id: 'movement.type',
      },
      {
        header: 'Remunerado',
        footer: (info) => info.column.id,
        cell: ({ row }) => (
          <Div
            css={{ display: 'flex', alignItems: 'flex-start', minWidth: 100 }}
          >
            <Tag
              variant={
                row.original.movement.should_pay_period
                  ? 'positive'
                  : 'negative'
              }
            >
              {row.original.movement.should_pay_period ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
        id: 'movement.should_pay_period',
      },
      {
        header: 'IPS',
        footer: (info) => info.column.id,
        cell: ({ row }) => (
          <Div
            css={{ display: 'flex', alignItems: 'flex-start', minWidth: 80 }}
          >
            <Tag
              variant={
                row.original.movement.should_include_in_ips
                  ? 'positive'
                  : 'negative'
              }
            >
              {row.original.movement.should_include_in_ips ? (
                <CheckIcon width={16} />
              ) : (
                <XMarkIcon width={16} />
              )}
            </Tag>
          </Div>
        ),
        id: 'movement.should_include_in_ips',
      },
      {
        header: 'Fecha',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) =>
          row.movement.type === 'MULTIPLE_DAYS' && row.movement.periods[0]?.date
            ? dayjs(row.movement.periods[0]?.date).format('DD/MM/YYYY')
            : row.movement.type !== 'MULTIPLE_DAYS' &&
              row.movement.periods[0]?.period_start
            ? dayjs(row.movement.periods[0]?.period_start).format('DD/MM/YYYY')
            : '',
        id: 'dateStart',
      },
      {
        header: () => null,
        cell: (info) => (
          <StyledContentText>
            <DropdownMenu rowItem={info.row.original} />
          </StyledContentText>
        ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.movement.id,
        id: '_id',
        meta: {
          content: 'component',
        },
      },
    ],
    [],
  )

  const table = useReactTable({
    data: movements?.data ?? [],
    columns,
    pageCount: movements?.totalPages ? movements.totalPages : -1,
    state: {
      pagination,
    },
    getRowId: (row) => row.movement.id,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <MoveListContainer>
      <ControlledFilterBar
        dateName="filters.date"
        genericFilters={[
          {
            label: 'Categoria de Movimiento',
            name: 'filters.categories',
            single: false,
            options: movementOptions,
            defaultValues: [],
          },
        ]}
        nameFilterName="filters.name"
        withAdvancedCells
        cellsIdsName="filters.cellsIds"
        dateType="single"
        isLoading={isFetchingMovements || isLoadingDeleteMovements}
        policiesIds={[
          ...permissionsArray.filter((p) => p.includes('moves')),
          'deleted',
        ]}
        // tag={dayjs(selectedDate).format('dddd')}
      />
      <Div
        css={{
          margin: '$4',
          // maxHeight: 'calc(100vh - 200px)',
          // overflow: 'hidden',
          // background: 'red',
        }}
      >
        <TableComponent<IMovementEnvelope>
          numberOfRows={movements?.data?.length ?? 0}
          table={table}
          isLoading={isFetchingMovements}
          totalNumberOfRows={movements?.total ?? 0}
          css={{
            maxHeight: 'calc(100vh - 132px)',
          }}
          domTableCss={{
            maxHeight: 'calc(100vh - 200px)',
          }}
        />
      </Div>
    </MoveListContainer>
  )
}

interface DropdownMenuProps {
  rowItem: IMovementEnvelope
}

const DropdownMenu = ({ rowItem }: DropdownMenuProps) => {
  const { mutateAsync: handleDeleteMovements } = useHandleDeleteMovements()
  const [isOpen, setIsOpen] = React.useState(false)
  const canEdit = useCan(['moves.edit'])
  const canDelete = useCan(['moves.delete'])

  const { handleOpenMovementDrawer } = useMovementsContext()

  return (
    <DialogContainer open={!!isOpen} onOpenChange={() => setIsOpen(false)}>
      <SimpleDialog
        description={
          '¿Está seguro que desea excluir el movimiento seleccionado?'
        }
        title={'Excluir Movimiento'}
        handleClose={() => setIsOpen(false)}
        handleConfirm={() => {
          handleDeleteMovements([rowItem.movement.id])
        }}
        icon={<TrashIcon />}
        overlayCss={{
          zIndex: 999,
        }}
        containerCSS={{
          zIndex: 9999,
        }}
        confirmLabel="Excluir"
      />
      <Menu
        items={[
          {
            id: '2',
            label: 'Editar',
            icon: <PencilSquareIcon />,
            onClick: () => {
              if (!canEdit) return
              setTimeout(() => handleOpenMovementDrawer(rowItem), 50)
            },
            disabled: !canEdit,
          },
          {
            id: '3',
            disabled: !canDelete,
            label: 'Excluir',
            icon: <TrashIcon />,
            onClick: () => {
              if (!canEdit) return
              // handleDeleteMovements([rowItem.movement.id])
              setTimeout(() => {
                setIsOpen(true)
              }, 1)
            },
          },
        ]}
      />
    </DialogContainer>
  )
}
