import { useMemo } from 'react'
import { InactivateWorkerSchema, InactiveWorkerField } from '.'
import { useFormContext, useWatch } from 'react-hook-form'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { useIsInactivateWorkerFormDisabled } from '../hooks'
import { useWorkerLiquidation } from '@/libs/react-query/hooks/useWorkerLiquidation'
import { Div } from '@/components'

export const useLastSalaryPayrolls = () => {
  // const { data: payrolls } = usePayrolls()
  const { control } = useFormContext<InactivateWorkerSchema>()
  const [salaries, userId, liquidationId] = useWatch({
    control,
    name: ['data.salaries', 'data.user_id', 'liquidationId'],
  })
  const isLoadingLiquidation = useIsInactivateWorkerFormDisabled()
  const { data: workerLiquidation } = useWorkerLiquidation({
    userId,
    liquidationId,
  })

  const form = useMemo(() => {
    const lastSalaryForm: InactiveWorkerField[] = []

    salaries.forEach((salary, index) => {
      const item = {
        _type: 'dropdown',
        name: `data.salaries.${index}.payrollId`,
        disabled: isLoadingLiquidation,
        label: salary.label,
        disclaimer: `Valor: ${typeDecoder('money-guarani').input(
          salary.value.toString(),
        )}`,
        options: workerLiquidation?.payrolls?.map((payroll) => ({
          label: payroll.name,
          value: payroll.id,
        })),
        defaultOption: !salary.payrollId
          ? {
              label: 'Seleccionar',
              value: '',
            }
          : {
              label: salary.disclaimer,
              value: salary.payrollId,
            },
      } as InactiveWorkerField

      lastSalaryForm.push(item)
    })

    lastSalaryForm.push({
      _type: 'custom',
      component: <Div css={{ width: 'calc((100% / 4) - 5%)' }} />,
    })
    lastSalaryForm.push({
      _type: 'custom',
      component: <Div css={{ width: 'calc((100% / 4) - 5%)' }} />,
    })
    lastSalaryForm.push({
      _type: 'input',
      inputType: 'money-guarani',
      disabled: true,
      name: 'data.salary_average',
      label: 'Salário Promedio',
    })

    lastSalaryForm.push({
      _type: 'input',
      inputType: 'money-guarani',
      disabled: true,
      name: 'data.daily_salary',
      label: 'Valor diário',
    })

    return lastSalaryForm
  }, [salaries, isLoadingLiquidation, workerLiquidation?.payrolls])

  return {
    form,
  }
}
