import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { CellFilter, Div } from '../..'
import { SearchInput } from './styles'
import { InputDatePickerProps, Progress, Tag } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ControlledCalendarInput } from '../ControlledCalendar'
import { OrganogramFilter } from '@/components/Filters/OrganogramFilter'
import { Chip } from '@/components/Chip'
import { useMemo } from 'react'
import { ControlledChip } from '../ControlledChip'

interface IControlledFilterBarGenericFilter {
  options: Array<{
    label: string
    value: string
  }>
  single?: boolean
  name: string
  label: string
  defaultValues?: string[]
}

interface IFilterBarProps {
  policiesIds: string[]
  isLoading: boolean

  genericFilters?: IControlledFilterBarGenericFilter[]

  multipleFilterOptions?: Array<{
    label: string
    value: string
  }>
  multipleFilterOptionName?: string
  defaultMultipleFilterValue?: string[]
  multipleFilterOptionLabel?: string

  dateType?: InputDatePickerProps['type']
  dateName?: string
  cellsIdsName?: string
  nameFilterName?: string
  tag?: string
  withoutTotalButtons?: boolean
  withAdvancedCells?: boolean
  maxDates?: number
}

export const ControlledFilterBar = (props: IFilterBarProps) => {
  const { control, setValue } = useFormContext()

  const date = useWatch({
    control,
    name: props.dateName || '',
  })

  const cellsIds = useWatch({
    control,
    name: props.cellsIdsName || '',
  })

  const name = useWatch({
    control,
    name: props.nameFilterName || '',
  })

  const selectedMultipleOptions = useWatch({
    control,
    name: props.multipleFilterOptionName || '',
    defaultValue: props.defaultMultipleFilterValue || [],
  })

  const multipleOptions = useMemo(() => {
    return props.multipleFilterOptions?.map((option) => {
      return {
        label: option.label,
        value: option.value,
        selected: selectedMultipleOptions?.includes(option.value),
      }
    })
  }, [selectedMultipleOptions, props.multipleFilterOptions])

  return (
    <Div
      css={{
        maxHeight: 44,
        minHeight: 44,
        zIndex: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100vw',
        height: '100%',
        width: '100%',
        borderBottom: '1px solid',
        borderColor: '$interface_light_down',
        background: '$interface_light_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          svg: {
            width: 16,
            height: 16,
          },
        }}
      >
        {props.nameFilterName && (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '$8',
              paddingRight: '$8',
            }}
          >
            <MagnifyingGlassIcon />
            <SearchInput
              value={name}
              onChange={(e) => {
                if (props.nameFilterName) {
                  setValue(props.nameFilterName, e.target.value)
                }
              }}
              placeholder="Buscar colaboradores..."
            />
          </Div>
        )}
        {props.dateName && (
          <Div
            css={{
              marginLeft: '$8',
              width: 240,
              zIndex: 99,
            }}
          >
            <ControlledCalendarInput
              name={props.dateName}
              calendarType={props.dateType || 'range'}
              modern
              // label="Seleccione una fecha"
              placeholder="Seleccione una fecha"
              firstDate={date?.[0] || null}
              maxDates={props.maxDates || 60}
            />
          </Div>
        )}
        {!!props.tag && (
          <Div css={{ marginLeft: 16 }}>
            <Tag>{props.tag}</Tag>
          </Div>
        )}
        {props.multipleFilterOptions && props.multipleFilterOptions.length && (
          <Div
            css={{
              marginLeft: '$4',
              zIndex: 50,
            }}
          >
            <Chip
              withoutTotalButtons={true}
              dropdownSide={'right'}
              label={props.multipleFilterOptionLabel || 'Sector'}
              options={multipleOptions || []}
              onChangeValue={(newOptions) => {
                const selectedOptions = newOptions
                  .filter((option) => option.selected)
                  .map((option) => option.value)

                setValue(props.multipleFilterOptionName || '', selectedOptions)
              }}
            />
          </Div>
        )}
        {props.isLoading && (
          <Div
            css={{
              marginLeft: '$4',
            }}
          >
            <Progress size="md" />
          </Div>
        )}
      </Div>
      <Div
        css={{
          display: 'flex',
          gap: '$4',
        }}
      >
        {props.genericFilters?.map((filter) => (
          <Div key={filter.name}>
            <ControlledChip
              withoutLabel
              name={filter.name}
              withoutTotalButtons={true}
              dropdownSide={'right'}
              label={filter.label}
              options={filter.options.map((option) => ({
                label: option.label,
                value: option.value,
                selected: !!filter.defaultValues?.includes(option.value),
              }))}
            />
          </Div>
        ))}
        {props.cellsIdsName && (
          <>
            {props.withAdvancedCells ? (
              <Div
                css={{
                  marginRight: '$4',
                  zIndex: 99,
                }}
              >
                <OrganogramFilter
                  selectedIds={cellsIds}
                  defaultValues={true}
                  policiesIds={props.policiesIds}
                  callback={(options) => {
                    if (!props.cellsIdsName) return
                    const cellsIds = options.map((cell) => cell.id)
                    setValue(props.cellsIdsName, cellsIds)
                  }}
                />
              </Div>
            ) : (
              <Div
                css={{
                  marginRight: '$4',
                  zIndex: 99,
                }}
              >
                <CellFilter
                  withoutTotalButtons={props.withoutTotalButtons}
                  selectedIds={cellsIds}
                  defaultValues={true}
                  policiesIds={props.policiesIds}
                  callback={(options) => {
                    if (!props.cellsIdsName) return
                    const cellsIds = options.map((cell) => cell.id)
                    setValue(props.cellsIdsName, cellsIds)
                  }}
                />
              </Div>
            )}
          </>
        )}
      </Div>
    </Div>
  )
}
