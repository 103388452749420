import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { ICell } from '../../types/organogram'
import { useMe } from '../useMe'

const fetchAvailableCells = async (policiesIds: string[]) => {
  try {
    const response = await apiV2.post<ICell[]>(
      '/permission/get-available-cells',
      {
        policiesIds,
      },
    )
    return response.data
  } catch (err) {
    return []
  }
}

export const useAvailableCells = (policiesIds: string[]) => {
  const { data: user } = useMe()

  const query = useQuery(
    ['availableCells', policiesIds],
    () => fetchAvailableCells(policiesIds),
    {
      enabled: !!user && !!user.company_id && !!policiesIds.length,
      refetchOnWindowFocus: true,
    },
  )
  return query
}
