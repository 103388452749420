import { ControlledFilterBar, Div } from '@/components'
import { DropdownV2 } from '@/components/DropdownV2'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useAvailableCells, useWorkers } from '@/libs/react-query/hooks'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDocumentsStateSchema } from '../../documents-state'
import { useUserDocumentsStructure } from '@/libs/react-query/hooks/useUserDocumentsStructure'
import { DocumentManagementStructure } from './DocumentManagementStructure'
import { AddDocumentDrawer } from './AddDocumentDrawer'
import { capitalizeFirstLetters } from '@/utils/workers/name'
import { ViewUserDocumentModal } from './ViewUserDocumentModal'
import { EditDocumentDrawer } from './EditDocumentDrawer'

export const DocumentsManagement = () => {
  const methods = useFormContext<IDocumentsStateSchema>()

  const userId = useWatch({
    control: methods.control,
    name: 'management.userId',
  })

  const { isFetching: isLoadingDocuments } = useUserDocumentsStructure(userId)

  return (
    <>
      <ControlledFilterBar isLoading={isLoadingDocuments} policiesIds={[]} />
      <Div
        css={{
          padding: '$4',
        }}
      >
        <Div
          css={{
            marginBottom: '$2',
          }}
        >
          <SelectWorkerDropdown />
        </Div>
        <DocumentManagementStructure />
      </Div>
      <AddDocumentDrawer />
      <EditDocumentDrawer />
      <ViewUserDocumentModal />
    </>
  )
}

const SelectWorkerDropdown = () => {
  const methods = useFormContext<IDocumentsStateSchema>()

  const userId = useWatch({
    control: methods.control,
    name: 'management.userId',
  })

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('documents')),
  )

  const { data: workers, isFetching: isFetchingSelectedWorkers } = useWorkers({
    cellsIds: availableCells?.map((c) => c.id),
    policiesIds: permissionsArray.filter((p) => p.includes('documents')),
    page: 0,
    pageSize: 10_000,
    allUsers: true,
  })

  return (
    <Div
      css={{
        width: '30%',
        zIndex: 5,
      }}
    >
      <DropdownV2
        isLoading={isFetchingSelectedWorkers}
        containerCss={{
          zIndex: 4,
        }}
        value={userId}
        onChangeValue={(v) => {
          methods.setValue('management.userId', v.value)
        }}
        options={
          workers?.data?.map((w) => {
            return {
              label: capitalizeFirstLetters(w.name),
              value: w.id,
            }
          }) || []
        }
      />
    </Div>
  )
}
