import { useQuery } from 'react-query'
import { apiV2 } from '@/services/api'

const fetchDocumentsTotalSpace = async () => {
  const response = await apiV2.post<number>('/documents/get-total-space')

  return response.data
}

export const useDocumentsTotalSpace = () => {
  const query = useQuery(['documents-total-space'], fetchDocumentsTotalSpace)

  return query
}
