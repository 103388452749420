import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IPayment } from '@/libs/react-query/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { queryClient } from '@/libs/react-query'
import { IPaymentSchema } from '@/pages/pagos/create-batch/form'
import { CreatePaymentPayload } from '../types'
dayjs.extend(utc)

const handleCreatePayment = async (props: IPaymentSchema) => {
  const apiPayload = handleParseDataToAPI(props)

  const response = await apiV2.post<IPayment>(
    '/payment/create-payment',
    apiPayload,
  )
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleCreatePayments = () => {
  const mutation = useMutation(['create-payment'], handleCreatePayment)

  return mutation
}

const handleParseDataToAPI = (
  payment: IPaymentSchema,
): CreatePaymentPayload => {
  // const dates =
  //   payment.dates.length === 2
  //     ? [
  //         {
  //           start_date: dayjs(payment.dates[0]).utc().format(),
  //           end_date: dayjs(payment.dates[1]).utc().format(),
  //         },
  //       ]
  //     : [
  //         {
  //           date: dayjs(payment.dates[0]).utc().format(),
  //         },
  //       ]
  const dates: any = []
  return {
    title: payment.title,
    description: payment.description,
    dates,
    payment_date: dayjs(payment.payment_date[0]).startOf('day').format(),
    payment_type: payment.payment_type,
    payroll_label: payment.payment_type,
    users: payment.usersIds.map((userId) => ({
      id: userId,
      ips: !!payment.ips,
    })),
    value: payment.value,
    value_type: payment.value_type,
    discount_ips9: payment.discount_ips9,
  }
}
