import { ControlledFilterBar, Div } from '@/components'
import { useAudits } from '@/libs/react-query/hooks/useAudits'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAllAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import React, { useEffect } from 'react'
import { IAuditStateSchema } from '../../audit-state'
import { AuditItem } from './AuditListItem'
import { Text } from '@punto-ui/react'
import { AuditPagination } from './AuditPagination'
import { AUDIT_TYPE_TO_LABEL } from '@/libs/react-query/types/audits'

export const AuditList = () => {
  const methods = useFormContext<IAuditStateSchema>()
  const auditList = useWatch({
    control: methods.control,
    name: 'auditList',
  })
  const [auditType, name] = useWatch({
    control: methods.control,
    name: ['auditList.type', 'auditList.name'],
  })

  useAllAvailableCells({
    methods,
    name: 'auditList.cellsIds',
    permission: 'audit',
  })

  const { data: audits, isFetching: isFetchingAudits } = useAudits({
    page: +auditList.page,
    perPage: +auditList.perPage,
    cellsIds: auditList.cellsIds,
    types: auditList.type,
    name: auditList.name,
  })

  useEffect(() => {
    if (audits) {
      methods.setValue('auditList.page', audits.page)
      methods.setValue('auditList.perPage', audits.pageSize)
    }
  }, [audits])

  useEffect(() => {
    methods.setValue('auditList.page', 0)
  }, [auditType, name])

  return (
    <Div>
      <ControlledFilterBar
        genericFilters={[
          {
            options: Object.entries(AUDIT_TYPE_TO_LABEL).map(
              ([key, value]) => ({
                label: value,
                value: key,
              }),
            ),
            single: false,
            name: 'auditList.type',
            label: 'Módulo',
            defaultValues: [],
          },
        ]}
        policiesIds={permissionsArray.filter((p) => p.includes('permisos'))}
        isLoading={isFetchingAudits}
        nameFilterName="auditList.name"
        cellsIdsName="auditList.cellsIds"
        withAdvancedCells
      />
      <Div
        css={{
          maxHeight: 'calc(100vh - 180px)',
          overflow: 'hidden',
          padding: '$5',
        }}
      >
        <Div
          css={{
            overflowX: 'scroll',
            maxHeight: 'calc(100vh - 200px)',
            background: '$interface_light_pure',
            borderRadius: '$lg',
          }}
        >
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              background: '$interface_light_pure',
              borderRadius: '$lg',
            }}
          >
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',

                padding: 12,

                position: 'sticky',
                top: 0,

                backgroundColor: '$interface_light_pure',
                zIndex: 1,

                '> div': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '$interface_light_pure',
                },
              }}
            >
              <Div
                css={{
                  minWidth: 316,
                  justifyContent: 'flex-start !important',
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Acción
                </Text>
              </Div>
              <Div
                css={{
                  minWidth: 200,
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Módulo
                </Text>
              </Div>
              <Div
                css={{
                  minWidth: 200,
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Status
                </Text>
              </Div>
              <Div
                css={{
                  minWidth: 200,
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Autorizaciones
                </Text>
              </Div>
              <Div
                css={{
                  minWidth: 250,
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Solicitud por
                </Text>
              </Div>
              <Div
                css={{
                  minWidth: 250,
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Colaboradores
                </Text>
              </Div>
              <Div
                css={{
                  minWidth: 200,
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$semibold',
                  }}
                >
                  Fecha
                </Text>
              </Div>
            </Div>
            {audits?.data?.map((audit, index) => (
              <AuditItem audit={audit} key={audit.id} index={index} />
            ))}
          </Div>
        </Div>
      </Div>
      <Div>
        <AuditPagination
          total={audits?.total || 0}
          totalPages={audits?.totalPages || 0}
        />
      </Div>
    </Div>
  )
}
