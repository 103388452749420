import { Text } from '@punto-ui/react'
import { styled } from '../../styles'

export const InputDatePickerContainer = styled('div', {
  position: 'relative',
})

export const InputContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  width: '100%',

  padding: '8px 12px',
  borderRadius: '$md',

  border: 'solid 1px',
  borderColor: '$brand_primary_pure',

  [`${Text}`]: {
    flex: 1,
    color: '$brand_primary_pure',
    marginRight: '$2',
  },

  minHeight: 24,
  minWidth: 190,

  svg: {
    height: 15,
    strokeWidth: 2,
    color: '$brand_primary_pure',
    width: 15,
  },
})

export const Touchable = styled('div', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
})

export const AllInputContainer = styled('div', {
  display: 'flex',
})

export const CalendarContainer = styled('div', {
  // position: 'absolute',
  // top: 'calc(100% + 4px)',
  // left: 0,
  // right: 0,
  // zIndex: 1,

  maxWidth: 256,
  minWidth: 250,

  backgroundColor: '$interface_light_pure',
  borderRadius: '$md',

  display: 'none',
  flexDirection: 'column',
  alignItems: 'stretch',

  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
  border: 'solid 1px $interface_light_down',
  // opacity: 0,
  transition: 'opacity 0.5s ease-in-out',

  overflow: 'hidden',

  variants: {
    stayOpen: {
      true: {
        position: 'unset',
        marginTop: '$2',
      },
    },
    // isOpen: {
    //   true: {
    //     display: 'flex',
    //     opacity: 1,
    //   },
    //   false: {
    //     display: 'none',
    //     opacity: 0,
    //   },
    // },
  },
})
