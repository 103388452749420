import { Box, Switch, Text, Tooltip } from '@punto-ui/react'
import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { ControlledSwitchP } from './types'
import { Div } from '@/components/Div'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

export const ControlledSwitch = <TFieldValues extends FieldValues>({
  onValueChange,
  help,
  makeHeightCorrection,
  ...props
}: ControlledSwitchP<TFieldValues>) => {
  const { control, setValue, getValues } = useFormContext()

  const value = useWatch({
    control,
    name: props.name,
    defaultValue: getValues(props.name),
  })

  const onCheckboxValueChange = (b: boolean) => {
    onValueChange?.(b)
    setValue(props.name, b as any)
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {makeHeightCorrection && (
        <Div
          css={{
            height: 26,
          }}
        />
      )}
      <Box
        as="label"
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '$2',
          padding: '$0',
          cursor: props.disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <Switch
          {...props}
          defaultChecked={value}
          onCheckedChange={onCheckboxValueChange}
          value={value}
          checked={value}
        />
        {!!props.label && (
          <Text size="sm" css={{ color: '$interface_dark_deep' }}>
            {props.label}
          </Text>
        )}
        {!!help && (
          <Tooltip message={help} arrow>
            <Div
              css={{
                height: 24,
                width: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                svg: {
                  color: '$interface_dark_down',
                  height: 20,
                  width: 20,
                },
              }}
            >
              <QuestionMarkCircleIcon />
            </Div>
          </Tooltip>
        )}
      </Box>
    </Div>
  )
}
