import { SmartColumnType } from '@/components/SmartTable/types'
import { Checkbox } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'

export const CheckboxHeader = ({
  name,
  column,
}: {
  name?: string
  column: SmartColumnType
}) => {
  const { setValue, control } = useFormContext()
  const allData = useWatch({
    name: 'data',
    control,
  })

  if (!name) return null

  const isAllSelected = allData?.every(
    (item: any) => !!(item[name].value || item[name].metadata?.disabled),
  )

  return (
    <Checkbox
      checked={isAllSelected}
      onCheckedChange={(e) => {
        const newData = allData?.map((item: any) => {
          const value = item[name].metadata?.disabled ? false : !!e

          return {
            ...item,
            [name]: {
              ...item[name],
              value,
            },
          }
        })

        setValue('data', newData)
        column?.onAllCheckedChange?.(!!e)
      }}
    />
  )
}
