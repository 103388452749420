import { FormRenderer } from '@/components'
import { FormContainer } from '..'
import { useVacations } from '../form/useVacations'
import { LiquidationCardHeader } from './LiquidationCardHeader'
import { InactivateWorkerSchema } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { formatCurrency } from '@/utils/numbers/currency'
import { useLiquidationConceptDisabled } from '../hooks/useLiquidationConceptDisabled'

interface ICausedVacationLiquidationParams {
  isOpen: boolean
  toggleOpen: (v: boolean) => void
}

export const CausedVacationLiquidation = ({
  isOpen,
  toggleOpen,
}: ICausedVacationLiquidationParams) => {
  const conceptKey = 'caused_vacations'

  const { control } = useFormContext<InactivateWorkerSchema>()
  const [totalVacationValue, vacationDays] = useWatch({
    control,
    name: ['data.total_vacations.value', 'data.total_vacations.earned_days'],
  })

  const { form: vacationsForm } = useVacations()

  const { disabled: isConceptDeactivated, concept } =
    useLiquidationConceptDisabled({
      conceptKey,
    })
  const isConceptClosed = isConceptDeactivated === true ? true : !isOpen

  return (
    <FormContainer>
      <LiquidationCardHeader
        isOpen={!isConceptClosed}
        setIsOpen={toggleOpen}
        title={concept.name || 'Vacaciones Causadas'}
        totals={[
          `Dias en haber: ${vacationDays} dias`,
          `Valor en haber: ${formatCurrency(+totalVacationValue)}`,
        ]}
        conceptType="basic"
        isIpsDisabled={!concept.ips}
        deactivateKey={conceptKey}
        disabled={isConceptDeactivated || false}
      />
      {!isConceptClosed && (
        <FormRenderer inline items={vacationsForm} cols={5} />
      )}
    </FormContainer>
  )
}
