import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { SmartTableFormItemValue } from '@/components/SmartTable/types'
import { z } from 'zod'

export const ReceiptTableSchema = z.object({
  data: z
    .object({
      id: z.string(),
      name: SmartTableFormItemValue,
      description: SmartTableFormItemValue,
      type: SmartTableFormItemValue,
      favorite: SmartTableFormItemValue,
      footerType: SmartTableFormItemValue,
      headerType: SmartTableFormItemValue,
      created_by: SmartTableFormItemValue,
      created_at: SmartTableFormItemValue,

      original: z.any(),
    })
    .array(),

  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
})

export type ReceiptTableSchemaType = z.infer<typeof ReceiptTableSchema>
export type ReceiptTableFields = Array<
  ControlledFieldProps<ReceiptTableSchemaType>
>
