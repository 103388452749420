import { create } from 'zustand'

interface DayDetailContextProps {
  view: 'tabs' | 'edit-movement' | 'add-movement'
  params: any

  updateView: (
    view: 'tabs' | 'edit-movement' | 'add-movement',
    params: any,
  ) => void
}

export const useDayDetail = create<DayDetailContextProps>((set, get) => {
  return {
    view: 'tabs',
    params: {},

    updateView: (view, params) => {
      set({ view, params })
    },
  }
})
