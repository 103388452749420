import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  SmartTableFormItemValue,
  SmartTableFormTagValue,
} from '@/components/SmartTable/types'
import { z } from 'zod'

const importWorkerContent = z.object({
  userChildren: z
    .object({
      name: z.string(),
      birthday: z.date().optional(),
      document: z.string().optional(),
      shouldReceiveChildrenBonus: z.boolean(),
    })
    .array(),

  externalIds: SmartTableFormTagValue,
  document: SmartTableFormItemValue,
  email: SmartTableFormItemValue,
  name: SmartTableFormItemValue,
  surname: SmartTableFormItemValue,
  phone: SmartTableFormItemValue,
  civilState: SmartTableFormTagValue,
  nationality: SmartTableFormTagValue,
  birthday: SmartTableFormItemValue,
  gender: SmartTableFormTagValue,
  bank: SmartTableFormItemValue,
  bankAccount: SmartTableFormItemValue,
  country: SmartTableFormItemValue,
  state: SmartTableFormItemValue,
  city: SmartTableFormItemValue,
  street: SmartTableFormItemValue,
  number: SmartTableFormItemValue,
  complement: SmartTableFormItemValue,
  neighborhood: SmartTableFormItemValue,
  cell: SmartTableFormItemValue,
  position: SmartTableFormItemValue,
  entranceDate: SmartTableFormItemValue,
  ipsEntranceDate: SmartTableFormItemValue,
  baseSalary: SmartTableFormItemValue,
  punchConfigId: SmartTableFormItemValue,
  paymentConfigId: SmartTableFormItemValue,
  vacationConfigId: SmartTableFormItemValue,
  children: SmartTableFormItemValue.array(),
})

export const importWorkersSchema = z.object({
  // files
  uploadedFile: z.custom<File>().optional(),

  data: z.array(importWorkerContent),
})

export type ImportWorkersContent = z.infer<typeof importWorkerContent>
export type ImportWorkersSchema = z.infer<typeof importWorkersSchema>
export type ImportWorkersFields = Array<
  ControlledFieldProps<ImportWorkersSchema>
>
