import React, { useEffect } from 'react'

export function useOutsideAlerters(
  refs: React.RefObject<HTMLElement>[],
  callback: () => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        refs.every((ref) => ref.current) &&
        event &&
        event.target &&
        !refs.some((ref) => ref.current?.contains(event.target))
      ) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refs, callback])
}
