import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import {
  SmartTableAvatarItemValue,
  SmartTableFormItemValue,
  SmartTableFormTagValue,
} from '@/components/SmartTable/types'
import { z } from 'zod'

export const PayrollPlaygroundContent = z.object({
  userId: z.string(),

  checked: SmartTableFormItemValue,
  avatar: SmartTableAvatarItemValue,
  name: SmartTableFormItemValue,
  surname: SmartTableFormItemValue,
  ci: SmartTableFormItemValue,
  punchConfigId: SmartTableFormItemValue,
  paymentConfigId: SmartTableFormItemValue,
  daysSummary: SmartTableFormTagValue.array(),
  naturalPaidDays: SmartTableFormItemValue,
  naturalUnpaidDays: SmartTableFormItemValue,
  countablePaidDays: SmartTableFormItemValue,
  countableUnpaidDays: SmartTableFormItemValue,
  totalWorkedDaysCompensation: SmartTableFormItemValue,
  totalMorningOrdinaryHours: SmartTableFormItemValue,
  totalMorningExtraHours: SmartTableFormItemValue,
  totalMorningExtraValue: SmartTableFormItemValue,
  totalMorningHolidayHours: SmartTableFormItemValue,
  totalMorningHolidayValue: SmartTableFormItemValue,
  totalMorningHours: SmartTableFormItemValue,
  totalMorningValue: SmartTableFormItemValue,
  additionalNightHours: SmartTableFormItemValue,
  totalNightOrdinaryHours: SmartTableFormItemValue,
  totalNightExtraHours: SmartTableFormItemValue,
  totalNightExtraValue: SmartTableFormItemValue,
  totalNightHolidayHours: SmartTableFormItemValue,
  totalNightHolidayValue: SmartTableFormItemValue,
  totalNightHours: SmartTableFormItemValue,
  totalNightValue: SmartTableFormItemValue,
  vacationDays: SmartTableFormItemValue,
  vacationDaysValue: SmartTableFormItemValue,
  childrenBonus: SmartTableFormItemValue,
  childrenBonusValue: SmartTableFormItemValue,
  conceptsPreIPS: SmartTableFormItemValue.array(),
  creditPaymentsPreIPS: SmartTableFormItemValue.array(),
  creditPaymentsAfterIPS: SmartTableFormItemValue.array(),
  paymentsSubtotal: SmartTableFormItemValue,
  positiveSubtotal: SmartTableFormItemValue,
  totalMorningDiscountHours: SmartTableFormItemValue,
  totalMorningDiscountHoursValue: SmartTableFormItemValue,
  totalNightDiscountHours: SmartTableFormItemValue,
  totalNightDiscountHoursValue: SmartTableFormItemValue,
  discountsSubtotal: SmartTableFormItemValue,
  totalDiscount: SmartTableFormItemValue,
  discountsPreIPS: SmartTableFormItemValue.array(),
  positiveIpsSubtotal: SmartTableFormItemValue,
  negativeIpsSubtotal: SmartTableFormItemValue,
  ipsBase: SmartTableFormItemValue,
  ips16: SmartTableFormItemValue,
  ips9: SmartTableFormItemValue,
  totalIps: SmartTableFormItemValue,
  salaryAfterIps: SmartTableFormItemValue,
  discountsAfterIPS: SmartTableFormItemValue.array(),
  conceptsAfterIPS: SmartTableFormItemValue.array(),
  finalSalaryValue: SmartTableFormItemValue,
  totalDiscountsAfterIPS: SmartTableFormItemValue,
  totalPaymentsAfterIPS: SmartTableFormItemValue,
  totalConceptsAfterIPS: SmartTableFormItemValue,
  baseSalary: SmartTableFormItemValue,
})

export const PayrollPlaygroundTable = z.object({
  data: z.array(PayrollPlaygroundContent),

  name: z.string(),
  status: z.string(),
  nameFilter: z.string(),
  pagination: z.object({
    page: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
  }),
  cellsIds: z.string().array(),
  description: z.string(),
  signatureDate: z.date().array(),
  periods: z.array(z.date()),
  cell: z.string(),
  isFavorite: z.boolean(),
  users_ids: z.array(z.string()),
  id: z.string(),
})

export type PayrollPlaygroundTableType = z.infer<typeof PayrollPlaygroundTable>
export type PayrollPlaygroundContentType = z.infer<
  typeof PayrollPlaygroundContent
>
export type PayrollPlaygroundFields = Array<
  ControlledFieldProps<PayrollPlaygroundTableType>
>
