import { Div } from '@/components'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import React, { useEffect } from 'react'
import { useHandleReceiptBuilderActions } from './actions'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { IReceiptBuilderTypes, ReceiptBuilderSchemaType } from './type'
import {
  fromReceiptTypeToRowsOptions,
  getCurrentReceiptRows,
} from './receipt-builder'
import { ReceiptStepper } from './components/ReportStepper'
import { ReceiptBuilderForm } from './components/ReceiptBuilderForm'
import { ReceiptBuilderPreview } from './components/ReceiptBuilderPreview'
import { v4 as uuid } from 'uuid'
import { useReportReceipt } from '@/libs/react-query/hooks/useReportReceipt'

export const getEmptyReceiptRow = () => {
  return {
    amount: { value: '', status: '' },
    conceptLabel: { value: '', status: '' },
    concepts: [],
    id: uuid(),
    includedConceptsInRow: [],
  }
}

export const ReceiptBuilder = (props: { id?: string }) => {
  const { data: company } = useMyCompany()

  const methods = useForm<ReceiptBuilderSchemaType>({
    defaultValues: {
      configurations: {
        title: '',
        description: '',
        isFavorite: false,
        type: '',
      },
      headerConfig: {
        title: '',
      },
      rowsOptions: {},
      data: [],
      currentStep: 0,
      loadedTemplate: !props.id,
      id: props.id || '',
      identifier_id: '',
      openColumns: {},
    },
  })

  useHandleReceiptBuilderActions({
    methods,
  })

  const [receiptType] = useWatch({
    control: methods.control,
    name: ['configurations.type'],
  })

  const { data: receipt } = useReportReceipt(props.id || '')

  const isFirstRender = React.useRef(true)

  useEffect(() => {
    if (isFirstRender.current && props.id) {
      isFirstRender.current = false
      return
    }

    const rowsOptions = fromReceiptTypeToRowsOptions(
      receiptType as IReceiptBuilderTypes,
      company?.concepts || [],
      company?.liquidationConcepts || [],
    )

    methods.setValue('rowsOptions', rowsOptions)
    methods.setValue(
      'openColumns',
      Object.keys(rowsOptions).reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {} as any),
    )

    const SINGLE_ROW_REPORTS = ['payment', 'vacations']

    const numberOfInitialRows = SINGLE_ROW_REPORTS.includes(receiptType) ? 1 : 5
    const initialRows = Array.from({ length: numberOfInitialRows }).map(() =>
      getEmptyReceiptRow(),
    )

    methods.setValue('data', initialRows)
  }, [props.id, receiptType, methods, company])

  useEffect(() => {
    if (!receipt) return

    methods.setValue('id', receipt.id)
    methods.setValue('identifier_id', receipt.identifier_id)
    methods.setValue('configurations', {
      description: receipt.description,
      isFavorite: receipt.favorite,
      title: receipt.title,
      name: receipt.name,
      type: receipt.type,
    })

    methods.setValue('footerConfig.type', receipt.footerType)
    methods.setValue('footerConfig.city', receipt.footerCity || undefined)
    methods.setValue('headerConfig.type', receipt.headerType)
    methods.setValue('headerConfig.title', receipt.headerTitle)

    const rows = getCurrentReceiptRows(
      receiptType,
      company?.concepts || [],
      company?.liquidationConcepts || [],
      receipt,
    )

    methods.setValue('data', rows)

    methods.setValue('loadedTemplate', true)
  }, [receipt, company, methods, receiptType])

  return (
    <FormProvider {...methods}>
      <Div
        css={{
          margin: 20,
          background: '$interface_light_pure',
          borderRadius: '$lg',
          border: '1px solid $interface_light_down',
        }}
      >
        <ReceiptStepper />
        <Div
          css={{
            display: 'flex',

            height: 'calc(100vh - 54px - 20px - 60px - 24px)',
          }}
        >
          <ReceiptBuilderForm />
          <ReceiptBuilderPreview />
        </Div>
      </Div>
    </FormProvider>
  )
}
