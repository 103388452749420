import { Div } from '@/components'
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline'
import { Text } from '@punto-ui/react'

export const EmptyContent = () => {
  return (
    <Div
      css={{
        flex: 1,

        background: '$brand_complementary_up',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        gap: '$2',
        height: 24,

        border: '1px solid $interface_light_deep',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '> svg': {
            height: 24,
            width: 24,
            color: '$brand_complementary_pure',
          },
        }}
      >
        <ArrowPathRoundedSquareIcon />
      </Div>
      <Text
        variant="caption"
        css={{
          color: '$brand_complementary_pure',
        }}
      >
        Agrege Contenido
      </Text>
    </Div>
  )
}
