import { generateRandomHour, generateRandomStatus } from '@/utils/random'
import { IReportBuilderColumnType } from '../../type'

export const singlePunchColumns: IReportBuilderColumnType[] = [
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomStatus,
    value: 'status',
    label: 'Status',
    valueType: 'string' as const,
    subgroup: 'Status',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'entrance',
    valueType: 'date' as const,
    label: 'Entrada',
    subgroup: 'Fechas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'exit',
    label: 'Salida',
    subgroup: 'Fechas',
    valueType: 'date' as const,
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'intervals',
    label: 'Intervalos',
    valueType: 'date' as const,
    subgroup: 'Fechas',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_hours',
    label: 'Total de Horas',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Generales',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_ordinary',
    label: 'Total Ord.',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Generales',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_extra',
    label: 'Total Ext.',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Generales',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_holiday',
    label: 'Total Fer.',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Generales',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_discount',
    label: 'Total Desc.',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Generales',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_morning',
    label: 'Total Diurno',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_night',
    getRandomPlaceholder: generateRandomHour,
    label: 'Total Nocturno',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    value: 'total_ordinary_morning',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'Total Ord. Diur.',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_ordinary_night',
    getRandomPlaceholder: generateRandomHour,
    label: 'Total Ord. Noc.',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_morning_extra',
    valueType: 'number' as const,
    label: 'Total Ext. Diur.',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    value: 'total_night_extra',
    getRandomPlaceholder: generateRandomHour,
    label: 'Total Ext. Noc.',
    valueType: 'number' as const,
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_morning_holiday',
    getRandomPlaceholder: generateRandomHour,
    label: 'Total Fer. Diur.',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    value: 'total_night_holiday',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    label: 'Total Fer. Noc.',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    value: 'total_morning_discount',
    valueType: 'number' as const,
    label: 'Total Desc. Diur.',
    getRandomPlaceholder: generateRandomHour,
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_night_discount',
    getRandomPlaceholder: generateRandomHour,
    label: 'Total Desc. Noc.',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    valueType: 'number' as const,
    value: 'total_interval',
    getRandomPlaceholder: generateRandomHour,
    label: 'Total Intervalo',
    subgroup: 'Totales Diurnos y Nocturnos',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_executed_authorized_ordinary_night',
    label: 'Total Aut. Ord. Noc.',
    valueType: 'number' as const,
    subgroup: 'Totales Autorizadas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_executed_authorized_morning_extra',
    valueType: 'number' as const,
    label: 'Total Aut. Ext. Diur.',
    subgroup: 'Totales Autorizadas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_executed_authorized_night_extra',
    valueType: 'number' as const,
    label: 'Total Aut. Ext. Noc.',
    subgroup: 'Totales Autorizadas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_executed_authorized_morning_holiday',
    valueType: 'number' as const,
    label: 'Total Aut. Fer. Diur.',
    subgroup: 'Totales Autorizadas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    valueType: 'number' as const,
    value: 'total_executed_authorized_night_holiday',
    label: 'Total Aut. Fer. Noc.',
    subgroup: 'Totales Autorizadas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_executed_authorized_morning_discount',
    label: 'Total Aut. Desc. Diur.',
    valueType: 'number' as const,
    subgroup: 'Totales Autorizadas',
  },
  {
    type: 'punches',
    getRandomPlaceholder: generateRandomHour,
    value: 'total_executed_authorized_night_discount',
    label: 'Total Aut. Desc. Noc.',
    valueType: 'number' as const,
    subgroup: 'Totales Autorizadas',
  },
]
