// import {
//   ArchiveBoxArrowDownIcon,
//   ArrowUpTrayIcon,
//   DocumentDuplicateIcon,
//   ShieldCheckIcon,
//   TrashIcon,
// } from '@heroicons/react/24/outline'
import { styled } from '@punto-ui/react'
import { useShiftCreation } from '../../context/hooks'

export const ActionsMenu = () => {
  const { isUpdating } = useShiftCreation()

  if (!isUpdating) {
    return null
  }

  return (
    <ActionsMenuContainer>
      {/* <Button variant={'secondary'}>
        <ArrowUpTrayIcon />
        Exportar
      </Button>
      <Button variant={'secondary'}>
        <DocumentDuplicateIcon />
        Duplicar
      </Button>
      <Button variant={'secondary'}>
        <ShieldCheckIcon />
        Activar
      </Button>
      <Button variant={'secondary'}>
        <ArchiveBoxArrowDownIcon />
        Archivar
      </Button>
      <Button variant={'tertiaryCritical'}>
        <TrashIcon />
        Eliminar
      </Button> */}
    </ActionsMenuContainer>
  )
}

const ActionsMenuContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginTop: 16,
})
