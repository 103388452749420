import { useEffect } from 'react'
import { IRouteAction } from '@/pages/router'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { UseFormReturn } from 'react-hook-form'
import { ImportPunchesSchema } from './form'
import { useHandleCreateManyPunchesFromDocument } from '@/libs/react-query/mutations/punchclock/useHandleCreateManyFromDocument'
import { parsePunchesCreationStructure } from './parser'
import useToast from '@/hooks/useToast'

export const useHandlePunchesImportActions = ({
  methods,
  isLoading,
}: {
  methods: UseFormReturn<ImportPunchesSchema>
  isLoading?: boolean
}) => {
  const { addToast } = useToast()
  const { setActions } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('punches', 'summary'),
    setActions: state.setActionsTab,
  }))

  const {
    mutateAsync: handleCreateManyPunches,
    isLoading: isLoadingCreateMany,
  } = useHandleCreateManyPunchesFromDocument()

  useEffect(() => {
    const actions: IRouteAction[] = []

    const isLoadingOrDisabled = isLoading || isLoadingCreateMany

    actions.push({
      label: 'Importar',
      action: async () => {
        try {
          const values = methods.getValues('data')
          if (!values.length) return

          const dto = parsePunchesCreationStructure(values)

          await handleCreateManyPunches(dto)

          addToast({
            title: 'Éxitos en la importación 👌',
            description: 'La importación de marcaciones fue exitosa',
            type: 'positive',
            id: Date.now(),
          })
        } catch (err) {
          addToast({
            title: 'Uppz! La importación no fue exitosa ❌',
            description: '¡Fracasado! Inténtalo de nuevo',
            type: 'negative',
            id: Date.now(),
          })
        }
      },
      disabled: isLoadingOrDisabled,
      isLoading: isLoadingOrDisabled,
      variant: 'primary',
      icon: <DocumentArrowDownIcon />,
    })

    setActions('punches', actions)

    return () => {
      setActions('punches', [])
    }
  }, [
    isLoading,
    methods,
    setActions,
    isLoadingCreateMany,
    handleCreateManyPunches,
  ])
}
