export const getFirstName = (name: string) => {
  const nameArray = name.split(' ')

  if (nameArray.length <= 3) {
    return nameArray[0]
  } else {
    return `${nameArray[0]} ${nameArray[1]}`
  }
}

export const getLastName = (name: string) => {
  const nameArray = name.split(' ')

  if (nameArray.length === 1 || nameArray.length === 0) {
    return ''
  }

  if (nameArray.length === 2) {
    return nameArray[nameArray.length - 1]
  }

  if (nameArray.length === 3) {
    return `${nameArray[nameArray.length - 2]} ${
      nameArray[nameArray.length - 1]
    }`
  }

  return `${nameArray[nameArray.length - 2]} ${nameArray[nameArray.length - 1]}`
}

export function capitalizeFirstLetter(word: string) {
  if (!word) return word // Return the original word if it's empty
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export function capitalizeFirstLetters(words?: string) {
  return words
    ? words
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ') || ''
    : ''
}

export function shortenName(name: string) {
  const nameArray = name.split(' ').filter((c) => c !== '')

  if (nameArray.length > 3) {
    // Keep the first and last names intact
    const firstName = nameArray[0]
    const lastName = nameArray[nameArray.length - 1]

    // Map the middle names to their initials
    const initials = nameArray.slice(1, -1).map((name) => name.charAt(0) + '.')

    // Join the first name, initials, and last name
    return [firstName, ...initials, lastName].join(' ')
  }

  // Return the name as is if it doesn't need shortening
  return name
}
