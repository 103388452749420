import { Drawer, DrawerContainer } from '@/components/Drawer'
import { IDocumentsStateSchema } from '../../documents-state'
import { useFormContext, useWatch } from 'react-hook-form'
import { Avatar, ControlledInput, Div } from '@/components'
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button, Divider, Text } from '@punto-ui/react'
import React, { useCallback, useState } from 'react'
import { ControlledDropdown } from '@/components/Forms'
import { useDocumentsStructure } from '@/libs/react-query/hooks/useDocumentsStructure'
import { flattenDocumentTree, getFileTypeIcon } from '../../documents-utils'
import { useWorkers } from '@/libs/react-query/hooks'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { capitalizeFirstLetters } from '@/utils/workers/name'
import { useDropzone } from 'react-dropzone'
import { useHandleUpdateDocumentEntity } from '@/libs/react-query/mutations/documents/useHandleUpdateDocumentEntity'
import { useHandleDeleteDocumentEntity } from '@/libs/react-query/mutations/documents/useHandleDeleteDocumentEntity'
import { ProgressBar } from '@/components/ProgressBar'

export const EditDocumentDrawer = () => {
  const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false)
  const { data: structure } = useDocumentsStructure()

  const methods = useFormContext<IDocumentsStateSchema>()

  const editDocumentEntity = useWatch({
    control: methods.control,
    name: 'editDocumentEntity',
  })

  const { documentFiles, isEditDocumentOpen, name, userId } = editDocumentEntity

  const setIsOpen = (v: boolean) => {
    methods.setValue('editDocumentEntity.isEditDocumentOpen', v)
    setIsConfirmingDeletion(false)
  }

  const documentOptions = structure ? flattenDocumentTree(structure) : []

  const {
    mutateAsync: handleUpdateDocumentEntity,
    isLoading: isLoadingUpdateDocumentEntity,
  } = useHandleUpdateDocumentEntity()

  const [progress, setProgress] = useState(0)

  const onProgress = (p: number) => {
    setProgress(p)
  }

  const {
    mutateAsync: handleDeleteDocumentEntity,
    isLoading: isLoadingDeleteDocumentEntity,
  } = useHandleDeleteDocumentEntity()

  const handleUpdateUserDocument = async () => {
    const isValid = await methods.trigger('editDocumentEntity')
    console.log(methods.formState.errors)
    if (!isValid) return
    const data = methods.getValues('editDocumentEntity')

    await handleUpdateDocumentEntity({
      files: data.newFiles,
      documentFiles: data.documentFiles,
      folderId: data.folderId,
      id: data.id,
      name: data.name,

      onProgress,
    })

    methods.setValue('editDocumentEntity', {
      id: '',
      userId: '',
      name: '',
      folderId: '',
      isEditDocumentOpen: false,
      documentFiles: [],
      newFiles: [],
    })

    setProgress(0)
  }

  const handleDeleteUserDocument = async () => {
    if (!isConfirmingDeletion) {
      setIsConfirmingDeletion(true)
      return
    }
    const data = methods.getValues('editDocumentEntity')
    await handleDeleteDocumentEntity(data.id)

    methods.setValue('editDocumentEntity', {
      id: '',
      userId: '',
      name: '',
      folderId: '',
      isEditDocumentOpen: false,
      documentFiles: [],
      newFiles: [],
    })

    setProgress(0)
  }

  const { data: users } = useWorkers({
    policiesIds: permissionsArray.filter((p) => p.includes('documents')),
    usersIds: userId ? [userId] : [],
    page: -1,
    allUsers: true,
  })

  const user = users?.data[0]

  return (
    <DrawerContainer open={isEditDocumentOpen} onOpenChange={setIsOpen}>
      <Drawer
        open={isEditDocumentOpen}
        onOpenChange={setIsOpen}
        noPadding
        content={
          <Div
            css={{
              minWidth: 610,
              maxWidth: 'min(50vw, 1000px)',
            }}
          >
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '$16',
              }}
            >
              {!!progress && (
                <ProgressBar
                  progressInPercent={progress}
                  text={
                    progress === 100 ? 'Finalizando edición' : `${progress}%`
                  }
                />
              )}
              <Div
                css={{
                  flex: 1,
                  height: 44,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '$4',
                  paddingRight: '$4',
                }}
              >
                <Button
                  size={'sm'}
                  variant="tertiary"
                  onClick={() => setIsOpen(false)}
                  disabled={
                    isLoadingUpdateDocumentEntity ||
                    isLoadingDeleteDocumentEntity
                  }
                  isLoading={isLoadingDeleteDocumentEntity}
                >
                  <XMarkIcon />
                  Cancelar
                </Button>
                <Button
                  size={'sm'}
                  variant={
                    isConfirmingDeletion
                      ? 'primaryCritical'
                      : 'secondaryCritical'
                  }
                  onClick={handleDeleteUserDocument}
                  isLoading={isLoadingDeleteDocumentEntity}
                  disabled={
                    isLoadingUpdateDocumentEntity ||
                    isLoadingDeleteDocumentEntity
                  }
                >
                  <TrashIcon />
                  {isConfirmingDeletion ? 'Confirmar Exclusión' : 'Excluir'}
                </Button>
                <Button
                  size={'sm'}
                  isLoading={
                    isLoadingUpdateDocumentEntity ||
                    isLoadingDeleteDocumentEntity
                  }
                  onClick={handleUpdateUserDocument}
                >
                  <CheckCircleIcon />
                  Guardar
                </Button>
              </Div>
            </Div>
            <Div
              css={{
                padding: '$4',
              }}
            >
              <Text
                variant="heading3"
                css={{
                  marginBottom: '$8',
                  color: '$interface_dark_deep',
                }}
              >
                Edición de: {name}
              </Text>

              <DrawerItemContainer>
                <DrawerItemLabel>Colaborador</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={user?.photo_url || ''}
                    alt=""
                    height={18}
                    width={18}
                  />
                  <Text
                    variant="caption"
                    css={{
                      color: '$interface_dark_deep',
                      marginLeft: '$1',
                    }}
                  >
                    {capitalizeFirstLetters(user?.name)}
                  </Text>
                </Div>
              </DrawerItemContainer>

              <DrawerItemContainer>
                <DrawerItemLabel>Ubicación</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                  }}
                >
                  <ControlledDropdown
                    name="editDocumentEntity.folderId"
                    options={documentOptions.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }))}
                  />
                </Div>
              </DrawerItemContainer>

              <DrawerItemContainer>
                <DrawerItemLabel>Nombre</DrawerItemLabel>
                <Div
                  css={{
                    flex: 1,
                  }}
                >
                  <ControlledInput
                    placeholder="Mi archivo"
                    name="editDocumentEntity.name"
                  />
                </Div>
              </DrawerItemContainer>
            </Div>
            <Divider orientation={'horizontal'} />
            <Div
              css={{
                maxHeight: 'calc(100vh - 240px)',
                overflow: 'scroll',
              }}
            >
              <Div
                css={{
                  padding: '$2',
                  paddingTop: '$4',
                  paddingBottom: '$4',
                }}
              >
                <Text
                  variant="heading3"
                  css={{
                    marginBottom: '$4',
                    color: '$interface_dark_deep',
                  }}
                >
                  Archivos Anexados
                </Text>
                <Div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {documentFiles.map((f) => (
                    <Div
                      key={f.name}
                      css={{
                        display: 'flex',
                        alignItems: 'center',

                        height: 38,
                        marginBottom: '$4',

                        border: '1px solid $interface_light_deep',
                        background: '$interface_light_up',
                        borderRadius: '$md',
                        paddingLeft: '$4',
                        paddingRight: '$4',

                        gap: '$2',
                      }}
                    >
                      {getFileTypeIcon(f.document_type)}
                      <Text
                        variant="description"
                        css={{
                          textOverflow: 'ellipsis',
                          color: '$interface_dark_down',
                        }}
                      >
                        {f.name}
                      </Text>
                      <Div
                        css={{
                          marginLeft: 'auto',
                        }}
                      >
                        <Button
                          variant="tertiaryCritical"
                          onClick={() => {
                            const files = methods.getValues(
                              'editDocumentEntity.documentFiles',
                            )
                            const newFiles = files.filter(
                              (oldFiles) => oldFiles.id !== f.id,
                            )

                            methods.setValue(
                              'editDocumentEntity.documentFiles',
                              newFiles,
                            )
                          }}
                        >
                          <TrashIcon />
                        </Button>
                      </Div>
                    </Div>
                  ))}
                </Div>
              </Div>

              <Divider orientation={'horizontal'} />

              <Div
                css={{
                  padding: '$2',
                  paddingTop: '$4',
                }}
              >
                <Text
                  variant="heading3"
                  css={{
                    marginBottom: '$4',
                    color: '$interface_dark_deep',
                  }}
                >
                  Nuevos Archivos
                </Text>
                <FileInput />
              </Div>
            </Div>
          </Div>
        }
      />
    </DrawerContainer>
  )
}

const DrawerItemContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '$2',
      }}
    >
      {children}
    </Div>
  )
}

const DrawerItemLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      variant="caption"
      css={{
        color: '$interface_dark_down',
        minWidth: '50%',
      }}
    >
      {children}
    </Text>
  )
}

const FileInput = () => {
  const methods = useFormContext<IDocumentsStateSchema>()
  const [tooManyFilesError, setTooManyFilesError] = useState(false)
  const [filesTooLargeError, setFilesTooLargeError] = useState(false)

  const formFiles = useWatch({
    control: methods.control,
    name: 'editDocumentEntity.newFiles',
  })

  const onDrop = useCallback(
    (files: File[]) => {
      const inFormFiles = methods.getValues('editDocumentEntity.newFiles')
      const newFiles = files.filter(
        (f) => !inFormFiles.find((f2) => f2.name === f.name),
      )

      const newAttachedFiles = [...inFormFiles, ...newFiles]

      if (newAttachedFiles.length > 5) {
        setTooManyFilesError(true)
        return
      }

      methods.setValue('editDocumentEntity.newFiles', newAttachedFiles)

      setTooManyFilesError(false)
      setFilesTooLargeError(false)
    },
    [methods],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: (options) => {
      console.log(options)
    },
    maxFiles: 5,
    maxSize: 100 * 1024 * 1024,
    onDropRejected: (error) => {
      const tooManyFilesError = error.find((e) =>
        e.errors.find((err) => err.code === 'too-many-files'),
      )
      const tooLargeError = error.find((e) =>
        e.errors.find((err) => err.code === 'file-too-large'),
      )

      setTooManyFilesError(!!tooManyFilesError)
      setFilesTooLargeError(!!tooLargeError)
    },
  })

  return (
    <>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {formFiles.map((f) => (
          <Div
            key={f.name}
            css={{
              display: 'flex',
              alignItems: 'center',

              minHeight: 38,
              marginBottom: '$2',

              border: '1px solid $interface_light_deep',
              background: '$interface_light_up',
              borderRadius: '$md',
              paddingLeft: '$4',
              paddingRight: '$4',

              gap: '$2',
            }}
          >
            {getFileTypeIcon(f.type)}
            <Text
              variant="description"
              css={{
                textOverflow: 'ellipsis',
                color: '$interface_dark_down',
              }}
            >
              {f.name}
            </Text>
            <Div
              css={{
                marginLeft: 'auto',
              }}
            >
              <Button
                variant="tertiaryCritical"
                onClick={() => {
                  const files = methods.getValues('editDocumentEntity.newFiles')
                  const newFiles = files.filter(
                    (oldFiles) => oldFiles.name !== f.name,
                  )

                  methods.setValue('editDocumentEntity.newFiles', newFiles)
                }}
              >
                <TrashIcon />
              </Button>
            </Div>
          </Div>
        ))}
      </Div>
      {tooManyFilesError && (
        <Div>
          <Text
            variant="caption"
            css={{
              color: '$status_danger_deep',
            }}
          >
            Solo se pueden adjuntar 5 archivos.
          </Text>
        </Div>
      )}
      {filesTooLargeError && (
        <Div>
          <Text
            variant="caption"
            css={{
              color: '$status_danger_deep',
            }}
          >
            El tamaño máximo de los archivos es de 100 MB.
          </Text>
        </Div>
      )}
      <Div
        {...getRootProps()}
        css={{
          cursor: 'pointer',
          marginTop: '$2',

          border: '1px dashed $interface_light_deep',
          background: '$interface_light_up',
          borderRadius: '$md',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 80,
        }}
      >
        <input {...getInputProps()} />
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',

            svg: {
              height: 20,
              width: 20,
              color: '$interface_dark_down',
            },
          }}
        >
          <Text
            variant="description"
            css={{
              color: '$interface_dark_down',
            }}
          >
            Alzar Archivos
          </Text>
          <CloudArrowUpIcon />
        </Div>
      </Div>
    </>
  )
}
