import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { IListTemplatesParams, ITemplateResponse } from '../../types/reports'

const fetchReportTemplates = async (props: IListTemplatesParams) => {
  try {
    const response = await apiV2.post<ITemplateResponse>(
      '/report-builder/list-templates',
      {
        page: props.page,
        perPage: props.perPage,
        name: props.name,
        favorite: props.favorite,
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const useReportTemplates = (props: IListTemplatesParams) => {
  const query = useQuery(
    ['templates', props],
    async () => {
      const workers = await fetchReportTemplates(props)

      return workers
    },
    {
      keepPreviousData: true,
    },
  )

  return query
}
